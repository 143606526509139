import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { Popup, Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import appStore from '@/stores/AppStore';
import CloudAPI from '@/services/CloudAPI';
import GradiantStack from '@/components/gradiant-stack/GradiantStack';
import userStore from '@/stores/UserStore';
import verifySVG from '@/assets/verify.svg';
import AnalyticsService from '@/services/AnalyticsService';
import BasicInput from '@/components/basic-input/BasicInput';

import './two-factor.scss';

const analytics = new AnalyticsService();

@observer
export default class TwoFactor extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			textVerificationAttempts: 0,
			verificationCode: '',
			verificationCodeError: false
		});
		this.text = getCmpText('EmployerSignup', {
			invalidVerificationCode: 'Verification code was incorrect.',
			verificationCodeTryAgain: 'Please try again.',
			enterVerificationCode: 'Enter Verification Code',
			missingCodeText: 'Did not receive code? ',
			missingCodeCta: 'Resend',
			submitCta: 'Done',
			maxAttemptsReached: 'Please try again later'
		});
		autoBind(this);
	}

	async onNext(page) {
		this.$f7.dialog.preloader();
		try {
			this.data.textVerificationAttempts = this.data.textVerificationAttempts + 1;
			const fId = firebase.auth().currentUser.uid;
			const data = this.data.verificationCode || '';
			const checkCode = await CloudAPI.submitAccountVerificationCode(fId, data.trim());
			analytics.trackWithData('Sign up', { step: 'account created', method: 'text verification' });
			userStore.isCreatingUser = false;
			this.$f7.dialog.close();
			firebase.auth().signOut();
			this.$f7.popup.close('.two-factor-popup');
		} catch (err) {
			console.log('error verifying code: ', err);
			this.$f7.dialog.close();
			if (this.data.textVerificationAttempts < 3) {
				this.$f7.dialog.alert(`${this.text.invalidVerificationCode} ${this.text.verificationCodeTryAgain}`);
				this.data.verificationCodeError = true;
			} else {
				this.$f7.dialog.alert(`${this.text.invalidVerificationCode}`);
				this.$f7.popup.close('.two-factor-popup');
			}
		}
	}

	onCodeChange(e) {
		const value = e.target.value || '';
		const cleanValue = value.replace(/\D/g, '');
		if (cleanValue.length > 6) {
			return;
		}
		this.data.verificationCode = cleanValue;
	}

	onCodeClick() {
		document.getElementById('employerVerificationCodeId').focus();
	}

	handleKeyDown(e) {
		if (e.key === 'Enter' && this.data.verificationCode.length === 6) {
			this.onNext();
		}
	}

	handlePopupClosed() {
		this.props.close();
	}

	componentDidMount() {
		console.log('2 factor mounted');
	}

	resendCode() {
		this.data.textVerificationAttempts = this.data.textVerificationAttempts + 1;
		if (this.data.textVerificationAttempts <= 3) {
			this.data.verificationCode = '';
			this.props.resend();
		} else {
			this.$f7.dialog.alert(this.text.maxAttemptsReached);
			this.$f7.popup.close('.two-factor-popup');
		}
	}

	render() {
		const attemptCount = this.data.textVerificationAttempts + 1;
		return (
			<div>
				<Popup
					opened={this.props.isOpen}
					onPopupClosed={this.handlePopupClosed}
					className={`two-factor-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
					closeByBackdropClick={false}
					closeOnEscape={false}
				>
					<GradiantStack stack="3" consumer={this.props.consumer} animate={true} id={`${this.props.id}_employer-two-factor`} />

					<div className={`consumer-validation-page`}>
						<div className={`content-ctn vbox hcenter animated fadeIn`}>
							<img src={verifySVG} className="herosvg" />
							<h1>{this.text.enterVerificationCode}</h1>
							<div className="text">Attempt {attemptCount} of 3</div>
							<div className="code-box vbox vcenter hcenter" onClick={this.onCodeClick}>
								{!this.data.verificationCodeError && (
									<div className="cells hbox vcenter hcenter">
										{[0, 1, 2, 3, 4, 5].map((num) => {
											let inputVals = this.data.verificationCode.split('');
											return (
												<div key={`input-field-${num}`} className="cell">
													{inputVals[num] ? inputVals[num] : ''}
												</div>
											);
										})}
									</div>
								)}
								{!this.data.verificationCodeError && (
									<input
										id="employerVerificationCodeId"
										type={appStore.isIos ? 'text' : 'number'}
										maxLength={6}
										value={this.data.verificationCode}
										onChange={this.onCodeChange}
										onKeyDown={this.handleKeyDown}
										autoComplete="one-time-code"
										className="hidden-input"
									/>
								)}
								{this.data.verificationCodeError && (
									<BasicInput
										id="employerVerificationCodeId"
										type={appStore.isIos ? 'text' : 'number'}
										maxLength={6}
										value={this.data.verificationCode}
										onChange={this.onCodeChange}
										onKeyDown={this.handleKeyDown}
										autoComplete="one-time-code"
									/>
								)}
							</div>
							<div className="text-link hbox vcenter" onClick={this.resendCode}>
								<div className="text">{this.text.missingCodeText}</div>
								<div className={`cta blue`}>{this.text.missingCodeCta}</div>
							</div>
							<Button fill large className={`round-btn blue`} disabled={this.data.verificationCode.length !== 6} onClick={this.onNext}>
								{this.text.submitCta}
							</Button>
						</div>
					</div>
				</Popup>
			</div>
		);
	}
}
