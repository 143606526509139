import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link, Popup } from 'framework7-react';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import FileDrop from '@/components/_EXPRESS/file-drop/FileDrop';
import ImageCropper from '@/components/image-cropper/ImageCropper';
import { DIMENSIONS } from '@/services/CanvasEditorService';

@observer
export default class NewCardPopover extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			newCardData: {
				documentName: '',
				documentSize: 'standard',
				documentSide: 'front',
				imageSettings: null,
				image: null
			}
		});
		autoBind(this);
	}

	onFileDropError(e) {
		console.log(e);
	}

	onFileDropComplete(e) {
		let fileReader = new FileReader();

		fileReader.onloadend = (e) => {
			var image = new Image();
			image.src = e.target.result;
			this.data.newCardData.image = image;
		};
		fileReader.readAsDataURL(e);
	}

	handleCrop(data) {
		const documentWidth = DIMENSIONS[this.data.newCardData.documentSize].width;
		const documentHeight = DIMENSIONS[this.data.newCardData.documentSize].height;

		const cropWidth = data.crop.width;
		const cropHeight = data.crop.height;

		const scaleOffset = documentWidth / cropWidth;

		this.data.newCardData.imageSettings = {
			width: data.image.width * scaleOffset,
			height: data.image.height * scaleOffset,
			x: data.image.x * scaleOffset,
			y: data.image.y * scaleOffset
		};
	}

	imageCropper() {
		return (
			<>
				{!this.data.newCardData.image && (
					<FileDrop
						onError={this.onFileDropError}
						accept="image/*, application/json"
						onComplete={this.onFileDropComplete}
						multi={false}
					/>
				)}
				{this.data.newCardData.image && (
					<div style={{ width: '100%', height: '250px', position: 'relative' }}>
						<ImageCropper
							image={this.data.newCardData.image}
							aspect={DIMENSIONS[this.data.newCardData.documentSize].ratio}
							onCrop={this.handleCrop}
						/>
					</div>
				)}
			</>
		);
	}

	handleSubmit() {
		const { onSubmit } = this.props;
		if (onSubmit) {
			onSubmit(this.data.newCardData);
		}
	}

	getFormConfig() {
		const dimensions = Object.fromEntries(Object.entries(DIMENSIONS).map(([k, v]) => [k, v.label]));
		return {
			documentName: {
				label: 'Card Name',
				type: 'text',
				placeholder: 'Enter name for card',
				validator: {
					type: 'length',
					value: 4
				}
			},
			row1: {
				// documentSize: {
				// 	label: 'Select the card dimensions',
				// 	type: 'dropdown',
				// 	className: 'dropdown-form-input',
				// 	icon: 'far fa-id-card',
				// 	listItems: dimensions
				// },
				documentSide: {
					label: 'Choose side',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-arrows-alt-h',
					listItems: { front: 'FRONT', back: 'BACK' }
				}
			}
		};
	}

	onClose() {
		this.data.newCardData = {
			documentName: '',
			documentSize: 'standard',
			documentSide: 'front',
			imageSettings: null,
			image: null
		};
		this.props.onClose();
	}

	render() {
		const { onSubmit, opened, onClose } = this.props;
		return (
			<FormPopup
				opened={opened}
				title="New Card"
				buttonText="Continue"
				formConfig={this.getFormConfig()}
				formData={this.data.newCardData}
				onSubmit={this.handleSubmit}
				onClose={this.onClose}
				additionalContent={this.imageCropper()}
				fullScreen={false}
			/>
		);
	}
}
