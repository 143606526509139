import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import { getCmpText, getLocalizedQuickLinks } from '@/utils/Localization';
import './no-payment-methods.scss';
import creditCardsNone from '@/assets/credit_cards_none.svg';

@observer
export class NoPaymentMethods extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('NoPaymentMethods', {
			noCardsPresentMessage: 'You do not have any cards added to your account yet!',
			addCardButtonText: 'Add a credit card',
			rejectNoCardsDialog: "I don't want to add a credit card yet"
		});
		autoBind(this);
	}

	render() {
		// let {fullName, brand} = this.data;
		let { onAddCard, onCancel } = this.props;
		return (
			<div className="page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="back hbox vcenter animated fadeInLeft" onClick={onCancel}>
							Cancel
						</div>
					</div>
				</div>
				<div className="no-cards-container">
					<img src={creditCardsNone} alt="" className="no-cards-image" />
					<h2 className="message">{this.text.noCardsPresentMessage}</h2>
					<Button
						fill
						large
						className="round-btn purple"
						onClick={onAddCard}
						id="add-card-button"
						tabLink="#add-credit-card"
						// disabled={!this.data.formComplete}
					>
						{this.text.addCardButtonText} <i className="far fa-long-arrow-right"></i>
					</Button>
					<Button className="btn cancel alt" onClick={onCancel}>
						{this.text.rejectNoCardsDialog}
					</Button>
				</div>
			</div>
		);
	}
}
