import React, { PureComponent } from 'react';

export default class Stack3 extends PureComponent {
	render() {
		return this.props.purple ? (
			<svg xmlns="http://www.w3.org/2000/svg" width="755" height="655" fill="none" viewBox="0 0 755 655">
				<g filter={`url(#filter0_d-${this.props.id})`} transform="matrix(.75904 .64248 -.7576 .66116 378.915 4.825)">
					<rect width="492.772" height="492.854" fill="#8CA5FF" rx="35"></rect>
					<rect width="492.772" height="492.854" fill={`url(#paint0_linear-${this.props.id})`} rx="35"></rect>
					<rect width="492.772" height="492.854" fill={`url(#paint1_linear-${this.props.id})`} rx="35"></rect>
				</g>
				<path
					fill={`url(#paint2_linear-${this.props.id})`}
					d="M485.334 218.965c14.645-12.78 38.41-13.072 53.082-.653l189.607 160.491c14.672 12.419 14.695 32.847.051 45.627L538.786 589.623c-14.645 12.78-38.41 13.073-53.082.654L296.097 429.786c-14.673-12.42-14.695-32.848-.051-45.628l189.288-165.193z"
					style={{ mixBlendMode: 'multiply' }}
				></path>
				<rect
					width="319.653"
					height="319.706"
					fill={`url(#paint3_linear-${this.props.id})`}
					rx="46"
					transform="matrix(.75951 .64191 -.75808 .6606 410.892 127.825)"
				></rect>
				<defs>
					<filter
						id={`filter0_d-${this.props.id}`}
						width="744.321"
						height="643.729"
						x="0.078"
						y="11.185"
						colorInterpolationFilters="sRGB"
						filterUnits="userSpaceOnUse"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
						<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
						<feOffset dx="-7" dy="7"></feOffset>
						<feGaussianBlur stdDeviation="7"></feGaussianBlur>
						<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"></feColorMatrix>
						<feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
						<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
					</filter>
					<linearGradient
						id={`paint0_linear-${this.props.id}`}
						x1="496.037"
						x2="47.966"
						y1="392.918"
						y2="398.802"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#0003EB"></stop>
						<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
					<linearGradient
						id={`paint1_linear-${this.props.id}`}
						x1="848.133"
						x2="-61.683"
						y1="848.985"
						y2="53.649"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.141" stopColor="#5151C2"></stop>
						<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
					<linearGradient
						id={`paint2_linear-${this.props.id}`}
						x1="805.753"
						x2="614.627"
						y1="398.302"
						y2="184.904"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
						<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
					<linearGradient
						id={`paint3_linear-${this.props.id}`}
						x1="321.77"
						x2="31.115"
						y1="254.879"
						y2="258.696"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#83B5FF" stopOpacity="0.31"></stop>
						<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
				</defs>
			</svg>
		) : (
			<svg xmlns="http://www.w3.org/2000/svg" width="755" height="655" fill="none" viewBox="0 0 755 655">
				<g filter={`url(#filter0_d-${this.props.id})`} transform="matrix(.75904 .64248 -.7576 .66116 378.915 5.269)">
					<rect width="492.772" height="492.854" fill="#4486FF" rx="35"></rect>
					<rect width="492.772" height="492.854" fill={`url(#paint0_linear-${this.props.id})`} rx="35"></rect>
					<rect width="492.772" height="492.854" fill={`url(#paint1_linear-${this.props.id})`} rx="35"></rect>
				</g>
				<path
					fill={`url(#paint2_linear-${this.props.id})`}
					d="M519.804 176.332c14.644-12.78 38.41-13.072 53.082-.653L762.493 336.17c14.672 12.419 14.695 32.847.05 45.627L573.255 546.99c-14.644 12.78-38.41 13.073-53.082.654L330.566 387.152c-14.672-12.419-14.694-32.847-.05-45.627l189.288-165.193z"
					style={{ mixBlendMode: 'multiply' }}
				></path>
				<rect
					width="319.653"
					height="319.706"
					fill={`url(#paint3_linear-${this.props.id})`}
					rx="35"
					transform="matrix(.75951 .64191 -.75808 .6606 531.259 64.388)"
					style={{ mixBlendMode: 'multiply' }}
				></rect>
				<defs>
					<filter
						id={`filter0_d-${this.props.id}`}
						width="744.321"
						height="643.729"
						x="0.077"
						y="11.63"
						colorInterpolationFilters="sRGB"
						filterUnits="userSpaceOnUse"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
						<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
						<feOffset dx="-7" dy="7"></feOffset>
						<feGaussianBlur stdDeviation="7"></feGaussianBlur>
						<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"></feColorMatrix>
						<feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
						<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
					</filter>
					<linearGradient
						id={`paint0_linear-${this.props.id}`}
						x1="496.037"
						x2="47.966"
						y1="392.918"
						y2="398.802"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#66FFA6" stopOpacity="0.3"></stop>
						<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
					<linearGradient
						id={`paint1_linear-${this.props.id}`}
						x1="848.133"
						x2="-61.683"
						y1="848.985"
						y2="53.649"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.141" stopColor="#71FFAC"></stop>
						<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
					<linearGradient
						id={`paint2_linear-${this.props.id}`}
						x1="840.222"
						x2="649.097"
						y1="355.669"
						y2="142.271"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
						<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
					<linearGradient
						id={`paint3_linear-${this.props.id}`}
						x1="321.77"
						x2="31.115"
						y1="254.879"
						y2="258.696"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
						<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
					</linearGradient>
				</defs>
			</svg>
		);
	}
}
