import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './printing-wizard.scss';
import _ from 'lodash';
import { Button, Link, Popup } from 'framework7-react';
import { PrintType } from './PrintingGroups';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import StateAbbrvMap from '@/utils/StateAbbrvMap';

@observer
export default class PrintingWizardPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			formValid: true,
			selectedType: PrintType.INDIVIDUAL,
			addressFormData: { street: '', street2: '', city: '', state: 'al', zipcode: '', saveAddress: true },
			carrierLetterInfo: { includeCarrierLettersForBulkShipment: false }
		});
		autoBind(this);
	}

	closeGroupPopup() {
		if (this.props.onClose) {
			this.resetForm();
			this.props.onClose();
		}
	}

	resetForm() {
		this.data.selectedType = PrintType.INDIVIDUAL;
		this.data.addressFormData = { street: '', street2: '', city: '', state: 'al', zipcode: '', saveAddress: false };
		this.data.formValid = true;
	}

	handlePopupOpen() {
		let {
			street = '',
			street2 = '',
			city = '',
			state = 'al',
			zipcode = '',
			name = '',
			contactName = '',
			includeCarrierLettersForBulkShipment = false
		} = _.cloneDeep(this.props.group);
		this.data.addressFormData = {
			street,
			street2,
			city,
			state,
			zipcode,
			saveAddress: _.get(this.props, 'group.printingType') === 'bulk',
			name,
			contactName: _.get(this.props, 'group.printingType') === 'bulk' ? contactName : null
		};
		this.data.carrierLetterInfo = {
			includeCarrierLettersForBulkShipment
		};
		this.data.selectedType = _.get(this.props, 'group.printingType');
	}

	onSubmit() {
		const submission = {
			type: _.cloneDeep(this.data.selectedType),
			address: _.cloneDeep(this.data.addressFormData),
			carrierLetterInfo: _.cloneDeep(this.data.carrierLetterInfo)
		};
		this.resetForm();
		this.props.onSubmit(submission);
	}

	getAddressForm() {
		const formConfig = {
			name: {
				label: 'Company Name',
				type: 'text',
				placeholder: 'Company Name',
				validator: {
					type: 'length',
					value: 1
				}
			},
			contactName: {
				label: 'Recipient Name (Care of)',
				type: 'text',
				placeholder: 'Recipient Name'
			},
			street: {
				label: 'Address Street 1',
				type: 'text',
				placeholder: 'Street address',
				validator: {
					type: 'length',
					value: 1
				}
			},
			row1: {
				street2: {
					label: 'Address Street 2',
					type: 'text',
					placeholder: 'Apt# PO Box Etc...'
				},
				city: {
					label: 'City',
					type: 'text',
					placeholder: 'City',
					validator: {
						type: 'length',
						value: 1
					}
				}
			},
			row2: {
				state: {
					label: 'State',
					type: 'dropdown',
					className: 'dropdown-form-input state-dropdown',
					listItems: StateAbbrvMap
				},
				zipcode: {
					label: 'Postal Code (5-digit)',
					type: 'number',
					placeholder: 'Postal Code',
					validator: {
						type: 'length',
						value: 5,
						match: true
					}
				}
			},
			saveAddress: {
				label: 'Save as the shipping address for this group?',
				type: 'toggle'
			}
		};
		if (this.data.selectedType === 'individual') {
			delete formConfig.contactName;
			delete formConfig.saveAddress;
		}
		return (
			<div>
				<h3>{this.data.selectedType === 'individual' ? 'Return Mail Address' : 'Shipping Address'}</h3>
				<ExpressFormBuilder
					formData={this.data.addressFormData}
					formConfig={formConfig}
					setValidationState={(valid) => {
						this.data.formValid = valid;
					}}
				/>
			</div>
		);
	}

	getCarrierLetterForm() {
		if (this.data.selectedType === 'individual') return null;

		const formConfig = {
			includeCarrierLettersForBulkShipment: {
				label: 'Add Carrier Letters to bulk shipment',
				type: 'toggle'
			}
		};
		return (
			<div>
				<h3>Carrier Letter Information</h3>
				<ExpressFormBuilder
					formData={this.data.carrierLetterInfo}
					formConfig={formConfig}
					setValidationState={(valid) => {
						this.data.formValid = valid;
					}}
				/>
			</div>
		);
	}

	isAddressValid() {
		return false;
	}

	disableShipping() {
		this.data.selectedType = null;
		this.onSubmit();
	}

	render() {
		let { opened, fullScreen, group } = this.props;
		return (
			<Popup
				className={`printing-group-wizard-popup express-form-popup ${fullScreen ? 'fullscreen' : ''}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeGroupPopup}
				onPopupOpen={this.handlePopupOpen}
				tabletFullscreen={fullScreen}
			>
				<div className="popup-header">
					<div className="title grow-1 ellipse">Print Setup - {_.startCase(_.toLower(group?.name))}</div>
					<Link popupClose>
						<i className="fad fa-times-square"></i>
					</Link>
				</div>
				<div className="main-content y-scroll">
					<h3>Fulfillment Type:</h3>
					<div className="toggle-ctn hbox vcenter">
						<div
							className={`fulfillment-type ${this.data.selectedType === PrintType.INDIVIDUAL && 'selected'}`}
							onClick={() => {
								this.data.selectedType = PrintType.INDIVIDUAL;
								this.data.formValid = this.isAddressValid();
							}}
						>
							<div className="title">Individual Shipment</div>
							<div className="desc">
								All ID cards will be printed and individually shipped to each members address as listed in your eligibility file.
							</div>
						</div>
						<div
							className={`fulfillment-type ${this.data.selectedType === PrintType.BULK && 'selected'}`}
							onClick={() => {
								this.data.selectedType = PrintType.BULK;
								this.data.formValid = this.isAddressValid();
							}}
						>
							<div className="title">Bulk Shipment</div>
							<div className="desc">
								The default bulk shipping address is the groups saved address. You can set a one time shipping address below, or choose
								to update the groups existing address.
							</div>
						</div>
					</div>
					{!!this.data.selectedType && this.getAddressForm()}
					{!!this.data.selectedType && <div className="spacer" />}
					{!!this.data.selectedType && this.getCarrierLetterForm()}
				</div>
				<div className="btn-ctn">
					<Button fill large className="round-btn" disabled={!this.data.formValid} onClick={this.onSubmit}>
						Submit
					</Button>
					{_.get(this.props, 'group.printingType') && (
						<Button large className="round-btn" onClick={this.disableShipping}>
							Disable
						</Button>
					)}
				</div>
			</Popup>
		);
	}
}
