import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Preloader } from 'framework7-react';
import './template-picker.scss';
import TemplateItem from './TemplateItem';

@observer
export default class TemplatePicker extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const { loading, templates, onSelect, selected } = this.props;
		if (!loading && templates.length === 0) {
			return <NoTemplates />;
		}
		if (loading) {
			return (
				<div className="hbox vcenter hcenter w-100">
					<Preloader size={32} color="blue"></Preloader>
				</div>
			);
		} else {
			return <Templates templates={templates} onSelect={onSelect} selected={selected}/>;
		}
	}
}

const NoTemplates = observer((props) => {
	return <div>No templates available!</div>;
});

const Templates = observer((props) => {
	const { templates, onSelect, selected } = props;

	return (
		<div className="template-picker w-100">
			<div className="grid-wrapper">
				{templates.map((template) => {
					return (
						<TemplateItem
							name={template.name}
							thumbnailKey={template.thumbnails.thumbnailMedium}
							id={template._id}
							tags={template.tags}
                            selected={selected}
                            onSelect={() => onSelect(template)}
						/>
					);
				})}
			</div>
		</div>
	);
});
