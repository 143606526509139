import React, { Component } from 'react';
import { Button, List, Chip, Progressbar, Preloader } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind, getFindCareIcon } from '../../utils/GeneralUtils';
import GlobalHeader from '../../components/global-header/GlobalHeader';
import UserService from '../../services/UserService';
import providerStore from '../../stores/ProviderStore';
import appStore from '../../stores/AppStore';
import _ from 'lodash';
import './find-care.scss';
import ProviderFilter from '../provider-filter/ProviderFilter';
import Map from '../../components/map/Map';
import ProviderSearch from '../provider-search/ProviderSearch';
import ProviderService from '../../services/ProviderService';
import ConfigService from '../../services/ConfigService';
import StorageService from '../../services/StorageService';
import BannerCard from '../../components/banner-card/BannerCard';
import ExternalService from '../../services/ExternalService';
import AnalyticsService from '../../services/AnalyticsService';
import doctorMale from '../../assets/doctor-male.svg';
import doctorFemale from '../../assets/doctor-female.svg';
import ProviderListItem from './provider-list-item/ProviderListItem';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
class FindCare extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			filterOpen: false,
			searchOpen: false,
			searching: true,
			telehealthOpen: false,
			startingLocation: { ..._.cloneDeep(appStore.currentLocation) },
			currentLocation: { ..._.cloneDeep(appStore.currentLocation) },
			activeProvider: {},
			showLoader: false,
			pageCount: 0
		});
		this.text = getCmpText('FindCare', {
			listText: 'List',
			mapText: 'Map',
			emptyText: `Sorry, no results were found. Try changing the filters above.`,
			searching: 'Finding the best providers...',
			searchError: 'Something went wrong, please try again.',
			telehealthTitle: 'Call Now',
			telehealthText2: 'Speak to a licensed doctor in minutes',
			telehealthText1: 'Telehealth $<%= copay %> co-pay',
			loadMore: 'Load More',
			filterText: 'Filter',
			searchText: 'Search',
			expandRadius: 'Expand Search Radius',
			careTeamText: 'Care Team',
			pcpText: 'Primary Care Provider',
			includeOutOfNetwork: 'Include out of network providers'
		});
		autoBind(this);
	}

	async componentDidMount() {
		let showMenuOnLoad = await storageService.getValue('app', 'showFilterMenu');
		if (showMenuOnLoad) {
			this.data.filterOpen = true;
			storageService.setValue('app', 'showFilterMenu', false);
		}
		if (providerStore.searchResults.length <= 0) {
			this.fireSearch();
		} else {
			this.data.searching = false;
		}
	}

	async expandMaxRadius() {
		const radiusConfig = await ProviderService.getRadiusConfig();
		providerStore.filters.radius = _.clone(radiusConfig.max) || 50;
		this.fireSearch();
	}

	includeOutOfNetworkResults() {
		providerStore.filters.inNetwork = false;
		this.fireSearch();
	}

	async loadNextPage() {
		let currentPage = providerStore.page;
		currentPage++;
		providerStore.page = currentPage;
		const pageEl = document.getElementById('findCareResults');
		const position = pageEl && pageEl.scrollTop;
		await this.fireSearch(currentPage);
		if (pageEl) {
			pageEl.scrollTop = position;
		}
	}

	toggleContent(evt) {
		if (appStore.isExpressPortal && appStore.demoMode) {
			this.fireSearch();
		}
		const optionClicked = evt.currentTarget.getAttribute('data-option');
		const currentSelection = _.clone(providerStore.toggleValue);
		const isSelected = optionClicked === currentSelection;
		if (isSelected) return;
		providerStore.toggleValue = optionClicked;
		if (optionClicked === 'list') {
			analytics.track('load_list');
		} else {
			analytics.track('load_map');
		}
	}

	async fireSearch(page) {
		console.log('Searching...');
		if (providerStore.page === 0) {
			this.data.searching = true;
		}
		try {
			await ProviderService.searchForProviders(page);
			this.data.showLoader = false;
			this.data.searching = false;
			this.data.activeProvider = {};
		} catch (err) {
			this.data.searching = false;
			console.log(err);
			this.$f7.dialog.alert(this.text.searchError);
		}
	}

	emptyContent() {
		const radiusConfig = ProviderService.getRadiusConfig();
		const radiusMax = _.get(radiusConfig, 'max') || 50;
		const currentFilters = _.cloneDeep(providerStore.filters) || {};
		const canExpandRadius = _.get(currentFilters, 'radius') < radiusMax;
		const canExpandNetwork = _.get(currentFilters, 'inNetwork');
		return (
			<div className="empty-list vbox vcenter hcenter animated fadeIn">
				<i className="fad fa-search"></i>
				<i className="fad fa-grin-beam-sweat"></i>
				<div className="empty-msg">{this.text.emptyText}</div>
				{(canExpandRadius || canExpandNetwork) && <div className="empty-msg">OR</div>}
				{canExpandRadius && (
					<Button large onClick={this.expandMaxRadius}>
						{this.text.expandRadius}
					</Button>
				)}
				{canExpandNetwork && !canExpandRadius && (
					<Button large onClick={this.includeOutOfNetworkResults}>
						{this.text.includeOutOfNetwork}
					</Button>
				)}
			</div>
		);
	}

	searchingContent() {
		return (
			<div className="empty-list vbox vcenter hcenter animated fadeIn">
				<h3>{this.text.searching}</h3>
				<i className="fad fa-search animated search-animation infinite"></i>
				<i className="fad fa-user-md"></i>
				<i className="fad fa-hospital"></i>
				<i className="fad fa-tooth"></i>
				<i className="fad fa-flask"></i>
				<i className="fad fa-glasses-alt"></i>
				<Progressbar infinite color="multi" className="animated fadeIn"></Progressbar>
			</div>
		);
	}

	getPhotoUrl(p) {
		if (_.get(p, 'photo', null) && _.get(p, 'photo').indexOf('general_doctor') < 0) {
			return p.photo.replace('asset1', 'asset2').replace('asset3', 'asset2').replace('asset4', 'asset2');
		}
		return _.get(p, 'personDetails.gender', 'm') === 'm' ? doctorMale : doctorFemale;
	}

	buildProvidersList() {
		let { searchResults } = providerStore;
		if (this.data.searching) {
			return this.searchingContent();
		}
		if (searchResults.length <= 0) {
			return this.emptyContent();
		}
		let { telehealthTitle, telehealthText1, telehealthText2, pcpText, careTeamText } = this.text;
		let config = ConfigService.getConfiguration();
		let listItems = [];
		const telehealthCopayTemplate = _.template(telehealthText1);
		const telehealthPlanData = UserService.getPlanTelehealthData();
		const telehealthCopayText = telehealthCopayTemplate(telehealthPlanData);

		if (_.get(config, 'features.telehealth')) {
			listItems.push(
				<BannerCard
					onClick={() => {
						ExternalService.telehealthInit();
					}}
					iconCls="fad fa-phone-alt"
					topText={UserService.isConsumer() ? '' : telehealthCopayText}
					title={telehealthTitle}
					bottomText={telehealthText2}
					key="telehealth-banner-card"
					color="blue2"
				/>
			);
		}

		_.forEach(searchResults, (p) => {
			const usersPcpNpi = _.get(UserService.getCurrentPcp(), 'npi');
			const isPcp = usersPcpNpi && usersPcpNpi === p.NPI;
			const hasName = p.fullName.trim().length > 0;
			if (hasName) {
				listItems.push(
					<ProviderListItem
						onClick={() => ProviderService.openProviderProfile(p, 'findCare')}
						image={p.isPerson ? this.getPhotoUrl(p) || true : false}
						icon={!p.isPerson && getFindCareIcon(p)}
						gender={_.get(p, 'personDetails.gender') || 'm'}
						provider={p}
						key={`provider-list-item-${p.NPI}`}
						isCareTeam={ProviderService.isOnCareTeam(p)}
						isPcp={isPcp}
						name={ProviderService.getCleanName(p)}
						specialties={_.get(p, 'allSpecialties', []).toString().split(',').join(', ').split('Other Medical Specialists,').join('')}
					/>
				);
			}
		});

		return listItems;
	}

	getContent() {
		switch (providerStore.toggleValue) {
			case 'list': {
				let { showLoader } = this.data;
				return (
					<List className="find-care-content ctn full y-scroll" key="provider-list-content" mediaList id="findCareResults">
						<>{this.buildProvidersList()}</>
						<div className="load-more-ctn vbox vcenter hcenter">
							{!showLoader && providerStore.searchResults.length > 0 && providerStore.searchResults.length % 75 === 0 && (
								<Button
									onClick={() => {
										this.data.showLoader = true;
										this.loadNextPage();
									}}
								>
									{this.text.loadMore}
								</Button>
							)}
							{showLoader && <Preloader color="blue"></Preloader>}
						</div>
					</List>
				);
			}
			case 'map': {
				let { searchResults } = providerStore;
				return (
					<div className="map-content ctn full animated fadeIn" key="maps-content">
						{this.data.searching && this.searchingContent()}
						{!this.data.searching && searchResults.length <= 0 && this.emptyContent()}
						{!this.data.searching && searchResults.length > 0 && (
							<Map
								onTelehealth={() => {
									ExternalService.telehealthInit();
								}}
							/>
						)}
					</div>
				);
			}
		}
	}

	render() {
		let { listText, mapText } = this.text;
		let { toggleValue, filters } = providerStore;
		let { filterOpen, searchOpen } = this.data;
		return (
			<div className={`find-care-page-component ${appStore.isMobile ? '' : 'desktop'}`} key={Math.random()} key="home-page-component">
				<GlobalHeader />
				<div className="content-header hbox vcenter animated fadeInLeft">
					<div
						className="vbox hcenter vcenter grow-1 option neumorphic"
						onClick={() => {
							this.data.searchOpen = true;
						}}
					>
						<i className="fad fa-search" />
						<div>{this.text.searchText}</div>
					</div>
					<div
						className="vbox hcenter vcenter grow-1 option neumorphic"
						onClick={() => {
							this.data.filterOpen = true;
						}}
					>
						<i className="fad fa-filter" />
						<div>{this.text.filterText}</div>
					</div>

					<div
						className={`vbox hcenter vcenter grow-1 option neumorphic ${toggleValue === 'list' ? 'active' : ''}`}
						onClick={this.toggleContent}
						data-option="list"
					>
						<i className="fad fa-th-list"></i>
						<div>{listText}</div>
					</div>
					<div
						className={`vbox hcenter vcenter grow-1 option neumorphic ${toggleValue === 'map' ? 'active' : ''}`}
						onClick={this.toggleContent}
						data-option="map"
					>
						<i className="fad fa-map" />
						<div>{mapText}</div>
					</div>
				</div>
				{this.getContent()}
				<ProviderFilter
					open={filterOpen}
					filters={_.cloneDeep(filters)}
					onClose={(applyFilters) => {
						this.data.filterOpen = false;
						if (applyFilters) {
							this.data.pageCount = 0;
							this.fireSearch();
						}
					}}
				/>
				<ProviderSearch
					open={searchOpen}
					onClose={() => {
						this.data.searchOpen = false;
					}}
				/>
			</div>
		);
	}
}

export default FindCare;
