import React, { Component } from 'react';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import screenerSVG from '@/assets/screener.svg';
import { Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import './screening-emergency.scss';
import screeningStore from '@/stores/ScreeningStore';
import ScreeningService from '@/services/ScreeningService';
@observer
export class ScreeningEmergency extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('ScreeningEmergency', {
			screeningEmergencyHeadline: 'Is this an emergency?',
			screeningEmergencySubheadline: 'Stop and call 911 if you are experiencing:',
			call911Headline: '{You|They} should call 911',
			call911SubHeadline: '{You|They} should seek care immediately.'
		});
		autoBind(this);
	}

	onNext() {
		const { onNext } = this.props;
		if (screeningStore.results['emergency'] === 'emergency_experiencing') {
			screeningStore.call911 = true;
		} else {
			onNext();
		}
	}

	selectChoice(choice) {
		const { onSelect } = this.props;
		onSelect(choice);
		// onNext();
	}
	renderChoices() {
		const { choices, isSelected } = this.props;
		let options = [];
		choices.forEach((choice, key) => {
			options.push(
				<Button
					className={`neubtn screening-choice-btn hbox hleft vcenter ${isSelected('emergency', choice.key) ? 'selected' : ''}`}
					key={key}
					onClick={() => this.selectChoice(choice)}
				>
					<div className="toggle-indicator vbox vcenter hcenter">
						{isSelected('emergency', choice.key) && <i className="fad fa-dot-circle"></i>}
					</div>
					<div className="text">{ScreeningService.pronounPlural(choice.text)}</div>
				</Button>
			);
		});

		return options;
	}

	renderBody() {
		const { screeningEmergencyHeadline, screeningEmergencySubheadline } = this.text;
		return (
			<div className="screening-emergency-page">
				<div className="header vbox hleft">
					<i className="fa fa-ambulance"></i>
					<h1>{screeningEmergencyHeadline}</h1>
					<h2>{screeningEmergencySubheadline}</h2>
				</div>
				<div className="intro-body">
					<ul className="info">
						<li>
							<div className="bullet">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
							<div className="text">Severe, constant chest pain or pressure</div>
						</li>
						<li>
							<div className="bullet">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
							<div className="text">Extreme difficulty breathing</div>
						</li>
						<li>
							<div className="bullet">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
							<div className="text">Severe, constant lightheadedness</div>
						</li>
						<li>
							<div className="bullet">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
							<div className="text">Serious disorientation or unresponsiveness</div>
						</li>
						<li>
							<div className="bullet">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
							<div className="text">Blue-tinted face or lips</div>
						</li>
					</ul>
					<div className="choices">{this.renderChoices()}</div>
					<Button
						fill
						large
						className="round-btn purple"
						onClick={this.onNext}
						id="add-card-button"
						disabled={screeningStore.nextQuestion === ''}
					>
						Next <i className="far fa-long-arrow-right"></i>
					</Button>
				</div>
			</div>
		);
	}

	renderCall911Body() {
		const { call911Headline, call911SubHeadline } = this.text;
		return (
			<div className="screening-emergency-page">
				<div className="header vbox vcenter hcenter">
					<i className="fa fa-ambulance"></i>
					<h1>{ScreeningService.pronounPlural(call911Headline)}</h1>
					<h2>{ScreeningService.pronounPlural(call911SubHeadline)}</h2>
				</div>
			</div>
		);
	}
	render() {
		const { showEmergency } = this.props;
		if (!showEmergency) {
			return '';
		} else if (screeningStore.call911) {
			return this.renderCall911Body();
		} else {
			return this.renderBody();
		}
	}
}
