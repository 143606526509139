import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Preloader } from 'framework7-react';
import _ from 'lodash';
import './table.scss';

@observer
export default class Table extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			searchItem: null,
			searchInput: ''
		});
		autoBind(this);
	}

	handleItemClick(evt) {
		const rowId = evt.currentTarget.getAttribute('data-id');
		const rowData = _.find(this.props.rows, { id: rowId });
		if (rowData.onClick) {
			rowData.onClick(rowData, evt);
		}
	}

	openSearch(evt) {
		this.data.searchInput = '';
		const searchItem = evt.currentTarget.getAttribute('data-item');
		this.data.searchItem = Number(searchItem);
	}

	searchChange(evt) {
		const value = evt.currentTarget.value;
		this.data.searchInput = value;
	}

	getRows() {
		const openIndex = this.data.searchItem;
		// const rows = _.cloneDeep(this.props.rows);
		const hasSearchInput =
			this.data.searchInput && this.data.searchInput.length > 0;
		const filteredRows = this.props.rows
			.map((r) => r)
			.filter((row) => {
				if (hasSearchInput) {
					const matchData = _.get(row, `items[${openIndex}].text`) || '';
					const searchCriteria = _.toLower(this.data.searchInput);
					const isMatch = _.toLower(matchData).indexOf(searchCriteria) > -1;
					return isMatch;
				}
				return true;
			});
		return filteredRows;
	}

	closeSearch() {
		this.data.searchInput = '';
		this.data.searchItem = null;
	}

	render() {
		const headers = this.props.headers || [];
		const rows = this.getRows();
		const searchOpen = this.data.searchItem;

		return (
			<div
				className={`express-table ${this.props.showLoader ? '' : 'shadow'} ${
					this.props.id || ''
				}`}
			>
				{this.props.showLoader && (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue" />
					</div>
				)}
				{!this.props.showLoader && headers && (
					<div className="table-list-header hbox vcenter">
						{headers.map((header, headerIndex) => {
							if (!isNaN(searchOpen) && searchOpen === headerIndex) {
								return (
									<div
										key={`table-item-${this.props.id}-${header.text}`}
										className={`input-ctn hbox vcenter header-item ${
											header.classList || ''
										}`}
									>
										<i
											className="fas fa-times-circle"
											onClick={this.closeSearch}
										/>
										<input
											placeholder={`Search by ${header.text}...`}
											value={this.data.searchInput}
											onChange={this.searchChange}
										/>
									</div>
								);
							}
							return (
								<div
									className={`header-item ${header.classList || ''} ${
										header.canSearch ? 'with-search' : ''
									}`}
									key={`table-item-${this.props.id}-${header.text}`}
									data-item={headerIndex}
									onClick={header.canSearch ? this.openSearch : () => {}}
								>
									{header.canSearch && <i className="fas fa-search" />}
									{header.text}
								</div>
							);
						})}
					</div>
				)}
				{rows.map((row, rowIndex) => {
					const rowItems = row.items || [];
					return (
						<div
							className={`table-list-item row ${row.classList || ''}`}
							key={`table-row-${this.props.id}-${row.id}`}
							data-id={row.id}
							onClick={this.handleItemClick}
						>
							{rowItems.map((rowItem, rowItemIndex) => {
								if (rowItem?.hide && rowItem?.hideMessage)
									return rowItem.hideMessage;
								return (
									<div
										key={`table-row-item-${this.props.id}-${rowIndex}-${rowItemIndex}`}
										className={`row-item ${rowItem.classList || ''}`}
									>
										{rowItem.component ?? rowItem.text}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	}
}
