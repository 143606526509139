import React, { Component } from 'react';
import './tab-slider.scss';
import { observer } from '@/utils/State';

@observer
export default class TabSlider extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let { activeTab, setActiveTab, config } = this.props;
		const tabCount = Object.keys(config).length;
		return (
			<div className="tab-slider animated fadeInRight">
				<div className="tabs-ctn">
					{Object.keys(config).map((index) => {
						return (
							<div
								key={Math.random()}
								className={`pilltab ${activeTab == index ? 'active' : ''}`}
								onClick={() => {
									setActiveTab(Number(index));
								}}
							>
								{config[index].name}
							</div>
						);
					})}
				</div>
				<div
					className={`slider`}
					style={{
						marginLeft: `${Number(activeTab) * (100 / tabCount)}%`,
						width: `${100 / tabCount}%`
					}}
				></div>
			</div>
		);
	}
}
