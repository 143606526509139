import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import IdCardDetail from '@/pages/EXPRESS/idcards/id-card-details/id-detail/IdCardDetail';
import DocumentPreview from '@/pages/EXPRESS/documents/document-preview/DocumentPreview';

@observer
export default class MemberIdCards extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			previewUrl: null
		});
		autoBind(this);
	}

	getCardUrl(side, planId) {
		const baseUrl = ExpressAPI.getBaseUrl();
		const memberId = expressStore.activeMember._id;
		const route = `/members/${memberId}/idCard?planId=${planId}&side=${side}&token=${_.get(
			expressStore,
			'authToken'
		)}&reload=${new Date().getTime()}`;
		const orgUrl = ExpressAPI.getExpressOrgUrl(route);
		const url = `${baseUrl}${orgUrl}`;
		return url;
	}

	handlePreview(side, planId) {
		this.data.previewUrl = this.getCardUrl(side, planId);
	}

	handlePreviewClose() {
		this.data.previewUrl = null;
	}
	render() {
		const carouselPaddingToSeeFirstCard =
			this.props.internalPlanIds.length > 2
				? 'id-card-details-carousel--body-3'
				: this.props.internalPlanIds.length > 1
				? 'id-card-details-carousel--body-2'
				: '';

		const idCardsContainer =
			this.props.internalPlanIds.length > 1
				? 'id-cards-container multiple-cards'
				: 'id-cards-container';
		return (
			<div className="id-card-details card vbox hcenter animated sldeIn id-card-details-carousel">
				<div className="card-header">
					<div className="title grow-2">ID Cards</div>
				</div>
				<div
					className={`card-body cards hbox hcenter vcenter ${carouselPaddingToSeeFirstCard}`}
				>
					{this.props.internalPlanIds.map((planId) => (
						<div className={idCardsContainer}>
							<div className="front-container">
								<IdCardDetail
									side="front"
									thumbnailUrl={this.getCardUrl('front', planId)}
									id={this.props.frontId}
									loading={false}
									onClick={() => this.handlePreview('front', planId)}
									onPreview={() => this.handlePreview('front', planId)}
								/>
							</div>

							<div className="front-container">
								<IdCardDetail
									side="back"
									thumbnailUrl={this.getCardUrl('back', planId)}
									loading={false}
									onClick={() => this.handlePreview('back', planId)}
									onPreview={() => this.handlePreview('back', planId)}
								/>
							</div>
						</div>
					))}
				</div>
				<DocumentPreview
					opened={this.data.previewUrl}
					onClose={this.handlePreviewClose}
					fileUrl={this.data.previewUrl}
				/>
			</div>
		);
	}
}
