import React, { Component } from 'react';
import { Progressbar, Button, Actions, ActionsGroup, ActionsButton, List, ListInput, Preloader } from 'framework7-react';
import GlobalHeader from '../../components/global-header/GlobalHeader';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import CloudAPI from '../../services/CloudAPI';
import goodrx from '../../assets/goodrxmb.png';
import prescriptionSVG from '../../assets/prescription-empty-state.svg';
import AnalyticsService from '../../services/AnalyticsService';
import _ from 'lodash';
import './prescriptions.scss';
import MedicationPage from './MedicationPage';
import appStore from '../../stores/AppStore';
import ExternalService from '../../services/ExternalService';
import UserService from '../../services/UserService';
import ProviderService from '../../services/ProviderService';

const analytics = new AnalyticsService();

@observer
export default class Prescriptions extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			searchInput: '',
			searchCandidates: [],
			prescriptions: [],
			activePharmacies: [],
			openCoupon: null,
			selectedValues: null,
			searching: false,
			toggleValue: 'prescriptions',
			medicationOpen: false,
			activeMedication: null,
			navigationOpen: false,
			phonesOpen: false,
			loadingPrescriptions: true
		});
		this.text = getCmpText('Prescriptions', {
			titleText: 'Prescriptions',
			prescriptions: 'Prescriptions',
			pharmacies: 'Pharmacies',
			loadingPrescriptions: 'Loading Prescriptions...',
			emptyPrescriptions: 'Search for a drug name above, after you save a coupon it will be shown here.',
			coupon: 'Coupon',
			priceShop: 'Price Shop',
			savedCoupons: 'Saved Coupons',
			navigate: 'Navigate',
			call: 'Call',
			savedPrescriptionTitle: 'My Saved Prescriptions',
			cancel: 'Cancel',
			noPharmNear1: 'We did not find any',
			noPharmNear2: 'locations nearby',
			drugSearchPlaceholder: 'Search for a drug name...',
			remove: 'Remove'
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.loadPrescriptions();
	}

	loadPrescriptions() {
		this.data.loadingPrescriptions = true;
		CloudAPI.getUserPrescriptions()
			.then((res) => {
				this.data.prescriptions = res;
				this.data.loadingPrescriptions = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.loadingPrescriptions = false;
			});
	}

	loadPharmacies(name, showNav) {
		console.log('loading pharma: ', name);
		this.data.activePharmacies = [];
		this.$f7.dialog.preloader();
		CloudAPI.getNearbyPharmacyByName(name)
			.then((res) => {
				this.data.activePharmacies = res;
				this.$f7.dialog.close();
				const hasPharms = res && res.length && res.length > 0;
				if (!hasPharms) {
					this.$f7.dialog.alert(`${this.text.noPharmNear1} ${name} ${this.text.noPharmNear2}`);
					return;
				}
				if (showNav) {
					this.data.navigationOpen = true;
				} else {
					this.data.phonesOpen = true;
				}
			})
			.catch((err) => {
				this.$f7.dialog.close();
				console.log(err);
			});
	}

	buildPhoneList() {
		let { activePharmacies } = this.data;
		console.log({ activePharmacies });
		return activePharmacies.map((l) => {
			let distance = UserService.getDistanceFromLocation(l);
			return (
				<ActionsButton
					onClick={() => {
						analytics.track('called_pharmacy');
						ExternalService.handleExternalRequest('call', l.phone);
					}}
					key={l._id}
					className="pharmacy-address"
				>
					<div className="address-info hbox vcenter">
						<div className="distance">
							<i className="fad fa-map-marker-alt" />
							<div className="milage">{distance} mi</div>
						</div>
						<div className="address ellipse">{`${l.street1} ${l.city}, ${l.state} ${l.zip}`}</div>
					</div>
				</ActionsButton>
			);
		});
	}

	buildNavigationList() {
		let { activePharmacies } = this.data;
		return activePharmacies.map((l) => {
			let distance = UserService.getDistanceFromLocation(l);
			return (
				<ActionsButton
					onClick={() => {
						ProviderService.openNavigation({ location: l }, this.$f7, true);
					}}
					key={l._id}
					className="pharmacy-address"
				>
					<div className="address-info hbox vcenter">
						<div className="distance">
							<i className="fad fa-map-marker-alt" />
							<div className="milage">{distance} mi</div>
						</div>
						<div className="address ellipse">{`${l.street1} ${l.city}, ${l.state} ${l.zip}`}</div>
					</div>
				</ActionsButton>
			);
		});
	}

	doSearch() {
		this.data.searching = true;
		if (this.fireOffSearch) {
			clearTimeout(this.fireOffSearch);
		}
		this.fireOffSearch = setTimeout(() => {
			CloudAPI.prescriptionSearch(this.data.searchInput)
				.then((res) => {
					this.data.searching = false;
					clearTimeout(this.fireOffSearch);
					this.fireOffSearch = null;
					if (res && res.data.candidates && res.data.candidates.length > 0) {
						this.data.searchCandidates = res.data.candidates;
					} else {
						this.data.searching = false;
						this.data.searchCandidates = [];
					}
				})
				.catch((err) => {
					this.data.searching = false;
					clearTimeout(this.fireOffSearch);
					this.fireOffSearch = null;
					console.log(err);
				});
		}, 500);
	}

	onSearchChange(evt) {
		let target = evt.currentTarget;
		this.data.searchInput = _.get(evt, 'currentTarget.value', '');
		if (this.data.searchInput.length > 2) {
			this.doSearch();
		}
	}

	onSearchClear() {
		this.data.searchInput = '';
		this.data.searchCandidates = [];
	}

	buildSearchCandidates() {
		return _.cloneDeep(this.data.searchCandidates).map((c) => (
			<div
				className="search-candidate neumorphic neuhover hbox vcenter"
				key={c}
				onClick={() => {
					this.data.activeMedication = c;
					this.data.medicationOpen = true;
				}}
			>
				<i className="fad fa-pills"></i>
				<span className="ellipse">{c}</span>
			</div>
		));
	}

	toggleContent() {
		let { toggleValue } = this.data;
		if (toggleValue === 'prescriptions') {
			analytics.track('load_pharmacies_page');
			this.data.toggleValue = 'pharmacies';
		} else {
			analytics.track('load_prescriptions_page');
			this.data.toggleValue = 'prescriptions';
		}
	}

	onGoodRxClicked() {
		const rxUrl = ExternalService.getRxUrl();
		ExternalService.handleExternalRequest('web', rxUrl);
	}

	removePrescription(p) {
		this.$f7.dialog.confirm('Are You Sure?', 'Remove From List', () => {
			this.$f7.dialog.preloader();
			console.log(JSON.stringify(p, null, 2));
			CloudAPI.removePrescription(p._id)
				.then(() => {
					this.loadPrescriptions();
					this.$f7.dialog.close();
					analytics.track('remove_prescription');
				})
				.catch((err) => {
					console.log(err);
					this.$f7.dialog.close();
					analytics.track('remove_prescription_error');
				});
		});
	}

	getLoader() {
		return (
			<div className="loading-ctn vbox vcenter hcenter">
				<Preloader></Preloader>
				<div>{this.text.loadingPrescriptions}</div>
			</div>
		);
	}

	emptyPanel() {
		return (
			<div className="empty-panel vbox vcenter hcenter">
				<img src={prescriptionSVG} alt="prescriptions" />
				<div>{this.text.emptyPrescriptions}</div>
			</div>
		);
	}

	buildPrescriptionsList() {
		let { prescriptions, loadingPrescriptions } = this.data;
		if (loadingPrescriptions) {
			return this.getLoader();
		}
		if (prescriptions && prescriptions.length === 0) {
			return this.emptyPanel();
		}
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		});
		return prescriptions.map((p) => {
			return (
				<div className="prescription" key={Math.random()}>
					<div className="name">
						{p.name} @ {p.pharmacy}
					</div>
					<div className="hbox details">
						<div className="vbox vcenter row-2 grow-1">
							<div className="dosage">{p.dosage}</div>
							<div className="form ">{p.form}</div>
							<div className="quantity">{p.quantity}</div>
						</div>
						<div className="price">{formatter.format(p.price)}</div>
					</div>
					<div className="hbox vcenter actions">
						<div
							className="action vbox vcenter hcenter"
							onClick={() => {
								this.removePrescription(p);
							}}
						>
							<i className="fad fa-trash neumorphic neuhover"></i>
							<div>{this.text.remove}</div>
						</div>
						<div
							className="action vbox vcenter hcenter"
							onClick={() => {
								ExternalService.handleExternalRequest('web', p.coupon_url);
							}}
						>
							<i className="fad fa-external-link neumorphic neuhover"></i>
							<div>GoodRx</div>
						</div>

						<div
							className="action vbox vcenter hcenter"
							onClick={() => {
								this.data.openCoupon = p.coupon_key;
								this.data.activeMedication = p.name;
								this.data.selectedValues = {
									dosage: p.dosage,
									form: p.form,
									quantity: p.quantity
								};
								this.data.medicationOpen = true;
							}}
						>
							<i className="fad fa-cut neumorphic neuhover"></i>
							<div>{this.text.coupon}</div>
						</div>
						<div
							className="action vbox vcenter hcenter"
							onClick={() => {
								this.data.activeMedication = p.name;
								this.data.selectedValues = {
									dosage: p.dosage,
									form: p.form,
									quantity: p.quantity
								};
								this.data.medicationOpen = true;
							}}
						>
							<i className="fad fa-search-dollar neumorphic neuhover"></i>
							<div>{this.text.priceShop}</div>
						</div>
					</div>
				</div>
			);
		});
	}

	buildPharmacyList() {
		let { prescriptions, loadingPrescriptions } = this.data;
		if (loadingPrescriptions) {
			return this.getLoader();
		}
		if (prescriptions && prescriptions.length === 0) {
			return this.emptyPanel();
		}
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		});
		let pharmacyGroup = _.groupBy(prescriptions, 'pharmacy');
		const pharmacyCards = [];
		_.forEach(Object.keys(pharmacyGroup), (pName) => {
			let prescriptions = pharmacyGroup[pName];
			pharmacyCards.push(
				<div className="pharmacy" key={Math.random()}>
					<div className="name">{pName}</div>
					<div className="label">{this.text.savedCoupons}</div>
					{prescriptions.map((p) => {
						return (
							<div className="coupon-ctn hbox vcenter" key={Math.random()}>
								<div className="details grow-1">
									<div className="price">
										{p.name} - {formatter.format(p.price)}
									</div>
									<div className="dosage">{p.dosage}</div>
									<div className="form ">{p.form}</div>
									<div className="quantity">{p.quantity}</div>
								</div>
								<div
									className="action vbox vcenter hcenter"
									onClick={() => {
										this.data.openCoupon = p.coupon_key;
										this.data.activeMedication = p.name;
										this.data.selectedValues = {
											dosage: p.dosage,
											form: p.form,
											quantity: p.quantity
										};
										this.data.medicationOpen = true;
									}}
								>
									<i className="fad fa-cut neumorphic neuhover"></i>
								</div>
							</div>
						);
					})}
					<div className="btn-ctn hbox vcenter">
						<div
							className="action vbox vcenter hcenter"
							onClick={() => {
								this.loadPharmacies(pName, true);
							}}
						>
							<i className="fad fa-directions neumorphic neuhover"></i>
							<div>{this.text.navigate}</div>
						</div>
						<div
							className="action vbox vcenter hcenter"
							onClick={() => {
								this.loadPharmacies(pName);
							}}
						>
							<i className="fad fa-phone neumorphic neuhover"></i>
							<div>{this.text.call}</div>
						</div>
					</div>
				</div>
			);
		});
		return pharmacyCards;
	}

	getContent() {
		switch (this.data.toggleValue) {
			case 'prescriptions': {
				return <div className="prescriptions-list animated fadeInUp">{this.buildPrescriptionsList()}</div>;
			}
			case 'pharmacies': {
				return <div className="pharmacy-list animated fadeInUp">{this.buildPharmacyList()}</div>;
			}
			default: {
				return <div>{this.text.savedPrescriptionTitle}</div>;
			}
		}
	}

	render() {
		let { searching, toggleValue, searchCandidates } = this.data;
		let { prescriptions, pharmacies } = this.text;
		return (
			<div className="prescriptions-page">
				<GlobalHeader />
				<div className={`prescriptions-body y-scroll animated fadeInUp ${appStore.isMobile ? '' : 'desktop'}`}>
					<List className={`search-form hbox vcenter ${appStore.isMobile ? '' : 'desktop'}`} noHairlines>
						<i className="fad fa-search" />
						<ListInput
							className={appStore.isMobile ? 'mobile' : ''}
							type="text"
							placeholder={this.text.drugSearchPlaceholder}
							name="searchInput"
							value={this.data.searchInput}
							onChange={this.onSearchChange}
							onInputClear={this.onSearchClear}
							clearButton
						/>
					</List>
					{searching && <Progressbar infinite color="multi" className="animated fadeIn searching-bar"></Progressbar>}
					<div className="search-candidates ">
						{this.buildSearchCandidates()}
						{searchCandidates.length > 0 && (
							<div className="img-ctn vbox hcenter" onClick={this.onGoodRxClicked}>
								<img src={goodrx} alt="goodrx" />
							</div>
						)}
					</div>
					{searchCandidates.length === 0 && (
						<div className="content-header hbox vcenter animated">
							<Button
								active={toggleValue === 'prescriptions'}
								onClick={this.toggleContent}
								className={`neumorphic ${toggleValue === 'prescriptions' ? 'active' : ''} m-r-16`}
							>
								<i className="fad fa-pills"></i>
								{prescriptions}
							</Button>
							<Button
								active={toggleValue === 'pharmacies'}
								onClick={this.toggleContent}
								className={`neumorphic ${toggleValue === 'pharmacies' ? 'active' : ''}`}
							>
								<i className="fad fa-clinic-medical"></i>
								{pharmacies}
							</Button>
						</div>
					)}
					{searchCandidates.length === 0 && <div className="active-content">{this.getContent()}</div>}
				</div>
				<MedicationPage
					medication={this.data.activeMedication}
					selectedValues={_.cloneDeep(this.data.selectedValues)}
					openCoupon={this.data.openCoupon}
					open={this.data.medicationOpen}
					onClose={(reloadList) => {
						setTimeout(() => {
							this.data.medicationOpen = false;
							this.data.selectedValues = null;
							this.data.openCoupon = null;
							if (reloadList) {
								this.onSearchClear();
								this.loadPrescriptions();
							}
						}, 100);
					}}
				></MedicationPage>
				<Actions
					id="pharmacy-navigation-actions"
					className="pharmacy-navigation-actions"
					opened={this.data.navigationOpen}
					onActionsClosed={() => {
						this.data.navigationOpen = false;
					}}
				>
					<ActionsGroup>{this.data.navigationOpen && this.buildNavigationList()}</ActionsGroup>
					<ActionsGroup>
						<ActionsButton color="red">{this.text.cancel}</ActionsButton>
					</ActionsGroup>
				</Actions>
				<Actions
					id="pharmacy-navigation-actions"
					className="pharmacy-navigation-actions"
					opened={this.data.phonesOpen}
					onActionsClosed={() => {
						this.data.phonesOpen = false;
					}}
				>
					<ActionsGroup>{this.data.phonesOpen && this.buildPhoneList()}</ActionsGroup>
					<ActionsGroup>
						<ActionsButton color="red">{this.text.cancel}</ActionsButton>
					</ActionsGroup>
				</Actions>
			</div>
		);
	}
}
