import React, { PureComponent } from 'react';
import './banner-card.scss';

export default class BannerCard extends PureComponent {
	render() {
		let { onClick, iconCls, title, topText, bottomText, content, color, lite, mright } = this.props;
		return (
			<div
				className={`banner-card ${color ? color : 'blue'} ${lite ? 'lite' : ''} hbox vcenter ${mright ? 'm-r-16' : ''}`}
				onClick={onClick}
			>
				{iconCls ? (
					<div className="icon-box">
						<i className={iconCls} />
					</div>
				) : (
					''
				)}
				{content ? (
					content
				) : (
					<div className="text vbox vcenter hcenter">
						<div className="subtitle">{topText}</div>
						<div className="title">{title}</div>
						<div className="subtitle">{bottomText}</div>
					</div>
				)}
			</div>
		);
	}
}
