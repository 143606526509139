import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import ExpressAPI from '@/services/ExpressAPI';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import './org-admin.scss';

@observer
export default class OrgAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			formData: {},
			orgs: [],
			openItem: null,
			originalOrg: {}
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.data.originalOrg = _.cloneDeep(expressStore.currentOrg);
		const orgs = await this.getOrgs();
		this.data.orgs = orgs;
	}

	async componentWillUnmount() {
		expressStore.currentOrg = _.cloneDeep(this.data.originalOrg);
	}

	async getOrgs() {
		this.$f7.dialog.preloader();
		try {
			const orgs = await ExpressAPI.getOrgs();
			this.$f7.dialog.close();
			return orgs;
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error loading organizations');
		}
	}

	async updateOrgInit(evt) {
		const orgId = evt.currentTarget.getAttribute('data-id');
		const orgData = _.cloneDeep(this.data.orgs.find((x) => x._id === orgId));
		expressStore.currentOrg = _.cloneDeep(orgData);
		const formConfig = await this.getOrgFormConfig(orgData);
		const formData = {
			name: orgData.name,
			type: orgData.type,
			owner: orgData.owner,
			reportExports: orgData.reportExports,
			autoApproveImports: orgData.autoApproveImports,
			idCardFulfillment: orgData.idCardFulfillment,
			costAndQualityEnabled: orgData?.costAndQualityEnabled,
			procedureSearchEnabled: orgData?.procedureSearchEnabled || false,
			overridePlanRequirementForIdCardAttachment:
				orgData.overridePlanRequirementForIdCardAttachment || false
		};
		orgData.formConfig = _.cloneDeep(formConfig);
		this.data.formData = formData;
		this.data.openItem = orgData;
	}

	closeFormPopup() {
		this.data.formData = {};
		this.data.openItem = null;
	}

	async createOrgInit() {
		const orgData = { _id: 'new' };
		const formConfig = await this.getOrgFormConfig();
		const formData = {
			name: '',
			type: '',
			owner: _.cloneDeep(_.get(expressStore, 'userData.user._id'))
		};
		orgData.formConfig = _.cloneDeep(formConfig);
		this.data.formData = formData;
		this.data.openItem = orgData;
	}

	async deleteOrgInit(evt) {
		const orgId = evt.currentTarget.getAttribute('data-id');
		const orgData = _.cloneDeep(this.data.orgs.find((x) => x._id === orgId));
		const confirmationText = `Are you sure you want to delete ${orgData.name}?`;
		this.$f7.dialog.confirm(confirmationText, () => {
			this.deleteOrg(orgId);
		});
	}

	async deleteOrg(org) {
		this.$f7.dialog.preloader();
		try {
			await ExpressAPI.deleteOrg(org);
			this.$f7.dialog.close();
			const orgs = await this.getOrgs();
			this.data.orgs = orgs;
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error Deleting Org');
		}
	}

	async submitOrgForm() {
		this.$f7.dialog.preloader();
		try {
			const isNew = this.data.openItem._id === 'new';
			if (isNew) {
				await ExpressAPI.createOrg(this.data.formData);
			} else {
				await ExpressAPI.updateOrg(this.data.formData);
			}
			this.$f7.dialog.close();
			const orgs = await this.getOrgs();
			this.data.orgs = orgs;
			this.closeFormPopup();
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error Saving Org Information');
		}
	}

	async getOwnerDropdown(orgData) {
		if (!orgData) {
			const emptyUserList = {};
			const currentUser = _.cloneDeep(_.get(expressStore, 'userData.user'));
			emptyUserList[currentUser._id] = _.startCase(
				`${currentUser.firstName} ${currentUser.lastName}`
			);
			return {
				type: 'dropdown',
				icon: 'fal fa-user',
				listItems: emptyUserList,
				label: 'Owner'
			};
		}
		try {
			this.$f7.dialog.preloader();
			const orgUserData = await ExpressAPI.getAllUsers();
			const users = _.get(orgUserData, 'users') || {};
			const userList = {};
			Object.keys(users).map((user) => {
				const userData = users[user];
				userList[user] = `${_.get(userData, 'user.firstName') || ''} ${
					_.get(userData, 'user.lastName') || ''
				}`;
			});
			this.$f7.dialog.close();
			return {
				type: 'dropdown',
				icon: 'fal fa-user',
				listItems: userList,
				label: 'Owner',
				displayValues: (key, data) => {
					if (key === orgData.owner) {
						return (
							<>
								<span className="tag">Current</span>
								<span>{_.startCase(data[key])}</span>
							</>
						);
					}
					return _.startCase(data[key]);
				}
			};
		} catch (err) {
			this.$f7.dialog.close();
			console.log('there was an error loading the current user info');
		}
	}

	async getOrgFormConfig(orgData) {
		return {
			name: {
				type: 'text',
				label: 'Name'
			},
			type: {
				label: 'Type',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'fal fa-building',
				listItems: {
					tpa: 'Third Party Administrator',
					broker: 'Insurance Broker',
					employer: 'Employer',
					other: 'Other'
				}
			},
			owner: await this.getOwnerDropdown(orgData),
			reportExports: {
				type: 'toggle',
				label: 'Allow Report Exports'
			},
			idCardFulfillment: {
				type: 'toggle',
				label: 'ID Card Printing'
			},
			costAndQualityEnabled: {
				type: 'toggle',
				label: 'Enable Provider Cost & Quality Scores'
			},
			procedureSearchEnabled: {
				type: 'toggle',
				label: 'Enable Procedure Search'
			},
			overridePlanRequirementForIdCardAttachment: {
				type: 'toggle',
				label: 'Remove Requirement for ID Card Attachment to Plans'
			}
		};
	}

	render() {
		const isSystemAdmin = _.cloneDeep(expressStore.isSystemAdmin());
		const allOrgs = _.cloneDeep(this.data.orgs) || [];
		const orgs = allOrgs.filter((x) => !x.status || x.status !== 'inactive');
		return (
			<div className="admin-org-container">
				<div className="admin-org-header">
					<h2 className="grow-1">Active Organizations</h2>
					<div
						className="list-btn ios button button-small button-fill"
						onClick={this.createOrgInit}
					>
						<i className="fad fa-plus-square" /> Create New Org
					</div>
				</div>
				<div className="cards-container">
					{orgs.map((org) => {
						const showTag = org.role && org.role.name !== 'System Admin';
						return (
							<div className="card" key={`org-card-${org._id}`}>
								<div className="header">
									{showTag && <div className="role">{org.role.name}</div>}
									{!showTag && <div />}
									{isSystemAdmin && (
										<div className="edit-block">
											<i
												className="fad fa-trash"
												onClick={this.deleteOrgInit}
												data-id={org._id}
											/>
											<i
												className="fad fa-edit"
												onClick={this.updateOrgInit}
												data-id={org._id}
											/>
										</div>
									)}
								</div>
								<div className="body" data-id={org._id}>
									<div className="icon-container">
										<i className={org.role.icon} />
									</div>
									<div className="title">{org.name}</div>
									<div className="subtitle">
										Primary Contact: {org.primaryContact.name}
									</div>
									<div>
										{' '}
										Created Date: {org.formattedCreatedDate || 'Unknown'}
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<FormPopup
					opened={!!this.data.openItem}
					title={`Edit Organization`}
					buttonText="Save"
					formData={this.data.formData || {}}
					formConfig={!!this.data.openItem ? this.data.openItem.formConfig : {}}
					onSubmit={this.submitOrgForm}
					onClose={this.closeFormPopup}
				/>
			</div>
		);
	}
}
