import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { observer, observable } from '../../../utils/State';
import appStore from '../../../stores/AppStore';
import GradiantStack from '../../../components/gradiant-stack/GradiantStack';
import ChoiceSVG from '../../../assets/choice.svg';

@observer
export default class SignupTypeSelection extends Component {
	constructor() {
		super();
		this.data = observable({});
		this.text = getCmpText('SignupTypeSelection', {
			title: 'Please Choose',
			stepText: '1 of 4',
			byMemberInfo: 'I have my member and group numbers',
			bySsn: 'My employer directed me to use my SSN number',
			consumer: "CareValet isn't included with my health insurance"
		});
		autoBind(this);
	}

	onBack() {
		if (this.props.onBack) {
			this.props.onBack('employer-choice');
		}
	}

	onHasIdClick() {
		if (this.props.onNext) {
			this.props.onNext('employer-lookup-member');
		}
	}

	onSSNClick() {
		if (this.props.onNext) {
			this.props.onNext('employer-lookup-ssn');
		}
	}

	consumerSignup() {
		if (this.props.onNext) {
			this.props.onNext('consumer-signup');
		}
	}

	render() {
		return (
			<div>
				<GradiantStack stack="2" animate={false} id="signup-type-selection" />
				<div className={`employer-choice-page`}>
					<div className="header-ctn hbox vcenter">
						<div className="back hbox vcenter animated fadeInLeft" onClick={this.onBack}>
							<i className="fal fa-chevron-left"></i>Back
						</div>
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={ChoiceSVG} className="herosvg" />
						<h1>{this.text.title}</h1>
						<div className="step-text">{this.text.stepText}</div>
						<div className="option hbox vcenter neucard" onClick={this.onHasIdClick}>
							<i className="fad fa-address-card"></i>
							<div className="ctnt">
								<div className="label">{this.text.byMemberInfo}</div>
							</div>
							<i className="fal fa-chevron-right"></i>
						</div>
						<div className="option hbox vcenter neucard" onClick={this.onSSNClick}>
							<i className="fad fa-key"></i>
							<div className="ctnt">
								<div className="label">{this.text.bySsn}</div>
							</div>
							<i className="fal fa-chevron-right"></i>
						</div>
						{!appStore.isApp() && (
							<div className="option hbox vcenter neucard" onClick={this.consumerSignup}>
								<i className="icon-logo" />
								<div className="ctnt">
									<div className="label">{this.text.consumer}</div>
								</div>
								<i className="fal fa-chevron-right"></i>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
