import React, { Component } from 'react';
import './segmented-button.scss';

export default class SegmentedButton extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div className="segmented-button">{this.props.children}</div>;
	}
}
