let mapStyles = {
	blue: [
		{
			featureType: 'all',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				},
				{
					hue: '#0079ff'
				},
				{
					saturation: '70'
				},
				{
					lightness: '0'
				}
			]
		},
		{
			featureType: 'all',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'administrative',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'administrative.country',
			elementType: 'geometry.fill',
			stylers: [
				{
					saturation: '2'
				}
			]
		},
		{
			featureType: 'administrative.country',
			elementType: 'labels',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'administrative.locality',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'landscape.natural',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'landscape.natural.landcover',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'simplified'
				},
				{
					saturation: '100'
				},
				{
					lightness: '100'
				},
				{
					gamma: '6.53'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.attraction',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.attraction',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.business',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.government',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.government',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'poi.government',
			elementType: 'labels.text.fill',
			stylers: [
				{
					visibility: 'off'
				},
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.government',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.medical',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.place_of_worship',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#050404',
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.school',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.school',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.school',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.school',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'labels',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#000000'
				}
			]
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'transit.station',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'transit.station.airport',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'transit.station.bus',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on'
				},
				{
					hue: '#00c3ff'
				},
				{
					saturation: '0'
				},
				{
					lightness: '0'
				}
			]
		}
	],
	green: [
		{
			featureType: 'landscape.natural',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on'
				},
				{
					color: '#a5ecc8'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on'
				},
				{
					color: '#a5ecc8'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{
					lightness: 100
				},
				{
					visibility: 'simplified'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'labels',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [
				{
					visibility: 'on'
				},
				{
					lightness: 700
				}
			]
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [
				{
					color: '#66cc99'
				}
			]
		}
	],
	uber: [
		{
			featureType: 'administrative',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#d6e2e6'
				}
			]
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#cfd4d5'
				}
			]
		},
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#7492a8'
				}
			]
		},
		{
			featureType: 'administrative.neighborhood',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#0079ff'
				}
			]
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#dde2e3'
				}
			]
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#cfd4d5'
				}
			]
		},
		{
			featureType: 'landscape.natural',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#dde2e3'
				}
			]
		},
		{
			featureType: 'landscape.natural',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#7492a8'
				}
			]
		},
		{
			featureType: 'landscape.natural.terrain',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#dde2e3',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#588ca4',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.icon',
			stylers: [
				{
					saturation: -100,
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#a9de83',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#bae6a1'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#c6e8b3',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#bae6a1',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#41626b'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'labels.icon',
			stylers: [
				{
					saturation: -45
				},
				{
					lightness: 10
				},
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#c1d1d6'
				}
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#a6b5bb'
				}
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'on'
				}
			]
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#9fb6bd'
				}
			]
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#ffffff'
				}
			]
		},
		{
			featureType: 'road.local',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#ffffff'
				}
			]
		},
		{
			featureType: 'transit',
			elementType: 'labels.icon',
			stylers: [
				{
					saturation: -70,
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#b4cbd4',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#588ca4',
					visibility: 'off'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'transit.station',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'transit.station',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#008cb5'
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'transit.station.airport',
			elementType: 'geometry.fill',
			stylers: [
				{
					saturation: -100
				},
				{
					lightness: -5
				},
				{
					visibility: 'off'
				}
			]
		},
		{
			featureType: 'water',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#a6cbe3'
				}
			]
		}
	]
};

export { mapStyles };
