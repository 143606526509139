import React, { Component } from 'react';
import { autoBind } from '@/utils/GeneralUtils';
import { Rect } from 'react-konva';
import { observer, observable } from '@/utils/State';
import CanvasFactory from '@/factories/CanvasFactory';

@observer
export default class RectangleNode extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ hovered: false });
		autoBind(this);
	}

	render() {
		const { canDrag, onTransformEnd, onNodeTransform, onDragEnd, onHighlight } = CanvasFactory.getActiveService();
		return (
			<>
				<Rect
					{...this.props}
					draggable={canDrag()}
					onTransformEnd={onTransformEnd}
					onDragEnd={onDragEnd}
					onTransform={onNodeTransform}
					onDrag={onHighlight}
					onDragMove={onHighlight}
				></Rect>
			</>
		);
	}
}
