import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Link, Popup } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import './new-card-template.scss';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import TemplatePicker from './template-picker/TemplatePicker';
import _ from 'lodash';
@observer
export default class NewCardTemplate extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			templateQuery: {
				type: 'id-card'
			},
			loading: false,
			templates: [],
			selectedTemplate: null,
			newCardData: {
				name: '',
				side: 'front',
				nodes: null,
				background: null,
				width: 0,
				height: 0
			},
			formValid: false
		});
		autoBind(this);
	}

	handleOpen() {
		this.data.loading = true;
		ExpressAPI.getTemplates(this.data.templateQuery)
			.then((res) => {
				this.data.templates = res;
				this.data.loading = false;
			})
			.catch((e) => {
				this.data.loading = false;
				showExpressError(this.$f7, e);
			});
	}

	handleClose() {
		const { onClose } = this.props;
		if (onClose) {
			onClose();
		}
	}

	getFormConfig() {
		return {
			row1: {
				name: {
					label: 'ID Card Name',
					type: 'text',
					placeholder: 'Enter name for card',
					validator: {
						type: 'length',
						value: 4
					}
				},
				side: {
					label: 'Side',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-arrows-alt-h',
					listItems: { front: 'front', back: 'back' }
				}
			}
		};
	}

	handleSelect(selectedDocument) {
		selectedDocument = _.cloneDeep(selectedDocument);
		this.data.newCardData.nodes = selectedDocument.nodes;
		this.data.newCardData.background = selectedDocument.background;
		this.data.newCardData.width = selectedDocument.width;
		this.data.newCardData.height = selectedDocument.height;
		if(selectedDocument.existingAsTemplate) {
			this.data.newCardData._id = selectedDocument._id;
			this.data.newCardData.existingAsTemplate = true;
			this.data.newCardData.customFields = selectedDocument.customFields;
		}
		this.data.selectedTemplate = selectedDocument._id;
		this.handleValidate();
	}

	handleValidate() {
		const formData = this.data.newCardData;
		if (formData.name === null || formData.name.length < 3) {
			this.data.formValid = false;
			return;
		}
		if (this.data.selectedTemplate === null) {
			this.data.formValid = false;
			return;
		}

		if (formData.side === null) {
			this.data.formValid = false;
			return;
		}

		this.data.formValid = true;
	}

	handleSubmit() {
		const { onSubmit } = this.props;
		if (onSubmit) {
			onSubmit(this.data.newCardData);
		}
	}

	render() {
		const { opened } = this.props;
		const { selectedTemplate, templates, loading, formValid } = this.data;
		return (
			<Popup
				opened={opened}
				onPopupOpen={this.handleOpen}
				onPopupClosed={this.handleClose}
				tabletFullscreen={true}
				className="new-card-from-template"
			>
				<div className="container v-100 y-scroll">
					<div className="popup-header hbox w-100 vcenter">
						<div className="vbox grow-1">
							<h1 className="title">New ID card</h1>
						</div>

						<Link popupClose>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
					<div className="body">
						<p className="description">To get started, fill out the basic information below and select a template.</p>
						<div className="form">
							<ExpressFormBuilder
								afterChange={this.handleValidate}
								formData={this.data.newCardData}
								formConfig={this.getFormConfig()}
							></ExpressFormBuilder>
						</div>
						<div className="templates">
							<h2>Select a template</h2>
							<TemplatePicker onSelect={this.handleSelect} selected={selectedTemplate} templates={templates} loading={loading} />
						</div>
					</div>
				</div>
				<div className="footer vbox vcenter w-100 hright">
					<a onClick={this.handleSubmit} className={`btn round-btn  ${formValid ? 'valid animated pulse infinite' : 'invalid'}`}>
						Continue
						<i className="fa fa-arrow-right"></i>
					</a>
				</div>
			</Popup>
		);
	}
}
