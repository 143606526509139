import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import CanvasFactory from '@/factories/CanvasFactory';
import BasicInput from '@/components/basic-input/BasicInput';
import { Range } from 'framework7-react';
@observer
export default class CornerRadius extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			mode: 'single'
		});
		autoBind(this);
	}

	handleRadiusChange(value) {
		const { setNodeProperty } = CanvasFactory.getActiveService();
		if (this.data.mode === 'single') {
			setNodeProperty('cornerRadius', [value, value, value, value]);
		} else {
			//TODO handle individual eventually
		}
	}

	renderInput() {
		const { nodeData, selectedNodes } = CanvasFactory.getActiveStore();
		const selectedNode = nodeData[selectedNodes[0]];
		if (this.data.mode === 'single') {
			return (
				<div className="property-edit">
					<div className="hbox vcenter range-selector">
						<div className="icon">
							<i className="fa fa-square"></i>
						</div>
						<Range min={1} max={200} step={1} value={selectedNode.cornerRadius[0]} className="grow-2" onRangeChange={(value) => this.handleRadiusChange(value)} />
						<div className="range-value">
							<BasicInput
								key={`express-form-input`}
								onChange={(e) => this.handleRadiusChange(e.target.value)}
								validate
								name="range-value"
								step="1"
								type="number"
								value={selectedNode.cornerRadius[0]}
								className="range-value"
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<></>
			);
		}
	}

	render() {
		return (
			<div className="vbox property-section">
				<div className="property-label">Corner Radius</div>
				<div className="property-edit">{this.renderInput()}</div>
			</div>
		);
	}
}
