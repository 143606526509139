import React, { Component, useRef } from 'react';
import { observer, observable } from '@/utils/State';
import { getCmpText } from '@/utils/Localization';
import { autoBind, getStripeKey } from '@/utils/GeneralUtils';
import { Elements } from '@stripe/react-stripe-js';
import { StripeCheckoutPage } from '../checkout-page/CheckoutPage';
import CartService from '@/services/CartService';
import packageSVG from '@/assets/package.svg';
import { Button, Card, CardContent, CardHeader, Link, List, ListItem } from 'framework7-react';
import './shop.scss';
import appStore from '@/stores/AppStore';
import windowStore from '@/stores/WindowStore';
import _ from 'lodash';

@observer
export default class Shop extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ checkoutOpen: false, checkoutItem: null, featureOpened: null });
		this.text = getCmpText('Shop', {
			token: 'token',
			carePackageName: 'Care Package',
			carePackageMessage: 'Save $24 when purchasing a pack of 3 care credits',
			careToken: '1 Care Credit',
			careTokenDescription: '1 Credit is good for 1 completed Telehealth visit',
			saveText: 'Save'
		});
		console.log('loading stripe');
		autoBind(this);
	}

	openCheckout(item) {
		console.log(item)
		CartService.addItemToCart(item);
		this.data.checkoutItem = item;
		this.data.checkoutOpen = true;
	}

	onPaymentComplete() {
		this.data.checkoutOpen = false;
		this.props.onPaymentComplete();
	}

	buildShopPackages() {
		//! Want to store these in the db or just hard code them in link to stripe somehow?
		let packages = [
			{
				name: this.text.carePackageName,
				savings: '24',
				price: '215.97',
				pricePerToken: '71.99',
				qty: 3,
				message: this.text.carePackageMessage
			}
		];
		let items = [];
		_.forEach(packages, (shopPackage) => {
			let { name, savings, price, pricePerToken, message } = shopPackage;
			items.push(
				<div
					className="package"
					key={Math.random()}
					onClick={() => {
						this.openCheckout(shopPackage);
					}}
				>
					<div className="savings-chip">{`${this.text.saveText} $ ${savings}`}</div>
					<div className="package-name">{name}</div>
					<div className="pricing hbox vbottom hleft">
						<div className="price hbox vcenter">
							<div className="currency">$</div>
							<div className="number">{price}</div>
						</div>
						<div className="token-price hbox vcenter">
							<div className="price">{pricePerToken}</div>
							<div className="divider">/</div>
							<div className="token">{this.text.token}</div>
						</div>
					</div>
					<div className="message-ctn">{message}</div>
					<div className="svg-ctn">
						<img src={packageSVG} />
					</div>
				</div>
			);
		});
		return <div className="packages vbox hcenter">{items}</div>;
	}

	buildShopItems() {
		let shopItems = [
			{
				price: '79.99',
				name: this.text.careToken,
				qty: 1,
				description: this.text.careTokenDescription
			}
		];
		let items = [];
		_.forEach(shopItems, (item) => {
			let { price, name, description } = item;
			items.push(
				<div
					className="shop-item neumorphic neuhover hbox vcenter"
					key="shop-item-1"
					onClick={() => {
						this.openCheckout(item);
					}}
				>
					<div className="details">
						<div className="item-name">{name}</div>
						<div className="desc">{description}</div>
					</div>
					<div className="price hbox vcenter">
						<div className="currency">$</div>
						<div>{price}</div>
					</div>
				</div>
			);
		});
		return <div className="shop-items vbox hcenter">{items}</div>;
	}

	renderFeatureCard() {
		const { products } = this.props;
		const featured = products && products.filter((p) => p.featured === 'true');
		if (featured) {
			return featured.map((card) => {
				return (
					<FeaturedCard
						onOpen={() => (this.data.featureOpened = card.id)}
						onClose={() => (this.data.featureOpened = null)}
						opened={this.data.featureOpened === card.id}
						name={card.name}
						price={card.price}
						unit={card.isRecurring ? card.recurringData.interval : ''}
						isRecurring={card.isRecurring}
						description={card.description}
						onCheckout={() => this.openCheckout(card)}
						imageUrl={card.images[0]}
						cardFeature={card.cardFeature}
						key={card.id}
					/>
				);
			});
		} else {
			return ''
		}
	}

	render() {
		return (
			<div className="h-100">
				<div className="shop-content y-scroll">
					<div style={{width: '100%'}} className={`vbox ${!this.data.featureOpened ? 'hcenter' : ''}`}>{this.renderFeatureCard()}</div>
					{this.buildShopPackages()}
					{this.buildShopItems()}
				</div>
				<Elements stripe={appStore.stripePromise}>
					<StripeCheckoutPage
						opened={this.data.checkoutOpen}
						data={{ items: [this.data.checkoutItem] }}
						onComplete={this.onPaymentComplete}
						onCloseCheckout={() => {
							this.data.checkoutOpen = false;
						}}
					></StripeCheckoutPage>
				</Elements>
			</div>
		);
	}
}

const FeaturedCard = observer((props) => {
	const { name, price, unit, cardFeature, description, imageUrl, opened, isRecurring, onOpen, onClose, onCheckout, key } = props;
	const featuredCard = useRef(null);

	let styleCss = {};
	let width = 0;

	if (featuredCard.current?.getBoundingClientRect() && opened) {
		const top = featuredCard.current?.offsetTop;
		const left = featuredCard.current?.offsetLeft;
		if (appStore.isMobile) {
			const transformY = `-${top}px`;
			const transformX = `-${left}px`;
			styleCss = { transform: `translate(${transformX}, ${transformY})`, height: windowStore.innerHeight };
		} else {
			styleCss = { left: 0, top: 0, height: '630px' };
		}
	}

	return (
		<div
			className={`featured-card ${opened ? 'opened' : ''}`}
			key={key}
			style={styleCss}
			onClick={() => {
				onOpen();
			}}
			ref={featuredCard}
		>
			<div className="header">
				<div className="hbox">
					<div className="package-name grow-1">{name}</div>
					{opened && (
						<Link onClick={onClose}>
							<i className="fad fa-times-square close-btn" />
						</Link>
					)}
				</div>

				<div className="pricing hbox vbottom hleft">
					<div className="price hbox vcenter">
						<div className="currency">$</div>
						<div className="number">
							{price} / {unit}
						</div>
					</div>					
				</div>
				<div className="feature">{cardFeature || ''}</div>
				<div className="vbox hcenter">
					<div className="svg-ctn">
						<img src={imageUrl} />
					</div>
				</div>
			</div>
			{/* <div className="savings-chip">{`${this.text.saveText} $ ${savings}`}</div> */}

			<div className="card-content">
				<div className="card-body vbox">
					<div className="message-ctn">{description}</div>
					<Button fill large className="round-btn purple" id="purchase-button" onClick={onCheckout}>
						{isRecurring ? 'Subscribe' : 'Purchase'}
					</Button>
				</div>
			</div>
		</div>
	);
});
