import React, { Component } from 'react';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import screenerSVG from '@/assets/screener.svg';
import { Button } from 'framework7-react';
import { observer } from '@/utils/State';
import './screening-intro.scss';
@observer
export class ScreeningIntro extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('ScreeningIntro', {
			screeningIntroHeadline: 'COVID-19 Screening Tool'
		});
		autoBind(this);
	}

	selectChoice(choice) {
		const { onSelect, onNext } = this.props;
		onSelect(choice);
		onNext();
	}
	renderChoices() {
		const { choices } = this.props;
		let choicesjsx = [];
		choices.forEach((choice, key) => {
			choicesjsx.push(
				<Button fill large className="round-btn purple" key={key} onClick={() => this.selectChoice(choice)}>
					<div className="text">{choice.text}</div>
				</Button>
			);
		});

		return choicesjsx;
	}
	render() {
		const { screeningIntroHeadline } = this.text;
		const { showIntro } = this.props;
		if (!showIntro) {
			return '';
		}
		return (
			<div className="screening-intro-page">
				<div className="header vbox hcenter">
					<img className="svg" src={screenerSVG} />
					<h1>{screeningIntroHeadline}</h1>
				</div>
				<div className="intro-body">
					<ul className="info">
						<li>
							<div className="bullet">
								<i className="fa fa-list"></i>
							</div>

							<div className="text">You’ll answer a few questions about symptoms and contact with others.</div>
						</li>
						<li>
							<div className="bullet">
								<i className="fa fa-user-shield"></i>
							</div>
							<div className="text">Your answers will not be shared with Apple or the CDC without your permission. </div>
						</li>
						<li>
							<div className="bullet">
								<i className="fa fa-exclamation-circle"></i>
							</div>
							<div className="text">
								By using this tool, you agree to its terms and that CareValet will not be liable for any harm relating to your use.
								<div className="details">
									Recommendations provided by this tool do not constitute medical advice and should not be used to diagnose or treat
									medical conditions.
								</div>
							</div>
						</li>
					</ul>
					<div className="choices">{this.renderChoices()}</div>
				</div>
			</div>
		);
	}
}
