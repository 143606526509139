import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind, getStripeKey } from '@/utils/GeneralUtils';
import { Popup, Link, Button, Preloader, Tabs, Tab, Page } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import appStore from '@/stores/AppStore';
import './billing-popup.scss';
import { StripeAddCreditCardForm } from './add-credit-card-form/AddCreditCardForm';
import { Elements } from '@stripe/react-stripe-js';
import UserBillingService from '@/services/UserBillingService';
import { NoPaymentMethods } from './no-payment-methods/NoPaymentMethods';

import BillingHome from './billing-home/BillingHome';
import TransactionPage from './transaction-page/TransactionPage';
import userStore from '@/stores/UserStore';

@observer
export default class BillingPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentView: 'loading',
			loadingTransactionHistory: false,
			loadingPaymentMethods: false,
			paymentMethods: [],
			prevView: '',
			transactionHistory: [],
			selectedTransaction: null
		});
		this.text = getCmpText('BillingPopup', {});

		autoBind(this);
	}

	closePrompt() {
		this.data.transactionHistory = [];
		//Billing before close logic here
		if (this.props.onCloseBilling) {
			this.props.onCloseBilling();
		}
	}

	//TODO lets just create a big switchView method that handels all the back / forward logic
	// and setting the view

	showAddCard(e) {
		this.$f7.tab.show('#add-credit-card', true);
		this.data.prevView = _.clone(this.data.currentView);
		this.data.currentView = 'addCard';
	}

	goToRoute(routeId) {
		if (!this.data.prevView) {
			this.data.prevView = routeId;
		}
		this.$f7.tab.show(routeId, true);
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async tabShow(e) {
		this.data.currentView = '#' + e.getAttribute('id');
	}

	async tabHide(e) {
		this.data.prevView = '#' + e.getAttribute('id');
	}

	onBack() {
		this.goToRoute(this.data.prevView);
	}

	async onCardAdded() {
		await this.fetchData();
	}

	async fetchPaymentMethods() {
		try {
			this.data.loadingPaymentMethods = true;
			this.data.paymentMethods = await UserBillingService.retrievePaymentMethods();
			this.data.loadingPaymentMethods = false;
		} catch (e) {
			this.data.loadingPaymentMethods = false;
			console.log("ERROR: An error occured while attempting to retrieve the user's payment methods");
		}
	}

	async fetchPaymentHistory() {
		try {
			this.data.loadingTransactionHistory = true;
			this.data.transactionHistory = await UserBillingService.getTransactionHistory();
			console.log(this.data.transactionHistory);
			this.data.loadingTransactionHistory = false;
		} catch (e) {
			this.data.loadingTransactionHistory = false;
			console.log('ERROR: An eccorued while attempting to retrieve the users payment history');
		}
	}

	async fetchData() {
		await this.fetchPaymentMethods();
		await this.fetchPaymentHistory();
		if (userStore.paymentMethods && userStore.paymentMethods.length < 0 && this.data.transactionHistory.length < 0) {
			this.goToRoute('#no-payment-methods');
		} else {
			this.goToRoute('#billing-home');
		}
	}
	async onPopupOpen() {
		this.fetchData();
	}

	handleTransactionSelect(transaction) {
		this.data.selectedTransaction = transaction;
		this.goToRoute('#transaction');
	}

	render() {
		let { currentView, transactionHistory } = this.data;
		let { paymentMethods } = userStore;
		return (
			<Popup
				opened={this.props.opened}
				className={`billing-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={true}
				closeOnEscape={true}
				onPopupClosed={this.closePrompt}
				onPopupOpen={this.onPopupOpen}
			>
				<Page>
					<Tabs animated>
						<Tab id="loading" className="page-content">
							<div className="vbox hcenter vcenter h-100">
								<Preloader></Preloader>
								<h2>Loading</h2>
							</div>
						</Tab>
						<Tab id="billing-home" className="page-content" onTabShow={this.tabShow} onTabHide={this.tabHide}>
							{currentView === '#billing-home' && (
								<BillingHome
									onCancel={this.closePrompt}
									showAddCard={() => this.goToRoute('#add-credit-card')}
									onTransactionSelect={this.handleTransactionSelect}
									paymentMethodsLoading={this.data.loadingPaymentMethods}
									paymentMethods={paymentMethods}
									transactionHistoryLoading={this.data.loadingTransactionHistory}
									transactionHistory={transactionHistory}
								/>
							)}
						</Tab>
						<Tab id="no-payment-methods" className="page-content" onTabShow={this.tabShow} onTabHide={this.tabHide}>
							{currentView === '#no-payment-methods' && (
								<NoPaymentMethods onAddCard={() => this.goToRoute('#add-credit-card')} onCancel={this.closePrompt} />
							)}
						</Tab>
						<Tab id="add-credit-card" className="page-content" onTabShow={this.tabShow} onTabHide={this.tabHide}>
							{currentView === '#add-credit-card' && (
								<Elements stripe={appStore.stripePromise}>
									<StripeAddCreditCardForm onBack={this.onBack} onCardAdded={this.onCardAdded} />
								</Elements>
							)}
						</Tab>
						<Tab id="transaction" className="page-content" onTabShow={this.tabShow} onTabHide={this.tabHide}>
							{currentView === '#transaction' && <TransactionPage transaction={this.data.selectedTransaction} onBack={this.onBack} />}
						</Tab>
					</Tabs>
				</Page>
			</Popup>
		);
	}
}
