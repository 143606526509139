import React, { Component } from 'react';
import _ from 'lodash';
import { Progressbar, Preloader, Link, Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './dashboard.scss';
import CloudAPI from '@/services/CloudAPI';
import appStore from '@/stores/AppStore';
import { format, setDay, setDate, getDaysInMonth } from 'date-fns';

@observer
export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			consumerTokenSummary: null,
			orgSummary: null,
			userCountByGroup: null,
			todayStats: null,
			weekStats: null,
			monthStats: null,
			showAllGroups: false,
			expandedOrg: null,
			groupData: null
		});
		this.today = new Date(new Date().setHours(0, 0, 0, 0));
		autoBind(this);
	}

	async componentDidMount() {
		// CloudAPI.getUserCountByGroup()
		CloudAPI.getConsumerTokenSummary().then((res) => {
			this.data.consumerTokenSummary = res;
		});
		CloudAPI.getOrgsSummary()
			.then((res) => {
				this.data.orgSummary = res;
			})
			.catch((err) => {
				console.log({ err });
				this.data.userCountByGroup = [];
			});
		CloudAPI.getDailyStats(this.today)
			.then((res) => {
				this.data.todayStats = res;
			})
			.catch((err) => {
				console.log({ err });
				this.data.todayStats = {};
			});
		CloudAPI.getWeeklyStats(this.today)
			.then((res) => {
				this.data.weekStats = res;
			})
			.catch((err) => {
				console.log({ err });
				this.data.weekStats = {};
			});
		CloudAPI.getMonthlyStats(this.today)
			.then((res) => {
				this.data.monthStats = res;
			})
			.catch((err) => {
				console.log({ err });
				this.data.monthStats = {};
			});
	}

	async exportCsv() {
		this.$f7.dialog.preloader();
		try {
			const userCountByGroup = await CloudAPI.getUserCountByGroup(this.data.expandedOrg);
			const csvRows = userCountByGroup.map((row) => {
				const formattedDisplayData = row.displayData.map((x) => {
					return x && typeof x === 'string' ? x.replace(/,/g, '') : x;
				});
				return formattedDisplayData.join(',');
			});
			const finalCSVOutput = `${csvRows.join('\n')}`;
			var exportTrigger = document.createElement('a');
			this.$f7.dialog.close();
			exportTrigger.href = 'data:text/csv;charset=utf-8,' + encodeURI(finalCSVOutput);
			exportTrigger.target = '_blank';
			exportTrigger.download = `users_by_group-${new Date().getTime()}.csv`;
			exportTrigger.click();
		} catch (err) {
			console.log('error exporting data: ', err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error downloading data');
		}
	}

	toggleGroups() {
		const newStatus = !this.data.showAllGroups;
		this.data.showAllGroups = newStatus;
	}

	async toggleOrgDetails(evt) {
		const orgId = evt.currentTarget.getAttribute('data-org');
		this.$f7.dialog.preloader();
		if (!orgId) {
			this.data.expandedOrg = null;
			this.data.groupData = [];
			this.$f7.dialog.close();
			return;
		}
		this.data.expandedOrg = orgId;
		this.data.groupData = await CloudAPI.getUserCountByGroup(orgId);
		this.$f7.dialog.close();
	}

	render() {
		const { orgSummary, expandedOrg, groupData, showAllGroups } = this.data;
		const expandedOrgData = _.find(orgSummary, { id: expandedOrg });
		const currentData = groupData && groupData.length > 0 ? groupData : orgSummary || [];
		const dataToShow = !showAllGroups ? currentData.filter((x) => x.memberCount > 0) : currentData;
		const showSummaryLoader = !currentData || currentData.length < 1;
		return (
			<div className="vbox vcenter hcenter dashboard-content">
				<div className="quick-stats-container">
					<div className="card">
						<div className="card-header">
							Today
							<span className="date-line">{format(this.today, 'MMM d')}</span>
						</div>
						<div className="card-content">
							{this.data.todayStats && (
								<div className="stats">
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-sign-in" />
											Logins:
										</span>
										<span className="data">{_.get(this.data, 'todayStats.logins')}</span>
									</div>
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-user-plus" />
											New:
										</span>
										<span className="data">{_.get(this.data, 'todayStats.registrations')}</span>
									</div>
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-calendar-check" />
											Appts:
										</span>
										<span className="data">{_.get(this.data, 'todayStats.appointments')}</span>
									</div>
								</div>
							)}
							{!this.data.todayStats && (
								<div className="data-loading">
									<Preloader color="blue" size={44} />
								</div>
							)}
						</div>
					</div>
					<div className="card">
						<div className="card-header">
							This Week
							<span className="date-line">
								{format(setDay(this.today, 0), 'MMM d')} - {format(setDay(this.today, 6), 'MMM d')}
							</span>
						</div>
						<div className="card-content">
							{this.data.weekStats && (
								<div className="stats">
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-sign-in" />
											Logins:
										</span>
										<span className="data">{_.get(this.data, 'weekStats.logins')}</span>
									</div>
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-user-plus" />
											New:
										</span>
										<span className="data">{_.get(this.data, 'weekStats.registrations')}</span>
									</div>
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-calendar-check" />
											Appts:
										</span>
										<span className="data">{_.get(this.data, 'weekStats.appointments')}</span>
									</div>
								</div>
							)}
							{!this.data.weekStats && (
								<div className="data-loading">
									<Preloader color="green" size={44} />
								</div>
							)}
						</div>
					</div>
					<div className="card">
						<div className="card-header">
							This Month
							<span className="date-line">
								{format(setDate(this.today, 1), 'MMM d')} - {format(setDate(this.today, getDaysInMonth(this.today)), 'MMM d')}
							</span>
						</div>
						<div className="card-content">
							{this.data.monthStats && (
								<div className="stats">
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-sign-in" />
											Logins:
										</span>
										<span className="data">{_.get(this.data, 'monthStats.logins')}</span>
									</div>
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-user-plus" />
											New:
										</span>
										<span className="data">{_.get(this.data, 'monthStats.registrations')}</span>
									</div>
									<div className="stats-row">
										<span className="label">
											<i className="fad fa-calendar-check" />
											Appts:
										</span>
										<span className="data">{_.get(this.data, 'monthStats.appointments')}</span>
									</div>
								</div>
							)}
							{!this.data.monthStats && (
								<div className="data-loading">
									<Preloader color="blue" size={44} />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="consumer-tokens-container">
					<div className="card">
						<div className="card-header">Consumer Credits</div>
						<div className="card-content">
							{this.data.consumerTokenSummary && (
								<div className="consumer-data-container">
									<div className="consumer-item">
										<div className="header-item">Type </div>
										<div className="header-item">Amount</div>
										<div className="header-item">Quantity</div>
									</div>
									<div className="consumer-item">
										<div className="label">Purchased: </div>
										<div className="value green">{_.get(this.data, 'consumerTokenSummary.purchased.total') || 'N/A'}</div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.purchased.count') || 'N/A'}</div>
									</div>
									<div className="consumer-item">
										<div className="label">Issued: </div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.issued.total') || 'N/A'}</div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.issued.count') || 'N/A'}</div>
									</div>
									<div className="consumer-item">
										<div className="label">Awarded: </div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.rewarded.total') || 'N/A'}</div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.rewarded.count') || '0'}</div>
									</div>
									<div className="consumer-item">
										<div className="label">Refunded: </div>
										<div className="value red">{_.get(this.data, 'consumerTokenSummary.refunded.total') || 'N/A'}</div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.refunded.count') || 'N/A'}</div>
									</div>
									<div className="consumer-item">
										<div className="label">Redeemed: </div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.redeemed.total') || 'N/A'}</div>
										<div className="value">{_.get(this.data, 'consumerTokenSummary.redeemed.count') || 'N/A'}</div>
									</div>
								</div>
							)}
							{!this.data.consumerTokenSummary && (
								<div className="data-loading">
									<Preloader color="blue" size={44} />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="table-container">
					<div className={`data-table data-table-init card ${appStore.isMobile ? 'x-scroll mobile' : 'x-scroll desktop'}`}>
						<div className="card-header">
							<span className="card-title">
								{!!expandedOrg && (
									<Link onClick={this.toggleOrgDetails}>
										<i className="fad fa-arrow-left" />
									</Link>
								)}
								{`${!!expandedOrg ? expandedOrgData.name : 'All Organizations'}`}
							</span>
							{this.data.orgSummary && (
								<span>
									<Button onClick={this.toggleGroups}>
										{showAllGroups ? `Filter ${expandedOrg ? 'Groups' : 'Orgs'}` : `View All ${expandedOrg ? 'Groups' : 'Orgs'}`}
									</Button>
									{!appStore.isMobile && <Button onClick={this.exportCsv}>Export to CSV</Button>}
								</span>
							)}
						</div>
						<div className={`card-content ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
							{dataToShow &&
								dataToShow.map((orgData, orgIndex) => {
									const isHeader = orgIndex === 0;
									const isTotals = orgIndex === 1;
									const rowItems = orgData.displayData;
									const rowClassName = isHeader ? 'table-row table-header' : isTotals ? 'table-row table-body y-scroll' : 'table-row';
									return (
										<div className={rowClassName} key={`group-summary-item-${orgData.id}`}>
											{rowItems.map((rowItem, rowIndex) => {
												const itemClass = rowIndex === 0 ? `flex-3 ${!isTotals && !isHeader && !expandedOrg ? 'org-name' : ''}` : '';
												if (rowIndex === 0 && !isTotals && !isHeader && !expandedOrg) {
													return (
														<div
															key={`group-summary-row-${orgData.id}-${rowIndex}`}
															className="flex-3 org-name"
															data-org={orgData.id}
															onClick={this.toggleOrgDetails}
														>
															{rowItem}
														</div>
													);
												}
												return (
													<div key={`group-summary-row-${orgData.id}-${rowIndex}`} className={itemClass}>
														{rowItem}
													</div>
												);
											})}
										</div>
									);
								})}
						</div>
						{showSummaryLoader && (
							<div className="data-loading">
								<Progressbar infinite color="multi" className="animated fadeIn" />
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
