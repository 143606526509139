import { observable } from '../utils/State';
import _ from 'lodash';
const appStore = observable({
	demoMode: null,
	showQuestionnaire: false,
	showReauthLoader: false,
	isMobile: true,
	isIos: false,
	isProviderPortal: false,
	isExpressPortal: false,
	locale: 'en-US',
	registeredPushListeners: false,
	deviceDetails: {},
	openProviderProfile: false,
	setLocationOpen: false,
	initalized: false,
	activeTab: 'home',
	apiIssues: false,
	bookingOpen: false,
	configLoaded: false,
	procedureSearchOpen: false,
	selectedProcedure: null,
	externalServicesOpen: false,
	forceAppUpdate: false,
	shopOpen: false,
	screenerOpen: false,
	telehealthOpen: false,
	showTelehealthModal: false,
	confirmedTelehealth: false,
	testFinderOpen: false,
	balanceBillOpen: false,
	telehealthType: null,
	consumerEnrollementOpen: false,
	shopItem: null,
	questionnaires: [],
	stripePromise: null,
	showExternalServiceMessage: false,
	externalServiceMessage: null,
	openVideoId: null,
	onCloseEnrollment() {
		appStore.consumerEnrollementOpen = false;
	},
	openEnrollment() {
		appStore.consumerEnrollementOpen = true;
	},
	openShop(item) {
		appStore.shopItem = item;
		appStore.shopOpen = true;
	},
	closeShop() {
		appStore.shopItem = null;
		appStore.shopOpen = false;
	},
	openScreener() {
		appStore.screenerOpen = true;
	},
	closeScreener() {
		appStore.screenerOpen = false;
	},
	isPWA() {
		return _.get(this, 'deviceDetails.standalone', false);
	},
	isApp() {
		return _.get(this, 'deviceDetails.cordova', false);
	},
	currentLocation: {
		lat: '27.9506',
		lon: '-82.4572',
		name: 'Tampa'
	}
});
export default appStore;
