import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Link, Popup } from 'framework7-react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import _ from 'lodash';
import './document-preview.scss';
import ExpressAPI from '@/services/ExpressAPI';
@observer
export default class DocumentPreview extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			documents: []
		});
		autoBind(this);
	}

	handlePopupClose() {
		const { onClose } = this.props;
		this.data.documents = [];
		if (onClose) {
			onClose();
		}
	}

	handleClose() {
		this.data.documents = [];
	}
	onOpen() {
		const { document, fileUrl } = this.props;
		console.log(fileUrl);
		if (fileUrl) {
			let document = {};
			document.uri = fileUrl;
			document.fileType = 'image/png';
			this.data.documents = [document];
		} else {
			ExpressAPI.getDocument(document._id)
				.then((res) => {
					let document = {};
					document.uri = res.fileUrl;
					document.fileType = res.fileData.fileType;
					this.data.documents = [document];
				})
				.catch((e) => {
					showExpressError(e);
				});
		}
	}

	render() {
		const { opened } = this.props;
		const { documents } = this.data;

		return (
			<Popup
				className="express-form-popup document-preview"
				opened={!!opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				tabletFullscreen={true}
				onPopupOpen={this.onOpen}
				onPopupClosed={this.handlePopupClose}
			>
				<div className="popup-header">
					<div className="title grow-1">Preview</div>
					<Link popupClose>
						<i className="fad fa-times-square"></i>
					</Link>
				</div>
				<div className="main-content">
					{documents.length && (
						<DocViewer
							config={{
								header: {
									disableHeader: true,
									disableFileName: true,
									retainURLParams: false
								}
							}}
							pluginRenderers={DocViewerRenderers}
							documents={_.cloneDeep(documents)}
							className="doc-viewer"
						/>
					)}
				</div>
			</Popup>
		);
	}
}
