import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveFunnel } from '@nivo/funnel';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './funnel-chart.scss';

@observer
export default class FunnelChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getColor(data) {
		return data.color;
	}

	handleItemClick(data) {
		console.log('funnel item was clicked: ', data);
	}

	formatData() {
		const data = _.get(this.props, 'data.data') || [];
		const formattedData = data.map((item) => {
			const itemData = item.data;
			const itemTotal = itemData.reduce((total, curr) => total + curr.y, 0);
			return {
				id: item.id,
				label: item.label,
				value: itemTotal,
				color: item.color
			};
		});
		return formattedData;
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const data = this.formatData();
		const chartConfig = chartProperties.config;
		return (
			<ResponsiveFunnel
				data={data}
				margin={{ top: 20, right: 20, bottom: isMobile ? 70 : 50, left: 20 }}
				valueFormat={chartConfig.format || ''}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				borderWidth={20}
				onClick={this.handleItemClick}
				labelColor={{ from: 'color', modifiers: [['darker', 3]] }}
				beforeSeparatorLength={100}
				beforeSeparatorOffset={20}
				afterSeparatorLength={100}
				afterSeparatorOffset={20}
				currentPartSizeExtension={10}
				currentBorderWidth={40}
				motionConfig="wobbly"
			/>
		);
	}
}
