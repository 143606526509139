// @ts-nocheck
import _ from 'lodash';
import ExpressAPI from './ExpressAPI';
import { showExpressError } from '@/utils/GeneralUtils';
import { isMatch } from 'date-fns';

const headerFields = [
	'relation',
	'memberId',
	'personCode',
	'groupId',
	'healthPlan',
	'insuredFirstName',
	'insuredMiddleName',
	'insuredLastName',
	'firstName',
	'middleName',
	'lastName',
	'ssn',
	'email',
	'phone',
	'street',
	'street2',
	'city',
	'state',
	'zipcode',
	'gender',
	'dob',
	'coverageLevel',
	'startDate',
	'termDate'
];

const requiredFields = [
	'relation',
	'memberId',
	'groupId',
	'healthPlan',
	'firstName',
	'lastName',
	'street',
	'city',
	'state',
	'zipcode',
	'gender',
	'dob',
	'coverageLevel',
	'startDate',
	'termDate'
];

const requireEDIPrimaryFields = [
	'relation',
	'memberId',
	'groupId',
	'healthPlan',
	'firstName',
	'lastName',
	'street',
	'city',
	'state',
	'zipcode',
	'gender',
	'dob',
	'startDate'
];

const staticFields = ['relation', 'state', 'zipcode', 'gender', 'dob', 'coverageLevel', 'startDate', 'termDate'];

class ExpressImportService {
	constructor() {}

	validateSheet(workbook) {
		if (workbook.SheetNames[0].toLowerCase() !== 'members') {
			return false;
		}
		return true;
	}

	validateHeaders(headers) {
		const headerValidator = {
			relation: 'Relation',
			memberId: 'Member Id',
			personCode: 'Person Code',
			groupId: 'Group Policy Number',
			healthPlan: 'Health Plan Id',
			healthPlanDental: 'Health Plan Dental Id',
			healthPlanVision: 'Health Plan Vision Id',
			insuredFirstName: 'Insured First Name',
			insuredMiddleName: 'Insured Middle Name',
			insuredLastName: 'Insured Last Name',
			firstName: 'Member First Name',
			middleName: 'Member Middle Name',
			lastName: 'Member Last Name',
			ssn: 'SSN',
			email: 'Email',
			phone: 'Mobile Phone',
			street: 'Address 1',
			street2: 'Address 2',
			city: 'City',
			state: 'State',
			zipcode: 'Zip Code',
			gender: 'Gender',
			dob: 'Birth Date',
			coverageLevel: 'Coverage Level',
			coverageLevelDental: 'Coverage Level Dental',
			coverageLevelVision: 'Coverage Level Vision',
			startDate: 'Start Date',
			termDate: 'Term Date'
		};
		let valid = true;
		_.forEach(Object.keys(headers), (headerKey) => {
			if (headers[headerKey] !== headerValidator[headerKey]) {
				valid = false;
			}
		});
		return valid;
	}

	async processExcel(fileName, formData) {
		try {
			const fileSummary = await ExpressAPI.processExcelFile(formData);
			document.getElementById('uploadInput').value = null;
			return { ...fileSummary, fileName };
		} catch (err) {
			document.getElementById('uploadInput').value = null;
			console.log(err);
			throw new Error('Unable to process this file, please confirm format matches the companion guide ruleset.');
		}
	}

	async processEDIMembers(fileName, members) {
		const invalidRecords = [];
		try {
			const validMemberMap = {};
			for (let i = 1; i < members.length; i++) {
				const rec = members[i];
				const missing = [];
				//* Check for missing required fields
				_.forEach(requireEDIPrimaryFields, (key) => {
					if (_.isEmpty(rec[key])) {
						if (key === 'healthPlan') {
							// * Account for healthPlanDental or healthPlanVision
							const isPlanDentalOrVision =
								rec.healthPlanDental && !_.isEmpty(rec.healthPlanDental)
									? rec.healthPlanDental
									: rec.healthPlanVision && !_.isEmpty(rec.healthPlanVision)
									? rec.healthPlanVision
									: false;

							if (isPlanDentalOrVision) return;
						}
						missing.push(key);
					}
				});
				if (missing.length > 0 && rec.relation === '18') {
					invalidRecords.push({ obj: rec, invalidFields: missing });
				} else {
					//* No fields are missing DATA so now data needs checked
					const invalid = [];
					_.forEach(staticFields, (key) => {
						switch (key) {
							case 'relation': {
								if (['18', '01', '19', 'G8'].indexOf(rec[key]) < 0) {
									rec[key] = 'G8';
								}
								break;
							}
							case 'state': {
								if (_.isEmpty(rec[key])) {
									break;
								}
								if (rec[key].length !== 2) {
									invalid.push(key);
								}
								break;
							}
							case 'zipcode': {
								if (_.isEmpty(rec[key])) {
									break;
								}
								if (rec[key].length < 5) {
									invalid.push(key);
								}
								if (rec[key].length > 5) {
									rec[key] = rec[key].substring(0, 5);
								}
								break;
							}
							case 'gender': {
								if (['m', 'f'].indexOf(rec[key].toLowerCase()) < 0) {
									invalid.push(key);
								}
								break;
							}
							case 'coverageLevel': {
								const isPlanMedicalOrAllCoverages = rec.coverageLevel && !_.isEmpty(rec.coverageLevel) ? rec.coverageLevel : false;
								const isPlanDental = rec.coverageLevelDental && !_.isEmpty(rec.coverageLevelDental) ? rec.coverageLevelDental : false;
								const isPlanVision = rec.coverageLevelVision && !_.isEmpty(rec.coverageLevelVision) ? rec.coverageLevelVision : false;
								const hasCoverageField = isPlanMedicalOrAllCoverages
									? 'coverageLevel'
									: isPlanDental
									? 'coverageLevelDental'
									: isPlanVision
									? 'coverageLevelVision'
									: false;

								if (!isPlanMedicalOrAllCoverages && !isPlanDental && !isPlanVision) {
									break;
								}
								if (hasCoverageField && ['EMP', 'ESP', 'ECH', 'FAM', 'E1D'].indexOf(rec[hasCoverageField].toUpperCase()) < 0) {
									invalid.push(key);
								}
								break;
							}
							case 'dob': {
								if (rec[key].length !== 8) {
									invalid.push(key);
								} else {
									//format to date
									let year = rec[key].substring(0, 4);
									let month = rec[key].substring(4, 6);
									let day = rec[key].substring(6, 8);
									rec[key] = `${month}/${day}/${year}`;
									if (!isMatch(rec[key], 'MM/dd/yyyy')) {
										invalid.push(key);
									}
								}
								break;
							}
							case 'startDate': {
								if (rec[key].length !== 8) {
									invalid.push(key);
								} else {
									//format to date
									let year = rec[key].substring(0, 4);
									let month = rec[key].substring(4, 6);
									let day = rec[key].substring(6, 8);
									rec[key] = `${month}/${day}/${year}`;
									if (!isMatch(rec[key], 'MM/dd/yyyy')) {
										invalid.push(key);
									}
								}
								break;
							}
							case 'termDate': {
								if (_.isEmpty(rec[key])) {
									break;
								}
								if (rec[key].length !== 8) {
									invalid.push(key);
								} else {
									//format to date
									let year = rec[key].substring(0, 4);
									let month = rec[key].substring(4, 6);
									let day = rec[key].substring(6, 8);
									rec[key] = `${month}/${day}/${year}`;
									if (!isMatch(rec[key], 'MM/dd/yyyy')) {
										invalid.push(key);
									}
								}
								break;
							}
						}
					});
					if (invalid.length > 0) {
						invalidRecords.push({ obj: rec, invalidFields: invalid });
					} else {
						let primaryId = rec.memberId.split('.')[0];
						if (!validMemberMap[primaryId]) {
							validMemberMap[primaryId] = [];
						}
						validMemberMap[primaryId].push(rec);
					}
				}
			}
			let validRecords = [];
			//add dependents by household to the primary member && fill in any missing dependent data
			_.forEach(Object.keys(validMemberMap), (memberId) => {
				let household = validMemberMap[memberId];
				if (household.length === 1) {
					household[0].dependents = [];
					validRecords.push(household[0]);
				} else {
					let primary = _.find(household, { relation: '18' });
					if (primary) {
						primary.dependents = [];
						_.forEach(household, (dependent) => {
							if (dependent.relation !== '18') {
								primary.dependents.push(dependent.memberId);
								if (_.isEmpty(dependent.street)) {
									dependent.street = primary.street;
								}
								if (_.isEmpty(dependent.street2)) {
									dependent.street2 = primary.street2;
								}
								if (_.isEmpty(dependent.city)) {
									dependent.city = primary.city;
								}
								if (_.isEmpty(dependent.state)) {
									dependent.state = primary.state;
								}
								if (_.isEmpty(dependent.zipcode)) {
									dependent.zipcode = primary.zipcode;
								}
								if (_.isEmpty(dependent.coverageLevel)) {
									const isPlanMedicalOrAllCoverages =
										primary.coverageLevel && !_.isEmpty(primary.coverageLevel) ? primary.coverageLevel : false;
									const isPlanDental =
										primary.coverageLevelDental && !_.isEmpty(primary.coverageLevelDental) ? primary.coverageLevelDental : false;
									const isPlanVision =
										primary.coverageLevelVision && !_.isEmpty(primary.coverageLevelVision) ? primary.coverageLevelVision : false;

									dependent.coverageLevel = isPlanMedicalOrAllCoverages
										? primary.coverageLevel
										: isPlanDental
										? primary.coverageLevelDental
										: isPlanVision
										? primary.coverageLevelVision
										: '';
									dependent.coverageLevelDental = isPlanDental ? primary.coverageLevelDental : '';
									dependent.coverageLevelVision = isPlanVision ? primary.coverageLevelVision : '';
								}
								if (!_.isEmpty(dependent.termDate)) {
									dependent.termDate = primary.termDate;
								}
							}
						});
					}
					_.forEach(household, (m) => validRecords.push(m));
				}
			});
			const uploadInput = document.getElementById('uploadInput');
			if (uploadInput) {
				document.getElementById('uploadInput').value = null;
			}
			return { valid: validRecords, invalid: invalidRecords, fileName };
		} catch (err) {
			document.getElementById('uploadInput').value = null;
			console.log(err);
			throw new Error(
				'Unable to process this file, please confirm the format matches the EDI Configuration rules of your orgnaization.'
			);
		}
	}

	async parseEDIFileToMembers(config, formData) {
		return await ExpressAPI.parseEDIFileToMembers(config, formData);
	}

	async processFileData(e, f7, onComplete) {
		try {
			const files = e.target.files;
			const f = files[0];
			const reader = new FileReader();
			let type = null;
			reader.onloadend = async (evt) => {
				try {
					console.log(`Processing ${type} file:`);
					if (
						(type === 'x12' || type === 'edi' || type === 'txt') &&
						evt.target.result.indexOf &&
						evt.target.result.indexOf('ISA*') >= 0
					) {
						console.log('EDI FILE UPLOADED');
						let formData = new FormData();
						const body = {
							name: f.name,
							type: 'x12'
						};
						formData.append('file', f, f.name);
						formData.append('document', JSON.stringify(body));
						const defaultConfig = await ExpressAPI.getDefaultEDIConfig();
						if (defaultConfig) {
							const members = await this.parseEDIFileToMembers(defaultConfig, formData);
							console.log('Members returned');
							const results = await this.processEDIMembers(f.name, members);
							if (onComplete) {
								onComplete(results);
							}
						} else {
							//need to generate file elements and go into that flow
							const results = await ExpressAPI.generateEDIFileElements(formData);
							onComplete({ needsConfig: true, fileElements: results, formData: formData });
						}
					} else {
						let formData = new FormData();
						const body = {
							name: f.name,
							type: f.type
						};
						formData.append('file', f, f.name);
						formData.append('document', JSON.stringify(body));
						const results = await this.processExcel(f.name, formData);
						if (onComplete) {
							onComplete(results);
						}
					}
				} catch (err) {
					console.log(err);
					showExpressError(f7, err);
				}
			};
			if (f && !_.isEmpty(f.name)) {
				console.log('type is: ', f.type);
				switch (f.type) {
					case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
						type = 'xlsx';
						reader.readAsArrayBuffer(f);
						break;
					}
					case 'application/vnd.ms-excel': {
						type = f.name.split('.')[1];
						reader.readAsArrayBuffer(f);
						break;
					}
					case 'text/plain': {
						type = 'txt';
						reader.readAsText(f);
						break;
					}
					case 'text/csv': {
						type = 'text';
						reader.readAsText(f);
						break;
					}
					default: {
						let t = f.name.split('.')[1];
						if (t === 'x12') {
							type = 'x12';
							reader.readAsText(f);
						} else if (t === 'edi') {
							type = 'edi';
							reader.readAsText(f);
						} else {
							f7.dialog.close();
							e.value = '';
							f7.dialog.alert('Unknown file type uploaded, please upload Excel (xlsx, csv) or x12 EDI files only.');
						}
						break;
					}
				}
			}
		} catch (err) {
			showExpressError(f7, err);
		}
	}
}

export default new ExpressImportService();
