import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import Table from '@/components/_EXPRESS/table/Table';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import { Progressbar, Preloader } from 'framework7-react';
import './group-admin.scss';

@observer
export default class GroupAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentGroup: null,
			groups: [],
			steps: [],
			loading: true
		});
		autoBind(this);
	}

	async componentDidMount() {
		const groups = await this.getGroups();
		this.data.groups = groups;
	}

	async getGroups() {
		this.$f7.dialog.preloader();
		try {
			const groups = await ExpressAPI.getAllAdminGroups();
			this.$f7.dialog.close();
			return groups;
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error loading groups');
		}
	}

	async getGroupDetails(id) {
		this.data.loading = true;
		const groupStatus = await ExpressAPI.getGroupStatus(id);
		this.data.steps = groupStatus.steps;
		this.data.loading = false;
	}

	handleRequestClick(data) {
		const id = data.id;
		const groupData = _.find(this.data.groups, { _id: id });
		this.data.currentGroup = groupData;
		this.getGroupDetails(id);
	}

	closeDetails() {
		this.data.currentGroup = null;
	}

	render() {
		const loading = false;
		const showGroupList = !this.data.currentGroup;
		const showGroupDetails = !!this.data.currentGroup;
		const hasGroups = this.data.groups && this.data.groups.length > 0;
		const groups = this.data.groups || [];
		const steps = this.data.steps || [];
		const completedSteps = steps.filter((x) => !!x.complete);
		const amountComplete = Math.ceil((completedSteps.length / steps.length) * 100) || 0;
		const tableHeaders = [
			{ text: 'Name', classList: 'ellipse', canSearch: true },
			{ text: 'Org', classList: 'ellipse', canSearch: true },
			{ text: 'Last Updated' },
			{ text: 'Status', canSearch: true }
		];
		const tableRows = groups.map((group) => {
			return {
				id: group._id,
				onClick: this.handleRequestClick,
				items: [
					{ text: group.name, classList: 'ellipse group-name capitalize' },
					{ text: group.org, classList: 'capitalize ellipse' },
					{ text: group.lastUpdated },
					{ classList: 'capitalize', text: group.status }
				]
			};
		});
		return (
			<div className="admin-group-container">
				{showGroupDetails && (
					<div className="group-admin-details">
						<Breadcrumb
							pageName="admin-groups"
							config={{
								0: { name: 'All Groups', icon: 'fad fa-files-medical', onClick: this.closeDetails },
								1: {
									name: `${_.get(this.data, 'currentGroup.org') || 'Unknown Org'} - ${_.get(this.data, 'currentGroup.name') || ''}`,
									onClick: () => {}
								}
							}}
							currentKey={1}
						/>
						{this.data.loading && (
							<div className="loading-ctn">
								<Preloader size={32} color="blue" />
							</div>
						)}
						{!this.data.loading && (
							<div className="group-overview-card">
								<Progressbar progress={amountComplete} id="group-inline-progressbar" />
								<div className="step-list">
									{steps.map((step, stepIndex) => {
										return (
											<div className={`step hbox vcenter ${step.complete ? 'completed' : ''}`} key={`group-admin-steps-${stepIndex}`}>
												<div className="status">
													{step.complete && <i className="fas fa-check animated bounceIn" />}
													{!step.complete && <i className="fas fa-exclamation animated bounceIn" />}
												</div>
												<div className="step-text">
													<div>
														<strong>{step.title}</strong>
													</div>
													<div className="step-info">{step.info}</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				)}
				{showGroupList && (
					<div className="express-groups-list">
						<h2 className="grow-1">Group List</h2>
						{!hasGroups && !loading && (
							<div className="empty-message vbox vcenter hcenter">
								<div className="icon-box hbox vcenter hcenter">
									<i className="fad fa-users-class" />
								</div>
								<h3>There are no groups created yet. Check back later!</h3>
							</div>
						)}
						{hasGroups && <Table id="group-admin-table" headers={tableHeaders} rows={tableRows} />}
					</div>
				)}
			</div>
		);
	}
}
