import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import AnalyticsService from '@/services/AnalyticsService';
import TwoFactor from '../employer-signup/two-factor/TwoFactor';
import EmailConfirmPopup from '../../EmailConfirmPopup';
import CloudAPI from '@/services/CloudAPI';
import './account-verification.scss';

const analytics = new AnalyticsService();

@observer
export default class AccountVerification extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ showTwoFactor: false, emailVerifyOpen: false });
		this.text = getCmpText('EmployerSignup', {
			verifyBySmsBtn: 'Verify by text message',
			verifyByEmailBtn: "I don't have access to my phone",
			unableToSendVerification:
				'Unable to send verification.  Please try again later'
		});
		autoBind(this);
	}

	componentWillUnmount() {
		try {
			firebase.auth().signOut();
		} catch (err) {
			console.log('unable to sign out: ', err);
		}
	}

	async onEmailVerify(evt) {
		this.$f7.dialog.preloader();
		try {
			if (this.props.createAccount) {
				await this.props.createAccount();
			}
			await this.sendEmailVerification();
			this.data.emailVerifyOpen = true;
			if (this.props.createAccount) {
				analytics.trackWithData('Sign up', {
					step: 'account created',
					method: 'email verification'
				});
			} else {
				analytics.trackWithData('Sign up', {
					step: 'resend verification',
					method: 'email verification'
				});
			}
			this.$f7.dialog.close();
		} catch (err) {
			console.log({ err });
			this.$f7.dialog.close();
			this.$f7.dialog.alert(
				this.text.unableToSendVerification,
				this.props.onNext
			);
		}
	}

	async sendEmailVerification() {
		return await firebase.auth().currentUser.sendEmailVerification({});
	}

	async onSMSVerify(evt) {
		this.$f7.dialog.confirm(
			'OPT IN for SMS messaging. By submitting this form and signing up for texts; you consent to receive account notifications from CareValet through our Twilio autodialer. You need to OPT IN to SMS or email for our product. MSG & data rates may apply. Unsubscribe anytime by reaching out to customerservice@carevlaet.com. Our Terms of service and Privacy Policy can be reviewed below.',
			'Please Confirm',
			async () => {
				//call back to toggle to proceed.
				try {
					if (this.props.createAccount) {
						this.$f7.dialog.preloader();
						await this.props.createAccount();
						this.$f7.dialog.close();
					}
					await this.sendSmsCode();
					if (this.props.createAccount) {
						analytics.trackWithData('Sign up', {
							step: 'account created',
							method: 'sms verification'
						});
					} else {
						analytics.trackWithData('Sign up', {
							step: 'resend verification',
							method: 'sms verification'
						});
					}
				} catch (err) {
					console.log('unable to send verification code: ', err);
					this.$f7.dialog.close();
					this.$f7.dialog.alert(
						this.text.unableToSendVerification,
						this.props.onNext
					);
				}
			}
		);
	}

	async sendSmsCode() {
		this.$f7.dialog.preloader();
		try {
			const fId = firebase.auth().currentUser.uid;
			await CloudAPI.textAccountVerification(fId);
			if (!this.data.showTwoFactor) {
				this.data.showTwoFactor = true;
			}
			this.$f7.dialog.close();
		} catch (err) {
			console.log('error sending SMS Code: ', err);
			analytics.trackWithData('Sign up', {
				step: 'send sms code failure',
				error: err && err.toString()
			});
			this.$f7.dialog.close();
			this.$f7.dialog.alert(
				this.text.unableToSendVerification,
				this.props.onNext
			);
		}
	}

	closeTwoFactor() {
		this.data.showTwoFactor = false;
		this.props.onNext();
	}

	closeEmailVerifyAlert() {
		this.data.emailVerifyOpen = false;
		this.props.onNext('accountVerification', 'email');
	}

	render() {
		return (
			<div className="account-verification-container">
				<Button
					disabled={this.props.disabled}
					fill
					large
					className="round-btn blue"
					onClick={this.onSMSVerify}
				>
					{this.text.verifyBySmsBtn}
				</Button>

				<Button
					disabled={this.props.disabled}
					fill
					large
					className="round-btn white-blue"
					onClick={this.onEmailVerify}
				>
					{this.text.verifyByEmailBtn}
				</Button>
				{this.data.showTwoFactor && (
					<TwoFactor
						id={this.props.id}
						isOpen
						close={this.closeTwoFactor}
						resend={this.sendSmsCode}
					/>
				)}
				{this.data.emailVerifyOpen && (
					<EmailConfirmPopup
						email={this.props.email}
						onResend={this.sendEmailVerification}
						opened={this.data.emailVerifyOpen}
						onNext={this.closeEmailVerifyAlert}
					/>
				)}
				<div className="psAndTosContainer">
					<div
						className="hbox vcenter tos-toggle"
						onClick={() => {
							window.open('https://carevalet.com/terms.html', '_blank');
						}}
					>
						<div className="termslink">
							<span>Terms Of Service</span>
						</div>
					</div>
					<div
						className="hbox vcenter tos-toggle"
						onClick={() => {
							window.open('https://carevalet.io/privacy.html', '_blank');
						}}
					>
						<div className="termslink">
							<span>Privacy Statement</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
