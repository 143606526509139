import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import './id-card-list.scss';
import { Button, Link, List, ListItem, Popover } from 'framework7-react';
import IdThumbnailList from './id-thumbnail-list/IdThumbnailList';
import ExpressAPI from '@/services/ExpressAPI';
import CanvasFactory from '@/factories/CanvasFactory';
import IdCardsEmpty from './id-cards-empty/IdCardsEmpty';
import NewCardPopover from './new-card-popover/NewCardPopover';
import { DIMENSIONS } from '@/services/CanvasEditorService';
import ImageCropper from '@/components/image-cropper/ImageCropper';
import FileDrop from '@/components/_EXPRESS/file-drop/FileDrop';
import IdList from './id-list/IdList';
import NewCardTemplate from './new-card-popover/NewCardTemplate';

//You can create multiple CanvasEditors as long as you link them to new CES's

@observer
export default class IDCardList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			view: 'thumbnails',
			loadedFrom: null,
			type: 'All',
			sort: 'Date',
			loading: false,
			searchInput: '',
			cards: [],
			newCard: null,
			side: 'all',
			backgroundImage: null,
			newCardFromTemplate: false
		});
		autoBind(this);
	}
	asyncLoad() {
		setTimeout(() => {
			this.loadCards();
		}, 0);
	}

	loadCards() {
		if (!this.data.loading) {
			this.data.cards = [];
			this.data.loading = true;
			this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
			ExpressAPI.listIdCards('all', true)
				.then((resp) => {
					this.data.loading = false;
					this.data.cards = resp;
				})
				.catch((e) => {
					this.data.loading = false;
					console.log('ERROR:: ', e);
				});
		}
	}

	filterAndSortCards() {
		let { cards, searchInput, sort } = this.data;
		searchInput = searchInput.toLowerCase();
		let filtered =
			searchInput.length > 0
				? cards.filter((card) => {
						let name = card.name.toLowerCase();
						if (searchInput.length === 1) {
							return name.search(searchInput) === 0;
						} else {
							return name.search(searchInput) > -1;
						}
				  })
				: cards;

		if (this.data.side !== 'all') {
			filtered = filtered.filter((card) => {
				return this.data.side === card.side;
			});
		}
		filtered = filtered.filter((c) => c !== null);
		let sorted = filtered;
		switch (sort) {
			case 'Date':
				sorted = filtered.slice().sort((a, b) => {
					return new Date(b?.lastUpdated) - new Date(a?.lastUpdated);
				});
				break;
			case 'Name':
				sorted = filtered.slice().sort((a, b) => {
					return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
				});
				break;
			case 'Side':
				sorted = filtered.slice().sort((a, b) => {
					return a.side.toLowerCase() === 'front' ? -1 : 1;
				});
				break;
			default:
				sorted = filtered;
		}

		return sorted;
	}

	handleSearch(e) {
		this.data.searchInput = e.target.value;
	}

	onDocumentSave() {
		this.loadCards();
	}

	handleEdit(document) {
		CanvasFactory.getService('id-cards').loadSavedEditor(document);
		expressStore.activeEditor = 'id-cards';
		expressStore.activeCard = document._id;
		CanvasFactory.getActiveService().setSaveCallback(this.onDocumentSave);
	}

	handleDelete(document) {
		this.$f7.dialog.confirm(
			`Are you sure you want to delete the "${document.name}"?`,
			'Please Double Check',
			() => {
				this.data.loading = true;

				ExpressAPI.deleteIdCard(document._id, false)
					.then((res) => {
						this.data.loading = false;
						this.loadCards();
					})
					.catch((err) => {
						this.data.loading = false;
						if (err.status === 424) {
							this.forceDelete(document);
						} else {
							showExpressError(this.$f7, err);
						}
					});
			},
			() => {}
		);
	}

	forceDelete(document) {
		this.$f7.dialog.confirm(
			`"${document.name}" is linked to a plan, deleting this document will also remove it from the plan.  Click ok if you wish to continue`,
			'Conflict!',
			() => {
				this.data.loading = true;

				ExpressAPI.deleteIdCard(document._id, true)
					.then((res) => {
						this.data.loading = false;
						this.loadCards();
					})
					.catch((err) => {
						this.data.loading = false;
						showExpressError(this.$f7, err);
					});
			},
			() => {}
		);
	}

	handleNewCard(data) {
		CanvasFactory.getService('id-cards').createNew(data);
		this.data.newCard = null;
		expressStore.activeEditor = 'id-cards';
		CanvasFactory.getActiveService().setSaveCallback(this.onDocumentSave);
	}

	handleNewCardFromTemplate(data) {
		CanvasFactory.getService('id-cards').loadSavedEditor(data);
		this.data.newCardFromTemplate = false;
		expressStore.activeEditor = 'id-cards';
		CanvasFactory.getActiveService().setSaveCallback(this.onDocumentSave);
		if (data.existingAsTemplate) {
			expressStore.activeCard = data._id;
		}
	}

	getFormConfig() {
		const dimensions = Object.fromEntries(Object.entries(DIMENSIONS).map(([k, v]) => [k, v.label]));
		return {
			documentName: {
				label: 'Card Name',
				type: 'text',
				placeholder: 'Enter name for card',
				validator: {
					type: 'length',
					value: 4
				}
			},
			row1: {
				documentSide: {
					label: 'Choose side',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-arrows-alt-h',
					listItems: { front: 'FRONT', back: 'BACK' }
				}
			}
		};
	}

	onFileDropError(e) {
		console.log(e);
	}

	onFileDropComplete(e) {
		let fileReader = new FileReader();

		fileReader.onloadend = (e) => {
			var image = new Image();
			image.src = e.target.result;
			this.data.backgroundImage = image;
		};
		fileReader.readAsDataURL(e);
	}

	getView() {
		const { loading } = this.data;
		const filteredCards = this.filterAndSortCards();
		if (this.data.view === 'list') {
			return <IdList loading={loading} cards={filteredCards} onEdit={this.handleEdit} onDelete={this.handleDelete} />;
		} else {
			return <IdThumbnailList loading={loading} cards={filteredCards} onEdit={this.handleEdit} onDelete={this.handleDelete} />;
		}
	}

	switchView(view) {
		this.data.view = view;
	}

	renderBody() {
		const { view, loading, cards, searchInput } = this.data;

		if (!loading && cards.length < 1) {
			return <IdCardsEmpty onNew={() => (this.data.newCard = { type: 'card' })} />;
		} else {
			return (
				<>
					<div className="hbox vcenter">
						<h1 className="text-4 mb-xl grow-1">ID Cards</h1>

						{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
						expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER ? null : (
							<Button fill small className="list-btn ios" popoverOpen=".new-card-type">
								<i className="fa fa-plus" />
								Create Card
							</Button>
						)}
					</div>
					<div className="hbox vcenter list-controls">
						<div className="left-controls hbox vcenter grow-1">
							<a className={`btn ${view === 'thumbnails' ? 'active' : ''}`} onClick={() => (this.data.view = 'thumbnails')}>
								<i className="fa fa-grip-horizontal"></i>
							</a>
							<a className={`btn ${view === 'list' ? 'active' : ''}`} onClick={() => (this.data.view = 'list')}>
								<i className="fa fa-bars"></i>
							</a>
							<div className="input-ctn hbox vcenter">
								<i className="fas fa-search"></i>
								<input placeholder="Search by name..." value={searchInput} onChange={this.handleSearch} />
							</div>
						</div>
						<div className="hbox vcenter right-controls">
							<Link popoverOpen=".side-sort" className="sort-btn">
								<div className="title">Side</div>
								<div className="selection hbox hcenter">
									<div className="selection-label grow-1">{this.data.side.toUpperCase()}</div>
									<i className="fa fa-angle-down"></i>
								</div>
							</Link>

							<Link popoverOpen=".id-sort" className="sort-btn">
								<div className="title">Sort</div>
								<div className="selection hbox hcenter">
									<div className="selection-label grow-1">By {this.data.sort}</div>
									<i className="fa fa-angle-down"></i>
								</div>
							</Link>
						</div>
					</div>
					{this.getView()}
					<Popover className="new-card-type">
						<List className="new-card-type-list">
							<ListItem
								popoverClose
								link="#"
								key="custom-card-select"
								className="new-card-item custom-card-select"
								onClick={(e) => (this.data.newCard = { type: 'card' })}
							>
								<div className="description">
									<i className="fa fa-credit-card-blank"></i>Blank
								</div>
							</ListItem>
							<ListItem
								popoverClose
								link="#"
								key="template-card-select"
								className="new-card-item template-card-select"
								onClick={(e) => (this.data.newCardFromTemplate = true)}
							>
								<div className="description">
									<i className="fa fa-id-card"></i>Template
								</div>
							</ListItem>
						</List>
					</Popover>
					<Popover className="id-sort">
						<List className="tools-list">
							<ListItem popoverClose link="#" key="date-filter1" className="tool-item" onClick={() => (this.data.sort = 'Date')}>
								<div className="description">Date</div>
							</ListItem>
							<ListItem popoverClose link="#" key="name-filter1" className="sort-item" onClick={() => (this.data.sort = 'Name')}>
								<div className="description">Name</div>
							</ListItem>
							<ListItem popoverClose link="#" key="side-filter1" className="sort-item" onClick={() => (this.data.sort = 'Side')}>
								<div className="description">Side</div>
							</ListItem>
						</List>
					</Popover>
					<Popover className="side-sort">
						<List className="tools-list">
							<ListItem popoverClose link="#" key="all_filter1" className="sort-item" onClick={() => (this.data.side = 'all')}>
								<div className="description">All</div>
							</ListItem>
							<ListItem popoverClose link="#" key="front-filter1" className="tool-item" onClick={() => (this.data.side = 'front')}>
								<div className="description">Front</div>
							</ListItem>
							<ListItem popoverClose link="#" key="backfilter-1" className="sort-item" onClick={() => (this.data.side = 'back')}>
								<div className="description">Back</div>
							</ListItem>
						</List>
					</Popover>
				</>
			);
		}
	}

	imageCropper() {
		return (
			<>
				{!this.data.backgroundImage && <FileDrop onError={this.onFileDropError} onComplete={this.onFileDropComplete} multi={false} />}
				{this.data.backgroundImage && (
					<div style={{ width: '100%', height: '250px', position: 'relative' }}>
						<ImageCropper image={this.data.backgroundImage} documentWidth={324} documentHeight={204} aspect={27 / 17} />
					</div>
				)}
			</>
		);
	}

	render() {
		const { loadedFrom } = this.data;
		const { currentOrg } = expressStore;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoad();
		}

		return (
			<div className="express-idcard-page">
				{this.renderBody()}
				<NewCardPopover
					opened={this.data.newCard}
					type={this.data.newCard?.type}
					onSubmit={this.handleNewCard}
					onClose={() => {
						this.data.newCard = null;
					}}
				/>
				<NewCardTemplate
					opened={this.data.newCardFromTemplate}
					onSubmit={this.handleNewCardFromTemplate}
					onClose={() => (this.data.newCardFromTemplate = false)}
				/>
			</div>
		);
	}
}
