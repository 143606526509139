import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import './documents-home.scss';
import DocumentList from './document-list/DocumentList';
import ExpressAPI from '@/services/ExpressAPI';
import DocumentDetails from './document-details/DocumentDetails';
import NewDocumentPopup from './new-document-popup/NewDocumentPopup';
import noDocuments from '@/assets/no-documents.svg';
import expressStore from '@/stores/ExpressStore';
@observer
export default class DocumentsHome extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			documentsLoading: false,
			newDocument: false,
			documents: [],
			document: null,
			loadedFrom: ''
		});
		autoBind(this);
	}

	componentDidMount() {
		this.fetchDocuments();
	}

	fetchDocuments() {
		this.data.documentsLoading = true;
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		ExpressAPI.getDocuments()
			.then((res) => {
				this.data.document = null;
				this.data.documents = res;
				this.data.documentsLoading = false;
			})
			.catch((e) => {
				showExpressError(this.$f7, e);
				this.data.document = null;
				this.data.documents = [];
				this.data.documentsLoading = false;
			});
	}

	asyncLoad() {
		setTimeout(() => {
			this.fetchDocuments();
		}, 0);
	}

	handleDocumentClick(document) {
		this.data.document = document;
	}

	handleDocumentDetailsBack() {
		this.data.document = null;
	}

	handleNewDocumentClose() {
		this.fetchDocuments();
		this.data.newDocument = false;
	}

	handleDocumentDelete(document) {
		this.$f7.dialog.confirm(
			`Are you sure you want to delete the "${document.name}"?`,
			'Please Double Check',
			() => {
				this.data.documentsLoading = true;

				ExpressAPI.deleteDocument(document._id, false)
					.then((res) => {
						this.data.documentsLoading = false;
						this.data.documents = res;
					})
					.catch((err) => {
						this.data.documentsLoading = false;
						if (err.status === 424) {
							this.forceDeleteDocument(document);
						} else {
							showExpressError(this.$f7, err);
						}
					});
			},
			() => {}
		);
	}

	forceDeleteDocument(document) {
		this.$f7.dialog.confirm(
			`"${document.name}" is linked to a plan, deleting this document will also remove it from the plan.  Click ok if you wish to continue`,
			'Conflict!',
			() => {
				this.data.documentsLoading = true;

				ExpressAPI.deleteDocument(document._id, true)
					.then((res) => {
						this.data.documentsLoading = false;
						this.data.documents = res;
					})
					.catch((err) => {
						this.data.documentsLoading = false;
						showExpressError(this.$f7, err);
					});
			},
			() => {}
		);
	}

	renderBody() {
		const { documentsLoading, documents, document } = this.data;
		if (!documentsLoading && !documents.length) {
			return <NoDocuments onNewDocumentClick={() => (this.data.newDocument = true)} />;
		} else if (document) {
			return (
				<DocumentDetails
					onDocumentBack={this.handleDocumentDetailsBack}
					document={document}
					onDocumentDelete={(document) => this.handleDocumentDelete(document)}
				/>
			);
		} else {
			return (
				<DocumentList
					onDocumentClick={this.handleDocumentClick}
					documentsLoading={documentsLoading}
					documents={documents}
					fetchDocuments={this.fetchDocuments}
					onNewDocumentClose={this.handleNewDocumentClose}
					showNewDocument={this.data.newDocument}
					onNewDocumentClick={() => (this.data.newDocument = true)}
					onDocumentDelete={(document) => this.handleDocumentDelete(document)}
				/>
			);
		}
	}

	render() {
		const { loadedFrom } = this.data;
		const { currentOrg } = expressStore;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoad();
		}
		return (
			<div className="documents-page">
				<NewDocumentPopup opened={this.data.newDocument} onClose={this.handleNewDocumentClose} />
				{this.renderBody()}
			</div>
		);
	}
}

const NoDocuments = (props) => {
	const { onNewDocumentClick } = props;
	return (
		<div className="vbox vcenter hcenter card-body no-documents">
			<img src={noDocuments} />
			<h1>No documents!</h1>
			<p>Documents added here can later be added to different plans.</p>
			<a onClick={onNewDocumentClick} className="btn round-btn animated pulse infinite">
				Add your first document
			</a>
		</div>
	);
};
