import React, { Component } from 'react';

export default class ButtonSegment extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { active, onClick } = this.props;
		return (
			<div className={`button-segment ${active ? 'active' : ''}`} onClick={onClick}>
				{this.props.children}
			</div>
		);
	}
}
