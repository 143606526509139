import React, { Component } from 'react';
import { observer } from '../../utils/State';
import './credit-card.scss';
import _ from 'lodash';
import { autoBind } from '../../utils/GeneralUtils';
import visa from '@/assets/visa.svg';
import amex from '@/assets/amex.svg';
import mastercard from '@/assets/mastercard.svg';
import discover from '@/assets/discover.svg';
@observer
export default class CreditCard extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getBrandLogo(brand) {
		switch (brand) {
			case 'visa':
				return <img src={visa} />;
			case 'mastercard':
				return <img src={mastercard} />;
			case 'amex':
				return <img src={amex} />;
			case 'discover':
				return <img src={discover} />;
			default:
				return <div style={{ background: '#fff' }} />;
		}
	}

	render() {
		let { name, expDate, brand, lastFour, onDelete } = this.props;

		return (
			<div className="credit-card">
				<div className={'card-box ' + brand}>
					<div className="card-top">
						<div className="card-brand">{this.getBrandLogo(brand)}</div>
						{onDelete && (
							<div className="remove" onClick={onDelete}>
								<i className="fa fa-trash"></i>
							</div>
						)}
					</div>

					<div className="card-number">
						<div className="card-column masked">••••</div>
						<div className="card-column masked">••••</div>
						<div className="card-column masked">••••</div>
						<div className="card-column">{lastFour}</div>
					</div>
					<div className="card-details">
						<div className="card-detail">
							<div className="card-detail-label">Card Holder</div>
							<div className="card-detail-value">{name}</div>
						</div>
						<div className="card-detail">
							<div className="card-detail-label">Expire</div>
							<div className="card-detail-value">{expDate}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
