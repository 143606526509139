import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import './canvas-templates.scss';
import ExpressAPI from '@/services/ExpressAPI';
import noDocuments from '@/assets/no-documents.svg';
import { Button, Link, List, ListItem, Popover } from 'framework7-react';
import NewCanvasTemplate from './new-canvas-template/NewCanvasTemplate';
import CanvasFactory from '@/factories/CanvasFactory';
import expressStore from '@/stores/ExpressStore';
import CanvasTemplateList from './canvas-template-list/CanvasTemplateList';
@observer
export default class CanvasTemplates extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			newTemplate: false,
			templateData: {
				name: '',
				type: 'other',
				width: 0,
				height: 0,
				tags: ''
			},
			templates: [],
			template: null,
			controlValues: {
				sort: 'date',
				search: '',
				type: 'all'
			}
		});
		autoBind(this);
	}

	componentDidMount() {
		this.fetchTemplates();
	}

	handleNewTemplate(e) {
		this.data.newTemplate = true;
	}

	handleSaveTemplate(formData, documentData) {
		documentData.type = this.data.templateData.type;
		documentData.tags = this.data.templateData.tags;
		formData.append('template', JSON.stringify(documentData));
		if (documentData.documentId) {
			ExpressAPI.updateTemplate(formData, documentData.documentId)
				.then((res) => {
					console.log(res);
				})
				.catch((e) => {
					showExpressError(this.$f7, e);
				});
		} else {
			ExpressAPI.saveTemplate(formData)
				.then((res) => {
					console.log(res);
				})
				.catch((e) => {
					showExpressError(this.$f7, e);
				});
		}
	}

	handleEdit(template) {
		this.data.templateData.type = template.type;
		this.data.templateData.tags = template.tags.join(',');
		CanvasFactory.getService('templates').loadSavedEditor(template);
		expressStore.activeEditor = 'templates';
		CanvasFactory.getActiveService().setExternalSave(this.handleSaveTemplate);
	}

	handleNewTemplateSubmit(data) {
		CanvasFactory.getService('templates').createNewTemplate(this.data.templateData);
		this.data.newTemplate = false;
		expressStore.activeEditor = 'templates';
		CanvasFactory.getActiveService().setExternalSave(this.handleSaveTemplate);
	}

	handleDelete(template) {
		this.$f7.dialog.confirm(
			`Are you sure you wish to delete "${template.name}"? Click ok if you wish to continue`,
			'Confirm',
			() => {
				this.data.loading = true;

				ExpressAPI.deleteTemplate(template._id)
					.then((res) => {
						this.data.loading = false;
						this.fetchTemplates();
					})
					.catch((err) => {
						this.data.loading = false;
						showExpressError(this.$f7, err);
					});
			},
			() => {}
		);
	}

	fetchTemplates(queryParams) {
		this.data.loading = true;
		ExpressAPI.getTemplates(queryParams)
			.then((res) => {
				this.data.templates = res;
				this.data.loading = false;
			})
			.catch((e) => {
				showExpressError(this.$f7, e);
				this.data.loading = false;
			});
	}

	renderBody() {
		const { loading, templates } = this.data;
		if (!loading && templates.length == 0) {
			return <NoTemplates onNewTemplate={this.handleNewTemplate} />;
		} else {
			return (
				<>
					<TemplatesHeader onNewTemplate={this.handleNewTemplate}>
						<TemplateControls controlValues={this.data.controlValues} />
						<CanvasTemplateList loading={loading} templates={templates} onEdit={this.handleEdit} onDelete={this.handleDelete} />
					</TemplatesHeader>
				</>
			);
		}
	}

	render() {
		return (
			<div className="canvas-templates-page">
				{this.renderBody()}
				<NewCanvasTemplate
					opened={this.data.newTemplate}
					onClose={() => (this.data.newTemplate = false)}
					onSubmit={this.handleNewTemplateSubmit}
					templateData={this.data.templateData}
				/>
			</div>
		);
	}
}

const NoTemplates = (props) => {
	const { onNewTemplate } = props;
	return (
		<div className="vbox vcenter hcenter card-body no-templates">
			<img src={noDocuments} />
			<h1>No templates!</h1>
			<p>There have been no templates created yet. You can be the first!</p>
			<a onClick={onNewTemplate} className="btn round-btn animated pulse infinite">
				Add a template
			</a>
		</div>
	);
};

const TemplatesHeader = observer((props) => {
	const { onNewTemplate, children } = props;
	return (
		<div className="vbox templates-header vcenter">
			<div className="hbox vcenter header-top">
				<h1 className="text-4 mb-xl grow-1">Canvas Templates</h1>
				<Button fill small className="list-btn ios" onClick={onNewTemplate}>
					<i className="fa fa-plus" />
					New template
				</Button>
			</div>
			{children}
		</div>
	);
});

const TemplateControls = (props) => {
	const {
		onSort,
		onFilter,
		onSearch,
		controlValues: { sort, search, type }
	} = props;
	return (
		<div className="hbox vcenter list-controls">
			<div className="left-controls hbox vcenter grow-1">
				<div className="input-ctn hbox vcenter">
					<i className="fas fa-search"></i>
					<input placeholder="Search by name..." value={search} onChange={onSearch} />
				</div>
			</div>
			<div className="hbox vcenter right-controls">
				<Link popoverOpen=".template-type-popover" className="sort-btn">
					<div className="title">Type</div>
					<div className="selection hbox hcenter">
						<div className="selection-label grow-1">{type}</div>
						<i className="fa fa-angle-down"></i>
					</div>
				</Link>

				<Link popoverOpen=".sort-templates-popover" className="sort-btn">
					<div className="title">Sort</div>
					<div className="selection hbox hcenter">
						<div className="selection-label grow-1">By {sort}</div>
						<i className="fa fa-angle-down"></i>
					</div>
				</Link>
			</div>
			<Popover className="sort-templates-popover">
				<List className="tools-list">
					<ListItem popoverClose link="#" key="document-date-filter1" className="tool-item" onClick={() => onSort('date')}>
						<div className="description">Date</div>
					</ListItem>
					<ListItem popoverClose link="#" key="document-name-filter1" className="sort-item" onClick={() => onSort('name')}>
						<div className="description">Name</div>
					</ListItem>
					<ListItem popoverClose link="#" key="document-type-filter1" className="sort-item" onClick={() => onSort('type')}>
						<div className="description">Type</div>
					</ListItem>
				</List>
			</Popover>
			<Popover className="template-type-popover">
				<List className="tools-list">
					<ListItem popoverClose link="#" key="all__template_filter1" className="sort-item" onClick={() => onFilter('all')}>
						<div className="description">All</div>
					</ListItem>
					<ListItem popoverClose link="#" key="id-card-filter" className="tool-item" onClick={() => onFilter('id-card')}>
						<div className="description">ID Card</div>
					</ListItem>
					<ListItem popoverClose link="#" key="other_template-1" className="sort-item" onClick={() => onFilter('other')}>
						<div className="description">Other</div>
					</ListItem>
				</List>
			</Popover>
		</div>
	);
};
