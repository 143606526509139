import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import Table from '@/components/_EXPRESS/table/Table';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import { Progressbar, Preloader, Button } from 'framework7-react';
import { format } from 'date-fns';
import './fulfillment-admin.scss';
import BasicInput from '@/components/basic-input/BasicInput';

@observer
export default class FulfillmentAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentOrder: null,
			orders: [],
			steps: [],
			editData: {},
			loading: true
		});
		autoBind(this);
	}

	async componentDidMount() {
		const orders = await this.getOrders();
		this.data.orders = orders;
	}

	async getOrders() {
		this.$f7.dialog.preloader();
		try {
			const orders = await ExpressAPI.getAllAdminFulfillments();
			this.$f7.dialog.close();
			return orders;
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error loading orders');
		}
	}

	resetSteps() {
		const orderData = _.cloneDeep(this.data.currentOrder);
		const steps = [
			{
				title: 'Approval',
				info: 'Order Must be approved before it can be processed. <%= approvalDate %>',
				complete: !!orderData.approvalDate && orderData.status !== 'awaiting-review'
			},
			{
				title: 'Generating',
				info: 'We generate each ordered card to send for printing. <%= remainingToGenerate %>',
				complete: orderData.status === 'fulfilled' || orderData.status === 'printed' || orderData.status === 'shipped'
			},
			{
				title: `Printing`,
				info: 'Vendor has printed the cards and is preparing them to ship to <%= shippingAddress %>.',
				complete: orderData.status === 'printed' || orderData.status === 'shipped'
			},
			{
				title: 'Errors',
				info: 'The vendor was unable to process all requested cards, please resubmit after resolving issues. <%= errorQty %>',
				complete: !orderData.errors || orderData.errors < 1
			},
			{
				title: 'Shipped',
				info: 'The printed cards have shipped. <%= shippedDate %>',
				complete: orderData.status === 'shipped',
				onClick: this.handleShippingClick,
				showForm: false,
				formConfig: {
					type: 'text',
					onChange: this.handleShipDateChange,
					label: 'Input Shipping Date (mm/dd/yyyy format)',
					valueKey: 'shippedDate',
					onSubmit: this.submitShippingDate
				}
			}
		];
		this.data.steps = steps;
	}

	async getOrderDetails() {
		this.resetSteps();
		const orderData = _.cloneDeep(this.data.currentOrder);
		this.data.loading = true;
		this.data.editData = {
			shippingDate: orderData.shippedDate
		};
		const orderNotShipped = 'This order has not been shipped.';
		const stepInfo = _.cloneDeep(this.data.steps);
		let approvalDate = orderData.approvalDate
			? `${orderData.count} Card(s) Approved On: ${format(
					new Date(new Date(orderData.approvalDate)).setUTCHours(5, 0, 0, 0),
					'MM/dd/yyyy'
			  )}`
			: 'This order has not been approved.';
		if (orderData.status === 'awaiting-review') {
			approvalDate = `${approvalDate}.  This order was system generated due to errors processing the initial order and REQUIRES system admin review.  After review, and if deemed appropriate, the status for this shipment can be changed to 'awaiting-fulfillment' to kick off processing, or 'canceled' to prevent processing.`;
		}
		const shippedDate = orderData.shippedDate
			? `${orderData.count - orderData.errors} Card(s) Shipped On: ${format(
					new Date(new Date(orderData.shippedDate)).setUTCHours(5, 0, 0, 0),
					'MM/dd/yyyy'
			  )}`
			: orderNotShipped;
		const stepData = {
			approvalDate: approvalDate,
			remainingToGenerate: orderData.remainingToGenerate || 'N/A',
			errorQty: `${orderData.errors} Requested Cards could not be processed` || 'There are no reported errors for this order',
			shippedDate: shippedDate,
			shippingAddress: orderData.shippingLine
		};
		stepInfo.map((step, stepIndex) => {
			const infoTemplate = _.template(step.info);
			const text = step.title === 'Shipped' && shippedDate === orderNotShipped ? orderNotShipped : infoTemplate(stepData);
			this.data.steps[stepIndex].info = text;
		});
		this.data.loading = false;
	}

	handleRequestClick(id) {
		const orderData = _.find(this.data.orders, { batchId: id });
		this.data.currentOrder = _.cloneDeep(orderData);
		this.getOrderDetails();
	}

	closeDetails() {
		this.data.currentOrder = null;
	}

	handleShippingClick(evt) {
		const stepIndex = evt.currentTarget.getAttribute('data-id');
		const stepData = this.data.steps[stepIndex];
		if (!this.data.steps[stepIndex].showForm) {
			const currentVal = this.data.currentOrder.shippedDate
				? format(new Date(this.data.currentOrder.shippedDate), 'MM/dd/yyyy')
				: null;
			this.data.steps[stepIndex].showForm = true;
			this.data.editData = { shippedDate: currentVal };
		}
	}

	handleShipDateChange(evt) {
		const val = evt.currentTarget.value;
		this.data.editData.shippedDate = val;
	}

	async submitShippingDate(evt) {
		try {
			this.data.loading = true;
			const val = this.data.editData.shippedDate;
			await ExpressAPI.updateFulfillmentShipDate(val, this.data.currentOrder.batchId, this.data.currentOrder.orgId);
			this.data.currentOrder = null;
			this.data.loading = false;
			const orders = await this.getOrders();
			this.data.orders = orders;
			this.data.steps = _.cloneDeep(this.data.steps).map((x) => {
				x.showForm = false;
				return x;
			});
		} catch (err) {
			this.$f7.dialog.alert('Invalid Date, Please try again');
			this.data.loading = false;
		}
	}

	render() {
		const loading = false;
		const showOrders = !this.data.currentOrder;
		const showOrderDetails = !!this.data.currentOrder;
		const hasOrders = this.data.orders && this.data.orders.length > 0;
		const orders = this.data.orders || [];
		const steps = this.data.steps || [];
		const completedSteps = steps.filter((x) => !!x.complete);
		const amountComplete = Math.ceil((completedSteps.length / steps.length) * 100) || 0;
		const tableHeaders = [
			{ text: 'Group Name', classList: 'ellipse group-name', canSearch: true },
			{ text: 'Org Name', classList: 'ellipse org-name', canSearch: true },
			{ text: 'Ship Date', canSearch: true, classList: 'small' },
			{ text: 'Type', canSearch: true, classList: 'small' },
			{ text: 'Shipping Address', canSearch: true, classList: 'ellipse' },
			{ text: 'Batch ID', canSearch: true },
			{ text: 'Count', classList: 'right small' },
			{ text: 'Errors', classList: 'right small' }
		];
		const tableRows = orders.map((order) => {
			const shippingDate = order.shippedDate
				? format(new Date(new Date(order.shippedDate)).setUTCHours(5, 0, 0, 0), 'MM/dd/yyyy')
				: 'Not Shipped';
			return {
				id: order.batchId,
				onClick: () => this.handleRequestClick(order.batchId),
				items: [
					{
						text: order.groupName || 'Unknown',
						classList: `capitalize group-name ellipse ${order.status === 'awaiting-review' ? 'red' : ''}`
					},
					{ text: order.orgName || 'Unknown', classList: 'ellipse capitalize org-name' },
					{ text: shippingDate, classList: 'small' },
					{ text: order.type, classList: 'capitalize small' },
					{ text: order.shippingLine, classList: 'ellipse' },
					{ text: order.batchId },
					{ text: order.count, classList: 'right small' },
					{ text: order.errors, classList: `right small ${order.errors > 0 ? 'red' : ''}` }
				]
			};
		});
		return (
			<div className="admin-group-container">
				{showOrderDetails && (
					<div className="group-admin-details">
						<Breadcrumb
							pageName="admin-groups"
							config={{
								0: { name: 'All Groups', icon: 'fad fa-files-medical', onClick: this.closeDetails },
								1: {
									name: `${_.get(this.data, 'currentOrder.orgName') || 'Unknown Org'} - ${
										_.get(this.data, 'currentOrder.groupName') || 'Unknown Group'
									}`,
									onClick: () => {}
								}
							}}
							currentKey={1}
						/>
						{this.data.loading && (
							<div className="loading-ctn">
								<Preloader size={32} color="blue" />
							</div>
						)}
						{!this.data.loading && (
							<div className="group-overview-card">
								<Progressbar progress={amountComplete} id="group-inline-progressbar" />
								<div className="step-list">
									<h2>Batch #: {this.data.currentOrder.batchId}</h2>
									{steps.map((step, stepIndex) => {
										return (
											<div
												className={`step hbox vcenter ${step.complete ? 'completed' : ''} ${!!step.onClick ? 'has-action' : ''}`}
												key={`group-admin-steps-${stepIndex}`}
												data-id={stepIndex}
												onClick={step.onClick}
											>
												<div className="status">
													{step.complete && <i className="fas fa-check animated bounceIn" />}
													{!step.complete && <i className="fas fa-exclamation animated bounceIn" />}
												</div>
												<div className="step-text">
													<div>
														<strong>{step.title}</strong>
													</div>
													<div className="step-info">{step.info}</div>
													{step.showForm && (
														<div className="step-form">
															<BasicInput
																type={step.formConfig.type}
																label={step.formConfig.label}
																onChange={step.formConfig.onChange}
																value={this.data.editData[step.formConfig.valueKey]}
															/>
															<Button large round fill onClick={step.formConfig.onSubmit}>
																Save
															</Button>
														</div>
													)}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				)}
				{showOrders && (
					<div className="express-groups-list">
						<h2 className="grow-1">Fulfillment List</h2>
						{!hasOrders && !loading && (
							<div className="empty-message vbox vcenter hcenter">
								<div className="icon-box hbox vcenter hcenter">
									<i className="fad fa-users-class" />
								</div>
								<h3>There are no orders created yet. Check back later!</h3>
							</div>
						)}
						{hasOrders && <Table id="fulfillment-admin-table" headers={tableHeaders} rows={tableRows} />}
					</div>
				)}
			</div>
		);
	}
}
