import CloudAPI from './CloudAPI';
import userStore from '@/stores/UserStore';
import _ from 'lodash';

class UserBillingService {
	async addPaymentMethod(paymentMethod) {
		//TODO Handle failed card payments
		let request = {
			paymentMethodId: paymentMethod.id
		};
		try {
			return await CloudAPI.addUserPaymentMethod(request);
		} catch (e) {
			return e;
		}
	}

	async deletePaymentMethod(paymentMethodId) {
		try {
			userStore.paymentsLoading = true;
			let response = await CloudAPI.deleteUserPaymentMethod(paymentMethodId);
			userStore.paymentMethods = response;
			userStore.paymentsLoading = false;
			return response;
		} catch (e) {
			console.log(e);
			userStore.paymentsLoading = false;
			throw e;
		}
	}

	async retrievePaymentMethods() {
		userStore.paymentsLoading = true;
		try {
			let paymentMethods = await CloudAPI.getUserPaymentMethods();
			userStore.paymentMethods = paymentMethods;
			userStore.paymentsLoading = false;
			return paymentMethods;
		} catch (e) {
			console.log('ERROR LOADING PAYMENT METHODS');
			console.log(e);
			userStore.paymentsLoading = false;
			return [];
		}
	}

	async getPaymentIntent(amount, description, customer) {
		let intentRequest = {
			amount: amount,
			currency: 'usd',
			customer: customer,
			description: description
		};

		return await CloudAPI.getPaymentIntent(intentRequest);
	}

	async getTransactionHistory() {
		try {
			let payments = await CloudAPI.getUserTransactionHistory();
			return payments;
		} catch (e) {
			console.log('ERROR OCCURED WHILE RETREIVING PAYMENT HISOTRY');
			console.log(e);
			return [];
		}
	}

	async getTransaction(id) {
		let charge = await CloudAPI.getTransaction(id);
		return charge;
	}
	async creditUserToken(count, transactionId) {
		let addTokenRequest = {};
		addTokenRequest.count = count;
		addTokenRequest.transactionId = transactionId;
		try {
			let newUser = await CloudAPI.creditUserToken(addTokenRequest);
			_.set(userStore, 'userData.user.tokenCount', newUser.tokenCount);
		} catch (e) {
			//TODO handle errors
			console.log(e);
		}
	}

	async getUserCallHistory() {
		return await CloudAPI.fetchUserCallHistory();
	}
}

export default new UserBillingService();
