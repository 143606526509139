import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import AnalyticsService from '@/services/AnalyticsService';
import GradiantStack from '@/components/gradiant-stack/GradiantStack';
import VerifySVG from '@/assets/verify2.svg';
import BasicInput from '@/components/basic-input/BasicInput';
import CloudAPI from '@/services/CloudAPI';
import './verify-info.scss';

const analytics = new AnalyticsService();

@observer
export default class VerifyInfo extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			firstName: _.get(props, 'info.firstName'),
			lastName: _.get(props, 'info.lastName'),
			email: _.get(props, 'info.email'),
			phone: _.get(props, 'info.phone'),
			memberId: '',
			emailValid: true
		});
		this.text = getCmpText('EmployerSignup', {
			backText: 'Back',
			verifyInfoTitle: 'Verify Information',
			stepText: 'Step <%= step.current %> of <%= step.total %>',
			memberIdLabel: 'Member ID from Medical ID Card:',
			memberIdPlaceholder: 'Input the member ID from your ID Card',
			firstNameLabel: 'First Name',
			firstNamePlaceholder: 'John',
			lastNameLabel: 'Last Name',
			lastNamePlaceholder: 'Doe',
			emailLabel: 'Email',
			emailPlaceholder: 'john.doe@test.com',
			phoneLabel: 'Mobile Number',
			phonePlaceholder: '5552223333',
			verifyInfoButton: 'Looks good!',
			duplicateEmail:
				'This email address is already associated with an account, please contact CareValet at 866-549-9533 if further help is needed.',
			errorMessage: 'Unable to find account, please try again.'
		});
		autoBind(this);
	}

	getCleanPhoneInput() {
		const phone = _.get(this.data, 'phone', '');
		const cleanPhone = phone.replace(/\D/g, '');
		return cleanPhone;
	}

	isDataValid() {
		let existanceCheck = ['firstName', 'lastName', 'email', 'phone'];
		let valid = true;
		const phone = this.getCleanPhoneInput();
		_.forEach(existanceCheck, (field) => {
			if (_.get(this.data, field, '').length <= 0) {
				valid = valid && false;
			}
			if (field === 'email') {
				const isEmailValid = this.data.emailValid;
				valid = valid && isEmailValid;
			}
			if (field === 'phone' && phone.length !== 10) {
				valid = valid && false;
			}
		});
		const hasUnkownMemberId = _.get(this, 'props.info.memberId', '').toLowerCase().indexOf('unknown') >= 0;
		if (hasUnkownMemberId && _.get(this, 'data.memberId', '').length <= 0) {
			valid = false;
		}
		return valid;
	}

	onBack() {
		this.props.onBack('verifyInfo');
	}

	async onNext() {
		const data = _.cloneDeep(this.data);
		const isValid = this.isDataValid();
		data.phone = this.getCleanPhoneInput();
		this.$f7.dialog.preloader();
		if (isValid) {
			try {
				const isValidAccount = await CloudAPI.validateNewAccount(data);
				this.$f7.dialog.close();
				if (isValidAccount) {
					analytics.trackWithData('Sign up', { step: 'verify account', status: 'valid account' });
					this.props.onNext('verifyInfo', this.data);
				} else {
					this.$f7.dialog.alert(this.text.duplicateEmail);
					analytics.trackWithData('Sign up', { step: 'verify account', status: 'duplicate email' });
				}
			} catch (err) {
				console.log('error validating account: ', err);
				this.$f7.dialog.close();
				this.$f7.dialog.alert(this.text.errorMessage);
				analytics.trackWithData('Sign up', { step: 'verify account', status: 'API error' });
			}
		} else {
			this.$f7.dialog.close();
		}
	}

	componentDidMount() {
		this.isDataValid();
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		this.data[target.name] = target.value;
	}

	handleEmailCheck(isValid) {
		this.data.emailValid = isValid;
	}

	render() {
		const isValid = this.isDataValid();
		const stepTextTemplate = _.template(this.text.stepText);
		const stepDisplayText = stepTextTemplate(_.cloneDeep(this.props));
		const hasUnkownMemberId = _.get(this, 'props.info.memberId', '').toLowerCase().indexOf('unknown') >= 0;
		return (
			<Fragment>
				<GradiantStack stack="2" animate={false} id="employer-verify-info" />
				<div className="employer-verify-info-page y-scroll">
					<div className="header-ctn hbox vcenter">
						<div className="back hbox vcenter animated fadeInLeft" onClick={this.onBack}>
							<i className="fal fa-chevron-left"></i>
							{this.text.backText}
						</div>
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={VerifySVG} className="herosvg" />
						<h1>{this.text.verifyInfoTitle}</h1>
						<div className="step-text">{stepDisplayText}</div>
						<div className="form-ctn">
							{hasUnkownMemberId && (
								<BasicInput
									onChange={this.onInputChange}
									label={this.text.memberIdLabel}
									value={this.data.memberId}
									type="text"
									minlength={5}
									placeholder={this.text.memberIdPlaceholder}
									name="memberId"
								/>
							)}
							<BasicInput
								onChange={this.onInputChange}
								label={this.text.firstNameLabel}
								value={this.data.firstName}
								type="text"
								placeholder={this.text.firstNamePlaceholder}
								name="firstName"
							/>
							<BasicInput
								onChange={this.onInputChange}
								label={this.text.lastNameLabel}
								value={this.data.lastName}
								type="text"
								placeholder={this.text.lastNamePlaceholder}
								name="lastName"
							/>
							<BasicInput
								onChange={this.onInputChange}
								label={this.text.emailLabel}
								type="email"
								value={this.data.email}
								validate
								placeholder={this.text.emailPlaceholder}
								name="email"
								id="emailInput"
								onValidate={this.handleEmailCheck}
							/>
							<BasicInput
								onChange={this.onInputChange}
								label={this.text.phoneLabel}
								value={this.data.phone}
								type="number"
								placeholder={this.text.phonePlaceholder}
								name="phone"
							/>
						</div>
						<Button fill large className="round-btn blue" onClick={this.onNext} disabled={!isValid}>
							{this.text.verifyInfoButton} <i className="far fa-long-arrow-right"></i>
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}
