import React, { Component } from 'react';
import _ from 'lodash';
import { Progressbar, Button, Link, Panel, Toggle } from 'framework7-react';
import GlobalHeader from '../../components/global-header/GlobalHeader';
import LanguageSelection from '../../components/language-selection/LanguageSelection';
import userStore from '../../stores/UserStore';
import appStore from '../../stores/AppStore';
import { getCmpText, getLocalizedDate } from '../../utils/Localization';
import { autoBind, formatDOB } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import MemberDocumentViewer from '../../components/member-document-viewer/MemberDocumentViewer';
import FeedbackForm from '../../components/feedback-form/FeedbackForm';
import StorageService from '../../services/StorageService';
import { parse } from 'date-fns';
import UserService from '../../services/UserService';
import ConfigService from '../../services/ConfigService';
import CloudAPI from '../../services/CloudAPI';
import EditProfile from './edit-profile/EditProfile';
import BillingPopup from '../../components/billing-popup/BillingPopup';
import BiometricsService from '../../services/BiometricsService';
import CareCoin from '../../assets/CareCoin';
import CallLogPopup from '@/components/call-log-popup/CallLogPopup';
import Support from '@/components/support/Support';
import TokenActivityPopup from '@/components/token-activity-popup/TokenActivityPopup';
import './profile.scss';
import WalletPopup from '@/components/wallet-popup/WalletPopup';

const storageService = new StorageService();

@observer
export default class Profile extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showGallery: false,
			documentType: null,
			showDependentPanel: false,
			selectedDependent: {},
			showFeedbackForm: false,
			hasBiometrics: false,
			editOpened: false,
			billingOpen: false,
			callLogOpen: false,
			tokenActivityOpen: false,
			enrolledInBiometrics: false,
			canEnrollInBiometrics: false,
			walletOpen: false,
			bioType: 'face'
		});
		this.phoneNumber = '866-549-9533';
		this.text = getCmpText('UserProfile', {
			walletText: 'Wallet',
			signout: 'Sign Out',
			addressLabel: 'Address:',
			dependentsHeader: 'Dependents',
			viewIDCard: 'View ID Card',
			viewSOB: 'View Statement of Benefits',
			dependentDetailTitle: 'Dependent Details',
			closeFeedbackFormBtn: 'Close',
			settingsHeader: 'Settings',
			feedbackCta: 'Give Feedback',
			enableBiometricsLabel: 'Enable device <%= bioType %> Login',
			enableBiometricsConfirmation:
				'You must sign back in to complete enrollment',
			disableBiometricsLabel: 'Disable device <%= bioType %> Login',
			disableBiometricsConfirmation: 'Success',
			biometricsFaceLabel: 'FaceID',
			biometricsFingerprintLabel: 'Fingerprint',
			biometricsError:
				'There was an error completing your request, please try again later',
			editText: 'Edit Profile',
			billingInfo: 'Update Billing Information',
			callLog: 'Call Log',
			tokenText: 'Care Credits',
			balanceText: 'Balance Remaining',
			adaOption: 'Enable Increased Accessibility',
			memberId: 'Member ID:',
			viewPrivacyPolicy: 'View Privacy Policy',
			installPwa: 'Install'
		});
		autoBind(this);
	}

	async componentDidMount() {
		const isApp = appStore.isApp();
		try {
			const bioEnrolled =
				await BiometricsService.checkBiometricsEnrollmentStatus();
			const bioType = await BiometricsService.getBiometricsType();
			if (bioEnrolled) {
				this.data.enrolledInBiometrics = true;
			}
			if (!bioEnrolled && !!bioType) {
				this.data.canEnrollInBiometrics = true;
			}
			this.data.bioType = bioType;
		} catch (err) {
			console.log('error getting bio status: ', err);
			this.data.canEnrollInBiometrics = false;
			this.data.enrolledInBiometrics = false;
		}
		// this.loadAccumulators();
	}

	async verifyEnrolledUser(username) {
		const isApp = appStore.isApp();
		const biometricsEnabled = await storageService.getValue(
			'app',
			'biometricsEnabled'
		);
		const userFound = !!username && userStore.isLoggedIn;
		const currentUser = _.get(userStore, 'userData.user.contactInfo.email');
		const userMatch = currentUser === username;
		this.data.hasBiometrics =
			isApp && biometricsEnabled && userFound && userMatch;
	}

	formatDOB(dobString) {
		if (!dobString) {
			return '';
		}
		const dateParts = dobString.split('T');
		const dateOnly = (dateParts && dateParts[0]) || dobString;
		const birthdayDisplay = getLocalizedDate(
			parse(dateOnly, 'yyyy-MM-dd', new Date()),
			'PP'
		);
		return birthdayDisplay;
	}
	showDependentDetails(dependent) {
		const birthdayDisplay = formatDOB(dependent.dob);
		dependent.birthdayDisplay = birthdayDisplay;
		this.data.showDependentPanel = true;
		this.data.selectedDependent = dependent;
	}

	handleSignout() {
		if (!appStore.isExpressPortal && !appStore.demoMode) {
			firebase.auth().signOut();
		} else {
			this.$f7.dialog.alert('During config edit mode this feature is disabled');
		}
	}

	launchImageViewer(evt) {
		const selectedOption = evt.currentTarget.getAttribute('data-type');
		this.data.documentType = selectedOption;
		this.data.showGallery = true;
	}

	closeGallery() {
		this.data.showGallery = false;
	}

	closeDependentPanel() {
		this.data.showDependentPanel = false;
		this.data.selectedDependent = {};
	}

	openFeedbackForm(evt) {
		this.data.showFeedbackForm = true;
	}

	closeFeedbackForm() {
		this.data.showFeedbackForm = false;
	}

	handleOpenWallet() {
		this.data.walletOpen = true;
	}

	handleCloseWallet() {
		this.data.walletOpen = false;
	}

	async onModeChange() {
		const currentStatus = _.get(
			userStore,
			'userData.user.accessibilityModeOn',
			false
		);
		this.$f7.dialog.preloader();
		try {
			await UserService.updateUIPreference(!currentStatus);
		} catch (err) {
			console.log('unable to update ui preference: ', err);
		}
		this.$f7.dialog.close();
	}
	async enableBiometrics() {
		this.$f7.dialog.preloader();
		try {
			await BiometricsService.clearDeclinedDate();
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.enableBiometricsConfirmation, () => {
				this.handleSignout();
			});
		} catch (err) {
			console.log('error enabling biometrics: ', err);
			this.$f7.dialog.close();
		}
	}
	async disableBiometrics() {
		this.$f7.dialog.preloader();
		try {
			await BiometricsService.turnOffBiometrics(true);
			this.data.enrolledInBiometrics = false;
			this.data.canEnrollInBiometrics = true;
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.disableBiometricsConfirmation);
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.biometricsError);
			console.log('unable to disable bio: ', err);
		}
	}

	openPrivacy() {
		if (appStore.isExpressPortal && appStore.demoMode) {
			this.$f7.dialog.alert(
				'Privacy Policy popup disabled in config edit mode'
			);
			return;
		}
		const questionnaireConfigs =
			ConfigService.getConfigValue('questionnaires') || [];
		const employerConfig = _.cloneDeep(questionnaireConfigs.termsOfService);
		const consumerConfig = _.cloneDeep(
			questionnaireConfigs.termsOfServiceConsumer
		);
		const configToShow =
			consumerConfig && consumerConfig.enabled
				? consumerConfig
				: employerConfig;
		configToShow.content = configToShow.content.filter(
			(item) => item.type !== 'button'
		);
		configToShow.content.push({
			type: 'button',
			label: 'CLOSE',
			action: 'cancel'
		});
		configToShow.required = false;
		configToShow.header = '';
		appStore.showQuestionnaire = true;
		appStore.questionnaires = [configToShow];
	}
	openPWAPrompt() {
		appStore.showPwaPrompt = true;
	}

	render() {
		const {
			firstName,
			lastName,
			dob,
			healthPlan,
			address,
			relation,
			memberId,
			type
		} = _.get(userStore, 'userData.member', {});
		const tokenCount = _.get(userStore, 'userData.user.tokenCount', 0);
		const { healthNetworkName } = _.get(userStore, 'userData.group', {});
		const { email, phone } = _.get(userStore, 'userData.user.contactInfo', {});
		const dependents = _.get(userStore, 'userData.dependents', []);
		const isPrimaryMember = relation === 'self';
		const showDependents =
			isPrimaryMember && dependents && dependents.length > 0;
		const birthdayDisplay = formatDOB(dob);
		let config = ConfigService.getConfiguration();
		const bioTypeLabel =
			this.data.bioType === 'face'
				? this.text.biometricsFaceLabel
				: this.text.biometricsFingerprintLabel;
		const bioEnrollTemplate = _.template(this.text.enableBiometricsLabel);
		const bioEnrollText = bioEnrollTemplate({ bioType: bioTypeLabel });
		const bioDisableTemplate = _.template(this.text.disableBiometricsLabel);
		const bioDisableText = bioDisableTemplate({ bioType: bioTypeLabel });
		const isExpressMember = type === 'express';
		const formatPhoneNumber = function (phoneNumberString) {
			var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
			var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				return '(' + match[1] + ') ' + match[2] + '-' + match[3];
			}
			return null;
		};
		const isTargetDevice =
			appStore.isMobile && (appStore.isIos || appStore.deviceDetails.android);
		const isNotPwa = !appStore.isPWA();
		const isNotApp = !appStore.isApp();
		const showInstallButton = isTargetDevice && isNotApp && isNotPwa;
		return (
			<div className="profile-page">
				<GlobalHeader />
				<div className="profile-body y-scroll">
					<div className="hbox vcenter w-100">
						<div className="grow-1"></div>
						<LanguageSelection />
					</div>
					<div className="profile-header vbox vcenter hcenter">
						<div className="header-icon">
							<i className="fad fa-user" />
						</div>
						<div className="header-details">
							<div className="title">{`${firstName} ${lastName}`}</div>
							<div className="info">
								{`${this.text.memberId}`}
								<span>{memberId}</span>
								{email && <p>{email}</p>}
								{phone && <p>{formatPhoneNumber(phone)}</p>}
								{dob && <p>{`${birthdayDisplay}`}</p>}
							</div>
						</div>
					</div>
					<div className="profile-data">
						<div className="header-actions hbox vcenter">
							{!UserService.isConsumer() && !isExpressMember && (
								<div
									className="action"
									onClick={this.launchImageViewer}
									data-type="idCard"
								>
									<div className="circle-btn">
										<i className="fad fa-id-card" />
									</div>
									<div className="label">{this.text.viewIDCard}</div>
								</div>
							)}
							{!UserService.isConsumer() && !isExpressMember && (
								<div
									className="action"
									onClick={this.launchImageViewer}
									data-type="sobDocs"
								>
									<div className="circle-btn">
										<i className="fad fa-files-medical" />
									</div>
									<div className="label">{this.text.viewSOB}</div>
								</div>
							)}
							<div className="action" onClick={this.handleSignout}>
								<div className="circle-btn">
									<i className="fad fa-sign-out" />
								</div>
								<div className="label">{this.text.signout}</div>
							</div>
						</div>
						{!userStore.isExpressUser() && showDependents && (
							<div className="profile-group">
								<div className="group-heading">
									{this.text.dependentsHeader}
								</div>
								<div className="group-details vbox vcenter hcenter">
									{dependents.map((dependent) => {
										const dependentKeys = Object.keys(dependent);
										return (
											<Button
												key={JSON.stringify(dependent)}
												className="neubtn dependents"
												onClick={() => {
													this.showDependentDetails(_.cloneDeep(dependent));
												}}
												panelOpen="right"
											>
												<i className="fad fa-user-friends dependent-icon" />
												{`${dependent.firstName} ${dependent.lastName}`}
											</Button>
										);
									})}
								</div>
							</div>
						)}
						<div className="profile-group">
							<div className="group-details vbox vcenter hcenter">
								{UserService.isConsumer() && !userStore.activeSubscription && (
									<Button
										className="neubtn balance-btn hbox vcenter hleft"
										onClick={() => {
											this.data.tokenActivityOpen = true;
										}}
									>
										<CareCoin color="#6769f3" />
										<div className="btn-msg">
											<div className="sub-title">{this.text.balanceText}</div>
											<div className="title">{`${tokenCount} ${this.text.tokenText}`}</div>
										</div>
									</Button>
								)}
								{UserService.isConsumer() && (
									<Button
										className="neubtn profile-btn"
										onClick={() => {
											this.data.editOpened = true;
										}}
									>
										<i className="fad fa-edit"></i>
										{this.text.editText}
									</Button>
								)}
								{UserService.isConsumer() && (
									<Button
										className="neubtn profile-btn"
										onClick={() => {
											this.data.billingOpen = true;
										}}
									>
										<i className="fad fa-credit-card"></i>
										{this.text.billingInfo}
									</Button>
								)}
								{UserService.isConsumer() && (
									<Button
										className="neubtn profile-btn"
										onClick={() => {
											this.data.callLogOpen = true;
										}}
									>
										<i className="fad fa-phone"></i>
										{this.text.callLog}
									</Button>
								)}
								{isExpressMember && (
									<Button
										className="neubtn profile-btn"
										onClick={this.handleOpenWallet}
									>
										<i className="fad fa-wallet"></i>
										{this.text.walletText}
									</Button>
								)}

								<Button
									className="neubtn profile-btn"
									onClick={this.openFeedbackForm}
								>
									<i className="fad fa-inbox-out"></i>
									{this.text.feedbackCta}
								</Button>
							</div>
						</div>
						<Support />
						<div className="profile-group vbox vcenter hcenter">
							<div className="group-heading">{this.text.settingsHeader}</div>
							<div className="group-details">
								<div className="group-item">
									<div className="label">{this.text.adaOption}</div>
									<Toggle
										defaultChecked={_.get(
											userStore,
											'userData.user.accessibilityModeOn',
											false
										)}
										onChange={this.onModeChange}
										color="green"
									/>
								</div>
								{this.data.enrolledInBiometrics && (
									<div className="group-item cta">
										<Button onClick={this.disableBiometrics}>
											{bioDisableText}
										</Button>
									</div>
								)}
								{this.data.canEnrollInBiometrics && (
									<div className="group-item cta">
										<Button onClick={this.enableBiometrics}>
											{bioEnrollText}
										</Button>
									</div>
								)}
							</div>
						</div>
						{showInstallButton && (
							<div className="profile-group vbox vcenter hcenter">
								<div className="group-details">
									<div className="group-item">
										<div className="privacy-link" onClick={this.openPWAPrompt}>
											{this.text.installPwa}
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="profile-group vbox vcenter hcenter">
							<div className="group-details privacy-policy">
								<div className="group-item">
									<div className="privacy-link" onClick={this.openPrivacy}>
										{this.text.viewPrivacyPolicy}
									</div>
								</div>
							</div>
						</div>
					</div>
					<FeedbackForm
						show={this.data.showFeedbackForm}
						close={this.closeFeedbackForm}
						type="USER"
					/>
					<Panel
						className="dependent-detail-panel"
						onPanelClose={this.closeDependentPanel}
						right
						cover
					>
						<div className="profile-header">
							<div className="header-icon">
								<i className="fad fa-user-friends" />
							</div>
							<div className="header-details">
								<p className="title">{`${this.data.selectedDependent.firstName} ${this.data.selectedDependent.lastName}`}</p>
								<div className="info">
									{this.data.selectedDependent.dob && (
										<p>{`${this.data.selectedDependent.birthdayDisplay}`}</p>
									)}
								</div>
							</div>
						</div>
						<div className="profile-data">
							<div className="profile-group">
								<p className="group-heading">{healthNetworkName}</p>
								<div className="group-details show-dividers">
									<p
										className="neubtn group-item cta"
										onClick={this.launchImageViewer}
										data-type="idCard"
									>
										<i className="fad fa-id-card" />
										{this.text.viewIDCard}
									</p>
									<p
										className="neubtn group-item cta"
										onClick={this.launchImageViewer}
										data-type="sobDocs"
									>
										<i className="fad fa-files-medical" />
										{this.text.viewSOB}
									</p>
								</div>
							</div>
						</div>
						{this.data.showGallery && (
							<MemberDocumentViewer
								onClose={this.closeGallery}
								docType={this.data.documentType}
								dependentData={_.cloneDeep(this.data.selectedDependent)}
							/>
						)}
					</Panel>
				</div>
				<EditProfile
					opened={this.data.editOpened}
					onCloseProfile={() => {
						this.data.editOpened = false;
					}}
				></EditProfile>
				{UserService.isConsumer() && (
					<BillingPopup
						opened={this.data.billingOpen}
						onCloseBilling={() => {
							this.data.billingOpen = false;
						}}
					></BillingPopup>
				)}
				{UserService.isConsumer() && (
					<TokenActivityPopup
						opened={this.data.tokenActivityOpen}
						onCloseTokenActivity={() => {
							this.data.tokenActivityOpen = false;
						}}
					/>
				)}
				<CallLogPopup
					opened={this.data.callLogOpen}
					onCloseCallLog={() => {
						this.data.callLogOpen = false;
					}}
				/>

				<WalletPopup
					opened={this.data.walletOpen}
					onClose={this.handleCloseWallet}
				/>
			</div>
		);
	}
}
