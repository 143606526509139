import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import { Progressbar, Preloader } from 'framework7-react';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import './plan-details.scss';

@observer
export default class PlanDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			steps: []
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.data.loading = true;
		const planStatus = await ExpressAPI.getPlanStatus(this.props.plan._id);
		this.data.steps = planStatus.steps;
		this.data.loading = false;
	}

	render() {
		const steps = this.data.steps || [];
		const completedSteps = steps.filter((x) => !!x.complete);
		const amountComplete = Math.ceil((completedSteps.length / steps.length) * 100) || 0;
		return (
			<div className="plan-admin-details">
				<Breadcrumb
					pageName="admin-plans"
					config={{
						0: { name: 'All Plans', icon: 'fad fa-files-medical', onClick: this.props.onClose },
						1: {
							name: `${_.get(this.props, 'plan.org') || 'Unknown Org'} - ${_.get(this.props, 'plan.name') || ''}`,
							onClick: () => {}
						}
					}}
					currentKey={1}
				/>
				{this.data.loading && (
					<div className="loading-ctn">
						<Preloader size={32} color="blue" />
					</div>
				)}
				{!this.data.loading && (
					<div className="plan-overview-card">
						<Progressbar progress={amountComplete} id="plan-inline-progressbar" />
						<div className="step-list">
							{steps.map((step, stepIndex) => {
								return (
									<div className={`step hbox vcenter ${step.complete ? 'completed' : ''}`} key={`plan-admin-steps-${stepIndex}`}>
										<div className="status">
											{step.complete && <i className="fas fa-check animated bounceIn" />}
											{!step.complete && <i className="fas fa-exclamation animated bounceIn" />}
										</div>
										<div className="step-text">
											<div>
												<strong>{step.title}</strong>
											</div>
											<div className="step-info">{step.info}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		);
	}
}
