import React, { Component } from 'react';
import { autoBind } from '@/utils/GeneralUtils';
import { Image } from 'react-konva';
import CanvasFactory from '@/factories/CanvasFactory';
import { observer, observable } from '@/utils/State';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';

@observer
export default class ImageNode extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			imageLoaded: false
		});
		autoBind(this);
	}

	componentDidMount() {
		if (!this.props.image) {
			this.loadImage();
		}
	}

	componentDidUpdate(props) {
		const { documentId, nodeData } = CanvasFactory.getActiveStore();
		let node = nodeData[this.props.id];
		if (!node.image) {
			this.loadImage()
		}
	}

	loadImage() {
		const { documentId, nodeData } = CanvasFactory.getActiveStore();
		let node = nodeData[this.props.id];

		if (node.fileKey) {
			ExpressAPI.getCanvasAsset(node.fileKey).then((url) => {
				node.image = new window.Image();
				node.image.crossOrigin = 'Anonymous';
				node.image.src = url;
				node.image.addEventListener('load', () => {
					this.data.imageLoaded = true;
					CanvasFactory.getActiveService().draw();
				});
			});
		}
	}

	render() {
		const { canDrag, onTransformEnd, onDragEnd, onNodeTransform, onHighlight } = CanvasFactory.getActiveService();
		return (
			<>
				<Image
					{...this.props}
					draggable={canDrag()}
					onTransformEnd={onTransformEnd}
					onDragEnd={onDragEnd}
					onDrag={onHighlight}
					onTransform={onNodeTransform}
					onDragMove={onHighlight}
				></Image>
			</>
		);
	}
}
