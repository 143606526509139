import React, { Component } from 'react';
import { Page, Link, Button, Card, CardContent, CardHeader, Menu, MenuItem, MenuDropdown, MenuDropdownItem } from 'framework7-react';
import ProviderSearchResults from './ProviderSearchResults';
import ProviderDetailsPage from './ProviderDetailsPage';
import { getCmpText } from '../../utils/Localization';
import { observer, observable } from '../../utils/State';
import _ from 'lodash';
import { autoBind } from '../../utils/GeneralUtils';

import './provider-management.scss';
import CloudAPI from '../../services/CloudAPI';

@observer
export default class ProviderManagement extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			activePage: 'search',
			selectedProvider: null,
			query: '',
			managementDetails: null
		});
		this.text = getCmpText('ProviderManagement', {
			noTextProbably: 'true'
		});
		autoBind(this);
	}

	async componentDidMount() {
		console.log('Mounted Provider Mgmt');
		this.data.managementDetails = await CloudAPI.getMgmtDetails();
	}

	onClose() {
		globalRouter.navigate('/home');
	}

	getActiveContent() {
		if (this.data.managementDetails) {
			switch (this.data.activePage) {
				case 'search': {
					return (
						<div className="filter-page hbox">
							<div className="left">
								<ProviderSearchResults
									setActiveProvider={(npi) => {
										if (this.data.selectedProvider !== npi) {
											this.data.selectedProvider = null;
											this.data.selectedProvider = npi;
										}
									}}
									mgmtDetails={_.cloneDeep(this.data.managementDetails)}
								/>
							</div>
							<div className="right">
								<ProviderDetailsPage provider={this.data.selectedProvider} mgmtDetails={_.cloneDeep(this.data.managementDetails)} />
							</div>
						</div>
					);
				}
				case 'careteam':
				case 'booked':
				case 'modified': {
					const title = this.data.activePage === 'modified' ? 'Verified' : _.capitalize(this.data.activePage);
					return (
						<div className="filter-page hbox">
							<div className="left">
								<ProviderSearchResults
									title={`${title} Providers`}
									loading={!this.data.providers}
									hideFilter
									hideSearch
									type={this.data.activePage}
									onSearch={this.showProvidersWithActivity}
									results={_.cloneDeep(this.data.providers)}
									setActiveProvider={(npi) => {
										if (this.data.selectedProvider !== npi) {
											this.data.selectedProvider = null;
											this.data.selectedProvider = npi;
										}
									}}
									mgmtDetails={_.cloneDeep(this.data.managementDetails)}
								/>
							</div>
							<div className="right">
								<ProviderDetailsPage provider={this.data.selectedProvider} mgmtDetails={_.cloneDeep(this.data.managementDetails)} />
							</div>
						</div>
					);
				}
				case 'keywords': {
					return (
						<div className="vbox vcenter hcenter wippage">Coming Soon! Manage Keyword Dicontary (things to change case of etc)</div>
					);
				}
				case 'specialty': {
					return <div className="vbox vcenter hcenter wippage">Coming Soon! Specialty Mapping</div>;
				}
			}
		}
	}

	async showProvidersWithActivity(page, loadMore) {
		this.data.activePage = page;
		if (loadMore) {
			const currentProviders = _.get(this.data, 'providers.providers') || [];
			const currentNPIs = currentProviders.map((provider) => provider.NPI);
			const nextProviders = await CloudAPI.providerMgmtProvidersByActivity(page, currentNPIs);
			const newProviders = currentProviders.concat(nextProviders.providers);
			this.data.providers = _.cloneDeep({ ...nextProviders, providers: newProviders });
		} else {
			this.data.providers = null;
			const providers = await CloudAPI.providerMgmtProvidersByActivity(page);
			this.data.providers = _.cloneDeep(providers);
		}
	}

	render() {
		let { activePage } = this.data;
		return (
			<Page className={`provider-mgmt-page`}>
				<div className="page-content hbox">
					<div className="nav-side-bar vbox grow-1">
						<Link popupClose onClick={this.onClose}>
							<i className="fa fa-chevron-left" />
							Back
						</Link>
						<div className="page-title">Provider Management</div>

						<div className="category">Providers</div>
						<div
							className={`nav-item hbox vcenter ${activePage === 'search' ? 'active' : ''}`}
							onClick={() => {
								this.data.providers = null;
								this.data.activePage = 'search';
							}}
						>
							<i className="fal fa-search" />
							<div className="label">Find</div>
						</div>
						<div
							className={`nav-item hbox vcenter ${activePage === 'careteam' ? 'active' : ''}`}
							onClick={() => this.showProvidersWithActivity('careteam', false)}
						>
							<i className="fal fa-heartbeat"></i>
							<div className="label">CareTeam</div>
						</div>
						<div
							className={`nav-item hbox vcenter ${activePage === 'booked' ? 'active' : ''}`}
							onClick={() => this.showProvidersWithActivity('booked', false)}
						>
							<i className="fal fa-calendar-alt"></i>
							<div className="label">Booked</div>
						</div>
						<div
							className={`nav-item hbox vcenter ${activePage === 'modified' ? 'active' : ''}`}
							onClick={() => this.showProvidersWithActivity('modified', false)}
						>
							<i className="fal fa-shield-check"></i>
							<div className="label">Verified</div>
						</div>
						<div className="category">Utilities</div>
						<div
							className={`nav-item hbox vcenter ${activePage === 'keywords' ? 'active' : ''}`}
							onClick={() => {
								this.data.activePage = 'keywords';
							}}
						>
							<i className="fal fa-book"></i>
							<div className="label">Keywords</div>
						</div>
						<div
							className={`nav-item hbox vcenter ${activePage === 'specialty' ? 'active' : ''}`}
							onClick={() => {
								this.data.activePage = 'specialty';
							}}
						>
							<i className="fal fa-project-diagram"></i>
							<div className="label">Specialty Mapper</div>
						</div>
					</div>
					<div className="main-content">{this.getActiveContent()}</div>
				</div>
			</Page>
		);
	}
}
