import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { Popup, Link, Button } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import userStore from '../../../stores/UserStore';
import appStore from '../../../stores/AppStore';
import BasicInput from '../../../components/basic-input/BasicInput';
import DateInput from '../../../components/date-input/DateInput';
import CloudAPI from '../../../services/CloudAPI';
import ExternalService from '@/services/ExternalService';
import './edit-profile.scss';
@observer
export default class EditProfile extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			dobValid: false,
			disableSave: true,
			..._.cloneDeep(_.get(userStore, 'userData.user.contactInfo', {})),
			firstName: _.cloneDeep(_.get(userStore, 'userData.user.firstName', '')),
			lastName: _.cloneDeep(_.get(userStore, 'userData.user.lastName', '')),
			dob: _.cloneDeep(_.get(userStore, 'userData.user.dob'))
		});
		if (!this.data.street2) {
			this.data.street2 = '';
		}
		this.data.disableSave = !this.validateForm();
		this.text = getCmpText('EditProfile', {
			dobLabel: 'Birthday',
			dobPlaceholder: '01/01/1980',
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email',
			phone: 'Phone',
			street1: 'Street',
			street2: 'Street 2',
			city: 'City',
			state: 'State',
			zipcode: 'Zip Code',
			save: 'Save',
			invalidDOB: 'You must be at least 18 years of age to use this service.',
			updateProfileError: 'Unable to save changes, please try again',
			title: 'Edit Profile',
			phoneHelper: 'If you need help changing your phone number please contact CareValet at 866-549-9533.',
			phoneHelperTitle: 'Sorry!',
			phoneHelperCallCta: 'Call Now',
			phoneHelperCloseCta: 'Close'
		});
		autoBind(this);
	}

	closePrompt() {
		this.props.onCloseProfile();
	}

	onInputChange(evt) {
		const target = evt.currentTarget;
		const field = target.name;
		if (field === 'phone') return;
		this.data[target.name] = target.value;
		this.data.disableSave = !this.validateForm();
	}

	validateEmail(email) {
		return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		);
	}

	validateForm() {
		const existanceCheck = ['street', 'city', 'state', 'zipCode', 'email'];
		const missingRequiredFields = existanceCheck.filter((field) => {
			const fieldVal = _.get(this.data, field) || '';
			return fieldVal < 1;
		});

		const hasRequiredFields = missingRequiredFields.length < 1;
		const hasValidEmail = this.validateEmail(this.data.email || '');
		const hasValidDob = this.data.dobValid;

		return hasRequiredFields && hasValidEmail && hasValidDob;
	}

	onSave() {
		console.log(JSON.stringify(this.data, null, 2));
		this.$f7.dialog.preloader();
		CloudAPI.saveProfileInfo(this.data)
			.then((res) => {
				let { user, member } = res;
				userStore.userData.user = user;
				userStore.userData.member = member;
				this.$f7.dialog.close();
				this.$f7.popup.close('.edit-profile-popup');
			})
			.catch((err) => {
				const errResponseString = _.get(err, 'responseText');
				const errorResponseData = errResponseString && JSON.parse(errResponseString);
				let errorMessage = this.text.updateProfileError;
				switch (errorResponseData.message) {
					case 'invalidDOB':
						errorMessage = this.text.invalidDOB;
						break;
					default:
						break;
				}
				this.$f7.dialog.close();
				this.$f7.dialog.alert(errorMessage);
			});
	}

	onDateChange(value, field, isValid) {
		this.data.dob = value;
		this.data.dobValid = isValid;
		this.data.disableSave = !this.validateForm();
	}

	showPhoneHelper() {
		this.$f7.dialog
			.create({
				title: this.text.phoneHelperTitle,
				text: this.text.phoneHelper,
				buttons: [
					{
						text: this.text.phoneHelperCallCta,
						onClick: () => {
							ExternalService.handleExternalRequest('call', '866-549-9533');
						}
					},
					{
						text: this.text.phoneHelperCloseCta,
						onClick: () => {
							console.log('cancel clicked');
						}
					}
				],
				verticalButtons: false
			})
			.open();
	}

	render() {
		let { firstName, lastName, dob, email, phone, street, street2, city, state, zipCode, disableSave } = this.data;
		return (
			<div>
				<Popup
					opened={this.props.opened}
					className={`edit-profile-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
					closeByBackdropClick={true}
					closeOnEscape={true}
					onPopupClosed={this.closePrompt}
				>
					<div className="popup-header">
						<div className="title grow-1">{this.text.title}</div>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					<div className="edit-form y-scroll">
						<BasicInput
							name="firstName"
							label={this.text.firstName}
							value={firstName}
							type="text"
							placeholder="John"
							onChange={this.onInputChange}
						/>
						<BasicInput
							name="lastName"
							label={this.text.lastName}
							value={lastName}
							type="text"
							placeholder="Doe"
							onChange={this.onInputChange}
						/>
						<BasicInput
							name="email"
							label={this.text.email}
							value={email}
							type="email"
							placeholder="mscott@carevalet.com"
							onChange={this.onInputChange}
						/>
						<div className="" onClick={this.showPhoneHelper}>
							<BasicInput
								name="phone"
								label={this.text.phone}
								value={phone}
								type="text"
								placeholder="5551113333"
								onChange={this.onInputChange}
								readOnly
							/>
						</div>
						{/* <Support /> */}
						{/* <div className="phone-info">*If you need to change your phone number please call CareValet at </div> */}
						<BasicInput
							name="street"
							label={this.text.street1}
							value={street}
							type="text"
							placeholder="123 rd"
							onChange={this.onInputChange}
						/>
						<BasicInput
							name="street2"
							label={this.text.street2}
							value={street2}
							type="text"
							placeholder=""
							onChange={this.onInputChange}
						/>
						<BasicInput
							name="city"
							label={this.text.city}
							value={city}
							type="text"
							placeholder="Tampa"
							onChange={this.onInputChange}
						/>
						<BasicInput
							name="state"
							label={this.text.state}
							value={state}
							type="text"
							placeholder="FL"
							onChange={this.onInputChange}
						/>
						<BasicInput
							name="zipCode"
							label={this.text.zipcode}
							value={zipCode}
							type="text"
							placeholder="33607"
							onChange={this.onInputChange}
						/>
						<DateInput onChange={this.onDateChange} label={this.text.dobLabel} placeholder={this.text.dobPlaceholder} value={dob} />
					</div>
					<div className="btn-ctn">
						<Button large onClick={this.onSave} disabled={disableSave}>
							{this.text.save}
						</Button>
					</div>
				</Popup>
			</div>
		);
	}
}
