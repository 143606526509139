import { observable } from '../utils/State';
import _ from 'lodash';
const userStore = observable({
	frontendVersion: null,
	backendVersion: null,
	isLoggedIn: false,
	isCreatingUser: false,
	firebaseUser: null,
	userData: null,
	consumerUser: null,
	manualLocation: false,
	locationServiceEnabled: true,
	signupData: { first: '', last: '', zip: '', mobile: '', referralSource: null },
	paymentsLoading: false,
	paymentMethods: [],
	recaptchaId: null,
	activeSubscription: false,
	acceptedProcedureSearchDisclaimer: false,
	setSubscriptionState: (subs) => {
		let { data } = subs;

		if (data && data.length > 0) {
			let activeSub = false;
			_.forEach(data, (sub) => {
				if (_.get(sub, 'status', null) === 'active') {
					activeSub = true;
				}
			});
			userStore.activeSubscription = activeSub;
		} else {
			userStore.activeSubscription = false;
		}
	},
	isExpressUser: () => {
		return _.get(userStore, 'userData.member.type', '') === 'express';
	},
	resetSignupData: () => {
		userStore.signupData = { first: '', last: '', zip: '', mobile: '', referralSource: null };
	}
});
if (process.env.NODE_ENV === 'development') {
	//@ts-ignore
	window.userStore = userStore;
}

export default userStore;
