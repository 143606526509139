export default {
	al: 'Alabama',
	ak: 'Alaska',
	as: 'American Samoa',
	az: 'Arizona',
	ar: 'Arkansas',
	ca: 'California',
	co: 'Colorado',
	ct: 'Connecticut',
	de: 'Delaware',
	dc: 'District Of Columbia',
	fm: 'Federated States Of Micronesia',
	fl: 'Florida',
	ga: 'Georgia',
	gu: 'Guam',
	hi: 'Hawaii',
	id: 'Idaho',
	il: 'Illinois',
	in: 'Indiana',
	ia: 'Iowa',
	ks: 'Kansas',
	ky: 'Kentucky',
	la: 'Louisiana',
	me: 'Maine',
	mh: 'Marshall Islands',
	md: 'Maryland',
	ma: 'Massachusetts',
	mi: 'Michigan',
	mn: 'Minnesota',
	ms: 'Mississippi',
	mo: 'Missouri',
	mt: 'Montana',
	ne: 'Nebraska',
	nv: 'Nevada',
	nh: 'New Hampshire',
	nj: 'New Jersey',
	nm: 'New Mexico',
	ny: 'New York',
	nc: 'North Carolina',
	nd: 'North Dakota',
	mp: 'Northern Mariana Islands',
	oh: 'Ohio',
	ok: 'Oklahoma',
	or: 'Oregon',
	pw: 'Palau',
	pa: 'Pennsylvania',
	pr: 'Puerto Rico',
	ri: 'Rhode Island',
	sc: 'South Carolina',
	sd: 'South Dakota',
	tn: 'Tennessee',
	tx: 'Texas',
	ut: 'Utah',
	vt: 'Vermont',
	vi: 'Virgin Islands',
	va: 'Virginia',
	wa: 'Washington',
	wv: 'West Virginia',
	wi: 'Wisconsin',
	wy: 'Wyoming'
};
