import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind, getUrlParams } from '../../utils/GeneralUtils';
import userStore from '../../stores/UserStore';
import CloudAPI from '../../services/CloudAPI';
import { Page } from 'framework7-react';
import './login.scss';
import { observer, observable } from '../../utils/State';
import StorageService from '../../services/StorageService';
import appStore from '../../stores/AppStore';
import LandingPage from './landing/LandingPage';
import EmployerSignin from './employer/employer-signin/EmployerSignin';
import ConsumerSignin from './consumer-signin/ConsumerSignin';
import ConsumerSignup from './consumer-signup/ConsumerSignup';
import ConsumerValidation from './consumer-validation/ConsumerValidation';
import ConsumerTelehealth from './consumer-telehealth/ConsumerTelehealth';
import SignupTypeSelection from './signup-type-selection/SignupTypeSelection';
import EmployerSignup from './employer/employer-signup/EmployerSignup';
import TwoFactorPopup from './TwoFactorPopup';
import ConsumerVerifyContact from './consumer-verify-contact/ConsumerVerifyContact';

const storageService = new StorageService();

@observer
export default class Login extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			email: '',
			password: '',
			emailValid: false,
			rememberMe: false,
			isProviderPortal: 0,
			consumerClickCount: 0,
			activePage: 'landing', //number || string
			lastPage: 'landing',
			twoFactorOpen: false,
			phone: null,
			isConsumer: false,
			urlParams: null
		});

		this.text = getCmpText('Login', {
			title: 'Welcome back,',
			signIn: 'Sign In',
			subtitle: 'Sign in to continue',
			resetPwd: 'Forgot password?',
			signUp: 'Sign Up',
			remember: 'Remember me',
			accountMsg: "Don't have an account?",
			emailLabel: 'E-mail',
			passwordLabel: 'Password',
			emailPlaceholder: 'Input your email',
			pwdPlaceholder: 'Input your password',
			loggingIn: 'Validating Credentials....',
			forgotPasswordSuccess: 'Please check your email to reset your password',
			forgotPasswordFailure: 'Unable to complete password reset',
			forgotPasswordModalTitle: 'Reset Password'
		});
		autoBind(this);
	}

	onConsumerEscape() {
		this.data.consumerClickCount = this.data.consumerClickCount + 1;
		if (this.data.consumerClickCount >= 5) {
			this.onNext('consumer-signin');
		}
	}

	async componentDidMount() {
		this.data.urlParams = getUrlParams();
		let referralSource = _.get(this.data.urlParams, 'ref', null);
		if (referralSource) {
			firebase.auth().signOut(); //in case someone previously authed on device
			console.info(`User referred by: ${referralSource}`);
			this.data.isConsumer = true;
			this.data.activePage = 'consumer-signup';
			CloudAPI.affiliateLanding(referralSource);
		}
	}

	setActiveAsync(page) {
		setTimeout(() => {
			this.data.activePage = page;
			this.data.isConsumer = page === 'employer-signin' ? false : true;
		}, 0);
	}

	getLandingPage() {
		//* Employer
		if (location.host === 'carevalet.io' || location.host === 'localhost:8080') {
			this.setActiveAsync('employer-signin');
			return <EmployerSignin onBack={this.onBack} />;
		}

		//* Consumer
		if (location.host === 'carevalet.doctor') {
			this.setActiveAsync('consumer-signin');
			return <ConsumerSignin onBack={this.onBack} />;
		}

		//* Fallback to existing logic
		if (location.host !== 'carevalet.io' && location.host !== 'carevalet.doctor') {
			let signInType = localStorage.getItem('app/signInType');
			if (signInType) {
				signInType = signInType.replace(/"/g, '');
			}
			if (signInType && signInType !== 'unknown' && !appStore.isApp()) {
				this.setActiveAsync(signInType);
				return signInType === 'consumer' ? <ConsumerSignin onBack={this.onBack} /> : <EmployerSignin onBack={this.onBack} />;
			}
			if (appStore.isApp()) {
				this.setActiveAsync('employer-signin');
				return <EmployerSignin onBack={this.onBack} />;
			}
			return <LandingPage onNext={this.onNext} />;
		}
	}

	getCurrentPage() {
		switch (this.data.activePage) {
			case 'landing': {
				return this.getLandingPage();
			}
			case 'employer-signin': {
				return <EmployerSignin onBack={this.onBack} onNext={this.onNext} />;
			}
			case 'employer-choice': {
				return <SignupTypeSelection onBack={this.onBack} onNext={this.onNext} />;
			}
			case 'employer-lookup-member': {
				return <EmployerSignup onBack={this.onBack} onNext={this.onNext} type={'member'} />;
			}
			case 'employer-lookup-ssn': {
				return <EmployerSignup onBack={this.onBack} onNext={this.onNext} type={'ssn'} />;
			}
			case 'consumer-signin': {
				return <ConsumerSignin onBack={this.onBack} onNext={this.onNext} onSignIn={this.onConsumerSignin} />;
			}
			case 'consumer-signup': {
				return <ConsumerSignup onBack={this.onBack} onNext={this.onNext} />;
			}
			case 'consumer-verify-contact': {
				return <ConsumerVerifyContact onBack={this.onBack} onNext={this.onNext} />;
			}
			case 'consumer-verification': {
				return (
					<ConsumerValidation onBack={this.onBack} onNext={this.onNext} consumer={true} hideBack={true} phone={this.data.phone} />
				);
			}
			case 'consumer-telehealth': {
				return <ConsumerTelehealth onBack={this.onBack} />;
			}
		}
	}

	onConsumerSignin(phone) {
		this.data.isConsumer = true;
		this.data.twoFactorOpen = true;
		this.data.phone = phone;
	}

	async onNext(page) {
		if (page) {
			switch (page) {
				case 'consumer-signin':
					this.data.isConsumer = true;
					break;
				case 'employer-signin':
					this.data.activePage = 'landing';
					break;
				default:
					break;
			}
			this.data.lastPage = _.clone(this.data.activePage);
			this.data.activePage = page;
		} else {
			console.log('no page submitted');
		}
	}

	onValidationNext(page) {
		if (page) {
			this.onNext(page);
		}
		this.data.twoFactorOpen = false;
	}

	onVerifyComplete() {
		this.data.twoFactorOpen = false;
	}

	async onBack(page) {
		switch (page) {
			case 'employer-signin': {
				await storageService.setValue('app', 'signInType', 'unknown');
				this.data.activePage = 'landing';
				break;
			}
			case 'employer-choice': {
				this.data.activePage = 'employer-signin';
				break;
			}
			case 'employer-lookup': {
				this.data.activePage = 'employer-choice';
				break;
			}
			case 'employer-verify-info': {
				this.data.activePage = this.data.lastPage;
				break;
			}
			case 'consumer-signin': {
				await storageService.setValue('app', 'signInType', 'unknown');
				userStore.isCreatingUser = false;
				this.data.activePage = 'landing';
				break;
			}
			case 'consumer-signup': {
				this.data.activePage = 'consumer-signin';
				break;
			}
			case 'consumer-validation': {
				this.data.activePage = 'consumer-signup';
				break;
			}
			case 'consumer-telehealth': {
				this.data.activePage = 'consumer-signin';
				break;
			}
			default: {
				this.data.activePage = this.data.lastPage;
				break;
			}
		}
	}

	render() {
		return (
			<Page
				className={`login-component ${appStore.isMobile ? 'mobile' : 'desktop'} ${
					this.data.isConsumer ? 'consumer-login' : 'employer-login'
				}`}
			>
				<div className="login-page-container">{this.getCurrentPage()}</div>
				<div className="logo-bottom hbox vcenter hcenter" onClick={this.onConsumerEscape}>
					<i className="icon-logo" />
					<span className="c">Care</span>
					<span className="v">Valet</span>
				</div>
				<TwoFactorPopup
					opened={this.data.twoFactorOpen}
					onNext={this.onValidationNext}
					consumer={this.data.isConsumer}
					onBack={this.onVerifyComplete}
					phone={this.data.phone}
				/>
			</Page>
		);
	}
}
