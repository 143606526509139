import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import TabSlider from '@/components/_EXPRESS/tab-slider/TabSlider';
import expressStore from '@/stores/ExpressStore';
import OrgAdmin from './org-admin/OrgAdmin';
import NetworkAdmin from './network-admin/NetworkAdmin';
import PlanAdmin from './plan-admin/PlanAdmin';
import GroupAdmin from './group-admin/GroupAdmin';
import FulfillmentAdmin from './fulfillment-admin/FulfillmentAdmin';
import FeatureFlagsAdmin from '@/pages/EXPRESS/admin/feature-flags/FeatureFlags';
import ApiActions from '@/pages/EXPRESS/admin/api-actions/ApiActions';
import './express-admin.scss';

@observer
export default class ExpressAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ activeTab: 0, isSysAdmin: false, orgRole: {} });
		autoBind(this);
	}

	componentDidMount() {
		this.data.orgRole = _.cloneDeep(expressStore.currentOrgRole());
		this.data.isSysAdmin = _.cloneDeep(expressStore.isSystemAdmin());
	}

	setActiveTab(tab) {
		this.data.activeTab = tab;
	}

	getTabConfig() {
		const tabs = {};
		const isOrgAdmin =
			_.get(this.data, 'orgRole.role') === expressStore.roleEnums.ORG_ADMIN;
		if (isOrgAdmin || this.data.isSysAdmin) {
			tabs[0] = { name: 'Organizations' };
			tabs[1] = { name: 'Groups' };
			tabs[2] = { name: 'Plans' };
			tabs[3] = { name: 'Networks' };
			tabs[4] = { name: 'Fulfillment' };
			tabs[5] = { name: 'Feature Flags' };
			tabs[6] = { name: 'API Actions' };
		}
		return tabs;
	}

	render() {
		return (
			<div className="express-admin-page">
				<h1 className="text-4 mb-xl">Admin</h1>
				<TabSlider
					activeTab={this.data.activeTab}
					setActiveTab={this.setActiveTab}
					config={this.getTabConfig()}
				/>
				<div className="admin-body">
					{this.data.activeTab === 0 && <OrgAdmin />}
					{this.data.activeTab === 1 && <GroupAdmin />}
					{this.data.activeTab === 2 && <PlanAdmin />}
					{this.data.activeTab === 3 && <NetworkAdmin />}
					{this.data.activeTab === 4 && <FulfillmentAdmin />}
					{this.data.activeTab === 5 && <FeatureFlagsAdmin />}
					{this.data.activeTab === 6 && <ApiActions />}
				</div>
			</div>
		);
	}
}
