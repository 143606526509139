// Import React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom';
import initPolyfills from './polyfills';
//import ProxyPolyfill from "proxy-polyfill/src/proxy";

// Import Framework7
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-React Plugin
import Framework7React from 'framework7-react';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../components/app.jsx';
import userStore from '../stores/UserStore';
import CloudAPI from '../services/CloudAPI';
import appStore from '../stores/AppStore';
initPolyfills();
appStore.isMobile = !Framework7.device.desktop;
appStore.isIos = Framework7.device.ios;
appStore.deviceDetails = Framework7.device;
const fireTheAppUp = async () => {
	const isExpressUser = localStorage.getItem('app/expressUser');
	console.log(`IS EXPRESS USER ======================= ${isExpressUser}`);
	if (window.location.host === 'carevalet.express' || isExpressUser) {
		appStore.isExpressPortal = true;
		console.log(`======= IS EXPRESS PORTAL =========`);
	}
	// Init F7 React Plugin
	Framework7.use(Framework7React);
	//Mount app
	ReactDOM.render(React.createElement(App), document.getElementById('app'));
};
fireTheAppUp();
