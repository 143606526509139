import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { Button } from 'framework7-react';
import './titlebar.scss';
import DocumentTitle from './document-title/DocumentTitle';
@observer
export default class Titlebar extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
	}

	render() {
		const { canEdit, onClose, onSave, onNew, onChangeTitle, onErase, eraseActive } = this.props;
		return (
			<div className="title-bar hbox vcenter">
				<DocumentTitle />
				<div className="hbox title-bar-center hcenter grow-1">
					<div className="hbox vcenter"></div>
				</div>
				<div className="hbox title-bar-right self-hcenter hright">
					{canEdit && (
						<Button className="save-btn" onClick={onSave}>
							<i className="fad fa-save"></i>
							Save
						</Button>
					)}
					<Button className="new-btn" onClick={onClose}>
						<i className="fad fa-times-square"></i>
						Close
					</Button>
				</div>
			</div>
		);
	}
}
