import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './bar-chart.scss';

@observer
export default class BarChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	formatData() {
		const reportData = _.get(this.props, 'data.data') || [];
		const reportKeyData = _.flatten(reportData.map((x) => x.data));
		const reportKeys = Array.from(new Set(reportKeyData.map((x) => x.x)));
		const reportGroupings = reportData.map((x) => x.label);
		const newFormattedData = reportKeys.map((reportKey) => {
			const dataKeys = {};
			reportData.map((reportItem) => {
				const reportItemData = reportItem.data.filter((d) => d.x === reportKey);
				reportItemData.map((x) => {
					dataKeys[reportItem.label] = x.y;
					dataKeys[`${reportItem.label}Color`] = reportItem.color;
				});
			});
			const formattedItem = {
				...dataKeys,
				name: reportKey
			};
			return formattedItem;
		});
		return {
			keys: reportGroupings,
			index: 'name',
			data: newFormattedData
		};
	}

	getColor(data) {
		const dataItem = data.id;
		const colorKey = `${dataItem}Color`;
		const color = data.data[colorKey];
		return color;
	}

	handleItemClick(data) {
		console.log('bar chart was clicked: ', data);
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const reportData = this.formatData();
		const chartConfig = chartProperties.config;
		return (
			<ResponsiveBar
				groupMode={chartConfig.stacked ? 'stacked' : 'grouped'}
				keys={reportData.keys}
				data={reportData.data}
				indexBy={reportData.index}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				margin={{ top: isMobile ? 20 : 20, right: 50, bottom: isMobile ? 70 : 50, left: 50 }}
				axisTop={null}
				axisRight={null}
				tooltip={(data) => {
					return (
						<div
							style={{
								background: 'white',
								padding: '9px 12px'
							}}
						>
							<div style={{ padding: '3px 0', maxWidth: 150 }}>
								<div style={{ color: data.color }}>{data.id}</div>
								<div style={{ fontWeight: 'bold' }}>{data.value}</div>
							</div>
						</div>
					);
				}}
				layout={chartConfig.direction ? chartConfig.direction : 'vertical'}
				onClick={this.handleItemClick}
				enableLabel={chartConfig.hasOwnProperty('enableLabels') ? chartConfig.enableLabels : true}
				axisBottom={{
					orient: 'bottom',
					tickSize: 5,
					tickPadding: isMobile ? 0 : 20,
					tickRotation: isMobile ? Number(-90) : 0
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: chartConfig.xLabel || 'count',
					legendOffset: -40,
					legendPosition: 'middle'
				}}
				theme={{
					fontSize: 12,
					grid: {
						line: {
							strokeDasharray: '4 4'
						}
					}
				}}
			/>
		);
	}
}
