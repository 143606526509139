import React, { Component } from 'react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import './express-invite-form.scss';
import { Button } from 'framework7-react';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import ExpressAuthService from '@/services/ExpressAuthService';
import ExpressAPI from '@/services/ExpressAPI';

@observer
export default class ExpressInviteForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			acceptedTOS: false,
			step: 1,
			formData: {
				firstName: '',
				lastName: '',
				jobTitle: '',
				companyName: '',
				companyType: 'tpa',
				membershipSize: 1,
				phone: '',
				email: '',
				password: '',
				confirmPassword: '',
				emailValid: false,
				validInvite: 'validating'
			}
		});
		autoBind(this);
	}

	tosToggle() {
		this.data.acceptedTOS = !this.data.acceptedTOS;
	}

	async onNext() {
		if (this.data.step === 1) {
			this.$f7.dialog.preloader('Validating');
			ExpressAPI.validateInvite({ code: this.props.inviteCode, email: this.data.formData.email })
				.then((res) => {
					this.$f7.dialog.close();
					this.data.step = this.data.step + 1;
				})
				.catch((err) => {
					showExpressError(this.$f7, err);
				});
		} else if (this.data.step === 2) {
			this.data.step = this.data.step + 1;
		} else {
			let { email, password, firstName, lastName, jobTitle, phone } = this.data.formData;
			const fbCreated = await ExpressAuthService.createFirebaseUser(this.$f7, email, password, firstName, lastName);
			if (fbCreated) {
				const expressUserCreated = await ExpressAPI.acceptInvite({
					email,
					password,
					firstName,
					lastName,
					jobTitle,
					phone
				});
				if (expressUserCreated) {
					firebase.auth().signOut();
					this.props.onBack(true);
				} else {
					console.log('Unable to create express user');
					this.$f7.dialog.alert(
						'Unable to creating an account with this info, please try again, if the issue persists email help@carevalet.com',
						'Error:'
					);
				}
			}
		}
	}

	onBack() {
		if (this.data.step > 1) {
			this.data.step--;
		} else {
			this.props.onBack();
		}
	}

	buildCurrentForm() {
		const formConfig = {
			1: {
				email: {
					label: 'Email Invitation Was Sent To',
					placeholder: 'Your Email',
					type: 'email',
					className: 'email-input'
				}
			},
			2: {
				firstName: {
					label: 'First Name',
					placeholder: 'Your First Name',
					type: 'text',
					className: 'text-input'
				},
				lastName: {
					label: 'Last Name',
					placeholder: 'Your Last Name',
					type: 'text',
					className: 'text-input'
				},
				jobTitle: {
					label: 'Job Title',
					placeholder: 'Your Job Title',
					type: 'text',
					className: 'text-input'
				}
			},
			3: {
				phone: {
					label: 'Phone',
					placeholder: 'Your Phone Number',
					type: 'phone',
					className: 'text-input'
				},
				password: {
					label: 'Password',
					placeholder: 'Enter a password',
					type: 'password',
					className: 'password-input'
				},
				confirmPassword: {
					label: 'Confirm Password',
					placeholder: 'Confirm Your password',
					type: 'password',
					className: 'password-input last-input'
				}
			}
		};

		let { formData, step } = this.data;
		return <ExpressFormBuilder formData={formData} formConfig={formConfig[step]} customValidator={true} />;
	}

	isFormValid() {
		let { step, formData, acceptedTOS } = this.data;
		if (step === 1) {
			let valid = true;
			let { emailValid } = formData;
			if (!emailValid) {
				valid = false;
			}
			return valid;
		}
		if (step === 2) {
			let { firstName, lastName, jobTitle } = formData;
			let valid = true;
			if (firstName.length < 2) {
				valid = false;
			}
			if (lastName.length < 2) {
				valid = false;
			}
			if (jobTitle.length < 2) {
				valid = false;
			}
			return valid;
		}
		if (step === 3) {
			let { phone, password, confirmPassword } = formData;
			let valid = true;
			if (!acceptedTOS) {
				valid = false;
			}
			if (phone.length !== 10) {
				valid = false;
			}
			if (password !== confirmPassword) {
				valid = false;
			}
			if (password) {
				var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
				const isStrong = strongRegex.test(password);
				if (!isStrong) {
					valid = false;
				}
			}
			return valid;
		}
		return false;
	}

	render() {
		let { step, acceptedTOS } = this.data;
		return (
			<div className={`express-invite-form vbox vcenter hcenter ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<h2 className="text-4">Sign Up</h2>
				{this.buildCurrentForm()}
				{step === 3 && (
					<div className="hbox vcenter tos-toggle" onClick={this.tosToggle}>
						<div className="toggle-indicator vbox vcenter hcenter">{acceptedTOS && <i className="fad fa-dot-circle"></i>}</div>
						<div className="termslink">
							I agree to the CareValet
							<span
								onClick={() => {
									window.open('https://carevalet.com/terms.html', '_blank');
								}}
							>
								Terms Of Service
							</span>
						</div>
					</div>
				)}
				{step === 3 && (
					<p>
						By registering, you agree to the processing of your personal data by CareValet as described in the
						<span
							onClick={() => {
								window.open('https://carevalet.io/privacy.html', '_blank');
							}}
						>
							Privacy Statement
						</span>
					</p>
				)}
				<div className="hbox vcenter btn-ctn">
					<Button large className="round-btn outline blue backbtn" onClick={this.onBack}>
						<i className="far fa-long-arrow-left"></i> Back
					</Button>
					<Button fill large className={`round-btn blue step${step}`} onClick={this.onNext} disabled={!this.isFormValid()}>
						{step === 3 ? 'Create Account' : 'Next'} <i className="far fa-long-arrow-right"></i>
					</Button>
				</div>
			</div>
		);
	}
}
