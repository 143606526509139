export default class UndoRedo {
	constructor(stackSize) {
		this.stackSize = stackSize ? stackSize : 10;
		this.undoHistory = [];
		this.redoHistory = [];
	}

	push(val) {
		this.undoHistory.push(val);
		this.redoHistory = [];
		if (this.undoHistory.length > this.stackSize) {
			this.undoHistory.splice(0, 1);
		}
	}

	top() {
		return this.undoHistory[this.undoHistory.length - 1];
	}

	list() {
		return this.undoHistory;
	}

	undo() {
		if (this.undoHistory.length > 0) {
			this.redoHistory.push(this.top());
			this.undoHistory.pop();
			return this.top();
		} else {
			return this.top();
		}
	}

	redo() {
		if (this.redoHistory.length !== 0) {
			const lastRedo = this.redoHistory[this.redoHistory.length - 1];
			this.undoHistory.push(lastRedo);
			this.redoHistory.pop();
		}

		return this.top();
	}
}
