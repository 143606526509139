import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import appStore from '@/stores/AppStore';
import BasicInput from '@/components/basic-input/BasicInput';
import DateInput from '@/components/date-input/DateInput';

@observer
export default class MemberDataLookup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			dobValid: false,
			datePickerOpen: _.clone(_.get(appStore, 'deviceDetails.desktop', false)),
			dob: null,
			dobTextDisplay: null,
			memberNumber: '',
			groupNumber: ''
		});
		this.text = getCmpText('EmployerSignup', {
			memberLabel: 'Member Number',
			memberPlaceholder: '000000',
			groupLabel: 'Group Number',
			groupPlaceholder: '000000',
			dobLabel: 'Date of Birth',
			dobPlaceholder: '01/01/1960'
		});
		autoBind(this);
	}

	componentDidMount() {
		this.props.formCallback({ values: _.cloneDeep(this.data), isValid: this.validateForm(), type: 'member' });
	}

	onDateChange(value, field, isValid) {
		this.data.dob = value;
		this.data.dobValid = isValid;
		this.props.formCallback({ values: _.cloneDeep(this.data), isValid: this.validateForm(), type: 'member' });
	}

	validateForm() {
		const dob = _.get(this.data, 'dob', '');
		const hasMemberNumber = _.get(this.data, 'memberNumber', '').length > 0;
		const hasGroupNumber = _.get(this.data, 'groupNumber', '').length > 0;
		const hasDOB = dob && dob.length > 0;
		const validDob = this.data.dobValid;
		return hasMemberNumber && hasGroupNumber && hasDOB && validDob;
	}

	onInputChange(evt) {
		const field = evt.currentTarget.name;
		const value = evt.currentTarget.value;
		this.data[field] = value;
		this.props.formCallback({ values: _.cloneDeep(this.data), isValid: this.validateForm(), type: 'member' });
	}

	render() {
		const { dob } = this.data;
		return (
			<div>
				<BasicInput
					label={this.text.memberLabel}
					name="memberNumber"
					onChange={this.onInputChange}
					value={this.data.memberNumber}
					type="text"
					placeholder={this.text.memberPlaceholder}
				/>
				<BasicInput
					onChange={this.onInputChange}
					label={this.text.groupLabel}
					name="groupNumber"
					value={this.data.groupNumber}
					type="text"
					placeholder={this.text.groupPlaceholder}
				/>
				<DateInput onChange={this.onDateChange} label={this.text.dobLabel} placeholder={this.text.dobPlaceholder} value={dob} />
			</div>
		);
	}
}
