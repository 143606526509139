import appStore from '../stores/AppStore';
import UserService from './UserService';
import providerStore from '../stores/ProviderStore';
import CloudAPI from './CloudAPI';
import AnalyticsService from './AnalyticsService';
import ConfigService from './ConfigService';
import _ from 'lodash';
import ExternalService from './ExternalService';
import doctorMale from '@/assets/doctor-male.svg';
import doctorFemale from '@/assets/doctor-female.svg';
import userStore from '@/stores/UserStore';
import { getCmpText } from '@/utils/Localization';
import { f7 } from 'framework7-react';

const analytics = new AnalyticsService();
class ProviderService {
	isInNetwork(provider) {
		let network = UserService.getHealthNetwork();
		//* no network means is consumer so everyone is 'in' or a tiered network we need to check for
		if (!network) {
			let tiers = UserService.getNetworkTiers();
			if (tiers && tiers.length > 0) {
				let pNet = _.get(provider, 'networks', []);
				let inNet = false;
				_.forEach(tiers, (t) => {
					if (pNet.indexOf(t.network) >= 0) {
						inNet = true;
					}
				});
				return inNet;
			}
			return true;
		}
		return _.get(provider, 'networks', []).indexOf(network) >= 0;
	}

	getLocationBookingConfig(provider, isCareTeam) {
		const providerSpecialties = provider.allSpecialties || [];
		const providerProfileConfigs =
			ConfigService.getConfigValue('providerProfile') || {};
		const overrides = _.get(providerProfileConfigs, 'overrides.booking') || {};
		const providerSpecialtiesWithOverrides = providerSpecialties.filter(
			(specialty) =>
				_.get(overrides, `${specialty}.enabled`) ||
				_.get(overrides, `${specialty}.disabled`) ||
				false
		);
		const entryPoint = isCareTeam ? 'careTeamCard' : providerStore.profileEntry;
		switch (entryPoint) {
			case 'manualSearch':
				const allSpecialtiesHaveOverride =
					providerSpecialtiesWithOverrides.length >= providerSpecialties.length;
				if (allSpecialtiesHaveOverride) {
					return (
						_.get(overrides, providerSpecialties[0]) || { disabled: false }
					);
				}
				return { disabled: false };
			case 'careTeam':
				const careTeamData = providerStore.careTeam || [];
				const providerCareTeamData =
					careTeamData.find((ctProvider) => ctProvider.NPI === provider.NPI) ||
					{};
				const careTeamSpecialty = providerCareTeamData.specialty;
				const careTeamSpecialtyOverride = _.get(
					overrides,
					careTeamSpecialty
				) || { enabled: false };
				return careTeamSpecialtyOverride.enabled
					? careTeamSpecialtyOverride
					: { disabled: false };
			case 'findCare':
				const currentCategory = providerStore.filters.category;
				const currentSpecialty = providerStore.filters.specialty;
				const categoryOverride = _.get(overrides, currentCategory);
				const categoryDisabled = _.get(
					overrides,
					`${currentCategory}.disabled`
				);
				if (categoryDisabled)
					return _.get(overrides, currentCategory) || { disabled: false };
				if (providerSpecialties.length > 1) {
					return _.get(overrides, currentSpecialty) || { disabled: false };
				}
				return providerSpecialtiesWithOverrides &&
					providerSpecialtiesWithOverrides.length > 0
					? _.get(overrides, providerSpecialtiesWithOverrides[0])
					: categoryOverride || { disabled: false };
			case 'careTeamCard':
				const specialtyOverride = _.get(overrides, provider.specialty) || {
					disabled: false
				};
				return specialtyOverride;
			default:
				return true;
		}
	}

	allowLocationCall(location, isCareTeam) {
		const providerProfileConfigs =
			ConfigService.getConfigValue('providerProfile') || {};
		const overrides = _.get(providerProfileConfigs, 'overrides.call') || {};
		const locationSpecialties = _.get(location, 'specialties') || [];
		const overrideKeys = Object.keys(overrides);
		const specialtiesWithOverrides = locationSpecialties
			.filter((specialty) => overrideKeys.indexOf(specialty) > -1)
			.filter((specialty) => overrides[specialty].disabled);
		const entryPoint = isCareTeam ? 'careTeamCard' : providerStore.profileEntry;
		switch (entryPoint) {
			case 'manualSearch':
				return locationSpecialties.length > specialtiesWithOverrides.length;
			case 'careTeam':
				return specialtiesWithOverrides.length < 1;
			case 'findCare':
				const currentCategory = providerStore.filters.category;
				const currentSpecialty = providerStore.filters.specialty;
				const categoryOverride = _.get(
					overrides,
					`${currentCategory}.disabled`
				);
				if (categoryOverride) return false;
				if (locationSpecialties.length > 1) {
					const filteredSpecialtyHasOverride =
						specialtiesWithOverrides.indexOf(currentSpecialty) > -1;
					return !filteredSpecialtyHasOverride;
				}
				return !specialtiesWithOverrides || specialtiesWithOverrides.length < 1;
			case 'careTeamCard':
				return locationSpecialties.length > specialtiesWithOverrides.length;
			default:
				return true;
		}
	}

	allowLocationNavigation(location, isCareTeam) {
		const providerProfileConfigs =
			ConfigService.getConfigValue('providerProfile') || {};
		const overrides =
			_.get(providerProfileConfigs, 'overrides.navigation') || {};
		const locationSpecialties = _.get(location, 'specialties') || [];
		const overrideKeys = Object.keys(overrides);
		const specialtiesWithOverrides = locationSpecialties
			.filter((specialty) => overrideKeys.indexOf(specialty) > -1)
			.filter((specialty) => overrides[specialty].disabled);
		const entryPoint = isCareTeam ? 'careTeamCard' : providerStore.profileEntry;
		switch (entryPoint) {
			case 'manualSearch':
				return locationSpecialties.length > specialtiesWithOverrides.length;
			case 'careTeam':
				return specialtiesWithOverrides.length < 1;
			case 'findCare':
				const currentCategory = providerStore.filters.category;
				const currentSpecialty = providerStore.filters.specialty;
				const categoryOverride = _.get(
					overrides,
					`${currentCategory}.disabled`
				);
				if (categoryOverride) return false;
				if (locationSpecialties.length > 1) {
					const filteredSpecialtyHasOverride =
						specialtiesWithOverrides.indexOf(currentSpecialty) > -1;
					return !filteredSpecialtyHasOverride;
				}
				return !specialtiesWithOverrides || specialtiesWithOverrides.length < 1;
			case 'careTeamCard':
				return locationSpecialties.length > specialtiesWithOverrides.length;
			default:
				return true;
		}
	}

	getNetworkData(provider) {
		const isConsumer = UserService.isConsumer();
		if (isConsumer) {
			return { inNetwork: true };
		}
		const isHybrid = UserService.isHybrid();
		if (isHybrid) {
			return { inNetwork: true, color: '#000' };
		}
		const network = UserService.getHealthNetwork();
		let inNetwork = this.isInNetwork(provider);
		if (!network) {
			const tiers = UserService.getNetworkTiers();
			if (!tiers) {
				console.error(
					'Something is not configured properly with network plan or tiers'
				);
				return;
			}
			let pNetworks = _.get(provider, 'networks', []);
			let bestTier = null;
			_.forEach(tiers, (t) => {
				if (pNetworks.indexOf(t.network) >= 0) {
					if (!bestTier) {
						bestTier = t;
					} else if (t.tier < bestTier.tier) {
						bestTier = t;
					}
				}
			});
			const colorMap = _.cloneDeep(
				ConfigService.getConfigValue('tierColorMap', {
					1: 'green',
					2: 'green-yellow',
					3: 'yellow',
					4: 'orange',
					5: 'red'
				})
			);
			const colorKeys = Object.keys(colorMap).map((x) =>
				Number(x.replace('tier', ''))
			);
			const colorValues = Object.values(colorMap);
			const bestTierVal = bestTier && bestTier.tier ? bestTier.tier : 5;
			const colorKey = colorKeys.indexOf(bestTierVal);
			const colorValue = colorKey > -1 ? colorValues[colorKey] : colorValues[5];
			const tierIcon = _.cloneDeep(
				ConfigService.getConfigValue(
					`tierIconMap.icon${_.get(bestTier, 'tier')}`
				)
			);
			return {
				tier: _.get(bestTier, 'tier'),
				network: _.get(bestTier, 'network'),
				inNetwork: inNetwork,
				color: colorValue,
				icon: tierIcon
			};
		}
		return {
			tier: null,
			network: network,
			inNetwork: inNetwork,
			color: inNetwork ? 'green' : 'red'
		};
	}

	getFormattedProviderLocations(providerData) {
		const provider = _.cloneDeep(providerData);
		if (provider) {
			provider.fullName = _.startCase(provider.fullName);
			const careTeam = _.get(providerStore, 'careTeam') || [];
			const locations = provider.locations || [];
			if (!locations || !Array.isArray(locations)) return [];
			const availableLocations = locations.filter(
				(location) => !location.suppressed
			);
			const locationsWithDistance = availableLocations.map((location) => {
				const distance = UserService.getDistanceFromLocation(location);
				location.distance = distance;
				return location;
			});
			const sortedLocations = locationsWithDistance.sort((a, b) =>
				Number(a.distance) > Number(b.distance) ? 1 : -1
			);
			const locationsWithNetworkInfo = sortedLocations.map((location) => {
				const networkData = this.getNetworkData({
					...provider,
					networks: location.networks
				});
				const inNetworkTier = (_.get(networkData, 'tier') || 5) < 4;
				const inNetwork = networkData.inNetwork || inNetworkTier;
				location.networkStatus = inNetwork;
				return location;
			});
			const locationsWithCareTeamData = locationsWithNetworkInfo.map(
				(location) => {
					const matchingCareTeam = careTeam.filter((careTeamLoc) => {
						const careTeamStreet = _.get(careTeamLoc, 'location.street1') || '';
						const careTeamStreetNumber = careTeamStreet.split(' ')[0];
						const thisStreet = _.get(location, 'street1') || '';
						const thisStreetNumber = thisStreet.split(' ')[0];
						const careTeamNPI = _.get(careTeamLoc, 'NPI');
						const thisNPI = _.get(location, 'NPI');
						const isLocationMatch =
							careTeamStreetNumber === thisStreetNumber &&
							careTeamNPI === thisNPI;
						return isLocationMatch;
					});
					const isOnCareTeam = matchingCareTeam && matchingCareTeam.length > 0;
					location.isCareTeam = isOnCareTeam;
					if (isOnCareTeam) {
						const careTeamSpecialty = matchingCareTeam[0].specialty;
						const filteredCareteamSpecialties = location.specialties.filter(
							(specialty) => specialty !== careTeamSpecialty
						);
						filteredCareteamSpecialties.unshift(careTeamSpecialty);
						location.specialties = filteredCareteamSpecialties;
					}
					return location;
				}
			);
			return locationsWithCareTeamData || [];
		}
	}
	getOpenProviderLocations() {
		const provider = _.cloneDeep(providerStore.openProvider);
		return provider.locations;
	}

	getOpenProviderLocationFilters() {
		const provider = _.cloneDeep(providerStore.openProvider) || {};
		const appliedFilters = provider.appliedFilters;
		const entryPoint = _.clone(providerStore.profileEntry);
		const finderFilters = _.cloneDeep(providerStore.filters);
		if (entryPoint === 'careTeam' && !appliedFilters) {
			const providerLocations = this.getOpenProviderLocations();
			const careTeamLocation = _.find(providerLocations, { isCareTeam: true });
			if (careTeamLocation) {
				const careTeamRadius = Math.ceil(careTeamLocation.distance / 5) * 5;
				const filtersToApply = {
					radius:
						careTeamLocation.distance > finderFilters.radius
							? careTeamRadius
							: finderFilters.radius,
					inNetwork: careTeamLocation.networkStatus,
					specialty:
						_.get(careTeamLocation, 'specialties[0]') || finderFilters.specialty
				};
				return filtersToApply;
			}
		}

		const filters = appliedFilters || finderFilters;
		const providerSpecialties = _.get(provider, 'allSpecialties') || [];
		const providerHasFilterSpecialty =
			providerSpecialties.indexOf(filters.specialty) > -1;
		if (!providerHasFilterSpecialty) {
			filters.specialty = providerSpecialties[0];
		}
		if (entryPoint === 'procedureSearch') {
			filters.radius = 100;
		}
		return filters;
	}

	formatProviderName(provider) {
		const providerFullName = _.get(provider, 'fullName') || '';
		const name = `${providerFullName.split(' llc').join(' LLC')}`;
		const credential = _.get(provider, 'personDetails.credential') || '';
		const nameParts = name.split(' ');
		const capitalizeParts = nameParts.map(
			(part) => `${part.charAt(0).toUpperCase()}${part.slice(1)}`
		);
		const capitalizeName = capitalizeParts.join(' ');
		const upperCaseCredential = credential.toUpperCase();
		return `${capitalizeName} ${upperCaseCredential}`;
	}

	getFilteredOpenProviderLocations(addToCareTeam, showAll) {
		const filters = this.getOpenProviderLocationFilters() || {};
		const modifiedFilters = _.get(providerStore, 'openProvider.appliedFilters');
		const searchRadius = filters.radius || 10;
		const entryPoint = addToCareTeam
			? 'addToCareTeam'
			: _.clone(providerStore.profileEntry);
		const locations = this.getOpenProviderLocations() || [];
		const filteredLocations = locations.filter((location) => {
			if (showAll || (modifiedFilters && modifiedFilters.showAllLocations))
				return true;
			const meetsRadiusCriteria = location.distance <= searchRadius;
			const meetsSpecialtyCriteria =
				location.specialties.indexOf(filters.specialty) > -1;
			const meetsNetworkCriteria = filters.inNetwork
				? location.networkStatus
				: true;
			const meetsBasicRequirements =
				meetsRadiusCriteria && meetsSpecialtyCriteria && meetsNetworkCriteria;
			if (modifiedFilters) {
				return meetsBasicRequirements;
			} else {
				switch (entryPoint) {
					case 'careTeam':
						return location.isCareTeam;
					case 'manualSearch':
						return meetsRadiusCriteria;
					default:
						return meetsBasicRequirements;
				}
			}
		});
		if (filteredLocations.length < 1 && locations.length > 0 && addToCareTeam) {
			filteredLocations.push(locations[0]);
		}
		const totalLocations = locations.length || 0;
		const locationsShown = filteredLocations.length || 0;
		const locationsFiltered = !showAll && locationsShown < totalLocations;
		return {
			locations: filteredLocations.slice(0, 99),
			isFiltered: locationsFiltered
		};
	}

	getRadiusConfig(category) {
		const { filters } = providerStore;
		const radiusConfigs = ConfigService.getConfigValue('search.radius', {});
		const defaultRadiusConfig = radiusConfigs.default;
		const categoryRadiusConfig = radiusConfigs[category || filters.category];
		const radiusConfig = categoryRadiusConfig || defaultRadiusConfig || {};
		return _.cloneDeep(radiusConfig);
	}

	getProviderImage(provider, noDefault) {
		let bdData = providerStore.openProviderBDData;
		let url = _.get(bdData, 'profile.image_url', '');
		if (
			(!noDefault && !url) ||
			url.indexOf('general_doctor_') >= 0 ||
			url === '(unknown)'
		) {
			return _.get(provider, 'personDetails.gender', 'm') === 'm'
				? doctorMale
				: doctorFemale;
		}
		return url
			.replace('asset1', 'asset2')
			.replace('asset3', 'asset2')
			.replace('asset4', 'asset2');
	}

	async searchForProviders(page) {
		console.time('search');
		const searchConfig = _.cloneDeep(
			_.get(ConfigService.getConfiguration(), 'search')
		);
		const filters = _.cloneDeep(providerStore.filters);
		const radiusConfig = this.getRadiusConfig();
		const configMaxRadius = radiusConfig.max || 50;
		if (!filters.radius || filters.radius > configMaxRadius) {
			filters.radius = radiusConfig.default || 10;
			providerStore.filters.radius = radiusConfig.default || 10;
		}
		let sortOptions = searchConfig.defaultSort;
		if (filters && !filters.inNetwork) {
			sortOptions = searchConfig.oonSort;
		}
		let { lat, lon } = appStore.currentLocation;
		const dto = filters;
		dto.coordinates = [lon, lat];
		let network = UserService.getHealthNetwork();
		if (!network) {
			let tiers = UserService.getNetworkTiers();
			if (tiers) {
				network = [];
				_.forEach(tiers, (t) => {
					network.push(t.network);
				});
			}
		}
		dto.network = network;
		dto.page = page;
		dto.sort = sortOptions;

		// * NOTE: this is so that we can correctly view the user's providers in admin mode
		if (appStore.demoMode) dto.demoMode = userStore.userData.demoHash;
		const searchOverrideConfigs = searchConfig.overrides || {};
		const searchOverrides = Object.keys(searchOverrideConfigs);
		const selectedSpecialty = _.get(dto, 'specialty') || '';
		const selectedCategory = _.get(dto, 'category') || '';
		const hasSpecialtyOverride =
			searchOverrides.indexOf(selectedSpecialty) > -1;
		const hasCategoryOverride = searchOverrides.indexOf(selectedCategory) > -1;
		if (hasSpecialtyOverride || hasCategoryOverride) {
			const overrideItem = hasSpecialtyOverride
				? selectedSpecialty
				: selectedCategory;
			const overrideConfig = searchOverrideConfigs[overrideItem];
			if (!!overrideConfig) {
				appStore.showExternalServiceMessage = true;
				appStore.externalServiceMessage = {
					icon: overrideConfig.icon,
					message: overrideConfig.message,
					title: overrideItem
				};
				providerStore.searchResults = [];
				providerStore.page = 0;
				this.buildMapData();
				return;
			}
		}
		try {
			dto.tierData = _.cloneDeep(
				_.get(userStore, 'userData.group.tiers') || []
			);
			const searchResults = await CloudAPI.providerSearch(dto);
			console.timeEnd('search');
			if (page > 0) {
				let existing = _.cloneDeep(providerStore.searchResults);
				_.forEach(searchResults, (rec) => {
					existing.push(rec);
				});
				providerStore.searchResults = existing;
				providerStore.page = page || 0;
			} else {
				providerStore.searchResults = searchResults;
				providerStore.page = 0;
			}
			this.buildMapData();
		} catch (err) {
			console.timeEnd('search');
			console.log('error getting search results: ', err);
			return err;
		}
	}

	buildMapData() {
		const fallBackLat =
			_.cloneDeep(
				_.get(ConfigService.getConfiguration(), 'map.defaultProps.center.lat')
			) || 27.964157;
		const fallBackLng =
			_.cloneDeep(
				_.get(ConfigService.getConfiguration(), 'map.defaultProps.center.lng')
			) || -82.452606;
		const { searchResults } = providerStore;
		const hasTelehealth = _.get(
			ConfigService.getConfiguration(),
			'features.telehealth'
		);
		let i = hasTelehealth ? 1 : 0;
		const locations = [
			{
				lat: Number(_.get(appStore, 'currentLocation.lat', fallBackLat)),
				lng: Number(_.get(appStore, 'currentLocation.lon', fallBackLng)),
				index: hasTelehealth ? 0 : null,
				id: 'user'
			}
		];
		_.forEach(searchResults, (p) => {
			let isInNetwork = this.isInNetwork(p);
			const npi = _.clone(p.NPI);
			_.forEach(p.locations, (l) => {
				let geo = _.get(l, `geoLocation.coordinates`, [
					fallBackLng,
					fallBackLat
				]);
				if (geo[0] !== NaN && geo[1] !== NaN) {
					locations.push({
						npi: npi,
						lat: Number(_.cloneDeep(geo[1])),
						lng: Number(_.cloneDeep(geo[0])),
						id: l._id,
						index: i,
						inNetwork: isInNetwork,
						networkData: this.getNetworkData(p)
					});
				}
			});
			i++;
		});
		providerStore.markerData = locations;
		providerStore.mapCenter = {
			lat: _.get(locations, '[0].lat', fallBackLat),
			lon: _.get(locations, '[0].lng', fallBackLng)
		};
	}

	openProviderProfile(provider, source) {
		const isInNetwork = this.getNetworkData(provider).inNetwork;
		const openProfile = () => {
			this.getProviderData(provider.NPI)
				.then((res) => {
					const responseData = _.cloneDeep(res);
					const formattedLocations =
						this.getFormattedProviderLocations(responseData);
					if (formattedLocations) {
						responseData.locations = formattedLocations;
					}
					providerStore.openProvider = responseData;
					providerStore.openProviderLoaded = true;
				})
				.catch((err) => {
					console.log(err);
				});
			providerStore.profileEntry = source;
			providerStore.openProvider = provider;
			appStore.openProviderProfile = true;
			globalRouter.navigate('/provider');
		};
		if (!isInNetwork) {
			let config = ConfigService.getConfiguration();
			const warningDisabled = _.get(
				config,
				'features.disableOONWarning',
				false
			);
			let TransparencySettingsText = getCmpText('TransparencySettings', {
				oonWarningText: ''
			});
			if (
				!warningDisabled &&
				!_.isEmpty(TransparencySettingsText.oonWarningText)
			) {
				//* Show OON Warning
				analytics.trackWithData('oon_warning', { npi: provider.npi });
				f7.dialog.alert(
					TransparencySettingsText.oonWarningText,
					'Warning!',
					openProfile
				);
			} else {
				openProfile();
			}
		} else {
			openProfile();
		}
	}

	isOnCareTeam(p) {
		let onTeam = _.find(providerStore.careTeam, { NPI: p.NPI });
		return !!onTeam;
	}

	getAddressText(l, removeStreet2) {
		return `${_.get(l, 'street1', '')} ${
			removeStreet2 ? '' : _.get(l, 'street2', '')
		} ${_.get(l, 'city', '')}, ${_.toUpper(_.get(l, 'state', ''))} ${_.get(
			l,
			'zip',
			''
		)}`;
	}

	async handleNavigationOptionClick(url, p, f7) {
		// shape may be different depending on where this is called from
		const providerData = {
			NPI: p.NPI,
			isPerson: p.isPerson,
			fullName: p.name || p.fullName,
			personDetails: {
				gender: p.gender
			}
		};
		if (p.personDetails) {
			providerData.personDetails = { ...p.personDetails };
		}
		const locationData = {
			...p.location
		};
		if (p.networks) {
			locationData.networks = p.networks;
		}
		const specialtiesToCheck = p.specialty || _.get(p, 'location.specialties');
		const shouldAutoAssignPcp =
			UserService.shouldAutoAssignPcp(specialtiesToCheck);
		ExternalService.handleExternalRequest('web', url);
		if (shouldAutoAssignPcp) {
			try {
				await UserService.autoAddProviderAsPcp(providerData, locationData);
			} catch (err) {
				console.log('unable to auto assign PCP: ', err);
			}
		}
	}

	openNavigation(p, f7, skipWarningCheck) {
		const isInNetwork = this.getNetworkData(p).inNetwork;
		const openNav = () => {
			const fallBackLat =
				_.cloneDeep(
					_.get(ConfigService.getConfiguration(), 'map.defaultProps.center.lat')
				) || 27.964157;
			const fallBackLng =
				_.cloneDeep(
					_.get(ConfigService.getConfiguration(), 'map.defaultProps.center.lng')
				) || -82.452606;
			analytics.track('opened_navigation');
			let pLocal = _.get(p, 'location.geoLocation.coordinates', [
				fallBackLat,
				fallBackLng
			]);
			let { lat, lon } = appStore.currentLocation;
			let buttons = [
				{
					text: 'Select Application',
					label: true
				},
				{
					text: 'Google Maps',
					icon: `<i class="fab fa-google nav-icons"></i>`,
					onClick: () => {
						const url = `https://maps.google.com/?q=${encodeURIComponent(
							this.getAddressText(p.location, true)
						)}`;
						this.handleNavigationOptionClick(url, p, f7);
					},
					bold: true
				}
			];
			if (appStore.isIos) {
				buttons.push({
					text: 'Apple Maps',
					icon: `<i class="fab fa-apple nav-icons"></i>`,
					onClick: () => {
						const url = `https://maps.apple.com/?q=${encodeURIComponent(
							this.getAddressText(p.location, true)
						)}`;
						this.handleNavigationOptionClick(url, p, f7);
					},
					bold: true
				});
			}
			if (appStore.isMobile) {
				buttons.push({
					text: 'Uber',
					icon: `<i class="fab fa-uber nav-icons"></i>`,
					onClick: () => {
						const url = `https://m.uber.com/ul/?client_id=4CWD0CWrqWwxhtEsKEe_C0pnQ9SQz_88&action=setPickup&pickup[latitude]=${lat}&pickup[longitude]=${lon}&dropoff[latitude]=${pLocal[1]}&dropoff[longitude]=${pLocal[0]}`;
						this.handleNavigationOptionClick(url, p, f7);
					},
					bold: true
				});
				buttons.push({
					text: 'Lyft',
					icon: `<i class="fab fa-lyft nav-icons"></i>`,
					onClick: () => {
						const url = `https://lyft.com/ride?id=lyft&pickup[latitude]=${lat}&pickup[longitude]=${lon}&partner=FZh6ICigrndH&&destination[latitude]=${pLocal[1]}&destination[longitude]=${pLocal[0]}`;
						this.handleNavigationOptionClick(url, p, f7);
					},
					bold: true
				});
			}
			f7.actions
				.create({
					cssClass: 'navigation-options-popup',
					buttons: [
						buttons,
						[
							{
								text: 'Cancel',
								color: 'red'
							}
						]
					]
				})
				.open();
		};
		if (skipWarningCheck) {
			openNav();
			return;
		}
		if (!isInNetwork) {
			let config = ConfigService.getConfiguration();
			const warningDisabled = _.get(
				config,
				'features.disableOONWarning',
				false
			);
			let TransparencySettingsText = getCmpText('TransparencySettings', {
				oonWarningText: ''
			});
			if (
				!warningDisabled &&
				!_.isEmpty(TransparencySettingsText.oonWarningText)
			) {
				//* Show OON Warning
				analytics.trackWithData('oon_warning', { npi: p.npi });
				f7.dialog.alert(
					TransparencySettingsText.oonWarningText,
					'Warning!',
					openNav
				);
			} else {
				openNav();
			}
		} else {
			openNav();
		}
	}

	getCleanName(p) {
		return `${_.startCase(
			_.get(p, 'fullName', _.get(p, 'name', '')).split(' llc').join(' LLC')
		)} ${_.get(p, 'personDetails.credential', '')}`;
	}

	getProviderData(npi) {
		return new Promise((resolve, reject) => {
			CloudAPI.getProvider(npi)
				.then((res) => {
					if (res.length > 0) {
						resolve(res[0]);
					} else {
						reject(`No provider found for NPI: ${npi}`);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getBetterDoctorData(npi) {
		return new Promise((resolve, reject) => {
			CloudAPI.getBetterDoctorData(npi)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

export default new ProviderService();
