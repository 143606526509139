// @ts-nocheck
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import { Button, f7, Link, Popup } from 'framework7-react';
import ExpressImportService from '@/services/ExpressImportService';
import ExpressAPI from '@/services/ExpressAPI';
import ImportProcessor from './import-processor/ImportProcessor';
import EDIMapper from './edi-mapper/EDIMapper';
import { format } from 'date-fns';
import './import-home.scss';
import InvalidRecords from './invalid-records/invalid-records';

const HeaderConfig = [
	{ key: 'relation', name: 'Relation' },
	{ key: 'memberId', name: 'Member ID' },
	{ key: 'personCode', name: 'Person Code' },
	{ key: 'groupId', name: 'Group ID' },
	{ key: 'healthPlan', name: 'Healthplan ID' },
	{ key: 'insuredFirstName', name: 'Insured First Name' },
	{ key: 'insuredMiddleName', name: 'Insured Middle Name' },
	{ key: 'insuredLastName', name: 'Insured Last Name' },
	{ key: 'firstName', name: 'Member First Name' },
	{ key: 'middleName', name: 'Member Last Name' },
	{ key: 'lastName', name: 'Member Middle Name' },
	{ key: 'ssn', name: 'SSN' },
	{ key: 'email', name: 'Email' },
	{ key: 'phone', name: 'Mobile Phone' },
	{ key: 'street', name: 'Address 1' },
	{ key: 'street2', name: 'Address 2' },
	{ key: 'city', name: 'City' },
	{ key: 'state', name: 'State' },
	{ key: 'zipcode', name: 'Zip Code' },
	{ key: 'gender', name: 'Gender' },
	{ key: 'dob', name: 'Birth Date' },
	{ key: 'coverageLevel', name: 'Coverage Level' },
	{ key: 'startDate', name: 'Start Date' },
	{ key: 'termDate', name: 'Term Date' }
];

const HeaderMap = {
	relation: 'Relation',
	memberId: 'Member Id',
	personCode: 'Person Code',
	groupId: 'Group Policy Number',
	healthPlan: 'Health Plan Id',
	insuredFirstName: 'Insured First Name',
	insuredMiddleName: 'Insured Middle Name',
	insuredLastName: 'Insured Last Name',
	firstName: 'Member First Name',
	middleName: 'Member Middle Name',
	lastName: 'Member Last Name',
	ssn: 'SSN',
	email: 'Email',
	phone: 'Mobile Phone',
	street: 'Address 1',
	street2: 'Address 2',
	city: 'City',
	state: 'State',
	zipcode: 'Zip Code',
	gender: 'Gender',
	dob: 'Birth Date',
	coverageLevel: 'Coverage Level',
	startDate: 'Start Date',
	termDate: 'Term Date'
};

@observer
export default class ImportHome extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			loadedFrom: null,
			fileImports: [],
			ediFormData: null,
			uploadOpen: false,
			activeImport: null,
			activePopupPage: 'upload',
			results: null
		});
		autoBind(this);
	}

	onUploadClick() {
		this.$f7.dialog.preloader('Uploading File');
		setTimeout(() => {
			this.$f7.dialog.close();
		}, 1000);
	}

	async processUpload(e) {
		this.$f7.dialog.close();
		this.$f7.dialog.preloader('Parsing File');
		const files = e.target.files;
		if (!files || !files[0]) {
			this.$f7.dialog.close(); //the user uploaded an improper file format & we rejected it
		}
		await ExpressImportService.processFileData(e, this.$f7, async (results) => {
			if (results) {
				results = _.cloneDeep(results);
				if (results.needsConfig) {
					this.data.ediFileElements = results.fileElements;
					this.data.activePopupPage = 'edimapper';
					this.data.ediFormData = results.formData;
					this.$f7.dialog.close();
				} else if (results?.invalid?.length > 0) {
					// TODO:
					this.data.results = results;
					this.data.activePopupPage = 'invalid';
					this.$f7.dialog.close();
				} else {
					try {
						await ExpressAPI.processValidMembers({
							members: results.valid,
							fileName: results.fileName
						});
						this.data.results = null;
						this.data.activePopupPage = 'upload';
						this.data.uploadOpen = false;
						this.$f7.dialog.close();
					} catch (err) {
						this.data.results = null;
						this.data.activePopupPage = 'upload';
						this.data.uploadOpen = false;
						showExpressError(this.$f7, err);
					}
				}
			} else {
				this.data.uploadOpen = false;
				this.$f7.dialog.close();
			}
		});
	}

	async downloadResource(type) {
		f7.dialog.preloader();
		let target = '';
		switch (type) {
			case 'guide': {
				target =
					'gs://carevalet-express.appspot.com/express/eligibility/CareValet Express Eligibility Companion Guide 1.4.pdf';
				break;
			}
			case 'template': {
				target =
					'gs://carevalet-express.appspot.com/express/eligibility/CareValet-Express-Eligibility-Template.xlsx';
				break;
			}
		}
		const gsReference = firebase.storage().refFromURL(target);
		const url = await gsReference.getDownloadURL();
		window.open(url, '_blank');
		f7.dialog.close();
	}

	asyncLoadImports() {
		setTimeout(this.loadImports, 0);
	}

	async loadImports() {
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		if (!this.data.loading) {
			this.data.loading = true;
			ExpressAPI.getMemberImports()
				.then((res) => {
					this.data.fileImports = res;
					this.data.loading = false;
				})
				.catch((err) => {
					this.data.loading = false;
					showExpressError(this.$f7, err);
				});
		}
	}

	openUpload() {
		this.data.uploadOpen = true;
	}

	closeUploadPopup() {
		this.data.ediFormData = null;
		this.data.uploadOpen = false;
		this.data.activePopupPage = 'upload';
	}

	handlePopupOpen() {}

	async onMappingComplete(config) {
		this.$f7.dialog.preloader('Importing');
		try {
			const results = await ExpressImportService.parseEDIFileToMembers(
				config,
				this.data.ediFormData
			);
			const cleanResults = await ExpressImportService.processEDIMembers(
				'First Express EDI File',
				results
			);
			if (cleanResults?.invalid?.length > 0) {
				this.data.results = cleanResults;
				this.data.activePopupPage = 'invalid';
				this.$f7.dialog.close();
			} else {
				try {
					await ExpressAPI.processValidMembers({
						members: cleanResults.valid,
						fileName: cleanResults.fileName
					});
					this.data.results = null;
					this.data.activePopupPage = 'upload';
					this.data.uploadOpen = false;
					this.$f7.dialog.close();
				} catch (err) {
					this.data.results = null;
					this.data.activePopupPage = 'upload';
					this.data.uploadOpen = false;
					showExpressError(this.$f7, err);
				}
			}
		} catch (err) {
			this.data.results = null;
			this.data.activePopupPage = 'upload';
			this.data.uploadOpen = false;
			showExpressError(this.$f7, err);
		}
		this.data.ediFormData = null;
	}

	getPopupContent() {
		switch (this.data.activePopupPage) {
			case 'upload': {
				return (
					<div className="main-content y-scroll">
						<div className="explainer">
							Below are links to our <strong>Express Eligibility</strong>{' '}
							companion guide, as well as our eligibility Excel template file.
							This format is the quickest way to get started working with
							Express.<br></br> <strong>NOTE:</strong> Express processes
							terminations by file absense. If you have previously included a
							member on a file, and any future files do not include that member,
							they will be scheduled to be termed.
						</div>
						<div className="links hbox vcenter hcenter">
							<Button
								large
								fill
								className="guide-btn round-btn ios"
								onClick={() => {
									this.downloadResource('guide');
								}}
							>
								<i className="fad fa-file-pdf"></i>
								Companion Guide
							</Button>
							<Button
								large
								fill
								className="template-btn round-btn ios"
								onClick={() => {
									this.downloadResource('template');
								}}
							>
								<i className="fad fa-file-excel" />
								Excel Template
							</Button>
						</div>
						<div className="explainer">
							If you are unable to use the express format you can also upload a
							traditional <strong>834 .x12</strong> file but there are
							additional steps required to work with that format.
						</div>
						<div className="upload-box vbox vcenter hcenter">
							<label htmlFor="uploadInput" className="custom-file-upload">
								<i className="fa fa-upload" />
								Click to Browse & Upload
							</label>
							<input
								type="file"
								id="uploadInput"
								onClick={this.onUploadClick}
								onChange={this.processUpload}
							></input>
						</div>
					</div>
				);
			}
			case 'edimapper': {
				return (
					<EDIMapper
						fileElements={this.data.ediFileElements}
						onComplete={this.onMappingComplete}
					></EDIMapper>
				);
			}
			case 'review': {
				return (
					<InvalidRecords
						records={this.data.results?.invalid}
						backText={`Back to Upload`}
						onBack={() => {
							this.data.results = null;
							this.data.activePopupPage = 'upload';
						}}
					/>
				);
			}
			case 'invalid': {
				return (
					<div className="main-content y-scroll">
						<div className="explainer center">
							We've found some records in this file contain missing or invalid
							data.
						</div>
						<div className="results hbox vcenter hcenter">
							<h2>
								<div className="invalid">
									{this.data.results?.invalid.length}
								</div>{' '}
								Invalid Records
							</h2>
							<h2>
								<div className="valid">{this.data.results?.valid.length}</div>{' '}
								Valid Records
							</h2>
						</div>
						<div className="explainer center">
							View the invalid record details and upload another file.
						</div>
						<div className="links hbox vcenter hcenter">
							<Button
								large
								fill
								className="round-btn ios"
								onClick={() => {
									this.data.activePopupPage = 'review';
								}}
							>
								Review Invalid Data
							</Button>
						</div>
						<Button
							large
							className="noshadow round-btn ios"
							onClick={() => {
								this.data.results = null;
								this.data.activePopupPage = 'upload';
							}}
						>
							Back to Upload
						</Button>
					</div>
				);
			}
		}
	}

	buildImportList() {
		if (!this.data.fileImports) {
			return '';
		}
		return (
			<div className="import-card-list">
				{_.sortBy(_.cloneDeep(this.data.fileImports), 'createdDate')
					.reverse()
					.map((i) => {
						let {
							fileName,
							createdDate,
							createdBy,
							manualOnlyCount,
							newCount,
							noGroupCount,
							noPlanCount,
							termedCount,
							updatedCount,
							totalMembers,
							primaryMembers,
							status,
							importedDate
						} = i;
						let statusLabel = status;
						const invalidCount = (_.get(i, 'invalid') || []).length;
						switch (status) {
							case 'canceled':
								statusLabel = 'Canceled';
								break;
							case 'incomplete':
								statusLabel = 'Missing Data';
								break;
							case 'reviewing':
								statusLabel = 'Ready to Review';
								break;
							case 'queued':
								statusLabel = 'Import Pending';
								break;
							default:
								'Imported';
						}
						return (
							<div
								className="import-list-item"
								key={i._id}
								onClick={() => {
									this.data.activeImport = i._id;
								}}
							>
								<div className="header hbox vcenter">
									<i className="fad fa-file-spreadsheet"></i>
									<div className="filename ellipse">{fileName}</div>
								</div>
								<div className="totals hbox vcenter hcenter">
									<div className="field vbox vcenter hcenter">
										<div className="label grow-1">Total:</div>
										<div className="value">{totalMembers}</div>
									</div>
									<div className="field vbox vcenter hcenter">
										<div className="label grow-1">Primary:</div>
										<div className="value">{primaryMembers}</div>
									</div>
									<div className="field vbox vcenter hcenter">
										<div className="label grow-1">Dependents:</div>
										<div className="value">{totalMembers - primaryMembers}</div>
									</div>
								</div>
								<div className="file-data hbox vcenter">
									<div className="left vbox vcenter">
										<div className="field hbox vcenter">
											<div className="label">Uploaded Date:</div>
											<div className="value small">
												{format(new Date(createdDate), 'M/dd/yy h:mm b')}
											</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">New Members:</div>
											<div className="value">{newCount}</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">Updated Members:</div>
											<div className="value">{updatedCount}</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">Termed Members:</div>
											<div className="value">{termedCount}</div>
										</div>
									</div>
									<div className="vbox vcenter">
										<div className="field hbox vcenter">
											<div className="label">Uploaded By:</div>
											<div className="value small">
												{_.capitalize(createdBy)}
											</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">Missing Group:</div>
											<div className="value">{noGroupCount}</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">Missing Plan:</div>
											<div className="value">{noPlanCount}</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">Manual Only:</div>
											<div className="value">{manualOnlyCount}</div>
										</div>
										<div className="field hbox vcenter">
											<div className="label grow-1">Invalid Members:</div>
											<div className="value">{invalidCount}</div>
										</div>
									</div>
								</div>
								<div className={`status ${status}`}>
									{statusLabel + ' '}
									{importedDate
										? format(new Date(importedDate), 'M/dd/yy h:mm b')
										: ''}
								</div>
							</div>
						);
					})}
			</div>
		);
	}

	render() {
		let { currentOrg } = expressStore;
		let { loadedFrom, fileImports, loading, uploadOpen, activeImport } =
			this.data;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoadImports();
		}
		return activeImport ? (
			<ImportProcessor
				importId={activeImport}
				onClose={() => {
					this.data.activeImport = null;
					this.loadImports();
				}}
			></ImportProcessor>
		) : (
			<div className="express-import-home-page">
				<h1 className="text-4 mb-xl grow-1">Import Data</h1>
				<div className="list-controls hbox vcenter">
					<div className="subtitle">
						You can bulk import/update/term your eligible members directly into
						our system by using the express eligibility import. Once imported
						into express member data is published nightly to the application or
						you can manually publish them in the Data Publisher utility.
					</div>
					<div className="grow-1"></div>
					<Button
						fill
						small
						className="list-btn ios refresh-btn"
						onClick={this.loadImports}
					>
						<i className="fa fa-sync-alt" />
						Refresh Imports
					</Button>
					<Button fill small className="list-btn ios" onClick={this.openUpload}>
						<i className="fa fa-upload" />
						Upload File
					</Button>
				</div>
				{!loading &&
					fileImports &&
					fileImports.length > 0 &&
					this.buildImportList()}
				{!loading && fileImports && fileImports.length === 0 && (
					<div className="empty-message vbox vcenter hcenter">
						<div className="icon-box hbox vcenter hcenter">
							<i className="fad fa-file-excel" />
							<i className="fad fa-file-spreadsheet"></i>
						</div>
						<h3>Upload your first file</h3>
						<Button
							fill
							small
							className="round-btn list-btn animated pulse infinite"
							onClick={this.openUpload}
						>
							<i className="fa fa-upload" />
							Upload File
						</Button>
					</div>
				)}
				<Popup
					opened={uploadOpen}
					className={`express-import-popup`}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupClosed={this.closeUploadPopup}
					onPopupOpen={this.handlePopupOpen}
				>
					<div className="popup-header">
						<div className="title grow-1">
							{this.data.activePopupPage === 'edimapper'
								? 'EDI Configration'
								: 'File Upload'}
						</div>
						<Link popupClose>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
					{uploadOpen && this.getPopupContent()}
				</Popup>
			</div>
		);
	}
}
