import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Button, Link, Popup } from 'framework7-react';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import ExpressAPI from '@/services/ExpressAPI';
import './change-id-card-popup.scss';
import IdCardDetail from '../id-detail/IdCardDetail';
@observer
export default class ChangeIdCardPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			idCardTemplates: [],
			loadingTemplates: false
		});
		autoBind(this);
	}

	onOpen() {
		const { frontId, backId } = this.props;
		this.props.changeIdData.templateIdFront = frontId;
		this.props.changeIdData.templateIdBack = backId;
		this.loadIdCards();
	}

	onClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	loadIdCards() {
		ExpressAPI.listIdCards('ALL', true)
			.then((res) => {
				this.data.idCardTemplates = res;
				this.data.loadingTemplates = false;
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
				this.data.loadingTemplates = false;
			});
	}

	render() {
		const { opened } = this.props;

		let cardFronts = {};
		let cardBacks = {};
		let templateMap = {};
		_.forEach(this.data.idCardTemplates, (t) => {
			templateMap[t._id] = t;
			if (t.side === 'front') {
				cardFronts[t._id] = t.name;
			}
			if (t.side === 'back') {
				cardBacks[t._id] = t.name;
			}
		});
		return (
			<Popup opened={opened} onPopupOpen={this.onOpen} onPopupClose={this.onClose}>
				<div className="change-id-card-popup">
					<div className="popup-header">
						<div className="title grow-1">Change ID Cards</div>
						<Link popupClose>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
					<div className="main-content y-scroll vbox hcenter">
						<ExpressFormBuilder
							formData={this.props.changeIdData}
							formConfig={{
								templateIdFront: {
									label: 'ID Card Template - Front',
									type: 'dropdown',
									className: 'dropdown-form-input',
									icon: 'fal fa-id-card',
									listItems: cardFronts
								}
							}}
						/>
						{opened && (
							<IdCardDetail
								loading={false}
								side="front"
								thumbnailKey={templateMap[this.props.changeIdData.templateIdFront]?.thumbnails.thumbnailMedium}
								size="medium"
								id="front-card"
							/>
						)}
						<ExpressFormBuilder
							formData={this.props.changeIdData}
							formConfig={{
								templateIdBack: {
									label: 'ID Card Template - Back',
									type: 'dropdown',
									className: 'dropdown-form-input',
									icon: 'fal fa-id-card',
									listItems: cardBacks
								}
							}}
						/>
						{opened && (
							<IdCardDetail
							loading={false}
							side="front"
							thumbnailKey={templateMap[this.props.changeIdData.templateIdBack]?.thumbnails.thumbnailMedium}
							size="medium"
							id="front-card"
						/>
						)}
					</div>
					<div className="btn-ctn">
						<Button fill large className="round-btn" onClick={this.props.onSave}>
							Save
						</Button>
					</div>
				</div>
			</Popup>
		);
	}
}
