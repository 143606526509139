import React, { Component } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import './credit-card-input.scss';
import { CardElement } from '@stripe/react-stripe-js';

@observer
export default class CreditCardInput extends Component {
	constructor(props) {
		super();
		autoBind(this);
	}

	onValidate(data) {
		if (this.props.onValidate) {
			this.props.onValidate(data);
		}
	}

	onFocus(evt) {
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	}

	onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	handleIconClick(evt) {
		if (this.props.iconAction) {
			this.props.iconAction(evt);
		}
	}

	render() {
		let { errorMessage, label, className, placeholder, onChange, icon } = this.props;
		return (
			<div className={`credit-card-input ${className ? className : ''}`}>
				{label && <div className="label">{label}</div>}
				{icon && (
					<div className="input-icon-holder" onClick={this.handleIconClick}>
						<i className="fal fa-calendar-alt" />
					</div>
				)}
				<CardElement
					options={{
						style: {
							base: {
								fontSize: '16px'
							},
							invalid: {
								color: '#9e2146'
							}
						}
					}}
					onChange={onChange}
				/>
				<div className="error-message">{errorMessage}</div>
			</div>
		);
	}
}
