import React, { Component, Fragment } from 'react';
import { observer, observable } from '../../utils/State';
import { autoBind, getFindCareIcon } from '../../utils/GeneralUtils';
import { Map, Overlay } from 'rgm';
import providerStore from '../../stores/ProviderStore';
import appStore from '../../stores/AppStore';
import _ from 'lodash';
import './site-map.scss';
import { mapStyles } from '../map/map-styles';
import ConfigService from '../../services/ConfigService';
import convertDistance from 'geolib/es/convertDistance';
const defaultProps = ConfigService.getConfigValue('map.defaultProps', {});
defaultProps.styles = mapStyles;

@observer
export default class SiteMap extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			mapApiLoaded: false,
			mapError: false,
			markerData: this.buildMapData(_.cloneDeep(props.locations))
		});
		this.originalCenter = {
			lat: Number(_.clone(_.get(appStore, 'currentLocation.lat', _.get(defaultProps, 'center.lat') || 27.964157))),
			lng: Number(_.clone(_.get(appStore, 'currentLocation.lon', _.get(defaultProps, 'center.lng') || -82.452606)))
		};
		this.mapRef = React.createRef();
		autoBind(this);
	}

	buildMapData(data) {
		const fallBackLat = _.cloneDeep(_.get(ConfigService.getConfiguration(), 'map.defaultProps.center.lat')) || 27.964157;
		const fallBackLng = _.cloneDeep(_.get(ConfigService.getConfiguration(), 'map.defaultProps.center.lng')) || -82.452606;
		let i = 0;
		const locations = [
			{
				lat: Number(_.get(appStore, 'currentLocation.lat', fallBackLat)),
				lng: Number(_.get(appStore, 'currentLocation.lon', fallBackLng)),
				index: 0,
				id: 'user'
			}
		];
		_.forEach(data, (l) => {
			let geo = _.get(l, `geoLocation.coordinates`, [fallBackLng, fallBackLat]);
			if (geo[0] !== NaN && geo[1] !== NaN) {
				locations.push({
					lat: Number(_.cloneDeep(geo[1])),
					lng: Number(_.cloneDeep(geo[0])),
					id: l._id,
					index: i,
					inNetwork: true
				});
			}
			i++;
		});
		providerStore.mapCenter = { lat: _.get(locations, '[0].lat', fallBackLat), lon: _.get(locations, '[0].lng', fallBackLng) };
		return locations;
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.data.markerData = this.buildMapData(_.cloneDeep(props.locations));
	}

	async componentDidMount() {
		if (window.google && window.google.maps) {
			this.handleMapLoaded();
		} else {
			if (!window.handleMapLoad) {
				window.handleMapLoad = this.handleMapLoaded;
			}
			this.$f7.dialog.preloader();
			const newElement = document.createElement('script');
			newElement.async = true;
			newElement.defer = true;
			newElement.setAttribute('importance', 'low');
			newElement.src = `https://maps.googleapis.com/maps/api/js?key=${defaultProps.googleMapKey}&callback=handleMapLoad`;
			newElement.addEventListener('load', this.handleMapLoaded);
			newElement.addEventListener('error', this.handleMapLoadError);
			if (document.body) {
				document.body.appendChild(newElement);
			}
		}
	}

	handleMapLoadError(err) {
		console.log('error loading map: ', err);
		this.data.mapError = true;
	}

	onMarkerClick(evt) {
		this.props.openLocation(evt.currentTarget.dataset.id);
	}

	buildLocationMarkers() {
		const { markerData } = this.data;
		const markers = [];
		markerData
			.slice()
			.reverse()
			.map((markerConfig) => {
				let iconClass = 'fas fa-map-marker-plus';
				const isUser = markerConfig.id === 'user';
				const isActive = markerConfig.npi && markerConfig.npi === _.get(providerStore, 'activeProvider.NPI');
				const activeClass = 'active animated infinite pulse';
				if (isUser) {
					iconClass = 'fad fa-street-view user-map-maper';
				}
				if (isActive && !isUser) {
					iconClass = 'fas fa-map-marker-alt';
				}
				if (!isUser) {
					markers.push(
						<div
							className="practice-location-marker"
							lat={markerConfig.lat}
							lng={markerConfig.lng}
							key={`${markerConfig.key}marker${markerConfig.id}`}
							data-index={markerConfig.index}
							data-id={markerConfig.id}
						>
							<i
								className={`fas fa-map-marker practice-location-marker bg ${isActive ? activeClass : ''}`}
								lat={markerConfig.lat}
								lng={markerConfig.lng}
								key={`${markerConfig.npi}-bg-marker-${markerConfig.id}`}
								data-index={markerConfig.index}
								data-id={markerConfig.id}
							/>
							<i
								lat={markerConfig.lat}
								lng={markerConfig.lng}
								className={`${!isActive ? _.get(markerConfig, 'networkData.color', '') : ''} practice-location-marker ${iconClass} ${
									isUser || isActive ? activeClass : ''
								}`}
								index={markerConfig.index}
								key={`${markerConfig.key}marker${markerConfig.id}`}
								onClick={this.onMarkerClick}
								data-index={markerConfig.index}
								data-id={markerConfig.id}
							/>
						</div>
					);
				} else {
					markers.push(
						<i
							lat={Number(_.get(appStore, 'currentLocation.lat', _.get(defaultProps, 'center.lat') || 27.964157))}
							lng={Number(_.get(appStore, 'currentLocation.lon', _.get(defaultProps, 'center.lng') || -82.452606))}
							className="fad fa-street-view user-map-maper animated infinite pulse"
							index={markerConfig.index}
							key={'you-marker-smile'}
							data-index={markerConfig.index}
						/>
					);
				}
			});
		return markers;
	}

	handleMapLoaded() {
		this.data.mapApiLoaded = true;
		this.data.mapError = false;
		setTimeout(() => {
			if (_.get(this.mapRef, 'current')) {
				this.mapRef.current.setZoom(10);
			}
			this.$f7.dialog.close();
		}, 300);
	}

	async setMapCenter() {
		if (_.get(this.mapRef, 'current')) {
			try {
				const currentCenter = await this.mapRef.current.getCenter();
				const currentLat = await currentCenter.lat();
				const currentLng = await currentCenter.lng();
				const nextLat = Number(_.clone(_.get(providerStore, 'mapCenter.lat', _.get(defaultProps, 'center.lat') || 27.964157)));
				const nextLng = Number(_.clone(_.get(providerStore, 'mapCenter.lon', _.get(defaultProps, 'center.lng') || -82.452606)));
				const shouldResetMapCenter = currentLat !== nextLat || currentLng !== nextLng;
				const userLat = Number(_.clone(_.get(appStore, 'currentLocation.lat', _.get(defaultProps, 'center.lat') || 27.964157)));
				const userLng = Number(_.clone(_.get(appStore, 'currentLocation.lon', _.get(defaultProps, 'center.lng') || -82.452606)));
				const isLocationChange = userLat === nextLat && userLng === nextLng;
				if (shouldResetMapCenter) {
					await this.mapRef.current.setOptions({
						center: {
							lat: Number(_.clone(_.get(providerStore, 'mapCenter.lat', _.get(defaultProps, 'center.lat') || 27.964157))),
							lng: Number(_.clone(_.get(providerStore, 'mapCenter.lon', _.get(defaultProps, 'center.lng') || -82.452606)))
						}
					});
				}
				if (isLocationChange && shouldResetMapCenter) {
					this.mapRef.current.setZoom(15);
				}
			} catch (err) {
				console.log('unable to reset map center: ', err);
			}
		}
	}

	render() {
		if (this.data.mapApiLoaded) {
			this.setMapCenter();
		}
		return (
			<div className="site-map">
				{this.data.mapApiLoaded && (
					<div className="map-container">
						<Map
							api={window.google.maps}
							ref={this.mapRef}
							options={{
								// cannot use state here as updates will cause errors - plugin requires us to manually call gmaps api to update options
								styles: defaultProps.styles[defaultProps.mapStyle],
								zoomControl: false,
								fullscreenControl: false,
								clickableIcons: false,
								zoom: defaultProps.zoom,
								disableDefaultUI: true,
								center: this.originalCenter,
								gestureHandling: 'greedy'
							}}
						>
							<Overlay>{this.buildLocationMarkers()}</Overlay>
						</Map>
					</div>
				)}
				{this.data.mapError && <div>Unable to load map</div>}
			</div>
		);
	}
}
