import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import './document-details.scss';
import DocumentPreview from '../document-preview/DocumentPreview';
import { Preloader } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import _ from 'lodash';
import expressStore from '@/stores/ExpressStore';
@observer
export default class DocumentDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			document: null,
			documents: [],
			downloading: false
		});
		autoBind(this);
	}

	componentDidMount() {
		const { document } = this.props;
		ExpressAPI.getDocument(document._id)
			.then((res) => {
				let document = {};
				document.id = res._id;
				document.uri = res.fileUrl;
				document.fileType = res.fileData.fileType;
				document.name = res.name;
				document.fileExtension = res.fileData.fileExtension;
				this.data.documents = [document];
			})
			.catch((e) => {
				showExpressError(e);
			});
	}

	formatSize(bytes) {
		const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
		if (bytes == 0) return '0 byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	}

	async downloadInit() {
		this.data.downloading = true;
		try {
			const documentBlob = await (await fetch(this.data.documents[0].uri)).blob();
			const documentUrl = URL.createObjectURL(documentBlob);
			const name = this.data.documents[0].name;
			const ext = this.data.documents[0].fileExtension;
			const downloadLink = document.createElement('a');
			downloadLink.download = `${name}.${ext}`;
			downloadLink.href = documentUrl;
			downloadLink.classList.add('external');
			document.body.appendChild(downloadLink);
			this.data.downloading = false;
			downloadLink.click();
			document.body.removeChild(downloadLink);
		} catch (err) {
			console.log('error downloading: ', err);
			this.data.downloading = false;
			this.$f7.dialog.alert('Error downloading document.');
		}
	}

	render() {
		const { document, onDocumentDelete } = this.props;
		const canDownload = this.data.documents && this.data.documents.length > 0;
		const isDownloading = this.data.downloading;
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		return (
			<div className="document-details">
				<Breadcrumb
					pageName="documents"
					config={{
						0: {
							name: 'All Documents',
							icon: 'fas fa-file',
							onClick: () => {
								this.props.onDocumentBack();
							}
						},
						1: {
							name: this.props.document.name,
							onClick: () => {
								console.log('hello');
							}
						}
					}}
					currentKey={1}
				/>
				<div className="card">
					<div className="card-header">
						<div className="title grow-2">Document Detail</div>
						<div className="document-actions">
							{canDownload && !isDownloading && (
								<i className="fad fa-download" title="Download Document" onClick={this.downloadInit}></i>
							)}
							{canDownload && isDownloading && <Preloader color="blue" size={18} />}
							{canEdit && <i className="fad fa-trash" title="Delete Document" onClick={() => onDocumentDelete(document)}></i>}
						</div>
					</div>
					<div className="documents-body">
						<div className="card-fields document-fields">
							<div className={`card-field`}>
								<div className="label">Document name:</div>
								<div className="value">{document.name}</div>
							</div>
							<div className={`card-field`}>
								<div className="label">Document type:</div>
								<div className="value">{document.type}</div>
							</div>
							<div className={`card-field`}>
								<div className="label">Last updated:</div>
								<div className="value">{document.lastUpdated}</div>
							</div>
							<div className={`card-field`}>
								<div className="label">Last updated by:</div>
								<div className="value">{document.lastUpdatedBy}</div>
							</div>
							<div className={`card-field`}>
								<div className="label">File type:</div>
								<div className="value">{document.fileData.fileType}</div>
							</div>
							<div className={`card-field`}>
								<div className="label">File size:</div>
								<div className="value">{this.formatSize(document.fileData.fileSize)}</div>
							</div>
							<div className={`card-field`}>
								<div className="label">File name:</div>
								<div className="value">{document.fileData.fileName}</div>
							</div>
						</div>
					</div>
				</div>
				{!isDownloading && <DocumentDetailPreview documents={this.data.documents} />}
			</div>
		);
	}
}

export const DocumentDetailPreview = (props) => {
	const { documents } = props;
	if (documents && documents.length) {
		return (
			<div className="card document-preview-card">
				<div className="card-header">
					<div className="title grow-2">Document Preview</div>
				</div>
				<div className="preview-body">
					<DocViewer
						config={{
							header: {
								disableHeader: true,
								disableFileName: true,
								retainURLParams: false
							}
						}}
						pluginRenderers={DocViewerRenderers}
						documents={_.cloneDeep(documents)}
						className="doc-viewer"
						id={documents[0].id}
					/>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};
