import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import expressStore from '@/stores/ExpressStore';
import { format } from 'date-fns';
import { autoBind, showExpressError, formatPhoneNumber } from '@/utils/GeneralUtils';
import { Popup } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';
import './network-details.scss';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import NetworkRequestForm from '../network-request-form/NetworkRequestForm';

@observer
export default class NetworkDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			networkEditOpened: null,
			networkChangeOpened: false,
			availableNetworks: {},
			editReady: false,
			editFormData: {
				type: 'traditional',
				name: '',
				request: '',
				contactName: '',
				contactPhone: '',
				contactEmail: ''
			}
		});
		autoBind(this);
	}

	componentDidMount() {
		this.data.editFormData = {
			type: _.get(this.props, 'network.type') || 'traditional',
			name: _.get(this.props, 'network.name'),
			request: _.get(this.props, 'network.request'),
			contactName: _.get(this.props, 'network.contactName'),
			contactPhone: _.get(this.props, 'network.contactPhone'),
			contactEmail: _.get(this.props, 'network.contactEmail'),
			tiers: _.get(this.props, 'network.tiers'),
			oonConfig: _.get(this.props, 'network.oonConfig')
		};
		this.data.editReady = true;
	}

	onSaveClose() {
		this.data.networkEditOpened = false;
		this.data.networkChangeOpened = false;
		this.data.editFormData = {
			name: '',
			request: '',
			contactName: '',
			contactPhone: '',
			contactEmail: ''
		};
	}

	onSaveNetwork(data) {
		if (this.data.networkChangeOpened) {
			this.onSaveClose();
			if (this.props.onSave) {
				this.props.onSave(data, true);
			}
		} else {
			const originalNetworkId = _.get(this.props, 'network.networkId');
			const originalNetworkExpressId = _.get(this.props, 'network._id');
			const networkDetails = {
				...data,
				networkId: !this.data.networkChangeOpened ? originalNetworkExpressId : data.networkId,
				existingNetwork: true
			};
			this.$f7.dialog.preloader('Saving...');
			ExpressAPI.saveNetwork(networkDetails)
				.then((res) => {
					this.onSaveClose();
					if (this.props.onSave) {
						this.props.onSave(res, false);
					}
					this.$f7.dialog.close();
				})
				.catch((err) => {
					showExpressError(this.$f7, err, '');
				});
		}
	}

	onEditNetworkClick() {
		this.data.networkEditOpened = true;
	}

	networkChangeInit() {
		this.data.networkChangeOpened = true;
	}

	buildNetworkDetailsCard(reqData) {
		const network = reqData ? reqData : _.cloneDeep(this.props.network);
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		const cardConfig = {
			name: { label: 'Network Name' },
			status: { label: 'Status' },
			type: { label: 'Network Type' },
			lastUpdated: { label: 'Last Updated' },
			requestedDate: { label: 'Requested Date' },
			contactName: { label: 'Contact Name' },
			contactEmail: { label: 'Contact Email' },
			contactPhone: { label: 'Contact Phone' },
			request: { label: 'Request Details', standalone: true }
		};
		const formatValue = (key, value) => {
			if (['status', 'type'].indexOf(key) >= 0) {
				return _.capitalize(value);
			}
			if (['lastUpdated', 'requestedDate'].indexOf(key) >= 0) {
				return format(new Date(value), 'MM/dd/yyyy');
			}
			if (key === 'contactPhone') {
				return formatPhoneNumber(value);
			}
			return value;
		};

		return (
			<div className="network-details-card animated fadeInUp" key={`network-card-${network._id}`}>
				<div className="card-header">
					<div className="title grow-1">Network Details{network.tier ? ` - Tier ${network.tier}` : ''}</div>
					{this.props.canChange && canEdit && (
						<i className="fad fa-exchange" title="Change Network" onClick={this.networkChangeInit}></i>
					)}
					{canEdit && <i className="fad fa-pen" title="Edit Network" onClick={this.onEditNetworkClick}></i>}
				</div>
				<div className="card-fields">
					{Object.keys(cardConfig).map((fieldName) => {
						let value = network[fieldName];
						let config = cardConfig[fieldName];
						if (_.isEmpty(value)) {
							return '';
						}
						return (
							<div key={`${network._id}-${fieldName}`} className={`card-field ${config.standalone ? 'standalone' : ''}`}>
								<div className="label">{config.label}:</div>
								<div className="value">{formatValue(fieldName, value)}</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	getBreadCrumb() {
		let currentKey = 0;
		if (this.props.network) {
			currentKey++;
		}
		return (
			<Breadcrumb
				pageName="networks"
				config={{
					0: {
						name: 'All Networks',
						icon: 'fad fad fa-hospital-user',
						onClick: () => {
							if (this.props.closeDetails) {
								this.props.closeDetails();
							}
						}
					},
					1: {
						name: this.props.network?.name,
						onClick: () => {}
					}
				}}
				currentKey={currentKey}
			/>
		);
	}

	//TODO build notes from network request
	buildNotes() {
		return '';
	}

	editOonConfig(data) {
		console.log('open that ish');
	}

	render() {
		let { networkEditOpened, editFormData, networkChangeOpened } = this.data;
		const isNoNetwork = _.get(expressStore, 'activePlan.type') === 'none';
		return (
			<div className={`express-network-details ${expressStore.activePage === 'networks' ? 'standalone' : 'subpage'}`}>
				{this.props.showBreadCrumb && this.getBreadCrumb()}
				{this.props.network && this.buildNetworkDetailsCard()}
				{isNoNetwork && (
					<div className="network-details-card animated fadeInUp">
						<div className="card-header">
							<div className="title grow-1">Network Details</div>
							<i className="fad fa-pen" title="Edit Network" onClick={this.editOonConfig}></i>
						</div>
						<div className="card-fields">This plan has no attached networks, but you can edit the way providers display here.</div>
					</div>
				)}
				{this.buildNotes()}
				<Popup
					className={`express-form-popup`}
					opened={networkEditOpened || networkChangeOpened}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupClosed={() => {
						this.data.networkEditOpened = false;
						this.data.networkChangeOpened = false;
						this.data.editFormData = {
							type: 'traditional',
							networkId: 0,
							name: '',
							contactName: '',
							contactPhone: '',
							contactEmail: '',
							request: '',
							existingNetwork: false
						};
					}}
				>
					{this.data.editReady && (
						<NetworkRequestForm
							network={this.props.network}
							onSubmit={this.onSaveNetwork}
							standalone
							editOnly={!networkChangeOpened}
							disableEditExisting={networkChangeOpened}
						/>
					)}
				</Popup>
			</div>
		);
	}
}
