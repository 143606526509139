import { observable, observer } from '@/utils/State';
import { Link, List, ListItem, Popover } from 'framework7-react';
import React, { Component } from 'react';

@observer
export default class FontFamily extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
	}

	renderFontOptions() {
		const { fontOptions, onChange } = this.props;
		let options = [];
		if (fontOptions) {
			fontOptions.forEach((option, key) => {
				options.push(
					<ListItem popoverClose link="#" key={key} onClick={() => onChange(option.font)}>
						{option.font}
					</ListItem>
				);
			});
		}

		return options;
	}
	render() {
		const { fontFamily } = this.props;
		return (
			<div className="vbox property-section">
				<div className="property-label">Font Face</div>
				<div className="property-edit">
					<Link className="drop-down hbox vcenter" popoverOpen=".panel-property-font-family">
						<div className="selected grow-1">{fontFamily}</div>
						<div className="fa fa-angle-down"></div>
					</Link>
				</div>
				<Popover className="panel-property-font-family">
					<List>{this.renderFontOptions()}</List>
				</Popover>
			</div>
		);
	}
}
