import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import './properties-panel.scss';
import FontSizeInput from './font-size-input/FontSizeInput';
import FontFamily from './font-family/FontFamily';
import FontStyle from './font-style/FontStyle';
import FontAlign from './font-align/FontAlign';
import ColorPicker from './color-picker/ColorPicker';
import { autoBind } from '@/utils/GeneralUtils';
import ProjectBackground from './project-background/ProjectBackground';
import TransformProperties from './position/TransformProperties';
import CanvasFactory from '@/factories/CanvasFactory';
import { SELECT_MODE } from '@/services/CanvasEditorService';
import CornerRadius from './corner-radius/CornerRadius';
import VariableField from './VariableField/VariableField';
import { Popover } from 'framework7-react';
import windowStore from '@/stores/WindowStore';
@observer
export default class PropertiesPanel extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});

		autoBind(this);
	}

	renderProperties() {
		const {
			editorStore: { nodeData, selectedNodes },
			setNodeProperty,
			getFonts
		} = CanvasFactory.getActiveService();
		const selectedNode = nodeData[selectedNodes[0]];
		const fontOptions = getFonts();
		let tools = [];

		if (selectedNode) {
			tools.push(<TransformProperties onPropertyChange={setNodeProperty} selectedNode={selectedNode} key="transform" />);
			if (selectedNode.type === 'variable') {
				tools.push(<VariableField fieldType={selectedNode.variableType} fieldValue={selectedNode.text} />);
			}
			Object.keys(selectedNode).map((property) => {
				switch (property) {
					case 'fontSize':
						tools.push(
							<FontSizeInput
								fontSize={selectedNode[property]}
								onChange={(value) => {
									setNodeProperty(property, value);
									setNodeProperty('height', 'auto');
								}}
								key={property}
							/>
						);
						break;
					case 'fontFamily':
						tools.push(
							<FontFamily
								fontOptions={fontOptions}
								fontFamily={selectedNode[property]}
								onChange={(value) => {
									setNodeProperty(property, value);
								}}
								key={property}
							/>
						);
						break;
					case 'fontStyle':
						tools.push(
							<FontStyle
								onChange={(value) => {
									setNodeProperty('fontStyle', value);
								}}
								onDecorationChange={(value) => {
									setNodeProperty('textDecoration', value);
								}}
								textDecoration={selectedNode['textDecoration']}
								fontStyle={selectedNode['fontStyle']}
								key={property}
							/>
						);
						break;
					case 'align':
						tools.push(
							<FontAlign
								onChange={(value) => {
									setNodeProperty(property, value);
								}}
								align={selectedNode[property]}
								key={property}
							/>
						);
						break;
					case 'fill':
						tools.push(
							<ColorPicker
								onChange={(value) => {
									setNodeProperty(property, value);
								}}
								color={selectedNode[property]}
								onClickEyeDropper={this.props.activateEyeDropper}
							/>
						);
						break;
					case 'cornerRadius': {
						tools.push(<CornerRadius />);
					}
				}
			});
		}

		return tools;
	}

	renderProjectSettings() {
		const { editorStore, setBackground, onAdjustBackground } = CanvasFactory.getActiveService();
		return (
			<div>
				<ProjectBackground />
			</div>
		);
	}

	renderHeader() {
		const {
			editorStore: { nodeData, selectedNodes, selectMode }
		} = CanvasFactory.getActiveService();
		const selectedNode = selectMode !== SELECT_MODE.EMPTY ? nodeData[selectedNodes[0]] : {};
		switch (selectedNode?.type) {
			case 'rectangle':
				return (
					<div className="hbox properties-type vcenter">
						<div className="type-icon">
							<i className="fad fa-square"></i>
						</div>
						<div className="type-title">Rectangle Settings</div>
					</div>
				);
			case 'image':
				return (
					<div className="hbox properties-type vcenter">
						<div className="type-icon">
							<i className="fad fa-image"></i>
						</div>
						<div className="type-title">Image Settings</div>
					</div>
				);
			case 'text':
				return (
					<div className="hbox properties-type vcenter">
						<div className="type-icon">
							<i className="fad fa-text"></i>
						</div>
						<div className="type-title">Text Settings</div>
					</div>
				);
			case 'circle':
				return (
					<div className="hbox properties-type vcenter">
						<div className="type-icon">
							<i className="fad fa-circle"></i>
						</div>
						<div className="type-title">Circle Settings</div>
					</div>
				);
			case 'variable':
				return (
					<div className="hbox properties-type vcenter">
						<div className="type-icon">
							<i className={`fad fa-${selectedNode.variableType === 'member' ? 'users' : 'file-plus'}`}></i>
						</div>
						<div className="type-title">{selectedNode.variableType} Field Settings</div>
					</div>
				);
			default:
				return (
					<div className="hbox properties-type vcenter">
						<div className="type-icon">
							<i className="fad fa-cogs"></i>
						</div>
						<div className="type-title">Project Settings</div>
					</div>
				);
		}
	}

	renderPanel() {
		const { selectMode, selectedNodes } = CanvasFactory.getActiveStore();
		if (selectMode === SELECT_MODE.SINGLE && selectedNodes) {
			return <>{this.renderProperties()}</>;
		} else {
			return <>{this.renderProjectSettings()}</>;
		}
	}

	render() {
		return (
			<div className="properties-panel">
				{this.renderHeader()}
				{this.renderPanel()}
			</div>
		);
	}
}
