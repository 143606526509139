import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import Table from '@/components/_EXPRESS/table/Table';
import NetworkDetails from '@/pages/express/networks/network-details/NetworkDetails';
import './network-admin.scss';

@observer
export default class NetworkAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentNetwork: null,
			networks: []
		});
		autoBind(this);
	}

	async componentDidMount() {
		const networks = await this.getNetworks();
		this.data.networks = networks;
	}

	async getNetworks() {
		this.$f7.dialog.preloader();
		try {
			const networks = await ExpressAPI.getAllNetworks();
			this.$f7.dialog.close();
			return networks;
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error loading pending networks');
		}
	}

	handleRequestClick(data) {
		const networkId = data.id;
		const networkData = _.find(this.data.networks, { _id: networkId });
		this.data.currentNetwork = networkData;
	}

	async saveNetworkChange(data) {
		const newNetworks = await this.getNetworks();
		this.data.currentNetwork = _.cloneDeep(data);
		this.data.networks = _.cloneDeep(newNetworks);
	}

	closeNetworkDetails() {
		this.data.currentNetwork = null;
	}

	render() {
		const loading = false;
		const showNetworkList = !this.data.currentNetwork;
		const showNetworkDetails = !!this.data.currentNetwork;
		const hasNetworks = this.data.networks && this.data.networks.length > 0;
		const networks = this.data.networks || [];
		const tableHeaders = [
			{ text: 'Name', classList: 'ellipse', canSearch: true },
			{ text: 'Org', classList: 'ellipse', canSearch: true },
			{ text: 'Last Updated' },
			{ text: 'Status', canSearch: true }
		];
		const tableRows = networks.map((network) => {
			return {
				id: network._id,
				onClick: this.handleRequestClick,
				items: [
					{ text: network.name, classList: 'ellipse network-name capitalize' },
					{ text: network.org, classList: 'capitalize ellipse' },
					{ text: network.lastUpdated },
					{ classList: 'capitalize', text: network.status }
				]
			};
		});
		return (
			<div className="admin-network-container">
				{showNetworkDetails && (
					<NetworkDetails
						network={this.data.currentNetwork}
						onSave={this.saveNetworkChange}
						closeDetails={this.closeNetworkDetails}
						showBreadCrumb={true}
					/>
				)}
				{showNetworkList && (
					<div className="express-networks-list">
						<h2 className="grow-1">Network Requests</h2>
						{!hasNetworks && !loading && (
							<div className="empty-message vbox vcenter hcenter">
								<div className="icon-box hbox vcenter hcenter">
									<i className="fad fa-users-class" />
								</div>
								<h3>There are no network requests yet. Check back later!</h3>
							</div>
						)}
						{hasNetworks && <Table id="network-admin-table" headers={tableHeaders} rows={tableRows} />}
					</div>
				)}
			</div>
		);
	}
}
