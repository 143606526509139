// @ts-nocheck

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../utils/State';
import { autoBind } from '../../../utils/GeneralUtils';
import adminStore from '../../../stores/AdminStore';
import appStore from '../../../stores/AppStore';
import { Button, Panel, Fab, Badge } from 'framework7-react';
import CloudAPI from '../../../services/CloudAPI';
import FilterMenu from './filter-menu/FilterMenu';
import GroupsData from './groups-data/GroupsData';
import SearchForm from './search-form/SearchForm';
import MessagingForm from '../../../components/messaging-form/MessagingForm';
import './group-mgmt.scss';
import AdminService from '../../../services/AdminService';

@observer
export default class GroupManagement extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			groups: [],
			organizations: [],
			filters: null,
			showSearchForm: false,
			showFilterMenu: false,
			showNotifcationForm: false,
			notificationData: {}
		});
		autoBind(this);
	}

	async componentDidMount() {
		await this.getGroups();
	}
	componentWillUnmount() {
		AdminService.messagesRemoveAllPending();
	}
	async getOrgs() {
		this.$f7.dialog.preloader();
		try {
			const orgs = await CloudAPI.getOrganizations();
			const networks = await CloudAPI.getNetworks();
			this.data.organizations = orgs;
			this.data.networks = networks;
			this.$f7.dialog.close();
		} catch (err) {
			console.log({ err });
			this.$f7.dialog.close();
			this.$f7.dialog.alert(`Error Loading Organizations`);
		}
	}

	async getGroups() {
		this.$f7.dialog.preloader('Loading');
		try {
			const groups = await CloudAPI.getGroups();
			this.data.groups = groups;
			this.$f7.dialog.close();
		} catch (err) {
			console.log({ err });
			this.$f7.dialog.close();
			this.$f7.dialog.alert(`Error Loading Data`);
		}
	}

	async getGroupMembers(groupUid) {
		this.$f7.dialog.preloader('Loading Members');
		const filters = _.cloneDeep(this.data.filters);
		const groups = _.cloneDeep(this.data.groups) || [];
		try {
			const members = await CloudAPI.getGroupMembers(groupUid, filters);
			const groupsWithMembers = groups.map((group) => {
				if (group.groupUid === groupUid) {
					group.members = members;
				}
				return group;
			});
			this.data.groups = groupsWithMembers;
			this.$f7.dialog.close();
		} catch (err) {
			console.log({ err });
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error Loading Members');
		}
	}

	async showFilterMenu() {
		this.data.showSearchForm = false;
		this.data.showMessagingForm = false;
		this.data.showFilterMenu = true;
		const hasOrgs = _.get(this.data, 'organizations', []).length > 0;
		if (!hasOrgs) {
			await this.getOrgs();
		}
		this.$f7.panel.open('left');
	}

	showSearchForm() {
		this.data.showFilterMenu = false;
		this.data.showMessagingForm = false;
		this.data.showSearchForm = true;
		this.$f7.panel.open('left');
	}

	getNotificationOptions(members) {
		const options = AdminService.getMessagingOptions(recipients);
		return options;
	}

	openMessagingForm() {
		this.data.showFilterMenu = false;
		this.data.showSearchForm = false;
		this.data.showMessagingForm = true;
	}

	closePanel() {
		this.data.showFilterMenu = false;
		this.data.showSearchForm = false;
		this.data.showMessagingForm = false;
		this.$f7.panel.close('left');
	}

	closeMessagingForm() {
		this.data.showFilterMenu = false;
		this.data.showSearchForm = false;
		this.data.showMessagingForm = false;
	}

	showConsumers() {
		this.searchMembers({
			orgs: ['5daa40c4583d0378fc8ba816'],
			groups: ['5ea9a65e625eab6b186a0dff'],
			networks: [],
			registeredStatus: 'all',
			memberStatus: 'all',
			showZeroMembers: false,
			showPcpOnly: false,
			relationship: 'all',
			hasPhone: false,
			hasEmail: false,
			lastLoginRange: [],
			registeredRange: []
		});
	}

	async searchMembers(searchData) {
		const currentFilters = _.cloneDeep(this.data.filters);
		const currentSearch = _.get(searchData, 'searchForm', {});
		const searchKeys = Object.keys(currentSearch).filter((x) => !!currentSearch[x]);
		const newFilters = {
			...currentFilters,
			...searchData
		};
		if (searchKeys && searchKeys.length < 1) {
			delete newFilters.searchForm;
		}
		if (newFilters) {
			this.$f7.dialog.preloader('Searching for Members');
			try {
				const newGroups = await CloudAPI.searchMembers(newFilters);
				this.data.groups = newGroups;
				this.$f7.dialog.close();
				this.data.filters = newFilters;
			} catch (err) {
				console.log({ err });
				this.$f7.dialog.close();
				this.$f7.dialog.alert(`Error Retrieving Members: ${err}`);
			}
		} else {
			this.getGroups();
		}
		this.closePanel();
	}

	async resetFilters() {
		this.data.filters = null;
		await this.getGroups();
		this.closePanel();
	}

	getFilterCount() {
		const { filters } = this.data;
		const searchData = _.get(filters, 'searchForm', {});
		const searchKeys = Object.keys(searchData).filter((x) => !!searchData[x]);
		let filterCount = 0;
		if (filters && filters.registeredStatus && filters.registeredStatus !== 'all') {
			filterCount++;
		}
		if (filters && filters.memberStatus && filters.memberStatus !== 'all') {
			filterCount++;
		}
		if (filters && filters.showPcpOnly) {
			filterCount++;
		}
		if (filters && filters.hasEmail) {
			filterCount++;
		}
		if (filters && filters.hasPhone) {
			filterCount++;
		}
		if (filters && filters.lastLoginRange && filters.lastLoginRange.length > 0) {
			filterCount++;
		}
		if (filters && filters.registeredRange && filters.registeredRange.length > 0) {
			filterCount++;
		}
		if (filters && filters.orgs && filters.orgs.length > 0) {
			filterCount++;
		}
		if (filters && filters.groups && filters.groups.length > 0) {
			filterCount++;
		}
		if (filters && filters.networks && filters.networks.length > 0) {
			filterCount++;
		}
		if (filters && filters.relationship && filters.relationship !== 'all') {
			filterCount++;
		}
		if (searchKeys && searchKeys.length > 0) {
			filterCount++;
		}
		return filterCount;
	}

	async downloadMembers() {
		this.$f7.dialog.preloader();
		try {
			const members = await CloudAPI.getMembers(this.data.filters);
			this.exportCsv(members);
		} catch (err) {
			console.log('unable to get members: ', err);
		}
		this.$f7.dialog.close();
	}

	exportCsv(members) {
		const headers = [
			'relation',
			'memberId',
			'groupId',
			'lastName',
			'firstName',
			'street',
			'city',
			'state',
			'zip',
			'dob',
			'phone',
			'email',
			'registeredUser',
			'lastLogin',
			'registeredDate',
			'pcpNpi',
			'pcpName'
		];
		const keysToCapitalize = ['lastName', 'firstName', 'street', 'city'];
		const keysToUpperCase = ['state', 'pcpName'];
		const csvRows = members.map((row) => {
			const rowData = headers.map((item) => {
				const rowValue = row[item] || '';
				const rowSafeValue = rowValue.toString().replace(/\#/g, 'Unit ').replace(/,/g, ' ');
				let formattedValue = rowSafeValue;
				if (keysToCapitalize.indexOf(item) > -1) {
					formattedValue = rowSafeValue.replace(/\w+/g, _.capitalize);
				}
				if (keysToUpperCase.indexOf(item) > -1) {
					formattedValue = _.upperCase(rowSafeValue);
				}
				return formattedValue;
			});
			return rowData.join(',');
		});
		csvRows.unshift(headers.join(','));
		const finalCSVOutput = `${csvRows.join('\n')}`;
		var exportTrigger = document.createElement('a');
		exportTrigger.href = 'data:text/csv;charset=utf-8,' + encodeURI(finalCSVOutput);
		exportTrigger.target = '_blank';
		exportTrigger.download = `members-${new Date().getTime()}.csv`;
		exportTrigger.click();
	}

	render() {
		const { groups, organizations, showFilterMenu, showSearchForm, showMessagingForm, filters, notificationData, networks } =
			this.data;
		const pendingMessages = adminStore.pendingMessages || [];
		const filterCount = this.getFilterCount();
		const searchData = _.get(filters, 'searchForm', {});
		const searchKeys = Object.keys(searchData).filter((x) => !!searchData[x]);

		return (
			<div className={`group-mgmt-page`}>
				<div className="group-mgmt-header">
					<h2>Manage Groups</h2>
					{pendingMessages.length > 0 && (
						<Fab
							className="notification-counter"
							position="right-top"
							slot="fixed"
							color="purple"
							text={`Pending Notification(s)`}
							onClick={this.openMessagingForm}
						>
							{pendingMessages.length}
						</Fab>
					)}
					<Button className="group-mgmt-panel-btn" raised onClick={this.showFilterMenu}>
						<i className="fad fa-filter" />
					</Button>
					<Button className="group-mgmt-panel-btn" raised onClick={this.showSearchForm}>
						<i className="fad fa-search" />
					</Button>
					<Button className="group-mgmt-panel-btn" raised onClick={this.showConsumers}>
						Consumers
					</Button>
					{filterCount > 0 && <Badge color="blue">{`${filterCount} Filter(s) Applied`}</Badge>}
					{searchKeys && searchKeys.length > 0 && <Badge color="blue">{`Search Results Only`}</Badge>}
				</div>
				<div>{!appStore.isMobile && <Button onClick={this.downloadMembers}>Download Members</Button>}</div>
				<Panel className="group-mgmt-panel" left cover backdrop onPanelClose={this.closePanel}>
					<Fragment>
						{showFilterMenu && (
							<FilterMenu
								groups={groups}
								organizations={organizations}
								networks={networks}
								close={this.closePanel}
								submit={this.searchMembers}
								filters={this.data.filters}
								resetFilters={this.resetFilters}
							/>
						)}
						{showSearchForm && (
							<SearchForm
								close={this.closePanel}
								search={this.searchMembers}
								priorSearch={_.cloneDeep(_.get(this.data, 'filters.searchForm', {}))}
							/>
						)}
					</Fragment>
				</Panel>
				<Fragment>
					<MessagingForm isOpen={this.data.showMessagingForm} close={this.closeMessagingForm} showBulkOptions />
					<GroupsData groups={groups} getGroupMembers={this.getGroupMembers} />
				</Fragment>
			</div>
		);
	}
}
