import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import CloudAPI from '@/services/CloudAPI';
import ReportingContainer from '@/components/reporting/ReportingContainer';

@observer
export default class Reporting extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	async getReportConfigs(report, request) {
		return await CloudAPI.getReportConfigurations(report, request);
	}
	async getReportInfo(report, request) {
		return await CloudAPI.getReportData(report, request);
	}
	render() {
		return <ReportingContainer getConfigs={this.getReportConfigs} getReportData={this.getReportInfo} />;
	}
}
