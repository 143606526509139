import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { Button, Link } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import StorageService from '../../../services/StorageService';
import LanguageSelection from '../../../components/language-selection/LanguageSelection';
import AnalyticsService from '../../../services/AnalyticsService';
import GradiantStack from '../../../components/gradiant-stack/GradiantStack';
import signinSVG from '../../../assets/signin.svg';
import BasicInput from '../../../components/basic-input/BasicInput';
import AuthService from '../../../services/AuthService';
import CloudAPI from '../../../services/CloudAPI';
import userStore from '@/stores/UserStore';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
export default class ConsumerSignin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ remember: false, mobile: '', signinValid: false, disableMobile: true, verificationCode: '' });
		this.text = getCmpText('ConsumerSignin', {
			signInTitle: 'Sign In',
			phoneLabel: 'Mobile Number',
			phonePlaceholder: 'Enter your mobile number',
			signInCta: 'Sign in',
			verificationText: 'A verification code will be sent to your phone via text message',
			rememberMe: 'Remember me',
			signUpText: "Don't have an account? ",
			signUpCta: 'Sign up',
			missingUserError: 'Phone number has no associated user. Please sign up below.',
			failedLoginError: 'Failed to login, please try again',
			generatingCode: 'Generating Code'
		});
		autoBind(this);
	}

	async componentDidMount() {
		setTimeout(() => {
			try {
				AuthService.initRecaptcha();
				this.data.disableMobile = false;
			} catch (err) {
				console.log('Unable to render the recaptcha');
				console.log(err);
			}
		}, 1000);
		const consumerLogin = await storageService.getValue('app', 'consumerLogin');
		if (consumerLogin) {
			this.data.remember = true;
			this.data.mobile = JSON.parse(consumerLogin).loginPhone;
			this.data.signinValid = true;
		}
	}

	componentWillUnmount() {
		userStore.recaptchaId = null;
	}

	onBack() {
		if (this.props.onBack) {
			this.props.onBack('consumer-signin');
		}
	}

	onNext() {
		analytics.trackWithData('Consumer Sign Up', { step: 'open form' });
		if (this.props.onNext) {
			this.props.onNext('consumer-signup');
		}
	}

	onMobileChange(evt) {
		let target = evt.currentTarget;
		let value = target.value.replace(/\D/g, '');
		this.data.mobile = value;
		if (value.length === 10) {
			this.data.signinValid = true;
		}
	}

	rememberToggle() {
		this.data.remember = !this.data.remember;
	}

	async onSignin() {
		this.$f7.dialog.preloader(this.text.generatingCode);
		try {
			const isValidConsumer = await CloudAPI.isValidConsumer(this.data.mobile);
			if (isValidConsumer) {
				const codeSent = await AuthService.sendSigninCode(this.data.mobile, this.$f7);
				if (codeSent) {
					this.$f7.dialog.close();
					if (this.data.remember) {
						let userLogin = { remember: true, loginPhone: this.data.mobile };
						storageService.setValue('app', 'consumerLogin', JSON.stringify(userLogin)).catch((err) => console.log(err));
					} else {
						storageService.clearStoreKey('app', 'consumerLogin');
					}
					this.props.onSignIn(this.data.mobile);
				} else {
					this.$f7.dialog.close();
					this.$f7.dialog.alert(this.text.failedLoginError);
				}
			} else {
				this.$f7.dialog.close();
				this.$f7.dialog.alert(this.text.missingUserError);
			}
		} catch (error) {
			console.log('error on sign in: ', error);
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.failedLoginError);
		}
	}

	render() {
		return (
			<>
				<GradiantStack stack="1" consumer animate={true} id="consumer-signin" />
				<div className={`consumer-signin-page y-scroll`}>
					<div className="header-ctn hbox vcenter">
						<div className="back hbox vcenter animated fadeInLeft" onClick={this.onBack}>
							<i className="fal fa-chevron-left"></i>Back
						</div>
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={signinSVG} className="herosvg" />
						<h1>{this.text.signInTitle}</h1>
						<BasicInput
							label={this.text.phoneLabel}
							type="tel"
							placeholder={this.text.phonePlaceholder}
							value={this.data.mobile}
							onChange={this.onMobileChange}
							maxlength={10}
							minlength={10}
							validate={true}
							disabled={this.data.disableMobile}
						/>
						<Button
							fill
							large
							className="round-btn purple"
							onClick={this.onSignin}
							id="sign-in-button"
							disabled={!this.data.signinValid}
						>
							{this.text.signInCta} <i className="far fa-long-arrow-right"></i>
						</Button>
						<div className="message-box">{this.text.verificationText}</div>
						<div className="remember-toggle hbox vcenter" onClick={this.rememberToggle}>
							<div className="toggle-indicator vbox vcenter hcenter">
								{this.data.remember && <i className="fad fa-dot-circle"></i>}
							</div>
							<div className="text">{this.text.rememberMe}</div>
						</div>
						<div className="text-link hbox vcenter" onClick={this.onNext}>
							<div className="text">{this.text.signUpText}</div>
							<div className="cta">{this.text.signUpCta}</div>
						</div>
					</div>
					<LanguageSelection />
					<div className="logo-ctn vbox vcenter hcenter">
						<i className="top-icon icon-logo" />
					</div>
					<div id="recaptcha"></div>
				</div>
			</>
		);
	}
}
