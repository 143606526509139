// @ts-nocheck

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../../utils/State';
import { autoBind } from '../../../../utils/GeneralUtils';
import ConfigService from '../../../../services/ConfigService';
import AdminService from '../../../../services/AdminService';
import { Panel, Badge, Button } from 'framework7-react';
import EditUserForm from '../edit-user/EditUserForm';
import './members-data.scss';
import ConsumerPanel from '../../consumer-panel/ConsumerPanel';
import appStore from '@/stores/AppStore';
import StorageService from '@/services/StorageService';

const storageService = new StorageService();

@observer
export default class MembersData extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showEditForm: true,
			editUser: null,
			openConsumer: null
		});
		autoBind(this);
	}

	notificationInit(evt) {
		const { members } = this.props;
		const type = evt.currentTarget.getAttribute('data-type');
		const member = evt.currentTarget.getAttribute('data-member');
		const memberData = _.find(members, { memberUid: member });
		const existingNotification =
			evt.currentTarget.getAttribute('data-status') === 'active';
		if (existingNotification) {
			AdminService.messageRemovePending(member, type, true);
		} else {
			const recipientData = {
				memberUid: member,
				firstName: memberData.firstName,
				lastName: memberData.lastName,
				phone: memberData.phone,
				devices: memberData.devices,
				email: memberData.email,
				languageDisplay: memberData.languageDisplay
			};
			AdminService.messageAddPending(member, type, recipientData);
		}
	}

	openEditForm(evt) {
		const { groupUid } = this.props;
		const memberUid = evt.currentTarget.getAttribute('data-member');
		const userData = this.props.members.find((x) => x.memberUid === memberUid);
		this.data.showEditForm = true;
		this.data.editUser = _.cloneDeep(userData);
		this.$f7.panel.open(`#editPanel-${groupUid}`);
	}

	closePanel(data) {
		const refreshRequested = _.get(data, 'refresh', false);
		this.data.showEditForm = false;
		this.data.editUser = null;
		this.$f7.panel.close('right');
		if (refreshRequested) {
			this.props.refreshMembers(this.props.groupUid);
		}
	}

	closeConsumer() {
		this.data.openConsumer = null;
	}

	async demoAsMember(evt) {
		const id = evt.currentTarget.getAttribute('data-member');
		appStore.demoMode = id;
		storageService.setValue('app', 'demoMode', id);
		ConfigService.resetConfig();
		window.location.reload();
	}

	render() {
		const { members = [], groupUid, groupData } = this.props;
		const { showEditForm, editUser } = this.data;
		return (
			<div className="members-container">
				<Panel
					id={`editPanel-${groupUid}`}
					className="group-mgmt-panel"
					right
					cover
					backdrop
					onPanelClose={this.closePanel}
				>
					{showEditForm && !!editUser && (
						<EditUserForm user={editUser} close={this.closePanel} />
					)}
				</Panel>
				<div className="card-content">
					{members.map((member) => {
						const memberUid = member.memberUid;
						const isActiveMember = member.status === 'active';
						const isUser = !!member.userUid;
						const languages = Object.values(
							ConfigService.getConfigValue('language', {})
						);
						const languageData = languages.find(
							(x) => x.config.code === member.language
						);
						const languageLabel =
							languageData &&
							_.get(languageData, 'config.displayValues.language');
						const messagingOptions =
							AdminService.getMessagingRecipientStatus(member);
						const isConsumer = member.consumer;
						return (
							<div key={member.memberUid} className="member-container">
								<div className="member-data-group">
									{!isConsumer && (
										<p
											className="demo-cta"
											onClick={this.demoAsMember}
											data-member={member.memberUid}
										>
											Demo As
										</p>
									)}
									<p className="member-name">
										{isUser && !isConsumer && (
											<span
												className="edit-user-btn"
												onClick={this.openEditForm}
												data-member={memberUid}
											>
												<i className="fad fa-edit purple" />
											</span>
										)}
										{member.firstName} {member.lastName}
									</p>
									{!isConsumer && (
										<p className="member-badges">
											<Badge color={isActiveMember ? 'green' : 'red'}>
												{isActiveMember ? 'Active Member' : 'Inactive Member'}
											</Badge>
											{isUser && <Badge color="purple">Registered User </Badge>}
										</p>
									)}
									{isConsumer && (
										<p className="member-badges">
											{member.enrolled && (
												<Badge color="purple2">Enrolled</Badge>
											)}
											{member.activated && (
												<Badge color="green">Activated</Badge>
											)}
										</p>
									)}
									{member.language && (
										<p className="member-language-alert">
											Language Preference: <span>{languageLabel}</span>
										</p>
									)}
									{member.lastLogin && (
										<p className="plan-info-item">
											Last Login: <span>{member.lastLogin}</span>
										</p>
									)}
									{isConsumer && (
										<p className="plan-info-item">
											Member ID: <span>{member.memberId}</span>
										</p>
									)}
									{member.referralSource && (
										<p className="plan-info-item">
											Registering Influencer:{' '}
											<span>{member.referralSource}</span>
										</p>
									)}
								</div>
								<div className="member-data-group">
									<div className="member-actions">
										{messagingOptions &&
											messagingOptions.map((option) => {
												const targetData = Array.isArray(member[option.key])
													? JSON.stringify(member[option.key])
													: member[option.key];
												let targetDisplay =
													member[option.key] ||
													`${option.type.toLowerCase()} not provided`;
												if (option.type === 'PUSH') {
													targetDisplay = `Send Push Notification (${
														member[option.key] ? member[option.key].length : 0
													} Devices)`;
												}
												return (
													<div
														key={`message-option-${option.type}-${memberUid}`}
														className={`member-action ${
															option.disabled ? 'disabled' : ''
														} ${option.pending ? 'pending' : ''}`}
														onClick={this.notificationInit}
														data-member={memberUid}
														data-type={option.type}
														data-info={targetData}
														data-status={option.pending ? 'active' : 'inactive'}
													>
														<span className="member-action-icon">
															<i
																className={`${option.icon} ${
																	option.disabled ? 'disabled' : 'blue'
																}`}
															/>
														</span>
														{targetDisplay}
													</div>
												);
											})}
									</div>
								</div>
								{isConsumer ? (
									<Button
										outline
										large
										className="consumer-menu hbox vcenter"
										onClick={() => {
											this.data.openConsumer = member;
										}}
									>
										<i className="fal fa-clipboard-list"></i>
										Consumer Menu
									</Button>
								) : (
									<div className="member-data-group">
										<p className="plan-info-item">
											Member #: {member.memberId}
										</p>
										<p className="plan-info-item">Group ID: {member.groupId}</p>
										<p className="plan-info-item">
											Medical Plan ID: {member.groupName}
										</p>
										<p className="plan-info-item">
											Dental Plan ID: {member.groupNameDental}
										</p>
										<p className="plan-info-item">
											Vision Plan ID: {member.groupNameVision}
										</p>
										{groupData.groupDisplayName && (
											<p className="plan-info-item">
												Group Name: {groupData.groupDisplayName}
											</p>
										)}
										<p className="plan-info-item">
											Relation: {member.relation}
										</p>
										{member.dob && (
											<p className="plan-info-item">DoB: {member.dob}</p>
										)}
										{groupData.healthNetworkName && (
											<p className="plan-info-item">
												Network:{' '}
												{_.get(groupData, 'healthNetworkData[0].productName') ||
													groupData.healthNetworkName}
											</p>
										)}
										{groupData.tiers &&
											groupData.tiers.map((tier) => {
												const networkData = _.find(groupData.tierNetworkData, {
													networkId: tier.network
												});
												const displayName = networkData
													? networkData.productName
													: tier.network;
												return (
													<p
														className="plan-info-item"
														key={`tierData-${groupUid}-${tier.tier}-${displayName}`}
													>
														{`Tier ${tier.tier}: ${displayName}`}
													</p>
												);
											})}
										{member.pcpData && (
											<Fragment>
												<p className="plan-info-item">
													PCP NPI: {_.get(member, 'pcpData.NPI')}
												</p>
												<p className="plan-info-item">
													PCP Name: {_.get(member, 'pcpData.name')}
												</p>
											</Fragment>
										)}
									</div>
								)}
							</div>
						);
					})}
					{members && members.length < 1 && (
						<div className="members-container-empty">
							<p>No member data to display</p>
						</div>
					)}
				</div>
				<ConsumerPanel
					opened={this.data.openConsumer ? true : false}
					consumer={this.data.openConsumer}
					closePanel={this.closeConsumer}
				></ConsumerPanel>
			</div>
		);
	}
}
