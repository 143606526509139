import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { List, ListInput, ListItem } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import { autoBind, formatDOB } from '../../../utils/GeneralUtils';

@observer
export default class AppointmentRequestTemplate extends Component {
	constructor(props) {
		super(props);
		this.previewRef = React.createRef();
		this.data = observable({
			shareFormValid: false,
			body: this.props.body || '',
			employerName: '',
			employeeType: 'Full Time Employee',
			customMessage: ''
		});
		autoBind(this);
	}

	componentDidMount() {
		this.showPreview();
		if (!this.props.body) {
			const newBody = this.buildEmailTemplate();
			this.props.onBodyChange(newBody);
		}
	}

	getPatientGender() {
		const gender = _.get(this.props, 'selectedMember.patient.gender', '');
		switch (gender) {
			case 'm':
				return 'Male';
			case 'f':
				return 'Female';
			default:
				return 'Not Provided';
		}
	}

	getRequestedDate() {
		const dateRequestType = _.get(this.props, 'selectedMember.requestType', 'first');
		const dateRangeStart = _.get(this.props, 'selectedMember.requestDate[0]');
		const dateRangeEnd = _.get(this.props, 'selectedMember.requestDate[1]');
		const suggestedDateDisplay = dateRangeEnd
			? `${formatDOB(dateRangeStart)} - ${formatDOB(dateRangeEnd)}`
			: formatDOB(dateRangeStart);
		const displayValue = dateRequestType === 'first' ? 'First available' : suggestedDateDisplay;
		return displayValue;
	}

	getRequestedTime() {
		const timeRequestType = _.get(this.props, 'selectedMember.requestTime', 'any');
		switch (timeRequestType) {
			case 'morning':
				return 'prefer before 1:00 PM';
			case 'afternoon':
				return 'prefer after 1:00 PM';
			default:
				return 'any time of day';
		}
	}

	getPatientFullName() {
		const firstName = _.get(this.props, 'selectedMember.patient.firstName', '');
		const lastName = _.get(this.props, 'selectedMember.patient.lastName', '');
		const fullName = `${firstName} ${lastName}`;
		return _.startCase(fullName);
	}

	getPatientPhone() {
		const userPhone = _.get(this.props, 'selectedMember.user.contactInfo.phone');
		const memberPhone = _.get(this.props, 'selectedMember.patient.phone');
		return userPhone || memberPhone || 'Not Provided';
	}

	getPatientCityStateZip() {
		const city = _.get(this.props, 'selectedMember.patient.address.city');
		const state = _.get(this.props, 'selectedMember.patient.address.state');
		const zip = _.get(this.props, 'selectedMember.patient.address.zipcode');
		const cityFormatted = city ? _.startCase(city) : 'Unknown City';
		const stateFormatted = state ? _.upperCase(state) : 'Unknown State';

		return `${cityFormatted}, ${stateFormatted} ${zip}`;
	}

	getPatientRelationshipLine() {
		const { employerName, employeeType } = this.data;
		const hasEmployerName = employerName && employerName.length > 0;
		const relationship = _.get(this.props, 'selectedMember.patient.relation');
		const isPrimary = relationship === 'self';
		if (!hasEmployerName) {
			return isPrimary ? 'Primary Member' : 'Dependent of Primary Member';
		}
		return isPrimary ? `${employeeType} with ${employerName}` : `Dependent of ${employeeType} with ${employerName}`;
	}

	getEmailIntro() {
		const { employerName } = this.data;
		const hasEmployerName = employerName && employerName.length > 0;
		const intro = 'This is an appointment request from CareValet, a patient concierge program.';
		const patientAcceptance =
			'The patient has accepted our terms and conditions and requested this appointment through our mobile application. Patient demographics and appointment request details are below.';
		const employerLine = `We have been hired by ${employerName} to help their employees stay on track with preventative and necessary medical care.`;

		return `${intro} ${hasEmployerName ? employerLine : ''} ${patientAcceptance}`;
	}

	getProviderInfoLine() {
		const fullName = _.startCase(_.get(this.props, 'selectedMember.provider.fullName', ''));
		const street1 = _.startCase(_.get(this.props, 'selectedMember.location.street1', ''));
		const street2 = _.startCase(_.get(this.props, 'selectedMember.location.street2', ''));
		const city = _.startCase(_.get(this.props, 'selectedMember.location.city', ''));
		const state = _.upperCase(_.get(this.props, 'selectedMember.location.state', ''));
		const zip = _.get(this.props, 'selectedMember.location.zip', '');

		return `${fullName} - ${street1} ${street2}, ${city}, ${state} ${zip}`;
	}

	getReplyInfo() {
		const patientName = this.getPatientFullName();
		const replyEmailAddress = 'info@carevalet.com';
		const subjectLine = escape(`Re: Appointment request for ${patientName}`);
		return `Please do not reply directly to this email, this inbox is not monitored.  For questions regarding this appointment please email <a href="mailto:${replyEmailAddress}?subject=${subjectLine}">info@carevalet.com</a>.`;
	}

	buildEmailTemplate() {
		const gender = this.getPatientGender();
		const requestDate = this.getRequestedDate();
		const requestTime = this.getRequestedTime();
		const patientPhone = this.getPatientPhone();
		const patientNetwork = _.get(this.props, 'selectedMember.group.healthNetworkName', 'Unknown');
		const patientName = this.getPatientFullName();
		const patientStreet = _.get(this.props, 'selectedMember.patient.address.street', '');
		const patientCityStateZip = this.getPatientCityStateZip();
		const patientDOB = formatDOB(_.get(this.props, 'selectedMember.patient.dob', 'Unknown'));
		const patientRelationshipLine = this.getPatientRelationshipLine();
		const emailIntro = this.getEmailIntro();
		const providerLine = this.getProviderInfoLine();
		const replyInfo = this.getReplyInfo();
		const body = `
            <p>${this.data.customMessage}</p>
            <p><strong>*${replyInfo}</strong></p>
            <p>${emailIntro}</p>
            <p>Appointment Request For:</p>
            <div><strong>${patientName}</strong></div>
            <div>${patientStreet}</div>
            <div>${patientCityStateZip}</div>
            <ul>
                <li>DOB: ${patientDOB}</li>
                <li>Gender: ${gender}</li>
                <li>Phone: ${patientPhone || 'Unknown'}</li>
                <li>Network: ${patientNetwork}</li>
                <li>${patientRelationshipLine}</li>
                <li>Existing Patient? ${
									_.get(this.props, 'selectedMember.patientType', 'new') === 'new' ? 'No, first visit' : 'Yes'
								}</li>
            </ul>
            <p style="color: red"><strong>If Social Security Number is required for your records the patient has requested to provide this while in office for security and privacy reasons.</strong></p>
            <p><strong>When: </strong> ${requestDate}, ${requestTime}</p>
            <p><strong>Reason for visit: </strong> ${_.get(this.props, 'selectedMember.note', 'Unknown')}</p>
            <p><strong>Provider Requested: </strong> ${providerLine}</p>
            <strong>ID Card and Benefit Recap attached.</strong>
        `;
		return body;
	}

	inputChange(evt) {
		const value = evt.currentTarget.value;
		const field = evt.currentTarget.name;
		const fieldClasses = evt.currentTarget.classList.value;
		const isInvalid = fieldClasses.indexOf('input-invalid') > -1;
		this.data.shareFormValid = !isInvalid;
		this.data[field] = value;
		this.showPreview();
		const newBody = this.buildEmailTemplate();
		this.props.onBodyChange(newBody);
	}

	updateCustomMessage(value) {
		this.data.customMessage = value;
		this.showPreview();
		const newBody = this.buildEmailTemplate();
		this.props.onBodyChange(newBody);
	}

	showPreview() {
		const body = this.buildEmailTemplate();
		if (this.previewRef) {
			this.previewRef.current.innerHTML = body;
		}
	}

	render() {
		return (
			<List>
				<ListInput
					outline
					label="Employer Name"
					type="text"
					name="employerName"
					placeholder="Optional Employer Name"
					onChange={this.inputChange}
					inputId="employerName"
					noStoreData
					value={this.data.employerName}
					className="md"
					floatingLabel={true}
				/>
				<ListItem title="Employment Status" />
				<ListItem
					className="employment-status-radio"
					radio
					value="Full Time Employee"
					name="employeeType"
					checked={this.data.employeeType === 'Full Time Employee'}
					onChange={this.inputChange}
					title="Full Time Employee"
				/>
				<ListItem
					className="employment-status-radio"
					radio
					value="Part Time Employee"
					name="employeeType"
					checked={this.data.employeeType === 'Part Time Employee'}
					onChange={this.inputChange}
					title="Part Time Employee"
				/>
				<ListInput
					outline
					label={this.props.subjectLabel}
					type="text"
					name="subject"
					placeholder={this.props.subjectPlaceholder}
					onChange={this.props.onSubjectChange}
					inputId="subject"
					noStoreData
					value={this.props.subject}
					className="md"
					floatingLabel={true}
				/>
				<ListInput
					outline
					label="Custom Message"
					type="texteditor"
					name="customMessage"
					onChange={this.updateCustomMessage}
					inputId="customMessage"
					noStoreData
					className="md"
					floatingLabel={true}
					value={this.data.customMessage}
					textEditorParams={{
						buttons: [],
						mode: 'popover'
					}}
				/>
				<ListItem>
					<div className="preview-window">
						<h4>Email Preview</h4>
						<div ref={this.previewRef}></div>
					</div>
				</ListItem>
			</List>
		);
	}
}
