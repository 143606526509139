import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind, getStripeKey } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import './add-credit-card-form.scss';
import CreditCardInput from '@/components/credit-card-input/CreditCardInput';
import BasicInput from '@/components/basic-input/BasicInput';
import CreditCard from '@/components/credit-card/CreditCard';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import UserBillingService from '@/services/UserBillingService';

@observer
export class AddCreditCardForm extends Component {
	constructor(props) {
		super();
		this.data = observable({
			brand: 'unkown',
			fullName: '',
			nameValid: false,
			cardValid: false,
			formComplete: false
		});
		autoBind(this);
	}

	onCreditChange(e) {
		this.data.brand = e.brand;
		this.data.cardValid = e.complete;
		this.isValid();
	}

	onChange(evt) {
		let target = evt.currentTarget;
		this.data[target.name] = target.value;
		this.data.nameValid = this.data[target.name] != '';
		this.isValid();
	}

	isValid() {
		if (this.data.nameValid && this.data.cardValid) {
			this.data.formComplete = true;
		} else {
			this.data.formComplete = false;
		}
	}

	async onAddCard(evt) {
		this.$f7.dialog.preloader('Adding card');
		const cardElement = this.props.elements.getElement(CardElement);
		const { paymentMethod, error } = await this.props.stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: _.get(this.data, 'fullName', '')
			}
		});

		if (error) {
			this.$f7.dialog.close();
			console.log(error);
		} else {
			console.log(await UserBillingService.addPaymentMethod(paymentMethod));
			try {
				await UserBillingService.addPaymentMethod(paymentMethod);
				this.$f7.dialog.close();
			} catch (e) {
				//TODO HANDLE ERRORS HERE
				console.log(e);
				this.$f7.dialog.close();
			}

			await this.props.onCardAdded();
		}
	}

	render() {
		let { fullName, brand } = this.data;
		let { onBack } = this.props;
		return (
			<div className="add-credit-form page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="back hbox vcenter animated fadeInLeft" onClick={onBack}>
							<i className="fal fa-chevron-left"></i>Back
						</div>
						<h1>Add New Card</h1>
					</div>
				</div>
				<div className="billing-content">
					<CreditCard brand={this.data.brand} name={fullName} lastFour="•••" />
					<div className="billing-form">
						<BasicInput
							name="fullName"
							label="Full Name"
							value={fullName}
							type="text"
							placeholder="John Doe"
							onChange={this.onChange}
						></BasicInput>
						<CreditCardInput name="creditCard" label="Card Details" onChange={this.onCreditChange} stripeKey={getStripeKey()} />
					</div>
					<Button
						fill
						large
						className="round-btn purple"
						onClick={this.onAddCard}
						id="add-card-button"
						disabled={!this.data.formComplete}
					>
						Add to wallet <i className="far fa-long-arrow-right"></i>
					</Button>
				</div>
			</div>
		);
	}
}

export const StripeAddCreditCardForm = (props) => {
	return (
		<ElementsConsumer>
			{({ elements, stripe }) => (
				<AddCreditCardForm elements={elements} stripe={stripe} onCardAdded={props.onCardAdded} onBack={props.onBack} />
			)}
		</ElementsConsumer>
	);
};
