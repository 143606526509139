import React, { PureComponent } from 'react';

export default class Stack1 extends PureComponent {
	render() {
		return (
			<svg width="549" height="659" fill="none" viewBox="0 0 549 659">
				<rect width="388" height="388" x="274.357" y="109.403" fill="#4486FF" rx="60" transform="rotate(45 274.357 109.403)"></rect>
				<rect
					width="388"
					height="388"
					x="274.357"
					y="109.403"
					fill={`url(#paint0_linear-${this.props.id})`}
					rx="60"
					transform="rotate(45 274.357 109.403)"
				></rect>
				<rect
					width="388"
					height="388"
					x="274.357"
					y="109.403"
					fill={`url(#paint1_linear-${this.props.id})`}
					rx="60"
					transform="rotate(45 274.357 109.403)"
				></rect>
				<rect
					width="388"
					height="388"
					x="274.357"
					y="55"
					fill={`url(#paint2_linear-${this.props.id})`}
					rx="60"
					transform="rotate(45 274.357 55)"
					style={{ mixBlendMode: 'multiply' }}
				></rect>
				<rect
					width="388"
					height="388"
					x="274.357"
					fill={`url(#paint2_linear-${this.props.id})`}
					rx="60"
					transform="rotate(45 274.357 0)"
					style={{ mixBlendMode: 'multiply' }}
				></rect>
				{this.props.purple ? (
					<defs>
						<linearGradient
							id={`paint2_linear-${this.props.id}`}
							x1="664.928"
							x2="312.125"
							y1="418.728"
							y2="423.363"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#4839EA" stopOpacity="0.3"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint1_linear-${this.props.id}`}
							x1="179.479"
							x2="647.374"
							y1="442.329"
							y2="436.8"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#fff" stopOpacity="0"></stop>
							<stop offset="1" stopColor="#4839EA" stopOpacity="0.5"></stop>
						</linearGradient>
						<linearGradient
							id={`paint0_linear-${this.props.id}`}
							x1="-25.833"
							x2="654.211"
							y1="397.348"
							y2="364.659"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#fff" stopOpacity="0"></stop>
							<stop offset="1" stopColor="#6363F2"></stop>
						</linearGradient>
					</defs>
				) : (
					<defs>
						<linearGradient
							id={`paint0_linear-${this.props.id}`}
							x1="664.928"
							x2="312.125"
							y1="418.728"
							y2="423.363"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#66FFA6" stopOpacity="0.3"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint1_linear-${this.props.id}`}
							x1="942.162"
							x2="225.892"
							y1="777.768"
							y2="151.521"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.141" stopColor="#71FFAC"></stop>
							<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint2_linear-${this.props.id}`}
							x1="664.928"
							x2="312.125"
							y1="364.325"
							y2="368.959"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint3_linear-${this.props.id}`}
							x1="664.928"
							x2="312.125"
							y1="309.325"
							y2="313.959"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
					</defs>
				)}
			</svg>
		);
	}
}
