import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import appStore from '@/stores/AppStore';
import AnalyticsService from '../../../services/AnalyticsService';
import GradiantStack from '../../../components/gradiant-stack/GradiantStack';
import verifySVG from '../../../assets/verify.svg';
import userStore from '../../../stores/UserStore';
import AuthService from '../../../services/AuthService';
import CloudAPI from '../../../services/CloudAPI';
import BasicInput from '@/components/basic-input/BasicInput';

const analytics = new AnalyticsService();
@observer
export default class ConsumerValidation extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ verificationCode: '', verificationCodeError: false });
		this.text = getCmpText('ConsumerValidation', {
			enterCode: 'Enter Verification Code',
			missingCodeText: 'Did not receive code? ',
			missingCodeCta: 'Resend',
			submitCta: 'Done',
			codeError: 'Invalid Code',
			back: 'Back'
		});
		autoBind(this);
	}

	async onBack() {
		if (this.props.onBack) {
			this.props.onBack();
		}
	}

	async onNext() {
		let { standalone, consumer } = this.props;
		analytics.trackWithData('Login', { step: 'submitted verification code' });
		this.$f7.dialog.preloader();
		if (standalone) {
			console.log('2FA Popup Submission');
			if (!consumer) {
				this.$f7.dialog.close();
				this.props.onNext(this.data.verificationCode, false);
			} else {
				userStore.isCreatingUser = true;
				//consumer signin
				const status = await AuthService.confirmSigninCode(`${this.data.verificationCode}`.trim());
				if (status && status.success) {
					analytics.trackWithData('Login', { step: 'verification code success' });
					await AuthService.setupConsumerToken();
					analytics.trackWithData('Login', { step: 'setup consumer token' });
					const user = await CloudAPI.getMyUser();
					analytics.trackWithData('Login', { step: 'get consumer record' });
					userStore.consumerUser = user;
					if (user.enrolled) {
						userStore.isCreatingUser = false;
						this.$f7.dialog.close();
						this.props.onNext();
						analytics.trackWithData('Login', { step: 'begin consumer sign in' });
						AuthService.handleConsumerSignin(userStore.firebaseUser, this.$f7);
					} else {
						analytics.trackWithData('Login', { step: 'prompt for telehealth enrollment' });
						this.$f7.dialog.close();
						this.props.onNext('consumer-telehealth');
					}
				} else {
					analytics.trackWithData('Login', { step: 'verification code failure', code: this.data.verificationCode, status: status });
					userStore.isCreatingUser = false;
					this.$f7.dialog.close();
					this.$f7.dialog.alert(this.text.codeError);
					this.data.verificationCodeError = true;
				}
			}
		} else {
			//consumer signup
			console.log('2FA Submission');
			userStore.isCreatingUser = true;
			const status = await AuthService.confirmSigninCode(`${this.data.verificationCode}`.trim());
			if (status && status.success) {
				analytics.trackWithData('Consumer Sign Up', { step: 'verification code success' });
				const consumerCreated = await AuthService.createConsumer(_.cloneDeep(userStore.signupData));
				if (!consumerCreated) {
					analytics.trackWithData('Consumer Sign Up', { step: 'create consumer error' });
					this.$f7.dialog.close();
					this.$f7.dialog.alert(this.text.createAccountError, () => this.props.onBack());
					return;
				}
				analytics.trackWithData('Consumer Sign Up', { step: 'create consumer' });
				await AuthService.setupConsumerToken();
				analytics.trackWithData('Consumer Sign Up', { step: 'setup consumer token' });
				const user = await CloudAPI.getMyUser();
				analytics.trackWithData('Consumer Sign Up', { step: 'get consumer record' });
				userStore.consumerUser = user;
				this.$f7.dialog.close();
				this.props.onNext('consumer-telehealth');
			} else {
				analytics.trackWithData('Consumer Sign Up', {
					step: 'verification code failure',
					code: this.data.verificationCode,
					status: status
				});
				this.$f7.dialog.close();
				this.$f7.dialog.alert(this.text.codeError);
				this.data.verificationCodeError = true;
			}
		}
	}

	async onResend() {
		this.$f7.dialog.preloader();
		const phone = this.props.phone || _.get(userStore, 'signupData.mobile');
		try {
			AuthService.initRecaptcha('recaptcha2');
			await AuthService.sendSigninCode(phone, this.$f7);
			this.$f7.dialog.close();
		} catch (err) {
			this.$f7.dialog.close();
			console.log('unable to resend code: ', err);
		}
	}

	onCodeChange(e) {
		const value = e.target.value || '';
		const cleanValue = value.replace(/\D/g, '');
		if (cleanValue.length > 6) {
			return;
		}
		this.data.verificationCode = cleanValue;
		if (this.data.verificationCode.length === 6) {
			this.onNext();
		}
	}

	onCodeClick() {
		document.getElementById('verificationCodeId').focus();
	}

	handleKeyDown(e) {
		if (e.key === 'Enter' && this.data.verificationCode.length === 6) {
			this.onNext();
		}
	}

	render() {
		return (
			<>
				<GradiantStack stack="3" consumer={this.props.consumer} animate={true} id="consumer-validation" />
				<div className={`consumer-validation-page`}>
					{!this.props.standalone && !this.props.hideBack && (
						<div className="header-ctn hbox vcenter">
							<div className="back hbox vcenter animated fadeInLeft" onClick={this.onBack}>
								<i className="fal fa-chevron-left"></i>
								{this.text.back}
							</div>
							<div className="grow-1"></div>
						</div>
					)}
					<div className={`content-ctn vbox hcenter animated fadeIn`}>
						<img src={verifySVG} className="herosvg" />
						<h1>{this.text.enterCode}</h1>
						<div className="code-box vbox vcenter hcenter" onClick={this.onCodeClick}>
							{!this.data.verificationCodeError && (
								<div className="cells hbox vcenter hcenter">
									{[0, 1, 2, 3, 4, 5].map((num) => {
										let inputVals = this.data.verificationCode.split('');
										return (
											<div key={Math.random()} className="cell">
												{inputVals[num] ? inputVals[num] : ''}
											</div>
										);
									})}
								</div>
							)}
							{!this.data.verificationCodeError && (
								<input
									id="verificationCodeId"
									type={appStore.isIos ? 'text' : 'number'}
									maxLength={6}
									value={this.data.verificationCode}
									onChange={this.onCodeChange}
									onKeyDown={this.handleKeyDown}
									autoComplete="one-time-code"
									className="hidden-input"
								/>
							)}
							{this.data.verificationCodeError && (
								<BasicInput
									id="verificationCodeId"
									type={appStore.isIos ? 'text' : 'number'}
									maxLength={6}
									value={this.data.verificationCode}
									onChange={this.onCodeChange}
									onKeyDown={this.handleKeyDown}
									autoComplete="one-time-code"
								></BasicInput>
							)}
						</div>
						<div className="text-link hbox vcenter" onClick={this.onResend}>
							<div className="text">{this.text.missingCodeText}</div>
							<div className={`cta ${this.props.consumer ? 'purple' : 'blue'}`}>{this.text.missingCodeCta}</div>
						</div>
						<Button
							fill
							large
							className={`round-btn ${this.props.consumer ? 'purple' : 'blue'}`}
							disabled={this.data.verificationCode.length !== 6}
							onClick={this.onNext}
						>
							{this.text.submitCta}
						</Button>
						<div id="recaptcha2" />
					</div>
				</div>
			</>
		);
	}
}
