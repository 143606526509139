// @ts-nocheck
import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind, validateEmail, validateUrl } from '@/utils/GeneralUtils';
import BasicInput from '@/components/basic-input/BasicInput';
import { ListItem, Preloader, Toggle } from 'framework7-react';
import DropDown from '@/components/drop-down/DropDown';
import './express-form-builder.scss';
import IconPicker from '@/components/_EXPRESS/icon-picker/IconPicker';
import { Multiselect } from 'multiselect-react-dropdown';

@observer
export default class ExpressFormBuilder extends Component {
	constructor(props) {
		super(props);
		// this.data = observable({ emailValid: false, sortedFormConfigKeys: [] });
		this.state = { emailValid: false, sortedFormConfigKeys: [] };
		autoBind(this);
	}

	componentDidMount() {
		this.sortFormConfigKeys();

		if (this.props.formData && Object.keys(this.props.formData).length > 0) {
			this.runValidator();
		}
	}

	componentDidUpdate() {
		const formConfigKeys = Object.keys(this.props.formConfig || []);
		if (formConfigKeys.length > 0) {
			if (
				formConfigKeys.every((configKey) =>
					this.state.sortedFormConfigKeys.includes(configKey)
				) &&
				formConfigKeys.length === this.state.sortedFormConfigKeys.length
			) {
				return;
			} else {
				this.sortFormConfigKeys();
			}
		} else {
			if (this.state.sortedFormConfigKeys.length !== 0)
				this.setState({ sortedFormConfigKeys: formConfigKeys });
		}
	}

	sortFormConfigKeys() {
		const { formConfig } = this.props;
		const keys = Object.keys(formConfig || []).sort((a, b) => {
			if (formConfig[a]?.sortOrder && formConfig[b]?.sortOrder)
				return formConfig[a]?.sortOrder - formConfig[b]?.sortOrder;

			return 0;
		});
		this.setState({ sortedFormConfigKeys: keys });
	}

	validateField(validator, value) {
		if (_.isEmpty(value) && validator.notRequired) {
			return true;
		}
		switch (validator.type) {
			case 'maxLength': {
				return value && value.length <= validator.value;
			}
			case 'length': {
				if (validator.match) {
					return value && value.length === validator.value;
				} else {
					return value && value.length >= validator.value;
				}
			}
			case 'tagCount': {
				if (value && value.length >= validator.value) {
					return true;
				}
				return false;
			}
			case 'date': {
				if (value && value.length === 10) {
					return true;
				}
				return false;
			}
			case 'phone': {
				value = value.replace(/\D/g, '');
				if (value && value.length === 10) {
					return true;
				}
				return false;
			}
			case 'email': {
				// return this.data.emailValid || validateEmail(value);
				return this.state.emailValid || validateEmail(value);
			}
			case 'url': {
				return validateUrl(value);
			}
		}
	}

	runValidator() {
		let { formData, formConfig, customValidator } = this.props;
		if (!customValidator) {
			let isValid = true;
			_.forEach(Object.keys(formConfig), (key) => {
				if (key.indexOf('row') == 0) {
					_.forEach(
						Object.keys(_.get(this, `props.formConfig[${key}]`, {})),
						(rowKey) => {
							//row fields validator
							let validator = _.get(formConfig, `${key}.${rowKey}.validator`);
							if (validator) {
								let valid = this.validateField(validator, formData[rowKey]);
								isValid = isValid && (!!valid || !!validator.notRequired);
							}
						}
					);
				} else {
					//root field validator
					let validator = _.get(formConfig, `${key}.validator`);
					if (validator) {
						let valid = this.validateField(validator, formData[key]);
						isValid = isValid && (!!valid || !!validator.notRequired);
					}
				}
			});
			if (this.props.setValidationState) {
				this.props.setValidationState(isValid);
			}
		}
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		let value = target.value;
		if (target.name === 'phone') {
			value = value.replace(/\D/g, '');
		}
		let dataProperty = _.clone(target.name);
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty =
				dataProperty.split('.')[dataProperty.split('.').length - 1];
		}

		const currentValue = _.cloneDeep(this.props.formData[dataProperty]);
		if (!_.isEqual(currentValue, value)) {
			this.props.formData[dataProperty] = value;
			if (this.props.afterChange) {
				this.props.afterChange(dataProperty);
			}
		}

		if (target.type === 'email') {
			const emailValid = this.validateField({ type: 'email' }, value);
			this.handleEmailCheck(emailValid);
		}

		this.runValidator();
	}

	onDateInputChange(evt) {
		let target = evt.currentTarget;
		const value = target.value;
		const cleanValue = value.replace(/[^0-9]/g, '');
		const cleanParts = cleanValue.split('');
		let month = cleanParts.slice(0, 2).join('');
		let day = cleanParts.slice(2, 4).join('');
		let year = cleanParts.slice(4, 8).join('');
		let dataProperty = target.name;
		if (target.name.indexOf('.') >= 0) {
			dataProperty = target.name.split('.')[target.name.split('.').length - 1];
		}
		if (cleanParts.length >= 5) {
			this.props.formData[dataProperty] = `${month}/${day}/${year}`;
		} else if (cleanParts.length >= 3) {
			this.props.formData[dataProperty] = `${month}/${day}`;
		} else {
			this.props.formData[dataProperty] = `${month}`;
		}
		if (this.props.afterChange) {
			this.props.afterChange(dataProperty);
		}
		this.runValidator();
	}

	handleEmailCheck(isValid) {
		const email = this.props.formData.email || '';
		this.props.formData.emailValid = isValid && email.length > 0;
	}

	buildDropDown(key, config) {
		let { formData } = this.props;
		let dataProperty = key;
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty =
				dataProperty.split('.')[dataProperty.split('.').length - 1];
		}
		let selected = formData[dataProperty];

		let listItems = [];
		_.forEach(Object.keys(config.listItems), (listItemKey) => {
			if (key) {
				let label = config.listItems[listItemKey];
				let value = _.clone(label);
				// let icon = config.listItems[listItemKey]
				if (
					config.displayValues &&
					typeof config.displayValues === 'function'
				) {
					value = config.displayValues(listItemKey, config.listItems);
				}
				listItems.push(
					<ListItem
						link="#"
						popoverClose
						className="drop-down-list-item express-dropdown"
						key={`${dataProperty}-${listItemKey}-dropdown-item`}
						onClick={() => {
							const currentValue = _.cloneDeep(
								this.props.formData[dataProperty]
							);
							if (!_.isEqual(currentValue, listItemKey)) {
								this.props.formData[dataProperty] = listItemKey;
								if (this.props.afterChange) {
									this.props.afterChange(dataProperty);
								}
							}
							this.runValidator();
						}}
					>
						<div className="dd-label">{value}</div>
					</ListItem>
				);
			}
		});
		let displayValue = config.listItems[selected];
		if (config.displayValues && typeof config.displayValues === 'function') {
			displayValue = config.displayValues(selected, config.listItems);
		}
		let { showHelpIconComponent, helpComponentText } = config;
		return (
			<DropDown
				key={`express-dropdown-${dataProperty}`}
				name={`formbuilder-dropdown-${dataProperty}`}
				label={config.label}
				icon={config.icon}
				items={listItems}
				className={`express-dropdown md ${config.className}`}
				displayValue={displayValue}
				helpComponentText={helpComponentText}
				showHelpIconComponent={showHelpIconComponent}
			/>
		);
	}

	handleSingleSelectCheckbox(
		currentField,
		otherCheckboxesInCheckBoxGroup,
		formData
	) {
		// *NOTE: Only runs when the checkbox selective mode is single
		otherCheckboxesInCheckBoxGroup.forEach((field) => {
			if (formData[field] === formData[currentField]) {
				formData[field] = !formData[field];
			}
		});
	}

	buildField(key) {
		let { formData } = this.props;
		const formConfig = _.get(this.props.formConfig, key) || {};

		let {
			label,
			placeholder,
			type,
			className,
			validator,
			disabled,
			resizable,
			tags,
			hideField,
			checkBoxGroup,
			canHaveMultipleValuesSelected,
			otherCheckboxesInCheckBoxGroup
		} = formConfig;

		let dataProperty = key;
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty =
				dataProperty.split('.')[dataProperty.split('.').length - 1];
		}

		switch (type) {
			case 'loader': {
				if (hideField) return null;
				return (
					<div
						className="form-loader vbox vcenter hcenter"
						key="express-form-loader-key"
					>
						<Preloader></Preloader>
					</div>
				);
			}
			case 'text': {
				if (hideField) return null;
				return (
					<BasicInput
						key={`express-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={_.get(formData, dataProperty)}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'textarea': {
				if (hideField) return null;

				return (
					<BasicInput
						key={`express-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''} ${resizable ? 'resizable' : ''}`}
					/>
				);
			}
			case 'number': {
				if (hideField) return null;
				const parsedNum = parseInt(
					_.get(formData, dataProperty, '')?.toString().replace(/\D+/g, '')
				);
				return (
					<BasicInput
						key={`express-form-input-${key}`}
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={parsedNum}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'toggle': {
				if (hideField) return null;
				return (
					<div
						className="express-form-toggle hbox vcenter"
						key={`express-form-toggle-${key}`}
					>
						<div className="label">{label}</div>
						<Toggle
							checked={formData[key] || false}
							onChange={(e) => {
								formData[key] = e.target.checked;
								if (this.props.afterChange) {
									this.props.afterChange(key);
								}
							}}
						></Toggle>
					</div>
				);
			}
			case 'phone': {
				if (hideField) return null;
				return (
					<BasicInput
						key={`express-form-input-${key}`}
						label={label}
						name={key}
						type="text"
						maxlength={10}
						minlength={10}
						validate={true}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'email': {
				if (hideField) return null;
				return (
					<BasicInput
						key={`express-form-input-${key}`}
						label={label}
						validate={true}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						disabled={disabled || false}
						onValidate={
							validator
								? (isValid) => {
										// this.data.emailValid = isValid;
										this.setState({ emailValid: isValid });
								  }
								: this.handleEmailCheck
						}
						onChange={this.onInputChange}
						className={`form-builder-email-input ${className || ''}`}
					/>
				);
			}
			case 'password': {
				if (hideField) return null;
				return (
					<BasicInput
						key={`express-form-input-${key}`}
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
					/>
				);
			}
			case 'dropdown': {
				if (hideField) return null;

				return this.buildDropDown(key, _.get(this, `props.formConfig.${key}`));
			}
			case 'iconPicker': {
				if (hideField) return null;
				return (
					<IconPicker
						key={`express-form-input-${key}`}
						label={label}
						onSelect={(icon) => {
							formData[key] = icon;
							if (this.props.afterChange) {
								this.props.afterChange(key);
							}
							this.runValidator();
						}}
						currentvalue={formData[dataProperty]}
					/>
				);
			}
			case 'date': {
				if (hideField) return null;
				return (
					<BasicInput
						key={`express-form-input-${key}`}
						label={label}
						onChange={this.onDateInputChange}
						name={key}
						placeholder="MM/DD/YYYY"
						value={formData[dataProperty]}
						disabled={disabled || false}
						type="text"
						className={`${className || ''}`}
					/>
				);
			}
			case 'tagpicker': {
				if (hideField) return null;
				return (
					<div
						className="express-tagpicker"
						key={`express-form-tagpicker-${key}`}
					>
						<div className="label">{label}</div>
						<Multiselect
							selectedValues={formData[dataProperty]}
							placeholder={placeholder}
							options={tags}
							onSelect={(e) => {
								formData[dataProperty] = e;
								this.runValidator();
							}}
							onRemove={(e) => {
								formData[dataProperty] = e;
								this.runValidator();
							}}
							selectedValues={formData[dataProperty]}
							displayValue="name"
						/>
					</div>
				);
			}
			case 'checkbox': {
				if (hideField) return null;

				return (
					<ListItem
						key={`express-form-checkbox-${key}`}
						checkbox
						title={label}
						name={key}
						onChange={() => {
							formData[key] = !formData[key];
							if (
								checkBoxGroup &&
								canHaveMultipleValuesSelected === false &&
								otherCheckboxesInCheckBoxGroup?.length > 0
							) {
								this.handleSingleSelectCheckbox(
									key,
									otherCheckboxesInCheckBoxGroup,
									formData
								);
							}
						}}
						value={key}
						checked={formData[key]}
					/>
				);
			}
			default: {
				return;
			}
		}
	}

	formBuilder() {
		let formInputs = [];
		_.forEach(this.state.sortedFormConfigKeys, (key) => {
			if (key.indexOf('row') == 0) {
				let rowFields = [];
				_.forEach(
					Object.keys(_.get(this, `props.formConfig[${key}]`, {})),
					(rowKey) => {
						let field = this.buildField(`${key}.${rowKey}`);
						if (field) {
							rowFields.push(field);
						}
					}
				);
				formInputs.push(
					<div className="form-row hbox vtop" key={`form-row-${key}`}>
						{rowFields}
					</div>
				);
			} else {
				let field = this.buildField(key);
				if (field) {
					formInputs.push(field);
				}
			}
		});
		return formInputs;
	}

	render() {
		return <div className="express-form-builder">{this.formBuilder()}</div>;
	}
}
