import React, { Component } from 'react';
import { List, ListInput } from 'framework7-react';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import ConfigService from '../../services/ConfigService';
import appStore from '../../stores/AppStore';
import UserService from '../../services/UserService';
import './language-selection.scss';

export default class Profile extends Component {
	constructor(props) {
		super();
		this.phoneNumber = '866-549-9533';
		this.text = getCmpText('LanguageSelection', {
			label: 'Language: ',
			placeholder: 'Please choose...'
		});
		autoBind(this);
	}

	updateLanguage(evt) {
		const selected = evt.currentTarget.value;
		UserService.setLocale(selected);
		this.$f7.views.main.router.refreshPage();
	}

	render() {
		const translations = ConfigService.getConfigValue('language', {});
		return (
			<List className="language-dropdown" noHairlines>
				<ListInput
					type="select"
					defaultValue={appStore.locale}
					placeholder={this.text.placeholder}
					onChange={this.updateLanguage}
					inlineLabel
				>
					{Object.values(translations).map((langOption) => (
						<option key={langOption.config.code} className="language-radio" value={langOption.config.code}>
							{langOption.config.displayValues.language}
						</option>
					))}
				</ListInput>
			</List>
		);
	}
}
