import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import userStore from '@/stores/UserStore';
import GradiantStack from '@/components/gradiant-stack/GradiantStack';
import AnalyticsService from '@/services/AnalyticsService';
import PassSVG from '@/assets/password.svg';
import BasicInput from '@/components/basic-input/BasicInput';
import CloudAPI from '@/services/CloudAPI';
import AccountVerification from '../../account-verification/AccountVerification';
import './create-password.scss';

const analytics = new AnalyticsService();
@observer
export default class CreatePassword extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ password: '', confirmation: '', errorType: null, valid: false });
		this.text = getCmpText('EmployerSignup', {
			passwordLabel: 'Password',
			passwordConfirmationLabel: 'Confirm Password',
			passwordRules: 'Password must contain 8 or more characters, at least 1 capital letter, and 1 number',
			verifyBySmsBtn: 'Verify by text message',
			verifyByEmailBtn: "I don't have access to my phone",
			createPasswordTitle: 'Create Password',
			stepText: 'Step <%= step.current %> of <%= step.total %>',
			passwordMismatchError: 'Password and confirmation password must match',
			createAccountError: 'Error creating account, please try again later'
		});
		autoBind(this);
	}

	validateForm() {
		const password = this.data.password;
		const confirmation = this.data.confirmation;
		var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
		const isStrongPassword = strongRegex.test(password);
		const passwordMatch = password === confirmation;
		if (!passwordMatch) {
			this.data.errorType = 'passwordMatch';
		}
		if (!isStrongPassword) {
			this.data.errorType = 'passwordStrength';
		}
		if (isStrongPassword && passwordMatch) {
			this.data.errorType = null;
		}
		this.data.valid = isStrongPassword && passwordMatch;
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		this.data[target.name] = target.value;
		this.validateForm();
	}

	async createFbUser() {
		const userEmail = this.props.info.email;
		const userPassword = this.data.password;
		try {
			await firebase.auth().createUserWithEmailAndPassword(userEmail, userPassword);
			return { success: true };
		} catch (err) {
			console.log('error creating fb user');
			console.log({ err });
			this.$f7.dialog.alert(this.text.createAccountError);
			return { success: false };
		}
	}

	async createAccount() {
		userStore.isCreatingUser = true;
		const currentRecord = _.cloneDeep(this.props.info);
		const newFbUser = await this.createFbUser();
		if (newFbUser.success) {
			const newFbUserId = firebase.auth().currentUser.uid;
			const newUserRecord = await CloudAPI.createAccount({ memberDetails: currentRecord, firebaseUid: newFbUserId });
			if (newUserRecord.success) {
				analytics.trackWithData('Sign up', { step: 'create password', type: 'employer' });
				return { success: true };
			} else {
				this.$f7.dialog.alert(this.text.createAccountError);
				return { success: false };
			}
		}
	}

	async onNext(page, verificationType) {
		this.props.onNext('accountVerification', verificationType);
	}

	render() {
		const stepTextTemplate = _.template(this.text.stepText);
		const stepDisplayText = stepTextTemplate(_.cloneDeep(this.props));
		const errorMessage = this.data.errorType === 'passwordMatch' && this.text.passwordMismatchError;
		return (
			<Fragment>
				<GradiantStack stack="2" animate={true} id="create-password" />
				<div className={`employer-password-page y-scroll`}>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={PassSVG} className="herosvg" />
						<h1>{this.text.createPasswordTitle}</h1>
						<div className="step-text">{stepDisplayText}</div>
						<BasicInput
							label={this.text.passwordLabel}
							name="password"
							type="password"
							placeholder={this.text.passwordLabel}
							value={this.data.password}
							onChange={this.onInputChange}
						/>
						<BasicInput
							label={this.text.passwordConfirmationLabel}
							name="confirmation"
							type="password"
							placeholder={this.text.passwordConfirmationLabel}
							value={this.data.confirmation}
							onChange={this.onInputChange}
							errorMessage={errorMessage}
						/>
						<div className={`text ${this.data.errorType === 'passwordStrength' ? 'red' : ''}`}>{this.text.passwordRules}</div>
						<AccountVerification
							id="employer-create-password"
							onNext={this.onNext}
							disabled={!this.data.valid}
							createAccount={this.createAccount}
							email={this.props.info.email}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}
