import React, { Component } from 'react';
import { Page, Link, Toolbar, Button, Block, Badge, Popup } from 'framework7-react';
import Logo from '../../static/logo/Logo';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import './application-shell.scss';
import appStore from '../../stores/AppStore';
import providerStore from '../../stores/ProviderStore';
import HomePage from '../home-page/HomePage';
import Profile from '../profile/Profile';
import TelehealthPrompt from '../../components/telehealth-prompt/TelehealthPrompt';
import FindCare from '../find-care/FindCare';
import userStore from '../../stores/UserStore';
import Appointments from '../appointments/Appointments';
import Booking from '../../components/booking/Booking';
import UserService from '../../services/UserService';
import AdminPage from '../admin-page/AdminPage';
import AnalyticsService from '../../services/AnalyticsService';
import ConfigService from '../../services/ConfigService';
import _ from 'lodash';
import Prescriptions from '../prescription/Prescriptions';
import ShopPopup from '@/components/shop-popup/ShopPopup';
import ConsumerTelehealth from '../login/consumer-telehealth/ConsumerTelehealth';
import BalanceBillPopup from '@/components/balance-billing-popup/BalanceBillPopup';
import ProcedureSearchPopup from '@/components/procedure-search-popup/ProcedureSearchPopup';

const analytics = new AnalyticsService();
@observer
class ApplicationShell extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ test: false });
		this.text = getCmpText('ApplicationShell', {
			setLocation: 'Set Location',
			home: 'Home',
			doctors: 'Find Care',
			appointments: 'Appointment',
			myInfo: 'Account',
			adminText: 'Admin',
			prescriptionText: 'Prescriptions'
		});
		autoBind(this);
	}

	changeTab(e) {
		appStore.activeTab = e.currentTarget.dataset.tab;
	}

	getActiveContent() {
		switch (appStore.activeTab) {
			case 'home': {
				analytics.track('loaded_home');
				return <HomePage />;
			}
			case 'doctors': {
				analytics.track('loaded_find_care');
				return <FindCare />;
			}
			case 'appointments': {
				analytics.track('loaded_appointments');
				return <Appointments />;
			}
			case 'profile': {
				analytics.track('loaded_user_profile');
				return <Profile key={Math.random()} />;
			}
			case 'prescription': {
				analytics.track('loaded_prescriptions');
				return <Prescriptions key={Math.random()} />;
			}
			case 'admin': {
				analytics.track('loaded_admin');
				return <AdminPage />;
			}
			case 'providermgmt': {
				analytics.track('loaded_provider_management');
				return (
					<div className="provider-mgmt-launcher">
						<h1>Launch Advanced Provider Management?</h1>
						<Block>
							<Button className="neumorphic neuhover">Launch</Button>
						</Block>
					</div>
				);
			}
		}
	}

	onBookingClose() {
		appStore.bookingOpen = false;
		setTimeout(() => {
			providerStore.selectedCareTeam = null;
		}, 200);
	}
	//TODO clean this up later and build it dynamically
	getDesktopMenu() {
		let { home, doctors, appointments, myInfo, adminText, setLocation, prescriptionText } = this.text;
		let config = ConfigService.getConfiguration();
		const { apptActions } = appStore;
		const hasActions = apptActions && apptActions > 0;
		const isDemoMode = appStore.demoMode;
		return (
			<div className="desktop-menu hbox">
				<div className="main-content">
					<Logo icon size="small" />
					<div className="spacer"></div>
					<div
						className="current-location hbox vcenter hcenter neumorphic neuhover"
						onClick={() => {
							appStore.setLocationOpen = true;
						}}
					>
						<i className="fad fa-map-marked-alt" />
						<div className="location ellipse">{_.get(appStore, 'currentLocation.name', setLocation)}</div>
					</div>
					{!UserService.isPDM() && (
						<>
							<div
								className={`cv-menu-item neumorphic ${appStore.activeTab === 'home' ? 'active' : ''}`}
								onClick={() => {
									appStore.activeTab = 'home';
								}}
							>
								<i className="fad fa-home-heart" />
								{home}
							</div>
							{!_.get(config, 'features.turnOffProvider', false) && (
								<div
									className={`cv-menu-item neumorphic ${appStore.activeTab === 'doctors' ? 'active' : ''}`}
									onClick={() => {
										appStore.activeTab = 'doctors';
									}}
								>
									<i className="fad fa-search" />
									{doctors}
								</div>
							)}
							{_.get(config, 'features.appointments') && !UserService.isConsumer() && (
								<div
									className={`cv-menu-item neumorphic ${appStore.activeTab === 'appointments' ? 'active' : ''}`}
									onClick={() => {
										appStore.activeTab = 'appointments';
									}}
								>
									<div className="icon-container">
										<i className="fad fa-calendar-day" />
										{!!hasActions && <Badge color="red">{apptActions}</Badge>}
									</div>

									{appointments}
								</div>
							)}
							{!_.get(config, 'features.turnOffPrescriptions') && (
								<div
									className={`cv-menu-item neumorphic ${appStore.activeTab === 'prescription' ? 'active' : ''}`}
									onClick={() => {
										appStore.activeTab = 'prescription';
									}}
								>
									<i className="fad fa-prescription" />
									{prescriptionText}
								</div>
							)}
							<div
								className={`cv-menu-item neumorphic ${appStore.activeTab === 'profile' ? 'active' : ''}`}
								onClick={() => {
									appStore.activeTab = 'profile';
								}}
							>
								<i className="fad fa-user-cog" />
								{myInfo}
							</div>
						</>
					)}
					<div className="spacer"></div>
					{UserService.isAdmin() && (
						<div
							className={`cv-menu-item neumorphic ${appStore.activeTab === 'admin' ? 'active' : ''}`}
							onClick={() => {
								appStore.activeTab = 'admin';
							}}
						>
							<i className="fad fa-user-crown"></i>
							{adminText}
						</div>
					)}
					{UserService.isAdmin() && isDemoMode && (
						<div
							className={`cv-menu-item neumorphic active`}
							onClick={() => {
								UserService.exitDemoMode();
							}}
						>
							<i className="fad fa-id-card-alt"></i>
							Exit Demo Mode
						</div>
					)}
					{(UserService.isAdmin() || UserService.isPDM()) && !appStore.isMobile && (
						<div
							className={`cv-menu-item neumorphic`}
							onClick={() => {
								globalRouter.navigate('/providermgmt');
							}}
						>
							<i className="fad fa-notes-medical"></i>
							Provider Mgmt
						</div>
					)}
					{UserService.isPDM() && (
						<div
							className={`cv-menu-item neumorphic`}
							onClick={() => {
								firebase.auth().signOut();
							}}
						>
							<i className="fad fa-sign-out"></i>
							Sign Out
						</div>
					)}
				</div>
				<div className="color-bar"></div>
			</div>
		);
	}

	render() {
		let { selectedCareTeam, openProvider } = providerStore;
		let config = ConfigService.getConfiguration();
		const hasActions = appStore.apptActions && appStore.apptActions > 0;
		return (
			<Page name="home" className={`landing-page ${!appStore.isMobile ? 'desktop' : ''}`}>
				{!appStore.isMobile && this.getDesktopMenu()}
				{userStore.isLoggedIn && <div className="content">{this.getActiveContent()}</div>}
				{appStore.isMobile && !UserService.isPDM() && (
					<Toolbar tabbar labels bottom className="application-toolbar ios">
						<Link tabLink="home" tabLinkActive={appStore.activeTab === 'home'} onClick={this.changeTab} className="use-icon ios">
							<i className="fad fa-home-heart" />
						</Link>
						{!_.get(config, 'features.turnOffProvider', false) && (
							<Link
								tabLink="doctors"
								tabLinkActive={appStore.activeTab === 'doctors'}
								onClick={this.changeTab}
								className="use-icon ios"
							>
								<i className="fad fa-search" />
							</Link>
						)}
						{_.get(config, 'features.appointments') && !UserService.isConsumer() && (
							<Link
								tabLink="appointments"
								tabLinkActive={appStore.activeTab === 'appointments'}
								onClick={this.changeTab}
								className="use-icon ios"
							>
								<i className="fad fa-calendar-day" />
								{hasActions && <Badge color="red">{appStore.apptActions}</Badge>}
							</Link>
						)}

						{!_.get(config, 'features.turnOffPrescriptions') && (
							<Link
								tabLink="prescription"
								tabLinkActive={appStore.activeTab === 'prescription'}
								onClick={this.changeTab}
								className="use-icon ios"
							>
								<i className="fad fa-prescription" />
							</Link>
						)}

						<Link tabLink="profile" tabLinkActive={appStore.activeTab === 'profile'} onClick={this.changeTab} className="use-icon ios">
							<i className="fad fa-user-cog" />
						</Link>
						{UserService.isAdmin() && (
							<Link tabLink="admin" tabLinkActive={appStore.activeTab === 'admin'} onClick={this.changeTab} className="use-icon ios">
								<i className="fad fa-user-crown"></i>
							</Link>
						)}
						{UserService.isAdmin() && appStore.demoMode && (
							<Link tabLink="demoMode" tabLinkActive={appStore.demoMode} onClick={UserService.exitDemoMode} className="use-icon ios">
								<i className="fad fa-id-card-alt"></i>
							</Link>
						)}
					</Toolbar>
				)}
				<Booking
					open={appStore.bookingOpen}
					onClose={this.onBookingClose}
					careTeam={selectedCareTeam}
					npi={selectedCareTeam ? null : _.cloneDeep(_.get(openProvider, 'NPI'))}
				/>
				<BalanceBillPopup
					opened={appStore.balanceBillOpen}
					onClose={() => {
						appStore.balanceBillOpen = false;
					}}
				></BalanceBillPopup>
				<TelehealthPrompt />
				<Popup opened={appStore.consumerEnrollementOpen} className="telehealth-enroll-popup">
					<ConsumerTelehealth onCancel={appStore.onCloseEnrollment} enroll={true}></ConsumerTelehealth>
				</Popup>
				<ProcedureSearchPopup
					opened={appStore.procedureSearchOpen}
					onClose={() => {
						appStore.procedureSearchOpen = false;
					}}
				/>
				{UserService.isConsumer() && (
					<ShopPopup shopItem={appStore.shopItem} opened={appStore.shopOpen} onCloseShop={appStore.closeShop}></ShopPopup>
				)}
				{/* TODO IDCard Viewer */}
				{/* TODO Benefits Viewer */}
			</Page>
		);
	}
}

export default ApplicationShell;
