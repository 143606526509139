import React, { PureComponent } from 'react';
import appStore from '@/stores/AppStore';
import Stack1 from '../../assets/Stack1.js';
import Stack2 from '../../assets/Stack2.js';
import Stack3 from '../../assets/Stack3.js';
import './gradiant-stack.scss';
const stacks = {
	stack1: Stack1,
	stack2: Stack2,
	stack3: Stack3
};
export default class GradiantStack extends PureComponent {
	render() {
		let { stack, animate, consumer, id } = this.props;
		const SVGElement = stacks[`stack${stack}`];
		return (
			<div className={`gradiant-stack vbox vcenter hcenter ${`stack-${stack}`} ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<div>
					<SVGElement purple={consumer} animate={animate} id={id}></SVGElement>
				</div>
			</div>
		);
	}
}
