import CanvasFactory from '@/factories/CanvasFactory';
import { SELECT_MODE } from '@/services/CanvasEditorService';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import React, { Component } from 'react';
import { Transformer } from 'react-konva';

@observer
export default class CanvasTransformer extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	handleTransformer(transformerRef) {
		const {
			setTransformer,
			editorStore: { selectedNodes }
		} = CanvasFactory.getActiveService();

		if (selectedNodes && selectedNodes.length > 0) {
			const stage = transformerRef.getStage();
			const selected = stage.find('#' + selectedNodes.join(',#'));
			if (transformerRef) {
				transformerRef.show();
				if (selected) {
					transformerRef.nodes(selected);
				} else {
					transformerRef.nodes([]);
				}
				transformerRef.getLayer().batchDraw();
				setTransformer(transformerRef);
			}
		}
	}

	renderTextTransformer() {
		const { editorVisible } = CanvasFactory.getActiveStore();
		return (
			<Transformer
				key={`${this.props.name}-transformer`}
				ref={this.handleTransformer}
				rotateEnabled={false}
				enabledAnchors={editorVisible ? [] : ['middle-left', 'middle-right']}
				boundBoxFunc={(oldBox, newBox) => {
					newBox.width = Math.max(30, newBox.width);
					return newBox;
				}}
			/>
		);
	}

	renderDefaultTransformer() {
		return <Transformer key={`${this.props.name}-transformer`} ref={this.handleTransformer} rotateEnabled={false} />;
	}

	renderTransformer() {
		const { selectedNodes, selectMode } = CanvasFactory.getActiveStore();

		console.log(selectedNodes)
		if (selectedNodes && selectMode === SELECT_MODE.SINGLE && selectedNodes[0].includes('text')) {
			return this.renderTextTransformer();
		} else if (selectedNodes.length === 1 && selectedNodes[0].includes('background')) {
			return <></>;
		} else {
			return this.renderDefaultTransformer();
		}
	}

	render() {
		const { selectedNodes } = CanvasFactory.getActiveStore();
		if (selectedNodes) {
			return this.renderTransformer();
		} else {
			return <></>;
		}
	}
}
