import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import CanvasFactory from '@/factories/CanvasFactory';
import BasicInput from '@/components/basic-input/BasicInput';
import './document-title.scss';
import expressStore from '@/stores/ExpressStore';
@observer
export default class DocumentTitle extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			nameEdit: false,
			invalid: false
		});
		autoBind(this);
	}

	renderInput() {
		const { documentName } = CanvasFactory.getActiveStore();
		const { setDocumentName } = CanvasFactory.getActiveService();
		const reservedNames = ['default_front', 'default_back'];
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		let name = documentName;
		if (this.data.nameEdit) {
			return (
				<>
					<BasicInput
						type="text"
						name="title"
						value={name}
						onChange={(e) => {
							const value = e.target.value;
							if (reservedNames.indexOf(value) > -1) {
								this.$f7.dialog.alert('Sorry, that name is taken.  Please try something else.');
								setDocumentName('');
							} else {
								setDocumentName(e.target.value);
							}
						}}
					/>
					<Button
						className="title-edit"
						disabled={this.data.invalid}
						onClick={(e) => {
							this.data.nameEdit = false;
						}}
					>
						<i className="fa fa-check"></i>
					</Button>
				</>
			);
		} else {
			return (
				<>
					<span className="document-title">{name}</span>
					{canEdit && reservedNames.indexOf(name) < 0 && (
						<Button
							className="title-edit"
							onClick={(e) => {
								if (this.data) this.data.nameEdit = true;
							}}
						>
							<i className="fad fa-pencil"></i>
						</Button>
					)}
				</>
			);
		}
	}

	render() {
		const { documentName } = CanvasFactory.getActiveStore();
		const { setDocumentName } = CanvasFactory.getActiveService();

		let name = documentName.split('::')[0];
		return <div className="hbox title-bar-left vcenter">{this.renderInput()}</div>;
	}
}
