import React, { Component } from 'react';
import { Segmented, Button, Range } from 'framework7-react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import './range-slider.scss';

export default class RangeSlider extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('ProviderFilter', {
			radius: 'Search Radius ',
			miles: ' (miles)'
		});
		autoBind(this);
	}

	onRadiusChange(value) {
		this.props.onChange(value);
	}

	componentDidUpdate() {
		const { max, scaleSteps, scaleSubSteps, value, step, min } = this.props;
		const currentSlider = this.$f7.range.get(`#${this.props.source}`);
		const currentMax = currentSlider && currentSlider.max;
		if (currentSlider && currentMax !== max) {
			currentSlider.max = max;
			currentSlider.min = min;
			currentSlider.step = step;
			currentSlider.scaleSteps = scaleSteps;
			currentSlider.scaleSubSteps = scaleSubSteps;
			currentSlider.updateScale();
			currentSlider.setValue(value);
		}
	}

	render() {
		let { miles, radius } = this.text;
		const { max, scaleSteps, scaleSubSteps, value, step, min, scale, source } = this.props;
		return (
			<div>
				<div className="label">
					{radius}
					<strong> {value}</strong> {miles}
				</div>
				<Range
					id={source}
					min={min}
					max={max}
					step={step}
					value={value}
					scale={scale}
					scaleSteps={scaleSteps}
					scaleSubSteps={scaleSubSteps}
					onRangeChanged={this.onRadiusChange}
				/>
			</div>
		);
	}
}
