import { autoBind } from '@/utils/GeneralUtils';
import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { Layer, Stage, Transformer, Group, Image } from 'react-konva';
import CanvasFactory from '@/factories/CanvasFactory';
import ExpressAPI from '@/services/ExpressAPI';

@observer
export default class BackgroundNode extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ imageLoaded: false });
		autoBind(this);
	}

	componentDidMount() {
		const {
			getBackground,
			editorStore: { backgroundData, backgroundEdit }
		} = CanvasFactory.getActiveService();

		
		if (backgroundData && backgroundData.type === 'image' && !backgroundData.image) {
			this.loadImage();
		}
	}

	loadImage() {
		const { documentId, backgroundData } = CanvasFactory.getActiveStore();
		ExpressAPI.getDocumentAsset(documentId, backgroundData.key).then((url) => {
			backgroundData.image = new window.Image();
			backgroundData.image.crossOrigin = 'Anonymous';
			backgroundData.image.src = url;
			backgroundData.image.addEventListener('load', () => {
				CanvasFactory.getActiveService().draw();
				this.data.imageLoaded = true;
			});
			backgroundData.imageLoaded = true;
			backgroundData.image.onerror = () => {
				const service = CanvasFactory.getActiveService();
				this.$f7.dialog.alert('There was an error loading your background image, please add a new one');
				service.setBackground(null);
			}
		});
	}

	handleTransformer(transformerRef) {
		const { setTransformer } = CanvasFactory.getActiveService();
		if (transformerRef) {
			transformerRef.show();
			const stage = transformerRef.getStage();
			const selected = stage.find((option) => {
				const optionType = option.getType();
				const optionId = option.getId();
				const isImage = optionId.indexOf('background') > -1 && optionType === 'Shape';
				return isImage;
			})
			if (selected) {
				transformerRef.nodes(selected);
			} else {
				transformerRef.nodes([]);
			}

			transformerRef.getLayer().batchDraw();
			setTransformer(transformerRef);
		}
	}

	render() {
		const {
			getBackground,
			editorStore: { backgroundData, backgroundEdit }
		} = CanvasFactory.getActiveService();

		if (!backgroundData) {
			return <></>;
		} else {
			return (
				<>
					<Group ref={getBackground} id="background">
						{backgroundData.image && <Image {...backgroundData} draggable={backgroundEdit} />}
						{backgroundEdit && (
							<Transformer key={`background-transformer`} centeredScaling={true} ref={this.handleTransformer} rotateEnabled={false} />
						)}
					</Group>
				</>
			);
		}
	}
}
