import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import { Preloader } from 'framework7-react';
@observer
export default class TemplateItem extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			url: null,
			active: false
		});
		this.image = React.createRef();
		autoBind(this);
	}

	async getImage() {
		const { id, thumbnailKey } = this.props;
		if (thumbnailKey) {
			ExpressAPI.getCanvasAsset(thumbnailKey).then((thumb) => {
				this.data.url = thumb;
			});
		}
	}
	componentDidMount() {
		this.getImage();
	}

	renderImage() {
		const { url } = this.data;
		if (!url) {
			return (
				<div className="image vbox vcenter hcenter">
					<Preloader color="blue" size="26"></Preloader>
				</div>
			);
		} else {
			return (
				<div className="image">
					<img src={url} alt="" />
				</div>
			);
		}
	}

	render() {
		const { name, tags, selected, id, onSelect } = this.props;
		return (
			<div
				className={`id-thumbnail vbox ${this.data.active ? 'active' : ''} ${selected === id ? 'selected' : ''}`}
				onMouseOver={() => (this.data.active = true)}
				onMouseOut={() => (this.data.active = false)}
				onClick={(e) => {
					if (e.target?.classList?.contains('fa-trash')) {
						return;
					}
					onSelect();
				}}
			>
				<div className={`image-container `}>
					<div className="actions vcenter hcenter hbox">
						<a className="btn" onClick={this.props.onEdit}>
							<i className="fa fa-pencil"></i>
						</a>
						<a className="btn delete" onClick={this.props.onDelete}>
							<i className="fa fa-trash"></i>
						</a>
					</div>
					{this.renderImage()}
				</div>
				<div className="vbox details vcenter">
					<div className="hbox name-container vcenter">
						<div className="vbox grow-1">
							<div className="name">{name}</div>
							<div className="tags">
								<Tags tags={tags} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const Tags = observer((props) => {
	const { tags } = props;
	if (tags.length === 0) {
		return <></>;
	} else {
		return tags.map((tag) => {
			return (
				<div className="chip">
					<div className="chip-label">{tag}</div>
				</div>
			);
		});
	}
});
