import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import './settings-page.scss';
import { autoBind } from '@/utils/GeneralUtils';
import TabSlider from '@/components/_EXPRESS/tab-slider/TabSlider';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import ExpressProfile from './express-profile/ExpressProfile';
import ExpressTeam from './express-team/ExpressTeam';
import ConfigEditor from '@/components/_EXPRESS/config-editor/ConfigEditor';
import MemberImportSettings from './member-import/MemberImportSettings';
import CustomFields from './custom-fields/CustomFields';

@observer
export default class SettingsPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ loadedFrom: null, activeTab: 0 });
		autoBind(this);
	}

	setActiveTab(tab) {
		this.data.activeTab = tab;
	}

	getActiveContent() {
		switch (this.data.activeTab) {
			case 0: {
				return <ExpressProfile></ExpressProfile>;
			}
			case 1: {
				return <ExpressTeam></ExpressTeam>;
			}
			case 2: {
				return <ConfigEditor type="org" />;
			}
			case 3: {
				return <MemberImportSettings />;
			}
			case 4: {
				return <CustomFields />;
			}
		}
	}

	//Global organization changed
	asyncLoadData() {
		setTimeout(() => {
			this.data.activeTab = 0;
			this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		}, 0);
	}

	getTabConfig() {
		const currentRole = expressStore.currentOrgRole();
		const orgRole = _.get(currentRole, 'role');
		const isOrgAdmin = orgRole === expressStore.roleEnums.ORG_ADMIN;
		const isSysAdmin = expressStore.isSystemAdmin();
		const showAdminTabs = isOrgAdmin || isSysAdmin;
		let tabs = {
			0: {
				name: 'Profile'
			}
		};
		if (showAdminTabs) {
			tabs[1] = {
				name: 'My Team'
			};
			tabs[2] = {
				name: 'App Config'
			};
			tabs[3] = {
				name: 'Data Imports'
			};
			tabs[4] = {
				name: 'Custom Fields'
			};
		}
		return tabs;
	}

	render() {
		let { loadedFrom } = this.data;
		let { currentOrg } = expressStore;

		if (loadedFrom !== currentOrg._id) {
			this.asyncLoadData();
		}
		return (
			<div className="express-settings-page">
				<h1 className="text-4 mb-xl">Settings</h1>
				<TabSlider activeTab={this.data.activeTab} setActiveTab={this.setActiveTab} config={this.getTabConfig()} />
				{this.getActiveContent()}
			</div>
		);
	}
}
