import _ from 'lodash';
import appStore from '@/stores/AppStore';
import StorageService from './StorageService';
import { getCmpText } from '../utils/Localization';

const storageService = new StorageService();

class BiometricsService {
	constructor() {
		this.text = getCmpText('Biometrics', {
			loginLabel: 'Sign in with ',
			faceLabel: 'FaceID',
			fingerprintLabel: 'Fingerprint',
			disabledAlert: 'Please visit the CareValet application settings and grant permission to login with ',
			credentialsError: 'temporarily disabled as account settings have changed.',
			genericError: 'Sign in failed, please try again'
		});
	}
	getBiometricsType() {
		return new Promise((resolve, reject) => {
			if (window.Fingerprint) {
				window.Fingerprint.isAvailable(
					(type) => {
						resolve(type);
					},
					(err) => reject(err)
				);
			} else {
				reject('Not available');
			}
		});
	}

	async checkForBiometricsAvailability() {
		if (window.Fingerprint && appStore.isApp()) {
			const type = await this.getBiometricsType();
			return !!type;
		} else {
			return false;
		}
	}

	async checkBiometricsEnrollmentStatus() {
		try {
			const isAvailable = await this.checkForBiometricsAvailability();
			const isEnrolled = await storageService.getValue('app', 'biometricsEnabled');
			return isAvailable && isEnrolled;
		} catch (err) {
			console.log('error getting enrollment status from device');
			return false;
		}
	}

	async shouldPromptBiometricsEnrollment() {
		try {
			const isAvailable = await this.checkForBiometricsAvailability();
			const alreadyEnrolled = await this.checkBiometricsEnrollmentStatus();
			const lastDeclined = await storageService.getValue('app', 'biometricsDeclinedDate');
			const canShowAgain = !lastDeclined;
			return isAvailable && canShowAgain && !alreadyEnrolled;
		} catch (err) {
			console.log('error checking for prompt status: ', err);
			return true;
		}
	}

	async turnOffBiometrics(isDeclined) {
		try {
			const currentDate = new Date().getTime();
			await storageService.setValue('app', 'biometricsEnabled', false);
			if (isDeclined) {
				await storageService.setValue('app', 'biometricsDeclinedDate', currentDate);
			}
			await this.setKeyStoreValue('username', null);
			await this.setKeyStoreValue('pwd', null);
		} catch (err) {
			console.log('error handling decline: ', err);
			return Promise.reject(err);
		}
	}

	async handleBiometricsEnrollment(username, password) {
		try {
			const bioResp = await this.deviceBiometricsPrompt();
			await storageService.setValue('app', 'biometricsEnabled', true);
			await storageService.setValue('app', 'biometricsDeclinedDate', false);
			await this.setKeyStoreValue('username', username);
			await this.setKeyStoreValue('pwd', password);
		} catch (err) {
			console.log('error enrolling in biometrics: ', err);
			this.handleBiometricsError(err);
			return Promise.reject(err);
		}
	}

	async clearDeclinedDate() {
		try {
			await storageService.setValue('app', 'biometricsDeclinedDate', false);
		} catch (err) {
			console.log('error clearing storage value');
		}
	}

	async handleLoginRequest() {
		try {
			const bioResp = await this.deviceBiometricsPrompt();
			const username = await this.getKeyStoreValue('username');
			const password = await this.getKeyStoreValue('pwd');
			return { username, password };
		} catch (err) {
			console.log('error handling bio login request: ', err);
			await this.handleBiometricsError(err);
			return err;
		}
	}

	async handleBiometricsError(err) {
		console.log('failed to login via bio: ', err);
		try {
			const errorCode = err.code;
			switch (errorCode) {
				case -101:
					console.log('system setting issue');
					await this.turnOffBiometrics();
					break;
				case -111:
					console.log('too many attempts error');
					break;
				case -108:
					console.log('dismissed bio prompt error');
					break;
				case -106:
					console.log('device is not enrolled in bio auth');
					await this.turnOffBiometrics();
					break;
				case 'CREDENTIALS_INVALID':
					console.log('outdated credentials on device');
					await this.turnOffBiometrics();
					break;
				case 'NOT_SUPPORTED':
					console.log('device cant even do this');
					await this.turnOffBiometrics();
					break;
				default:
					console.log('a new error is here: ', err);
					break;
			}
		} catch (err) {
			console.log('error handling error: ', err);
		}
	}

	deviceBiometricsPrompt() {
		return new Promise((resolve, reject) => {
			window.Fingerprint.show(
				{ clientId: 'CareValet', disableBackup: true },
				(res) => resolve(res),
				(err) => reject(err)
			);
		});
	}

	setKeyStoreValue(key, val) {
		if (!val) {
			return new Promise((resolve, reject) => {
				cordova.plugins.SecureKeyStore.remove(
					(res) => resolve(res),
					(err) => reject(err),
					key
				);
			});
		}
		return new Promise((resolve, reject) => {
			cordova.plugins.SecureKeyStore.set(
				(res) => resolve(res),
				(err) => reject(err),
				key,
				_.clone(val)
			);
		});
	}

	getKeyStoreValue(key) {
		return new Promise((resolve, reject) => {
			cordova.plugins.SecureKeyStore.get(
				(storeValue) => resolve(storeValue),
				(storeError) => reject(storeError),
				key
			);
		});
	}
}

export default new BiometricsService();
