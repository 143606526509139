import React, { Component } from 'react';
import { Preloader, Link, Popover, List, ListItem, Button } from 'framework7-react';
import { getCmpText } from '../../utils/Localization';
import { observer, observable } from '../../utils/State';
import _ from 'lodash';
import { autoBind, getProviderIcon } from '../../utils/GeneralUtils';
import './provider-search-results.scss';
import DropDown from '../../components/drop-down/DropDown';
import appStore from '../../stores/AppStore';
import CloudAPI from '../../services/CloudAPI';
import UserService from '../../services/UserService';
@observer
export default class ProviderSearchResults extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			query: '',
			filtersOpen: false,
			searching: false,
			results: [],
			selectedProvider: null,
			pageCount: 0,
			filters: {
				distance: 10,
				category: 'Physicians',
				specialty: 'Primary Care',
				network: 'All Networks'
			}
		});
		this.mgmtDetails = props.mgmtDetails;
		this.text = getCmpText('ProviderSearchResults', {
			noTextProbably: 'true'
		});
		autoBind(this);
	}

	componentDidMount() {
		console.log('Mounted Provider Search');
	}

	onSearchChange(e) {
		this.data.query = e.currentTarget.value;
	}

	fireSearch(evt) {
		if (evt.key === 'Enter') {
			this.doSearch();
		}
	}

	async doSearch(applyFilters, page) {
		let { query, filters } = this.data;
		this.data.filtersOpen = false;
		this.data.searching = page !== undefined ? false : true;
		if (applyFilters) {
			this.data.filteredSearch = true;
			if (!page) {
				this.data.pageCount = 0;
			}
			const results = await CloudAPI.providerMgmtSearch(_.cloneDeep(filters), page);
			if (page > 0) {
				let existing = _.cloneDeep(this.data.results);
				_.forEach(results, (rec) => {
					existing.push(rec);
				});
				this.data.results = existing;
			} else {
				this.data.results = results;
				this.data.pageCount = 0;
			}
		} else if (query.length === 10) {
			this.data.filteredSearch = false;
			this.data.results = await CloudAPI.getProvider(query);
		} else if (query.length > 2) {
			this.data.filteredSearch = false;
			this.data.results = await CloudAPI.providerMgmtQuickSearch(query);
		}
		if (this.data.results.length === 1 && this.data.results[0].NPI) {
			this.data.selectedProvider = this.data.results[0].NPI;
			this.props.setActiveProvider(this.data.results[0].NPI);
		}
		this.data.searching = false;
	}

	async loadNextPage() {
		this.data.pageCount++;
		const pageEl = document.getElementById('filteredSearchResults');
		const position = pageEl && pageEl.scrollTop;
		this.data.showLoader = true;
		if (this.props.onSearch) {
			await this.props.onSearch(this.props.type, true);
		} else {
			await this.doSearch(true, this.data.pageCount);
		}

		this.data.showLoader = false;
		if (pageEl) {
			pageEl.scrollTop = position;
		}
	}

	buildSearchResults() {
		let { showLoader } = this.data;
		const resultsProvided = this.props.results;
		const hasMoreResults = !showLoader && this.data.results.length > 0 && this.data.results.length % 75 === 0;
		const providedResultsHaveMore = (_.get(this.props, 'results.totalPages') || 1) > (_.get(this.props, 'results.currentPage') || 1);
		const shouldShowLoadMore = resultsProvided ? providedResultsHaveMore : hasMoreResults;
		if (this.data.searching || this.props.loading) {
			return (
				<div className="searching vbox vcenter hcenter">
					<Preloader color="white" size="64"></Preloader>
				</div>
			);
		}

		return (
			<div className={`results-list y-scroll ${resultsProvided ? 'no-search' : ''}`} id="filteredSearchResults">
				{this.buildProvidersCards()}
				<div className="load-more">
					{shouldShowLoadMore && (
						<Button
							onClick={() => {
								this.data.showLoader = true;
								this.loadNextPage();
							}}
						>
							Load More
						</Button>
					)}
					{showLoader && <Preloader color="blue"></Preloader>}
				</div>
			</div>
		);
	}

	getFullName(p) {
		if (!_.isEmpty(p.fullName.trim())) {
			return p.fullName;
		}
		return _.get(p, 'locations[0].fullName', 'Missing Name');
	}

	buildProvidersCards() {
		const resultsToShow = _.get(this.props, 'results.providers') || this.data.results;
		if (resultsToShow.length > 0) {
			let cards = [];
			let NPIS = [];
			_.forEach(resultsToShow, (p) => {
				if (!_.isEmpty(p.fullName.trim()) && NPIS.indexOf(p.NPI) < 0) {
					const specialties = [];
					const unique = [];
					if (p.specialties) {
						_.forEach(p.specialties, (s) => {
							if (unique.indexOf(s) < 0) {
								unique.push(s);
								specialties.push(<span key={s}>{s}</span>);
							}
						});
					} else {
						_.forEach(p.locations, (l) => {
							_.forEach(l.specialties, (s) => {
								if (unique.indexOf(s) < 0) {
									unique.push(s);
									specialties.push(<span key={s}>{s}</span>);
								}
							});
						});
					}
					NPIS.push(p.NPI);
					cards.push(
						<div
							className={`provider-card ${this.data.selectedProvider === p.NPI ? 'selected' : ''}`}
							key={`${p.NPI}-${this.data.pageCount}`}
							onClick={() => {
								this.data.selectedProvider = p.NPI;
								this.props.setActiveProvider(p.NPI);
							}}
						>
							<div className="name">
								{this.getFullName(p)} {p.verified && <i className="fad fa-shield-check" />}
							</div>
							<div className="specialties">{specialties}</div>
							<div className="location">{`${p.locations.length} Locations In Range`}</div>
						</div>
					);
				}
			});
			return cards;
		} else {
			return <div className="no-results">Apply filters or change query</div>;
		}
	}

	buildDistanceDropdown() {
		let { distance } = this.mgmtDetails;
		let listItems = [];
		_.forEach(distance, (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.data.filters.distance = rec;
					}}
				>
					<div className="label">{`${rec} Miles`}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	buildCategoryDropdown() {
		let { category, specialty } = this.mgmtDetails;
		let listItems = [];
		_.forEach(category, (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.data.filters.category = rec;
						if (specialty[rec]) {
							this.data.filters.specialty = specialty[rec][0];
						} else {
							this.data.filters.specialty = null;
						}
					}}
				>
					<div className="label">{rec}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	buildSpecialtyDropdown() {
		let { specialty } = this.mgmtDetails;
		let listItems = [];
		_.forEach(specialty[this.data.filters.category], (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.data.filters.specialty = rec;
					}}
				>
					<div className="label">{rec}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	buildNetworkDropdown() {
		let { networkToId } = this.mgmtDetails;
		let listItems = [];
		_.forEach(Object.keys(networkToId), (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.data.filters.network = rec;
					}}
				>
					<div className="label">{rec}</div>
				</ListItem>
			);
		});
		listItems.unshift(
			<ListItem
				link="#"
				popoverClose
				className="filter-list-item hbox vcenter"
				key="allNetworksOption"
				onClick={() => (this.data.filters.network = 'All Networks')}
			>
				All Networks
			</ListItem>
		);
		return listItems;
	}

	getFiltersMenu() {
		let { distance, category, specialty, network } = this.data.filters;
		return (
			<div className="filters-menu animated fadeInUp">
				<div className="hbox vcenter">
					<h1 className="grow-1">Filters</h1>
					<i
						className="fad fa-times-square"
						onClick={() => {
							this.data.filtersOpen = false;
						}}
					></i>
				</div>
				<div className="filters-list">
					<DropDown name="distance-dd" label="Distance" items={this.buildDistanceDropdown()} displayValue={`${distance} miles`} />
					<DropDown name="category-dd" label="Category" items={this.buildCategoryDropdown()} displayValue={category} />
					{specialty && (
						<DropDown name="specialty-dd" label="Specialty" items={this.buildSpecialtyDropdown()} displayValue={specialty} />
					)}
					<DropDown name="network-dd" label="Network" items={this.buildNetworkDropdown()} displayValue={network} />
					<Button
						fill
						className="search-btn"
						onClick={() => {
							this.doSearch(true);
						}}
					>
						Apply Filters
					</Button>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="provider-search-results">
				<div className="top-menu hbox vcenter">
					<div
						className="current-location hbox vcenter hcenter"
						onClick={() => {
							appStore.setLocationOpen = true;
						}}
					>
						<i className="fad fa-map-marked-alt" />
						<div className="location ellipse">{_.get(appStore, 'currentLocation.name', 'Location')}</div>
					</div>
					{!this.props.hideFilter && (
						<div
							className="filter-btn hbox vcenter hright"
							onClick={() => {
								this.data.filtersOpen = true;
							}}
						>
							<i className="fal fa-filter" />
							<div>Filters</div>
						</div>
					)}
				</div>
				<div className="results-title">{this.props.title || 'Search Results'}</div>
				{!this.props.hideSearch && (
					<div className={`nav-search hbox vcenter`}>
						<i className="fal fa-search" />
						<input
							placeholder="Search NPI or Name (75mi Radius)"
							type="text"
							value={this.data.query}
							onChange={this.onSearchChange}
							onKeyUp={this.fireSearch}
						></input>
					</div>
				)}
				{this.buildSearchResults()}
				{this.data.filtersOpen && this.getFiltersMenu()}
			</div>
		);
	}
}
