import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { getCmpText } from '@/utils/Localization';
import { Preloader, Button } from 'framework7-react';
import { format } from 'date-fns';

@observer
export default class TokenActivityList extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('TokenActivityList', {
			cancelBtnTxt: 'Close',
			tokenActivityHeader: 'Token Activity',
			tokenSectionHeader: 'Latest activity',
			tokenTxt: 'Token',
			purchasedTxt: 'Purchased',
			awardedTxt: 'Rewarded',
			usedTxt: 'Used',
			issuedTxt: 'Issued',
			reducedTxt: 'Deducted',
			errorTxt: 'error',
			refundedTxt: 'Refunded',
			storeTxt: 'Store'
		});
		autoBind(this);
	}

	formatDescription(token) {
		let str = '';
		switch (token.tokenReason) {
			case 'purchased':
				str += `${this.text.purchasedTxt} ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}!`;
				break;
			case 'awarded':
				str += `${this.text.awardedTxt} ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}!`;
				break;
			case 'used':
				str += `${this.text.usedTxt} ${token.quantity} ${this.text.tokenTxt}${
					token.quantity > 1 ? 's' : ''
				} on ${token.notes.toLowerCase()}`;
				break;
			case 'error':
				str += token.tokenType == 'credit' ? this.text.issuedTxt : this.text.reducedTxt;
				str += ` ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''} due to ${this.text.errorTxt}`;
				break;
			case 'refunded':
				str += `${this.text.refundedTxt}  ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}`;
				break;
			default:
				str += token.tokenType == 'credit' ? this.text.issuedTxt : this.text.reducedTxt;
				str += ` ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}`;
		}
		return str;
	}

	formatDeductTxt(token) {
		let str = '';
		if (token.tokenType === 'credit') {
			str += `${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}`;
		} else {
			str += `(${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''})`;
		}
		return str;
	}

	renderTokenActivity() {
		let { tokenActivity, onViewDetail } = this.props;
		let tokens = [];
		tokenActivity.forEach((token, key) => {
			tokens.push(
				<Button className="neubtn balance-btn hbox hleft token" key={key} onClick={() => onViewDetail(token)}>
					<div className="token-holder">
						<div className="details-container">
							<div className="description">{this.formatDescription(token)}</div>
							<div className="time">
								{format(new Date(token.timestamp), 'MMMM dd, yyyy')} {format(new Date(token.timestamp), 'pp')}
							</div>
						</div>
						<div className={`${token.tokenType} amount`}>{this.formatDeductTxt(token)}</div>
					</div>
				</Button>
			);
		});

		return tokens;
	}

	render() {
		let { cancelBtnTxt, tokenActivityHeader, tokenSectionHeader, storeTxt } = this.text;
		let { onClose, loading, onShopOpen } = this.props;
		return (
			<div className="page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="navigation hbox vcenter">
							<div className="btn" onClick={onClose}>
								{cancelBtnTxt}
							</div>
							<div className="btn" onClick={onShopOpen}>
								{storeTxt}
							</div>
						</div>

						<h1>{tokenActivityHeader}</h1>
					</div>
				</div>
				<div className="section-header">
					<div className="title">{tokenSectionHeader}</div>
				</div>
				<div className="token-activity-container">
					{loading && <Preloader></Preloader>}
					{!loading && this.renderTokenActivity()}
				</div>
			</div>
		);
	}
}
