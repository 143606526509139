import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import nocards from '@/assets/nocards.svg';
import './id-cards-empty.scss';
import { Button } from 'framework7-react';
@observer
export default class IdCardsEmpty extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		return (
			<div className="cards-empty vbox hcenter vcenter">
				<img src={nocards} />
				<div className="message-container vbox hcenter">
					<h1>No ID Cards!</h1>
					<p>It looks like you haven’t created any ID Cards yet</p>
					<p>Click below to get started</p>
					<Button fill className="list-btn ios" onClick={this.props.onNew}>
						Let's get started!
					</Button>
				</div>
			</div>
		);
	}
}
