import { Preloader } from 'framework7-react';
import React, { Component } from 'react';
import appStore from '@/stores/AppStore';
import { observer, observable } from '@/utils/State';
import {
	autoBind,
	formatPhoneNumber,
	showExpressError,
	formatDOB
} from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import ExpressAPI from '@/services/ExpressAPI';
import TabSlider from '@/components/_EXPRESS/tab-slider/TabSlider';
import underConstruction from '@/assets/construction.png';
import _ from 'lodash';
import './member-details.scss';
import MemberIdCards from './member-id-cards/MemberIdCards';

@observer
export default class MemberDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			group: {},
			activeTab: 0,
			dependents: null,
			parent: null
		});
		autoBind(this);
	}

	changeMember(parent, changeTo) {
		this.data = observable({
			group: {},
			activeTab: 0,
			dependents: null,
			parent: _.cloneDeep(parent)
		});
		expressStore.activeMember = _.cloneDeep(changeTo);
		this.loadGroup();
	}

	componentDidMount() {
		this.loadGroup();
	}

	loadGroup() {
		ExpressAPI.getGroupById(expressStore.activeMember.internalGroupId)
			.then((res) => {
				this.data.group = res;
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	loadDependents() {
		this.data.dependents = null;
		ExpressAPI.getDependents(expressStore.activeMember)
			.then((res) => {
				this.data.dependents = res;
			})
			.catch((err) => {
				this.data.dependents = [];
				showExpressError(this.$f7, err);
			});
	}

	buildConstruction() {
		return (
			<div className="construction-ctn">
				<h2>Coming Soon!</h2>
				<img src={underConstruction} />
			</div>
		);
	}

	onEditMemberClick() {
		this.props.onEdit(expressStore.activeMember);
	}

	buildDetailsCard() {
		let { activeMember } = expressStore;
		const basicConfig = {
			relation: { label: 'Type' },
			status: { label: 'Status' },
			firstName: { label: 'First Name' },
			middleName: { label: 'Middle Name' },
			lastName: { label: 'Last Name' },
			memberId: { label: 'Member ID' },
			dob: { label: 'Date of Birth' },
			personCode: { label: 'Person Code' },
			groupId: { label: 'Group ID' },
			healthPlan: { label: 'Medical Plan ID' },
			healthPlanDental: { label: 'Dental Plan ID' },
			healthVision: { label: 'Vision Plan ID' },
			coverageLevel: { label: 'Medical Coverage Level' },
			coverageLevelDental: { label: 'Dental Coverage Level' },
			coverageLevelVision: { label: 'Vision Coverage Level' },
			gender: { label: 'Gender' },
			email: { label: 'Email' },
			phone: { label: 'Mobile Phone' },
			startDate: { label: 'Start Date' },
			termDate: { label: 'Term Date' },
			lastUpdated: { label: 'Last Updated' },
			address: { label: 'Address' },
			lastPublished: { label: 'Last Publish Date' }
		};
		const formatValue = (key, value) => {
			if (
				[
					'termDate',
					'startDate',
					'lastUpdated',
					'lastPublished',
					'dob'
				].indexOf(key) >= 0 &&
				!!value
			) {
				return formatDOB(value, 'yyyy-MM-dd', 'MM/dd/yyyy');
			}
			if (
				[
					'firstName',
					'lastName',
					'middleName',
					'lastUpdatedBy',
					'type',
					'status'
				].indexOf(key) >= 0 &&
				!!value
			) {
				return _.capitalize(value);
			}
			if (key === 'phone') {
				return formatPhoneNumber(value);
			}
			if (key === 'lastPublished' && !value) {
				return 'Not Published';
			}
			if (key === 'relation') {
				return {
					self: 'Primary',
					spouse: 'Spouse',
					child: 'Child',
					other: 'Other'
				}[value];
			}
			if (key === 'gender') {
				return {
					m: 'Male',
					f: 'Female'
				}[value];
			}
			if (key === 'address') {
				return `${_.get(value, 'street', '')} ${_.get(
					value,
					'street2',
					''
				)} ${_.get(value, 'city', '')}, ${_.toUpper(
					_.get(value, 'state', '')
				)} ${_.get(value, 'zipcode', '')}`;
			}
			return value;
		};

		const buildFields = (cardConfig) => {
			const fields = Object.keys(cardConfig)
				.map((fieldName) => {
					let value = _.get(activeMember, fieldName);
					let config = cardConfig[fieldName];
					if (_.isEmpty(value)) {
						return fieldName === 'lastPublished' ? (
							<div
								key={`${activeMember._id}-${fieldName}`}
								className="card-field"
							>
								<div className="label">{config.label}:</div>
								<div className="value">{formatValue(fieldName, value)}</div>
							</div>
						) : null;
					}
					return (
						<div
							key={`${activeMember._id}-${fieldName}`}
							className="card-field"
						>
							<div className="label">{config.label}:</div>
							<div className="value">{formatValue(fieldName, value)}</div>
						</div>
					);
				})
				.filter((f) => f !== null);
			if (fields.length === 0) {
				return (
					<div className="card-field">
						<div className="label">No Data</div>
					</div>
				);
			}
			return fields;
		};
		let canEdit = this.data.group.memberDataType === 'manual';
		return (
			<div className="member-details-card animated fadeInUp">
				<div className="card-header">
					<div className="title grow-1">Member Details</div>
					{canEdit && (
						<i className="fad fa-pen" onClick={this.onEditMemberClick}></i>
					)}
				</div>
				<div className="card-fields">{buildFields(basicConfig)}</div>
			</div>
		);
	}

	getBreadCrumb() {
		let currentKey = 0;
		let { parent } = this.data;
		if (expressStore.activeMember) {
			currentKey++;
		}
		if (parent) {
			currentKey++;
		}
		const config = {
			0: {
				name: 'All Members',
				icon: 'fad fa-users',
				onClick: () => {
					expressStore.activeMember = null;
					if (this.props.loadPlans) {
						this.props.loadPlans();
					}
				}
			}
		};
		if (parent) {
			config[1] = {
				name: _.capitalize(`${parent?.firstName} ${parent?.lastName}`),
				onClick: () => {
					this.changeMember(null, parent);
				}
			};
			config[2] = {
				name: _.capitalize(
					`${expressStore.activeMember?.firstName} ${expressStore.activeMember?.lastName}`
				),
				onClick: () => {}
			};
		} else {
			config[1] = {
				name: _.capitalize(
					`${expressStore.activeMember?.firstName} ${expressStore.activeMember?.lastName}`
				),
				onClick: () => {}
			};
		}

		return (
			<Breadcrumb pageName="plans" config={config} currentKey={currentKey} />
		);
	}

	setActiveTab(tab) {
		switch (tab) {
			case 1: {
				this.loadDependents();
				break;
			}
			default: {
				break;
			}
		}
		this.data.activeTab = tab;
	}

	buildTabSlider() {
		const config = {
			0: {
				name: 'ID Cards'
			}
		};
		if (expressStore.activeMember?.relation === 'self') {
			config[1] = {
				name: 'Dependents'
			};
			// config[2] = {
			// 	name: 'ID Cards'
			// };
		} else {
			// config[1] = {
			// 	name: 'ID Cards'
			// };
		}
		return (
			<TabSlider
				activeTab={this.data.activeTab}
				setActiveTab={this.setActiveTab}
				config={config}
			/>
		);
	}

	buildDependentsList() {
		return (
			<div className="dependent-list">
				{!this.data.dependents && (
					<div className="loading-ctn vbox vcenter hcenter">
						<Preloader></Preloader>
					</div>
				)}
				{this.data.dependents &&
					this.data.dependents.length > 0 &&
					this.data.dependents.map((m) => {
						return (
							<div
								className="dependent"
								key={m._id}
								onClick={() => {
									this.changeMember(expressStore.activeMember, m);
								}}
							>
								<div className="header hbox vcenter">
									<div className="name grow-1">{`${m.firstName} ${m.lastName}`}</div>
									<div className="type">
										{
											{
												self: 'Primary',
												spouse: 'Spouse',
												child: 'Child',
												other: 'Other'
											}[m.relation]
										}
									</div>
								</div>
								<div className="row hbox vcenter">
									<div className="field">
										<div className="label">Member ID</div>
										<div className="value">{m.memberId}</div>
									</div>
									<div className="field">
										<div className="label">Group ID</div>
										<div className="value">{m.groupId}</div>
									</div>
									<div className="field">
										<div className="label">Plan ID</div>
										<div className="value">{m.groupId}</div>
									</div>
									<div className="field">
										<div className="label">Start Date - Term Date</div>
										<div className="value">
											{`${formatDOB(
												m.startDate,
												'yyyy-MM-dd',
												'MM/dd/yyyy'
											)} - ${formatDOB(
												m.termDate,
												'yyyy-MM-dd',
												'MM/dd/yyyy'
											)}`}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				{this.data.dependents && this.data.dependents.length === 0 && (
					<div className="empty-ctn vbox vcenter hcenter">
						<h2>This member has no dependents</h2>
					</div>
				)}
			</div>
		);
	}

	getTabContent() {
		const internalPlanIds = expressStore.activeMember.internalPlanId || [];
		if (expressStore.activeMember?.relation === 'self') {
			switch (this.data.activeTab) {
				case 0: {
					return <MemberIdCards internalPlanIds={internalPlanIds} />;
				}
				case 1: {
					return this.buildDependentsList();
				}
			}
		} else {
			switch (this.data.activeTab) {
				case 0: {
					return <MemberIdCards internalPlanIds={internalPlanIds} />;
				}
				case 1: {
					return this.buildConstruction();
				}
			}
		}
	}

	render() {
		return (
			<div
				className={`member-details-page ${
					appStore.isMobile ? 'mobile' : 'desktop'
				} ${expressStore.activePage === 'members' ? 'standalone' : 'subpage'}`}
			>
				{expressStore.activePage === 'members' ? (
					this.getBreadCrumb()
				) : (
					<div className="page-header">
						<div
							className="back hbox vcenter"
							onClick={() => {
								expressStore.activeMember = null;
								if (this.props.loadMembers) {
									this.props.loadMembers();
								}
							}}
						>
							<i className="fas fa-chevron-left"></i>
							Back To List
						</div>
					</div>
				)}
				{this.buildDetailsCard()}
				{this.buildTabSlider()}
				{this.getTabContent()}
			</div>
		);
	}
}
