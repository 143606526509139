import appStore from '../stores/AppStore';
import ConfigService from './ConfigService';
import _ from 'lodash';
import userStore from '../stores/UserStore';
import AnalyticsService from './AnalyticsService';
import UserService from './UserService';
import { f7 } from 'framework7-react';
import CloudAPI from './CloudAPI';

const analytics = new AnalyticsService();

class ExternalService {
	getTelehealthNumber() {
		return _.get(userStore, 'userData.group.telehealth.phone');
	}

	getEmergencyNumber() {
		return ConfigService.getConfigValue('telehealth.emergency', '911');
	}

	getCrisisNumber() {
		return ConfigService.getConfigValue('telehealth.crisis', '211');
	}

	getTelehealthUrl() {
		return _.get(userStore, 'userData.group.telehealth.website');
	}

	getRxUrl() {
		return 'https://goodrx.com';
	}

	async handleExternalRequest(type, data) {
		switch (type) {
			case 'call': {
				const cleanPhoneData = data === 'telehealth' ? null : data;
				const phone = cleanPhoneData || this.getTelehealthNumber();
				this.handleCall(phone);
				break;
			}
			case 'web': {
				analytics.trackWithData('opened_external_site', { url: data });
				const cleanUrlData = data === 'telehealth' ? null : data;
				const webUrl = cleanUrlData || this.getTelehealthUrl();
				this.handleUrl(webUrl);
				break;
			}
			case 'video': {
				this.handleVideo(data);
				break;
			}
			case 'email': {
				this.handleUrl(`mailto:${data}`);
				break;
			}
			case 'emergency': {
				analytics.track('called_emergency');
				const emergencyNumber = this.getEmergencyNumber();
				this.handleCall(emergencyNumber);
				break;
			}
			case 'crisis': {
				analytics.track('called_crisis');
				const crisisNumber = this.getCrisisNumber();
				this.handleCall(crisisNumber);
				break;
			}
			case 'message': {
				appStore.showExternalServiceMessage = true;
				appStore.externalServiceMessage = {
					icon: data.icon,
					message: data.data,
					title: data.name
				};
				break;
			}
			default: {
				console.log('unknown request type: ', type);
				break;
			}
		}
	}

	getFormattedPhoneNumber(phone) {
		const cleanPhone = phone.replace(/\D/g, '');
		const formattedPhone = cleanPhone.replace(
			/(\d{3})(\d{3})(\d{4})/,
			'($1) $2-$3'
		);
		return formattedPhone;
	}

	handleVideo(vimeoId) {
		appStore.openVideoId = vimeoId;
	}

	handleCall(phone) {
		const phoneUrl = `tel:${phone}`;
		if (!appStore.isMobile || appStore.demoMode) {
			const formattedNumber = this.getFormattedPhoneNumber(phone);
			let message =
				appStore.demoMode && !formattedNumber
					? 'Not Configured'
					: formattedNumber;
			f7.dialog.alert(message, 'Phone');
			return;
		}
		if (appStore.isApp()) {
			cordova.InAppBrowser.open(phoneUrl, '_system', 'usewkwebview=yes');
		} else {
			window.location.href = phoneUrl;
		}
	}

	// *NOTE: PWA pop up as well as inAPPBrowser for application
	handleUrl(url) {
		if (appStore.isApp()) {
			cordova.InAppBrowser.open(url, '_system', 'userwkwebview=yes');
		} else {
			window.open(url, '_blank');
		}
	}

	consumerTelehealthClick() {
		if (!UserService.isConsumerEnrolled()) {
			appStore.openEnrollment();
		} else if (!UserService.isConsumerActivated()) {
			this.checkTelehealthActivationStatus();
		} else if (UserService.isConsumerActivated()) {
			if (_.get(userStore, 'userData.user.tokenCount', 0) > 0) {
				if (!appStore.confirmedTelehealth) {
					analytics.trackWithData('show_telehealth_prompt', { type: 'call' });
					appStore.showTelehealthModal = true;
					appStore.telehealthType = 'call';
				}
			} else {
				appStore.openShop();
				return;
			}
		}
	}

	checkTelehealthActivationStatus() {
		f7.dialog.preloader('Checking Status....');
		CloudAPI.getMyUser()
			.then((res) => {
				if (res.activated) {
					_.set(userStore, 'userData.user', res);
					f7.dialog.close();
					f7.dialog.alert('Activation Complete!');
					this.consumerTelehealthClick();
				} else {
					f7.dialog.close();
					f7.dialog.alert('Activation still in progress.');
				}
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
			});
	}

	onTelehealthClick(service) {
		const isUrgentService = service === 'emergency' || service === 'crisis';
		if (UserService.isConsumer() && !isUrgentService) {
			this.consumerTelehealthClick();
			return;
		}
		switch (service) {
			case 'call': {
				if (appStore.confirmedTelehealth) {
					appStore.confirmedTelehealth = false;
					appStore.telehealthType = null;
					analytics.track('called_telehealth');
					this.handleExternalRequest(service);
				} else {
					analytics.trackWithData('show_telehealth_prompt', { type: service });
					appStore.showTelehealthModal = true;
					appStore.telehealthType = 'call';
				}
				break;
			}
			case 'web': {
				if (appStore.confirmedTelehealth) {
					appStore.confirmedTelehealth = false;
					appStore.telehealthType = null;
					analytics.track('opened_telehealth_site');
					this.handleExternalRequest(service);
				} else {
					analytics.trackWithData('show_telehealth_prompt', { type: service });
					appStore.showTelehealthModal = true;
					appStore.telehealthType = 'web';
				}
				break;
			}
			default:
				this.handleExternalRequest(service);
		}
	}

	telehealthInit() {
		if (UserService.isConsumer()) {
			if (_.get(userStore, 'userData.user.tokenCount', 0) > 0) {
				this.consumerTelehealthClick();
			} else {
				appStore.openShop();
			}
		} else if (UserService.isConsumer() && !UserService.isConsumerEnrolled()) {
			appStore.openEnrollment();
		} else {
			appStore.telehealthOpen = true;
		}
	}
}

export default new ExternalService();
