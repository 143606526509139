import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import './fulfillment-wizard.scss';
import _ from 'lodash';
import { Button, Preloader } from 'framework7-react';
import BasicInput from '@/components/basic-input/BasicInput';
import { FulfillmentStatus } from './FulfillmentList';
import ExpressAPI from '@/services/ExpressAPI';
import IdDetail from '../idcards/id-card-details/id-detail/IdCardDetail';
@observer
export default class FulfillmentWizard extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			formValid: true,
			searching: false,
			loading: true,
			approvedInput: '',
			searchInput: '',
			lastInputValue: '',
			currentStep: 1,
			members: [],
			selected: [],
			cardsToPrint: []
		});
		autoBind(this);
	}

	componentDidMount() {
		ExpressAPI.getGroupMembers(_.get(this, 'props.groupData.group._id', null), {
			firstName: 1,
			lastName: 1,
			memberId: 1,
			internalPlanId: 1,
			address: 1,
			relation: 1
		})
			.then((res) => {
				this.data.members = res;
				this.data.loading = false;
			})
			.catch((e) => {
				this.data.loading = false;
				showExpressError(this.$f7, e);
			});
	}

	getIDCardsForPlans() {
		this.data.loading = true;
		let plans = [];
		_.forEach(this.data.members, (m) => {
			m.internalPlanId.forEach((pId) => {
				if (this.data.selected.indexOf(m._id) >= 0 && plans.indexOf(pId) < 0) {
					plans.push(pId);
				}
			});
		});
		ExpressAPI.getIDCardsForPlans(plans)
			.then((res) => {
				this.data.cardsToPrint = res;
				this.data.loading = false;
			})
			.catch((err) => {
				this.data.loading = false;
				showExpressError(this.$f7, err);
			});
	}

	validateAddress(address) {
		let isValid = true;
		_.forEach(Object.keys(address), (key) => {
			if (key !== 'street2' && _.isEmpty(key)) {
				isValid = false;
			}
		});
		return isValid;
	}

	onBack() {
		if (this.data.currentStep == 1) {
			//Cleanring due to mem issues
			this.data.selected = [];
			this.data.members = [];
			this.data.cardsToPrint = [];
			this.props.onBack();
		}
		if (this.data.currentStep == 2) {
			this.data.currentStep = 1;
		}
	}

	onSubmit() {
		this.$f7.dialog.preloader('Submitting Order...');
		//* Can we change this and create new fulfillments + groups.
		// ! CAN I just copy existing fulfillment and add additional orders.
		ExpressAPI.submitIDCardOrder({
			fulfillmentId: _.get(this, 'props.groupData.fulfillment._id', null),
			idCardTemplates: this.data.cardsToPrint,
			membersToFulfill: this.data.selected
		})
			.then(() => {
				this.data.selected = [];
				this.data.members = [];
				this.data.cardsToPrint = [];
				this.$f7.dialog.close();
				this.props.onSubmit();
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	onApprovalInputChange(e) {
		this.data.approvedInput = _.toUpper(e.currentTarget.value);
	}

	searchChange(e) {
		this.data.searching = true;
		if (this.fireOffSearch) {
			clearTimeout(this.fireOffSearch);
		}
		this.data.lastInputValue = e.currentTarget.value;
		this.fireOffSearch = setTimeout(() => {
			this.data.searchInput = _.cloneDeep(
				this.data.lastInputValue
			).toLowerCase();
			this.data.lastInputValue = '';
			this.data.searching = false;
		}, 200);
	}

	getEligibleMembers() {
		return this.data.members.filter((m) => {
			if (this.validateAddress(m.address)) {
				return true;
			}
			return false;
		});
	}

	filterMembersBySearchInput() {
		let { members, searchInput } = this.data;
		return (
			searchInput?.length > 0
				? members.filter((m) => {
						let { firstName, lastName, memberId } = m;
						if (
							(firstName.toLowerCase().indexOf(searchInput) >= 0 ||
								lastName.toLowerCase().indexOf(searchInput) >= 0 ||
								memberId.toLowerCase().indexOf(searchInput) >= 0) &&
							this.validateAddress(m.address)
						) {
							return true;
						}
						return false;
				  })
				: members
		).sort((a, b) => {
			if (a.lastName < b.lastName) {
				return -1;
			}
			if (a.lastName > b.lastName) {
				return 1;
			}
			return 0;
		});
	}

	onChecked(m) {
		if (this.data.selected.indexOf(m.id) >= 0) {
			_.pull(this.data.selected, m.id);
		} else {
			this.data.selected.push(m.id);
		}
	}

	buildAwaitingFulfillmentView() {
		let { groupData } = this.props;
		const batchErrors = _.get(groupData, 'fulfillment.processingErrors').map(
			(x) => x.internalMemberId.toString()
		);
		const membersWithErrorsData = this.data.members.filter((member) => {
			const memberId = member._id.toString();
			const isError = batchErrors.indexOf(memberId) > -1;
			return isError;
		});
		const membersToFulfillRaw =
			_.get(groupData, 'fulfillment.membersToFulfill') || [];
		const membersToFulfillData = this.data.members.filter((member) => {
			const memberId = member._id.toString();
			const isInBatch = membersToFulfillRaw.indexOf(memberId) > -1;
			const isError = batchErrors.indexOf(memberId) > -1;
			return isInBatch && !isError;
		});
		const membersToFulfill = membersToFulfillData.map((x) => x._id.toString()); //remove any with errors from count
		const totalCount = membersToFulfill.length + batchErrors.length;
		return (
			<div className="confirmation-details">
				<h2 className="stats">
					{membersToFulfill.length} of {totalCount} member(s) submitted for this
					order are processing:
				</h2>
				{this.getMemberChecklist(membersToFulfillData, true)}
				{batchErrors && batchErrors.length > 0 && (
					<div>
						<h2>
							{batchErrors.length} of {totalCount} member(s) are unable to be
							processed:
						</h2>
						<p>
							A new order has been generated for these records and may be
							resubmitted
						</p>
						{this.getMemberChecklist(membersWithErrorsData, true)}
					</div>
				)}
				<h2 className="stats">
					The following ID card templates are being used for this order:
				</h2>
				{_.get(groupData, 'fulfillment.idCardTemplates', []).map((cardData) => {
					return (
						<div
							className="card-body cards hbox hcenter vcenter"
							key={`plan-cards-${cardData.internalPlanId}}`}
						>
							<div className="front-container">
								<IdDetail
									side="front"
									thumbnailKey={cardData.front?.thumbnails?.thumbnailLarge}
									id={cardData.front._id}
									loading={false}
								/>
							</div>
							<div className="back-container">
								<IdDetail
									side="back"
									thumbnailKey={cardData.back?.thumbnails?.thumbnailLarge}
									id={cardData.back._id}
									loading={false}
								/>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	buildAwaitingApprovalView() {
		let { members, selected } = this.data;
		const eligibleMembers = this.filterMembersBySearchInput();
		const eligibleCount = this.getEligibleMembers().length;
		const allEligible = eligibleCount === members.length;
		return (
			<>
				<h2 className="member-count hbox vcenter">
					<div className="grow-1">
						Eligible Members:{' '}
						{`(${
							allEligible ? eligibleCount : `${eligibleCount}/${members.length}`
						})`}
						{!allEligible && (
							<i
								className="fad fa-info-circle"
								onClick={() => {
									this.$f7.dialog.alert(
										'Only members with a valid address are eligible for printing and fulfillment of ID cards. If members are missing from this list, ensure that they have a valid address and either update them inside of express or via your eligibility process.',
										'Note:'
									);
								}}
							></i>
						)}
					</div>
					<Button
						large
						fill
						className="round-btn"
						disabled={this.data.selected.length === 0}
						onClick={() => {
							this.data.currentStep = 2;
							this.getIDCardsForPlans();
						}}
					>
						Next
					</Button>
				</h2>
				<div>
					Select the members that you would like to order ID cards for and then
					click NEXT to approve this order.
				</div>
				<div className="members-list-header hbox vcenter">
					<div className="input-ctn hbox vcenter">
						<i className="fas fa-search"></i>
						<input
							placeholder="First, Last, or Member ID..."
							onChange={this.searchChange}
						/>
					</div>
					<h4>Selected Members: {selected.length}</h4>
					<div className="grow-1"></div>
					<Button
						small
						className="list-btn secondary"
						onClick={() => {
							this.data.selected = [];
						}}
					>
						Unselect All
					</Button>
					<Button
						small
						className="list-btn secondary ml24"
						onClick={() => {
							this.data.selected = this.getEligibleMembers().map((m) => m.id);
						}}
					>
						Select All
					</Button>
				</div>
				{members.length === 0 && this.data.loading && (
					<div className="hbox vcenter hcenter">
						<Preloader color="bue" size="32px"></Preloader>
					</div>
				)}
				{members.length === 0 && !this.data.loading && (
					<div className="empty-message">
						This group currently has no eligibile members.
					</div>
				)}
				{this.getMemberChecklist(eligibleMembers, false)}
			</>
		);
	}

	getMemberChecklist(members, readOnly) {
		return (
			<div className="members-list">
				{members.map((m) => {
					const checked = readOnly || this.data.selected.indexOf(m.id) > -1;
					return (
						<div
							className="member-item hbox vcenter"
							key={m.id}
							onClick={() => {
								if (!readOnly) this.onChecked(m);
							}}
						>
							{!readOnly && (
								<input
									className="m-r-20"
									readOnly
									type="checkbox"
									data-member={m.id}
									checked={checked}
								/>
							)}
							<div className="m-r-20 ellipse">{m.firstName}</div>
							<div className="m-r-20 ellipse">{m.lastName}</div>
							<div className="m-r-20">{`${m.memberId}${
								!_.isEmpty(m.personCode) ? `.${m.personCode}` : ''
							}`}</div>
						</div>
					);
				})}
			</div>
		);
	}

	buildConfirmationScreen() {
		let { groupData } = this.props;
		return (
			<div className="confirmation-details">
				<h2 className="stats">
					You have selected ({this.data.selected.length}) members for this
					order:
				</h2>
				{groupData.fulfillment.status ===
					FulfillmentStatus.AWAITING_APPROVAL && (
					<div className="btn-ctn hbox vcenter">
						<BasicInput
							key={`approval-input-key`}
							label={`Please type 'APPROVED' to submit. Once approved will be unable to cancel this order:`}
							name={'approvalText'}
							type={'text'}
							placeholder={'Type here to approve this order'}
							value={this.data.approvedInput}
							onChange={this.onApprovalInputChange}
							className="approval-input"
						/>
						<Button
							fill
							large
							className="round-btn red"
							disabled={this.data.approvedInput !== 'APPROVED'}
							onClick={this.onSubmit}
						>
							Submit Order
						</Button>
					</div>
				)}
				<h2 className="stats">
					The following ID card templates will be used for this order:
				</h2>
				{this.data.cardsToPrint.map((cardData) => {
					return (
						<div
							className="card-body cards hbox hcenter vcenter"
							key={`plan-cards-${cardData.internalPlanId}}`}
						>
							<div className="front-container">
								<IdDetail
									side="front"
									thumbnailKey={cardData.front?.thumbnails?.thumbnailLarge}
									id={cardData.front._id}
									loading={false}
								/>
							</div>
							<div className="back-container">
								<IdDetail
									side="back"
									thumbnailKey={cardData.back?.thumbnails?.thumbnailLarge}
									id={cardData.back._id}
									loading={false}
								/>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	getCurrentStepContent() {
		let { groupData } = this.props;
		//* Other than step 1, all steps are 1 to 1 with their screen, however step 1 also has a read only mode for after the order has been submitted
		switch (this.data.currentStep) {
			case 1: {
				return groupData.fulfillment.status ===
					FulfillmentStatus.AWAITING_APPROVAL
					? this.buildAwaitingApprovalView()
					: this.buildAwaitingFulfillmentView();
			}
			case 2: {
				return this.data.loading ? (
					<div className="hbox vcenter hcenter">
						<Preloader color="bue" size="32px"></Preloader>
					</div>
				) : (
					this.buildConfirmationScreen()
				);
			}
			default: {
				break;
			}
		}
	}

	render() {
		let { groupData } = this.props;
		return (
			<div className={`fulfillment-wizard`}>
				<div className="popup-header hbox vcenter">
					<Button
						fill
						large
						className="list-btn mr24 hbox vcenter"
						onClick={this.onBack}
					>
						<i className="far fa-long-arrow-alt-left"></i>
						Back
					</Button>
					<h2 className="title grow-1 ellipse">
						{_.get(groupData, 'fulfillment.status') ===
						FulfillmentStatus.AWAITING_APPROVAL
							? 'Order Settings For - '
							: 'Pending Order For - '}
						{_.startCase(_.toLower(groupData?.group?.name))}
					</h2>
				</div>
				{this.getCurrentStepContent()}
			</div>
		);
	}
}
