// @ts-nocheck
import _ from 'lodash';
import userStore from '../stores/UserStore';
import appStore from '../stores/AppStore';

export default class AnalyticsService {
	initUser() {
		try {
			const PROJECT = process.env.NODE_ENV === 'development' ? 'b2f5e88f9b02b8fa5f6527ede69b41f8' : '62b230bfaa8a94505fd0ab605332fa72';
			const isConsumer = _.get(userStore, 'userData.user.consumer', false);
			const isEmployee = _.get(userStore, 'userData.user.contactInfo.email', '').indexOf('carevalet') >= 0;
			console.log(`Setting AMPLITUDE to project ${PROJECT}`);
			amplitude.getInstance().init(PROJECT);

			//ensure even unknown users have ids to bug check against
			let id = _.get(userStore, 'userData.user._id', null);
			if (!id) {
				id = localStorage.getItem('app/loginSessionId');
				if (!id) {
					id = `unknown-user-${Math.floor(Math.random() * 100000000)}`;
					localStorage.setItem('app/loginSessionId', id);
				}
			}
			amplitude.getInstance().setUserId(id);
			this.setIdentity({ employee: isEmployee, consumer: isConsumer });
		} catch (err) {
			console.log(err);
		}
	}

	setIdentity(data) {
		let identify = new amplitude.Identify();
		_.forEach(Object.keys(data), (key) => {
			identify.set(key, _.get(data, key, 'missing-value'));
		});
		amplitude.getInstance().identify(identify);
	}

	track(event) {
		const eventData = {
			version: `${userStore.frontendVersion}-${userStore.backendVersion}`,
			pwa: `${appStore.isPWA()}`,
			app: `${appStore.isApp()}`,
			page: appStore.activeTab
		};
		try {
			amplitude.getInstance().logEvent(event, eventData);
		} catch (err) {
			console.log(err);
		}
	}

	trackWithData(event, data) {
		const dataWithVersion = {
			...data,
			version: `${userStore.frontendVersion}-${userStore.backendVersion}`,
			pwa: `${appStore.isPWA()}`,
			app: `${appStore.isApp()}`,
			page: appStore.activeTab
		};
		try {
			amplitude.getInstance().logEvent(event, dataWithVersion);
		} catch (err) {
			console.log(err);
		}
	}
}
