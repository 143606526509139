import CanvasEditorService from '@/services/CanvasEditorService';
import expressStore from '@/stores/ExpressStore';
import { v4 as uuidv4 } from 'uuid';

const CanvasFactory = {
	serviceMap: {},

	//TODO - We need to create different types of CanvasEditorServices for different types of documents
	getService: function (id) {
		if (CanvasFactory.serviceMap[id]) {
			return CanvasFactory.serviceMap[id];
		} else {
			return CanvasFactory.createService(id);
		}
	},
	getStore: function (id) {
		return CanvasFactory.serviceMap[id].getStore();
	},
	removeService: function (id) {
		if (this.serviceMap[id]) {
			delete CanvasFactory.serviceMap[id];
		}
	},
	createService: function (id) {
		id = id ? id : `canvas-${uuidv4()}`;
		CanvasFactory.serviceMap[id] = new CanvasEditorService();
		return CanvasFactory.serviceMap[id];
	},
	list() {},

	getActiveService: function () {
		return CanvasFactory.getService(expressStore.activeEditor);
	},

	getActiveStore: function () {
		return CanvasFactory.getActiveService().getStore();
	}
};
export default CanvasFactory;
