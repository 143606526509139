import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import MetricsBar from '@/components/_EXPRESS/metrics-bar/MetricsBar';
import ExpressAPI from '@/services/ExpressAPI';
import ReportingContainer from '@/components/reporting/ReportingContainer';
import expressStore from '@/stores/ExpressStore';
import './reporting.scss';

@observer
export default class Reporting extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			metricsLoading: true,
			metrics: { groups: 0, plans: 0, totalMembers: 0, primaryMembers: 0, networks: 0 },
			currentOrg: _.clone(expressStore.currentOrg._id),
			refreshReports: false
		});
		autoBind(this);
	}

	componentDidMount() {
		this.loadMetrics();
	}

	componentDidUpdate(prevProps, prevState) {
		const currentOrg = this.data.currentOrg;
		const newOrg = expressStore.currentOrg._id;
		if (currentOrg !== newOrg) {
			this.loadMetrics();
			this.data.refreshReports = true;
			this.data.currentOrg = _.clone(newOrg);
			setTimeout(() => {
				this.data.refreshReports = false;
			}, 500);
		}
	}

	loadMetrics() {
		this.data.metricsLoading = true;
		ExpressAPI.getMetrics()
			.then((res) => {
				this.data.metrics = res;
				this.data.metricsLoading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.metricsLoading = false;
			});
	}

	async getReportList(report, request) {
		return await ExpressAPI.getReportList(request);
	}

	async getReportInfo(report, request) {
		return await ExpressAPI.getReportData(report, request);
	}

	render() {
		const activeOrg = expressStore.currentOrg._id; //Bit of a hack, but this lets us listen for org changes & update data
		console.log('Refresh Reports for org: ', activeOrg);
		return (
			<div className="express-reporting-page">
				<h1 className="text-4 mb-xl">Reporting</h1>
				<MetricsBar
					title="Metrics"
					loadMetrics={this.loadMetrics}
					metrics={this.data.metrics}
					showRefresh={true}
					loading={this.data.metricsLoading}
				></MetricsBar>
				<h3 className="category hbox vcenter">
					<div className="grow-1">Reports</div>
				</h3>
				<div className="reporting-subtitle">
					{`*${expressStore.currentOrg.name}  data will begin to accrue as active members are published and registered.  Report data is updated daily.`}
				</div>
				{!this.data.refreshReports && (
					<ReportingContainer getConfigs={this.getReportList} getReportData={this.getReportInfo} showReportMenu />
				)}
			</div>
		);
	}
}
