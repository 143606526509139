import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import { Button } from 'framework7-react';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import { derviePlanBaseConfig } from '@/pages/EXPRESS/groups/plans-list/plan-details/PlanDetails';
import './custom-fields.scss';

@observer
export default class CustomFields extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: false,
			showCustomFieldEditor: false,
			allIdCards: {},
			isLabelValid: true,
			serverRespError: false,
			serverRespErrorText: '',
			currentCustomFieldSelected: {
				existingField: false,
				key: '',
				label: '',
				type: 'text',
				placeholder: '',
				isCustomAccumulator: false,
				isUsd: false,
				isPercentage: false,
				isUsedInCalculations: false
			}
		});
		autoBind(this);
	}

	componentDidMount() {
		let idCardCustomFields = {};
		ExpressAPI.listIdCards('all').then((idCards) => {
			idCards?.forEach((card, i) => {
				if (card?.customFields)
					idCardCustomFields = Object.assign(
						{},
						idCardCustomFields,
						card.customFields
					);

				if (i === idCards.length - 1) this.data.allIdCards = idCardCustomFields;
			});
		});
	}

	dervieFormConfig(config) {
		return {
			label: {
				label: 'Field Label',
				placeholder: 'T1 IND OOP',
				type: 'text'
			},
			type: {
				label: 'Field Type',
				type: 'dropdown',
				className: 'dropdown-form-input',
				listItems: {
					text: 'Text',
					number: 'Number'
				}
			},
			placeholder: {
				label: 'Field Sample Input (placeholder)',
				placeholder: '60',
				type: 'text'
			},
			isCustomAccumulator: {
				label: 'Is Custom Accumulator?',
				type: 'checkbox'
			},
			isUsd: {
				label: 'Is USD?',
				type: 'checkbox',
				hideField: config.type !== 'number',
				checkBoxGroup: 'valueType',
				canHaveMultipleValuesSelected: false,
				otherCheckboxesInCheckBoxGroup: ['isPercentage']
			},
			isPercentage: {
				label: 'Is Percentage?',
				type: 'checkbox',
				hideField: config.type !== 'number',
				checkBoxGroup: 'valueType',
				canHaveMultipleValuesSelected: false,
				otherCheckboxesInCheckBoxGroup: ['isUsd']
			},
			isUsedInCalculations: {
				label: 'Field is used in procedure pricing calculations?',
				type: 'checkbox',
				hideField: config.type !== 'number',
				checkBoxGroup: 'valueType',
				canHaveMultipleValuesSelected: false
			}
		};
	}

	resetCustomFieldsUIForm() {
		this.data.showCustomFieldEditor = false;
		this.data.currentCustomFieldSelected = {
			existingField: false,
			key: '',
			label: '',
			type: 'text',
			placeholder: '',
			isCustomAccumulator: false,
			isUsd: false,
			isPercentage: false,
			isUsedInCalculations: false
		};
	}

	resetServerErrorStatus() {
		setTimeout(() => {
			this.data.serverRespError = false;
			this.data.serverRespErrorText = '';
		}, 1000);
	}

	deleteField(customField) {
		this.data.loading = true;
		ExpressAPI.deleteOrgCustomFieldsFormConfig(
			expressStore.currentOrg._id,
			customField.key
		)
			.then((res) => {
				expressStore.currentOrg = res;
				this.data.loading = false;
			})
			.catch((err) => {
				this.data.loading = false;
				const errRes = JSON.parse(err.response);
				this.data.serverRespError = true;
				this.data.serverRespErrorText = errRes.message;

				this.resetServerErrorStatus();
			});
	}

	onChangeSortOrder(currentSortOrder, direction, key) {
		this.data.loading = true;
		const { currentOrg } = expressStore;
		const customFieldsFormConfig = _.get(
			currentOrg,
			'customFieldsFormConfig',
			{}
		);
		const fieldsToChange = {};
		let destinationField;
		const currentFieldsSortOrder = currentSortOrder;

		const sortedFields = Object.keys(customFieldsFormConfig).sort(
			(a, b) =>
				customFieldsFormConfig[a]?.sortOrder -
				customFieldsFormConfig[b]?.sortOrder
		);

		sortedFields.forEach(async (f, index) => {
			if (
				customFieldsFormConfig[f].sortOrder === currentFieldsSortOrder &&
				!destinationField
			) {
				//*NOTE Item focused moving
				if (
					direction === 'DOWN' &&
					customFieldsFormConfig[f].key === key &&
					currentFieldsSortOrder !== sortedFields.length
				) {
					destinationField = customFieldsFormConfig[sortedFields[index + 1]];

					//* current field we are moving
					fieldsToChange[f] = customFieldsFormConfig[f];
					fieldsToChange[f].sortOrder = destinationField.sortOrder;

					//* the field that the current field to move is being replacing in order
					destinationField.sortOrder = currentFieldsSortOrder;
					fieldsToChange[sortedFields[index + 1]] = destinationField;
				}

				if (
					direction === 'UP' &&
					customFieldsFormConfig[f].key === key &&
					currentFieldsSortOrder !== 1
				) {
					destinationField = customFieldsFormConfig[sortedFields[index - 1]];

					//* current field we are moving
					fieldsToChange[f] = customFieldsFormConfig[f];
					fieldsToChange[f].sortOrder = destinationField.sortOrder;

					//* the field that the current field to move is being replacing in order
					destinationField.sortOrder = currentFieldsSortOrder;
					fieldsToChange[sortedFields[index - 1]] = destinationField;
				}
			}

			if (index === sortedFields.length - 1) {
				expressStore.currentOrg.customFieldsFormConfig = Object.assign(
					{},
					expressStore.currentOrg?.customFieldsFormConfig,
					fieldsToChange
				);

				await ExpressAPI.updateOrg({
					customFieldsFormConfig: expressStore.currentOrg.customFieldsFormConfig
				});
				this.data.loading = false;
			}
		});
	}

	onSaveCustomField() {
		let {
			key,
			isCustomAccumulator,
			label,
			type,
			placeholder,
			isUsd,
			isPercentage,
			isUsedInCalculations
		} = this.data.currentCustomFieldSelected;

		this.data.loading = true;

		if (!key) key = label.split(' ').join('_');

		ExpressAPI.saveOrgCustomFieldsFormConfig(expressStore.currentOrg._id, {
			key,
			label,
			type,
			placeholder,
			isUsd,
			isPercentage,
			isUsedInCalculations,
			isCustomAccumulator
		})
			.then((res) => {
				expressStore.currentOrg = res;
				this.data.loading = false;
			})
			.catch((err) => {
				this.data.loading = false;
				const errRes = JSON.parse(err.response);
				this.data.serverRespError = true;
				this.data.serverRespErrorText = errRes.message;

				this.resetServerErrorStatus();
			});

		this.resetCustomFieldsUIForm();
	}

	buildList() {
		let { loading } = this.data;
		const { currentOrg } = expressStore;
		const customFieldsFormConfig = _.get(
			currentOrg,
			'customFieldsFormConfig',
			{}
		);

		const sortedFields = Object.keys(customFieldsFormConfig)
			.sort(
				(a, b) =>
					customFieldsFormConfig[a]?.sortOrder -
					customFieldsFormConfig[b]?.sortOrder
			)
			.map((field) => {
				return { fieldName: [field], value: customFieldsFormConfig[field] };
			});

		let items = [];
		_.forEach(sortedFields, (cf) => {
			const fieldCofing = cf.value;
			const isEliance =
				!!cf?.fieldName[0]?.match('plan.') === true ? true : false;

			items.push(
				<div
					className={`table-list-item fields-list-item hbox vcenter ${
						loading ? 'disabled' : ''
					}`}
					key={`${currentOrg._id}-${fieldCofing.key}`}
					onClick={(e) => {
						const skipConditionals =
							// @ts-ignore
							e.target?.classList?.contains('fa-trash-alt') ||
							// @ts-ignore
							e.target?.classList?.contains('fa-arrow-up') ||
							// @ts-ignore
							e.target?.classList?.contains('fa-arrow-down') ||
							// @ts-ignore
							e.target?.classList?.contains('sortOrder-button');

						if (skipConditionals) {
							return;
						}

						this.data.showCustomFieldEditor = true;
						this.data.currentCustomFieldSelected = {
							existingField: true,
							key: fieldCofing.key,
							label: fieldCofing.label,
							type: fieldCofing.type,
							placeholder: fieldCofing.placeholder,
							isCustomAccumulator: fieldCofing.isCustomAccumulator,
							isUsd: fieldCofing.isUsd,
							isPercentage: fieldCofing.isPercentage,
							isUsedInCalculations: fieldCofing.isUsedInCalculations
						};
					}}
				>
					<div className="name grow-1" title={_.toUpper(fieldCofing.label)}>
						{fieldCofing.label}
					</div>
					<div className="field grow-1">
						{fieldCofing.type && fieldCofing.type}
					</div>
					<div className="field grow-1">{fieldCofing.placeholder}</div>
					<div className="field grow-1">
						{fieldCofing.isCustomAccumulator && (
							<i className="fa-regular fa-square-check"></i>
						)}
					</div>
					<div className="field grow-1">
						{fieldCofing.isUsd && (
							<i className="fa-regular fa-square-check"></i>
						)}
					</div>
					<div className="field grow-1">
						{fieldCofing.isPercentage && (
							<i className="fa-regular fa-square-check"></i>
						)}
					</div>
					<div className="field grow-1">
						{fieldCofing.isUsedInCalculations && (
							<i className="fa-regular fa-square-check"></i>
						)}
					</div>
					<div className="field grow-1 sortOrder">
						<div className="sortOrder-button-wrapper">
							<div
								className="sortOrder-button"
								onClick={() =>
									this.onChangeSortOrder(
										fieldCofing.sortOrder,
										'UP',
										fieldCofing.key
									)
								}
							>
								<i className="fa-solid fa-arrow-up"></i>
							</div>
							<div
								className="sortOrder-button"
								onClick={() =>
									this.onChangeSortOrder(
										fieldCofing.sortOrder,
										'DOWN',
										fieldCofing.key
									)
								}
							>
								<i className="fa-solid fa-arrow-down"></i>
							</div>
						</div>
					</div>
					<i
						className="fad fa-trash-alt trash-icon-override"
						title="Delete Custom Fields"
						onClick={() => {
							if (isEliance) return this.deleteField({ key: cf?.fieldName[0] });

							return this.deleteField(fieldCofing);
						}}
					></i>
				</div>
			);
		});
		return (
			<div className={`plan-list ${loading ? '' : 'shadow'}`}>
				{sortedFields.length !== 0 && (
					<div className="table-list-header hbox vcenter">
						<div className="name grow-1">Field Label</div>
						<div className="field grow-1">Field Type</div>
						<div className="field grow-1">Field Placeholder</div>
						<div className="field grow-1">Is Accumulator</div>
						<div className="field grow-1">Is USD</div>
						<div className="field grow-1">Is Percentage</div>
						<div className="field grow-1">Is Used in Calculations</div>
						<div className="field grow-1">Sort Action</div>
					</div>
				)}
				{!loading && sortedFields.length === 0 ? (
					<>
						<h3>No fields here to show</h3>
					</>
				) : (
					items
				)}
			</div>
		);
	}

	checkIfFieldIsDup(valid) {
		let dupField;

		// ORG's Custom Fields
		const { currentOrg } = expressStore;
		const customFieldsFormConfig = _.get(
			currentOrg,
			'customFieldsFormConfig',
			{}
		);
		const customFields = Object.keys(customFieldsFormConfig);

		dupField = customFields.find((cf) => {
			const fieldCofing = customFieldsFormConfig[cf];
			if (
				this.data.currentCustomFieldSelected.label === fieldCofing.label &&
				this.data.currentCustomFieldSelected.existingField === false
			)
				return true;
		});
		if (dupField) {
			this.data.isLabelValid = false;
			return false;
		} // false for validator

		// Base fields
		const baseFields = derviePlanBaseConfig();

		for (const property in baseFields.basicConfig) {
			if (
				this.data.currentCustomFieldSelected.label ===
				`${baseFields.basicConfig[property]}.label`
			)
				dupField = true;
		}

		for (const property in baseFields.idcardConfig) {
			if (
				this.data.currentCustomFieldSelected.label ===
				`${baseFields.basicConfig[property]}.label`
			)
				dupField = true;
		}
		if (dupField) {
			this.data.isLabelValid = false;
			return false;
		} // false for validator

		// Existing manually entered ID model custom fields form config
		for (const property in this.data.allIdCards) {
			if (
				this.data.currentCustomFieldSelected.label ===
				`${this.data.allIdCards[property]}.label`
			)
				dupField = true;
		}

		if (dupField) {
			this.data.isLabelValid = false;
			return false;
		} // false for validator

		// Still valid
		this.data.isLabelValid = true;
		return true;
	}

	render() {
		const {
			currentCustomFieldSelected,
			showCustomFieldEditor,
			isLabelValid,
			serverRespError,
			serverRespErrorText,
			loading
		} = this.data;
		return (
			<div className="express-custom-fields">
				<div className="custom-fields-header-container">
					<div>
						<h2>Health Plans Custom Fields</h2>
						<p>
							A custom field is a plan field that you can use to populate
							additional static data for your plans. You will find the created
							fields in the HealthPlan Additional Data section.
						</p>
						<p>
							These fields can be of type Text or Number. If number is selected,
							you will be able to choose if it's a Custom Accumulator or if the
							field needs to be used for Procedure Pricing, along with a few
							other options.
						</p>
						<p>
							An example of a use case would be if you had multiple copays for a
							tiered network for that plan's network. A plan could use a
							different copay based on the network tier.
						</p>
						<p>
							You can create the other copays here and then enter the vaule for
							them (which will be accessed at) in the HealthPlan Additional Data
							Edit screen.{' '}
						</p>
					</div>

					<div className="btn-ctn">
						<Button
							fill
							small
							className="list-btn ios"
							onClick={() => {
								this.data.showCustomFieldEditor = true;
							}}
						>
							ADD CUSTOM FIELD
						</Button>
					</div>
				</div>
				<div className="space-break" />
				{serverRespError && (
					<h4 className="server_response">{serverRespErrorText}</h4>
				)}
				{this.buildList()}

				<FormPopup
					opened={showCustomFieldEditor}
					title="Custom Field Editor"
					buttonText={'Save'}
					formData={currentCustomFieldSelected}
					formConfig={this.dervieFormConfig(currentCustomFieldSelected)}
					onSubmit={this.onSaveCustomField}
					onClose={() => this.resetCustomFieldsUIForm()}
					customValidator={(valid) => {
						return this.checkIfFieldIsDup(valid);
					}}
					additionalContent={
						!isLabelValid && <h4>Your label needs to be unique.</h4>
					}
				/>
			</div>
		);
	}
}
