import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Button, Link, Popup, Preloader } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import { DOC_ICON } from '../document-constants';
import './link-document-popup.scss';
import expressStore from '@/stores/ExpressStore';
@observer
export default class LinkDocumentPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			fetchingDocuments: false,
			documents: [],
			searchValue: ''
		});
		autoBind(this);
	}

	handlePopupOpen() {
		this.fetchAvailableDocuments();
	}

	fetchAvailableDocuments() {
		this.data.fetchingDocuments = true;
		ExpressAPI.getAvailableDocuments(expressStore.activePlan?._id)
			.then((resp) => {
				this.data.fetchingDocuments = false;
				this.data.documents = resp;
			})
			.catch((e) => {
				showExpressError(this.$f7, e);
				this.data.fetchingDocuments = false;
			});
	}

	handlePopupClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handleSearch(val) {
		this.data.searchValue = val;
	}
    

	filteredDocuments() {
		const { searchValue, documents } = this.data;
		let search = searchValue.toLowerCase();
		let filtered =
			search.length > 0
				? documents.filter((document) => {
						let name = document.name.toLowerCase();
						if (search.length === 1) {
							return name.search(search) === 0;
						} else {
							return name.search(search) > -1;
						}
				  })
				: documents;

		return filtered;
	}

    handleAddDocument(id) {
        ExpressAPI.linkDocumentToPlan(expressStore.activePlan?._id, id).then(() => {
            this.handlePopupClose();
        }).catch((e) => {
			showExpressError(this.$f7, e);
        })
    }

	render() {
		const { opened } = this.props;
		const { fetchingDocuments, documents } = this.data;
		return (
			<Popup
				className="express-form-popup link-document-popup"
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.handlePopupClose}
				onPopupOpen={this.handlePopupOpen}
			>
				<div className="popup-header">
					<div className="title grow-1">Add document to plan</div>
					<Link popupClose>
						<i className="fad fa-times-square"></i>
					</Link>
				</div>
				<div className="main-content y-scroll">
					<LinkDocumentBody {...this.data} onSearch={this.handleSearch} onAddDocument={this.handleAddDocument} filteredDocuments={this.filteredDocuments()}/>
				</div>
			</Popup>
		);
	}
}

const LinkDocumentBody = (props) => {
	const { fetchingDocuments, documents } = props;
	if (fetchingDocuments) {
		return (
			<div className="hbox vcenter hcenter w-100">
				<Preloader size={32} color="blue"></Preloader>
			</div>
		);
	} else if (!fetchingDocuments && documents.length > 0) {
		return <DocumentList {...props} />;
	} else {
		return <div>No Documents!</div>;
	}
};

const DocumentList = (props) => {
	const { filteredDocuments, searchValue, onSearch, onAddDocument } = props;
	console.log(searchValue);
	return (
		<div className="document-list">
			<div className="vbox">
				<div className="input-ctn hbox vcenter">
					<i className="fas fa-search"></i>
					<input placeholder="Search by name..." value={searchValue} onChange={(e) => onSearch(e.target.value)} />
				</div>
				<div className="vbox documents">
					{filteredDocuments.map((document, key) => {
						return <Document document={document} key={document._id} onAddDocument={onAddDocument}/>;
					})}
				</div>
			</div>
		</div>
	);
};

const Document = (props) => {
	const { document, onAddDocument } = props;
	return (
		<div className="hbox document vcenter" onClick={() => onAddDocument(document._id)}>
			<div className="icon">
				<i className={`fa ${DOC_ICON[document.fileData.fileType]}`}></i>
			</div>
			<div className="vbox grow-1">
				<div className="document-name">{document.name}</div>
				<div className="document-type">{document.type}</div>
			</div>
			<a className="action">
				<i className="fa fa-plus"></i>
			</a>
		</div>
	);
};
