import React, { Component, createRef } from 'react';
import { observer, observable } from '@/utils/State';
import { PhotoBrowser } from 'framework7-react';
import './tutorial-page.scss';
import { autoBind } from '@/utils/GeneralUtils';

@observer
export default class TutorialPage extends Component {
	// constructor(props) {
	// 	super();
	// 	this.page = createRef();
	// 	autoBind(this);
	// }
	render() {
		let { tutorialTitle, content } = this.props;
		return (
			<div className="express-tutorial-page">
				<h1 className="tutorial-title">{tutorialTitle}</h1>
				<div className="tutorial-description-container">
					<div className="tutorial-description">Description:</div>
					<div className="tutorial-description-content">{content.description}</div>
				</div>
				<hr />
				{Object.keys(content.steps).map((index) => {
					const { stepTitle, body, img, alternateText } = content.steps[index];
					const page = createRef();
					const photos = [img];
					return (
						<div className="step-content" key={`step-content-key-${index}`}>
							<h3 className="step-title">{stepTitle}</h3>
							<p className="step-body">{body}</p>
							{/* <PhotoBrowser photos={photos} type="page" ref={this.page} toolbar={false} navbar={true} theme="dark" /> */}
							<div className="photo-browser-page">
								<PhotoBrowser photos={photos} type="page" ref={page} toolbar={false} navbar={true} />
							</div>
							<img
								className="step-image"
								src={img}
								width="600"
								alt={alternateText}
								onClick={() => {
									// this.page.current.open();
									page.current.open();
								}}
							/>
						</div>
					);
				})}
				<hr />
			</div>
		);
	}
}
