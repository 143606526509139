import React, { Component } from 'react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { autoBind, formatDOB } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import { Button, Preloader } from 'framework7-react';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import ExpressAPI from '@/services/ExpressAPI';
import './groups-home.scss';
import GroupDetails from './group-details/GroupDetails';
import StatusPopup from '@/components/_EXPRESS/status-poup/StatusPopup';

@observer
export default class GroupsHome extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showStatusPopup: false,
			searchInput: '',
			createOpened: false,
			loading: false,
			groups: [],
			loadedFrom: null,
			createFormData: {
				name: '',
				groupId: '',
				effectiveDate: '',
				renewalDate: '',
				contactName: '',
				contactPhone: '',
				contactEmail: '',
				memberDataType: 'manual'
			}
		});
		autoBind(this);
	}

	/**
	 * Used to allow for group data reload on global org change
	 * Will be called once on page load since loadedFrom is null by default
	 * This means componentDidMount is unneeded to load the data directly
	 */
	asyncLoadGroups() {
		setTimeout(() => {
			this.loadGroups();
		}, 0);
	}

	async loadGroups() {
		if (!this.data.loading) {
			this.data.loading = true;
			this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
			ExpressAPI.getGroups()
				.then((res) => {
					this.data.loading = false;
					this.data.groups = res;
				})
				.catch((err) => {
					this.data.loading = false;
					console.log(err);
				});
		}
	}

	searchChange(e) {
		this.data.searchInput = e.currentTarget.value;
	}

	onOpenCreateGroup() {
		this.data.createOpened = true;
	}

	onSaveGroup() {
		this.$f7.dialog.preloader('Saving...');
		ExpressAPI.saveGroup(this.data.createFormData)
			.then((res) => {
				this.data.groups = res;
				this.data.createOpened = false;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	getFormConfig() {
		return {
			row1: {
				name: {
					label: 'Group Name',
					placeholder: 'Name of this group',
					type: 'text',
					validator: {
						type: 'length',
						value: 2
					}
				},
				groupId: {
					label: 'Group ID',
					placeholder: `ID for this group`,
					type: 'text',
					validator: {
						type: 'length',
						value: 2
					}
				}
			},
			row2: {
				effectiveDate: {
					label: 'Effective Date',
					type: 'date',
					validator: {
						type: 'date'
					}
				},
				renewalDate: {
					label: 'Renewal Date',
					type: 'date',
					validator: {
						type: 'date'
					}
				}
			},
			contactName: {
				label: 'Contact Name',
				placeholder: 'Main contact for this group',
				type: 'text',
				validator: {
					type: 'length',
					value: 2
				}
			},
			contactPhone: {
				label: 'Contact Phone',
				placeholder: 'Main contact phone number',
				type: 'phone',
				validator: {
					type: 'phone'
				}
			},
			contactEmail: {
				label: 'Contact Email',
				placeholder: 'Main contact email address',
				type: 'email',
				validator: {
					type: 'email'
				}
			},
			memberDataType: {
				label: 'Member Data',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: this.data.createFormData?.memberDataType === 'manual' ? 'fad fa-user-edit' : 'fad fa-file-upload',
				listItems: { manual: 'Manually Manage Member Data', import: 'Import Member Data via Files' }
			}
		};
	}

	statusChip(group) {
		switch (group.status) {
			case 'completed': {
				return (
					<div
						className={`status-chip completed`}
						onClick={() => {
							this.data.showStatusPopup = true;
						}}
					>
						<i className="fad fa-check-circle"></i> Setup Completed
					</div>
				);
			}
			case 'pending': {
				return (
					<div
						className={`status-chip pending`}
						onClick={() => {
							this.data.showStatusPopup = true;
						}}
					>
						<i className="fad fa-exclamation-triangle"></i> Setup Incomplete
					</div>
				);
			}
			case 'review': {
				return (
					<div
						className={`status-chip review`}
						onClick={() => {
							this.data.showStatusPopup = true;
						}}
					>
						<i className="fad fa-eye"></i> Under Review
					</div>
				);
			}
			default:
				return '';
		}
	}

	closeStatusPopup() {
		expressStore.activeGroup = null;
		this.data.showStatusPopup = false;
	}

	buildList() {
		let { groups, loading, searchInput } = this.data;
		let items = [];
		_.forEach(groups, (g) => {
			if (searchInput.length > 0 && _.toLower(g.name).indexOf(searchInput.toLowerCase()) < 0) {
				return;
			}
			items.push(
				<div
					className="table-list-item group-list-item hbox vcenter"
					key={`${g.orgId}-${g._id}`}
					onClick={(e) => {
						expressStore.activeGroup = g;
					}}
				>
					<div className="name ellipse" title={_.toUpper(g.name)}>
						{_.toLower(g.name)}
					</div>
					<div className="field grow-1">{g.groupId}</div>
					<div className="field grow-1">{formatDOB(g.effectiveDate, 'yyyy-MM-dd', 'MMMM dd, yyyy')}</div>
					<div className="field grow-1">{g.createdBy}</div>
					<div className="field status">{this.statusChip(g)}</div>
				</div>
			);
		});
		return (
			<div className={`group-list ${loading ? '' : 'shadow'}`}>
				{groups.length !== 0 && (
					<div className="table-list-header group-list-header hbox vcenter">
						<div className="name">Group Name</div>
						<div className="field grow-1">Group ID</div>
						<div className="field grow-1">Effective Date</div>
						<div className="field grow-1">Created By</div>
						<div className="field status">Status</div>
					</div>
				)}
				{!loading ? (
					items
				) : (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
			</div>
		);
	}

	render() {
		let { searchInput, groups, loading, createOpened, loadedFrom } = this.data;
		let { currentOrg } = expressStore;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoadGroups();
		}
		return (
			<div className="express-groups-home-page">
				{expressStore.activeGroup && !this.data.showStatusPopup && (
					<GroupDetails statusChipBuilder={this.statusChip} loadGroups={this.loadGroups} />
				)}
				{(!expressStore.activeGroup || this.data.showStatusPopup) && (
					<>
						<h1 className="text-4 mb-xl grow-1">Groups</h1>
						<div className="list-controls hbox vcenter">
							<div className="input-ctn hbox vcenter">
								<i className="fas fa-search"></i>
								<input placeholder="Search for groups by name..." value={searchInput} onChange={this.searchChange} />
							</div>
							<div className="grow-1"></div>
							{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
							expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER ? null : (
								<Button fill small className="list-btn ios" onClick={this.onOpenCreateGroup}>
									<i className="fa fa-plus" />
									Add Group
								</Button>
							)}
						</div>
						{groups.length <= 0 &&
						!loading &&
						expressStore.currentOrgRole()?.hasOwnProperty('role') &&
						expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER ? null : groups.length <= 0 && !loading ? (
							<div className="empty-message vbox vcenter hcenter">
								<div className="icon-box hbox vcenter hcenter">
									<i className="fad fa-users-class" />
								</div>
								<h3>Add your first group</h3>
								<Button fill small className="round-btn list-btn animated pulse infinite" onClick={this.onOpenCreateGroup}>
									<i className="fa fa-plus" />
									Add Group
								</Button>
							</div>
						) : null}
						{this.buildList()}
						<FormPopup
							opened={createOpened}
							title="New Group"
							buttonText="Save"
							formData={this.data.createFormData}
							formConfig={this.getFormConfig()}
							onSubmit={this.onSaveGroup}
							additionalContent={
								<div className="form-additional-details">
									<div className="form-message">
										{this.data.createFormData?.memberDataType === 'manual'
											? `Prefered for smaller groups. This group's members can be manually created, edited, and termed straight from express. Any members included on files with this group ID will not be loaded, updated, or termed via that process.`
											: `Prefered for large groups or initially seeding a group with data then switching to manual later. Members will only be loaded via the data import process. You cannot manually edit any members in this group.`}
									</div>
									<div className="form-message">
										<i className="fad fa-exclamation-triangle"></i>
										You can change this at anytime but note: <br />
										Data may be lost or overridden if you switch processes and have not backed up the changes in the destination
										system.
									</div>
								</div>
							}
							onClose={() => {
								this.data.createOpened = false;
							}}
						/>
					</>
				)}
				<StatusPopup
					open={this.data.showStatusPopup}
					status={_.get(expressStore, 'activeGroup.status')}
					type="group"
					itemId={_.get(expressStore, 'activeGroup._id')}
					onClose={this.closeStatusPopup}
					title={`${_.get(expressStore, 'activeGroup.name')} Status Summary`}
				/>
			</div>
		);
	}
}
