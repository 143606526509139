import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import './express-team.scss';
import ExpressAPI from '@/services/ExpressAPI';
import { Button, Link, Popup } from 'framework7-react';
import { format } from 'date-fns';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';

const ROLE_DETAILS = {
	'organization-admin': {
		icon: 'fad fa-user-crown',
		name: 'Organization Admin',
		details:
			'This role allows for complete access to all organization level settings, pages, and resources. As well as, access to every group, plan, network, and member. This is the highest level of access a user can have'
	},
	'group-admin': {
		icon: 'fad fa-user-shield',
		name: 'Group Admin',
		details:
			'This role allows for access to all groups, plans, members, and other attached resources. \n\nThis role CANNOT edit organization level details or invite others to the platform.'
	},
	'group-user': {
		icon: 'fad fa-user',
		name: 'Group User',
		details:
			'This role allows the user VIEW access to every attached resource for a specific selection of groups.\n \n This role CANNOT EDIT or CREATE resources they have view access to.'
	}
};

@observer
export default class ExpressTeam extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loadedFrom: null,
			userData: {},
			editingUser: false,
			inviteOpen: false,
			rolesOpen: false,
			groups: [],
			editFormData: { role: 'group-admin', groups: [] },
			inviteFormData: { email: '', role: 'group-admin', groups: [], firstName: '', lastName: '' }
		});
		autoBind(this);
	}

	componentDidMount() {}

	asyncFetchUsers() {
		setTimeout(() => {
			this.fetchUsers();
		}, 0);
	}

	fetchUsers() {
		this.$f7.dialog.preloader('Fetching Users');
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		ExpressAPI.getAllUsers()
			.then(async (res) => {
				this.data.userData = res;
				const groups = await ExpressAPI.getGroups();
				this.data.groups = groups;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	onSendInvite() {
		this.$f7.dialog.preloader('Sending Invite');
		ExpressAPI.inviteUser(this.data.inviteFormData)
			.then(() => {
				this.data.inviteOpen = false;
				this.data.inviteFormData = { email: '', role: 'group-admin', groups: [], firstName: '', lastName: '' };
				this.$f7.dialog.close();
				//causes a reload of everything
				this.data.loadedFrom = null;
			})
			.catch((err) => {
				console.log(err);
				showExpressError(this.$f7, err);
			});
	}

	onSaveUser() {
		this.$f7.dialog.preloader('Updating User');
		ExpressAPI.updateUserRole(this.data.editingUser, this.data.editFormData)
			.then(() => {
				this.data.editingUser = null;
				this.data.editFormData = { role: 'group-admin', groups: [] };
				this.$f7.dialog.close();
				//causes a reload of everything
				this.data.loadedFrom = null;
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
		this.data.editingUser = null;
		this.data.editFormData = { role: 'group-admin', groups: [] };
	}

	onEditUser(record) {
		let role = _.get(record, 'role.role', 'group-user');
		if (role === 'systems-admin') {
			role = 'organization-admin';
		}
		if (role === 'group-user') {
			let groups = [];
			_.forEach(this.data.groups, (g) => {
				if (record.groups.indexOf(g._id) >= 0) {
					groups.push({
						id: g._id,
						name: `${g.name} - ${g.groupId}`
					});
				}
			});
			console.log(groups);
			this.data.editFormData.groups = groups;
		}
		this.data.editFormData.role = role;
		this.data.editingUser = record.user._id;
	}

	onDeleteUser(record) {
		this.$f7.dialog.confirm('Deleting this invite cannot be undone', 'Are you sure?', () => {
			this.$f7.dialog.preloader('Deleting User');
			ExpressAPI.deleteUser(record.user._id)
				.then((res) => {
					this.$f7.dialog.close();
					//causes a reload of everything
					this.data.loadedFrom = null;
				})
				.catch((err) => {
					showExpressError(this.$f7, err);
				});
		});
	}

	buildUserList() {
		let { userData } = this.data;
		if (userData && userData.users) {
			let { users } = userData;
			return (
				<div className="user-card-list">
					{Object.keys(users).map((userId) => {
						const record = users[userId];
						const user = record.user;
						const roleData = ROLE_DETAILS[_.get(record, 'role.role', 'group-user')] || {
							name: 'System Admin',
							icon: 'fad fa-user-crown'
						};
						return (
							<div className="user-card vbox vcenter hcenter" key={userId}>
								<div className="user-card-header hbox vcenter">
									<div className="role">{user.owner ? 'Owner' : roleData.name}</div>
									<div className="grow-1"></div>
									{!user.owner && (
										<i
											className="fad fa-trash"
											onClick={() => {
												this.onDeleteUser(record);
											}}
										></i>
									)}
									{!user.owner && (
										<i
											className="fad fa-edit"
											onClick={() => {
												this.onEditUser(record);
											}}
										></i>
									)}
								</div>
								<div className="icon-ctn">
									{user.owner ? <i className="fad fa-user-crown"></i> : <i className={`${roleData.icon}`}></i>}
								</div>
								<div className="name">{`${user.firstName} ${user.lastName}`}</div>
								<div className="title">{user.jobTitle}</div>
								<div className="email">{user.email}</div>
								{user.status === 'active' && (
									<div className="login">
										<span>Last Login:</span>
										{format(new Date(user.lastLogin), 'M/dd/yy h:mm b')}
									</div>
								)}
								{user.status === 'pending' && <div className="pending">Invitation Sent</div>}
							</div>
						);
					})}
				</div>
			);
		}
		return '';
	}

	getEditConfig() {
		let { editFormData } = this.data;
		let config = {
			role: {
				label: 'User Role',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'fad fa-shield-alt',
				listItems: {
					'organization-admin': 'Organization Admin',
					'group-admin': 'Group Admin',
					'group-user': 'Group User'
				}
			}
		};
		if (editFormData.role === 'group-user') {
			let groupTags = this.data.groups.map((g) => {
				return {
					id: g._id,
					name: `${g.name} - ${g.groupId}`
				};
			});
			config['groups'] = {
				label: 'Which groups does this user need access to?',
				placeholder: 'Select one or more groups',
				type: 'tagpicker',
				tags: groupTags,
				validator: {
					type: 'tagCount',
					value: 1
				}
			};
		}
		return config;
	}

	getInviteConfig() {
		let { inviteFormData } = this.data;
		let config = {
			row1: {
				firstName: {
					label: 'First Name',
					type: 'text',
					placeholder: 'First name',
					validator: {
						type: 'length',
						value: 1
					}
				},
				lastName: {
					label: 'Last Name',
					type: 'text',
					placeholder: 'Last name',
					validator: {
						type: 'length',
						value: 1
					}
				}
			},
			email: {
				label: 'Email',
				placeholder: 'name@yourcompany.com',
				type: 'email',
				validator: {
					type: 'email'
				}
			},
			role: {
				label: 'User Role',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'fad fa-shield-alt',
				listItems: {
					'organization-admin': 'Organization Admin',
					'group-admin': 'Group Admin',
					'group-user': 'Group User'
				}
			}
		};
		if (inviteFormData.role === 'group-user') {
			let groupTags = this.data.groups.map((g) => {
				return {
					id: g._id,
					name: `${g.name} - ${g.groupId}`
				};
			});
			config['groups'] = {
				label: 'Which groups does this user need access to?',
				placeholder: 'Select one or more groups',
				type: 'tagpicker',
				tags: groupTags,
				validator: {
					type: 'tagCount',
					value: 1
				}
			};
		}
		return config;
	}

	getRoleInformation(role) {
		let selectedRole = role ? ROLE_DETAILS[role] : ROLE_DETAILS[this.data.inviteFormData.role];
		return (
			<div className="role-details" key={Math.random()}>
				<div className="hbox vcenter">
					<div className="title">Role Details: </div>
					<i className={selectedRole.icon}></i>
					<div className="name">{selectedRole.name}</div>
				</div>
				<p className="details">{selectedRole.details}</p>
			</div>
		);
	}

	render() {
		let { currentOrg } = expressStore;
		let { loadedFrom, inviteOpen, inviteFormData, rolesOpen, editingUser, editFormData } = this.data;
		if (loadedFrom !== currentOrg._id) {
			this.asyncFetchUsers();
		}
		return (
			<div className="express-team-page">
				<div className="team-header hbox vcenter">
					<h2 className="grow-1">All Team Members</h2>
					<Button
						small
						fill
						className="list-btn secondary ios"
						onClick={() => {
							this.data.rolesOpen = true;
						}}
					>
						<i className="fad fa-shield-alt"></i>
						View Roles
					</Button>
					<Button
						small
						fill
						className="list-btn ios"
						onClick={() => {
							this.data.inviteOpen = true;
						}}
					>
						<i className="fad fa-paper-plane"></i>
						Send Invite
					</Button>
				</div>
				{/* {loading && (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)} */}
				{this.buildUserList()}
				<FormPopup
					opened={inviteOpen}
					title="Create New Invite"
					buttonText="Send Invite"
					formData={inviteFormData}
					formConfig={this.getInviteConfig()}
					onSubmit={this.onSendInvite}
					additionalContent={this.getRoleInformation()}
					onClose={() => {
						this.data.inviteOpen = false;
						this.data.inviteFormData = { email: '', role: 'group-admin', groups: [], firstName: '', lastName: '' };
					}}
				></FormPopup>
				<FormPopup
					opened={!!editingUser}
					title="Change User Role"
					buttonText="Save"
					formData={editFormData}
					formConfig={this.getEditConfig()}
					onSubmit={this.onSaveUser}
					additionalContent={this.getRoleInformation(editFormData.role)}
					onClose={() => {
						this.data.editingUser = null;
						this.data.editFormData = { role: 'group-admin', groups: [] };
					}}
				></FormPopup>
				<Popup
					className={`express-form-popup express-role-popup`}
					opened={rolesOpen}
					closeByBackdropClick={false}
					onPopupClosed={() => {
						this.data.rolesOpen = false;
					}}
				>
					<div className="popup-header">
						<div className="title grow-1">Express Roles</div>
						<Link popupClose>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
					<div className="roles-ctn y-scroll">
						{Object.keys(ROLE_DETAILS).map((role) => {
							return this.getRoleInformation(role);
						})}
					</div>
				</Popup>
			</div>
		);
	}
}
