import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import { Rect } from 'react-konva';
import React, { Component } from 'react';
import { render } from 'react-dom';
import CanvasFactory from '@/factories/CanvasFactory';

@observer
export default class Whiteout extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const {whiteoutBox} =  CanvasFactory.getActiveStore();
		return <Rect stroke="#3B82F6" dash={[10, 10]} {...whiteoutBox} />;
	}
}
