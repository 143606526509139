import { observable } from '../utils/State';
import _ from 'lodash';
const cartStore = observable({
	items: [],
	description: '',
	isRecurring: false,
	recurringData: {},
	totals: {
		subtotal: 0,
		tax: 0,
		final: 0,
		qty: 0
	}
});
window.cartStore = cartStore;
export default cartStore;
