import React, { Component } from 'react';
import './breadcrumb.scss';
export default class Breadcrumb extends Component {
	constructor(props) {
		super(props);
	}

	/**
	 * config is index based
	 * current key is index of current page in this crumb
	 */
	render() {
		let { config, currentKey, pageName } = this.props;
		let crumbs = Object.keys(config).map((key) => {
			let { className, onClick, name, icon } = config[key];
			return (
				<div className={`crumb ${className || ''}`} onClick={onClick} key={`${pageName}-crumb-${key}`}>
					{icon && <i className={icon}></i>}
					<span>{name}</span>
				</div>
			);
		});
		let items = [];
		for (let i = 0; i < Object.keys(config).length; i++) {
			if (i < currentKey) {
				items.push(crumbs[i]);
				items.push(<i className="fas fa-chevron-right" key={`chevron-${i}`}></i>);
			} else if (i === currentKey) {
				items.push(crumbs[i]);
			}
		}
		return <div className="express-breadcrumb hbox vcenter mb-xl animated fadeInRight">{items}</div>;
	}
}
