import React from 'react';
import _ from 'lodash';
import { observable } from '@/utils/State';
import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import ExpressAPI from './ExpressAPI';
import { extendObservable } from 'mobx';
import UndoRedo from '@/utils/UndoRedo';

export const SELECT_MODE = {
	MIXED: 'mixed',
	SINGLE: 'single',
	EMPTY: 'empty'
};

export const DIMENSIONS = {
	standard: {
		width: 1013 / window.devicePixelRatio,
		height: 638 / window.devicePixelRatio,
		ratio: 27 / 17,
		label: 'STANDARD (CR80) 3.375" x 2.125"'
	},
	small: {
		width: 317.088,
		height: 196.896,
		ratio: 2051 / 3303,
		label: 'SMALL (CR79) 3.303" x 2.051"'
	},
	large: {
		width: 372.48,
		height: 252.48,
		ratio: 27 / 17,
		label: 'LARGE (CR100) 3.88" x 2.63"'
	}
};
export default class CanvasEditorService {
	//TODO switch things like whiteout, texteditor, toolActive with a type
	constructor() {
		this.editorStore = observable({
			nodeData: {},
			documentWidth: 505.2,
			documentHeight: 318.75,
			documentName: 'untitled',
			documentId: null,
			documentSide: 'front',
			selectedNodes: null,
			selectMode: SELECT_MODE.EMPTY,
			singleSelect: false,
			clipboard: null,
			fontsLoaded: false,
			backgroundData: {},
			backgroundEdit: false,
			showTextEditor: false,
			zoom: 100,
			whiteoutActive: false,
			whiteoutStart: false,
			eyeDropperActive: false,
			saving: false,
			highlight: { active: false },
			existingAsTemplate: false,
			customFields: {},
			cursor: {
				downX: 0,
				downY: 0,
				upY: 0,
				upX: 0
			},
			whiteoutBox: {
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				visible: false
			},
			showMenu: false,
			menu: {
				x: 0,
				y: 0
			}
		});
		this.stageRef = React.createRef();
		this.mainGroupRef = React.createRef();
		this.backgroundGroupRef = React.createRef();
		this.downloadStageRef = React.createRef();
		this.fonts = [
			{
				font: 'Open Sans',
				weights: [300, 400, 500, 600, 700, 800]
			},
			{
				font: 'Roboto',
				weights: [400, 700]
			},
			{
				font: 'Roboto Mono',
				weights: [400, 700]
			}
			// {
			// 	font: 'Do Hyeon',
			// 	weights: [400, 700]
			// }
		];
		this.history = new UndoRedo(12);
		this.saveCallback = null;
		this.externalSave = null;
	}

	setSaveCallback(callback) {
		this.saveCallback = callback;
	}

	setExternalSave(externalSave) {
		this.externalSave = externalSave;
	}

	updateStore(updates) {
		let keys = Object.keys(updates);
		for (let i = 0; i < keys.length; i++) {
			this.editorStore[keys[i]] = updates[i];
		}
	}

	getStore = () => {
		return this.editorStore;
	};

	canDrag = () => {
		return !this.editorStore.whiteoutActive;
	};

	onClose = () => {
		this.history = new UndoRedo(12);
		this.reset();
	};

	handleStageClick = (e) => {
		if (e.target.nodeType === 'Stage' || e.target.name === 'bg') {
			if (this.editorStore.selectedNodes) {
				this.selectNodes([]);
			}
			if (this.editorStore.backgroundEdit) {
				this.editorStore.backgroundEdit = false;
			}
		} else {
			if (e.target.id()) {
				this.selectNodes([]);
				this.selectNodes([`${e.target.id()}`]);
			}
			this.getMainGroup().current.draw();
		}
	};

	reset = (
		documentName,
		documentId,
		documentWidth,
		documentHeight,
		documentSide,
		nodeData,
		backgroundData,
		existingAsTemplate = false,
		customFields = {}
	) => {
		let zoom = 100;

		this.history.push(_.cloneDeep(nodeData));

		extendObservable(this.editorStore, {
			nodeData: nodeData ? nodeData : {},
			documentWidth: documentWidth ? documentWidth : 505.2,
			documentHeight: documentHeight ? documentHeight : 318.75,
			documentName: documentName ? documentName : 'untitled',
			documentId: documentId ? documentId : null,
			documentSide: documentSide ? documentSide : 'front',
			selectedNodes: null,
			selectMode: SELECT_MODE.EMPTY,
			singleSelect: false,
			clipboard: null,
			fontsLoaded: false,
			backgroundData: backgroundData ? backgroundData : {},
			customFields: customFields ? customFields : {},
			backgroundEdit: false,
			showTextEditor: false,
			zoom: zoom,
			whiteoutActive: false,
			whiteoutStart: false,
			eyeDropperActive: false,
			existingAsTemplate: existingAsTemplate,
			saving: false,
			highlight: { active: false },
			cursor: {
				downX: 0,
				downY: 0,
				upY: 0,
				upX: 0
			},
			whiteoutBox: {
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				visible: false
			},
			showMenu: false,
			menu: {
				x: 0,
				y: 0
			}
		});
	};

	toggleBackgroundEdit = () => {
		this.getStore().backgroundEdit = !this.getStore().backgroundEdit;
	};
	// STAGE FUNCTIONS
	getStage = () => {
		return this.stageRef;
	};

	getDownloadStage = () => {
		return this.downloadStageRef;
	};

	getMainGroup = () => {
		return this.mainGroupRef;
	};

	getBackground = () => {
		return this.backgroundGroupRef;
	};

	zoomOut = () => {
		this.zoom(-0.1);
	};

	zoomIn = () => {
		this.zoom(0.1);
	};

	initDocument = (name, width, height) => {
		this.setDocumentDimensions(width, height);
		this.setDocumentName(name);
	};
	setDocumentDimensions = (width, height) => {
		this.editorStore.documentWidth = width;
		this.editorStore.documentHeight = height;
	};

	setDocumentName = (name) => {
		this.editorStore.documentName = name;
		// .replace(' ', '').replace('::', ''); //commenting out bcz its buggy but leaving bcz IDK why it was originally here
	};

	centerStage = () => {
		const stage = this.getStage().current;

		const currentScale = {
			x: stage.scaleX(),
			y: stage.scaleY()
		};
		const center = {
			x: stage.width() / 2,
			y: stage.height() / 2
		};

		const relatedTo = {
			x: (center.x - stage.x()) / currentScale.x,
			y: (center.y - stage.y()) / currentScale.y
		};

		stage.scale({
			x: currentScale.x,
			y: currentScale.y
		});

		const newPosition = {
			x: center.x - relatedTo.x * stage.scaleX(),
			y: center.y - relatedTo.y * stage.scaleY()
		};

		stage.position(newPosition);

		stage.draw();
	};

	zoom = (amount) => {
		const stage = this.getStage().current;

		const currentScale = {
			x: stage.scaleX(),
			y: stage.scaleY()
		};
		const center = {
			x: stage.width() / 2,
			y: stage.height() / 2
		};

		const relatedTo = {
			x: (center.x - stage.x()) / currentScale.x,
			y: (center.y - stage.y()) / currentScale.y
		};

		stage.scale({
			x: currentScale.x + amount,
			y: currentScale.y + amount
		});

		const newPosition = {
			x: center.x - relatedTo.x * stage.scaleX(),
			y: center.y - relatedTo.y * stage.scaleY()
		};

		stage.position(newPosition);

		this.getStore().zoom = this.getStore().zoom + amount * 100;
		stage.draw();
	};

	getRelativePosition = (e) => {
		const transform = this.getMainGroup().current.getAbsoluteTransform().copy();
		transform.invert();

		var pos = this.getStage().current.getPointerPosition();

		return transform.point(pos);
	};

	// STAGE EVENTS
	stageMouseDown = (data) => {
		const { whiteoutActive, cursor } = this.editorStore;
		const { x, y } = this.getRelativePosition(data);

		cursor.downX = x;
		cursor.downY = y;
		// this.updateStore({cursor: {downX: x, downY: y}});

		if (whiteoutActive) {
			this.handleWhiteoutMouseDown(data);
		}
	};

	stageMouseMove = (data) => {
		const { whiteoutActive, whiteoutStart, cursor, eyeDropperActive } = this.editorStore;
		const { x, y } = this.getRelativePosition(data);
		cursor.moveX = x;
		cursor.moveY = y;
		if (whiteoutActive && whiteoutStart) {
			this.handleWhiteoutMouseMove(data);
		}

		if (eyeDropperActive) {
			this.handleEyeDropperMove(data);
		}
	};

	stageMouseUp = (data) => {
		const { cursor } = this.editorStore;
		const { x, y } = this.getRelativePosition(data);

		cursor.upX = x;
		cursor.upY = y;

		this.handleWhiteoutMouseUp(data);
	};

	onDrag = (e) => {
		this.onHighlight(e);
	};
	//WHITEOUT FUNCTIONS
	toggleWhiteout = () => {
		this.editorStore.whiteoutActive = true;
	};

	handleWhiteoutMouseDown = (data) => {
		this.editorStore.whiteoutStart = true;
	};

	handleWhiteoutMouseMove = (data) => {
		const { x, y } = this.getRelativePosition(data);
		let {
			cursor: { downX, downY },
			whiteoutStart
		} = this.editorStore;
		if (whiteoutStart) {
			const box = { width: x - downX, height: y - downY, x: downX, y: downY, visible: true };
			this.editorStore.whiteoutBox = box;
			// this.editorStore.whiteoutBox = { ...box };
		}
	};

	handleWhiteoutMouseUp = (data) => {
		let { whiteoutActive } = this.editorStore;
		if (whiteoutActive) {
			const box = _.cloneDeep(this.getStore().whiteoutBox);
			const rect = {
				...box,
				fill: '#FFFFFF'
			};
			this.addNode('whiteout', rect);
			this.getStore().whiteoutBox = {
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				visible: false
			};
			this.getStore().whiteoutStart = false;
			this.getStore().whiteoutActive = false;
		}
	};

	// NODE FUNCTIONS
	setTransformer = (transformer) => {
		this.transformer = transformer;
	};

	getTransformer = () => {
		return this.transformer;
	};

	setNodeProperty = (property, value, targetId) => {
		if (targetId && this.editorStore.nodeData[targetId]) {
			this.editorStore.nodeData[targetId][property] = value;
		} else {
			const selectedNode = this.getSelectedNode();
			if (selectedNode) {
				this.editorStore.nodeData[this.editorStore.selectedNodes[0]][property] = value;

				if (this.getTransformer()) {
					this.getTransformer().forceUpdate();
				}
				this.getStage().current.draw();
			}
		}
	};

	setNodeProperties = (properties, targetId) => {
		if (this.editorStore.nodeData[targetId]) {
			this.editorStore.nodeData[targetId] = { ...this.editorStore.nodeData[targetId], ...properties };
		}
	};

	getNode = (name) => {
		if (this.getStage().current) {
			return this.getStage().current.findOne(`#${name}`);
		}
	};

	showMenu = (position) => {
		this.getStore().showMenu = true;
		this.getStore().menu.x = position.x;
		this.getStore().menu.y = position.y;
	};

	hideMenu = () => {
		if (this.getStore().showMenu) {
			this.getStore().showMenu = false;
		}
	};

	undo = () => {
		let undoState = _.cloneDeep(this.history.undo());
		this.editorStore.nodeData = undoState ? undoState : this.editorStore.nodeData;
	};

	redo = () => {
		let redoState = _.cloneDeep(this.history.redo());
		this.editorStore.nodeData = redoState ? redoState : this.editorStore.nodeData;
	};

	addToHistory = () => {
		let cloned = _.cloneDeep(this.editorStore.nodeData);
		this.history.push(cloned);
	};

	activateEyeDropper = () => {
		this.editorStore.eyeDropperActive = true;
	};

	deactivateEyeDropper = () => {
		this.editorStore.eyeDropperActive = false;
	};

	getSelectedNode = () => {
		if (this.getStore().selectedNodes && this.getStore().selectMode === SELECT_MODE.SINGLE) {
			return this.getNode(this.getStore().selectedNodes[0]);
		}
	};

	//To be used for eye dropper
	handleEyeDropperMove = (e) => {
		const cords = this.getRelativePosition();
		const x = e.evt.offsetX;
		const y = e.evt.offsetY;
		const canvas = this.getStage().current.findOne('#main-layer').getCanvas();
		const ctx = canvas.getContext('2d');
		const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
		const index = (y * imgData.width + x) * 4;
		const red = imgData.data[index];
		const green = imgData.data[index + 1];
		const blue = imgData.data[index + 2];
		const alpha = imgData.data[index + 3];
		const rgba = `${red}, ${green}, ${blue}, ${alpha}`;

		const hex = `#${(((1 << 24) + (parseInt(red) << 16) + green) << 8) + parseInt(blue).toString(16).slice(1)}`;

		// return rgba;
	};

	moveNode = (position) => {
		if (this.getSelectedNode() && position) {
			const selectedNode = this.getSelectedNode();
			let move = {
				up: () => selectedNode.moveUp(),
				down: () => selectedNode.moveDown(),
				top: () => selectedNode.moveToTop(),
				bottom: () => selectedNode.moveToBottom()
			};
			move[position]();
			this.setNodeProperty('zIndex', this.getSelectedNode().index);
			this.getMainGroup().current.draw();
		}
	};

	onHighlight = (e) => {
		if (e.target.attrs.type === 'circle') {
			this.editorStore.highlight = {
				active: true,
				x: e.target.x() - e.target.radiusX(),
				y: e.target.y() - e.target.radiusY(),
				width: e.target.radiusX() * 2,
				height: e.target.radiusY() * 2
			};
		} else {
			this.editorStore.highlight = {
				active: true,
				x: e.target.x(),
				y: e.target.y(),
				width: e.target.width(),
				height: e.target.height()
			};
		}

		// this.editorStore.highlight.active = true;
	};

	onHighlightOut = () => {
		this.editorStore.highlight.active = false;
	};

	selectText = (id) => {
		this.editorStore.showTextEditor = true;

		let node = this.editorStore.nodeData[id];
		this.setNodeProperty('opacity', 0);
		// if (node) {
		// 	node.opacity = 0;
		// }
	};

	getKonvaNode = (id) => {
		this.getNode(id);
	};

	moveNodePosition = (direction) => {
		const selectedNode = this.getSelectedNode();
		if (this.getSelectedNode()) {
			const move = {
				left: () => this.setNodeProperty('x', selectedNode.x() - 1),
				right: () => this.setNodeProperty('x', selectedNode.x() + 1),
				up: () => this.setNodeProperty('y', selectedNode.y() - 1),
				down: () => this.setNodeProperty('y', selectedNode.y() + 1)
			};

			move[direction]();
		}
	};

	copyNode = (e) => {
		const selectedNode = this.getSelectedNode();
		if (selectedNode && !this.editorStore.showTextEditor) {
			if (e) {
				e.preventDefault();
			}
			let copiedNode = selectedNode.clone();
			if (selectedNode.attrs.type === 'image') {
				copiedNode.attrs.image = selectedNode.attrs.image;
			}
			this.editorStore.clipboard = copiedNode.attrs;
		} else {
			this.editorStore.clipboard = null;
		}
	};

	pasteNode = (e) => {
		if (this.editorStore.clipboard && !this.editorStore.showTextEditor) {
			if (e) {
				e.preventDefault();
			}
			const clonedNode = this.editorStore.clipboard;
			this.addNode(clonedNode.type, clonedNode);
		}
	};

	generateNodeId = (type) => {
		return `${type}-node-${uuidv4()}`;
	};

	deleteNode = () => {
		if (this.editorStore.selectedNodes && !this.editorStore.showTextEditor) {
			const keys = this.editorStore.selectedNodes;
			this.selectNodes([]);
			keys.forEach((key) => {
				this.onHighlightOut();
				this.getTransformer().hide();
				if (key.indexOf('background-image') > -1) {
					delete this.editorStore.backgroundData;
				} else {
					delete this.editorStore.nodeData[key.replace('#', '')];
				}
			});

			this.getStage().current.draw();
			this.addToHistory();
		}
	};

	download = async (evt) => {
		var dataURL = this.generatePreview();
		const name = this.editorStore.documentName;
		this.downloadURI(dataURL, `${name}.png`);
	};

	//REFERENCING THEIR EXMAPLE PROBABLY A BETTER WAY TO DO THIS
	downloadURI = (uri, name) => {
		var link = document.createElement('a');
		link.download = name;
		link.href = uri;
		link.classList.add('external'); //F7 Requires this
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	async getImageBlob(src) {
		return await fetch(src).then((r) => r.blob());
	}

	save = async () => {
		let formData = new FormData();
		for (let [key, image] of Object.entries(this.editorStore.nodeData)) {
			if (key.includes('image')) {
				const currentImgUrl = image.image.src;
				let imageUrl = image.image.src;
				if (imageUrl.indexOf('data:image') < 0) {
					imageUrl = await ExpressAPI.getCanvasAsset(image.fileKey);
				}
				formData.append('images[]', await this.getImageBlob(imageUrl), key);
			}
		}

		if (
			this.editorStore.backgroundData &&
			this.editorStore.backgroundData.image &&
			!_.isEmpty(this.editorStore.backgroundData.image.src)
		) {
			let bgUrl = this.editorStore.backgroundData.image.src;
			if (bgUrl.indexOf('data:image') < 0) {
				bgUrl = await ExpressAPI.getDocumentAsset(this.getStore().documentId, this.editorStore.backgroundData.key);
			}
			formData.append('images[]', await this.getImageBlob(bgUrl), this.editorStore.backgroundData.key);
		}

		formData.append('images[]', await this.getImageBlob(this.generatePreview(200, 0.5)), 'thumbnailSmall');
		formData.append('images[]', await this.getImageBlob(this.generatePreview(400, 0.5)), 'thumbnailMedium');
		formData.append('images[]', await this.getImageBlob(this.generatePreview(800, 0.5)), 'thumbnailLarge');

		if (this.externalSave) {
			this.externalSave(formData, this.buildSaveObject(), this.originalDoc);
		} else {
			formData.append('document', JSON.stringify(this.buildSaveObject()));

			let response;
			if (this.getStore().documentId && !this.getStore().existingAsTemplate) {
				response = await ExpressAPI.updateIdCard(formData, this.getStore().documentId);
			} else {
				response = await ExpressAPI.saveIdCard(formData);
			}

			if (response) {
				this.editorStore.documentName = response.name;
				this.editorStore.documentId = response._id;
			}

			if (this.saveCallback) {
				this.saveCallback();
			}
		}
	};

	buildSaveObject = () => {
		let document = {
			documentId: this.editorStore.documentId,
			name: this.editorStore.documentName,
			width: this.editorStore.documentWidth,
			height: this.editorStore.documentHeight,
			background: this.editorStore.backgroundData || {},
			side: this.editorStore.documentSide,
			nodes: this.editorStore.nodeData,
			customFields: this.editorStore.customFields
		};
		return document;
	};

	//ALL FOR POC REFACTOR LATER
	generatePreview = (size, quality) => {
		const newStage = this.getDownloadStage().current;

		const mainGroup = this.getMainGroup().current.clone();
		mainGroup.x(0);
		mainGroup.y(0);

		let layer = new Konva.Layer();
		layer.x(0);
		layer.y(0);
		newStage.add(layer);
		layer.add(mainGroup.clone());

		const pixelRatio = size ? size / this.editorStore.documentWidth : 0.5;
		const imgQuality = quality ? quality : 1;

		newStage.visible(true);
		const dataURL = newStage.toDataURL({ mimeType: 'image/jpeg', pixelRatio: pixelRatio, quality: imgQuality });

		newStage.clear();
		newStage.destroyChildren();
		newStage.draw();

		newStage.visible(false);

		return dataURL;
	};

	getFonts = () => {
		return this.fonts;
	};

	createFontLink = () => {
		const families = this.fonts
			.reduce((acc, font) => {
				const family = font.font.replace(/ +/g, '+');
				const weights = (font.weights || []).join(',');

				return [...acc, family + (weights && `:${weights}`)];
			}, [])
			.join('|');

		return `https://fonts.googleapis.com/css?family=${families}`;
	};

	selectNodes = (nodes) => {
		if (!nodes || nodes.length < 1) {
			this.editorStore.selectMode = SELECT_MODE.EMPTY;
			this.editorStore.selectedNodes = null;
		} else if (nodes.length === 1 && !nodes[0].includes('background')) {
			this.editorStore.selectedNodes = nodes;
			this.editorStore.selectMode = SELECT_MODE.SINGLE;
		} else {
			this.editorStore.selectedNodes = nodes;
			this.editorStore.selectMode = SELECT_MODE.MIXED;
		}
	};

	setBackground = (image) => {
		if (!image) {
			this.editorStore.backgroundData = null;
			return;
		}
		let nodeKey = uuidv4();
		const layer = this.getMainGroup().current;
		const nodeId = `background-image-node-${nodeKey}`;

		this.editorStore.backgroundData = {
			key: nodeId,
			type: 'image',
			id: `${nodeId}`,
			name: 'background',
			x: (layer.width() - image.width) / 2,
			y: (layer.height() - image.height) / 2,
			image: image,
			width: image.width,
			height: image.height,
			visible: true,
			onDragEnd: (e) => {
				let { x, y, name } = e.target.attrs;
				this.editorStore.backgroundData.x = x;
			},
			onDragMove: (e) => this.onDrag(e),
			onTransform: (e) => {
				this.onNodeTransform(e);
			},
			onTransformEnd: (e) => {
				this.onBackgroundTransformEnd(e);
			},
			getKonvaNode: () => this.getNode(nodeId)
		};
	};

	addNodes = (nodes) => {
		for (const [key, value] of Object.entries(nodes)) {
			this.addNode(value.type, value);
		}
	};

	addNode = (type, data) => {
		let nodeKey = uuidv4();
		const layer = this.getMainGroup().current;

		const nodeId = `${type}-node-${nodeKey}`;
		data.getKonvaNode = () => this.getNode(nodeId);
		data.zIndex = 1;
		switch (type) {
			case 'variable':
				{
					this.editorStore.nodeData[nodeId] = {
						type: 'variable',
						x: (layer.width() - 150) / 2,
						y: (layer.height() - 18) / 2,
						wrap: 'word',
						width: 150,
						text: '[VARIABLE]',
						fontFamily: 'Open Sans',
						fontSize: 18,
						fontStyle: 'normal',
						lineHeight: 1,
						height: 'auto',
						textDecoration: '',
						align: 'left',
						fill: '#000',
						draggable: true,
						visible: true,
						...data,
						id: nodeId,
						name: nodeId,
						key: nodeId
					};
				}
				break;
			case 'text':
				{
					this.editorStore.nodeData[nodeId] = {
						type: 'text',
						x: (layer.width() - 150) / 2,
						y: (layer.height() - 18) / 2,
						wrap: 'word',
						text: 'Sample Text',
						width: 150,
						fontFamily: 'Open Sans',
						fontSize: 18,
						fontStyle: 'normal',
						lineHeight: 1,
						height: 'auto',
						textDecoration: '',
						align: 'left',
						fill: '#000',
						draggable: true,
						visible: true,
						...data,
						id: nodeId,
						name: nodeId,
						key: nodeId
					};
				}

				break;
			case 'circle':
				{
					this.editorStore.nodeData[nodeId] = {
						type: 'circle',
						radiusX: 75,
						radiusY: 75,
						x: layer.width() / 2,
						y: layer.height() / 2,
						fill: '#000',
						draggable: true,
						visible: true,
						...data,
						id: nodeId,
						name: nodeId,
						key: nodeId
					};
				}

				break;
			case 'image':
				{
					this.editorStore.nodeData[nodeId] = {
						type: 'image',
						x: (layer.width() - data.image.width) / 2,
						y: (layer.height() - data.image.height) / 2,
						image: data.image,
						width: data.image.width,
						height: data.image.height,
						draggable: true,
						visible: true,
						...data,
						id: nodeId,
						name: nodeId,
						key: nodeId
					};
				}
				break;
			case 'rectangle':
				{
					this.editorStore.nodeData[nodeId] = {
						type: 'rectangle',
						x: (layer.width() - data?.width || 150) / 2,
						y: (layer.height() - data?.height || 18) / 2,
						width: 150,
						height: 18,
						draggable: true,
						visible: true,
						fill: '#000000',
						...data,
						id: nodeId,
						name: nodeId,
						key: nodeId,
						cornerRadius: [4, 4, 4, 4]
					};
				}
				break;
			case 'whiteout':
				{
					this.editorStore.nodeData[nodeId] = {
						type: 'rectangle',
						name: 'background',
						draggable: true,
						visible: true,
						...data,
						id: nodeId,
						key: nodeId
					};
				}
				break;
			default:
				break;
		}

		this.addToHistory();

		this.selectNodes([`${nodeId}`]);
		this.moveNode('top');
		if (type === 'text') {
			this.selectText(nodeId);
		}

		this.getMainGroup().current.draw();
		this.getStage().current.batchDraw();
	};

	onTextNodeTransform = (e) => {
		let { id } = e.target.attrs;
		this.editorStore.nodeData[id].width = Math.round(e.target.width() * e.target.scaleX());

		e.target.setAttrs({
			width: e.target.width() * e.target.scaleX(),
			height: 'auto',
			scaleX: 1,
			scaleY: 1
		});

		this.addToHistory();
		//handle text area transform
		// this.getNode(id).height() + 2

		// this.getNode(this.editorStore.selectedNode).height = e.target.height();
	};

	onBackgroundTransformEnd = (e) => {
		let { id } = e.target.attrs;
		this.editorStore.backgroundData.width = Math.round(e.target.width() * e.target.scaleX());
		this.editorStore.backgroundData.height = Math.round(e.target.height() * e.target.scaleY());
		this.editorStore.backgroundData.x = Math.round(e.target.x());
		this.editorStore.backgroundData.y = Math.round(e.target.y());
	};

	onTransformEnd = (e) => {
		const { id } = e.target.attrs;

		if (e.target.attrs.type === 'text') {
			this.editorStore.nodeData[id].width = Math.round(e.target.width() * e.target.scaleX());
			this.editorStore.nodeData[id].height = Math.round(e.target.height() * e.target.scaleY()) + 2;
		} else if (e.target.attrs.type === 'circle') {
			this.editorStore.nodeData[id].radiusX = Math.round(e.target.radiusX() * e.target.scaleX());
			this.editorStore.nodeData[id].radiusY = Math.round(e.target.radiusY() * e.target.scaleY());
		} else {
			this.editorStore.nodeData[id].width = Math.round(e.target.width() * e.target.scaleX());
			this.editorStore.nodeData[id].height = Math.round(e.target.height() * e.target.scaleY());
		}

		this.editorStore.nodeData[id].x = Math.round(e.target.x());
		this.editorStore.nodeData[id].y = Math.round(e.target.y());
		this.addToHistory();
	};

	onDragEnd = (e) => {
		let { x, y, id } = e.target.attrs;
		this.editorStore.nodeData[id].x = parseInt(x);
		this.editorStore.nodeData[id].y = parseInt(y);

		this.addToHistory();
	};

	onNodeTransform = (e) => {
		let { id, type } = e.target.attrs;
		const newWidth = e.target.width() * e.target.scaleX();
		const newHeight = e.target.height() * e.target.scaleY();
		e.target.setAttrs({
			width: newWidth,
			height: newHeight,
			scaleX: 1,
			scaleY: 1
		});
		if (id.indexOf('background-image') > -1) {
			this.editorStore.backgroundData.width = newWidth;
			this.editorStore.backgroundData.height = newHeight;
		} else {
			this.editorStore.nodeData[id].width = newWidth;
			this.editorStore.nodeData[id].height = newHeight;
		}
		this.addToHistory();
	};

	onCircleTransform = (e) => {
		const { id } = e.target.attrs;

		this.editorStore.nodeData[id].radiusX = e.target.radiusX() * e.target.scaleX();
		this.editorStore.nodeData[id].radiusY = e.target.radiusY() * e.target.scaleY();

		this.addToHistory();
	};

	onTextNodeEditorBlur = (e) => {
		let editorStore = this.getStore();
		this.setNodeProperty('opacity', 1);
		editorStore.showTextEditor = false;
		editorStore.nodeData[e.target.id].text = e.target.value;
		this.getMainGroup().current.draw();
		this.addToHistory();
	};

	loadSavedEditor = (doc) => {
		doc = _.cloneDeep(doc);
		if (doc.nodes) {
			Object.keys(doc.nodes).map((k) => {
				doc.nodes[k].getKonvaNode = () => this.getNode(doc.nodes[k].id);
			});
		}
		this.originalDoc = doc;
		this.isDefault = doc.defaultCard;
		this.reset(
			doc.name,
			doc._id,
			doc.width,
			doc.height,
			doc.side,
			doc.nodes,
			doc.background,
			doc.existingAsTemplate,
			doc.customFields
		);
	};

	isMac = () => {
		return navigator.platform.includes('Mac');
	};

	draw = () => {
		this.getMainGroup().current.draw();
	};

	getDimension(dimensionId) {
		return DIMENSIONS[dimensionId];
	}

	createNew(newCardData) {
		const dimension = this.getDimension(newCardData.documentSize);
		let backgroundData = {};
		if (newCardData.image && newCardData.imageSettings) {
			let nodeKey = uuidv4();
			const nodeId = `background-image-node-${nodeKey}`;
			backgroundData = {
				key: nodeId,
				type: 'image',
				id: `${nodeId}`,
				name: 'background',
				x: newCardData.imageSettings.x,
				y: newCardData.imageSettings.y,
				image: newCardData.image,
				width: newCardData.imageSettings.width,
				height: newCardData.imageSettings.height,
				visible: true,
				onDragEnd: (e) => {
					let { x, y, name } = e.target.attrs;
					this.editorStore.backgroundData.x = x;
				},
				onDragMove: (e) => this.onDrag(e),
				onTransform: (e) => {
					this.onNodeTransform(e);
				},
				onTransformEnd: (e) => {
					this.onBackgroundTransformEnd(e);
				},
				getKonvaNode: () => this.getNode(nodeId)
			};
		}
		this.reset(
			newCardData.documentName,
			null,
			dimension.width,
			dimension.height,
			newCardData.documentSide,
			{},
			backgroundData,
			false,
			newCardData.customFields
		);
	}

	createNewTemplate(newTemplateData) {
		const { name, width, height, tags } = newTemplateData;
		this.reset(name, null, width, height);
	}
}
