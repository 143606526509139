import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './flip-card.scss';

@observer
export default class FlipCard extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			dragging: false,
			startPos: 0,
			currentPos: 0,
			currentRotate: 0,
			prevRotate: 0,
			currentSide: 'front'
		});
		autoBind(this);
		this.flipCardRef = React.createRef();
	}

	swipeAxis = {
		horizontal: 'x',
		vertical: 'y'
	};

	flipAxis = {
		horizontal: 'y',
		vertical: 'x'
	};

	componentDidUpdate() {
		const { flipEnabled } = this.props;
		if (flipEnabled === false) {
			this.data.currentPos = 0;
			this.data.currentRotate = Math.abs(this.data.currentRotate) === 360 ? this.data.currentRotate : 0;
			this.data.prevRotate = 0;
			this.data.currentSide = 'front';
			this.data.startPos = 0;
			this.data.dragging = false;
		}
	}

	handleTouchStart(e) {
		const { flipEnabled, direction } = this.props;
		if (flipEnabled) {
			e.preventDefault();
			this.data.currentSide = this.getSide();
			this.data.startPos = this.getPosition(e);
			this.data.dragging = true;

			this.data.prevRotate = Math.abs(this.data.prevRotate) == 360 ? 0 : this.data.prevRotate; // Reset loop
			this.data.currentRotate = Math.abs(this.data.currentRotate) == 360 ? 0 : this.data.currentRotate; // Reset loop
		}
	}

	handleTouchMove(e) {
		const { flipEnabled, direction, width, height } = this.props;
		const { dragging, startPos } = this.data;

		if (flipEnabled && dragging) {
			e.preventDefault();
			const ratio = 180 / (direction === 'horizontal' ? parseInt(width) : parseInt(height));
			const currentPosition = this.getPosition(e);
			const rotate = (currentPosition - startPos) * ratio;
			this.data.currentRotate = this.data.prevRotate + rotate;
			this.data.currentPos = currentPosition;
		}
	}

	handleTouchEnd(e) {
		const { flipEnabled, flipOnClick } = this.props;
		const { dragging, startPos } = this.data;

		e.preventDefault();
		if (!flipEnabled && e.type !== 'mouseleave') {
			this.props.onClick();
		} else {
			if (dragging) {
				const touchEndPos = this.getPosition(e);
				const distance = touchEndPos - startPos;
				const swipeDirection = distance < 0 ? 'back' : 'forward';
				this.data.dragging = false;
				if (Math.abs(distance) > 20) {
					this.flip(swipeDirection);
				} else {
					if (flipOnClick) {
						this.clickFlip(e);
					}
				}
			}
		}
	}

	handleBtnFlip(e) {
		this.data.prevRotate = Math.abs(this.data.prevRotate) == 360 ? 0 : this.data.prevRotate; // Reset loop
		this.data.currentRotate = Math.abs(this.data.currentRotate) == 360 ? 0 : this.data.currentRotate; // Reset loop
		this.clickFlip(e);
	}

	clickFlip(e) {
		e.preventDefault();
		const { direction } = this.props;
		const container = this.flipCardRef.current.getBoundingClientRect();
		const axis = this.flipAxis[direction];
		const tapPos = this.getPosition(e);
		const offset = container[axis];
		const length = direction === 'horizontal' ? container.width : container.height;
		const absoluteTap = tapPos - offset;
		this.flip(absoluteTap > length / 2 ? 'forward' : 'backward');
	}

	flip(flipDirection) {
		const { onFlip } = this.props;
		const { prevRotate } = this.data;

		this.data.currentRotate = flipDirection === 'forward' ? prevRotate + 180 : prevRotate - 180;
		this.data.prevRotate = this.data.currentRotate;
		this.data.currentSide = this.getSide();

		if (onFlip) onFlip(this.data.currentSide);
	}

	getPosition(e) {
		const { direction } = this.props;
		const axis = this.swipeAxis[direction];
		if (e.type === 'touchend') return e.changedTouches[0][`client${axis.toUpperCase()}`];
		if (e.type === 'click') return e[`page${axis.toUpperCase()}`];
		return e.type.includes('mouse') ? e[`page${axis.toUpperCase()}`] : e.touches[0][`client${axis.toUpperCase()}`];
	}

	getSide() {
		const sides = ['front', 'back'];
		return sides[Math.abs((this.data.currentRotate / 180) % 2)];
	}

	getFlipPercent() {
		return Math.abs((this.data.currentRotate - this.data.prevRotate) / 180);
	}

	render() {
		const { front, back, width, height, direction, style, controls, flipEnabled } = this.props;
		const { currentRotate, dragging, currentSide } = this.data;

		const axis = this.flipAxis[direction];
		return (
			<div className="flip-card-container">
				<div
					className="flip-card vbox hcenter"
					ref={this.flipCardRef}
					style={{
						width: width,
						height: height,
						...style
					}}
					onMouseDown={this.handleTouchStart}
					onMouseMove={this.handleTouchMove}
					onMouseUp={this.handleTouchEnd}
					onMouseLeave={this.handleTouchEnd}
					onTouchMove={this.handleTouchMove}
					onTouchEnd={this.handleTouchEnd}
					onTouchStart={this.handleTouchStart}
				>
					<div
						className={`flip-card-faces ${dragging ? '' : 'flipAnimate'} `}
						style={{
							width: width,
							height: height,
							transform: `rotate${axis}(${currentRotate}deg)`
						}}
						draggable="true"
					>
						<div className="flip-card-face front">{front}</div>
						<div
							className="flip-card-face back"
							style={{
								transform: `rotate${axis}(${180}deg)`
							}}
						>
							{back}
						</div>
					</div>
				</div>
				{controls && flipEnabled && (
					<div className="flip-controls-container hbox hcenter" onClick={this.handleBtnFlip}>
						<div
							className={`flip-controls  ${dragging ? '' : 'flipAnimate'} `}
							style={{
								transform: `rotate${axis}(${currentRotate}deg)`
							}}
						>
							<div className={`ctrl-btn front hbox vcenter hcenter ${currentSide === 'front' ? 'active' : ''}`}>
								<i className="fa fa-repeat"></i>
							</div>
							<div
								className={`ctrl-btn backside hbox vcenter hcenter ${currentSide === 'back' ? 'active' : ''}`}
								style={{
									transform: `rotate${axis}(${180}deg)`
								}}
							>
								<i className="fa fa-repeat"></i>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
