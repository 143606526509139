import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../utils/State';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import './feedback-management.scss';
import CloudAPI from '../../../services/CloudAPI';
import { format } from 'date-fns';
import { List, ListInput, Button, Popup, Link, Segmented } from 'framework7-react';

//TODO mande global enums
const FeedbackStatus = {
	NEW: 'New' || '',
	REVIEWING: 'Reviewing',
	COMPLETED: 'Completed'
};

@observer
class FeedbackManagement extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			New: [],
			reviewing: [],
			completed: [],
			detailsPopupOpen: false,
			showDetailsPopup: false,
			showFeedbackComments: false,
			activeFeedback: null,
			activeUser: null,
			activeProvider: null,
			tgglType: 'user',
			showDocViewer: false,
			comments: '',
			feedbackStatus: ''
		});
		this.text = getCmpText('FeedbackManagement', {
			cancel: 'Cancel',
			reviewing: 'Reviewing',
			complete: 'Complete',
			reopen: 'Re-Open',
			inactiveUser: 'USER DOES NOT EXIST'
		});
		autoBind(this);
	}

	componentDidMount() {
		console.log('Feedback Management Page Mounted');
		this.fetchFeedbacks();
	}

	fetchFeedbacks() {
		return CloudAPI.getFeedbacks()
			.then((res) => {
				let New = [];
				let reviewing = [];
				let completed = [];

				_.forEach(res, (feedback) => {
					if (feedback.status === FeedbackStatus.REVIEWING) {
						reviewing.push(feedback);
					} else if (feedback.status === FeedbackStatus.COMPLETED) {
						completed.push(feedback);
					} else {
						New.push(feedback);
					}
				});
				this.data.New = New;
				this.data.reviewing = reviewing;
				this.data.completed = completed;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	fetchUserData() {
		CloudAPI.getUserData(this.data.activeFeedback.userId)
			.then((res) => {
				this.data.activeUser = res;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	fetchProviderData() {
		CloudAPI.getProvider(this.data.activeFeedback.npi)
			.then((res) => {
				this.data.activeProvider = res;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	onDetailsPopupClose() {
		this.data.showDetailsPopup = false;
		this.data.detailsPopupOpen = false;
		this.data.showFeedbackComments = false;
		this.data.feedbackStatus = '';
		this.data.comments = '';
		this.data.activeAppt = null;
		this.data.activeUser = null;
		this.data.tgglType = 'user';
	}

	onUpdateFeedbackStatus() {
		const requestData = {
			comments: this.data.comments,
			status: this.data.feedbackStatus,
			id: this.data.activeFeedback._id
		};
		CloudAPI.updateFeedbackStatus(requestData)
			.then(() => {
				this.onDetailsPopupClose();
				this.fetchFeedbacks().then(() => {
					this.$f7.dialog.close();
				});
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	onFeedbackReviewing() {
		this.data.feedbackStatus = FeedbackStatus.REVIEWING;
		this.data.showFeedbackComments = true;
	}

	onFeedbackComplete() {
		this.data.feedbackStatus = FeedbackStatus.COMPLETED;
		this.data.showFeedbackComments = true;
	}

	updateComment(evt) {
		const value = evt.currentTarget.value;
		this.data.comments = value;
	}

	clearMessage() {
		this.data.comments = '';
	}

	getTable(items, h1, h2, h3) {
		return (
			<div className="data-table">
				<table>
					<thead>
						<tr>
							<th>{h1}</th>
							<th>{h2}</th>
							<th>{h3}</th>
						</tr>
					</thead>
					<tbody>{items}</tbody>
				</table>
			</div>
		);
	}

	getTableRows(feedback, col1, col2, col3, btnText, clickHandler) {
		return (
			<tr className="feedback-item" key={feedback._id}>
				<td className="user">{col1}</td>
				<td className="type">{col2}</td>
				<td className="time">{col3}</td>
				<td>
					<Button round small fill={btnText === 'Review' ? true : false} onClick={clickHandler}>
						{btnText}
					</Button>
				</td>
			</tr>
		);
	}

	getActionBtns() {
		let activeFeedback = _.get(this.data, 'activeFeedback', {}) || {};
		if (activeFeedback.status === FeedbackStatus.COMPLETED) {
			return (
				<>
					<Button fill round onClick={this.onFeedbackReviewing} color="purple">
						{this.text.reopen}
					</Button>
				</>
			);
		} else {
			return (
				<>
					<Button fill round onClick={this.onFeedbackReviewing} color="purple">
						{this.text.reviewing}
					</Button>
					<Button fill round onClick={this.onFeedbackComplete} color="green">
						{this.text.complete}
					</Button>
				</>
			);
		}
	}

	openFeedbackItem(feedback) {
		this.data.activeFeedback = feedback;
		this.data.detailsPopupOpen = true;
		this.fetchUserData();
		if (feedback.type === 'BAD_DATA') {
			this.fetchProviderData();
		}
	}

	buildLists() {
		let { New, reviewing, completed } = this.data;
		let reqItems = [];
		let revItems = [];
		let compItems = [];
		let reqs = _.cloneDeep(New).sort((a, b) => {
			return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
		});
		_.forEach(reqs, (feedback) => {
			let activeUser = feedback.user[0] ? true : false;
			let userName = activeUser ? `${feedback.user[0].firstName} ${feedback.user[0].lastName}` : this.text.inactiveUser;
			reqItems.push(
				this.getTableRows(feedback, userName, feedback.type, format(new Date(feedback.createdDate), 'MM/dd/yyyy p'), 'Review', () => {
					this.openFeedbackItem(feedback);
				})
			);
		});
		_.forEach(reviewing, (feedback) => {
			let activeUser = feedback.user[0] ? true : false;
			let userName = activeUser ? `${feedback.user[0].firstName} ${feedback.user[0].lastName}` : this.text.inactiveUser;
			revItems.push(
				this.getTableRows(
					feedback,
					userName,
					feedback.type,
					feedback.updatedDate
						? format(new Date(feedback.updatedDate), 'MM/dd/yyyy p')
						: format(new Date(feedback.createdDate), 'MM/dd/yyyy p'),
					'View Details',
					() => {
						this.openFeedbackItem(feedback);
					}
				)
			);
		});
		_.forEach(completed, (feedback) => {
			let activeUser = feedback.user[0] ? true : false;
			let userName = activeUser ? `${feedback.user[0].firstName} ${feedback.user[0].lastName}` : this.text.inactiveUser;
			compItems.push(
				this.getTableRows(
					feedback,
					userName,
					feedback.type,
					feedback.updatedDate
						? format(new Date(feedback.updatedDate), 'MM/dd/yyyy p')
						: format(new Date(feedback.createdDate), 'MM/dd/yyyy p'),
					'View Details',
					() => {
						this.openFeedbackItem(feedback);
					}
				)
			);
		});

		return (
			<List>
				<div className="list-header vbox vcenter hcenter blue">
					Latest Feedback <h5>(Newest First)</h5>
				</div>
				{reqItems.length > 0 ? this.getTable(reqItems, 'User', 'Type', 'Created Time') : <div className="empty">No new feedback.</div>}
				<div className="list-header purple">Feedback In Review</div>
				{revItems.length > 0 ? (
					this.getTable(revItems, 'User', 'Type', 'Updated Date')
				) : (
					<div className="empty">No feedback being reviewed.</div>
				)}
				<div className="list-header green">Completed Feedback</div>
				{compItems.length > 0 ? (
					this.getTable(compItems, 'User', 'Type', 'Updated Date')
				) : (
					<div className="empty">No completed feedback.</div>
				)}
			</List>
		);
	}

	getPrettyObject(data, field) {
		return (
			<pre>
				{JSON.stringify(_.get(data, field, {}), null, 2)
					.split('"')
					.join('')
					.replace('{', '')
					.replace('}', '')
					.replace('[', '')
					.replace(']', '')}
			</pre>
		);
	}

	handleInfoField(data, field) {
		try {
			if (field === 'dob' || (field.toLowerCase().indexOf('date') >= 0 && typeof data[field] !== 'object')) {
				return format(new Date(_.get(data, `${field}`, '')), 'MM/dd/yyyy');
			}
			if (field === 'billingAddress') {
				try {
					return this.getPrettyObject(data, 'billingAddress');
				} catch (err) {
					console.log(err);
					return JSON.stringify(_.get(data, 'billingAddress', {}), null, 2);
				}
			}
			if (typeof data[field] === 'object') {
				return this.getPrettyObject(data, field);
			}
		} catch (err) {
			console.log(`Error on field : ${field}`);
		}
		if (_.isEmpty(_.get(data, field))) {
			if (_.isNumber(data[field])) {
				return data[field];
			}
			return 'N/A';
		}
		return _.get(data, `${field}`, 'N/A').toString();
	}

	buildUserInfo() {
		let items = [];
		let feedback = _.get(this.data, 'activeUser', {});
		let excluded = [
			'_id',
			'dependents',
			'relation',
			'insurerCode',
			'startDate',
			'termDate',
			'coverageType',
			'devices',
			'firebaseUid',
			'hash',
			'__v',
			'lastLogin'
		];
		if (feedback) {
			_.forEach(Object.keys(_.get(feedback, `user`, {})), (field) => {
				if (excluded.indexOf(field) < 0) {
					items.push(
						<div key={`lv-pair-${Math.random()}`}>
							<div className="label">{field}:</div>
							<div className={`field ${field.split(' ').join('')}`}>{this.handleInfoField(feedback.user, field)}</div>
						</div>
					);
				}
			});
			items.push(
				<div className="user-data" key="other-user-data">
					<div className="label">Network:</div>
					<div className="field">{_.get(this, 'data.activeUser.group.healthNetworkName', 'Unknown')}</div>
				</div>
			);
			return <div className="info-data y-scroll">{items}</div>;
		} else {
			return <div className="feedback-inactive-user vbox vcenter hcenter blue">{this.text.inactiveUser}</div>;
		}
	}

	buildProviderInfo() {
		let items = [];
		let providerInfo = _.get(this.data, 'activeProvider', {}) || {};
		let excluded = [
			'_id',
			'isPerson',
			'lastUpdated',
			'geoLocation',
			'latitude',
			'longitude',
			'fullName',
			'locations',
			'rawSpecialties',
			'fromNPI'
		];

		if (providerInfo.length > 1) {
			_.forEach(providerInfo, (field) => {
				items.push(
					<div key={`lv-pair-${Math.random()}`}>
						<div className="label">Providers:</div>
						<div className="field">{field.fullName}</div>
					</div>
				);
			});
		} else {
			if (providerInfo && providerInfo[0]) {
				_.forEach(Object.keys(providerInfo[0]), (field) => {
					if (excluded.indexOf(field) < 0) {
						items.push(
							<div key={`lv-pair-${Math.random()}`}>
								<div className="label">{field}:</div>
								<div className="field">{this.handleInfoField(providerInfo[0], field)}</div>
							</div>
						);
					}
				});
			}
		}

		return <div className="info-data y-scroll">{items}</div>;
	}

	buildRequestInfo() {
		let feedback = _.get(this.data, 'activeFeedback', {});
		let items = [];
		let excluded = ['_id', '__v', 'userId', 'user'];
		_.forEach(Object.keys(feedback), (field) => {
			if (excluded.indexOf(field) < 0) {
				items.push(
					<div key={`lv-pair-${Math.random()}`}>
						<div className="label">{field}:</div>
						<div className="field">{this.handleInfoField(feedback, field)}</div>
					</div>
				);
			}
		});

		return <div className="info-data y-scroll">{items}</div>;
	}

	getInfoContent() {
		switch (this.data.tgglType) {
			case 'user': {
				return this.buildUserInfo();
			}
			case 'provider': {
				return this.buildProviderInfo();
			}
			case 'request': {
				return this.buildRequestInfo();
			}
		}
	}

	buildDetailsPopup() {
		let feedback = _.get(this.data, 'activeFeedback', {});
		switch (feedback.type) {
			case 'BAD_DATA': {
				return this.buildBadDataPopup();
			}
			case 'REFUND_TOKEN': {
				return this.buildUserPopup();
			}
			default:
				return this.buildUserPopup();
		}
	}

	buildUserPopup() {
		let { detailsPopupOpen, tgglType } = this.data;
		return (
			<div className="feedback-content">
				<Segmented raised round small className="info-toggle">
					<Button
						round
						small
						active={tgglType === 'user'}
						onClick={() => {
							this.data.tgglType = 'user';
						}}
					>
						User
					</Button>
					<Button
						round
						small
						active={tgglType === 'request'}
						onClick={() => {
							this.data.tgglType = 'request';
						}}
					>
						Request
					</Button>
				</Segmented>
				{detailsPopupOpen && this.getInfoContent()}
				<div className="btn-ctn hbox vcenter hcenter">{this.getActionBtns()}</div>
			</div>
		);
	}

	buildBadDataPopup() {
		let { detailsPopupOpen, tgglType } = this.data;
		return (
			<div className="feedback-content">
				<Segmented raised round small className="info-toggle">
					<Button
						round
						small
						active={tgglType === 'user'}
						onClick={() => {
							this.data.tgglType = 'user';
						}}
					>
						User
					</Button>
					<Button
						round
						small
						active={tgglType === 'provider'}
						onClick={() => {
							this.data.tgglType = 'provider';
						}}
					>
						Provider
					</Button>
					<Button
						round
						small
						active={tgglType === 'request'}
						onClick={() => {
							this.data.tgglType = 'request';
						}}
					>
						Request
					</Button>
				</Segmented>
				{detailsPopupOpen && this.getInfoContent()}
				<div className="btn-ctn hbox vcenter hcenter">{this.getActionBtns()}</div>
			</div>
		);
	}

	buildFeedbackCommentsForm() {
		try {
			return (
				<div className="feedback-comments-form">
					<div className="label">Comments:</div>
					<List className="no-hairlines date-picker-list">
						<ListInput
							label={this.text.messageLabel}
							type="textarea"
							clearButton
							floatingLabel={true}
							className="md"
							name="feedbackComment"
							value={this.data.comments}
							onChange={this.updateComment}
							onInputClear={this.clearComment}
						/>
					</List>
					<Button large fill round onClick={this.onUpdateFeedbackStatus}>
						Submit
					</Button>
					<Button
						large
						round
						onClick={() => {
							this.data.showFeedbackComments = false;
						}}
					>
						Back
					</Button>
				</div>
			);
		} catch (err) {
			console.log(err);
		}
	}

	render() {
		let { detailsPopupOpen, showFeedbackComments } = this.data;
		return (
			<div className="feedback-mgmt-page">
				{this.buildLists()}
				<Popup opened={detailsPopupOpen} className="feedback-popup" onPopupClosed={this.onDetailsPopupClose}>
					<div className="detailspopup-header hbox vcenter hright">
						<h2 className="grow-1">{'Review Feedback'}</h2>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					{detailsPopupOpen && !showFeedbackComments && this.buildDetailsPopup()}
					{detailsPopupOpen && showFeedbackComments && <div className="feedback-content">{this.buildFeedbackCommentsForm()}</div>}
				</Popup>
			</div>
		);
	}
}
export default FeedbackManagement;
