import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { observer } from '@/utils/State';
import { Popup, Navbar, NavRight, Link } from 'framework7-react';
import appStore from '@/stores/AppStore';
import ExternalService from '@/services/ExternalService';
import './external-service-message.scss';

@observer
export default class ExternalServiceMessage extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getMessageContents() {
		if (!appStore.showExternalServiceMessage) {
			return <span />;
		}
		const messagePrep = _.get(appStore, 'externalServiceMessage.message') || '';
		const message = messagePrep.replace(/<p>|\\n/g, '\n'); //noticing some variation between the way this is handled
		const icon = _.get(appStore, 'externalServiceMessage.icon');
		const urlCheck = new RegExp('https?:\\/\\/[^\\s]+');
		const phoneCheck = new RegExp('[0-9]{10}');
		const simpleEmailCheck = new RegExp('[^@\\s]+@[^@\\s]+\\.[^@\\s]+');
		const newLineCheck = new RegExp(/[\r\n]/);
		const messageLines = message.split(newLineCheck);
		return (
			<div className="message-body y-scroll">
				<div className="icon-container">{icon && <i className={icon} />}</div>
				{messageLines.map((messageLine) => {
					const messageRegex = new RegExp(`(${urlCheck.source}|${phoneCheck.source}|${simpleEmailCheck.source}|${newLineCheck})`, 'g');
					const messageParts = messageLine.split(messageRegex);
					return (
						<div className="new-line">
							{messageParts.map((messageSegment, index) => {
								const isUrl = urlCheck.test(messageSegment);
								const isPhone = phoneCheck.test(messageSegment);
								const isEmail = simpleEmailCheck.test(messageSegment);
								const isNewLine = newLineCheck.test(messageSegment);
								const hasClickHandling = isUrl || isPhone || isEmail;
								if (hasClickHandling) {
									const type = isPhone ? 'call' : isEmail ? 'email' : 'web';
									const displayData = isPhone ? ExternalService.getFormattedPhoneNumber(messageSegment) : messageSegment;
									return (
										<span
											key={`message-string-${index}`}
											className="message-link"
											onClick={() => ExternalService.handleExternalRequest(type, messageSegment)}
										>
											{displayData}
										</span>
									);
								}
								return (
									<span className={`${isNewLine ? 'new-line' : ''}`} key={`message-string-${index}`}>
										{messageSegment}
									</span>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	}

	handleMessageClosed(evt) {
		appStore.showExternalServiceMessage = false;
	}

	render() {
		const title = _.get(appStore, 'externalServiceMessage.title') || '';
		return (
			<div className="external-services-message-container">
				<Popup
					className="external-services-message-modal"
					opened={appStore.showExternalServiceMessage}
					onPopupClosed={this.handleMessageClosed}
				>
					<Navbar title={title} className="message-navbar">
						<NavRight>
							<Link popupClose className="message-close">
								<i className="fad fa-times-square" />
							</Link>
						</NavRight>
					</Navbar>
					<div className="message-content">{this.getMessageContents()}</div>
				</Popup>
			</div>
		);
	}
}
