import React, { Component } from 'react';
import { Gauge, Preloader } from 'framework7-react';

import { observable, observer } from '@/utils/State';
import CloudAPI from '@/services/CloudAPI';
import userStore from '@/stores/UserStore';
import ConfigService from '@/services/ConfigService';
import './provider-scores.scss';

@observer
export default class ProviderScores extends Component {
	constructor(props) {
		super(props);

		this.data = observable({
			isLoading: true,
			cost: null,
			quality: null,
			overall: null
		});
	}

	componentDidMount() {
		// Fetch providerscores on component mount and update state variables
		const membersOrgId = userStore?.userData?.member?.orgId;
		CloudAPI.getProviderScores(this.props.npi, membersOrgId).then((res) => {
			if (res) {
				this.data.isLoading = false;
				this.data.cost = res?.cost || null;
				this.data.quality = res?.quality || null;
				this.data.overall = res?.overall_score || null;
				return;
			}

			if (!res) return (this.data.isLoading = false);
		});
	}

	deriveIsCostAndQualityFeatureOn = () => ConfigService.getConfigValue('features.costAndQuality', false);

	deriveGuageColor = (score) => {
		const s = Math.ceil(score);
		const colorMatrix = {
			1: '#ff4242',
			2: '#ff5e00bb',
			3: '#9acd32',
			4: '#277b52',
			5: '#00e676'
		};

		return colorMatrix[s.toString()];
	};

	deriveScoreGauges = (cost, quality, overall) => {
		return (
			<>
				{(!cost || !quality) && overall && (
					<div className="provider-scores-gauge">
						<Gauge
							type="semicircle"
							value={overall / 5}
							valueText={`${overall}/ 5`}
							valueTextColor={this.deriveGuageColor(overall)}
							valueFontSize={24}
							valueFontWeight={800}
							borderWidth={8}
							borderColor={this.deriveGuageColor(overall)}
						/>
						<span>Overall Score</span>
					</div>
				)}
				{cost && (
					<div className="provider-scores-gauge">
						<Gauge
							type="semicircle"
							value={cost / 5}
							valueText={`${cost}/ 5`}
							valueTextColor={this.deriveGuageColor(cost)}
							valueFontSize={24}
							valueFontWeight={800}
							borderWidth={8}
							borderColor={this.deriveGuageColor(cost)}
						/>
						<span>Cost Score</span>
					</div>
				)}
				{quality && (
					<div className="provider-scores-gauge">
						<Gauge
							type="semicircle"
							value={quality / 5}
							valueText={`${quality}/ 5`}
							valueTextColor={this.deriveGuageColor(quality)}
							valueFontSize={24}
							valueFontWeight={800}
							borderWidth={8}
							borderColor={this.deriveGuageColor(quality)}
						/>
						<span>Quality Score</span>
					</div>
				)}
			</>
		);
	};

	render() {
		const { isLoading, cost, quality, overall } = this.data;

		if (!isLoading && !cost && !quality && !overall) return null;
		if (!this.deriveIsCostAndQualityFeatureOn()) return null;

		return (
			<div className="provider-scores-gauge-content">
				{isLoading ? (
					<Preloader color="blue" size="26" />
				) : (
					<div className="provider-scores-gauge-wrapper">{this.deriveScoreGauges(cost, quality, overall)}</div>
				)}
			</div>
		);
	}
}
