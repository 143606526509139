import { autoBind } from '@/utils/GeneralUtils';
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import './project-background.scss';
import CanvasFactory from '@/factories/CanvasFactory';
@observer
export default class ProjectBackground extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.hiddenFile = React.createRef();
		autoBind(this);
	}

	handleChangeFile = (file) => {
		const { setBackground } = CanvasFactory.getActiveService();
		let fileData = new FileReader();

		fileData.onloadend = (e) => {
			const content = e.target.result;
			let image = new Image();
			image.src = content;
			image.onload = (e) => {
				setBackground(image);
				// console.log(image.width)
			};

			this.hiddenFile.current.value = '';
		};
		fileData.readAsDataURL(file);
	};

	handleBackgroundSet(e) {
		this.hiddenFile.current.click();
	}

	renderBackgroundSettings() {
		const {
			editorStore: { backgroundData, backgroundEdit },
			toggleBackgroundEdit
		} = CanvasFactory.getActiveService();
		return (
			<div>
				<img src={backgroundData.image.src} width="100%" />
				<div className="btn-container hbox">
					<div className="btn hbox vcenter hcenter" onClick={this.handleBackgroundSet}>
						<i className="fad fa-exchange"></i>
						Change
					</div>
					<div className="btn hbox vcenter hcenter" onClick={() => toggleBackgroundEdit()}>
						{!backgroundEdit ? <i className="fad fa-expand-alt"></i> : <i className="fad fa-check"></i>}
						{!backgroundEdit ? 'Adjust' : 'Done'}
					</div>
				</div>
			</div>
		);
	}

	renderBackgroundBody() {
		const {
			editorStore: { backgroundData, backgroundEdit }
		} = CanvasFactory.getActiveService();
		if (backgroundData && backgroundData.image) {
			return this.renderBackgroundSettings();
		} else {
			return (
				<div className="btn hbox vcenter hcenter" onClick={this.handleBackgroundSet}>
					<i className="fad fa-images"></i>
					Choose
				</div>
			);
		}
	}

	render() {
		return (
			<div className="vbox property-section background-property">
				<div className="property-label">Background</div>
				{this.renderBackgroundBody()}
				<input
					type="file"
					ref={this.hiddenFile}
					style={{ display: 'none' }}
					onChange={(e) => this.handleChangeFile(e.target.files[0])}
				/>
			</div>
		);
	}
}
