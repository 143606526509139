import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveAreaBump } from '@nivo/bump';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './area-bump-chart.scss';

@observer
export default class AreaBumpChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getColor(data) {
		return data.color;
	}

	handleItemClick(data) {
		console.log('bump item was clicked: ', data);
	}

	getTooltip(data) {
		return (
			<div className="bump-tooltip">
				<div className="label">{data.serie.label}</div>
				<div className="values">
					{data.serie.data
						.filter((x) => x.y !== 0)
						.map((x) => {
							return (
								<div className="data-item" key={`data-item-${data.serie.id}-${x.x}`}>
									<div className="label">{x.x}</div>
									<div className="value">{x.y}</div>
								</div>
							);
						})}
				</div>
			</div>
		);
	}

	getCustomPoint(data) {
		const { x, y, isActive, isInactive, size, color, borderColor, borderWidth } = data;
		return (
			<g transform={`translate(${x}, ${y})`} style={{ pointerEvents: 'none' }}>
				{isActive && (
					<text textAnchor="middle" y={-4} fill={borderColor} fontWeight="bold">
						{data.data.original}
					</text>
				)}
				{isInactive && (
					<text textAnchor="middle" y={4} fill={borderColor}>
						{data.data.original}
					</text>
				)}
				{!isActive && !isInactive && (
					<circle
						cx={size * -0.5 - 4}
						cy={size * -0.5 + 4}
						r={size / 2}
						fill={color}
						strokeWidth={borderWidth}
						stroke={borderColor}
						style={{ pointerEvents: 'none' }}
					></circle>
				)}
			</g>
		);
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const chartConfig = chartProperties.config;
		return (
			<ResponsiveAreaBump
				data={_.get(this.props, 'data.data') || []}
				margin={{ top: isMobile ? 20 : 20, right: 50, bottom: isMobile ? 70 : 50, left: 50 }}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				lineWidth={3}
				activeLineWidth={6}
				inactiveLineWidth={3}
				inactiveOpacity={0.15}
				pointComponent={this.getCustomPoint}
				pointSize={10}
				activePointSize={16}
				inactivePointSize={0}
				onClick={this.handleItemClick}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={3}
				activePointBorderWidth={3}
				pointBorderColor={{ from: 'serie.color' }}
				endLabel={false}
				axisTop={null}
				tooltip={this.getTooltip}
				axisBottom={{
					tickSize: 5,
					tickPadding: isMobile ? 5 : 20,
					tickRotation: isMobile ? Number(-90) : 0,
					legend: '',
					legendPosition: 'middle',
					legendOffset: 32
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'ranking',
					legendPosition: 'middle',
					legendOffset: -40
				}}
			/>
		);
	}
}
