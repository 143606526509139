import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import './fulfillment-list.scss';
import _ from 'lodash';
import expressStore from '@/stores/ExpressStore';
import Table from '@/components/_EXPRESS/table/Table';
import { Chip } from 'framework7-react';
import { format } from 'date-fns';
import FulfillmentWizard from './FulfillmentWizard';
import { PrintType } from './PrintingGroups';

export const FulfillmentStatus = {
	AWAITING_APPROVAL: 'awaiting-approval',
	AWAITING_FULFILLMENT: 'awaiting-fulfillment',
	FULFILLED: 'fulfilled',
	SHIPPED: 'shipped',
	CANCELED: 'canceled',
	PRINTED: 'printed'
};
@observer
export default class FulfillmentList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loadedFrom: null,
			loading: true,
			fulfillments: [],
			groupMap: {},
			activeGroup: null
		});
		autoBind(this);
	}

	componentDidMount() {
		const groupsCopy = _.cloneDeep(this.props.groups) || [];
		const groupsToObject = _.keyBy(groupsCopy, '_id');
		this.data.groupMap = groupsToObject;
	}

	getFulfillments() {
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		this.data.loading = true;
		ExpressAPI.getFulfillments()
			.then((res) => {
				this.data.fulfillments = res;
				this.data.loading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.loading = false;
			});
	}

	asyncGetFulfillments() {
		setTimeout(() => {
			this.getFulfillments();
		}, 0);
	}

	cancelFulfillment(id, e) {
		if (!e.target.classList.contains('fulfillment-trash-icon-override')) return;

		this.data.loading = true;
		ExpressAPI.cancelFulfillment(id)
			.then((res) => {
				this.data.fulfillments = res;
				this.data.loading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.loading = false;
			});
	}

	onRowClick(fulfillment, group, e) {
		if (e.target.classList.contains('fulfillment-trash-icon-override')) return;

		this.data.activeGroup = {
			fulfillment: _.cloneDeep(fulfillment),
			group: _.cloneDeep(group)
		};
	}

	buildShipDate(f) {
		let potentialDate = new Date(f.estimatedShipDate || new Date());
		const statusMap = {
			'awaiting-approval': 'Needs Approval',
			'awaiting-fulfillment': format(potentialDate, 'MMMM dd, yyyy'),
			fulfilled: format(potentialDate, 'MMMM dd, yyyy'),
			printed: format(potentialDate, 'MMMM dd, yyyy')
		};
		return statusMap[f.status];
	}

	buildStatusChip(status, id) {
		const statusMap = {
			'awaiting-approval': 'Pending Approval',
			'awaiting-fulfillment': 'Processing',
			fulfilled: 'Printing',
			printed: 'Preparing Shipment'
		};
		const iconMap = {
			'awaiting-approval': 'far fa-minus-circle',
			'awaiting-fulfillment': 'fas fa-sync-alt',
			fulfilled: 'fas fa-shredder',
			printed: 'fas fa-truck-loading'
		};
		const colorMap = {
			'awaiting-approval': 'grey',
			'awaiting-fulfillment': 'yellow',
			fulfilled: 'green',
			printed: 'green'
		};
		return (
			<Chip
				text={statusMap[status]}
				mediaBgColor={colorMap[status]}
				className="ios fulfillment-status-chip"
				key={`status-chip-${id}`}
			>
				<i
					slot="media"
					className={iconMap[status]}
					key={`status-chip-icon-${id}`}
				></i>
			</Chip>
		);
	}

	buildTypeChip(printingType) {
		return (
			<Chip
				text={
					printingType === PrintType.BULK
						? 'Bulk Shipping'
						: printingType === PrintType.INDIVIDUAL
						? 'Individual Shipping'
						: ''
				}
				mediaBgColor={'green'}
				className={`ios shipping-chip ${printingType}`}
			>
				{printingType === PrintType.BULK ? (
					<i
						slot="media"
						className="fas fa-box"
						key={`fulfillment-chip-bulk-${Math.random()}`}
					></i>
				) : (
					<i
						slot="media"
						className="fas fa-envelope"
						key={`fulfillment-chip-ind-${Math.random()}`}
					/>
				)}
			</Chip>
		);
	}

	render() {
		if (this.data.loadedFrom !== expressStore.currentOrg._id) {
			this.asyncGetFulfillments();
		}
		const tableHeaders = [
			{ text: 'Name', classList: 'ellipse', canSearch: true },
			{ text: 'Fulfillment Type', classList: 'small', canSearch: true },
			{ text: 'Potential Ship Date', classList: 'small' },
			{ text: 'Status', classList: 'small' },
			{ text: 'Cancel', classList: 'small' }
		];
		const tableRows =
			this.data?.fulfillments?.map((f) => {
				const groupName =
					_.get(this.data.groupMap, `${f.internalGroupId}.name`) ||
					'Unknown Group';
				return {
					id: f._id,
					onClick: (placeHolder, e) => {
						this.onRowClick(
							_.cloneDeep(f),
							_.cloneDeep(this.data.groupMap[f.internalGroupId]),
							e
						);
					},
					items: [
						{ text: groupName, classList: 'ellipse group-name capitalize' },
						{
							text: this.buildTypeChip(f.printingType),
							classList: 'capitalize small'
						},
						{ text: this.buildShipDate(f), classList: 'small' },
						{
							text: this.buildStatusChip(f.status, f._id),
							classList: 'capitalize small'
						},
						{
							id: `${f._id}`,
							text: 'delete',
							name: 'fulfillment-delete-button',
							hide: f.status === 'fulfilled' || f.status === 'printed',
							hideMessage: "Can't cancel order",
							component: (
								<i
									className="fad fa-trash-alt fulfillment-trash-icon-override"
									key={`express-form-toggle-${f._id}`}
									title="Delete Feature Flag"
									onClick={(e) => {
										return this.cancelFulfillment(f._id, e);
									}}
								></i>
							),
							classList: 'small fulfillment-delete-container'
						}
					]
				};
			}) || [];
		return (
			<div className="express-fulfillment-list">
				{!this.data.activeGroup && (
					<div className="details-text hbox vcenter">
						<p className="grow-1">
							Below are all groups that have printing enabled via the previous
							screen. If you would like to schedule the group for ID card
							fulfillment select the group and follow the on screen approval
							steps. Once cards have been shipped check the shipment history tab
							for more details.
						</p>
					</div>
				)}
				{!this.data.loading && this.data?.fulfillments?.length == 0 && (
					<div className="empty-message vbox vcenter hcenter">
						<div className="icon-box hbox vcenter hcenter">
							<i className="fad fa-users-class" />
						</div>
						<h3>There are no groups with fulfillment enabled.</h3>
					</div>
				)}
				{!this.data.activeGroup && this.data?.fulfillments?.length > 0 && (
					<Table
						id="print-fulfillment-table"
						headers={tableHeaders}
						rows={tableRows}
					/>
				)}
				{this.data.activeGroup && (
					<FulfillmentWizard
						groupData={this.data.activeGroup ? this.data.activeGroup : {}}
						onBack={() => {
							this.data.activeGroup = null;
						}}
						onSubmit={() => {
							this.data.activeGroup = null;
							this.getFulfillments();
						}}
					></FulfillmentWizard>
				)}
			</div>
		);
	}
}
