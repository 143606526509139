import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import './support-page.scss';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import _ from 'lodash';
import TutorialPage from './tutorial-page/TutorialPage';
import { completeCategoriesArr, completeTutorialsArr } from './tutorial-page/TutorialData';

let tutorialsArr = _.cloneDeep(completeTutorialsArr);
let categoriesArr = _.cloneDeep(completeCategoriesArr);
@observer
export default class SupportPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			hideCategories: false,
			hideTutorials: true,
			activeTutorial: null,
			activeCategory: null,
			searchInput: ''
		});
		autoBind(this);
	}

	// * This is where define what page is loaded for a given tutorial key
	getActiveTutorial() {
		switch (this.data.activeTutorial) {
			case 'createGroup': {
				return (
					<TutorialPage content={completeTutorialsArr[0].content} tutorialTitle={completeTutorialsArr[0].tutorialTitle}></TutorialPage>
				);
			}
			case 'createHealthPlan': {
				return (
					<TutorialPage content={completeTutorialsArr[1].content} tutorialTitle={completeTutorialsArr[1].tutorialTitle}></TutorialPage>
				);
			}
			case 'createSingleNetwork': {
				return (
					<TutorialPage content={completeTutorialsArr[2].content} tutorialTitle={completeTutorialsArr[2].tutorialTitle}></TutorialPage>
				);
			}
			case 'createTieredNetwork': {
				return (
					<TutorialPage content={completeTutorialsArr[3].content} tutorialTitle={completeTutorialsArr[3].tutorialTitle}></TutorialPage>
				);
			}
			case 'createOpenNetwork': {
				return (
					<TutorialPage content={completeTutorialsArr[4].content} tutorialTitle={completeTutorialsArr[4].tutorialTitle}></TutorialPage>
				);
			}
			case 'createMember': {
				return (
					<TutorialPage content={completeTutorialsArr[5].content} tutorialTitle={completeTutorialsArr[5].tutorialTitle}></TutorialPage>
				);
			}
			case 'viewGroupDetails': {
				return (
					<TutorialPage content={completeTutorialsArr[6].content} tutorialTitle={completeTutorialsArr[6].tutorialTitle}></TutorialPage>
				);
			}
			case 'editGroupDetails': {
				return (
					<TutorialPage content={completeTutorialsArr[7].content} tutorialTitle={completeTutorialsArr[7].tutorialTitle}></TutorialPage>
				);
			}
			case 'importMemberData': {
				return (
					<TutorialPage content={completeTutorialsArr[8].content} tutorialTitle={completeTutorialsArr[8].tutorialTitle}></TutorialPage>
				);
			}
			case 'inputMemberData': {
				return (
					<TutorialPage content={completeTutorialsArr[9].content} tutorialTitle={completeTutorialsArr[9].tutorialTitle}></TutorialPage>
				);
			}
			case 'manageGroupAppConfig': {
				return (
					<TutorialPage
						content={completeTutorialsArr[10].content}
						tutorialTitle={completeTutorialsArr[10].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'viewGroupMembers': {
				return (
					<TutorialPage
						content={completeTutorialsArr[11].content}
						tutorialTitle={completeTutorialsArr[11].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'changeHealthPlanNetworkGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[12].content}
						tutorialTitle={completeTutorialsArr[12].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'viewIdCardHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[13].content}
						tutorialTitle={completeTutorialsArr[13].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editBackIdHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[14].content}
						tutorialTitle={completeTutorialsArr[14].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editFrontIdHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[15].content}
						tutorialTitle={completeTutorialsArr[15].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'swapIdHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[16].content}
						tutorialTitle={completeTutorialsArr[16].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'addFirstDocToOrg': {
				return (
					<TutorialPage
						content={completeTutorialsArr[17].content}
						tutorialTitle={completeTutorialsArr[17].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'addDocToHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[18].content}
						tutorialTitle={completeTutorialsArr[18].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'viewDocHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[19].content}
						tutorialTitle={completeTutorialsArr[19].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'removeDocHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[20].content}
						tutorialTitle={completeTutorialsArr[20].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'viewDocsAttachedToHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[21].content}
						tutorialTitle={completeTutorialsArr[21].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'searchWhenAddingDocToHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[22].content}
						tutorialTitle={completeTutorialsArr[22].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'gettingToAppConfigHealthPlanGroupPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[23].content}
						tutorialTitle={completeTutorialsArr[23].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'providerProfilePlanConfigsGroupPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[24].content}
						tutorialTitle={completeTutorialsArr[24].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'homePagePlanConfigsGroupPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[25].content}
						tutorialTitle={completeTutorialsArr[25].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'providerFinderPlanConfigsGroupPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[26].content}
						tutorialTitle={completeTutorialsArr[26].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'profilePagePlanConfigsGroupPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[27].content}
						tutorialTitle={completeTutorialsArr[27].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'searchingForGroup': {
				return (
					<TutorialPage
						content={completeTutorialsArr[28].content}
						tutorialTitle={completeTutorialsArr[28].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'connectExistingHealthPlanToGroup': {
				return (
					<TutorialPage
						content={completeTutorialsArr[29].content}
						tutorialTitle={completeTutorialsArr[29].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'removeHealthPlanFromGroup': {
				return (
					<TutorialPage
						content={completeTutorialsArr[30].content}
						tutorialTitle={completeTutorialsArr[30].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'searchForHealthPlanAttachedToGroup': {
				return (
					<TutorialPage
						content={completeTutorialsArr[31].content}
						tutorialTitle={completeTutorialsArr[31].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editHealthPlanDetailsGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[32].content}
						tutorialTitle={completeTutorialsArr[32].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'setupCustomTelelhealthHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[33].content}
						tutorialTitle={completeTutorialsArr[33].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'turnOffTelehealthForHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[34].content}
						tutorialTitle={completeTutorialsArr[34].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editAdditionalDetailsHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[35].content}
						tutorialTitle={completeTutorialsArr[35].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editNetworkDetailsHealthPlanGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[36].content}
						tutorialTitle={completeTutorialsArr[36].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'viewingMemberOfGroup': {
				return (
					<TutorialPage
						content={completeTutorialsArr[37].content}
						tutorialTitle={completeTutorialsArr[37].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToSearchForHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[38].content}
						tutorialTitle={completeTutorialsArr[38].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'deletingHealthPlanFromOrg': {
				return (
					<TutorialPage
						content={completeTutorialsArr[39].content}
						tutorialTitle={completeTutorialsArr[39].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'invitingTeamMember': {
				return (
					<TutorialPage
						content={completeTutorialsArr[40].content}
						tutorialTitle={completeTutorialsArr[40].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'findAppConfigHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[41].content}
						tutorialTitle={completeTutorialsArr[41].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'customTelehealthForHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[42].content}
						tutorialTitle={completeTutorialsArr[42].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'turningOffTelehealthHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[43].content}
						tutorialTitle={completeTutorialsArr[43].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editHealthPlanDetailsHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[44].content}
						tutorialTitle={completeTutorialsArr[44].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editAdditionalDetailsForHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[45].content}
						tutorialTitle={completeTutorialsArr[45].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'changeNetworkForHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[46].content}
						tutorialTitle={completeTutorialsArr[46].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editIdCardForHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[47].content}
						tutorialTitle={completeTutorialsArr[47].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToChangeIdCardHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[48].content}
						tutorialTitle={completeTutorialsArr[48].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'addDocToHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[49].content}
						tutorialTitle={completeTutorialsArr[49].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'viewDocAttachedToHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[50].content}
						tutorialTitle={completeTutorialsArr[50].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'removeDocFromHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[51].content}
						tutorialTitle={completeTutorialsArr[51].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'editNetworkDetailsForHealthPlan': {
				return (
					<TutorialPage
						content={completeTutorialsArr[52].content}
						tutorialTitle={completeTutorialsArr[52].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'searchingForMember': {
				return (
					<TutorialPage
						content={completeTutorialsArr[53].content}
						tutorialTitle={completeTutorialsArr[53].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToEditTieredNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[54].content}
						tutorialTitle={completeTutorialsArr[54].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToEditTraditionalNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[55].content}
						tutorialTitle={completeTutorialsArr[55].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToEditNoneNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[56].content}
						tutorialTitle={completeTutorialsArr[56].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToSearchForNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[57].content}
						tutorialTitle={completeTutorialsArr[57].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'cloningHealthPlanWithTieredNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[58].content}
						tutorialTitle={completeTutorialsArr[58].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'cloningHealthPlanWithNoneNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[59].content}
						tutorialTitle={completeTutorialsArr[59].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'cloningHealthPlanWithTraditionalNetwork': {
				return (
					<TutorialPage
						content={completeTutorialsArr[60].content}
						tutorialTitle={completeTutorialsArr[60].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'addTextBoxToIdCard': {
				return (
					<TutorialPage
						content={completeTutorialsArr[61].content}
						tutorialTitle={completeTutorialsArr[61].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'addFieldsToIdCard': {
				return (
					<TutorialPage
						content={completeTutorialsArr[62].content}
						tutorialTitle={completeTutorialsArr[62].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'usingTheImageTool': {
				return (
					<TutorialPage
						content={completeTutorialsArr[63].content}
						tutorialTitle={completeTutorialsArr[63].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'changingBackgroundIdOfCard': {
				return (
					<TutorialPage
						content={completeTutorialsArr[64].content}
						tutorialTitle={completeTutorialsArr[64].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'usingWhiteOutTool': {
				return (
					<TutorialPage
						content={completeTutorialsArr[65].content}
						tutorialTitle={completeTutorialsArr[65].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'usingTextSettings': {
				return (
					<TutorialPage
						content={completeTutorialsArr[66].content}
						tutorialTitle={completeTutorialsArr[66].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'usingFieldSettings': {
				return (
					<TutorialPage
						content={completeTutorialsArr[67].content}
						tutorialTitle={completeTutorialsArr[67].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'usingShapeToolAndSettings': {
				return (
					<TutorialPage
						content={completeTutorialsArr[68].content}
						tutorialTitle={completeTutorialsArr[68].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToCreateIdCardWithTemplate': {
				return (
					<TutorialPage
						content={completeTutorialsArr[69].content}
						tutorialTitle={completeTutorialsArr[69].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToCreateNonTemplatedIdCard': {
				return (
					<TutorialPage
						content={completeTutorialsArr[70].content}
						tutorialTitle={completeTutorialsArr[70].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToCreateAnIdCard': {
				return (
					<TutorialPage
						content={completeTutorialsArr[71].content}
						tutorialTitle={completeTutorialsArr[71].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToUseCopyPasteDeleteTools': {
				return (
					<TutorialPage
						content={completeTutorialsArr[72].content}
						tutorialTitle={completeTutorialsArr[72].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToUseMoveToTools': {
				return (
					<TutorialPage
						content={completeTutorialsArr[73].content}
						tutorialTitle={completeTutorialsArr[73].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleProviderFinderGroups': {
				return (
					<TutorialPage
						content={completeTutorialsArr[74].content}
						tutorialTitle={completeTutorialsArr[74].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleBookAppointmentMctGroups': {
				return (
					<TutorialPage
						content={completeTutorialsArr[75].content}
						tutorialTitle={completeTutorialsArr[75].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToTogglePrescriptionNavBarGroups': {
				return (
					<TutorialPage
						content={completeTutorialsArr[76].content}
						tutorialTitle={completeTutorialsArr[76].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleCovidTestFinderGroups': {
				return (
					<TutorialPage
						content={completeTutorialsArr[77].content}
						tutorialTitle={completeTutorialsArr[77].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToAddCustomLogoGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[78].content}
						tutorialTitle={completeTutorialsArr[78].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToConfigureTelehealthTextGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[79].content}
						tutorialTitle={completeTutorialsArr[79].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleMyPrescriptionBannerCardGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[80].content}
						tutorialTitle={completeTutorialsArr[80].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleTelehealthBannerCardGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[81].content}
						tutorialTitle={completeTutorialsArr[81].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleProcedureBannerCardGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[82].content}
						tutorialTitle={completeTutorialsArr[82].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleBalanceBillingBannerCardGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[83].content}
						tutorialTitle={completeTutorialsArr[83].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleAdditionalServicesBannerCardGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[84].content}
						tutorialTitle={completeTutorialsArr[84].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToToggleCustomBannerCardsGroupsPage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[85].content}
						tutorialTitle={completeTutorialsArr[85].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToCreateCustomBannerCardCall': {
				return (
					<TutorialPage
						content={completeTutorialsArr[86].content}
						tutorialTitle={completeTutorialsArr[86].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToCreateCustomBannerCardWebsite': {
				return (
					<TutorialPage
						content={completeTutorialsArr[87].content}
						tutorialTitle={completeTutorialsArr[87].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToCreateCustomBannerCardMessage': {
				return (
					<TutorialPage
						content={completeTutorialsArr[88].content}
						tutorialTitle={completeTutorialsArr[88].tutorialTitle}
					></TutorialPage>
				);
			}
			case 'howToDeleteCustomBannerCard': {
				return (
					<TutorialPage
						content={completeTutorialsArr[89].content}
						tutorialTitle={completeTutorialsArr[89].tutorialTitle}
					></TutorialPage>
				);
			}
			default: {
			}
		}
	}

	searchChange(e) {
		this.data.searchInput = e.currentTarget.value;
	}

	searchCheck(e) {
		if (!this.data.hideCategories && this.data.hideTutorials) {
			this.data.hideCategories = true;
			this.data.hideTutorials = false;
			this.resetTutorialsArr();
			this.searchChange(e);
			this.sortTutorialArray();
		}
		if (this.data.hideCategories && !this.data.hideTutorials) {
			this.resetTutorialsArr();
			this.searchChange(e);
			this.sortTutorialArray();
		}
	}

	resetTutorialsArr() {
		tutorialsArr = _.cloneDeep(completeTutorialsArr);
	}

	sortTutorialArray() {
		let newTutorialsArr = [];
		for (let i = 0; i < tutorialsArr.length; i++) {
			if (tutorialsArr[i].tutorialTitle.toLowerCase().indexOf(this.data.searchInput.toLowerCase()) !== -1) {
				newTutorialsArr.push(tutorialsArr[i]);
			}
		}
		tutorialsArr = _.cloneDeep(newTutorialsArr);
	}

	sortBasedOnCategory() {
		this.resetTutorialsArr();
		let newTutorialsArr = [];
		for (let i = 0; i < tutorialsArr.length; i++) {
			if (tutorialsArr[i].categories.toLowerCase().indexOf(this.data.searchInput.toLowerCase()) !== -1) {
				newTutorialsArr.push(tutorialsArr[i]);
			}
		}
		tutorialsArr = _.cloneDeep(newTutorialsArr);
	}

	render() {
		let { searchInput } = this.data;
		return (
			<div className="express-support-page">
				<h1>Tutorials & Walkthroughs</h1>
				{!this.data.activeTutorial && ( // display the search bar unless there is an active tutorial
					<div className="list-controls hbox vcenter">
						<div className="input-ctn hbox vcenter">
							<i className="fas fa-search"></i>
							<input placeholder="Tutorial name or keyword" value={searchInput} onChange={this.searchCheck} />
						</div>
						<div className="grow-1"></div>
					</div>
				)}
				{!this.data.hideCategories &&
					this.data.hideTutorials && ( // when hide categories is false and hide tutorials is true display the category list
						<div>
							<div className="tutorials">
								{categoriesArr.map((categories) => (
									<div
										className="tutorial-card vbox vcenter hcenter"
										key={categories.caseName}
										onClick={() => {
											this.data.activeCategory = categories.searchTerm; // this will cause the active category to equal the selected categories casename
											console.log(this.data.activeCategory);
											this.data.hideCategories = true; // This will hide the categories list after the user clicks on a category
											this.data.hideTutorials = false; // This will cause the tutorials to appear after the user clicks on a category
											this.data.searchInput = this.data.activeCategory;
											{
												this.sortBasedOnCategory();
											}
										}}
									>
										<i className={categories.iconDescription}></i>
										{categories.tutorialTitle}
									</div>
								))}
							</div>
						</div>
					)}
				{this.data.hideCategories &&
					!this.data.hideTutorials && ( // when hide categories is true and hide tutorials is false display the tutorial list
						<div className="current-tutorial">
							<Button
								fill
								large
								onClick={() => {
									this.data.hideCategories = false; // This brings back the category list
									this.data.hideTutorials = true; // This hides the tutorial list
									this.data.searchInput = ''; // This clears the search input
								}}
							>
								Back To All Categories
							</Button>
							<div className="tutorials">
								{tutorialsArr.map((tutorial) => (
									<div
										className="tutorial-card vbox vcenter hcenter"
										key={tutorial.caseName}
										onClick={() => {
											this.data.activeTutorial = tutorial.caseName; // this will cause the active tutorial to equal the selected tutorials casename
											this.data.hideTutorials = true; // This will cause the tutorials to hide
										}}
									>
										<i className={tutorial.iconDescription}></i>
										{tutorial.tutorialTitle}
									</div>
								))}
							</div>
						</div>
					)}
				{this.data.activeTutorial && ( // when there is an active tutorial this back to all tutorials button should appear
					<Button
						fill
						large
						onClick={() => {
							this.data.activeTutorial = null; // This clears the active tutorial on the page
							this.data.hideTutorials = false; // This turns off hide tutorials and should render the tutorial list that was previously shown
						}}
					>
						Back To All Tutorials
					</Button>
				)}
				{this.data.activeTutorial && this.getActiveTutorial()}
			</div>
		);
	}
}
