import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Popup, Link } from 'framework7-react';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { observer } from '../../utils/State';
import appStore from '../../stores/AppStore';
import AppleShareIcon from '../../assets/share.svg';
import Logo from '../../static/logo/Logo';
import androidPWA from '@/assets/androidPWA.gif';
import iosPWA from '@/assets/iosPWA.gif';
import './pwa-prompt.scss';

@observer
export default class PwaPrompt extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('PwaPrompt', {
			title: 'Install',
			content: 'Install this application on your home page for a better experience.',
			instruction: 'then "Add to Home Screen"',
			iosBrowser: 'From Safari tap',
			androidBrowser: 'From Chrome tap'
		});
		autoBind(this);
	}

	closePrompt() {
		appStore.showPwaPrompt = false;
	}

	render() {
		const shouldShow = !appStore.showReauthLoader && appStore.showPwaPrompt;
		const instructionBrowserText = appStore.isIos ? this.text.iosBrowser : this.text.androidBrowser;
		return (
			<div>
				<Popup className="pwa-prompt-popup" opened={shouldShow} onPopupClosed={this.closePrompt}>
					<div className="popup-header">
						<h1 className="header">
							<span className="header-text">{this.text.title}</span> <Logo icon size="small" />
						</h1>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					<div className="container y-scroll">
						<p className="text">{this.text.content}</p>
						<p className="instructions">
							{instructionBrowserText}
							<span className="icon-container">
								{appStore.isIos && <img className="apple-share-icon" src={AppleShareIcon} />}
								{_.get(appStore, 'deviceDetails.android') && <i className="far fa-ellipsis-v" />}
							</span>
							{this.text.instruction}
						</p>
						<div>
							{appStore.isIos && (
								<div>
									<img className="instructional-gif" src={iosPWA} />
								</div>
							)}
							{_.get(appStore, 'deviceDetails.android') && (
								<div>
									<img className="instructional-gif" src={androidPWA} />
								</div>
							)}
						</div>
					</div>
				</Popup>
			</div>
		);
	}
}
