import React, { Component } from 'react';
import { Chip, Progressbar, ListInput, Popup, Page, Link, List, ListItem, Range } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import _ from 'lodash';
import './provider-search.scss';
import providerStore from '../../stores/ProviderStore';
import searchSVG from '../../assets/providerSearch.svg';
import CloudAPI from '../../services/CloudAPI';
import UserService from '../../services/UserService';
import ProviderService from '../../services/ProviderService';
import RangeSlider from '../../components/range-slider/RangeSlider';
import AnalyticsService from '../../services/AnalyticsService';
import NetworkChip from '@/components/network-chip/NetworkChip';
import appStore from '../../stores/AppStore';

const analytics = new AnalyticsService();

@observer
class ProviderSearch extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			query: '',
			providers: [],
			searching: false,
			radius: 10
		});
		this.text = getCmpText('ProviderSearch', {
			title: 'Search',
			placeholder: 'Input Provider or Facility Name',
			empty: 'Try searching by provider or facility name to see results.',
			inNetworkText: 'In-Network',
			outNetworkText: 'Out-Network',
			radiusText: 'Search Radius',
			miles: '(miles)',
			searchError: 'Something went wrong, please try again.',
			locationText: 'Locations'
		});
		this.findCareText = getCmpText('FindCare', {
			pcpText: 'Primary Care Provider',
			careTeamText: 'Care Team'
		});
		autoBind(this);
	}

	async componentDidMount() {}

	onClose() {
		const query = _.cloneDeep(this.data.query);
		if (query && query.length > 0) {
			analytics.trackWithData('manual_search', { query: query });
		}
		this.props.onClose();
	}

	handleChange(evt) {
		this.data.query = evt.currentTarget.value;
		if (this.data.query.length >= 2) {
			this.doSearch();
		}
	}

	doSearch() {
		this.data.searching = true;
		if (this.fireOffSearch) {
			clearTimeout(this.fireOffSearch);
		}
		this.fireOffSearch = setTimeout(() => {
			if (this.data.query.length >= 2) {
				CloudAPI.nameSearch(_.clone(this.data.query), _.clone(this.data.radius))
					.then((res) => {
						console.log('Completed search ');
						this.data.searching = false;
						clearTimeout(this.fireOffSearch);
						this.fireOffSearch = null;
						// let cleaned = [];
						// _.forEach(res, results => {
						//   let provider = results.provider;
						//   delete results.provider;
						//   cleaned.push({ ...results, ...provider });
						// });
						const locationsWithDistance = _.cloneDeep(res).map((result) => {
							const distance = UserService.getDistanceFromLocation(_.get(result, 'locations[0]', {}));
							result.distance = distance;
							return result;
						});
						this.data.providers = locationsWithDistance;
					})
					.catch((err) => {
						console.log(err);
						this.$f7.dialog.alert(this.text.searchError);
					});
			} else {
				this.data.searching = false;
			}
		}, 500);
	}

	//TODO account for long names
	buildResults() {
		let { providers } = this.data;
		if (providers.length > 0) {
			let listItems = [];
			_.forEach(providers, (p) => {
				const usersPcp = _.get(UserService.getCurrentPcp(), 'npi');
				const isPcp = p.NPI === usersPcp;
				let name = _.capitalize(p.fullName);
				listItems.push(
					<div
						className="search-result hbox vcenter neumorphic neuhover"
						key={p._id}
						onClick={() => {
							this.onClose();
							ProviderService.openProviderProfile(p, 'manualSearch');
						}}
					>
						<div className="vbox vcenter">
							<div className="chips-container">
								<NetworkChip provider={p} />
								{isPcp && (
									<Chip text={this.findCareText.pcpText} mediaBgColor="blue" className="ios">
										<i slot="media" className={`fad fa-parking-circle`}></i>
									</Chip>
								)}
								{ProviderService.isOnCareTeam(p) && (
									<Chip text={this.findCareText.careTeamText} mediaBgColor="purple" className="ios">
										<i slot="media" className={`fad fa-heart`}></i>
									</Chip>
								)}
							</div>
							<div className="title">
								<strong>{name}</strong>
							</div>
							<div className="distance-item">
								<i className={`fad fa-map-marker-alt`} />
								<div className="distance">
									{p.distance}
									{this.text.miles}
								</div>
							</div>
						</div>
					</div>
				);
			});
			return listItems;
		} else {
			return (
				<div className="empty-list vbox vcenter hcenter">
					<img src={searchSVG} />
					<div className="empty-msg">{this.text.empty}</div>
				</div>
			);
		}
	}

	onRadiusChange(value) {
		if (value === 0) {
			value = 1;
		}
		this.data.radius = value;
		if (this.data.query.length >= 2) {
			this.doSearch();
		}
		if (this.props.open) {
			_.set(providerStore, 'filters.radius', value);
		}
	}

	render() {
		let { title, placeholder, radiusText, miles } = this.text;
		let { open } = this.props;
		let { query, searching } = this.data;
		const currentValue = _.clone(_.get(providerStore, 'filters.radius', 10));
		const value = currentValue > 50 ? 10 : currentValue;

		return (
			<Popup className="provider-search-popup" opened={open} onPopupClosed={this.onClose}>
				<Page className="filter-page">
					<div className="filter-header hbox vcenter hright">
						<h1 className="grow-1">{title}</h1>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					<List className={`search-form hbox vcenter ${appStore.isMobile ? '' : 'desktop'}`} noHairlines>
						<i className="fad fa-search" />
						<ListInput
							className={appStore.isMobile ? 'mobile' : ''}
							autofocus={true}
							type="text"
							placeholder={placeholder}
							value={query}
							onChange={this.handleChange}
						/>
					</List>
					<div className="range">
						<RangeSlider
							min={0}
							max={50}
							step={1}
							value={value}
							scale={true}
							scaleSteps={5}
							scaleSubSteps={5}
							onChange={this.onRadiusChange}
							source="findCareSearch"
						/>
					</div>
					{searching && <Progressbar infinite color="multi" className="animated fadeIn"></Progressbar>}
					<div className="inner-page-content y-scroll">{this.buildResults()}</div>
				</Page>
			</Popup>
		);
	}
}

export default ProviderSearch;
