import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Ellipse } from 'react-konva';
import CanvasFactory from '@/factories/CanvasFactory';

@observer
export default class CircleNode extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		const { canDrag, onTransformEnd, onDragEnd, onCircleTransform, onHighlight } = CanvasFactory.getActiveService();
		return (
			<>
				<Ellipse
					{...this.props}
					onTransform={onCircleTransform}
					onDragEnd={onDragEnd}
					onTransformEnd={onTransformEnd}
					onDrag={onHighlight}
					draggable={canDrag()}
					onDragMove={onHighlight}
				></Ellipse>
			</>
		);
	}
}
