export default function getTelehealthConfig(currentData) {
	const disableTextInputs =
		['carevalet', 'none'].indexOf(currentData.type) > -1;
	return {
		type: {
			label: 'Network Type',
			type: 'dropdown',
			className: 'dropdown-form-input',
			icon: 'far fa-hospital-user',
			listItems: {
				carevalet: 'CareValet Built in $0 Co-Pay',
				custom: 'Bring Your Own Telehealth',
				none: 'No Telehealth'
			}
		},
		careValetType: {
			label: 'CareValet Built in Type',
			type: 'dropdown',
			className: 'dropdown-form-input',
			hideField: currentData.type !== 'carevalet',
			listItems: {
				Teladoc: 'Teladoc',
				Recuro: 'Recuro'
			}
		},
		serviceOffering: {
			label: 'Recuro Service Offering',
			type: 'dropdown',
			className: 'dropdown-form-input',
			showHelpIconComponent: true,
			helpComponentText:
				'Contact customerservice@carevalet.com if you have questions about Recuro service offerings.',
			hideField:
				currentData.type !== 'carevalet' ||
				currentData?.careValetType !== 'Recuro',
			listItems: {
				vuc: 'Virtual Urgent Care',
				vbh: 'Virtual Behavioral Health',
				'vuc+vbh': 'Virtual Urgent Care + Virtual Behavioral Health',
				vpc: 'Virtual Primary Care',
				'vpc+vbh': 'Virtual Primary Care + Virtual Behavioral Health',
				cc: 'Complete Care'
			}
		},
		row1: {
			name: {
				label: 'Telehealth Name',
				placeholder: 'Name of this telehealth provider',
				type: 'text',
				hideField: currentData.type !== 'custom',
				disabled: disableTextInputs,
				validator: {
					type: 'length',
					value: 2
				}
			},
			phone: {
				label: 'Telehealth Phone Number',
				placeholder: 'Phone number for telehealth provider',
				type: 'phone',
				hideField: currentData.type !== 'custom',
				disabled: disableTextInputs,
				validator: {
					type: 'phone',
					notRequired: true
				}
			}
		},
		row2: {
			website: {
				label: 'Telehealth Website',
				placeholder: 'Website for telehealth provider',
				type: 'text',
				hideField: currentData.type !== 'custom',
				disabled: disableTextInputs,
				validator: {
					type: 'url'
				}
			},
			copay: {
				label: 'Copay for a telehealth call',
				placeholder: 'Copay in $ for a telehealth call',
				hideField: currentData.type !== 'custom',
				disabled: disableTextInputs,
				type: 'number'
			}
		}
	};
}
