import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../../../utils/Localization';
import { autoBind } from '../../../../../utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '../../../../../utils/State';
import AnalyticsService from '../../../../../services/AnalyticsService';
import GradiantStack from '../../../../../components/gradiant-stack/GradiantStack';
import LookupSVG from '@/assets/lookup.svg';
import CloudAPI from '../../../../../services/CloudAPI';
import MemberDataLookup from './MemberDataLookup';
import SsnLookup from './SsnLookup';

const analytics = new AnalyticsService();

@observer
export default class Lookup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			formData: {},
			formValid: false,
			formType: ''
		});
		this.text = getCmpText('EmployerSignup', {
			searching: 'Searching...',
			backText: 'Back',
			accountLookupTitle: 'Account Lookup',
			stepText: 'Step <%= step.current %> of <%= step.total %>',
			findAccountBtn: 'Find my account',
			errorMessage: 'Unable to find account, please try again.',
			userExists: 'There is already an account for this user, please contact CareValet at 866-549-9533 if further help is needed.',
			invalidDOB: 'Must be at least 18 years of age to register'
		});
		autoBind(this);
	}

	onBack() {
		this.props.onBack('lookup');
	}

	async onNext() {
		const isValid = this.data.formValid;
		if (!isValid) return;
		this.$f7.dialog.preloader(this.text.searching);
		try {
			const formData = _.cloneDeep(this.data.formData) || {};
			const formType = this.data.formType;
			if (formType === 'ssn') {
				analytics.trackWithData('Sign up', { step: 'find account', method: 'vitalInfo' });
			} else {
				analytics.trackWithData('Sign up', { step: 'find account', method: 'memberInfo' });
				if (formData.dob) {
					const dobInput = formData.dob;
					const dobISO = new Date(new Date(dobInput).setUTCHours(0, 0, 0, 0)).toISOString();
					formData.dob = dobISO;
				}
			}
			const findAccountRes = await CloudAPI.findAccount(formData);
			this.$f7.dialog.close();
			if (!findAccountRes) {
				this.$f7.dialog.alert(this.text.errorMessage);
				return;
			}
			this.props.onNext('lookup', findAccountRes);
		} catch (err) {
			console.log('Account lookup error: ', err);
			const errResponseString = _.get(err, 'responseText');
			const errorResponseData = errResponseString && JSON.parse(errResponseString);
			let errorMessage = this.text.errorMessage;
			switch (errorResponseData.message) {
				case 'invalidDOB':
					errorMessage = this.text.invalidDOB;
					break;
				case 'userExists':
					errorMessage = this.text.userExists;
					break;
				default:
					break;
			}
			this.$f7.dialog.close();
			this.$f7.dialog.alert(errorMessage);
		}
	}

	validateForm(formData) {
		if (!formData) return;
		this.data.formData = formData.values;
		this.data.formValid = formData.isValid;
		this.data.formType = formData.type;
	}

	render() {
		const { type } = this.props;
		const ssnLookup = type === 'ssn';
		const isValid = this.data.formValid;
		const stepTextTemplate = _.template(this.text.stepText);
		const stepDisplayText = stepTextTemplate(_.cloneDeep(this.props));
		return (
			<Fragment>
				<GradiantStack stack="2" animate={false} id="employer-lookup" />
				<div className={`employer-lookup-page y-scroll`}>
					<div className="header-ctn hbox vcenter">
						<div className="back hbox vcenter animated fadeInLeft" onClick={this.onBack}>
							<i className="fal fa-chevron-left"></i>
							{this.text.backText}
						</div>
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={LookupSVG} className="herosvg" />
						<h1>{this.text.accountLookupTitle}</h1>
						<div className="step-text">{stepDisplayText}</div>
						<div className="form-ctn">
							{ssnLookup && <SsnLookup formCallback={this.validateForm} />}
							{!ssnLookup && <MemberDataLookup formCallback={this.validateForm} />}
						</div>
						<Button disabled={!isValid} fill large className="round-btn blue" onClick={this.onNext}>
							{this.text.findAccountBtn} <i className="far fa-long-arrow-right"></i>
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}
