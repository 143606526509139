import React, { Component } from 'react';
import { observer } from '@/utils/State';
import './token-activity-detail.scss';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { formatRelative } from 'date-fns';
import Support from '@/components/support/Support';
import { Button } from 'framework7-react';

@observer
export default class TokenActivityDetail extends Component {
	constructor(props) {
		super(props);
		this.data = {};
		this.text = getCmpText('TokenActivityDetail', {
			timeTxt: 'Time',
			quantityTxt: 'Token Amount',
			typeTxt: 'Type',
			summaryTxt: 'Summary',
			headerTxt: 'Detail',
			descriptionTxt: 'Description',
			tokenTxt: 'Token',
			purchasedTxt: 'Purchased',
			awardedTxt: 'Rewarded',
			usedTxt: 'Used',
			issuedTxt: 'Issued',
			reducedTxt: 'Deducted',
			errorTxt: 'error',
			refundedTxt: 'Refunded',
			goToReceiptTxt: 'View the receipt',
			goToCallTxt: 'View the call details'
		});
	}

	formatDescription(token) {
		let str = '';
		switch (token.tokenReason) {
			case 'purchased':
				str += `${this.text.purchasedTxt} ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}!`;
				break;
			case 'awarded':
				str += `${this.text.awardedTxt} ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}!`;
				break;
			case 'used':
				str += `${this.text.usedTxt} ${token.quantity} ${this.text.tokenTxt}${
					token.quantity > 1 ? 's' : ''
				} on ${token.notes.toLowerCase()}`;
				break;
			case 'error':
				str += token.tokenType == 'credit' ? this.text.issuedTxt : this.text.reducedTxt;
				str += ` ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''} due to ${this.text.errorTxt}`;
				break;
			case 'refunded':
				str += `${this.text.refundedTxt}  ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}`;
				break;
			default:
				str += token.tokenType == 'credit' ? this.text.issuedTxt : this.text.reducedTxt;
				str += ` ${token.quantity} ${this.text.tokenTxt}${token.quantity > 1 ? 's' : ''}`;
		}
		return str;
	}

	generateLinks() {
		let { token } = this.props;
		let { goToReceiptTxt, goToCallTxt } = this.text;
		if (token.metaData) {
			if (token.metaData.transactionId) {
				return (
					<Button className="btn alt" onClick={() => this.props.onReceipt(token.metaData.transactionId)}>
						{goToReceiptTxt} →
					</Button>
				);
			} else if (token.metaData.callId) {
				return (
					<Button className="btn alt" onClick={() => this.props.onShowCallLog(token.metaData.callId)}>
						{goToCallTxt} →
					</Button>
				);
			}
		}
	}

	render() {
		let { timeTxt, headerTxt, quantityTxt, typeTxt, descriptionTxt } = this.text;
		let { token, onBack } = this.props;

		let dateCreated = _.get(token, 'timestamp', new Date());

		return (
			<div className="page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="back hbox vcenter" onClick={onBack}>
							<i className="fal fa-chevron-left"></i>Back
						</div>
						<h1>{headerTxt}</h1>
					</div>
				</div>
				<div className="transaction-container">
					<div className="overview-data">
						<div className="overview-pair">
							<div className="key">{typeTxt}</div>
							<div className="value">{_.get(token, 'tokenType', '').toUpperCase()}</div>
						</div>
						<div className="overview-pair">
							<div className="key">{timeTxt}</div>
							<div className="value">{formatRelative(new Date(dateCreated), new Date())}</div>
						</div>
						<div className="overview-pair">
							<div className="key">{quantityTxt}</div>
							<div className="value">{_.get(token, 'quantity', 0)}</div>
						</div>
						<div className="overview-pair">
							<div className="key">{descriptionTxt}</div>
							<div className="value">{this.formatDescription(token)}</div>
						</div>
						{this.generateLinks()}
						<Support />
					</div>
				</div>
			</div>
		);
	}
}
