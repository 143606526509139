import CanvasFactory from '@/factories/CanvasFactory';
import windowStore from '@/stores/WindowStore';
import { observable, observer } from '@/utils/State';
import { Link, List, ListItem, Popover } from 'framework7-react';
import React, { Component } from 'react';

@observer
export default class VariableField extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
	}

	render() {
		const { fieldType, fieldValue } = this.props;
		return (
			<div className="vbox property-section">
				<div className="property-label">{fieldType} FIELD</div>
				<div className="property-edit">
					<Link className="drop-down hbox vcenter" popoverOpen={`${fieldType === 'member' ? '.member-fields-swap' : fieldType === 'group' ? '.group-fields-swap' : '.plan-fields-swap'}`}>
						<div className="selected grow-1">{fieldValue}</div>
						<div className="fa fa-angle-down"></div>
					</Link>
				</div>
			</div>
		);
	}
}
