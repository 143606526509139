import React, { Component, Fragment } from 'react';
import { Popup, PageContent, Link, List, ListItem, Button, Toggle } from 'framework7-react';
import { getCmpText } from '@/utils/Localization';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import providerStore from '@/stores/ProviderStore';
import './add-to-care-team.scss';
import ProviderService from '@/services/ProviderService';
import { autoBind } from '@/utils/GeneralUtils';
import UserService from '@/services/UserService';
import CloudAPI from '@/services/CloudAPI';
import AnalyticsService from '@/services/AnalyticsService';
import NetworkChip from '@/components/network-chip/NetworkChip';

const analytics = new AnalyticsService();
@observer
export default class AddToCareTeamPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			careTeamOpen: false,
			selectedLocation: null,
			selectedSpecialty: null,
			setPcp: false,
			showAllLocations: false
		});
		this.text = getCmpText('ProviderProfile', {
			addToTeam: 'Added Successfully!',
			miles: ' mi',
			addText: 'Add',
			addToTeamText: 'Select A Location',
			chooseSpecialty: 'Select A Specialty',
			areYouSure: 'Are you sure',
			removeFromTeam: 'You want to remove this provider from your care team?',
			viewMoreLocations: 'View All Locations',
			assignAsPcp: 'Primary Care Physician',
			pcpToggleLabel: 'Make <%= fullName %> your primary care physician?',
			pcpToggleDislaimer:
				'You may only have one physician assigned as your primary care physician.  If selected, <%= pcpFullName %> will be removed.',
			clearPcp: 'Are you sure you want to remove this physician as your primary care provider?'
		});
		autoBind(this);
	}

	validateAddToCareTeam() {
		const activeLocation = this.getActiveLocationNPI();
		const activeSpecialty = this.getActiveSpecialty();
		const isValid = !!activeLocation && !!activeSpecialty;
		return isValid;
	}

	getActiveLocationNPI() {
		const { selectedLocation } = this.data;
		const locations = _.get(
			ProviderService.getFilteredOpenProviderLocations(this.props.opened, this.data.showAllLocations),
			'locations',
			[]
		);
		const firstLocation = _.clone(_.get(locations, '[0]._id'));
		const activeLocation = selectedLocation ? selectedLocation : firstLocation;
		return activeLocation;
	}

	getActiveSpecialty() {
		const { selectedSpecialty } = this.data;
		const locations = _.get(providerStore, 'openProvider.locations', []);
		const activeLocation = this.getActiveLocationNPI();
		const selectedLocationData = _.find(locations, { _id: activeLocation });
		const firstSpecialty = _.clone(_.get(selectedLocationData, 'specialties[0]', 'Primary Care'));
		const activeSpecialty = selectedSpecialty ? selectedSpecialty : firstSpecialty;
		return activeSpecialty;
	}

	onCareTeamAdd() {
		let { setPcp } = this.data;
		const activeLocation = this.getActiveLocationNPI();
		let p = providerStore.openProvider;
		let l = _.find(_.get(p, 'locations'), { _id: activeLocation });
		const isValid = this.validateAddToCareTeam();
		if (!isValid) {
			return;
		}

		let options = {
			position: 'center',
			closeTimeout: 2000
		};

		options.text = this.text.addToTeam;
		options.icon = `<i class="fad fa-check large-icon-toast" ></i>`;
		p.careTeam = true;

		const careteamSpecialty = this.getActiveSpecialty();
		const autoSetPcp = UserService.shouldAutoAssignPcp(careteamSpecialty);
		const setAsPcp = (careteamSpecialty === 'Primary Care' && setPcp) || autoSetPcp;
		this.$f7.dialog.preloader();
		const image = ProviderService.getProviderImage(p, true);
		CloudAPI.addToCareTeam({
			NPI: p.NPI,
			networks: l.networks,
			isPerson: p.isPerson,
			imageUrl: _.isEmpty(image) ? null : image,
			isPcp: setAsPcp,
			name: this.getName(p, true),
			gender: _.get(p, 'personDetails.gender', null),
			location: l,
			specialty: careteamSpecialty
		})
			.then((res) => {
				this.$f7.dialog.close();
				let toast = this.$f7.toast.create(options);
				toast.open();
				providerStore.careTeam = res;
				this.props.onClose();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	getName(p, stringOnly) {
		let name = `${_.get(p, 'fullName', '').split(' llc').join(' LLC')}`;
		if (stringOnly) {
			return name;
		}
		return (
			<span>
				<span className="provider-name">{`${name} `}</span>
				{_.get(p, 'personDetails.credential', '')}
			</span>
		);
	}

	showAllLocations() {
		this.data.showAllLocations = true;
		let providerNPI = _.get(providerStore, 'openProvider.NPI');
		analytics.trackWithData('view_more_provider_locations', { npi: providerNPI, entry: providerStore.profileEntry });
	}

	onSelectedLocationChange(evt) {
		this.data.selectedLocation = evt.currentTarget.value;
		this.validateAddToCareTeam();
	}

	onPcpSelectionChange(evt) {
		const currentValue = _.clone(this.data.setPcp);
		this.data.setPcp = !currentValue;
		this.validateAddToCareTeam();
	}

	onSelectedSpecialtyChange(evt) {
		this.data.selectedSpecialty = evt.currentTarget.value;
		this.validateAddToCareTeam();
	}

	handleClose() {
		this.data.showAllLocations = false;
		this.props.onClose();
	}

	render() {
		let careTeamOpen = this.props.opened;
		let { addText, addToTeamText } = this.text;
		const addToCareTeamValid = this.validateAddToCareTeam();
		const provider = _.cloneDeep(providerStore.openProvider);
		if (!provider || !providerStore.openProviderLoaded) {
			return <Fragment />;
		}
		const pcpMessageTemplate = _.template(this.text.pcpToggleLabel);
		const pcpDisclaimerTemplate = _.template(this.text.pcpToggleDislaimer);
		const pcpFullName = _.get(UserService.getCurrentPcp(), 'fullName');
		const activeLocation = this.getActiveLocationNPI();
		provider.fullName = _.startCase(provider.fullName);
		const locationData = ProviderService.getFilteredOpenProviderLocations(true, this.data.showAllLocations);
		const careteamSpecialty = this.getActiveSpecialty();
		const autoSetPcp = UserService.shouldAutoAssignPcp(careteamSpecialty);
		const locations = _.get(locationData, 'locations') || [];
		const locationsFiltered = _.get(locationData, 'isFiltered', false);
		const activeLocData = _.find(locations, { _id: activeLocation });
		const activeSpecialty = this.getActiveSpecialty();
		const activeLocSpecialties = (activeLocData && activeLocData.specialties) || [];
		return (
			<div className="add-to-care-team-container">
				<Popup className="care-team-popup" opened={careTeamOpen} onPopupClosed={this.handleClose}>
					<div className="hbox vcenter hright">
						<Link popupClose>
							<i className="fad fa-times" />
						</Link>
					</div>
					<PageContent className="page-content">
						<div className="prompt">{addToTeamText}</div>
						<List mediaList noHairlines key="care-team-menu">
							{locations.map((location) => {
								const providerWithLocationNetworks = { ...provider, networks: location.networks };
								return (
									<ListItem
										checkbox
										checked={activeLocation === location._id}
										name="location"
										value={location._id}
										onChange={this.onSelectedLocationChange}
										key={location._id}
										className={`care-location-item hbox vcenter neumorphic neuhover ${
											activeLocation === location._id ? 'active' : ''
										}`}
									>
										<div className="location-info">
											<div className="vbox vcenter hcenter location-distance">
												<i className={`fad fa-map-marker-alt`} />
												<div className="distance">
													{location.distance}
													{this.text.miles}
												</div>
											</div>
											<div className="chips-container">
												<NetworkChip provider={providerWithLocationNetworks} />
											</div>
										</div>
										{ProviderService.getAddressText(location)}
									</ListItem>
								);
							})}
							{locationsFiltered && (
								<ListItem>
									<Button round large onClick={this.showAllLocations} className="view-more-cta">
										{this.text.viewMoreLocations}
									</Button>
								</ListItem>
							)}
							{activeLocSpecialties && activeLocSpecialties.length > 0 && (
								<ListItem className="prompt" key="other-prompt">
									{this.text.chooseSpecialty}
								</ListItem>
							)}
							{activeLocSpecialties.map((specialty) => {
								return (
									<ListItem
										checkbox
										checked={activeSpecialty === specialty}
										name="specialty"
										value={specialty}
										onChange={this.onSelectedSpecialtyChange}
										key={`${activeLocation}-${specialty}`}
										className={`care-specialty-item hbox vcenter neumorphic neuhover ${activeSpecialty === specialty ? 'active' : ''}`}
									>
										{specialty}
									</ListItem>
								);
							})}
							{careteamSpecialty === 'Primary Care' && !autoSetPcp && (
								<ListItem className="prompt" key="pcp-prompt">
									{this.text.assignAsPcp}
								</ListItem>
							)}
							{careteamSpecialty === 'Primary Care' && !autoSetPcp && (
								<ListItem key="pcp-selection" className="care-pcp-item">
									<div>
										<p>{pcpMessageTemplate(provider)}</p>
										{pcpFullName && <p className="pcp-disclaimer">{pcpDisclaimerTemplate({ pcpFullName })}</p>}
									</div>
									<Toggle checked={this.data.setPcp} name="pcp" value="isPcp" onToggleChange={this.onPcpSelectionChange} />
								</ListItem>
							)}
						</List>
					</PageContent>
					<div className="page-footer">
						<Button fill onClick={this.onCareTeamAdd} className="btnfix" disabled={!addToCareTeamValid}>
							{addText}
						</Button>
					</div>
				</Popup>
			</div>
		);
	}
}
