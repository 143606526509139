import React, { Component } from 'react';
import _ from 'lodash';
import XLSX from 'xlsx';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer, observable } from '@/utils/State';
import { ListItem, Toggle, Preloader } from 'framework7-react';
import DropDown from '@/components/drop-down/DropDown';
import LineChart from './line-chart/LineChart';
import BarChart from './bar-chart/BarChart';
import PieChart from './pie-chart/PieChart';
import BumpChart from './bump-chart/BumpChart';
import './chart.scss';
import AreaBumpChart from './area-bump-chart/AreaBumpChart';
import FunnelChart from './funnel-chart/FunnelChart';
import CalendarChart from './calendar-chart/CalendarChart';
import WaffleChart from './waffle-chart/WaffleChart';

@observer
export default class Chart extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			hiddenItems: [],
			loading: false
		});
		autoBind(this);
	}

	handleLegendClick(evt) {
		const legendId = evt.currentTarget.getAttribute('data-item');
		const chartProperties = this.props.data;
		const chartData = chartProperties.data;
		const currentlyHidden = this.data.hiddenItems;
		const isHidden = currentlyHidden.indexOf(legendId) > -1;
		if (isHidden) {
			const remainingHidden = _.clone(this.data.hiddenItems).filter((x) => x !== legendId);
			this.data.hiddenItems = remainingHidden;
		} else {
			const newItems = _.clone(this.data.hiddenItems);
			newItems.push(legendId);
			const chartDataSize = chartData.length;
			const hiddenSize = newItems.length;
			if (chartDataSize > hiddenSize) {
				this.data.hiddenItems = newItems;
			} else {
				this.$f7.dialog.alert('You must show at least one item');
			}
		}
	}

	handleOptionChange(optionData, value) {
		const optionType = optionData.type;
		const optionValue = value;
		const options = _.get(optionData, 'options') || [];
		const currentSelection = _.get(this.props, `selectedFilters.${optionData.id}`);
		const optionConfig = _.find(options, { id: value }) || {};
		const currentSelectionConfig = _.find(options, { id: currentSelection }) || {};
		const reloadData = optionData.reload || optionConfig.reload || currentSelectionConfig.reload;
		this.data.hiddenItems = [];
		this.data.loading = true;
		setTimeout(
			() => {
				this.data.loading = false;
				this.props.onFilterSelection(optionData.id, optionValue, !reloadData);
			},
			optionType === 'dropdown' ? 500 : 0
		);
	}

	getReportFilters() {
		const filterConfigs = _.get(this.props, 'data.config.reportFilters') || [];
		const applicableFilters = filterConfigs.filter((filterConfig) => {
			const filterDependency = filterConfig.dependency || {};
			const currentDependencyValue = _.get(this.props, `selectedFilters.${filterDependency.id}`);
			const requiredFilterValue = filterDependency.requiredValue;
			const excludedFilterValue = filterDependency.excludedValue;
			if (requiredFilterValue) {
				return currentDependencyValue === requiredFilterValue;
			}
			if (excludedFilterValue) {
				return currentDependencyValue !== excludedFilterValue;
			}
			return true;
		});
		return applicableFilters;
	}

	exportData() {
		this.$f7.dialog.preloader();
		const selectedReport = _.get(this.props, 'data.config.title');
		try {
			const exportData = _.get(this.props, 'data.config.exportData');
			const exportAllowed = _.get(this.props, 'data.config.export');
			if (exportData && exportAllowed) {
				const workbook = XLSX.utils.book_new();
				var worksheet = XLSX.utils.json_to_sheet(exportData, { cellDates: true });
				worksheet['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 50 }];
				XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
				const fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary', bookSST: false });
				const fileBuf = new ArrayBuffer(fileData.length);
				const fileView = new Uint8Array(fileBuf);
				for (var i = 0; i != fileData.length; ++i) fileView[i] = fileData.charCodeAt(i) & 0xff;
				const exportTrigger = document.createElement('a');
				const blob = new Blob([fileBuf], { type: 'octet/stream' });
				const url = window.URL.createObjectURL(blob);
				exportTrigger.href = url;
				exportTrigger.download = `${selectedReport}.xlsx`;
				exportTrigger.click();
				window.URL.revokeObjectURL(url);
				this.$f7.dialog.close();
			} else {
				this.$f7.dialog.close();
				this.$f7.dialog.alert('Coming Soon!');
			}
		} catch (err) {
			console.log('error exporting data: ', err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error downloading data');
		}
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data || {};
		const chartData = chartProperties.data || [];
		const filteredChartData = chartData.filter((item) => {
			const hiddenItems = this.data.hiddenItems;
			return hiddenItems.indexOf(item.id) < 0;
		});
		const chartConfig = chartProperties.config || {};
		const reportFilters = this.getReportFilters();
		const chartType = _.get(this.props, 'selectedFilters.chartType') || _.get(this.props, 'data.config.defaultChartType') || 'line';
		const updatedData = { ...this.props.data, data: filteredChartData };
		const allData = _.flatten(chartData.map((x) => x.data));
		const chartTotals = allData.reduce((total, curr) => total + Number(curr.y || 0), 0);
		const exportData = _.get(chartConfig, 'exportData') || [];
		const hasExport = _.get(chartConfig, 'export') && exportData && exportData.length > 0;
		return (
			<div className={`chart-container animated ${this.props.animate ? 'slideInUp' : ''}`}>
				<h4 className="chart-title">
					{chartConfig.title}
					{hasExport && (
						<div className="chart-export-cta" onClick={this.exportData}>
							<i className="fad fa-file-excel" />
							Export
						</div>
					)}
				</h4>
				<div className="chart-subtitle">{chartConfig.subTitle}</div>
				<div className="chart-description">{chartConfig.description}</div>

				{(this.props.loading || this.data.loading) && (
					<div className="preloader-container">
						<Preloader size={32} color="blue" />
					</div>
				)}
				{chartTotals > 0 && (
					<div className="graph-container">
						{chartType === 'line' && <LineChart data={updatedData} />}
						{chartType === 'bar' && <BarChart data={updatedData} />}
						{chartType === 'pie' && <PieChart data={updatedData} selectedFilters={this.props.selectedFilters} />}
						{chartType === 'bump' && <BumpChart data={updatedData} selectedFilters={this.props.selectedFilters} />}
						{chartType === 'areaBump' && <AreaBumpChart data={updatedData} selectedFilters={this.props.selectedFilters} />}
						{chartType === 'funnel' && <FunnelChart data={updatedData} selectedFilters={this.props.selectedFilters} />}
						{chartType === 'calendar' && <CalendarChart data={updatedData} selectedFilters={this.props.selectedFilters} />}
						{chartType === 'waffle' && <WaffleChart data={updatedData} selectedFilters={this.props.selectedFilters} />}
					</div>
				)}
				{chartTotals < 1 && (
					<div className="graph-container empty-state">
						<i className="fad fa-signal-slash" />
						No data for the current selection.
					</div>
				)}
				<div className="disclaimer">{_.get(chartProperties, 'disclaimer') && <span>{chartProperties.disclaimer}</span>}</div>
				{chartTotals > 0 && (
					<div className="chart-footer">
						<div className={`legend-container ${isMobile ? 'mobile' : 'desktop'}`}>
							{chartData.map((item, index) => {
								const isHidden = this.data.hiddenItems.indexOf(item.id) > -1;
								return (
									<div
										className="legend-item"
										onClick={this.handleLegendClick}
										data-item={item.id}
										key={`legendItem-${item.id}`}
										style={{ color: item.color }}
									>
										{isHidden && <i className="fad fa-eye-slash" />}
										{!isHidden && <i className="fad fa-eye" />}
										<span className="legend-text">{item.label || item.id}</span>
									</div>
								);
							})}
						</div>
						<div className={`chart-filters ${isMobile ? 'mobile' : 'desktop'}`}>
							{reportFilters && reportFilters.length > 0 && <h4>Report Options</h4>}
							{reportFilters.map((filter) => {
								const filterValue = _.get(this.props, `selectedFilters.${filter.id}`) || filter.default || false;
								switch (filter.type) {
									case 'toggle':
										return (
											<div className="filter-option" key={`${chartConfig.id}-${filter.id}`}>
												<div className="label">{filter.name}</div>
												<Toggle
													name={`${filter.id}`}
													value={filterValue ? filterValue.toString() : 'false'}
													checked={filterValue}
													onToggleChange={() => this.handleOptionChange(filter, !filterValue)}
													color="green"
												/>
											</div>
										);
									case 'dropdown':
										const activeItem = _.get(this.props, `selectedFilters.${filter.id}`) || chartConfig.defaultChartType;

										const dropdownItems = filter.options.map((fOption) => {
											const isActive = activeItem === fOption.id;
											return (
												<ListItem
													className={`reporting-dropdown-item ${isActive ? 'active' : ''}`}
													key={`${chartConfig.id}-filter-dropdown-${filter.id}-${fOption.id}`}
													onClick={() => this.handleOptionChange(filter, fOption.id)}
													popoverClose=".cv-dropdown"
													link="#"
												>
													<div>{fOption.label}</div>
												</ListItem>
											);
										});
										const activeData = filter.options.find((x) => x.id === activeItem);
										const activeLabel = activeData ? activeData.label : 'Please Select';
										return (
											<DropDown
												key={`chart-option-dropdown-${chartConfig.id}-${filter.id}`}
												id={`chart-option-dropdown-${chartConfig.id}-${filter.id}`}
												className="filter-item"
												name={`chart-option-dropdown-${chartConfig.id}-${filter.id}`}
												items={dropdownItems}
												displayValue={activeLabel}
												label={filter.name}
											/>
										);
									default:
										return <p>Something else</p>;
								}
							})}
						</div>
					</div>
				)}
			</div>
		);
	}
}
