import React, { Component } from 'react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { autoBind, formatDOB, showExpressError } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import './members-list.scss';
import { Button, Preloader } from 'framework7-react';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import ExpressAPI from '@/services/ExpressAPI';
import StateAbbrvMap from '@/utils/StateAbbrvMap';
import MemberDetails from './member-details/MemberDetails';
const relationEnums = {
	self: 'Primary',
	spouse: 'Spouse',
	child: 'Child',
	other: 'Other'
};
const pageSize = 50;

@observer
export default class MembersList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			searchInput: '',
			loading: true,
			filterOpen: false,
			loadedFrom: null,
			editOpen: false,
			editing: {},
			members: [],
			groups: [],
			plans: [],
			createOpen: false,
			formData: this.getFormSchema(),
			query: this.getFilterSchema(),
			lastQuery: null,
			hideNext: false,
			totalResults: 0,
			page: 0
		});
		autoBind(this);
	}

	componentDidMount() {
		expressStore.activeMember = null;
		let { groups, plans } = this.data;
	}

	componentWillUnmount() {
		expressStore.activeMember = null;
	}

	resetFormData() {
		this.data.formData = this.getFormSchema();
	}

	asyncLoadMembers() {
		setTimeout(() => {
			expressStore.activeMember = null;
			this.loadMembers();
		}, 0);
	}

	openEdit(member) {
		this.data.editing = this.getEditingData(member);
		this.data.editOpen = true;
	}

	compareQueries(current, old) {}

	async loadMembers() {
		this.data.filterOpen = false;
		this.data.loading = true;
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		const q = { ...this.data.query, input: this.data.searchInput };

		//The list is currently on the group page
		if (this.props.groupId) {
			q.internalGroupId = this.props.groupId;
		}

		if (!_.isEqual(_.cloneDeep(q), _.cloneDeep(this.data.lastQuery))) {
			this.data.page = 0;
			this.data.lastQuery = _.cloneDeep(q);
			// @ts-ignore
			q.page = 0;
		} else {
			this.data.lastQuery = _.cloneDeep(q);
			// @ts-ignore
			q.page = this.data.page;
		}
		ExpressAPI.getMembers(q)
			.then(async (res) => {
				this.data.totalResults = res.total;
				this.data.members = res.results;
				const groups = await ExpressAPI.getGroups();
				this.data.groups = groups;
				const plans = await ExpressAPI.getAllPlans();
				this.data.plans = plans;
				let manualGroup = _.find(groups, { memberDataType: 'manual' });
				this.data.formData = this.getFormSchema();
				this.data.formData.internalGroupId = manualGroup?._id;
				// this.data.formData.internalPlanId = plans[0]?._id
				this.data.loading = false;
			})
			.catch((err) => {
				this.data.loading = false;
				showExpressError(this.$f7, err);
			});
	}

	nextPage() {
		this.data.page = this.data.page + 1;
		this.loadMembers();
	}

	lastPage() {
		if (this.data.page > 0) {
			this.data.page = this.data.page - 1;
			this.loadMembers();
		}
	}

	searchChange(e) {
		this.data.searchInput = e.currentTarget.value;
	}

	onAddMember() {
		if (
			this.data.groups.length === 0 ||
			this.data.plans.length === 0 ||
			!this.data.formData.internalGroupId
		) {
			showExpressError(
				this.$f7,
				null,
				'You must create at least 1 group and 1 plan prior to adding any members. At least 1 group must be setup for manual member management'
			);
			this.data.createOpen = false;
		} else {
			this.data.createOpen = true;
		}
	}

	onShowFilters() {
		this.data.filterOpen = true;
	}

	buildList() {
		let { members, loading, hideNext } = this.data;
		let items = [];
		_.forEach(members, (m) => {
			items.push(
				<div
					className="table-list-item members-list-item hbox vcenter"
					key={`${m.orgId}-${m._id}`}
					onClick={() => {
						expressStore.activeMember = m;
					}}
				>
					<div className="field">{_.capitalize(relationEnums[m.relation])}</div>
					<div
						className="field grow-1 ellipse"
						title={_.toUpper(`${m.firstName} ${m.lastName}`)}
					>
						{_.startCase(`${m.firstName} ${m.lastName}`)}
					</div>
					<div className="field grow-1">{m.memberId}</div>
					<div className="field grow-1">{m.groupId}</div>
					<div className="field grow-1">
						{m.healthPlan ? m.healthPlan : '-'}
					</div>
					<div className="field grow-1">
						{m.healthPlanDental ? m.healthPlanDental : '-'}
					</div>
					<div className="field grow-1">
						{m.healthPlanVision ? m.healthPlanVision : '-'}
					</div>
				</div>
			);
		});
		return (
			<div>
				<div className={`members-list ${loading ? '' : 'shadow'}`}>
					{members.length !== 0 && (
						<div className="table-list-header members-list-header hbox vcenter">
							<div className="field">Type</div>
							<div className="field grow-1">Name</div>
							<div className="field grow-1">Member ID</div>
							<div className="field grow-1">Group ID</div>
							<div className="field grow-1">Medical Plan</div>
							<div className="field grow-1">Dental Plan</div>
							<div className="field grow-1">Vision Plan</div>
						</div>
					)}
					{!loading ? (
						<div className="member-items">{items}</div>
					) : (
						<div className="hbox vcenter hcenter w-100">
							<Preloader size={32} color="blue"></Preloader>
						</div>
					)}
				</div>
				{!loading && members.length > 0 && this.buildTableControls()}
			</div>
		);
	}

	buildTableControls() {
		const PAGE_SIZE = 50;
		const showControls = this.data.members.length > 0;
		return showControls ? (
			<div
				className="table-controls hbox vcenter hcenter"
				key={`member-table-controls`}
			>
				{this.data.page > 0 && (
					<Button fill small onClick={this.lastPage}>
						Back
					</Button>
				)}
				<div>{`${this.data.page + 1} of ${Math.ceil(
					this.data.totalResults / PAGE_SIZE
				)} Pages`}</div>
				{this.data.page + 1 !==
					Math.ceil(this.data.totalResults / PAGE_SIZE) && (
					<Button fill small onClick={this.nextPage}>
						Next
					</Button>
				)}
			</div>
		) : (
			''
		);
	}

	onSaveMember() {
		let group = _.find(this.data.groups, {
			_id: this.data.formData.internalGroupId
		});

		let plans = this.data.plans.filter((plans) => {
			if (
				this.data.formData.healthPlan === plans.planId ||
				this.data.formData.healthPlanDental === plans.planId ||
				this.data.formData.healthPlanVision === plans.planId
			)
				return true;
		});
		this.$f7.dialog.preloader('Saving...');
		ExpressAPI.createMember({
			...this.data.formData,
			groupId: group.groupId,
			internalPlanId: plans.map((p) => p._id)
		})
			.then(() => {
				this.data.createOpen = false;
				this.$f7.dialog.close();
				this.loadMembers();
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	onSaveEditMember() {
		let group = _.find(this.data.groups, {
			_id: this.data.editing.internalGroupId
		});

		let plans = this.data.plans.filter((plan) => {
			if (
				this.data.editing.healthPlan === plan.planId ||
				this.data.editing.healthPlanDental === plan.planId ||
				this.data.editing.healthPlanVision === plan.planId
			)
				return true;
		});

		this.$f7.dialog.preloader('Saving...');
		ExpressAPI.saveMember({
			...this.data.editing,
			groupId: group.groupId,
			internalPlanId: plans.map((p) => p._id)
		})
			.then((res) => {
				this.data.editOpen = false;
				this.$f7.dialog.close();
				if (expressStore.activeMember) {
					expressStore.activeMember = res;
					this.loadMembers();
				} else {
					this.loadMembers();
				}
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	getFilterSchema() {
		return {
			internalGroupId: this.props.groupId ? this.props.groupId : 'all',
			internalPlanId: 'all',
			relation: 'all',
			gender: 'all',
			status: 'active',
			registrationStatus: 'all'
		};
	}

	getFilterConfig() {
		let { groups, plans } = this.data;
		const groupListItems = {};
		if (!this.props.groupId) {
			groupListItems['all'] = 'All';
		}
		const planListItems = {
			all: 'All'
		};
		const relationListItems = { all: 'All', ..._.clone(relationEnums) };
		const genderListItems = {
			all: 'All',
			m: 'Male',
			f: 'Female'
		};
		_.forEach(groups, (g) => {
			if (this.props.groupId === g._id) {
				groupListItems[g._id] = `${g.name} - ${g.groupId}`;
			} else if (!this.props.groupId) {
				groupListItems[g._id] = `${g.name} - ${g.groupId}`;
			}
		});
		_.forEach(plans, (p) => {
			planListItems[p._id] = `${p.name} - ${p.planId}`;
		});
		return {
			row0: {
				status: {
					label: 'MemberStatus',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user-alt',
					listItems: {
						active: 'Active',
						inactive: 'Termed',
						all: 'All'
					}
				},
				registrationStatus: {
					label: 'Registration Status',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user-alt',
					listItems: {
						registered: 'Registered',
						notRegistered: 'Not Registered',
						all: 'All'
					}
				}
			},
			row1: {
				internalGroupId: {
					label: 'Group',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-users-class',
					listItems: groupListItems
				},
				internalPlanId: {
					label: 'Healthplan',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-files-medical',
					listItems: planListItems
				}
			},
			row2: {
				relation: {
					label: 'Relation',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user',
					listItems: relationListItems
				},
				gender: {
					label: 'Gender',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-venus-mars',
					listItems: genderListItems
				}
			}
		};
	}

	getFormConfig() {
		let { groups, plans } = this.data;
		const groupListItems = {};
		const medicalPlansList = { '': 'Select Plan if Applicable' };
		const dentalPlansList = { '': 'Select Plan if Applicable' };
		const visionPlansList = { '': 'Select Plan if Applicable' };
		const relationListItems = _.clone(relationEnums);
		const genderListItems = {
			m: 'Male',
			f: 'Female'
		};
		_.forEach(groups, (g) => {
			if (g.memberDataType === 'manual') {
				groupListItems[g._id] = `${g.name} - ${g.groupId}`;
			}
		});
		_.forEach(plans, (p) => {
			switch (p?.healthPlanCoverageType) {
				case 'Medical':
					medicalPlansList[p.planId] = `${p.name} - ${p.planId}`;
					break;
				case 'Dental':
					dentalPlansList[p.planId] = `${p.name} - ${p.planId}`;
					break;
				case 'Vision':
					visionPlansList[p.planId] = `${p.name} - ${p.planId}`;
					break;
				default:
					medicalPlansList[p.planId] = `${p.name} - ${p.planId}`;
					break;
			}
		});
		return {
			internalGroupId: {
				label: 'Select an existing group',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-users-class',
				listItems: groupListItems
			},
			healthPlan: {
				label:
					'Select an existing Medical Healthplan (Will be linked if not already)',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-files-medical',
				listItems: medicalPlansList
			},
			healthPlanDental: {
				label:
					'Select an existing Dental Healthplan (Will be linked if not already)',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-files-medical',
				listItems: dentalPlansList
			},
			healthPlanVision: {
				label:
					'Select an existing Vision Healthplan (Will be linked if not already)',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-files-medical',
				listItems: visionPlansList
			},
			row1: {
				relation: {
					label: 'Relation',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user',
					listItems: relationListItems
				},
				gender: {
					label: 'Gender',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-venus-mars',
					listItems: genderListItems
				}
			},
			row2: {
				memberId: {
					label: 'Member ID',
					type: 'text',
					placeholder: 'Member ID',
					validator: {
						type: 'length',
						value: 4
					}
				},
				personCode: {
					label: 'Person Code (Unique # within a family unit)',
					type: 'text',
					placeholder: 'Ex: 1, 2, 3, etc'
				}
			},
			firstName: {
				label: 'First Name',
				type: 'text',
				placeholder: 'Member first name',
				validator: {
					type: 'length',
					value: 1
				}
			},
			row4: {
				middleName: {
					label: 'Middle Name or Initial',
					type: 'text',
					placeholder: 'Member name or initial'
				},
				lastName: {
					label: 'Last Name',
					type: 'text',
					placeholder: 'Member last name',
					validator: {
						type: 'length',
						value: 1
					}
				}
			},
			row5: {
				dob: {
					label: 'Date of Birth',
					type: 'date',
					placeholder: 'Members birthday',
					validator: {
						type: 'date'
					}
				},
				coverageLevel: {
					label: 'Medical Coverage Level',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user-friends',
					listItems: {
						'': 'Select if Applicable',
						EMP: 'Employee',
						ESP: 'Employee & Spouse',
						ECH: 'Employee & Children',
						FAM: 'Family'
					}
				}
			},

			row6: {
				coverageLevelDental: {
					label: 'Dental Coverage Level',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user-friends',
					listItems: {
						'': 'Select if Applicable',
						EMP: 'Employee',
						ESP: 'Employee & Spouse',
						ECH: 'Employee & Children',
						FAM: 'Family'
					}
				},
				coverageLevelVision: {
					label: 'Vision Coverage Level',
					type: 'dropdown',
					className: 'dropdown-form-input',
					icon: 'far fa-user-friends',
					listItems: {
						'': 'Select if Applicable',
						EMP: 'Employee',
						ESP: 'Employee & Spouse',
						ECH: 'Employee & Children',
						FAM: 'Family'
					}
				}
			},
			row7: {
				startDate: {
					label: 'Start Date: (App access starts on this date)',
					type: 'date',
					validator: {
						type: 'date'
					}
				},
				termDate: {
					label: 'Term Date: (Access removed after this date)',
					type: 'date',
					validator: {
						type: 'date',
						notRequired: true
					}
				}
			},
			row8: {
				email: {
					label: 'Email',
					placeholder: 'Member email',
					type: 'email',
					validator: {
						type: 'email',
						notRequired: true
					}
				},
				phone: {
					label: 'Mobile Phone',
					placeholder: 'Member mobile phone#',
					type: 'phone',
					validator: {
						type: 'phone',
						notRequired: true
					}
				}
			},
			street: {
				label: 'Address Street 1',
				type: 'text',
				placeholder: 'Street address',
				validator: {
					type: 'length',
					value: 1
				}
			},
			row9: {
				street2: {
					label: 'Address Street 2',
					type: 'text',
					placeholder: 'Apt# PO Box Etc...'
				},
				city: {
					label: 'City',
					type: 'text',
					placeholder: 'City',
					validator: {
						type: 'length',
						value: 1
					}
				}
			},
			row10: {
				state: {
					label: 'State',
					type: 'dropdown',
					className: 'dropdown-form-input state-dropdown',
					listItems: StateAbbrvMap
				},
				zipcode: {
					label: 'Postal Code (5-digit)',
					type: 'number',
					placeholder: 'Postal Code',
					validator: {
						type: 'length',
						value: 5,
						match: true
					}
				}
			}
		};
	}

	getFormSchema() {
		let internalGroupId = '';
		let plansList = [];
		if (this.data) {
			let { groups, plans } = this.data;
			plansList = plans;
			internalGroupId = groups[0]?._id;
		}

		return {
			internalGroupId: internalGroupId,
			internalPlanId: [],
			healthPlan: '',
			healthPlanDental: '',
			healthPlanVision: '',
			relation: 'self',
			gender: 'm',
			firstName: '',
			lastName: '',
			middleName: '',
			memberId: '',
			personCode: '',
			ssn: '',
			dob: '',
			coverageLevel: 'EMP',
			coverageLevelDental: '',
			coverageLevelVision: '',
			startDate: '',
			termDate: '',
			email: '',
			phone: '',
			street: '',
			street2: '',
			city: '',
			state: 'al',
			zipcode: ''
		};
	}

	getEditingData(data) {
		if (!data) {
			return {};
		}
		const {
			_id,
			firstName,
			lastName,
			middleName,
			memberId,
			healthPlan = '',
			healthPlanDental = '',
			healthPlanVision = '',
			internalGroupId,
			internalPlanId,
			personCode,
			email,
			phone,
			relation,
			gender,
			coverageLevel = '',
			coverageLevelDental = '',
			coverageLevelVision = '',
			dob,
			startDate,
			termDate,
			address
		} = data;
		const state = {
			_id: _id,
			...address,
			firstName,
			lastName,
			middleName,
			memberId,
			healthPlan,
			healthPlanDental,
			healthPlanVision,
			internalGroupId,
			internalPlanId,
			personCode,
			email,
			phone,
			relation,
			gender,
			coverageLevel,
			coverageLevelDental,
			coverageLevelVision
		};
		state['dob'] = formatDOB(dob, 'yyyy-MM-dd', 'MM/dd/yyyy');
		state['startDate'] = formatDOB(startDate, 'yyyy-MM-dd', 'MM/dd/yyyy');
		state['termDate'] = formatDOB(termDate, 'yyyy-MM-dd', 'MM/dd/yyyy');
		return _.cloneDeep(state);
	}

	render() {
		let { groupId } = this.props;
		let {
			loadedFrom,
			loading,
			searchInput,
			members,
			formData,
			createOpen,
			editing,
			editOpen,
			filterOpen,
			query
		} = this.data;
		let { currentOrg, activePage, activeMember, activeGroup } = expressStore;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoadMembers();
		}
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') &&
			expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;

		return (
			<div>
				{activeMember ? (
					<MemberDetails onEdit={this.openEdit}></MemberDetails>
				) : (
					<div
						className={`express-members-list ${
							activePage === 'members' ? 'add-padding' : ''
						}`}
					>
						{!groupId && <h1 className="text-4 mb-xl grow-1">Members</h1>}
						<div className="list-controls hbox vcenter">
							<div className="input-ctn hbox vcenter">
								<i className="fas fa-search"></i>
								<input
									placeholder="Last name or member ID..."
									value={searchInput}
									onChange={this.searchChange}
								/>
							</div>
							{!this.props.groupId && (
								<Button
									small
									className="filter-btn ios"
									onClick={this.onShowFilters}
								>
									<i className="fad fa-filter" />
									Filters
								</Button>
							)}
							<Button
								fill
								small
								className="list-btn ios mr24"
								onClick={this.loadMembers}
							>
								<i className="fa fa-search" />
								Search
							</Button>
							<div className="grow-1"></div>
							{!canEdit
								? null
								: !loading &&
								  (!activeGroup || activeGroup.memberDataType === 'manual') && (
										<Button
											fill
											small
											className="list-btn ios ml24"
											onClick={this.onAddMember}
										>
											<i className="fa fa-plus" />
											Add Member
										</Button>
								  )}
						</div>
						{!loading &&
							members.length <= 0 &&
							(activeGroup?.memberDataType === 'manual' || !activeGroup) && (
								<div className="empty-message vbox vcenter hcenter">
									<div className="icon-box hbox vcenter hcenter">
										<i className="fad fa-users" />
									</div>
									<h3>Add your first member</h3>
									<Button
										fill
										small
										className="round-btn list-btn animated pulse infinite"
										onClick={this.onAddMember}
									>
										<i className="fa fa-plus" />
										Add Member
									</Button>
								</div>
							)}
						{!loading &&
							members.length === 0 &&
							activeGroup?.memberDataType === 'import' && (
								<div className="empty-message vbox vcenter hcenter">
									<div className="icon-box hbox vcenter hcenter">
										<i className="fad fa-users" />
									</div>
									<h3>Add your first members</h3>
									<Button
										fill
										small
										className="round-btn list-btn animated pulse infinite"
										onClick={() => {
											expressStore.activePage = 'importdata';
										}}
									>
										<i className="fa fa-plus" />
										Import
									</Button>
								</div>
							)}
						{this.buildList()}
						<FormPopup
							opened={filterOpen}
							title="Filters"
							buttonText="Apply"
							formData={query}
							formConfig={this.getFilterConfig()}
							onSubmit={this.loadMembers}
							onClose={() => {
								this.data.filterOpen = false;
							}}
						/>
						<FormPopup
							opened={createOpen}
							title="Add Member"
							buttonText="Save"
							formData={formData}
							formConfig={this.getFormConfig()}
							onSubmit={this.onSaveMember}
							onClose={() => {
								this.data.createOpen = false;
								this.resetFormData();
							}}
						/>
					</div>
				)}
				<FormPopup
					opened={editOpen}
					title="Edit Member"
					buttonText="Save"
					formData={editing}
					formConfig={this.getFormConfig()}
					onSubmit={this.onSaveEditMember}
					onClose={() => {
						this.data.editing = {};
						this.data.editOpen = false;
						this.resetFormData();
					}}
				/>
			</div>
		);
	}
}
