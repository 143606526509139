import React, { Component } from 'react';
import { observer } from '@/utils/State';
import './transaction-page.scss';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { formatRelative } from 'date-fns';

@observer
export default class TransactionPage extends Component {
	constructor(props) {
		super(props);
		this.data = {};
		this.text = getCmpText('TransactionPageCmp', {
			amountPaidTxt: 'Amount Paid',
			amountRefundedTxt: 'Amount Refunded',
			datePaidTxt: 'Date Paid',
			paymentMethodTxt: 'Payment Method',
			summaryTxt: 'Summary',
			headerTxt: 'Receipt'
		});
	}

	render() {
		let { amountPaidTxt, amountRefundedTxt, datePaidTxt, paymentMethodTxt, summaryTxt, headerTxt } = this.text;
		let { transaction, onBack } = this.props;
		let amountPaid = (_.get(transaction, 'amount_captured', 0) / 100).toFixed(2);
		let amountRefunded = (_.get(transaction, 'amount_refunded', 0) / 100).toFixed(2);
		let total = (_.get(transaction, 'amount', 0) / 100).toFixed(2);
		let description = _.get(transaction, 'description', '');
		let dateCreated = new Date(_.get(transaction, 'created', '1') * 1000);
		let brand = _.get(transaction, 'payment_method_details.card.brand');
		let lastFour = _.get(transaction, 'payment_method_details.card.last4');
		return (
			<div className="page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="back hbox vcenter" onClick={onBack}>
							<i className="fal fa-chevron-left"></i>Back
						</div>
						<h1>
							{headerTxt} - {transaction.created}
						</h1>
					</div>
				</div>
				<div className="transaction-container">
					<div className="overview-data">
						<div className="overview-pair">
							<div className="key">{amountPaidTxt}</div>
							<div className="value">{amountPaid}</div>
						</div>
						<div className="overview-pair">
							<div className="key">{datePaidTxt}</div>
							<div className="value">{formatRelative(dateCreated, new Date())}</div>
						</div>
						<div className="overview-pair">
							<div className="key">{paymentMethodTxt}</div>
							<div className="value">
								<span className="brand">{brand}</span> - {lastFour}
							</div>
						</div>
					</div>
					<div className="summary">
						<div className="title">{summaryTxt}</div>
						<div className="line-item">
							<div className="description">{description}</div>
							<div className="amount">{total}</div>
						</div>
						<div className="line-item final-amount">
							<div className="description">{amountPaidTxt}</div>
							<div className="amount">{amountPaid}</div>
						</div>
						{
							_.get(transaction, 'refunded', false) &&
							<div className="line-item refund-amount">
							<div className="description">{amountRefundedTxt}</div>
							<div className="amount ">{amountRefunded}</div>
						</div>
						}
					</div>
				</div>
			</div>
		);
	}
}
