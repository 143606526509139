import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../../utils/State';
import { autoBind } from '../../../../utils/GeneralUtils';
import { Button, BlockTitle, List, ListItem, Toggle, ListInput } from 'framework7-react';
import './filter-menu.scss';

@observer
export default class FilterMenu extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showOrgFilter: false,
			showGroupFilter: false,
			showNetworkFilter: false,
			memberStatus: _.get(this.props, 'filters.memberStatus', 'all'),
			orgFilters: _.get(this.props, 'filters.orgs', []),
			hasEmail: _.get(this.props, 'filters.hasEmail', false),
			hasPhone: _.get(this.props, 'filters.hasPhone', false),
			groupFilters: _.get(this.props, 'filters.groups', []),
			networkFilters: _.get(this.props, 'filters.networks', []),
			showZeroMembers: _.get(this.props, 'filters.showZeroMembers', false),
			relationship: _.get(this.props, 'filters.relationship', 'all'),
			showPcpOnly: _.get(this.props, 'filters.showPcpOnly', false),
			registeredStatus: _.get(this.props, 'filters.registeredStatus', 'all'),
			lastLoginRange: _.get(this.props, 'filters.lastLoginRange', []),
			registeredRange: _.get(this.props, 'filters.registeredRange', []),
			showLoginCalendar: _.get(this.props, 'filters.lastLoginRange', []).length > 0,
			showRegisteredCalendar: _.get(this.props, 'filters.registeredRange', []).length > 0
		});
		autoBind(this);
	}

	filterByOrg(evt) {
		const orgId = evt.currentTarget.value;
		const currentOrgFilters = _.cloneDeep(this.data.orgFilters) || [];
		const orgData = this.props.organizations.find((org) => org._id === orgId);
		const orgGroupIds = _.get(orgData, 'groups', []).map((orgGroup) => orgGroup._id);
		const currentGroupFilters = _.cloneDeep(this.data.groupFilters) || [];
		const isSelected = currentOrgFilters.indexOf(orgId) > -1;
		if (isSelected) {
			this.data.orgFilters = currentOrgFilters.filter((filter) => filter !== orgId);
			this.data.groupFilters = currentGroupFilters.filter((filter) => orgGroupIds.indexOf(filter) < 0);
		} else {
			currentOrgFilters.push(orgId);
			this.data.orgFilters = currentOrgFilters;
		}
	}

	filterByGroup(evt) {
		const groupId = evt.currentTarget.value;
		const currentGroupFilters = _.cloneDeep(this.data.groupFilters) || [];
		const isSelected = currentGroupFilters.indexOf(groupId) > -1;
		if (isSelected) {
			this.data.groupFilters = currentGroupFilters.filter((filter) => filter !== groupId);
		} else {
			currentGroupFilters.push(groupId);
			this.data.groupFilters = currentGroupFilters;
		}
	}

	filterByNetwork(evt) {
		const networkId = evt.currentTarget.value;
		const currentNetworkFilters = _.cloneDeep(this.data.networkFilters) || [];
		const isSelected = currentNetworkFilters.indexOf(networkId) > -1;
		if (isSelected) {
			this.data.networkFilters = currentNetworkFilters.filter((network) => network !== networkId);
		} else {
			currentNetworkFilters.push(networkId);
			this.data.networkFilters = currentNetworkFilters;
		}
	}

	filterByStatus(evt) {
		const value = evt.currentTarget.value;
		this.data.memberStatus = value;
	}

	filterByUserStatus(evt) {
		const value = evt.currentTarget.value;
		this.data.registeredStatus = value;
	}

	filterByRelationship(evt) {
		const value = evt.currentTarget.value;
		this.data.relationship = value;
	}

	toggleShowZeroMembers() {
		const currentStatus = this.data.showZeroMembers;
		this.data.showZeroMembers = !currentStatus;
	}

	togglePhone() {
		const currentStatus = this.data.hasPhone;
		this.data.hasPhone = !currentStatus;
	}

	toggleEmail() {
		const currentStatus = this.data.hasEmail;
		this.data.hasEmail = !currentStatus;
	}

	toggleShowPcpOnly() {
		const currentStatus = this.data.showPcpOnly;
		this.data.showPcpOnly = !currentStatus;
	}

	toggleLoginRange() {
		const currentStatus = this.data.showLoginCalendar;
		this.data.showLoginCalendar = !currentStatus;
	}

	toggleRegisteredRange() {
		const currentStatus = this.data.showRegisteredCalendar;
		this.data.showRegisteredCalendar = !currentStatus;
	}

	async applyFilters() {
		const reqData = {
			orgs: this.data.orgFilters,
			groups: this.data.groupFilters,
			networks: this.data.networkFilters,
			registeredStatus: this.data.registeredStatus,
			memberStatus: this.data.memberStatus,
			showZeroMembers: this.data.showZeroMembers,
			showPcpOnly: this.data.showPcpOnly,
			relationship: this.data.relationship,
			hasPhone: this.data.hasPhone,
			hasEmail: this.data.hasEmail,
			lastLoginRange: this.data.showLoginCalendar ? this.data.lastLoginRange : [],
			registeredRange: this.data.showRegisteredCalendar ? this.data.registeredRange : []
		};
		this.props.submit(_.cloneDeep(reqData));
	}

	toggleOrgFilter() {
		this.data.showOrgFilter = !this.data.showOrgFilter;
	}

	toggleGroupFilter() {
		this.data.showGroupFilter = !this.data.showGroupFilter;
	}

	toggleNetworkFilter() {
		this.data.showNetworkFilter = !this.data.showNetworkFilter;
	}

	loginFilterChange(val) {
		this.data.lastLoginRange = val;
	}

	registeredFilterChange(val) {
		this.data.registeredRange = val;
	}

	render() {
		const { groups, organizations, networks } = this.props;
		const { showOrgFilter, showGroupFilter, orgFilters, groupFilters, networkFilters, showNetworkFilter } = this.data;
		const filteredGroupData = [];
		_.cloneDeep(organizations).map((org) => {
			org.groups.map((orgGroup) => {
				let orgCriteriaMet = true;
				if (orgFilters && orgFilters.length > 0) {
					orgCriteriaMet = orgFilters.indexOf(org._id) > -1;
				}
				if (orgCriteriaMet) {
					filteredGroupData.push(orgGroup);
				}
			});
		});
		const filteredNetworkData = networks || [];

		return (
			<div className="filter-menu">
				<div className="filter-header">
					<h2>
						Filter Menu
						<i className="fad fa-times-square" onClick={this.props.close} />
					</h2>
				</div>
				<div className="filter-body y-scroll">
					<List noHairlines>
						<ListItem
							title={`Filter By Org (${orgFilters ? orgFilters.length : 0} Selected)`}
							onClick={this.toggleOrgFilter}
							name="org-list-parent"
						>
							{showOrgFilter && <i slot="media" className="fad fa-minus-square animated blue" />}
							{!showOrgFilter && <i slot="media" className="fad fa-plus-square animated blue" />}
						</ListItem>
						{showOrgFilter &&
							organizations &&
							organizations.map((org) => {
								const isSelected = orgFilters && orgFilters.indexOf(org._id) > -1;
								return (
									<ListItem
										key={`org-filter-${org._id}`}
										checkbox
										title={org.name}
										name="org-list"
										onChange={this.filterByOrg}
										value={org._id}
										checked={isSelected}
										className="animated fadeIn"
									/>
								);
							})}
					</List>
					<List noHairlines>
						<ListItem
							title={`Filter By Group (${groupFilters ? groupFilters.length : 0} Selected)`}
							onClick={this.toggleGroupFilter}
							name="group-list-parent"
						>
							{showGroupFilter && <i slot="media" className="fad fa-minus-square animated blue" />}
							{!showGroupFilter && <i slot="media" className="fad fa-plus-square animated blue" />}
						</ListItem>
						{showGroupFilter &&
							filteredGroupData &&
							filteredGroupData
								.sort((a, b) => {
									const aGroupName = a.groupDisplayName ? a.groupDisplayName.toUpperCase() : a.groupId.toUpperCase();
									const bGroupName = b.groupDisplayName ? b.groupDisplayName.toUpperCase() : b.groupId.toUpperCase();
									if (aGroupName > bGroupName) {
										return 1;
									} else if (bGroupName > aGroupName) {
										return -1;
									} else {
										const aPlanName = a.groupName.toUpperCase();
										const bPlanName = b.groupName.toUpperCase();
										if (aPlanName > bPlanName) {
											return 1;
										} else if (bPlanName > aPlanName) {
											return -1;
										} else {
											return 0;
										}
									}
								})
								.map((group) => {
									const isSelected = groupFilters && groupFilters.indexOf(group._id) > -1;
									return (
										<ListItem
											key={`group-filter-${group._id}`}
											checkbox
											title={`${group.groupDisplayName || group.groupId} - ${group.groupName}`}
											name="group-list"
											onChange={this.filterByGroup}
											value={group._id}
											checked={isSelected}
											className="animated fadeIn"
										/>
									);
								})}
					</List>
					<List noHairlines>
						<ListItem
							title={`Filter By Network (${networkFilters ? networkFilters.length : 0} Selected)`}
							onClick={this.toggleNetworkFilter}
							name="group-list-parent"
						>
							{showNetworkFilter && <i slot="media" className="fad fa-minus-square animated blue" />}
							{!showNetworkFilter && <i slot="media" className="fad fa-plus-square animated blue" />}
						</ListItem>
						{showNetworkFilter &&
							filteredNetworkData &&
							filteredNetworkData.map((network) => {
								const isSelected = networkFilters && networkFilters.indexOf(network.networkId) > -1;
								return (
									<ListItem
										key={`group-filter-${network.networkId}`}
										checkbox
										title={network.productName ? `${network.productName} (${network.networkId})` : network.networkId}
										name="network-list"
										onChange={this.filterByNetwork}
										value={network.networkId}
										checked={isSelected}
										className="animated fadeIn"
									/>
								);
							})}
					</List>
					<List noHairlines>
						<ListItem>
							<span>Has Phone Number</span>
							<Toggle checked={this.data.hasPhone} onToggleChange={this.togglePhone} />
						</ListItem>
						<ListItem>
							<span>Has Email Address</span>
							<Toggle checked={this.data.hasEmail} onToggleChange={this.toggleEmail} />
						</ListItem>
						<ListItem>
							<span>Hide Groups with Zero Members:</span>
							<Toggle checked={!this.data.showZeroMembers} onToggleChange={this.toggleShowZeroMembers} />
						</ListItem>
						<ListItem>
							<span>Members with assigned PCP:</span>
							<Toggle checked={this.data.showPcpOnly} onToggleChange={this.toggleShowPcpOnly} />
						</ListItem>
						<ListItem className="last-login-toggle">
							<span>Filter by Last Login Date</span>
							<Toggle checked={this.data.showLoginCalendar} onToggleChange={this.toggleLoginRange} />
						</ListItem>
						<ListItem
							id="lastLoginPicker"
							className={`last-login-filter ${this.data.showLoginCalendar ? 'show-login-filter' : 'hidden'}`}
						/>
						<ListInput
							className="hidden last-login-input-field"
							label="Label Here"
							type="datepicker"
							placeholder="placeholder here"
							value={_.get(this.data, 'lastLoginRange', []).length > 0 ? this.data.lastLoginRange : [new Date()]}
							onCalendarChange={this.loginFilterChange}
							calendarParams={{
								dateFormat: 'mm/dd/yyyy',
								rangePicker: true,
								rangePickerMinDays: 1,
								maxDate: new Date(),
								containerEl: '#lastLoginPicker'
							}}
						/>
						<ListItem className="last-login-toggle">
							<span>Filter by Registered Date</span>
							<Toggle checked={this.data.showRegisteredCalendar} onToggleChange={this.toggleRegisteredRange} />
						</ListItem>
						<ListItem
							id="registeredPicker"
							className={`last-login-filter ${this.data.showRegisteredCalendar ? 'show-login-filter' : 'hidden'}`}
						/>
						<ListInput
							className="hidden last-login-input-field"
							label="Label Here"
							type="datepicker"
							placeholder="placeholder here"
							value={_.get(this.data, 'registeredRange', []).length > 0 ? this.data.registeredRange : [new Date()]}
							onCalendarChange={this.registeredFilterChange}
							calendarParams={{
								dateFormat: 'mm/dd/yyyy',
								rangePicker: true,
								rangePickerMinDays: 1,
								maxDate: new Date(),
								containerEl: '#registeredPicker'
							}}
						/>
					</List>
					<BlockTitle>Filter by Relationship to Member: </BlockTitle>
					<List>
						<ListItem
							radio
							title="Self"
							name="self"
							value="self"
							onChange={this.filterByRelationship}
							checked={this.data.relationship === 'self'}
						/>
						<ListItem
							radio
							title="Child"
							value="child"
							name="child"
							onChange={this.filterByRelationship}
							checked={this.data.relationship === 'child'}
						/>
						<ListItem
							radio
							title="Spouse"
							value="spouse"
							name="spouse"
							onChange={this.filterByRelationship}
							checked={this.data.relationship === 'spouse'}
						/>
						<ListItem
							radio
							title="Show All"
							value="all"
							name="all"
							onChange={this.filterByRelationship}
							checked={this.data.relationship === 'all'}
						/>
					</List>
					<BlockTitle>Filter by Member Status: </BlockTitle>
					<List>
						<ListItem
							radio
							title="Active"
							name="activeOnly"
							value="active"
							onChange={this.filterByStatus}
							checked={this.data.memberStatus === 'active'}
						/>
						<ListItem
							radio
							title="Inactive"
							value="inactive"
							name="activeOnly"
							onChange={this.filterByStatus}
							checked={this.data.memberStatus === 'inactive'}
						/>
						<ListItem
							radio
							title="Show All"
							value="all"
							name="activeOnly"
							onChange={this.filterByStatus}
							checked={this.data.memberStatus === 'all'}
						/>
					</List>
					<BlockTitle>Filter by User Status: </BlockTitle>
					<List>
						<ListItem
							radio
							title="Registered"
							name="registeredUserStatus"
							value="registeredMembers"
							onChange={this.filterByUserStatus}
							checked={this.data.registeredStatus === 'registeredMembers'}
						/>
						<ListItem
							radio
							title="Not Yet Registered"
							value="unregisteredMembers"
							name="registeredUserStatus"
							onChange={this.filterByUserStatus}
							checked={this.data.registeredStatus === 'unregisteredMembers'}
						/>
						<ListItem
							radio
							title="Show All"
							value="all"
							name="registeredUserStatus"
							onChange={this.filterByUserStatus}
							checked={this.data.registeredStatus === 'all'}
						/>
					</List>
				</div>
				<div className="filter-footer">
					<Button round fill onClick={this.applyFilters}>
						Apply Filters
					</Button>
					<Button raised round onClick={this.props.resetFilters}>
						Reset All Filters
					</Button>
				</div>
			</div>
		);
	}
}
