import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import StorageService from '@/services/StorageService';
import LanguageSelection from '@/components/language-selection/LanguageSelection';
import userStore from '@/stores/UserStore';
import appStore from '@/stores/AppStore';
import AnalyticsService from '@/services/AnalyticsService';
import GradiantStack from '@/components/gradiant-stack/GradiantStack';
import FingerprintSvg from '@/assets/finger_print.svg';
import FaceIdSvg from '@/assets/face_id.svg';
import biometricsService from '@/services/BiometricsService';
import signinSVG from '@/assets/signin.svg';
import BasicInput from '@/components/basic-input/BasicInput';
import BiometricsPrompt from '../biometrics-prompt/BiometricsPrompt';
import AccountVerification from '../account-verification/AccountVerification';

import './employer-signin.scss';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
export default class EmployerSignin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			rememberMe: false,
			email: '',
			password: '',
			emailValid: false,
			forgotPassword: false,
			showBioEnrollmentPrompt: false,
			showBioLoginCta: false,
			bioType: '',
			verificationRequired: false
		});
		this.text = getCmpText('EmployerSignin', {
			loggingIn: 'Logging In...',
			verificationError: 'Please verify your account before signing in',
			forgotPasswordSuccess: 'Please check your email to reset your password',
			forgotPasswordFailure: 'Unable to complete password reset',
			forgotPasswordModalTitle: 'Reset Password',
			signInTitle: 'Sign In',
			sendPasswordResetCta: 'Send Password Reset',
			forgotPassword: 'Forgot Password?',
			signInCta: 'Sign in',
			back: 'Back',
			emailPlaceholder: 'Enter your email',
			passwordPlaceholder: 'Enter your password',
			rememberMe: 'Remember me',
			cancelPasswordReset: 'Return to sign in',
			signUpCta: 'Sign up',
			signUpText: "Don't have an account? ",
			biometricsLogin: 'Sign in with ',
			biometricsFaceLabel: 'FaceID',
			biometricsThumbLabel: 'Fingerprint',
			biometricsDisabledAlert:
				'Please visit the CareValet application settings and grant permission to login with ',
			biometricsCredentialsError:
				'temporarily disabled as account settings have changed.  Please manually login to update enrollment.',
			biometricsGenericError: 'Sign in failed, please try again',
			biometricsEnrollmentLabel: 'Enable sign in with ',
			loginError:
				'Unable to sign in.  Please check your username and password and try again.'
		});
		autoBind(this);
	}

	async componentDidMount() {
		try {
			const bioIsAvailable =
				await biometricsService.checkBiometricsEnrollmentStatus();
			const bioType = await biometricsService.getBiometricsType();
			this.data.bioType = bioType;
			this.data.showBioLoginCta = bioIsAvailable;
		} catch (err) {
			this.data.showBioLoginCta = false;
		}
		try {
			const userLoginInfo = await storageService.getValue('app', 'userLogin');
			const loginInfo = JSON.parse(userLoginInfo);
			const rememberMe = loginInfo && loginInfo.rememberMe;
			this.data.rememberMe = rememberMe;
			if (rememberMe) {
				this.data.email = loginInfo && loginInfo.loginEmail;
			}
		} catch (err) {
			console.log('error getting remember me status: ', err);
			this.data.rememberMe = false;
			this.data.email = '';
		}
	}

	handleEmailCheck(isValid) {
		const email = this.data.email || '';
		this.data.emailValid = isValid && email.length > 0;
	}

	onInputChange(evt) {
		const isEmailValid = this.$f7.input.validate('.email-address');
		const target = evt.currentTarget;
		const field = target.name;
		const value = target.value;
		this.data[field] = value;
		this.data.emailValid = isEmailValid && value.length > 1;
	}

	onBack() {
		if (this.data.forgotPassword) {
			this.closePasswordReset();
			return;
		}
		if (this.props.onBack) {
			this.props.onBack('employer-signin');
		}
	}

	onNext() {
		if (this.props.onNext) {
			this.props.onNext('employer-choice');
		}
	}

	rememberToggle() {
		this.data.rememberMe = !this.data.rememberMe;
	}

	async sendPasswordResetEmail() {
		const email = this.data.email;
		if (!email) return;
		this.$f7.dialog.preloader();
		try {
			const fbResp = await firebase.auth().sendPasswordResetEmail(email);
			analytics.trackWithData('password_reset', {
				success: true,
				email: email
			});
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.forgotPasswordSuccess);
			this.closePasswordReset();
		} catch (err) {
			this.$f7.dialog.close();
			analytics.trackWithData('password_reset', {
				success: false,
				email: email
			});
			console.log('error sending password reset email: ', err);
			this.$f7.dialog.alert(this.text.forgotPasswordFailure);
		}
	}

	forgotPassword() {
		const email = this.data.email || '';
		const isEmailValid = this.$f7.input.validateInputs('.email-address');
		this.data.emailValid = isEmailValid && email.length > 0;
		this.data.forgotPassword = true;
	}

	async onSignIn() {
		this.$f7.dialog.preloader(this.text.loggingIn);
		let { email, password, emailValid, rememberMe } = this.data;
		userStore.isCreatingUser = false;
		if (userStore.isLoggedIn) {
			firebase.auth().signOut();
		}
		try {
			const bioShouldPrompt =
				await biometricsService.shouldPromptBiometricsEnrollment();
			if (bioShouldPrompt) {
				userStore.isCreatingUser = true;
			}
			const loginResult = await firebase
				.auth()
				.signInWithEmailAndPassword(email, password);
			const userEmailVerified = _.get(loginResult, 'user.emailVerified', false);
			appStore.showReauthLoader = false;
			if (!userEmailVerified) {
				this.$f7.dialog.close();
				analytics.trackWithData('login_error', {
					reason: 'account_verification_required',
					email: email
				});
				userStore.isCreatingUser = true;
				this.data.verificationRequired = true;
				this.$f7.dialog.alert(this.text.verificationError);
			} else {
				this.data.verificationRequired = false;
				this.data.showBioEnrollmentPrompt = bioShouldPrompt;
				this.$f7.dialog.close();
				if (bioShouldPrompt) {
					firebase.auth().signOut();
				}
			}
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.loginError);
			analytics.trackWithData('login_error', {
				reason: 'unknown',
				error: err && err.toString(),
				email: email
			});
		}
		if (rememberMe && emailValid) {
			let userLogin = { rememberMe: rememberMe, loginEmail: email };
			storageService
				.setValue('app', 'userLogin', JSON.stringify(userLogin))
				.catch((err) => console.log(err));
		} else {
			storageService.clearStoreKey('app', 'userLogin');
		}
	}

	closePasswordReset() {
		this.data.forgotPassword = false;
	}

	async bioLoginInit() {
		this.$f7.dialog.preloader();
		try {
			const bioLoginCreds = await biometricsService.handleLoginRequest();
			if (bioLoginCreds) {
				const username = bioLoginCreds.username;
				const password = bioLoginCreds.password;
				if (username && password) {
					this.data.email = username;
					this.data.password = password;
					const loginResult = await firebase
						.auth()
						.signInWithEmailAndPassword(username, password);
					this.$f7.dialog.close();
				} else {
					this.$f7.dialog.close();
					this.handleBioLoginError();
				}
			} else {
				this.$f7.dialog.close();
				this.handleBioLoginError();
			}
		} catch (err) {
			this.$f7.dialog.close();
			console.log('error processing bio login: ', err);
			analytics.trackWithData('login_error', {
				reason: 'bio_login_failed',
				error: err && err.toString()
			});
			this.handleBioLoginError();
		}
	}

	async handleBioLoginError() {
		const bioTypeLabel =
			this.data.bioType === 'face'
				? this.text.biometricsFaceLabel
				: this.text.biometricsThumbLabel;
		const alertMessage = `${bioTypeLabel} ${this.text.biometricsCredentialsError}`;
		try {
			await biometricsService.turnOffBiometrics(false);
		} catch (err) {
			console.log('error turning off bio service');
		}
		this.$f7.dialog.alert(alertMessage);
	}

	closeBiometricsPrompt(email, password) {
		this.data.showBioEnrollmentPrompt = false;
		userStore.isCreatingUser = false;
		firebase.auth().signInWithEmailAndPassword(email, password);
	}

	clearVerificationRequired(type) {
		this.data.verificationRequired = false;
	}

	render() {
		let { rememberMe, email, password, emailValid, forgotPassword } = this.data;
		const titleText = forgotPassword
			? this.text.forgotPasswordModalTitle
			: this.text.signInTitle;
		const bioTypeLabel =
			this.data.bioType === 'face'
				? this.text.biometricsFaceLabel
				: this.text.biometricsThumbLabel;
		const bioLoginBtnText = `${this.text.biometricsLogin} ${bioTypeLabel}`;
		const bioIcon = this.data.bioType === 'face' ? FaceIdSvg : FingerprintSvg;
		if (this.data.showBioEnrollmentPrompt) {
			return (
				<BiometricsPrompt
					username={_.clone(this.data.email)}
					password={_.clone(this.data.password)}
					closePrompt={this.closeBiometricsPrompt}
				/>
			);
		}

		return (
			<Fragment>
				<GradiantStack stack="1" animate={true} id="employer-signin" />
				<div className={`employer-signin-page y-scroll`}>
					<div className="header-ctn hbox vcenter">
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={signinSVG} className="herosvg" />
						<h1>{titleText}</h1>
						{!userStore.isLoggedIn && (
							<BasicInput
								validate
								name="email"
								type="email"
								placeholder={this.text.emailPlaceholder}
								value={email}
								onChange={this.onInputChange}
								onValidate={this.handleEmailCheck}
								className="email-address"
							/>
						)}
						{!userStore.isLoggedIn && !forgotPassword && (
							<BasicInput
								name="password"
								type="password"
								placeholder={this.text.passwordPlaceholder}
								value={password}
								onChange={this.onInputChange}
							/>
						)}
						{forgotPassword && (
							<Button
								fill
								large
								className="round-btn blue"
								disabled={!emailValid}
								onClick={this.sendPasswordResetEmail}
							>
								{this.text.sendPasswordResetCta}{' '}
								<i className="far fa-long-arrow-right"></i>
							</Button>
						)}
						{!forgotPassword && !this.data.verificationRequired && (
							<Button
								fill
								large
								className="round-btn blue"
								disabled={!(emailValid && password.length > 0)}
								onClick={this.onSignIn}
							>
								{this.text.signInCta}{' '}
								<i className="far fa-long-arrow-right"></i>
							</Button>
						)}
						{this.data.verificationRequired && (
							<AccountVerification
								email={email}
								onNext={this.clearVerificationRequired}
								disabled={false}
								id="employer-signin"
							/>
						)}
						<div className="actions hbox vcenter">
							<div
								className="remember-toggle hbox vcenter"
								onClick={this.rememberToggle}
							>
								<div className="toggle-indicator vbox vcenter hcenter">
									{rememberMe && <i className="fad fa-dot-circle"></i>}
								</div>
								<div className="text">{this.text.rememberMe}</div>
							</div>
							<div className="w-100"></div>
							{!forgotPassword && (
								<div className="password-reset" onClick={this.forgotPassword}>
									{this.text.forgotPassword}
								</div>
							)}
							{forgotPassword && (
								<div
									className="password-reset"
									onClick={this.closePasswordReset}
								>
									{this.text.cancelPasswordReset}
								</div>
							)}
						</div>
						{this.data.showBioLoginCta && (
							<div
								className="bio-box hbox vcenter biometrics-container"
								onClick={this.bioLoginInit}
							>
								<img className="bio-icon" src={bioIcon} />
								<div>{bioLoginBtnText}</div>
							</div>
						)}
						<div className="text-link hbox vcenter" onClick={this.onNext}>
							<div className="text">{this.text.signUpText}</div>
							<div className="cta">{this.text.signUpCta}</div>
						</div>
						<div
							className="hbox vcenter tos-toggle"
							onClick={() => {
								window.open('https://carevalet.com/terms.html', '_blank');
							}}
						>
							<div className="termslink">
								<span>Terms Of Service</span>
							</div>
						</div>
						<div
							className="hbox vcenter tos-toggle"
							onClick={() => {
								window.open('https://carevalet.io/privacy.html', '_blank');
							}}
						>
							<div className="termslink">
								<span>Privacy Statement</span>
							</div>
						</div>
						<LanguageSelection />
						{location.hostname === 'localhost' && (
							<Button
								onClick={() => {
									this.$f7.dialog.alert(
										'You want to leave CareValet & go to Express? Hit ESC to cancel',
										'Are you sure?',
										() => {
											appStore.isExpressPortal = true;
											this.$f7.views.main.router.navigate('/express', {
												clearPreviousHistory: true,
												reloadAll: true
											});
										}
									);
								}}
							>
								Localhost go to Express
							</Button>
						)}
						<div className="logo-ctn vbox vcenter hcenter">
							<i className="top-icon icon-logo" />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
