import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Fab, FabButton, FabButtons, Icon, Popup } from 'framework7-react';
import appStore from '@/stores/AppStore';
import { getCmpText } from '@/utils/Localization';
import CloudAPI from '@/services/CloudAPI';
import _ from 'lodash';
import './wallet-popup.scss';
import WalletHome from './wallet-home/WalletHome';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ShareDocumentPanel from './share-document-panel/ShareDocumentPanel';
import UserService from '@/services/UserService';

@observer
export default class WalletPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loadingDocuments: false,
			documents: [],
			cardFront: '',
			cardBack: '',
			currentView: 'home',
			activeDocument: null,
			activeDependent: null,
			shareDocument: {
				opened: false,
				isInsuranceCard: false,
				shareMethod: 'email',
				documentUri: null
			}
		});
		this.text = getCmpText('WalletPopup', {
			closeTxt: 'Close',
			walletHeaderTxt: 'Wallet',
			insuranceCardTxtHeadline: 'Insurance card',
			documentsHeaderTxt: 'Plan documents',
			accumulatorHeaderTxt: 'Accumulators',
			walletBackTxt: 'Back',
			emailLabel: 'Email',
			faxLabel: 'Fax',
			downloadLabel: 'Download',
			otherLabel: 'Other',
			shareSuccess: 'Success!'
		});
		autoBind(this);
	}

	handleOpen() {
		this.fetchPlanDocuments();
	}

	handleClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handleViewDocument(docObj) {
		if (appStore.demoMode) {
			CloudAPI.getUserPlanDocumentDemo(docObj._id, appStore.demoMode)
				.then((res) => {
					let document = {};
					document.uri = res.fileUrl;
					document.fileType = res.fileData.fileType;
					document.name = docObj.name;
					document.fileName = res.fileData.fileName;
					this.data.activeDocument = [document];
					this.data.currentView = 'previewDocument';
				})
				.catch((e) => {
					console.log('handle view error');
				});
		} else {
			CloudAPI.getUserPlanDocument(docObj._id)
				.then((res) => {
					let document = {};
					document.uri = res.fileUrl;
					document.fileType = res.fileData.fileType;
					document.name = docObj.name;
					document.fileName = res.fileData.fileName;
					this.data.activeDocument = [document];
					this.data.currentView = 'previewDocument';
				})
				.catch((e) => {
					console.log('handle view error');
				});
		}
	}

	handlePreviewBack() {
		this.data.currentView = 'home';
		this.data.activeDocument = null;
	}

	handleViewId() {
		this.data.currentView = 'previewId';
	}

	fetchPlanDocuments() {
		this.data.loadingDocuments = true;
		if (appStore.demoMode) {
			CloudAPI.getUserPlanDocumentsDemo(appStore.demoMode)
				.then((res) => {
					this.data.loadingDocuments = false;
					this.data.documents = res;
				})
				.catch((e) => {
					this.data.loadingDocuments = false;
					console.log(e);
				});
		} else {
			CloudAPI.getUserPlanDocuments()
				.then((res) => {
					this.data.loadingDocuments = false;
					this.data.documents = res;
				})
				.catch((e) => {
					this.data.loadingDocuments = false;
					console.log(e);
				});
		}
	}
	handleClosed() {}

	handleSelectDependent(dependent) {
		this.data.activeDependent = dependent;
	}

	handleDeselectDependent() {
		this.data.activeDependent = null;
	}

	shareError(err) {
		this.$f7.dialog.close();
		this.$f7.dialog.alert(this.text.shareDocumentError);
		if (appStore.isIos) {
			try {
				this.$f7.statusbar.overlaysWebView(false);
				this.$f7.statusbar.overlaysWebView(true);
			} catch (err) {
				console.log('error resetting status bar state');
			}
		}
	}

	async triggerNavigatorShare(documentObj) {
		const shareData = {
			title: documentObj.fileName,
			text: documentObj.fileName,
			url: documentObj.uri
		};
		navigator.share(shareData);
	}

	triggerDeviceShareMenu(docObj) {
		try {
			let images = [docObj.uri];
			const options = {
				files: images,
				url: 'https://www.carevalet.io',
				chooserTitle: this.text.shareCta
			};
			window.plugins.socialsharing.shareWithOptions(
				options,
				this.shareSuccessful,
				this.shareError
			);
			return;
		} catch (err) {
			this.shareError(err);
		}
	}
	shareSuccessful(data) {
		this.$f7.dialog.close();
		if (appStore.isIos) {
			try {
				this.$f7.statusbar.overlaysWebView(false);
				this.$f7.statusbar.overlaysWebView(true);
			} catch (err) {
				console.log('error resetting status bar state');
			}
		}
	}

	triggerDesktopDownload(documentObj) {
		this.$f7.dialog.close(); //close any open dialogs before it loses scope
		const trigger = document.createElement('a');

		trigger.href = documentObj.uri;
		trigger.target = appStore.isIos ? '_self' : '_blank';
		trigger.download = documentObj.fileName;
		trigger.click();
	}

	handleShareDocumentOpen(shareMethod, isInsuranceCard, documentObj) {
		if (shareMethod === 'download') {
			try {
				if (documentObj) {
					if (appStore.isPWA()) {
						this.triggerDesktopDownload(documentObj);
					} else {
						this.triggerDesktopDownload(documentObj);
					}
				} else {
					this.shareError('No data returned');
				}
			} catch (err) {
				this.shareError(err);
			}
		} else if (shareMethod === 'other') {
			if (appStore.isApp()) {
				this.triggerDeviceShareMenu(documentObj);
			} else {
				this.triggerNavigatorShare(documentObj);
			}
		} else {
			this.data.shareDocument.isInsuranceCard = isInsuranceCard;
			this.data.shareDocument.shareMethod = shareMethod;
			this.data.shareDocument.opened = true;
			this.data.shareDocument.document = documentObj;
		}
	}

	handleShareDocumentClose() {
		this.data.shareDocument.opened = false;
	}

	async handleShareDocument(data) {
		const {
			shareDocument: { shareMethod, isInsuranceCard, document }
		} = this.data;

		let requestData = {};
		requestData.method = shareMethod;
		requestData[shareMethod] = {};
		requestData[shareMethod].to = data.to;
		requestData.docType = isInsuranceCard ? 'idcard' : 'documents';
		requestData.dependent = this.data.activeDependent || null;
		if (!isInsuranceCard) {
			requestData.document = document;
		}

		if (shareMethod === 'email') {
			requestData[shareMethod].subject = data.subject;
			requestData[shareMethod].body = data.body;
		}

		if (this.props.activeDependent) {
			requestData.dependent = this.props.activeDependent._id;
		}

		await this.shareDocuments(requestData);
	}

	async shareDocuments(requestData) {
		this.$f7.dialog.preloader();
		return UserService.sharePlanDocuments(requestData)
			.then(() => {
				// if (skipToast) return;
				let toast = this.$f7.toast.create({
					position: 'center',
					closeTimeout: 2000,
					icon: `<i class="fad fa-check large-icon-toast" ></i>`,
					text: this.text.shareSuccess
				});
				toast.open();
				this.handleShareDocumentClose();
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log('error processing request: ', err);
				this.$f7.dialog.close();
				this.$f7.dialog.alert(
					_.get(
						JSON.parse(err.response),
						'message',
						'An error occured, please try again.'
					)
				);
			});
	}

	renderWalletView() {
		const {
			currentView,
			loadingDocuments,
			documents,
			activeDocument,
			activeDependent,
			shareDocument
		} = this.data;
		return (
			<>
				{!activeDocument && (
					<WalletHome
						onClose={this.handleClose}
						loadingDocuments={loadingDocuments}
						documents={documents}
						onViewId={this.handleViewId}
						activeDependent={activeDependent}
						onSelectDependent={this.handleSelectDependent}
						onDeselectDependent={this.handleDeselectDependent}
						onViewDocument={this.handleViewDocument}
						opened={currentView === 'home'}
						onShareDocument={this.handleShareDocumentOpen}
					/>
				)}
				<DocumentPreview
					documentObj={activeDocument}
					onBack={this.handlePreviewBack}
					backTxt={this.text.walletBackTxt}
					faxLabel={this.text.faxLabel}
					emailLabel={this.text.emailLabel}
					otherLabel={this.text.otherLabel}
					downloadLabel={this.text.downloadLabel}
					opened={currentView === 'previewDocument'}
					onShareDocument={this.handleShareDocumentOpen}
				/>
			</>
		);
	}

	render() {
		const { opened } = this.props;
		const { shareDocument } = this.data;

		return (
			<Popup
				opened={opened}
				className={`wallet-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.handleClosed}
				onPopupOpened={this.handleOpen}
				onPopupOpen={this.handleOpen}
			>
				{opened && <>{this.renderWalletView()}</>}

				<ShareDocumentPanel
					opened={shareDocument.opened}
					onClose={this.handleShareDocumentClose}
					shareMethod={shareDocument.shareMethod}
					isInsuranceCard={shareDocument.isInsuranceCard}
					onSubmit={this.handleShareDocument}
				/>
			</Popup>
		);
	}
}

const DocumentPreview = observer((props) => {
	const {
		documentObj,
		onBack,
		backTxt,
		opened,
		style,
		emailLabel,
		faxLabel,
		downloadLabel,
		otherLabel,
		shareLabel,
		onShareDocument,
		onFabClick
	} = props;
	if (opened) {
		return (
			<div className="wallet-document-preview" style={style}>
				<div className="page-wrapper">
					<div className="global-header-popup">
						<Fab
							position="right-bottom"
							slot="fixed"
							onClick={onFabClick}
							text={shareLabel}
						>
							<Icon ios="f7:plus" aurora="f7:add" md="material:add"></Icon>
							<Icon
								ios="f7:multiply"
								aurora="f7:close"
								md="material:close"
							></Icon>
							<FabButtons className="share-document-buttons" position="top">
								<FabButton
									onClick={() => {
										onShareDocument('email', false, documentObj[0]);
									}}
								>
									{emailLabel}
								</FabButton>
								{/* NOTE: TWILIO Deprecated https://support.twilio.com/hc/en-us/articles/223136667-Fax-Support-on-Twilio */}
								{/* <FabButton
									onClick={() => {
										onShareDocument('fax', false, documentObj[0]);
									}}
								>
									{faxLabel}
								</FabButton> */}
								{!appStore.isApp() && (
									<FabButton
										onClick={() => {
											onShareDocument('download', false, documentObj[0]);
										}}
									>
										{downloadLabel}
									</FabButton>
								)}
								{navigator.share && (
									<FabButton
										onClick={() => {
											onShareDocument('other', false, documentObj[0]);
										}}
									>
										{otherLabel}
									</FabButton>
								)}
							</FabButtons>
						</Fab>
						<div className="header-ctn hbox vcenter">
							<h1 className="grow-1">{documentObj[0].name}</h1>
							<div className="back hbox vcenter " onClick={onBack}>
								{backTxt}
							</div>
						</div>
					</div>

					<DocViewer
						config={{
							header: {
								disableHeader: true,
								disableFileName: true,
								retainURLParams: false
							}
						}}
						pluginRenderers={DocViewerRenderers}
						documents={_.cloneDeep(documentObj)}
						className="doc-viewer"
					/>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
});
