import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsivePie } from '@nivo/pie';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './pie-chart.scss';

@observer
export default class PieChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	formatData() {
		const reportData = _.get(this.props, 'data.data') || [];
		const newFormattedData = reportData.map((reportItem) => {
			const reportItemTotal = reportItem.data.reduce((total, curr) => total + curr.y, 0);
			const formattedItem = {
				id: reportItem.id,
				label: reportItem.label,
				value: reportItemTotal,
				color: reportItem.color
			};
			return formattedItem;
		});
		const totalCount = newFormattedData.reduce((total, curr) => total + curr.value, 0);
		const percentageFormattedData = newFormattedData.map((x) => {
			const newValue = ((x.value / totalCount) * 100).toFixed(2);
			return { ...x, value: newValue };
		});
		const isPercentage = _.get(this.props, 'selectedFilters.showPercentages');
		return { data: isPercentage ? percentageFormattedData : newFormattedData };
	}

	getColor(data) {
		return data.data.color;
	}

	handleClick(data) {
		console.log('item was clicked: ', data);
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const reportData = this.formatData();
		const chartConfig = chartProperties.config;
		return (
			<ResponsivePie
				data={reportData.data}
				borderWidth={1}
				borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
				radialLabel="label"
				radialLabelsSkipAngle={10}
				radialLabelsTextColor="#333333"
				radialLabelsLinkColor={{ from: 'color' }}
				sliceLabelsSkipAngle={10}
				sliceLabelsTextColor="#ffffff"
				onClick={this.handleClick}
				enableRadialLabels={!isMobile}
				innerRadius={0.5}
				padAngle={0.7}
				cornerRadius={3}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				margin={{ top: isMobile ? 20 : 20, right: 50, bottom: 50, left: 50 }}
				theme={{
					fontSize: 12,
					grid: {
						line: {
							strokeDasharray: '4 4'
						}
					}
				}}
			/>
		);
	}
}
