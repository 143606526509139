import React, { Component } from 'react';
import { observer } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import IdThumbnail from '@/components/_EXPRESS/id-thumbnail/IdThumbnail';
import './id-thumbnail-list.scss';
import { Preloader } from 'framework7-react';

@observer
export default class IdThumbnailList extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	renderThumbnails() {
		const { cards, onEdit, onDelete } = this.props;
		let thumbs = [];
		cards.forEach((card) => {
			thumbs.push(
				<IdThumbnail
					key={card._id}
					onEdit={() => onEdit(card)}
					onDelete={(evt) => {
						evt.stopPropagation();
						onDelete(card);
					}}
					// onSetDefault={() => onSetDefault(card)}
					name={card.name}
					id={card._id}
					thumbnailKey={card.thumbnails?.thumbnailMedium}
					side={card.side}
					defaultCard={card.defaultCard}
					lastUpdated={card?.lastUpdated || Date.now()}
				/>
			);
		});
		return thumbs;
	}

	render() {
		const { loading, cards } = this.props;

		if (loading) {
			return (
				<div className="hbox vcenter hcenter w-100">
					<Preloader size={32} color="blue"></Preloader>
				</div>
			);
		} else {
			return <div className="id-thumbnails-list hbox">{this.renderThumbnails()}</div>;
		}
	}
}
