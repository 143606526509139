import React, { Component } from 'react';
import './provider-home.scss';
import userStore from '../../../stores/UserStore';
export default class ProviderHome extends Component {
	componentDidMount() {
		if (!userStore.isLoggedIn) {
			this.$f7.views.appShell.router.navigate('/providerlogin');
			setTimeout(() => {
				this.$f7.views.appShell.router.refreshPage();
			}, 100);
		}
	}

	render() {
		if (!userStore.isLoggedIn) return '';
		return (
			<div className="provider-home-page animated fadeInUp">
				<h1>Provider Portal Home Page</h1>
			</div>
		);
	}
}
