import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { Button, Popup } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import EmailSVG from '../../assets/sendemail.svg';
import GradiantStack from '../../components/gradiant-stack/GradiantStack';

@observer
export default class EmailConfirmPopup extends Component {
	constructor(props) {
		super();
		this.data = observable({});

		this.text = getCmpText('EmailConfirmPopup', {
			subtitle: 'A confirmation email has been sent to',
			message:
				'Please check your email and click the verification link to complete registration. If you do not see this email, please check your spam or other message folders.',
			next: 'Sign in',
			resend: "I didn't receive an email"
		});
		autoBind(this);
	}

	onNext() {
		this.$f7.popup.close('.email-sent-popup');
	}

	async onResend() {
		this.$f7.dialog.preloader();
		try {
			await this.props.onResend();
		} catch (err) {
			console.log('error resending email: ', err);
		}
		this.$f7.dialog.close();
	}

	handlePopupClosed() {
		this.props.onNext();
	}

	render() {
		return (
			<div>
				<Popup
					opened={this.props.opened}
					className={`two-factor-popup email-sent-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupClosed={this.handlePopupClosed}
				>
					<GradiantStack stack="1" animate={false} consumer={false} id="email-confirm" />
					<div className="email-confirm-popup animated fadeIn y-scroll">
						<img src={EmailSVG} className="herosvg" />
						<div className="subtitle">{this.text.subtitle}</div>
						<h1>{this.props.email}</h1>
						<div className="spam-check">{this.text.message}</div>
						<Button fill large className={`round-btn`} onClick={this.onNext}>
							{this.text.next}
						</Button>
						<Button fill large className={`round-btn red`} onClick={this.onResend}>
							{this.text.resend}
						</Button>
					</div>
				</Popup>
			</div>
		);
	}
}
