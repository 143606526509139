import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Rect } from 'react-konva';
import CanvasFactory from '@/factories/CanvasFactory';
@observer
export default class CanvasHighlight extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		// const {
		// 	editorStore: { highlight, selectedNode }
		// } = this.props;
		let {highlight} = CanvasFactory.getActiveStore();
		if (highlight && highlight.active) {
			return (
				<Rect
					stroke="#0085FF"
					strokeWidth={2}
					strokeEnabled={highlight.active}
					x={highlight.x}
					y={highlight.y}
					width={highlight.width}
					height={highlight.height}
					listening={false}
				/>
			);
		} else {
			return <></>;
		}
	}
}
