import React, { Component } from 'react';
import _ from 'lodash';
import { Chip, Swiper, SwiperSlide } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import AnalyticsService from '@/services/AnalyticsService';
import appStore from '@/stores/AppStore';
import ConfigService from '@/services/ConfigService';
import UserService from '@/services/UserService';
import ExternalService from '@/services/ExternalService';
import CareCoin from '@/assets/CareCoin';
import userStore from '@/stores/UserStore';
import signupSVG from '../../assets/signup.svg';
import construction from '../../assets/underConstruction.svg';
import balanceBilling from '@/assets/invoice.png';
import procedureSearchImg from '@/assets/procedureSearch.png';
import surgeryPng from '@/assets/surgery.png';
import CloudAPI from '@/services/CloudAPI';
const analytics = new AnalyticsService();

@observer
export default class HomeCoverflow extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			test: true
		});
		this.text = getCmpText('HomeCoverflow', {
			telehealthTitle: 'Call Now',
			telehealthText1: 'Telehealth $<%= copay %> co-pay',
			telehealthText2: 'Speak to a licensed doctor in minutes',
			prescriptionTitle: 'My Prescriptions',
			presccriptionSubText: 'Coupons, price compare, and more!',
			cancel: 'Cancel',
			telehealth: 'Talk to a Physician Now',
			loading: 'Loading',
			externalText: 'Additional Services',
			externalText2: 'Tap to access additional services',
			enrollTitle: 'Enroll In Telehealth',
			enrollText:
				"We just need a little bit more info and you're off to the races!",
			careCredits: 'Care Credits',
			creditAmountText: '10% off pack of 3',
			savingsAmount: 'Save $24',
			telehealthActivationInProgress: 'Activation in Progress',
			telehealthActivationDelay:
				"We just need a little bit more info and you're off to the races!",
			balanceBillTitle: 'Does your bill look wrong?',
			balanceBillMessage: 'Tap here for help',
			surgeryText: 'Tap here for guidance',
			surgeryTitle: 'Schedule a Surgery',
			procedureSearchTitle: 'Procedure Search',
			procedureSearchMessage: 'Get cost estimates for 500 different procedures!'
		});
		autoBind(this);
	}

	async componentDidMount() {
		try {
			const subs = await CloudAPI.getSubscriptions();
			userStore.setSubscriptionState(subs);
		} catch (err) {
			console.log('CPM ERROR:: ', err);
		}
	}

	onPrescriptions() {
		appStore.activeTab = 'prescription';
	}

	onExternalServices() {
		analytics.track('Open External Services Menu');
		appStore.externalServicesOpen = true;
	}

	onBalanceBillClick() {
		appStore.balanceBillOpen = true;
	}

	onProcedureSearchClick() {
		appStore.procedureSearchOpen = true;
	}

	buildConsumerTelehleathCard() {
		let { telehealthTitle, telehealthText2 } = this.text;
		if (!UserService.isConsumerEnrolled()) {
			return (
				<div
					className={`flowcard blue`}
					onClick={() => {
						analytics.track('Open Telehealth Enrollment Form');
						appStore.openEnrollment();
					}}
				>
					<img src={signupSVG} className="enroll-img"></img>
					<div className="title">{this.text.enrollTitle}</div>
					<div className="bottom-text">{this.text.enrollText}</div>
				</div>
			);
		} else if (
			UserService.isConsumerEnrolled() &&
			!UserService.isConsumerActivated()
		) {
			return (
				<div
					className={`flowcard blue`}
					onClick={() => {
						analytics.track('Check Telehealth Enrollment Status');
						ExternalService.checkTelehealthActivationStatus();
					}}
				>
					<img src={construction} className="construction" />
					<div className="title">
						{this.text.telehealthActivationInProgress}
					</div>
					<div className="bottom-text">
						{this.text.telehealthActivationDelay}
					</div>
				</div>
			);
		} else if (
			UserService.isConsumerEnrolled() &&
			UserService.isConsumerActivated()
		) {
			return (
				<div
					className={`flowcard blue`}
					onClick={() => {
						analytics.track('Consumer Telehealth Init');
						ExternalService.telehealthInit();
					}}
				>
					<i className="fad fa-phone-alt" />
					<div className="title">{telehealthTitle}</div>
					<div className="bottom-text">{telehealthText2}</div>
					<div className="credit-ticker hbox vcenter">
						<CareCoin />
						<div className="count">
							{_.get(userStore, 'userData.user.tokenCount', 0)}
						</div>
					</div>
				</div>
			);
		}
	}

	onSurgeryClicked() {
		const surgeryConfig = _.cloneDeep(
			_.get(ConfigService.getConfiguration(), 'coverflow.surgeryCard') || {}
		);
		if (surgeryConfig.type === 'message') {
			ExternalService.handleExternalRequest(surgeryConfig.type, surgeryConfig);
		} else {
			ExternalService.handleExternalRequest(
				surgeryConfig.type,
				surgeryConfig.data
			);
		}
	}

	async openVPCSSO() {
		this.$f7.dialog.preloader();
		try {
			const sso = await CloudAPI.getVPCSSOUrl();
			this.$f7.dialog.close();
			window.open(sso.ssoUrl, '_blank');
		} catch (err) {
			console.log('openVPCSSO err:: ', err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert(
				'We were unable to validate your access to these services, please try again later, and double check your subscription status',
				'Error'
			);
		}
	}

	render() {
		let {
			telehealthTitle,
			telehealthText1,
			telehealthText2,
			prescriptionTitle,
			presccriptionSubText,
			externalText,
			externalText2,
			balanceBillTitle,
			balanceBillMessage,
			surgeryText,
			surgeryTitle,
			procedureSearchTitle,
			procedureSearchMessage
		} = this.text;
		let config = ConfigService.getConfiguration();
		const telehealthCopayTemplate = _.template(telehealthText1);
		const telehealthPlanData = UserService.getPlanTelehealthData();
		const telehealthCopayText = telehealthCopayTemplate(telehealthPlanData);

		let coverflow = {
			initialSlide: 1,
			surgeryCard: {
				type: 'call',
				data: '123123134'
			},
			cardMap: {}
		};

		//! HANDLING OLD FEATURE TOGGLES FOR NOW
		// const features = _.get(config, 'features', {});
		// if (!UserService.isConsumer()) {
		// 	if (!features.turnOffPrescriptions) {
		// 		coverflow.cardMap[0] = 'prescriptions';
		// 	}
		// 	if (!!features.telehealth) {
		// 		coverflow.cardMap[1] = 'telehealth';
		// 	}
		// 	if (!!features.externalServices) {
		// 		coverflow.cardMap[2] = 'externalServices';
		// 	}
		// 	if (!!features.scheduleSurgery) {
		// 		coverflow.cardMap[3] = 'scheduleSurgery';
		// 	}
		// 	if (!!features.balanceBilling) {
		// 		coverflow.cardMap[4] = 'balanceBilling';
		// 	}
		// }

		if (UserService.isConsumer()) {
			coverflow.cardMap[0] = 'prescriptions';
			if (userStore.activeSubscription) {
				coverflow.cardMap[1] = 'vpcCard';
				coverflow.cardMap[2] = undefined;
			} else {
				coverflow.cardMap[1] = 'telehealthConsumer';
				coverflow.cardMap[2] = 'consumerShop';
			}
		}

		//! IF WE INCLUDE COVERFLOW CONFIG WE CAN JUST IGNORE ALL OF ABOVE
		if (config.coverflow) {
			let coverflowItems = _.cloneDeep(config.coverflow);
			if (config.coverflow.cardOrder) {
				const cards = Object.values(coverflowItems.cardMap);
				const cardOrder = coverflowItems.cardOrder.filter(
					(x) => cards.indexOf(x) > -1
				);
				const newCards = {};
				cardOrder.map((card, i) => {
					const cardIndex = cards.indexOf(card);
					const cardData = cards[cardIndex];
					if (cardData) {
						_.set(newCards, i.toString(), cardData);
					}
				});
				const unorderedCards = cards.filter((card) => {
					const currentData = Object.values(newCards).indexOf(card) > -1;
					return !currentData;
				});
				const orderedCardSize = Object.values(newCards).length;
				unorderedCards.map((x, i) => {
					_.set(newCards, (orderedCardSize + i).toString(), x);
				});
				coverflowItems.cardMap = newCards;
			}
			coverflow = coverflowItems;
		}

		return (
			<Swiper
				className={`card-swiper ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				pagination
				navigation
				params={{
					effect: 'coverflow',
					initialSlide: coverflow?.initialSlide,
					grabCursor: true,
					centeredSlides: true,
					slidesPerView: 'auto',
					spaceBetween: 4,
					coverflowEffect: {
						slideShadows: false
					}
				}}
			>
				{Object.values(coverflow?.cardMap || {}).map((type) => {
					switch (type) {
						case 'prescriptions': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard green`}
										onClick={this.onPrescriptions}
									>
										<i className="fad fa-prescription" />
										<div className="title">{prescriptionTitle}</div>
										<div className="bottom-text">{presccriptionSubText}</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'procedureSearch': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard blue2`}
										onClick={this.onProcedureSearchClick}
									>
										<img
											src={procedureSearchImg}
											className="procedure-search-img"
										/>
										<div className="title">{procedureSearchTitle}</div>
										<div className="bottom-text">{procedureSearchMessage}</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'balanceBilling': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard blue2`}
										onClick={this.onBalanceBillClick}
									>
										<img src={balanceBilling} className="svgimage" />
										<div className="title">{balanceBillTitle}</div>
										<div className="bottom-text">{balanceBillMessage}</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'scheduleSurgery': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard white`}
										onClick={this.onSurgeryClicked}
									>
										<img src={surgeryPng} className="svgimage" />
										<div className="title">{surgeryTitle}</div>
										<div className="bottom-text">{surgeryText}</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'telehealthConsumer': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									{this.buildConsumerTelehleathCard()}
								</SwiperSlide>
							);
						}
						case 'telehealth': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard blue`}
										onClick={ExternalService.telehealthInit}
									>
										<i className="fad fa-phone-alt" />
										<div className="top-text">{telehealthCopayText}</div>
										<div className="title">{telehealthTitle}</div>
										<div className="bottom-text">{telehealthText2}</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'externalServices': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard blue2`}
										onClick={this.onExternalServices}
									>
										<i className="fad fa-external-link-square-alt" />
										<div className="title">{externalText}</div>
										<div className="bottom-text">{externalText2}</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'consumerShop': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard pink`}
										onClick={() => {
											analytics.track('Open Shop Init');
											appStore.openShop('package1');
										}}
									>
										<Chip text={this.text.savingsAmount} color="purple" />
										<CareCoin />
										<div className="title">{this.text.careCredits}</div>
										<div className="bottom-text">
											{this.text.creditAmountText}
										</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'vpcCard': {
							return (
								<SwiperSlide key={`coverflow-card-${type}`}>
									<div
										className={`flowcard pink`}
										onClick={() => {
											analytics.track('Open VPC');
											this.openVPCSSO();
										}}
									>
										<i className="fad fa-user-md"></i>
										<div className="title">{'Access Virtual Primary Care'}</div>
										<div className="bottom-text">
											{'Unlimited Telehealth Calls'}
										</div>
									</div>
								</SwiperSlide>
							);
						}
						case 'additionalCards': {
							// coverflow.additionalCards = {
							// 	0: {
							// 		key: 'test1',
							// 		type: 'video',
							// 		data: '409329632',
							// 		icon: 'fad fa-acorn',
							// 		title: 'Test',
							// 		subtitle: 'Test'
							// 	},
							// 	1: {
							// 		key: 'test2',
							// 		type: 'call',
							// 		data: '5555555555',
							// 		icon: 'fad fa-acorn',
							// 		title: 'Test',
							// 		subtitle: 'Test'
							// 	}
							// };

							return Object.values(coverflow?.additionalCards || {}).map(
								(card) => {
									return (
										<SwiperSlide key={`coverflow-card-${card.key}`}>
											<div
												className={`flowcard blue2`}
												onClick={(actions) => {
													ExternalService.handleExternalRequest(
														card.type,
														card.data
													);
												}}
											>
												<i className={card.icon} />
												<div className="title">{card.title}</div>
												<div className="bottom-text">{card.subtitle}</div>
											</div>
										</SwiperSlide>
									);
								}
							);
						}
						default: {
							console.log(`INVALID CARD TYPE ${type}`);
							return '';
						}
					}
				})}
			</Swiper>
		);
	}
}
