import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { ListItem } from 'framework7-react';
import _ from 'lodash';
import DropDown from '@/components/drop-down/DropDown';
import './icon-picker.scss';

@observer
export default class IconPicker extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showDemoShell: false
		});
		autoBind(this);
	}

	getAvailableIcons() {
		return [
			'fa-check',
			'fa-exclamation',
			'fa-user-headset',
			'fa-map-marker',
			'fa-alarm-exclamation',
			'fa-user',
			'fa-question',
			'fa-image',
			'fa-text',
			'fa-cogs',
			'fa-exchange',
			'fa-images',
			'fa-id-card',
			'fa-calendar',
			'fa-directions',
			'fa-heartbeat',
			'fa-signal',
			'fa-eye',
			'fa-x-ray',
			'fa-street-view',
			'fa-phone',
			'fa-phone-alt',
			'fa-heart',
			'fa-parking-circle',
			'fa-share',
			'fa-share-alt',
			'fa-vial',
			'fa-external-link',
			'fa-list',
			'fa-info-circle',
			'fa-users-class',
			'fa-comments',
			'fa-handshake',
			'fa-file-invoice',
			'fa-file-invoice-dollar',
			'fa-coin',
			'fa-abacus',
			'fa-prescription',
			'fa-user-cog',
			'fa-user-crown',
			'fa-id-card-alt',
			'fa-notes-medical',
			'fa-sticky-note',
			'fa-home-heart',
			'fa-home',
			'fa-do-not-enter',
			'fa-search',
			'fa-lock-alt',
			'fa-lock',
			'fa-files-medical',
			'fa-hospital',
			'fa-hospital-user',
			'fa-folders',
			'fa-life-ring',
			'fa-exclamation-triangle',
			'fa-pen',
			'fa-user-md',
			'fa-weight',
			'fa-walking',
			'fa-running',
			'fa-salad',
			'fa-heart-rate',
			'fa-hand-holding-seedling',
			'fa-hand-holding-heart',
			'fa-dumbbell',
			'fa-hand-holding-usd',
			'fa-hands-heart',
			'fa-user-injured',
			'fa-user-md-chat',
			'fa-user-nurse',
			'fa-file-medical',
			'fa-monitor-heart-rate',
			'fa-head-side-medical',
			'fa-procedures',
			'fa-viruses',
			'fa-virus',
			'fa-bacteria',
			'fa-ambulance',
			'fa-laptop-medical',
			'fa-briefcase-medical',
			'fa-books-medical',
			'fa-head-side-brain',
			'fa-medkit',
			'fa-first-aid',
			'fa-vials',
			'fa-teeth-open',
			'fa-teeth',
			'fa-toothbrush',
			'fa-tooth',
			'fa-stethoscope'
		];
	}

	render() {
		const listItems = this.getAvailableIcons().map((icon) => {
			return (
				<ListItem
					className="icon-picker-item neumorphic neuhover"
					onClick={() => this.props.onSelect(`fad ${icon}`)}
					key={`icon-picker-item-${icon}`}
					popoverClose
					link="#"
				>
					<i className={`fad ${icon}`} />
				</ListItem>
			);
		});
		listItems.unshift(
			<ListItem
				className="icon-picker-item neumorphic neuhover none"
				onClick={() => this.props.onSelect('none')}
				key="icon-picker-item-none"
				popoverClose
				link="#"
			>
				None
			</ListItem>
		);
		const value = this.props.currentValue || '';
		const displayValue = value.replace('fad fa-', '').replace(/-/g, ' ');
		return (
			<div className="icon-picker-container">
				<DropDown
					name={`icon-picker-dropdown`}
					label={this.props.label}
					items={listItems}
					className={`icon-picker-dropdown`}
					displayValue={displayValue}
				/>
			</div>
		);
	}
}
