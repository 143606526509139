import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import TextNodeEditor from './text-node/TextNodeEditor';
import './canvas-editor.scss';
import Titlebar from './titlebar/Titlebar';
import PropertiesPanel from './properties-panel/PropertiesPanel';
import Tools from './tools/Tools';
import Canvas from './canvas/Canvas';
import windowStore from '@/stores/WindowStore';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import CanvasFactory from '@/factories/CanvasFactory';
import { Popover } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import expressStore from '@/stores/ExpressStore';
@observer
export default class CanvasEditor extends Component {
	constructor(props) {
		super(props);
		this.hiddenFile = React.createRef();
		this.data = observable({
			planFields: {},
			memberFields: {},
			groupFields: {},
			showPrintRestrictions: false
		});
		autoBind(this);
	}

	canvasService = CanvasFactory.getActiveService();
	editorStore = CanvasFactory.getActiveStore();

	async componentDidMount() {
		await this.setVariableOptions();
		await this.setRestrictedAreas();
	}

	async setRestrictedAreas() {
		const docData = CanvasFactory.getActiveStore();
		if (expressStore.currentOrg.idCardFulfillment) {
			this.data.showPrintRestrictions = true;
		}
	}

	async setVariableOptions() {
		const fields = await ExpressAPI.getIdCardFields(expressStore.activeCard);
		this.data.planFields = fields.plan;
		this.data.memberFields = fields.member;
		this.data.groupFields = fields.group;
	}

	handleZoomIn(key, e) {
		e.preventDefault();
		this.canvasService.zoomIn();
	}
	handleZoomOut(key, e) {
		e.preventDefault(e);
		this.canvasService.zoomOut();
	}

	handleDelete(key, e) {
		this.canvasService.deleteNode();
	}

	handleArrowKeys(key, e) {
		this.canvasService.moveNodePosition(key);
	}

	handleCopy(key, e) {
		this.canvasService.copyNode(e);
	}

	handlePaste(key, e) {
		this.canvasService.pasteNode(e);
	}

	handleUndo(key, e) {
		this.canvasService.undo(e);
	}

	handleRedo(key, e) {
		this.canvasService.redo(e);
	}

	async handleSave() {
		//TODO error handling
		this.data.showPrintRestrictions = false;
		this.$f7.dialog.preloader('Saving...');
		await CanvasFactory.getActiveService().save();
		this.$f7.dialog.close();
		await this.setRestrictedAreas();
	}

	handleAddField(label, key, type) {
		const { addNode } = CanvasFactory.getActiveService();
		addNode('variable', { fontSize: 24, text: `[${label}]`, variableKey: key, variableType: type });
	}

	handleChangeField(label, key, type) {
		const {
			editorStore: { selectedNodes },
			setNodeProperties
		} = CanvasFactory.getActiveService();
		setNodeProperties({ text: `[${label}]`, variableKey: key, variableType: type }, selectedNodes[0]);
	}

	renderFields(type, isEdit) {
		const { addNode } = CanvasFactory.getActiveService();
		const variableOptions = type === 'plan' ? this.data.planFields : type === 'group' ? this.data.groupFields : this.data.memberFields;
		let options = [];
		options.push(
			<div key={Math.random()} className="heading">
				SELECT OPTION
			</div>
		);
		Object.keys(variableOptions).forEach((key) => {
			const option = variableOptions[key];
			options.push(
				<div
					key={key}
					className="variable-option hbox vcenter popover-close"
					onClick={() => {
						if (isEdit) {
							this.handleChangeField(option.label, key, type);
						} else {
							this.handleAddField(option.label, key, type);
						}
					}}
				>
					<div className="variable-icon">
						{`{`}
						<i>x</i>
						{`}`}
					</div>
					<div className="label">{option.label}</div>
				</div>
			);
		});

		return options;
	}

	render() {
		const { documentWidth, documentHeight, documentSide } = CanvasFactory.getActiveStore();
		const ctrlKey = this.canvasService.isMac() ? 'meta' : 'ctrl';
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;

		return (
			<div
				className={`canvas-editor ${this.editorStore.whiteoutActive ? 'cursor whiteout' : ''}`}
				onClick={this.canvasService.hideMenu}
			>
				{canEdit && (
					<>
						<KeyboardEventHandler handleKeys={[`${ctrlKey}+=`]} onKeyEvent={this.handleZoomIn} />
						<KeyboardEventHandler handleKeys={[`${ctrlKey}+-`]} onKeyEvent={this.handleZoomOut} />
						<KeyboardEventHandler handleKeys={['delete', 'backspace']} onKeyEvent={this.handleDelete} />
						<KeyboardEventHandler handleKeys={['up', 'down', 'left', 'right']} onKeyEvent={this.handleArrowKeys} />
						<KeyboardEventHandler handleKeys={[`${ctrlKey}+c`]} onKeyEvent={this.handleCopy} />
						<KeyboardEventHandler handleKeys={[`${ctrlKey}+v`]} onKeyEvent={this.handlePaste} />
						<KeyboardEventHandler handleKeys={[`${ctrlKey}+z`]} onKeyEvent={this.handleUndo} />
						<KeyboardEventHandler
							handleKeys={[`${this.canvasService.isMac() ? 'meta+shift+z' : 'ctrl + y'}`]}
							onKeyEvent={this.handleRedo}
						/>
					</>
				)}
				<TextNodeEditor />
				<Titlebar onClose={this.props.onClose} onSave={this.handleSave} canEdit={canEdit} />
				<div className="editor-body hbox">
					{canEdit && <Tools bounds=".editor-body" />}
					<Canvas
						width={windowStore.innerWidth - 300}
						height={windowStore.innerHeight - 56}
						documentWidth={documentWidth}
						documentHeight={documentHeight}
						printRestrictions={this.data.showPrintRestrictions}
						isBack={documentSide === 'back'}
						canEdit={canEdit}
					/>
					{canEdit && <PropertiesPanel />}
					<Popover className="variable-fields plan-fields y-scroll" backdrop={false}>
						<div className="vbox" style={{ minHeight: `${windowStore.innerHeight}px` }}>
							{this.renderFields('plan', false)}
						</div>
					</Popover>
					<Popover className="variable-fields member-fields y-scroll" backdrop={false}>
						<div className="vbox" style={{ minHeight: `${windowStore.innerHeight}px` }}>
							{this.renderFields('member', false)}
						</div>
					</Popover>
					<Popover className="variable-fields group-fields y-scroll" backdrop={false}>
						<div className="vbox" style={{ minHeight: `${windowStore.innerHeight}px` }}>
							{this.renderFields('group', false)}
						</div>
					</Popover>
					<Popover className="variable-fields member-fields-swap y-scroll" backdrop={false}>
						<div className="vbox" style={{ minHeight: `${windowStore.innerHeight}px` }}>
							{this.renderFields('member', true)}
						</div>
					</Popover>
					<Popover className="variable-fields plan-fields-swap y-scroll" backdrop={false}>
						<div className="vbox" style={{ minHeight: `${windowStore.innerHeight}px` }}>
							{this.renderFields('plan', true)}
						</div>
					</Popover>
					<Popover className="variable-fields group-fields-swap y-scroll" backdrop={false}>
						<div className="vbox" style={{ minHeight: `${windowStore.innerHeight}px` }}>
							{this.renderFields('group', true)}
						</div>
					</Popover>
				</div>
			</div>
		);
	}
}
