import React, { Component } from 'react';
import { Popup, Button } from 'framework7-react';
import { getCmpText } from '@/utils/Localization';
import { observer, observable } from '@/utils/State';
import ScreeningService from '@/services/ScreeningService';
import screeningStore from '@/stores/ScreeningStore';
import appStore from '@/stores/AppStore';
import { autoBind } from '@/utils/GeneralUtils';
import './screening-tool-popup.scss';
import { ScreeningIntro } from './screening-intro/ScreeningIntro';
import { ScreeningEmergency } from './screening-emergency/ScreeningEmergency';
import { ScreeningOutcome } from './screening-outcome/ScreeningOutcome';
import AnalyticsService from '@/services/AnalyticsService';
const analytics = new AnalyticsService();
@observer
export class ScreeningToolPopup extends Component {
	constructor(props) {
		super(props);
		this.popup = React.createRef();
		this.screeningStore = screeningStore;
		this.text = getCmpText('ScreeningToolPopup', {});
		this.data = observable({
			showIntro: true,
			someoneElse: false
		});
		autoBind(this);
	}

	closePrompt() {
		ScreeningService.reset();
		this.props.onClose();
	}

	onPopupOpen() {
		analytics.track('covid_screening_open');
		console.log('open');
	}

	onSelectChoice(choice) {
		ScreeningService.onSelect(choice);
	}

	onNext() {
		ScreeningService.onNext();
		if (this.popup.current) {
			this.popup.current.scrollTo(0, 0);
		}
	}

	onBack() {
		if (screeningStore.questionTrail.length > 0) {
			ScreeningService.onBack();
			this.popup.current.scrollTo(0, 0);
		} else {
			this.closePrompt();
		}
	}

	isSelected(type, key) {
		if (type === 'single' || type === 'emergency') {
			return screeningStore.results[screeningStore.currentQuestion] === key;
		} else if (type === 'multi') {
			const questionResult = _.get(screeningStore.results, screeningStore.currentQuestion, []);
			return questionResult.includes(key);
		}
	}
	renderChoices(type, choices) {
		let options = [];
		if (type === 'single' || type === 'multi') {
			choices.forEach((choice, key) => {
				options.push(
					<Button
						className={`neubtn screening-choice-btn hbox hleft vcenter ${this.isSelected(type, choice.key) ? 'selected' : ''}`}
						key={key}
						onClick={() => this.onSelectChoice(choice)}
					>
						<div className="toggle-indicator vbox vcenter hcenter">
							{this.isSelected(type, choice.key) && <i className="fad fa-dot-circle"></i>}
						</div>
						<div className="text">{ScreeningService.pronounPlural(choice.text)}</div>
					</Button>
				);
			});
		}
		return options;
	}

	renderQuestionaire() {
		const script = ScreeningService.getScript();
		const questions = Object.keys(script);

		let questionaire = [];

		questions.forEach((key) => {
			let question = script[key];
			switch (question.type) {
				case 'intro':
					questionaire.push(
						<ScreeningIntro
							showIntro={key === this.screeningStore.currentQuestion}
							onSelect={this.onSelectChoice}
							onNext={this.onNext}
							choices={question.choices}
							key={key}
						/>
					);
					break;
				case 'emergency':
					questionaire.push(
						<ScreeningEmergency
							showEmergency={key === this.screeningStore.currentQuestion}
							onSelect={this.onSelectChoice}
							onNext={this.onNext}
							choices={question.choices}
							isSelected={this.isSelected}
							key={key}
						/>
					);
					break;
				case 'end':
					questionaire.push(
						<div className={`question ${key !== this.screeningStore.currentQuestion ? 'hidden' : ''}`} key={key}>
							<div className="question-header">
								<h1>{ScreeningService.pronounPlural(question.header)}</h1>
								{question.description && <h2>{ScreeningService.pronounPlural(question.description)}</h2>}
							</div>
						</div>
					);
					break;
				default:
					questionaire.push(
						<div className={`question ${key !== this.screeningStore.currentQuestion ? 'hidden' : ''}`} key={key}>
							<div className="question-header">
								<h1>{ScreeningService.pronounPlural(question.header)}</h1>
								{question.description && <h2>{ScreeningService.pronounPlural(question.description)}</h2>}
							</div>
							<div className="choices">{this.renderChoices(question.type, question.choices)}</div>
							<Button
								fill
								large
								className="round-btn purple"
								onClick={this.onNext}
								id="add-card-button"
								disabled={screeningStore.nextQuestion === ''}
							>
								Next <i className="far fa-long-arrow-right"></i>
							</Button>
						</div>
					);
			}
		});

		questionaire.push(<ScreeningOutcome showOutcome={this.screeningStore.completed} key={'screening-outcome'} />);

		return questionaire;
	}

	onScreenerComplete(outcome) {
		if (this.props.onScreenerComplete) {
			analytics.trackWithData('covid_screening_complete', { outcomes: outcome });
			this.props.onScreenerComplete(outcome);
		}
		this.closePrompt();
	}

	renderQuestionaireBody() {
		return (
			<div className="page-wrapper" ref={this.popup}>
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="navigation hbox vcenter">
							<div className="back hbox vcenter" onClick={this.onBack}>
								<i className="fal fa-chevron-left"></i>Back
							</div>
							<div className="btn" onClick={this.closePrompt}>
								Cancel
							</div>
						</div>
					</div>
				</div>
				{this.renderQuestionaire()}
			</div>
		);
	}

	renderContent() {
		if (screeningStore.completed) {
			return <ScreeningOutcome showOutcome={this.screeningStore.completed} onComplete={this.onScreenerComplete} />;
		} else {
			return this.renderQuestionaireBody();
		}
	}

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`covid-screener-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closePrompt}
				onPopupOpen={this.onPopupOpen}
				backdrop={false}
			>
				{this.renderContent()}
			</Popup>
		);
	}
}
