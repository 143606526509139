import appStore from '@/stores/AppStore';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import ExpressAPI from './ExpressAPI';
import StorageService from './StorageService';
const storageService = new StorageService();

class ExpressAuthService {
	constructor() {}
	init(f7) {
		console.log('ExpressAuthService INIT CALL');
		const firebaseConfig = {
			apiKey: 'AIzaSyDNJHTTguqau46smFBZ3M3JloGqZhvOkZI',
			authDomain: 'carevalet-express.firebaseapp.com',
			projectId: 'carevalet-express',
			storageBucket: 'carevalet-express.appspot.com',
			messagingSenderId: '520138950647',
			appId: '1:520138950647:web:53c6bb19d3fa3f6b6dd0f2',
			measurementId: 'G-KJ5QSVS2HQ'
		};
		// Initialize Firebase
		firebase.initializeApp(firebaseConfig);
		// appStore.showReauthLoader = true;
		//Will auto login if token is good
		firebase.auth().onAuthStateChanged((authUser) => this.handleAuthChange(authUser, f7));
	}

	async createFirebaseUser(f7, email, password, firstName, lastName) {
		expressStore.isCreatingUser = true;
		try {
			await firebase.auth().createUserWithEmailAndPassword(email, password);
			await firebase.auth().currentUser.updateProfile({
				displayName: `${firstName} ${lastName}`
			});
			await firebase.auth().currentUser.sendEmailVerification({});
			const token = await firebase.auth().currentUser.getIdToken(true);
			expressStore.authToken = token;
			return true;
		} catch (err) {
			console.log(err);
			if (err.code === 'auth/email-already-in-use') {
				f7.dialog.alert(err.message, 'Error:');
			} else {
				f7.dialog.alert(
					'Unable to creating an account with this info, please try again, if the issue persists email help@carevalet.com',
					'Error:'
				);
			}

			return false;
		}
	}

	async handleSignIn(data, f7) {
		appStore.showReauthLoader = true;
		let { email, password, rememberMe } = data;
		let userLogin = { rememberMe: rememberMe, loginEmail: email };
		storageService.setValue('app', 'expressLogin', JSON.stringify(userLogin)).catch((err) => console.log(err));
		//Just in case
		if (expressStore.isLoggedIn) {
			firebase.auth().signOut();
		}
		try {
			const loginResult = await firebase.auth().signInWithEmailAndPassword(email, password);
			const userEmailVerified = _.get(loginResult, 'user.emailVerified', false);
			if (!userEmailVerified) {
				await firebase.auth().currentUser.sendEmailVerification({});
				firebase.auth().signOut(f7);
				appStore.showReauthLoader = false;
				f7.dialog.alert(
					`Please verify your email address, we have sent an email to ${email}, if you do not see it in your inbox please check your spam or junk folders. If the issue persists, please contact help@carevalet.com`,
					'Verify Your Email'
				);
			} else {
				console.log('Express Firebase login successful');
				//Success & Email is verified and the rest will be handled by handleAuthChange() below
			}
		} catch (err) {
			console.log(err);
			switch (err.code) {
				case 'auth/user-not-found': {
					f7.dialog.alert(`Unable to find user with email ${email}`, 'Authentication Error');
					break;
				}
				case 'auth/invalid-email': {
					f7.dialog.alert(`Unable to find user with email ${email}`, 'Authentication Error');
					break;
				}
				case 'auth/wrong-password': {
					f7.dialog.alert(`Invalid password for email ${email}`, 'Authentication Error');
					break;
				}
				default: {
					f7.dialog.alert(`Unkwnown error, please try again or email us at help@carevalet.com`, 'Authentication Error');
					break;
				}
			}
			appStore.showReauthLoader = false;
		}
	}

	async handleAuthChange(authUser, f7) {
		console.log('Handling Express Auth Change');
		//create user causes an auth change
		if (expressStore.isCreatingUser) {
			f7.dialog.alert(
				`We have sent you a verification link via email, if you do not see it in your inbox please check your spam or junk folders.`,
				'Verify Your Email'
			);
			expressStore.isCreatingUser = false;
			return false;
		}
		//if not verified and somehow go here just return
		if (authUser && !authUser.emailVerified) {
			return false;
		}
		console.log(`Current Auth User: ${authUser}`);
		appStore.showReauthLoader = true;
		if (authUser) {
			//capture and set toke in store
			const token = await firebase.auth().currentUser.getIdToken(true);
			expressStore.authToken = token;
			expressStore.isLoggedIn = true;
			//Login to the express platform
			const platformLogin = await this.platformSignin(f7);
			//on success do the setup
			const currentPath = _.get(f7, 'views.main.router.currentRoute.path');
			if (platformLogin) {
				if (currentPath === '/idcardeditor') {
					expressStore.activePage = 'home';
					expressStore.activeEditor = 'id-cards';
				} else {
					if (_.get(f7, 'views.appShell.router')) {
						expressStore.activePage = 'home';
						f7.views.main.router.navigate('/expresss');
						f7.views.main.router.refreshPage();
					}
				}
			}
		} else {
			appStore.showReauthLoader = false;
			this.signout(f7);
		}
	}

	async platformSignin(f7) {
		try {
			const platformRes = await ExpressAPI.login();
			let { user, roles, orgs } = platformRes;
			expressStore.userData.user = user;
			expressStore.userData.roles = roles;
			expressStore.myOrganizations = orgs.sort((a, b) => {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});

			expressStore.currentOrg = expressStore.myOrganizations[0];
			appStore.showReauthLoader = false;
		} catch (err) {
			let message = _.get(JSON.parse(err.response), 'message', '');
			if (message === `Your account is not active`) {
				f7.dialog.alert(
					`Your account is not active, if you believe you should have access, please contact help@carevalet.com.`,
					'Unable To Sign In'
				);
			} else {
				f7.dialog.alert(
					`Something went wrong, we were unable to authenticate you with the Express paltform, please try again and if the issue persists contact help@carevalet.com.`,
					'Unable To Sign In'
				);
			}
			expressStore.isLoggedIn = false;
			expressStore.authToken = null;
			return false;
		}
		return true;
	}

	signout(f7) {
		console.log('Calling Signout');
		const currentPath = _.get(f7, 'views.main.router.currentRoute.path');
		expressStore.activeEditor = null;
		expressStore.activeCard = null;
		const isLoginPage = currentPath && currentPath.indexOf('login') > -1;
		if (!currentPath || !isLoginPage) {
			//! TODO this is where you do cleanup of global store data etc
			expressStore.isLoggedIn = false;
			expressStore.authToken = null;
			f7.views.main.router.navigate('/login', { clearPreviousHistory: true, reloadAll: false });
		} else if (isLoginPage) {
			console.log('Refreshing login');
			f7.views.main.router.refreshPage();
		}
	}
}

export default new ExpressAuthService();
