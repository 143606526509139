import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Popup, Link } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import appStore from '@/stores/AppStore';
import QrCodeWithLogo from 'qrcode-with-logos';
import logoPNG from '@/assets/icons/icon-128x128.png';
import { getCmpText } from '@/utils/Localization';
import './afffiliate-link-popup.scss';

@observer
export default class AffiliateLinkPopup extends Component {
	constructor(props) {
		super(props);
		this.linkRef = React.createRef();
		this.text = getCmpText('AffiliateLinkPopup', {
			downloadCta: 'Download',
			copied: 'Link copied to clipboard'
		});
		this.data = observable({
			downloadImageFunction: () => {
				console.log('No Function Set');
			}
		});
		autoBind(this);
	}

	generateLink(aff) {
		setTimeout(() => {
			let qrcode = new QrCodeWithLogo({
				canvas: document.getElementById('qrcanvas'),
				content: `https://${window.location.host}/#!/login?ref=${this.props.code}`,
				width: 300,
				image: document.getElementById('qrimage'),
				logo: {
					src: logoPNG
				}
			});
			qrcode.toCanvas().then(() => {
				qrcode.toImage().then(() => {
					this.data.downloadImageFunction = () => {
						console.log('Downloading Image');
						qrcode.downloadImage(`qr-img-${this.props.code}`);
					};
				});
			});
		}, 100);
	}

	handlePopupOpen() {
		this.generateLink();
	}

	closeLinkPopup() {
		this.data.downloadImageFunction = () => {
			console.log('No Function Set');
		};
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	render() {
		return (
			<div>
				<Popup
					opened={this.props.isOpen}
					className={`affiliate-link-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupClosed={this.closeLinkPopup}
					onPopupOpen={this.handlePopupOpen}
				>
					<div className="popup-header">
						<div className="title grow-1">{this.props.title}</div>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					<div className="link-ctn">
						<canvas id="qrcanvas"></canvas>
						<img src="" alt="" id="qrimage" />
						<Button className="download-btn" onClick={this.data.downloadImageFunction}>
							{this.text.downloadCta}
						</Button>
						<textarea
							readOnly
							className="link-text"
							value={`https://${window.location.host}/#!/login?ref=${this.props.code}`}
							ref={this.linkRef}
							onClick={() => {
								const linkDiv = this.linkRef && this.linkRef.current;
								linkDiv.select();
								document.execCommand('copy');
								this.$f7.dialog.alert(this.text.copied);
							}}
						></textarea>
					</div>
				</Popup>
			</div>
		);
	}
}
