import React, { Component } from 'react';
import { addBusinessDays } from 'date-fns';
import { Segmented, Button, Popup, Page, Link, Popover, List, ListItem, ListInput } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import DropDown from '../../components/drop-down/DropDown';
import _ from 'lodash';
import './booking.scss';
import UserService from '../../services/UserService';
import ConfigService from '../../services/ConfigService';
import ProviderService from '../../services/ProviderService';
import userStore from '../../stores/UserStore';
import CloudAPI from '../../services/CloudAPI';
import AnalyticsService from '../../services/AnalyticsService';
const Visit_Reasons = ConfigService.getConfigValue('visitReasons', {});
const analytics = new AnalyticsService();
@observer
class Booking extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			selectedMember: '',
			selectedMemberId: '',
			provider: {},
			selectedLocation: {},
			selectedType: 'existing',
			dateType: 'first',
			requestedTime: 'anytime',
			selectedDate: [new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 0, 0, 0) + 86400000)],
			selectedReason: 0,
			reasonDetails: ''
		});
		this.text = getCmpText('Booking', {
			title: 'New Appointment',
			submitBtn: 'Submit Request',
			patient: 'Patient:',
			preferedDate: 'Preferred Date:',
			preferedTime: 'Preferred Time:',
			locationText: 'Practice Location:',
			reasonLabel: 'Visit Reason:',
			patientType: 'Patient Type',
			existingPatient: `I have seen this doctor before`,
			newPatient: 'I am a new patient',
			visitSummary: 'Visit Reason Details: (75 char min)',
			reasonPlaceholder: 'Describe in a sentence or two the reason for your vist.',
			firstText: 'First Available',
			suggestedDate: 'Suggest Date',
			anytime: 'Anytime',
			morning: 'Morning',
			afternoon: 'Afternoon',
			dateRangeText: 'Suggest a Date Range',
			dateRangePlaceholder: 'Select 2 days',
			reason0: 'Wellness / Preventative',
			reason1: 'Office Visit',
			reason2: 'Illness',
			reason3: 'Follow-up',
			reason4: 'Other',
			success: 'Request Submitted',
			calendarCloseText: 'Close',
			specialtySelector: 'Select Specialty',
			earlierApptText: '*Please contact the provider directly if you need an appointment prior to the earliest available date above.',
			warningMessage:
				'If this is your first time visiting this doctor, they may require new patients to call their practice directly to setup an appointment. If you still make a request via CareValet we will attempt to schedule the appointment however if it is time sensitive we suggest closing this form and tapping on the call button on the location you want to visit to schedule your first visit.',
			warning: 'Warning'
		});
		this.providerText = getCmpText('ProviderProfile', {
			miles: 'mi'
		});
		autoBind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let { careTeam, open, npi } = nextProps;
		if (open) {
			ProviderService.getProviderData(careTeam ? careTeam.NPI : npi)
				.then((res) => {
					console.log('Setting provider for booking in props change');
					this.data.provider = res;
					const sortedLocations = this.getSortedLocations();
					const fallbackLocation = sortedLocations ? sortedLocations[0] : {};
					const careTeamLoc = careTeam ? careTeam.location : {};
					const careTeamLocInNetwork = _.find(sortedLocations, { latitude: careTeamLoc.latitude, longitude: careTeamLoc.longitude });
					this.data.selectedLocation = careTeam && !!careTeamLocInNetwork ? careTeam.location : fallbackLocation;
					this.data.selectedMember = _.get(userStore, 'userData.member._id', '');
					this.setSpecialty();
					analytics.track('opened_provider_booking_screen');
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.data = observable({
				selectedMember: '',
				selectedMemberId: '',
				provider: {},
				selectedLocation: {},
				selectedType: 'existing',
				dateType: 'first',
				requestedTime: 'anytime',
				selectedSpecialty: null,
				selectedDate: this.getInitialDateRanges(),
				selectedReason: 0,
				reasonDetails: ''
			});
			this.setMinDate();
		}
	}

	getSelectedSpecialty() {
		const careteamSpecialty = _.get(this.props, 'careTeam.specialty');
		const selectedLocationSpecialties = _.get(this.data, 'selectedLocation.specialties', ['Primary Care']);
		const currentSelection = this.data.selectedSpecialty;
		if (careteamSpecialty) {
			return careteamSpecialty;
		}
		if (currentSelection) {
			return currentSelection;
		}
		return selectedLocationSpecialties[0];
	}

	setSpecialty(specialty) {
		this.data.selectedSpecialty = specialty || this.getSelectedSpecialty();
		this.data.selectedDate = this.getInitialDateRanges();
		this.setMinDate();
		this.data.dateType = 'first';
	}

	getInitialDateRanges() {
		const selectedSpecialty = this.getSelectedSpecialty();
		const apptConfig = ConfigService.getConfigValue('appointments', {});
		const timingConfig = _.get(apptConfig, 'timing', {});
		let lagInDays = 3;
		switch (selectedSpecialty) {
			case 'Primary Care':
				lagInDays = timingConfig[selectedSpecialty] || 3;
				break;
			default:
				lagInDays = timingConfig[selectedSpecialty] || 7;
				break;
		}
		// const oneDay = 60 * 60 * 24 * 1000;
		// const today = new Date().getTime();
		// const start = today + oneDay * lagInDays;
		// const rangeStart = new Date(new Date(start).setHours(0, 0, 0, 0));
		// const rangeEnd = new Date(new Date(start + oneDay).setHours(0, 0, 0, 0));

		const rangeStart = addBusinessDays(new Date(), lagInDays);
		const rangeEnd = addBusinessDays(new Date(), lagInDays + 1);
		return [rangeStart, rangeEnd];
	}

	setMinDate() {
		const range = this.getInitialDateRanges();
		this.data.minDate = range && range[0];
	}

	componentDidMount() {
		this.data.selectedDate = this.getInitialDateRanges();
		this.setMinDate();
		this.setSpecialty();
	}

	onClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
		this.dateType = 'first';
	}

	getMembers() {
		return [..._.cloneDeep(_.get(userStore, 'userData.dependents', [])), _.cloneDeep(_.get(userStore, 'userData.member'))];
	}

	buildPatientSelector() {
		let { patient } = this.text;
		let { selectedMember } = this.data;
		let members = this.getMembers();
		let selected = _.find(members, { _id: selectedMember });
		let listItems = [];
		_.forEach(members, (m) => {
			if (m) {
				listItems.push(
					<ListItem
						link="#"
						popoverClose
						className="patient-list-item"
						key={m._id + Math.random()}
						onClick={() => {
							this.data.selectedMember = m._id;
							this.data.selectedMemberId = m.memberId;
						}}
					>
						<div className="patient-name">{`${_.get(m, 'firstName', '')} ${_.get(m, 'lastName', '')}`}</div>
					</ListItem>
				);
			}
		});
		return (
			<DropDown
				name="patient-popover-menu"
				label={patient}
				icon="fad fa-user"
				items={listItems}
				displayValue={_.capitalize(`${_.get(selected, 'firstName', '')} ${_.get(selected, 'lastName', '')}`)}
			/>
		);
	}

	buildSpecialtySelector() {
		const location = _.cloneDeep(this.data.selectedLocation);
		const locationSpecialties = _.get(location, 'specialties', ['Primary Care']);
		const careteamSpecialty = _.get(this.props, 'careTeam.specialty');
		const specialtyOptions = careteamSpecialty ? [careteamSpecialty] : locationSpecialties;
		const listItems = specialtyOptions.map((specialty) => {
			return (
				<ListItem
					link="#"
					popoverClose
					className="specialty-list-item"
					key={`specialty-${specialty}`}
					onClick={() => {
						this.setSpecialty(specialty);
					}}
				>
					<div className="specialty-name">{specialty}</div>
				</ListItem>
			);
		});
		return (
			<DropDown
				name="specialty-popover-menu"
				label={this.text.specialtySelector}
				icon="fad fa-user-md"
				items={listItems}
				displayValue={this.data.selectedSpecialty}
			/>
		);
	}

	buildNewPatientWarning() {
		return (
			<div className="alert-box">
				<div className="warning-header hbox vcenter">
					<i className="fad fa-exclamation-triangle"></i>
					<div>{this.text.warning}</div>
				</div>
				<div className="warning-message">{this.text.warningMessage}</div>
			</div>
		);
	}

	buildPatientTypeSelector() {
		let { patientType, newPatient, existingPatient } = this.text;
		let { selectedType } = this.data;
		const listItems = [
			<ListItem
				link="#"
				popoverClose
				className="patient-type-list-item"
				key={'new-patient-type' + Math.random()}
				onClick={() => {
					this.data.selectedType = 'new';
				}}
			>
				<div className="patient-name">{newPatient}</div>
			</ListItem>,
			<ListItem
				link="#"
				popoverClose
				className="patient-type-list-item"
				key={'existing-patient-type'}
				onClick={() => {
					this.data.selectedType = 'existing';
				}}
			>
				<div className="patient-name">{existingPatient}</div>
			</ListItem>
		];
		return (
			<>
				<DropDown
					name="patient-type-popover-menu"
					label={patientType}
					icon="fad fa-clipboard-user"
					items={listItems}
					displayValue={`${selectedType === 'new' ? newPatient : existingPatient}`}
				/>
				{selectedType === 'new' && this.buildNewPatientWarning()}
			</>
		);
	}

	getSortedLocations() {
		let { provider } = this.data;

		const locationsWithDistance = _.cloneDeep(_.get(provider, 'locations', [])).map((location) => {
			const networkData = ProviderService.getNetworkData({ ...provider, networks: location.networks });
			location.tier = networkData.tier;
			location.inNetwork = networkData.inNetwork;
			const distance = UserService.getDistanceFromLocation(location);
			location.distance = distance;
			return location;
		});
		const sortedLocations = locationsWithDistance.sort((a, b) => (Number(a.distance) > Number(b.distance) ? 1 : -1));
		const filteredLocations = sortedLocations.filter((location) => (!!location.tier && location.tier < 5) || location.inNetwork);
		return filteredLocations;
	}

	buildLocationSelector() {
		let { locationText } = this.text;
		let { selectedLocation, provider } = this.data;
		const sortedLocations = this.getSortedLocations();
		let listItems = [];
		_.forEach(sortedLocations, (l) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="practice-list-item"
					key={l._id + Math.random()}
					onClick={() => {
						this.data.selectedLocation = l;
					}}
				>
					<div className="practice">
						<div className="distance-item">
							<i className={`fad fa-map-marker-alt`} />
							<div className="distance">
								{l.distance}
								{this.providerText.miles}
							</div>
						</div>
						{ProviderService.getAddressText(l)}
					</div>
				</ListItem>
			);
		});
		return (
			<DropDown
				name="patient-booking-location-menu"
				label={locationText}
				icon="fad fa-map-marked-alt"
				items={listItems}
				displayValue={ProviderService.getAddressText(selectedLocation)}
			/>
		);
	}

	toggleDate() {
		let { selectedDate, minDate } = this.data;
		const calendar = this.$f7.calendar;
		if (this.data.dateType === 'first') {
			this.data.dateType = 'suggested';
			this.$f7.calendar.create({
				value: selectedDate,
				containerEl: '#suggestedDatePicker',
				dateFormat: 'mm/dd/yyyy',
				rangePicker: true,
				rangePickerMinDays: 2,
				minDate: minDate,
				on: {
					change: this.onDateChange
				}
			});
		} else {
			this.data.dateType = 'first';
			if (calendar) {
				calendar.destroy();
			}
		}
	}

	onDateChange(cal) {
		const value = cal.getValue();
		this.data.selectedDate = _.cloneDeep(value);
	}

	buildDateSelector() {
		let { preferedDate, firstText, suggestedDate, dateRangeText, dateRangePlaceholder, calendarCloseText } = this.text;
		let { dateType, selectedDate, minDate } = this.data;
		return (
			<div className="prefered-date">
				<div className="label">{preferedDate}</div>
				<div className="date-toggle hbox vcenter">
					<Button round active={dateType === 'first'} onClick={this.toggleDate}>
						{firstText}
					</Button>
					<Button round active={dateType === 'suggested'} onClick={this.toggleDate}>
						{suggestedDate}
					</Button>
				</div>
				<List className="no-hairlines suggested-date-container">
					{dateType === 'suggested' && (
						<ListItem
							id="suggestedDatePicker"
							className={`suggested-date-cal ${dateType === 'suggested' ? 'show-calendar' : 'hidden'}`}
						/>
					)}
				</List>
				{dateType === 'suggested' && <p className="suggested-date-disclaimer">{this.text.earlierApptText} </p>}
			</div>
		);
	}

	buildTimeSelector() {
		let { preferedTime, anytime, morning, afternoon } = this.text;
		let { requestedTime } = this.data;
		return (
			<div className="prefered-date">
				<div className="label">{preferedTime}</div>
				<div className="time-toggle hbox vcenter">
					<Button
						round
						active={requestedTime === 'anytime'}
						onClick={() => {
							this.data.requestedTime = 'anytime';
						}}
					>
						{anytime}
					</Button>
					<Button
						round
						active={requestedTime === 'morning'}
						onClick={() => {
							this.data.requestedTime = 'morning';
						}}
					>
						{morning}
					</Button>
					<Button
						round
						active={requestedTime === 'afternoon'}
						onClick={() => {
							this.data.requestedTime = 'afternoon';
						}}
					>
						{afternoon}
					</Button>
				</div>
			</div>
		);
	}

	getReasonText(reason) {
		return _.get(this.text, `reason${reason}`, 'Other');
	}

	buildReasonSelector() {
		let { reasonLabel } = this.text;
		let { selectedReason } = this.data;
		let listItems = [];
		_.forEach(Object.keys(Visit_Reasons), (reason) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="practice-list-item"
					key={reason + Math.random()}
					id={`amandaTestVisitReason-${reason}`}
					onClick={() => {
						this.data.selectedReason = reason;
					}}
				>
					<div className="practice">{this.getReasonText(reason)}</div>
				</ListItem>
			);
		});
		return (
			<DropDown
				name="request-reason-menu"
				labe={reasonLabel}
				icon="fad fa-question"
				items={listItems}
				displayValue={this.getReasonText(selectedReason)}
			/>
		);
	}

	buildReasonInput() {
		let { reasonPlaceholder, visitSummary } = this.text;
		let { reasonDetails } = this.data;
		return (
			<div className="patient-reason">
				<div className="label">{visitSummary}</div>
				<List className="no-hairlines reason-details-list">
					<ListInput
						id="visit-reason-input"
						type="textarea"
						placeholder={reasonPlaceholder}
						value={reasonDetails}
						onChange={(e) => {
							this.data.reasonDetails = e.target.value;
						}}
					></ListInput>
				</List>
			</div>
		);
	}

	isRequestvalid() {
		const apptReason = _.get(this.data, 'reasonDetails', '').trim();
		return apptReason.length >= 75;
	}

	async onSubmitRequest() {
		let {
			selectedMember,
			selectedMemberId,
			provider,
			selectedLocation,
			selectedType,
			dateType,
			requestedTime,
			selectedDate,
			selectedReason,
			reasonDetails
		} = this.data;
		this.$f7.dialog.preloader();
		const selectedLocationData = _.find(provider.locations, {
			latitude: selectedLocation.latitude,
			longitude: selectedLocation.longitude
		});
		const combinedLocationInfo = _.cloneDeep(selectedLocation);
		if (_.get(selectedLocationData, 'specialties')) {
			combinedLocationInfo.specialties = selectedLocationData.specialties;
		}
		const shouldSetAsPcp = UserService.shouldAutoAssignPcp(selectedLocationData.specialties);
		if (shouldSetAsPcp) {
			try {
				await UserService.autoAddProviderAsPcp(provider, selectedLocationData, selectedMember);
			} catch (err) {
				console.log('unable to auto assign PCP: ', err);
			}
		}
		CloudAPI.submitAppointmentRequest({
			member: selectedMember,
			memberId: selectedMemberId,
			npi: provider.NPI,
			location: combinedLocationInfo,
			patientType: selectedType,
			requestType: dateType,
			requestDate: selectedDate,
			requestTime: requestedTime,
			reason: selectedReason,
			note: reasonDetails
		})
			.then((res) => {
				console.log(res);
				this.$f7.dialog.close();
				let toast = this.$f7.toast.create({
					position: 'center',
					closeTimeout: 2000,
					icon: `<i class="fad fa-check large-icon-toast" ></i>`,
					text: this.text.success
				});
				toast.open();
				this.onClose();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				this.$f7.dialog.alert(_.get(JSON.parse(err.response), 'message', 'An error occured, please try again.'));
			});
	}

	render() {
		let { title, submitBtn } = this.text;
		let { open, bookingKey } = this.props;
		return (
			<Popup className={`booking-popup`} opened={open} onPopupClosed={this.onClose} key={bookingKey}>
				<Page className="booking-page">
					<div className="filter-header hbox vcenter hright">
						<h1 className="grow-1">{title}</h1>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					<div className="booking-content y-scroll" id="bookingScrollArea">
						{this.buildPatientSelector()}
						{this.buildPatientTypeSelector()}
						{this.buildSpecialtySelector()}
						{this.buildLocationSelector()}
						{this.buildDateSelector()}
						{this.buildTimeSelector()}
						{this.buildReasonSelector()}
						{this.buildReasonInput()}
					</div>
					<div className="btn-ctn">
						<Button
							large
							className="submit-btn vbox vcenter hcenter"
							disabled={!this.isRequestvalid()}
							onClick={this.onSubmitRequest}
							id="booking-submit-btn"
						>
							{submitBtn}
						</Button>
					</div>
				</Page>
			</Popup>
		);
	}
}

export default Booking;
