import React, { Component } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { observer, observable } from '../../../utils/State';
import StorageService from '../../../services/StorageService';
import AnalyticsService from '../../../services/AnalyticsService';
import GradiantStack from '../../../components/gradiant-stack/GradiantStack';
import signupSVG from '../../../assets/signup.svg';
import BasicInput from '../../../components/basic-input/BasicInput';
import { Button } from 'framework7-react';
import AuthService from '../../../services/AuthService';
import userStore from '../../../stores/UserStore';
import CloudAPI from '../../../services/CloudAPI';
import DateInput from '@/components/date-input/DateInput';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
export default class ConsumerTelehealth extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			enroll: props.enroll ? props.enroll : false,
			gender: 'm',
			dob: format(new Date(), 'yyyy-MM-dd'),
			disableEnroll: true,
			email: '',
			street1: '',
			street2: '',
			city: _.clone(_.get(userStore, 'consumerUser.contactInfo.city', '')),
			state: _.clone(_.get(userStore, 'consumerUser.contactInfo.state', '')),
			zip: _.clone(_.get(userStore, 'consumerUser.contactInfo.zipCode', '')),
			dobValid: false
		});

		this.text = getCmpText('ConsumerTelehealth', {
			additionalInformation: 'Additional Information',
			enroll: 'Enroll',
			dobLabel: 'Date of Birth',
			dobPlaceholder: '01/01/1960',
			enrollmentError: 'Unable to enroll, please try again',
			cancel: 'Cancel',
			inTelehealth: 'in telehealth',
			finishLater: "I'll do it later",
			skip: 'Skip',
			enrollmentDelay: "We just need a little bit more info and you're off to the races!",
			emailLabel: 'Email',
			emailPlaceholder: 'youremail@example.com',
			streetOneLabel: 'Street 1',
			streetOnePlaceholder: '123 Example Street',
			streetTwoLabel: 'Street 2',
			streetTwoPlaceholder: 'Unit 4',
			cityLabel: 'City',
			cityPlaceholder: 'Tampa',
			stateLabel: 'State',
			statePlaceholder: 'FL',
			zipLabel: 'Postal Code',
			zipPlaceholder: '12345',
			genderLabel: 'Gender',
			male: 'Male',
			female: 'Female',
			invalidDOB: 'You must be at least 18 years of age to enroll in telehealth services.'
		});
		autoBind(this);
	}

	onCancel() {
		analytics.trackWithData('Consumer Telehealth Enrollment Prompt', { status: 'cancel' });
		userStore.isCreatingUser = false;
		if (this.props.onCancel) {
			this.props.onCancel();
		} else {
			try {
				const currentUser = firebase.auth().currentUser;
				AuthService.handleConsumerSignin(currentUser, this.$f7);
			} catch (err) {
				AuthService.handleConsumerSignin(null, this.$f7);
			}
		}
	}

	onNext() {
		let { street1, street2, city, state, zip, dob, gender, email } = this.data;
		userStore.isCreatingUser = false;
		this.$f7.dialog.preloader();
		CloudAPI.enrollTelehealth({ street1, street2, city, state, zip, dob, gender, email })
			.then(() => {
				analytics.trackWithData('Consumer Telehealth Enrollmentment', { status: 'success' });
				this.$f7.dialog.close();
				AuthService.handleConsumerSignin(userStore.firebaseUser, this.$f7);
				if (this.props.onCancel) {
					this.props.onCancel();
				}
			})
			.catch((err) => {
				console.log(err);
				const errResponseString = _.get(err, 'responseText');
				const errorResponseData = errResponseString ? JSON.parse(errResponseString) : {};
				analytics.trackWithData('Consumer Telehealth Enrollmentment', { status: 'failure', reason: errorResponseData.message });
				let errorMessage = this.text.enrollmentError;
				switch (errorResponseData.message) {
					case 'invalidDOB':
						errorMessage = this.text.invalidDOB;
						break;
					default:
						break;
				}
				this.$f7.dialog.close();
				this.$f7.dialog.alert(errorMessage);
			});
	}

	onEnroll() {
		analytics.trackWithData('Consumer Telehealth Enrollment Prompt', { status: 'enroll' });
		userStore.isCreatingUser = true;
		this.data.enroll = true;
	}

	onDateChange(value, field, isValid) {
		this.data.dob = value;
		this.data.dobValid = isValid;
		this.data.disableEnroll = !this.validateForm();
	}

	validateForm() {
		const validDob = this.data.dobValid;
		if (!validDob) {
			return false;
		}

		let existanceCheck = ['street1', 'city', 'state', 'zip', 'email'];
		let valid = true;
		_.forEach(existanceCheck, (field) => {
			if (_.get(this.data, field, '').length <= 0) {
				valid = false;
			}
			if (field === 'email') {
				const validateEmail = (email) => {
					return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
						email
					);
				};
				valid = validateEmail(_.get(this.data, field, ''));
			}
		});
		return valid;
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		this.data[target.name] = target.value;
		this.data.disableEnroll = !this.validateForm();
	}

	onToggleGender() {
		this.data.gender = this.data.gender === 'm' ? 'f' : 'm';
	}

	render() {
		let { enroll, dob, email, street1, street2, city, state, zip, disableEnroll } = this.data;
		return (
			<>
				<GradiantStack stack="2" consumer id="consumer-telehealth" />
				<div className={`consumer-telehealth-page y-scroll`}>
					<div className="header-ctn hbox vcenter">
						{enroll && (
							<div className="back hbox vcenter animated fadeInLeft" onClick={this.onCancel}>
								<i className="fal fa-chevron-left"></i>
								{this.text.cancel}
							</div>
						)}
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={signupSVG} className="herosvg" />
						<h1>{enroll ? this.text.additionalInformation : this.text.enroll}</h1>
						{!enroll && (
							<>
								<div className="selection hbox vcenter hcenter">
									<div className="enroll neucard vbox vcenter hleft" onClick={this.onEnroll}>
										<i className="fas fa-check"></i>
										<div>
											{this.text.enroll}
											<br /> {this.text.inTelehealth}
										</div>
									</div>
									<div className="skip neucard vbox vcenter hleft" onClick={this.onCancel}>
										<i className="fas fa-times"></i>
										<div>
											{this.text.skip} <br /> {this.text.finishLater}
										</div>
									</div>
								</div>
								<div className="message-box">{this.text.enrollmentDelay}</div>
							</>
						)}
						{enroll && (
							<div className="enrollment-ctn animated fadeInUp">
								<BasicInput
									name="email"
									label={this.text.emailLabel}
									value={email}
									type="email"
									placeholder={this.text.emailPlaceholder}
									onChange={this.onInputChange}
								></BasicInput>
								<BasicInput
									name="street1"
									label={this.text.streetOneLabel}
									value={street1}
									type="text"
									placeholder={this.text.streetOnePlaceholder}
									onChange={this.onInputChange}
								></BasicInput>
								<BasicInput
									name="street2"
									label={this.text.streetTwoLabel}
									value={street2}
									type="text"
									placeholder={this.text.streetTwoPlaceholder}
									onChange={this.onInputChange}
								></BasicInput>
								<BasicInput
									name="city"
									label={this.text.cityLabel}
									value={city}
									type="text"
									placeholder={this.text.cityPlaceholder}
									onChange={this.onInputChange}
								></BasicInput>
								<BasicInput
									name="state"
									label={this.text.stateLabel}
									value={state}
									type="text"
									placeholder={this.text.statePlaceholder}
									onChange={this.onInputChange}
								></BasicInput>
								<BasicInput
									name="zip"
									label={this.text.zipLabel}
									value={zip}
									type="text"
									placeholder={this.text.zipPlaceholder}
									onChange={this.onInputChange}
								></BasicInput>
								<div className="dob-ctn">
									<DateInput
										onChange={this.onDateChange}
										label={this.text.dobLabel}
										placeholder={this.text.dobPlaceholder}
										value={dob}
									/>
									<div className="gender-ctn">
										<div className="label">{this.text.genderLabel}</div>
										<div className="selection gender hbox vcenter hleft">
											<div
												className={`gender neucard vbox vcenter hcenter ${this.data.gender === 'm' ? 'active' : ''}`}
												onClick={this.onToggleGender}
											>
												<i className="fas fa-mars"></i>
												<div>{this.text.male}</div>
											</div>
											<div
												className={`gender neucard vbox vcenter hcenter ${this.data.gender === 'f' ? 'active' : ''}`}
												onClick={this.onToggleGender}
											>
												<i className="fas fa-venus"></i>
												<div>{this.text.female}</div>
											</div>
										</div>
									</div>
									<Button fill large className="round-btn purple" onClick={this.onNext} disabled={disableEnroll}>
										{this.text.enroll} <i className="far fa-long-arrow-right"></i>
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}
