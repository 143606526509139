import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import UserBillingService from '@/services/UserBillingService';
import appStore from '@/stores/AppStore';
import { Popup, Preloader, Button, Page, Tabs, Tab, Badge } from 'framework7-react';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import './token-activity-popup.scss';

import CloudAPI from '@/services/CloudAPI';
import UserTokenService from '@/services/UserTokenService';
import TokenActivityList from './token-activity-list/TokenActivityList';
import Shop from '../shop/Shop';
import TokenActivityDetail from './token-activity-detail/TokenActivityDetail';
import TransactionPage from '../billing-popup/transaction-page/TransactionPage';
import { transaction } from 'mobx';
import CallDetail from '../call-log-popup/call-detail/CallDetail';

@observer
export default class TokenActivityPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true,
			detailLoading: true,
			tokenActivity: [],
			tokenDetail: {},
			currentTransaction: {},
			callDetails: []
		});
		this.text = getCmpText('TokenActivityPopup', {
			backTxt: 'Back',
			closeText: 'Close'
		});
		autoBind(this);
	}

	async onPopupOpen() {
		await this.fetchData();
		if (this.data.tokenActivity.length < 1) {
			this.onShopOpen();
		} else {
			this.show('token-list');
		}
	}

	async fetchData() {
		try {
			this.data.loading = true;
			this.data.tokenActivity = await UserTokenService.getUserTokens();
			this.data.loading = false;
		} catch (e) {
			this.data.loading = false;
			console.log("ERROR: An error occured while attempting to retrieve the user's payment methods");
		}
	}

	closePrompt() {
		this.data.tokenActivity = [];
		if (this.props.onCloseTokenActivity) {
			this.props.onCloseTokenActivity();
		}
	}

	show(id) {
		this.$f7.tab.show('#' + id, true);
	}

	viewDetail(detail) {
		this.data.tokenDetail = detail;
		this.show('token-detail');
	}

	async onShopOpen() {
		try {
			this.$f7.dialog.preloader();
			await UserBillingService.retrievePaymentMethods();
			this.$f7.dialog.close();
		} catch (e) {
			console.log(e);
		}
		this.show('shop');
	}
	async onPaymentComplete() {
		await this.fetchData();
		this.show('token-list');
	}

	async showReceipt(transactionId) {
		try {
			this.$f7.dialog.preloader();
			this.data.currentTransaction = await UserBillingService.getTransaction(transactionId);
			this.$f7.dialog.close();
			this.show('receipt');
		} catch (e) {
			this.$f7.dialog.alert('Error', 'An occurred while retrieving receipt.');
			this.$f7.dialog.close();
		}
	}

	async showCallLog(callSid) {
		try {
			this.$f7.dialog.preloader();
			let call = await CloudAPI.getCallSequence(callSid);
			this.data.callDetails = call;
			this.$f7.dialog.close();
			this.show('call-log');
		} catch (e) {
			this.$f7.dialog.alert('Error', 'An occurred while retrieving call log data.');
			console.log(e);
			this.$f7.dialog.close();
		}
	}

	showList() {
		this.data.currentTransaction = {};
		this.data.callDetails = [];
		this.data.tokenDetail = {};
		this.show('token-list');
	}

	getShopText() {
		if (this.data.tokenActivity.length > 0) {
			return (
				<div
					className="btn"
					onClick={() => {
						this.show('token-list');
					}}
				>
					<i className="fal fa-chevron-left"></i>
					{this.text.backTxt}
				</div>
			);
		} else {
			return (
				<div
					className="btn"
					onClick={() => {
						this.closePrompt();
					}}
				>
					{this.text.closeText}
				</div>
			);
		}
	}
	render() {
		let { loading, detailLoading, tokenActivity, tokenDetail } = this.data;
		let { backTxt } = this.text;
		return (
			<Popup
				opened={this.props.opened}
				className={`token-activity-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={true}
				closeOnEscape={true}
				onPopupClosed={this.closePrompt}
				onPopupOpen={this.onPopupOpen}
			>
				<Page>
					<Tabs animated>
						<Tab id="shop" className={`token-activity-page`}>
							<div className="page-wrapper">
								<div className="global-header-popup">
									<div className="header-ctn">
										<div className="navigation hbox vcenter">{this.getShopText()}</div>

										<h1>Store</h1>
									</div>
								</div>
								<Shop onPaymentComplete={this.onPaymentComplete} />
							</div>
						</Tab>
						<Tab id="token-list">
							<TokenActivityList
								onViewDetail={this.viewDetail}
								tokenActivity={tokenActivity}
								loading={loading}
								onClose={this.closePrompt}
								onShopOpen={this.onShopOpen}
							></TokenActivityList>
						</Tab>

						<Tab id="token-detail">
							<TokenActivityDetail
								token={this.data.tokenDetail}
								onBack={this.showList}
								onReceipt={this.showReceipt}
								onShowCallLog={this.showCallLog}
							/>
						</Tab>
						<Tab id="call-log">
							<CallDetail
								callDetails={this.data.callDetails}
								onBack={() => {
									this.show('token-detail');
								}}
							/>
						</Tab>
						<Tab id="receipt">
							<TransactionPage
								transaction={this.data.currentTransaction}
								onBack={() => {
									this.show('token-detail');
								}}
							/>
						</Tab>
					</Tabs>
				</Page>
			</Popup>
		);
	}
}
