import React, { Component } from 'react';
import _ from 'lodash';
import { Popup, Link, Page, List, ListInput, Progressbar, Button, f7, Sheet, BlockTitle, ListItem, Toolbar } from 'framework7-react';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import procedureSearchImg from '@/assets/procedureSearch.png';
import searchSVG from '@/assets/providerSearch.svg';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import AnalyticsService from '@/services/AnalyticsService';
import doctorMale from '@/assets/doctor-male.svg';
import doctorFemale from '@/assets/doctor-female.svg';
import './procedure-search-popup.scss';
import CloudAPI from '@/services/CloudAPI';
import ProviderService from '@/services/ProviderService';
import userStore from '@/stores/UserStore';
import NetworkChip from '../network-chip/NetworkChip';
import RangeSlider from '../range-slider/RangeSlider';
const analytics = new AnalyticsService();

@observer
export default class ProcedureSearchPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			query: '',
			radius: 20,
			providerType: 'facilities',
			procedures: [],
			providers: [],
			accums: [],
			accumulatorDetails: [],
			searching: false,
			activeProcedure: null,
			activeProvider: null,
			networkProvider: null,
			isDisclaimerWarningOpen: false
		});
		this.text = getCmpText('ProcedureSearchPopup', {
			title: 'Search',
			placeholder: 'Enter a procedure name or code',
			empty: 'Try searching by procedure or CPT code',
			searchError: 'Something went wrong, please try again.',
			searchingText: 'Searching for nearby providers that can perform this procedure...',
			noProviders: `It appears there are no providers in your area that perform this procedure`,
			inNetworkAccumText: 'In-Network Accumulator Balance',
			tier1AccumText: 'Tier 1 Accumulator Balance',
			tier2AccumText: 'Tier 2 Accumulator Balance',
			outNetworkAccumText: 'Out-of-Network Accumulator Balance',
			deductibleText: 'Deductible',
			oopText: 'Out-of-Pocket'
		});
		autoBind(this);
	}

	componentDidMount() {}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.opened !== this.props.opened && this.props.opened) this.data.isDisclaimerWarningOpen = true;
	}

	loadPricingData() {
		CloudAPI.getAccumulators()
			.then((res) => {
				this.data.accums = res;
				this.data.searching = false;
			})
			.catch((err) => {
				console.log(err);
			});
		CloudAPI.getAccumulatorDetails()
			.then((res) => {
				this.data.accumulatorDetails = res;
			})
			.catch((err) => {
				console.log(err);
			});
		CloudAPI.getProvider(this.data.activeProvider.provider.NPI)
			.then((res) => {
				if (res.length > 0) {
					this.data.networkProvider = res[0];
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	onClose() {
		const query = _.cloneDeep(this.data.query);
		if (query && query.length > 0) {
			analytics.trackWithData('procedure_search', { query: query });
		}
		//TODO uncomment if we want to remove state
		// this.data.query = '';
		// this.data.activeProcedure = null;
		// this.data.procedures = [];
		// this.data.providers = [];
		// this.data.searching = false;
		if (this.props.onClose) {
			if (this.data.isDisclaimerWarningOpen) this.data.isDisclaimerWarningOpen = false;
			this.props.onClose();
		}
	}

	async onPopupOpen() {
		analytics.track('procedure_search_open');
	}

	handleChange(evt) {
		this.data.query = evt.currentTarget.value;
		if (this.data.query.length >= 2) {
			this.doSearch();
		}
	}

	doSearch() {
		this.data.searching = true;
		if (this.fireOffSearch) {
			clearTimeout(this.fireOffSearch);
		}
		this.fireOffSearch = setTimeout(() => {
			if (this.data.query.length >= 2) {
				CloudAPI.procedureSearch(_.clone(this.data.query))
					.then((res) => {
						this.data.searching = false;
						clearTimeout(this.fireOffSearch);
						this.fireOffSearch = null;
						this.data.procedures = res;
						analytics.trackWithData('procedure_search', { query: this.data.query });
					})
					.catch((err) => {
						console.log(err);
						this.$f7.dialog.alert(this.text.searchError);
					});
			} else {
				this.data.searching = false;
			}
		}, 500);
	}

	doProviderSearch() {
		const { activeProcedure, radius, providerType } = this.data;
		this.data.searching = true;
		CloudAPI.procedureProviderSearch(activeProcedure.code, radius, providerType)
			.then((res) => {
				this.data.providers = res;
				this.data.searching = false;
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.alert(this.text.searchError);
				this.data.searching = false;
			});
	}

	buildCodeResults() {
		const { procedures } = this.data;
		if (procedures.length <= 0) {
			return (
				<div className="empty-list vbox vcenter hcenter">
					<img src={procedureSearchImg} />
					<div className="empty-msg">{this.text.empty}</div>
				</div>
			);
		}
		return procedures.map((p) => {
			const { _id, code, code_description, code_type, code_plain_language_description } = p;

			return (
				<div
					key={_id}
					className="procedure-card neubtn"
					onClick={() => {
						this.data.activeProcedure = p;
						analytics.trackWithData('procedure_selected', { code: p.code });
						this.doProviderSearch();
					}}
				>
					<div className="procedure-desc">{code_plain_language_description}</div>
					<div className="procedure-code">
						{code_type}: {code}
					</div>
				</div>
			);
		});
	}

	openProviderProfile(providerData) {
		appStore.selectedProcedure = { ...providerData, ...this.data.activeProcedure };
		ProviderService.openProviderProfile(providerData, 'procedureSearch');
		this.onClose();
	}

	buildProviderResults() {
		const { providers, searching } = this.data;
		if (searching) {
			return (
				<div className="empty-list vbox vcenter hcenter">
					<img src={searchSVG} />
					<div className="empty-msg">{this.text.searchingText}</div>
				</div>
			);
		}
		if (providers.length <= 0) {
			return (
				<div className="empty-list vbox vcenter hcenter">
					<img src={procedureSearchImg} />
					<div className="empty-msg">{this.text.noProviders}</div>
					<Link
						onClick={() => {
							this.data.activeProcedure = null;
						}}
					>
						Search Different Procedure
					</Link>
				</div>
			);
		}

		return providers.map((p, i) => {
			const { _id, provider, negotiated_rate } = p;
			const { isPerson, fullName, NPI, personDetails } = provider;

			return (
				<div
					key={`${NPI}-${_id}`}
					className="provider-card neubtn hbox vcenter"
					onClick={() => {
						this.data.searching = true;
						this.data.activeProvider = p;
						analytics.trackWithData('procedure__provider_selected', { npi: NPI });
						this.loadPricingData();
					}}
				>
					<div className="photo">
						{isPerson ? (
							<img alt="doctor" src={personDetails.gender === 'm' ? doctorMale : doctorFemale} />
						) : (
							<i className="fad fa-hospital"></i>
						)}
					</div>
					<div>
						{this.data.networkProvider && <NetworkChip provider={this.data.networkProvider} />}
						<div className="result-name">{fullName}</div>
						<div className="rate">
							Your Estimated Cost: <span>${Number(negotiated_rate).toFixed(2)}</span>
						</div>
						<div className="subtext">Tap to view personlized estimate</div>
					</div>
				</div>
			);
		});
	}

	getAccumulatorGuage(maxObj) {
		let { accums } = this.data;
		let accum = _.find(accums, { type: maxObj.type }, null);

		if (!accum) {
			accum = {
				type: maxObj.type,
				value: 0
			};
		}
		return (
			<div className="accum vbox vcenter w-100" key={accum.type + Math.random()}>
				<div>
					{`${this.text[accum.type.indexOf('oop') >= 0 ? 'oopText' : 'deductibleText']} ($${Number(accum.value || 0)} of $${Number(
						maxObj.value
					)})`}
				</div>
				<Progressbar
					progress={(Number(accum.value || 0) / Number(maxObj.value)) * 100}
					color={accum.type.indexOf('oop') >= 0 ? 'green' : 'blue'}
					className="neumorphic"
				></Progressbar>
			</div>
		);
	}

	buildAccumulators() {
		const { inNetwork } = ProviderService.getNetworkData(this.data.networkProvider);
		const { oopMax, deductible, customAccumulators } = _.get(userStore, 'userData.group', {});
		const accums = [];

		if (oopMax || deductible) {
			//show header and deductible values
			accums.push(
				<div className="accumulator-group left" key="in-network-accums">
					<p className="group-heading">{this.text.inNetworkAccumText}</p>
					<div className="group-details vbox vcenter hcenter">
						{deductible ? this.getAccumulatorGuage({ type: 'deductible', value: deductible }) : ''}
						{oopMax ? this.getAccumulatorGuage({ type: 'oopMax', value: oopMax }) : ''}
					</div>
				</div>
			);
		}
		if (customAccumulators && Object.keys(customAccumulators).length > 0) {
			const memberAccums = _.cloneDeep(this.data.accums)
				.map((accum) => {
					const isTier = accum.type.indexOf('Tier') > -1;
					const isOut = accum.type.indexOf('Out') > -1;
					const isIn = !isTier && !isOut;
					const isOop = accum.type.indexOf('oop') > -1;
					const isDeductible = accum.type.indexOf('deductible') > -1;
					let category = '';
					if (isTier) {
						const tierNum = accum.type.split('Tier')[1];
						category = this.text[`tier${tierNum}AccumText`];
					}
					if (isOut) {
						category = this.text.outNetworkAccumText;
					}
					if (isIn) {
						category = this.text.inNetworkAccumText;
					}
					const barLabel = this.text[isOop ? 'oopText' : 'deductibleText'];
					return {
						...accum,
						label: barLabel,
						category: category
					};
				})
				.filter((accum) => !!customAccumulators[accum.type])
				.filter((accum) => {
					if (inNetwork) return !accum.type.toLowerCase().includes('outnetwork');
					else return accum.type.toLowerCase().includes('outnetwork');
				});

			const accumCategories = Array.from(new Set(memberAccums.map((x) => x.category)));
			accumCategories.map((accumCat) => {
				const accumItems = memberAccums.filter((x) => x.category === accumCat);
				accums.push(
					<div className="accumulator-group left" key={`accumItem-${accumCat}`}>
						<p className="group-heading">{accumCat}</p>
						<div className="group-details vbox vcenter hcenter">
							{accumItems.map((accumData) => {
								const accumType = accumData.type;
								const accumMaxData = customAccumulators[accumType];
								const accumValueDisplay = `$${Number(accumData.value || 0)}`;
								const accumMaxDisplay = `$${Number(accumMaxData)}`;
								return (
									<div className="accum vbox vcenter w-100" key={accumType + Math.random()}>
										<div>{`${accumData.label} ${accumValueDisplay} of ${accumMaxDisplay}`}</div>
										<Progressbar
											progress={(Number(accumData.value || 0) / Number(accumMaxData)) * 100}
											color={accumType.indexOf('oop') >= 0 ? 'green' : 'blue'}
											className="neumorphic"
										></Progressbar>
									</div>
								);
							})}
						</div>
					</div>
				);
			});
		}
		if (customAccumulators && customAccumulators.length > 0) {
			//check for tiers hard coded 1 & 2 right now
			let oopTier1 = _.find(customAccumulators, { type: `oopTier1` });
			let oopTier2 = _.find(customAccumulators, { type: `oopTier2` });
			let deductibleTier1 = _.find(customAccumulators, { type: `deductibleTier1` });
			let deductibleTier2 = _.find(customAccumulators, { type: `deductibleTier2` });
			let oopOutNetwork = _.find(customAccumulators, { type: `oopOutNetwork` });
			let deductibleOutNetwork = _.find(customAccumulators, { type: `deductibleOutNetwork` });

			if (oopTier1 || deductibleTier1) {
				accums.push(
					<div className="accumulator-group left" key="tier1-accums">
						<p className="group-heading">{this.text.tier1AccumText}</p>
						<div className="group-details vbox vcenter hcenter">
							{deductibleTier1 ? this.getAccumulatorGuage(deductibleTier1) : ''}
							{oopTier1 ? this.getAccumulatorGuage(oopTier1) : ''}
						</div>
					</div>
				);
			}
			if (oopTier2 || deductibleTier2) {
				accums.push(
					<div className="accumulator-group left" key="tier2-accums">
						<p className="group-heading">{this.text.tier2AccumText}</p>
						<div className="group-details vbox vcenter hcenter">
							{deductibleTier2 ? this.getAccumulatorGuage(deductibleTier2) : ''}
							{oopTier2 ? this.getAccumulatorGuage(oopTier2) : ''}
						</div>
					</div>
				);
			}
			if (oopOutNetwork || deductibleOutNetwork) {
				accums.push(
					<div className="accumulator-group left" key="out-network-accums">
						<p className="group-heading">{this.text.outNetworkAccumText}</p>
						<div className="group-details vbox vcenter hcenter">
							{deductibleOutNetwork ? this.getAccumulatorGuage(deductibleOutNetwork) : ''}
							{oopOutNetwork ? this.getAccumulatorGuage(oopOutNetwork) : ''}
						</div>
					</div>
				);
			}
		}
		if (accums.length <= 0) {
			return (
				<div className="empty-message">
					All accumulators total $0 so your portion due is the max amount allowed for the specific accumulation. Ex: Out of Pocket Max
					is $1000, and procedure is $1500, you owe $1000. Please contact your plan administrator for further information.
				</div>
			);
		}
		return accums;
	}

	// {
	// 	accumulatorTypeKey: 'urgentCareCopay',
	// 	currency: 'USD',
	// 	dollarValue: '',
	// 	expressPlanId: 'AD1',
	// 	label: 'Urgent Care Copay',
	// 	orgId: '60a538cc00764b836cddb1f9',
	// 	otherValue: null,
	// 	percentageValue: null,
	// 	sample: '$50',
	// 	valueType: 'dollar'
	// };
	buildAccumDetails() {
		let { accumulatorDetails } = this.data;
		if (accumulatorDetails.length <= 0) {
			return '';
		}
		let details = [];
		_.forEach(accumulatorDetails, (ad) => {
			let { currency, valueType, label, accumulatorTypeKey, dollarValue, otherValue, percentageValue } = ad;
			const value = valueType === 'dollar' ? dollarValue : valueType === 'percentage' ? percentageValue : otherValue;
			if (!_.isEmpty(value) && valueType !== 'other') {
				details.push(
					<div className="detail-item" key={`details-item-key-${accumulatorTypeKey}`}>
						<div className="label">{label}</div>
						<div className="value">{value}</div>
					</div>
				);
			}
		});
		return details;
	}

	buildPriceEstimate() {
		let { code_type, code, code_plain_language_description } = this.data.activeProcedure;
		const { _id, provider, negotiated_rate } = this.data.activeProvider;
		const { isPerson, fullName, NPI, personDetails } = provider;

		return (
			<div className="price-estimate y-scroll">
				<div className="price-card neucard">
					<h3>Procedure:</h3>
					<div className="card-procedure-desc">{code_plain_language_description}</div>
					<div className="card-procedure-code">
						{code_type}: {code}
					</div>
					<h3>Provider:</h3>
					{this.data.networkProvider && <NetworkChip provider={this.data.networkProvider} />}
					<div className="card-result-name">{fullName}</div>
					<div className="card-rate hbox vcenter">
						<div className="grow-1">Your Estimated Coast:</div>
						<span>${Number(negotiated_rate).toFixed(2)}</span>
					</div>
					<div className="divider"></div>
					<div className="disclaimer-1">
						The above price is your estimated cost associated only with this specific procedure. You may have additional copayments or
						coinsurance associated with this type of procedure.
					</div>
					<div className="divider"></div>
					{/* <h3>Plan Details:</h3> //* This has been removed by preference of Joe 4/11/2023 - ZM
					<div className="accumulator-details">{this.buildAccumDetails()}</div> */}
					<div className="divider"></div>
					<h3>Current Accumulators:</h3>
					{this.buildAccumulators()}
					<div className="divider"></div>
					{this.data.networkProvider && (
						<Button
							round
							large
							className="btnfix neumorphic neuhover"
							onClick={() => {
								this.openProviderProfile(this.data.networkProvider);
							}}
						>
							View Provider Profile
						</Button>
					)}
					<div className="disclaimer-2">
						<span>*</span>
						{''}
						1. I understand the fees and/or costs provided via this tool are only estimates and my final responsibility may be higher
						or lower than the estimate for various reasons.
						<br />
						2. I understand this is not a guarantee of benefit plan coverage or payment and the actual payer and patient portion
						reflected on my EOB may also be higher or lower.
						<br />
						3. I understand this estimate DOES NOT include other services billed for separately by other providers or facilities
						associated with this service.
					</div>
				</div>
				<Button
					round
					large
					fill
					className="btnfix neumorphic neuhover"
					onClick={() => {
						window.print();
					}}
				>
					Save
				</Button>
			</div>
		);
	}

	render() {
		let { title, placeholder } = this.text;
		let { opened } = this.props;
		let { query, searching, activeProcedure, activeProvider, radius, providerType, isDisclaimerWarningOpen } = this.data;

		return (
			<Popup className="procedure-search-popup" opened={opened} onPopupClosed={this.onClose}>
				{
					<div
						className={`procedure-search-popup-opactiy-${
							opened && isDisclaimerWarningOpen && !userStore.acceptedProcedureSearchDisclaimer ? 'show' : 'hide'
						}`}
					/>
				}
				{activeProvider && (
					<Page className="filter-page">
						<div className="filter-header hbox vcenter hright">
							{activeProcedure && (
								<Link
									className="back-btn"
									onClick={() => {
										this.data.activeProvider = null;
										this.data.networkProvider = null;
										this.data.searching = false;
									}}
								>
									Back
								</Link>
							)}
							<h1 className="grow-1">Price Estimate</h1>
							<Link popupClose>
								<i className="fad fa-times-square" />
							</Link>
						</div>
						{searching && <Progressbar infinite color="multi" className="animated fadeIn"></Progressbar>}
						{this.buildPriceEstimate()}
					</Page>
				)}
				{!activeProvider && (
					<Page className="filter-page">
						<div className="filter-header hbox vcenter hright">
							{activeProcedure && (
								<Link
									className="back-btn"
									onClick={() => {
										this.data.activeProcedure = null;
									}}
								>
									Back
								</Link>
							)}
							<h1 className="grow-1">{title}</h1>
							<Link popupClose>
								<i className="fad fa-times-square" />
							</Link>
						</div>
						{!activeProcedure && (
							<List className={`search-form hbox vcenter ${appStore.isMobile ? '' : 'desktop'}`} noHairlines>
								<i className="fad fa-search" />
								<ListInput
									className={appStore.isMobile ? 'mobile' : ''}
									autofocus={true}
									type="text"
									placeholder={placeholder}
									value={query}
									onChange={this.handleChange}
								/>
							</List>
						)}

						{searching && <Progressbar infinite color="multi" className="animated fadeIn"></Progressbar>}
						{!activeProcedure && <div className="inner-page-content y-scroll">{this.buildCodeResults()}</div>}
						{activeProcedure && (
							<div className="search-controls">
								<div className="active-procedure">
									<div className="procedure-desc ellipse">{activeProcedure.code_plain_language_description}</div>
									<div className="procedure-code">
										{activeProcedure.code_type}: {activeProcedure.code}
									</div>
								</div>
								<div className="type-toggle">
									<div
										className={`type-btn ${providerType === 'facilities' ? 'active' : ''}`}
										onClick={() => {
											this.data.providerType = 'facilities';
											analytics.trackWithData('provider_type_change', { type: providerType });
											this.doProviderSearch();
										}}
									>
										<i className="fad fa-hospital"></i>
										<div>Facilities</div>
									</div>
									<div
										className={`type-btn ${providerType === 'individuals' ? 'active' : ''}`}
										onClick={() => {
											this.data.providerType = 'individuals';
											analytics.trackWithData('provider_type_change', { type: providerType });
											this.doProviderSearch();
										}}
									>
										<i className="fad fa-user-md"></i>
										<div>Individuals</div>
									</div>
								</div>
								<div className="range">
									<RangeSlider
										min={10}
										max={65}
										step={5}
										value={radius}
										scale={true}
										scaleSteps={5}
										scaleSubSteps={5}
										onChange={(e) => {
											this.data.radius = e;
											analytics.trackWithData('procedure_range_change', { range: e });
											this.doProviderSearch();
										}}
										source="procedureSearch"
									/>
								</div>
							</div>
						)}
						{activeProcedure && <div className="inner-page-content y-scroll providers">{this.buildProviderResults()}</div>}
					</Page>
				)}

				<Sheet
					className="procedure-search-popup-sheet-disclaimer"
					opened={this.data.isDisclaimerWarningOpen && !userStore.acceptedProcedureSearchDisclaimer}
					push
				>
					<Toolbar>
						<div className="left">
							<Link
								onClick={() => {
									this.data.isDisclaimerWarningOpen = false;
									userStore.acceptedProcedureSearchDisclaimer = true;
								}}
							>
								I ACCEPT
							</Link>
						</div>
						<div className="right">
							<Link
								onClick={() => {
									// IS this a problem
									this.data.isDisclaimerWarningOpen = false;
									this.onClose();
								}}
							>
								I DO NOT ACCEPT
							</Link>
						</div>
					</Toolbar>
					<BlockTitle medium className="margin-top">
						Disclaimer:
					</BlockTitle>
					<List>
						<ListItem>
							1. I understand the fees and/or costs provided via this tool are only estimates and my final responsibility may be higher
							or lower than the estimate for various reasons.
						</ListItem>
						<ListItem>
							2. I understand this is not a guarantee of benefit plan coverage or payment and the actual payer and patient portion
							reflected on my EOB may also be higher or lower.
						</ListItem>
						<ListItem>
							3. I understand this estimate DOES NOT include other services billed for separately by other providers or facilities
							associated with this service.
						</ListItem>
					</List>
				</Sheet>
			</Popup>
		);
	}
}
