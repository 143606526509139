import React, { Component } from 'react';
import Logo from '../../static/logo/Logo';
import './global-header.scss';
import _ from 'lodash';
import appStore from '../../stores/AppStore';
import userStore from '../../stores/UserStore';
import ProviderService from '../../services/ProviderService';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import UserService from '../../services/UserService';
import { observer, observable } from '../../utils/State';
import { Popup, List, ListInput, ListItem, Button, Block, Progressbar, Link } from 'framework7-react';
import AnalyticsService from '../../services/AnalyticsService';
import LocationService from '../../services/LocationService';

const analytics = new AnalyticsService();
@observer
export default class GlobalHeader extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ zip: '', searching: false, cities: [], selected: '' });
		this.text = getCmpText('GlobalHeader', {
			locationAlert: 'Unable to access location please set manually',
			setLocation: 'Set Location',
			zipLabel: 'Search Zip Code',
			zipPlaceholder: 'The zip code you want to find care in or around.',
			title: 'Set Your Search Location',
			userCurrent: 'Use Current Location',
			enableLocationService: '*To use your current location, enable location services for CareValet on your device.'
		});
		autoBind(this);
	}

	handleAppStateAfterManualLocationChange() {
		appStore.setLocationOpen = false;
		ProviderService.searchForProviders();
	}

	async useCurrentLocation() {
		userStore.manualLocation = false;
		this.$f7.dialog.preloader();
		try {
			const locationData = await LocationService.setLocation('bgGeo', true);
			this.$f7.dialog.close();
			this.handleAppStateAfterManualLocationChange();
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.locationAlert);
		}
	}

	onSetLocation() {
		const isApp = appStore.isApp();
		const bgGeo = window.BackgroundGeolocation;
		if (isApp && bgGeo) {
			console.log('stop getting location updates');
			bgGeo.stop();
		}
		let selected = _.find(this.data.cities, { zip: this.data.selected });
		userStore.manualLocation = true;
		UserService.setUserLocation(selected)
			.then(() => {
				this.handleAppStateAfterManualLocationChange();
				analytics.trackWithData('Manual Location Change', { type: 'input', status: 'success', source: 'user input' });
			})
			.catch(() => {
				this.$f7.dialog.alert(this.text.locationAlert);
				analytics.trackWithData('Manual Location Change', { type: 'input', status: 'error', source: 'user input' });
			});
	}

	openFindLocation() {
		appStore.setLocationOpen = true;
	}

	closeFindLocation() {
		appStore.setLocationOpen = false;
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		this.data.zip = target.value;
		if (this.data.zip.length >= 4) {
			this.zipSearch();
		}
	}

	async zipSearch() {
		if (this.data.zip.length >= 5) {
			this.data.searching = true;
			try {
				console.log('searching');
				const zipResults = await UserService.citySearch(this.data.zip);
				console.log('completed search');
				this.data.searching = false;
				this.data.cities = zipResults;
				this.data.selected = null;
			} catch (err) {
				console.log('error finding zip');
			}
		}
	}

	buildListItems() {
		let { cities } = this.data;
		let items = [];
		_.forEach(cities, (c) => {
			items.push(
				<ListItem
					checkbox
					key={c.zip}
					checked={this.data.selected === c.zip}
					onClick={() => {
						this.data.selected = c.zip;
					}}
				>
					{`${c.city} ${c.state_name}, ${c.zip}`}
				</ListItem>
			);
		});
		return items;
	}

	onClearInput(evt) {
		this.data.zip = '';
	}

	//TODO build swipe up for location capture
	//city name input, default to reverse zip from member data
	//use current location button
	//set location on app initilization
	render() {
		let { zip, selected, searching } = this.data;
		let { zipLabel, zipPlaceholder, title, setLocation, userCurrent, enableLocationService } = this.text;
		return (
			<div className={`global-header ${appStore.isMobile ? '' : 'desktop'}`}>
				<div className="header-ctn hbox vcenter w-100">
					{appStore.isMobile && <Logo icon size="small" />}
					<div className="w-100" />
					<div className="current-location hbox vcenter hcenter" onClick={this.openFindLocation}>
						<i className="fad fa-map-marked-alt" />
						<div className="location ellipse">{_.get(appStore, 'currentLocation.name', setLocation)}</div>
					</div>
				</div>
				<div className="color-bar"></div>
				<Popup
					className="find-location-sheet y-scroll"
					opened={appStore.setLocationOpen}
					onSheetClosed={this.closeFindLocation}
					backdrop
				>
					<Block>
						<div className="close">
							<Link popupClose onClick={this.closeFindLocation}>
								<i className="fad fa-times-square" />
							</Link>
						</div>
						<h2 className="title">{title}</h2>
						<div className="grow-1 vbox vcenter hcenter">
							<i className="fad fa-street-view animated infinite pulse" />
						</div>

						<List className="options-list no-hairlines" mediaList>
							<ListInput
								label={zipLabel}
								type="text"
								placeholder={zipPlaceholder}
								clearButton
								floatingLabel={true}
								className="md"
								name="zip"
								value={zip}
								onChange={this.onInputChange}
								onInputClear={this.onClearInput}
							/>
							{searching && <Progressbar infinite color="multi" className="animated fadeIn" />}
							{this.buildListItems()}
						</List>
						<Button fill large round className="set-location" disabled={!selected} onClick={this.onSetLocation}>
							{setLocation}
						</Button>
						<Button large onClick={this.useCurrentLocation}>
							{userCurrent}
						</Button>
						{!userStore.locationServiceEnabled && <p className="location-info-text">{enableLocationService}</p>}
					</Block>
				</Popup>
			</div>
		);
	}
}
