import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import noDocuments from '@/assets/no-documents.svg';
import './plan-document-details.scss';
import ExpressAPI from '@/services/ExpressAPI';
import expressStore from '@/stores/ExpressStore';
import { Preloader } from 'framework7-react';
import LinkDocumentPopup from '../link-document-popup/LinkDocumentPopup';
import { DOC_ICON } from '../document-constants';
import DocumentPreview from '../document-preview/DocumentPreview';
@observer
export default class PlanDocumentDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			documentsLoading: false,
			documents: [],
			previewDoc: null,
			showLinkCard: false,
			showPreview: false
		});
		autoBind(this);
	}

	componentDidMount() {
		this.fetchPlanDocuments();
	}

	fetchPlanDocuments() {
		this.data.documentsLoading = true;
		ExpressAPI.getPlanDocuments(expressStore.activePlan?._id)
			.then((resp) => {
				this.data.documents = resp;
				this.data.documentsLoading = false;
			})
			.catch((e) => {
				this.data.documentsLoading = false;
				showExpressError(this.$f7, e);
			});
	}

	toggleLinkCardPopup() {
		this.data.showLinkCard = !this.data.showLinkCard;
	}

	handleLinkDocumentClose() {
		this.fetchPlanDocuments();
		this.data.showLinkCard = false;
	}

	handleUnlinkDocument(document) {
		this.$f7.dialog.confirm(
			'Note: This will not delete the document',
			`Are you sure you want to remove "${document.name}" from plan?`,

			() => {
				// this.data.documentsLoading = true;
				ExpressAPI.unlinkDocument(expressStore.activePlan._id, document._id)
					.then((res) => {
						// this.data.documentsLoading = false;
						// this.data.documents = res;
						this.fetchPlanDocuments();
					})
					.catch((err) => {
						this.data.documentsLoading = false;
						showExpressError(this.$f7, err);
					});
			},
			() => {}
		);
	}
	render() {
		const { showLinkCard, documents, documentsLoading } = this.data;
		return (
			<div className="card vbox hcenter animated sldeIn plan-document-details">
				<div className="card-header">
					<div className="title grow-2">Documents</div>
					<i className="fa fa-plus" title="Add document" onClick={this.toggleLinkCardPopup}></i>
				</div>
				<div className="documents-body">
					<PlanDocumentsBody
						documentsLoading={documentsLoading}
						documents={documents}
						toggleLinkCardPopup={this.toggleLinkCardPopup}
						onUnlinkDocument={this.handleUnlinkDocument}
						onPreviewOpen={(doc) => {
							this.data.previewDoc = doc;
							this.data.showPreview = true;
						}}
					/>
				</div>

				<LinkDocumentPopup opened={showLinkCard} onClose={this.handleLinkDocumentClose} planDocuments={documents} />
				<DocumentPreview
					onClose={() => {
						this.data.previewDoc = null;
						this.data.showPreview = false;
					}}
					opened={this.data.showPreview}
					document={this.data.previewDoc}
				/>
			</div>
		);
	}
}

const PlanDocumentsBody = (props) => {
	const { documents, documentsLoading, toggleLinkCardPopup } = props;
	if (documentsLoading) {
		return (
			<div>
				<Preloader />
			</div>
		);
	} else if (documents.length == 0 && !documentsLoading) {
		return <NoDocuments toggleLinkCardPopup={toggleLinkCardPopup} />;
	} else {
		return <DocumentList {...props} />;
	}
};

const NoDocuments = (props) => {
	const { toggleLinkCardPopup } = props;
	return (
		<div className="vbox vcenter hcenter card-body no-documents ">
			<img src={noDocuments} />
			<h2>No documents!</h2>
			<p>It seems you haven't added any documents to this plan yet.</p>
			<a onClick={toggleLinkCardPopup} className="btn round-btn">
				Add a document
			</a>
		</div>
	);
};

const DocumentList = (props) => {
	const { documents, onUnlinkDocument, onPreviewOpen, onPreviewClose, showPreview } = props;
	return (
		<div className="documents">
			{documents.map((document) => {
				return <Document onPreviewOpen={onPreviewOpen} document={document} key={document._id} onUnlinkDocument={onUnlinkDocument} />;
			})}
		</div>
	);
};

const Document = (props) => {
	const { document, onUnlinkDocument, onPreviewOpen } = props;
	return (
		<div className="document hbox vcenter">
			<div className="icon">
				<i className={`fa ${DOC_ICON[document.fileData.fileType]}`}></i>
			</div>
			<div className="vbox grow-1">
				<div className="name">{document.name}</div>
				<div className="type">{document.type}</div>
			</div>

			<div className="actions">
				<a className="btn" onClick={() => onPreviewOpen(document)}>
					<i className="fad fa-eye"></i>
				</a>
				<a className="btn remove" onClick={(e) => onUnlinkDocument(document)}>
					<i className="fad fa-trash"></i>
				</a>
			</div>
		</div>
	);
};
