import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveLine } from '@nivo/line';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './line-chart.scss';

@observer
export default class LineChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getColor(data) {
		return data.color;
	}

	handleItemClick(data) {
		//does not get triggered with slice tooltip => leaving here for future possibilities
		console.log('line item was clicked: ', data);
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const chartData = chartProperties.data;
		const chartConfig = chartProperties.config;
		return (
			<ResponsiveLine
				data={chartData}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				margin={{ top: isMobile ? 20 : 20, right: 50, bottom: isMobile ? 70 : 50, left: 60 }}
				xScale={{ type: 'point' }}
				yScale={{
					type: 'linear',
					min: chartConfig.yMin,
					max: chartConfig.yMax ? chartConfig.yMax : 'auto',
					stacked: chartConfig.stacked,
					reverse: false
				}}
				onClick={this.handleItemClick}
				axisTop={null}
				axisRight={null}
				sliceTooltip={({ slice }) => {
					return (
						<div
							style={{
								background: 'white',
								padding: '9px 12px',
								border: '1px solid #ccc'
							}}
						>
							{slice.points
								.sort((a, b) => (a.data.y < b.data.y ? 1 : -1))
								.map((point) => (
									<div
										key={point.id}
										style={{
											color: point.serieColor,
											padding: '3px 0'
										}}
									>
										<strong>{point.data.pointLabel || point.serieId}</strong> [{point.data.yFormatted}]
									</div>
								))}
						</div>
					);
				}}
				enableSlices="x"
				axisBottom={{
					orient: 'bottom',
					tickSize: 5,
					tickPadding: isMobile ? 0 : 20,
					tickRotation: isMobile ? Number(-90) : 0
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: chartConfig.xLabel || 'count',
					legendOffset: -50,
					legendPosition: 'middle'
				}}
				lineWidth={1}
				pointSize={6}
				pointColor="#fff"
				pointBorderWidth={1}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabelYOffset={-12}
				enableArea={chartConfig.showArea}
				areaOpacity={chartConfig.areaOpacity || 0.2}
				useMesh={false}
				theme={{
					fontSize: 12,
					grid: {
						line: {
							strokeDasharray: '4 4'
						}
					}
				}}
			/>
		);
	}
}
