import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import userStore from '../../../stores/UserStore';
import AnalyticsService from '../../../services/AnalyticsService';
import GradiantStack from '../../../components/gradiant-stack/GradiantStack';
import signupSVG from '../../../assets/signup.svg';
import BasicInput from '../../../components/basic-input/BasicInput';

const analytics = new AnalyticsService();

@observer
export default class ConsumerSignup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ disableSignup: !this.validateForm() });
		this.text = getCmpText('ConsumerSignup', {
			title: 'Sign Up',
			firstNameLabel: 'First Name',
			firstNamePlaceholder: 'John',
			lastNameLabel: 'Last Name',
			lastNamePlaceholder: 'Doe',
			zipLabel: 'Zip Code',
			zipPlaceholder: '12345',
			phoneLabel: 'Mobile Number',
			phonePlaceholder: '5554443333',
			sendCode: 'Send verification code',
			sendCodeDisclaimer: '*You will receive a text message to verify your number carrier rates may apply'
		});
		autoBind(this);
	}

	componentDidMount() {}

	onBack() {
		if (this.props.onBack) {
			this.props.onBack('consumer-signup');
		}
	}

	async onNext() {
		analytics.trackWithData('Consumer Sign Up', { step: 'user info form' });
		if (this.props.onNext) {
			this.props.onNext('consumer-verify-contact');
		}
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		let value = target.value;
		if (target.name === 'zip' || target.name === 'mobile') {
			value = value.replace(/\D/g, '');
		}
		userStore.signupData[target.name] = value;
		this.data.disableSignup = !this.validateForm(target);
	}

	validateForm(target) {
		if (target && !target.validity.valid) {
			return false;
		}
		let { first, last, zip, mobile } = userStore.signupData;
		let valid = true;
		if (_.isEmpty(first) || _.isEmpty(last)) {
			valid = false;
		}
		if (mobile.length !== 10) {
			valid = false;
		}
		if (zip.toString().length !== 5) {
			valid = false;
		}
		return valid;
	}

	render() {
		let { first, last, zip, mobile } = userStore.signupData;

		return (
			<>
				<GradiantStack stack="2" consumer animate={false} id="consumer-signup" />
				<div className={`consumer-signup-page y-scroll`}>
					<div className="header-ctn hbox vcenter">
						<div className="back hbox vcenter animated fadeInLeft" onClick={this.onBack}>
							<i className="fal fa-chevron-left"></i>Back
						</div>
						<div className="grow-1"></div>
					</div>
					<div className="content-ctn vbox hcenter animated fadeInRight">
						<img src={signupSVG} className="herosvg" />
						<h1>{this.text.title}</h1>
						<BasicInput
							name={'first'}
							label={this.text.firstNameLabel}
							type="text"
							placeholder={this.text.firstNamePlaceholder}
							value={first}
							onChange={this.onInputChange}
							validate={true}
						/>
						<BasicInput
							name={'last'}
							label={this.text.lastNameLabel}
							type="text"
							placeholder={this.text.lastNamePlaceholder}
							value={last}
							onChange={this.onInputChange}
							validate={true}
						/>
						<BasicInput
							name={'zip'}
							label={this.text.zipLabel}
							type="text"
							placeholder={this.text.zipPlaceholder}
							value={zip}
							onChange={this.onInputChange}
							maxlength={5}
							minlength={5}
							validate={true}
						/>
						<BasicInput
							name={'mobile'}
							label={this.text.phoneLabel}
							type="text"
							placeholder={this.text.phonePlaceholder}
							value={mobile}
							onChange={this.onInputChange}
							maxlength={10}
							minlength={10}
							validate={true}
						/>
						<Button fill large className="round-btn purple" onClick={this.onNext} disabled={this.data.disableSignup}>
							{this.text.sendCode} <i className="far fa-long-arrow-right"></i>
						</Button>
						<div className="message-box">{this.text.sendCodeDisclaimer}</div>
					</div>
				</div>
			</>
		);
	}
}
