import React, { Component } from 'react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import GettingStarted from './getting-started/GettingStarted';
import './home.scss';
import MetricsBar from '@/components/_EXPRESS/metrics-bar/MetricsBar';
import ExpressAPI from '@/services/ExpressAPI';
import { Button } from 'framework7-react';
@observer
export default class Home extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loadedFrom: null,
			showGettingStarted: false,
			applicationConfigured: false,
			metricsLoading: true,
			metrics: { groups: 0, plans: 0, totalMembers: 0, primaryMembers: 0, networks: 0 }
		});
		autoBind(this);
	}

	componentDidMount() {}

	asyncLoadMetrics() {
		setTimeout(() => {
			this.loadMetrics();
			let orgRole = expressStore.currentOrgRole();
			const setupComplete = expressStore.userData.user.setupCompleted;
			const isOrgAdmin = orgRole && orgRole.role === expressStore.roleEnums.ORG_ADMIN;
			const isSystemAdmin = expressStore.isSystemAdmin();
			if (!setupComplete && (isOrgAdmin || isSystemAdmin)) {
				this.data.showGettingStarted = true;
			}
		}, 0);
	}

	loadMetrics() {
		this.data.metricsLoading = true;
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		ExpressAPI.getMetrics()
			.then((res) => {
				this.data.metrics = res;
				this.data.metricsLoading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.metricsLoading = false;
			});
	}

	handleStepClick(type) {
		switch (type) {
			case 'group': {
				expressStore.activePage = 'groups';
				break;
			}
			case 'networks': {
				expressStore.activePage = 'networks';
				break;
			}
			case 'plan': {
				const hasOrphanPlans = _.get(this.data, 'metrics.orphanPlans');
				if (hasOrphanPlans) {
					expressStore.activePage = 'groups';
				} else {
					expressStore.activePage = 'allPlans';
				}
				break;
			}
			case 'members': {
				expressStore.activePage = 'members';
				break;
			}
			case 'finalize': {
				this.data.applicationConfigured = true;
				expressStore.userData.user.setupCompleted = true;
				this.data.showGettingStarted = false;
				ExpressAPI.setupCompleted();
				break;
			}
		}
	}

	render() {
		let { metrics, applicationConfigured, metricsLoading, loadedFrom } = this.data;
		let { currentOrg } = expressStore;
		if (loadedFrom !== currentOrg._id && expressStore.isLoggedIn) {
			this.asyncLoadMetrics();
		}
		return (
			<div className="express-home-page">
				<h1 className="text-4 mb-xl">Welcome {_.capitalize(_.get(expressStore, 'userData.user.firstName', ''))}</h1>
				{this.data.showGettingStarted && (
					<GettingStarted metrics={metrics} applicationConfigured={applicationConfigured} handleStepClick={this.handleStepClick} />
				)}
				<MetricsBar
					title={`${currentOrg.name} Metrics`}
					loadMetrics={this.loadMetrics}
					metrics={metrics}
					showRefresh={true}
					loading={metricsLoading}
				></MetricsBar>
				<div className="hbox vcenter">
					<div className="support-cta grow-1">
						<div className="hbox vcenter">
							<i className="fad fa-life-ring" /> <h3>Need Help?</h3>
						</div>
						<div className="support-message">
							Express has compiled a large assortment of tutorials for all major actions on the platform. You can check them out by
							going to 'Support' on the navigation bar or by clicking this button!
							<Button
								fill
								small
								onClick={() => {
									expressStore.activePage = 'support';
								}}
							>
								Support
							</Button>
						</div>
					</div>
					<div className="support-cta grow-1">
						<div className="hbox vcenter">
							<i className="fad fa-chart-bar" /> <h3>Looking for Reports?</h3>
						</div>
						<div className="support-message">
							Express provides insights into key metrics about your users and their usage. You can check them out by going to
							'Reporting' on the navigation bar or by clicking this button!
							<Button
								fill
								small
								onClick={() => {
									expressStore.activePage = 'reporting';
								}}
							>
								Reporting
							</Button>
						</div>
					</div>
				</div>
				<div className="hbox vcenter">
					<div className="support-cta grow-1">
						<div className="hbox vcenter">
							<i className="fad fa-folders" /> <h3>Searching for a document?</h3>
						</div>
						<div className="support-message">
							Express has unlimited document storage! You can upload and manage documents for your plans with ease. Check it out by
							going to 'Documents' on the navigation bar or by clicking this button!
							<Button
								fill
								small
								onClick={() => {
									expressStore.activePage = 'documents';
								}}
							>
								Documents
							</Button>
						</div>
					</div>
					<div className="support-cta grow-1">
						<div className="hbox vcenter">
							<i className="fad fa-users" /> <h3>Onboard a new Team Member!</h3>
						</div>
						<div className="support-message">
							Hire a new team member? You can invite them to the platform with just a few simple steps. First go to 'Settings' on the
							navigation bar or by clicking this button. Then navigate to 'My Team' to send them an invite!
							<Button
								fill
								small
								onClick={() => {
									expressStore.activePage = 'settings';
								}}
							>
								Invite My Team
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
