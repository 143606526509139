import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import './printing-groups.scss';
import _ from 'lodash';
import { Button, Chip } from 'framework7-react';
import PrintingWizardPopup from './PrintingWizardPopup';

//* Enums for group.printingType value
export const PrintType = {
	BULK: 'bulk',
	INDIVIDUAL: 'individual'
};
@observer
export default class PrintingGroups extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true,
			updated: {},
			removed: [],
			changedSelection: false,
			selectGroupOpen: false,
			selectedGroup: null
		});
		autoBind(this);
	}

	onSaveChanges() {
		const toUpdate = Object.values(this.data.updated).map((group) => {
			let {
				street,
				street2,
				city,
				state,
				zipcode,
				saveAddress,
				contactName,
				includeCarrierLettersForBulkShipment
			} = group;
			let payload = {
				internalGroupId: group._id,
				printingType: group.printingType,
				groupName: group.name,
				street,
				street2,
				city,
				state,
				zipcode,
				saveAddress,
				contactName,
				includeCarrierLettersForBulkShipment
			};
			return payload;
		});
		this.$f7.dialog.preloader('Saving...');
		ExpressAPI.savePrintingGroups({
			toRemove: this.data.removed,
			toUpdate: toUpdate
		})
			.then(() => {
				this.$f7.dialog.close();
				if (this.props.reload) {
					this.props.reload();
				}
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	promptPrintingWizard(group) {
		this.data.selectedGroup = group;
		this.data.selectGroupOpen = true;
	}

	closeGroupPopup() {
		this.data.selectGroupOpen = false;
	}

	onWizardSubmit({ type, address, carrierLetterInfo }) {
		const selectedGroupId = _.clone(this.data.selectedGroup._id);
		const group = this.data.selectedGroup;

		let { updated } = this.data;
		let isRemoved = this.data.removed.indexOf(group._id) >= 0;
		let isEnabled = !_.isEmpty(group?.printingType);
		let hasChanges = !_.isEmpty(updated[group._id]);
		if ((isEnabled && !isRemoved) || (isEnabled && hasChanges) || hasChanges) {
			this.$f7.dialog.confirm(
				'Currently we allow multiple fulfillments per group. Please double check the members selected are the members that needs ID Cards.',
				'Are you sure?',
				() => {
					delete updated[group._id];
					this.stageGroupUpdates({ type, address, carrierLetterInfo });
				}
			);
		} else {
			this.stageGroupUpdates({ type, address, carrierLetterInfo });
		}
	}

	stageGroupUpdates({ type, address, carrierLetterInfo }) {
		const selectedGroupId = _.clone(this.data.selectedGroup._id);
		const group = this.data.selectedGroup;
		if (!type) {
			this.data.removed.push(selectedGroupId);
		} else {
			//* Clear address if it's actually empty
			if (_.isEmpty(address.street)) {
				address = {};
			}
			//* Update this groups type and address
			this.data.updated[_.clone(this.data.selectedGroup._id)] = {
				..._.cloneDeep(this.data.selectedGroup),
				printingType: type,
				...address,
				...carrierLetterInfo
			};
			//* If they were previously marked for removal undo that
			_.pull(this.data.removed, this.data.selectedGroup._id);
		}
		this.data.selectedGroup = null;
		this.data.selectGroupOpen = false;
	}

	selectGroup(group) {
		this.promptPrintingWizard(group);
	}

	render() {
		return (
			<div className="express-printing-groups">
				<div className="details-text hbox vcenter">
					<p className="grow-2">
						Click on the groups that you would like to enable shipping for. The
						green indicator means printing is enabled, and each group will
						indicate weather it's setup for bulk or individual shipping. Once
						you select your groups, make sure to click save.
					</p>
					<div className="hbox vcenter hright grow-1">
						<Button
							small
							className="list-btn ios secondary"
							disabled={Object.keys(this.data.updated).length <= 0}
							onClick={() => {
								this.data.updated = {};
								this.data.removed = [];
							}}
						>
							Clear Changes
						</Button>
						<Button
							fill
							small
							className="round-btn list-btn"
							disabled={
								!(
									Object.keys(this.data.updated).length > 0 ||
									this.data.removed.length > 0
								)
							}
							onClick={this.onSaveChanges}
						>
							Save
						</Button>
					</div>
				</div>
				<div className="group-ctn">
					{/* TODO: Need to make it so that only an 100 can be ordered at one time. */}
					{this.props.groups.map((g) => {
						let updateRec = this.data.updated[g._id];
						let isRemoved = this.data.removed.indexOf(g._id) >= 0;
						let printingType = updateRec
							? updateRec.printingType
							: g.printingType;
						return (
							<div
								className={`group ${updateRec && !isRemoved && 'selected'}`}
								key={`group-toggle-${g._id}`}
								onClick={() => {
									this.selectGroup(g);
								}}
							>
								<div className="details hbox vcenter">
									{!isRemoved && !_.isEmpty(printingType) ? (
										<Chip
											text={
												printingType === PrintType.BULK
													? 'Bulk Shipping'
													: printingType === PrintType.INDIVIDUAL
													? 'Individual Shipping'
													: ''
											}
											mediaBgColor={'green'}
											className="ios shipping-chip"
										>
											{printingType === PrintType.BULK ? (
												<i slot="media" className="fas fa-box"></i>
											) : (
												<i slot="media" className="fas fa-envelope" />
											)}
										</Chip>
									) : (
										<Chip
											text={'Disabled'}
											mediaBgColor={'grey'}
											className="ios shipping-chip"
										>
											<i slot="media" className="fas fa-do-not-enter"></i>
										</Chip>
									)}
								</div>
								<div className="name hbox vcenter hcenter">{g.name}</div>
							</div>
						);
					})}
				</div>
				<PrintingWizardPopup
					opened={this.data.selectGroupOpen}
					onClose={this.closeGroupPopup}
					group={this.data.selectedGroup}
					onSubmit={this.onWizardSubmit}
				></PrintingWizardPopup>
			</div>
		);
	}
}
