import React, { Component } from 'react';
import { List, Button, BlockFooter, ListInput, ListItem, Link, Row } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind, getUrlParams } from '../../../utils/GeneralUtils';
import waves from './background.svg';
import pplogo from '../../../assets/pp.svg';
import logoImage from '../../../assets/logoAsset.png';
import './provider-login.scss';
import userStore from '../../../stores/UserStore';

@observer
export default class ProviderLogin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			email: '',
			password: '',
			confirmpassword: '',
			first: '',
			last: '',
			practicePhone: '',
			npi: '',
			accessCode: '',
			emailValid: false,
			showRegistration: false
		});
		this.text = getCmpText('ProviderLogin', {
			title: 'Welcome back,',
			signIn: 'Sign In',
			subtitle: 'Sign in to continue',
			resetPwd: 'Forgot password?',
			signUp: 'Sign Up',
			remember: 'Remember me',
			accountMsg: "Don't have an account?",
			emailLabel: 'E-mail',
			passwordLabel: 'Password',
			emailPlaceholder: 'Input your email',
			pwdPlaceholder: 'Input your password',
			loggingIn: 'Validating Credentials....',
			forgotPasswordSuccess: 'Please check your email to reset your password',
			forgotPasswordFailure: 'Unable to complete password reset',
			forgotPasswordModalTitle: 'Reset Password'
		});
		autoBind(this);
	}

	componentDidMount() {
		this.data.urlParams = getUrlParams();
		let accessCode = _.get(this.data.urlParams, 'accessCode', null);
		if (accessCode) {
			this.data.accessCode = accessCode;
			this.data.showRegistration = true;
		}
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		this.data[target.name] = target.value;
		if (target.name === 'email' && target.validity.valid) {
			this.data.emailValid = true;
		} else if (target.name === 'email') {
			this.data.emailValid = false;
		}
	}

	onClearInput(evt) {
		this.data[evt.currentTarget.name] = '';
		if (evt.currentTarget.name === 'email') {
			this.data.emailValid = false;
		}
	}

	onSignin() {
		//! TODO ProvuderPortalAuthService stuff
		userStore.isLoggedIn = true;
		this.$f7.views.appShell.router.navigate('/providerhome', { animate: false, reloadAll: true });
	}

	openRegister() {
		this.data.showRegistration = !this.data.showRegistration;
	}

	render() {
		if (userStore.isLoggedIn) {
			return '';
		}
		let { emailLabel, emailPlaceholder, passwordLabel, pwdPlaceholder } = this.text;
		let { email, password, first, last, npi, accessCode, practicePhone, confirmpassword, emailValid, showRegistration } = this.data;
		return (
			<div className="provider-portal vbox vcenter hcenter">
				<img className="waves" src={waves} alt="waves" />
				<img className="pplogo" src={pplogo} alt="logo" />
				<div className={`signinregister-card vbox vcenter hcenter ${showRegistration ? 'expanded' : ''}`}>
					{!showRegistration && (
						<>
							<div className="welcome vbox vcenter hleft">
								<h1>Provider Portal</h1>
								<div className="poweredby hbox vcenter hleft w-100">
									<div>Powered By:</div>
									<img src={logoImage} alt="logo" />
									<div className="c">Care</div>
									<div className="v">Valet</div>
								</div>
								<h2>Welcome back,</h2>
								<div>Sign in to continute</div>
							</div>
							<List className="provider-login-form" noHairlines>
								<ListInput
									label={emailLabel}
									type="email"
									placeholder={emailPlaceholder}
									clearButton
									name="email"
									value={email}
									onChange={this.onInputChange}
									onInputClear={this.onClearInput}
									validate
									outline
									autofocus
								/>
								<ListInput
									label={passwordLabel}
									type="password"
									placeholder={pwdPlaceholder}
									clearButton
									name="password"
									value={password}
									onChange={this.onInputChange}
									onInputClear={this.onClearInput}
									outline
								/>
							</List>
							<div className="btn-ctn vbox vcenter hcenter">
								<Button fill large className="signin-btn" onClick={this.onSignin}>
									Sign In
								</Button>
							</div>
							<Link className="register" onClick={this.openRegister}>
								Create Account
							</Link>
							<Link>Forgot Password?</Link>
						</>
					)}
					{showRegistration && (
						<>
							<div className="welcome vbox vcenter hleft">
								<h1>Sign Up</h1>
								<div>It's quick and easy.</div>
							</div>
							<div className="provider-login-form">
								<List className="horizontal hbox vcenter" noHairlines>
									<ListInput
										label={'First Name*'}
										type="text"
										placeholder={'First name'}
										clearButton
										name="first"
										value={first}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										outline
										autofocus
									/>
									<ListInput
										label={'Last Name*'}
										type="text"
										placeholder={'Last name'}
										clearButton
										name="last"
										value={last}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										outline
									/>
								</List>
								<List className="horizontal hbox vcenter" noHairlines>
									<ListInput
										label={'NPI Number*'}
										type="text"
										placeholder={'NPI Number'}
										clearButton
										name="npi"
										value={npi}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										outline
									/>
									<ListInput
										label={'Access Code'}
										type="text"
										placeholder={'Have an access code?'}
										clearButton
										name="accessCode"
										value={accessCode}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										outline
									/>
								</List>
								<List className="horizontal hbox vcenter" noHairlines>
									<ListInput
										label={'Practice Phone*'}
										type="tel"
										placeholder={'Requires Verification'}
										clearButton
										name="practicePhone"
										value={practicePhone}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										validate
										outline
									/>
									<ListInput
										label={'Email*'}
										type="email"
										placeholder={emailPlaceholder}
										clearButton
										name="email"
										value={email}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										validate
										outline
									/>
								</List>
								<List className="horizontal hbox vcenter" noHairlines>
									<ListInput
										label={'Password*'}
										type="password"
										placeholder={pwdPlaceholder}
										clearButton
										name="password"
										value={password}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										outline
									/>
									<ListInput
										label={'Confirm Password*'}
										type="password"
										placeholder={pwdPlaceholder}
										clearButton
										name="confirmpassword"
										value={confirmpassword}
										onChange={this.onInputChange}
										onInputClear={this.onClearInput}
										outline
									/>
								</List>
							</div>
							<div className="btn-ctn vbox vcenter hcenter">
								<Button fill large className="signin-btn" onClick={this.onSignin}>
									Sign Up
								</Button>
							</div>
							<Link className="register" onClick={this.openRegister}>
								Back
							</Link>
						</>
					)}
				</div>
			</div>
		);
	}
}
