import React, { Component } from 'react';
import { Page, Chip, Link, Block, Button, Fab, Icon, FabButtons, FabButton } from 'framework7-react';
import { getCmpText } from '@/utils/Localization';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import providerStore from '@/stores/ProviderStore';
import appStore from '@/stores/AppStore';
import './provider-profile.scss';
import ProviderService from '@/services/ProviderService';
import ExternalService from '@/services/ExternalService';
import { autoBind, getProviderIcon } from '@/utils/GeneralUtils';
import UserService from '@/services/UserService';
import FeedbackForm from '@/components/feedback-form/FeedbackForm';
import CloudAPI from '@/services/CloudAPI';
import doctorMale from '@/assets/doctor-male.svg';
import doctorFemale from '@/assets/doctor-female.svg';
import AnalyticsService from '@/services/AnalyticsService';
import ConfigService from '@/services/ConfigService';
import NetworkChip from '@/components/network-chip/NetworkChip';
import AddToCareTeamPopup from '@/components/add-to-care-team/AddToCareTeam';
import ProviderLocations from '@/components/provider-locations/ProviderLocations';
import ProviderScores from '@/components/provider-scores/ProviderScores';

const analytics = new AnalyticsService();
@observer
export default class ProviderProfile extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			careTeamOpen: false,
			bookingOpen: false,
			setPcp: false,
			showFeedbackForm: false
		});
		this.npiRef = React.createRef();
		this.text = getCmpText('ProviderProfile', {
			linksText: 'External Links',
			bioTitle: 'Biography',
			bookAppt: 'Book Appointment',
			areYouSure: 'Are you sure',
			removeFromTeam: 'You want to remove this provider from your care team?',
			badDataCta: 'Report Bad Data',
			careTeamText: 'Care Team',
			pcpText: 'Primary Care Provider',
			pcpFabBtnAdd: 'Set as PCP',
			pcpFabBtnRemove: 'Remove as PCP',
			careteamFabBtnAdd: 'Add to Care Team',
			careteamFabBtnRemove: 'Remove from Care Team',
			networkMessage:
				'Providers are ranked based on your health plan network structure between green and red. Green being an often, better cost or quality provider, and red being an out of network provider. ',
			clearPcp: 'Are you sure you want to remove this physician as your primary care provider?'
		});
		autoBind(this);
	}

	onClose() {
		this.$f7.router.app.views.current.router.back();
		setTimeout(() => {
			providerStore.openProviderLoaded = false;
			appStore.openProviderProfile = false;
			providerStore.openProvider = {};
			providerStore.openProviderBDData = {};
			providerStore.profileEntry = null;
			setTimeout(() => {
				if (appStore.selectedProcedure) {
					appStore.selectedProcedure = null;
					appStore.procedureSearchOpen = true;
				}
			}, 200);
		}, 300);
	}

	//TODO @PHC tier X should show book appt, other tier should link to their solution
	openBooking() {
		const config = ConfigService.getConfiguration();
		const currentProvder = _.cloneDeep(providerStore.openProvider);
		const providerSpecialtyBookingConfig = ProviderService.getLocationBookingConfig(currentProvder) || {};
		if (providerSpecialtyBookingConfig.enabled) {
			analytics.trackWithData('specialty booking override', providerSpecialtyBookingConfig);
			ExternalService.handleExternalRequest(providerSpecialtyBookingConfig.type, providerSpecialtyBookingConfig.data);
			return;
		}
		let sendExternal = false;
		const bookingUrl = _.get(config, 'booking.apptBookingUrl');
		const bookingMessage = _.get(config, 'booking.apptBookingMessage');
		if (!!bookingUrl || !!bookingMessage) {
			//check to external booking setup
			let apptBookingNetworks = _.get(config, 'booking.apptBookingNetworks', []);
			_.forEach(currentProvder.networks, (n) => {
				if (apptBookingNetworks.indexOf(n) >= 0) {
					sendExternal = true;
				}
			});
		}
		if (sendExternal) {
			if (bookingMessage) {
				analytics.track('opened_external_booking_message');
				appStore.showExternalServiceMessage = true;
				appStore.externalServiceMessage = {
					icon: 'fad fa-calendar-edit',
					message: bookingMessage,
					title: this.text.bookText
				};
				return;
			} else {
				analytics.trackWithData('opened_external_site', { url: bookingUrl });
				ExternalService.handleExternalRequest('web', bookingUrl);
				return;
			}
		}
		if (ProviderService.isOnCareTeam(currentProvder)) {
			// setting this allows booking to default the location to the location on careteam
			providerStore.selectedCareTeam = _.cloneDeep(_.find(providerStore.careTeam, { NPI: currentProvder.NPI }));
		}
		appStore.bookingOpen = true;
	}

	getName(p, stringOnly) {
		let name = `${_.get(p, 'fullName', '').split(' llc').join(' LLC')}`;
		if (stringOnly) {
			return name;
		}
		return (
			<span>
				<span className="provider-name">{`${name} `}</span>
				{_.get(p, 'personDetails.credential', '')}
			</span>
		);
	}

	openFeedbackForm() {
		this.data.showFeedbackForm = true;
	}

	closeFeedbackForm() {
		this.data.showFeedbackForm = false;
	}

	buildProviderCard() {
		let p = providerStore.openProvider;
		let config = ConfigService.getConfiguration();
		const usersPcpNPI = _.get(UserService.getCurrentPcp(), 'npi');
		if (p) {
			let { careTeamText, pcpText } = this.text;
			const providerSpecialtyBookingOverrideConfig = ProviderService.getLocationBookingConfig(p) || {};
			const showBooking =
				ProviderService.isInNetwork(p) &&
				p.isPerson &&
				_.get(config, 'features.appointments') &&
				!UserService.isConsumer() &&
				!providerSpecialtyBookingOverrideConfig.disabled;
			return (
				<div className="profile-card vbox hcenter vcenter">
					<div className="hbox hleft vcenter profile-card-header">
						{p.isPerson && (
							<div className="image-outer-container profile-image">
								<div className="image-inner-container">
									<object data={ProviderService.getProviderImage(p)} type="image/png">
										<img src={_.get(p, 'personDetails.gender', 'm') === 'm' ? doctorMale : doctorFemale} />
									</object>
								</div>
							</div>
						)}
						{!p.isPerson && (
							<div className="organization">
								<i className={`${getProviderIcon(p, false, true)}`}></i>
							</div>
						)}
						<div className="vbox vcenter">
							<div className="title">{this.getName(p)}</div>
							<div className="specialty">
								{_.get(p, 'allSpecialties', []).toString().split(',').join(', ').split('Other Medical Specialists,').join('')}
							</div>
						</div>
					</div>
					<div className="chips">
						<NetworkChip provider={p} handleClick={() => this.$f7.dialog.alert(this.text.networkMessage)} />
						{ProviderService.isOnCareTeam(p) && (
							<Chip text={careTeamText} mediaBgColor="purple" className="ios">
								<i slot="media" className={`fad fa-heart`}></i>
							</Chip>
						)}
						{p.NPI === usersPcpNPI && (
							<Chip text={pcpText} mediaBgColor="blue" className="ios">
								<i slot="media" className={`fad fa-parking-circle`}></i>
							</Chip>
						)}
					</div>
					{showBooking && (
						<div className="btn-ctn vbox vcenter hcenter">
							<Button className="book-appt-btn hbox vcenter hcenter btnfix neumorphic meuhover" large fill onClick={this.openBooking}>
								<i className="fad fa-calendar-day"></i> {this.text.bookAppt}
							</Button>
						</div>
					)}
					<FeedbackForm
						show={this.data.showFeedbackForm}
						close={this.closeFeedbackForm}
						type="BAD_DATA"
						npi={_.get(providerStore, 'openProvider.NPI', null)}
					/>
				</div>
			);
		}
		return '';
	}

	copyNPI() {
		if (UserService.isAdmin()) {
			// Added admin check to prevent random text copying to user clipboard (internal feature)
			const npi = this.npiRef && this.npiRef.current;
			npi.select();
			document.execCommand('copy');
		}
	}

	buildProcedureDetails() {
		let { negotiated_rate, code_plain_language_description, code, code_type } = appStore.selectedProcedure;
		return (
			<div className="procedure-details">
				<h3>Selected Procedure</h3>
				<div className="code">
					{code_type}: {code}
				</div>
				<div className="code-desc">{code_plain_language_description}</div>
				<div className="rate hbox vcenter">
					<div className="grow-1">Negotiated Rate:</div> <span>${negotiated_rate}</span>
				</div>
				<Button round large fill className="btnfix neumorphic neuhover">
					Generate Personalized Estimate
				</Button>
			</div>
		);
	}

	buildProviderContent() {
		let p = providerStore.openProvider;
		let { bioTitle, linksText } = this.text;
		if (p) {
			let bio = _.get(p, 'bio', _.get(providerStore, 'openProviderBDData.profile.bio'));
			return (
				<Block className="profile-content">
					{bio && (
						<>
							<h3>{bioTitle}</h3>
							<p className="bio animated fadeIn">{bio}</p>
						</>
					)}
					{/* {appStore.selectedProcedure && this.buildProcedureDetails()} */}
					<ProviderScores npi={p.NPI} />
					<ProviderLocations />
					{p.links && p.links.length > 0 && (
						<div className="links">
							<h3 className="location-title">{linksText}</h3>
							<div className="links-ctn hbox">
								{p.links.map((l) => {
									return (
										<div
											className="link neumorphic neuhover"
											key={Math.random()}
											onClick={() => {
												analytics.trackWithData('opened_external_site', { url: l.url });
												ExternalService.handleExternalRequest('web', l.url);
											}}
										>
											{l.type}
										</div>
									);
								})}
							</div>
						</div>
					)}
					<Button className="feedback-cta btnfix neumorphic neuhover" round large onClick={this.openFeedbackForm}>
						{this.text.badDataCta}
					</Button>
					<div className="vbox vcenter hcenter">
						<textarea readOnly className="npi-number" ref={this.npiRef} onClick={this.copyNPI} value={p.NPI} />
					</div>
				</Block>
			);
		}
		return '';
	}

	toggleCareTeamMenu(removeMember) {
		const isOnCareTeam = ProviderService.isOnCareTeam(providerStore.openProvider);
		const isPcpChange = this.data.setPcp;
		if ((isOnCareTeam && !isPcpChange) || removeMember) {
			this.$f7.dialog.confirm(this.text.removeFromTeam, this.text.areYouSure, () => {
				this.$f7.dialog.preloader();
				CloudAPI.removeFromCareTeam(_.get(providerStore, 'openProvider.NPI', ''))
					.then((res) => {
						providerStore.careTeam = res;
						this.$f7.dialog.close();
					})
					.catch((err) => {
						console.log(err);
						this.$f7.dialog.close();
					});
			});
		} else {
			this.data.careTeamOpen = !this.data.careTeamOpen;
		}
	}

	async onConfirmRemovePcp() {
		this.$f7.dialog.preloader();
		try {
			const newCareTeam = await CloudAPI.clearPcp();
			providerStore.careTeam = newCareTeam;
		} catch (err) {
			console.log('unable to remove PCP: ', err);
		}
		this.$f7.dialog.close();
	}

	removePcpInit() {
		this.$f7.dialog.confirm(this.text.clearPcp, this.onConfirmRemovePcp);
	}

	componentDidMount() {
		const isOpen = appStore.openProviderProfile;
		if (isOpen) {
			//Added to prevent dups, did mount seems to sometimes hit onClose as well
			let providerNPI = _.get(providerStore, 'openProvider.NPI');
			analytics.trackWithData('view_provider_profile', { npi: providerNPI, entry: providerStore.profileEntry });
		}
	}

	closeAddToCareTeam() {
		this.data.careTeamOpen = false;
	}

	render() {
		let { careTeamOpen } = this.data;
		let p = providerStore.openProvider;
		const usersPcpNPI = _.get(UserService.getCurrentPcp(), 'npi');
		return (
			<Page className={`provider-profile-page ${appStore.isMobile ? '' : 'desktop'}`}>
				<div className="profile-header">
					<Link popupClose onClick={this.onClose}>
						<i className="fad fa-times" />
					</Link>
				</div>
				<div className="profile-main y-scroll">
					<div className="profile-top">{this.buildProviderCard()}</div>
					{this.buildProviderContent()}
					<AddToCareTeamPopup opened={careTeamOpen} onClose={this.closeAddToCareTeam} />
				</div>
				<Fab position="right-bottom" slot="fixed" color="purple" text="Add">
					<Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
					<Icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></Icon>
					<FabButtons position="top">
						{p.NPI !== usersPcpNPI && _.get(p, 'allSpecialties', []).indexOf('Primary Care') >= 0 && (
							<FabButton
								label={this.text.pcpFabBtnAdd}
								onClick={() => {
									this.data.setPcp = true;
									this.toggleCareTeamMenu(false);
									this.$f7.fab.close();
								}}
							>
								<i className="fad fa-parking-circle"></i>
							</FabButton>
						)}
						{p.NPI === usersPcpNPI && (
							<FabButton label={this.text.pcpFabBtnRemove} onClick={this.removePcpInit}>
								<i className="far fa-parking-circle-slash" />
							</FabButton>
						)}
						{ProviderService.isOnCareTeam(p) && (
							<FabButton
								label={this.text.careteamFabBtnRemove}
								onClick={() => {
									this.toggleCareTeamMenu(true);
								}}
							>
								<i className="fas fa-minus"></i>
								<i className="fad fa-heart"></i>
							</FabButton>
						)}
						{!ProviderService.isOnCareTeam(p) && (
							<FabButton
								label={this.text.careteamFabBtnAdd}
								onClick={() => {
									this.toggleCareTeamMenu(false);
								}}
							>
								<i className="fas fa-plus"></i>
								<i className="fad fa-heart"></i>
							</FabButton>
						)}
					</FabButtons>
				</Fab>
			</Page>
		);
	}
}
