import React, { Component } from 'react';
import { Button, Link, Popover, List, ListItem } from 'framework7-react';
import { observer } from '../../utils/State';
import { autoBind } from '../../utils/GeneralUtils';
import './drop-down.scss';
import _ from 'lodash';

@observer
export default class DropDown extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		let {
			label,
			name,
			icon,
			items,
			displayValue,
			id,
			className,
			helpComponentText,
			showHelpIconComponent
		} = this.props;
		let addedClass = '';
		switch (name) {
			case 'patient-booking-location-menu':
				addedClass = 'selected-practice';
				break;
			case 'patient-popover-menu':
				addedClass = 'patient-name';
				break;
			default:
				break;
		}
		return (
			<>
				{showHelpIconComponent && (
					<>
						<Popover className="helpIcon-content">
							<List>
								<ListItem>{helpComponentText}</ListItem>
							</List>
						</Popover>
						<Button className="helpIcon-button" popoverOpen=".helpIcon-content">
							<i className="fad fa-question-circle" />
						</Button>
					</>
				)}
				<Link
					className={`dropdown-component vbox ${className}`}
					popoverOpen={`.${name}`}
					id={name}
				>
					<div className="label">{label}</div>
					<div className="selected-value hbox vcenter neumorphic">
						{icon && <i className={icon} />}
						<div className={`grow-1 ellipse ${addedClass}`}>{displayValue}</div>
						{items.length > 1 && <i className="fad fa-chevron-down"></i>}
					</div>
				</Link>
				<Popover className={`${name} cv-dropdown`} id={id ? id : null}>
					<List>{items}</List>
				</Popover>
			</>
		);
	}
}
