export const DOC_ICON = {
	'application/pdf': 'fa-file-pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
	'application/msword': 'fa-file-word',
	'application/rtf': 'fa-file',
	'text/plain': 'fa-file',
	'application/vnd.ms-excel': 'fa-file-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel',
	'application/vnd.ms-powerpoint':'fa-file-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fa-file-powerpoint',
	'image/png': 'fa-file-image',
	'image/jpeg': 'fa-file-image',
	'image/gif': 'fa-file-image'
};

export const DOC_TYPES = {
	'application/pdf': 'Adobe PDF',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Microsoft PowerPoint(pptx)',
	'application/vnd.ms-powerpoint':'Microsoft PowerPoint',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word(docx)',
	'application/msword': 'Microsoft Word',
	'application/rtf': 'Rich Text Format',
	'text/plain': 'Text',
	'application/vnd.ms-excel': 'Microsoft Excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Microsoft Excel(xlsx)',
	'image/png': 'Image',
	'image/jpeg': 'Image',
	'image/gif': 'Image'
};
