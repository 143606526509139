import React, { Component } from 'react';
import { Popup, List, ListItem, Button, Actions, ActionsGroup, ActionsLabel, ActionsButton } from 'framework7-react';
import ExternalService from '../../services/ExternalService';
import { observer, observable } from '../../utils/State';
import { autoBind } from '../../utils/GeneralUtils';
import { getCmpText } from '../../utils/Localization';
import appStore from '../../stores/AppStore';
import callingSVG from '../../assets/calling.svg';
import _ from 'lodash';
import './telehealth-prompt.scss';
import UserService from '@/services/UserService';
import CloudAPI from '@/services/CloudAPI';
import userStore from '@/stores/UserStore';

@observer
export default class TelehealthPrompt extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showConsumerPrompt: false,
			helpOpen: false
		});
		this.text = getCmpText('Telehealth', {
			cancel: 'Cancel',
			telehealth: 'Talk to a Physician Now',
			visitSite: 'Open Telehealth Website',
			callNowText: 'Call Now',
			telehealthTitle2: 'Access Telehealth',
			telehealthDisclaimerTitle: 'Please Review',
			telehealthTreatableHeader: 'Common issues telehealth and virtual care providers treat include, but are not limited to:',
			telehealthTreatableItems: 'respiratory stuff, another thing, another nother thing',
			telehealthUntreatableHeader: 'Common issues telehealth and virtual care providers CANNOT treat include, but are not limited to:',
			telehealthUntreatableItems: 'Chest pain, pregnancy',
			telehealthDisclaimerAccept: 'Continue with Telehealth',
			telehealthDisclaimerCancel: 'Cancel',
			consumerMessage: `CareValet will call you shortly.`
		});
		autoBind(this);
	}

	confirmTelehealth() {
		if (UserService.isConsumer()) {
			CloudAPI.initConsumerTelehealthCall()
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
			this.data.showConsumerPrompt = true;
		} else {
			appStore.showTelehealthModal = false;
			appStore.confirmedTelehealth = true;
			if (appStore.telehealthType) {
				ExternalService.onTelehealthClick(appStore.telehealthType);
			} else {
				appStore.telehealthOpen = true;
			}
			this.$f7.popup.close('#telehealthDisclaimer');
		}
	}

	closeTelehealthDisclaimer() {
		this.data.showConsumerPrompt = false;
		appStore.showTelehealthModal = false;
		appStore.confirmedTelehealth = false;
		appStore.telehealthType = null;
		appStore.telehealthOpen = false;
		this.$f7.popup.close('#telehealthDisclaimer');
		if (UserService.isConsumer()) {
			CloudAPI.getMyUser()
				.then((res) => {
					_.set(userStore, 'userData.user', res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	render() {
		const {
			cancel,
			visitSite,
			callNowText,
			telehealthTitle2,
			telehealthTreatableHeader,
			telehealthTreatableItems,
			telehealthDisclaimerTitle,
			telehealthUntreatableHeader,
			telehealthUntreatableItems,
			telehealthDisclaimerAccept
		} = this.text;
		const telehealthTreatable = telehealthTreatableItems.split(',');
		const telehealthUntreatable = telehealthUntreatableItems.split(',');
		const telehealthNumber = ExternalService.getTelehealthNumber();
		const telehealthSite = ExternalService.getTelehealthUrl();
		return (
			<div>
				<Popup
					id="telehealthDisclaimer"
					className="telehealth-disclaimer-popup"
					opened={appStore.showTelehealthModal}
					onPopupClosed={this.closeTelehealthDisclaimer}
					closeByBackdropClick={false}
				>
					{!this.data.showConsumerPrompt && (
						<div className="header">
							<div className="text">{telehealthDisclaimerTitle}</div>
							<div className="" onClick={this.closeTelehealthDisclaimer}>
								<i className="fad fa-times-square" />
							</div>
						</div>
					)}
					{!this.data.showConsumerPrompt && (
						<div className="telehealth-disclaimer-body y-scroll">
							<div className="section">
								<div className="section-header">{telehealthTreatableHeader}</div>
								<List noHairlines>
									{telehealthTreatable.map((item) => {
										return (
											<ListItem key={`treatable-item-${item}`} title={item}>
												<i className="fal fa-check" slot="media" />
											</ListItem>
										);
									})}
								</List>
							</div>
							<div className="section">
								<div className="section-header">{telehealthUntreatableHeader}</div>
								<List noHairlines>
									{telehealthUntreatable.map((item) => {
										return (
											<ListItem key={`untreatable-item-${item}`} title={item}>
												<i className="fal fa-times" slot="media" />
											</ListItem>
										);
									})}
								</List>
							</div>
						</div>
					)}
					{!this.data.showConsumerPrompt && (
						<div className="telehealth-footer">
							<Button large fill onClick={this.confirmTelehealth}>
								{telehealthDisclaimerAccept}
							</Button>
						</div>
					)}
					{this.data.showConsumerPrompt && (
						//TODO @blee cleanup
						<div className="consumer-prompt">
							<div className="consumer-message large">Stand By</div>
							<div className="img-ctn">
								<img src={callingSVG} />
							</div>
							<div className="consumer-message">We will be calling you shortly</div>
							<Button fill large className="completed-btn round-btn purple" onClick={this.closeTelehealthDisclaimer}>
								Call Completed!
							</Button>
							<Button
								onClick={() => {
									this.data.helpOpen = true;
								}}
							>
								Having Trouble?
							</Button>
						</div>
					)}
				</Popup>
				<Actions
					id="telehealth-actions"
					opened={appStore.telehealthOpen}
					onActionsClosed={() => {
						appStore.telehealthOpen = false;
					}}
				>
					<ActionsGroup>
						<ActionsLabel bold className="hbox vcenter hcenter">
							{telehealthTitle2}
						</ActionsLabel>
						{!!telehealthNumber && (
							<ActionsButton
								onClick={() => {
									ExternalService.onTelehealthClick('call');
								}}
							>
								{callNowText}
							</ActionsButton>
						)}
						{!!telehealthSite && (
							<ActionsButton
								onClick={() => {
									ExternalService.onTelehealthClick('web');
								}}
							>
								{visitSite}
							</ActionsButton>
						)}
					</ActionsGroup>
					<ActionsGroup>
						<ActionsButton color="red">{cancel}</ActionsButton>
					</ActionsGroup>
				</Actions>
				<Actions
					id="telehealth-actions"
					opened={this.data.helpOpen}
					onActionsClosed={() => {
						this.data.helpOpen = false;
					}}
				>
					<ActionsGroup>
						<ActionsLabel bold className="hbox vcenter hcenter">
							Support
						</ActionsLabel>
						<ActionsButton
							onClick={() => {
								ExternalService.handleExternalRequest('call', '866-549-9533');
							}}
						>
							{callNowText}
						</ActionsButton>
					</ActionsGroup>
					<ActionsGroup>
						<ActionsButton color="red">{cancel}</ActionsButton>
					</ActionsGroup>
				</Actions>
			</div>
		);
	}
}
