import React from 'react';
import { observer } from '@/utils/State';
import { Button, Progressbar } from 'framework7-react';
import './getting-started.scss';
@observer
export default class GettingStarted extends React.PureComponent {
	render() {
		let { metrics, applicationConfigured } = this.props;
		let groupCompleted = metrics.groups > 0;
		let planCompleted = metrics.plans > 0;
		let memberCompleted = metrics.totalMembers > 0;
		let networkCompleted = metrics.networks > 0;
		let currentStep = 1;
		if (groupCompleted) {
			currentStep++;
		}
		if (networkCompleted) {
			currentStep++;
		}
		if (planCompleted) {
			currentStep++;
		}
		if (memberCompleted) {
			currentStep++;
		}

		return (
			<div className="express-getting-started animated zoomIn">
				<h2 className="category hbox vcenter">
					<div className="grow-1 lato">Getting Started</div>
				</h2>
				<div className="message">Looks like you're new here. No worries, below are some simple steps to help get you started.</div>
				<Progressbar progress={applicationConfigured ? 100 : Math.abs(currentStep * 20) - 20} id="demo-inline-progressbar" />
				<div className="step-list">
					<div
						className={`step hbox vcenter ${currentStep === 1 && 'active'} ${groupCompleted ? 'completed' : ''}`}
						onClick={() => {
							if (currentStep === 1) {
								this.props.handleStepClick('group');
							}
						}}
					>
						<div className="status">{groupCompleted ? <i className="fas fa-check animated bounceIn"></i> : '1'}</div>
						<div className="step-text">
							{groupCompleted
								? `To see your newly created groups, navigate to the 'Groups' page via the menu on the left side of the screen.\nTo create new groups in the future you can do it directly from the 'Groups' page.`
								: 'Create a new group'}
						</div>
					</div>
					<div
						className={`step hbox vcenter ${currentStep === 2 && 'active'} ${networkCompleted ? 'completed' : ''}`}
						onClick={() => {
							if (currentStep === 2) {
								this.props.handleStepClick('networks');
							}
						}}
					>
						<div className="status">{networkCompleted ? <i className="fas fa-check animated bounceIn"></i> : '2'}</div>
						<div className="step-text">
							{networkCompleted
								? `Setup one of our existing provider networks or submit a request for a new one to be added to the platform`
								: 'Setup a provider network'}
						</div>
					</div>
					<div
						className={`step hbox vcenter ${currentStep === 3 && 'active'} ${planCompleted ? 'completed' : ''}`}
						onClick={() => {
							if (currentStep === 3) {
								this.props.handleStepClick('plan');
							}
						}}
					>
						<div className="status">{planCompleted ? <i className="fas fa-check animated bounceIn"></i> : '3'}</div>
						<div className="step-text">
							{planCompleted
								? `To see your newly created healthplan, navigate to the 'HealthPlans' page via the menu on the left side of the screen, or under the group details on the group page.`
								: 'Create and attach a new healthplan to the group'}
						</div>
					</div>
					<div
						className={`step hbox vcenter ${currentStep === 4 && 'active'} ${memberCompleted ? 'completed' : ''}`}
						onClick={() => {
							if (currentStep === 4) {
								this.props.handleStepClick('members');
							}
						}}
					>
						<div className="status">{memberCompleted ? <i className="fas fa-check animated bounceIn"></i> : '4'}</div>
						<div className="step-text">
							{memberCompleted
								? `To view members you've successfully loaded into the platform, navigate to the 'Members' page via the menu on the left side of the screen.`
								: 'Load eligible members into the group'}
						</div>
					</div>
					<div className={`step hbox vcenter ${currentStep === 5 && 'active'} ${applicationConfigured ? 'completed' : ''}`}>
						<div className="status">{applicationConfigured ? <i className="fas fa-check animated bounceIn"></i> : '5'}</div>
						<div>
							{currentStep !== 5 && <div className="step-text">Complete your account's final registration steps.</div>}
							{currentStep === 5 && (
								<div className="step-text m-b-16">
									Great Job! Looks like you're off to the races, if you have any questions or need support you can always drop us an
									email at help@carevalet.com
									<br></br>Click the button below to close this setup menu.
								</div>
							)}
							{currentStep === 5 && (
								<div className="vbox hleft">
									<Button
										fill
										small
										className="round-btn"
										onClick={() => {
											if (currentStep === 5) {
												this.props.handleStepClick('finalize');
											}
										}}
									>
										Close Setup
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
