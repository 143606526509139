import React, { Component, Fragment } from 'react';
import { Swiper, SwiperSlide, Chip } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind, getFindCareIcon } from '../../utils/GeneralUtils';
import { Map, Overlay } from 'rgm';
import providerStore from '../../stores/ProviderStore';
import appStore from '../../stores/AppStore';
import _ from 'lodash';
import './map.scss';
import { mapStyles } from './map-styles';
import ProviderService from '../../services/ProviderService';
import UserService from '../../services/UserService';
import ConfigService from '../../services/ConfigService';
import convertDistance from 'geolib/es/convertDistance';
import BannerCard from '../../components/banner-card/BannerCard';
import doctorMale from '../../assets/doctor-male.svg';
import doctorFemale from '../../assets/doctor-female.svg';
import NetworkChip from '../network-chip/NetworkChip';
const defaultProps = ConfigService.getConfigValue('map.defaultProps', {});
defaultProps.styles = mapStyles;

@observer
export default class MapContainer extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			mapApiLoaded: false,
			mapError: false
		});
		this.originalCenter = {
			lat: Number(_.clone(_.get(appStore, 'currentLocation.lat', _.get(defaultProps, 'center.lat') || 27.964157))),
			lng: Number(_.clone(_.get(appStore, 'currentLocation.lon', _.get(defaultProps, 'center.lng') || -82.452606)))
		};
		this.text = getCmpText('FindCare', {
			inNetworkText: 'In-Network',
			outNetworkText: 'Out-Network',
			locationText: 'Practicing at %s locations',
			miAway: ' mi away',
			telehealthTitle: 'Call Now',
			telehealthText2: 'Speak to a licensed doctor in minutes',
			telehealthText1: 'Telehealth $<%= copay %> co-pay',
			mapLoadError: 'There was an error loading the map, please try again later.',
			careTeamText: 'Care Team',
			pcpText: 'Primary Care Provider'
		});
		this.mapRef = React.createRef();
		autoBind(this);
	}

	async componentDidMount() {
		if (window.google && window.google.maps) {
			this.handleMapLoaded();
		} else {
			if (!window.handleMapLoad) {
				window.handleMapLoad = this.handleMapLoaded;
			}
			this.$f7.dialog.preloader();
			const newElement = document.createElement('script');
			newElement.async = true;
			newElement.defer = true;
			newElement.setAttribute('importance', 'low');
			newElement.src = `https://maps.googleapis.com/maps/api/js?key=${defaultProps.googleMapKey}&callback=handleMapLoad`;
			newElement.addEventListener('load', this.handleMapLoaded);
			newElement.addEventListener('error', this.handleMapLoadError);
			if (document.body) {
				document.body.appendChild(newElement);
			}
		}
		const hasTelehealth = _.get(ConfigService.getConfiguration(), 'features.telehealth');
		if (!hasTelehealth) {
			providerStore.activeProvider = _.get(providerStore, `searchResults[0]`, {});
		}
	}

	handleMapLoadError(err) {
		console.log('error loading map: ', err);
		this.data.mapError = true;
	}

	handleSwipeChange() {
		let i = this.$f7.swiper.get().activeIndex;
		const hasTelehealth = _.get(ConfigService.getConfiguration(), 'features.telehealth');
		if (i === 0 && hasTelehealth) {
			providerStore.mapCenter = { ..._.cloneDeep(appStore.currentLocation) };
			providerStore.activeProvider = {};
			if (_.get(this.mapRef, 'current')) {
				this.mapRef.current.setZoom(14);
			}
		} else {
			let geo = _.cloneDeep(
				_.get(providerStore, `searchResults[${i - (hasTelehealth ? 1 : 0)}].locations[0].geoLocation.coordinates`, [
					-82.524221,
					27.95457
				])
			);
			providerStore.mapCenter = { lat: geo[1], lon: geo[0] };
			providerStore.activeProvider = _.cloneDeep(_.get(providerStore, `searchResults[${i - (hasTelehealth ? 1 : 0)}]`, {}));
			if (_.get(this.mapRef, 'current')) {
				this.mapRef.current.setZoom(14);
			}
		}
	}

	onMarkerClick(evt) {
		let i = _.get(evt, 'currentTarget.dataset.index', 0);
		this.$f7.swiper.get().slideTo(i);
	}

	getPhotoUrl(p) {
		if (_.get(p, 'photo', null) && _.get(p, 'photo').indexOf('general_doctor') < 0) {
			return p.photo;
		}
		return `https://asset3.betterdoctor.com/assets/general_doctor_${
			_.get(p, 'personDetails.gender', 'm') === 'm' ? 'male.png' : 'female.png'
		}`;
	}

	buildMapCards() {
		let { searchResults } = providerStore;
		let { miAway, telehealthTitle, telehealthText1, telehealthText2, pcpText, careTeamText } = this.text;
		let config = ConfigService.getConfiguration();
		let slides = [];

		const telehealthCopayTemplate = _.template(telehealthText1);
		const telehealthPlanData = UserService.getPlanTelehealthData();
		const telehealthCopayText = telehealthCopayTemplate(telehealthPlanData);

		if (_.get(config, 'features.telehealth')) {
			slides.push(
				<SwiperSlide key="telehealth-banner-card-map-2">
					<BannerCard
						onClick={this.props.onTelehealth}
						iconCls="fad fa-phone-alt"
						topText={UserService.isConsumer() ? '' : telehealthCopayText}
						title={telehealthTitle}
						bottomText={telehealthText2}
						color="blue2"
					/>
				</SwiperSlide>
			);
		}

		_.forEach(searchResults, (p) => {
			const usersPcpNpi = _.get(UserService.getCurrentPcp(), 'npi');
			const isPcp = p.NPI === usersPcpNpi;
			slides.push(
				<SwiperSlide key={p.NPI} data-NPI={p.NPI} className="vbox hcenter">
					<div
						className="provider-card"
						onClick={() => {
							ProviderService.openProviderProfile(p, 'findCare');
						}}
					>
						<div className="hbox">
							<div className="vbox hcenter left">
								<div className="image-ctn vbox hcenter vcenter">
									{p.isPerson && (
										<object data={this.getPhotoUrl(p)} type="image/png" className="provider-photo">
											<img src={_.get(p, 'personDetails.gender', 'm') === 'm' ? doctorMale : doctorFemale} />
										</object>
									)}
									{!p.isPerson && (
										<div className="organization">
											<i className={`${getFindCareIcon(p)}`}></i>
										</div>
									)}
								</div>
								<div className="distance vbox vcenter hcenter">
									<i className="fal fa-map-marker-alt" />
									{convertDistance(_.get(p, 'distance', 0), 'mi').toFixed(1)}
									<div>{miAway}</div>
								</div>
							</div>
							<div className="details">
								<div className="chips">
									<NetworkChip provider={p} />
									{ProviderService.isOnCareTeam(p) && (
										<Chip text={careTeamText} mediaBgColor="purple" className="ios">
											<i slot="media" className={`fad fa-heart`}></i>
										</Chip>
									)}
									{isPcp && (
										<Chip text={pcpText} mediaBgColor="blue" className="ios">
											<i slot="media" className={`fad fa-parking-circle`}></i>
										</Chip>
									)}
								</div>
								<div className="provider-name ellipse">{ProviderService.getCleanName(p)}</div>
								<div className="specialty">
									{_.get(p, 'allSpecialties', []).toString().split(',').join(', ').split('Other Medical Specialists,').join('')}
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
			);
		});
		return <>{slides}</>;
	}

	buildLocationMarkers() {
		const { markerData = [] } = providerStore;
		const markers = [];
		markerData
			.slice()
			.reverse()
			.map((markerConfig) => {
				let iconClass = 'fas fa-map-marker-plus';
				const isUser = markerConfig.id === 'user';
				const isActive = markerConfig.npi && markerConfig.npi === _.get(providerStore, 'activeProvider.NPI');
				const activeClass = 'active animated infinite pulse';
				if (isUser) {
					iconClass = 'fad fa-street-view user-map-maper';
				}
				if (isActive && !isUser) {
					iconClass = 'fas fa-map-marker-alt';
				}
				if (!isUser) {
					markers.push(
						<div
							className="practice-location-marker"
							lat={markerConfig.lat}
							lng={markerConfig.lng}
							key={`${markerConfig.key}marker${markerConfig.id}`}
							data-index={markerConfig.index}
						>
							<i
								className={`fas fa-map-marker practice-location-marker bg ${isActive ? activeClass : ''}`}
								lat={markerConfig.lat}
								lng={markerConfig.lng}
								key={`${markerConfig.npi}-bg-marker-${markerConfig.id}`}
								data-index={markerConfig.index}
							/>
							<i
								lat={markerConfig.lat}
								lng={markerConfig.lng}
								className={`${!isActive ? _.get(markerConfig, 'networkData.color', '') : ''} practice-location-marker ${iconClass} ${
									isUser || isActive ? activeClass : ''
								}`}
								index={markerConfig.index}
								key={`${markerConfig.key}marker${markerConfig.id}`}
								onClick={this.onMarkerClick}
								data-index={markerConfig.index}
							/>
						</div>
					);
				} else {
					markers.push(
						<i
							lat={Number(_.get(appStore, 'currentLocation.lat', _.get(defaultProps, 'center.lat') || 27.964157))}
							lng={Number(_.get(appStore, 'currentLocation.lon', _.get(defaultProps, 'center.lng') || -82.452606))}
							className="fad fa-street-view user-map-maper animated infinite pulse"
							index={markerConfig.index}
							key={'you-marker-smile'}
							onClick={this.onMarkerClick}
							data-index={markerConfig.index}
						/>
					);
				}
			});
		return markers;
	}

	handleMapLoaded() {
		this.data.mapApiLoaded = true;
		this.data.mapError = false;
		setTimeout(() => {
			if (_.get(this.mapRef, 'current')) {
				this.mapRef.current.setZoom(15);
			}
			this.$f7.dialog.close();
		}, 300);
	}

	async setMapCenter() {
		if (_.get(this.mapRef, 'current')) {
			try {
				const currentCenter = await this.mapRef.current.getCenter();
				const currentLat = await currentCenter.lat();
				const currentLng = await currentCenter.lng();
				const nextLat = Number(_.clone(_.get(providerStore, 'mapCenter.lat', _.get(defaultProps, 'center.lat') || 27.964157)));
				const nextLng = Number(_.clone(_.get(providerStore, 'mapCenter.lon', _.get(defaultProps, 'center.lng') || -82.452606)));
				const shouldResetMapCenter = currentLat !== nextLat || currentLng !== nextLng;
				const userLat = Number(_.clone(_.get(appStore, 'currentLocation.lat', _.get(defaultProps, 'center.lat') || 27.964157)));
				const userLng = Number(_.clone(_.get(appStore, 'currentLocation.lon', _.get(defaultProps, 'center.lng') || -82.452606)));
				const isLocationChange = userLat === nextLat && userLng === nextLng;
				if (shouldResetMapCenter) {
					await this.mapRef.current.setOptions({
						center: {
							lat: Number(_.clone(_.get(providerStore, 'mapCenter.lat', _.get(defaultProps, 'center.lat') || 27.964157))),
							lng: Number(_.clone(_.get(providerStore, 'mapCenter.lon', _.get(defaultProps, 'center.lng') || -82.452606)))
						}
					});
				}
				if (isLocationChange && shouldResetMapCenter) {
					this.mapRef.current.setZoom(15);
				}
			} catch (err) {
				console.log('unable to reset map center: ', err);
			}
		}
	}

	render() {
		if (this.data.mapApiLoaded) {
			this.setMapCenter();
		}
		return (
			<Fragment>
				{this.data.mapApiLoaded && (
					<div className="map-container">
						<Map
							api={window.google.maps}
							ref={this.mapRef}
							options={{
								// cannot use state here as updates will cause errors - plugin requires us to manually call gmaps api to update options
								styles: defaultProps.styles[defaultProps.mapStyle],
								zoomControl: false,
								fullscreenControl: false,
								clickableIcons: false,
								zoom: defaultProps.zoom,
								disableDefaultUI: true,
								center: this.originalCenter,
								gestureHandling: 'greedy'
							}}
						>
							<Overlay>{this.buildLocationMarkers()}</Overlay>
						</Map>
					</div>
				)}
				{this.data.mapError && <div>{this.text.mapLoadError}</div>}
				<Swiper
					className={`provider-swiper ${appStore.isIos ? 'ios' : ''}`}
					params={{ spaceBetween: 1, on: { slideChangeTransitionEnd: this.handleSwipeChange } }}
					navigation
				>
					{this.buildMapCards()}
				</Swiper>
			</Fragment>
		);
	}
}
