import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '../../../utils/GeneralUtils';
import DefaultEmailTemplate from './DefaultEmailTemplate';
import AppointmentRequestTemplate from './AppointmentRequestTemplate';

export default class EmailTemplate extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		switch (this.props.template) {
			case 'appointmentRequest':
				return (
					<AppointmentRequestTemplate
						subject={this.props.subject}
						body={this.props.body}
						onSubjectChange={this.props.onSubjectChange}
						onBodyChange={this.props.onBodyChange}
						subjectLabel={this.props.subjectLabel}
						bodyLabel={this.props.bodyLabel}
						subjectPlaceholder={this.props.subjectPlaceholder}
						selectedMember={this.props.selectedMember}
					/>
				);
			default:
				return (
					<DefaultEmailTemplate
						subject={this.props.subject}
						body={this.props.body}
						onSubjectChange={this.props.onSubjectChange}
						onBodyChange={this.props.onBodyChange}
						subjectLabel={this.props.subjectLabel}
						bodyLabel={this.props.bodyLabel}
						subjectPlaceholder={this.props.subjectPlaceholder}
					/>
				);
		}
	}
}
