import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import BasicInput from '@/components/basic-input/BasicInput';
import { ListItem, Toggle } from 'framework7-react';
import DropDown from '@/components/drop-down/DropDown';
import IconPicker from '@/components/_EXPRESS/icon-picker/IconPicker';
import FileDrop from '@/components/_EXPRESS/file-drop/FileDrop';

@observer
export default class ConfigFormBuilder extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ emailValid: false });
		autoBind(this);
	}

	onFileDropComplete(e, config) {
		let fileReader = new FileReader();
		fileReader.onloadend = (e) => {
			this.props.onChange(config, e.target.result);
		};
		fileReader.readAsDataURL(e);
	}

	buildDropDown(key, config) {
		const listItems = Object.keys(config.sourceConfig.listItems).map((itemKey) => {
			const label = config.sourceConfig.listItems[itemKey];
			return (
				<ListItem
					link="#"
					popoverClose
					className="drop-down-list-item express-dropdown"
					key={`dd-item-${config.id}-${itemKey}-${label}`}
					onClick={(data) => this.props.onChange(config, itemKey)}
				>
					<div className="dd-label">{label}</div>
				</ListItem>
			);
		});
		return (
			<DropDown
				disabled={config.disabled || false}
				key={`express-dropdown-${config.id}`}
				name={`formbuilder-dropdown-${config.id}`}
				label={config.label}
				icon={config.icon}
				items={listItems}
				className={`express-dropdown md ${config.className}`}
				displayValue={config.sourceConfig.listItems[config.value]}
			/>
		);
	}

	getField(fieldConfig) {
		const type = fieldConfig.type;
		const value = fieldConfig.value;

		switch (type) {
			case 'text': {
				return (
					<BasicInput
						key={`express-form-input-${fieldConfig.id}`}
						validate
						label={fieldConfig.label}
						name={fieldConfig.id}
						type={type}
						placeholder={fieldConfig.placeholder}
						value={value}
						onChange={(data) => this.props.onChange(fieldConfig, data)}
						disabled={fieldConfig.disabled || false}
						className={`${this.props.className || ''}`}
					/>
				);
			}
			case 'number': {
				return (
					<BasicInput
						key={`express-form-input-${fieldConfig.id}`}
						validate
						label={fieldConfig.label}
						name={fieldConfig.id}
						type={type}
						max={9999999}
						placeholder={fieldConfig.placeholder}
						value={value}
						onChange={(data) => this.props.onChange(fieldConfig, data)}
						disabled={fieldConfig.disabled || false}
						className={`${this.props.className || ''}`}
					/>
				);
			}
			case 'toggle': {
				return (
					<div className="express-form-toggle hbox vcenter" key={`express-form-toggle-${fieldConfig.id}`}>
						<div className="label">{fieldConfig.label}</div>
						<Toggle
							disabled={fieldConfig.disabled || false}
							checked={value ? true : false}
							onChange={(data) => {
								this.props.onChange(fieldConfig, data);
							}}
						></Toggle>
					</div>
				);
			}
			case 'dropdown': {
				return this.buildDropDown(fieldConfig.id, fieldConfig);
			}
			case 'iconPicker': {
				return (
					<IconPicker
						key={`express-form-input-${fieldConfig.id}`}
						label={fieldConfig.label}
						onSelect={(icon) => {
							this.props.onChange(fieldConfig, icon);
						}}
						disabled={fieldConfig.disabled || false}
						currentValue={value}
					/>
				);
			}
			case 'none': {
				return (
					<div className="basic-input no-field" key={`express-form-label-${fieldConfig.id}`}>
						<div className="label">{fieldConfig.label}</div>
					</div>
				);
			}
			case 'fileDrop': {
				return (
					<div className="file-drop-wrapper">
						{value && (
							<div className={`logo hbox vcenter hcenter med`}>
								<img src={value} alt="logo" className="third-party" />
							</div>
						)}
						<FileDrop
							onError={(err) => console.log('file drop error: ', err)}
							accept="image/*"
							onComplete={(e) => this.onFileDropComplete(e, fieldConfig)}
							multi={false}
						/>
					</div>
				);
			}
			default: {
				return (
					<BasicInput
						key={`express-form-input-${fieldConfig.id}`}
						validate
						label={fieldConfig.label}
						name={fieldConfig.id}
						type={type}
						placeholder={fieldConfig.placeholder}
						value={value}
						onChange={(data) => this.props.onChange(fieldConfig, data)}
						disabled={fieldConfig.disabled || false}
						className={`${this.props.className || ''}`}
					/>
				);
			}
		}
	}

	render() {
		return (
			<div className="express-form-builder">
				{this.props.fields.map((field) => {
					return this.getField(field);
				})}
			</div>
		);
	}
}
