import React, { Component } from 'react';
import booking from '../../assets/booking.svg';
import { Button, List, Badge } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import './appointments.scss';
import GlobalHeader from '../../components/global-header/GlobalHeader';
import CloudAPI from '../../services/CloudAPI';
import { format } from 'date-fns';
import ProviderService from '../../services/ProviderService';
import AnalyticsService from '../../services/AnalyticsService';
import UserService from '../../services/UserService';
import appStore from '@/stores/AppStore';
import ExternalService from '@/services/ExternalService';
const analytics = new AnalyticsService();
//TODO mande global enums
const ApptStatus = {
	REQUESTED: 'Requested',
	SCHEDULED: 'Scheduled',
	CONFIRMED: 'Confirmed',
	CANCELED: 'Canceled'
};
@observer
class Appointments extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ toggleValue: 'scheduled', requested: [], upcoming: [], previous: [] });
		this.text = getCmpText('Appointments', {
			scheduled: 'Upcoming',
			previous: 'Previous',
			emptyText: 'You Have No Appointments',
			searchText: 'Find Care',
			patientLabel: 'Patient:',
			noteLabel: 'Details:',
			reqDate: 'Requested Date:',
			reqTime: 'Requested Time:',
			schTime: 'Scheduled Time:',
			schDate: 'Scheduled Date:',
			reasonLabel: 'Reason:',
			reason0: 'Wellness / Preventative',
			reason1: 'Office Visit',
			reason2: 'Illness',
			reason3: 'Follow-up',
			reason4: 'Other',
			cancel: 'Cancel',
			confirm: 'Confirm Appointment',
			callText: 'Call',
			navText: 'Navigate',
			firstText: 'First Available',
			userCancled: 'User Canceled Appointment',
			confirmApptText: 'Confirm Details Below',
			confirmedText: 'Confirmed',
			confirmCancel: 'Are you sure you want to cancel this appointment?'
		});
		autoBind(this);
	}

	componentDidMount() {
		console.log('Appointments Page Mounted');
		this.fetchAppointments();
	}

	async fetchAppointments() {
		try {
			const appointments = await UserService.getUserAppointments();
			this.data.requested = _.cloneDeep(appointments.requested);
			this.data.upcoming = _.cloneDeep(appointments.upcoming);
			this.data.previous = _.cloneDeep(appointments.previous);
		} catch (err) {
			console.log({ err });
		}
	}

	toggleContent() {
		let { toggleValue } = this.data;
		if (toggleValue === 'scheduled') {
			analytics.track('load_previous_appointments');
			this.data.toggleValue = 'previous';
		} else {
			analytics.track('load_upcoming_appointments');
			this.data.toggleValue = 'scheduled';
		}
	}

	onSearchClick() {
		appStore.activeTab = 'doctors';
	}

	confirmAppt(appt) {
		let { confirmApptText, confirmedText, schTime } = this.text;
		let message = `${schTime} <br/> <strong>${format(new Date(appt.scheduledDate), 'MM/dd/yyyy p')}</strong>`;
		this.$f7.dialog.confirm(message, confirmApptText, () => {
			this.$f7.dialog.preloader();
			CloudAPI.confirmAppt(appt)
				.then(async () => {
					await this.fetchAppointments();
					this.$f7.dialog.close();
					//TODO really need a notification/toast service for opening loaders, toasts, etc
					let options = {
						position: 'center',
						closeTimeout: 1500,
						text: confirmedText,
						icon: `<i class="fad fa-check large-icon-toast" ></i>`
					};
					let toast = this.$f7.toast.create(options);
					toast.open();
				})
				.catch((err) => {
					console.log(err);
					this.$f7.dialog.close();
					this.$f7.dialog.alert(_.get(JSON.parse(err.response), 'message', 'An error occured, please try again.'));
				});
		});
	}

	cancelAppt(appt) {
		this.$f7.dialog.confirm(this.text.confirmCancel, '', () => {
			this.$f7.dialog.preloader();
			CloudAPI.cancelAppt(appt, this.text.userCancled)
				.then(async () => {
					await this.fetchAppointments();
					this.$f7.dialog.close();
					//TODO really need a notification/toast service for opening loaders, toasts, etc
					let options = {
						position: 'center',
						closeTimeout: 1500,
						text: ApptStatus.CANCELED,
						icon: `<i class="fad fa-check large-icon-toast" ></i>`
					};
					let toast = this.$f7.toast.create(options);
					toast.open();
				})
				.catch((err) => {
					console.log(err);
					this.$f7.dialog.close();
					this.$f7.dialog.alert(_.get(JSON.parse(err.response), 'message', 'An error occured, please try again.'));
				});
		});
	}

	onNavigate(location, provider) {
		ProviderService.openNavigation({ ...provider, location: location }, this.$f7, true);
	}

	async onCall(location, provider) {
		const shouldAddPcp = UserService.shouldAutoAssignPcp(_.get(location, 'specialties'));
		if (shouldAddPcp) {
			this.$f7.dialog.preloader();
			try {
				await UserService.autoAddProviderAsPcp(provider, location);
			} catch (err) {
				console.log('unable to auto assign PCP: ', err);
			}
			this.$f7.dialog.close();
		}
		analytics.track('called_provider');
		const providerPhone = _.get(location, 'phone', '');
		ExternalService.handleExternalRequest('call', providerPhone);
	}

	getPatientName(patient) {
		return _.startCase(`${patient.firstName} ${patient.lastName}`);
	}

	buildListItems(items, appts) {
		let {
			patientLabel,
			reqDate,
			reqTime,
			noteLabel,
			reasonLabel,
			cancel,
			confirm,
			schTime,
			schDate,
			callText,
			navText,
			firstText,
			userCancled
		} = this.text;
		_.forEach(appts, (appt) => {
			items.push(
				<div className="appt-item neumorphic" key={appt._id}>
					<div className="title-row hbox vcenter">
						<div className="grow-1 ellipse">{`${_.startCase(_.get(appt.provider, 'fullName', '').split(' llc').join(' LLC'))} ${_.get(
							appt.provider,
							'personDetails.credential',
							''
						)}`}</div>
						<Badge className={`badge-${appt.status}`}>{appt.status}</Badge>
					</div>
					<div className="item-content-ctn">
						<div className="label">{patientLabel}</div>
						<div className="patient field">{this.getPatientName(appt.patient)}</div>
						{appt.status === ApptStatus.REQUESTED && (
							<div className="requested-content">
								<div className="label">{reqDate}</div>
								<div className="req-date field">
									{appt.requestType === 'first'
										? firstText
										: `${format(new Date(_.get(appt, 'requestDate[0]', new Date())), 'MM/dd/yyyy')} - ${format(
												new Date(_.get(appt, 'requestDate[1]', _.get(appt, 'requestDate[0]', new Date()))),
												'MM/dd/yyyy'
										  )}`}
								</div>
								<div className="label">{reqTime}</div>
								<div className="req-time field">{_.startCase(appt.requestTime)}</div>
							</div>
						)}
						{appt.scheduledDate && (
							<div className="scheduled-content">
								<div className="label">{schDate}</div>
								<div className="sch-date field">{format(new Date(appt.scheduledDate), 'MM/dd/yyyy p')}</div>
							</div>
						)}
						<div className="label">{reasonLabel}</div>
						<div className="note field">{_.get(this, `text.reason${appt.reason}`, 'Other')}</div>
						<div className="label">{noteLabel}</div>
						<div className="note field">{appt.note}</div>
					</div>
					{appt.status !== ApptStatus.CANCELED && (
						<div className="bottom-bar hbox vcenter">
							<div className="actions">
								{appt.status === ApptStatus.SCHEDULED && (
									<>
										<Button
											small
											fill
											round
											className="animated pulse infinite"
											onClick={() => {
												this.confirmAppt(appt);
											}}
										>
											{confirm}
										</Button>
										<div className="grow-1"></div>
										<Button
											small
											onClick={() => {
												this.cancelAppt(appt);
											}}
										>
											{cancel}
										</Button>
									</>
								)}
								{appt.status === ApptStatus.CONFIRMED && (
									<>
										<div
											className="action vbox vcenter hcenter"
											onClick={() => {
												this.onNavigate(appt.location, appt.provider);
											}}
										>
											<i className="fad fa-directions"></i>
											<div>{navText}</div>
										</div>
										{_.get(appt, 'location.phone', false) && (
											<div
												className="action vbox vcenter hcenter"
												onClick={() => {
													this.onCall(appt.location, appt.provider);
												}}
											>
												<i className="fad fa-phone"></i>
												<div>{callText}</div>
											</div>
										)}

										{new Date(appt.scheduledDate) > new Date() && (
											<div
												className="action vbox vcenter hcenter"
												onClick={() => {
													this.cancelAppt(appt);
												}}
											>
												<i className="fad fa-do-not-enter"></i>
												<div>{cancel}</div>
											</div>
										)}
									</>
								)}
								{appt.status === ApptStatus.REQUESTED && (
									<>
										<div className="grow-1"></div>
										<Button
											small
											onClick={() => {
												this.cancelAppt(appt);
											}}
										>
											{cancel}
										</Button>
									</>
								)}
							</div>
						</div>
					)}
					{appt.status === ApptStatus.CANCELED && (
						<div className="cancled-reason hbox vcenter">
							<div className="reason">{appt.deniedReason ? appt.deniedReason : userCancled}</div>
						</div>
					)}
				</div>
			);
		});
	}

	getContent() {
		let { emptyText, searchText } = this.text;
		let { requested, upcoming, previous } = this.data;
		let empty = (
			<div className="vbox vcenter hcenter empty-content">
				<div className="empty-text">{emptyText}</div>
				<img src={booking} className="booking-svg" />
				<Button className="find-care-btn neumorphic neuhover" large onClick={this.onSearchClick}>
					<i className="fad fa-search"></i>
					{searchText}
				</Button>
			</div>
		);
		switch (this.data.toggleValue) {
			case 'scheduled': {
				let listItems = [];
				if (requested.length > 0 || upcoming.length > 0) {
					this.buildListItems(listItems, upcoming);
					this.buildListItems(listItems, requested);
				}
				return (
					<div
						className={`appointment-content animated fadeInUp y-scroll ${listItems.length > 0 ? '' : 'empty'}`}
						key="scheduled-appointments"
					>
						<List className={`appt-list`} mediaList>
							{listItems.length > 0 ? listItems : empty}
						</List>
					</div>
				);
			}
			case 'previous': {
				let listItems = [];
				if (previous.length > 0 || upcoming.length > 0) {
					let prev = _.cloneDeep(previous).sort((a, b) => {
						return new Date(a.updatedDate) < new Date(b.updatedDate) ? 1 : -1;
					});
					this.buildListItems(listItems, prev);
				}
				return (
					<div
						className={`appointment-content animated fadeInUp y-scroll ${listItems.length > 0 ? '' : 'empty'}`}
						key="previous-appointments"
					>
						<List className={`appt-list`} mediaList>
							{listItems.length > 0 ? listItems : empty}
						</List>
					</div>
				);
			}
		}
		return '';
	}

	render() {
		let { scheduled, previous } = this.text;
		let { toggleValue } = this.data;
		return (
			<div className="appointments-component" key="appointments-component">
				<GlobalHeader />
				<div className="content-header hbox vcenter animated">
					<Button
						active={toggleValue === 'scheduled'}
						onClick={this.toggleContent}
						className={`neumorphic ${toggleValue === 'scheduled' ? 'active' : ''}`}
					>
						{scheduled}
					</Button>
					<Button
						active={toggleValue === 'previous'}
						onClick={this.toggleContent}
						className={`neumorphic ${toggleValue === 'previous' ? 'active' : ''}`}
					>
						{previous}
					</Button>
				</div>
				{this.getContent()}
			</div>
		);
	}
}

export default Appointments;
