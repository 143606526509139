import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import ConfigService from '@/services/ConfigService';
import ExternalService from '@/services/ExternalService';
import { getCmpText } from '@/utils/Localization';
import AnalyticsService from '@/services/AnalyticsService';
import appStore from '@/stores/AppStore';

import './external-services-menu.scss';

const analytics = new AnalyticsService();

@observer
export default class ExternalServicesMenu extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			animationCls: 'zoomIn'
		});
		this.text = getCmpText('ExternalServices', {
			title: 'Your Services'
		});
		autoBind(this);
	}

	handleClick(selection) {
		analytics.track('launched_external_service');
		switch (selection.action) {
			case 'message':
				appStore.showExternalServiceMessage = true;
				appStore.externalServiceMessage = {
					icon: _.clone(selection.icon),
					message: _.clone(selection.data),
					title: _.clone(selection.name)
				};
				break;
			default:
				ExternalService.handleExternalRequest(selection.action, selection.data);
		}
	}

	onClose() {
		this.data.animationCls = 'zoomOut';
		setTimeout(() => {
			this.props.onClose();
		}, 300);
	}

	render() {
		const config = ConfigService.getConfiguration();
		const externalServices = config.externalServices || [];
		return (
			<div className={`external-services`}>
				<div className={`app-drawer animated ${this.data.animationCls}`}>
					<div className="hbox vcenter hright">
						<div className="title">{this.text.title}</div>
						<i className="fad fa-times-square" onClick={this.onClose} />
					</div>
					<div className="app-ctn">
						{externalServices.map((option) => {
							return (
								<div className={`app vbox hcenter vcenter ${option.color}`} onClick={() => this.handleClick(option)} key={option.name}>
									<i className={option.icon} />
									<div className="name">{option.name}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
