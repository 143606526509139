import { autoBind } from '@/utils/GeneralUtils';
import { getCmpText } from '@/utils/Localization';
import { observer, observable } from '@/utils/State';
import { Button } from 'framework7-react';
import React, { Component } from 'react';
import TransactionPage from '../transaction-page/TransactionPage';
import './transaction-history.scss';

@observer
export default class TransactionHistory extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('NoPaymentMethods', {
			noCardsPresentMessage: 'You do not have any cards added to your account yet!',
			addCardButtonText: 'Add a credit card',
			rejectNoCardsDialog: "I don't want to add a credit card yet"
		});
		this.data = observable({
			selectedTransaction: null
		});
		autoBind(this);
	}

	renderTransactions() {
		let { transactionHistory, onTransactionSelect } = this.props;
		let transactions = [];
		transactionHistory.forEach((transaction, key) => {
			let { created, amount } = transaction;
			let dateCreated = new Date(created * 1000);
			amount = (amount / 100).toFixed(2);
			transactions.push(
				<Button className="neubtn balance-btn hbox hleft transaction" key={key} onClick={() => onTransactionSelect(transaction)}>
					<div className="transaction-holder">
						<div className="details-container">
							<div className="description">{transaction.description}</div>
							<div className="date-time">{dateCreated.toLocaleString('en-US').replace(',', '| ')}</div>
						</div>
						<div className={`amount ${transaction.refunded ?  'refund': ''}`}>{transaction.refunded ? '' : '-'}  {amount}</div>
					</div>
				</Button>
			);
		});

		if (this.data.selectedTransaction !== null) {
			return <TransactionPage transaction={this.data.selectedTransaction} />;
		} else {
			return <div>{transactions}</div>;
		}
	}

	render() {
		return (
			<div className="transaction-history-container">{this.renderTransactions()}</div>
			// TODO SHOW MORE FEATURE
		);
	}
}
