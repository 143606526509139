import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import Table from '@/components/_EXPRESS/table/Table';
import PlanDetails from './plan-details/PlanDetails';
import './plan-admin.scss';

@observer
export default class PlanAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentPlan: null,
			plans: []
		});
		autoBind(this);
	}

	async componentDidMount() {
		const plans = await this.getPlans();
		this.data.plans = plans;
	}

	async getPlans() {
		this.$f7.dialog.preloader();
		try {
			const plans = await ExpressAPI.getAllAdminPlans();
			this.$f7.dialog.close();
			return plans;
		} catch (err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error loading plans');
		}
	}

	handleRequestClick(data) {
		const id = data.id;
		const planData = _.find(this.data.plans, { _id: id });
		this.data.currentPlan = planData;
	}

	closeDetails() {
		this.data.currentPlan = null;
	}

	render() {
		const loading = false;
		const showPlanList = !this.data.currentPlan;
		const showPlanDetails = !!this.data.currentPlan;
		const hasPlans = this.data.plans && this.data.plans.length > 0;
		const plans = this.data.plans || [];
		const tableHeaders = [
			{ text: 'Name', classList: 'ellipse', canSearch: true },
			{ text: 'Org', classList: 'ellipse', canSearch: true },
			{ text: 'Last Updated' },
			{ text: 'Status', canSearch: true }
		];
		const tableRows = plans.map((plan) => {
			return {
				id: plan._id,
				onClick: this.handleRequestClick,
				items: [
					{ text: plan.name, classList: 'ellipse plan-name capitalize' },
					{ text: plan.org, classList: 'capitalize ellipse' },
					{ text: plan.lastUpdated },
					{ classList: 'capitalize', text: plan.status }
				]
			};
		});
		return (
			<div className="admin-plan-container">
				{showPlanDetails && <PlanDetails onClose={this.closeDetails} plan={this.data.currentPlan} />}
				{showPlanList && (
					<div className="express-plans-list">
						<h2 className="grow-1">Plan List</h2>
						{!hasPlans && !loading && (
							<div className="empty-message vbox vcenter hcenter">
								<div className="icon-box hbox vcenter hcenter">
									<i className="fad fa-users-class" />
								</div>
								<h3>There are no plans created yet. Check back later!</h3>
							</div>
						)}
						{hasPlans && <Table id="plan-admin-table" headers={tableHeaders} rows={tableRows} />}
					</div>
				)}
			</div>
		);
	}
}
