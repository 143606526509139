import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../../utils/State';
import { autoBind } from '../../../../utils/GeneralUtils';
import {
	Button,
	Link,
	ListInput,
	List,
	ListItem,
	Popup,
	Segmented
} from 'framework7-react';
import CloudAPI from '../../../../services/CloudAPI';
import { formatDOB } from '../../../../utils/GeneralUtils';
import './user-notes.scss';

@observer
export default class UserNotes extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			newNoteText: '',
			toggleType: 'user',
			notes: []
		});
		autoBind(this);
	}

	updateField(evt) {
		const field = evt.currentTarget.name;
		const value = evt.currentTarget.value;
		this.data[field] = value;
	}

	clearField(evt) {
		const field = evt.currentTarget.name;
		this.data[field] = '';
	}

	async getNotes() {
		const userUid = this.props.userUid;
		this.$f7.dialog.preloader('Loading Notes');
		try {
			const notes = await CloudAPI.getUserNotes(userUid);
			this.data.notes = notes;
			this.$f7.dialog.close();
		} catch (err) {
			console.log('error retreiving notes: ', err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error loading notes');
		}
	}

	async openNotes() {
		this.getNotes();
	}

	async saveNote() {
		const note = this.data.newNoteText;
		const userUid = this.props.userUid;
		this.$f7.dialog.preloader('Saving');
		try {
			await CloudAPI.createUserNote(userUid, { note: note });
			this.data.newNoteText = '';
			this.$f7.dialog.close();
			this.getNotes();
		} catch (err) {
			console.log('error saving note: ', err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error saving note, please try again later.');
		}
	}

	closeNotes() {
		this.data.notes = [];
		this.data.toggleType = 'user';
		this.props.close();
	}

	getFilteredNotes() {
		const notes = this.data.notes || [];
		return notes.filter((note) => {
			switch (this.data.toggleType) {
				case 'user':
					return !note.isQuestionnaire && !note.isMessage;
				case 'questionnaire':
					return note.isQuestionnaire;
				case 'messages':
					return note.isMessage;
				default:
					return true;
			}
		});
	}

	render() {
		const { newNoteText, toggleType } = this.data;
		const notes = this.getFilteredNotes();
		let tabText = 'notes';
		if (toggleType === 'questionnaire') {
			tabText = 'questionnaires';
		}
		if (toggleType === 'messages') {
			tabText = 'messages';
		}
		return (
			<Popup
				className="user-notes-modal"
				opened={this.props.show}
				onPopupClosed={this.closeNotes}
				onPopupOpened={this.openNotes}
			>
				<div className="user-notes-header hbox vcenter hright">
					<h2>Notes</h2>
					<Link popupClose>
						<i className="fad fa-times-square" />
					</Link>
				</div>
				<div className="notes-type-toggles">
					<Segmented raised round small className="info-toggle">
						<Button
							round
							small
							active={toggleType === 'user'}
							onClick={() => {
								this.data.toggleType = 'user';
							}}
						>
							Admin Notes
						</Button>
						<Button
							round
							small
							active={toggleType === 'questionnaire'}
							onClick={() => {
								this.data.toggleType = 'questionnaire';
							}}
						>
							Surveys
						</Button>
						<Button
							round
							small
							active={toggleType === 'messages'}
							onClick={() => {
								this.data.toggleType = 'messages';
							}}
						>
							Messages
						</Button>
					</Segmented>
				</div>
				<List
					className={`user-notes-list y-scroll ${
						toggleType === 'user' ? 'with-input' : ''
					}`}
					noHairlines
				>
					{notes.map((note, index) => {
						const firstName = _.get(note, 'authorData.firstName', '');
						const lastName = _.get(note, 'authorData.lastName', '');
						const authorName = note.authorData
							? `${firstName} ${lastName}`
							: 'Unknown User';
						return (
							<ListItem key={`note-${note._id}-${index}`} className="user-note">
								<div className={`note-meta-data ${toggleType}`}>
									<span>{formatDOB(note.createdDate)}</span>
									{toggleType !== 'questionnaire' && (
										<span>Created By: {authorName}</span>
									)}
									{toggleType !== 'user' && <span>Type: {note.type}</span>}
									{toggleType === 'messages' && (
										<span>Status: {note.status}</span>
									)}
								</div>
								{toggleType !== 'questionnaire' && <p>{note.note}</p>}
								{toggleType === 'questionnaire' && (
									<div>
										{Object.keys(_.get(note, 'note', [])).map((responseKey) => {
											const responseValue = _.get(
												note,
												`note[${responseKey}]`,
												''
											);
											return (
												<p
													className="questionnaire-responses"
													key={`questionnaire-${responseKey}-${note._id}-${index}`}
												>
													<span className="response-key">{responseKey}: </span>
													{responseValue === true && (
														<i className="fal fa-check response-value-icon" />
													)}
													{responseValue === false && (
														<i className="fal fa-times response-value-icon" />
													)}
													{responseValue !== true &&
														responseValue !== false && (
															<span className="response-answer">
																{responseValue}
															</span>
														)}
												</p>
											);
										})}
									</div>
								)}
								{toggleType === 'messages' && (
									<div>
										<p>{note.body}</p>
										{note.attachments && (
											<div>
												{note.attachments.map((attachment) => {
													return <p>{attachment}</p>;
												})}
											</div>
										)}
									</div>
								)}
							</ListItem>
						);
					})}
					{(!notes || notes.length < 1) && (
						<p className="user-notes-empty">{`There are no ${tabText} to show for this user.`}</p>
					)}
				</List>
				{toggleType === 'user' && (
					<List className="user-notes-footer">
						<ListInput
							outline
							label="New Note"
							type="textarea"
							placeholder="You can add additional notes here"
							className="md"
							floatingLabel
							onChange={this.updateField}
							name="newNoteText"
							value={newNoteText || ''}
							onInputClear={this.clearField}
						/>
						<Button
							onClick={this.saveNote}
							disabled={!newNoteText || newNoteText.length < 1}
						>
							Save Note
						</Button>
					</List>
				)}
			</Popup>
		);
	}
}
