import { autoBind } from '@/utils/GeneralUtils';
import { getCmpText } from '@/utils/Localization';
import { observer, observable } from '@/utils/State';
import React, { Component } from 'react';
import './screening-outcome.scss';
import ScreeningService from '@/services/ScreeningService';
import screeningStore from '@/stores/ScreeningStore';
import providerCovid from '@/assets/provider-covid.svg';
import { Chip, Button } from 'framework7-react';
@observer
export class ScreeningOutcome extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('ScreeningOutcome', {
			contactFacilityHeader: 'Contact Your Facility’s Care Team',
			contactFacilitySub: 'Discuss your symptoms with the doctor or care team in charge at your facility.',
			contactWorkHeader: 'Contact Your Work’s Occupational Health Provider',
			contactWorkSub: 'Let your occupational health provider at work know about your current symptoms.',
			contactProviderHeader: 'Contact a Healthcare Provider',
			contactProviderSub:
				'Discuss your symptoms with a doctor or a medical professional. Call before you visit a medical facility. You can also consider a telemedicine appointment.',
			selfIsolateHeader: 'Isolate From Others',
			selfIsolateSub: 'You should wear a mask and stay six feet away from anyone you don’t live with.',
			socialDistanceHeader: 'Continue Physical Distancing',
			socialDistanceSub: 'You should wear a mask and stay six feet away from anyone you don’t live with.',
			responsesTxt: 'Your responses',
			ageTxt: 'Age',
			symptomsTxt: 'Symptoms',
			conditionsTxt: 'Conditions',
			exposureTxt: 'Exposure',
			facilityTxt: 'Long-term care facility resident',
			healthWorkerTxt: 'Healthcare worker or volunteer',
			ageBetween1864: '18 to 64',
			ageGreater65: '65 or older',
			oneOrMoreTxt: 'One or more',
			noneTxt: 'None',
			seriousExposureTxt: 'Significant exposure to COVID‑19',
			minorExposureTxt: 'Probable exposure to COVID‑19',
			yes: 'Yes',
			no: 'No',
			symptomSevrityTxt: 'Symptom severity',
			symptomSevereTxt: 'Major impact on daily activities',
			minorSymptomTxt: 'Some impact on daily activities',
			notSevereTxt: 'No impact on daily activities',
			finishTxt: 'Finish',
			testingRecommendedTxt: 'Testing Recommended',
			testingNotRecommendedTxt: 'No Testing Needed'
		});
		this.data = observable({});
		autoBind(this);
	}

	getHeaderText(outcome) {
		let headerTxt = {};

		if (outcome.includes('contact_facility')) {
			headerTxt.head = this.text.contactFacilityHeader;
			headerTxt.sub = this.text.contactFacilitySub;
			headerTxt.artwork = providerCovid;
			return headerTxt;
		}

		if (outcome.includes('contact_occupational_health')) {
			headerTxt.head = this.text.contactWorkHeader;
			headerTxt.sub = this.text.contactWorkSub;
			headerTxt.artwork = providerCovid;
			return headerTxt;
		}

		if (outcome.includes('contact_provider_testing_recommended')) {
			headerTxt.head = this.text.contactProviderHeader;
			headerTxt.sub = this.text.contactProviderSub;
			headerTxt.artwork = providerCovid;
			return headerTxt;
		}

		if (outcome.includes('self_isolate')) {
			headerTxt.head = this.text.selfIsolateHeader;
			headerTxt.sub = this.text.selfIsolateSub;
			headerTxt.artwork = providerCovid;
			return headerTxt;
		}

		headerTxt.head = this.text.socialDistanceHeader;
		headerTxt.sub = this.text.socialDistanceSub;
		headerTxt.artwork = providerCovid;
		return headerTxt;
	}

	snakeToCamel(str) {
		return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
	}

	getResponseTxt(response) {
		if (Array.isArray(response)) {
			const section = response[0].split('_')[0];
			if (response.length > 0) {
				if (section === 'symptoms' || section === 'conditions') {
					return response.filter((i) => i.split('_')[1] !== 'none').length > 0 ? this.text.oneOrMoreTxt : this.text.noneTxt;
				} else if (section === 'exposure') {
					if (response.filter((i) => i.split('_')[1] !== 'none')) {
						return this.text.noneTxt;
					} else {
						return ScreeningService.isSevereExposure(response) ? this.text.seriousExposureTxt : this.text.minorExposureTxt;
					}
				}
			}
		} else {
			const section = response.split('_')[0];
			if (section === 'age') {
				return this.text[this.snakeToCamel(response)];
			} else if (section === 'careFacilityLive' || section === 'careFacilityWork') {
				return this.text[response.split('_')[1]];
			} else if (section === 'severity') {
				if (response.split('_')[1] === 'none') {
					return this.text.notSevereTxt;
				} else {
					return ScreeningService.isSymptomSevere(response) ? this.text.symptomSevereTxt : this.text.minorSymptomTxt;
				}
			}
		}
	}

	renderTestingChip(outcome) {
		if (ScreeningService.isTestingRecommended(outcome)) {
			return (
				<Chip text={this.text.testingRecommendedTxt} mediaBgColor="red" className="ios" key="">
					<i slot="media" className="fad fa-vial"></i>
				</Chip>
			);
		} else {
			return (
				<Chip text={this.text.testingNotRecommendedTxt} mediaBgColor="green" className="ios" key="">
					<i slot="media" className="fad fa-times-circle"></i>
				</Chip>
			);
		}
	}

	renderHeader() {
		const outcome = ScreeningService.generateOutcome();
		const header = this.getHeaderText(outcome);
		return (
			<div className="vbox hleft outcome-header">
				{this.renderTestingChip(outcome)}

				<h1 className="outcome-headline">{header.head}</h1>
				<p className="outcome-detail">{header.sub}</p>
			</div>
		);
	}

	renderResult() {
		const outcome = ScreeningService.generateOutcome();
		let results = [];
		outcome.forEach((result, k) => {
			results.push(<div>{result}</div>);
		});

		return results;
	}

	renderResponses() {
		return (
			<div className="vbox hleft responses">
				<h2 className="response-headline">{this.text.responsesTxt}:</h2>
				<ul className="response-list">
					<li className="response-item">
						<span className="key">{this.text.ageTxt}:</span> {this.getResponseTxt(screeningStore.results['age'])}
					</li>
					<li className="response-item">
						<span className="key">{this.text.symptomsTxt}:</span> {this.getResponseTxt(screeningStore.results['symptoms'])}
					</li>
					{screeningStore.results['severity'] !== '' && (
						<li className="response-item">
							<span className="key">{this.text.symptomSevrityTxt}:</span> {this.getResponseTxt(screeningStore.results['severity'])}
						</li>
					)}

					<li className="response-item">
						<span className="key">{this.text.conditionsTxt}:</span> {this.getResponseTxt(screeningStore.results['conditions'])}
					</li>
					<li className="response-item">
						<span className="key">{this.text.exposureTxt}:</span> {this.getResponseTxt(screeningStore.results['exposure'])}
					</li>
					<li className="response-item">
						<span className="key">{this.text.facilityTxt}:</span> {this.getResponseTxt(screeningStore.results['careFacilityLive'])}
					</li>
					<li className="response-item">
						<span className="key">{this.text.healthWorkerTxt}:</span> {this.getResponseTxt(screeningStore.results['careFacilityWork'])}
					</li>
				</ul>
			</div>
		);
	}

	renderBody() {
		const outcome = ScreeningService.generateOutcome();
		return (
			<div className="page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="navigation hbox vcenter">
							<div className="back hbox vcenter" onClick={ScreeningService.onBack}>
								<i className="fal fa-chevron-left"></i>Back
							</div>
						</div>
					</div>
				</div>
				{this.renderHeader()}
				{this.renderResponses()}
				<Button fill large className="round-btn purple" onClick={() => this.props.onComplete(outcome)}>
					<div className="text">{this.text.finishTxt}</div>
				</Button>
			</div>
		);
	}

	render() {
		if (this.props.showOutcome) {
			return <div className="screening-outcome-page">{this.renderBody()}</div>;
		} else {
			return '';
		}
	}
}
