import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import expressStore from '@/stores/ExpressStore';
import { format } from 'date-fns';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Button, Preloader, Popup, Link } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';
import './network-list.scss';
import NetworkDetails from './network-details/NetworkDetails';
import NetworkRequestForm from '@/pages/EXPRESS/networks/network-request-form/NetworkRequestForm';

@observer
export default class NetoworkList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			searchInput: '',
			networks: [],
			loading: false,
			loadedFrom: null,
			createOpen: false,
			currentNetwork: null,
			availableNetworks: [],
			formData: {
				type: 'traditional',
				networkId: 0,
				name: '',
				contactName: '',
				contactPhone: '',
				contactEmail: '',
				request: '',
				existingNetwork: false
			}
		});
		autoBind(this);
	}

	asyncLoadNetworks() {
		setTimeout(() => {
			this.data.currentNetwork = null;
			this.loadNetworks();
		}, 0);
	}

	async loadNetworks() {
		if (!this.data.loading) {
			this.data.loading = true;
			this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
			ExpressAPI.getNetworks()
				.then((res) => {
					this.data.loading = false;
					this.data.networks = res;
				})
				.catch((err) => {
					this.data.loading = false;
					console.log(err);
				});
			ExpressAPI.getAvailableNetworks()
				.then((res) => {
					this.data.availableNetworks = res;
				})
				.catch((err) => {
					showExpressError(this.$f7, err);
				});
		}
	}

	onOpenCreateNetwork() {
		this.data.createOpen = true;
	}

	searchChange(e) {
		this.data.searchInput = e.currentTarget.value;
	}

	buildList() {
		let { networks, loading, searchInput } = this.data;
		let items = [];
		_.forEach(networks, (n) => {
			if (searchInput.length > 0 && _.toLower(n.name).indexOf(searchInput.toLowerCase()) < 0) {
				return;
			}
			items.push(
				<div
					className="table-list-item network-list-item hbox vcenter"
					key={`${n.orgId}-${n._id}`}
					onClick={(e) => {
						// @ts-ignore
						// if (!e.target?.classList?.contains('status-chip')) {
						this.data.currentNetwork = _.cloneDeep(n);
						// }
					}}
				>
					<div className="name ellipse" title={_.toUpper(n.name)}>
						{n.name}
					</div>
					<div className="field grow-1 type">{_.capitalize(n.type)}</div>
					<div className="field grow-1">{format(new Date(n.lastUpdated), 'MMMM dd, yyyy')}</div>
					<div className="field grow-1">{_.capitalize(n.status)}</div>
				</div>
			);
		});
		return (
			<div className={`network-list ${loading ? '' : 'shadow'}`}>
				{networks.length !== 0 && (
					<div className="table-list-header network-list-header hbox vcenter">
						<div className="name">Name</div>
						<div className="type field grow-1">Type</div>
						<div className="field grow-1">Last Updated</div>
						<div className="field grow-1">Status</div>
					</div>
				)}
				{!loading ? (
					items
				) : (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
			</div>
		);
	}

	onSaveNetwork(data) {
		this.$f7.dialog.preloader('Saving...');
		const formData = _.cloneDeep(data);
		if (formData.type === 'tiered') {
			formData.existingNetwork = false;
			formData.networkId = 0;
		}
		ExpressAPI.saveNetwork(formData)
			.then((res) => {
				this.asyncLoadNetworks();
				this.data.createOpen = false;
				this.data.formData = {
					type: 'traditional',
					networkId: 0,
					name: '',
					contactName: '',
					contactPhone: '',
					contactEmail: '',
					request: '',
					existingNetwork: false
				};
				this.$f7.dialog.close();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				showExpressError(this.$f7, err);
			});
	}

	onNetworkSave(network) {
		this.data.currentNetwork = network;
		this.asyncLoadNetworks();
	}

	render() {
		let { searchInput, networks, loading, loadedFrom, formData, createOpen, currentNetwork } = this.data;
		let { currentOrg, activePage } = expressStore;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoadNetworks();
		}
		let networkListItems = { 0: 'Request New Network' };
		let requestableNetworks = _.cloneDeep(this.data.availableNetworks);
		let existingNetworksMap = {};
		_.forEach(networks, (n) => {
			networkListItems[n._id] = n.name;
			existingNetworksMap[n._id] = n;
			if (n.networkId && requestableNetworks[n.networkId]) {
				delete requestableNetworks[n.networkId];
			}
		});
		_.forEach(Object.keys(requestableNetworks), (key) => {
			networkListItems[key] = requestableNetworks[key];
		});
		return currentNetwork ? (
			<NetworkDetails
				showBreadCrumb={true}
				network={currentNetwork}
				onSave={this.onNetworkSave}
				closeDetails={() => {
					this.data.currentNetwork = null;
				}}
			/>
		) : (
			<div className={`express-networks-list ${activePage === 'networks' ? 'add-padding' : ''}`}>
				<h1 className="text-4 mb-xl grow-1">Networks</h1>
				<div className="list-controls hbox vcenter">
					<div className="input-ctn hbox vcenter">
						<i className="fas fa-search"></i>
						<input placeholder="Search for networks by name..." value={searchInput} onChange={this.searchChange} />
					</div>
					<div className="grow-1"></div>
					{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
					expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER ? null : (
						<Button fill small className="list-btn ios" onClick={this.onOpenCreateNetwork}>
							<i className="fa fa-plus" />
							Request Network
						</Button>
					)}
				</div>

				{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
				expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
					? null
					: networks.length <= 0 &&
					  !loading && (
							<div className="empty-message vbox vcenter hcenter">
								<div className="icon-box hbox vcenter hcenter">
									<i className="fad fa-users-class" />
								</div>
								<h3>Request your first network</h3>
								<Button fill small className="round-btn list-btn animated pulse infinite" onClick={this.onOpenCreateNetwork}>
									<i className="fa fa-plus" />
									Request Network
								</Button>
							</div>
					  )}
				{this.buildList()}
				<Popup
					className={`express-form-popup`}
					opened={createOpen}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupClosed={() => {
						this.data.createOpen = false;
						this.data.formData = {
							type: 'traditional',
							networkId: 0,
							name: '',
							contactName: '',
							contactPhone: '',
							contactEmail: '',
							request: '',
							existingNetwork: false
						};
					}}
				>
					{createOpen && <NetworkRequestForm onSubmit={this.onSaveNetwork} standalone />}
				</Popup>
			</div>
		);
	}
}
