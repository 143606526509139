import Framework7 from 'framework7/framework7.esm.bundle.js';
import userStore from '../stores/UserStore';
import _ from 'lodash';
import AnalyticsService from './AnalyticsService';
import appStore from '../stores/AppStore';
const getBaseURL = () => {
	const env = process.env.NODE_ENV;
	const isDev = env === 'development';
	let base = isDev
		? 'http://localhost:3000/api'
		: 'https://carevalet.cloud/api'; // setup initial fall back in case all others fail
	if (appStore.isApp()) {
		//installed apps have no location.host value
		base = isDev
			? 'https://api.carevalet.dev/api'
			: 'https://carevalet.cloud/api';
		return base;
	}
	const API_ENVS = {
		'carevalet-development.web.app': 'https://api.carevalet.dev/api',
		'bs-local.com:8080': 'http://bs-local.com:3000/api',
		'carevalet.io': 'https://carevalet.cloud/api',
		'carevalet.express': 'https://carevalet.cloud/api',
		'carevalet.doctor': 'https://carevalet.cloud/api',
		'dev.carevalet.app': 'https://api.carevalet.dev/api',
		'localhost:8080': 'http://localhost:3000/api',
		'0.0.0.0:8080': 'http://0.0.0.0:3000/api',
		'cvdemos.web.app': 'https://carevalet.dev/api',
		'doctyrs.tech': 'https://api.carevalet.dev/api'
	};
	const checkForSpecialRouting = API_ENVS[location.host];
	if (location.port === '' && !API_ENVS[location.host]) {
		return 'http://' + location.host + '/api';
	}
	return checkForSpecialRouting || base;
};
const analytics = new AnalyticsService();
class CloudAPI {
	getBaseUrl() {
		return getBaseURL();
	}
	getHeaders() {
		const headers = {
			version: _.get(userStore, 'frontendVersion', '')
		};
		const userToken = _.get(userStore, 'userToken');
		if (userToken) {
			headers.authorization = userToken;
		}
		return headers;
	}
	get(url, noToken, noBase) {
		try {
			return new Promise((resolve, reject) => {
				Framework7.request({
					method: 'GET',
					url: noBase ? url : getBaseURL() + url,
					crossDomain: true,
					headers: this.getHeaders(),
					success: function (res) {
						try {
							resolve(JSON.parse(res));
						} catch {
							resolve(res);
						}
					},
					error: (xhr) => {
						this.handleError(xhr);
						reject(xhr);
					}
				});
			});
		} catch {
			return Promise.reject('Unable to access API');
		}
	}

	post(url, data, noToken, noBase) {
		return new Promise((resolve, reject) => {
			Framework7.request({
				method: 'POST',
				contentType: 'application/json',
				url: noBase ? url : getBaseURL() + url,
				data: data,
				crossDomain: true,
				headers: this.getHeaders(),
				success: function (res) {
					try {
						resolve(JSON.parse(res));
					} catch {
						resolve(res);
					}
				},
				error: (xhr) => {
					setTimeout(() => {
						//make sure dialogs, popups, modals have a chance to finish closing animations
						this.handleError(xhr);
						reject(xhr);
					}, 500);
				}
			});
		});
	}

	handleError(xhr) {
		//TODO right now this is a catch all for expired tokens but will add a more precise check later with a jwt lib to check expiration
		const errorCode = Number(_.get(xhr, 'status', 0));
		console.log('Handling API Error: ', errorCode);
		const loginUrl = '/#!/login';
		switch (errorCode) {
			case 401:
				firebase.auth().signOut();
				console.log('Logging out user due to 401');
				break;
			case 403:
				firebase.auth().signOut();
				console.log('Logging out user due to 403');
				break;
			case 426:
				appStore.forceAppUpdate = true;
				analytics.track('app_update_required');
				break;
			default:
				break;
		}
		return xhr;
	}

	//=========CAREVALET CLOUD API START ==================================================

	async getConfig() {
		if (appStore.demoMode) {
			const data = await this.get(`/config/demo/${appStore.demoMode}`);
			userStore.userData.group = _.cloneDeep(data.plan);
			userStore.userData.demoHash = _.cloneDeep(data.memberHash);
			userStore.userData.dependents = _.cloneDeep(data.dependents);
			userStore.userData.member = _.cloneDeep(data.member);
			return data.config;
		}
		return this.get('/config');
	}

	async getVersion() {
		const serverData = await this.get('/ping');
		return serverData && serverData.version;
	}
	//Assums firebase user is already signed in and this is more of a token verification with the server to get member data
	async login() {
		return this.get('/user/login');
	}

	async getUserRewards() {
		return this.get('/user/rewards');
	}

	async saveProfileInfo(user) {
		return this.post('/user/updateProfile', user);
	}

	async setUserTimezone(tz) {
		if (userStore.isLoggedIn) {
			return this.post('/user/setTimezone', { timezone: tz });
		}
	}

	async setLanguagePreference(language) {
		if (userStore.isLoggedIn) {
			return this.post('/user/setLanguagePreference', { language: language });
		}
	}

	async setUIPreference(status) {
		if (userStore.isLoggedIn) {
			return this.post('/user/setUIPreference', {
				accessibilityModeOn: status
			});
		}
	}

	async findAccount(data) {
		let search = {};
		if (!_.isEmpty(data.groupNumber) && !_.isEmpty(data.memberNumber)) {
			search = {
				groupNumber: data.groupNumber,
				memberNumber: data.memberNumber,
				dob: data.dob
			};
		} else {
			search = { zipCode: data.zip, ssn: data.ssn, lastName: data.lastName };
		}
		return this.post('/user/findAccount', search);
	}

	async textAccountVerification(fId) {
		return this.get(`/user/sendSMSVerification/${fId}`);
	}

	async submitAccountVerificationCode(fId, code) {
		return this.get(`/user/verifySMS/${fId}/${code}`);
	}

	async validateNewAccount(data) {
		return this.post(`/user/validateNewAccount`, data);
	}

	async createAccount(accountData) {
		return this.post('/user/createAccount', accountData);
	}

	async createConsumer(consumerData) {
		return this.post('/user/createConsumer', consumerData);
	}

	async getVPCSSOUrl() {
		return this.get('/user/vpcsso');
	}

	async enrollTelehealth(data) {
		return this.post('/user/enrolltelehealth', data);
	}

	async isValidConsumer(phone) {
		return this.get(`/user/validconsumer/${encodeURIComponent(phone)}`);
	}

	async getMyUser() {
		return this.get(`/user/me`);
	}

	async addDevice(deviceToken) {
		return this.post('/user/addDevice', { device: deviceToken });
	}

	async submitQuestionnaire(data) {
		return this.post('/user/questionnaireSubmission', data);
	}

	async getInsuranceCard(memberId, side) {
		return this.get(`/user/insuranceCard?side=${side}`);
	}

	async getUserPlanDocuments() {
		return this.get(`/user/planDocuments`);
	}

	async getUserPlanDocumentsDemo(id) {
		return this.get(`/user/planDocuments/demo/${id}`);
	}

	async getUserPlanDocument(documentId) {
		return this.get(`/user/planDocuments/${documentId}`);
	}

	async getUserPlanDocumentDemo(documentId, memberId) {
		return this.get(`/user/planDocuments/demo/${documentId}/${memberId}`);
	}

	async getCareTeam(demoHash) {
		if (demoHash) return this.get(`/careteam/${demoHash}`);

		return this.get(`/careteam`);
	}

	async submitConsumerAccountRequest(data) {
		const response = await this.post(
			'/consumer-account/createConsumer',
			data,
			true,
			false
		);
		return response;
	}
	addToCareTeam(data) {
		try {
			_.remove(data.location.rawSpecialties, (n) => {
				return n === null;
			});
		} catch (err) {
			console.log(err);
		}
		analytics.track('add_to_careteam');
		return this.post('/careteam/add', data);
	}

	removeFromCareTeam(NPI) {
		analytics.track('remove_from_careteam');
		return this.get(`/careteam/remove/${NPI}`);
	}

	clearPcp() {
		analytics.track('remove PCP indicator');
		return this.get('/careteam/clear-pcp');
	}

	submitFeedback(data) {
		return this.post(`/feedback/createFeedback`, data);
	}

	getPlanDocuments(data) {
		analytics.track('view_documents');
		const dependentHash = _.get(data, 'requestedDependent.hash', '');
		return this.get(`/membership-document/idcard/${dependentHash}`);
	}

	getPlanDocumentsDemo(data) {
		analytics.track('view_documents');
		const dependentHash = _.get(data, 'requestedDependent.hash', '');
		const primaryHash = data.primaryDemoId ?? '';
		const URL = dependentHash
			? `/membership-document/idcard/demo/${dependentHash}`
			: `/membership-document/idcard/demo/${primaryHash}`;
		return this.get(URL);
	}

	mailPlanDocuments(data) {
		analytics.track('mail_plan_documents');
		return Promise.resolve('done');
	}

	downloadPlanDocuments(data) {
		console.log('downloadPlanDocuments');
		analytics.track('download_plan_documents');
		const responseType =
			data.formatRequested && data.formatRequested === 'url' ? 'text' : 'blob';
		if (data.isExpress) {
			console.log('downloadPlanDocuments data.isExpress');
			return new Promise((resolve, reject) => {
				Framework7.request({
					method: 'POST',
					contentType: 'application/json',
					url: getBaseURL() + '/membership-document/download/express',
					data: data,
					crossDomain: true,
					headers: this.getHeaders(),
					xhrFields: {
						responseType: responseType // to avoid binary data being mangled on charset conversion
					},
					success: function (res) {
						try {
							resolve(JSON.parse(res));
						} catch {
							resolve(res);
						}
					},
					error: (xhr) => {
						setTimeout(() => {
							//make sure dialogs, popups, modals have a chance to finish closing animations
							this.handleError(xhr);
							reject(xhr);
						}, 500);
					}
				});
			});
		} else {
			return this.post(`/membership-document/download`, data);
		}
	}

	emailPlanDocuments(data) {
		analytics.track('email_plan_documents');
		return this.post(`/membership-document/email/${data.docType}`, data);
	}

	faxPlanDocuments(data) {
		analytics.track('fax_plan_documents');
		return this.post(`/membership-document/fax/${data.docType}`, data);
	}
	//* Provider Search API START ===============================================

	async findGeoLocation(query) {
		return this.get(`/search/findGeo/${encodeURIComponent(query)}`);
	}

	async findByGeoLocation(lat, lon) {
		return this.post(`/search/findByGeo`, { lat: lat, lon: lon });
	}

	async findZip(lat, lon) {
		return this.post(`/search/findZip`, { lat: lat, lon: lon });
	}

	async nameSearch(query, radius) {
		let { lat, lon, address } = appStore.currentLocation;
		return this.get(
			`/search/providers/${encodeURIComponent(
				query
			)}?radius=${encodeURIComponent(radius)}&lat=${encodeURIComponent(
				lat
			)}&lon=${encodeURIComponent(lon)}`
		);
	}

	async procedureSearch(query) {
		return this.get(`/search/procedures?query=${encodeURIComponent(query)}`);
	}

	async procedureProviderSearch(code, radius, providerType) {
		let { lat, lon } = appStore.currentLocation;
		const url = appStore.demoMode
			? `/search/providers/procedure/demo/${encodeURIComponent(code)}/${
					appStore.demoMode
			  }?lat=${encodeURIComponent(lat)}&lon=${encodeURIComponent(
					lon
			  )}&radius=${encodeURIComponent(
					radius
			  )}&providerType=${encodeURIComponent(providerType)}`
			: `/search/providers/procedure/${encodeURIComponent(
					code
			  )}?lat=${encodeURIComponent(lat)}&lon=${encodeURIComponent(
					lon
			  )}&radius=${encodeURIComponent(
					radius
			  )}&providerType=${encodeURIComponent(providerType)}`;

		return this.get(url);
	}

	async getProvider(npi) {
		return this.get(`/search/providers/npi/${encodeURIComponent(npi)}`);
	}

	async providerSearch(dto) {
		return this.post('/search/providers', dto);
	}

	async getBetterDoctorData(npi) {
		return this.get(
			`/search/providers/betterdoctor/${encodeURIComponent(npi)}`
		);
	}

	//* Provider Search API END ===============================================

	//* COVID API START ========
	async searchForSites(filter) {
		let { lat, lon } = appStore.currentLocation;
		filter.lat = lat;
		filter.lon = lon;
		return this.post('/covid/search', filter);
	}

	//* COVID API END ========

	//* Prescription API START ===============================================

	async prescriptionSearch(query) {
		return this.get(`/prescription/search/${encodeURIComponent(query)}`);
	}

	async getDrugInfo(name) {
		return this.get(`/prescription/drug-info/${encodeURIComponent(name)}`);
	}

	async priceCompare(query) {
		let { name, form, dosage, quantity, zipcode } = query;
		return this.get(
			`/prescription/compare-price?name=${encodeURIComponent(
				name
			)}&form=${form}&dosage=${dosage}&quantity=${quantity}&zipcode=${zipcode}`
		);
	}

	async getCoupon(couponKey) {
		return this.get(
			`/prescription/coupon?couponKey=${encodeURIComponent(couponKey)}`
		);
	}

	async getUserPrescriptions() {
		return this.get(`/prescription`);
	}

	async savePrescription(prescription) {
		return this.post('/prescription/save', prescription);
	}

	async removePrescription(id) {
		return this.get(`/prescription/remove/${id}`);
	}

	async getNearbyPharmacyByName(name) {
		let { lat, lon } = appStore.currentLocation;
		return this.get(
			`/prescription/pharmacy?name=${encodeURIComponent(
				name
			)}&lat=${encodeURIComponent(lat)}&lon=${encodeURIComponent(lon)}`
		);
	}

	//* Prescription API END ===============================================

	//* Provider Management API START ===============================================

	async providerMgmtQuickSearch(query) {
		//default distance to 50?
		let { lat, lon } = appStore.currentLocation;
		return this.get(
			`/search/providers/${encodeURIComponent(
				query
			)}?radius=${encodeURIComponent(75)}&lat=${encodeURIComponent(
				lat
			)}&lon=${encodeURIComponent(lon)}&ismgmt=${true}`
		);
	}

	async verifyProvider(provider) {
		return this.post('/providers/verify', provider);
	}

	async getProviderScores(npi, orgId) {
		return this.get(`/search/scores?npi=${npi}&orgId=${orgId}`);
	}

	async providerMgmtProvidersByActivity(type, prevNPIs) {
		return this.post('/providers/activitySearch', { type, prevNPIs });
	}

	async providerMgmtSearch(filters, page) {
		let { lat, lon } = appStore.currentLocation;
		filters.lat = lat;
		filters.lon = lon;
		filters.page = page;
		return this.post('/providers/filtersearch', filters);
	}

	async getMgmtDetails() {
		return await this.get('/providers/config');
	}

	//* Provider Management API END ===============================================

	async submitAppointmentRequest(AppointmentDTO) {
		analytics.track('requested_appointment');
		return this.post('/appointment/request', AppointmentDTO);
	}

	async getMyAppointments() {
		return this.get('/appointment/all');
	}

	async confirmAppt(appt) {
		analytics.track('confirmed_appointment');
		return this.get(`/appointment/confirm/${encodeURIComponent(appt._id)}`);
	}

	async cancelAppt(appt, reason) {
		analytics.track('canceled_appointment');
		return this.get(
			`/appointment/cancel/${encodeURIComponent(
				appt._id
			)}?reason=${encodeURIComponent(reason)}`
		);
	}

	async changeAppointmentLocation(apptId, npi, locationId) {
		return this.get(
			`/appointment/changeprovider/${encodeURIComponent(
				apptId
			)}?npi=${encodeURIComponent(npi)}&location=${encodeURIComponent(
				locationId
			)}`
		);
	}

	async affiliateLanding(code) {
		return this.get(`/user/affiliate-landing/${encodeURIComponent(code)}`);
	}

	//* ADMIN API START ========================================

	async isAdmin() {
		return this.get(`/admin/verify`);
	}

	async getAppointments() {
		return this.get(`/admin/appointments`);
	}

	async getUserData(id) {
		return this.get(`/admin/user/${id}`);
	}

	async scheduleAppointment(appt, date) {
		return this.post(`/admin/appointments/submit`, {
			date: date,
			id: appt._id
		});
	}

	async consumerTransactions(id) {
		return this.get(`/admin/transactions/${id}`);
	}

	async consumerCallLog(fId) {
		return this.get(`/admin/calllog/${fId}`);
	}

	async issueCareCredit(consumer, credit, type, issuedBy, notes) {
		return this.post(`/admin/issue-credit`, {
			consumer,
			credit,
			type,
			issuedBy,
			notes
		});
	}

	async getAdmins() {
		return this.get(`/admin/list`);
	}

	async creditHistory(fId) {
		return this.get(`/admin/credit-history/${fId}`);
	}

	async cancelAppointment(appt) {
		return this.post(`/admin/appointments/cancel`, {
			id: appt._id,
			reason: 'Admin Canceled Meeting' //TODO get reason from admin page
		});
	}

	async getReportConfigurations(id, body) {
		return this.post(`/admin/reporting/report-configs/${id}`, body);
	}

	async getReportData(id, body) {
		return this.post(`/admin/reporting/report-data/${id}`, body);
	}

	async getConsumerTokenSummary() {
		return this.get('/admin/reporting/consumer-token-summary');
	}

	async getOrgsSummary() {
		return this.get('/admin/reporting/org-summary');
	}

	async getUserCountByGroup(org) {
		const queryString = org ? `org=${org}` : 'org=all';
		return this.get(`/admin/reporting/user-count-by-group?${queryString}`);
	}

	async getDailyStats(date) {
		return this.post('/admin/reporting/daily-stats', { today: date });
	}

	async getWeeklyStats(date) {
		return this.post('/admin/reporting/weekly-stats', { today: date });
	}

	async getMonthlyStats(date) {
		return this.post('/admin/reporting/monthly-stats', { today: date });
	}

	async getOrganizations() {
		return this.get('/admin/organizations');
	}

	async getNetworks() {
		return this.get('/admin/networks');
	}

	async getGroups() {
		return this.get('/admin/groups');
	}

	async getMembers(filters) {
		return this.get(
			`/admin/members?filters=${encodeURIComponent(JSON.stringify(filters))}`
		);
	}

	async getGroupMembers(groupUid, filters) {
		return this.get(
			`/admin/group/${encodeURIComponent(
				groupUid
			)}/members?filters=${encodeURIComponent(JSON.stringify(filters))}`
		);
	}

	async searchMembers(searchData) {
		return this.post(`/admin/search-members/`, searchData);
	}

	async smsUserPasswordReset(id, email) {
		return this.get(`/admin/user/smsPasswordReset/${id}/${email}`);
	}

	async emailUserPasswordReset(id, email) {
		return this.get(`/admin/user/emailPasswordReset/${id}/${email}`);
	}

	async updateUser(id, data) {
		return this.post(`/admin/user/${id}`, data);
	}

	async getAccumulators() {
		return this.get('/user/accumulators');
	}

	async getAccumulatorDetails() {
		return this.get('/user/accumulator-details');
	}

	async getExpressAccumulators(integration) {
		return this.get(`/user/accumulators/${integration}`);
	}

	async getExpressAccumulatorsDemo(integration, memberHash) {
		return this.get(`/user/accumulators/demo/${integration}/${memberHash}`);
	}

	async getExpressDependentAccumulators(integration, dependent) {
		return this.get(`/user/accumulators/${integration}/${dependent}`);
	}

	async getExpressDependentAccumulatorsDemo(
		integration,
		dependent,
		memberHash
	) {
		return this.get(
			`/user/accumulators/demo/${integration}/${dependent}/${memberHash}`
		);
	}

	async getUserNotes(id) {
		return this.get(`/user/userNotes/${id}`);
	}

	async createUserNote(id, data) {
		return this.post(`/user/userNote/${id}`, data);
	}

	async getEmailVerificationStatus(id) {
		return this.get(`/admin/user/${id}/emailVerification`);
	}

	async sendPushNotifications(title, body, devices) {
		const req = {
			message: body,
			title: title,
			devices: devices
		};
		return this.post(`/admin/messaging/push-notifications`, req);
	}

	async sendTextMessages(body, numbers) {
		const req = {
			message: body,
			numbers: numbers
		};
		return this.post(`/admin/messaging/text-notifications`, req);
	}

	async sendEmailMessages(title, body, emails) {
		const req = {
			emails: emails,
			subject: title,
			text: body
		};
		return this.post(`/admin/messaging/email-notifications`, req);
	}

	async getFeedbacks() {
		return this.get(`/admin/feedbacks`);
	}

	async updateFeedbackStatus(data) {
		return this.post(`/admin/feedbacks/update-status`, data);
	}

	async getAffiliate(id) {
		return this.get(`/admin/affiliate/${id}`);
	}

	async getAffiliates() {
		return this.get(`/admin/affiliates`);
	}

	async createAffiliate(data) {
		return this.post(`/admin/affiliates/create`, data);
	}

	async saveAffiliate(data) {
		return this.post(`/admin/affiliates/save`, data);
	}

	//* ADMIN API END ========================================

	//* USER BILLING API ========================================

	async addUserPaymentMethod(paymentMethodRequest) {
		const response = await this.post(
			'/user/billing/paymentMethod',
			paymentMethodRequest
		);
		return response;
	}

	async deleteUserPaymentMethod(paymentMethodId) {
		const response = await this.get(
			'/user/billing/paymentMethod/delete?id=' + paymentMethodId
		);
		return response;
	}

	async getUserPaymentMethods() {
		return await this.get('/user/billing/paymentMethod/all');
	}

	async getPaymentIntent(intentRequest) {
		return await this.post('/user/billing/paymentIntent', intentRequest);
	}

	async getUserTransactionHistory() {
		return await this.get('/user/billing/transaction/all');
	}

	async getTransaction(id) {
		return await this.get('/user/billing/transaction/' + id);
	}
	async creditUserToken(addTokenRequest) {
		return await this.post('/user/billing/tokens', addTokenRequest);
	}

	async initConsumerTelehealthCall() {
		return await this.get('/user/billing/telehealth/call');
	}

	async fetchUserCallHistory() {
		return await this.get('/user/billing/telehealth/events');
	}

	async createSubscription(subscriptionRequest) {
		return await this.post(
			'/user/billing/subscription/create',
			subscriptionRequest
		);
	}

	async enableVPC() {
		return await this.post('/user/billing/subscription/enable');
	}

	async getSubscriptions() {
		return await this.get('/user/billing/subscriptions');
	}

	async cancelSubscription(subscriptionId) {
		return await this.post(
			`/user/billing/subscription/cancel/${subscriptionId}`
		);
	}

	//* USER BILLING API END ====================================

	//* VOICE API BEGIN ========================================

	async getCallSequence(id) {
		return await this.get('/voice/' + id);
	}

	//* VOICE API END ==========================================

	//* USER TOKEN API BEGIN ========================================

	async getUserTokens() {
		return await this.get('/user/tokens/list');
	}

	//* USER TOKEN API END ========================================

	//* PRODUCTS API BEGIN ============================
	async getProducts() {
		return await this.get('/products');
	}

	//* PRODUCTS API END ============================

	//=========CAREVALET CLOUD API END ==================================================
}
export default new CloudAPI();

// @ts-ignore
window.cloudApi = new CloudAPI();
