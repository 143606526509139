import React, { Component } from 'react';
import { autoBind } from '@/utils/GeneralUtils';
import { Text, Transformer } from 'react-konva';
import { observer, observable } from '@/utils/State';
import CanvasFactory from '@/factories/CanvasFactory';

@observer
export default class TextNode extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		const { canDrag, onTransformEnd, selectText, onTextNodeTransform, onDragEnd, onHighlight } = CanvasFactory.getActiveService();
		return (
			<>
				<Text
					{...this.props}
					verticalAlign="middle"
					draggable={canDrag()}
					texbaseline="middle"
					onTransformEnd={onTransformEnd}
					onDragEnd={onDragEnd}
					onTransform={onTextNodeTransform}
					onDblClick={this.props.type === 'text' && selectText}
					onDragMove={onHighlight}
				></Text>
			</>
		);
	}
}
