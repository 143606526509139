import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import FileDrop from '@/components/_EXPRESS/file-drop/FileDrop';
import './new-document-popup.scss';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import DocumentService from '@/services/DocumentService';
import { DOC_ICON, DOC_TYPES } from '../document-constants';

const DOCUMENT_TYPES = { sob: 'Statement of Benefits', other: 'Other' };

@observer
export default class NewDocumentPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			newDocumentData: {
				documentName: '',
				documentType: '',
				document: {}
			},
			valid: false
		});
		autoBind(this);
	}

	onClose() {
		this.resetData();
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	resetData() {
		this.data.newDocumentData = {
			documentName: '',
			documentType: '',
			document: {}
		};
	}

	handleSubmit() {
		this.$f7.dialog.preloader('Saving...');
		DocumentService.uploadDocument(this.data.newDocumentData)
			.then((resp) => {
				this.onClose();
				this.$f7.dialog.close();
			})
			.catch((e) => {
				console.log(e);
				this.$f7.dialog.close();
			});
	}

	handleFileError(e) {
		console.log(e);
		//TODO handle error
	}

	onReadFile(e) {
		this.data.newDocumentData.document.data = e.target.result;
	}

	handleDelete(e) {
		this.data.newDocumentData.document = {};
	}

	handleDocumentUploaded(e) {
		this.data.newDocumentData.document.name = e.name;
		this.data.newDocumentData.document.type = e.type;
		this.data.newDocumentData.document.isImage = false;
		if (e.type.match(/image\/+/g)) {
			let fileReader = new FileReader();
			fileReader.onloadend = (e) => this.onReadFile(e);
			this.data.newDocumentData.document.isImage = true;
			fileReader.readAsDataURL(e);
		} else {
			this.data.newDocumentData.document.data = e;
		}
	}

	isValid() {
		return this.data.newDocumentData.documentName.length > 4 &&
		this.data.newDocumentData.documentType.length > 0 &&
		this.data.newDocumentData.document.data
	}


	//TODO We need to incorporate the image type to the form builder
	render() {
		const { opened } = this.props;
		return (
			<>
				<FormPopup
					opened={opened}
					title="New Document"
					buttonText="Save"
					formConfig={newDocumentConfig}
					formData={this.data.newDocumentData}
					onSubmit={this.handleSubmit}
					onClose={this.onClose}
					additionalContent={
						<DocumentDrop
							document={this.data.newDocumentData.document}
							onError={this.handleFileError}
							onComplete={this.handleDocumentUploaded}
							onDelete={this.handleDelete}
						/>
					}
				/>
			</>
		);
	}
}

const DocumentDrop = observer((props) => {
	const { document, onError, onComplete, onDelete } = props;
	if (document.name === undefined) {
		return <FileDrop onError={onError} onComplete={onComplete} multi={false} accept={Object.keys(DOC_TYPES).join(',')} />;
	} else {
		return <NewDocumentDetail document={document} onDelete={onDelete} />;
	}
});

const NewDocumentDetail = observer((props) => {
	const { document, onDelete } = props;
	return (
		<div className="new-document-detail hbox vcenter">
			<div className="icon vbox vcenter hcenter">
				<i className={`fa ${DOC_ICON[document.type]}`}></i>
			</div>
			<div className="document-description vbox grow-1">
				<div className="name">{document.name}</div>
				<div className="type">{DOC_TYPES[document.type]}</div>
			</div>
			<a className="delete" onClick={onDelete}>
				Delete
			</a>
		</div>
	);
});

const newDocumentConfig = {
	documentName: {
		label: 'Document Name',
		type: 'text',
		placeholder: 'Enter name for document',
		validator: {
			type: 'length',
			value: 4
		}
	},
	row1: {
		documentType: {
			label: 'Choose the type of document',
			type: 'dropdown',
			className: 'dropdown-form-input',
			icon: 'far fa-file',
			listItems: DOCUMENT_TYPES,
			validator: {
				type: 'length',
				value: 1
			}
		}
	}
};

