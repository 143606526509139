import './refund-form.scss';
import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Link, ListInput, List, Sheet, Toolbar } from 'framework7-react';
import userStore from '../../stores/UserStore';
import { getCmpText, getLocalizedDate } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import UserService from '../../services/UserService';

@observer
export default class RefundForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			message: '',
			show: this.props.show,
			valid: false,
			closing: false,
			manualReason: null
		});
		this.text = getCmpText('RefundForm', {
			otherLabel: 'Reason for refund:',
			cancelRefundFormBtn: 'Cancel',
			title: 'Refund Request',
			inputHelper: 'Max 250 characters',
			directions: 'Please choose an option that best matches the reason for your refund',
			success: 'We have received your request, and will reach out to you soon!',
			submitCta: 'Request Refund',
			noCallBackCv: 'I never received a call from CareValet',
			noCallbackTeledoc: 'I never received a call back from a provider',
			callDropped: 'My call was dropped',
			notListed: 'I do not see my reason listed above',
			unableToProvide: 'Provider was unable to treat me via telehealth',
			goBackToList: 'Take me back to the list',
			minimumText: 'Minimum',
			maximumText: 'Maximum',
			characters: 'characters'
		});
		this.refundReasons = [
			{
				key: 'NO_CALL_BACK_CV',
				textVar: 'noCallBackCv'
			},
			{
				key: 'NO_CALL_BACK_DOCTOR',
				textVar: 'noCallbackTeledoc'
			},
			{
				key: 'CALL_DROPPED',
				textVar: 'callDropped'
			},
			{
				key: 'PROVIDER_UNABLE_TO_TREAT',
				textVar: 'unableToProvide'
			},
			{
				key: 'NOT_LISTED',
				textVar: 'notListed',
				requiredDetail: {
					min: 120,
					max: 250
				}
			}
		];
		autoBind(this);
	}
	updateMessage(evt) {
		const value = evt.currentTarget.value;
		this.data.message = value;
		this.validateForm();
	}

	resetData() {
		this.data.manualReason = null;
		this.data.message = '';
	}
	validateForm() {
		if (this.data.manualReason) {
			const min = _.get(this.data.manualReason, 'requiredDetail.min', 0);
			const max = _.get(this.data.manualReason, 'requiredDetail.max', 250);
			const hasMessage = this.data.message.length > min;
			const notTooLong = this.data.message.length < max;
			let isValid = hasMessage && notTooLong;
			this.data.valid = isValid;
			console.log(isValid);
			return isValid;
		} else {
			return true;
		}
	}
	submitRefund() {
		const isValid = this.validateForm();
		console.log(isValid);
		if (!isValid) return;
		const requestData = {
			message: this.data.message,
			type: 'REFUND_TOKEN',
			callId: _.get(this.props.call, 'callSid', ''),
			userId: userStore.userData.user._id
		};

		this.$f7.dialog.preloader();
		UserService.submitFeedback(requestData)
			.then(() => {
				this.$f7.dialog.close();
				let toast = this.$f7.toast.create({
					position: 'center',
					closeTimeout: 2000,
					icon: `<i class="fad fa-check large-icon-toast" ></i>`,
					text: this.text.success
				});
				toast.open();
				this.closeRefundForm();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				this.$f7.dialog.alert(_.get(JSON.parse(err.response), 'message', 'An error occured, please try again.'));
			});
	}
	clearMessage() {
		this.data.message = '';
		this.validateForm();
	}

	getAnimation() {
		return this.props.opened && this.data.closing ? 'slideOutDown' : 'slideInUp';
	}

	closeRefundForm() {
		const { onCancel } = this.props;
		this.data.closing = true;
		setTimeout(() => {
			this.data.closing = false;
			this.resetData();
			onCancel();
		}, 350);
	}

	onSelectReason(reason) {
		if (reason.requiredDetail) {
			this.data.message = '';
			this.data.manualReason = reason;
		} else {
			this.data.message = reason.key;
			this.submitRefund();
		}
	}

	renderRefundOptions() {
		let options = [];
		if (!this.data.manualReason) {
			this.refundReasons.forEach((option, key) => {
				options.push(
					<Button className="neubtn option-btn" key={key} onClick={() => this.onSelectReason(option)}>
						<span>{this.text[option.textVar]}</span>
						<i className="fa fa-chevron-right"></i>
					</Button>
				);
			});
		}

		return options;
	}

	renderManulReason() {
		if (this.data.manualReason) {
			return (
				<div>
					<form>
						<Button className="btn alt" onClick={() => (this.data.manualReason = null)}>
							<i className="fa fa-arrow-left"></i>
							{this.text.goBackToList}
						</Button>
						<List className="feedback-form no-hairlines">
							<p className="required-text">
								*{this.text.minimumText} is {this.data.manualReason.requiredDetail.min} {this.text.characters}
							</p>
							<ListInput
								label={this.text.otherLabel}
								type="textarea"
								clearButton
								floatingLabel={true}
								className="md"
								name="feedbackMessage"
								value={this.data.message}
								onChange={this.updateMessage}
								onInputClear={this.clearMessage}
							/>
						</List>
					</form>

					<Button fill large className="round-btn purple" onClick={this.submitRefund} id="complete-button" disabled={!this.data.valid}>
						{this.text.submitCta}
					</Button>
				</div>
			);
		}
	}

	render() {
		const { opened, onCancel } = this.props;
		if (!this.props.opened) {
			return '';
		}
		return (
			<div className={`refund-popover ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<div className={`refund-panel animated ${this.getAnimation()}`}>
					<div className="panel-header hbox hright">
						{/* We are going to want to change how this section gets rendered and show / hide the header */}
						<div className="cancel-btn" onClick={this.closeRefundForm}>
							{this.text.cancelRefundFormBtn}
						</div>
					</div>
					<h2>{this.text.title}</h2>
					<p className="directions">{this.text.directions}</p>
					<div className="refund-container">
						{this.renderRefundOptions()}
						{this.renderManulReason()}
					</div>
				</div>
			</div>
		);
	}
}
