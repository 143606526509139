import React, { Component } from 'react';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';
import './edi-mapper.scss';
import { Button } from 'framework7-react';
const ExpressFields = [
	{
		key: 'relation',
		name: 'Relation',
		required: true,
		guess: ['INS02'],
		info: 'Individual Relationship Code, 18-Self, 01-Spouse, 19-Child, G8-Other'
	},
	{
		key: 'memberId',
		name: 'Member ID',
		required: true,
		guess: [`REF02:REF01['23']`, `REF02:REF01['0F']`],
		info: 'This field should match what shows on the members ID card as they will used this to claim their account. Sometimes referred to as subscriber number, client number, or member number.'
	},
	{
		key: 'personCode',
		name: 'Person Code',
		required: false,
		guess: [`REF02:REF01['QQ']`],
		info: 'If you utalize sequential codes on non primary insured members, this is the field to map them to, especially if you show these codes on the ID card.'
	},
	{
		key: 'groupId',
		name: 'Group ID / Number',
		required: true,
		guess: [`REF02:REF01['1L']`],
		info: 'This ID should directly map to the Group ID field on the Groups you create inside of the Express platform.'
	},
	{
		key: 'healthPlan',
		name: 'Healthplan ID',
		required: true,
		guess: ['HD04'],
		info: 'This ID should directly map to the Healthplan ID field on the Healthplans you create inside of the Express platform.'
	},
	{ key: 'firstName', name: 'Member First Name', required: true, guess: ['NM104'] },
	{ key: 'middleName', name: 'Member Middle Name', required: false, guess: ['NM105'] },
	{ key: 'lastName', name: 'Member Last Name', required: true, guess: ['NM103'] },
	{ key: 'ssn', name: 'SSN', required: false, guess: [`NM109:NM108['34']`] },
	{ key: 'email', name: 'Email', required: false, guess: [`PER06:PER05['EM']`, `PER04:PER03['EM']`] },
	{ key: 'phone', name: 'Mobile Phone', required: false, guess: [`PER04:PER03['TE']`] },
	{ key: 'street', name: 'Address 1', required: true, guess: ['N301'] },
	{ key: 'street2', name: 'Address 2', required: false, guess: ['N302'] },
	{ key: 'city', name: 'City', required: true, guess: ['N401'] },
	{ key: 'state', name: 'State', required: true, guess: ['N402'] },
	{ key: 'zipcode', name: 'Zip Code', required: true, guess: ['N403'] },
	{ key: 'gender', name: 'Gender', required: true, guess: ['DMG03'] },
	{ key: 'dob', name: 'Birth Date', required: true, guess: [`DMG02:DMG01['D8']`] },
	{
		key: 'coverageLevel',
		name: 'Coverage Level',
		required: true,
		guess: ['HD05'],
		info: 'Indicated weather the health coverage is for the employee, employee and spouse, family, etc.'
	},
	{
		key: 'startDate',
		name: 'Start Date',
		required: true,
		guess: [`DTP03:DTP01['348']`],
		info: 'The member will not have access to the CareValet platform until this date.'
	},
	{
		key: 'termDate',
		name: 'Term Date',
		required: false,
		guess: [`DTP03:DTP01['349']`],
		info: 'The Express platform processes terminations by absence on file uploads, but future updates will allow for you to also enable termination via this date field the way it works for manual data management.'
	}
];

@observer
export default class EDIMapper extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			mappedFields: []
		});
		autoBind(this);
	}

	componentDidMount() {
		this.$f7.dialog.close();
		//guess mapping up front
		const fields = [];
		try {
			_.forEach(ExpressFields, (f) => {
				const getMatch = (selectors) => {
					for (let index = 0; index < selectors.length; index++) {
						const res = _.find(this.props.fileElements, { selector: selectors[index] });
						if (res) {
							const elIndex = _.findIndex(this.props.fileElements, { selector: selectors[index] });
							return { element: _.cloneDeep(res), index: elIndex };
						}
					}
					return null;
				};
				let match = getMatch(f.guess);
				if (match) {
					fields.push({
						elementIndex: match.index,
						draggableId: `draggable-${match.index}`,
						fieldKey: f.key
					});
				}
			});
		} catch (err) {
			console.log('Unable to guess field mapping');
			console.log(err);
		}
		this.data.mappedFields = fields;
	}

	buildFieldSlots() {
		return ExpressFields.map((field) => {
			return (
				<div className="express-field hbox vcenter hright" key={`${field.key}-field`}>
					<div className="field-details hbox vcenter grow-1">
						{field.info ? (
							<i
								className="fad fa-info-circle"
								onClick={() => {
									this.$f7.dialog.alert(field.info, 'Field Information');
								}}
							></i>
						) : (
							<div className="spacer"></div>
						)}
						<div className="name grow-1">
							<div>
								{field.name}
								{field.required && <span>*</span>}
							</div>
							{field.required && <div className="required">*required</div>}
						</div>
						<i className="fad fa-long-arrow-alt-right"></i>
					</div>

					<Droppable droppableId={`droppablefield-${field.key}`}>
						{(provided, snapshot) => {
							let mapped = _.find(this.data.mappedFields, { fieldKey: field.key });
							return (
								<div
									className={`field-slot vbox vcenter hcenter ${snapshot.isDraggingOver ? 'dropping' : ''}`}
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{mapped && (
										<Draggable draggableId={`draggable-${mapped.fieldKey}`} index={0}>
											{(provided, snapshot) => {
												let element = this.props.fileElements[mapped.elementIndex];
												return (
													<div
														className="field-ctn"
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<div className="field-header">
															{!_.isEmpty(element.qualifierName) ? element.qualifierName : element.name}
														</div>
														<div className="selector">
															<span>Selector:</span>
															{element.selector}
														</div>
														<div className="sample ellipse">
															<span>Sample:</span>
															{element.samples[0]}
														</div>
														<div className="grip">
															<i className="fas fa-grip-horizontal"></i>
														</div>
													</div>
												);
											}}
										</Draggable>
									)}
									{provided.placeholder}
								</div>
							);
						}}
					</Droppable>
				</div>
			);
		});
	}

	buildUnmappedFields() {
		return (
			<Droppable droppableId="unmappedDroppable">
				{(provided, snapshot) => (
					<div
						className={`fields-container ${snapshot.isDraggingOver ? 'dropping' : ''}`}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{this.props.fileElements.map((element, i) => {
							let mapped = _.find(this.data.mappedFields, { elementIndex: i });
							if (mapped) {
								return (
									<Draggable draggableId={`draggable-${i}`} index={i} key={`file-field-element-${i}`}>
										{(provided, snapshot) => (
											<div
												className="empty-draggable"
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											></div>
										)}
									</Draggable>
								);
							}
							return (
								<Draggable draggableId={`draggable-${i}`} index={i} key={`file-field-element-${i}`}>
									{(provided, snapshot) => (
										<div className="field-ctn" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											<div className="field-header">{!_.isEmpty(element.qualifierName) ? element.qualifierName : element.name}</div>
											<div className="selector">
												<span>Selector:</span>
												{element.selector}
											</div>
											<div className="sample ellipse">
												<span>Sample:</span>
												{element.samples[0]}
											</div>
											<div className="grip">
												<i className="fas fa-grip-horizontal"></i>
											</div>
										</div>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		);
	}

	onDragEnd(result) {
		if (result.reason === 'DROP') {
			let destination = _.get(result, 'destination.droppableId', '');
			if (destination === 'unmappedDroppable') {
				console.log('I am moving from an existing droppable area to unmapped fields');
				let fieldKey = _.get(result, 'draggableId', '').split('-')[1];
				let index = _.findIndex(this.data.mappedFields, { fieldKey: fieldKey });
				if (this.data.mappedFields.length === 1) {
					this.data.mappedFields = [];
				} else if (index !== -1) {
					let currentFields = _.cloneDeep(this.data.mappedFields);
					currentFields.splice(index, 1);
					this.data.mappedFields = currentFields;
				}
			} else {
				let moving = _.get(result, 'source.droppableId', '').indexOf('droppablefield') >= 0;
				let destinationKey = _.get(result, 'destination.droppableId', '').split('-')[1];
				if (!_.isEmpty(destinationKey)) {
					let fields = _.cloneDeep(this.data.mappedFields);
					let existing = _.find(fields, { fieldKey: destinationKey });
					if (existing) {
						let index = _.findIndex(fields, { fieldKey: destinationKey });
						fields.splice(index, 1);
					}
					if (moving) {
						console.log('I am moving from an existing droppable area');
						let from = _.get(result, 'source.droppableId', '').split('-')[1];
						if (!_.isEmpty(from)) {
							console.log(`Moving from ${from}`);
							let existingIndex = _.findIndex(fields, { fieldKey: from });
							fields[existingIndex] = {
								elementIndex: fields[existingIndex] ? fields[existingIndex].elementIndex : 0,
								draggableId: result.draggableId,
								fieldKey: destinationKey
							};
						}
					} else {
						fields.push({
							elementIndex: _.get(result, 'source.index'),
							draggableId: result.draggableId,
							fieldKey: destinationKey
						});
					}
					this.data.mappedFields = fields;
				}
			}
		}
	}

	buildMappedMember() {
		let { mappedFields } = this.data;
		const getElementValue = (field) => {
			let mappedRec = _.find(mappedFields, { fieldKey: field.key });
			let elementValue = {
				key: field.key,
				label: field.name
			};
			if (mappedRec) {
				let ediField = _.cloneDeep(this.props.fileElements[mappedRec.elementIndex]);
				if (!_.isEmpty(ediField.samples[0])) {
					elementValue.value = <div className="mapped-value ellipse">{ediField.samples[0]}</div>;
				}

				if (!_.isEmpty(elementValue.value) && field.required && !ediField.containsDataForAllMembers) {
					let v = elementValue.value;
					elementValue.value = (
						<>
							{v}
							<div className="warning hbox vcenter">
								<i className="fad fa-exclamation-circle"></i>
								<div className="message">
									This is a required field for the Express platform. The currently mapped field does not contain a populated value for
									every record in the file. If this is the correct field mapping then please ensure that every record has the approaite
									value set in your file. Otherwise, this may not be the correct corresponding field.
								</div>
							</div>
						</>
					);
				}
				if (_.isEmpty(elementValue.value) && field.required) {
					elementValue.value = (
						<div className="warning hbox vcenter">
							<i className="fad fa-exclamation-circle"></i>
							<div className="message">
								This is a required field for the Express platform. The currently mapped field does not contain a populated value for
								every record in the file. If this is the correct field mapping then please ensure that every record has the approaite
								value set in your file. Otherwise, this may not be the correct corresponding field.
							</div>
						</div>
					);
				}
				if (_.isEmpty(elementValue.value) && !field.required) {
					elementValue.value = <div className="mapped-value ellipse">No Sample Data</div>;
				}
			}
			if (_.isEmpty(elementValue.value)) {
				elementValue.value = (
					<div className="empty hbox vcenter">
						<i className="fad fa-exclamation-triangle"></i>
						<div className="message">No field has been mapped to this value</div>
					</div>
				);
			}
			return elementValue;
		};

		return (
			<div className="mapped-records">
				{ExpressFields.map((field, index) => {
					let value = getElementValue(field);
					return (
						<div className="field" key={`mapped-field-${value.key}-${index}`}>
							<div className="label">{value.label}:</div>
							<div className="value">{value.value}</div>
						</div>
					);
				})}
			</div>
		);
	}

	onSaveMapping() {
		this.$f7.dialog.preloader('Saving Configuration');
		let config = {
			name: 'Default EDI Config',
			type: 'default',
			fieldMapping: {},
			fileElements: this.props.fileElements
		};
		_.forEach(ExpressFields, (f) => {
			let mapped = _.find(this.data.mappedFields, { fieldKey: f.key });
			if (mapped) {
				let element = this.props.fileElements[mapped.elementIndex];
				if (element) {
					config.fieldMapping[f.key] = element.selector;
				} else {
					config.fieldMapping[f.key] = null;
				}
			} else {
				config.fieldMapping[f.key] = null;
			}
		});
		ExpressAPI.saveEDIConfig(config)
			.then((res) => {
				this.$f7.dialog.close();
				this.props.onComplete(res);
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	isMappingValid() {
		let validMapping = true;
		_.forEach(ExpressFields, (f) => {
			if (f.required) {
				let match = _.find(this.data.mappedFields, { fieldKey: f.key });
				if (!match) {
					validMapping = false;
				}
			}
		});
		return validMapping;
	}

	render() {
		return (
			<div className="edi-mapper-page y-scroll">
				<div className="header-ctn hbox vcenter hright">
					<p className="description">
						In order to process your unique EDI 834 format, we need to create a one time mapping file to identify where the data that
						Express needs to function lives in your file format. Please drag and drop the fields & values from your file into the field
						slots below. We’ve set the defaults to our best guess but please ensure every fields is in it’s correct spot. If you don’t
						have a field in your file that we require, please include that field in a file and upload it again.
					</p>
					<div className="grow-1"></div>
					<Button large fill className="round-btn ios" onClick={this.onSaveMapping} disabled={!this.isMappingValid()}>
						<i className="fad fa-save"></i>
						Save Mappings
					</Button>
				</div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<div className="content-container hbox">
						<div className="express-fields">
							<div className="section-header">Express Fields</div>
							{this.buildFieldSlots()}
						</div>
						<div className="unmapped-fields">
							<div className="section-header">
								EDI File Fields <span>(Drag on top of the corresponding express fields)</span>
							</div>
							{this.buildUnmappedFields()}
						</div>
						<div className="sample-records">
							<div className="section-header">Sample Mapped Records</div>
							{this.buildMappedMember()}
						</div>
					</div>
				</DragDropContext>
			</div>
		);
	}
}
