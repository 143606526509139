import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { getCmpText } from '@/utils/Localization';
import { Preloader, Button } from 'framework7-react';
import { format } from 'date-fns';
@observer
export default class CallList extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('CallList', {
			cancelBtnTxt: 'Close',
			callLogHeaderTxt: 'Call Log',
			callLogSectionHeaderTxt: 'Latest Calls',
			deductionTxt: '1 Care Token'
		});
		autoBind(this);
	}

	renderCallHistory() {
		let { callHistory, onViewDetail } = this.props;
		let { deductionTxt } = this.text;
		let calls = [];
		callHistory.forEach((call, key) => {
			calls.push(
				<Button className="neubtn balance-btn hbox hleft call" key={key} onClick={() => onViewDetail(call.callSid)}>
					<div className="call-holder">
						<div className="details-container">
							<div className="date">{format(new Date(call.dateCreated), 'MMMM dd, yyyy')}</div>
							<div className="time">{format(new Date(call.dateCreated), 'ppp')}</div>
						</div>
						<div className="deduct">- {deductionTxt}</div>
					</div>
				</Button>
			);
		});

		return calls;
	}

	render() {
		let { cancelBtnTxt, callLogHeaderTxt, callLogSectionHeaderTxt } = this.text;
		let { onClose, loading } = this.props;
		return (
			<div className="page-wrapper">
				<div className="global-header-popup">
					<div className="header-ctn">
						<div className="back hbox vcenter" onClick={onClose}>
							{cancelBtnTxt}
						</div>
						<h1>{callLogHeaderTxt}</h1>
					</div>
				</div>
				<div className="section-header">
					<div className="title">{callLogSectionHeaderTxt}</div>
				</div>
				<div className="call-log-container">
					{loading && <Preloader></Preloader>}
					{!loading && this.renderCallHistory()}
				</div>
			</div>
		);
	}
}
