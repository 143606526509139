import imageCreateGroup1 from "@/assets/tutorials/tutorial-create-group/image-create-group-1.png";
import imageCreateGroup2 from "@/assets/tutorials/tutorial-create-group/image-create-group-2.png";
import imageCreateGroup3 from "@/assets/tutorials/tutorial-create-group/image-create-group-3.png";
import imageCreateGroup4 from "@/assets/tutorials/tutorial-create-group/image-create-group-4.png";
import imageCreateGroup5 from "@/assets/tutorials/tutorial-create-group/image-create-group-5.png";
import imageCreateGroup6 from "@/assets/tutorials/tutorial-create-group/image-create-group-6.png";
import imageCreateGroup7 from "@/assets/tutorials/tutorial-create-group/image-create-group-7.png";
import imageCreateGroup8 from "@/assets/tutorials/tutorial-create-group/image-create-group-8.png";
import imageCreateGroup9 from "@/assets/tutorials/tutorial-create-group/image-create-group-9.png";
import imageCreateGroup10 from "@/assets/tutorials/tutorial-create-group/image-create-group-10.png";
import imageCreateGroup11 from "@/assets/tutorials/tutorial-create-group/image-create-group-11.png";
import imageCreateGroup12 from "@/assets/tutorials/tutorial-create-group/image-create-group-12.png";
import imageCreateGroup13 from "@/assets/tutorials/tutorial-create-group/image-create-group-13.png";
import imageCreateGroup14 from "@/assets/tutorials/tutorial-create-group/image-create-group-14.png";
import imageCreateGroup15 from "@/assets/tutorials/tutorial-create-group/image-create-group-15.png";
import imageCreateGroup16 from "@/assets/tutorials/tutorial-create-group/image-create-group-16.png";
import imageCreateHealthplan1 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-1.png";
import imageCreateHealthplan2 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-2.png";
import imageCreateHealthplan3 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-3.png";
import imageCreateHealthplan4 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-4.png";
import imageCreateHealthplan5 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-5.png";
import imageCreateHealthplan6 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-6.png";
import imageCreateHealthplan7 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-7.png";
import imageCreateHealthplan8 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-8.png";
import imageCreateHealthplan9 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-9.png";
import imageCreateHealthplan10 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-10.png";
import imageCreateHealthplan11 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-11.png";
import imageCreateHealthplan12 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-12.png";
import imageCreateHealthplan13 from "@/assets/tutorials/tutorial-create-healthplan/image-create-healthplans-13.png";
import imageCreateSingleNetwork1 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-1.png";
import imageCreateSingleNetwork2 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-2.png";
import imageCreateSingleNetwork3 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-3.png";
import imageCreateSingleNetwork4 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-4.png";
import imageCreateSingleNetwork5 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-5.png";
import imageCreateSingleNetwork6 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-6.png";
import imageCreateSingleNetwork7 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-7.png";
import imageCreateSingleNetwork8 from "@/assets/tutorials/tutorial-create-single-network/image-create-single-network-8.png";
import imageCreateTieredNetwork1 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-1.png";
import imageCreateTieredNetwork2 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-2.png";
import imageCreateTieredNetwork3 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-3.png";
import imageCreateTieredNetwork4 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-4.png";
import imageCreateTieredNetwork5 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-5.png";
import imageCreateTieredNetwork6 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-6.png";
import imageCreateTieredNetwork7 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-7.png";
import imageCreateTieredNetwork8 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-8.png";
import imageCreateTieredNetwork9 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-9.png";
import imageCreateTieredNetwork10 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-10.png";
import imageCreateTieredNetwork11 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-11.png";
import imageCreateTieredNetwork12 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-12.png";
import imageCreateTieredNetwork13 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-13.png";
import imageCreateTieredNetwork14 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-14.png";
import imageCreateTieredNetwork15 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-15.png";
import imageCreateTieredNetwork16 from "@/assets/tutorials/tutorial-create-tiered-network/image-create-tiered-network-16.png";
import imageCreateOpenNetwork1 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-1.png";
import imageCreateOpenNetwork2 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-2.png";
import imageCreateOpenNetwork3 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-3.png";
import imageCreateOpenNetwork4 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-4.png";
import imageCreateOpenNetwork5 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-5.png";
import imageCreateOpenNetwork6 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-6.png";
import imageCreateOpenNetwork7 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-7.png";
import imageCreateOpenNetwork8 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-8.png";
import imageCreateOpenNetwork9 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-9.png";
import imageCreateOpenNetwork10 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-10.png";
import imageCreateOpenNetwork11 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-11.png";
import imageCreateOpenNetwork12 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-12.png";
import imageCreateOpenNetwork13 from "@/assets/tutorials/tutorial-create-open-network/image-create-open-network-13.png";
import imageCreateMember1 from "@/assets/tutorials/tutorial-create-member/image-create-member-1.png";
import imageCreateMember2 from "@/assets/tutorials/tutorial-create-member/image-create-member-2.png";
import imageCreateMember3 from "@/assets/tutorials/tutorial-create-member/image-create-member-3.png";
import imageCreateMember4 from "@/assets/tutorials/tutorial-create-member/image-create-member-4.png";
import imageCreateMember5 from "@/assets/tutorials/tutorial-create-member/image-create-member-5.png";
import imageCreateMember6 from "@/assets/tutorials/tutorial-create-member/image-create-member-6.png";
import imageCreateMember7 from "@/assets/tutorials/tutorial-create-member/image-create-member-7.png";
import imageCreateMember8 from "@/assets/tutorials/tutorial-create-member/image-create-member-8.png";
import imageCreateMember9 from "@/assets/tutorials/tutorial-create-member/image-create-member-9.png";
import imageCreateMember10 from "@/assets/tutorials/tutorial-create-member/image-create-member-10.png";
import imageCreateMember11 from "@/assets/tutorials/tutorial-create-member/image-create-member-11.png";
import imageCreateMember12 from "@/assets/tutorials/tutorial-create-member/image-create-member-12.png";
import imageCreateMember13 from "@/assets/tutorials/tutorial-create-member/image-create-member-13.png";
import imageCreateMember14 from "@/assets/tutorials/tutorial-create-member/image-create-member-14.png";
import imageCreateMember15 from "@/assets/tutorials/tutorial-create-member/image-create-member-15.png";
import imageCreateMember16 from "@/assets/tutorials/tutorial-create-member/image-create-member-16.png";
import imageCreateMember17 from "@/assets/tutorials/tutorial-create-member/image-create-member-17.png";
import imageCreateMember18 from "@/assets/tutorials/tutorial-create-member/image-create-member-18.png";
import imageViewGroupDetails1 from "@/assets/tutorials/tutorial-view-group-details/image-view-group-details-1.png";
import imageViewGroupDetails2 from "@/assets/tutorials/tutorial-view-group-details/image-view-group-details-2.png";
import imageViewGroupDetails3 from "@/assets/tutorials/tutorial-view-group-details/image-view-group-details-3.png";
import imageEditGroupDetails1 from "@/assets/tutorials/tutorial-edit-group-details/image-edit-group-details-1.png";
import imageEditGroupDetails2 from "@/assets/tutorials/tutorial-edit-group-details/image-edit-group-details-2.png";
import imageEditGroupDetails3 from "@/assets/tutorials/tutorial-edit-group-details/image-edit-group-details-3.png";
import imageEditGroupDetails4 from "@/assets/tutorials/tutorial-edit-group-details/image-edit-group-details-4.png";
import imageEditGroupDetails5 from "@/assets/tutorials/tutorial-edit-group-details/image-edit-group-details-5.png";
import imageImportMemberData1 from "@/assets/tutorials/tutorial-import-member-data/image-import-member-data-1.png";
import imageImportMemberData2 from "@/assets/tutorials/tutorial-import-member-data/image-import-member-data-2.png";
import imageImportMemberData3 from "@/assets/tutorials/tutorial-import-member-data/image-import-member-data-3.png";
import imageImportMemberData4 from "@/assets/tutorials/tutorial-import-member-data/image-import-member-data-4.png";
import imageImportMemberData5 from "@/assets/tutorials/tutorial-import-member-data/image-import-member-data-5.png";
import imageImportMemberData6 from "@/assets/tutorials/tutorial-import-member-data/image-import-member-data-6.png";
import imageInputMemberData1 from "@/assets/tutorials/tutorial-input-member-data/image-input-member-data-1.png";
import imageInputMemberData2 from "@/assets/tutorials/tutorial-input-member-data/image-input-member-data-2.png";
import imageInputMemberData3 from "@/assets/tutorials/tutorial-input-member-data/image-input-member-data-3.png";
import imageInputMemberData4 from "@/assets/tutorials/tutorial-input-member-data/image-input-member-data-4.png";
import imageInputMemberData5 from "@/assets/tutorials/tutorial-input-member-data/image-input-member-data-5.png";
import imageInputMemberData6 from "@/assets/tutorials/tutorial-input-member-data/image-input-member-data-6.png";					
import imageManageGroupAppConfig1 from "@/assets/tutorials/tutorial-manage-group-app-config/image-manage-group-app-config-1.png";
import imageManageGroupAppConfig2 from "@/assets/tutorials/tutorial-manage-group-app-config/image-manage-group-app-config-2.png";
import imageManageGroupAppConfig3 from "@/assets/tutorials/tutorial-manage-group-app-config/image-manage-group-app-config-3.png";
import imageViewGroupMembers1 from "@/assets/tutorials/tutorial-view-group-members/image-view-group-members-1.png";
import imageViewGroupMembers2 from "@/assets/tutorials/tutorial-view-group-members/image-view-group-members-2.png";
import imageViewGroupMembers3 from "@/assets/tutorials/tutorial-view-group-members/image-view-group-members-3.png";
import imageChangeHealthplanNetworkGroupsPage1 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-1.png";
import imageChangeHealthplanNetworkGroupsPage2 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-2.png";
import imageChangeHealthplanNetworkGroupsPage3 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-3.png";
import imageChangeHealthplanNetworkGroupsPage4 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-4.png";
import imageChangeHealthplanNetworkGroupsPage5 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-5.png";
import imageChangeHealthplanNetworkGroupsPage6 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-6.png";
import imageChangeHealthplanNetworkGroupsPage7 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-7.png";
import imageChangeHealthplanNetworkGroupsPage8 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-8.png";
import imageChangeHealthplanNetworkGroupsPage9 from "@/assets/tutorials/tutorial-change-healthplan-network-groups-page/image-change-healthplan-network-groups-page-9.png";
import imageViewIdCardHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-view-id-card-healthplan-groups-page/image-view-id-card-healthplan-groups-page-1.png";
import imageViewIdCardHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-view-id-card-healthplan-groups-page/image-view-id-card-healthplan-groups-page-2.png";
import imageViewIdCardHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-view-id-card-healthplan-groups-page/image-view-id-card-healthplan-groups-page-3.png";
import imageViewIdCardHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-view-id-card-healthplan-groups-page/image-view-id-card-healthplan-groups-page-4.png";
import imageViewIdCardHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-view-id-card-healthplan-groups-page/image-view-id-card-healthplan-groups-page-5.png";					
import imageEditBackIdHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-edit-back-id-healthplan-groups-page/image-edit-back-id-healthplan-groups-page-1.png";
import imageEditBackIdHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-edit-back-id-healthplan-groups-page/image-edit-back-id-healthplan-groups-page-2.png";
import imageEditBackIdHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-edit-back-id-healthplan-groups-page/image-edit-back-id-healthplan-groups-page-3.png";
import imageEditBackIdHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-edit-back-id-healthplan-groups-page/image-edit-back-id-healthplan-groups-page-4.png";
import imageEditBackIdHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-edit-back-id-healthplan-groups-page/image-edit-back-id-healthplan-groups-page-5.png";
import imageEditBackIdHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-edit-back-id-healthplan-groups-page/image-edit-back-id-healthplan-groups-page-6.png";					
import imageEditFrontIdHealthPlanGroupsPage1 from "@/assets/tutorials/tutorial-edit-front-id-card-healthplan-groups-page/image-edit-front-id-healthplan-groups-page-1.png";
import imageEditFrontIdHealthPlanGroupsPage2 from "@/assets/tutorials/tutorial-edit-front-id-card-healthplan-groups-page/image-edit-front-id-healthplan-groups-page-2.png";
import imageEditFrontIdHealthPlanGroupsPage3 from "@/assets/tutorials/tutorial-edit-front-id-card-healthplan-groups-page/image-edit-front-id-healthplan-groups-page-3.png";
import imageEditFrontIdHealthPlanGroupsPage4 from "@/assets/tutorials/tutorial-edit-front-id-card-healthplan-groups-page/image-edit-front-id-healthplan-groups-page-4.png";
import imageEditFrontIdHealthPlanGroupsPage5 from "@/assets/tutorials/tutorial-edit-front-id-card-healthplan-groups-page/image-edit-front-id-healthplan-groups-page-5.png";
import imageEditFrontIdHealthPlanGroupsPage6 from "@/assets/tutorials/tutorial-edit-front-id-card-healthplan-groups-page/image-edit-front-id-healthplan-groups-page-6.png";
import imageSwapIdHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-1.png";
import imageSwapIdHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-2.png";
import imageSwapIdHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-3.png";
import imageSwapIdHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-4.png";
import imageSwapIdHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-5.png";
import imageSwapIdHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-6.png";
import imageSwapIdHealthplanGroupsPage7 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-7.png";
import imageSwapIdHealthplanGroupsPage8 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-8.png";
import imageSwapIdHealthplanGroupsPage9 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-9.png";
import imageSwapIdHealthplanGroupsPage10 from "@/assets/tutorials/tutorial-swap-id-healthplan-groups-page/image-swap-id-healthplan-groups-page-10.png";
import imageAddFirstDocToOrg1 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-1.png";
import imageAddFirstDocToOrg2 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-2.png";
import imageAddFirstDocToOrg3 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-3.png";
import imageAddFirstDocToOrg4 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-4.png";
import imageAddFirstDocToOrg5 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-5.png";
import imageAddFirstDocToOrg6 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-6.png";
import imageAddFirstDocToOrg7 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-7.png";
import imageAddFirstDocToOrg8 from "@/assets/tutorials/tutorial-add-first-doc-to-org/image-add-first-doc-to-org-8.png";
import imageAddDocToHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-add-doc-to-healthplan-groups-page/image-add-doc-to-healthplan-groups-page-1.png";
import imageAddDocToHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-add-doc-to-healthplan-groups-page/image-add-doc-to-healthplan-groups-page-2.png";
import imageAddDocToHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-add-doc-to-healthplan-groups-page/image-add-doc-to-healthplan-groups-page-3.png";
import imageAddDocToHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-add-doc-to-healthplan-groups-page/image-add-doc-to-healthplan-groups-page-4.png";
import imageAddDocToHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-add-doc-to-healthplan-groups-page/image-add-doc-to-healthplan-groups-page-5.png";
import imageAddDocToHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-add-doc-to-healthplan-groups-page/image-add-doc-to-healthplan-groups-page-6.png";
import imageViewDocHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-view-doc-healthplan-groups-page/image-view-doc-healthplan-groups-page-1.png";
import imageViewDocHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-view-doc-healthplan-groups-page/image-view-doc-healthplan-groups-page-2.png";
import imageViewDocHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-view-doc-healthplan-groups-page/image-view-doc-healthplan-groups-page-3.png";
import imageViewDocHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-view-doc-healthplan-groups-page/image-view-doc-healthplan-groups-page-4.png";
import imageViewDocHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-view-doc-healthplan-groups-page/image-view-doc-healthplan-groups-page-5.png";
import imageRemoveDocHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-remove-doc-healthplan-groups-page/image-remove-doc-healthplan-groups-page-1.png";
import imageRemoveDocHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-remove-doc-healthplan-groups-page/image-remove-doc-healthplan-groups-page-2.png";
import imageRemoveDocHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-remove-doc-healthplan-groups-page/image-remove-doc-healthplan-groups-page-3.png";
import imageRemoveDocHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-remove-doc-healthplan-groups-page/image-remove-doc-healthplan-groups-page-4.png";
import imageRemoveDocHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-remove-doc-healthplan-groups-page/image-remove-doc-healthplan-groups-page-5.png";
import imageRemoveDocHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-remove-doc-healthplan-groups-page/image-remove-doc-healthplan-groups-page-6.png";
import imageViewDocAttachedToHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan-groups-page/image-view-doc-attached-to-healthplan-groups-page-1.png";
import imageViewDocAttachedToHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan-groups-page/image-view-doc-attached-to-healthplan-groups-page-2.png";
import imageViewDocAttachedToHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan-groups-page/image-view-doc-attached-to-healthplan-groups-page-3.png";
import imageViewDocAttachedToHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan-groups-page/image-view-doc-attached-to-healthplan-groups-page-4.png";
import imageViewDocAttachedToHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan-groups-page/image-view-doc-attached-to-healthplan-groups-page-5.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-1.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-2.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-3.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-4.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-5.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-6.png";
import imageSearchWhenAddingDocToHealthplanGroupsPage7 from "@/assets/tutorials/tutorial-search-when-adding-doc-to-healthplan-groups-page/image-search-when-adding-doc-to-healthplan-groups-page-7.png";
import imageGettingToAppConfigHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-getting-to-app-config-healthplan-groups-page/image-getting-to-app-config-healthplan-groups-page-1.png";
import imageGettingToAppConfigHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-getting-to-app-config-healthplan-groups-page/image-getting-to-app-config-healthplan-groups-page-2.png";
import imageGettingToAppConfigHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-getting-to-app-config-healthplan-groups-page/image-getting-to-app-config-healthplan-groups-page-3.png";
import imageGettingToAppConfigHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-getting-to-app-config-healthplan-groups-page/image-getting-to-app-config-healthplan-groups-page-4.png";
import imageProviderProfilePlanConfigGroupsPage1 from "@/assets/tutorials/tutorial-provider-profile-plan-config-groups-page/image-provider-profile-plan-config-groups-page-1.png";
import imageProviderProfilePlanConfigGroupsPage2 from "@/assets/tutorials/tutorial-provider-profile-plan-config-groups-page/image-provider-profile-plan-config-groups-page-2.png";
import imageProviderProfilePlanConfigGroupsPage3 from "@/assets/tutorials/tutorial-provider-profile-plan-config-groups-page/image-provider-profile-plan-config-groups-page-3.png";
import imageProviderProfilePlanConfigGroupsPage4 from "@/assets/tutorials/tutorial-provider-profile-plan-config-groups-page/image-provider-profile-plan-config-groups-page-4.png";
import imageProviderProfilePlanConfigGroupsPage5 from "@/assets/tutorials/tutorial-provider-profile-plan-config-groups-page/image-provider-profile-plan-config-groups-page-5.png";
import imageHomePagePlanConfigGroupsPage1 from "@/assets/tutorials/tutorial-home-page-plan-config-groups-page/image-home-page-plan-config-groups-page-1.png";
import imageHomePagePlanConfigGroupsPage2 from "@/assets/tutorials/tutorial-home-page-plan-config-groups-page/image-home-page-plan-config-groups-page-2.png";
import imageHomePagePlanConfigGroupsPage3 from "@/assets/tutorials/tutorial-home-page-plan-config-groups-page/image-home-page-plan-config-groups-page-3.png";
import imageHomePagePlanConfigGroupsPage4 from "@/assets/tutorials/tutorial-home-page-plan-config-groups-page/image-home-page-plan-config-groups-page-4.png";
import imageHomePagePlanConfigGroupsPage5 from "@/assets/tutorials/tutorial-home-page-plan-config-groups-page/image-home-page-plan-config-groups-page-5.png";
import imageProviderFinderPlanConfigGroupsPage1 from "@/assets/tutorials/tutorial-provider-finder-plan-config-groups-page/image-provider-finder-plan-config-groups-page-1.png";
import imageProviderFinderPlanConfigGroupsPage2 from "@/assets/tutorials/tutorial-provider-finder-plan-config-groups-page/image-provider-finder-plan-config-groups-page-2.png";
import imageProviderFinderPlanConfigGroupsPage3 from "@/assets/tutorials/tutorial-provider-finder-plan-config-groups-page/image-provider-finder-plan-config-groups-page-3.png";
import imageProviderFinderPlanConfigGroupsPage4 from "@/assets/tutorials/tutorial-provider-finder-plan-config-groups-page/image-provider-finder-plan-config-groups-page-4.png";
import imageProviderFinderPlanConfigGroupsPage5 from "@/assets/tutorials/tutorial-provider-finder-plan-config-groups-page/image-provider-finder-plan-config-groups-page-5.png";
import imageProfilePagePlanConfigGroupsPage1 from "@/assets/tutorials/tutorial-profile-page-plan-config-groups-page/image-profile-page-plan-config-groups-page-1.png";
import imageProfilePagePlanConfigGroupsPage2 from "@/assets/tutorials/tutorial-profile-page-plan-config-groups-page/image-profile-page-plan-config-groups-page-2.png";
import imageProfilePagePlanConfigGroupsPage3 from "@/assets/tutorials/tutorial-profile-page-plan-config-groups-page/image-profile-page-plan-config-groups-page-3.png";
import imageProfilePagePlanConfigGroupsPage4 from "@/assets/tutorials/tutorial-profile-page-plan-config-groups-page/image-profile-page-plan-config-groups-page-4.png";
import imageProfilePagePlanConfigGroupsPage5 from "@/assets/tutorials/tutorial-profile-page-plan-config-groups-page/image-profile-page-plan-config-groups-page-5.png";
import imageSearchForGroup1 from "@/assets/tutorials/tutorial-search-for-group/image-search-for-group-1.png";
import imageSearchForGroup2 from "@/assets/tutorials/tutorial-search-for-group/image-search-for-group-2.png";
import imageSearchForGroup3 from "@/assets/tutorials/tutorial-search-for-group/image-search-for-group-3.png";
import imageConnectExistingHealthplanToGroup1 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-1.png";
import imageConnectExistingHealthplanToGroup2 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-2.png";
import imageConnectExistingHealthplanToGroup3 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-3.png";
import imageConnectExistingHealthplanToGroup4 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-4.png";
import imageConnectExistingHealthplanToGroup5 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-5.png";
import imageConnectExistingHealthplanToGroup6 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-6.png";
import imageConnectExistingHealthplanToGroup7 from "@/assets/tutorials/tutorial-connect-existing-healthplan-to-group/image-connect-existing-healthplan-to-group-7.png";
import imageRemoveHealthplanFromGroup1 from "@/assets/tutorials/tutorial-remove-healthplan-from-group/image-remove-healthplan-from-group-1.png";
import imageRemoveHealthplanFromGroup2 from "@/assets/tutorials/tutorial-remove-healthplan-from-group/image-remove-healthplan-from-group-2.png";
import imageRemoveHealthplanFromGroup3 from "@/assets/tutorials/tutorial-remove-healthplan-from-group/image-remove-healthplan-from-group-3.png";
import imageRemoveHealthplanFromGroup4 from "@/assets/tutorials/tutorial-remove-healthplan-from-group/image-remove-healthplan-from-group-4.png";
import imageSearchForHealthplanAttachedToGroup1 from "@/assets/tutorials/tutorial-search-for-healthplan-attached-to-group/image-search-for-healthplan-attached-to-group-1.png";
import imageSearchForHealthplanAttachedToGroup2 from "@/assets/tutorials/tutorial-search-for-healthplan-attached-to-group/image-search-for-healthplan-attached-to-group-2.png";
import imageSearchForHealthplanAttachedToGroup3 from "@/assets/tutorials/tutorial-search-for-healthplan-attached-to-group/image-search-for-healthplan-attached-to-group-3.png";
import imageSearchForHealthplanAttachedToGroup4 from "@/assets/tutorials/tutorial-search-for-healthplan-attached-to-group/image-search-for-healthplan-attached-to-group-4.png";
import imageEditHealthplanDetailsGroupsPage1 from "@/assets/tutorials/tutorial-edit-healthplan-details-groups-page/image-edit-healthplan-details-groups-page-1.png";
import imageEditHealthplanDetailsGroupsPage2 from "@/assets/tutorials/tutorial-edit-healthplan-details-groups-page/image-edit-healthplan-details-groups-page-2.png";
import imageEditHealthplanDetailsGroupsPage3 from "@/assets/tutorials/tutorial-edit-healthplan-details-groups-page/image-edit-healthplan-details-groups-page-3.png";
import imageEditHealthplanDetailsGroupsPage4 from "@/assets/tutorials/tutorial-edit-healthplan-details-groups-page/image-edit-healthplan-details-groups-page-4.png";
import imageEditHealthplanDetailsGroupsPage5 from "@/assets/tutorials/tutorial-edit-healthplan-details-groups-page/image-edit-healthplan-details-groups-page-5.png";
import imageSetupCustomTelehealthHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-1.png";
import imageSetupCustomTelehealthHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-2.png";
import imageSetupCustomTelehealthHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-3.png";
import imageSetupCustomTelehealthHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-4.png";
import imageSetupCustomTelehealthHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-5.png";
import imageSetupCustomTelehealthHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-6.png";
import imageSetupCustomTelehealthHealthplanGroupsPage7 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-7.png";
import imageSetupCustomTelehealthHealthplanGroupsPage8 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-8.png";
import imageSetupCustomTelehealthHealthplanGroupsPage9 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-9.png";
import imageSetupCustomTelehealthHealthplanGroupsPage10 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-10.png";
import imageSetupCustomTelehealthHealthplanGroupsPage11 from "@/assets/tutorials/tutorial-setup-custom-telehealth-healthplan-groups-page/image-setup-custom-telehealth-healthplan-groups-page-11.png";
import imageTurnOffTelehealthForHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-1.png";
import imageTurnOffTelehealthForHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-2.png";
import imageTurnOffTelehealthForHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-3.png";
import imageTurnOffTelehealthForHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-4.png";
import imageTurnOffTelehealthForHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-5.png";
import imageTurnOffTelehealthForHealthplanGroupsPage6 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-6.png";
import imageTurnOffTelehealthForHealthplanGroupsPage7 from "@/assets/tutorials/tutorial-turn-off-telehealth-for-healthplan-groups-page/image-turn-off-telehealth-for-healthplan-groups-page-7.png";
import imageEditAdditionalDetailsHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-edit-additional-details-healthplan-groups-page/image-edit-additional-details-healthplan-groups-page-1.png";
import imageEditAdditionalDetailsHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-edit-additional-details-healthplan-groups-page/image-edit-additional-details-healthplan-groups-page-2.png";
import imageEditAdditionalDetailsHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-edit-additional-details-healthplan-groups-page/image-edit-additional-details-healthplan-groups-page-3.png";
import imageEditAdditionalDetailsHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-edit-additional-details-healthplan-groups-page/image-edit-additional-details-healthplan-groups-page-4.png";
import imageEditAdditionalDetailsHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-edit-additional-details-healthplan-groups-page/image-edit-additional-details-healthplan-groups-page-5.png";
import imageEditNetworkDetailsHealthplanGroupsPage1 from "@/assets/tutorials/tutorial-edit-network-details-healthplan-groups-page/image-edit-network-details-healthplan-groups-page-1.png";
import imageEditNetworkDetailsHealthplanGroupsPage2 from "@/assets/tutorials/tutorial-edit-network-details-healthplan-groups-page/image-edit-network-details-healthplan-groups-page-2.png";
import imageEditNetworkDetailsHealthplanGroupsPage3 from "@/assets/tutorials/tutorial-edit-network-details-healthplan-groups-page/image-edit-network-details-healthplan-groups-page-3.png";
import imageEditNetworkDetailsHealthplanGroupsPage4 from "@/assets/tutorials/tutorial-edit-network-details-healthplan-groups-page/image-edit-network-details-healthplan-groups-page-4.png";
import imageEditNetworkDetailsHealthplanGroupsPage5 from "@/assets/tutorials/tutorial-edit-network-details-healthplan-groups-page/image-edit-network-details-healthplan-groups-page-5.png";
import imageViewMemberOfGroup1 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-1.png";
import imageViewMemberOfGroup2 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-2.png";
import imageViewMemberOfGroup3 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-3.png";
import imageViewMemberOfGroup4 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-4.png";
import imageViewMemberOfGroup5 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-5.png";
import imageViewMemberOfGroup6 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-6.png";
import imageViewMemberOfGroup7 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-7.png";
import imageViewMemberOfGroup8 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-8.png";
import imageViewMemberOfGroup9 from "@/assets/tutorials/tutorial-view-member-of-group/image-view-member-of-group-9.png";
import imageSearchForHealthPlan1 from "@/assets/tutorials/tutorial-search-for-healthplan/image-search-for-healthplan-1.png";
import imageSearchForHealthPlan2 from "@/assets/tutorials/tutorial-search-for-healthplan/image-search-for-healthplan-2.png";
import imageSearchForHealthPlan3 from "@/assets/tutorials/tutorial-search-for-healthplan/image-search-for-healthplan-3.png";
import imageDeletingHealthplanFromOrg1 from "@/assets/tutorials/tutorial-deleting-healthplan-from-org/image-deleting-healthplan-from-org-1.png";
import imageDeletingHealthplanFromOrg2 from "@/assets/tutorials/tutorial-deleting-healthplan-from-org/image-deleting-healthplan-from-org-2.png";
import imageDeletingHealthplanFromOrg3 from "@/assets/tutorials/tutorial-deleting-healthplan-from-org/image-deleting-healthplan-from-org-3.png";
import imageInvitingTeamMember1 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-1.png";
import imageInvitingTeamMember2 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-2.png";
import imageInvitingTeamMember3 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-3.png";
import imageInvitingTeamMember4 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-4.png";
import imageInvitingTeamMember5 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-5.png";
import imageInvitingTeamMember6 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-6.png";
import imageInvitingTeamMember7 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-7.png";
import imageInvitingTeamMember8 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-8.png";
import imageInvitingTeamMember9 from "@/assets/tutorials/tutorial-inviting-team-member/image-inviting-team-member-9.png";
import imageFindAppConfig1 from "@/assets/tutorials/tutorial-find-app-config-healthplan/image-find-app-config-healthplan-1.png";
import imageFindAppConfig2 from "@/assets/tutorials/tutorial-find-app-config-healthplan/image-find-app-config-healthplan-2.png";
import imageFindAppConfig3 from "@/assets/tutorials/tutorial-find-app-config-healthplan/image-find-app-config-healthplan-3.png";
import imageCustomTelehealthForHealthplan1 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-1.png";
import imageCustomTelehealthForHealthplan2 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-2.png";
import imageCustomTelehealthForHealthplan3 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-3.png";
import imageCustomTelehealthForHealthplan4 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-4.png";
import imageCustomTelehealthForHealthplan5 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-5.png";
import imageCustomTelehealthForHealthplan6 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-6.png";
import imageCustomTelehealthForHealthplan7 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-7.png";
import imageCustomTelehealthForHealthplan8 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-8.png";
import imageCustomTelehealthForHealthplan9 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-9.png";
import imageCustomTelehealthForHealthplan10 from "@/assets/tutorials/tutorial-custom-telehealth-for-healthplan/image-custom-telehealth-for-healthplan-10.png";
import imageTurnOffTelehealthHealthplan1 from "@/assets/tutorials/tutorial-turn-off-telehealth-healthplan/image-turn-off-telehealth-healthplan-1.png";
import imageTurnOffTelehealthHealthplan2 from "@/assets/tutorials/tutorial-turn-off-telehealth-healthplan/image-turn-off-telehealth-healthplan-2.png";
import imageTurnOffTelehealthHealthplan3 from "@/assets/tutorials/tutorial-turn-off-telehealth-healthplan/image-turn-off-telehealth-healthplan-3.png";
import imageTurnOffTelehealthHealthplan4 from "@/assets/tutorials/tutorial-turn-off-telehealth-healthplan/image-turn-off-telehealth-healthplan-4.png";
import imageTurnOffTelehealthHealthplan5 from "@/assets/tutorials/tutorial-turn-off-telehealth-healthplan/image-turn-off-telehealth-healthplan-5.png";
import imageTurnOffTelehealthHealthplan6 from "@/assets/tutorials/tutorial-turn-off-telehealth-healthplan/image-turn-off-telehealth-healthplan-6.png";
import imageEditHealthplanDetailsHealthplan1 from "@/assets/tutorials/tutorial-edit-healthplan-details-healthplan/image-edit-healthplan-details-healthplan-1.png";
import imageEditHealthplanDetailsHealthplan2 from "@/assets/tutorials/tutorial-edit-healthplan-details-healthplan/image-edit-healthplan-details-healthplan-2.png";
import imageEditHealthplanDetailsHealthplan3 from "@/assets/tutorials/tutorial-edit-healthplan-details-healthplan/image-edit-healthplan-details-healthplan-3.png";
import imageEditHealthplanDetailsHealthplan4 from "@/assets/tutorials/tutorial-edit-healthplan-details-healthplan/image-edit-healthplan-details-healthplan-4.png";
import imageEditHealthplanAdditionalDetailsHealthplan1 from "@/assets/tutorials/tutorial-edit-healthplan-additional-details-healthplan/image-edit-healthplan-additional-details-healthplan-1.png";
import imageEditHealthplanAdditionalDetailsHealthplan2 from "@/assets/tutorials/tutorial-edit-healthplan-additional-details-healthplan/image-edit-healthplan-additional-details-healthplan-2.png";
import imageEditHealthplanAdditionalDetailsHealthplan3 from "@/assets/tutorials/tutorial-edit-healthplan-additional-details-healthplan/image-edit-healthplan-additional-details-healthplan-3.png";
import imageEditHealthplanAdditionalDetailsHealthplan4 from "@/assets/tutorials/tutorial-edit-healthplan-additional-details-healthplan/image-edit-healthplan-additional-details-healthplan-4.png";
import imageChangeNetworkForHealthplan1 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-1.png";
import imageChangeNetworkForHealthplan2 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-2.png";
import imageChangeNetworkForHealthplan3 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-3.png";
import imageChangeNetworkForHealthplan4 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-4.png";
import imageChangeNetworkForHealthplan5 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-5.png";
import imageChangeNetworkForHealthplan6 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-6.png";
import imageChangeNetworkForHealthplan7 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-7.png";
import imageChangeNetworkForHealthplan8 from "@/assets/tutorials/tutorial-change-network-for-healthplan/image-change-network-for-healthplan-8.png";
import imageEditIdCardForHealthplan1 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-1.png";
import imageEditIdCardForHealthplan2 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-2.png";
import imageEditIdCardForHealthplan3 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-3.png";
import imageEditIdCardForHealthplan4 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-4.png";
import imageEditIdCardForHealthplan5 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-5.png";
import imageEditIdCardForHealthplan6 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-6.png";
import imageEditIdCardForHealthplan7 from "@/assets/tutorials/tutorial-edit-id-card-for-healthplan/image-edit-id-card-for-healthplan-7.png";
import imageChangeIdCardHealthplan1 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-1.png";
import imageChangeIdCardHealthplan2 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-2.png";
import imageChangeIdCardHealthplan3 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-3.png";
import imageChangeIdCardHealthplan4 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-4.png";
import imageChangeIdCardHealthplan5 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-5.png";
import imageChangeIdCardHealthplan6 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-6.png";
import imageChangeIdCardHealthplan7 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-7.png";
import imageChangeIdCardHealthplan8 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-8.png";
import imageChangeIdCardHealthplan9 from "@/assets/tutorials/tutorial-change-id-card-healthplan/image-change-id-card-healthplan-9.png";
import imageAddDocToHealthplan1 from "@/assets/tutorials/tutorial-add-doc-to-healthplan/image-add-doc-to-healthplan-1.png";
import imageAddDocToHealthplan2 from "@/assets/tutorials/tutorial-add-doc-to-healthplan/image-add-doc-to-healthplan-2.png";
import imageAddDocToHealthplan3 from "@/assets/tutorials/tutorial-add-doc-to-healthplan/image-add-doc-to-healthplan-3.png";
import imageAddDocToHealthplan4 from "@/assets/tutorials/tutorial-add-doc-to-healthplan/image-add-doc-to-healthplan-4.png";
import imageAddDocToHealthplan5 from "@/assets/tutorials/tutorial-add-doc-to-healthplan/image-add-doc-to-healthplan-5.png";
import imageViewDocAttachedToHealthplan1 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan/image-view-doc-attached-to-healthplan-1.png";
import imageViewDocAttachedToHealthplan2 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan/image-view-doc-attached-to-healthplan-2.png";
import imageViewDocAttachedToHealthplan3 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan/image-view-doc-attached-to-healthplan-3.png";
import imageViewDocAttachedToHealthplan4 from "@/assets/tutorials/tutorial-view-doc-attached-to-healthplan/image-view-doc-attached-to-healthplan-4.png";
import imageRemoveDocFromHealthplan1 from "@/assets/tutorials/tutorial-remove-doc-from-healthplan/image-remove-doc-from-healthplan-1.png";
import imageRemoveDocFromHealthplan2 from "@/assets/tutorials/tutorial-remove-doc-from-healthplan/image-remove-doc-from-healthplan-2.png";
import imageRemoveDocFromHealthplan3 from "@/assets/tutorials/tutorial-remove-doc-from-healthplan/image-remove-doc-from-healthplan-3.png";
import imageRemoveDocFromHealthplan4 from "@/assets/tutorials/tutorial-remove-doc-from-healthplan/image-remove-doc-from-healthplan-4.png";
import imageRemoveDocFromHealthplan5 from "@/assets/tutorials/tutorial-remove-doc-from-healthplan/image-remove-doc-from-healthplan-5.png";
import imageEditNetworkDetailsForHealthplan1 from "@/assets/tutorials/tutorial-edit-network-details-for-healthplan/image-edit-network-details-for-healthplan-1.png";
import imageEditNetworkDetailsForHealthplan2 from "@/assets/tutorials/tutorial-edit-network-details-for-healthplan/image-edit-network-details-for-healthplan-2.png";
import imageEditNetworkDetailsForHealthplan3 from "@/assets/tutorials/tutorial-edit-network-details-for-healthplan/image-edit-network-details-for-healthplan-3.png";
import imageEditNetworkDetailsForHealthplan4 from "@/assets/tutorials/tutorial-edit-network-details-for-healthplan/image-edit-network-details-for-healthplan-4.png";
import imageSearchForMember1 from "@/assets/tutorials/tutorial-search-for-member/image-search-for-member-1.png";
import imageSearchForMember2 from "@/assets/tutorials/tutorial-search-for-member/image-search-for-member-2.png";
import imageSearchForMember3 from "@/assets/tutorials/tutorial-search-for-member/image-search-for-member-3.png";
import imageSearchForMember4 from "@/assets/tutorials/tutorial-search-for-member/image-search-for-member-4.png";
import imageSearchForMember5 from "@/assets/tutorials/tutorial-search-for-member/image-search-for-member-5.png";
import imageEditTieredNetwork1 from "@/assets/tutorials/tutorial-edit-tiered-network/image-edit-tiered-network-1.png";
import imageEditTieredNetwork2 from "@/assets/tutorials/tutorial-edit-tiered-network/image-edit-tiered-network-2.png";
import imageEditTieredNetwork3 from "@/assets/tutorials/tutorial-edit-tiered-network/image-edit-tiered-network-3.png";
import imageEditTieredNetwork4 from "@/assets/tutorials/tutorial-edit-tiered-network/image-edit-tiered-network-4.png";
import imageEditTraditionalNetwork1 from "@/assets/tutorials/tutorial-edit-traditional-network/image-edit-traditional-network-1.png";
import imageEditTraditionalNetwork2 from "@/assets/tutorials/tutorial-edit-traditional-network/image-edit-traditional-network-2.png";
import imageEditTraditionalNetwork3 from "@/assets/tutorials/tutorial-edit-traditional-network/image-edit-traditional-network-3.png";
import imageEditTraditionalNetwork4 from "@/assets/tutorials/tutorial-edit-traditional-network/image-edit-traditional-network-4.png";
import imageEditTraditionalNetwork5 from "@/assets/tutorials/tutorial-edit-traditional-network/image-edit-traditional-network-5.png";
import imageEditNoneNetwork1 from "@/assets/tutorials/tutorial-edit-none-network/image-edit-none-network-1.png";
import imageEditNoneNetwork2 from "@/assets/tutorials/tutorial-edit-none-network/image-edit-none-network-2.png";
import imageEditNoneNetwork3 from "@/assets/tutorials/tutorial-edit-none-network/image-edit-none-network-3.png";
import imageEditNoneNetwork4 from "@/assets/tutorials/tutorial-edit-none-network/image-edit-none-network-4.png";
import imageSearchForNetwork1 from "@/assets/tutorials/tutorial-search-for-network/image-search-for-network-1.png";
import imageSearchForNetwork2 from "@/assets/tutorials/tutorial-search-for-network/image-search-for-network-2.png";
import imageSearchForNetwork3 from "@/assets/tutorials/tutorial-search-for-network/image-search-for-network-3.png";
import imageCloneHealthplanTieredNetwork1 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-1.png";
import imageCloneHealthplanTieredNetwork2 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-2.png";
import imageCloneHealthplanTieredNetwork3 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-3.png";
import imageCloneHealthplanTieredNetwork4 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-4.png";
import imageCloneHealthplanTieredNetwork5 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-5.png";
import imageCloneHealthplanTieredNetwork6 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-6.png";
import imageCloneHealthplanTieredNetwork7 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-7.png";
import imageCloneHealthplanTieredNetwork8 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-8.png";
import imageCloneHealthplanTieredNetwork9 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-9.png";
import imageCloneHealthplanTieredNetwork10 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-10.png";
import imageCloneHealthplanTieredNetwork11 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-11.png";
import imageCloneHealthplanTieredNetwork12 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-12.png";
import imageCloneHealthplanTieredNetwork13 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-13.png";
import imageCloneHealthplanTieredNetwork14 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-14.png";
import imageCloneHealthplanTieredNetwork15 from "@/assets/tutorials/tutorial-clone-healthplan-tiered-network/image-clone-healthplan-tiered-network-15.png";
import imageCloneHealthplanNoneNetwork1 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-1.png";
import imageCloneHealthplanNoneNetwork2 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-2.png";
import imageCloneHealthplanNoneNetwork3 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-3.png";
import imageCloneHealthplanNoneNetwork4 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-4.png";
import imageCloneHealthplanNoneNetwork5 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-5.png";
import imageCloneHealthplanNoneNetwork6 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-6.png";
import imageCloneHealthplanNoneNetwork7 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-7.png";
import imageCloneHealthplanNoneNetwork8 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-8.png";
import imageCloneHealthplanNoneNetwork9 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-9.png";
import imageCloneHealthplanNoneNetwork10 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-10.png";
import imageCloneHealthplanNoneNetwork11 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-11.png";
import imageCloneHealthplanNoneNetwork12 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-12.png";
import imageCloneHealthplanNoneNetwork13 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-13.png";
import imageCloneHealthplanNoneNetwork14 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-14.png";
import imageCloneHealthplanNoneNetwork15 from "@/assets/tutorials/tutorial-clone-healthplan-none-network/image-clone-healthplan-none-network-15.png";
import imageCloneHealthplanTraditionalNetwork1 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-1.png";
import imageCloneHealthplanTraditionalNetwork2 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-2.png";
import imageCloneHealthplanTraditionalNetwork3 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-3.png";
import imageCloneHealthplanTraditionalNetwork4 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-4.png";
import imageCloneHealthplanTraditionalNetwork5 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-5.png";
import imageCloneHealthplanTraditionalNetwork6 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-6.png";
import imageCloneHealthplanTraditionalNetwork7 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-7.png";
import imageCloneHealthplanTraditionalNetwork8 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-8.png";
import imageCloneHealthplanTraditionalNetwork9 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-9.png";
import imageCloneHealthplanTraditionalNetwork10 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-10.png";
import imageCloneHealthplanTraditionalNetwork11 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-11.png";
import imageCloneHealthplanTraditionalNetwork12 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-12.png";
import imageCloneHealthplanTraditionalNetwork13 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-13.png";
import imageCloneHealthplanTraditionalNetwork14 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-14.png";
import imageCloneHealthplanTraditionalNetwork15 from "@/assets/tutorials/tutorial-clone-healthplan-traditional-network/image-clone-healthplan-traditional-network-15.png";
import imageAddTextBoxToIdCard1 from "@/assets/tutorials/tutorial-add-text-box-to-id-card/image-add-text-box-to-id-card-1.png";
import imageAddTextBoxToIdCard2 from "@/assets/tutorials/tutorial-add-text-box-to-id-card/image-add-text-box-to-id-card-2.png";
import imageAddTextBoxToIdCard3 from "@/assets/tutorials/tutorial-add-text-box-to-id-card/image-add-text-box-to-id-card-3.png";
import imageAddTextBoxToIdCard4 from "@/assets/tutorials/tutorial-add-text-box-to-id-card/image-add-text-box-to-id-card-4.png";
import imageAddTextBoxToIdCard5 from "@/assets/tutorials/tutorial-add-text-box-to-id-card/image-add-text-box-to-id-card-5.png";
import imageAddFieldToIdCard1 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-1.png";
import imageAddFieldToIdCard2 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-2.png";
import imageAddFieldToIdCard3 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-3.png";
import imageAddFieldToIdCard4 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-4.png";
import imageAddFieldToIdCard5 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-5.png";
import imageAddFieldToIdCard6 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-6.png";
import imageAddFieldToIdCard7 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-7.png";
import imageAddFieldToIdCard8 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-8.png";
import imageAddFieldToIdCard9 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-9.png";
import imageAddFieldToIdCard10 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-10.png";
import imageAddFieldToIdCard11 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-11.png";
import imageAddFieldToIdCard12 from "@/assets/tutorials/tutorial-add-field-to-id-card/image-add-field-to-id-card-12.png";
import imageUseImageTool1 from "@/assets/tutorials/tutorial-use-image-tool/image-use-image-tool-1.png";
import imageUseImageTool2 from "@/assets/tutorials/tutorial-use-image-tool/image-use-image-tool-2.png";
import imageUseImageTool3 from "@/assets/tutorials/tutorial-use-image-tool/image-use-image-tool-3.png";
import imageUseImageTool4 from "@/assets/tutorials/tutorial-use-image-tool/image-use-image-tool-4.png";
import imageUseImageTool5 from "@/assets/tutorials/tutorial-use-image-tool/image-use-image-tool-5.png";
import imageChangeBackgroundIdCard1 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-1.png";
import imageChangeBackgroundIdCard2 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-2.png";
import imageChangeBackgroundIdCard3 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-3.png";
import imageChangeBackgroundIdCard4 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-4.png";
import imageChangeBackgroundIdCard5 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-5.png";
import imageChangeBackgroundIdCard6 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-6.png";
import imageChangeBackgroundIdCard7 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-7.png";
import imageChangeBackgroundIdCard8 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-8.png";
import imageChangeBackgroundIdCard9 from "@/assets/tutorials/tutorial-change-background-id-card/image-change-background-id-card-9.png";
import imageUseWhiteOutTool1 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-1.png";
import imageUseWhiteOutTool2 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-2.png";
import imageUseWhiteOutTool3 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-3.png";
import imageUseWhiteOutTool4 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-4.png";
import imageUseWhiteOutTool5 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-5.png";
import imageUseWhiteOutTool6 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-6.png";
import imageUseWhiteOutTool7 from "@/assets/tutorials/tutorial-use-white-out-tool/image-use-white-out-tool-7.png";
import imageUseTextSettings1 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-1.png";
import imageUseTextSettings2 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-2.png";
import imageUseTextSettings3 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-3.png";
import imageUseTextSettings4 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-4.png";
import imageUseTextSettings5 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-5.png";
import imageUseTextSettings6 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-6.png";
import imageUseTextSettings7 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-7.png";
import imageUseTextSettings8 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-8.png";
import imageUseTextSettings9 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-9.png";
import imageUseTextSettings10 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-10.png";
import imageUseTextSettings11 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-11.png";
import imageUseTextSettings12 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-12.png";
import imageUseTextSettings13 from "@/assets/tutorials/tutorial-use-text-settings/image-use-text-settings-13.png";
import imageUseFieldSettings1 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-1.png";
import imageUseFieldSettings2 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-2.png";
import imageUseFieldSettings3 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-3.png";
import imageUseFieldSettings4 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-4.png";
import imageUseFieldSettings5 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-5.png";
import imageUseFieldSettings6 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-6.png";
import imageUseFieldSettings7 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-7.png";
import imageUseFieldSettings8 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-8.png";
import imageUseFieldSettings9 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-9.png";
import imageUseFieldSettings10 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-10.png";
import imageUseFieldSettings11 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-11.png";
import imageUseFieldSettings12 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-12.png";
import imageUseFieldSettings13 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-13.png";
import imageUseFieldSettings14 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-14.png";
import imageUseFieldSettings15 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-15.png";
import imageUseFieldSettings16 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-16.png";
import imageUseFieldSettings17 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-17.png";
import imageUseFieldSettings18 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-18.png";
import imageUseFieldSettings19 from "@/assets/tutorials/tutorial-use-field-settings/image-use-field-settings-19.png";
import imageUseShapeToolSettings1 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-1.png";
import imageUseShapeToolSettings2 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-2.png";
import imageUseShapeToolSettings3 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-3.png";
import imageUseShapeToolSettings4 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-4.png";
import imageUseShapeToolSettings5 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-5.png";
import imageUseShapeToolSettings6 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-6.png";
import imageUseShapeToolSettings7 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-7.png";
import imageUseShapeToolSettings8 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-8.png";
import imageUseShapeToolSettings9 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-9.png";
import imageUseShapeToolSettings10 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-10.png";
import imageUseShapeToolSettings11 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-11.png";
import imageUseShapeToolSettings12 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-12.png";
import imageUseShapeToolSettings13 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-13.png";
import imageUseShapeToolSettings14 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-14.png";
import imageUseShapeToolSettings15 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-15.png";
import imageUseShapeToolSettings16 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-16.png";
import imageUseShapeToolSettings17 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-17.png";
import imageUseShapeToolSettings18 from "@/assets/tutorials/tutorial-use-shape-tool-settings/image-use-shape-tool-settings-18.png";
import imageCreateIdCardWithTemplate1 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-1.png";
import imageCreateIdCardWithTemplate2 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-2.png";
import imageCreateIdCardWithTemplate3 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-3.png";
import imageCreateIdCardWithTemplate4 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-4.png";
import imageCreateIdCardWithTemplate5 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-5.png";
import imageCreateIdCardWithTemplate6 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-6.png";
import imageCreateIdCardWithTemplate7 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-7.png";
import imageCreateIdCardWithTemplate8 from "@/assets/tutorials/tutorial-create-id-card-with-template/image-create-id-card-with-template-8.png";
import imageCreateNonTemplatedIdCard1 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-1.png";
import imageCreateNonTemplatedIdCard2 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-2.png";
import imageCreateNonTemplatedIdCard3 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-3.png";
import imageCreateNonTemplatedIdCard4 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-4.png";
import imageCreateNonTemplatedIdCard5 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-5.png";
import imageCreateNonTemplatedIdCard6 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-6.png";
import imageCreateNonTemplatedIdCard7 from "@/assets/tutorials/tutorial-create-non-templated-id-card/image-create-non-templated-id-card-7.png";
import imageCreateIdCard1 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-1.png";
import imageCreateIdCard2 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-2.png";
import imageCreateIdCard3 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-3.png";
import imageCreateIdCard4 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-4.png";
import imageCreateIdCard5 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-5.png";
import imageCreateIdCard6 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-6.png";
import imageCreateIdCard7 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-7.png";
import imageCreateIdCard8 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-8.png";
import imageCreateIdCard9 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-9.png";
import imageCreateIdCard10 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-10.png";
import imageCreateIdCard11 from "@/assets/tutorials/tutorial-create-id-card/image-create-id-card-11.png";
import imageUseCopyPasteDeleteTools1 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-1.png";
import imageUseCopyPasteDeleteTools2 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-2.png";
import imageUseCopyPasteDeleteTools3 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-3.png";
import imageUseCopyPasteDeleteTools4 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-4.png";
import imageUseCopyPasteDeleteTools5 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-5.png";
import imageUseCopyPasteDeleteTools6 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-6.png";
import imageUseCopyPasteDeleteTools7 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-7.png";
import imageUseCopyPasteDeleteTools8 from "@/assets/tutorials/tutorial-use-copy-paste-detele-tools/image-use-copy-paste-detele-tools-8.png";
import imageUseMoveToTools1 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-1.png";
import imageUseMoveToTools2 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-2.png";
import imageUseMoveToTools3 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-3.png";
import imageUseMoveToTools4 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-4.png";
import imageUseMoveToTools5 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-5.png";
import imageUseMoveToTools6 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-6.png";
import imageUseMoveToTools7 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-7.png";
import imageUseMoveToTools8 from "@/assets/tutorials/tutorial-use-move-to-tools/image-use-move-to-tools-8.png";
import imageToggleProviderFinderGroups1 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-1.png";
import imageToggleProviderFinderGroups2 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-2.png";
import imageToggleProviderFinderGroups3 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-3.png";
import imageToggleProviderFinderGroups4 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-4.png";
import imageToggleProviderFinderGroups5 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-5.png";
import imageToggleProviderFinderGroups6 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-6.png";
import imageToggleProviderFinderGroups7 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-7.png";
import imageToggleProviderFinderGroups8 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-8.png";
import imageToggleProviderFinderGroups9 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-9.png";
import imageToggleProviderFinderGroups10 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-10.png";
import imageToggleProviderFinderGroups11 from "@/assets/tutorials/tutorial-toggle-provider-finder-groups/image-toggle-provider-finder-groups-11.png";
import imageToggleBookAppointmentMctGroups1 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-1.png";
import imageToggleBookAppointmentMctGroups2 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-2.png";
import imageToggleBookAppointmentMctGroups3 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-3.png";
import imageToggleBookAppointmentMctGroups4 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-4.png";
import imageToggleBookAppointmentMctGroups5 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-5.png";
import imageToggleBookAppointmentMctGroups6 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-6.png";
import imageToggleBookAppointmentMctGroups7 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-7.png";
import imageToggleBookAppointmentMctGroups8 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-8.png";
import imageToggleBookAppointmentMctGroups9 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-9.png";
import imageToggleBookAppointmentMctGroups10 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-10.png";
import imageToggleBookAppointmentMctGroups11 from "@/assets/tutorials/tutorial-toggle-book-appointment-mct-groups/image-toggle-book-appointment-mct-groups-11.png";
import imageTogglePrescriptionNavbarGroups1 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-1.png";
import imageTogglePrescriptionNavbarGroups2 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-2.png";
import imageTogglePrescriptionNavbarGroups3 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-3.png";
import imageTogglePrescriptionNavbarGroups4 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-4.png";
import imageTogglePrescriptionNavbarGroups5 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-5.png";
import imageTogglePrescriptionNavbarGroups6 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-6.png";
import imageTogglePrescriptionNavbarGroups7 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-7.png";
import imageTogglePrescriptionNavbarGroups8 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-8.png";
import imageTogglePrescriptionNavbarGroups9 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-9.png";
import imageTogglePrescriptionNavbarGroups10 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-10.png";
import imageTogglePrescriptionNavbarGroups11 from "@/assets/tutorials/tutorial-toggle-prescription-navbar-groups/image-toggle-prescription-navbar-groups-11.png";
import imageToggleCovidTestFinderGroups1 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-1.png";
import imageToggleCovidTestFinderGroups2 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-2.png";
import imageToggleCovidTestFinderGroups3 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-3.png";
import imageToggleCovidTestFinderGroups4 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-4.png";
import imageToggleCovidTestFinderGroups5 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-5.png";
import imageToggleCovidTestFinderGroups6 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-6.png";
import imageToggleCovidTestFinderGroups7 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-7.png";
import imageToggleCovidTestFinderGroups8 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-8.png";
import imageToggleCovidTestFinderGroups9 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-9.png";
import imageToggleCovidTestFinderGroups10 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-10.png";
import imageToggleCovidTestFinderGroups11 from "@/assets/tutorials/tutorial-toggle-covid-test-finder-groups/image-toggle-covid-test-finder-groups-11.png";
import imageAddCustomLogoGroupsPage1 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-1.png";
import imageAddCustomLogoGroupsPage2 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-2.png";
import imageAddCustomLogoGroupsPage3 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-3.png";
import imageAddCustomLogoGroupsPage4 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-4.png";
import imageAddCustomLogoGroupsPage5 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-5.png";
import imageAddCustomLogoGroupsPage6 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-6.png";
import imageAddCustomLogoGroupsPage7 from "@/assets/tutorials/tutorial-add-custom-logo-groups-page/image-add-custom-logo-groups-page-7.png";
import imageConfigureTelehealthTextGroupsPage1 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-1.png";
import imageConfigureTelehealthTextGroupsPage2 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-2.png";
import imageConfigureTelehealthTextGroupsPage3 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-3.png";
import imageConfigureTelehealthTextGroupsPage4 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-4.png";
import imageConfigureTelehealthTextGroupsPage5 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-5.png";
import imageConfigureTelehealthTextGroupsPage6 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-6.png";
import imageConfigureTelehealthTextGroupsPage7 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-7.png";
import imageConfigureTelehealthTextGroupsPage8 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-8.png";
import imageConfigureTelehealthTextGroupsPage9 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-9.png";
import imageConfigureTelehealthTextGroupsPage10 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-10.png";
import imageConfigureTelehealthTextGroupsPage11 from "@/assets/tutorials/tutorial-configure-telehealth-text-groups-page/image-configure-telehealth-text-groups-page-11.png";
import imageToggleMyPrescriptionBannerCardGroupsPage1 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-1.png";
import imageToggleMyPrescriptionBannerCardGroupsPage2 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-2.png";
import imageToggleMyPrescriptionBannerCardGroupsPage3 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-3.png";
import imageToggleMyPrescriptionBannerCardGroupsPage4 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-4.png";
import imageToggleMyPrescriptionBannerCardGroupsPage5 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-5.png";
import imageToggleMyPrescriptionBannerCardGroupsPage6 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-6.png";
import imageToggleMyPrescriptionBannerCardGroupsPage7 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-7.png";
import imageToggleMyPrescriptionBannerCardGroupsPage8 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-8.png";
import imageToggleMyPrescriptionBannerCardGroupsPage9 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-9.png";
import imageToggleMyPrescriptionBannerCardGroupsPage10 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-10.png";
import imageToggleMyPrescriptionBannerCardGroupsPage11 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-11.png";
import imageToggleMyPrescriptionBannerCardGroupsPage12 from "@/assets/tutorials/tutorial-toggle-my-prescription-banner-card-groups-page/image-toggle-my-prescription-banner-card-groups-page-12.png";
import imageToggleTelehealthBannerCardGroupsPage1 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-1.png";
import imageToggleTelehealthBannerCardGroupsPage2 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-2.png";
import imageToggleTelehealthBannerCardGroupsPage3 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-3.png";
import imageToggleTelehealthBannerCardGroupsPage4 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-4.png";
import imageToggleTelehealthBannerCardGroupsPage5 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-5.png";
import imageToggleTelehealthBannerCardGroupsPage6 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-6.png";
import imageToggleTelehealthBannerCardGroupsPage7 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-7.png";
import imageToggleTelehealthBannerCardGroupsPage8 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-8.png";
import imageToggleTelehealthBannerCardGroupsPage9 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-9.png";
import imageToggleTelehealthBannerCardGroupsPage10 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-10.png";
import imageToggleTelehealthBannerCardGroupsPage11 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-11.png";
import imageToggleTelehealthBannerCardGroupsPage12 from "@/assets/tutorials/tutorial-toggle-telehealth-banner-card-groups-page/image-toggle-telehealth-banner-card-groups-page-12.png";
import imageToggleProcedureBannerCardGroupsPage1 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-1.png";
import imageToggleProcedureBannerCardGroupsPage2 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-2.png";
import imageToggleProcedureBannerCardGroupsPage3 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-3.png";
import imageToggleProcedureBannerCardGroupsPage4 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-4.png";
import imageToggleProcedureBannerCardGroupsPage5 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-5.png";
import imageToggleProcedureBannerCardGroupsPage6 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-6.png";
import imageToggleProcedureBannerCardGroupsPage7 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-7.png";
import imageToggleProcedureBannerCardGroupsPage8 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-8.png";
import imageToggleProcedureBannerCardGroupsPage9 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-9.png";
import imageToggleProcedureBannerCardGroupsPage10 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-10.png";
import imageToggleProcedureBannerCardGroupsPage11 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-11.png";
import imageToggleProcedureBannerCardGroupsPage12 from "@/assets/tutorials/tutorial-toggle-procedure-banner-card-groups-page/image-toggle-procedure-banner-card-groups-page-12.png";
import imageToggleBalanceBillingBannerCardGroupsPage1 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-1.png";
import imageToggleBalanceBillingBannerCardGroupsPage2 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-2.png";
import imageToggleBalanceBillingBannerCardGroupsPage3 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-3.png";
import imageToggleBalanceBillingBannerCardGroupsPage4 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-4.png";
import imageToggleBalanceBillingBannerCardGroupsPage5 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-5.png";
import imageToggleBalanceBillingBannerCardGroupsPage6 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-6.png";
import imageToggleBalanceBillingBannerCardGroupsPage7 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-7.png";
import imageToggleBalanceBillingBannerCardGroupsPage8 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-8.png";
import imageToggleBalanceBillingBannerCardGroupsPage9 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-9.png";
import imageToggleBalanceBillingBannerCardGroupsPage10 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-10.png";
import imageToggleBalanceBillingBannerCardGroupsPage11 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-11.png";
import imageToggleBalanceBillingBannerCardGroupsPage12 from "@/assets/tutorials/tutorial-toggle-balance-billing-banner-card-groups-page/image-toggle-balance-billing-banner-card-groups-page-12.png";
import imageToggleAdditionalServciesBannerCardGroupsPage1 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-1.png";
import imageToggleAdditionalServciesBannerCardGroupsPage2 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-2.png";
import imageToggleAdditionalServciesBannerCardGroupsPage3 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-3.png";
import imageToggleAdditionalServciesBannerCardGroupsPage4 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-4.png";
import imageToggleAdditionalServciesBannerCardGroupsPage5 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-5.png";
import imageToggleAdditionalServciesBannerCardGroupsPage6 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-6.png";
import imageToggleAdditionalServciesBannerCardGroupsPage7 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-7.png";
import imageToggleAdditionalServciesBannerCardGroupsPage8 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-8.png";
import imageToggleAdditionalServciesBannerCardGroupsPage9 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-9.png";
import imageToggleAdditionalServciesBannerCardGroupsPage10 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-10.png";
import imageToggleAdditionalServciesBannerCardGroupsPage11 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-11.png";
import imageToggleAdditionalServciesBannerCardGroupsPage12 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-12.png";
import imageToggleAdditionalServciesBannerCardGroupsPage13 from "@/assets/tutorials/tutorial-toggle-additional-services-banner-card-groups-page/image-toggle-additional-services-banner-card-groups-page-13.png";
import imageToggleCustomBannerCardsGroupsPage1 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-1.png";
import imageToggleCustomBannerCardsGroupsPage2 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-2.png";
import imageToggleCustomBannerCardsGroupsPage3 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-3.png";
import imageToggleCustomBannerCardsGroupsPage4 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-4.png";
import imageToggleCustomBannerCardsGroupsPage5 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-5.png";
import imageToggleCustomBannerCardsGroupsPage6 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-6.png";
import imageToggleCustomBannerCardsGroupsPage7 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-7.png";
import imageToggleCustomBannerCardsGroupsPage8 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-8.png";
import imageToggleCustomBannerCardsGroupsPage9 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-9.png";
import imageToggleCustomBannerCardsGroupsPage10 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-10.png";
import imageToggleCustomBannerCardsGroupsPage11 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-11.png";
import imageToggleCustomBannerCardsGroupsPage12 from "@/assets/tutorials/tutorial-toggle-custom-banner-cards-groups-page/image-toggle-custom-banner-cards-groups-page-12.png";
import imageCreateCustomBannerCardCall1 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-1.png";
import imageCreateCustomBannerCardCall2 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-2.png";
import imageCreateCustomBannerCardCall3 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-3.png";
import imageCreateCustomBannerCardCall4 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-4.png";
import imageCreateCustomBannerCardCall5 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-5.png";
import imageCreateCustomBannerCardCall6 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-6.png";
import imageCreateCustomBannerCardCall7 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-7.png";
import imageCreateCustomBannerCardCall8 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-8.png";
import imageCreateCustomBannerCardCall9 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-9.png";
import imageCreateCustomBannerCardCall10 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-10.png";
import imageCreateCustomBannerCardCall11 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-11.png";
import imageCreateCustomBannerCardCall12 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-12.png";
import imageCreateCustomBannerCardCall13 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-13.png";
import imageCreateCustomBannerCardCall14 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-14.png";
import imageCreateCustomBannerCardCall15 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-15.png";
import imageCreateCustomBannerCardCall16 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-16.png";
import imageCreateCustomBannerCardCall17 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-17.png";
import imageCreateCustomBannerCardCall18 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-18.png";
import imageCreateCustomBannerCardCall19 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-19.png";
import imageCreateCustomBannerCardCall20 from "@/assets/tutorials/tutorial-create-custom-banner-card-call/image-create-custom-banner-card-call-20.png";
import imageCreateCustomBannerCardWebsite1 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-1.png";
import imageCreateCustomBannerCardWebsite2 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-2.png";
import imageCreateCustomBannerCardWebsite3 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-3.png";
import imageCreateCustomBannerCardWebsite4 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-4.png";
import imageCreateCustomBannerCardWebsite5 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-5.png";
import imageCreateCustomBannerCardWebsite6 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-6.png";
import imageCreateCustomBannerCardWebsite7 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-7.png";
import imageCreateCustomBannerCardWebsite8 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-8.png";
import imageCreateCustomBannerCardWebsite9 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-9.png";
import imageCreateCustomBannerCardWebsite10 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-10.png";
import imageCreateCustomBannerCardWebsite11 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-11.png";
import imageCreateCustomBannerCardWebsite12 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-12.png";
import imageCreateCustomBannerCardWebsite13 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-13.png";
import imageCreateCustomBannerCardWebsite14 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-14.png";
import imageCreateCustomBannerCardWebsite15 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-15.png";
import imageCreateCustomBannerCardWebsite16 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-16.png";
import imageCreateCustomBannerCardWebsite17 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-17.png";
import imageCreateCustomBannerCardWebsite18 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-18.png";
import imageCreateCustomBannerCardWebsite19 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-19.png";
import imageCreateCustomBannerCardWebsite20 from "@/assets/tutorials/tutorial-create-custom-banner-card-website/image-create-custom-banner-card-website-20.png";
import imageCreateCustomBannerCardMessage1 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-1.png";
import imageCreateCustomBannerCardMessage2 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-2.png";
import imageCreateCustomBannerCardMessage3 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-3.png";
import imageCreateCustomBannerCardMessage4 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-4.png";
import imageCreateCustomBannerCardMessage5 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-5.png";
import imageCreateCustomBannerCardMessage6 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-6.png";
import imageCreateCustomBannerCardMessage7 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-7.png";
import imageCreateCustomBannerCardMessage8 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-8.png";
import imageCreateCustomBannerCardMessage9 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-9.png";
import imageCreateCustomBannerCardMessage10 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-10.png";
import imageCreateCustomBannerCardMessage11 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-11.png";
import imageCreateCustomBannerCardMessage12 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-12.png";
import imageCreateCustomBannerCardMessage13 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-13.png";
import imageCreateCustomBannerCardMessage14 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-14.png";
import imageCreateCustomBannerCardMessage15 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-15.png";
import imageCreateCustomBannerCardMessage16 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-16.png";
import imageCreateCustomBannerCardMessage17 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-17.png";
import imageCreateCustomBannerCardMessage18 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-18.png";
import imageCreateCustomBannerCardMessage19 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-19.png";
import imageCreateCustomBannerCardMessage20 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-20.png";
import imageCreateCustomBannerCardMessage21 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-21.png";
import imageCreateCustomBannerCardMessage22 from "@/assets/tutorials/tutorial-create-custom-banner-card-message/image-create-custom-banner-card-message-22.png";
import imageDeleteCustomBannerCard1 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-1.png";
import imageDeleteCustomBannerCard2 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-2.png";
import imageDeleteCustomBannerCard3 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-3.png";
import imageDeleteCustomBannerCard4 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-4.png";
import imageDeleteCustomBannerCard5 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-5.png";
import imageDeleteCustomBannerCard6 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-6.png";
import imageDeleteCustomBannerCard7 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-7.png";
import imageDeleteCustomBannerCard8 from "@/assets/tutorials/tutorial-delete-custom-banner-card/image-delete-custom-banner-card-8.png";


export const completeCategoriesArr = [
	{
		caseName: 'groups',
		iconDescription: 'fad fa-users-class',
		tutorialTitle: 'Groups',
		searchTerm: 'Group'
	},
	{
		caseName: 'healthPlans',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'HealthPlans',
		searchTerm: 'HealthPlan'
	},
	{
		caseName: 'networks',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Networks',
		searchTerm: 'Network'
	},
	{
		caseName: 'members',
		iconDescription: 'fad fa-users',
		tutorialTitle: 'Members',
		searchTerm: 'Member'
	},
	{
		caseName: 'idCards',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'ID Cards',
		searchTerm: 'ID'
	},
	{
		caseName: 'documents',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Documents',
		searchTerm: 'Document'
	},
	{
		caseName: 'appConfig',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'App Configuration',
		searchTerm: 'App'
	}
];
									
export const completeTutorialsArr = [
	{
		caseName: 'createGroup',
		iconDescription: 'fad fa-users-class',
		tutorialTitle: 'Create A Group',
		categories: 'groups',
		content: {
			description: 'A Group can contain multiple HealthPlans and a designated app configuration. Every member is part of a Group.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can add a Group and search for a Group.',
					img: imageCreateGroup1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on ADD GROUP',
					body: 'Clicking on Add Group will cause the New Group window to appear. On the New Group window, you can fill out important group details and determine how member data is handled.',
					img: imageCreateGroup2,
					alternateText: 'Click on ADD GROUP'
				},
				2: {
					stepTitle: '3. Enter your Groups name into the Group Name text box',
					body: 'A Group Name will be searchable on the Groups page.',
					img: imageCreateGroup3,
					alternateText: 'Enter your Groups name into the Group Name text box'
				},
				3: {
					stepTitle: '4. Enter a Group ID into the Group ID text box',
					body: 'The Group ID will determine which Group a member is associated with and will be used when signing up for the CareValet app.',
					img: imageCreateGroup4,
					alternateText: '4. Enter a Group ID into the Group ID text box'
				},
				4: {
					stepTitle: '5. Enter a Group start date into the Effective Date text box',
					body: 'The Effective Date is when a Group and all of its attached HealthPlans Members will be activated.',
					img: imageCreateGroup5,
					alternateText: ''
				},
				5: {
					stepTitle: '6. Enter a Group end date into the Renewal Date text box',
					body: 'he Renewal Date is when a Group and all of its attached HealthPlans Members will be deactivated.',
					img: imageCreateGroup6,
					alternateText: 'Enter a Group end date into the Renewal Date text box'
				},
				6: {
					stepTitle: '7. Enter a Group contact name into the Contact Name text box',
					body: 'The Contact Name will be the main contact for any Group related needs.',
					img: imageCreateGroup7,
					alternateText: 'Enter a Group contact name into the Contact Name text box'
				},
				7: {
					stepTitle: '8. Enter the Group contacts phone number into the Contact Phone text box',
					body: 'The Contact Phone will be the contact number for any Group related needs.',
					img: imageCreateGroup8,
					alternateText: 'Enter the Group contacts phone number into the Contact Phone text box'
				},
				8: {
					stepTitle: '9. Enter the Group contacts email address into into the Contact Email text box',
					body: 'The Contact Email will be the email address for any Group related needs.',
					img: imageCreateGroup9,
					alternateText: 'Enter the Group contacts email address into into the Contact Email text box'
				},
				9: {
					stepTitle: '10. Click on Member Data drop down button',
					body: 'The Member Data drop down button will allow you to decide how to manage your member data.',
					img: imageCreateGroup10,
					alternateText: 'Click on Member Data drop down button'
				},
				10: {
					stepTitle: '11. Click on Import Member Data via Files',
					body: 'Importing Member Data Via Files is preferred for large groups or initially seeding a group with data. You cannot manually edit any members in this group while this setting is active. You can switch to manual entry later.',
					img: imageCreateGroup11,
					alternateText: 'Click on Import Member Data via Files'
				},
				11: {
					stepTitle: '12. Notice the description of the Import Member Data Via Files setting',
					body: '',
					img: imageCreateGroup12,
					alternateText: 'Notice the description of the Import Member Data Via Files setting'
				},
				12: {
					stepTitle: '13. Click on Member Data drop down button',
					body: 'The Member Data drop down button will allow you to decide how to manage your member data.',
					img: imageCreateGroup13,
					alternateText: 'Click on Member Data drop down button'
				},
				13: {
					stepTitle: '14. Click on Manually Manage Member Data',
					body: 'Manually Manage Member Data is preferred for small groups. You can manually create and edit or term any members in this group while this setting is active.',
					img: imageCreateGroup14,
					alternateText: 'Click on Manually Manage Member Data'
				},
				14: {
					stepTitle: '15. Notice the description of the Manually Manage Member Data setting',
					body: '',
					img: imageCreateGroup15,
					alternateText: 'Notice the description of the Manually Manage Member Data setting'
				},
				15: {
					stepTitle: '16. Click on SAVE',
					body: 'Clicking on SAVE will create this Group based on the information entered on the New Group window. This new Group will appear on the Group list on the Groups page. This Groups ID will now be selectable when creating a member.',
					img: imageCreateGroup16,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'createHealthPlan',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'Create A HealthPlan',
		categories: 'healthPlans',
		content: {
			description: 'A HealthPlan is part of a Group and has an assigned network. Every member is part of a specific HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on the HealthPlans button will take you to the HealthPlans page. Where you can add a HealthPlan and search for a HealthPlan.',
					img: imageCreateHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Click on ADD PLAN',
					body: 'Clicking on Add Plan will cause the Create HealthPlan window to appear. On the Create HealthPlan window you can fill out important HealthPlan details.',
					img: imageCreateHealthplan2,
					alternateText: 'Click on ADD PLAN'
				},
				2: {
					stepTitle: '3. Click on Create New',
					body: 'Clicking on Create New will bring you to the HealthPlan Details window.',
					img: imageCreateHealthplan3,
					alternateText: 'Click on Create New'
				},
				3: {
					stepTitle: '4. Enter your HealthPlans name into the HealthPlan Name textbox',
					body: 'A HealthPlans name will be searchable on the Groups page.',
					img: imageCreateHealthplan4,
					alternateText: 'Enter your HealthPlans name into the HealthPlan Name textbox'
				},
				4: {
					stepTitle: '5. Enter a HealthPlan ID into the HealthPlan ID text box',
					body: 'The HealthPlan ID will determine which HealthPlan a member is associated with.',
					img: imageCreateHealthplan5,
					alternateText: 'Enter a HealthPlan ID into the HealthPlan ID text box'
				},
				5: {
					stepTitle: '6. Enter the HealthPlans primary contact number into the Primary Phone Number text box',
					body: 'The Primary Phone Number will be the main contact for any HealthPlan related needs.',
					img: imageCreateHealthplan6,
					alternateText: 'Enter the HealthPlans primary contact number into the Primary Phone Number text box'
				},
				6: {
					stepTitle: '7. Enter the HealthPlans Primary Website URL into the Primary Website URL text box',
					body: 'The Primary Website URL will be the Primary Website used for any HealthPlan related needs.',
					img: imageCreateHealthplan7,
					alternateText: 'Enter the HealthPlans Primary Website URL into the Primary Website URL text box'
				},
				7: {
					stepTitle: '8. Click on Next',
					body: 'Clicking on Next will take you to the Network Setup window.',
					img: imageCreateHealthplan8,
					alternateText: 'Click on Next'
				},
				8: {
					stepTitle: '9. Click on Request New Network',
					body: 'Clicking on the Request New Network button will bring up a list of Network options available to you.',
					img: imageCreateHealthplan9,
					alternateText: 'Click on Request New Network'
				},
				9: {
					stepTitle: '10. Select an existing network',
					body: 'This will be the network tied to this HealthPlan',
					img: imageCreateHealthplan10,
					alternateText: 'Select an existing network'
				},
				10: {
					stepTitle: '11. Click on Next',
					body: 'Clicking on Next will take you to the Telehealth Details window',
					img: imageCreateHealthplan11,
					alternateText: 'Click on Next'
				},
				11: {
					stepTitle: '12. Click on Next',
					body: 'Clicking on Next will take you to the ID Card window',
					img: imageCreateHealthplan12,
					alternateText: 'Click on Next'
				},
				12: {
					stepTitle: '13. Click on Save',
					body: 'Clicking on Save will create the HealthPlan and add your HealthPlan to the HealthPlans list on the HealthPlans page.',
					img: imageCreateHealthplan13,
					alternateText: 'Click on Save'
				}
			}
		}
	},
	{
		caseName: 'createSingleNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Create A Single Network',
		categories: 'networks',
		content: {
			description:
				'A traditional single Network is set up to have an in-network and an out-network within the CareValet app. A network is assigned to a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will take you to the Networks page. Where you can add a Network and search for a Network.',
					img: imageCreateSingleNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: '2. Click on REQUEST NETWORK',
					body: 'Clicking on REQUEST NETWORK will cause the Network Setup window to appear. On the Network Setup window, you can choose your Network Type, Request a New Network or use an existing one. As well as fill out important Network details.',
					img: imageCreateSingleNetwork2,
					alternateText: 'Click on REQUEST NETWORK'
				},
				2: {
					stepTitle: '3. Enter your Network name into the Network Name text box',
					body: 'A Network Name will be searchable on the Networks page.',
					img: imageCreateSingleNetwork3,
					alternateText: 'Enter your Network name into the Network Name text box'
				},
				3: {
					stepTitle: '4. Enter a Network contact name into the Network Contact Name text box',
					body: 'The Network Contact Name will be the main contact for any Networks related needs.',
					img: imageCreateSingleNetwork4,
					alternateText: 'Enter a Network contact name into the Network Contact Name text box'
				},
				4: {
					stepTitle: '5. Enter the Network contacts email address into the Network Contact Email text box',
					body: 'The Network Contact Email will be the email address for any Network related needs.',
					img: imageCreateSingleNetwork5,
					alternateText: 'Enter the Network contacts email address into the Network Contact Email text box'
				},
				5: {
					stepTitle: '6. Enter the Network contacts phone number into the Network Contact Phone Number text box',
					body: 'The Network Contact Phone Number will be the contact number for any Network related needs.',
					img: imageCreateSingleNetwork6,
					alternateText: 'Enter the Network contacts phone number into the Network Contact Phone Number text box'
				},
				6: {
					stepTitle: '7. Enter Request Details into the Request Details text box',
					body: 'Any additional Network details should be entered here.',
					img: imageCreateSingleNetwork7,
					alternateText: 'Enter Request Details into the Request Details text box'
				},
				7: {
					stepTitle: '8. Click on Next',
					body: 'Clicking on Next will create your Network and add it to the Networks list on the Networks page.',
					img: imageCreateSingleNetwork8,
					alternateText: 'Click on Next'
				}
			}
		}
	},
	{
		caseName: 'createTieredNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Create A Tiered Network',
		categories: 'networks',
		content: {
			description:
				'A tiered Network is set up to have multiple tiers of in-network and an out-network within the CareValet app. A Network is assigned to a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will take you to the Networks page. Where you can add a Network and search for a Network.',
					img: imageCreateTieredNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: '2. Click on REQUEST NETWORK',
					body: 'Clicking on REQUEST NETWORK will cause the Network Setup window to appear. On the Network Setup window, you can choose your Network Type, Request a New Network or use an existing one. As well as fill out important Network details.',
					img: imageCreateTieredNetwork2,
					alternateText: 'Click on REQUEST NETWORK'
				},
				2: {
					stepTitle: '3. Click on the Network Type drop down button',
					body: 'Clicking on the Network Type drop-down button will bring up a list of Network Types.',
					img: imageCreateTieredNetwork3,
					alternateText: 'CClick on the Network Type drop down button'
				},
				3: {
					stepTitle: '4. Click on Tiered - Multiple Provider Networks',
					body: 'Clicking on Tiered will cause the Network Setup Window to change to allow for a Tiered Network Setup.',
					img: imageCreateTieredNetwork4,
					alternateText: 'Click on Tiered - Multiple Provider Networks'
				},
				4: {
					stepTitle: '5. Enter your Network name into the Network Name text box',
					body: 'A Network Name will be searchable on the Networks page.',
					img: imageCreateTieredNetwork5,
					alternateText: 'Enter your Network name into the Network Name text box'
				},
				5: {
					stepTitle: '6. Click on Add Networks to Tier',
					body: 'Clicking on Add Networks to Tier will bring up the Configure Tier 1 window and allow you to select a Network to tie to this Network tier.',
					img: imageCreateTieredNetwork6,
					alternateText: 'Click on Add Networks to Tier'
				},
				6: {
					stepTitle: '7. Click on Select Tier 1 Networks button',
					body: 'Clicking on the Select Tier 1 Networks button will cause a list of available networks to appear.',
					img: imageCreateTieredNetwork7,
					alternateText: 'Click on Select Tier 1 Networks button'
				},
				7: {
					stepTitle: '8. Click on a Network',
					body: 'Clicking on a network will bind it to this tier of your tiered Network.',
					img: imageCreateTieredNetwork8,
					alternateText: 'Click on a Network'
				},
				8: {
					stepTitle: '9. Click on CONTINUE',
					body: 'Clicking on CONTINUE will confirm your tier one Network configuration and take you back to the Network Setup window.',
					img: imageCreateTieredNetwork9,
					alternateText: 'Click on CONTINUE'
				},
				9: {
					stepTitle: '10. Click on Add Another Tier',
					body: 'Clicking on Add Another to Tier will bring up the Configure Tier 2 window and allow you to select a Network to tie to this Network tier.',
					img: imageCreateTieredNetwork10,
					alternateText: 'Click on Add Another Tier'
				},
				10: {
					stepTitle: '11. Click on Select Tier 2 Networks button',
					body: 'Clicking on the Select Tier 2 Networks button will cause a list of available networks to appear.',
					img: imageCreateTieredNetwork11,
					alternateText: 'Click on Select Tier 2 Networks button'
				},
				11: {
					stepTitle: '12. Click on a Network',
					body: 'Clicking on a network will bind it to this tier of your tiered Network.',
					img: imageCreateTieredNetwork12,
					alternateText: 'Click on a Network'
				},
				12: {
					stepTitle: '13. Click on the Select Tier 2 Color drop down button',
					body: 'Clicking on the Select Tier 2 Color button will bring up a list of colors.',
					img: imageCreateTieredNetwork13,
					alternateText: 'Click on the Select Tier 2 Color drop down button'
				},
				13: {
					stepTitle: '14. Click on Green/Yellow',
					body: 'Clicking on a color will cause the Network chip color for this tier within the CareValet app to change to the select color.',
					img: imageCreateTieredNetwork14,
					alternateText: 'Click on Green/Yellow'
				},
				14: {
					stepTitle: '15. Click on CONTINUE',
					body: 'Clicking on CONTINUE will confirm your tier one Network configuration and take you back to the Network Setup window.',
					img: imageCreateTieredNetwork15,
					alternateText: 'Click on CONTINUE'
				},
				15: {
					stepTitle: '16. Click on Next',
					body: 'Clicking on Next will create your Network and add it to the Networks list on the Networks page.',
					img: imageCreateTieredNetwork16,
					alternateText: ''
				}
			}
		}
	},
	{
		caseName: 'createOpenNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Create An Open Network',
		categories: 'networks',
		content: {
			description:
				'An open Network is set up to have no network boundaries and within the CareValet app. A network is assigned to a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will take you to the Networks page. Where you can add a Network and search for a Network.',
					img: imageCreateOpenNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: '2. Click on REQUEST NETWORK',
					body: 'Clicking on REQUEST NETWORK will cause the Network Setup window to appear. On the Network Setup window, you can choose your Network Type, Request a New Network or use an existing one. As well as fill out important Network details.',
					img: imageCreateOpenNetwork2,
					alternateText: 'Click on REQUEST NETWORK'
				},
				2: {
					stepTitle: '3. Click on the Network Type drop down button',
					body: 'Clicking on the Network Type drop-down button will bring up a list of Network Types.',
					img: imageCreateOpenNetwork3,
					alternateText: 'Click on the Network Type drop down button'
				},
				3: {
					stepTitle: '4. Click on None - All providers should display the same in this network',
					body: 'Clicking on None will cause the Network Setup Window to change to allow for a None Network Setup.',
					img: imageCreateOpenNetwork4,
					alternateText: 'Click on None - All providers should display the same in this network'
				},
				4: {
					stepTitle: '5. Enter your Network name into the Network Name text box',
					body: 'A Network Name will be searchable on the Networks page.',
					img: imageCreateOpenNetwork5,
					alternateText: 'Enter your Network name into the Network Name text box'
				},
				5: {
					stepTitle: '6. Click on the edit provider card button.',
					body: 'Clicking on the edit provider card button will bring up the Configure Other Providers window and allow for network chip modifications.',
					img: imageCreateOpenNetwork6,
					alternateText: 'Click on the edit provider card button.'
				},
				6: {
					stepTitle: '7. Click on the Select Chip Color for All Providers drop down button',
					body: 'Clicking on the Select Chip Color for All Providers drop down button will bring up a list of colors.',
					img: imageCreateOpenNetwork7,
					alternateText: 'Click on the Select Chip Color for All Providers drop down button'
				},
				7: {
					stepTitle: '8. Click on Green',
					body: 'Clicking on a color will cause the Network chip color for this Network within the CareValet app to change to the select color.',
					img: imageCreateOpenNetwork8
					,
					alternateText: 'Click on Green'
				},
				8: {
					stepTitle: '9. Click on the Select Chip Icon drop down button',
					body: 'Clicking on the Select Chip Icon drop down button will cause a list of possible network chip icons to appear.',
					img: imageCreateOpenNetwork9,
					alternateText: 'Click on the Select Chip Icon drop down button'
				},
				9: {
					stepTitle: '10. Click on the Checkmark icon',
					body: 'Clicking on the Checkmark icon will attach this icon to the network chip on the provider card within the CareValet app.',
					img: imageCreateOpenNetwork10,
					alternateText: 'Click on the Checkmark icon'
				},
				10: {
					stepTitle: '11. Enter a network label name into the Label for All Providers Text Box',
					body: 'Entering a network label name will attach this label to the network chip on the provider card within the CareValet app.',
					img: imageCreateOpenNetwork11,
					alternateText: 'Enter a network label name into the Label for All Providers Text Box'
				},
				11: {
					stepTitle: '12. Click on CONTINUE',
					body: 'Clicking on CONTINUE will confirm your  Network configuration and take you back to the Network Setup window.',
					img: imageCreateOpenNetwork12,
					alternateText: 'Click on CONTINUE'
				},
				12: {
					stepTitle: '13. Click on Next',
					body: 'Clicking on Next will create your Network and add it to the Networks list on the Networks page.',
					img: imageCreateOpenNetwork13,
					alternateText: 'Click on Next'
				}
			}
		}
	},
	{
		caseName: 'createMember',
		iconDescription: 'fad fa-users',
		tutorialTitle: 'Create A Member',
		categories: 'members',
		content: {
			description: 'A Member is a user within the CareValet app. A Member is part of a Group and a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Members',
					body: "Clicking on Members will take you to the Member's page. Where you can add a Member and search for a Member.",
					img: imageCreateMember1,
					alternateText: 'Click on members'
				},
				1: {
					stepTitle: '2. Click on ADD MEMBER',
					body: 'Clicking on Add Member will cause the Add Member window to appear. On the Add Member window, you can fill out important Member details.',
					img: imageCreateMember2,
					alternateText: 'Click on ADD MEMBER'
				},
				2: {
					stepTitle: '3. Enter a Member ID into the Member ID text box',
					body: "The Member ID will determine the Member's Member Number and will be used when signing up for the CareValet app.",
					img: imageCreateMember3,
					alternateText: 'Enter a Person Code into the Person Code text box'
				},
				3: {
					stepTitle: '4. Enter a Person Code into the Person Code text box',
					body: 'The Person code is the unique number associated with each member of a family unit.',
					img: imageCreateMember4,
					alternateText: 'Enter a Person Code into the Person Code text box'
				},
				4: {
					stepTitle: "5. Enter the member's first name into the First Name text box.",
					body: "This will be the member's first name throughout Express and the CareValet app.",
					img: imageCreateMember5,
					alternateText: "Enter the member's first name into the First Name text box."
				},
				5: {
					stepTitle: "6. Enter the member's middle name or initial into the Middle Name or Initial text box.",
					body: "This will be the member's middle name throughout Express and the CareValet app.",
					img: imageCreateMember6,
					alternateText: "Enter the member's middle name or initial into the Middle Name or Initial text box."
				},
				6: {
					stepTitle: "7. Enter the member's last name into the Last Name text box.",
					body: "This will be the member's last name throughout Express and the CareValet app.",
					img: imageCreateMember7,
					alternateText: "Enter the member's last name into the Last Name text box."
				},
				7: {
					stepTitle: "8. Enter the member's date of birth into the Date of Birth text box",
					body: "This will be the member's date of birth throughout Express and the CareValet app. The member's date of birth will be required to sign up for the CareValet app.",
					img: imageCreateMember8,
					alternateText: "Enter the member's date of birth into the Date of Birth text box"
				},
				8: {
					stepTitle: '9. Enter a start date into the Start Date text box',
					body: 'The start date is when a Member will be able to access the CareValet app.',
					img: imageCreateMember9,
					alternateText: 'Enter a start date into the Start Date text box'
				},
				9: {
					stepTitle: '10. Enter a term date into the Term Date text box',
					body: 'The term date is when a Member will be lose access to the CareValet app.',
					img: imageCreateMember10,
					alternateText: 'Enter a term date into the Term Date text box'
				},
				10: {
					stepTitle: "11. Enter the member's email address into into the Email text box",
					body: "This email address will be the default email address associated with the member's CareValet account. This is the email address that the member signs into the CareValet app with.",
					img: imageCreateMember11,
					alternateText: "Enter the member's email address into into the Email text box"
				},
				11: {
					stepTitle: "12. Enter the member's phone number into the Contact Phone text box",
					body: "This will be the member's phone number throughout CareValet and Express.",
					img: imageCreateMember12,
					alternateText: "Enter the member's phone number into the Contact Phone text box"
				},
				12: {
					stepTitle: "13. Enter the member's address into the Address Street 1 textbox",
					body: "This will be the member's address throughout CareValet and Express.",
					img: imageCreateMember13,
					alternateText: "Enter the member's address into the Address Street 1 textbox"
				},
				13: {
					stepTitle: "14. Enter the member's city into the City textbox",
					body: "This will be the member's city throughout CareValet and Express.",
					img: imageCreateMember14,
					alternateText: "Enter the member's city into the City textbox"
				},
				14: {
					stepTitle: '15. Click on the State drop down button',
					body: 'You should see a list of states appear',
					img: imageCreateMember15,
					alternateText: 'Click on the State drop down button'
				},
				15: {
					stepTitle: "16. Click on the member's state",
					body: "This will be the member's State throughout CareValet and Express.",
					img: imageCreateMember16,
					alternateText: "Click on the member's state"
				},
				16: {
					stepTitle: "17. Enter the member's postal code into the Postal Code textbox",
					body: "This will be the member's postal code throughout CareValet and Express.",
					img: imageCreateMember17,
					alternateText: "Enter the member's postal code into the Postal Code textbox"
				},
				17: {
					stepTitle: '18. Click on SAVE',
					body: "Clicking on SAVE will create this Member based on the information entered on the Add Member window. This new Member will appear on the Member list on the member's page. This member's ID will be used when entering their Member Number when signing up for the CareValet app.",
					img: imageCreateMember18,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'viewGroupDetails',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'View Group Details',
		categories: 'groups',
		content: {
			description: 'The Group Details will contain important information regarding the group.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageViewGroupDetails1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageViewGroupDetails2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Notice the Group Details',
					body: 'You can edit group details.',
					img: imageViewGroupDetails3,
					alternateText: 'Notice the Group Details'
				}
			}
		}
	},
	{
		caseName: 'editGroupDetails',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'Edit Group Details',
		categories: 'groups',
		content: {
			description: 'Important Group information can be modified in the Edit Group window.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageEditGroupDetails1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageEditGroupDetails2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on the edit group details button',
					body: 'Clicking on the edit group details button will bring up the Edit Group window.',
					img: imageEditGroupDetails3,
					alternateText: 'Click on a Group'
				},
				3: {
					stepTitle: '4. Edit any group details that need to be changed',
					body: 'On the Edit Group window, you can modify the Group Name, Group ID, Effective Date, Renewal Date, Contact Name, Contact Phone, Contact Email.',
					img: imageEditGroupDetails4,
					alternateText: 'Edit any group details that need to be changed'
				},
				4: {
					stepTitle: '5. Click on SAVE',
					body: 'Clicking on SAVE will apply the changes made to the group.',
					img: imageEditGroupDetails5,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'importMemberData',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'Import Member Data',
		categories: 'members, documents',
		content: {
			description: 'Group data can be manually entered or imported. Only one type of inputting member data can be allowed at a time.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageImportMemberData1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageImportMemberData2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on the edit group details button',
					body: 'Clicking on the edit group details button will bring up the Edit Group window.',
					img: imageImportMemberData3,
					alternateText: 'Click on the edit group details button'
				},
				3: {
					stepTitle: '4. Click on the Member Data drop-down button',
					body: 'Clicking on the Member Data drop-down button will bring up a list of different  ways to input member data.',
					img: imageImportMemberData4,
					alternateText: 'Click on the Member Data drop-down button'
				},
				4: {
					stepTitle: '5. Click on Import Member Data via Files',
					body: 'Clicking on Import Member Data via Files will turn off your ability to Manually Manage Member Data and turn on your ability to Import Member Data via Files.',
					img: imageImportMemberData5,
					alternateText: 'Click on Import Member Data via Files'
				},
				5: {
					stepTitle: '6. Click on SAVE',
					body: 'Clicking on SAVE will apply the changes made to the group.',
					img: imageImportMemberData6,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'inputMemberData',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'Manually Input Member Data',
		categories: 'members, documents',
		content: {
			description: 'Group data can be manually entered or imported. Only one type of inputting member data can be allowed at a time.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageInputMemberData1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageInputMemberData2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on the edit group details button',
					body: 'Clicking on the edit group details button will bring up the Edit Group window.',
					img: imageInputMemberData3,
					alternateText: 'Click on the edit group details button'
				},
				3: {
					stepTitle: '4. Click on the Member Data drop-down button',
					body: 'Clicking on the Member Data drop-down button will bring up a list of different ways to input member data.',
					img: imageInputMemberData4,
					alternateText: 'Click on the Member Data drop-down button'
				},
				4: {
					stepTitle: '5. Click on Manually Manage Member Data',
					body: 'Clicking on Manually Manage Member Data will turn off your ability to Import Member Data via Files and turn on your ability to Manually Manage Member Data.',
					img: imageInputMemberData5,
					alternateText: 'Click on Manually Manage Member Data'
				},
				5: {
					stepTitle: '6. Click on SAVE',
					body: 'Clicking on SAVE will apply the changes made to the group.',
					img: imageInputMemberData6,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'manageGroupAppConfig',
		iconDescription: 'fad fa-users-class',
		tutorialTitle: 'Managing App Configurations For Group',
		categories: 'appConfig, groups',
		content: {
			description: 'App configurations can be specified for each individual group.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageManageGroupAppConfig1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageManageGroupAppConfig2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on the App Config tab',
					body: 'Clicking on the App Config tab will bring up Manage Group Configuration.',
					img: imageManageGroupAppConfig3,
					alternateText: 'Click on the App Config tab'
				}
			}
		}
	},
	{
		caseName: 'viewGroupMembers',
		iconDescription: 'fad fa-users',
		tutorialTitle: 'How To View Group Members',
		categories: 'groups, members',
		content: {
			description: 'All Members within a Group can be viewed via the Member tab on the Group Details page',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageViewGroupMembers1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageViewGroupMembers2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on the Members tab',
					body: 'Clicking on the Members tab will bring up a list of Members associated with this Group.',
					img: imageViewGroupMembers3,
					alternateText: 'Click on the Members tab'
				}
			}
		}
	},
	{
		caseName: 'changeHealthPlanNetworkGroupsPage',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to change a Network for a HealthPlan from the Groups Page',
		categories: 'healthPlans, networks, groups',
		content: {
			description:
				"If a HealthPlans Network needs to be changed. You can change the network from the Network Setup window on the Group's page.",
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageChangeHealthplanNetworkGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageChangeHealthplanNetworkGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageChangeHealthplanNetworkGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the Network Setup button',
					body: 'The Network Setup button will take you to the Network Setup window. The Network Setup window is where you can change your Network type.',
					img: imageChangeHealthplanNetworkGroupsPage4,
					alternateText: 'Click on the Network Setup button'
				},
				4: {
					stepTitle: '5. Click on the Network Type drop down button',
					body: 'Clicking on Network Type will bring up the Network Type list. On the Network Type list, you can choose from a Traditional Network, Tiered Network, and a None Network.',
					img: imageChangeHealthplanNetworkGroupsPage5,
					alternateText: 'Click on the Network Type drop down button'
				},
				5: {
					stepTitle: '6. Click on a Network type',
					body: 'Clicking on a Network type will transform the setup window to allow for setting up the chosen Network Type.',
					img: imageChangeHealthplanNetworkGroupsPage6,
					alternateText: 'Click on a Network type'
				},
				6: {
					stepTitle: '7. Click on the Request New Network drop down button',
					body: 'This will bring up a list of available Networks of the chosen Network Type.',
					img: imageChangeHealthplanNetworkGroupsPage7,
					alternateText: 'Click on the Request New Network drop down button'
				},
				7: {
					stepTitle: '8. Click on a network',
					body: 'This Network will be the chosen Network for this HealthPlan. Network details will be filled in based on the chosen Network.',
					img: imageChangeHealthplanNetworkGroupsPage8,
					alternateText: 'Click on a network'
				},
				8: {
					stepTitle: '9. Click on Next',
					body: 'Clicking on Next will save the Network changes made to the selected HealthPlan.',
					img: imageChangeHealthplanNetworkGroupsPage9,
					alternateText: 'Click on Next'
				}
			}
		}
	},
	{
		caseName: 'viewIdCardHealthPlanGroupsPage',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to view the ID Card for a HealthPlan from the Groups Page',
		categories: 'groups, healthPlans, idCards',
		content: {
			description: 'On the Groups page, you can view the front and back of an ID card for a selected HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageViewIdCardHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageViewIdCardHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageViewIdCardHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the ID Cards tab',
					body: 'Clicking on ID Cards will bring up the ID Cards tab for this HealthPlan.',
					img: imageViewIdCardHealthplanGroupsPage4,
					alternateText: 'Click on the ID Cards tab'
				},
				4: {
					stepTitle: '5. HealthPlan ID Cards tab',
					body: 'On the ID Cards tab, you can swap a HealthPlans ID Card by clicking on the change ID cards button. You can also choose which side of an ID card to edit by clicking on the front or back of the ID card.',
					img: imageViewIdCardHealthplanGroupsPage5,
					alternateText: 'HealthPlan ID Cards tab'
				}
			}
		}
	},
	{
		caseName: 'editBackIdHealthPlanGroupsPage',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to edit the back of an ID Card for a HealthPlan from the Groups page',
		categories: 'groups, healthPlans, idCards',
		content: {
			description: 'On the Groups page, you can view the front and back of an ID card for a selected HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageEditBackIdHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageEditBackIdHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageEditBackIdHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the ID Cards tab',
					body: 'Clicking on ID Cards will bring up the ID Cards tab for this HealthPlan.',
					img: imageEditBackIdHealthplanGroupsPage4,
					alternateText: 'Click on the ID Cards tab'
				},
				4: {
					stepTitle: '5. Click on the back of the ID Card',
					body: 'Clicking on the back of the ID Card will take you to the ID Card page where you can modify the back of the card for this HealthPlan.',
					img: imageEditBackIdHealthplanGroupsPage5,
					alternateText: 'Click on the back of the ID Card'
				},
				5: {
					stepTitle: '6. On the ID Cards page you can make your edits and click Save',
					body: 'Clicking on Save will save your ID card changes to the HealthPlan.',
					img: imageEditBackIdHealthplanGroupsPage6,
					alternateText: 'On the ID Cards page you can make your edits and click Save'
				}
			}
		}
	},
	{
		caseName: 'editFrontIdHealthPlanGroupsPage',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to edit the front of an ID Card for a HealthPlan from the Groups page',
		categories: 'groups, healthPlans, idCards',
		content: {
			description: 'From the Groups page, you can select an attached HealthPlans ID Card for editing.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageEditFrontIdHealthPlanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageEditFrontIdHealthPlanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageEditFrontIdHealthPlanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the ID Cards tab',
					body: 'Clicking on ID Cards will bring up the ID Cards tab for this HealthPlan.',
					img: imageEditFrontIdHealthPlanGroupsPage4,
					alternateText: 'Click on the ID Cards tab'
				},
				4: {
					stepTitle: '5. Click on the front of the ID Card',
					body: 'Clicking on the front of the ID Card will take you to the ID Card page where you can modify the front of the card for this HealthPlan.',
					img: imageEditFrontIdHealthPlanGroupsPage5,
					alternateText: 'Click on the front of the ID Card'
				},
				5: {
					stepTitle: '6. On the ID Cards page you can make your edits and click Save',
					body: 'Clicking on Save will save your ID card changes to the HealthPlan.',
					img: imageEditFrontIdHealthPlanGroupsPage6,
					alternateText: 'On the ID Cards page you can make your edits and click Save'
				}
			}
		}
	},
	{					
		caseName: 'swapIdHealthPlanGroupsPage',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to swap an ID Card for a HealthPlan from the Groups page',
		categories: 'groups, healthPlans, idCards',
		content: {
			description: 'From the Groups page, you can swap an attached HealthPlans ID Card via the Change ID Cards window.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageSwapIdHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageSwapIdHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageSwapIdHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Link to page Click on the ID Cards tab',
					body: 'Clicking on ID Cards will bring up the ID Cards tab for this HealthPlan.',
					img: imageSwapIdHealthplanGroupsPage4,
					alternateText: 'Link to page  Click on the ID Cards tab'
				},
				4: {
					stepTitle: '5. Click on the Change ID Cards button',
					body: 'Clicking on the Change ID Card button will take you to the Change ID Card window. From the Change ID Card window, you can swap ID Cards for this HealthPlan.',
					img: imageSwapIdHealthplanGroupsPage5,
					alternateText: 'Click on the Change ID Cards button'
				},
				5: {
					stepTitle: '6. Click on the ID Card Template - Front drop down button',
					body: 'This will bring up a list of available Front ID Card templates.',
					img: imageSwapIdHealthplanGroupsPage6,
					alternateText: 'Click on the ID Card Template - Front drop down button'
				},
				6: {
					stepTitle: '7. Select a Front ID Card Template',
					body: 'This choice will determine which Front ID Card template this HealthPlan will use.',
					img: imageSwapIdHealthplanGroupsPage7,
					alternateText: 'Select a Front ID Card Template'
				},
				7: {
					stepTitle: '8. Click on the ID Card Template - Back drop down button',
					body: 'This will bring up a list of available Back ID Card templates.',
					img: imageSwapIdHealthplanGroupsPage8,
					alternateText: 'Click on the ID Card Template - Back drop down button'
				},
				8: {
					stepTitle: '9. Select a Back ID Card Template',
					body: 'This choice will determine which Front ID Card template this HealthPlan will use.',
					img: imageSwapIdHealthplanGroupsPage9,
					alternateText: 'Select a Back ID Card Template'
				},
				9: {
					stepTitle: '10. Click on SAVE',
					body: 'Clicking on Save will save your ID card changes to the HealthPlan.',
					img: imageSwapIdHealthplanGroupsPage10,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{					
		caseName: 'addFirstDocToOrg',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Adding your first document to your Organization',
		categories: 'documents',
		content: {
			description: 'You can upload documents to be used throughout Express for the selected organization.',
			steps: {
				0: {
					stepTitle: '1. Click on Documents',
					body: 'Clicking on Documents will bring you to the Documents page. On the Documents page, you can add documents to your organization.',
					img: imageAddFirstDocToOrg1,
					alternateText: 'Click on Documents'
				},
				1: {
					stepTitle: '2. Click on Add your first document',
					body: 'Clicking on Add your first document will bring up the New Document window. On the New Document window, you can upload, name, and choose the type of document you are uploading.',
					img: imageAddFirstDocToOrg2,
					alternateText: 'Click on Add your first document'
				},
				2: {
					stepTitle: '3. Click on Click or drop files here',
					body: 'This will bring up your file explorer and you can choose what file to upload.',
					img: imageAddFirstDocToOrg3,
					alternateText: 'Click on Click or drop files here'
				},
				3: {
					stepTitle: '4. After uploading your file it will be added to the New Document window',
					body: 'Your files name and type will be shown on the New Document window.',
					img: imageAddFirstDocToOrg4,
					alternateText: 'After uploading your file it will be added to the New Document window'
				},
				4: {
					stepTitle: '5. Click on the Choose the type of document drop down button',
					body: 'This will bring up a list of document types. Currently, these are Statement of Benefits and Other.',
					img: imageAddFirstDocToOrg5,
					alternateText: 'Click on the Choose the type of document drop down button'
				},
				5: {
					stepTitle: '6. Click on a document type',
					body: 'This will set this documents type in Express.',
					img: imageAddFirstDocToOrg6,
					alternateText: 'Click on the Choose the type of document drop down button'
				},
				6: {
					stepTitle: '7. Enter the Documents name.',
					body: 'This is the Name the document will have within Express.',
					img: imageAddFirstDocToOrg7,
					alternateText: 'Enter the Documents name.'
				},
				7: {
					stepTitle: '8. Click on SAVE',
					body: 'Clicking on Save will save your Document to the Organization.',
					img: imageAddFirstDocToOrg8,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'addDocToHealthPlanGroupsPage',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Adding a document to a HealthPlan from the Groups page',
		categories: 'groups, healthPlans, documents',
		content: {
			description:
				"From the Groups page, you can add a Document to the selected HealthPlan. Documents attached to a HealthPlan are accessible via a user's Benefits Wallet.",
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageAddDocToHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageAddDocToHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageAddDocToHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the Documents tab',
					body: 'Clicking on Documents will bring up the Documents Tab. From the Documents tab, you can add new Documents to this HealthPlan as well as see Documents already attached to this HealthPlan.',
					img: imageAddDocToHealthplanGroupsPage4,
					alternateText: 'Click on the Documents tab'
				},
				4: {
					stepTitle: '5. Click on the Add Document button',
					body: 'Clicking on the Add Document button will bring up the Add Document window. On the Add Document window, you can add a document to this HealthPlan.',
					img: imageAddDocToHealthplanGroupsPage5,
					alternateText: 'Click on the Add Document button'
				},
				5: {
					stepTitle: '6. Click on the +',
					body: 'This will add the document to this HealthPlan. The HealthPlans users within the CareValet app can now access this document via their Benefits Wallet.',
					img: imageAddDocToHealthplanGroupsPage6,
					alternateText: 'Click on the plus'
				}
			}
		}
	},
	{
		caseName: 'viewDocHealthPlanGroupsPage',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Viewing a document attached to a HealthPlan from the Groups page',
		categories: 'groups, healthPlans',
		content: {
			description: 'From the Groups page, you can view a Document attached to the selected HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageViewDocHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageViewDocHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageViewDocHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the Documents tab',
					body: 'Clicking on Documents will bring up the Documents Tab. From the Documents tab, you can add new Documents to this HealthPlan as well as see Documents already attached to this HealthPlan.',
					img: imageViewDocHealthplanGroupsPage4,
					alternateText: 'Click on the Documents tab'
				},
				4: {
					stepTitle: '5. Click on the View Document button',
					body: 'Clicking on the View Document button will bring up the Document Preview page. On the Document Preview page, you can look through the uploaded document.',
					img: imageViewDocHealthplanGroupsPage5,
					alternateText: 'Click on the View Document button'
				}
			}
		}
	},
	{
		caseName: 'removeDocHealthPlanGroupsPage',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Removing a document for a HealthPlan from the Groups page',
		categories: 'groups, documents, healthPlans',
		content: {
			description:
				'From the Groups page, you can remove a Document attached to the selected HealthPlan. Removing a Document from a HealthPlan will cause users within this HealthPlan to no longer see this document in their Benefits Wallet.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',					
					img: imageRemoveDocHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageRemoveDocHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageRemoveDocHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the Documents tab',
					body: 'Clicking on Documents will bring up the Documents Tab. From the Documents tab, you can add new Documents to this HealthPlan as well as see Documents already attached to this HealthPlan.',
					img: imageRemoveDocHealthplanGroupsPage4,
					alternateText: 'Click on the Documents tab'
				},
				4: {
					stepTitle: '5. Click on the Delete Document button',
					body: 'Clicking on the View Document button will prompt you with the choice to remove the document from the HealthPlan or to cancel the action.',
					img: imageRemoveDocHealthplanGroupsPage5,
					alternateText: 'Click on the Delete Document button'
				},
				5: {
					stepTitle: '6. Click on OK',
					body: 'Clicking on OK will remove the document from this HealthPlan. Users within this HealthPlan will no longer see this document in their Benefits Wallet.',
					img: imageRemoveDocHealthplanGroupsPage6,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'viewDocsAttachedToHealthPlanGroupsPage',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'How to see what documents are attached to a HealthPlan from the Groups page',
		categories: 'groups, healthPlans, documents',
		content: {
			description: 'For HealthPlans with documents already attached to them, you can view them under the Documents tab.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageViewDocAttachedToHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageViewDocAttachedToHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageViewDocAttachedToHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the Documents tab',
					body: 'Clicking on Documents will bring up the Documents Tab.',
					img: imageViewDocAttachedToHealthplanGroupsPage4,
					alternateText: 'Click on the Documents tab'
				},
				4: {
					stepTitle: '5. The Documents list',
					body: 'On the Documents list, you will see which Documents are attached to this HealthPlan.',
					img: imageViewDocAttachedToHealthplanGroupsPage5,
					alternateText: 'The Documents list'
				}
			}
		}
	},
	{
		caseName: 'searchWhenAddingDocToHealthPlanGroupsPage',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Using Search when adding a Document to the HealthPlan from the Groups page',
		categories: 'groups, healthPlans, documents',
		content: {
			description:
				'If you have many documents attached to an organization and need to search for a document to add to a HealthPlan you can do this within the Add Document window.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageSearchWhenAddingDocToHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageSearchWhenAddingDocToHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageSearchWhenAddingDocToHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the Documents tab',
					body: 'Clicking on Documents will bring up the Documents Tab. From the Documents tab, you can add new Documents to this HealthPlan as well as see Documents already attached to this HealthPlan.',
					img: imageSearchWhenAddingDocToHealthplanGroupsPage4,
					alternateText: 'Click on the Documents tab'
				},
				4: {
					stepTitle: '5. Click on the Add Document button',
					body: 'Clicking on the Add Document button will bring up the Add Document window. On the Add Document window, you can add a document to this HealthPlan.',
					img: imageSearchWhenAddingDocToHealthplanGroupsPage5,
					alternateText: 'Click on the Add Document button'
				},
				5: {
					stepTitle: '6. Enter the name of the Document you are searching for that is attached to this organization',
					body: 'All documents not matching the entered text will be removed from the document list within the Add Document window.',
					img: imageSearchWhenAddingDocToHealthplanGroupsPage6,
					alternateText: 'Enter the name of the Document you are searching for that is attached to this organization'
				},
				6: {
					stepTitle: '7. Clear the name from the search box',
					body: 'The documents that did not match the text will reappear with a cleared search box.',
					img: imageSearchWhenAddingDocToHealthplanGroupsPage7,
					alternateText: 'Click on Statement Of Benefits'
				}
			}
		}
	},
	{
		caseName: 'gettingToAppConfigHealthPlanGroupPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'Getting to the App Config tab for a HealthPlan from the Groups page',
		categories: 'groups, healthPlans, appConfig',
		content: {
			description:
				'A HealthPlans App Configuration can be modified from the App Config tab under the selected HealthPlan on the Groups page',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageGettingToAppConfigHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageGettingToAppConfigHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageGettingToAppConfigHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the App Config tab',
					body: 'Clicking on Documents will bring up the App Config Tab for the selected HealthPlan.',
					img: imageGettingToAppConfigHealthplanGroupsPage4,
					alternateText: 'Click on the App Config tab'
				}
			}
		}
	},
	{
		caseName: 'providerProfilePlanConfigsGroupPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'Managing Provider Profile Plan Configurations from the Groups page',
		categories: 'groups, appConfig',
		content: {
			description:
				'A HealthPlans Provider Profile App Configuration can be modified from the App Config tab under the selected HealthPlan on the Groups page',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageProviderProfilePlanConfigGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageProviderProfilePlanConfigGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageProviderProfilePlanConfigGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the App Config tab',
					body: 'Clicking on Documents will bring up the App Config Tab for the selected HealthPlan.',
					img: imageProviderProfilePlanConfigGroupsPage4,
					alternateText: 'Click on the App Config tab'
				},
				4: {
					stepTitle: '5. Click on PROVIDER PROFILE',
					body: 'Clicking on Provider Profile will bring up the configurations available on the Provider Profile page of the CareValet app.',
					img: imageProviderProfilePlanConfigGroupsPage5,
					alternateText: 'Click on PROVIDER PROFILE'
				}
			}
		}
	},
	{
		caseName: 'homePagePlanConfigsGroupPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'Managing Home Page Plan Configurations from the Groups page',
		categories: 'groups, appConfig',
		content: {
			description:
				'A HealthPlans Home Page App Configuration can be modified from the App Config tab under the selected HealthPlan on the Groups page.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageHomePagePlanConfigGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageHomePagePlanConfigGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Click on Select a HealthPlan from the HealthPlan listNetworkSupport AccountPending',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageHomePagePlanConfigGroupsPage3,
					alternateText: 'Click on Select a HealthPlan from the HealthPlan listNetworkSupport AccountPending'
				},
				3: {
					stepTitle: '4. Click on the App Config tab',
					body: 'Clicking on Documents will bring up the App Config Tab for the selected HealthPlan.',
					img: imageHomePagePlanConfigGroupsPage4,
					alternateText: 'Click on the App Config tab'
				},
				4: {
					stepTitle: '5. Click on HOME PAGE',
					body: 'Clicking on Home Page will bring up the configurations available on the Home page of the CareValet app.',
					img: imageHomePagePlanConfigGroupsPage5,
					alternateText: 'Click on HOME PAGE'
				}
			}
		}
	},
	{
		caseName: 'providerFinderPlanConfigsGroupPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'Managing Provider Finder Plan Configurations from the Groups page',
		categories: 'groups, appConfig',
		content: {
			description:
				'A HealthPlans Provider Finder App Configuration can be modified from the App Config tab under the selected HealthPlan on the Groups page.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageProviderFinderPlanConfigGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageProviderFinderPlanConfigGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageProviderFinderPlanConfigGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the App Config tab',
					body: 'Clicking on Documents will bring up the App Config Tab for the selected HealthPlan.',
					img: imageProviderFinderPlanConfigGroupsPage4,
					alternateText: 'Click on the App Config tab'
				},
				4: {
					stepTitle: '5. Click on PROVIDER FINDER',
					body: 'Clicking on Provider Finder will bring up the configurations available on the Provider Finder page of the CareValet app.',
					img: imageProviderFinderPlanConfigGroupsPage5,
					alternateText: 'Click on PROVIDER FINDER'
				}
			}
		}
	},
	{
		caseName: 'profilePagePlanConfigsGroupPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'Managing Profile Page Plan Configurations from the Groups page',
		categories: 'groups, appConfig',
		content: {
			description:
				'A HealthPlans Profile Page App Configuration can be modified from the App Config tab under the selected HealthPlan on the Groups page',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageProfilePagePlanConfigGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageProfilePagePlanConfigGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageProfilePagePlanConfigGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the App Config tab',
					body: 'Clicking on Documents will bring up the App Config Tab for the selected HealthPlan.',
					img: imageProfilePagePlanConfigGroupsPage4,
					alternateText: 'Click on the App Config tab'
				},
				4: {
					stepTitle: '5. Click on PROFILE PAGE',
					body: 'Clicking on Profile Page will bring up the configurations available on the Profile page of the CareValet app.',
					img: imageProfilePagePlanConfigGroupsPage5,
					alternateText: 'Click on PROFILE PAGE'
				}
			}
		}
	},
	{
		caseName: 'searchingForGroup',
		iconDescription: 'fad fa-users-class',
		tutorialTitle: 'On the Groups page, you can search for a group by name.',
		categories: 'groups',
		content: {
			description: 'On the Groups page, you can search for a group by name.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageSearchForGroup1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Enter a Group Name into the search box',
					body: 'This will remove all Group names that do not match the entered text.',
					img: imageSearchForGroup2,
					alternateText: 'Enter a Group Name into the search box'
				},
				2: {
					stepTitle: '3. Delete the text',
					body: 'Deleting the text will clear the search filter.',
					img: imageSearchForGroup3,
					alternateText: 'Delete the text'
				}
			}
		}
	},
	{
		caseName: 'connectExistingHealthPlanToGroup',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to connect an existing HealthPlan to a Group',
		categories: 'healthPlans, groups',
		content: {
			description: 'Connecting a HealthPlan to the Group will add it to the Group. Multiple HealthPlans can exist within a Group.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageConnectExistingHealthplanToGroup1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageConnectExistingHealthplanToGroup2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on CONNECT PLAN',
					body: 'Clicking on Connect Plan will bring up a window that allows you to connect an existing HealthPlan or create a new one.',
					img: imageConnectExistingHealthplanToGroup3,
					alternateText: 'Click on CONNECT PLAN'
				},
				3: {
					stepTitle: '4. Click on the existing plans drop down',
					body: 'This will bring up a list of your existing plans.',
					img: imageConnectExistingHealthplanToGroup4,
					alternateText: 'Click on the existing plans drop down'
				},
				4: {
					stepTitle: '5. Select a plan from the list',
					body: 'You can only select one plan at a time.',
					img: imageConnectExistingHealthplanToGroup5,
					alternateText: 'Select a plan from the list'
				},
				5: {
					stepTitle: '6. Click on Connect',
					body: 'Clicking on Connect will connect your selected HealthPlan to your Group.',
					img: imageConnectExistingHealthplanToGroup6,
					alternateText: 'Click on Connect'
				},
				6: {
					stepTitle: '7. You have successfully connected the HealthPlan to the Group.',
					body: "You will find this HealthPlan at the bottom of this Group's page on the plan list.",
					img: imageConnectExistingHealthplanToGroup7,
					alternateText: 'You have successfully connected the HealthPlan to the Group.'
				}
			}
		}
	},
	{
		caseName: 'removeHealthPlanFromGroup',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to remove a HealthPlan from a Group',
		categories: 'healthPlans, groups',
		content: {
			description: "If you no longer want a HealthPlan to be connected to a Group you can remove it on a selected Group's Page.",
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageRemoveHealthplanFromGroup1,
					alternateText: 'Click on Groups'
				},
				2: {
					stepTitle: '2. Select a Group from the Groups list',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageRemoveHealthplanFromGroup2,
					alternateText: 'Select a Group from the Groups list'
				},
				3: {
					stepTitle: '3. Click on the delete button for the HealthPlan you wish to remove',
					body: 'Clicking on the delete button will give you the option to remove it from this Group.',
					img: imageRemoveHealthplanFromGroup3,
					alternateText: 'Click on the delete button for the HealthPlan you wish to remove'
				},
				4: {
					stepTitle: '4. Click on OK',
					body: 'Clicking on OK will remove this HealthPlan from the Group.',
					img: imageRemoveHealthplanFromGroup4,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'searchForHealthPlanAttachedToGroup',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to search for a HealthPlan attached to a Group',
		categories: 'healthPlans, groups',
		content: {
			description: "On a Group's page, you can search for a HealthPlan by entering its name into the HealthPlan search box.",
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageSearchForHealthplanAttachedToGroup1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageSearchForHealthplanAttachedToGroup2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Enter a HealthPlan into the search box',
					body: 'This will remove all Group names that do not match the entered text.',
					img: imageSearchForHealthplanAttachedToGroup3,
					alternateText: 'Enter a HealthPlan into the search box'
				},
				3: {
					stepTitle: '4. Delete the text',
					body: 'Deleting the text will clear the search filter.',
					img: imageSearchForHealthplanAttachedToGroup4,
					alternateText: 'Delete the text'
				}
			}
		}
	},
	{
		caseName: 'editHealthPlanDetailsGroupsPage',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: "How to edit HealthPlan Details from the Group's Page",
		categories: 'healthPlans, groups',
		content: {
			description: 'A HealthPlans name, ID, phone number and website can be edited by accessing an edit HealthPlan window.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageEditHealthplanDetailsGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageEditHealthplanDetailsGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageEditHealthplanDetailsGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on edit button for Plan Details',
					body: 'This will bring up the Edit Plan Details window.',
					img: imageEditHealthplanDetailsGroupsPage4,
					alternateText: 'Click on edit button for Plan Details'
				},
				4: {
					stepTitle: '5. Edit the HealthPlan details you wish to change and click on Save',
					body: 'Clicking on Save will save your changes to the HealthPlan.',
					img: imageEditHealthplanDetailsGroupsPage5,
					alternateText: 'Edit the HealthPlan details you wish to change and click on Save'
				}
			}
		}
	},
	{
		caseName: 'setupCustomTelelhealthHealthPlanGroupsPage',
		iconDescription: 'fad fa-phone-plus',
		tutorialTitle: 'How to set up your own telehealth for a HealthPlan from the Groups Page',
		categories: 'healthPlan, groups',
		content: {
			description: 'You can modify the telehealth settings for a HealthPlan on the Groups page after selecting a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageSetupCustomTelehealthHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageSetupCustomTelehealthHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan'
				},
				3: {
					stepTitle: '4. Click on the edit button for Telehealth',
					body: 'This will bring up the Edit Telehealth Details window. On the Edit Telehealth Details window, you can modify the telehealth type, name, phone number, website and copay for a call.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage4,
					alternateText: 'Click on the edit button for Telehealth'
				},
				4: {
					stepTitle: '5. Click on the Telehealth Type',
					body: 'This will bring up a list of Telehealth type options. Such as CareValet built-in, bring your own and no Telehealth.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage5,
					alternateText: 'Click on the Telehealth Type'
				},
				5: {
					stepTitle: '6. Select a Bring Your Own Telehealth',
					body: 'Bring Your Own Telehealth allows you to customize the Telehealth settings.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage6,
					alternateText: 'Select a Bring Your Own Telehealth'
				},
				6: {
					stepTitle: '7. Enter a telehealth name into the Telehealth Name textbox',
					body: 'This will be the Name of your Telehealth associated with this HealthPlan.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage7,
					alternateText: 'Enter a telehealth name into the Telehealth Name textbox'
				},
				7: {
					stepTitle: '8. Enter the phone number for your telehealth in the Telehealth Phone Number textbox',
					body: 'This will be the phone number telehealth calls when the user clicks on telehealth Call Now throughout the CareValet app.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage8,
					alternateText: 'Enter the phone number for your telehealth in the Telehealth Phone Number textbox'
				},
				8: {
					stepTitle: '9. Enter the website for your telehealth in the Telehealth Website textbox',
					body: 'This will be the website telehealth sends the user to when the user clicks on Open Telehealth Website throughout the CareValet app.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage9,
					alternateText: 'Enter the website for your telehealth in the Telehealth Website textbox'
				},
				9: {
					stepTitle: '10. Enter the copay for your telehealth in the Copay for a telehealth call textbox',
					body: 'This will be the cost associated with a telehealth call throughout the CareValet app.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage10,
					alternateText: 'Enter the copay for your telehealth in the Copay for a telehealth call textbox'
				},
				10: {
					stepTitle: '11. Click on SAVE',
					body: 'Clicking on Save will save your telehealth changes to the HealthPlan.',
					img: imageSetupCustomTelehealthHealthplanGroupsPage11,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'turnOffTelehealthForHealthPlanGroupsPage',
		iconDescription: 'fad fa-phone-plus',
		tutorialTitle: 'How to turn off Telehealth for a HealthPlan from the Group page',
		categories: 'healthPlans, groups',
		content: {
			description: 'You can modify the telehealth settings for a HealthPlan on the Groups page after selecting a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageTurnOffTelehealthForHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageTurnOffTelehealthForHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageTurnOffTelehealthForHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan'
				},
				3: {
					stepTitle: '4. Click on the edit button for Telehealth',
					body: 'This will bring up the Edit Telehealth Details window. On the Edit Telehealth Details window, you can modify the telehealth type, name, phone number, website and copay for a call.',
					img: imageTurnOffTelehealthForHealthplanGroupsPage4,
					alternateText: 'Click on the edit button for Telehealth'
				},
				4: {
					stepTitle: '5. Click on the Telehealth Type',
					body: 'This will bring up a list of Telehealth type options. Such as CareValet built-in, bring your own and no Telehealth.',
					img: imageTurnOffTelehealthForHealthplanGroupsPage5,
					alternateText: 'Click on the Telehealth Type'
				},
				5: {
					stepTitle: '6. Select a No Telehealth',
					body: 'No telehealth allows you to turn Telehealth for this HealthPlan off.',
					img: imageTurnOffTelehealthForHealthplanGroupsPage6,
					alternateText: 'Select a No Telehealth'
				},
				6: {
					stepTitle: '7. Click on SAVE',
					body: 'Clicking on Save will save your telehealth changes to the HealthPlan.',
					img: imageTurnOffTelehealthForHealthplanGroupsPage7,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'editAdditionalDetailsHealthPlanGroupsPage',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to edit additional details of a HealthPlan from the Group Page',
		categories: 'healthPlans, groups',
		content: {
			description:
				'A HealthPlans Additional Details Rx PCN, RxBin, Rx Group#, PayerID, Office Visit Copay, Specialist Copay, Urgent Care Copay, ER Copay, Deductible, Out of Pocket Max, Family Deductible, Family Out of Pocket Max can all be edited on the Edit Additional Details window.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageEditAdditionalDetailsHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageEditAdditionalDetailsHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageEditAdditionalDetailsHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on the edit button for Additional Data',
					body: 'This will bring up the Edit Additional Details window.',
					img: imageEditAdditionalDetailsHealthplanGroupsPage4,
					alternateText: 'Click on the edit button for Additional Data'
				},
				4: {
					stepTitle: '5. Edit the Additional details you wish to change and click on Save',
					body: 'Clicking on Save will save your changes to the HealthPlan.',
					img: imageEditAdditionalDetailsHealthplanGroupsPage5,
					alternateText: 'Edit the Additional details you wish to change and click on Save'
				}
			}
		}
	},
	{
		caseName: 'editNetworkDetailsHealthPlanGroupsPage',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to edit Network Details of a HealthPlan from the Groups page',
		categories: 'healthPlans, groups',
		content: {
			description:
				"Network Details can be edited for the HealthPlan from the Groups page. Traditional networks allow you to modify the Network's name, contact name, contact email, contact phone number & request details. Tiered networks allow you to modify the Network's name, add additional tiers as well as modify tier configurations. Open networks allow you to modify thevNetwork's name and tier configuration.",
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will bring you to the Groups page. On the Groups page, you can create or select a Group.',
					img: imageEditNetworkDetailsHealthplanGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "Selecting a Group will bring you to that Group's page. A Group's page will let you edit Group Details, connect HealthPlans, Manage App Configurations for the Group and see Group Members.",
					img: imageEditNetworkDetailsHealthplanGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select a HealthPlan from the HealthPlan list',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. A HealthPlan's page will let you edit HealthPlan details, connect a HealthPlan to a Group, select a HealthPlan to view its page, set the app configuration for a Group, and see Group Members.",
					img: imageEditNetworkDetailsHealthplanGroupsPage3,
					alternateText: 'Select a HealthPlan from the HealthPlan list'
				},
				3: {
					stepTitle: '4. Click on edit button for Network Details',
					body: 'This will bring up the Edit Network Request window.',
					img: imageEditNetworkDetailsHealthplanGroupsPage4,
					alternateText: 'Click on edit button for Network Details'
				},
				4: {
					stepTitle: '5. Edit the details you want to change within thge Edit Network Request window nd click on Next',
					body: "Clicking on Next will save your changes to the HealthPlan and the HealthPlan's Network.",
					img: imageEditNetworkDetailsHealthplanGroupsPage5,
					alternateText: 'Edit the details you want to change within thge Edit Network Request window nd click on Next'
				}
			}
		}
	},
	{
		caseName: 'viewingMemberOfGroup',
		iconDescription: 'fad fa-users',
		tutorialTitle: 'Viewing a Member of a Group',
		categories: 'groups, members',
		content: {
			description:
				"After selecting a member of a Group, you can view Member Details, the Member's ID Card, and the Member's Dependents.",
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'Clicking on Groups will take you to the Groups page. Where you can select a Group.',
					img: imageViewMemberOfGroup1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Click on a Group',
					body: 'Clicking on a Group will bring up the Group Details page.',
					img: imageViewMemberOfGroup2,
					alternateText: 'Click on a Group'
				},
				2: {
					stepTitle: '3. Click on the Members tab',
					body: 'Clicking on the Members tab will bring up a list of Members associated with this Group.',
					img: imageViewMemberOfGroup3,
					alternateText: 'Click on the Members tab'
				},
				3: {
					stepTitle: '4. Select a Member',
					body: 'Selecting a Member will bring up their Member Details, ID Cards, and Dependents if they have.',
					img: imageViewMemberOfGroup4,
					alternateText: 'Select a Member'
				},
				4: {
					stepTitle: '5. Here you can see the Member Details',
					body: '',
					img: imageViewMemberOfGroup5,
					alternateText: 'Here you can see the Member Details'
				},
				5: {
					stepTitle: '6. Click on the edit button for Member Details',
					body: 'This will open the Edit Member window. This is only available to Groups with the Manually Manage Member Data setting.',
					img: imageViewMemberOfGroup6,
					alternateText: 'Click on the edit button for Member Details'
				},
				6: {
					stepTitle: '7. Here you can modify member data and click on SAVE to save the changes made',
					body: 'Clicking on Save will close the Edit Member window.',
					img: imageViewMemberOfGroup7,
					alternateText: 'Here you can modify member data and click on SAVE to save the changes made'
				},
				7: {
					stepTitle: "8. Below the Member Details section of the page is the Member's ID Cards",
					body: 'Here you can click on the Front or Back ID Card and see a preview.',
					img: imageViewMemberOfGroup8,
					alternateText: "Below the Member Details section of the page is the Member's ID Cards"
				},
				8: {
					stepTitle: '9. Click on Dependents to view the dependents of this Member',
					body: 'This will bring up a list of selectable dependents if this member has dependents.',
					img: imageViewMemberOfGroup9,
					alternateText: 'Click on Dependents to view the dependents of this Member'
				}
			}
		}
	},
	{
		caseName: 'howToSearchForHealthPlan',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to search for a HealthPlan',
		categories: 'healthPlans',
		content: {
			description: 'HealthPlans can be filtered by entering a HealthPlans name into the search box on the HealthPlans page.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageSearchForHealthPlan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Enter a HealthPlans name into the search box',
					body: 'This will remove all HealthPlans that do not have the HealthPlan name entered into the search box.',
					img: imageSearchForHealthPlan2,
					alternateText: 'Enter a HealthPlans name into the search box'
				},
				2: {
					stepTitle: '3. Remove the HealthPlans name or text to clear the filtered search',
					body: 'Notice the other HealthPlans for this Organization reappear',
					img: imageSearchForHealthPlan3,
					alternateText: 'Remove the HealthPlans name or text to clear the filtered search'
				}
			}
		}
	},
	{
		caseName: 'deletingHealthPlanFromOrg',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'Deleting a HealthPlan from an Organization',
		categories: 'healthPlans',
		content: {
			description: 'HealthPlans can be filtered by entering a HealthPlans name into the search bo on the HealthPlans page.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageDeletingHealthplanFromOrg1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Click on the delete button for the HealthPlan you wish to delete from the organization',
					body: 'This will bring up a Please Double Check prompt.',
					img: imageDeletingHealthplanFromOrg2,
					alternateText: 'Click on the delete button for the HealthPlan you wish to delete from the organization'
				},
				2: {
					stepTitle: '3. Click on OK',
					body: 'Clicking on OK will completely remove this HealthPlan from the Organization.',
					img: imageDeletingHealthplanFromOrg3,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'invitingTeamMember',
		iconDescription: 'fad fa-cogs',
		tutorialTitle: 'Inviting a team member to Express',
		categories: 'orgSetup',
		content: {
			description: 'You can invite a coworker to express and assign them a role from the My Team section of the Settings page.',
			steps: {
				0: {
					stepTitle: '1. Click on Settings',
					body: "This will bring you to your Organizations Settings page. On the Settings page, you can see your accounts Profile, your Organization's My Team, App Configuration, and Member Import settings.",
					img: imageInvitingTeamMember1,
					alternateText: 'Click on Settings'
				},
				1: {
					stepTitle: '2. Click on My Team',
					body: 'On the My Team tab, you can view your Organizations team members, their roles and send invites to bring on team new members.',
					img: imageInvitingTeamMember2,
					alternateText: 'Click on My Team'
				},
				2: {
					stepTitle: '3. Click on SEND INVITE',
					body: 'This will bring up the Create New Invite window.',
					img: imageInvitingTeamMember3,
					alternateText: 'Click on SEND INVITE'
				},
				3: {
					stepTitle: '4. Enter the first name of the new team member',
					body: '',
					img: imageInvitingTeamMember4,
					alternateText: 'Enter the first name of the new team member'
				},
				4: {
					stepTitle: '5. Enter the last name of the new team member',
					body: '',
					img: imageInvitingTeamMember5,
					alternateText: 'Enter the last name of the new team member'
				},
				5: {
					stepTitle: '6. Enter the email of the team member',
					body: '',
					img: imageInvitingTeamMember6,
					alternateText: 'Enter the email of the team member'
				},
				6: {
					stepTitle: '7. Click on the User Role drop down button',
					body: 'This will bring up a list of possible user roles.',
					img: imageInvitingTeamMember7,
					alternateText: 'Click on the User Role drop down button'
				},
				7: {
					stepTitle: '8. Select the desired role for this new team member',
					body: 'Each role has different permissions.',
					img: imageInvitingTeamMember8,
					alternateText: 'Select the desired role for this new team member'
				},
				8: {
					stepTitle: '9. Click on SEND INVITE',
					body: 'Clicking on SEND INVITE will email the new team member with a link to sign up for Express. They have to check their email and follow instructions to complete the signup process.',
					img: imageInvitingTeamMember9,
					alternateText: 'Click on SEND INVITE'
				}
			}
		}
	},
	{
		caseName: 'findAppConfigHealthPlan',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'Where to find the App Configuration settings for a HealthPlan',
		categories: 'healthPlans, appConfig',
		content: {
			description: 'A HealthPlans App Configuration settings can be changed from the App Config tab of the selected HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageFindAppConfig1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageFindAppConfig2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on App Config',
					body: "Clicking on App Config will bring up this HealthPlan's App Configuration settings.",
					img: imageFindAppConfig3,
					alternateText: 'Click on App Config'
				}
			}
		}
	},
	{
		caseName: 'customTelehealthForHealthPlan',
		iconDescription: 'fad fa-phone-alt',
		tutorialTitle: 'Setting up a custom Telehealth for a HealthPlan',
		categories: 'healthPlans',
		content: {
			description:
				'HealthPlans can have Telehealth through CareValet, they can also have their own custom setup or you can turn Telehealth off.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageCustomTelehealthForHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageCustomTelehealthForHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on the edit Telehealth button',
					body: 'This will bring up the Edit Telehealth Details window.',
					img: imageCustomTelehealthForHealthplan3,
					alternateText: 'Click on the edit Telehealth button'
				},
				3: {
					stepTitle: '4. Click on the Type drop down button',
					body: 'This will bring up a list of Telehealth types this HealthPlan can have.',
					img: imageCustomTelehealthForHealthplan4,
					alternateText: 'Click on the Type drop down button'
				},
				4: {
					stepTitle: '5. Click on Bring Your Own Telehealth',
					body: 'This will allow you to set up your own Telehealth settings.',
					img: imageCustomTelehealthForHealthplan5,
					alternateText: 'Click on Bring Your Own Telehealth'
				},
				5: {
					stepTitle: '6. Enter a name for your Telehealth',
					body: 'This will be the Name of the Telehealth button in the CareValet app.',
					img: imageCustomTelehealthForHealthplan6,
					alternateText: 'Enter a name for your Telehealth'
				},
				6: {
					stepTitle: '7. Enter the number for your Telehealth',
					body: 'This will be the number members of this HealthPlan will be directed to after clicking on the Telehealth button within the CareValet app.',
					img: imageCustomTelehealthForHealthplan7,
					alternateText: 'Enter the number for your Telehealth'
				},
				7: {
					stepTitle: '8. Enter a Telehealth website',
					body: 'This will be the URL the members of this HealthPlan will be directed to when they click on the Open Telehealth Website button within the CareValet app.',
					img: imageCustomTelehealthForHealthplan8,
					alternateText: 'Enter a Telehealth website'
				},
				8: {
					stepTitle: '9. Enter the cost of the Telehealth call',
					body: 'This will be displayed to members of this HealthPlan on the Telehealth button within the CareValet app.',
					img: imageCustomTelehealthForHealthplan9,
					alternateText: 'Enter the cost of the Telehealth call'
				},
				9: {
					stepTitle: '10. Click on SAVE',
					body: 'This will save your changes to the Telehealth settings for this HealthPlan.',
					img: imageCustomTelehealthForHealthplan10,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'turningOffTelehealthHealthPlan',
		iconDescription: 'fad fa-phone-alt',
		tutorialTitle: 'Turning off Telehealth for a HealthPlan',
		categories: 'healthPlans',
		content: {
			description: 'HealthPlans can turn off Telehealth access for its members.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageTurnOffTelehealthHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageTurnOffTelehealthHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on the edit Telehealth button',
					body: 'This will bring up the Edit Telehealth Details window.',
					img: imageTurnOffTelehealthHealthplan3,
					alternateText: 'Click on the edit Telehealth button'
				},
				3: {
					stepTitle: '4. Click on the Type drop down button',
					body: 'This will bring up a list of Telehealth types this HealthPlan can have.',
					img: imageTurnOffTelehealthHealthplan4,
					alternateText: 'Click on the Type drop down button'
				},
				4: {
					stepTitle: '5. Click on No Telehealth',
					body: 'This will turn off Telehealth for the Members of this HealthPlan.',
					img: imageTurnOffTelehealthHealthplan5,
					alternateText: 'Click on No Telehealth'
				},
				5: {
					stepTitle: '6. Click on SAVE',
					body: 'This will save the changes made to Telehealth for this HealthPlan',
					img: imageTurnOffTelehealthHealthplan6,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'editHealthPlanDetailsHealthPlan',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to edit the HealthPlan Details for a HealthPlan',
		categories: 'healthPlans',
		content: {
			description: 'A HealthPlans name, ID, phone number and website can be edited by accessing an edit HealthPlan window.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageEditHealthplanDetailsHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageEditHealthplanDetailsHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on the edit button for Plan Details',
					body: 'This will bring up the Edit Plan Details window.',
					img: imageEditHealthplanDetailsHealthplan3,
					alternateText: 'Click on the edit button for Plan Details'
				},
				3: {
					stepTitle: '4. Make the necessary changes and click on SAVE.',
					body: 'Clicking on Save will save the changes to this HealthPlan',
					img: imageEditHealthplanDetailsHealthplan4,
					alternateText: 'Make the necessary changes and click on SAVE.'
				}
			}
		}
	},
	{
		caseName: 'editAdditionalDetailsForHealthPlan',
		iconDescription: 'fad fa-files-medical',
		tutorialTitle: 'How to edit Additional Details for a HealthPlan',
		categories: 'healthPlans',
		content: {
			description:
				'A HealthPlans Additional Details Rx PCN, RxBin, Rx Group#, PayerID, Office Visit Copay, Specialist Copay, Urgent Care Copay, ER Copay, Deductible, Out of Pocket Max, Family Deductible, Family Out of Pocket Max can all be edited on the Edit Additional Details window.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageEditHealthplanAdditionalDetailsHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageEditHealthplanAdditionalDetailsHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				3: {
					stepTitle: '3. Click on the edit button for Additional Data',
					body: 'This will bring up the Edit Additional Data window.',
					img: imageEditHealthplanAdditionalDetailsHealthplan3,
					alternateText: 'Click on the edit button for Additional Data'
				},
				4: {
					stepTitle: '4. Make the necessary changes and click on SAVE',
					body: 'Clicking on SAVE will save the changes made to the HealthPlan',
					img: imageEditHealthplanAdditionalDetailsHealthplan4,
					alternateText: 'Make the necessary changes and click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'changeNetworkForHealthPlan',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to change the Network for a HealthPlan',
		categories: 'healthPlans, networks',
		content: {
			description: 'If a HealthPlans Network needs to be changed. You can change the network from the Network Setup window',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageChangeNetworkForHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageChangeNetworkForHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on Network Setup button',
					body: 'The Network Setup button will take you to the Network Setup window. The Network Setup window is where you can change your Network type.',
					img: imageChangeNetworkForHealthplan3,
					alternateText: 'Click on Network Setup button'
				},
				3: {
					stepTitle: '4. Click on the Network Type drop down button',
					body: 'Clicking on Network Type will bring up the Network Type list. On the Network Type list, you can choose from a Traditional Network, Tiered Network, and a None Network.',
					img: imageChangeNetworkForHealthplan4,
					alternateText: 'Click on the Network Type drop down button'
				},
				4: {
					stepTitle: '5. Click on a Network type',
					body: 'Clicking on a Network type will transform the setup window to allow for setting up the chosen Network Type.',
					img: imageChangeNetworkForHealthplan5,
					alternateText: 'Click on a Network type'
				},
				5: {
					stepTitle: '6. Click on the Network drop down button',
					body: 'This will bring up a list of available Networks of the chosen Network Type.',
					img: imageChangeNetworkForHealthplan6,
					alternateText: 'Click on the Network drop down button'
				},
				6: {
					stepTitle: '7. Click on a network',
					body: 'This Network will be the chosen Network for this HealthPlan. Network details will be filled in based on the chosen Network.',
					img: imageChangeNetworkForHealthplan7,
					alternateText: 'Click on a network'
				},
				7: {
					stepTitle: '8. Click on Next',
					body: 'Clicking on Next will save the Network changes made to the selected HealthPlan.',
					img: imageChangeNetworkForHealthplan8,
					alternateText: 'Click on Next'
				}
			}
		}
	},
	{
		caseName: 'editIdCardForHealthPlan',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to edit the ID Card for a HealthPlan',
		categories: 'healthPlans, idCards',
		content: {
			description:
				"If the ID cards for a HealthPlan need to be modified. You can access HealthPlans ID cards by clicking on them under the ID Cards tab on the HealthPlan's page",
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageEditIdCardForHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageEditIdCardForHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on ID Cards',
					body: "This will bring up this HealthPlan's ID Cards.",
					img: imageEditIdCardForHealthplan3,
					alternateText: 'Click on ID Cards'
				},
				3: {
					stepTitle: '4. Click on the FRONT of the ID Card',
					body: 'This will bring you to the ID Card editor and will have the front of the card already loaded.',
					img: imageEditIdCardForHealthplan4,
					alternateText: 'Click on the FRONT of the ID Card'
				},
				4: {
					stepTitle: '5. Click on CLOSE',
					body: 'This will close the editor. If you made any changes you want to keep click SAVE instead.',
					img: imageEditIdCardForHealthplan5,
					alternateText: 'Click on CLOSE'
				},
				5: {
					stepTitle: '6. Click on the BACK of the ID Card',
					body: 'This will bring you to the ID Card editor and will have the back of the card already loaded.',
					img: imageEditIdCardForHealthplan6,
					alternateText: 'Click on the BACK of the ID Card'
				},
				6: {
					stepTitle: '7. Click on CLOSE',
					body: 'This will close the editor. If you made any changes you want to keep click SAVE instead.',
					img: imageEditIdCardForHealthplan7,
					alternateText: 'Click on CLOSE'
				}
			}
		}
	},
	{
		caseName: 'howToChangeIdCardHealthPlan',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to change the ID Card for a HealthPlan',
		categories: 'idCards, healthPlans',
		content: {
			description:
				"If the ID cards for a HealthPlan need to be changed. You can access HealthPlans ID cards by clicking on the change ID card button under the ID Cards tab on the HealthPlan's page",
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageChangeIdCardHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageChangeIdCardHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on ID Cards',
					body: "This will bring up this HealthPlan's ID Cards.",
					img: imageChangeIdCardHealthplan3,
					alternateText: 'Click on ID Cards'
				},
				3: {
					stepTitle: '4. Click on the Change ID Cards button',
					body: 'This will bring up the Change ID Cards window.',
					img: imageChangeIdCardHealthplan4,
					alternateText: 'Click on the Change ID Cards button'
				},
				4: {
					stepTitle: '5. Click on the ID Card Template - Front drop down button',
					body: 'This will bring up a list of available ID Card fronts.',
					img: imageChangeIdCardHealthplan5,
					alternateText: 'Click on the ID Card Template - Front drop down button'
				},
				5: {
					stepTitle: '6. Select the desired front of an ID Card',
					body: 'This will be what users see on the front of their ID Card',
					img: imageChangeIdCardHealthplan6,
					alternateText: 'Select the desired front of an ID Card'
				},
				6: {
					stepTitle: '7. Click on the ID Card Template - Back drop down button',
					body: 'This will bring up a list of available ID Card backs.',
					img: imageChangeIdCardHealthplan7,
					alternateText: 'Click on the ID Card Template - Back drop down button'
				},
				7: {
					stepTitle: '8. Select the desired back of an ID Card',
					body: 'This will be what users see on the back of their ID Card',
					img: imageChangeIdCardHealthplan8,
					alternateText: 'Select the desired back of an ID Card'
				},
				8: {
					stepTitle: '9. Click on SAVE',
					body: "Clicking on SAVE will swap this HealthPlan's old ID cards front and back for the newly selected front and back.",
					img: imageChangeIdCardHealthplan9,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'addDocToHealthPlan',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Adding a document to a HealthPlan',
		categories: 'healthPlans, documents, orgSetup',
		content: {
			description:
				'You can add a document to a HealthPlan that users can access via their Benefits Wallet within the CareValet app. Documents must already be uploaded to your Organization before they can be added to a HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageAddDocToHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageAddDocToHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on Documents',
					body: 'This will bring up the Documents section of this HealthPlan.',
					img: imageAddDocToHealthplan3,
					alternateText: 'Click on Documents'
				},
				3: {
					stepTitle: '4. Click on the Add Documents button',
					body: 'This will bring up the Add document to plan window.',
					img: imageAddDocToHealthplan4,
					alternateText: 'Click on the Add Documents button'
				},
				4: {
					stepTitle: '5. Click on +',
					body: 'Clicking on the + will add the document to this HealthPlan. You must have documents already uploaded to this Organization before being able to add a document to a HealthPlan. Users can view this and other documents added to this HealthPlan from their Benefits Wallet within the CareValet app.',
					img: imageAddDocToHealthplan5,
					alternateText: 'Click on plus'
				}
			}
		}
	},
	{
		caseName: 'viewDocAttachedToHealthPlan',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Viewing a Document attached to a HealthPlan',
		categories: 'healthPlans, documents',
		content: {
			description: "You can view documents attached to a HealthPlan from the Documents tab on that HealthPlan's page.",
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageViewDocAttachedToHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageViewDocAttachedToHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on Documents',
					body: 'This will bring up the Documents section of this HealthPlan.',
					img: imageViewDocAttachedToHealthplan3,
					alternateText: 'Click on Documents'
				},
				3: {
					stepTitle: '4. Click on the Preview Document button',
					body: 'This will bring up a preview of this document.',
					img: imageViewDocAttachedToHealthplan4,
					alternateText: 'Click on the Preview Document button'
				}
			}
		}
	},
	{
		caseName: 'removeDocFromHealthPlan',
		iconDescription: 'fad fa-folders',
		tutorialTitle: 'Removing a Document from a HealthPlan',
		categories: 'healthPlans, documents',
		content: {
			description:
				"If you want to remove a document from a HealthPlan so that users can no longer access it via their Benefits Wallet within the CareValet app. You can remove the document from the Documents tab of the HealthPlan's page.",
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageRemoveDocFromHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageRemoveDocFromHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on Documents',
					body: 'This will bring up the Documents section of this HealthPlan.',
					img: imageRemoveDocFromHealthplan3,
					alternateText: 'Click on Documents'
				},
				3: {
					stepTitle: '4. Click on the Remove Documents button',
					body: 'This will bring up a prompt verifying if you really want to remove this document from the HealthPlan.',
					img: imageRemoveDocFromHealthplan4,
					alternateText: 'Click on the Remove Documents button'
				},
				4: {
					stepTitle: '5. Click on OK',
					body: 'Clicking on OK will remove this document from this HealthPlan. But will not delete this document from the Organization. The document will now no longer be available for members of this HealthPlan within the CareValet app when they open their Benefits Wallet.',
					img: imageRemoveDocFromHealthplan5,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'editNetworkDetailsForHealthPlan',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to edit the Network details for a HealthPlan',
		categories: 'healthPlans, networks',
		content: {
			description:
				"If a HealthPlan's Network details need to be changed. You can change the network details from the Edit Network Request window.",
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on HealthPlans will bring you to the HealthPlans page. On the HealthPlans page, you can create or select a HealthPlan.',
					img: imageEditNetworkDetailsForHealthplan1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Select a HealthPlan',
					body: "Selecting a HealthPlan will bring you to that HealthPlan's page. On a HealthPlan's page, you can edit HealthPlan Details, Telehealth settings, and manage Additional Data. You can also view and modify the HealthPlan's Network, ID Cards, add and remove HealthPlan documents, and manage App Configuration settings.",
					img: imageEditNetworkDetailsForHealthplan2,
					alternateText: 'Select a HealthPlan'
				},
				2: {
					stepTitle: '3. Click on Edit Network Details button',
					body: 'This will bring up the Edit Network Request window.',
					img: imageEditNetworkDetailsForHealthplan3,
					alternateText: 'Click on Edit Network Details button'
				},
				3: {
					stepTitle: '4. Make the necessary changes and click on Next',
					body: 'Clicking on Next will save the changes to this HealthPlan',
					img: imageEditNetworkDetailsForHealthplan4,
					alternateText: 'Make the necessary changes and click on Next'
				}
			}
		}
	},
	{
		caseName: 'searchingForMember',
		iconDescription: 'fad fa-users',
		tutorialTitle: 'Searching for a Member',
		categories: 'members',
		content: {
			description: 'If your member list gets too long you can search for a Member on the Members page.',
			steps: {
				0: {
					stepTitle: '1. Click on Members',
					body: 'Clicking on Members will take you to the Members page. Where you can add a Member and search for a Member.',
					img: imageSearchForMember1,
					alternateText: 'Click on Members'
				},
				1: {
					stepTitle: '2. Enter the last name of the member you are trying to look up',
					body: 'This will be used to filter the list by this last name.',
					img: imageSearchForMember2,
					alternateText: 'Enter the last name of the member you are trying to look up'
				},
				2: {
					stepTitle: '3. Enter the last name you want to search for',
					body: '',
					img: imageSearchForMember3,
					alternateText: 'Enter the last name you want to search for'
				},
				3: {
					stepTitle: '4. Click on SEARCH',
					body: 'This will clear the list of all users without the last name you entered.',
					img: imageSearchForMember4,
					alternateText: 'Click on SEARCH'
				},
				4: {
					stepTitle: '5. Clear the text and click on SEARCH',
					body: 'You will see the list repopulate with names that were filtered out.',
					img: imageSearchForMember5,
					alternateText: 'Clear the text and click on SEARCH'
				}
			}
		}
	},
	{
		caseName: 'howToEditTieredNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to edit a Tiered Network',
		categories: 'networks',
		content: {
			description: 'A Tiered Network can be edited from the Networks page of Express.',
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will take you to the Networks page. Where you can add a Network and search for a Network.',
					img: imageEditTieredNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: '2. Select the Tiered Network you would like to edit',
					body: "Selecting a Network will bring you to the Network's page.",
					img: imageEditTieredNetwork2,
					alternateText: 'Select the Network you would like to edit'
				},
				2: {
					stepTitle: '3. Click on edit Network button',
					body: 'Clicking on the edit Network button will bring up the Edit Network Request window.',
					img: imageEditTieredNetwork3,
					alternateText: 'Click on edit Network button'
				},
				3: {
					stepTitle: '4. On this window you can modify Network details and click on Next to save',
					body: 'Clicking on Next will save the changes to this Network.',
					img: imageEditTieredNetwork4,
					alternateText: 'On this window you can modify Network details and click on Next to save'
				}
			}
		}
	},
	{
		caseName: 'howToEditTraditionalNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to edit a Traditional Network',
		categories: 'networks',
		content: {
			description: 'A Traditional Network can be edited from the Networks page of Express.',
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will take you to the Networks page. Where you can add a Network and search for a Network.',
					img: imageEditTraditionalNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: '2. Select the Traditional Network you would like to edit',
					body: "Selecting a Network will bring you to the Network's page.",
					img: imageEditTraditionalNetwork2,
					alternateText: 'Select the Traditional Network you would like to edit'
				},
				2: {
					stepTitle: '3. Click on edit Network button',
					body: 'Clicking on the edit Network button will bring up the Edit Network Request window.',
					img: imageEditTraditionalNetwork3,
					alternateText: 'Click on edit Network button'
				},
				3: {
					stepTitle: '4. On this window you can modify Network details',
					body: '',
					img: imageEditTraditionalNetwork4,
					alternateText: 'On this window you can modify Network details'
				},
				4: {
					stepTitle: '5. Click on Next to save',
					body: 'Clicking on Next will save the changes to this Network.',
					img: imageEditTraditionalNetwork5,
					alternateText: 'Click on Next to save'
				}
			}
		}
	},
	{
		caseName: 'howToEditNoneNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to edit a None Network',
		categories: 'networks',
		content: {
			description: 'A None Network can be edited from the Networks page of Express.',
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will take you to the Networks page. Where you can add a Network and search for a Network.',
					img: imageEditNoneNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: '2. Select the None Network you would like to edit',
					body: "Selecting a Network will bring you to the Network's page.",
					img: imageEditNoneNetwork2,
					alternateText: 'Select the None Network you would like to edit'
				},
				2: {
					stepTitle: '3. Click on edit Network button',
					body: 'Clicking on the edit Network button will bring up the Edit Network Request window.',
					img: imageEditNoneNetwork3,
					alternateText: 'Click on edit Network button'
				},
				3: {
					stepTitle: '4. On this window you can modify Network details and click on Next to save',
					body: 'Clicking on Next will save the changes to this Network.',
					img: imageEditNoneNetwork4,
					alternateText: 'On this window you can modify Network details and click on Next to save'
				}
			}
		}
	},
	{
		caseName: 'howToSearchForNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'How to search for a Network',
		categories: 'networks',
		content: {
			description: "Networks can be filtered by entering a Network's name into the search box on the Networks page.",
			steps: {
				0: {
					stepTitle: '1. Click on Networks',
					body: 'Clicking on Networks will bring you to the Networks page. On the Networks page, you can Request or select a Network.',
					img: imageSearchForNetwork1,
					alternateText: 'Click on Networks'
				},
				1: {
					stepTitle: "2. Enter a Network's name into the search box",
					body: 'This will remove all Networks that do not have the Network name entered into the search box.',
					img: imageSearchForNetwork2,
					alternateText: "Enter a Network's name into the search box"
				},
				2: {
					stepTitle: "3. Remove the Network's name or text to clear the filtered search",
					body: 'Notice the other Networks for this Organization reappear',
					img: imageSearchForNetwork3,
					alternateText: "Remove the Network's name or text to clear the filtered search"
				}
			}
		}
	},
	{
		caseName: 'cloningHealthPlanWithTieredNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Cloning a HealthPlan with a Tiered Network',
		categories: 'healthPlans, networks',
		content: {
			description:
				'In order to clone the Network when cloning a HealthPlan, you must select the Tiered Network type and choose the Network of the Cloned HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on the HealthPlans button will take you to the HealthPlans page. Where you can add a HealthPlan and search for a HealthPlan.',
					img: imageCloneHealthplanTieredNetwork1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Click on ADD PLAN',
					body: 'Clicking on Add Plan will cause the Create HealthPlan window to appear. On the Create HealthPlan window you can clone a previously created HealthPlan.',
					img: imageCloneHealthplanTieredNetwork2,
					alternateText: 'Click on ADD PLAN'
				},
				2: {
					stepTitle: '3. Click on the clone drop down list',
					body: 'This will bring up a list of HealthPlan that you can clone.',
					img: imageCloneHealthplanTieredNetwork3,
					alternateText: 'Click on the clone drop down list'
				},
				3: {
					stepTitle: '4. Select the HealthPlan that you would like to clone',
					body: 'This will take cause the HealthPlan generator to copy this HealthPlans details that are duplicatable.',
					img: imageCloneHealthplanTieredNetwork4,
					alternateText: 'Select the HealthPlan that you would like to clone'
				},
				4: {
					stepTitle: '5. Click on Clone',
					body: 'Clicking on Clone will bring you to the HealthPlan Details window.',
					img: imageCloneHealthplanTieredNetwork5,
					alternateText: 'Click on Clone'
				},
				5: {
					stepTitle: '6. Enter a name for the HealthPlan',
					body: 'This will be the name of the new HealthPlan since you should not have two HealthPlans with the same name.',
					img: imageCloneHealthplanTieredNetwork6,
					alternateText: 'Enter a name for the HealthPlan'
				},
				6: {
					stepTitle: '7. Enter a HealthPlan ID for the HealthPlan',
					body: 'This will be the HealthPlan ID of the new HealthPlan since you should not have two HealthPlans with the same HealthPlan ID.',
					img: imageCloneHealthplanTieredNetwork7,
					alternateText: 'Enter a HealthPlan ID for the HealthPlan'
				},
				7: {
					stepTitle: '8. Click on Next',
					body: 'This will take you to the Network Setup window.',
					img: imageCloneHealthplanTieredNetwork8,
					alternateText: 'Click on Next'
				},
				8: {
					stepTitle: '9. Click on the Network Type drop down button',
					body: 'This will bring up a list of available Network Types.',
					img: imageCloneHealthplanTieredNetwork9,
					alternateText: 'Click on the Network Type drop down button'
				},
				9: {
					stepTitle: '10. Select the Network Type of the HealthPlan you are cloning',
					body: 'This will allow you to find Networks within this Network type.',
					img: imageCloneHealthplanTieredNetwork10,
					alternateText: 'Select the Network Type of the HealthPlan you are cloning'
				},
				10: {
					stepTitle: '11. Click on the Request to a new network, or use an existing one drop down',
					body: 'This will bring up a list of available Networks of this Network type.',
					img: imageCloneHealthplanTieredNetwork11,
					alternateText: 'Click on the Request to a new network, or use an existing one drop down'
				},
				11: {
					stepTitle: '12. Select the Network of the HealthPlan you are cloning',
					body: 'This will fill in Network details for you based on the selected Network.',
					img: imageCloneHealthplanTieredNetwork12,
					alternateText: 'Select the Network of the HealthPlan you are cloning'
				},
				12: {
					stepTitle: '13. Click on Next',
					body: 'This will save the Network information to this HealthPlan and bring up the Telehealth Details window.',
					img: imageCloneHealthplanTieredNetwork13,
					alternateText: 'Click on Next'
				},
				13: {
					stepTitle: '14. Click on Next',
					body: 'Clicking on Next will save the Telehealth Details to this HealthPlan.',
					img: imageCloneHealthplanTieredNetwork14,
					alternateText: 'Click on Next'
				},
				14: {
					stepTitle: '15. Click on Save',
					body: 'This will save the selected ID Cards, GoodRx data, and the copays to this HealthPlan. Saving will create the HealthPlan and add it to the HealthPlan list with the chosen Network attached.',
					img: imageCloneHealthplanTieredNetwork15,
					alternateText: 'Click on Save'
				}
			}
		}
	},
	{
		caseName: 'cloningHealthPlanWithNoneNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Cloning a HealthPlan with a None Network',
		categories: 'healthPlans, networks',
		content: {
			description: 'In order to clone the Network when cloning a HealthPlan, you must choose the Network of the Cloned HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on the HealthPlans button will take you to the HealthPlans page. Where you can add a HealthPlan and search for a HealthPlan.',
					img: imageCloneHealthplanNoneNetwork1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Click on ADD PLAN',
					body: 'Clicking on Add Plan will cause the Create HealthPlan window to appear. On the Create HealthPlan window, you can clone a previously created HealthPlan.',
					img: imageCloneHealthplanNoneNetwork2,
					alternateText: 'Click on ADD PLAN'
				},
				2: {
					stepTitle: '3. Click on the clone drop down list',
					body: 'This will bring up a list of HealthPlan that you can clone.',
					img: imageCloneHealthplanNoneNetwork3,
					alternateText: 'Click on the clone drop down list'
				},
				3: {
					stepTitle: '4. Select the HealthPlan that you would like to clone',
					body: 'This will take cause the HealthPlan generator to copy this HealthPlans details that are duplicatable.',
					img: imageCloneHealthplanNoneNetwork4,
					alternateText: 'Select the HealthPlan that you would like to clone'
				},
				4: {
					stepTitle: '5. Click on Clone',
					body: 'Clicking on Clone will bring you to the HealthPlan Details window.',
					img: imageCloneHealthplanNoneNetwork5,
					alternateText: 'Click on Clone'
				},
				5: {
					stepTitle: '6. Enter a name for the HealthPlan',
					body: 'This will be the name of the new HealthPlan since you should not have two HealthPlans with the same name.',
					img: imageCloneHealthplanNoneNetwork6,
					alternateText: 'Enter a name for the HealthPlan'
				},
				6: {
					stepTitle: '7. Enter a HealthPlan ID for the HealthPlan',
					body: 'This will be the HealthPlan ID of the new HealthPlan since you should not have two HealthPlans with the same HealthPlan ID.',
					img: imageCloneHealthplanNoneNetwork7,
					alternateText: 'Enter a HealthPlan ID for the HealthPlan'
				},
				7: {
					stepTitle: '8. Click on Next',
					body: 'This will take you to the Network Setup window.',
					img: imageCloneHealthplanNoneNetwork8,
					alternateText: 'Click on Next'
				},
				8: {
					stepTitle: '9. Click on the Network Type drop down button',
					body: 'This will bring up a list of available Network Types.',
					img: imageCloneHealthplanNoneNetwork9,
					alternateText: 'Click on the Network Type drop down button'
				},
				9: {
					stepTitle: '10. Select the Network Type of the HealthPlan you are cloning',
					body: 'This will allow you to find Networks within this Network type.',
					img: imageCloneHealthplanNoneNetwork10,
					alternateText: 'Select the Network Type of the HealthPlan you are cloning'
				},
				10: {
					stepTitle: '11. Click on the Request to a new network, or use an existing one drop down',
					body: 'This will bring up a list of available Networks of this Network type.',
					img: imageCloneHealthplanNoneNetwork11,
					alternateText: 'Click on the Request to a new network, or use an existing one drop down'
				},
				11: {
					stepTitle: '12. Select the Network of the HealthPlan you are cloning',
					body: 'This will fill in Network details for you based on the selected Network.',
					img: imageCloneHealthplanNoneNetwork12,
					alternateText: 'Select the Network of the HealthPlan you are cloning'
				},
				12: {
					stepTitle: '13. Click on Next',
					body: 'This will save the Network information to this HealthPlan and bring up the Telehealth Details window.',
					img: imageCloneHealthplanNoneNetwork13,
					alternateText: 'Click on Next'
				},
				13: {
					stepTitle: '14. Click on Next',
					body: 'Clicking on Next will save the Telehealth Details to this HealthPlan.',
					img: imageCloneHealthplanNoneNetwork14,
					alternateText: 'Click on Next'
				},
				14: {
					stepTitle: '15. Click on Save',
					body: 'This will save the selected ID Cards, GoodRx data, and the copays to this HealthPlan. Saving will create the HealthPlan and add it to the HealthPlan list with the chosen Network attached.',
					img: imageCloneHealthplanNoneNetwork15,
					alternateText: 'Click on Save'
				}
			}
		}
	},
	{
		caseName: 'cloningHealthPlanWithTraditionalNetwork',
		iconDescription: 'fad fa-hospital-user',
		tutorialTitle: 'Cloning a HealthPlan with a Traditional Network',
		categories: 'healthPlans, networks',
		content: {
			description: 'In order to clone the Network when cloning a HealthPlan, you must choose the Network of the Cloned HealthPlan.',
			steps: {
				0: {
					stepTitle: '1. Click on HealthPlans',
					body: 'Clicking on the HealthPlans button will take you to the HealthPlans page. Where you can add a HealthPlan and search for a HealthPlan.',
					img: imageCloneHealthplanTraditionalNetwork1,
					alternateText: 'Click on HealthPlans'
				},
				1: {
					stepTitle: '2. Click on ADD PLAN',
					body: 'Clicking on Add Plan will cause the Create HealthPlan window to appear. On the Create HealthPlan window, you can clone a previously created HealthPlan.',
					img: imageCloneHealthplanTraditionalNetwork2,
					alternateText: 'Click on ADD PLAN'
				},
				2: {
					stepTitle: '3. Click on the clone drop down list',
					body: 'This will bring up a list of HealthPlan that you can clone.',
					img: imageCloneHealthplanTraditionalNetwork3,
					alternateText: 'Click on the clone drop down list'
				},
				3: {
					stepTitle: '4. Select the HealthPlan that you would like to clone',
					body: 'This will take cause the HealthPlan generator to copy this HealthPlans details that are duplicatable.',
					img: imageCloneHealthplanTraditionalNetwork4,
					alternateText: 'Select the HealthPlan that you would like to clone'
				},
				4: {
					stepTitle: '5. Click on Clone',
					body: 'Clicking on Clone will bring you to the HealthPlan Details window.',
					img: imageCloneHealthplanTraditionalNetwork5,
					alternateText: 'Click on Clone'
				},
				5: {
					stepTitle: '6. Enter a name for the HealthPlan',
					body: 'This will be the name of the new HealthPlan since you should not have two HealthPlans with the same name.',
					img: imageCloneHealthplanTraditionalNetwork6,
					alternateText: 'Enter a name for the HealthPlan'
				},
				6: {
					stepTitle: '7. Enter a HealthPlan ID for the HealthPlan',
					body: 'This will be the HealthPlan ID of the new HealthPlan since you should not have two HealthPlans with the same HealthPlan ID.',
					img: imageCloneHealthplanTraditionalNetwork7,
					alternateText: 'Enter a HealthPlan ID for the HealthPlan'
				},
				7: {
					stepTitle: '8. Click on Next',
					body: 'This will take you to the Network Setup window.',
					img: imageCloneHealthplanTraditionalNetwork8,
					alternateText: 'Click on Next'
				},
				8: {
					stepTitle: '9. Click on the Network Type drop down button',
					body: 'This will bring up a list of available Network Types.',
					img: imageCloneHealthplanTraditionalNetwork9,
					alternateText: 'Click on the Network Type drop down button'
				},
				9: {
					stepTitle: '10. Select the Network Type of the HealthPlan you are cloning',
					body: 'This will allow you to find Networks within this Network type.',
					img: imageCloneHealthplanTraditionalNetwork10,
					alternateText: 'Select the Network Type of the HealthPlan you are cloning'
				},
				10: {
					stepTitle: '11. Click on the Request to a new network, or use an existing one drop down',
					body: 'This will bring up a list of available Networks of this Network type.',
					img: imageCloneHealthplanTraditionalNetwork11,
					alternateText: 'Click on the Request to a new network, or use an existing one drop down'
				},
				11: {
					stepTitle: '12. Select the Network of the HealthPlan you are cloning',
					body: 'This will fill in Network details for you based on the selected Network.',
					img: imageCloneHealthplanTraditionalNetwork12,
					alternateText: 'Select the Network of the HealthPlan you are cloning'
				},
				12: {
					stepTitle: '13. Click on Next',
					body: 'This will save the Network information to this HealthPlan and bring up the Telehealth Details window.',
					img: imageCloneHealthplanTraditionalNetwork13,
					alternateText: 'Click on Next'
				},
				13: {
					stepTitle: '14. Click on Next',
					body: 'Clicking on Next will save the Telehealth Details to this HealthPlan.',
					img: imageCloneHealthplanTraditionalNetwork14,
					alternateText: 'Click on Next'
				},

				14: {
					stepTitle: '15. Click on Save',
					body: 'This will save the selected ID Cards, GoodRx data, and the copays to this HealthPlan. Saving will create the HealthPlan and add it to the HealthPlan list with the chosen Network attached.',
					img: imageCloneHealthplanTraditionalNetwork15,
					alternateText: 'Click on Save'
				}
			}
		}
	},
	{
		caseName: 'addTextBoxToIdCard',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Adding a text box to an ID card',
		categories: 'idCards',
		content: {
			description: 'You can add custom text to an ID card.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageAddTextBoxToIdCard1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageAddTextBoxToIdCard2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on the Text button',
					body: 'This will add a text box to the center of the ID card.',
					img: imageAddTextBoxToIdCard3,
					alternateText: 'Click on the Text button'
				},
				3: {
					stepTitle: '4. Click on the text box and drag it to the correct position on the ID card',
					body: 'This will be the position of the text on the ID card when generated.',
					img: imageAddTextBoxToIdCard4,
					alternateText: 'Click on the text box and drag it to the correct position on the ID card'
				},
				4: {
					stepTitle: '5. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageAddTextBoxToIdCard5,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'addFieldsToIdCard',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Adding fields to an ID card',
		categories: 'idCards',
		content: {
			description:
				'By adding a field to an ID card. You can pull data from the Group Details, HealthPlan Details and the Member Details sections of Express.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageAddFieldToIdCard1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageAddFieldToIdCard2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on Member field',
					body: 'This will bring up a list of fields available from the Member Details section of Express.',
					img: imageAddFieldToIdCard3,
					alternateText: 'Click on Member field'
				},
				3: {
					stepTitle: '4. Select a field to add to the ID card',
					body: 'When a user views their ID card in the CareValet app. This field will populate with data from the Member Details section of Express.',
					img: imageAddFieldToIdCard4,
					alternateText: 'Select a field to add to the ID card'
				},
				4: {
					stepTitle: '5. Drag the newly created field from the center of the ID card to the desired location',
					body: 'This will be where the data associated with this Member Field, appears on the ID card.',
					img: imageAddFieldToIdCard5,
					alternateText: 'Drag the newly created field from the center of the ID card to the desired location'
				},
				5: {
					stepTitle: '6. Click on Plan field',
					body: 'This will bring up a list of fields available from the HealthPlan Details section of Express.',
					img: imageAddFieldToIdCard6,
					alternateText: 'Click on Plan field'
				},
				6: {
					stepTitle: '7. Select a field to add to the ID card',
					body: 'When a user views their ID card in the CareValet app. This field will populate with data from the HealthPlan Details section of Express.',
					img: imageAddFieldToIdCard7,
					alternateText: 'Select a field to add to the ID card'
				},
				7: {
					stepTitle: '8. Drag the newly created field from the center of the ID card to the desired location',
					body: 'This will be where the data associated with this HealthPlan Field, appears on the ID card.',
					img: imageAddFieldToIdCard8,
					alternateText: 'Drag the newly created field from the center of the ID card to the desired location'
				},
				8: {
					stepTitle: '9. Click on Group field',
					body: 'This will bring up a list of fields available from the Group Details section of Express.',
					img: imageAddFieldToIdCard9,
					alternateText: 'Click on Group field'
				},
				9: {
					stepTitle: '10. Select a field to add to the ID card',
					body: 'When a user views their ID card in the CareValet app. This field will populate with data from the Group Details section of Express.',
					img: imageAddFieldToIdCard10,
					alternateText: 'Select a field to add to the ID card'
				},
				10: {
					stepTitle: '11. Drag the newly created field from the center of the ID card to the desired location',
					body: 'This will be where the data associated with this Group Field, appears on the ID card.',
					img: imageAddFieldToIdCard11,
					alternateText: 'Drag the newly created field from the center of the ID card to the desired location'
				},
				11: {
					stepTitle: '12. Drag one of the sides of this field',
					body: 'This will increase the space the data can take up on the ID card. This is important when handling large names or text. So that the text does not get cut off by another field or run onto the next line.',
					img: imageAddFieldToIdCard12,
					alternateText: 'Drag one of the sides of this field'
				}
			}
		}
	},
	{
		caseName: 'usingTheImageTool',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Using the Image tool',
		categories: 'idCards',
		content: {
			description: 'You can add images to the ID cards. For example, a logo can be added to the ID card via this method.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageUseImageTool1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageUseImageTool2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on the image button in the tool box on the left side of the ID card editor',
					body: "This will open your computer's file share. Select the file and it will appear in the middle of the ID card.",
					img: imageUseImageTool3,
					alternateText: 'Click on the image button in the tool box on the left side of the ID card editor'
				},
				3: {
					stepTitle: '4. Click on the image and drag it to the desired location on the ID card',
					body: 'This will be the location the image appears on the ID card.',
					img: imageUseImageTool4,
					alternateText: 'Click on the image and drag it to the desired location on the ID card'
				},
				4: {
					stepTitle: '5. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageUseImageTool5,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'changingBackgroundIdOfCard',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Changing the background of an ID card',
		categories: 'idCards',
		content: {
			description:
				'Using the Project Settings menu you can change the background and adjust the size of the background in the ID card editor.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageChangeBackgroundIdCard1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageChangeBackgroundIdCard2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. After selecting an ID card the Project Settings menu will appear on the right of the ID card.',
					body: 'The Project Settings menu allows you to change the default background image and adjust the size of the background.',
					img: imageChangeBackgroundIdCard3,
					alternateText: 'After selecting an ID card the Project Settings menu will appear on the right of the ID card.'
				},
				3: {
					stepTitle: '4. Click on Change',
					body: 'This will bring up your file explorer',
					img: imageChangeBackgroundIdCard4,
					alternateText: 'Click on Change'
				},
				4: {
					stepTitle: '5. Select your desired background image from your file explorer',
					body: 'This will add the background image to the ID card editor. You will have to delete your previous background, by clicking on it and pressing delete. You can keep the fields and text boxes and images that already existed on the previous background.',
					img: imageChangeBackgroundIdCard5,
					alternateText: 'Select your desired background image from your file explorer'
				},
				5: {
					stepTitle: '6. Click on the Adjust button within the Project Settings menu',
					body: 'Since the background image is not the proper size, the adjustment tool should be utilized. Once selected adjustment boxes will appear on the edges of the ID card.',
					img: imageChangeBackgroundIdCard6,
					alternateText: 'Click on the Adjust button within the Project Settings menu'
				},
				6: {
					stepTitle: '7. Dragging the boxes on the edge of the background image will adjust the size of the image',
					body: 'If you adjust at the corners, all the corners will be adjusted in the same manner. If you adjust at the sides, the opposite side will be adjusted in the same manner.',
					img: imageChangeBackgroundIdCard7,
					alternateText: 'Dragging the boxes on the edge of the background image will adjust the size of the image'
				},
				7: {
					stepTitle: '8. Click on Done to close the adjustment tool',
					body: '',
					img: imageChangeBackgroundIdCard8,
					alternateText: 'Click on Done to close the adjustment tool'
				},
				8: {
					stepTitle: '9. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageChangeBackgroundIdCard9,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'usingWhiteOutTool',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Using the Whiteout tool',
		categories: 'idCards',
		content: {
			description: 'You can white out portions of the ID card by using the Whiteout tool on the left side of the ID card editor.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageUseWhiteOutTool1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageUseWhiteOutTool2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on the Whiteout button',
					body: 'This will activate the whiteout tool',
					img: imageUseWhiteOutTool3,
					alternateText: 'Click on the Whiteout button'
				},
				3: {
					stepTitle: '4. Click on the ID card and drag over the content that needs to be would like to whiteout',
					body: 'This will create a movable white box over the area that was dragged over with the whiteout tool.',
					img: imageUseWhiteOutTool4,
					alternateText: 'Click on the ID card and drag over the content that needs to be would like to whiteout'
				},
				4: {
					stepTitle: '5. Click on presentation',
					body: '',
					img: imageUseWhiteOutTool5,
					alternateText: 'Click on presentation'
				},
				5: {
					stepTitle:
						'6. Select the white out box and input the correct color value into the Color input box or select it from the color wheel',
					body: 'This will change the color of the box to the desired color.',
					img: imageUseWhiteOutTool6,
					alternateText:
						'Select the white out box and input the correct color value into the Color input box or select it from the color wheel'
				},
				6: {
					stepTitle: '7. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageUseWhiteOutTool7,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'usingTextSettings',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Using the Text Settings',
		categories: 'idCards',
		content: {
			description: 'You can add custom text to an ID card and modify how the text appears on the ID card.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageUseTextSettings1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageUseTextSettings2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on a text box on the ID card',
					body: 'This will bring up the Text Settings menu on the right side of the ID card editor.',
					img: imageUseTextSettings3,
					alternateText: 'Click on a text box on the ID card'
				},
				3: {
					stepTitle: '4. To adjust the font click on the Font Face drop down button.',
					body: 'This will bring up a list of available fonts.',
					img: imageUseTextSettings4,
					alternateText: 'To adjust the font click on the Font Face drop down button.'
				},
				4: {
					stepTitle: '5. Select the preferred font',
					body: 'This will change the font of the text within the selected text box. This will not impact other text on the ID card.',
					img: imageUseTextSettings5,
					alternateText: 'Select the preferred font'
				},
				5: {
					stepTitle: '6. Select a desired style or set of styles for this text box',
					body: 'This will change the styling of the text within the box. The currently selected style choice S will add a strikethrough to the text within the box. B will bolden the text. I will italicize the text. U will underline the text. You can use any combination of these styles together.',
					img: imageUseTextSettings6,
					alternateText: 'Select a desired style or set of styles for this text box'
				},
				6: {
					stepTitle: '7. Select a desired alignment for this text box',
					body: 'This will change the alignment of the text within the box. The left alignment choice will cause the text to align on the left of the text box. The middle alignment choice will center the text within the text box. The right alignment choice will align on the right of the text box.',
					img: imageUseTextSettings7,
					alternateText: 'Select a desired alignment for this text box'
				},
				7: {
					stepTitle: '8. Selecting the color cirlce at the bottom of the text settings menu will bring up the color tool.',
					body: 'You can modify the color of the text by selecting a color using the color tool.',
					img: imageUseTextSettings8,
					alternateText: 'Selecting the color cirlce at the bottom of the text settings menu will bring up the color tool.'
				},
				8: {
					stepTitle: '9. If you have a color already on the card that has been selected within the ID card editor',
					body: 'You will see the value stored in the color text box. You can copy this value and add it to other text boxes that you would like to add the color too.',
					img: imageUseTextSettings9,
					alternateText: 'If you have a color already on the card that has been selected within the ID card editor'
				},
				9: {
					stepTitle: '10. Instead of grabbing the text box on the ID card and modifying its position',
					body: 'You can adjust its placement on the ID card by changing the X and Y values in the transform section of the Text Settings menu.',
					img: imageUseTextSettings10,
					alternateText: 'Instead of grabbing the text box on the ID card and modifying its position'
				},
				10: {
					stepTitle: '11. Instead of grabbing the text box on the ID card and modifying its size',
					body: 'You can modify its size on the ID card by changing the W and H values in the transform section of the Text Settings menu.',
					img: imageUseTextSettings11,
					alternateText: 'Instead of grabbing the text box on the ID card and modifying its size'
				},
				11: {
					stepTitle: '12. To modify the size of the text in the text box',
					body: 'You can enter the desired size into the size text box or drag the size bar to the desired size.',
					img: imageUseTextSettings12,
					alternateText: 'To modify the size of the text in the text box'
				},
				12: {
					stepTitle: '13. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageUseTextSettings13,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'usingFieldSettings',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Using the Field Settings',
		categories: 'idCards',
		content: {
			description: 'You can add fields to an ID card and modify how the Field appears on the ID card.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageUseFieldSettings1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageUseFieldSettings2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on Plan field',
					body: 'This will bring up a list of fields available from the HealthPlan Details section of Express.',
					img: imageUseFieldSettings3,
					alternateText: 'Click on Plan field'
				},
				3: {
					stepTitle: '4. Select a Plan field to add to the ID card',
					body: 'When a user views their ID card in the CareValet app. This field will populate with data from the HealthPlan Details section of Express.',
					img: imageUseFieldSettings4,
					alternateText: 'Select a Plan field to add to the ID card'
				},
				4: {
					stepTitle: '5. Drag the newly created field from the center of the ID card to the desired location',
					body: 'This will be where the data associated with this Plan Field, appears on the ID card.',
					img: imageUseFieldSettings5,
					alternateText: 'Drag the newly created field from the center of the ID card to the desired location'
				},
				5: {
					stepTitle: '6. The position of the field can be adjusted by changing the value in the Transform X input box',
					body: "This will move this field's horizontal location on the ID card.",
					img: imageUseFieldSettings6,
					alternateText: 'The position of the field can be adjusted by changing the value in the Transform X input box'
				},
				6: {
					stepTitle: '7. The position of the field can be adjusted by changing the value in the Transform Y input box',
					body: "This will move this field's vertical location on the ID card.",
					img: imageUseFieldSettings7,
					alternateText: 'The position of the field can be adjusted by changing the value in the Transform Y input box'
				},
				7: {
					stepTitle: '8. The width of the field can be adjusted by changing the value in the Transform W input box',
					body: "This will increase this field's width on the ID card.",
					img: imageUseFieldSettings8,
					alternateText: 'The width of the field can be adjusted by changing the value in the Transform W input box'
				},
				8: {
					stepTitle: '9. The width of the field can be adjusted by changing the value in the Transform H input box',
					body: "This will increase this field's height on the ID card.",
					img: imageUseFieldSettings9,
					alternateText: 'The width of the field can be adjusted by changing the value in the Transform H input box'
				},
				9: {
					stepTitle: '10. To change the font of the field, click on the Font Face drop down button',
					body: 'This will bring up a list of available fonts for this field.',
					img: imageUseFieldSettings10,
					alternateText: 'To change the font of the field, click on the Font Face drop down button'
				},
				10: {
					stepTitle: '11. Select the desired Font for the field',
					body: 'This will change the font of the field to the selected font.',
					img: imageUseFieldSettings11,
					alternateText: 'Select the desired Font for the field'
				},
				11: {
					stepTitle:
						'12. The size of the font can be adjusted by entering a value into the size input box, or by dragging the size slider',
					body: 'This will increase or decrease the size of the field depending on what value is input.',
					img: imageUseFieldSettings12,
					alternateText:
						'The size of the font can be adjusted by entering a value into the size input box, or by dragging the size slider'
				},
				12: {
					stepTitle: '13. Select a desired style or set of styles for this text box',
					body: 'This will change the styling of the text within the box. The currently highlighted style choice S will add a strikethrough to the text within the box. B will bolden the text. I will italicize the text. U will underline the text. You can use any combination of these styles together.',
					img: imageUseFieldSettings13,
					alternateText: 'Select a desired style or set of styles for this text box'
				},
				13: {
					stepTitle: '14. Select a desired alignment for this text box',
					body: 'This will change the alignment of the text within the box. The left alignment choice will cause the text to align on the left of the text box. The middle alignment choice will center the text within the text box. The right alignment choice will align on the right of the text box.',
					img: imageUseFieldSettings14,
					alternateText: 'Select a desired alignment for this text box'
				},
				14: {
					stepTitle: '15. Click on the color button to adjust the color of the selected field',
					body: 'This will bring up the color adjustment tool.',
					img: imageUseFieldSettings15,
					alternateText: 'Click on the color button to adjust the color of the selected field'
				},
				15: {
					stepTitle: '16. Enter a color value into the color input box',
					body: 'This will change the color of the field.',
					img: imageUseFieldSettings16,
					alternateText: 'Enter a color value into the color input box'
				},
				16: {
					stepTitle: '17. Click on the Plan Field drop down box',
					body: 'This will show a list of fields from the same field group such as Group fields, Plan fields, and Member fields.',
					img: imageUseFieldSettings17,
					alternateText: 'Click on the Plan Field drop down box'
				},
				17: {
					stepTitle: '18. Select the desired field',
					body: 'This will change the currently selected field to the chosen field.',
					img: imageUseFieldSettings18,
					alternateText: 'Select the desired field'
				},
				18: {
					stepTitle: '19. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageUseFieldSettings19,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'usingShapeToolAndSettings',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'Using the Shape Tool and Settings',
		categories: 'idCards',
		content: {
			description: 'You can create and place shapes onto the ID card by using the Shape tool on the left side of the ID card editor.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will take you to the ID Cards page.',
					img: imageUseShapeToolSettings1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Select the ID card you would like to edit',
					body: 'This will open the selected ID card in the ID card editor.',
					img: imageUseShapeToolSettings2,
					alternateText: 'Select the ID card you would like to edit'
				},
				2: {
					stepTitle: '3. Click on the Shape button',
					body: 'This will cause the shape options to pop up.',
					img: imageUseShapeToolSettings3,
					alternateText: 'Click on the Shape button'
				},
				3: {
					stepTitle: '4. Select a shape',
					body: 'This will be the shape that the shaping tool creates on the ID card.',
					img: imageUseShapeToolSettings4,
					alternateText: 'Select a shape'
				},
				4: {
					stepTitle: '5. Click and drag on the ID card to create a shape',
					body: 'This will create the selected shape on the ID card.',
					img: imageUseShapeToolSettings5,
					alternateText: 'Click and drag on the ID card to create a shape'
				},
				5: {
					stepTitle: '6. You can expand and contract the shape by clicking on and dragging the boxes on the edge of the shape',
					body: 'If you adjust at the corners, all the corners will be adjusted in the same manner. If you adjust at the sides, the opposite side will be adjusted in the same manner.',
					img: imageUseShapeToolSettings6,
					alternateText: 'You can expand and contract the shape by clicking on and dragging the boxes on the edge of the shape'
				},
				6: {
					stepTitle: '7. Click on the color button to open the color selector',
					body: 'This will allow you to select a color from the color modifier tool.',
					img: imageUseShapeToolSettings7,
					alternateText: 'Click on the color button to open the color selector'
				},
				7: {
					stepTitle: '8. Enter a color value into the Color input box to change the color of the shape.',
					body: 'This will cause the color of the shape to change to the chosen color value.',
					img: imageUseShapeToolSettings8,
					alternateText: 'Enter a color value into the Color input box to change the color of the shape.'
				},
				8: {
					stepTitle: '9. You can adjust the corners of the shape by moving the Corner Radius slider',
					body: 'This will cause the corners of the shape to become more or less round.',
					img: imageUseShapeToolSettings9,
					alternateText: 'You can adjust the corners of the shape by moving the Corner Radius slider'
				},
				9: {
					stepTitle: '10. You can adjust the corners of the shape by inputting a value into the Corner Radius input box.',
					body: 'This will cause the corners of the shape to become more or less round.',
					img: imageUseShapeToolSettings10,
					alternateText: 'You can adjust the corners of the shape by inputting a value into the Corner Radius input box.'
				},
				10: {
					stepTitle: '11. The position of the shape can be adjusted by changing the value in the Transform X input box',
					body: "This will move this shape's horizontal location on the ID card.",
					img: imageUseShapeToolSettings11,
					alternateText: 'The position of the shape can be adjusted by changing the value in the Transform X input box'
				},
				11: {
					stepTitle: '12. The position of the shape can be adjusted by changing the value in the Transform Y input box',
					body: "This will move this shape's vertical location on the ID card.",
					img: imageUseShapeToolSettings12,
					alternateText: 'The position of the shape can be adjusted by changing the value in the Transform Y input box'
				},
				12: {
					stepTitle: '13. The width of the shape can be adjusted by changing the value in the Transform W input box',
					body: "This will increase this shape's width on the ID card.",
					img: imageUseShapeToolSettings13,
					alternateText: 'The width of the shape can be adjusted by changing the value in the Transform W input box'
				},
				13: {
					stepTitle: '14. The width of the shape can be adjusted by changing the value in the Transform H input box',
					body: "This will increase this shape's height on the ID card.",
					img: imageUseShapeToolSettings14,
					alternateText: 'The width of the shape can be adjusted by changing the value in the Transform H input box'
				},
				14: {
					stepTitle: '15. You can add text to the shape by selecting the text button after the shape has been made',
					body: 'Previous text will go behind the shape if it was made before the shape was.',
					img: imageUseShapeToolSettings15,
					alternateText: 'You can add text to the shape by selecting the text button after the shape has been made'
				},
				15: {
					stepTitle: '16. You can add a field to the shape by selecting the a field button after the shape has already been made',
					body: 'Previous fields will go behind the shape if it was made before the shape was.',
					img: imageUseShapeToolSettings16,
					alternateText: 'You can add a field to the shape by selecting the a field button after the shape has already been made'
				},
				16: {
					stepTitle: '17. Select the desired field',
					body: 'This will appear at the center of the ID card and can be placed over the shape.',
					img: imageUseShapeToolSettings17,
					alternateText: 'Select the desired field'
				},
				17: {
					stepTitle: '18. Click on SAVE',
					body: 'This will save the changes to the ID card. All members with this ID card attached to their HealthPlan will see the update in their benefits wallet immediately.',
					img: imageUseShapeToolSettings18,
					alternateText: 'Click on SAVE'
				}
			}
		}
	},
	{
		caseName: 'howToCreateIdCardWithTemplate',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to create an ID card with a Template',
		categories: 'idCards',
		content: {
			description: 'ID cards can be created using a template. Instead of creating it from scratch.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will bring up the ID Card page.',
					img: imageCreateIdCardWithTemplate1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Click on CREATE CARD',
					body: 'This will bring up the options Blank and Template.',
					img: imageCreateIdCardWithTemplate2,
					alternateText: 'Click on CREATE CARD'
				},
				2: {
					stepTitle: '3. Click on Template',
					body: 'This will bring up the New Card window. On the New Card page, you can name the ID card, determine the desired template for the ID card, and if it is the front or back of the ID card.',
					img: imageCreateIdCardWithTemplate3,
					alternateText: 'Click on Template'
				},
				3: {
					stepTitle: '4. Select a template',
					body: 'This will be the ID card when opened in the ID card editor',
					img: imageCreateIdCardWithTemplate4,
					alternateText: 'Select a template'
				},
				4: {
					stepTitle: '5. Name the ID card',
					body: 'This will be the name that identifies this ID card throughout Express.',
					img: imageCreateIdCardWithTemplate5,
					alternateText: 'Name the ID card'
				},
				5: {
					stepTitle: '6. Click on the Side drop down button',
					body: 'This will bring up the options of Front and Back.',
					img: imageCreateIdCardWithTemplate6,
					alternateText: 'Click on the Side drop down button'
				},
				6: {
					stepTitle: '7. Click on Front',
					body: 'This will set the ID cards side to be front.',
					img: imageCreateIdCardWithTemplate7,
					alternateText: 'Click on Front'
				},
				7: {
					stepTitle: '8. Click on CONTINUE',
					body: 'This will bring up the selected template in the ID card editor.',
					img: imageCreateIdCardWithTemplate8,
					alternateText: 'Click on CONTINUE'
				}
			}
		}
	},
	{
		caseName: 'howToCreateNonTemplatedIdCard',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to create a non templated ID card',
		categories: 'idCards',
		content: {
			description: 'ID cards can be created using a template. Instead of creating it from scratch.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will bring up the ID Card page.',
					img: imageCreateNonTemplatedIdCard1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Click on CREATE CARD',
					body: 'This will bring up the options of Blank and Template.',
					img: imageCreateNonTemplatedIdCard2,
					alternateText: 'Click on CREATE CARD'
				},
				2: {
					stepTitle: '3. Click on Blank',
					body: 'This will bring up the New Card window. On the New Card window, you can name the ID card,  and if it is the front or back of the ID card.',
					img: imageCreateNonTemplatedIdCard3,
					alternateText: 'Click on Blank'
				},
				3: {
					stepTitle: '4. Enter a name for the ID card in the Card Name text box',
					body: 'This will be the name that identifies this ID card throughout Express.',
					img: imageCreateNonTemplatedIdCard4,
					alternateText: 'Enter a name for the ID card in the Card Name text box'
				},
				4: {
					stepTitle: '5. Click on the Choose side drop down button',
					body: 'This will bring up the options Front and Back.',
					img: imageCreateNonTemplatedIdCard5,
					alternateText: 'Click on the Choose side drop down button'
				},
				5: {
					stepTitle: '6. Click on FRONT',
					body: 'This will set the ID cards side to be front.',
					img: imageCreateNonTemplatedIdCard6,
					alternateText: 'Click on FRONT'
				},
				6: {
					stepTitle: '7. Click on CONTINUE',
					body: 'This will close the New Card window and cause the ID card editor to open.',
					img: imageCreateNonTemplatedIdCard7,
					alternateText: 'Click on CONTINUE'
				}
			}
		}
	},
	{
		caseName: 'howToCreateAnIdCard',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to create an ID card',
		categories: 'idCards',
		content: {
			description: 'The front and back of an ID card can be created via the Create Card button.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will bring up the ID Card page.',
					img: imageCreateIdCard1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Click on CREATE CARD',
					body: 'This will bring up the options of Blank and Template.',
					img: imageCreateIdCard2,
					alternateText: 'Click on CREATE CARD'
				},
				2: {
					stepTitle: '3. Click on Blank',
					body: 'This will bring up the New Card window. On the New Card window, you can name the ID card, and if it is the front or back of the ID card.',
					img: imageCreateIdCard3,
					alternateText: 'Click on Blank'
				},
				3: {
					stepTitle: '4. Enter a name for the ID card in the Card Name text box',
					body: 'This will be the name that identifies this ID card throughout Express.',
					img: imageCreateIdCard4,
					alternateText: 'Enter a name for the ID card in the Card Name text box'
				},
				4: {
					stepTitle: '5. Click on the Choose side drop down button',
					body: 'This will bring up the options Front and Back.',
					img: imageCreateIdCard5,
					alternateText: 'Click on the Choose side drop down button'
				},
				5: {
					stepTitle: '6. Click on FRONT',
					body: 'This will set the ID cards side to be front.',
					img: imageCreateIdCard6,
					alternateText: 'Click on FRONT'
				},
				6: {
					stepTitle: '7. Click on the Click or drop file here button',
					body: "This will bring up the computer's file share system.",
					img: imageCreateIdCard7,
					alternateText: 'Click on the Click or drop file here button'
				},
				7: {
					stepTitle: '8. Select the file',
					body: 'Notice the file will show up in place of the Click or drop file here button',
					img: imageCreateIdCard8,
					alternateText: 'Select the file'
				},
				8: {
					stepTitle: '9. To modify the size click on the zoom bar',
					body: 'This will enlarge or shrink the image depending on which direction the bar is dragged.',
					img: imageCreateIdCard9,
					alternateText: 'To modify the size click on the zoom bar'
				},
				9: {
					stepTitle: '10. Click on AUTO',
					body: 'This will automatically size the image.',
					img: imageCreateIdCard10,
					alternateText: 'Click on AUTO'
				},
				10: {
					stepTitle: '11. Click on CONTINUE',
					body: 'This will close the New Card window and cause the ID card editor to open.',
					img: imageCreateIdCard11,
					alternateText: 'Click on CONTINUE'
				}
			}
		}
	},
	{
		caseName: 'howToUseCopyPasteDeleteTools',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to use the copy, paste and delete tools',
		categories: 'idCards',
		content: {
			description: 'Sometimes when editing an ID card objects may need to get copied or deleted. The Copy and delete tools will help.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will bring up the ID Cards page',
					img: imageUseCopyPasteDeleteTools1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Click on Walkthrough front ID card',
					body: 'This will bring up the ID card editor with the selected card loaded.',
					img: imageUseCopyPasteDeleteTools2,
					alternateText: 'Click on Walkthrough front ID card'
				},
				2: {
					stepTitle: '3. Right click on one of the objects',
					body: 'You will see some options appear, such as Copy, Delete, Move Forward, Move to Front, Move Backward, and Move to Back.',
					img: imageUseCopyPasteDeleteTools3,
					alternateText: 'Right click on one of the objects'
				},
				3: {
					stepTitle: '4. Click on Copy',
					body: 'This will copy the object.',
					img: imageUseCopyPasteDeleteTools4,
					alternateText: 'Click on Copy'
				},
				4: {
					stepTitle: '5. Right click on the object that was just copied',
					body: 'You will see some options appear, such as Copy, Delete, Move Forward, Move to Front, Move Backward, and Move to Back.',
					img: imageUseCopyPasteDeleteTools5,
					alternateText: 'Right click on the object that was just copied'
				},
				5: {
					stepTitle: '6. Click on Delete',
					body: 'This will delete the object from the ID card editor.',
					img: imageUseCopyPasteDeleteTools6,
					alternateText: 'Click on Delete'
				},
				6: {
					stepTitle: '7. Right click anywhere on the ID card',
					body: 'You will see some options appear, such as Copy, Delete, Move Forward, Move to Front, Move Backward, and Move to Back.',
					img: imageUseCopyPasteDeleteTools7,
					alternateText: 'Right click anywhere on the ID card'
				},
				7: {
					stepTitle: '8. Click on Paste',
					body: 'This will paste the copied object in the location it was copied.',
					img: imageUseCopyPasteDeleteTools8,
					alternateText: 'Click on Paste'
				}
			}
		}
	},
	{
		caseName: 'howToUseMoveToTools',
		iconDescription: 'fad fa-id-card',
		tutorialTitle: 'How to use the Move to Front and Move to Back tools',
		categories: 'idCards',
		content: {
			description:
				'Sometimes when editing an ID card objects can get stuck behind or in front of other objects. The Move to Front and Move to Back tools will help when dealing with a problematic object.',
			steps: {
				0: {
					stepTitle: '1. Click on ID Cards',
					body: 'This will bring up the ID Cards page',
					img: imageUseMoveToTools1,
					alternateText: 'Click on ID Cards'
				},
				1: {
					stepTitle: '2. Click on Walkthrough front ID card',
					body: 'This will bring up the ID card editor with the selected card loaded.',
					img: imageUseMoveToTools2,
					alternateText: 'Click on Walkthrough front ID card'
				},
				2: {
					stepTitle: '3. Right click on an object in the ID card editor',
					body: 'You will see some options appear, such as Copy, Delete, Move Forward, Move to Front, Move Backward, and Move to Back.',
					img: imageUseMoveToTools3,
					alternateText: 'Right click on an object in the ID card editor'
				},
				3: {
					stepTitle: '4. Click on Move to Back',
					body: 'This will move the object behind all objects in the ID card editor',
					img: imageUseMoveToTools4,
					alternateText: 'Click on Move to Back'
				},
				4: {
					stepTitle: '5. Click on the object on the ID card that is now in front of the other objects and drag it to the side',
					body: 'This will show the objects that were just hidden.',
					img: imageUseMoveToTools5,
					alternateText: 'Click on the object on the ID card that is now in front of the other objects and drag it to the side'
				},
				5: {
					stepTitle: '6. Right Click on one of the hideen objects',
					body: 'You will see some options appear, such as Copy, Delete, Move Forward, Move to Front, Move Backward, and Move to Back.',
					img: imageUseMoveToTools6,
					alternateText: 'Right Click on one of the hideen objects'
				},
				6: {
					stepTitle: '7. Click on Move to Front',
					body: 'This will move the object in front of all objects in the ID card editor',
					img: imageUseMoveToTools7,
					alternateText: 'Click on Move to Front'
				},
				7: {
					stepTitle: "8. Drag the object back to it's original position",
					body: 'The objects that you moved to the front should now appear in front of the object that was obstructing them before.',
					img: imageUseMoveToTools8,
					alternateText: "Drag the object back to it's original position"
				}
			}
		}
	},
	{
		caseName: 'howToToggleProviderFinderGroups',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable Provider Finder',
		categories: 'appConfig',
		content: {
			description:
				'The Provider Finder can be turned off in the app if needed. This will remove the Provider Finder button on the home page as well as the Provider button on the navbar.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleProviderFinderGroups1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleProviderFinderGroups2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration',
					img: imageToggleProviderFinderGroups3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on the Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageToggleProviderFinderGroups4,
					alternateText: 'Scroll down and click on the Home Page Toggles'
				},
				4: {
					stepTitle: '5. Click on the Disable provider finder toggle',
					body: 'This will turn off the Provider Finder button on the Home page of the CareValet app. It will also turn off the Provider button on the navbar.',
					img: imageToggleProviderFinderGroups5,
					alternateText: 'Click on the Disable provider finder toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleProviderFinderGroups6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleProviderFinderGroups7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Click on Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageToggleProviderFinderGroups8,
					alternateText: 'Click on Home Page Toggles'
				},
				8: {
					stepTitle: '9. Click on the Disable Provider Finder toggle',
					body: 'This will turn the Disable Provider Finder toggle off and cause the Provider Finder button on the Home page and the navbar to reactivate.',
					img: imageToggleProviderFinderGroups9,
					alternateText: 'Click on the Disable Provider Finder toggle'
				},
				9: {
					stepTitle: '10. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleProviderFinderGroups10,
					alternateText: 'Click on Save Group Config Changes'
				},
				10: {
					stepTitle: '11. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleProviderFinderGroups11,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleBookAppointmentMctGroups',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable Book Appointments from My Care Team providers',
		categories: 'appConfig',
		content: {
			description:
				'You can enable or disable the Book Appointments button from the CareValet app, via the Home Page section of Manage Configurations. This only affects the Book Appointments button for providers that appear on the users CareTeam.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleBookAppointmentMctGroups1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleBookAppointmentMctGroups2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration',
					img: imageToggleBookAppointmentMctGroups3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on the Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageToggleBookAppointmentMctGroups4,
					alternateText: 'Scroll down and click on the Home Page Toggles'
				},
				4: {
					stepTitle: '5. Click on the Appointments Enabled toggle',
					body: 'This will turn off the Book Appointment button for provider cards on the Home Page under My Care Team.',
					img: imageToggleBookAppointmentMctGroups5,
					alternateText: 'Click on the Appointments Enabled toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleBookAppointmentMctGroups6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleBookAppointmentMctGroups7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Click on Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageToggleBookAppointmentMctGroups8,
					alternateText: 'Click on Home Page Toggles'
				},
				8: {
					stepTitle: '9. Click on the Appointments Enabled toggle',
					body: 'This will turn on the Book Appointment button for provider cards on the Home Page under My Care Team.',
					img: imageToggleBookAppointmentMctGroups9,
					alternateText: 'Click on the Appointments Enabled toggle'
				},
				9: {
					stepTitle: '10. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleBookAppointmentMctGroups10,
					alternateText: 'Click on Save Group Config Changes'
				},
				10: {
					stepTitle: '11. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleBookAppointmentMctGroups11,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToTogglePrescriptionNavBarGroups',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Prescription navbar button',
		categories: 'appConfig',
		content: {
			description:
				'The Prescription navbar button can be turned off in the app if needed. This will remove the Prescription button from the navbar.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageTogglePrescriptionNavbarGroups1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageTogglePrescriptionNavbarGroups2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration',
					img: imageTogglePrescriptionNavbarGroups3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on the Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageTogglePrescriptionNavbarGroups4,
					alternateText: 'Scroll down and click on the Home Page Toggles'
				},
				4: {
					stepTitle: '5. Click on the Hide Prescription Navigation button toggle',
					body: 'This will turn off the Prescription button on the navbar within the CareValet app.',
					img: imageTogglePrescriptionNavbarGroups5,
					alternateText: 'Click on the Hide Prescription Navigation button toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageTogglePrescriptionNavbarGroups6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageTogglePrescriptionNavbarGroups7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Click on Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageTogglePrescriptionNavbarGroups8,
					alternateText: 'Click on Home Page Toggles'
				},
				8: {
					stepTitle: '9. Click on the Hide Prescription Navigation button toggle',
					body: 'This will turn on the Prescription button on the navbar within the CareValet app.',
					img: imageTogglePrescriptionNavbarGroups9,
					alternateText: 'Click on the Hide Prescription Navigation button toggle'
				},
				9: {
					stepTitle: '10. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageTogglePrescriptionNavbarGroups10,
					alternateText: 'Click on Save Group Config Changes'
				},
				10: {
					stepTitle: '11. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageTogglePrescriptionNavbarGroups11,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleCovidTestFinderGroups',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Covid Test Finder',
		categories: 'appConfig',
		content: {
			description:
				'The Covid Test Finder can be turned off in the app if needed. This will remove the Covid Test Finder button on the home page.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleCovidTestFinderGroups1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleCovidTestFinderGroups2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration',
					img: imageToggleCovidTestFinderGroups3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on the Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageToggleCovidTestFinderGroups4,
					alternateText: 'Scroll down and click on the Home Page Toggles'
				},
				4: {
					stepTitle: '5. Click on the Covid Test Finder Enabled toggle',
					body: 'This will turn off the Covid Test Finder button on the Home page of the CareValet app.',
					img: imageToggleCovidTestFinderGroups5,
					alternateText: 'Click on the Covid Test Finder Enabled toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleCovidTestFinderGroups6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleCovidTestFinderGroups7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Click on Home Page Toggles',
					body: 'This will bring up the Home Page Toggle options.',
					img: imageToggleCovidTestFinderGroups8,
					alternateText: 'Click on Home Page Toggles'
				},
				8: {
					stepTitle: '9. Click on the Covid Test Finder Enabled toggle',
					body: 'This will turn on the Covid Test Finder button on the Home page of the CareValet app.',
					img: imageToggleCovidTestFinderGroups9,
					alternateText: 'Click on the Covid Test Finder Enabled toggle'
				},
				9: {
					stepTitle: '10. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleCovidTestFinderGroups10,
					alternateText: 'Click on Save Group Config Changes'
				},
				10: {
					stepTitle: '11. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleCovidTestFinderGroups11,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToAddCustomLogoGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to add a custom logo',
		categories: 'appConfig',
		content: {
			description:
				'White labeling is possible via the custom logo config setting. The logo can be swapped with an image of your choosing.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageAddCustomLogoGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageAddCustomLogoGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageAddCustomLogoGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on the Custom Logo drop down button',
					body: 'This will bring up the option to add a file to use as the logo.',
					img: imageAddCustomLogoGroupsPage4,
					alternateText: 'Scroll down and click on the Custom Logo drop down button'
				},
				4: {
					stepTitle: '5. Click on Click or drop files here',
					body: 'This will bring up the file explorer of your device.',
					img: imageAddCustomLogoGroupsPage5,
					alternateText: 'Click on Click or drop files here'
				},
				5: {
					stepTitle: '6. After choosing a file, click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageAddCustomLogoGroupsPage6,
					alternateText: 'After choosing a file, click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageAddCustomLogoGroupsPage7,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToConfigureTelehealthTextGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to Configure Telehealth Text',
		categories: 'appConfig',
		content: {
			description: 'The Telehealth button has configurable text.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageConfigureTelehealthTextGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageConfigureTelehealthTextGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageConfigureTelehealthTextGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Configure Telehealth Text',
					body: 'This will bring up the option to change the text used on the Telehealth button.',
					img: imageConfigureTelehealthTextGroupsPage4,
					alternateText: 'Scroll down and click on Configure Telehealth Text'
				},
				4: {
					stepTitle: '5. In the Telehealth Card Title text box enter the desired text',
					body: 'This will replace the large bolded text placement on the Telehealth button.',
					img: imageConfigureTelehealthTextGroupsPage5,
					alternateText: 'In the Telehealth Card Title text box enter the desired text'
				},
				5: {
					stepTitle: '6. In the Telehealth Card Top Text text box, enter the desired text',
					body: 'This will replace the top text on the Telehealth button.',
					img: imageConfigureTelehealthTextGroupsPage6,
					alternateText: 'In the Telehealth Card Top Text text box, enter the desired text'
				},
				6: {
					stepTitle: '7. In the Telehealth Card Bottom Text text box, enter the desired text',
					body: 'This will replace the bottom text on the Telehealth button.',
					img: imageConfigureTelehealthTextGroupsPage7,
					alternateText: 'In the Telehealth Card Bottom Text text box, enter the desired text'
				},
				7: {
					stepTitle: '8. In the Action List Title text box, enter the desired text',
					body: 'This will change the title of the telehealth action list.',
					img: imageConfigureTelehealthTextGroupsPage8,
					alternateText: 'In the Action List Title text box, enter the desired text'
				},
				8: {
					stepTitle: '9. In the Action List Visit Website Text text box, enter the desired text',
					body: 'This will change the text of the Open Website button on the action list.',
					img: imageConfigureTelehealthTextGroupsPage9,
					alternateText: 'In the Action List Visit Website Text text box, enter the desired text'
				},
				9: {
					stepTitle: '10. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageConfigureTelehealthTextGroupsPage10,
					alternateText: 'Click on Save Group Config Changes'
				},
				10: {
					stepTitle: '11. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageConfigureTelehealthTextGroupsPage11,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleMyPrescriptionBannerCardGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the My Prescriptions banner card',
		categories: 'appConfig',
		content: {
			description: 'The My Prescription banner card can be toggled on and off in the app if needed.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleMyPrescriptionBannerCardGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the Prescription Card toggle',
					body: 'This will turn off the Prescription banner card on the front page of the CareValet app.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage5,
					alternateText: 'Click on the Prescription Card toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage8,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				8: {
					stepTitle: '9. Click on the Prescription Card toggle',
					body: 'This will turn on the Prescription banner card on the front page of the CareValet app.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage9,
					alternateText: 'Click on the Prescription Card toggle'
				},
				9: {
					stepTitle: '10. Click on Next slide',
					body: 'This will bring up the My Prescription banner card.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage10,
					alternateText: 'Click on Next slide'
				},
				10: {
					stepTitle: '11. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage11,
					alternateText: 'Click on Save Group Config Changes'
				},
				11: {
					stepTitle: '12. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleMyPrescriptionBannerCardGroupsPage12,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleTelehealthBannerCardGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Telehealth banner card',
		categories: 'appConfig',
		content: {
			description: 'The Telehealth banner card can be toggled on and off in the app if needed.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleTelehealthBannerCardGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleTelehealthBannerCardGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageToggleTelehealthBannerCardGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleTelehealthBannerCardGroupsPage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the Telehealth Card toggle',
					body: 'This will turn off the Telehealth banner card on the front page of the CareValet app.',
					img: imageToggleTelehealthBannerCardGroupsPage5,
					alternateText: 'Click on the Telehealth Card toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleTelehealthBannerCardGroupsPage6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleTelehealthBannerCardGroupsPage7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleTelehealthBannerCardGroupsPage8,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				8: {
					stepTitle: '9. Click on the Telehealth Card toggle',
					body: 'This will turn on the Telehealth banner card on the front page of the CareValet app.',
					img: imageToggleTelehealthBannerCardGroupsPage9,
					alternateText: 'Click on the Telehealth Card toggle'
				},
				9: {
					stepTitle: '10. Click on Next slide',
					body: 'This will bring up the My Prescription banner card.',
					img: imageToggleTelehealthBannerCardGroupsPage10,
					alternateText: 'Click on Next slide'
				},
				10: {
					stepTitle: '11. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleTelehealthBannerCardGroupsPage11,
					alternateText: 'Click on Save Group Config Changes'
				},
				11: {
					stepTitle: '12. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleTelehealthBannerCardGroupsPage12,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleProcedureBannerCardGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Procedure banner card',
		categories: 'appConfig',
		content: {
			description: 'The Procedure banner card can be toggled on and off in the app if needed.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleProcedureBannerCardGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleProcedureBannerCardGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageToggleProcedureBannerCardGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleProcedureBannerCardGroupsPage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the Procedure Card toggle',
					body: 'This will turn on the Procedure banner card on the front page of the CareValet app.',
					img: imageToggleProcedureBannerCardGroupsPage5,
					alternateText: 'Click on the Procedure Card toggle'
				},
				5: {
					stepTitle: '6. Click on Next slide button',
					body: 'This will bring the Procedure banner card into view.',
					img: imageToggleProcedureBannerCardGroupsPage6,
					alternateText: 'Click on Next slide button'
				},
				6: {
					stepTitle: '7. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleProcedureBannerCardGroupsPage7,
					alternateText: 'Click on Save Group Config Changes'
				},
				7: {
					stepTitle: '8. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleProcedureBannerCardGroupsPage8,
					alternateText: 'Click on OK'
				},
				8: {
					stepTitle: '9. Click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleProcedureBannerCardGroupsPage9,
					alternateText: 'Click on Select Banner Cards'
				},
				9: {
					stepTitle: '10. Click on the Procedure Card toggle',
					body: 'This will turn off the Procedure banner card on the front page of the CareValet app.',
					img: imageToggleProcedureBannerCardGroupsPage10,
					alternateText: 'Click on the Procedure Card toggle'
				},
				10: {
					stepTitle: '11. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleProcedureBannerCardGroupsPage11,
					alternateText: 'Click on Save Group Config Changes'
				},
				11: {
					stepTitle: '12. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleProcedureBannerCardGroupsPage12,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleBalanceBillingBannerCardGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Balance Billing banner card',
		categories: 'appConfig',
		content: {
			description: 'The Balance Billing banner card can be toggled on and off in the app if needed.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleBalanceBillingBannerCardGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleBalanceBillingBannerCardGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageToggleBalanceBillingBannerCardGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleBalanceBillingBannerCardGroupsPage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the Balance Billing Card toggle',
					body: 'This will turn on the Balance Billing banner card on the front page of the CareValet app.',
					img: imageToggleBalanceBillingBannerCardGroupsPage5,
					alternateText: 'Click on the Balance Billing Card toggle'
				},
				5: {
					stepTitle: '6. Click on Next slide button',
					body: 'This will bring the Balance Billing banner card into view.',
					img: imageToggleBalanceBillingBannerCardGroupsPage6,
					alternateText: 'Click on Next slide button'
				},
				6: {
					stepTitle: '7. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleBalanceBillingBannerCardGroupsPage7,
					alternateText: 'Click on Save Group Config Changes'
				},
				7: {
					stepTitle: '8. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleBalanceBillingBannerCardGroupsPage8,
					alternateText: 'Click on OK'
				},
				8: {
					stepTitle: '9. Click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleBalanceBillingBannerCardGroupsPage9,
					alternateText: 'Click on Select Banner Cards'
				},
				9: {
					stepTitle: '10. Click on the Balance Billing Card toggle',
					body: 'This will turn off the Balance Billing banner card on the front page of the CareValet app.',
					img: imageToggleBalanceBillingBannerCardGroupsPage10,
					alternateText: 'Click on the Balance Billing Card toggle'
				},
				10: {
					stepTitle: '11. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleBalanceBillingBannerCardGroupsPage11,
					alternateText: 'Click on Save Group Config Changes'
				},
				11: {
					stepTitle: '12. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleBalanceBillingBannerCardGroupsPage12,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleAdditionalServicesBannerCardGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Additional Services banner card',
		categories: 'appConfig',
		content: {
			description: 'The Additional Services banner card can be toggled on and off in the app if needed.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleAdditionalServciesBannerCardGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the External Services Card toggle',
					body: 'This will turn on the Additional Services banner card on the front page of the CareValet app.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage5,
					alternateText: 'Click on the External Services Card toggle'
				},
				5: {
					stepTitle: '6. Click on Next slide button',
					body: 'This will progress through the banner cards.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage6,
					alternateText: 'Click on Next slide button'
				},
				6: {
					stepTitle: '7. Click on Next slide',
					body: 'This will bring the Additional Services banner card into view.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage7,
					alternateText: 'Click on Next slide'
				},
				7: {
					stepTitle: '8. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage8,
					alternateText: 'Click on Save Group Config Changes'
				},
				8: {
					stepTitle: '9. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage9,
					alternateText: 'Click on OK'
				},
				9: {
					stepTitle: '10. Click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage10,
					alternateText: 'Click on Select Banner Cards'
				},
				10: {
					stepTitle: '11. Click on the External Services Card toggle',
					body: 'This will turn off the Additional Services banner card on the front page of the CareValet app.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage11,
					alternateText: 'Click on the External Services Card toggle'
				},
				11: {
					stepTitle: '12. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage12,
					alternateText: 'Click on Save Group Config Changes'
				},
				12: {
					stepTitle: '13. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleAdditionalServciesBannerCardGroupsPage13,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToToggleCustomBannerCardsGroupsPage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to enable and disable the Custom banner cards',
		categories: 'appConfig',
		content: {
			description: 'The Custom banner cards can be toggled on and off in the app if needed.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageToggleCustomBannerCardsGroupsPage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageToggleCustomBannerCardsGroupsPage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageToggleCustomBannerCardsGroupsPage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleCustomBannerCardsGroupsPage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the Enable Custom Cards toggle',
					body: 'This will turn on the Enable Custom Cards drop-down button',
					img: imageToggleCustomBannerCardsGroupsPage5,
					alternateText: 'Click on the Enable Custom Cards toggle'
				},
				5: {
					stepTitle: '6. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleCustomBannerCardsGroupsPage6,
					alternateText: 'Click on Save Group Config Changes'
				},
				6: {
					stepTitle: '7. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning.',
					img: imageToggleCustomBannerCardsGroupsPage7,
					alternateText: 'Click on OK'
				},
				7: {
					stepTitle: '8. Notice the Configure Custom Banner Cards drop down has now appeared',
					body: 'This is where you can create, delete, and edit custom banner cards.',
					img: imageToggleCustomBannerCardsGroupsPage8,
					alternateText: 'Notice the Configure Custom Banner Cards drop down has now appeared'
				},
				8: {
					stepTitle: '9. Click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageToggleCustomBannerCardsGroupsPage9,
					alternateText: 'Click on Select Banner Cards'
				},
				9: {
					stepTitle: '10. Click on the Enable Custom Cards toggle',
					body: 'This will turn off the Configure Custom Banner Cards drop-down button.',
					img: imageToggleCustomBannerCardsGroupsPage10,
					alternateText: 'Click on the Enable Custom Cards toggle'
				},
				10: {
					stepTitle: '11. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageToggleCustomBannerCardsGroupsPage11,
					alternateText: 'Click on Save Group Config Changes'
				},
				11: {
					stepTitle: '12. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageToggleCustomBannerCardsGroupsPage12,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToCreateCustomBannerCardCall',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to create a custom banner card call',
		categories: 'appConfig',
		content: {
			description:
				'Custom banner cards that prompt a user to call a phone number when clicked on. Can be created in the app config menu.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageCreateCustomBannerCardCall1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageCreateCustomBannerCardCall2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageCreateCustomBannerCardCall3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageCreateCustomBannerCardCall4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Make sure that Enable Custom Cards is toggled on',
					body: 'This will cause the Configure Custom Banner Cards button to appear.',
					img: imageCreateCustomBannerCardCall5,
					alternateText: 'Make sure that Enable Custom Cards is toggled on'
				},
				5: {
					stepTitle: '6. Scroll down and click on Configure Custom Banner Cards',
					body: 'This will bring up the Configure Custom Banner Cards options.',
					img: imageCreateCustomBannerCardCall6,
					alternateText: 'Scroll down and click on Configure Custom Banner Cards'
				},
				6: {
					stepTitle: '7. Click on Add New',
					body: 'This will bring up the Configure Custom Banner Cards window.',
					img: imageCreateCustomBannerCardCall7,
					alternateText: 'Click on Add New'
				},
				7: {
					stepTitle: '8. Enter a card title into the Card Title text box',
					body: 'This will be presented to the user as the title of the banner card.',
					img: imageCreateCustomBannerCardCall8,
					alternateText: 'Enter a card title into the Card Title text box'
				},
				8: {
					stepTitle: '9. Enter a card subtitle into the Card Subtitle text box.',
					body: 'This will be presented to the user as the subtitle of the banner card. This will appear underneath the title on the banner card.',
					img: imageCreateCustomBannerCardCall9,
					alternateText: 'Enter a card subtitle into the Card Subtitle text box.'
				},
				9: {
					stepTitle: '10. Click on the Card Icon button',
					body: 'This will bring up a list of available icons to show on the banner card.',
					img: imageCreateCustomBannerCardCall10,
					alternateText: 'Click on the Card Icon button'
				},
				10: {
					stepTitle: '11. Select an icon',
					body: 'This icon will show on the banner card.',
					img: imageCreateCustomBannerCardCall11,
					alternateText: 'Select an icon'
				},
				11: {
					stepTitle: '12. Click on the Card Action button',
					body: 'This will bring up a list of possible actions the custom banner card can perform.',
					img: imageCreateCustomBannerCardCall12,
					alternateText: 'Click on the Card Action button'
				},
				12: {
					stepTitle: '13. Select Call',
					body: 'This card action will prompt the user to call a number when they click on this banner card.',
					img: imageCreateCustomBannerCardCall13,
					alternateText: 'Select Call'
				},
				13: {
					stepTitle: '14. Enter a phone number into the Phone Number text box',
					body: 'This will be the phone number the user is directed to.',
					img: imageCreateCustomBannerCardCall14,
					alternateText: 'Enter a phone number into the Phone Number text box'
				},
				14: {
					stepTitle: '15. Click on CLOSE',
					body: 'This will close the Configure Custom Banner Cards window and create the custom banner card.',
					img: imageCreateCustomBannerCardCall15,
					alternateText: 'Click on CLOSE'
				},
				15: {
					stepTitle: '16. Click on Next slide',
					body: 'This will progress through the banner cards.',
					img: imageCreateCustomBannerCardCall16,
					alternateText: 'Click on Next slide'
				},
				16: {
					stepTitle: '17. Click on Next slide',
					body: 'This will bring the custom banner card into view.',
					img: imageCreateCustomBannerCardCall17,
					alternateText: 'Click on Next slide'
				},
				17: {
					stepTitle: '18. Notice the newly created custom banner card appears here',
					body: '',
					img: imageCreateCustomBannerCardCall18,
					alternateText: 'Notice the newly created custom banner card appears here'
				},
				18: {
					stepTitle: '19. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageCreateCustomBannerCardCall19,
					alternateText: 'Click on Save Group Config Changes'
				},
				19: {
					stepTitle: '20. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageCreateCustomBannerCardCall20,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToCreateCustomBannerCardWebsite',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to create a custom banner card website',
		categories: 'appConfig',
		content: {
			description: 'Custom banner cards that bring a user to a website when clicked on. Can be created in the app config menu.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageCreateCustomBannerCardWebsite1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageCreateCustomBannerCardWebsite2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageCreateCustomBannerCardWebsite3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageCreateCustomBannerCardWebsite4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Make sure that Enable Custom Cards is toggled on',
					body: 'This will cause the Configure Custom Banner Cards button to appear.',
					img: imageCreateCustomBannerCardWebsite5,
					alternateText: 'Make sure that Enable Custom Cards is toggled on'
				},
				5: {
					stepTitle: '6. Scroll down and click on Configure Custom Banner Cards',
					body: 'This will bring up the Configure Custom Banner Cards options.',
					img: imageCreateCustomBannerCardWebsite6,
					alternateText: 'Scroll down and click on Configure Custom Banner Cards'
				},
				6: {
					stepTitle: '7. Click on Add New',
					body: 'This will bring up the Configure Custom Banner Cards window.',
					img: imageCreateCustomBannerCardWebsite7,
					alternateText: 'Click on Add New'
				},
				7: {
					stepTitle: '8. Enter a card title into the Card Title text box',
					body: 'This will be presented to the user as the title of the banner card.',
					img: imageCreateCustomBannerCardWebsite8,
					alternateText: 'Enter a card title into the Card Title text box'
				},
				8: {
					stepTitle: '9. Enter a card subtitle into the Card Subtitle text box.',
					body: 'This will be presented to the user as the subtitle of the banner card. This will appear underneath the title on the banner card.',
					img: imageCreateCustomBannerCardWebsite9,
					alternateText: 'Enter a card subtitle into the Card Subtitle text box.'
				},
				9: {
					stepTitle: '10. Click on the Card Icon button',
					body: 'This will bring up a list of available icons to show on the banner card.',
					img: imageCreateCustomBannerCardWebsite10,
					alternateText: 'Click on the Card Icon button'
				},
				10: {
					stepTitle: '11. Select an icon',
					body: 'This icon will show on the banner card.',
					img: imageCreateCustomBannerCardWebsite11,
					alternateText: 'Select an icon'
				},
				11: {
					stepTitle: '12. Click on the Card Action button',
					body: 'This will bring up a list of possible actions the custom banner card can perform.',
					img: imageCreateCustomBannerCardWebsite12,
					alternateText: 'Click on the Card Action button'
				},
				12: {
					stepTitle: '13. Select Website',
					body: 'This card action will bring the user to a website when they click on this banner card.',
					img: imageCreateCustomBannerCardWebsite13,
					alternateText: 'Select Website'
				},
				13: {
					stepTitle: '14. Enter a website into the Website link text box',
					body: 'This will be the website the user is sent to.',
					img: imageCreateCustomBannerCardWebsite14,
					alternateText: 'Enter a website into the Website link text box'
				},
				14: {
					stepTitle: '15. Click on CLOSE',
					body: 'This will close the Configure Custom Banner Cards window and create the custom banner card.',
					img: imageCreateCustomBannerCardWebsite15,
					alternateText: 'Click on CLOSE'
				},
				15: {
					stepTitle: '16. Click on Next slide',
					body: 'This will progress through the banner cards.',
					img: imageCreateCustomBannerCardWebsite16,
					alternateText: 'Click on Next slide'
				},
				16: {
					stepTitle: '17. Click on Next slide',
					body: 'This will bring the custom banner card into view.',
					img: imageCreateCustomBannerCardWebsite17,
					alternateText: 'Click on Next slide'
				},
				17: {
					stepTitle: '18. Notice the newly created custom banner card appears here',
					body: '',
					img: imageCreateCustomBannerCardWebsite18,
					alternateText: 'Notice the newly created custom banner card appears here'
				},
				18: {
					stepTitle: '19. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageCreateCustomBannerCardWebsite19,
					alternateText: 'Click on Save Group Config Changes'
				},
				19: {
					stepTitle: '20. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageCreateCustomBannerCardWebsite20,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToCreateCustomBannerCardMessage',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to create a custom banner card message',
		categories: 'appConfig',
		content: {
			description:
				'Custom banner cards that opens a message window for the user when clicked on. Can be created in the app config menu.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageCreateCustomBannerCardMessage1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageCreateCustomBannerCardMessage2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageCreateCustomBannerCardMessage3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Scroll down and click on Select Banner Cards',
					body: 'This will bring up the Banner Cards toggle options.',
					img: imageCreateCustomBannerCardMessage4,
					alternateText: 'Scroll down and click on Select Banner Cards'
				},
				4: {
					stepTitle: '5. Make sure that Enable Custom Cards is toggled on',
					body: 'This will cause the Configure Custom Banner Cards button to appear.',
					img: imageCreateCustomBannerCardMessage5,
					alternateText: 'Make sure that Enable Custom Cards is toggled on'
				},
				5: {
					stepTitle: '6. Scroll down and click on Configure Custom Banner Cards',
					body: 'This will bring up the Configure Custom Banner Cards options.',
					img: imageCreateCustomBannerCardMessage6,
					alternateText: 'Scroll down and click on Configure Custom Banner Cards'
				},
				6: {
					stepTitle: '7. Click on Add New',
					body: 'This will bring up the Configure Custom Banner Cards window.',
					img: imageCreateCustomBannerCardMessage7,
					alternateText: 'Click on Add New'
				},
				7: {
					stepTitle: '8. Enter a card title into the Card Title text box',
					body: 'This will be presented to the user as the title of the banner card.',
					img: imageCreateCustomBannerCardMessage8,
					alternateText: 'Enter a card title into the Card Title text box'
				},
				8: {
					stepTitle: '9. Click on the Card Icon button',
					body: 'This will bring up a list of available icons to show on the banner card.',
					img: imageCreateCustomBannerCardMessage9,
					alternateText: 'Click on the Card Icon button'
				},
				9: {
					stepTitle: '10. Select an icon',
					body: 'This icon will show on the banner card.',
					img: imageCreateCustomBannerCardMessage10,
					alternateText: 'Select an icon'
				},
				10: {
					stepTitle: '11. Click on the Card Action button',
					body: 'This card action will prompt the user to call a number when they click on this banner card.',
					img: imageCreateCustomBannerCardMessage11,
					alternateText: 'Click on the Card Action button'
				},
				11: {
					stepTitle: '12. Select Message',
					body: 'This card action will open a message window when the user clicks on this banner card.',
					img: imageCreateCustomBannerCardMessage12,
					alternateText: 'Select Message'
				},
				12: {
					stepTitle: '13. Enter a message title into the Message Title text box',
					body: 'This will be displayed at the top of the message window.',
					img: imageCreateCustomBannerCardMessage13,
					alternateText: 'Enter a message title into the Message Title text box'
				},
				13: {
					stepTitle: '14. Enter message text into the Message Text text box',
					body: 'This text will be displayed within the message window.',
					img: imageCreateCustomBannerCardMessage14,
					alternateText: 'Enter message text into the Message Text text box'
				},
				14: {
					stepTitle: '15. Click on the Message Icon button',
					body: 'This will bring up a list of available icons to show on the message window.',
					img: imageCreateCustomBannerCardMessage15,
					alternateText: 'Click on the Message Icon button'
				},
				15: {
					stepTitle: '16. Select an icon',
					body: 'This icon will show on the banner card.',
					img: imageCreateCustomBannerCardMessage16,
					alternateText: 'Select an icon'
				},
				16: {
					stepTitle: '17. Click on CLOSE',
					body: 'This will close the Configure Custom Banner Cards window and create the custom banner card.',
					img: imageCreateCustomBannerCardMessage17,
					alternateText: 'Click on CLOSE'
				},
				17: {
					stepTitle: '18. Click on Next slide',
					body: 'This will progress through the banner cards.',
					img: imageCreateCustomBannerCardMessage18,
					alternateText: 'Click on Next slide'
				},
				18: {
					stepTitle: '19. Click on Next slide',
					body: 'This will bring the custom banner card into view.',
					img: imageCreateCustomBannerCardMessage19,
					alternateText: 'Click on Next slide'
				},
				19: {
					stepTitle: '20. Notice the newly created custom banner card appears here',
					body: '',
					img: imageCreateCustomBannerCardMessage20,
					alternateText: 'Notice the newly created custom banner card appears here'
				},
				20: {
					stepTitle: '21. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageCreateCustomBannerCardMessage21,
					alternateText: 'Click on Save Group Config Changes'
				},
				21: {
					stepTitle: '22. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageCreateCustomBannerCardMessage22,
					alternateText: 'Click on OK'
				}
			}
		}
	},
	{
		caseName: 'howToDeleteCustomBannerCard',
		iconDescription: 'fad fa-mobile',
		tutorialTitle: 'How to delete a custom banner card',
		categories: 'appConfig',
		content: {
			description: 'Custom banner cards can be deleted from the app config menu.',
			steps: {
				0: {
					stepTitle: '1. Click on Groups',
					body: 'This will take you to the Groups page.',
					img: imageDeleteCustomBannerCard1,
					alternateText: 'Click on Groups'
				},
				1: {
					stepTitle: '2. Select a Group',
					body: "This will take you to that group's page.",
					img: imageDeleteCustomBannerCard2,
					alternateText: 'Select a Group'
				},
				2: {
					stepTitle: '3. Select the App Config tab',
					body: 'This will bring up Manage Group Configuration.',
					img: imageDeleteCustomBannerCard3,
					alternateText: 'Select the App Config tab'
				},
				3: {
					stepTitle: '4. Click on Configure Custom Banner Cards',
					body: 'This will bring up the Configure Custom Banner Cards options.',
					img: imageDeleteCustomBannerCard4,
					alternateText: 'Click on Configure Custom Banner Cards'
				},
				4: {
					stepTitle: '5. Click on the X button next to the custom banner card that you would like to delete',
					body: 'This will bring up a delete item prompt.',
					img: imageDeleteCustomBannerCard5,
					alternateText: 'Click on the X button next to the custom banner card that you would like to delete'
				},
				5: {
					stepTitle: '6. Click on OK',
					body: 'This will remove the custom banner card.',
					img: imageDeleteCustomBannerCard6,
					alternateText: 'Click on OK'
				},
				6: {
					stepTitle: '7. Click on Save Group Config Changes',
					body: 'This will bring up a confirmation prompt.',
					img: imageDeleteCustomBannerCard7,
					alternateText: 'Click on Save Group Config Changes'
				},
				7: {
					stepTitle: '8. Click on OK',
					body: 'Clicking on OK will save the changes and the changes will be imported the next morning. These changes will affect users within this group.',
					img: imageDeleteCustomBannerCard8,
					alternateText: 'Click on OK'
				}
			}
		}
	}
];
