import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import './card-carousel.scss';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import CreditCard from '@/components/credit-card/CreditCard';
import { Swiper, SwiperSlide } from 'framework7-react';
import appStore from '@/stores/AppStore';

@observer
export default class CardCarousel extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
		this.data = observable({
			methodsLoaded: false
		});
	}

	//Unfortunately this seems to be the only way we can retrieve the index of the active
	componentDidMount() {
		this.$f7.swiper.get('.credit-card-swiper').on('activeIndexChange', (e) => {
			this.slideChange(this.$f7.swiper.get('.credit-card-swiper').activeIndex);
		});
	}

	slideChange(index) {
		if (this.props.onCardSelect) {
			this.props.onCardSelect(index);
		}
	}
	render() {
		let { paymentMethods, onDelete } = this.props;
		return (
			<div>
				<Swiper
					className={`credit-card-swiper ${appStore.isMobile ? 'mobile' : 'desktop'}`}
					pagination
					navigation
					params={{
						slidesPerView: 'auto',
						spaceBetween: 30,
						initialSlide: 0,
						grabCursor: true,
						centeredSlides: true
					}}
				>
					{paymentMethods.map((method, key) => {
						let expDay = _.padStart(_.get(method, 'card.exp_month', ''), 2, '0');
						let expYear = _.get(method, 'card.exp_year', '');
						let expDate = `${expDay}/${expYear}`;

						return (
							<SwiperSlide key={key}>
								<CreditCard
									key={key}
									name={_.get(method, 'billing_details.name', '')}
									expDate={expDate}
									onDelete={onDelete ? () => onDelete(method.id) : false}
									brand={method.card.brand}
									lastFour={method.card.last4}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		);
	}
}
