import ExpressAPI from './ExpressAPI';

class DocumentService {
	async uploadDocument(newDocumentData) {
		let formData = new FormData();
		let documentData = newDocumentData.document.data;

		if (newDocumentData.document.isImage) {
			documentData = await this.getImageBlob(documentData);
		}

		const body = {
			name: newDocumentData.documentName,
			type: newDocumentData.documentType
		};

		formData.append('file', documentData, newDocumentData.document.name);
		formData.append('document', JSON.stringify(body));

		return await ExpressAPI.uploadDoucment(formData);
	}

	async getImageBlob(src) {
		return await fetch(src).then((r) => r.blob());
	}
}

export default new DocumentService();
