import _ from 'lodash';
import adminStore from '../stores/AdminStore';
class AdminService {
	getMessagingOptions(recipients) {
		if (!recipients) {
			recipients = adminStore.pendingRecipients || [];
		}
		const recipientIds = recipients.map((recipient) => recipient.memberUid);
		const pendingMessages = adminStore.pendingMessages.filter((message) => recipientIds.indexOf(message.memberUid) > -1);
		const textAvailable = recipients.filter((recipient) => !!recipient.phone).length;
		const textPending = pendingMessages.filter((message) => message.type === 'TEXT').length;
		const emailAvailable = recipients.filter((recipient) => !!recipient.email).length;
		const emailPending = pendingMessages.filter((message) => message.type === 'EMAIL').length;
		const pushAvailable = recipients.filter((recipient) => !!recipient.devices && recipient.devices.length > 0).length;
		const pushPending = pendingMessages.filter((message) => message.type === 'PUSH').length;

		const optionData = [
			{
				type: 'TEXT',
				disabled: !textAvailable || textAvailable === 0,
				qtyAvailable: textAvailable,
				qtySelected: textPending,
				key: 'phone',
				icon: 'fal fa-sms',
				pending: textAvailable > 0 && textPending === textAvailable
			},
			{
				type: 'EMAIL',
				disabled: !emailAvailable || emailAvailable === 0,
				qtyAvailable: emailAvailable,
				qtySelected: emailPending,
				key: 'email',
				icon: 'fal fa-envelope',
				pending: emailAvailable > 0 && emailPending === emailAvailable
			},
			{
				type: 'PUSH',
				disabled: !pushAvailable || pushAvailable === 0,
				qtyAvailable: pushAvailable,
				qtySelected: pushPending,
				key: 'devices',
				icon: 'fal fa-mobile',
				pending: pushAvailable > 0 && pushPending === pushAvailable
			}
		];
		return optionData;
	}
	getMessagingRecipientsData() {
		return adminStore.pendingRecipients || [];
	}
	getMessagingRecipientStatus(recipient) {
		const options = this.getMessagingOptions();
		const pendingMessages = this.getMessagesPending();
		const optionsWithData = options.map((option) => {
			const pending = pendingMessages.filter((message) => {
				return message.type === option.type && message.memberUid === recipient.memberUid;
			});
			const recipientData = recipient[option.key];
			let qtyAvailable = !!recipientData ? 1 : 0;
			let qtySelected = pending ? pending.length : 0;
			let disabled = !recipientData;
			if (option.type === 'PUSH') {
				qtyAvailable = recipientData && recipientData.length > 0 ? 1 : 0;
				disabled = !recipientData || recipientData.length < 1;
			}
			const optionData = {
				...option,
				qtyAvailable: qtyAvailable,
				qtySelected: qtySelected,
				disabled: disabled,
				pending: qtyAvailable > 0 && qtySelected === qtyAvailable
			};
			return optionData;
		});
		return optionsWithData;
	}
	getMessagingLanguages() {
		const pendingRecipients = adminStore.pendingRecipients;
		const pendingMessages = adminStore.pendingMessages || [];
		const messageLanguages = pendingMessages.map((message) => {
			const uid = message.memberUid;
			const recipientData = _.find(pendingRecipients, { memberUid: uid });
			return recipientData.languageDisplay;
		});
		const uniqueLanguages = new Set(messageLanguages);
		const languageArray = Array.from(uniqueLanguages);
		return languageArray;
	}
	getMessagesPending() {
		return _.cloneDeep(adminStore.pendingMessages);
	}
	messageAddPending(memberUid, type, recipientData) {
		const currentPending = _.cloneDeep(adminStore.pendingMessages);
		const alreadyPending = _.find(currentPending, { memberUid: memberUid, type: type });
		const currentRecipients = _.cloneDeep(adminStore.pendingRecipients) || [recipientData];
		const thisRecipient = _.find(currentRecipients, { memberUid: memberUid }) || recipientData;
		const recipientOptions = this.getMessagingRecipientStatus(thisRecipient);
		const optionData = _.find(recipientOptions, { type: type });
		if (!alreadyPending && !optionData.disabled) {
			currentPending.push({ memberUid, type });
		}
		adminStore.pendingMessages = currentPending;
		if (recipientData) {
			const recipients = _.cloneDeep(adminStore.pendingRecipients) || [];
			const recipientPending = _.find(recipients, { memberUid, memberUid });
			if (!recipientPending) {
				recipients.push(recipientData);
				adminStore.pendingRecipients = recipients;
			}
		}
	}
	messageRemovePending(memberUid, type, removeRecipient) {
		const currentPending = _.cloneDeep(adminStore.pendingMessages);
		const updatedPending = currentPending.filter((message) => {
			const memberMatch = message.memberUid === memberUid;
			const typeMatch = message.type === type;
			if (memberMatch) {
				if (type === 'all') {
					return false;
				}
				return !typeMatch;
			}
			return true;
		});
		adminStore.pendingMessages = updatedPending;
		if (removeRecipient) {
			const messagesForRecipient = adminStore.pendingMessages.filter((message) => message.memberUid === memberUid);
			if (messagesForRecipient.length < 1) {
				const pendingRecipients = _.cloneDeep(adminStore.pendingRecipients);
				const updatedRecipients = pendingRecipients.filter((recipient) => recipient.memberUid !== memberUid);
				adminStore.pendingRecipients = updatedRecipients;
			}
		}
	}
	messagesRemoveAllPending() {
		adminStore.pendingMessages = [];
		adminStore.pendingRecipients = [];
	}
	messagesBulkRemoveType(recipients, type, removeRecipient) {
		for (let recipient of recipients) {
			this.messageRemovePending(recipient.memberUid, type, removeRecipient);
		}
	}
	messagesBulkAddType(recipients, type) {
		for (let recipient of recipients) {
			this.messageAddPending(recipient.memberUid, type, recipient);
		}
	}
}

export default new AdminService();
