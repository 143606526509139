import { observable } from '../utils/State';
import _ from 'lodash';
const expressStore = observable({
	isLoggedIn: false,
	activated: true,
	activePage: 'home',
	isCreatingUser: false,
	authToken: null,
	userData: { user: {}, roles: [] },
	myOrganizations: [{ _id: '123', name: 'Unknown Org 1' }],
	currentOrg: { _id: '123', name: 'Unknown Org 1' },
	activeGroup: null,
	activePlan: null,
	activeEditor: null,
	activeCard: null,
	activeMember: null,
	activeTour: null,
	pendingConfigs: null,
	roleEnums: {
		SYSTEM_ADMIN: 'systems-admin',
		ORG_ADMIN: 'organization-admin',
		GROUP_ADMIN: 'group-admin',
		GROUP_USER: 'group-user'
	},
	currentOrgRole: () => {
		let { userData, currentOrg } = expressStore;
		return _.find(userData.roles, { orgId: currentOrg._id });
	},
	hasAnAdminRole: () => {
		let { userData, currentOrg } = expressStore;
		let orgRole = _.find(userData.roles, { orgId: currentOrg._id });
		if (orgRole) {
			return orgRole.role === 'group-admin' || orgRole.role === 'organization-admin' || false;
		}
		return expressStore.isSystemAdmin();
	},
	isOrgOwner: () => {
		let { userData, currentOrg } = expressStore;
		return userData.user._id === currentOrg.owner;
	},
	isSystemAdmin: () => {
		let { userData } = expressStore;
		let isSysAdmin = false;
		_.forEach(userData.roles, (r) => {
			if (r.role === expressStore.roleEnums.SYSTEM_ADMIN) {
				isSysAdmin = true;
			}
		});
		return isSysAdmin;
	}
});
export default expressStore;
