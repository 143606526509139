// @ts-nocheck
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import Spreadsheet from 'react-spreadsheet';
import './invalid-records.scss';

const HeaderMap = {
	relation: 'Relation',
	memberId: 'Member Id',
	personCode: 'Person Code',
	groupId: 'Group Policy Number',
	healthPlan: 'Health Plan Id',
	healthPlanVision: 'Health Plan Vision Id',
	healthPlanDental: 'Health Plan Dental Id',
	insuredFirstName: 'Insured First Name',
	insuredMiddleName: 'Insured Middle Name',
	insuredLastName: 'Insured Last Name',
	firstName: 'Member First Name',
	middleName: 'Member Middle Name',
	lastName: 'Member Last Name',
	// ssn: 'SSN', //we do not want to show this in the UI
	email: 'Email',
	phone: 'Mobile Phone',
	street: 'Address 1',
	street2: 'Address 2',
	city: 'City',
	state: 'State',
	zipcode: 'Zip Code',
	gender: 'Gender',
	dob: 'Birth Date',
	coverageLevel: 'Coverage Level',
	coverageLevelDental: 'Coverage Level Dental',
	coverageLevelVision: 'Coverage Level Vision',
	startDate: 'Start Date',
	termDate: 'Term Date'
};

@observer
export default class InvalidRecords extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const rows = this.props.records.map((rec) => {
			let { obj, invalidFields } = _.cloneDeep(rec);
			let row = [];
			_.forEach(Object.keys(HeaderMap), (key) => {
				const isValid = invalidFields.indexOf(key) < 0;
				row.push({
					value: _.isEmpty(obj[key]) ? 'Missing' : obj[key],
					readOnly: true,
					DataViewer: () => {
						return (
							<div className={isValid ? 'valid-cell' : 'invalid-cell'}>
								{_.isEmpty(obj[key]) ? (isValid ? '' : 'Missing') : obj[key]}
							</div>
						);
					}
				});
			});
			return row;
		});
		return (
			<div className="main-content">
				<div className="explainer center">
					The following records have invalid or missing data, any field colored red is invalid. Hint: (Shift + mouse wheel scrolls
					horizontally). Invalid records cannot be imported.
				</div>
				<div className="invalid-table">
					{this.props.records && <Spreadsheet columnLabels={Object.values(HeaderMap)} data={rows} />}
				</div>
				<div className="explainer center">Please correct the invalid fields and upload a new file</div>
				{this.props.backText && this.props.onBack && (
					<Button large className="noshadow round-btn ios" onClick={this.props.onBack}>
						{this.props.backText}
					</Button>
				)}
			</div>
		);
	}
}
