// @ts-nocheck
import CloudAPI from './CloudAPI';
import appStore from '../stores/AppStore';

export default class NotificationService {
	init() {
		let FirebasePlugin = window.FirebasePlugin;
		if (!FirebasePlugin || !cordova || appStore.registeredPushListeners) {
			return;
		}
		this.log('Init Firebase Cloud Messaging...');

		try {
			//Register handlers
			FirebasePlugin.onMessageReceived(
				(message) => {
					try {
						console.dir(message);
						if (message.messageType === 'notification') {
							this.handleNotificationMessage(message);
						} else {
							this.handleDataMessage(message);
						}
					} catch (e) {
						this.logError('Exception in onMessageReceived callback: ' + e.message);
					}
				},
				(error) => {
					this.logError('Failed receiving FirebasePlugin message', error);
				}
			);

			FirebasePlugin.onTokenRefresh(
				(token) => {
					this.log('Token refreshed: ' + token);
					this.updateUserDevices(token);
				},
				(error) => {
					this.logError('Failed to refresh token', error);
				}
			);

			this.checkNotificationPermission(false); // Check permission then get token

			this.checkAutoInit();

			// Platform-specific

			if (cordova.platformId === 'android') {
				this.initAndroid();
			} else if (cordova.platformId === 'ios') {
				this.initIos();
			}
			appStore.registeredPushListeners = true;
		} catch (err) {
			console.log('Error durning setup of FCM');
		}
	}

	initIos() {
		FirebasePlugin.onApnsTokenReceived(
			(token) => {
				this.log('APNS token received: ' + token);
				this.updateUserDevices(token);
			},
			(error) => {
				this.logError('Failed to receive APNS token', error);
			}
		);
	}

	initAndroid() {
		// Custom FCM receiver plugin
		cordova.plugin.customfcmreceiver.registerReceiver((message) => {
			this.log('Received custom message: ' + message);
		});

		// Define custom  channel - all keys are except 'id' are optional.
		var customChannel = {
			// channel ID - must be unique per app package
			id: 'my_channel_id'

			//   // Channel name. Default: empty string
			//   name: 'My channel name',

			//   //The sound to play once a push comes. Default value: 'default'
			//   //Values allowed:
			//   //'default' - plays the default notification sound
			//   //'ringtone' - plays the currently set ringtone
			//   //filename - the filename of the sound file located in '/res/raw' without file extension (mysound.mp3 -> mysound)
			//   sound: 'blackberry',

			//   //Vibrate on new notification. Default value: true
			//   //Possible values:
			//   //Boolean - vibrate or not
			//   //Array - vibration pattern - e.g. [500, 200, 500] - milliseconds vibrate, milliseconds pause, vibrate, pause, etc.
			//   vibration: [300, 200, 300],

			//   // Whether to blink the LED
			//   light: true,

			//   //LED color in ARGB format - this example BLUE color. If set to -1, light color will be default. Default value: -1.
			//   lightColor: '0xFF0000FF',

			//   //Importance - integer from 0 to 4. Default value: 3
			//   //0 - none - no sound, does not show in the shade
			//   //1 - min - no sound, only shows in the shade, below the fold
			//   //2 - low - no sound, shows in the shade, and potentially in the status bar
			//   //3 - default - shows everywhere, makes noise, but does not visually intrude
			//   //4 - high - shows everywhere, makes noise and peeks
			//   importance: 4,

			//   //Show badge over app icon when non handled pushes are present. Default value: true
			//   badge: true,

			//   //Show message on locked screen. Default value: 1
			//   //Possible values (default 1):
			//   //-1 - secret - Do not reveal any part of the notification on a secure lockscreen.
			//   //0 - private - Show the notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
			//   //1 - public - Show the notification in its entirety on all lockscreens.
			//   visibility: 1
		};

		FirebasePlugin.createChannel(
			customChannel,
			() => {
				this.log('Created custom channel: ' + customChannel.id);
				FirebasePlugin.listChannels(
					(channels) => {
						if (typeof channels == 'undefined') return;
						for (var i = 0; i < channels.length; i++) {
							this.log('Channel id=' + channels[i].id + '; name=' + channels[i].name);
						}
					},
					(error) => {
						this.logError('List channels error: ' + error);
					}
				);
			},
			(error) => {
				this.logError('Create channel error', error);
			}
		);
	}

	// Notifications
	checkNotificationPermission(requested) {
		FirebasePlugin.hasPermission((hasPermission) => {
			if (hasPermission) {
				this.log('Remote notifications permission granted');
				// Granted
				this.getToken();
			} else if (!requested) {
				// Request permission
				this.log('Requesting remote notifications permission');
				FirebasePlugin.grantPermission(this.checkNotificationPermission(true));
			} else {
				// Denied
				this.logError("Notifications won't be shown as permission is denied");
			}
		});
	}

	checkAutoInit() {
		FirebasePlugin.isAutoInitEnabled(
			(enabled) => {
				this.log('Auto init is ' + (enabled ? 'enabled' : 'disabled'));
			},
			(error) => {
				this.logError('Failed to check auto init', error);
			}
		);
	}

	enableAutoInit() {
		FirebasePlugin.setAutoInitEnabled(
			true,
			() => {
				this.log('Enabled auto init');
				this.checkAutoInit();
			},
			(error) => {
				this.logError('Failed to enable auto init', error);
			}
		);
	}

	disableAutoInit() {
		FirebasePlugin.setAutoInitEnabled(
			false,
			() => {
				this.log('Disabled auto init');
				this.checkAutoInit();
			},
			(error) => {
				this.logError('Failed to disable auto init', error);
			}
		);
	}

	getID() {
		FirebasePlugin.getId(
			(id) => {
				this.log('Got FCM ID: ' + id);
			},
			(error) => {
				this.logError('Failed to get FCM ID', error);
			}
		);
	}

	getToken() {
		FirebasePlugin.getToken(
			(token) => {
				this.log('Got FCM token: ' + token);
				this.updateUserDevices(token);
			},
			(error) => {
				this.logError('Failed to get FCM token', error);
			}
		);
	}

	getAPNSToken() {
		FirebasePlugin.getAPNSToken(
			(token) => {
				this.log('Got APNS token: ' + token);
				this.updateUserDevices(token);
			},
			(error) => {
				this.logError('Failed to get APNS token', error);
			}
		);
	}

	updateUserDevices(token) {
		try {
			CloudAPI.addDevice(token);
		} catch (err) {
			this.logError('Failed to get update user devices', err);
		}
	}

	handleNotificationMessage(message) {
		var title;
		if (message.title) {
			title = message.title;
		} else if (message.notification && message.notification.title) {
			title = message.notification.title;
		} else if (message.aps && message.aps.alert && message.aps.alert.title) {
			title = message.aps.alert.title;
		}

		var body;
		if (message.body) {
			body = message.body;
		} else if (message.notification && message.notification.body) {
			body = message.notification.body;
		} else if (message.aps && message.aps.alert && message.aps.alert.body) {
			body = message.aps.alert.body;
		}

		var msg = 'Notification message received';
		if (message.tap) {
			msg += ' (tapped in ' + message.tap + ')';
		}
		if (title) {
			msg += '; title=' + title;
		}
		if (body) {
			msg += '; body=' + body;
		}
		msg += ': ' + JSON.stringify(message);
		this.log(msg);
		try {
			f7.notification
				.create({
					icon: '<i class="icon-logo" style="color: #4486ff; font-size: 16px;"></i>',
					title: title,
					titleRightText: 'CareValet',
					text: body,
					closeButton: true
				})
				.open();
		} catch {}
	}

	handleDataMessage(message) {
		this.log('Data message received: ' + JSON.stringify(message));
		try {
			f7.notification
				.create({
					icon: '<i class="icon-logo" style="color: #4486ff;"></i>',
					title: 'CareValet',
					titleRightText: message.notification.title,
					text: message.notification.body,
					closeTimeout: 3000
				})
				.open();
		} catch {}
	}

	clearNotifications() {
		FirebasePlugin.clearAllNotifications(
			() => {
				this.log('Cleared all notifications');
			},
			(error) => {
				this.logError('Failed to clear notifications', error);
			}
		);
	}

	subscribe(tpoic) {
		FirebasePlugin.subscribe(
			topic || 'my_topic',
			() => {
				this.log('Subscribed to topic');
			},
			(error) => {
				this.logError('Failed to subscribe to topic', error);
			}
		);
	}

	unsubscribe(topic) {
		FirebasePlugin.unsubscribe(
			topic || 'my_topic',
			() => {
				this.log('Unsubscribed from topic');
			},
			(error) => {
				this.logError('Failed to unsubscribe from topic', error);
			}
		);
	}

	getBadgeNumber() {
		FirebasePlugin.getBadgeNumber(
			(number) => {
				this.log('Current badge number: ' + number);
			},
			(error) => {
				this.logError('Failed to get badge number', error);
			}
		);
	}

	incrementBadgeNumber() {
		FirebasePlugin.getBadgeNumber(
			(current) => {
				var number = current + 1;
				FirebasePlugin.setBadgeNumber(
					number,
					() => {
						this.log('Set badge number to: ' + number);
					},
					(error) => {
						this.logError('Failed to set badge number', error);
					}
				);
			},
			(error) => {
				this.logError('Failed to get badge number', error);
			}
		);
	}

	clearBadgeNumber() {
		FirebasePlugin.setBadgeNumber(
			0,
			() => {
				this.log('Cleared badge number');
			},
			(error) => {
				this.logError('Failed to clear badge number', error);
			}
		);
	}

	unregister() {
		FirebasePlugin.unregister(
			() => {
				this.log('Unregistered from Firebase');
			},
			(error) => {
				this.logError('Failed to unregister from Firebase', error);
			}
		);
	}

	log(msg, opts) {
		opts = opts || {};

		opts.logLevel = opts.logLevel || 'log';
		console[opts.logLevel](msg);

		opts.msg = msg;
	}

	logError(msg, error) {
		if (typeof error === 'object') {
			msg += ': ' + JSON.stringify(error);
		} else if (typeof error === 'string') {
			msg += ': ' + error;
		}
		this.log(msg, {
			logLevel: 'error'
		});
	}
}
