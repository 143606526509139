import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import { Card, Preloader } from 'framework7-react';
import './id-thumbnail.scss';
import { formatDistance } from 'date-fns';
import expressStore from '@/stores/ExpressStore';
@observer
export default class IdThumbnail extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			url: null,
			active: false
		});
		this.image = React.createRef();
		autoBind(this);
	}

	async getImage() {
		const { id, thumbnailKey } = this.props;
		if (thumbnailKey) {
			ExpressAPI.getCanvasAsset(thumbnailKey).then((thumb) => {
				this.data.url = thumb;
			});
		} else {
			ExpressAPI.getIdThumbnail(id).then((thumb) => {
				this.data.url = thumb;
			});
		}
	}
	componentDidMount() {
		this.getImage();
	}

	renderImage() {
		const { url } = this.data;
		if (!url) {
			return (
				<div className="image vbox vcenter hcenter">
					<Preloader color="blue" size="26"></Preloader>
				</div>
			);
		} else {
			return (
				<div className="image">
					<img src={url} alt="" />
				</div>
			);
		}
	}

	render() {
		const { name, lastUpdated, side, defaultCard } = this.props;
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		return (
			<div
				className="id-thumbnail vbox"
				onMouseOver={() => (this.data.active = true)}
				onMouseOut={() => (this.data.active = false)}
				onClick={(e) => {
					if (e.target?.classList?.contains('bypass')) {
						return;
					}
					this.props.onEdit();
				}}
			>
				<div className={`image-container ${this.data.active ? 'active' : ''}`}>
					<div className="actions vcenter hcenter hbox">
						{canEdit && (
							<a className="btn" onClick={this.props.onEdit}>
								<i className="fa fa-pencil"></i>
							</a>
						)}
						{!canEdit && (
							<a className="btn" onClick={this.props.onEdit}>
								<i className="fa fa-eye"></i>
							</a>
						)}
						{canEdit && !defaultCard && (
							<a className="btn delete" onClick={this.props.onDelete}>
								<i className="fa fa-trash bypass"></i>
							</a>
						)}
					</div>
					{this.renderImage()}
				</div>
				<div className="vbox details vcenter">
					<div className="hbox name-container vcenter">
						<div className="vbox grow-1">
							<div className="name">{name.split('::')[0]}</div>
							<div className="side">{side}</div>
							{expressStore.isSystemAdmin() && defaultCard ? <div>default</div> : ''}
						</div>
					</div>
					<div className="last-updated">modified {formatDistance(new Date(lastUpdated), new Date(), { addSuffix: true })}</div>
				</div>
			</div>
		);
	}
}
