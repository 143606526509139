import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './canvas-template-list.scss';
import { Preloader } from 'framework7-react';
import { format } from 'date-fns';
@observer
export default class CanvasTemplateList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const { templatesLoading, templates, onEdit, onDelete, onPreview } = this.props;
		return (
			<div className="canvas-template-list">
				<DocumentListHeader />
				{templatesLoading && (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
				{templates.map((template, key) => {
					return (
						<IdListItem
							onClick={() => onEdit(template)}
							name={template.name}
							type={template.type}
							addedBy={template.createdBy}
							date={template.lastUpdated}
							fileData={template.fileData}
							documentId={template._id}
							onDelete={() => onDelete(template)}
						/>
					);
				})}
			</div>
		);
	}
}

const DocumentListHeader = (props) => {
	return (
		<div className="table-list-header document-list-header hbox vcenter">
			<div className="field grow-1">Name</div>
			<div className="field grow-1">Type</div>
			<div className="field grow-1">Added by</div>
			<div className="field grow-1">Last modified</div>
			<div className="field"></div>
		</div>
	);
};

const IdListItem = (props) => {
	const { documentId, name, side, addedBy, date, fileData, onClick, onDelete, onPreview } = props;
	const dateFormatted = date ? format(new Date(date), 'MM-dd-yy hh:mm a') : '';
	return (
		<div
			onClick={(e) => {
				if (e.target?.classList?.contains('action')) {
					return;
				}
				onClick();
			}}
			className="table-list-item members-list-item hbox vcenter"
			key={`${documentId}`}
		>
			<div className="field grow-1">{name}</div>
			<div className="field grow-1">{side}</div>
			<div className="field grow-1">{addedBy}</div>
			<div className="field grow-1">{dateFormatted} </div>
			<div className="field">
				<a className="btn action trash" onClick={onDelete}>
					<i className="fad fa-trash action"></i>
				</a>
			</div>
		</div>
	);
};
