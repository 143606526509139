import React, { Component } from 'react';
import { List, ListInput } from 'framework7-react';

export default class DefaultEmailTemplate extends Component {
	render() {
		return (
			<List>
				<ListInput
					outline
					label={this.props.subjectLabel}
					type="text"
					name="subject"
					placeholder={this.props.subjectPlaceholder}
					onChange={this.props.onSubjectChange}
					inputId="subject"
					noStoreData
					value={this.props.subject}
					className="md"
					floatingLabel={true}
				/>
				<ListInput
					outline
					label={this.props.bodyLabel}
					type="texteditor"
					name="emailBody"
					onChange={this.props.onBodyChange}
					inputId="emailBody"
					noStoreData
					className="md"
					floatingLabel={true}
					value={this.props.body}
					textEditorParams={{
						buttons: [],
						mode: 'popover'
					}}
				/>
			</List>
		);
	}
}
