import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { Popup, Link, Chip, Toggle, Button } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { format } from 'date-fns';
import screenerSVG from '@/assets/screener.svg';
import vaccineSVG from '@/assets/vaccine.svg';
import appStore from '../../stores/AppStore';
import './test-finder.scss';
import CloudAPI from '@/services/CloudAPI';
import UserService from '@/services/UserService';
import RangeSlider from '../range-slider/RangeSlider';
import ExternalService from '@/services/ExternalService';
import ProviderService from '@/services/ProviderService';
import SiteMap from './SiteMap';
import noresultsSVG from '@/assets/noresults.svg';
import AnalyticsService from '@/services/AnalyticsService';
import ConfigService from '@/services/ConfigService';
const analytics = new AnalyticsService();
@observer
export default class TestFinder extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			page: 'resultsview',
			resultsView: 'resultsview',
			lastView: 'resultsview',
			showFilter: false,
			filterAnimation: 'slideInUp',
			activeLocation: null,
			filters: {
				searchRadius: 25,
				appointmentOnly: false,
				sameDay: false,
				moleculer: true,
				callFirst: false,
				referralRequired: false,
				driveThrough: false,
				antibody: true,
				antigen: true,
				allowUnknowns: true
			},
			results: []
		});
		this.text = getCmpText('TestFinder', {
			title: 'Test Site Finder',
			subtitle: 'Last Updated: ',
			vaccineTitle: 'Vaccine Finder Tool',
			vaccineMessage: 'Helps you find clinics, pharmacies, and other locations that offer COVID-19 vaccines in the United States.',
			screenerTitle: 'COVID-19 Screening Tool',
			screenerMessage:
				'Some locations require that individuals meet specific criteria in order to qualify for testing. Tap here to open our assessment and determine whether you meet testing criteria.',
			listHeader: 'Nearby Sites',
			filterButton: 'Apply',
			call: 'Call',
			nav: 'Navigate',
			website: 'Website',
			details: 'Details',
			hours: 'Hours',
			emptyMsg: 'We were unable to find any test sites, please change your search criteria and try again.',
			unknownDateModified: 'Unknown',
			lastUpdatedLabel: 'Last Updated'
		});
		autoBind(this);
	}

	componentDidMount() {
		const config = ConfigService.getConfigValue('testFinder');
		if (config && config.filterDefaults) {
			const currentFilters = _.cloneDeep(this.data.filters);
			this.data.filters = _.merge(currentFilters, _.cloneDeep(config.filterDefaults));
		}
	}

	handleOnClose() {
		if (this.props.onCloseFinder) {
			this.props.onCloseFinder();
		}
	}

	doSearch() {
		console.log('Fetching test locations');
		analytics.trackWithData('covid_test_finder_search', this.data.filters);
		CloudAPI.searchForSites(this.data.filters)
			.then((res) => {
				this.data.results = res;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				console.log(err);
			});
	}

	async onPopupOpen() {
		analytics.track('covid_test_finder_open');
		this.doSearch();
	}

	getVaccineTool() {
		return (
			<div
				className="screener neumorphic hbox vcenter"
				onClick={() => ExternalService.handleExternalRequest('web', 'https://vaccinefinder.org/search/')}
			>
				<img className="svg" src={vaccineSVG} />
				<div className="details">
					<div className="screener-title">{this.text.vaccineTitle}</div>
					<div className="screener-msg">{this.text.vaccineMessage}</div>
				</div>
				<i className="fal fa-chevron-right"></i>
			</div>
		);
	}

	getScreener() {
		return (
			<div className="screener neumorphic hbox vcenter" onClick={appStore.openScreener}>
				<img className="svg" src={screenerSVG} />
				<div className="details">
					<div className="screener-title">{this.text.screenerTitle}</div>
					<div className="screener-msg">{this.text.screenerMessage}</div>
				</div>
				<i className="fal fa-chevron-right"></i>
			</div>
		);
	}

	openMapView() {
		this.data.lastView = 'mapview';
		this.data.resultsView = 'mapview';
		this.data.page = 'mapview';
		analytics.track('covid_test_finder_map_loaded');
	}

	closeMapView() {
		this.data.lastView = 'resultsview';
		this.data.resultsView = 'resultsview';
		this.data.page = 'resultsview';
	}

	buildChip(id, text, icon, value) {
		let color = value === 'y' ? 'green' : value === 'n' ? 'red' : 'yellow';
		if (value === 'n') {
			text = `No ${text}`;
		}
		// if (color === 'red') {
		// 	return '';
		// }
		return (
			<Chip text={text} mediaBgColor={color} className="ios" key={id}>
				<i slot="media" className={icon}></i>
			</Chip>
		);
	}

	buildChips(location) {
		let { driveThrough, appointmentOnly, referralRequired, callFirst, testType, sameDay } = location;
		let chips = [
			this.buildChip(location._id + 'test', testType, 'fad fa-vial', 'y'),
			this.buildChip(location._id + 'appt', 'Appointment Req', 'fas fa-calendar-alt', appointmentOnly),
			this.buildChip(location._id + 'call', 'Call Ahead Req', 'fas fa-phone', callFirst),
			this.buildChip(location._id + 'referral', 'Referral Req', 'fas fa-file-medical', referralRequired),
			this.buildChip(
				location._id + 'sameday',
				'Same Day Results',
				'fas fa-tachometer-alt-fastest',
				sameDay == 'no' ? 'n' : sameDay == 'unknown' ? 'u' : sameDay == 'yes' ? 'y' : sameDay == 'limited' ? 'y' : 'n'
			),
			this.buildChip(location._id + 'drive', 'Drive Through', 'fas fa-car', driveThrough)
		];
		return chips;
	}

	getResultsList() {
		if (this.data.results.length === 0) {
			return (
				<div className="results-list empty animated zoomIn">
					<img src={noresultsSVG} className="noresults" />
					<div className="empty-text">{this.text.emptyMsg}</div>
				</div>
			);
		}
		return (
			<div className="results-list">
				{this.data.results &&
					this.data.results.map &&
					this.data.results.map((l) => {
						return (
							<div
								className="list-item neumorphic hbox vcenter"
								key={l._id}
								onClick={() => {
									analytics.trackWithData('covid_test_finder_profile_loaded', {
										location: l.fullAddress,
										city: l.city,
										geoLocation: l.geoLocation
									});
									this.data.lastView = 'resultsview';
									this.data.activeLocation = l;
									this.data.page = 'profile';
								}}
							>
								<div className="marker vbox vcenter hcenter">
									<i className="fad fa-map-marker-alt"></i>
									<div>{UserService.getDistanceFromLocation(l)}mi</div>
								</div>
								<div className="details grow-1">
									<div className="name">{l.facilityName}</div>
									<div className="address">{l.fullAddress}</div>
									<div className="chips">{this.buildChips(l)}</div>
								</div>
								<i className="fal fa-chevron-right"></i>
							</div>
						);
					})}
			</div>
		);
	}

	toggleFilter() {
		this.data.showFilter = !this.data.showFilter;
	}

	backToResults() {
		this.data.resultsView = _.clone(this.data.lastView);
		this.data.page = _.clone(this.data.lastView);
		this.data.activeLocation = null;
	}

	getSiteProfile() {
		let {
			_id,
			facilityName,
			fullAddress,
			hours,
			comments,
			dateModified,
			phone,
			website,
			healthDeptUrl,
			geoLocation
		} = this.data.activeLocation;
		const lastUpdated =
			dateModified && dateModified !== 'unknown' ? format(new Date(dateModified), 'MM/dd/yyyy p') : this.text.unknownDateModified;
		return (
			<div className="content-ctn location-profile animated fadeInRight" key={`active-profile-${_id}`}>
				<div className="popup-header">
					<Link onClick={this.backToResults} className="grow-1 hbox vcenter w-100">
						<i className="far fa-long-arrow-left"></i>
						<div>Back</div>
					</Link>
					<Link popupClose>Close</Link>
				</div>
				<div className="page-cnt y-scroll">
					<div className="title grow-1">{facilityName}</div>
					<div className="subtitle hbox vcenter">
						<div className="marker hbox vcenter">
							<i className="fad fa-map-marker-alt"></i>
							<div>{UserService.getDistanceFromLocation(this.data.activeLocation)}mi</div>
						</div>
						<div>{fullAddress}</div>
					</div>
					<div className="chips">{this.buildChips(this.data.activeLocation)}</div>
					<div className="actions-ctn hbox vcenter">
						{phone && (
							<div
								className="action-btn vbox vcenter hcenter"
								onClick={() => {
									analytics.track('covid_call_test_site');
									ExternalService.handleExternalRequest('call', phone);
								}}
							>
								<div className="btn">
									<i className="fad fa-phone"></i>
								</div>
								<div className="label">{this.text.call}</div>
							</div>
						)}
						<div
							className="action-btn vbox vcenter hcenter"
							onClick={() => {
								analytics.track('covid_navigate_to_test_site');
								ProviderService.openNavigation({ location: { geoLocation, street1: fullAddress } }, this.$f7);
							}}
						>
							<div className="btn">
								<i className="fad fa-directions"></i>
							</div>
							<div className="label">{this.text.nav}</div>
						</div>
						{(website || healthDeptUrl) && (
							<div
								className="action-btn vbox vcenter hcenter"
								onClick={() => {
									analytics.track('covid_load_website');
									ExternalService.handleExternalRequest('web', website || healthDeptUrl);
								}}
							>
								<div className="btn">
									<i className="fad fa-external-link"></i>
								</div>
								<div className="label">{this.text.website}</div>
							</div>
						)}
					</div>
					{this.getScreener()}
					{comments && (
						<div className="text-data">
							<div className="category">{this.text.details}</div>
							<div className="body">{comments}</div>
						</div>
					)}
					{hours && (
						<div className="text-data">
							<div className="category">{this.text.hours}</div>
							<div className="body">{hours}</div>
						</div>
					)}
					<div className="updates">{`${this.text.lastUpdatedLabel}: ${lastUpdated}`}</div>
				</div>
			</div>
		);
	}

	popupContent() {
		switch (this.data.page) {
			case 'resultsview': {
				const lastUpdatedSubtitle = `${format(new Date(new Date().setHours(4)).setMinutes(0), 'MM/dd/yyyy p')}`;
				return (
					<div className="content-ctn animated fadeInLeft" key="test-list-ctn">
						<div className="popup-header">
							<div></div>
							<Link popupClose>Close</Link>
						</div>
						<div className="title grow-1">{this.text.title}</div>
						<div className="subtitle grow-1">{`${this.text.subtitle} ${lastUpdatedSubtitle}`}</div>
						<div className="page-cnt y-scroll">
							{this.getVaccineTool()}
							{this.getScreener()}
							<div className="list-header hbox vcenter">
								<div className="header-title grow-1">{this.text.listHeader}</div>
								<div className="filter-btn vbox vcenter hcenter neumorphic" onClick={this.toggleFilter}>
									<i className="fas fa-filter"></i>
									<div className="indicator red"></div>
								</div>
								<div className={`toggle-list-btn hbox vcenter neumorphic ${this.data.resultsView}`} onClick={this.openMapView}>
									<i className="fad fa-list"></i>
									<div className="divider"></div>
									<i className="fad fa-map-marked-alt"></i>
								</div>
							</div>
							<div className="color-key">
								<div className="keyval hbox vcenter">
									<div className="dot green"></div>
									<div className="val">Yes</div>
								</div>
								<div className="keyval hbox vcenter">
									<div className="dot red"></div>
									<div className="val">No</div>
								</div>
								<div className="keyval hbox vcenter">
									<div className="dot yellow"></div>
									<div className="val">Unknown</div>
								</div>
							</div>
							{this.getResultsList()}
						</div>
					</div>
				);
			}
			case 'mapview': {
				return (
					<div className="mapview-ctn animated fadeIn" key="map-list-ctn">
						<div className="map-page page-cnt">
							<div className="list-header hbox vcenter">
								<div className="grow-1"></div>
								<div className="top-btn-ctn hbox vcenter animated fadeInRight" key="floating-menu">
									<div className="filter-btn vbox vcenter hcenter neumorphic" onClick={this.toggleFilter}>
										<i className="fas fa-filter"></i>
										<div className="indicator red"></div>
									</div>
									<div className={`toggle-list-btn hbox vcenter neumorphic ${this.data.resultsView}`} onClick={this.closeMapView}>
										<i className="fad fa-list"></i>
										<div className="divider"></div>
										<i className="fad fa-map-marked-alt"></i>
									</div>
								</div>
							</div>
							<SiteMap
								locations={this.data.results}
								openLocation={(id) => {
									this.data.lastView = 'mapview';
									this.data.activeLocation = _.cloneDeep(_.find(this.data.results, { _id: id }));
									this.data.page = 'profile';
								}}
							/>
						</div>
					</div>
				);
			}
			case 'profile': {
				return this.getSiteProfile();
			}
		}
	}

	onToggleChange(e) {
		this.data.filters[e.currentTarget.name] = e.currentTarget.checked;
	}

	showInfoBox(message, title) {
		this.$f7.dialog.alert(message, title);
	}

	onRadiusChange(value) {
		if (value === 0) {
			value = 1;
		}
		this.data.filters.searchRadius = value;
	}

	buildFilterMenu() {
		let {
			searchRadius,
			appointmentOnly,
			sameDay,
			moleculer,
			callFirst,
			referralRequired,
			driveThrough,
			antibody,
			antigen,
			allowUnknowns
		} = this.data.filters;

		return (
			<div className={`filter-ctn ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<div className={`filter-menu animated ${this.data.filterAnimation}`}>
					<div className="title">Filters</div>
					<div className="range">
						<RangeSlider
							min={0}
							max={100}
							step={1}
							value={searchRadius}
							scale={true}
							scaleSteps={5}
							scaleSubSteps={5}
							onChange={this.onRadiusChange}
							source="testSiteFinder"
						/>
					</div>
					<div className="toggle-ctn hbox vcenter">
						<div className="label grow-1">Appointment Required:</div>
						<Toggle name="appointmentOnly" checked={appointmentOnly} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<div className="label grow-1">Call Ahead Required:</div>
						<Toggle name="callFirst" checked={callFirst} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<div className="label grow-1">Referral Required:</div>
						<Toggle name="referralRequired" checked={referralRequired} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<div className="label grow-1">Same Day Results:</div>
						<Toggle name="sameDay" checked={sameDay} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<div className="label grow-1">Drive Through Site:</div>
						<Toggle name="driveThrough" checked={driveThrough} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<i
							className="fad fa-info-circle"
							onClick={() => {
								this.showInfoBox(
									`All information is sourced from local governments and healthcare providers, but details can change quickly so we've marked data that we're not sure of as <strong>Unknown</strong>. Please contact testing sites or your local health department directly for official information and testing requirements.`,
									'Uncertain Data'
								);
							}}
						></i>
						<div className="label grow-1">Return Uncertain Data:</div>
						<Toggle name="allowUnknowns" checked={allowUnknowns} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<i
							className="fad fa-info-circle"
							onClick={() => {
								this.showInfoBox(
									`Also known as a diagnostic test, viral test, molecular test, nucleic acid amplification test (NAAT), RT-PCR test, LAMP test. Molecular Testing is different from other types of coronavirus testing because it takes just a little bit longer. However, medical experts say it is more accurate.`,
									'Moleculer Testing'
								);
							}}
						></i>
						<div className="label grow-1">Moleculer Testing:</div>
						<Toggle name="moleculer" checked={moleculer} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<i
							className="fad fa-info-circle"
							onClick={() => {
								this.showInfoBox(
									'These can detect if someone had a past COVID-19 infection but not if they still are positive for the virus.',
									'Antibody Testing'
								);
							}}
						></i>
						<div className="label grow-1">Antibody Testing:</div>
						<Toggle name="antibody" checked={antibody} onChange={this.onToggleChange} color="consumer" />
					</div>
					<div className="toggle-ctn hbox vcenter">
						<i
							className="fad fa-info-circle"
							onClick={() => {
								this.showInfoBox(
									'These tests are taken with a nasal or throat swab and detect a protein that is part of the coronavirus. These tests are particularly useful for identifying a person who is at or near peak infection. Antigen tests are less expensive and generally faster. The downside is that they can be less accurate.',
									'Antigen Testing'
								);
							}}
						></i>
						<div className="label grow-1">Antigen Testing:</div>
						<Toggle name="antigen" checked={antigen} onChange={this.onToggleChange} color="consumer" />
					</div>
					<Button fill large className="round-btn purple" onClick={this.applyFilters}>
						{this.text.filterButton}
					</Button>
				</div>
			</div>
		);
	}

	applyFilters() {
		this.$f7.dialog.preloader();
		this.data.filterAnimation = 'slideOutDown';
		this.doSearch();
		setTimeout(() => {
			this.toggleFilter();
			this.data.filterAnimation = 'slideInUp';
		}, 390);
	}

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`test-finder-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpen={this.onPopupOpen}
				onPopupClosed={this.handleOnClose}
			>
				{this.data.showFilter && this.buildFilterMenu()}
				{this.popupContent()}
			</Popup>
		);
	}
}
