import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Button, Link } from 'framework7-react';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import TierBuilder from '@/pages/EXPRESS/groups/plans-list/tier-builder/TierBuilder';
import _ from 'lodash';
import ExpressAPI from '@/services/ExpressAPI';
import './network-request-form.scss';
import expressStore from '@/stores/ExpressStore';

@observer
export default class NetworkRequestForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			formValid: false,
			loadingNetworks: false,
			existingNetworks: [],
			availableNetworks: [],
			showTierBuilder: false,
			formData: {
				status: 'pending',
				type: 'traditional',
				networkId: 0,
				name: '',
				contactName: '',
				contactPhone: '',
				contactEmail: '',
				request: null,
				existingNetwork: false,
				tierNetworkId: 'new',
				oonConfig: {
					color: 'red',
					text: 'Out-Network',
					hideChip: false
				}
			}
		});
		autoBind(this);
	}

	async componentDidMount() {
		const setupData = this.getDefaultDataByType(_.get(this.props, 'network.type'));
		this.data.formData = _.cloneDeep(setupData);
		if (this.props.editOnly && this.props.network.type !== 'traditional') {
			this.data.formValid = true; // theres no formbuilder forms to validate
			this.data.showTierBuilder = true; // thats all there is to do for these
		}
		await this.loadNetworks();
	}

	getDefaultDataByType(type) {
		let defaults = _.cloneDeep(this.props.network || {});
		if (!type) {
			type = defaults.type || 'traditional';
		}
		if (type !== defaults.type) {
			defaults = {};
		}
		const baseData = {
			status: defaults.status,
			networkId: defaults.networkId || defaults._id || 0,
			name: defaults.name || '',
			contactName: defaults.contactName || '',
			contactPhone: defaults.contactPhone || '',
			contactEmail: defaults.contactEmail || '',
			request: defaults.request || '',
			tiers: defaults.tiers || [],
			oonConfig: {
				text: _.get(defaults, 'oonConfig.text') || 'Out-Network',
				color: _.get(defaults, 'oonConfig.color') || 'red',
				hideChip: _.get(defaults, 'oonConfig.hideChip') || false
			}
		};
		switch (type) {
			case 'traditional':
				return {
					...baseData,
					type: 'traditional',
					existingNetwork: !!defaults && defaults.type === 'traditional'
				};
			case 'tiered':
				if (!baseData.tiers || baseData.tiers.length < 1) {
					baseData.tiers = [{ tier: 1, networks: [], color: 'green', text: 'In-Network' }];
				}
				return {
					...baseData,
					type: 'tiered',
					existingNetwork: !!defaults && defaults.type === 'tiered',
					request: null,
					contactName: null,
					contactPhone: null,
					contactEmail: null
				};
			default:
				if (!baseData.tiers) {
					baseData.tiers = [];
				}
				return {
					...baseData,
					type: 'none',
					existingNetwork: false
				};
		}
	}

	async loadNetworks() {
		this.data.loadingNetworks = true;
		ExpressAPI.getNetworks()
			.then((res) => {
				this.data.existingNetworks = res;
				this.data.loadingNetworks = false;
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
				this.data.loadingNetworks = false;
			});
		ExpressAPI.getAvailableNetworks()
			.then((res) => {
				this.data.availableNetworks = res;
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
			});
	}

	handleSubmit() {
		const isDisabled = this.checkTraditionalEditStatus();
		if (isDisabled && !this.props.disableEditExisting) {
			this.$f7.dialog.alert('This network has already been requested.  You can see the status of your request in the network list');
		} else {
			const formData = _.cloneDeep(this.data.formData);
			this.props.onSubmit(formData);
		}
	}

	saveTiers(data) {
		if (data.oonConfig) {
			this.data.formData.oonConfig = _.cloneDeep(data.oonConfig);
		} else {
			const currentTiers = _.cloneDeep(this.data.formData.tiers);
			const oldData = currentTiers.find((x) => x.tier === data.tier.tier);
			if (oldData) {
				if (!data.delete) {
					const updatedData = currentTiers.map((x) => {
						const isUpdated = x.tier === data.tier.tier;
						if (isUpdated) {
							return _.cloneDeep(data.tier);
						}
						return x;
					});
					this.data.formData.tiers = updatedData;
				} else {
					const remainingData = currentTiers.filter((x) => x.tier !== data.tier.tier).map((x, i) => ({ ...x, tier: i + 1 }));
					this.data.formData.tiers = remainingData;
				}
			} else {
				currentTiers.push(_.cloneDeep(data.tier));
				this.data.formData.tiers = currentTiers;
			}
		}
	}

	getNetworkListByType(type) {
		const requestableNetworks = _.cloneDeep(this.data.availableNetworks);
		const requestedNetworks = _.cloneDeep(this.data.existingNetworks);
		const filteredNetworks = requestedNetworks.filter((network) => network.type === type);
		let mappedNetworks = Object.keys(requestableNetworks).map((networkKey) => {
			const existingRequestData = filteredNetworks.find((x) => x._id === networkKey || x.networkId === networkKey);
			return {
				id: networkKey,
				name: requestableNetworks[networkKey],
				isExisting: !!existingRequestData,
				requestData: existingRequestData
			};
		});
		if (type === 'tiered' || type === 'none') {
			mappedNetworks = mappedNetworks.filter((x) => !!x.isExisting);
		}
		const newNetworks = filteredNetworks
			.filter((network) => !network.networkId && network.type === type)
			.map((network) => ({ id: network._id, name: network.name, isExisting: true, requestData: network }));
		const finalNetworks = _.flatten([mappedNetworks, newNetworks]);
		return finalNetworks;
	}

	getFormFieldConfigs() {
		const networkDropdownData = this.getNetworkListDropdownItems();
		const isEdit = this.props.editOnly;
		const currentType = this.data.formData.type;
		const isSysAdmin = expressStore.isSystemAdmin();
		return {
			status: {
				label: 'Request Status',
				type: isSysAdmin ? 'dropdown' : 'hidden',
				className: 'dropdown-form-input',
				icon: 'fad fa-unlock-alt',
				listItems: {
					approved: 'Approved',
					pending: 'Pending'
				}
			},
			type: {
				label: 'Network Type',
				type: isEdit ? 'hidden' : 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-network-wired',
				listItems: {
					traditional: 'Traditional - Single Network',
					tiered: 'Tiered - Multiple Provider Networks',
					none: 'None - All providers should display the same in this network'
				}
			},
			id: {
				label: 'Request to a new network, or use an exsting one',
				type: isEdit ? 'hidden' : 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-hospital-user',
				listItems: networkDropdownData.list,
				displayValues: networkDropdownData.getDisplayValues
			},
			name: {
				label: 'Network Name',
				placeholder: 'Name of the network',
				type: 'text',
				validator: {
					type: 'length',
					value: 3
				}
			},
			contactName: {
				label: 'Network Contact Name',
				placeholder: 'Contact to connect network',
				type: 'text',
				validator: {
					type: 'length',
					value: 3,
					notRequired: currentType !== 'traditional'
				}
			},
			contactEmail: {
				label: 'Network Contact Email',
				placeholder: 'Network contact email',
				type: 'email',
				validator: {
					type: 'email',
					notRequired: currentType !== 'traditional'
				}
			},
			contactPhone: {
				label: 'Network Contact Phone Number',
				placeholder: 'Network contact phone',
				type: 'phone',
				validator: {
					type: 'phone',
					notRequired: currentType !== 'traditional'
				}
			},
			request: {
				label: 'Request Details',
				placeholder:
					'Please add any additional details that may help us connect to this network and load them into the system for you.',
				type: 'textarea',
				validator: {
					type: 'length',
					value: 3,
					notRequired: currentType !== 'traditional'
				}
			}
		};
	}

	getNetworkListDropdownItems() {
		const type = _.get(this.data, 'formData.type');
		const filteredNetworkData = this.getNetworkListByType(type);
		const networkListItems = type === 'traditional' ? { 0: 'Request New Network' } : { 0: 'Create New' };
		filteredNetworkData.map((network) => {
			networkListItems[network.id] = network.name;
		});
		const existingNetworkKeys = filteredNetworkData.filter((x) => !!x.isExisting).map((x) => x.id);
		return {
			list: networkListItems,
			getDisplayValues: (key, data) => {
				const isExisting = existingNetworkKeys.indexOf(key) > -1;
				if (isExisting) {
					return (
						<>
							<span className="tag">Existing</span>
							<span>{data[key]}</span>
						</>
					);
				}
				return data[key];
			}
		};
	}

	checkTraditionalEditStatus() {
		const filteredNetworkData = this.getNetworkListByType('traditional');
		const networkListItems = { 0: 'Request New Network' };
		filteredNetworkData.map((network) => {
			networkListItems[network.id] = network.name;
		});
		const existingNetworkKeys = filteredNetworkData.filter((x) => !!x.isExisting).map((x) => x.id);
		const currentNetworkSelected = _.get(this.data, 'formData.networkId') || '0';
		const disableRequestFields = !this.props.editOnly && existingNetworkKeys.indexOf(currentNetworkSelected.toString()) > -1;
		return disableRequestFields;
	}

	getTraditionalNetworkConfig() {
		const currentNetworkSelected = _.get(this.data, 'formData.networkId') || '0';
		const disableRequestFields = this.checkTraditionalEditStatus();
		const disableName = !this.props.editOnly && currentNetworkSelected.toString() !== '0';
		const formConfigs = this.getFormFieldConfigs();
		return {
			type: formConfigs.type,
			networkId: formConfigs.id,
			row1: {
				name: {
					...formConfigs.name,
					disabled: disableName || (disableRequestFields && this.props.disableEditExisting)
				},
				contactName: {
					...formConfigs.contactName,
					disabled: disableRequestFields
				}
			},
			row2: {
				contactEmail: {
					...formConfigs.contactEmail,
					disabled: disableRequestFields
				},
				contactPhone: {
					...formConfigs.contactPhone,
					disabled: disableRequestFields
				}
			},
			request: {
				...formConfigs.request,
				disabled: disableRequestFields
			},
			status: formConfigs.status
		};
	}

	getTieredNetworkFormConfig() {
		const currentNetworkSelected = _.get(this.data, 'formData.networkId') || '0';
		const disableName = !this.props.editOnly && currentNetworkSelected.toString() !== '0';
		const formConfigs = this.getFormFieldConfigs();
		return {
			type: formConfigs.type,
			networkId: {
				...formConfigs.id,
				label: 'Start from scratch or use existing'
			},
			name: {
				...formConfigs.name,
				disabled: disableName
			},
			status: formConfigs.status
		};
	}

	handleNetworkIdChange() {
		const type = _.clone(_.get(this.data, 'formData.type') || 'traditional');
		const currentNetwork = _.clone(_.get(this.data, 'formData.networkId'));
		const newNetwork = currentNetwork === 0;
		const filteredNetworkData = this.getNetworkListByType(type);
		const defaultData = this.getDefaultDataByType(type);
		const originalNetworkData = filteredNetworkData.find((x) => x.id === currentNetwork) || {};
		if (!newNetwork) {
			const existingRequest = originalNetworkData.requestData || {};
			const newData = {
				...defaultData,
				...existingRequest,
				existingNetwork: !!originalNetworkData.isExisting,
				networkId: currentNetwork,
				name: originalNetworkData.name,
				type: type
			};
			this.data.formData = _.cloneDeep(newData);
			if (originalNetworkData.isExisting) {
				this.data.formValid = true;
			}
		}
		if (newNetwork) {
			const newData = {
				...defaultData,
				name: '',
				existingNetwork: false
			};
			this.data.formData = _.cloneDeep(newData);
		}
	}

	handleTypeChange() {
		this.data.showTierBuilder = false;
		const type = this.data.formData.type;
		const defaultData = this.getDefaultDataByType(type);
		if (type === 'traditional') {
			defaultData.tiers = [];
			defaultData.contactEmail = '';
			defaultData.contactPhone = '';
			defaultData.contactName = '';
		}
		if (type === 'none') {
			defaultData.tiers = [];
		}
		if (type !== 'traditional') {
			this.data.showTierBuilder = true;
		}
		this.data.formData = _.cloneDeep(defaultData);
	}

	checkFormValidation(status) {
		const type = _.clone(_.get(this.data, 'formData.type') || 'traditional');
		const currentNetwork = _.clone(_.get(this.data, 'formData.networkId'));
		const filteredNetworkData = this.getNetworkListByType(type);
		const originalNetworkData = filteredNetworkData.find((x) => x.id === currentNetwork) || {};
		if (originalNetworkData.isExisting) {
			this.data.formValid = true;
		} else {
			this.data.formValid = status;
		}
	}

	render() {
		const currentType = _.get(this.data, 'formData.type');
		const currentTiers = _.get(this.data, 'formData.tiers') || [];
		const emptyTiers = (_.get(this.data, 'formData.tiers') || []).filter((tier) => {
			const networks = tier.networks;
			return !networks || networks.length < 1;
		});
		const tiersValid = emptyTiers.length < 1 && currentTiers.length > 0;
		const disableSubmit = !this.data.formValid || (this.data.formData.type === 'tiered' && !tiersValid);
		const showTierBuilder = this.data.showTierBuilder && this.data.existingNetworks && this.data.existingNetworks.length > 0;
		const showTierBuilderError = this.data.showTierBuilder && !showTierBuilder;
		let currentFormConfig = {};
		if (this.data.formData.type === 'traditional') {
			currentFormConfig = this.getTraditionalNetworkConfig();
		} else {
			currentFormConfig = this.getTieredNetworkFormConfig();
		}
		const title = this.props.editOnly ? 'Edit Network Request' : 'Network Setup';
		return (
			<div className="network-request-form-container">
				{this.props.standalone && (
					<div className="popup-header hbox vcenter">
						<div className="title grow-1">{title}</div>
						<Link popupClose>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
				)}
				<div className={`${this.props.standalone ? 'main-content y-scroll' : 'form-body'}`}>
					{this.data.showTierBuilder && currentType === 'tiered' && (
						<div className="helper-text">
							<div className="message-text">
								A tiered network allows you to group 1 more more networks into a "Tier". You can then control their position in search
								results as well as the color and associated labels for that set of providers or facilities.
							</div>
						</div>
					)}
					{this.data.showTierBuilder && currentType === 'none' && (
						<div className="helper-text">
							<div className="message-text">
								Here you can control how providers are presented throughout the application when there are no designated network
								providers. Any plans that you assign to this network will display as shown/configured below. If you don't need to make
								any changes to the default display, then you can skip this setup all together.
							</div>
						</div>
					)}
					<ExpressFormBuilder
						formData={this.data.formData}
						formConfig={currentFormConfig}
						setValidationState={(valid) => {
							this.checkFormValidation(valid);
						}}
						afterChange={(field) => {
							if (field === 'networkId') {
								this.handleNetworkIdChange();
							}
							if (field === 'type') {
								this.handleTypeChange();
							}
						}}
					/>
					{showTierBuilder && (
						<TierBuilder
							networks={this.getNetworkListByType('traditional')}
							saveTier={this.saveTiers}
							data={this.data.formData}
							buttonText="Continue"
							disableEdit={this.props.disableEditExisting && this.data.formData.existingNetwork}
						/>
					)}
					{!showTierBuilder && !showTierBuilderError && (
						<div className="message-text mb-l">
							Our team will be reaching out soon to all contacts in order to approve this network.
						</div>
					)}
					{showTierBuilderError && (
						<div className="message-text mb-l">
							You cannot create a tiered network without first requesting network access to networks that will be in each tier.
						</div>
					)}
				</div>
				<div className="btn-ctn">
					<Button large fill className="round-btn ios" disabled={disableSubmit} onClick={this.handleSubmit}>
						Next
					</Button>
				</div>
			</div>
		);
	}
}
