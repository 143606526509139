import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import ConfigService from '../services/ConfigService';
import _ from 'lodash';
import appStore from '../stores/AppStore';

export function standardizeLocale(locale = 'en-US') {
	// TODO @AMH added as a fallback in case user device has a language selectin that we don't specifically support - will fall back to English
	const translations = Object.values(ConfigService.getConfigValue('language', {}));
	const targetTranslationData = _.find(translations, { config: { code: locale } });
	if (!targetTranslationData) {
		const languageParts = locale.split('-');
		const languageKey = languageParts[0];
		const regionKey = languageParts[1];
		switch (languageKey) {
			case 'es': // if requesting spanish, return the one we def. have
				return 'es-MX';
			case 'en': // if requesting English, return the one we def. have
				return 'en-US';
			default:
				return 'en-US'; // return English for anything else.
		}
	}
	return locale;
}

export function getCmpText(cmpName, defaults) {
	const translations = Object.values(ConfigService.getConfigValue('language', {}));
	const targetLocale = appStore.locale;
	const targetTranslationData = _.find(translations, { config: { code: targetLocale } });
	const targetTranslations = _.get(targetTranslationData, `translations.${cmpName}`, {});
	const requestedKeys = Object.keys(defaults);
	const missingKeys = [];
	translations.map((lang) => {
		const translatedData = _.get(lang, `translations.${cmpName}`, {});
		requestedKeys
			.filter((reqKey) => !translatedData[reqKey])
			.map((reqKey) => {
				const data = {
					language: lang.config.code,
					component: cmpName,
					key: reqKey,
					default: defaults[reqKey],
					host: _.get(window, 'location.hostname', '')
				};
				missingKeys.push(data);
			});
	});
	// if(missingKeys.length > 0) {
	//   console.log("do something with these missing translations: ", JSON.stringify(missingKeys, null, 2))
	// }
	return { ...defaults, ...targetTranslations };
}

export function getLocalizedDate(date, strFormat) {
	const locales = { es, enUS };
	return format(date, strFormat, { locale: locales[appStore.locale] });
}

export function getSpecialtyKey(selectedCategory, selectedSpecialty) {
	const categories = ConfigService.getConfigValue('search.categories', {}) || {};
	const locale = appStore.locale;
	const categoryDetails = _.find(Object.values(categories), { type: selectedCategory });
	const enSpecialties = _.get(categoryDetails, 'specialty["en-US"]', []); //server knows en-US values only
	const translatedSpecialties = _.get(categoryDetails, `specialty[${locale}]`, []);
	const selectedIndex = translatedSpecialties.indexOf(selectedSpecialty);
	return locale === 'en-US' ? selectedSpecialty : enSpecialties[selectedIndex];
}
export function getLocalizedSpecialties(selectedCategory) {
	const categories = ConfigService.getConfigValue('search.categories', {}) || {};
	const locale = appStore.locale;
	const categoryDetails = _.find(Object.values(categories), { type: selectedCategory });
	const specialties = _.get(categoryDetails, `specialty.${locale}`) || [];
	return specialties;
}

export function getLocalizedQuickLinks() {
	const quickLinks = ConfigService.getConfigValue('search.quickLinks', {}) || {};
	const locale = appStore.locale;
	const quickLinkData = Object.values(_.cloneDeep(quickLinks)) || [];
	const displayValues = quickLinkData.map((qlItem) => {
		const localizedValue = _.get(qlItem, `label[${locale}]`) || _.get(qlItem, `label["en-US]`, '');
		qlItem.label = localizedValue;
		return qlItem;
	});
	return displayValues;
}

export function getLocalizedCategories() {
	const categories = ConfigService.getConfigValue('search.categories', {}) || {};
	const locale = appStore.locale;
	const categoryKeys = Object.keys(categories);
	const localizedCategories = categoryKeys.map((catKey) => {
		return {
			...categories[catKey],
			name: _.get(categories, `${catKey}.name.${locale}`) || '',
			specialty: _.get(categories, `${catKey}.specialty.${locale}`) || ''
		};
	});
	return localizedCategories;
}
