import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './share-document-panel.scss';
import { getCmpText } from '@/utils/Localization';
import BasicInput from '@/components/basic-input/BasicInput';
import { Button } from 'framework7-react';
import _ from 'lodash';

@observer
export default class ShareDocumentPanel extends Component {
	constructor(props) {
		super(props);

		this.data = observable({
			to: '',
			subject: '',
			body: '',
			formInvalid: true
		});

		this.text = getCmpText('ShareDocumentPanel', {
			cancelTxt: 'Cancel',
			emailHeadline: 'Email document',
			emailCardHeadline: 'Email card',
			faxHeadline: 'Fax document',
			faxCardHeadline: 'Fax card',
			sendEmailCard: 'Send card',
			sendFaxCard: 'Fax card',
			emailPlaceholder: "Enter the recepient's email",
			subjectPlaceholder: 'Enter the email subject line',
			bodyPlaceholder: 'Enter a message you would like included in the email',
			faxNumberPlaceholder: "Enter the recepient's fax number",
			submitEmailCard: 'Email card',
			submitEmail: 'Email document',
			submitFaxCard: 'Fax card',
			submitFax: 'Fax document',
			emailAddressLabel: 'Email',
			emailBodyLabel: 'Custom message',
			faxNumberLabel: 'Fax Number'
		});
		autoBind(this);
	}

	getHeadlineText() {
		const { shareMethod, isInsuranceCard } = this.props;
		if (isInsuranceCard) {
			if (shareMethod === 'email') return this.text.emailCardHeadline;
			if (shareMethod === 'fax') return this.text.faxCardHeadline;
		}

		if (shareMethod === 'email') return this.text.emailHeadline;
		if (shareMethod === 'fax') return this.text.faxHeadline;
	}

	getSubmitText() {
		const { shareMethod, isInsuranceCard } = this.props;
		if (isInsuranceCard) {
			if (shareMethod === 'email') return this.text.submitEmailCard;
			if (shareMethod === 'fax') return this.text.submitFaxCard;
		}

		if (shareMethod === 'email') return this.text.submitEmail;
		if (shareMethod === 'fax') return this.text.submitFax;
	}

	async handleSubmit() {
		if (this.props.onSubmit) {
			await this.props.onSubmit(_.cloneDeep(this.data));
			this.resetForm();
		}
	}

	async resetForm() {
		this.data.to = '';
		this.data.subject = '';
		this.data.body = '';
		this.data.formInvalid = true;
	}

	handleChange(e) {
		let target = e.currentTarget;
		this.data[target.name] = target.value;
	}

	handleValidate(isValid) {
		this.data.formInvalid = !isValid;
	}

	handleClose() {
		this.resetForm();
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	render() {
		const { opened, onClose, shareMethod } = this.props;
		return (
			<div className={`share-document-panel animated ${opened ? 'opened' : ''}`}>
				<div className={`share-document-backdrop animated`}></div>
				{opened && (
					<div className={`share-document-body  ${opened ? 'opened' : ''}`}>
						<div className="header hbox vcenter">
							<h1 className="title grow-1">{this.getHeadlineText()}</h1>
							<a className="back-btn" onClick={this.handleClose}>
								{this.text.cancelTxt}
							</a>
						</div>
						<ShareDocumentForm
							shareMethod={shareMethod}
							shareFormData={this.data}
							onChange={this.handleChange}
							onValidate={this.handleValidate}
							onSubmit={this.handleSubmit}
							text={this.text}
							submitText={this.getSubmitText()}
							formInvalid={this.data.formInvalid}
						/>
					</div>
				)}
			</div>
		);
	}
}

const ShareDocumentForm = observer((props) => {
	const {
		shareMethod,
		onSubmit,
		onChange,
		shareFormData,
		submitText,
		onValidate,
		formInvalid,
		text: {
			emailPlaceholder,
			subjectPlaceholder,
			bodyPlaceholder,
			faxNumberPlaceholder,
			faxNumberLabel,
			emailAddressLabel,
			emailBodyLabel
		}
	} = props;
	return (
		<div className="share-form vbox">
			{shareMethod === 'email' && (
				<EmailForm
					onChange={onChange}
					emailAddressLabel={emailAddressLabel}
					shareFormData={shareFormData}
					emailPlaceholder={emailPlaceholder}
					emailBodyLabel={emailBodyLabel}
					subjectPlaceholder={subjectPlaceholder}
					bodyPlaceholder={bodyPlaceholder}
					onValidate={onValidate}
				/>
			)}
			{shareMethod === 'fax' && (
				<FaxForm
					faxNumberLabel={faxNumberLabel}
					faxNumberPlaceholder={faxNumberPlaceholder}
					onChange={onChange}
					shareFormData={shareFormData}
					onValidate={onValidate}
				/>
			)}
			<Button fill large className="round-btn" onClick={onSubmit} id="share-document-button" disabled={formInvalid}>
				{submitText} <i className="far fa-long-arrow-right"></i>
			</Button>
		</div>
	);
});

const EmailForm = observer((props) => {
	const {
		emailPlaceholder,
		subjectPlaceholder,
		bodyPlaceholder,
		onChange,
		onValidate,
		emailAddressLabel,
		emailBodyLabel,
		shareFormData: { to, subject, body }
	} = props;
	return (
		<div className="share-inputs">
			<BasicInput
				validate
				name="to"
				label={emailAddressLabel}
				value={to}
				type="email"
				onChange={onChange}
				placeholder={emailPlaceholder}
				onValidate={onValidate}
			/>
			<BasicInput name="subject" label="Subject" value={subject} onChange={onChange} type="text" placeholder={subjectPlaceholder} />
			<BasicInput name="body" label={emailBodyLabel} value={body} type="textarea" onChange={onChange} placeholder={bodyPlaceholder} />
		</div>
	);
});

const FaxForm = observer((props) => {
	const {
		faxNumberPlaceholderText,
		faxNumberLabel,
		onChange,
		onValidate,
		shareFormData: { to }
	} = props;
	return (
		<div className="share-inputs">
			<BasicInput
				validate
				name="to"
				label={faxNumberLabel}
				value={to}
				type="text"
				minlength={10}
				onChange={onChange}
				placeholder={faxNumberPlaceholderText}
				onValidate={onValidate}
			></BasicInput>
		</div>
	);
});
