import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveBump } from '@nivo/bump';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './bump-chart.scss';

@observer
export default class BumpChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getColor(data) {
		return data.color;
	}

	formatData() {
		const data = _.get(this.props, 'data.data') || [];
		const allItemsData = _.flatten(data.map((x) => x.data));
		const formattedData = data.map((item) => {
			const itemData = item.data;
			const formattedData = itemData.map((group, index) => {
				const allDataForGroup = allItemsData.filter((x) => x.x === group.x).map((x) => x.y);
				const sortedData = allDataForGroup.sort((a, b) => (a < b ? 1 : -1));
				const thisRanking = sortedData.indexOf(group.y);
				return {
					x: group.x,
					y: thisRanking + 1,
					original: group.y
				};
			});
			return {
				...item,
				data: formattedData
			};
		});
		return formattedData;
	}

	handleItemClick(data) {
		console.log('bump item was clicked: ', data);
	}

	getCustomPoint(data) {
		const { x, y, isActive, isInactive, size, color, borderColor, borderWidth } = data;
		return (
			<g transform={`translate(${x}, ${y})`} style={{ pointerEvents: 'none' }}>
				{isActive && (
					<text textAnchor="middle" y={-4} fill={borderColor} fontWeight="bold">
						{data.data.original}
					</text>
				)}
				{isInactive && (
					<text textAnchor="middle" y={4} fill={borderColor}>
						{data.data.original}
					</text>
				)}
				{!isActive && !isInactive && (
					<circle
						cx={size * -0.5 - 4}
						cy={size * -0.5 + 4}
						r={size / 2}
						fill={color}
						strokeWidth={borderWidth}
						stroke={borderColor}
						style={{ pointerEvents: 'none' }}
					></circle>
				)}
			</g>
		);
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const chartData = this.formatData();
		const chartConfig = chartProperties.config;
		return (
			<ResponsiveBump
				data={chartData}
				margin={{ top: isMobile ? 20 : 20, right: 50, bottom: isMobile ? 70 : 50, left: 50 }}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				lineWidth={3}
				activeLineWidth={6}
				inactiveLineWidth={3}
				inactiveOpacity={0.15}
				pointComponent={this.getCustomPoint}
				pointSize={10}
				activePointSize={16}
				inactivePointSize={0}
				onClick={this.handleItemClick}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={3}
				activePointBorderWidth={3}
				pointBorderColor={{ from: 'serie.color' }}
				endLabel={false}
				axisTop={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: isMobile ? 5 : 20,
					tickRotation: isMobile ? Number(-90) : 0,
					legend: '',
					legendPosition: 'middle',
					legendOffset: 32
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'ranking',
					legendPosition: 'middle',
					legendOffset: -40
				}}
			/>
		);
	}
}
