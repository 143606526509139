// @ts-nocheck
import React, { Component, createRef, componentWillUnmount } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Popup, Link, List, ListItem } from 'framework7-react';
import _ from 'lodash';
import './config-editor.scss';
import DemoAppShell from '@/components/demo-app-shell/DemoAppShell';
import appStore from '@/stores/AppStore';
import ExpressAPI from '@/services/ExpressAPI';
import FormBuilder from './ConfigFormBuilder';
import ConfigService from '@/services/ConfigService';
import expressStore from '@/stores/ExpressStore';
import providerStore from '@/stores/ProviderStore';
import mockData from '@/components/demo-app-shell/mock-data';
import ProviderService from '@/services/ProviderService';

const originalConfigService = _.cloneDeep(ConfigService);

@observer
export default class ConfigEditor extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			demoStatus: 'ready',
			config: {},
			editorConfigs: {},
			openForms: [],
			disclaimerText: '',
			demoConfigs: {}
		});
		this.notification = createRef(null);
		autoBind(this);
	}

	async componentDidMount() {
		await this.getEditorConfigs();
	}

	setInitialConfig(config) {
		this.data.config = _.cloneDeep(config);
	}

	async getEditorConfigs() {
		const data = await ExpressAPI.getConfigForms();
		this.data.editorConfigs = _.cloneDeep(data.forms);
		this.data.disclaimerText = _.cloneDeep(data.disclaimer);
		this.data.demoConfigs = _.cloneDeep(data.demoConfigs);
	}

	async componentWillUnmount() {
		console.log('I AM UNMOUNTING');
		expressStore.pendingConfigs = null;
		this.removeNotificationRef();
	}

	demoAppUpdate() {
		this.data.demoStatus = 'ready';
	}

	showForm(evt) {
		const formId = evt.currentTarget.getAttribute('data-form');
		const currentOpen = _.cloneDeep(this.data.openForms);
		const isOpen = currentOpen.indexOf(formId) > -1;
		if (isOpen) {
			this.data.openForms = currentOpen.filter((x) => x !== formId);
		} else {
			currentOpen.push(formId);
			this.data.openForms = currentOpen;
		}
	}

	onConfigUpdate(fieldConfig, evt) {
		const configKey = fieldConfig.sourceConfig.configKey;
		let newVal = _.get(evt, 'currentTarget.value');
		switch (fieldConfig.type) {
			case 'toggle':
			case 'checkbox':
				newVal = !fieldConfig.value;
				if (fieldConfig.sourceConfig.configType === 'array') {
					const thisItem = fieldConfig.sourceConfig.configValue;
					const currArr = _.cloneDeep(_.get(this.data.config, fieldConfig.sourceConfig.configKey) || []);
					const itemIndex = _.get(fieldConfig, 'sourceConfig.itemIndex');
					if (newVal) {
						if (isNaN(itemIndex)) {
							currArr.push(thisItem);
						} else {
							currArr.splice(itemIndex, 0, thisItem);
						}

						newVal = currArr;
					} else {
						newVal = currArr.filter((x) => x !== thisItem);
					}
				} else {
					if (fieldConfig.sourceConfig.configValue) {
						if (!!newVal) {
							newVal = fieldConfig.sourceConfig.configValue;
						}
					}
					if (fieldConfig.sourceConfig.inverted) {
						newVal = !newVal;
					}
				}
				break;
			case 'text':
				break;
			case 'dropdown':
			case 'iconPicker':
			case 'fileDrop':
				newVal = evt;
				break;
			default:
				console.log('default handling');
		}
		if (!newVal && fieldConfig.sourceConfig.deleteValue) {
			_.unset(this.data.config, configKey);
		} else {
			_.set(this.data.config, configKey, _.cloneDeep(newVal));
		}
		if (fieldConfig.sourceConfig.refresh) {
			this.data.demoStatus = 'loading';
			setTimeout(() => {
				this.data.demoStatus = 'ready';
			}, 1000);
		}
		this.trackConfigChange(fieldConfig.sourceConfig);
	}

	fieldDependencyCheck(fieldConfig) {
		if (!fieldConfig) return false;
		const dependency = fieldConfig.dependency || {};
		if (dependency.method) {
			const dependencyFn = _[dependency.method];
			if (dependencyFn && typeof dependencyFn === 'function') {
				let dependencyValue = _.get(this.data.config, dependency.key);
				if (typeof dependency.value === 'boolean' && typeof dependencyValue !== 'boolean') {
					dependencyValue = !!dependencyValue;
				}
				const dependencyResult = _[dependency.method](dependencyValue, dependency.value);
				const isMet = dependency.hasOwnProperty('expect') ? _.isEqual(dependencyResult, dependency.expect) : dependencyResult;
				return isMet;
			}
		}
		return true;
	}

	getFormData(formConfig) {
		const formType = formConfig.type;
		switch (formType) {
			case 'form':
				const dependencyCheck = this.fieldDependencyCheck(formConfig);
				if (!dependencyCheck) return {};
				const fieldKeys = Object.keys(formConfig.items);
				const prepFields = fieldKeys.map((fieldKey) => this.getFormItemData(formConfig, fieldKey)).filter((x) => x.canShow);
				return prepFields;
			default:
				break;
		}
		return {};
	}

	getFormItemData(formConfig, fieldKey) {
		const fieldId = `${formConfig.id}-${fieldKey}`.replace(/ /g, '');
		const itemData = formConfig.items[fieldKey];
		let currentValue = _.get(this.data.config, formConfig.items[fieldKey].configKey);
		if (itemData.configType === 'array') {
			currentValue = (currentValue || []).indexOf(itemData.configValue) > -1;
		}
		if (itemData.type === 'toggle' || itemData.type === 'checkbox') {
			currentValue = !!currentValue;
			if (itemData.inverted) {
				currentValue = !currentValue;
			}
		}
		if (itemData.type === 'text' && _.isEmpty(currentValue)) {
			currentValue = itemData.default;
		}
		return {
			type: formConfig.items[fieldKey].type,
			label: formConfig.items[fieldKey].label,
			id: fieldId,
			value: currentValue || '',
			disabled: formConfig.items[fieldKey].disabled,
			sourceConfig: formConfig.items[fieldKey],
			canShow: this.fieldDependencyCheck(itemData),
			placeholder: formConfig.items[fieldKey].placeholder
		};
	}

	getPopupFormData() {
		const data = this.getFormData(this.data.popupForm);
		const newData = data.map((x) => {
			x.value = _.get(this.data.config, x.sourceConfig.configKey);
			return x;
		});
		return newData;
	}

	handleItemSorting(formConfig, data, test) {
		const currentData = _.get(this.data.config, formConfig.itemsKey);
		const currentValues = Object.values(currentData).filter((x) => !!x);
		const order = _.cloneDeep(_.get(this.data.config, formConfig.configKey) || []).filter((x) => currentValues.indexOf(x) > -1);
		const unorderedCards = currentValues.filter((card) => {
			const currentData = Object.values(order).indexOf(card) > -1;
			return !currentData && !!card;
		});
		unorderedCards.map((x) => order.push(x));
		const thisItem = order[data.from];
		const newValues = _.cloneDeep(order);
		newValues.splice(data.from, 1);
		newValues.splice(data.to, 0, thisItem);
		const configKey = formConfig.configKey;
		_.set(this.data.config, configKey, _.cloneDeep(newValues.filter((x) => !!x)));
		this.trackConfigChange(formConfig);
		if (formConfig.refresh) {
			this.data.demoStatus = 'loading';
			setTimeout(() => {
				this.data.demoStatus = 'ready';
			}, 1000);
		}
	}

	getFormBody(formId, thisFormConfig) {
		const currentTab = this.getActiveTab();
		const editorConfigs = _.get(this.data.editorConfigs, `${currentTab}.items`);
		const formConfig = editorConfigs.find((x) => x.id === formId) || thisFormConfig;
		const formType = formConfig.type;
		const dependencyCheck = this.fieldDependencyCheck(formConfig);
		if (!dependencyCheck) {
			return false;
		}
		switch (formType) {
			case 'sortable':
				const cards = Object.values(_.get(this.data.config, formConfig.itemsKey) || []);
				const order = _.cloneDeep(_.get(this.data.config, formConfig.configKey) || []).filter((x) => cards.indexOf(x) > -1);
				const addedCards = cards.filter((card) => {
					const currentData = Object.values(order).indexOf(card) > -1;
					return !currentData;
				});
				addedCards.map((x) => order.push(x));
				const sortItems = order
					.map((x) => {
						const itemConfig = _.find(formConfig.items, { configValue: x });
						return itemConfig;
					})
					.filter((x) => this.fieldDependencyCheck(x));
				return (
					<List
						className={`sortable-tap-hold sortable-enabled`}
						sortable
						sortableOpposite
						id={`sortable${formConfig.id}`}
						onSortableSort={(data, test) => this.handleItemSorting(formConfig, data, test)}
					>
						{sortItems.map((item) => {
							return <ListItem key={`sortable-item=${item.configValue}`} data-item={item.configValue} title={item.label} />;
						})}
					</List>
				);
			case 'form':
				const prepFields = this.getFormData(formConfig);
				const hasDataToShow = prepFields.length > 0;
				if (hasDataToShow) {
					return (
						<div className="card-fields">
							<div className="card-subtitle">{formConfig.info}</div>
							{formConfig.newItemConfig && (
								<div className="add-config-cta">
									Add New <i className="fad fa-plus" />
								</div>
							)}
							<div className="form-container">
								<FormBuilder fields={prepFields} onChange={this.onConfigUpdate} />
							</div>
						</div>
					);
				} else {
					return false;
				}
			case 'array':
				const formItems = _.get(this.data.config, formConfig.configKey);
				let itemsToMap = [];
				if (Array.isArray(formItems)) {
					itemsToMap = formItems || [];
				} else {
					itemsToMap = Object.keys(formItems || {}).map((formItem) => {
						const formItemConfig = formConfig.itemConfig || {};
						const formItemFields = Object.keys(formItemConfig) || [];
						formItemFields.push('disableEdit');
						const itemData = {};
						formItemFields.map((formItemField) => {
							itemData[formItemField] = _.get(formItems, `[${formItem}][${formItemField}]`);
						});
						return itemData;
					});
				}
				return (
					<div className="card-fields">
						<div className="card-subtitle">{formConfig.info}</div>
						{formConfig.canAdd && (
							<div className="add-config-cta" onClick={() => this.addItem(formConfig)}>
								<i className="fad fa-plus" />
								Add New
							</div>
						)}
						<div className="form-container">
							{itemsToMap.map((item, itemIndex) => {
								const displayKeys = formConfig.displayKeys;
								const displayValues = displayKeys.map((dKey) => _.startCase(item[dKey])).filter((x) => !!x && x !== '');
								const displayString = displayValues.join(' - ');
								const itemEdit = !item.disableEdit;
								return (
									<div className="form-array-item" key={`array-form-item-${formConfig.id}-${itemIndex}`}>
										<div className="label">
											{formConfig.canDelete && (
												<div className="delete-container" onClick={() => this.deleteArrayItem(formConfig, item, itemIndex)}>
													<i className="fad fa-times" />
												</div>
											)}
											{displayString}
										</div>
										<div className="actions">
											{formConfig.canEdit && itemEdit && (
												<div className="edit-container" onClick={() => this.editArrayItem(formConfig, item, itemIndex)}>
													<i className="fad fa-pencil" />
												</div>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			default:
				return <div className="form-container">IDK what goes here</div>;
		}
	}

	trackConfigChange(fieldConfig) {
		const configCopy = _.cloneDeep(fieldConfig);
		const originalValue = _.cloneDeep(originalConfigService.getConfigValue(configCopy.configKey));
		const newValue = _.cloneDeep(_.get(this.data.config, configCopy.configKey));
		const formatPending = {
			configKey: fieldConfig.configKey,
			originalValue: originalValue,
			newValue: newValue || false,
			fieldConfig: fieldConfig
		};
		if (!expressStore.pendingConfigs) {
			expressStore.pendingConfigs = [];
		}
		const currentPending = _.cloneDeep(expressStore.pendingConfigs);
		const currentIndex = currentPending.map((x) => x.configKey).indexOf(fieldConfig.configKey);
		if (currentIndex > -1) {
			if (originalValue === newValue || (!originalValue && !newValue) || _.isEqual(originalValue, newValue)) {
				const removeMatchingItem = currentPending
					.filter((x, i) => {
						const isMatch = i === currentIndex;
						return !isMatch;
					})
					.filter((x) => {
						//any changes that depended on this should also be removed
						const dependencyMet = this.fieldDependencyCheck(x.fieldConfig);
						return dependencyMet;
					});
				expressStore.pendingConfigs = _.cloneDeep(removeMatchingItem);
			} else {
				currentPending[currentIndex] = formatPending;
				expressStore.pendingConfigs = _.cloneDeep(currentPending);
			}
		} else {
			currentPending.push(formatPending);
			expressStore.pendingConfigs = _.cloneDeep(currentPending);
		}
	}

	deleteArrayItem(formConfig, item, itemIndex) {
		this.$f7.dialog.confirm('Are you sure you want to delete this item?', 'Delete Item', () => {
			const currentItems = _.cloneDeep(_.get(this.data.config, formConfig.configKey) || []);
			if (Array.isArray(currentItems)) {
				const newItems = currentItems.filter((x, i) => i !== itemIndex);
				_.set(this.data.config, formConfig.configKey, newItems);
			} else {
				const newObject = _.cloneDeep(currentItems);
				const getDeletedObjectKey = Object.keys(currentItems)[itemIndex];
				delete newObject[getDeletedObjectKey];
				_.set(this.data.config, formConfig.configKey, newObject);
			}
			this.trackConfigChange(formConfig);
		});
	}

	editArrayItem(formConfig, item, itemIndex) {
		this.addItem(formConfig, item, itemIndex);
	}

	getConfigEditor() {
		const editorConfigs = this.data.editorConfigs;
		const currentPage = this.getActiveTab();
		const editableConfigs = _.cloneDeep(editorConfigs[currentPage] || {});
		const configForms = editableConfigs.items || [];
		const emptyConfigEditor = (
			<div key={`empty-group-item-${currentPage}`} className="empty-editor group-details-card">
				<div className="card-header">
					<div>
						<i className="fad fa-exclamation-triangle" />
					</div>
					<div>No Configurations Available for this Page </div>
				</div>
			</div>
		);
		const pageForms = configForms
			.filter((x) => !!this.getFormBody(x.id))
			.map((form) => {
				const isOpen = this.data.openForms.indexOf(form.id) > -1;
				const formBody = this.getFormBody(form.id);
				if (!formBody) {
					return <div key={`empty-group-item-${form.id}`} />;
				}
				return (
					<div className="group-details-card animated fadeInUp" key={`config-editor-form-${form.id}`}>
						<div className="card-header" data-form={form.id} onClick={this.showForm}>
							{form.label}
							{isOpen && <i className="fad fa-chevron-up" />}
							{!isOpen && <i className="fad fa-chevron-down" />}
						</div>
						{isOpen && <div>{formBody}</div>}
					</div>
				);
			});
		return pageForms.length > 0 ? pageForms : emptyConfigEditor;
	}

	addItem(formConfig, item, itemIndex) {
		const newConfig = _.cloneDeep(formConfig);
		newConfig.id = 'addItemConfig';
		newConfig.type = 'form';
		const itemConfigKeys = Object.keys(newConfig.itemConfig);
		newConfig.items = {};
		const currentValue = _.get(this.data.config, newConfig.configKey);
		if (newConfig.configType === 'array') {
			let nextIndex = currentValue ? currentValue.length : 0;
			const hasIndex = !isNaN(itemIndex);
			if (hasIndex) {
				nextIndex = itemIndex;
			}
			itemConfigKeys.map((itemKey) => {
				const itemData = newConfig.itemConfig[itemKey];
				const itemConfigKey = `${newConfig.configKey}.${nextIndex}.${itemData.configKey}`;
				if (!hasIndex) {
					_.set(this.data.config, itemConfigKey, itemData.default || '');
				}
				const dependency = itemData.formDependency
					? {
							key: `${newConfig.configKey}.${nextIndex}.${itemData.formDependency.key}`,
							method: itemData.formDependency.method,
							value: itemData.formDependency.value
					  }
					: itemData.dependency;
				newConfig.items[itemKey] = {
					type: itemData.type,
					label: itemData.label,
					default: itemData.default,
					listItems: itemData.listItems,
					configKey: itemConfigKey,
					dependency: dependency
				};
			});
		} else if (newConfig.configType === 'object') {
			let nextKey = currentValue ? Object.keys(currentValue).length : 1;
			const hasIndex = !isNaN(itemIndex);
			if (hasIndex) {
				nextKey = itemIndex;
			}
			let thisItemKey = `custom${nextKey}`;
			if (hasIndex) {
				thisItemKey = Object.keys(currentValue)[itemIndex];
			}
			itemConfigKeys.map((itemKey) => {
				const itemData = newConfig.itemConfig[itemKey];
				const itemConfigKey = `${newConfig.configKey}.${thisItemKey}.${itemData.configKey}`;
				if (!hasIndex) {
					_.set(this.data.config, itemConfigKey, itemData.default || '');
				}
				const dependency = itemData.formDependency
					? {
							key: `${newConfig.configKey}.${thisItemKey}.${itemData.formDependency.key}`,
							method: itemData.formDependency.method,
							value: itemData.formDependency.value
					  }
					: itemData.dependency;
				newConfig.items[itemKey] = {
					type: itemData.type,
					label: itemData.label,
					default: itemData.default,
					listItems: itemData.listItems,
					configKey: itemConfigKey,
					dependency: dependency
				};
			});
		}
		this.trackConfigChange(newConfig);
		this.data.popupForm = _.cloneDeep(newConfig);
	}

	closePopupForm() {
		this.data.popupForm = null;
	}

	async initSave() {
		this.$f7.dialog.confirm(this.data.disclaimerText.replace('* ', ''), 'Please Confirm', () => {
			this.saveConfigChanges();
		});
	}

	async saveConfigChanges() {
		this.$f7.dialog.preloader();
		const newConfig = _.cloneDeep(this.data.config);
		const pendingChanges = _.cloneDeep(expressStore.pendingConfigs);
		const formData = new FormData();
		for (let i = 0; i < pendingChanges.length; i++) {
			const change = pendingChanges[i];
			const type = _.get(change, 'fieldConfig.type');
			if (type === 'fileDrop' && !!change.newValue) {
				const blob = await fetch(change.newValue).then((r) => r.blob());
				formData.append('images[]', blob, change.configKey);
			}
		}

		formData.append('newConfig', JSON.stringify(newConfig));
		formData.append('changes', JSON.stringify(pendingChanges));
		const response = await ExpressAPI.saveConfig(formData);
		if (response) {
			this.data.openForms = [];
			expressStore.pendingConfigs = null;
			await originalConfigService.resetConfig();
			await originalConfigService.initConfig();
			const config = _.cloneDeep(originalConfigService.getConfiguration());
			this.setInitialConfig(config);
			this.$f7.dialog.close();
		} else {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('There was an error saving your configuration, please try again later');
		}
	}

	async forceTabChange(evt) {
		const tabClicked = evt.currentTarget.getAttribute('data-tab');
		if (appStore.openProviderProfile) {
			providerStore.openProviderLoaded = false;
			appStore.openProviderProfile = false;
			providerStore.openProvider = {};
			providerStore.openProviderBDData = {};
			providerStore.profileEntry = null;
		}
		if (tabClicked === 'providerProfile') {
			appStore.openProviderProfile = true;
			const providers = await mockData.getCareTeam();
			ProviderService.openProviderProfile(providers[0], 'findCare');
		} else {
			appStore.activeTab = tabClicked;
		}
	}

	getActiveTab() {
		let currentTab = _.cloneDeep(appStore.activeTab);
		if (appStore.openProviderProfile) {
			currentTab = 'providerProfile';
		}
		return currentTab;
	}

	showChangesNotification(differences) {
		// Closes when the differences are 0 (will happen on a savw of changes)
		if (differences.length < 1 && this.notification.current) this.notification.current.close();

		if (differences.length < 1) return null;

		// Create toast
		if (!this.notification.current) {
			this.notification.current = this.$f7.toast.create({
				text: "Don't forget to save!",
				horizontalPosition: 'right',
				// * The hard coded style for color is the same as $yellow. However, the notification is out of the configeditor's html tree.
				icon: `<i class="fa fa-exclamation large-icon-toast" style="color:#daaf12" ></i>`
			});
		}
		// Open it
		this.notification.current.open();
	}

	removeNotificationRef() {
		if (this.notification.current) {
			// on component unmount, we remove ref.
			this.notification.current.close();
			this.notification.current.destroy();
		}
	}

	render() {
		const differences = Object.values(_.cloneDeep(expressStore.pendingConfigs || {}));
		const configurablePages = this.data.editorConfigs ? Object.keys(this.data.editorConfigs) : [];
		const activeTab = this.getActiveTab();
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		return (
			<div className="app-config-container">
				<div className="demo-container">
					<DemoAppShell
						handleDemoRender={this.demoAppUpdate}
						setInitialConfig={this.setInitialConfig}
						config={_.cloneDeep(this.data.config)}
						reload={this.data.demoStatus === 'loading'}
						type={this.props.type}
						demoConfig={this.data.demoConfigs}
					/>
				</div>
				<div className="config-editor-container">
					{canEdit && (
						<>
							<div className="config-review-btn">
								{!differences || (differences.length < 1 && <div>{`Manage ${_.startCase(appStore.demoMode)} Configurations`}</div>)}
								{differences && differences.length > 0 && (
									<Button fill large onClick={this.initSave}>
										{`Save ${_.startCase(appStore.demoMode)} Config Changes`}
									</Button>
								)}
							</div>
							<div className="config-disclaimer">{this.data.disclaimerText}</div>
							<div className="config-editor-forms  y-scroll">
								<div className="config-editor-pages tabs">
									{configurablePages.map((page) => {
										const tabData = _.get(this.data.editorConfigs, `${page}.tabLabel`);
										const label = tabData ? tabData : page;
										return (
											<div
												key={`page-tab-${page}`}
												data-tab={page}
												className={`tab-item ${activeTab === page ? 'active' : ''}`}
												onClick={this.forceTabChange}
											>
												{label}
											</div>
										);
									})}
								</div>
								{this.getConfigEditor()}
								{this.showChangesNotification(differences)}
							</div>
						</>
					)}
				</div>
				<Popup className="express-form-popup" opened={!!this.data.popupForm} onPopupClosed={this.closePopupForm}>
					<div className="popup-header">
						<div className="title grow-1">{_.get(this.data, 'popupForm.label')}</div>
						<Link popupClose>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
					<div className="main-content y-scroll">
						{this.data.popupForm && <FormBuilder fields={this.getPopupFormData()} onChange={this.onConfigUpdate} />}
					</div>
					<div className="footer">
						<div className="btn-ctn">
							<Button fill large className="round-btn" onClick={this.closePopupForm}>
								Close
							</Button>
						</div>
					</div>
				</Popup>
			</div>
		);
	}
}
