import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import { observer, observable } from '../../../utils/State';
import StorageService from '../../../services/StorageService';
import LanguageSelection from '../../../components/language-selection/LanguageSelection';
import AnalyticsService from '../../../services/AnalyticsService';
import GradiantStack from '../../../components/gradiant-stack/GradiantStack';
import ChooseSVG from '../../../assets/choose.svg';
import './landing-page.scss';
import appStore from '@/stores/AppStore';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
export default class LandingPage extends Component {
	constructor(props) {
		super();
		this.data = observable({clickcounter: 0});
		this.text = getCmpText('LandingPage', {
			title: 'Which best describes you',
			optionOneTitle: 'My Insurance Includes CareValet',
			optionOneText: 'If your health insurance includes CareValet you should choose this option.',
			optionTwoTitle: 'I want to use CareValet myself',
			optionTwoText: 'I want to see how CareValet can help manage my healthcare experience.'
		});
		autoBind(this);
	}

	async onB2BClick() {
		analytics.trackWithData('App Landing Page', { type: 'b2b' });
		await storageService.setValue('app', 'signInType', 'employer-signin');
		if (this.props.onNext) {
			this.props.onNext('employer-signin');
		}
	}

	async onConsumerClick() {
		analytics.trackWithData('App Landing Page', { type: 'consumer' });
		await storageService.setValue('app', 'signInType', 'consumer-signin');
		if (this.props.onNext) {
			this.props.onNext('consumer-signin');
		}
	}

	render() {
		return (
			<div className="site-visitor-type">
				<GradiantStack stack="1" consumer animate={true} id="app-landing" />
				<div className="landing-page y-scroll">
					<div
						className="logo-ctn vbox vcenter hcenter"
						onClick={() => {
							if (!appStore.isMobile) {
								this.data.clickcounter++;
								if(this.data.clickcounter >= 5) {
									this.data.clickcounter = 0;
									this.$f7.dialog.alert('You want to leave CareValet & go to Express? Hit ESC to cancel', 'Are you sure?', () => {
										appStore.isExpressPortal = true;
										this.$f7.views.main.router.navigate('/express', { clearPreviousHistory: true, reloadAll: true });
									});
								}
							}
						}}
					>
						<i className="top-icon icon-logo animated flip" />
					</div>
					<div className="form-ctn animated fadeInUp">
						<img src={ChooseSVG} className="choose" />
						<div className="title">{this.text.title}</div>
						<div className="option hbox vcenter neucard" onClick={this.onB2BClick}>
							<i className="fad fa-image"></i>
							<div className="ctnt">
								<div className="label">{this.text.optionOneTitle}</div>
								<div>{this.text.optionOneText}</div>
							</div>
							<i className="fal fa-chevron-right"></i>
						</div>
						<div className="option hbox vcenter neucard" onClick={this.onConsumerClick}>
							<i className="fad fa-image"></i>
							<div className="ctnt">
								<div className="label">{this.text.optionTwoTitle}</div>
								<div>{this.text.optionTwoText}</div>
							</div>
							<i className="fal fa-chevron-right"></i>
						</div>
						<LanguageSelection />
					</div>
				</div>
			</div>
		);
	}
}
