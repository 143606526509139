import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { Popup, Link, Block, Button } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import Vimeo from '@u-wave/react-vimeo';
import ExternalService from '@/services/ExternalService';
import AnalyticsService from '@/services/AnalyticsService';
import ConfigService from '@/services/ConfigService';
import './balance-bill-popup.scss';
const analytics = new AnalyticsService();
@observer
export default class BalanceBillPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.text = getCmpText('BalanceBillPopup', {
			title: 'Have you received a BALANCE BILL?',
			subtitle: `Balance Bill: a statement from a hospital or facility claiming a a higher balance than what the original Explination of Benefits, (EOB) says was owed.`,
			close: 'Close',
			contactUs: 'Contact Us',
			directionsHeader: 'If you have received a balance bill:',
			stepOneHeader: 'Contact Us Immediately',
			stepOneInfo:
				'By Federal Law you have 60 days from the date of the initial balance bill to dispute the initial balace bill to dispute erroneous charges. Disputes filed after 60 days are not protected under Fair Credit Billing Act (FCBA).',
			stepTwoHeader: 'Pay Your Portion',
			stepTwoInfo:
				'Make arrangements to pay your portion on time, including any deductibles, copays, coinsurance or non-covered services.',
			stepThreeHeader: 'Be Responsive',
			stepThreeInfo: 'Respond quickly to directions via phone or email.',
			reminderText: 'Remember communication within 60 days is key!'
		});
		autoBind(this);
	}
	//*===START CONTROLS===============================================================

	componentDidMount() {
		const config = ConfigService.getConfigValue('balanceBillPopup');
		if (config) {
			//got balance bill config
		}
	}

	handleOnClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	async onPopupOpen() {
		analytics.track('balance_bill_screen_open');
	}

	//*===END CONTROLS===============================================================

	callService() {
		const config = ConfigService.getConfigValue('balanceBillPopup') || {};
		const number = config.contactNumber;
		if (number) {
			analytics.track('Balancing Billing contact us call');
			ExternalService.handleExternalRequest('call', number);
		} else {
			analytics.track('Config error:  Balance billing contact number missing');
		}
	}

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`balance-bill-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpen={this.onPopupOpen}
				onPopupClosed={this.handleOnClose}
			>
				<div className="content-ctn y-scroll" key="balance-bill-ctn">
					<div className="popup-header">
						<div></div>
						<Link popupClose>{this.text.close}</Link>
					</div>
					<div className="title grow-1">{this.text.title}</div>
					<div className="subtitle grow-1">{`${this.text.subtitle}`}</div>
					<div className="page-cnt ">
						<Button fill large className="round-btn blue hbox vcenter hcenter" onClick={this.callService}>
							<i className="fad fa-user-headset"></i>
							{this.text.contactUs}
						</Button>
						<Vimeo className={`videmo`} video={`389390153`} />
						<Block className="steps">
							<div className="statement">{this.text.directionsHeader}</div>
							<div className="step hbox vcenter">
								<div className="number hbox vcenter hcenter">1</div>
								<div>
									<div className="step-title">{this.text.stepOneHeader}</div>
									<p>{this.text.stepOneInfo}</p>
								</div>
							</div>
							<Button fill large className="round-btn blue hbox vcenter hcenter" onClick={this.callService}>
								<i className="fad fa-user-headset"></i>
								{this.text.contactUs}
							</Button>
							<div className="step hbox vcenter">
								<div className="number hbox vcenter hcenter">2</div>
								<div>
									<div className="step-title">{this.text.stepTwoHeader}</div>
									<p>{this.text.stepTwoInfo}</p>
								</div>
							</div>
							<div className="step hbox vcenter">
								<div className="number hbox vcenter hcenter">3</div>
								<div>
									<div className="step-title">{this.text.stepThreeHeader}</div>
									<p>{this.text.stepThreeInfo}</p>
								</div>
							</div>
						</Block>
						<Block strong className="warning hbox vcenter hcenter">
							<i className="fad fa-alarm-exclamation"></i>
							<strong>{this.text.reminderText}</strong>
						</Block>
					</div>
				</div>
			</Popup>
		);
	}
}
