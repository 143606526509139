import React, { Component } from 'react';
import { Button, Toggle } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import Table from '@/components/_EXPRESS/table/Table';
import './feature-flags.scss';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';

@observer
export default class FeatureFlagsAdmin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			flags: [],
			componentLoading: true,
			featureFlagRowLoading: false,
			showFeatureFlagEditor: false,
			currentlyEditingFlag: false,
			featureFlag: {
				flagName: '',
				status: false
			}
		});
		autoBind(this);
	}

	async componentDidMount() {
		const flags = await ExpressAPI.getFeatureFlags();
		this.data.componentLoading = false;
		if (flags?.length > 0) this.data.flags = flags;
	}

	async updateFeatureFlag() {
		this.data.featureFlagRowLoading = true;
		const { currentlyEditingFlag } = this.data;
		const { status, flagName } = this.data.featureFlag;
		const flags = await ExpressAPI.toggleFeatureFlag(currentlyEditingFlag, {
			status,
			flagName
		});
		if (this.data.currentlyEditingFlag) this.data.currentlyEditingFlag = false;
		if (this.data.showFeatureFlagEditor)
			this.data.showFeatureFlagEditor = false;
		this.data.featureFlagRowLoading = false;
		this.data.flags = flags;
	}

	async toggleFeatureFlag(id, status, flagName) {
		this.data.featureFlagRowLoading = true;
		const flags = await ExpressAPI.toggleFeatureFlag(id, {
			status,
			flagName
		});

		this.data.featureFlagRowLoading = false;
		this.data.flags = flags;
	}

	async deleteField(id) {
		this.data.featureFlagRowLoading = true;
		const flags = await ExpressAPI.deleteFeatureFlag(id);

		this.data.featureFlagRowLoading = false;
		this.data.flags = flags;
	}

	async saveFeatureFlag() {
		const dataToSave = { ...this.data.featureFlag };
		dataToSave.flagName = dataToSave.flagName.toUpperCase();

		const flags = await ExpressAPI.addFeatureFlag(dataToSave);
		if (this.data.showFeatureFlagEditor)
			this.data.showFeatureFlagEditor = false;
		if (this.data.featureFlagRowLoading)
			this.data.featureFlagRowLoading = false;

		this.data.flags = flags;
	}

	editFeatureFlag(id) {
		const flag = this.data.flags.find((f) => f._id === id);
		this.data.featureFlag = {
			flagName: flag.flag_name,
			status: flag.status
		};
		this.data.showFeatureFlagEditor = true;
		this.data.currentlyEditingFlag = id;
	}

	resetFeatureFlagForm() {
		this.data.featureFlag = {
			flagName: '',
			status: false
		};
		if (this.data.showFeatureFlagEditor)
			this.data.showFeatureFlagEditor = false;
		if (this.data.featureFlagRowLoading)
			this.data.featureFlagRowLoading = false;
	}

	render() {
		const {
			flags,
			componentLoading,
			featureFlagRowLoading,
			showFeatureFlagEditor,
			currentlyEditingFlag,
			featureFlag
		} = this.data;
		return (
			<>
				<div className="admin-feature-flags-container">
					<div className="admin-feature-flags-header-actions">
						<h2 className="grow-1">Feature Flags</h2>
						<div className="btn-ctn admin-feature-flag-add-button">
							<Button
								fill
								small
								disabled={featureFlagRowLoading}
								className="list-btn ios"
								onClick={() => {
									this.data.showFeatureFlagEditor = true;
								}}
							>
								ADD FEATURE FLAG
							</Button>
						</div>
					</div>

					{flags.length === 0 && !componentLoading && (
						<div className="empty-message vbox vcenter hcenter">
							<div className="icon-box hbox vcenter hcenter">
								<i className="fad fa-users-class" />
							</div>
							<h3>There are no feature flags to show.</h3>
						</div>
					)}
					{flags.length > 0 && (
						<Table
							id="feature-flags-admin-table"
							headers={[
								{ text: 'Edit', classList: 'small' },
								{ text: 'Flag Name', classList: 'ellipse' },
								{ text: 'Status', classList: 'small' },
								{ text: 'Delete', classList: 'small' }
							]}
							rows={flags.map((flag, index) => {
								return {
									id: flag._id || flag.flag_name,
									items: [
										{
											id: `${flag._id}-${index}`,
											text: `${flag.flag_name}-${index}` || 'Unknown',
											classList: `xt-small`,
											component: (
												<div
													className="hbox vcenter"
													key={`express-feature-flag-edit-${flag._id}`}
													onClick={() => this.editFeatureFlag(flag._id)}
												>
													<i className="fad fa-pencil" />
												</div>
											)
										},
										{
											id: `${flag._id}-${index}`,
											text: flag.flag_name || 'Unknown',
											classList: `capitalize ellipse`
										},
										{
											id: `${flag._id}-${index}`,
											text: 'status',
											component: (
												<div
													className="express-form-toggle hbox vcenter"
													key={`express-form-toggle-container-${flag._id}`}
												>
													<div className="label">Status</div>
													<Toggle
														key={`express-form-toggle-${flag._id}`}
														disabled={featureFlagRowLoading}
														checked={!!flag.status}
														onChange={(e) => {
															this.toggleFeatureFlag(
																flag._id,
																e.target.checked,
																flag.flag_name
															);
														}}
													/>
												</div>
											),
											classList: 'small'
										},
										{
											id: `${flag._id}-${index}`,
											text: 'delete',
											component: (
												<i
													className="fad fa-trash-alt admin-trash-icon-override"
													key={`express-form-toggle-${flag._id}`}
													title="Delete Feature Flag"
													onClick={() => {
														return this.deleteField(flag._id);
													}}
												></i>
											),
											classList: 'small'
										}
									]
								};
							})}
						/>
					)}

					<FormPopup
						opened={!!showFeatureFlagEditor}
						title="Feature Flag Editor"
						buttonText={'Save'}
						formData={featureFlag}
						formConfig={{
							flagName: {
								label: 'Field Name',
								placeholder: 'Flag Name',
								type: 'text'
							},
							status: {
								label: 'Flag Status',
								type: 'toggle'
							}
						}}
						onSubmit={
							currentlyEditingFlag
								? this.updateFeatureFlag
								: this.saveFeatureFlag
						}
						onClose={this.resetFeatureFlagForm}
					/>
				</div>
			</>
		);
	}
}
