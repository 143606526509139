import _ from 'lodash';
import appStore from '@/stores/AppStore';
import userStore from '@/stores/UserStore';
import StorageService from './StorageService';
import CloudAPI from './CloudAPI';
import AnalyticsService from './AnalyticsService';

const analytics = new AnalyticsService();
const storageService = new StorageService();

class LocationService {
	async handleBrowserLocation(tryDeviceOnly) {
		try {
			const browserLocation = await this.getLocationFromBrowser();
			const lat = _.get(browserLocation, 'coords.latitude');
			const lng = _.get(browserLocation, 'coords.longitude');
			const geoSearchRes = await CloudAPI.findByGeoLocation(lat, lng);
			const browserBestLocation = this.getBestGeoMatch(geoSearchRes, browserLocation);
			await this.saveLocation(_.cloneDeep(browserBestLocation));
			if (tryDeviceOnly) {
				analytics.trackWithData('Manual Location Change', { type: 'current', status: 'success', source: 'navigator' });
			} else {
				analytics.trackWithData('Set Location', { type: 'navigator', status: 'success' });
			}
			userStore.locationServiceEnabled = !!browserLocation;
			return browserBestLocation;
		} catch (err) {
			userStore.locationServiceEnabled = false;
			if (!tryDeviceOnly) {
				analytics.trackWithData('Set Location', { type: 'navigator', status: 'error' });
				const savedLocation = await this.setLocation('saved');
				return savedLocation;
			} else {
				analytics.trackWithData('Manual Location Change', { type: 'current', status: 'error', source: 'navigator' });
				throw new Error(err);
			}
		}
	}

	async handleSavedLocation() {
		try {
			const savedLocation = await storageService.getValue('app', 'location');
			const savedLocType = typeof savedLocation;
			if (savedLocType !== 'object' || !savedLocation) {
				analytics.trackWithData('Set Location', { type: 'saved', status: 'error', savedLocation: savedLocation });
				this.setLocation('member');
			} else {
				await this.saveLocation(_.cloneDeep(savedLocation));
				analytics.trackWithData('Set Location', { type: 'saved', status: 'success', savedLocation: savedLocation });
			}
		} catch (err) {
			analytics.trackWithData('Set Location', { type: 'saved', status: 'error' });
			this.setLocation('member');
		}
	}

	async handleMemberZipLocation() {
		try {
			const memberZip = _.get(userStore, 'userData.member.address.zipcode');
			const zipData = await this.zipSearch(memberZip);
			zipData.zip = memberZip;
			await this.saveLocation(_.cloneDeep(zipData));
			analytics.trackWithData('Set Location', { type: 'member zip', status: 'success' });
		} catch (err) {
			analytics.trackWithData('Set Location', { type: 'member zip', status: 'error' });
			this.setLocation('prompt');
		}
	}

	async handleBgGeoLocationChange(location) {
		if (!location.sample && location.coords) {
			const lat = _.get(location, 'coords.latitude');
			const lng = _.get(location, 'coords.longitude');
			const locationInfo = await CloudAPI.findByGeoLocation(lat, lng);
			const locationTransformed = this.getBestGeoMatch(locationInfo, location);
			await this.saveLocation(locationTransformed, true);
		}
	}

	async handleBgGeoLocationChangeError(errCode) {
		const bgGeo = window.BackgroundGeolocation;
		userStore.manualLocation = true;
		userStore.locationServiceEnabled = false;
		bgGeo.stop();
		this.setLocation('browser');
	}

	async handleCordovaGeoLocation(tryDeviceOnly) {
		try {
			const bgGeo = window.BackgroundGeolocation;
			const cordovaGeoStatus = await this.getCordovaBgGeoStatus();
			if (!bgGeo) {
				const browserLoc = await this.setLocation('browser', tryDeviceOnly);
				return browserLoc;
			}
			if (!tryDeviceOnly) {
				bgGeo.on(
					'location',
					(location) => this.handleBgGeoLocationChange(location),
					(errCode) => this.handleBgGeoLocationChangeError(errCode)
				);
			}
			const position = await window.BackgroundGeolocation.getCurrentPosition();
			if (tryDeviceOnly) {
				analytics.trackWithData('Manual Location Change', { type: 'current', status: 'success', source: 'bgGeo' });
				await this.handleBgGeoLocationChange(position);
			} else {
				analytics.trackWithData('Set Location', { type: 'bgGeo', status: 'success' });
			}
		} catch (err) {
			if (tryDeviceOnly) {
				analytics.trackWithData('Manual Location Change', { type: 'current', status: 'error', source: 'bgGeo' });
			} else {
				analytics.trackWithData('Set Location', { type: 'bgGeo', status: 'error' });
			}
			console.log(`LOCATION: ERROR HIT HERE: ${JSON.stringify(err)}`);
			if (err === 1) {
				const savedLoc = await this.setLocation('saved', tryDeviceOnly);
				return savedLoc;
			} else {
				const browserLoc = await this.setLocation('browser', tryDeviceOnly);
				return browserLoc;
			}
		}
	}

	async setLocation(provider, tryDeviceOnly) {
		switch (provider) {
			case 'bgGeo':
				return await this.handleCordovaGeoLocation(tryDeviceOnly);
			case 'browser':
				return await this.handleBrowserLocation(tryDeviceOnly);
			case 'saved':
				await this.handleSavedLocation();
				break;
			case 'member':
				await this.handleMemberZipLocation();
				break;
			default:
				appStore.setLocationOpen = true;
				break;
		}
	}

	async getCordovaBgGeoStatus() {
		try {
			const currentStatus = await window.BackgroundGeolocation.getProviderState();
			return currentStatus && currentStatus.status !== 2 ? currentStatus : false;
		} catch (err) {
			return false;
		}
	}

	async getLocationFromBrowser() {
		return new Promise((resolve, reject) => {
			// Browser Geolocation API
			navigator.geolocation.getCurrentPosition(
				(position) => resolve(position),
				(err) => reject(err),
				{ timeout: 5000 } //required for android permission error to fire
			);
		});
	}

	getBestGeoMatch(results, position) {
		const matchesNotRoutes = results.filter((x) => x.types.indexOf('route') < 0);
		const localityMatch = matchesNotRoutes.find((match) => match.types.indexOf('locality') > -1);
		const postalCodeMatch = matchesNotRoutes.find((match) => match.types.indexOf('postal_code') > -1);
		const bestMatch = localityMatch || postalCodeMatch;
		if (!bestMatch) {
			throw new Error('no match found');
		}
		const formattedMatch = {
			name: bestMatch.formatted_address.split(',')[0],
			lat: _.get(position, 'coords.latitude', _.get(bestMatch, 'geometry.location.lat')),
			lon: _.get(position, 'coords.longitude', _.get(bestMatch, 'geometry.location.lng'))
		};
		return formattedMatch;
	}

	async saveLocation(locData, appOnly) {
		const formattedLocationData = _.cloneDeep(locData);
		if (!locData.zip) {
			const zipMatch = await CloudAPI.findZip(locData.lat, locData.lon);
			formattedLocationData.zip = _.get(zipMatch, 'zip');
		}
		try {
			appStore.currentLocation = _.cloneDeep(formattedLocationData);
			if (!appOnly) {
				await storageService.setValue('app', 'location', _.cloneDeep(formattedLocationData));
			}
		} catch (err) {
			console.log('error saving locatin data: ', err);
		}
	}

	async zipSearch(zip) {
		try {
			const resp = await CloudAPI.findGeoLocation(zip);
			const firstItem = resp.candidates[0];
			return firstItem;
		} catch (err) {
			return Promise.reject(err);
		}
	}
}

export default new LocationService();
