import React, { Component } from 'react';
import { observer } from '../utils/State';
import appStore from '../stores/AppStore';
import windowStore from '@/stores/WindowStore';
import { getCmpText } from '../utils/Localization';
import { App, View, Preloader } from 'framework7-react';
import AuthService from '../services/AuthService';
import cordovaApp from '../js/cordova-app';
import routes from '../js/routes';
import userStore from '../stores/UserStore';
import CloudAPI from '../services/CloudAPI';
import UserService from '../services/UserService';
import StorageService from '../services/StorageService';
import { autoBind } from '../utils/GeneralUtils';
import AppQuestionnaire from './app-questionnaire/AppQuestionnaire';
import PwaPrompt from './pwa-prompt/PwaPrompt';
import ExternalServicesMenu from './external-services-menu/ExternalServicesMenu';
import ExternalServiceMessage from './external-service-message/ExternalServiceMessage';
import AnalyticsService from '@/services/AnalyticsService';
import VideoPopup from './video-popup/VideoPopup';
import ExpressAuthService from '@/services/ExpressAuthService';

const storageService = new StorageService();
const analytics = new AnalyticsService();
@observer
export default class extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('App', {
			reauth: 'Authenticating User',
			unknownLoginError: 'Unable to Login, please try again.',
			updateRequired:
				'You have an outdated application.  Please visit the App Store and update the CareValet application to the latest version to continue.'
		});
		this.state = {
			// Framework7 Parameters
			f7params: {
				id: 'io.carevalet.app', // App bundle ID
				name: 'CareValet', // App name
				sortable: {
					moveElements: false //we handle item display and sorting in react - without this it conflicts
				},
				theme: appStore.isExpressPortal || appStore.isProviderPortal ? 'md' : 'auto', // Automatic theme detection
				// App root data
				data: function () {
					return {
						test: 'test'
					};
				},
				// App routes
				routes: routes,
				version: process.env.VERSION,
				// Input settings
				input: {
					scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
					scrollIntoViewCentered: this.$device.cordova && !this.$device.electron
				},
				touch: {
					fastClicks: true
				},
				// Cordova Statusbar settings
				statusbar: {
					overlay: (this.$device.cordova && this.$device.ios) || 'auto',
					iosOverlaysWebView: true,
					androidOverlaysWebView: false,
					iosBackgroundColor: '#F5F5F5',
					iosTextColor: 'black'
				}
			}
		};
		autoBind(this);
		//TODO move this to config service and setup all that there
		storageService.createStore('app');
	}

	componentDidMount() {
		this.$f7ready(async (f7) => {
			if (appStore.isPWA()) {
				const $ = f7.$;
				$('html').addClass('device-pwa');
			}
			let size = f7.getSize();
			windowStore.innerWidth = size.width;
			windowStore.innerHeight = size.height;
			f7.$(window).resize((rec) => {
				// @ts-ignore //getSize works
				size = f7.getSize();
				if (size.width < 1000) {
					appStore.isMobile = true;
				} else {
					appStore.isMobile = false;
				}

				windowStore.innerWidth = size.width;
				windowStore.innerHeight = size.height;
			});
			// Init cordova APIs (see cordova-app.js)
			let beVersion = '';
			try {
				beVersion = await CloudAPI.getVersion();
			} catch (err) {
				console.log('unable to get backend version');
			}
			userStore.backendVersion = beVersion;
			userStore.frontendVersion = process.env.VERSION;
			await UserService.handleClientTimezone();
			await UserService.handleLanguagePreferences(f7);
			await UserService.handleUIPreference();

			if (f7.device.cordova) {
				cordovaApp.init(f7);
			}
			appStore.initalized = true;
			//@ts-ignore
			window.globalRouter = f7.views.appShell.router;
			//@ts-ignore
			window.f7 = f7;
			if (appStore.apiIssues) {
				analytics.track('api_issue');
				f7.dialog.alert(`We're currently down for maintenance, please try again later. Thank you for your understanding. `);
			}
			if (!appStore.isExpressPortal) {
				AuthService.init(f7);
			} else if (appStore.isExpressPortal) {
				ExpressAuthService.init(f7);
			}
		});
	}

	closeQuestionnaire() {
		appStore.showQuestionnaire = false;
	}

	render() {
		return (
			//@ts-ignore // does not like the touch.fastClick=true param
			<App params={this.state.f7params} className={appStore.isExpressPortal ? 'express-app' : 'carevalet-empcon'}>
				{/* Status bar overlay for fullscreen mode*/}
				<div className="statusbar"></div>
				{!appStore.forceAppUpdate && (
					<View main className="safe-areas" name="appShell" pushState={true} reloadPages={userStore.isLoggedIn}>
						{appStore.showReauthLoader && (
							<div className="full-page-loader">
								<div className="preloader-container elevation-12">
									<i className="fad fa-user-lock"></i>
									<Preloader size={32} color="blue"></Preloader>
									<h2>{this.text.reauth}</h2>
								</div>
							</div>
						)}
						{!appStore.showReauthLoader && appStore.showQuestionnaire && <AppQuestionnaire close={this.closeQuestionnaire} />}
					</View>
				)}
				{/* PWA prompt Needs to be outside the main view because if that dies/reloads before the popup is removed from the DOM, f7 flips out and loads 2 */}
				<PwaPrompt />
				{appStore.forceAppUpdate && (
					<div className="update-container">
						<div className="update-body">
							<div className="update-icon">
								<i className="fad fa-wrench" />
							</div>
							<h2>{this.text.updateRequired}</h2>
						</div>
					</div>
				)}
				{appStore.externalServicesOpen && !appStore.isExpressPortal && (
					<ExternalServicesMenu
						onClose={() => {
							appStore.externalServicesOpen = false;
						}}
					/>
				)}
				<ExternalServiceMessage />
				<VideoPopup
					opened={appStore.openVideoId !== null}
					onClose={() => {
						appStore.openVideoId = null;
					}}
				/>
			</App>
		);
	}
}
