import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { Popup, Link, Preloader } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import './shop-popup.scss';
import UserBillingService from '@/services/UserBillingService';
import Shop from '../shop/Shop';
import CloudAPI from '@/services/CloudAPI';
@observer
export default class ShopPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ checkoutOpen: false, checkoutItem: null, products: [], productsLoading: false });
		this.text = getCmpText('Shop', {
			title: 'Store'
		});
		autoBind(this);
	}

	closePrompt() {
		//Billing before close logic here
		if (this.props.onCloseShop) {
			this.props.onCloseShop();
		}
	}

	handlePaymentComplete() {
		this.closePrompt();
	}
	async onPopupOpen() {
		this.data.productsLoading = true;
		CloudAPI.getProducts()
			.then((products) => {
				this.data.products = products.map((p) => {
					let newProduct = {};
					newProduct.id = p.product.id;
					newProduct.priceId = p.id;
					newProduct.featured = p.product.metadata.featured;
					newProduct.price = p.unit_amount / 100;
					newProduct.name = p.product.name;
					newProduct.description = p.product.description;
					newProduct.images = p.product.images;
					newProduct.isRecurring = p.type === 'recurring';
					let type = _.get(p, 'product.metadata.type', null);
					if(type) {
						newProduct.type = type;
						if(type === 'vpc-individual') {
							newProduct.cardFeature = '*Unlimited Telehealth Calls!'
						}
					}
					if (p.type === 'recurring') {
						newProduct.recurringData = p.recurring;
					}

					return newProduct;
				});
				this.data.productsLoading = false;
			})
			.catch((err) => {
				console.log(err);
			});
		await UserBillingService.retrievePaymentMethods();
	}

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`shop-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={true}
				closeOnEscape={true}
				onPopupOpen={this.onPopupOpen}
				onPopupClosed={this.closePrompt}
			>
				<div className="popup-header">
					<div className="title grow-1">{this.text.title}</div>
					<Link popupClose>
						<i className="fad fa-times-square" />
					</Link>
				</div>
				{this.data.productsLoading && <Preloader />}
				{!this.data.productsLoading && <Shop products={this.data.products} onPaymentComplete={this.handlePaymentComplete} />}
			</Popup>
		);
	}
}
