import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { format } from 'date-fns';
import {
	autoBind,
	formatPhoneNumber,
	showExpressError
} from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import Spreadsheet from 'react-spreadsheet';
import _ from 'lodash';
import './import-processor.scss';
import { Button, Preloader } from 'framework7-react';
import InvalidRecords from '../invalid-records/invalid-records';

@observer
export default class ImportProcessor extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			memberImport: null,
			layout: 'grid',
			activeMember: null,
			showReview: false,
			pages: {
				new: 1,
				updated: 1,
				missingGroup: 1,
				missingPlan: 1,
				termed: 1
			}
		});
		autoBind(this);
	}

	componentDidMount() {
		ExpressAPI.getImportById(this.props.importId)
			.then((res) => {
				this.data.memberImport = res;
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	toggleLayout() {
		this.data.layout = this.data.layout === 'grid' ? 'list' : 'grid';
	}

	onPublishFile() {
		this.$f7.dialog.confirm(
			'Importing this file cannot be undone and you cannot import another until this one completes processing.',
			'Are you sure?',
			() => {
				this.$f7.dialog.preloader();
				ExpressAPI.publishEligibilityFile(this.props.importId)
					.then(() => {
						setTimeout(() => {
							this.$f7.dialog.close();
							this.props.onClose();
						}, 100);
					})
					.catch((err) => {
						this.$f7.dialog.close();
						this.props.onClose();
						showExpressError(this.$f7, err);
					});
			}
		);
	}

	onDeleteFile() {
		this.$f7.dialog.confirm(
			'Deleting this file cannot be undone, but you can upload it again if needed.',
			'Are you sure?',
			() => {
				this.$f7.dialog.preloader();
				ExpressAPI.deleteFileImport(this.props.importId)
					.then(() => {
						setTimeout(() => {
							this.$f7.dialog.close();
							this.props.onClose();
						}, 100);
					})
					.catch((err) => {
						this.$f7.dialog.close();
						this.props.onClose();
						showExpressError(this.$f7, err);
					});
			}
		);
	}

	processValue(key, value) {
		if (
			['termDate', 'startDate', 'lastUpdated', 'dob', 'lastPublished'].indexOf(
				key
			) >= 0
		) {
			let date = format(new Date(value), 'MM/dd/yyyy');
			return date;
		}
		if (
			[
				'firstName',
				'lastName',
				'middleName',
				'lastUpdatedBy',
				'type',
				'status'
			].indexOf(key) >= 0 &&
			!!value
		) {
			return _.capitalize(value);
		}
		if (key === 'phone') {
			return formatPhoneNumber(value);
		}
		if (key === 'lastPublished' && !value) {
			return 'Not Published';
		}
		if (key === 'gender') {
			return {
				m: 'Male',
				f: 'Female'
			}[value];
		}
		if (key === 'relation') {
			return {
				self: 'Primary',
				spouse: 'Spouse',
				child: 'Child',
				other: 'Other'
			}[value];
		}
		if (key === 'insurerCode') {
			return value ? 'Redacted' : '-';
		}
		if (key === 'dependents') {
			return value ? value.length : '0';
		}
		return value;
	}

	buildMemberCard(member, type) {
		let updatedFields = null;
		if (member.member) {
			updatedFields = _.cloneDeep(member.updatedFields);
			member = _.cloneDeep(member.member);
		}
		const fieldMap = {
			relation: 'Type',
			memberId: 'Member ID',
			groupId: 'Group ID',
			healthPlan: 'Medical Plan ID',
			healthPlanDental: 'Dental Plan ID',
			healthPlanVision: 'Vision Plan ID',
			startDate: 'Start Date',
			termDate: 'Term Date'
		};

		return (
			<div
				className="member-card"
				key={member.hash}
				onClick={() => {
					this.openMember(member, updatedFields);
				}}
			>
				<div className={`member-card-header ellipse ${type}`}>
					{`${_.capitalize(member.firstName)} ${_.capitalize(member.lastName)}`}
				</div>
				<div className="field-ctn">
					{Object.keys(fieldMap).map((key) => {
						let value = this.processValue(key, _.get(member, key, ''));
						return (
							<div className="field" key={`${member.hash}${key}`}>
								<div className="label">{fieldMap[key]}</div>
								<div className="value ellipse" title={value}>
									{value && value.length !== 0 ? value : '-'}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	openMember(member, updatedFields) {
		this.data.activeMember = { member, updatedFields };
	}

	buildMemberRows(members, type) {
		const ListHeaderMap = {
			relation: 'Relation',
			firstName: 'First Name',
			lastName: 'Last Name',
			memberId: 'Member ID',
			groupId: 'Group ID',
			healthPlan: 'Healthplan ID',
			startDate: 'Start Date',
			termDate: 'Term Date'
		};
		let currentPage = this.data.pages[type];
		let toShow = _.cloneDeep(members).slice(
			(currentPage - 1) * 100,
			currentPage * 100
		);
		return toShow.map((rec) => {
			let row = [];
			let updatedFields;
			if (rec.member) {
				updatedFields = rec.updatedFields;
				rec = rec.member;
			}
			_.forEach(Object.keys(ListHeaderMap), (key) => {
				let value = this.processValue(key, rec[key]);
				row.push({
					value: value ? value : '-',
					readOnly: true,
					DataViewer: () => {
						if (key === 'memberId') {
							return (
								<div
									className={'valid-cell link'}
									onClick={() => {
										this.openMember(rec, updatedFields);
									}}
								>
									{value ? value : '-'}
								</div>
							);
						}
						return <div className={'valid-cell'}>{value ? value : '-'}</div>;
					}
				});
			});
			return row;
		});
	}

	buildTableControls(type, dataLength) {
		const PAGE_SIZE = 100;
		return dataLength >= PAGE_SIZE ? (
			<div
				className="table-controls hbox vcenter hcenter"
				key={`${type}-table-controls`}
			>
				{this.data.pages[type] > 1 && (
					<Button
						fill
						small
						onClick={() => {
							this.data.pages[type] = this.data.pages[type] - 1;
						}}
					>
						Back
					</Button>
				)}
				<div>{`${this.data.pages[type]} of ${Math.ceil(
					dataLength / PAGE_SIZE
				)} Pages`}</div>
				{this.data.pages[type] !== Math.ceil(dataLength / PAGE_SIZE) && (
					<Button
						fill
						small
						onClick={() => {
							this.data.pages[type] = this.data.pages[type] + 1;
						}}
					>
						Next
					</Button>
				)}
			</div>
		) : (
			''
		);
	}

	buildImportSheet() {
		let { memberImport } = this.data;
		if (!memberImport) {
			return (
				<div className="vbox vcenter hcenter">
					<Preloader color="blue" size="26"></Preloader>
				</div>
			);
		}
		const ListHeaderMap = {
			relation: 'Relation',
			firstName: 'First Name',
			lastName: 'Last Name',
			memberId: 'Member ID',
			groupId: 'Group ID',
			healthPlan: 'Medical Plan ID',
			healthPlanDental: 'Dental Plan ID',
			healthPlanVision: 'Vision Plan ID',
			startDate: 'Start Date',
			termDate: 'Term Date'
		};
		let layout = this.data.layout;
		let records = [];
		if (memberImport.invalid && memberImport.invalid.length > 0) {
			records.push(
				<>
					<div className="list-header missing" key="invalid-member-header">
						Invalid Members
					</div>
					<div className="results">
						<InvalidRecords records={memberImport.invalid} />
					</div>
				</>
			);
		}
		if (memberImport.missingGroup && memberImport.missingGroup.length > 0) {
			records.push(
				<div className="list-header missing" key="missing-group-header">
					Unknown Group ID
				</div>
			);
			if (layout === 'list') {
				records.push(
					<>
						<Spreadsheet
							key="missing-group-spreadsheet"
							columnLabels={Object.values(ListHeaderMap)}
							data={this.buildMemberRows(
								memberImport.missingGroup,
								'missingGroup'
							)}
						/>
						{this.buildTableControls(
							'missingGroup',
							memberImport.missingGroup.length
						)}
					</>
				);
			} else {
				_.forEach(memberImport.missingGroup, (m) => {
					records.push(this.buildMemberCard(m, 'nogroup'));
				});
			}
		}
		if (memberImport.missingPlan && memberImport.missingPlan.length > 0) {
			records.push(
				<div
					className="list-header hbox vcenter missing"
					key="missing-plan-header"
				>
					<div>Unknown Healthplan ID</div>
					{/* <p>Pleast ceate a corresponding Healthplan to the below IDs</p>
					<Button fill small className="list-btn ios blue">Create Plan</Button> */}
				</div>
			);
			if (layout === 'list') {
				records.push(
					<>
						<Spreadsheet
							key="missing-group-spreadsheet"
							columnLabels={Object.values(ListHeaderMap)}
							data={this.buildMemberRows(
								memberImport.missingPlan,
								'missingGroup'
							)}
						/>
						{this.buildTableControls(
							'missingPlan',
							memberImport.missingPlan.length
						)}
					</>
				);
			} else {
				_.forEach(memberImport.missingPlan, (m) => {
					records.push(this.buildMemberCard(m, 'noplan'));
				});
			}
		}
		if (memberImport.termedMembers && memberImport.termedMembers.length > 0) {
			records.push(
				<div className="list-header termed" key="termed-members-header">
					Terminated Members
				</div>
			);
			if (layout === 'list') {
				records.push(
					<>
						<Spreadsheet
							key="termed-members-sheet"
							columnLabels={Object.values(ListHeaderMap)}
							data={this.buildMemberRows(memberImport.termedMembers, 'termed')}
						/>
						{this.buildTableControls(
							'termed',
							memberImport.termedMembers.length
						)}
					</>
				);
			} else {
				_.forEach(memberImport.termedMembers, (m) => {
					records.push(this.buildMemberCard(m, 'termed'));
				});
			}
		}
		if (memberImport.updatedMembers && memberImport.updatedMembers.length > 0) {
			records.push(
				<div className="list-header updated" key="updated-members-header">
					Updated Members
				</div>
			);
			if (layout === 'list') {
				records.push(
					<>
						<Spreadsheet
							key="updated-members-sheet"
							columnLabels={Object.values(ListHeaderMap)}
							data={this.buildMemberRows(
								memberImport.updatedMembers,
								'updated'
							)}
						/>
						{this.buildTableControls(
							'updated',
							memberImport.updatedMembers.length
						)}
					</>
				);
			} else {
				_.forEach(memberImport.updatedMembers, (m) => {
					records.push(this.buildMemberCard(m, 'updated'));
				});
			}
		}
		if (memberImport.newMembers && memberImport.newMembers.length > 0) {
			records.push(
				<div className="list-header new" key="new-members-header">
					New Members
				</div>
			);
			if (layout === 'list') {
				records.push(
					<>
						<Spreadsheet
							key="new-members-sheet"
							columnLabels={Object.values(ListHeaderMap)}
							data={this.buildMemberRows(memberImport.newMembers, 'new')}
						/>
						{this.buildTableControls('new', memberImport.newMembers.length)}
					</>
				);
			} else {
				_.forEach(memberImport.newMembers, (m) => {
					records.push(this.buildMemberCard(m, 'new'));
				});
			}
		}
		return (
			<div className="members-by-type y-scroll">
				{records.length === 0 ? (
					<div className="empty-message vbox hcenter">
						<h1>No Changes</h1>
						<i className="fad fa-folder-open"></i>
						<p>
							Looks like all of your records are up to date! You can choose to
							import this file for your records but no data will be changed so
							we reccomend you delete the import.
						</p>
					</div>
				) : (
					records
				)}
			</div>
		);
	}

	buildActiveMember() {
		let { member, updatedFields } = this.data.activeMember;

		const fieldMap = {
			status: 'Status',
			relation: 'Type',
			firstName: 'First Name',
			middleName: 'Middle Name',
			lastName: 'Last Name',
			memberId: 'Member ID',
			personCode: 'Person Code',
			groupId: 'Group ID',
			healthPlan: 'Medical Plan ID',
			healthPlanDental: 'Dental Plan ID',
			healthPlanVision: 'Vision Plan ID',
			phone: 'Phone',
			email: 'Email',
			gender: 'Gender',
			dob: 'Birthday',
			startDate: 'Start Date',
			termDate: 'Term Date',
			coverageLevel: 'Medical Coverage Level',
			coverageLevelDental: 'Dental Coverage Level',
			coverageLevelVision: 'Vision Coverage Level',
			dependents: 'Dependent Count',
			insurerCode: 'SSN'
		};

		let addressText = '';
		let addressUpdated = false;

		let { address } = member;

		if (address) {
			let { street, street2, city, state, zipcode } = address;
			addressText = `${street}${
				!_.isEmpty(street2) ? ' ' + street2 : ''
			}, ${_.capitalize(city)}, ${_.toUpper(state)} ${zipcode}`;
			if (updatedFields) {
				_.forEach(updatedFields, (f) => {
					if (
						['street', 'street2', 'city', 'state', 'zipcode'].indexOf(f) >= 0
					) {
						addressUpdated = true;
					}
				});
			}
		}
		return (
			<div className="member-panel-backdrop">
				<div className="right-panel animated fadeInRight">
					<div className="panel-header hbox vcenter">
						<Button
							onClick={() => {
								this.data.activeMember = null;
							}}
						>
							Close
						</Button>
						<h2>Member Details</h2>
					</div>
					<div className="field-ctn">
						{Object.keys(fieldMap).map((key) => {
							let value = this.processValue(key, _.get(member, key, ''));
							return (
								<div className="field" key={`${member.hash}${key}`}>
									<div className="label">
										{fieldMap[key]}{' '}
										{updatedFields?.indexOf(key) >= 0 && <span>Updated</span>}
									</div>
									<div className="value ellipse" title={value}>
										{value ? value : '-'}
									</div>
								</div>
							);
						})}
						<div className="field address" key={`${member.hash}address`}>
							<div className="label">
								Address {addressUpdated && <span>Updated</span>}
							</div>
							<div className="value ellipse" title={addressText}>
								{addressText}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let { memberImport } = this.data;
		let importable = true;
		let needsToBeImported = false;

		// reviewing queued processing imported error canceled published scheduled
		if (memberImport) {
			let missingGroup = memberImport.missingGroup.length;
			let missingPlan = memberImport.missingPlan.length;
			if (
				(missingGroup && missingGroup > 0) ||
				(missingPlan && missingPlan > 0) ||
				memberImport.status === 'error'
			) {
				importable = false;
			}

			if (importable && memberImport.status === 'reviewing') {
				needsToBeImported = true;
			}
		}

		return (
			<div className="import-processor-page y-scroll">
				<div className="page-header hbox vcenter">
					<div
						className="back hbox vcenter"
						onClick={() => {
							this.props.onClose();
						}}
					>
						<i className="fas fa-chevron-left"></i>
						Back To List
					</div>
					<div className="grow-1 hbox vcenter hcenter">
						<h1 className="file-name">
							{memberImport && memberImport.fileName
								? memberImport.fileName
								: 'Loading...'}
						</h1>
					</div>
					{memberImport && memberImport?.status !== 'published' && (
						<Button
							fill
							small
							className="list-btn ios red"
							onClick={this.onDeleteFile}
						>
							<i className="fad fa-trash"></i>
							Delete File
						</Button>
					)}
					{memberImport && memberImport.status === 'reviewing' && (
						<Button
							fill
							small
							className="list-btn ios"
							onClick={this.onPublishFile}
						>
							<i className="fad fa-cloud-upload"></i>
							Import Changes
						</Button>
					)}
				</div>
				<div className="processing-message">
					{importable &&
						needsToBeImported &&
						`Please review the changes below, if all of the changes look accurate you can choose to import this file. All imported changes will be completed withing 30 minutes from the time you selected it for importing. If something is wrong, delete this file and re-upload a corrected one.`}
					{memberImport &&
						!importable &&
						`This file is unable to be imported due to missing plan or groups corresponding to the members in this file. Please ensure you have created all groups and plans prior to importing members into them. Once you've setup all matching Groups and Plans, reimport the file and everything should be ready to review!`}
				</div>
				<div className="controls hbox vcenter">
					<a
						className={`btn ${this.data.layout === 'grid' ? 'active' : ''}`}
						onClick={this.toggleLayout}
					>
						<i className="fa fa-grip-horizontal"></i>
					</a>
					<a
						className={`btn ${this.data.layout === 'list' ? 'active' : ''}`}
						onClick={this.toggleLayout}
					>
						<i className="fa fa-bars"></i>
					</a>
				</div>
				{this.buildImportSheet()}
				{this.data.activeMember && this.buildActiveMember()}
			</div>
		);
	}
}
