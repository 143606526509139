import React, { PureComponent } from 'react';

export default class CareCoin extends PureComponent {
	render() {
		let color = this.props.color || '#fff';
		return (
			<svg width="200" height="150" fill="none" viewBox="0 0 200 150" className="care-coin">
				<path
					fill={color}
					d="M100 0C44.793 0 0 25.172 0 56.25V100c0 27.625 44.793 50 100 50s100-22.375 100-50V56.25C200 25.172 155.207 0 100 0zM34.375 116.941C24.383 111.391 18.75 105.156 18.75 100V88.957a88.282 88.282 0 0015.625 9.668v18.316zm37.5 12.063a136.293 136.293 0 01-25-6.375v-18.785a146.023 146.023 0 0025 6.359v18.801zm43.75 1.562c-5.004.43-10.195.696-15.625.696s-10.621-.266-15.625-.696v-18.82c5.106.453 10.297.754 15.625.754 5.328 0 10.52-.301 15.625-.754v18.82zm37.5-7.925a136.3 136.3 0 01-25 6.375v-18.813a146.007 146.007 0 0025-6.359v18.797zM181.25 100c0 5.156-5.633 11.391-15.625 16.941V98.625a88.3 88.3 0 0015.625-9.668V100zM100 93.75c-46.484 0-81.25-19.797-81.25-37.5s34.766-37.5 81.25-37.5 81.25 19.797 81.25 37.5-34.746 37.5-81.25 37.5z"
				></path>
				<path
					fill={color}
					d="M129.108 32.76c-7.599-5.732-18.9-4.701-25.875 1.67l-2.732 2.492-2.732-2.492c-6.961-6.371-18.276-7.402-25.875-1.67-8.709 6.58-9.167 18.391-1.373 25.524l26.832 24.528c1.734 1.584 4.549 1.584 6.282 0l26.832-24.528c7.808-7.133 7.35-18.943-1.359-25.523z"
				></path>
			</svg>
		);
	}
}
