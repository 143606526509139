import BasicInput from '@/components/basic-input/BasicInput';
import { autoBind } from '@/utils/GeneralUtils';
import { Input } from 'framework7-react';
import React, { Component } from 'react';

export default class ColorPicker extends Component {
	constructor(props) {
		super(props);
		this.colorRef = React.createRef();
		autoBind(this);
	}

	onColorSelect(e) {
		const { onChange } = this.props;
		if (e.hex) {
			onChange(e.hex);
			this.$f7.colorPicker.close('.color-picker');
		}
	}

	handleColorPicker() {
		console.log(this.colorRef);
		this.colorRef.current.click();
	}

	render() {
		const { color, onChange } = this.props;
		return (
			<div className="vbox property-section">
				<div className="property-label">Color</div>
				<div className="property-edit">
					<div className="color-picker hbox vcenter">
						<div
							className="btn color"
							style={{
								background: color,
								color: color
							}}
							onClick={this.handleColorPicker}
						></div>
						<input
							type="color"
							onChange={(e) => onChange(e.target.value)}
							style={{ visibility: 'hidden', width: 0, transform: 'translate(-40px, 0)' }}
							ref={this.colorRef}
							value={color}
						/>
						<div className="color-value hbox hcenter">
							<BasicInput
								key={`express-form-input`}
								validate
								name="range-value"
								type="text"
								value={color}
								onChange={(e) => onChange(e.target.value)}
								className="range-value"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
