import React, { Component } from 'react';
import { Preloader, Button, Popup, Link, Popover, List, ListItem } from 'framework7-react';
import { getCmpText } from '../../utils/Localization';
import { observer, observable } from '../../utils/State';
import _ from 'lodash';
import { autoBind, getProviderIcon } from '../../utils/GeneralUtils';
import CloudAPI from '../../services/CloudAPI';
import doctorMale from '../../assets/doctor-male.svg';
import doctorFemale from '../../assets/doctor-female.svg';
import './provider-details-page.scss';
import providerStore from '../../stores/ProviderStore';
import ProviderService from '../../services/ProviderService';

@observer
export default class ProviderDetailsPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			npi: null,
			loading: false,
			provider: null,
			editingProvider: null,
			baseProvider: null,
			pendingChange: false,
			coreEditOpen: false,
			locationEditOpen: false,
			verificationOpen: false,
			editingLocationId: null,
			linkType: '',
			linkUrl: ''
		});
		this.mgmtDetails = props.mgmtDetails;
		this.text = getCmpText('ProviderDetailsPage', {
			noTextProbably: 'true'
		});
		autoBind(this);
	}

	async componentDidMount() {
		console.log('Mounted Provider Details');
	}

	humanNetworkArray(arr) {
		return arr.map((a) => {
			return this.mgmtDetails.networkIdToName[a];
		});
	}

	async loadProviderData() {
		providerStore.openProviderBDData = {};
		this.data.provider = null;
		this.data.baseProvider = null;
		this.data.editingProvider = null;
		let res = await CloudAPI.getProvider(this.data.npi);
		try {
			let bddata = await ProviderService.getBetterDoctorData(this.data.npi);
			if (bddata) {
				providerStore.openProviderBDData = bddata;
				if (res[0] && !res[0].bio) {
					res[0].bio = _.get(providerStore.openProviderBDData, 'profile.bio', '');
				}
			}
		} catch (err) {
			console.log(err.responseText);
		}
		if (res[0] && !res[0].bio) {
			res[0].bio = '';
		}
		this.data.provider = res[0];
		this.data.baseProvider = res[0];
		this.data.editingProvider = res[0];
		this.data.loading = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let { provider } = nextProps;
		if (this.data.npi !== provider && provider !== null) {
			this.data.pendingChange = false;
			this.data.npi = provider;
			this.data.loading = true;
			this.loadProviderData();
		}
	}

	buildLocationList() {
		let { provider, npi } = this.data;
		if (provider && provider.NPI === npi) {
			let locations = [];
			_.forEach(this.data.provider.locations, (l) => {
				let networks = [];
				let rawSpecialties = [];
				let specialties = [];
				_.forEach(_.uniq(l.networks), (n) => {
					networks.push(
						<div className="network" key={n}>
							{this.mgmtDetails.networkIdToName[n] ? this.mgmtDetails.networkIdToName[n] : `Unknown Network ${n}`}
						</div>
					);
				});
				_.forEach(_.uniq(l.specialties), (s) => {
					specialties.push(
						<div className="specialty" key={s}>
							{s}
						</div>
					);
				});
				_.forEach(_.uniq(l.rawSpecialties), (rs) => {
					rawSpecialties.push(
						<div
							className={`rawspecialty ${rs.length === 10 && rs.indexOf('X') >= 0 ? 'link' : ''}`}
							key={rs}
							onClick={() => {
								if (rs.length === 10 && rs.indexOf('X') >= 0) {
									window.open(`https://npidb.org/taxonomy/${rs}`);
								}
							}}
						>
							{rs}
						</div>
					);
				});
				locations.push(
					<div className="location-card" key={l._id}>
						<div
							className="address"
							onClick={() => {
								window.open(
									`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(ProviderService.getAddressText(l))}`
								);
							}}
						>
							{ProviderService.getAddressText(l)}
						</div>
						<div className="geo">
							<div>
								<span>Latitude:</span> {l.latitude ? l.latitude : '(none)'}
							</div>
							<div>
								<span>Longitude:</span> {l.longitude ? l.longitude : '(none)'}
							</div>
							<div
								className="view"
								onClick={() => {
									window.open(`https://www.google.com/maps/search/?api=1&query=${l.latitude},${l.longitude}`);
								}}
							>
								VIEW GEO
							</div>
						</div>
						{!provider.isPerson && (
							<div className="searchable">
								<span>Searchable As:</span>
								<div>{l.fullName.split(' ').join(' ')}</div>
								{l.fullName !== provider.fullName ? <div className="alert">{'Different than core name'}</div> : ''}
							</div>
						)}
						<div className="phone">
							<span>Phone:</span> {l.phone ? l.phone : '(none)'}
						</div>
						<div className="phone">
							<span>Fax:</span> {l.fax ? l.fax : '(none)'}
						</div>
						<div className="email">
							<span>Email:</span> {l.email ? l.email : '(none)'}
						</div>
						<div className="header">Specialties:</div>
						<div className="specialties hbox vcenter">{specialties}</div>
						<div className="header">Raw Specialties:</div>
						<div className="rawspecialties hbox vcenter">{rawSpecialties}</div>
						<div className="header">Networks:</div>
						<div className="networks hbox vcenter">{networks}</div>
						<div className="actions">
							{l.suppressed && <div className="suppressed">Suppressed</div>}
							<Button
								onClick={() => {
									this.data.locationEditOpen = true;
									this.data.editingLocationId = l._id;
								}}
							>
								<i className="fad fa-pencil" /> Edit
							</Button>
						</div>
					</div>
				);
			});
			return locations;
		}
		return '';
	}

	buildProviderData() {
		let { provider, pendingChange } = this.data;
		if (provider) {
			return (
				<div className="provider-details">
					<div className="top y-scroll">
						<div className="detail-card hbox">
							<div className="photo vbox hcenter vcenter">
								{provider.isPerson && (
									<object data={ProviderService.getProviderImage(provider)} type="image/png">
										<img src={_.get(provider, 'personDetails.gender', 'm') === 'm' ? doctorMale : doctorFemale} />
									</object>
								)}
								{!provider.isPerson && (
									<div className="organization">
										<i className={`${getProviderIcon(provider, false, true)}`}></i>
									</div>
								)}
								{provider.isPerson && (
									<Button
										className="hbox vcenter"
										onClick={() => {
											this.$f7.dialog.alert('Coming soon! :D');
										}}
									>
										<i className="fal fa-image"></i> <div>Change</div>
									</Button>
								)}
							</div>
							<div className="core">
								{provider.locations.length > 50 && (
									<div className="warning">WARNING DO NOT EDIT THIS REC SEND NPI TO PRODUCT TEAM</div>
								)}
								<div className="name">{provider.fullName}</div>
								{provider.isPerson && (
									<>
										<div className="person-field">
											<span>First Name:</span> {provider.personDetails.firstName}
										</div>
										<div className="person-field">
											<span>Last Name:</span> {provider.personDetails.lastName}
										</div>
										<div className="person-field">
											<span>Credentials:</span> {provider.personDetails.credential}
										</div>
										<div className="person-field">
											<span>Gender:</span> {provider.personDetails.gender}
										</div>
									</>
								)}
								{!provider.isPerson && (
									<div className="org-field">
										<span>Legal Name:</span> {_.get(provider, 'organizationDetails.legalName', '')}
									</div>
								)}
								<div className="location-ct">
									<span>Total Locations:</span> {provider.locations.length}
								</div>
								<div className="npi">
									<span>NPI:</span> {provider.NPI}
								</div>
								{provider.links && provider.links.length > 0 && (
									<div className="links-ctn">
										<div className="label">External Links:</div>
										{provider.links.map((l) => {
											return (
												<div className="link-item hbox vcenter" key={Math.random()}>
													<div className="type">{l.type}</div>
													<div
														className="url ellipse"
														onClick={() => {
															window.open(l.url);
														}}
													>
														{l.url}
													</div>
												</div>
											);
										})}
									</div>
								)}
								<div className="npi">
									<span>Biography:</span>
									{_.get(provider, 'bio', '').length > 1
										? _.get(provider, 'bio', '')
										: _.get(providerStore.openProviderBDData, 'profile.bio', '')}
								</div>
							</div>
							<div className="actions">
								<Button
									onClick={() => {
										this.data.coreEditOpen = true;
									}}
								>
									<i className="fad fa-pencil" /> Edit
								</Button>
							</div>
						</div>
						<div className="location-list">{this.buildLocationList()}</div>
					</div>
					<div className="bottom-bar hbox vcenter hright">
						{pendingChange && <div className="changes">Pending Changes</div>}
						<Button onClick={this.onSaveAndVerifyOpen} disabled={!this.data.pendingChange}>
							<i className="fad fa-shield-check" /> Verify & Save
						</Button>
					</div>
				</div>
			);
		}
	}

	buildVerificationContent() {
		if (!this.data.verificationOpen) {
			return;
		}
		const fields = {
			'personDetails.firstName': 'First Name:',
			'personDetails.lastName': 'Last Name:',
			'personDetails.credential': 'Credential:',
			fullName: 'Searchable As:',
			'organizationDetails.legalName': 'Business Legal Name:',
			bio: 'Biography',
			links: 'External Links',
			location: {
				fullName: 'Searchable As:',
				street1: 'Street 1:',
				street2: 'Street 2:',
				city: 'City:',
				state: 'State:',
				zip: 'Zip Code:',
				phone: 'Phone:',
				fax: 'Fax:',
				email: 'Email:',
				latitude: 'Latitude:',
				longitude: 'Longitude:',
				suppressed: 'Suppressed:'
			}
		};
		const diffs = [];
		_.forEach(Object.keys(fields), (field) => {
			let baseField = _.get(this.data.baseProvider, field, null);
			let newField = _.get(this.data.provider, field, null);
			if (field === 'links' && baseField !== newField) {
				let finalBaseVal = _.isEmpty(baseField) ? 'Empty' : JSON.stringify(baseField, null, 2);
				let finalNewVal = _.isEmpty(newField) ? 'Empty' : JSON.stringify(newField, null, 2);
				if (finalBaseVal !== finalNewVal) {
					diffs.push(
						<div className="delta-fields" key={Math.random()}>
							<div className="label">{fields[field]}</div>
							<div className="diff hbox vcenter">
								<div className="old">{finalBaseVal}</div>
								<div className="new">{finalNewVal}</div>
							</div>
						</div>
					);
				}
			} else if (field !== 'location' && baseField && baseField !== newField) {
				diffs.push(
					<div className="delta-fields" key={Math.random()}>
						<div className="label">{fields[field]}</div>
						<div className="diff hbox vcenter">
							<div className="old">{_.isEmpty(baseField) ? 'Empty' : baseField}</div>
							<div className="new">{_.isEmpty(newField) ? 'Empty' : newField}</div>
						</div>
					</div>
				);
			} else if (field === 'location') {
				_.forEach(this.data.baseProvider.locations, (l) => {
					const newLocation = _.find(this.data.provider.locations, { _id: l._id });
					if (newLocation.suppressed !== l.suppressed) {
						diffs.push(
							<div className="delta-fields" key={Math.random()}>
								<div className="label">{`Suppressed Location:`}</div>
								<div className="diff hbox vcenter">
									<div className="old">{ProviderService.getAddressText(l)}</div>
									<div className="new">{newLocation.suppressed ? 'True' : 'False'}</div>
								</div>
							</div>
						);
					} else {
						let hasChanges = false;
						_.forEach(Object.keys(fields.location), (locationField) => {
							baseField = _.get(l, locationField, null);
							newField = _.get(newLocation, locationField, null);
							if (baseField !== newField && locationField !== 'suppressed') {
								hasChanges = true;
								diffs.push(
									<div className="delta-fields" key={Math.random()}>
										<div className="label">{`${_.upperCase(newLocation.street1)} - ${fields.location[locationField]}`}</div>
										<div className="diff hbox vcenter">
											<div className="old">{_.isEmpty(baseField) ? 'Empty' : baseField}</div>
											<div className="new">{_.isEmpty(newField) ? 'Empty' : newField}</div>
										</div>
									</div>
								);
							}
						});
						//Specialty Chech
						if (JSON.stringify(_.cloneDeep(newLocation.specialties).sort()) !== JSON.stringify(_.cloneDeep(l.specialties).sort())) {
							hasChanges = true;
							diffs.push(
								<div className="delta-fields" key={Math.random()}>
									<div className="label">{_.upperCase(newLocation.street1)} - Specialties:</div>
									<div className="diff hbox vcenter">
										<div className="old">
											{JSON.stringify(_.cloneDeep(l.specialties).sort()).split(',').join(', ').split('"').join('').trim()}
										</div>
										<div className="new">
											{JSON.stringify(_.cloneDeep(newLocation.specialties).sort()).split(',').join(', ').split('"').join('').trim()}
										</div>
									</div>
								</div>
							);
						}

						//Network Check
						if (JSON.stringify(_.cloneDeep(newLocation.networks).sort()) !== JSON.stringify(_.cloneDeep(l.networks).sort())) {
							hasChanges = true;
							diffs.push(
								<div className="delta-fields" key={Math.random()}>
									<div className="label">{_.upperCase(newLocation.street1)} - Networks:</div>
									<div className="diff hbox vcenter">
										<div className="old">
											{JSON.stringify(this.humanNetworkArray(_.cloneDeep(l.networks).sort()))
												.split(',')
												.join(', ')
												.split('"')
												.join('')
												.trim()}
										</div>
										<div className="new">
											{JSON.stringify(this.humanNetworkArray(_.cloneDeep(newLocation.networks).sort()))
												.split(',')
												.join(', ')
												.split('"')
												.join('')
												.trim()}
										</div>
									</div>
								</div>
							);
						}

						if (!hasChanges) {
							diffs.push(
								<div className="delta-fields" key={Math.random()}>
									<div className="label">Existing Location:</div>
									<div className="diff hbox vcenter">
										<div className="old">Unchanged</div>
										<div className="new">{ProviderService.getAddressText(l)}</div>
									</div>
								</div>
							);
						}
					}
				});
			}
		});
		return (
			<div className="core-edit">
				<h2>
					<i className="fad fa-shield-check" /> Verify Changes
				</h2>
				<div className="fields y-scroll">
					<div className="subtitle">
						Please verify <strong style={{ color: 'red' }}>ALL</strong> changes before saving, this data will be used in it's entirety
						for this provider. Validate all locations as well as core provider data.
					</div>
					{diffs}
				</div>
				<div className="btn-ctn hbox vcenter hright">
					<Button
						onClick={() => {
							this.data.verificationOpen = false;
						}}
					>
						Cancel
					</Button>
					<Button fill onClick={this.onSaveRecord}>
						Save
					</Button>
				</div>
			</div>
		);
	}

	onSaveRecord() {
		this.$f7.dialog.preloader('Saving...');
		this.onVerificationClose();
		//Patch location names before saving?
		CloudAPI.verifyProvider(_.cloneDeep(this.data.provider))
			.then((res) => {
				this.data.pendingChange = false;
				this.data.baseProvider = res;
				this.data.provider = res;
				this.$f7.dialog.close();
				const toast = this.$f7.toast.create({
					position: 'center',
					closeTimeout: 1500,
					icon: `<i class="fad fa-check large-icon-toast" ></i>`,
					text: 'Success!'
				});
				toast.open();
			})
			.catch((err) => {
				this.$f7.dialog.close();
			});
	}

	onSaveAndVerifyOpen() {
		this.data.verificationOpen = true;
	}

	onVerificationClose() {
		this.data.verificationOpen = false;
	}

	onLocationEditClose() {
		if (JSON.stringify(this.data.provider) != JSON.stringify(this.data.baseProvider)) {
			this.data.pendingChange = true;
		} else {
			this.data.pendingChange = false;
		}
		this.data.locationEditOpen = false;
		this.data.editingLocationId = null;
	}

	onCoreEditClose() {
		if (JSON.stringify(this.data.provider) != JSON.stringify(this.data.baseProvider)) {
			this.data.pendingChange = true;
		} else {
			this.data.pendingChange = false;
		}
		this.data.coreEditOpen = false;
	}

	onCoreChange(e) {
		let { name, value } = e.target;
		this.data.editingProvider[name] = name === 'personDetails.credential' ? value : value.toLowerCase();
	}

	onBioChange(e) {
		let { value } = e.target;
		this.data.editingProvider.bio = value;
	}

	storeCoreChanges() {
		let { provider, editingProvider } = this.data;
		if (provider.isPerson) {
			this.data.provider.personDetails.firstName = _.get(editingProvider, 'personDetails.firstName');
			this.data.provider.personDetails.lastName = _.get(editingProvider, 'personDetails.lastName');
			this.data.provider.personDetails.credential = _.get(editingProvider, 'personDetails.credential');
		} else if (!provider.isPerson) {
			this.data.provider.organizationDetails.legalName = _.get(editingProvider, 'organizationDetails.legalName');
		}
		this.data.provider.links = _.cloneDeep(editingProvider.links);
		this.data.provider.bio = _.cloneDeep(editingProvider.bio);
		this.data.provider.fullName = editingProvider.fullName;
		this.data.editingProvider = _.cloneDeep(this.data.provider);
		this.data.coreEditOpen = false;
	}

	storeLocationChange() {
		this.data.provider = { ...this.data.provider, ...this.data.editingProvider };
		this.data.locationEditOpen = false;
	}

	onLocationChange(e) {
		let { name, value } = e.target;
		const currentIndex = _.findIndex(_.get(this.data, 'editingProvider.locations', []), { _id: this.data.editingLocationId });
		this.data.editingProvider.locations[currentIndex][name] = value.toLowerCase();
	}

	buildSpecialtyDropdown() {
		let { specialty } = this.mgmtDetails;
		let listItems = [];
		_.forEach(specialty[this.data.filters.category], (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.data.filters.specialty = rec;
					}}
				>
					<div className="label">{rec}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	buildNetworkDropdown() {
		let { networkToId } = this.mgmtDetails;
		let listItems = [];
		_.forEach(Object.keys(networkToId), (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.data.filters.network = rec;
					}}
				>
					<div className="label">{rec}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	onNetworkChange(e, location) {
		let { networkToId } = this.mgmtDetails;
		let selected = [];
		Array.from(e.currentTarget.options).forEach((o) => {
			if (o.selected) {
				selected.push(networkToId[o.value]);
			}
		});
		location.networks = selected;
	}

	onSpecialtyChange(e, location) {
		let selected = [];
		Array.from(e.currentTarget.options).forEach((o) => {
			if (o.selected) {
				selected.push(o.value);
			}
		});
		location.specialties = selected;
	}

	buildNetworkPicker(location) {
		let networks = Object.keys(this.mgmtDetails.networkToId);
		let options = [];
		_.forEach(networks, (n) => {
			options.push(
				<option value={n} key={n.split(' ').join('')}>
					{n}
				</option>
			);
		});
		let defaultNetworks = [];
		_.forEach(location.networks, (n) => {
			defaultNetworks.push(this.mgmtDetails.networkIdToName[n]);
		});
		return (
			<ListItem title="Networks:" smartSelect smartSelectParams={{ openIn: 'popover' }} key="smart-select-networks">
				<select
					name="networks"
					multiple
					defaultValue={defaultNetworks}
					onChange={(e) => {
						this.onNetworkChange(e, location);
					}}
				>
					{options}
				</select>
			</ListItem>
		);
	}

	buildSpecialtyDropdown(location) {
		let options = [];
		_.forEach(this.mgmtDetails.allSpecialties, (n) => {
			options.push(
				<option value={n} key={n.split(' ').join('')}>
					{n}
				</option>
			);
		});
		return (
			<ListItem title="Specialties:" smartSelect smartSelectParams={{ openIn: 'popover' }} key="smart-select-specialties">
				<select
					name="specialty"
					multiple
					defaultValue={location.specialties}
					onChange={(e) => {
						this.onSpecialtyChange(e, location);
					}}
				>
					{options}
				</select>
			</ListItem>
		);
	}

	buildLocationEdit() {
		let { editingProvider } = this.data;
		if (!editingProvider) {
			return;
		}
		const currentIndex = _.findIndex(_.get(this.data, 'editingProvider.locations', []), { _id: this.data.editingLocationId });
		let location = this.data.editingProvider.locations[currentIndex];
		if (!location) {
			return;
		}

		const fieldMap = {
			fullName: 'Searchable As:',
			street1: 'Street 1:',
			street2: 'Street 2:',
			city: 'City:',
			state: 'State:',
			zip: 'Zip Code:',
			phone: 'Phone:',
			fax: 'Fax:',
			email: 'Email (Scheduling):',
			latitude: 'Latitude',
			longitude: 'Longitude'
		};
		let fields = [];

		//Insert all lists
		fields.push(
			<List className="multi-pickers" key="dropdown-lists">
				{[this.buildNetworkPicker(location), this.buildSpecialtyDropdown(location)]}
			</List>
		);

		//Insert all inputs
		_.forEach(Object.keys(fieldMap), (fieldName) => {
			let value = _.get(location, fieldName, '');
			fields.push(
				<div className="fieldset" key={fieldName}>
					<div className="label">{fieldMap[fieldName]}</div>
					<input name={fieldName} value={value} onChange={this.onLocationChange}></input>
				</div>
			);
		});

		return (
			<div className="core-edit">
				<h2>Edit Location Data</h2>
				<div className="fields y-scroll">{fields}</div>
				<div className="btn-ctn hbox vcenter hright">
					<Button
						onClick={() => {
							this.data.locationEditOpen = false;
						}}
					>
						Cancel
					</Button>
					<Button onClick={this.suppressOpenLocation}>{this.isSuppressed() ? `Un-Suppress` : `Suppress`}</Button>
					<Button fill onClick={this.storeLocationChange}>
						Submit
					</Button>
				</div>
			</div>
		);
	}

	buildCoreEdit() {
		let { editingProvider } = this.data;
		if (!editingProvider) {
			return;
		}
		const fieldMap = {
			'personDetails.firstName': 'First Name:',
			'personDetails.lastName': 'Last Name:',
			'personDetails.credential': 'Credential:',
			fullName: 'Core Name:',
			'organizationDetails.legalName': 'Business Legal Name:'
		};
		let fields = [];
		_.forEach(Object.keys(fieldMap), (fieldName) => {
			let value = _.get(editingProvider, fieldName, '');
			if (fieldName.indexOf('personDetails') >= 0 && editingProvider.isPerson) {
				fields.push(
					<div className="fieldset" key={fieldName}>
						<div className="label">{fieldMap[fieldName]}</div>
						<input name={fieldName} value={value} onChange={this.onCoreChange}></input>
					</div>
				);
			}
			if (fieldName.indexOf('organizationDetails') >= 0 && !editingProvider.isPerson) {
				fields.push(
					<div className="fieldset" key={fieldName}>
						<div className="label">{fieldMap[fieldName]}</div>
						<input name={fieldName} value={value} onChange={this.onCoreChange}></input>
					</div>
				);
			}
			if (fieldName === 'fullName') {
				fields.push(
					<div className="fieldset" key={fieldName}>
						<div className="label">{editingProvider.isPerson ? 'Searchable As:' : '' + fieldMap[fieldName]}</div>
						<input name={fieldName} value={value} onChange={this.onCoreChange}></input>
					</div>
				);
			}
		});
		fields.push(
			<div className="fieldset" key={'external-links'}>
				<div className="label">External Links</div>
				<div className="links-ctn">
					<div className="link-header">
						<div className="type-title">Type:</div> <div className="url-title">URL (must be https):</div>
					</div>
					<div className="link-row hbox vcenter">
						<input name="linkType" value={this.data.linkType} onChange={this.onLinkChange}></input>
						<input name="linkUrl" value={this.data.linkUrl} onChange={this.onLinkChange}></input>
						<Button
							onClick={() => {
								if (!editingProvider.links) {
									editingProvider.links = [];
								}
								if (this.data.linkType.length > 3 && this.data.linkUrl.length > 10) {
									editingProvider.links.push({
										type: this.data.linkType,
										url: this.data.linkUrl
									});
									this.data.linkUrl = '';
									this.data.linkType = '';
								}
							}}
						>
							Add
						</Button>
					</div>
					<div className="links">
						{editingProvider.links && editingProvider.links.length > 0
							? editingProvider.links.map((l) => {
									return (
										<div className="link-item hbox vcenter" key={Math.random()}>
											<div className="type">{l.type}</div>
											<div className="url ellipse">{l.url}</div>
											<Button
												onClick={() => {
													let i = _.findIndex(editingProvider.links, { type: l.type });
													editingProvider.links.splice(i, 1);
												}}
											>
												<i className="fal fa-trash" />
											</Button>
										</div>
									);
							  })
							: ''}
					</div>
				</div>
			</div>
		);
		fields.push(
			<div className="fieldset" key={'biography'}>
				<div className="label">Biography</div>
				<textarea value={_.get(editingProvider, 'bio', '')} onChange={this.onBioChange}></textarea>
			</div>
		);

		return (
			<div className="core-edit">
				<h2>Edit Core Data</h2>
				<div className="fields y-scroll">{fields}</div>
				<div className="btn-ctn hbox vcenter hright">
					<Button
						onClick={() => {
							this.data.coreEditOpen = false;
							this.data.editingProvider = _.cloneDeep(this.data.provider);
						}}
					>
						Cancel
					</Button>

					<Button fill onClick={this.storeCoreChanges}>
						Submit
					</Button>
				</div>
			</div>
		);
	}

	onLinkChange(e) {
		let { name, value } = e.target;
		this.data[name] = value;
	}

	isSuppressed() {
		let activelocation = _.find(_.get(this.data, 'provider.locations', []), { _id: this.data.editingLocationId });
		if (activelocation && activelocation.suppressed) {
			return true;
		}
		return false;
	}

	suppressOpenLocation() {
		const currentIndex = _.findIndex(_.get(this.data, 'provider.locations', []), { _id: this.data.editingLocationId });
		let rec = this.data.provider.locations[currentIndex];
		if (rec && rec.suppressed) {
			delete this.data.provider.locations[currentIndex].suppressed;
		} else if (rec) {
			this.data.provider.locations[currentIndex].suppressed = true;
		}
		this.data.editingProvider = _.cloneDeep(this.data.provider);
		this.data.locationEditOpen = false;
	}

	render() {
		return (
			<div className="provider-details-page">
				{this.data.loading && (
					<div className="loading">
						<Preloader color="white" size="120"></Preloader>
						<div>Gathering Data...</div>
					</div>
				)}
				{this.buildProviderData()}
				<Popup className="provider-core-edit-popup" opened={this.data.coreEditOpen} onPopupClosed={this.onCoreEditClose} backdrop>
					{this.buildCoreEdit()}
				</Popup>
				<Popup
					className="provider-location-edit-popup"
					opened={this.data.locationEditOpen}
					onPopupClosed={this.onLocationEditClose}
					backdrop
				>
					{this.buildLocationEdit()}
				</Popup>
				<Popup
					className="provider-save-verify-popup"
					opened={this.data.verificationOpen}
					onPopupClosed={this.onVerificationClose}
					backdrop
				>
					{this.buildVerificationContent()}
				</Popup>
			</div>
		);
	}
}
