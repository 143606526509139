import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { getCmpText } from '@/utils/Localization';
import { Button, Preloader, Swiper, SwiperSlide } from 'framework7-react';
import { format } from 'date-fns';
import CloudAPI from '@/services/CloudAPI';
import userStore from '@/stores/UserStore';
import _ from 'lodash';
import './wallet-home.scss';
import windowStore from '@/stores/WindowStore';
import appStore from '@/stores/AppStore';
import FlipCard from '@/components/flip-card/FlipCard';
import ShareDocumentPanel from '@/components/wallet-popup/share-document-panel/ShareDocumentPanel';
import UserService from '@/services/UserService';
import found_error from '@/assets/found_error.svg';
import ConfigService from '@/services/ConfigService';
import { Progressbar } from 'framework7-react';

@observer
export default class WalletHome extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showCardPreview: false,
			idCardPdf: null,
			shareDocument: {
				opened: false,
				isInsuranceCard: false,
				shareMethod: 'email',
				documentId: null
			},
			selectedDependent: null,
			imageReloadStamp: null,
			imageFront: null,
			imageBack: null,
			idCardsList: [],
			cancelRequest: false,
			accums: [],
			expressAccums: null,
			dependentAccums: null
		});
		this.text = getCmpText('WalletPopup', {
			inNetworkAccumText: 'In-Network Account Balance',
			tier1AccumText: 'Tier 1 Account Balance',
			tier2AccumText: 'Tier 2 Account Balance',
			outNetworkAccumText: 'Out-of-Network Account Balance',
			closeTxt: 'Close',
			doneTxt: 'Done',
			walletHeaderTxt: 'Wallet',
			insuranceCardTxtHeadline: 'Insurance card',
			documentsHeaderTxt: 'Plan documents',
			accumulatorHeaderTxt: 'Accumulators',
			dependentHeaderTxt: 'Dependents',
			shareSuccess: 'Document Sent',
			backTxt: 'Back',
			previewText: 'Share',
			shareCta: 'Share',
			oopText: 'Out-of-Pocket',
			deductibleText: 'Deductible',
			loadingShareOptions: 'Loading Sharing Options...'
		});
		autoBind(this);
		this.mainRef = React.createRef();
		this.cardContainerRef = React.createRef();
	}

	async componentDidMount() {
		this.data.imageReloadStamp = new Date().getTime();
		this.getIdCard();
		this.loadAccumulatorData();
	}

	async loadAccumulatorData() {
		const config = ConfigService.getConfiguration();
		const hasAccumulators =
			_.get(config, 'features.walletAccumulators', false) ||
			_.get(config, 'features.profileAccumulators', false);
		if (hasAccumulators) {
			const integration = _.get(config, 'accumulators.integrationType', null);
			if (!_.isEmpty(integration)) {
				await this.getExpressAccumulatorData(integration);
			} else {
				// Fires if there is no Intregration (NOTE: There has to be an intregration for widget to show - app config)
				await this.getAccumulatorData(); // return array with single object  of the most recent accumulator total
			}
		}
	}

	async loadDependentAccumulators(dependent) {
		const config = ConfigService.getConfiguration();
		const hasAccumulators =
			_.get(config, 'features.walletAccumulators', false) ||
			_.get(config, 'features.profileAccumulators', false);
		if (hasAccumulators) {
			const integration = _.get(config, 'accumulators.integrationType', null);
			if (!_.isEmpty(integration)) {
				await this.getExpressDependentAccumulatorData(integration, dependent);
			} else {
				// Fires if there is no Intregration (NOTE: There has to be an intregration for widget to show - app config)
				await this.getAccumulatorData(); // return array with single object  of the most recent accumulator total
			}
		}
	}

	async handlePreview() {
		const isError =
			this.data.imageFront === 'error' || this.data.imageBack === 'error';
		if (isError) {
			this.getIdCard();
		} else {
			this.data.showCardPreview = true;
		}
	}

	handleClose() {
		if (this.data.showCardPreview) {
			if (this.props.activeDependent) {
				this.props.onDeselectDependent();
				this.getIdCard(true);
				this.data.dependentAccums = null;
				this.loadAccumulatorData();
			}
			this.data.showCardPreview = false;
		} else {
			if (this.props.activeDependent) {
				this.props.onDeselectDependent();
				this.getIdCard(true);
				this.data.dependentAccums = null;
				this.loadAccumulatorData();
			}
			this.props.onClose();
		}
	}

	handleShareDocumentClose() {
		this.data.shareDocument.opened = false;
	}

	shareError(err) {
		console.log('error sharing document: ', err);
		this.$f7.dialog.close();
		this.$f7.dialog.alert(this.text.shareDocumentError);
		if (appStore.isIos) {
			try {
				this.$f7.statusbar.overlaysWebView(false);
				this.$f7.statusbar.overlaysWebView(true);
			} catch (err) {
				console.log('error resetting status bar state');
			}
		}
	}

	triggerDeviceShareMenu() {
		try {
			const docType = this.props.docType;
			const cardFront = this.data.imageFront;
			const cardBack = this.data.imageBack;
			let images = [cardFront, cardBack];
			const options = {
				files: images,
				url: 'https://www.carevalet.io',
				chooserTitle: this.text.shareCta
			};
			window.plugins.socialsharing.shareWithOptions(
				options,
				this.shareSuccessful,
				this.shareError
			);
			return;
		} catch (err) {
			this.shareError(err);
		}
	}

	shareSuccessful(data) {
		this.$f7.dialog.close();
		if (appStore.isIos) {
			try {
				this.$f7.statusbar.overlaysWebView(false);
				this.$f7.statusbar.overlaysWebView(true);
			} catch (err) {
				console.log('error resetting status bar state');
			}
		}
	}

	async handleWebDownloads(type) {
		const isIdCard = type === 'id-card';
		const canShare = navigator.share;
		this.data.shareDocument.isInsuranceCard = isIdCard;
		try {
			if (appStore.isApp()) {
				this.triggerDeviceShareMenu();
				return;
			}
			if (navigator.share) {
				await this.triggerNavigatorShare(this.data.idCardPdf, type);
				return;
			}
			this.$f7.dialog.preloader();
			const documentData = await this.getDocumentPdf(isIdCard && canShare);
			this.$f7.dialog.close();
			if (documentData) {
				this.triggerDesktopDownload(documentData, type);
			} else {
				this.shareError('No data returned');
			}
		} catch (err) {
			this.$f7.dialog.close();
			this.shareError(err);
		}
	}

	async triggerNavigatorShare(fileUrl, type) {
		const shareData = {
			title: type,
			text: 'Sharing document',
			url: fileUrl
			// files: [new File([blob], 'id.pdf', { type: blob.type })]
		};
		try {
			await navigator.share(shareData);
		} catch (err) {
			console.log(err);
		}
	}

	async getDocumentPdf(urlOnly) {
		try {
			const requestData = {
				method: 'download',
				isExpress: true,
				documentsRequested: this.data.shareDocument.isInsuranceCard
					? 'id-card'
					: 'document',
				dependentRequested: this.props.activeDependent
					? this.props.activeDependent._id
					: null,
				formatRequested: urlOnly ? 'url' : 'blob'
			};

			if (!this.data.shareDocument.isInsuranceCard) {
				requestData.documentId = this.data.shareDocument.documentId;
			}
			const documents = await UserService.sharePlanDocuments(requestData);
			return documents;
		} catch (err) {
			console.log('err.response:: ', err.response);
			this.shareError(err);
		}
	}

	triggerDesktopDownload(documentBlob, docType) {
		this.$f7.dialog.close(); //close any open dialogs before it loses scope
		const documentUrl = URL.createObjectURL(documentBlob);
		const trigger = document.createElement('a');
		trigger.href = documentUrl;
		trigger.target = appStore.isIos ? '_self' : '_blank';
		trigger.download = `${docType !== 'all' ? docType : 'MemberDocuments'}.pdf`;
		trigger.click();
		URL.revokeObjectURL(documentUrl);
	}

	triggerMobileWebDownload(documentBlob) {
		let reader = new FileReader();
		reader.readAsDataURL(documentBlob);

		reader.onload = async () => {
			const documentUrl = reader.result;
			window.location.href = documentUrl;
		};
	}

	async getIdCard(showPrimary) {
		if (this.data.imageBack === 'loading') {
			//if dependent is selected or closed while ID card was already loading
			this.data.cancelRequest = true;
		}
		this.data.imageFront = 'loading';
		this.data.imageBack = 'loading';
		try {
			const showDependent = !showPrimary && this.props.activeDependent;
			let planDocs;
			if (appStore.demoMode) {
				planDocs = await CloudAPI.getPlanDocumentsDemo({
					requestedDependent: showDependent,
					primaryDemoId: userStore.userData.demoHash
				});
			} else {
				planDocs = await CloudAPI.getPlanDocuments({
					requestedDependent: showDependent
				});
			}
			const canShare = navigator.share;
			if (canShare) {
				this.data.shareDocument.isInsuranceCard = true;
				this.getDocumentPdf(true).then((res) => {
					//dont want to block the rest of loading for it.
					this.data.idCardPdf = _.cloneDeep(res);
				});
			}
			if (!this.data.cancelRequest) {
				const cardFront = _.get(planDocs.idCards[0], 'cardFront') || 'error';
				const cardBack = _.get(planDocs.idCards[0], 'cardBack') || 'error';
				this.data.imageFront = cardFront;
				this.data.imageBack = cardBack;
				this.data.idCardsList = planDocs.idCards || [];
			}
			this.data.cancelRequest = false;
		} catch (err) {
			this.data.imageFront = 'error';
			this.data.imageBack = 'error';
		}
	}

	onSelectDependent(dependent) {
		this.data.showCardPreview = true;
		this.props.onSelectDependent(dependent);
		this.getIdCard(false);
		this.loadDependentAccumulators(dependent);
	}

	async getExpressAccumulatorData(integration) {
		let res;
		if (appStore.demoMode)
			res = await CloudAPI.getExpressAccumulatorsDemo(
				integration,
				userStore.userData.demoHash
			);
		else res = await CloudAPI.getExpressAccumulators(integration);
		console.log('res:: ', res);
		switch (integration) {
			case 'WLT':
				{
					if (!res || !res.values || Object.keys(res.values).length < 1) {
						this.data.expressAccums = [];
					} else {
						this.data.expressAccums = [...res];
					}
				}
				break;
			case 'STANDARD': {
				this.data.expressAccums = [...res];
				break;
			}
			default:
				{
					this.data.expressAccums = [];
				}
				break;
		}
		return;
	}

	async getExpressDependentAccumulatorData(integration, dependent) {
		let res;
		if (appStore.demoMode)
			res = await CloudAPI.getExpressDependentAccumulatorsDemo(
				integration,
				dependent.hash,
				userStore.userData.demoHash
			);
		else
			res = await CloudAPI.getExpressDependentAccumulators(
				integration,
				dependent.hash
			);
		switch (integration) {
			case 'WLT':
				{
					if (!res || !res.values || Object.keys(res.values).length < 1) {
						this.data.dependentAccums = [];
					} else {
						this.data.dependentAccums = [...res];
					}
				}
				break;
			case 'STANDARD': {
				this.data.dependentAccums = [...res];
				break;
			}
			default:
				{
					this.data.dependentAccums = [];
				}
				break;
		}
		return;
	}

	async getAccumulatorData() {
		const res = await CloudAPI.getAccumulators();
		this.data.accums = res;
	}

	getAccumulatorGauge(maxObj) {
		const planOopMax = _.get(userStore, 'userData.group.oopMax', 0);
		const planDeductible = _.get(userStore, 'userData.group.deductible', 0);

		const { type: accumtype, value: details } = maxObj;
		const isOop = accumtype.indexOf('oop') >= 0 ? true : false;
		return (
			<div className="accum vbox vcenter w-100" key={accumtype + Math.random()}>
				<div>
					{`${this.text[isOop ? 'oopText' : 'deductibleText']} ($${Number(
						details.value || 0
					)} of $${Number(isOop ? planOopMax : planDeductible)})`}
				</div>
				<Progressbar
					progress={
						(Number(details.value || 0) /
							Number(isOop ? planOopMax : planDeductible)) *
						100
					}
					color={isOop ? 'green' : 'blue'}
					className="neumorphic"
				></Progressbar>
			</div>
		);
	}

	ElianceGetAccumulatorGauge(maxObj) {
		// {type: 'oopTier1', details: { value: 0.0, max: 3500}}

		const { type, details } = maxObj;
		const isOop = type?.toLowerCase().includes('oop') ? true : false;
		return (
			<div className="accum vbox vcenter w-100" key={type + Math.random()}>
				<div>
					{`${this.text[isOop ? 'oopText' : 'deductibleText']} ($${Number(
						details.value || 0
					)} of $${Number(details.max)})`}
				</div>
				<Progressbar
					progress={(Number(details.value || 0) / Number(details.max)) * 100}
					color={isOop ? 'green' : 'blue'}
					className="neumorphic"
				></Progressbar>
			</div>
		);
	}

	getAccumulators() {
		const config = ConfigService.getConfiguration();
		const integration = _.get(config, 'accumulators.integrationType', null);

		const { customAccumulators } = _.get(userStore, 'userData.group', {});
		// Account for ELIANCE SEE PROFILE.
		if (customAccumulators && Object.keys(customAccumulators).length > 0) {
			const { dependentAccums, expressAccums } = this.data;
			const loadedData =
				this.props.activeDependent && dependentAccums
					? dependentAccums
					: expressAccums;

			// const elianceToCareValetMap = {
			// 	OOP_INT1: 'oopTier1',
			// 	OOP_INT2: 'oopTier2',
			// 	DED_OUTT3: 'deductibleOutNetwork',
			// 	DED_INT1: 'deductibleTier1',
			// 	DED_INT2: 'deductibleTier2',
			// 	OOP_OUTT3: 'oopOutNetwork'
			// };

			const domAccums = [];
			let oopTier1 = loadedData.find((accumDets) => {
				if (accumDets.type === 'OOP_INT1') {
					return accumDets;
				}
			});
			let oopTier2 = loadedData.find((accumDets) => {
				if (accumDets.type === 'OOP_INT2') {
					return accumDets;
				}
			});

			let deductibleTier1 = loadedData.find((accumDets) => {
				if (accumDets.type === 'DED_INT1') {
					return accumDets;
				}
			});

			let deductibleTier2 = loadedData.find((accumDets) => {
				if (accumDets.type === 'DED_INT2') {
					return accumDets;
				}
			});

			let oopOutNetwork = loadedData.find((accumDets) => {
				if (accumDets.type === 'OOP_OUTT3') {
					return accumDets;
				}
			});

			let deductibleOutNetwork = loadedData.find((accumDets) => {
				if (accumDets.type === 'DED_OUTT3') {
					return accumDets;
				}
			});

			if (oopTier1 || deductibleTier1) {
				domAccums.push(
					<div className="profile-group left" key="tier1-accums">
						<p className="group-heading">{this.text.tier1AccumText}</p>
						<div className="group-details vbox vcenter hcenter">
							{oopTier1 // TODO: THIS IS WHAT I HAVE TO DO FOR ALL OF THESE ALONG WITH TESTING ELIANCE AND ALL STANDARD IMPLEMENTATIONS ALONG WITH DEPLOYIN. REF FIND HOF ARRAY FUNCTION.
								? this.ElianceGetAccumulatorGauge({
										type: oopTier1.type,
										details: {
											max: customAccumulators['oopTier1'],
											value: oopTier1.value
										}
								  })
								: ''}
							{deductibleTier1
								? this.ElianceGetAccumulatorGauge({
										type: deductibleTier1.type,
										details: {
											max: customAccumulators[`deductibleTier1`],
											value: deductibleTier1.value
										}
								  })
								: ''}
						</div>
					</div>
				);
			}
			if (oopTier2 || deductibleTier2) {
				domAccums.push(
					<div className="profile-group left" key="tier2-accums">
						<p className="group-heading">{this.text.tier2AccumText}</p>
						<div className="group-details vbox vcenter hcenter">
							{oopTier2
								? this.ElianceGetAccumulatorGauge({
										type: oopTier2.type,
										details: {
											max: customAccumulators['oopTier2'],
											value: oopTier2.value
										}
								  })
								: ''}
							{deductibleTier2
								? this.ElianceGetAccumulatorGauge({
										type: deductibleTier2.type,
										details: {
											max: customAccumulators['deductibleTier2'],
											value: deductibleTier2.value
										}
								  })
								: ''}
						</div>
					</div>
				);
			}
			if (oopOutNetwork || deductibleOutNetwork) {
				domAccums.push(
					<div className="profile-group left" key="out-network-accums">
						<p className="group-heading">{this.text.outNetworkAccumText}</p>
						<div className="group-details vbox vcenter hcenter">
							{oopOutNetwork
								? this.ElianceGetAccumulatorGauge({
										type: oopOutNetwork.type,
										details: {
											max: customAccumulators[`oopOutNetwork`],
											value: oopOutNetwork.value
										}
								  })
								: ''}
							{deductibleOutNetwork
								? this.ElianceGetAccumulatorGauge({
										type: deductibleOutNetwork.type,
										details: {
											max: customAccumulators[`deductibleOutNetwork`],
											value: deductibleOutNetwork.value
										}
								  })
								: ''}
						</div>
					</div>
				);
			}
			return domAccums;
		}

		let currentPlanHeader = '';
		function showHeader(header) {
			if (header === currentPlanHeader) return null;
			if (header && currentPlanHeader !== header) {
				currentPlanHeader = header;
				return header;
			}
		}

		switch (integration) {
			case 'WLT': {
				const accumConfig = _.get(config, 'accumulators', { values: {} });
				const LANG_KEY = appStore.locale === 'en-US' ? 'english' : 'spanish';
				const labels = accumConfig[LANG_KEY];
				const WLTKeys = Object.keys(labels);
				const loadedData = this.props.activeDependent
					? this.data.dependentAccums
					: this.data.expressAccums;
				let { values } = loadedData || { values: {} };
				let accumDataMap = {};
				_.forEach(WLTKeys, (key) => {
					let maxValue = accumConfig[`${key.replace('-S', '')}MAX`];
					//only can display if they set max values
					if (!_.isEmpty(maxValue)) {
						accumDataMap[key.replace('-S', '')] = {
							label: labels[key],
							maxValue: maxValue,
							currentValue: values[key.replace('-S', '')] || '0'
						};
					}
				});
				let accumulatorData = Object.keys(accumDataMap);
				if (accumulatorData.length <= 0) {
					return '';
				}
				const accumulatorElements = [];
				_.forEach(accumulatorData, (accumKey) => {
					let accumData = accumDataMap[accumKey];
					accumulatorElements.push(
						<div
							className="accum vbox vcenter w-100"
							key={`${accumKey}-accumulator`}
						>
							<div>{`${accumData.label} ($${Number(
								accumData.currentValue || 0
							)} of $${Number(accumData.maxValue)})`}</div>
							<Progressbar
								progress={
									(Number(accumData.currentValue || 0) /
										Number(accumData.maxValue)) *
									100
								}
								color={accumulatorElements.length % 2 === 1 ? 'blue' : 'green'}
								className="neumorphic"
							></Progressbar>
						</div>
					);
				});
				return accumulatorElements;
			}
			default: {
				// STANDARD INTREGRATION
				let { accums, dependentAccums, expressAccums } = this.data;
				const loadedData =
					this.props.activeDependent && dependentAccums
						? dependentAccums
						: expressAccums
						? expressAccums
						: accums;

				const domAccums = [];

				loadedData.forEach((acc) => {
					if (acc.type) {
						domAccums.push(
							<>
								<div
									className="profile-group left"
									key={`in-network-accums ${Math.random()}`}
								>
									{showHeader(acc.planId) ? (
										<p className="group-heading">Balances for {acc.planId}</p>
									) : null}
									<div className="group-details vbox vcenter hcenter">
										{acc.type === 'oopMax'
											? this.getAccumulatorGauge({
													type: 'oopMax',
													value: { value: acc.value }
											  })
											: ''}
										{acc.type === 'deductible'
											? this.getAccumulatorGauge({
													type: 'deductible',
													value: { value: acc.value }
											  })
											: ''}
									</div>
								</div>
							</>
						);
					}
				});

				return domAccums;
			}
		}
	}

	render() {
		const { opened, activeDependent, onShareDocument } = this.props;
		const { showCardPreview, shareDocument } = this.data;
		const showPreview = showCardPreview || !!activeDependent;
		const cardWidth = appStore.isMobile
			? windowStore.innerWidth - 32 + 'px'
			: 480 - 32 + 'px';
		const cardHeight = appStore.isMobile
			? (windowStore.innerWidth - 32) * 0.63 + 'px'
			: 480 * 0.6 + 'px';
		const containerWidth = appStore.isMobile ? windowStore.innerWidth : 630;

		const cardFront = this.data.imageFront;
		const cardBack = this.data.imageBack;

		const cardFrontImage = (
			<img className="card-image" style={{ width: '100%' }} src={cardFront} />
		);
		const cardBackImage = (
			<img
				className="card-image"
				style={{ width: '100%' }}
				src={cardBack}
				onClick={this.handlePreview}
			/>
		);

		const imageError = (
			<div className="error-card">
				<img className="card-image" src={found_error} />
				Error Loading Card. Click here to try again.
			</div>
		);
		const preloader = (
			<div className="hbox vcenter hcenter w-100">
				<Preloader size={32} color="blue"></Preloader>
			</div>
		);
		const hasImageError =
			(cardFront && cardFront === 'error') ||
			(cardBack && cardBack === 'error');
		const frontData =
			cardFront && cardFront !== 'error'
				? cardFront !== 'loading'
					? cardFrontImage
					: preloader
				: imageError;
		const backData =
			cardBack && cardBack !== 'error'
				? cardBack !== 'loading'
					? cardBackImage
					: preloader
				: imageError;
		const dependents = _.get(userStore, 'userData.dependents', []);
		const canShare = navigator.share;
		const hasAccumulators =
			(this.data.accums && this.data.accums.length > 0) ||
			this.data.expressAccums !== null ||
			this.data.dependentAccums !== null;
		const loadShareOptions = showCardPreview || !!activeDependent;
		const showShareOptions =
			loadShareOptions && (!!this.data.idCardPdf || !canShare);
		return (
			<div className="wallet-container">
				<div
					style={{
						display: opened ? '' : 'none',
						width: containerWidth,
						transform: '0'
					}}
					className={`hbox wallet-home animated ${
						showPreview ? 'preview-active' : ''
					}`}
				>
					<div
						className={`page-wrapper ${
							!!this.props.activeDependent
								? 'dependent-view'
								: 'main-member-view'
						}`}
						style={{ width: containerWidth + 'px', overflow: 'visible' }}
					>
						<div className={`global-header-popup animated`}>
							<div className="header-ctn hbox vcenter">
								{!this.props.activeDependent && (
									<div className="vbox grow-1 animated">
										<h1>
											{showCardPreview
												? this.text.previewText
												: this.text.walletHeaderTxt}
										</h1>
									</div>
								)}
								{!!this.props.activeDependent && (
									<div className="dep-name vbox grow-1 animated">
										<h1>
											{activeDependent?.firstName} {activeDependent?.lastName}
										</h1>
									</div>
								)}
								{!showCardPreview && (
									<div
										className="back-btn hbox vcenter animated"
										onClick={this.handleClose}
									>
										{this.text.closeTxt}
									</div>
								)}
								{showCardPreview && (
									<div
										className="back-btn hbox vcenter animated"
										onClick={this.handleClose}
									>
										{!!this.props.activeDependent && (
											<i className="fa fa-angle-left"></i>
										)}
										{this.text.backTxt}
									</div>
								)}
							</div>
						</div>
						<div className="content-ctn">
							<div
								className={`insurance-card hbox hcenter ${
									showCardPreview ? 'opened' : ''
								}`}
							>
								<Swiper
									className={`card-swiper ${
										appStore.isMobile ? 'mobile' : 'desktop'
									}`}
									pagination
									navigation={false}
									params={{
										effect: 'coverflow',
										initialSlide: 0,
										grabCursor: true,
										centeredSlides: true,
										slidesPerView: 'auto',
										spaceBetween: 4,
										coverflowEffect: {
											slideShadows: false
										}
									}}
								>
									{cardFront === 'error' ? (
										<div className="error-card">
											<img className="card-image" src={found_error} />
											Error Loading Card. Click here to try again.
										</div>
									) : cardFront === 'loading' ? (
										<div className="hbox vcenter hcenter w-100">
											<Preloader size={32} color="blue"></Preloader>
										</div>
									) : (
										this.data?.idCardsList?.length > 0 &&
										this.data.idCardsList.map((idCard, i) => (
											<SwiperSlide key={i}>
												<FlipCard
													controls
													flipOnClick
													flipEnabled={showCardPreview && !hasImageError}
													direction="horizontal"
													width={cardWidth}
													height={cardHeight}
													onClick={this.handlePreview}
													front={
														<img
															className="card-image"
															style={{ width: '100%' }}
															src={idCard.cardFront}
														/>
													}
													back={
														<img
															className="card-image"
															style={{ width: '100%' }}
															src={idCard.cardBack}
															onClick={this.handlePreview}
														/>
													}
												/>
											</SwiperSlide>
										))
									)}
								</Swiper>
							</div>
							{loadShareOptions && !showShareOptions && (
								<div className={`share-menu vbox animated preloader-ctn`}>
									<Preloader size={32} />
									{this.text.loadingShareOptions}
								</div>
							)}
							{this.props.activeDependent && hasAccumulators && (
								<div>
									<div className="section-header">
										<div className="title">
											{this.text.accumulatorHeaderTxt}
										</div>
									</div>
									<div className="wallet-accumulators">
										{this.getAccumulators()}
									</div>
								</div>
							)}
							<div
								className={`share-menu vbox animated ${
									showShareOptions ? '' : 'hidden'
								}`}
							>
								<Button
									className="neubtn hbox hleft vcenter share-item"
									onClick={() => onShareDocument('email', true)}
								>
									<i className="icon fad fa-envelope" />
									<div className="document-item-detail vbox grow-1">
										<div className="document-name">E-mail ID Card</div>
									</div>
									<div className="fa fa-chevron-right"></div>
								</Button>
								{/* NOTE: TWILIO Deprecated https://support.twilio.com/hc/en-us/articles/223136667-Fax-Support-on-Twilio */}
								{/* <Button className="neubtn hbox hleft vcenter share-item" onClick={() => onShareDocument('fax', true)}>
									<i className="icon fad fa-fax" />
									<div className="document-item-detail vbox grow-1">
										<div className="document-name">Fax ID Card</div>
									</div>
									<div className="fa fa-chevron-right"></div>
								</Button> */}
								<Button
									className="neubtn hbox hleft vcenter share-item"
									onClick={async () => await this.handleWebDownloads('id-card')}
								>
									<i className="icon fad fa-download" />
									<div className="document-item-detail vbox grow-1">
										<div className="document-name">{`${
											canShare ? 'Share' : 'Download'
										} ID Card`}</div>
									</div>
									<div className="fa fa-chevron-right"></div>
								</Button>
							</div>
							<div
								className={`wallet-body animated ${
									showCardPreview ? '' : 'shown'
								}`}
							>
								{dependents && dependents.length > 0 && (
									<>
										<div className="section-header">
											<div className="title">
												{this.text.dependentHeaderTxt}
											</div>
										</div>
										<div className="wallet-dependents">
											{dependents.map((dependent) => {
												return (
													<Button
														className="neubtn hbox hleft vcenter dependent item"
														key={dependent._id}
														onClick={() => this.onSelectDependent(dependent)}
													>
														<i className="icon fad fa-user" />
														<div className="item-detail vbox grow-1">
															<div className="name dependent">
																{dependent.firstName} {dependent.lastName}
															</div>
														</div>
														<div className="fa fa-chevron-right"></div>
													</Button>
												);
											})}
										</div>
									</>
								)}
								{hasAccumulators && (
									<div>
										<div className="section-header">
											<div className="title">
												{this.text.accumulatorHeaderTxt}
											</div>
										</div>
										<div className="wallet-accumulators">
											{this.getAccumulators()}
										</div>
									</div>
								)}
								<div className="section-header">
									<div className="title">{this.text.documentsHeaderTxt}</div>
								</div>
								{!this.props.loadingDocuments && (
									<>
										{this.props.documents && (
											<div className="wallet-documents">
												{this.props.documents
													.sort(
														(curr, next) =>
															new Date(next.lastUpdated).getTime() -
															new Date(curr.lastUpdated).getTime()
													)
													.map((doc) => {
														const date = format(
															new Date(doc.lastUpdated),
															'MMMM do, yyyy'
														);
														return (
															<Button
																className="neubtn hbox hleft vcenter document-item item"
																key={doc._id}
																onClick={() => this.props.onViewDocument(doc)}
															>
																<i className="icon fad fa-files-medical" />
																<div className="item-detail vbox">
																	<div className="document-name name ellipse">
																		{doc.name}
																	</div>
																	<div className="date">{date}</div>
																</div>
																<div className="fa fa-chevron-right"></div>
															</Button>
														);
													})}
											</div>
										)}
									</>
								)}
								{this.props.loadingDocuments && preloader}
							</div>
							<ShareDocumentPanel
								opened={shareDocument.opened}
								onClose={this.handleShareDocumentClose}
								shareMethod={shareDocument.shareMethod}
								isInsuranceCard={shareDocument.isInsuranceCard}
								onSubmit={onShareDocument}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
