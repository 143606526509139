export default function initPolyfills() {
	// @ts-ignore
	if (!String.prototype.format) {
		// eslint-disable-next-line no-extend-native
		// @ts-ignore
		String.prototype.format = function () {
			let result = this;
			const matches = this.match(/(%s)/g);
			for (let i = 0; i < matches.length; i++) {
				let val = arguments[i];
				if (typeof val === 'object') {
					let fixObj = function (obj) {
						if (typeof obj !== 'object') {
							return JSON.stringify(obj);
						}
						// recursive object serialization
						let props = Object.keys(obj)
							.map((key) => `${key}:${fixObj(obj[key])}`)
							.join(',');
						return `{${props}}`;
					};
					let fixArray = function (arr) {
						if (Array.isArray(arr)) {
							let newArr = [];
							_.forEach(arr, (value) => {
								if (typeof value === 'object') {
									newArr.push(fixObj(value));
								}
							});
							return `[${newArr.join(',')}]`;
						} else {
							return JSON.stringify(arr);
						}
					};
					val = Array.isArray(val) ? fixArray(val) : fixObj(val);
					result = result.replace(/("%s")/, val ? val : '');
				} else {
					result = result.replace(/(%s)/, val ? val : '');
				}
			}
			return result;
		};
	}
}
