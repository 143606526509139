import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { getCmpText } from '@/utils/Localization';
import { Button, Preloader } from 'framework7-react';
import CallTimeline from '../call-timeline/CallTimeline';
import { formatDuration, intervalToDuration } from 'date-fns';
import './call-detail.scss';
import Support from '@/components/support/Support';
import RefundForm from '@/components/refund-form/RefundForm';
@observer
export default class CallDetail extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			refundOpened: false
		});
		this.text = getCmpText('CallDetail', {
			back: 'Back',
			callDetail: 'Call Detail',
			loadingTxt: 'Loading',
			timelineTxt: 'Timeline',
			duration: 'Duration'
		});
		autoBind(this);
	}

	back() {
		if (this.props.onBack) {
			this.props.onBack();
		}
	}

	getDuration() {
		let duration = <div></div>;
		let mainEvent = this.getPrimary();
		if (mainEvent) {
			duration = (
				<div className="duration">{formatDuration(intervalToDuration({ start: 0, end: mainEvent.callDuration * 1000 }))}</div>
			);
		}
		return duration;
	}

	getPrimary() {
		let mainEvent = null;
		this.props.callDetails.forEach((event, k) => {
			if (event.callStatus === 'completed') {
				mainEvent = event;
			}
		});
		return mainEvent;
	}

	render() {
		let { back, callDetail, loadingTxt, timelineTxt, duration } = this.text;
		let { loading, callDetails, onBack, onRefund } = this.props;
		if (loading) {
			return (
				<div className="vbox hcenter vcenter h-100">
					<Preloader></Preloader>
					<h2>{loadingTxt}</h2>
				</div>
			);
		} else {
			return (
				<div className="call-detail page-wrapper">
					<div className="global-header-popup">
						<div className="header-ctn">
							<div className="back hbox vcenter" onClick={onBack}>
								<i className="fal fa-chevron-left"></i>
								{back}
							</div>
							<h1>{callDetail}</h1>
						</div>
					</div>
					<div className="duration">{this.getDuration()}</div>
					<div className="section-header">
						<div className="title">{timelineTxt}</div>
					</div>
					<CallTimeline callDetails={callDetails}></CallTimeline>
					<Button className="btn alt red" onClick={() => (this.data.refundOpened = !this.data.refundOpened)}>
						Request a refund →
					</Button>
					<Support />
					<RefundForm opened={this.data.refundOpened} onCancel={() => (this.data.refundOpened = false)} call={this.getPrimary()} />
				</div>
			);
		}
	}
}
