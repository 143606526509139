import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { Popup } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import StorageService from '../../services/StorageService';
import appStore from '../../stores/AppStore';
import ConsumerValidation from './consumer-validation/ConsumerValidation';

const storageService = new StorageService();

@observer
export default class TwoFactorPopup extends Component {
	constructor(props) {
		super();
		this.data = observable({});

		this.text = getCmpText('TwoFactorPopup', {});
		autoBind(this);
	}

	onNext(page) {
		if (this.props.onNext) {
			this.props.onNext(page);
		}
	}

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`two-factor-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
			>
				<ConsumerValidation
					standalone={true}
					onNext={this.onNext}
					onBack={this.props.onBack}
					consumer={this.props.consumer}
					phone={this.props.phone}
				/>
			</Popup>
		);
	}
}
