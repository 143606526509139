import React, { Component } from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import appStore from '@/stores/AppStore';
import { observer, observable } from '@/utils/State';
import './printing-page.scss';
import { autoBind } from '@/utils/GeneralUtils';
import { Chip, Preloader, Link, Popup, Button } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import TabSlider from '@/components/_EXPRESS/tab-slider/TabSlider';
import PrintingGroups, { PrintType } from './PrintingGroups';
import FulfillmentList from './FulfillmentList';
import Table from '@/components/_EXPRESS/table/Table';
import { format } from 'date-fns';

@observer
export default class PrintingPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true,
			loadedFrom: null,
			activeTab: 0,
			groups: [],
			shipments: [],
			idCardFrontBase64String: '',
			idCardBackBase64String: '',
			pdfFrontId: '',
			pdfBackId: '',
			showPDFView: false,
			showOrdersList: false,
			pdfViewerLoading: false,
			focusedOrder: []
		});
		autoBind(this);
	}

	async asyncGetPrintingDetails() {
		setTimeout(() => {
			this.getPrintingDetails();
		}, 0);
	}

	async asyncGetOrderHistory() {
		setTimeout(() => {
			this.loadShipmentHistory();
		}, 0);
	}

	async loadIdCardPrintedFromHistory(batchId, idCardFront, idCardBack) {
		this.data.pdfViewerLoading = true;
		ExpressAPI.getIdCardPrinted(batchId, idCardFront, idCardBack)
			.then((res) => {
				console.log('RES: ', res);
				let uint8ArrayFront = new Uint8Array(res.frontCardPDF.data);
				let blobFront = new Blob([uint8ArrayFront]);
				let base64StringFront;

				let fileReaderFront = new FileReader();
				fileReaderFront.onload = () => {
					base64StringFront = fileReaderFront.result.split(',')[1];
					console.log(base64StringFront);
					this.data.idCardFrontBase64String = base64StringFront;
				};

				let uint8ArrayBack = new Uint8Array(res.backCardPDF.data);
				let blobBack = new Blob([uint8ArrayBack]);
				let base64StringBack;

				let fileReaderBack = new FileReader();
				fileReaderBack.onload = () => {
					base64StringBack = fileReaderBack.result.split(',')[1];
					console.log(base64StringBack);
					this.data.idCardBackBase64String = base64StringBack;
				};

				fileReaderFront.readAsDataURL(blobFront);
				fileReaderBack.readAsDataURL(blobBack);

				this.data.pdfFrontId = idCardFront;
				this.data.pdfBackId = idCardBack;
				this.data.pdfViewerLoading = false;
				this.data.showPDFView = true;
			})
			.catch((err) => console.log('ERR:: ', err));

		return;
	}

	async loadShipmentHistory() {
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		this.data.loading = true;
		ExpressAPI.getShipmentHistory()
			.then((res) => {
				this.data.shipments = res.sort(
					(a, b) =>
						new Date(b.shippedDate).getTime() -
						new Date(a.shippedDate).getTime()
				);
				this.data.loading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.loading = false;
			});
	}

	async getPrintingDetails() {
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		this.data.loading = true;
		ExpressAPI.getGroups()
			.then((res) => {
				this.data.groups = res.sort((a, b) => a.name - b.name);
				this.data.loading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.loading = false;
			});
	}

	setActiveTab(tab) {
		if (tab === 2) {
			this.loadShipmentHistory();
		}
		this.data.activeTab = tab;
	}

	getTabConfig() {
		return {
			0: { name: 'Group Management' },
			1: { name: 'Ordering' },
			2: { name: 'Shipment History' }
		};
	}

	buildTabs() {
		return (
			<TabSlider
				activeTab={this.data.activeTab}
				setActiveTab={this.setActiveTab}
				config={this.getTabConfig()}
			/>
		);
	}

	buildTypeChip(printingType) {
		return (
			<Chip
				text={
					printingType === PrintType.BULK
						? 'Bulk Shipping'
						: printingType === PrintType.INDIVIDUAL
						? 'Individual Shipping'
						: ''
				}
				mediaBgColor={'green'}
				className={`ios shipping-chip ${printingType}`}
			>
				{printingType === PrintType.BULK ? (
					<i
						slot="media"
						className="fas fa-box"
						key={`fulfillment-chip-bulk-${Math.random()}`}
					></i>
				) : (
					<i
						slot="media"
						className="fas fa-envelope"
						key={`fulfillment-chip-ind-${Math.random()}`}
					/>
				)}
			</Chip>
		);
	}

	buildHistoryList() {
		const tableHeaders = [
			{ text: 'Group Name', classList: 'ellipse' },
			{ text: 'ID Card Count', classList: 'ellipse' },
			{ text: 'Fulfillment Type', classList: 'ellipse' },
			{ text: 'Shipped Date', classList: '' }
		];

		const tableRows = this.data.shipments.map((f) => {
			const groupData = this.data.groups.find(
				(group) => group._id.toString() === f.internalGroupId
			);
			return {
				id: f._id,
				onClick: async () => {
					const matchedShipment = {
						...this.data.shipments.find((s) => s._id === f._id)
					};
					console.log('ID of clicked on row: ', matchedShipment);

					matchedShipment.orderFileData.forEach((f, i) =>
						console.log('ID of clicked on row.orderFileData: ' + i, {
							...f
						})
					);

					this.data.focusedOrder = matchedShipment.orderFileData;
					this.data.showOrdersList = true;
				},
				items: [
					{
						text: groupData.name || 'Unknown Group',
						classList: 'ellipse group-name capitalize'
					},
					{ text: f.membersToFulfill.length, classList: 'ellipse' },
					{ text: this.buildTypeChip(f.printingType), classList: 'ellipse' },
					{
						text: format(new Date(f.shippedDate), 'MMMM dd, yyyy'),
						classList: 'capitalize'
					}
				]
			};
		});
		return (
			<div className="order-history-view">
				<Link onClick={this.downloadShippingHistory}>Download History</Link>
				<Table
					id="print-order-history-table"
					headers={tableHeaders}
					rows={tableRows}
				/>
			</div>
		);
	}

	buildSelectedOrderView() {
		const tableHeaders = [
			{ text: 'First Name', classList: 'ellipse' },
			{ text: 'Last Name', classList: 'ellipse' },
			{ text: 'Front PDF ID', classList: 'ellipse-large' },
			{ text: 'Back PDF ID', classList: 'ellipse-large' },
			{ text: 'Fulfillment Type', classList: 'ellipse' }
		];

		const tableRows = this.data.focusedOrder.map((order) => {
			const id = order['Front PDF Callout'];
			return {
				id,
				onClick: async () => {
					const matchOrder = {
						...this.data.focusedOrder.find(
							(s) => s['Front PDF Callout'] === order['Front PDF Callout']
						)
					};
					console.log('ID of clicked on row: ', matchOrder);

					// Method to get images from our sftp
					await this.loadIdCardPrintedFromHistory(
						matchOrder['Batch ID'],
						matchOrder['Front PDF Callout'],
						matchOrder['Back PDF Callout']
					);
				},
				items: [
					{
						text: order['First Name'],
						classList: 'ellipse'
					},
					{ text: order['Last Name'], classList: 'ellipse' },
					{ text: order['Front PDF Callout'], classList: 'ellipse-large' },
					{ text: order['Back PDF Callout'], classList: 'ellipse-large' },
					{ text: this.buildTypeChip(order.types), classList: 'ellipse' }
				]
			};
		});

		return (
			<>
				<div className="individual-order-history-view">
					<Table
						id="individual-order-history-table"
						headers={tableHeaders}
						rows={tableRows}
					/>
				</div>
			</>
		);
	}

	closeSelectedOrderPopup() {
		this.data.showOrdersList = false;
		this.data.showPDFView = false;
		this.data.pdfViewerLoading = false;
		this.data.idCardBackBase64String = '';
		this.data.idCardFrontBase64String = '';
		this.data.pdfFrontId = '';
		this.data.pdfBackId = '';
	}

	getActiveContent() {
		switch (this.data.activeTab) {
			case 0: {
				return (
					<PrintingGroups
						groups={this.data.groups}
						reload={() => {
							this.asyncGetPrintingDetails();
						}}
					></PrintingGroups>
				);
			}
			case 1: {
				return <FulfillmentList groups={this.data.groups}></FulfillmentList>;
			}
			case 2: {
				return this.buildHistoryList();
			}
		}
	}

	triggerBrowserDownloadBehavior(csvHeaders, csvBody) {
		const finalCSVOutput = `${csvHeaders}\n${csvBody.join('\n')}`;
		const exportTrigger = document.createElement('a');
		const blob = new Blob([finalCSVOutput], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);

		this.$f7.dialog.close();
		exportTrigger.setAttribute('href', url);
		exportTrigger.setAttribute('target', '_blank');
		exportTrigger.setAttribute(
			'download',
			`shipment_history-${new Date().getTime()}.csv`
		);
		exportTrigger.click();
	}

	async downloadShippingHistory(evt) {
		this.$f7.dialog.preloader();
		try {
			const batches = _.cloneDeep(this.data.shipments) || [];
			const batchData = _.flattenDeep(
				batches.map((batch) => {
					const batchSummary = batch.orderFileData.map((batchRow) => {
						return {
							'Group ID': batchRow.metaData.groupId,
							'Group Name': batchRow.metaData.groupName,
							'Plan ID': batchRow.metaData.planId,
							'Plan Name': batchRow.metaData.planName,
							'Batch ID': batch._id.toString(),
							'Created Date': batch.createdDate
								? format(new Date(batch.createdDate), 'MM/dd/yyyy')
								: 'CHECK',
							'Approval Date': batch.approvalDate
								? format(new Date(batch.approvalDate), 'MM/dd/yyyy')
								: 'CHECK',
							'Shipped Date': batch.shippedDate
								? format(new Date(batch.shippedDate), 'MM/dd/yyyy')
								: 'CHECK',
							'First Name': batchRow['First Name'],
							'Last Name': batchRow['Last Name'],
							'Member ID': batchRow.metaData.memberId,
							'Shipping Address': batchRow['Street Address 1'],
							'Shipping Address 2': batchRow['Street Address 2'],
							City: batchRow['City'],
							State: batchRow['State'],
							'Zip Code': batchRow['Postal Code'],
							Type: batchRow['Type']
						};
					});
					return batchSummary;
				})
			);
			const csvHeaders = Object.keys(batchData[0]).join(',');
			let csvBody = [];

			for (let i = 0; i <= batchData.length - 1; i++) {
				const data = batchData[i];
				const items = Object.values(data);
				const formattedDisplayData = items.map((x) => {
					return x && typeof x === 'string' ? x.replace(/,/g, '') : x;
				});

				csvBody.push(formattedDisplayData.join(','));

				if (i === batchData.length - 1)
					return this.triggerBrowserDownloadBehavior(csvHeaders, csvBody);
			}
		} catch (err) {
			console.log('error exporting data: ', err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Error downloading data');
		}
	}

	render() {
		if (
			this.data.loadedFrom !== expressStore.currentOrg._id &&
			expressStore.isLoggedIn
		) {
			if (this.data.activeTab === 2) {
				this.asyncGetOrderHistory();
			} else {
				this.asyncGetPrintingDetails();
			}
		}
		return (
			<div className="express-printing-page">
				<h1 className="text-4 mb-xl">Printing</h1>
				<div className="details-text">
					<p>
						Express makes printing and shipping ID cards as simple as a few
						button clicks. In order to get started please enable printing for
						your desired groups and follow the setup prompts.
					</p>
					<p>
						<strong>Don't worry</strong>, we won't start shipping cards until
						your changes go through a manual approval process so feel free to
						setup your groups without worrying about accidentailly printing
						something. If you have any trouble feel free to reach out to
						<a
							onClick={() => {
								let url = 'mailto:help@carevalet.com';
								if (appStore.isApp()) {
									cordova.InAppBrowser.open(
										url,
										'_system',
										'userwkwebview=yes'
									);
								} else {
									window.open(url, '_blank');
								}
							}}
						>
							help@carevalet.com
						</a>
					</p>
				</div>
				{this.data.loading ? (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				) : (
					this.buildTabs()
				)}
				{!this.data.loading && this.getActiveContent()}
				{!this.data.loading && this.data.focusedOrder.length > 0 && (
					<Popup
						className={`selected-order-popup express-form-popup y-scroll`}
						opened={this.data.showOrdersList}
						closeByBackdropClick={false}
						closeOnEscape={false}
						onPopupClosed={this.closeSelectedOrderPopup}
					>
						<div className="popup-header">
							<div className="title grow-1 ellipse ">
								Batch Id - {this.data.focusedOrder[0]['Batch ID'] ?? 'LOADING'}
							</div>
							{this.data.pdfViewerLoading && (
								<div className="hbox vcenter hcenter w-100">
									<Preloader size={32} color="blue"></Preloader>
								</div>
							)}
							<Link popupClose>
								<i className="fad fa-times-square"></i>
							</Link>
						</div>
						{!this.data.showPDFView && (
							<div className="y-scroll">{this.buildSelectedOrderView()}</div>
						)}
						{this.data.showPDFView &&
						this.data.idCardBackBase64String &&
						this.data.idCardFrontBase64String ? (
							<>
								<div className="pdf-view-actions-container">
									<Button
										small
										className="filter-btn ios"
										onClick={() => {
											this.data.idCardBackBase64String = '';
											this.data.idCardBackBase64String = '';
											this.data.pdfBackId = '';
											this.data.pdfFrontId = '';
											this.data.showPDFView = false;
										}}
									>
										Close PDF Viewer
									</Button>
								</div>

								<h2>Front Id - {this.data.pdfFrontId ?? 'LOADING'}</h2>

								<div className="pdf-viewer y-scroll">
									<PDFViewer
										navigation={{
											css: {
												navbarWrapper: 'pdf-viewer-wrapper-override'
											}
										}}
										scale={1}
										hideNavbar={false}
										hideRotation={true}
										navbarOnTop={true}
										document={{
											base64: this.data.idCardFrontBase64String
										}}
									></PDFViewer>
								</div>
								<br />
								<h2>Back Id - {this.data.pdfBackId ?? 'LOADING'}</h2>
								<div className="pdf-viewer y-scroll">
									<PDFViewer
										navigation={{
											css: {
												navbarWrapper: 'pdf-viewer-wrapper-override'
											}
										}}
										scale={1}
										hideNavbar={false}
										hideRotation={true}
										navbarOnTop={true}
										document={{
											base64: this.data.idCardBackBase64String
										}}
									></PDFViewer>
								</div>
							</>
						) : null}
					</Popup>
				)}
			</div>
		);
	}
}
