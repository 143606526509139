import React, { Component } from 'react';
import { Layer, Stage, Rect, Group } from 'react-konva';
import { observer, observable } from '@/utils/State';
import './canvas.scss';
import { autoBind } from '@/utils/GeneralUtils';
import Whiteout from '../whiteout/Whiteout';
import ZoomUndo from '../zoom-undo/ZoomUndo';
import CanvasNodes from '../canvas-nodes/CanvasNodes';
import CanvasHighlight from '../canvas-highlight/CanvasHighlight';
import BackgroundNode from '../background-node/BackgroundNode';
import CanvasTransformer from '../canvas-transformer/CanvasTransformer';
import CanvasContextMenu from '../canvas-context-menu/CanvasContextMenu';
import CanvasFactory from '@/factories/CanvasFactory';
import CanvasMask from './canvas-mask/CanvasMask';
import RectangleNode from '../rectangle-node/RectangleNode';
import TextNode from '../text-node/TextNode';

@observer
export default class Canvas extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.containerRef = React.createRef();
		autoBind(this);
	}

	showMenu(e) {
		const { selectNodes, showMenu } = CanvasFactory.getActiveService();
		e.evt.preventDefault();
		if (e.target.id()) {
			selectNodes([`${e.target.id()}`]);
		}

		showMenu({ x: e.evt.pageX, y: e.evt.pageY });
	}

	renderMenu() {
		if (this.props) {
			const {
				menu: { x, y },
				showMenu
			} = CanvasFactory.getActiveStore();
			if (showMenu) {
				return <CanvasContextMenu x={x} y={y} />;
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.width !== this.props.width || prevProps.height !== this.props.height) {
			CanvasFactory.getActiveService().centerStage();
		}
	}

	componentDidMount() {
		const { documentWidth } = CanvasFactory.getActiveStore();
		if (documentWidth > window.innerWidth * 0.5) {
			let percent = 1 - ((window.innerWidth / documentWidth) * 0.5).toFixed(1);
			CanvasFactory.getActiveService().zoom(-percent);
		}
	}

	render() {
		const { canEdit, width, height, documentWidth, documentHeight } = this.props;
		const canvasService = CanvasFactory.getActiveService();
		const documentPos = {
			x: (width - documentWidth) / 2,
			y: (height - documentHeight) / 2
		};

		return (
			<div className="artboard">
				<CanvasContextMenu />
				<ZoomUndo canEdit={canEdit} />
				<div className="canvas hbox vcenter hcenter grow-1" ref={this.containerRef}>
					<Stage
						width={width}
						height={height}
						ref={canvasService.getStage()}
						onClick={canvasService.handleStageClick}
						onMouseDown={(e) => canvasService.stageMouseDown(e)}
						onMouseUp={(e) => canvasService.stageMouseUp(e)}
						onMouseMove={(e) => canvasService.stageMouseMove(e)}
						onContextMenu={(e) => this.showMenu(e)}
					>
						<Layer id="main-layer">
							<Group
								ref={canvasService.getMainGroup()}
								x={documentPos.x}
								y={documentPos.y}
								width={documentWidth}
								height={documentHeight}
							>
								<BackgroundNode />
								<CanvasNodes />
							</Group>
							{this.props.printRestrictions && (
								<Group x={documentPos.x} y={documentPos.y} width={documentWidth} height={documentHeight} listening={false}>
									<RectangleNode
										type="rectangle"
										x={18.75 / 2}
										y={18.75 / 2}
										width={1013 - 18.75 * 1}
										height={638 - 18.75 * 1}
										draggable={false}
										visible={true}
										opacity={0.25}
										strokeWidth={18.75}
										stroke={'#000'}
										zIndex={900}
										id={'print-bleed'}
										name={'print-bleed'}
										key={'print-bleed'}
										cornerRadius={[0, 0, 0, 0]}
										listening={false}
										viewOnly={true}
									/>
								</Group>
							)}
							{this.props.printRestrictions && this.props.isBack && (
								<Group x={documentPos.x} y={documentPos.y} width={documentWidth} height={documentHeight} listening={false}>
									<RectangleNode
										type="rectangle"
										x={37.5}
										y={0}
										width={150}
										height={37.5}
										draggable={false}
										visible={true}
										fill={'#000'}
										zIndex={900}
										id={'print-top'}
										name={'print-top'}
										key={'print-top'}
										cornerRadius={[4, 4, 4, 4]}
										listening={false}
										viewOnly={true}
									/>
									<TextNode
										type={'text'}
										x={37.5}
										y={0}
										width={150}
										height={37.5}
										draggable={false}
										visible={true}
										fill={'#fff'}
										zIndex={901}
										id={'print-top-text'}
										name={'print-top-text'}
										key={'print-top-text'}
										text={'Reserved'}
										align={'center'}
										fontStyle={'bold'}
										fontSize={14}
										listening={false}
										viewOnly={true}
									/>
									<RectangleNode
										type="rectangle"
										x={37.5}
										y={501}
										width={75}
										height={75}
										draggable={false}
										visible={true}
										fill={'#000'}
										zIndex={900}
										id={'print-bottom'}
										name={'print-bottom'}
										key={'print-bottom'}
										cornerRadius={[4, 4, 4, 4]}
										listening={false}
										viewOnly={true}
									/>
									<TextNode
										type={'text'}
										x={37.5}
										y={501}
										width={75}
										height={75}
										draggable={false}
										visible={true}
										fill={'#fff'}
										zIndex={901}
										id={'print-bottom-text'}
										name={'print-bottom-text'}
										key={'print-bottom-text'}
										text={'R'}
										align={'center'}
										fontStyle={'bold'}
										fontSize={16}
										listening={false}
										viewOnly={true}
									/>
								</Group>
							)}
						</Layer>
						<Layer>
							<CanvasMask
								documentWidth={documentWidth}
								documentHeight={documentHeight}
								documentX={documentPos.x}
								documentY={documentPos.y}
								containerWidth={width}
								containerHeight={height}
							/>
							<Group x={documentPos.x} y={documentPos.y} width={documentWidth} height={documentHeight} name="tools-group">
								<Whiteout />
								<CanvasHighlight />
								<CanvasTransformer />
							</Group>

							<Rect
								stroke="#9ca3af"
								strokeWidth={1}
								x={documentPos.x}
								y={documentPos.y}
								width={documentWidth}
								height={documentHeight}
								listening={false}
								name="border"
							/>
						</Layer>
					</Stage>
				</div>
				<Stage
					id="backStage"
					visible={false}
					width={documentWidth}
					height={documentHeight}
					ref={canvasService.getDownloadStage()}
				></Stage>
				{this.props.printRestrictions && (
					<div className="disclaimer-container">
						<div className="disclaimer">
							Space marked <strong>'R'</strong> or <strong>'Reserved'</strong> are for printing and fulfillment requirements and
							content cannot be placed in the marked areas. Gray border around the card indicates bleed area, no text should be placed
							in this area as it may be cutoff during printing.
						</div>
					</div>
				)}
			</div>
		);
	}
}
