import React, { PureComponent } from 'react';
import { observer } from '../../utils/State';
import logoImage from '../../assets/logoAsset.png';
import consumerLogo from '../../assets/logosmallpurple.png';
import './logo.scss';
import ConfigService from '../../services/ConfigService';
import appStore from '../../stores/AppStore';
import userStore from '../../stores/UserStore';
import amps from '../../assets/amps.png';
import javelina from '../../assets/javelina.png';
import selffundingpartners from '../../assets/selffundingpartners.png';
import healthpac from '../../assets/healthpac.png';
import elap from '../../assets/elap_logo.png';
import sympl from '../../assets/sympl2.png';
import eliance from '../../assets/eliance2.png';
import ccmLogo from '../../assets/ccmlogo.png';
import bioLogo from '../../assets/bioIq.png';
import UserService from '@/services/UserService';

@observer
export default class Logo extends PureComponent {
	getBrandedLogo(branding) {
		let { size = 'med' } = this.props;
		let logo = branding.logo;
		if (branding.logoSrc !== 's3' && !appStore.isExpressPortal && !appStore.demoMode) {
			if (branding.logo.indexOf('amps') >= 0) {
				logo = amps;
			}
			if (branding.logo.indexOf('javelina') >= 0) {
				logo = javelina;
			}
			if (branding.logo.indexOf('healthpac') >= 0) {
				logo = healthpac;
			}
			if (branding.logo.indexOf('sympl') >= 0) {
				logo = sympl;
			}
			if (branding.logo.indexOf('elap') >= 0) {
				logo = elap;
			}
			if (branding.logo.indexOf('eliance') >= 0) {
				logo = eliance;
			}
			if (branding.logo.indexOf('ccm') >= 0) {
				logo = ccmLogo;
			}
			if (branding.logo.indexOf('bioIq') >= 0) {
				logo = bioLogo;
			}
			if (branding.logo.indexOf('selffundingpartners') >= 0) {
				logo = selffundingpartners;
			}
		}
		return (
			<div className={`logo hbox vcenter ${!appStore.isMobile ? 'hcenter' : ''} ${size} ${branding.logoSrc !== 's3' && !appStore.demoMode ? branding.logo : 'configured-logo'}`}>
				<img src={logo} alt="logo" className="third-party" />
			</div>
		);
	}

	carevaletLogo() {
		if (UserService.isConsumer()) {
			let { icon = false, size = 'med' } = this.props;
			return (
				<div className={`logo-consumer hbox vcenter hcenter ${size}`}>
					{icon && <img src={consumerLogo} alt="logo" />}
					<div className="c">Care</div>
					<div className="v">Valet</div>
				</div>
			);
		}
		let { icon = false, size = 'med' } = this.props;
		return (
			<div className={`logo hbox vcenter hcenter ${size}`}>
				{icon && <img src={logoImage} alt="logo" />}
				<div className="c">Care</div>
				<div className="v">Valet</div>
			</div>
		);
	}

	render() {
		let { branding = {} } = ConfigService.getConfiguration();
		let { configLoaded } = appStore;
		let { isLoggedIn } = userStore;
		if (!configLoaded && isLoggedIn) {
			return (
				<div className="logo logo-loading-text hbox vcenter">
					<i className="fad fa-lock-alt"></i>
					<div>Securing Session...</div>
				</div>
			);
		}
		if (!isLoggedIn) {
			return this.carevaletLogo();
		}
		if (configLoaded && branding.logo) {
			return this.getBrandedLogo(branding);
		}
		return this.carevaletLogo();
	}
}
