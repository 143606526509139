import './feedback-form.scss';
import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Link, ListInput, List, Popup, Toolbar } from 'framework7-react';
import userStore from '../../stores/UserStore';
import { getCmpText, getLocalizedDate } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import UserService from '../../services/UserService';
import BasicInput from '../basic-input/BasicInput';

@observer
export default class FeedbackForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			message: '',
			show: this.props.show,
			valid: false,
			selectedCategory: this.props.type || 'USER'
		});
		this.text = getCmpText('FeedbackForm', {
			messageLabel: 'Provide Feedback here:',
			closeFeedbackFormBtn: 'Close',
			title: 'Send Feedback',
			inputHelper: 'Max 250 characters',
			success: 'Thank you for your feedback',
			submitCta: 'Submit',
			generalFeedback: 'General Feedback',
			badDataFeedback: 'Report Bad Data',
			reportBugFeedback: 'Report Bug',
			telehealthFeedback: 'Telehealth Issues',
			prescriptionFeedback: 'Coupon Issue',
			paymentFeedback: 'Payment Issue',
			feedbackCategoriesTitle: 'Feedback Categories',
			feedbackInputLabel: 'Your Feedback: ',
			remainingCharactersLabel: 'Characters left: '
		});
		autoBind(this);
	}
	updateMessage(evt) {
		const value = evt.currentTarget.value;
		if (value.length <= 5000) {
			this.data.message = value;
		}
		this.validateForm();
	}
	validateForm() {
		const hasMessage = this.data.message.length > 0;
		const doesNotExceedLength = this.data.message.length <= 5000;
		const isValid = hasMessage && doesNotExceedLength;
		this.data.valid = isValid;
		return isValid;
	}
	submitFeedback() {
		const isValid = this.validateForm();
		if (!isValid) return;
		const requestData = {
			message: this.data.message,
			type: this.data.selectedCategory,
			userId: userStore.userData.user._id
		};
		if (this.props.npi) {
			requestData.npi = this.props.npi;
		}
		if (this.props.data) {
			requestData.data = this.props.data;
		}
		this.$f7.dialog.preloader();
		UserService.submitFeedback(requestData)
			.then(() => {
				this.$f7.dialog.close();
				let toast = this.$f7.toast.create({
					position: 'center',
					closeTimeout: 2000,
					icon: `<i class="fad fa-check large-icon-toast" ></i>`,
					text: this.text.success
				});
				toast.open();
				this.closeFeedbackForm();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				this.$f7.dialog.alert(_.get(JSON.parse(err.response), 'message', 'An error occured, please try again.'));
			});
	}
	clearMessage() {
		this.data.message = '';
		this.validateForm();
	}
	closeFeedbackForm() {
		this.data.message = '';
		this.props.close();
	}

	selectCategory(evt) {
		const catSelected = evt.currentTarget.getAttribute('data-category');
		this.data.selectedCategory = catSelected;
	}
	render() {
		const feedbackCategoryConfigs = {
			USER: {
				textKey: 'generalFeedback',
				disabled: false
			},
			BAD_DATA: {
				textKey: 'badDataFeedback'
			},
			BUG: {
				textKey: 'reportBugFeedback'
			},
			TELEHEALTH: {
				textKey: 'telehealthFeedback'
			},
			RX: {
				textKey: 'prescriptionFeedback'
			},
			PAYMENT: {
				textKey: 'paymentFeedback'
			},
			disableTest: {
				textKey: 'closeFeedbackFormBtn',
				disabled: true
			}
		};
		const feedbackCategories = Object.keys(feedbackCategoryConfigs);
		return (
			<div>
				<Popup
					opened={this.props.show}
					backdrop
					onPopupClosed={this.closeFeedbackForm}
					className={`feedback-form-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				>
					<Toolbar className="feedback-header">
						<div className="left">{this.text.title}</div>
						<div className="right">
							<div onClick={this.closeFeedbackForm}>
								<i className="fad fa-times-square" />
							</div>
						</div>
					</Toolbar>
					<div className="feedback-form-body y-scroll">
						<p className="categories-title">{this.text.feedbackCategoriesTitle}</p>
						<div className="feedback-categories">
							{feedbackCategories.map((category) => {
								const categoryData = _.get(feedbackCategoryConfigs, category) || {};
								const categoryText = this.text[categoryData.textKey] || categoryData.textKey;
								if (!categoryData.disabled) {
									return (
										<div
											key={`category-item-${category}`}
											data-category={category}
											className={`category-container neubtn ${this.data.selectedCategory === category ? 'button-active' : ''}`}
											onClick={this.selectCategory}
										>
											{categoryText}
										</div>
									);
								}
							})}
						</div>
						<form>
							<p className="feedback-input-label">{this.text.feedbackInputLabel}</p>
							<BasicInput
								type="textarea"
								className="feedback-text-input"
								value={this.data.message}
								name="feedbackMessage"
								onChange={this.updateMessage}
								maxlength="5000"
							/>
							<p className="remaining-characters">
								{this.text.remainingCharactersLabel} {5000 - this.data.message.length} / 5000
							</p>
						</form>
					</div>
					<div className="feedback-footer">
						<Button fill large round onClick={this.submitFeedback} disabled={!this.data.valid}>
							{this.text.submitCta}
						</Button>
					</div>
				</Popup>
			</div>
		);
	}
}
