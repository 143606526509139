import cartStore from '@/stores/CartStore';
import _ from 'lodash';

class CartService {
	addItemToCart(item) {
		cartStore.items.push(item);
		cartStore.description = item.name; //FOR DEMO PURPOSES ONLY
		cartStore.isRecurring = item.isRecurring;
		if (item.isRecurring) {
			cartStore.recurringData = item.recurringData;
		}
		this.calculateTotals();
	}

	calculateTotals() {
		let totals = cartStore.totals;
		let items = _.get(cartStore, 'items');
		if (items.length > 0) {
			items.forEach((item) => {
				let price = parseFloat(_.get(item, 'price'), 0);
				totals.subtotal += Math.round(price * 100) / 100;

				totals.tax += item.isRecurring ? 0 : Math.round(price * 0.08 * 100) / 100;

				totals.qty += item.qty;
			});

			totals.final = totals.tax + totals.subtotal;

			totals.subtotal = totals.subtotal.toFixed(2);
			totals.tax = totals.tax.toFixed(2);
			totals.final = totals.final.toFixed(2);
		}
	}

	resetCart() {
		cartStore.items = [];
		cartStore.totals = {
			subtotal: 0,
			final: 0,
			tax: 0,
			qty: 0
		};
		this.calculateTotals();
	}
}

export default new CartService();
