import appStore from '../stores/AppStore';
import { observable } from '../utils/State';
import _ from 'lodash';
import CloudAPI from './CloudAPI';
import merge from 'deepmerge';
import englishTranslations from './data/englishTranslations.json';
import spanishTranslations from './data/spanishTranslations.json';
import ExpressAPI from '@/services/ExpressAPI';

const baseConfig = {
	telehealth: {
		emergency: '911',
		crisis: '211'
	},
	search: {
		radius: {
			default: {
				default: 25,
				max: 100
			},
			physician: {
				default: 10,
				max: 50
			}
		}
	},
	member: {
		documents: {
			shareOptions: {
				email: true,
				fax: true,
				mail: false,
				download: true
			}
		}
	},
	map: {
		defaultProps: {
			center: {
				lat: 40.07358,
				lng: -76.31455
			},
			zoom: 12,
			defaultOptions: {
				fullscreenControl: false
			},
			mapStyle: 'uber',
			styles: null,
			googleMapKey: 'AIzaSyCkhIG_NEubfJb7FOLEfk0Lc8N9NGQVgmY'
		}
	},
	visitReasons: {
		0: 'Wellness / Preventative',
		1: 'Office Visit',
		2: 'Illness',
		3: 'Follow-up',
		4: 'Other'
	},
	patientSatisfactionSurvey: {
		enabled: true,
		titleKey: 'surveyTitle',
		headerKey: 'surveyHeader',
		subtitleKey: 'surveySubtitle',
		questions: {
			timely: {
				textKey: 'surveyWaitTime',
				type: 'rating',
				defaultValue: '0'
			},
			resolution: {
				textKey: 'surveyConcernResolution',
				type: 'rating',
				defaultValue: '0'
			},
			officeStaff: {
				textKey: 'surveyOfficeStaff',
				type: 'rating',
				defaultValue: '0'
			},
			retention: {
				textKey: 'surveyRetention',
				type: 'rating',
				defaultValue: '0'
			},
			freeText: {
				textKey: 'surveyComments',
				type: 'textInput',
				defaultValue: ''
			},
			submitButton: {
				type: 'button',
				textKey: 'surveySubmit',
				action: 'submit'
			}
		}
	},
	language: {
		english: {
			config: {
				language: 'en',
				region: 'US',
				code: 'en-US',
				displayValues: {
					language: 'English'
				}
			},
			translations: englishTranslations
		},
		spanish: {
			config: {
				language: 'es',
				region: 'MX',
				code: 'es-MX',
				displayValues: {
					language: 'Spanish'
				}
			},
			translations: spanishTranslations
		}
	}
};
let config = observable(baseConfig);
class ConfigService {
	getConfigValue(path, defaultVal) {
		return _.get(this.getConfiguration(), path, defaultVal);
	}

	getConfiguration() {
		return config;
	}

	resetConfig() {
		config = observable(baseConfig);
	}

	async initConfig() {
		let cfg;
		if (!appStore.isExpressPortal) {
			cfg = await CloudAPI.getConfig();
		} else {
			cfg = await ExpressAPI.getConfig();
		}
		config = observable(merge(_.cloneDeep(config), cfg));
		if (_.get(config, 'branding.logoSrc') === 's3') {
			//handle s3 logos here to prevent urls from expiring on installed apps & to keep us from doing the fetch everytime we load the img tag
			try {
				const logoRes = await fetch(config.branding.logo);
				const logoBlob = await logoRes.blob();
				const logoDataUrl = URL.createObjectURL(logoBlob);
				config.branding.logo = logoDataUrl;
			} catch (err) {
				console.log('error loading s3 logo: ', err);
				config.branding.logo = null;
				config.branding.logoSrc = 'error';
			}
		}
		appStore.configLoaded = true;
		window.configuration = config;
		return 'gogo';
	}
}
export default new ConfigService();
