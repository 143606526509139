import React, { Component } from 'react';
import { autoBind, getStripeKey } from '../../utils/GeneralUtils';
import { getCmpText } from '../../utils/Localization';
import { observer, observable } from '../../utils/State';
import userStore from '@/stores/UserStore';
import './checkout.scss';
import CardCarousel from '@/components/card-carousel/CardCarousel';
import { Button } from 'framework7-react';
import UserBillingService from '../../services/UserBillingService';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import cartStore from '../../stores/CartStore';
import CartService from '../../services/CartService';
import _ from 'lodash';
import CreditCardInput from '../credit-card-input/CreditCardInput';
import BasicInput from '../basic-input/BasicInput';
import Authorizing from './authorizing-loader/AuthorizingLoader';
import ApprovedCredit from '@/assets/approved_credit.svg';
import CardDecline from '@/assets/card_decline.svg';
import appStore from '@/stores/AppStore';
import TransactionHistory from '../billing-popup/transaction-history/TransactionHistory';
import CloudAPI from '@/services/CloudAPI';
@observer
export class CheckoutPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			closing: false,
			activePage: 'payment',
			selectedPaymentMethodIndex: 0,
			usingWallet: true,
			cardValid: false,
			paymentMessage: '',
			saveCard: false,
			fullName: _.get(userStore, 'userData.user.firstName') + ' ' + _.get(userStore, 'userData.user.lastName')
		});

		this.text = getCmpText('CheckoutPage', {
			newCardTxt: 'I want to use a different card',
			useWalletTxt: 'I want to use a saved card',
			subtotalTxt: 'Subtotal',
			taxTxt: 'Tax',
			finalTotalTxt: 'Total',
			paymentSuccessTxt: 'Approved!',
			completeBtnTxt: 'Go back',
			tryAgainTxt: 'Try again',
			saveCardTxt: 'Save card for future use',
			close: 'Close',
			title: 'Checkout'
		});
		autoBind(this);
	}

	onClose() {
		if (this.data.activePage !== 'authorizing') {
			CartService.resetCart();
			this.data.closing = true;

			setTimeout(() => {
				this.data.closing = false;
				if (this.props.onCloseCheckout) {
					this.resetData();
					this.props.onCloseCheckout();
				}
			}, 350);
		}
	}

	closeAndComplete() {
		CartService.resetCart();
		this.data.closing = true;
		CloudAPI.getSubscriptions().then(res => {
			userStore.setSubscriptionState(res);
		});
		setTimeout(() => {
			this.data.closing = false;
			if (this.props.onCloseCheckout) {
				this.resetData();
				this.props.onComplete();
			}
		}, 350);
	}

	resetData() {
		this.data.activePage = 'payment';
		this.data.selectedPaymentMethodIndex = 0;
		this.data.usingWallet = true;
		this.data.paymentMessage = '';
		this.data.saveCard = false;
		this.data.fullName = _.get(userStore, 'userData.user.firstName') + ' ' + _.get(userStore, 'userData.user.lastName');
	}

	async onPurchase() {
		let { paymentMethods } = userStore;
		let { usingWallet } = this.data;
		this.data.activePage = 'authorizing';
		if (cartStore.isRecurring) {
			const cardElement = this.props.elements.getElement(CardElement);
			let paymentMethod = {};

			try {
				const req = {
					items: [
						{
							price: cartStore.items[0].priceId
						}
					],
					payment_behavior: 'default_incomplete',
					expand: ['latest_invoice.payment_intent'],
				};
				const resp = await CloudAPI.createSubscription(req);
				console.log(`Using wallet ${usingWallet}`);
				if (usingWallet && paymentMethods.length > 0) {
					paymentMethod = {
						payment_method: userStore.paymentMethods[this.data.selectedPaymentMethodIndex]['id']
					};
				} else {
					paymentMethod = {
						payment_method: {
							card: cardElement,
							billing_details: {
								name: this.data.fullName
							}
						}
					};

					if (this.data.saveCard) {
						paymentMethod.setup_future_usage = 'off_session';
					}
				}
				
				let paymentResponse = await this.requestPayment(resp.latest_invoice.payment_intent.client_secret);
				if (paymentResponse.error) {
					this.data.activePage = 'error';
					let errorArray = paymentResponse.error.message.split(' ');
					this.data.paymentMessage = paymentResponse.error.message;
					if (errorArray.includes('API')) {
						this.data.paymentMessage = 'Error connecting to payment processor, contact support!';
					}
					await CloudAPI.cancelSubscription(resp.id);
				} else {
					let transactionId = _.get(paymentResponse, 'paymentIntent.id');
					await UserBillingService.creditUserToken(_.get(cartStore, 'totals.qty', 0), transactionId);
					
					this.data.activePage = 'approved';
					setTimeout(this.closeAndComplete, 1500);

					//TODO handle success stuff, clearing of data, etc
					//! This checks for active subs and enabled the VPC plan via Akos
					await CloudAPI.enableVPC();
					// this.$f7.dialog.close();
				}
			
			} catch (e) {
				console.log(e);
				this.data.activePage = 'error';
			}
			// this.data.activePage = 'payment';
		} else {
			let response = await this.sendPaymentIntent();
			let { client_secret } = response;
			try {
				let paymentResponse = await this.requestPayment(client_secret);
				if (paymentResponse.error) {
					this.data.activePage = 'error';
					let errorArray = paymentResponse.error.message.split(' ');
					this.data.paymentMessage = paymentResponse.error.message;
					if (errorArray.includes('API')) {
						this.data.paymentMessage = 'Error connecting to payment processor, contact support!';
					}
				} else {
					let transactionId = _.get(paymentResponse, 'paymentIntent.id');
					await UserBillingService.creditUserToken(_.get(cartStore, 'totals.qty', 0), transactionId);

					this.data.activePage = 'approved';
					setTimeout(this.closeAndComplete, 1500);

					//TODO handle success stuff, clearing of data, etc
					// this.$f7.dialog.close();
				}
			} catch (e) {
				this.data.activePage = 'error';
				console.log(e);
			}
		}
	}

	async sendPaymentIntent() {
		let { paymentMethods } = userStore;
		let { usingWallet } = this.data;
		try {
			if (paymentMethods.length > 0 && usingWallet) {
				return await UserBillingService.getPaymentIntent(
					cartStore.totals.final * 100,
					cartStore.description,
					userStore.paymentMethods[this.data.selectedPaymentMethodIndex].customer
				);
			} else {
				return await UserBillingService.getPaymentIntent(cartStore.totals.final * 100, cartStore.description);
			}
		} catch (e) {
			//TODO HANDLE ERROR
		}
	}

	async requestPayment(secret) {
		const cardElement = this.props.elements.getElement(CardElement);
		let { paymentMethods } = userStore;
		let { usingWallet } = this.data;
		let paymentMethod = {};
		if (usingWallet && paymentMethods.length > 0) {
			paymentMethod = {
				payment_method: userStore.paymentMethods[this.data.selectedPaymentMethodIndex]['id']
			};
		} else {
			paymentMethod = {
				payment_method: {
					card: cardElement,
					billing_details: {
						name: this.data.fullName
					}
				}
			};

			if (this.data.saveCard) {
				paymentMethod.setup_future_usage = 'off_session';
			}
		}
		try {
			const result = await this.props.stripe.confirmCardPayment(secret, paymentMethod);
			return result;
		} catch (e) {
			console.log(e);
			return '';
		}
	}

	getAnimation() {
		return this.props.opened && this.data.closing ? 'slideOutDown' : 'slideInUp';
	}

	onCardSelect(index) {
		this.data.selectedPaymentMethodIndex = index;
	}

	isPurchaseDisabled() {
		if (this.data.usingWallet && userStore.paymentMethods.length > 0) {
			return false;
		} else if (this.data.fullName !== '' && this.data.cardValid) {
			return false;
		} else {
			return true;
		}
	}

	onCreditChange(e) {
		this.data.cardValid = e.complete;
	}

	buildActiveContent() {
		let { subtotalTxt, taxTxt, finalTotalTxt } = this.text;
		let { subtotal, tax, final } = cartStore.totals;
		let { activePage } = this.data;

		return (
			<div className="checkout-container">
				<div className={`checkout-section ${activePage === 'payment' ? '' : 'hidden'}`}>
					{/* TODO HANDLE LOADING */}
					{this.renderPaymentEntry()}
					<div className="totals">
						{!cartStore.isRecurring && (
							<>
								<div className="total-pair">
									<div className="key">{subtotalTxt}</div>
									<div className="value">{subtotal}</div>
								</div>
								<div className="total-pair">
									<div className="key">{taxTxt}</div>
									<div className="value">{tax}</div>
								</div>
							</>
						)}

						<div className="total-pair final">
							<div className="key">{finalTotalTxt}</div>
							<div className="value">
								{final} {cartStore.isRecurring && `/ ${cartStore.recurringData?.interval}`}{' '}
							</div>
						</div>
					</div>
					<Button
						fill
						large
						className="round-btn purple"
						onClick={this.onPurchase}
						id="purchase-button"
						disabled={this.isPurchaseDisabled()}
					>
						Purchase
					</Button>
				</div>
				<div className={`checkout-section authorizing middle ${activePage === 'authorizing' ? '' : 'hidden'}`}>
					<Authorizing />
				</div>
				<div className={`checkout-section approved middle ${activePage === 'approved' ? '' : 'hidden'}`}>
					<div className="approved-image">
						<img src={ApprovedCredit} alt="Approved!" />
					</div>
					<h2>{this.text.paymentSuccessTxt}</h2>
					<Button fill large className="round-btn purple" onClick={this.closeAndComplete} id="complete-button" disabled={false}>
						{this.text.completeBtnTxt}
					</Button>
					{/* TODO - Add complete button to close the entire dialog */}
				</div>
				<div className={`checkout-section middle error ${activePage === 'error' ? '' : 'hidden'}`}>
					<div className="declined-image">
						<img src={CardDecline} alt="error" />
					</div>
					<h2>{this.data.paymentMessage}</h2>
					<Button
						fill
						large
						className="round-btn purple"
						onClick={() => {
							this.data.activePage = 'payment';
						}}
						id="complete-button"
						disabled={false}
					>
						{this.text.tryAgainTxt}
					</Button>
				</div>
			</div>
		);
	}

	toggleWallet() {
		this.data.saveCard = false;
		this.data.usingWallet = !this.data.usingWallet;
	}

	onChange(evt) {
		let target = evt.currentTarget;
		console.log(target);
		if (target.key === 'cc-name') {
			this.data.fullName = target.value;
		} else {
			this.data[target.name] = target.value;
		}
	}

	renderPaymentEntry() {
		let { paymentMethods } = userStore;
		let { usingWallet } = this.data;
		let { newCardTxt, useWalletTxt } = this.text;
		let showWallet = paymentMethods.length > 0 && usingWallet;
		return (
			<div>
				<div className={showWallet ? '' : 'hidden'}>
					<CardCarousel paymentMethods={paymentMethods} onCardSelect={this.onCardSelect} />
					<Button className="btn alt" onClick={this.toggleWallet}>
						{newCardTxt} →
					</Button>
				</div>
				<div className={`manual-entry ${showWallet ? 'hidden' : ''}`}>
					<BasicInput
						name="fullName"
						label="Full Name"
						value={this.data.fullName}
						type="text"
						placeholder="John Doe"
						onChange={this.onChange}
					></BasicInput>
					<CreditCardInput name="creditCard2" label="Card Details" onChange={this.onCreditChange} stripeKey={getStripeKey()} />
					<div className="save-card-toggle hbox vcenter" onClick={() => (this.data.saveCard = !this.data.saveCard)}>
						<div className="toggle-indicator vbox vcenter hcenter">{this.data.saveCard && <i className="fad fa-dot-circle"></i>}</div>
						<div className="text">{this.text.saveCardTxt}</div>
					</div>
					{paymentMethods.length > 0 && (
						<Button className="btn alt" onClick={this.toggleWallet}>
							{useWalletTxt} →
						</Button>
					)}
				</div>
			</div>
		);
	}

	render() {
		if (!this.props.opened) {
			return '';
		}

		return (
			<div className={`checkout-page ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<div className={`checkout-panel animated ${this.getAnimation()}`}>
					<div className="panel-header hbox hright">
						{/* We are going to want to change how this section gets rendered and show / hide the header */}
						<div className="cancel-btn" onClick={this.onClose}>
							{this.text.close}
						</div>
					</div>
					<h2>{this.text.title}</h2>
					{this.buildActiveContent()}
				</div>
			</div>
		);
	}
}

//TODO Move all this to a seperate wrapper component within page
export const StripeCheckoutPage = (props) => {
	return (
		<ElementsConsumer>{({ elements, stripe }) => <CheckoutPage elements={elements} stripe={stripe} {...props} />}</ElementsConsumer>
	);
};
