import { observer, observable } from '@/utils/State';
import React, { Component } from 'react';
import { Range } from 'framework7-react';
import BasicInput from '@/components/basic-input/BasicInput';
@observer
export default class FontSizeInput extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
	}

	render() {
		const { fontSize, onChange } = this.props;
		return (
			<div className="vbox property-section">
				<div className="property-label">Size</div>
				<div className="property-edit">
					<div className="hbox vcenter range-selector">
						<div className="icon">
							<i className="fad fa-text-size"></i>
						</div>
						<Range min={1} max={200} step={1} value={fontSize} className="grow-2" onRangeChange={onChange} />
						<div className="range-value">
							<BasicInput
								key={`express-form-input`}
								onChange={(e) => {
									const val = e.target.value || '';
									const cleanValue = val.replace(/\D/g, '');
									onChange(cleanValue);
								}}
								validate
								name="range-value"
								type="text"
								value={fontSize}
								className="range-value"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
