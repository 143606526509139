import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../utils/State';
import { getCmpText } from '../../../utils/Localization';
import { autoBind } from '../../../utils/GeneralUtils';
import MemberDocumentViewer from '../../../components/member-document-viewer/MemberDocumentViewer';
import './appt-management.scss';
import CloudAPI from '../../../services/CloudAPI';
import adminStore from '../../../stores/AdminStore';
import { format, addDays } from 'date-fns';
import {
	List,
	ListInput,
	Button,
	Panel,
	Popup,
	Link,
	Segmented
} from 'framework7-react';
import ExternalService from '../../../services/ExternalService';
import MessagingForm from '../../../components/messaging-form/MessagingForm';

//TODO mande global enums
const ApptStatus = {
	REQUESTED: 'Requested',
	SCHEDULED: 'Scheduled',
	CONFIRMED: 'Confirmed',
	CANCELED: 'Canceled'
};

@observer
class AppointmentManagement extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			requested: [],
			scheduled: [],
			confirmed: [],
			previous: [],
			selectedLocation: '',
			changeProviderData: {},
			showLocationPicker: false,
			schedulerOpen: false,
			showScheduler: false,
			activeAppt: null,
			activeUser: null,
			scheduledDate: null,
			tgglType: 'patient',
			showDocViewer: false,
			showMessagingForm: false
		});
		this.text = getCmpText('AppointmentManagement', {
			scheduled: 'Upcoming',
			previous: 'Previous',
			emptyText: 'You Have No Appointments',
			searchText: 'Find Care',
			patientLabel: 'Patient:',
			noteLabel: 'Details:',
			reqDate: 'Requested Date:',
			reqTime: 'Requested Time:',
			schTime: 'Scheduled Time:',
			schDate: 'Scheduled Date:',
			reasonLabel: 'Reason:',
			reason0: 'Wellness / Preventative',
			reason1: 'Office Visit',
			reason2: 'Illness',
			reason3: 'Follow-up',
			reason4: 'Other',
			cancel: 'Cancel',
			confirm: 'Confirm Appointment',
			callText: 'Call',
			navText: 'Navigate',
			firstText: 'First Available',
			userCancled: 'User Canceled Appointment',
			confirmApptText: 'Confirm Details Below',
			confirmedText: 'Confirmed',
			confirmCancel: 'Are you sure you want to cancel this appointment?',
			emailDocumentsCta: 'Email ID Card',
			cancelApptCta: 'Cancel Appt',
			rescheduleApptCta: 'Re-schedule Appt',
			scheduleApptCta: 'Schedule Appt'
		});
		autoBind(this);
	}

	componentDidMount() {
		console.log('AppointmentManagement');
		this.fetchAppointments();
	}

	fetchAppointments() {
		return CloudAPI.getAppointments()
			.then((res) => {
				let scheduled = [];
				let previous = [];
				let requested = [];
				let confirmed = [];

				_.forEach(res, (appt) => {
					if (appt.status === ApptStatus.REQUESTED) {
						requested.push(appt);
					} else if (appt.status === ApptStatus.CANCELED) {
						previous.push(appt);
					} else if (
						appt.status === ApptStatus.CONFIRMED &&
						new Date(appt.scheduledDate) > new Date()
					) {
						confirmed.push(appt);
					} else if (
						appt.status === ApptStatus.SCHEDULED &&
						new Date(appt.scheduledDate) > new Date()
					) {
						scheduled.push(appt);
					} else {
						previous.push(appt);
					}
				});
				this.data.requested = requested;
				this.data.scheduled = scheduled;
				this.data.confirmed = confirmed;
				this.data.previous = previous;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	fetchUserData() {
		CloudAPI.getUserData(this.data.activeAppt.scheduler)
			.then((res) => {
				this.data.activeUser = res;
				const languageCode = _.get(res, 'user.languageCode');
				let languagePreference;
				switch (languageCode) {
					case 'en-US':
						languagePreference = 'English';
						break;
					case 'es-MX':
						languagePreference = 'Spanish';
						break;
					default:
						break;
				}
				if (languagePreference) {
					_.set(
						this.data,
						'activeAppt.patient.languagePreference',
						languagePreference
					);
					_.set(
						this.data,
						'activeAppt.patient.languageCode',
						_.get(res, 'user.languageCode', 'en-US')
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	onSchedulerClose() {
		this.closeMessagingForm();
		this.data.showScheduler = false;
		this.data.schedulerOpen = false;
		this.data.activeAppt = null;
		this.data.activeUser = null;
		this.data.tgglType = 'patient';
		this.data.scheduledDate = null;
		this.$f7.dialog.preloader();
		this.fetchAppointments();
	}

	onCancelAppt() {
		this.$f7.dialog.confirm('', 'Are you sure?', () => {
			this.$f7.dialog.preloader();
			CloudAPI.cancelAppointment(this.data.activeAppt)
				.then(() => {
					this.onSchedulerClose();
					this.fetchAppointments().then(() => {
						this.$f7.dialog.close();
					});
				})
				.catch((err) => {
					console.log(err);
					this.$f7.dialog.close();
				});
		});
	}

	onScheduleAppt() {
		this.data.showScheduler = true;
	}

	onOpenDocumentViewer() {
		this.data.showDocViewer = true;
	}

	closeDocumentViewer() {
		this.data.showDocViewer = false;
	}

	getPatientName(patient) {
		return _.startCase(`${patient.firstName} ${patient.lastName}`);
	}

	getProviderName(appt) {
		return `${_.startCase(
			_.get(appt.provider, 'fullName', '').split(' llc').join(' LLC')
		)} ${_.get(appt.provider, 'personDetails.credential', '')}`;
	}

	getTable(items, h1, h2, h3) {
		return (
			<div className="data-table">
				<table>
					<thead>
						<tr>
							<th>{h1}</th>
							<th>{h2}</th>
							<th>{h3}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>{items}</tbody>
				</table>
			</div>
		);
	}

	getTableRows(appt, col1, col2, col3, btnText, clickHandler) {
		return (
			<tr className="appt-item" key={appt._id}>
				<td className="patient">{col1}</td>
				<td className="provider">{col2}</td>
				<td className="time">
					{col3}{' '}
					{appt.status === ApptStatus.CANCELED ? (
						<span>Canceled</span>
					) : col3 === 'Updated Date' ? (
						<span className="green">Completed</span>
					) : (
						''
					)}
				</td>
				<td>
					<Button
						round
						small
						fill={btnText === 'Schedule' ? true : false}
						onClick={clickHandler}
					>
						{btnText}
					</Button>
				</td>
			</tr>
		);
	}

	getActionBtns() {
		let activeAppt = _.get(this.data, 'activeAppt', {}) || {};
		if (
			activeAppt.status !== ApptStatus.CANCELED ||
			(activeAppt.scheduledDate &&
				new Date(activeAppt.scheduledDate) < new Date())
		) {
			return (
				<>
					<Button round onClick={this.onCancelAppt}>
						{this.text.cancelApptCta}
					</Button>
					<Button round onClick={this.onOpenDocumentViewer}>
						Share Documents
					</Button>
					<Button fill round onClick={this.onScheduleAppt} color="purple">
						{[ApptStatus.SCHEDULED, ApptStatus.CONFIRMED].indexOf(
							activeAppt.status
						) >= 0
							? this.text.rescheduleApptCta
							: this.text.scheduleApptCta}
					</Button>
				</>
			);
		}
	}

	buildLists() {
		let { requested, scheduled, confirmed, previous } = this.data;
		let reqItems = [];
		let schItems = [];
		let cnfrItems = [];
		let pastItems = [];
		let reqs = _.cloneDeep(requested).sort((a, b) => {
			return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1;
		});
		_.forEach(reqs, (appt) => {
			reqItems.push(
				this.getTableRows(
					appt,
					this.getPatientName(appt.patient),
					this.getProviderName(appt),
					appt.requestType === 'first'
						? 'First Available'
						: `${format(
								new Date(_.get(appt, 'requestDate[0]', new Date())),
								'MM/dd/yyyy'
						  )} - ${format(
								new Date(
									_.get(
										appt,
										'requestDate[1]',
										_.get(appt, 'requestDate[0]', new Date())
									)
								),
								'MM/dd/yyyy'
						  )}`,
					'Schedule',
					() => {
						this.data.activeAppt = appt;
						this.data.schedulerOpen = true;
						this.fetchUserData();
					}
				)
			);
		});
		_.forEach(scheduled, (appt) => {
			schItems.push(
				this.getTableRows(
					appt,
					this.getPatientName(appt.patient),
					this.getProviderName(appt),
					format(new Date(appt.scheduledDate), 'MM/dd/yyyy p'),
					'Re-Schedule',
					() => {
						this.data.activeAppt = appt;
						this.data.schedulerOpen = true;
						this.fetchUserData();
					}
				)
			);
		});
		_.forEach(confirmed, (appt) => {
			cnfrItems.push(
				this.getTableRows(
					appt,
					this.getPatientName(appt.patient),
					this.getProviderName(appt),
					format(new Date(appt.scheduledDate), 'MM/dd/yyyy p'),
					'View Details',
					() => {
						this.data.activeAppt = appt;
						this.data.schedulerOpen = true;
						this.fetchUserData();
					}
				)
			);
		});
		let prev = _.cloneDeep(previous).sort((a, b) => {
			return new Date(a.updatedDate) < new Date(b.updatedDate) ? 1 : -1;
		});
		_.forEach(prev, (appt) => {
			pastItems.push(
				this.getTableRows(
					appt,
					this.getPatientName(appt.patient),
					this.getProviderName(appt),
					appt.scheduledDate
						? format(new Date(appt.scheduledDate), 'MM/dd/yyyy p')
						: 'Never Scheduled',
					'View Details',
					() => {
						this.data.activeAppt = appt;
						this.data.schedulerOpen = true;
						this.fetchUserData();
					}
				)
			);
		});
		return (
			<List>
				<div className="list-header vbox vcenter hcenter blue">
					Appointment Requests <h5>(Newest First)</h5>
				</div>
				{reqItems.length > 0 ? (
					this.getTable(reqItems, 'Patient', 'Provider', 'Requested Time')
				) : (
					<div className="empty">No Requested Appointments</div>
				)}
				<div className="list-header purple">Awaiting User Confirmation</div>
				{schItems.length > 0 ? (
					this.getTable(schItems, 'Patient', 'Provider', 'Scheduled Time')
				) : (
					<div className="empty">No Scheduled Appointments</div>
				)}
				<div className="list-header green">User Confirmed</div>
				{cnfrItems.length > 0 ? (
					this.getTable(cnfrItems, 'Patient', 'Provider', 'Scheduled Time')
				) : (
					<div className="empty">No Confirmed Appointments</div>
				)}
				<div className="list-header">Completed / Canceled</div>
				{pastItems.length > 0 ? (
					this.getTable(pastItems, 'Patient', 'Provider', 'Updated Date')
				) : (
					<div className="empty">No Canceled / Completed Appointments</div>
				)}
			</List>
		);
	}

	getPrettyObject(data, field) {
		return (
			<pre>
				{JSON.stringify(_.get(data, field, {}), null, 2)
					.split('"')
					.join('')
					.replace('{', '')
					.replace('}', '')
					.replace('[', '')
					.replace(']', '')}
			</pre>
		);
	}

	handleInfoField(data, field) {
		try {
			if (
				field === 'dob' ||
				(field.toLowerCase().indexOf('date') >= 0 &&
					typeof data[field] !== 'object')
			) {
				return format(new Date(_.get(data, `${field}`, '')), 'MM/dd/yyyy');
			}
			if (field === 'address') {
				try {
					return this.getPrettyObject(data, 'address');
				} catch (err) {
					console.log(err);
					return JSON.stringify(_.get(data, 'address', {}), null, 2);
				}
			}
			if (field === 'reason') {
				return _.get(this, `text.reason${data[field]}`, 'Other');
			}
			if (field === 'requestDate') {
				return data.requestType === 'first'
					? this.text.firstText
					: `${format(
							new Date(_.get(data, 'requestDate[0]', new Date())),
							'MM/dd/yyyy'
					  )} - ${format(
							new Date(
								_.get(
									appt,
									'requestDate[1]',
									_.get(appt, 'requestDate[0]', new Date())
								)
							),
							'MM/dd/yyyy'
					  )}`;
			}
			if (typeof data[field] === 'object') {
				return this.getPrettyObject(data, field);
			}
		} catch (err) {
			console.log(`Error on field : ${field}`);
		}
		if (_.isEmpty(_.get(data, field))) {
			return 'N/A';
		}
		return _.get(data, `${field}`, 'N/A').toString();
	}
	openMessagingForm() {
		const phone = _.get(
			this.data,
			'activeUser.user.contactInfo.phone',
			_.get(this.data, 'activeAppt.patient.phone')
		);
		const email = _.get(
			this.data,
			'activeUser.user.contactInfo.email',
			_.get(this.data, 'activeAppt.patient.email')
		);
		const devices = _.get(this.data, 'activeUser.user.devices');
		const patientData = _.get(this.data, 'activeAppt.patient', {});
		adminStore.pendingRecipients = [
			{
				memberUid: _.get(this.data, 'activeAppt.patient._id'),
				firstName: _.get(this.data, 'activeAppt.patient.firstName'),
				lastName: _.get(this.data, 'activeAppt.patient.lastName'),
				phone: _.clone(phone),
				email: _.clone(email),
				devices: _.clone(devices),
				languageDisplay: _.get(
					this.data,
					'activeAppt.patient.languagePreference'
				),
				language: _.get(this.data, 'activeAppt.patient.languageCode')
			}
		];
		this.data.showMessagingForm = true;
	}
	buildPatientInfo() {
		let items = [];
		let appt = _.get(this.data, 'activeAppt', {});
		let excluded = [
			'_id',
			'dependents',
			'relation',
			'status',
			'insurerCode',
			'startDate',
			'termDate',
			'coverageType'
		];
		_.forEach(Object.keys(_.get(appt, `patient`, {})), (field) => {
			if (excluded.indexOf(field) < 0) {
				items.push(
					<div key={`lv-pair-${Math.random()}`}>
						<div className="label">{field}:</div>
						<div className={`field ${field.split(' ').join('')}`}>
							{this.handleInfoField(appt.patient, field)}
						</div>
					</div>
				);
			}
		});
		if (this.data.activeUser) {
			items.push(
				<div className="user-data" key="other-user-data">
					<div className="label">Phone:</div>
					<div className="field">
						{_.get(this, 'data.activeUser.user.contactInfo.phone', 'Unknown')}
					</div>
					<div className="label">Network:</div>
					<div className="field">
						{_.get(this, 'data.activeUser.group.healthNetworkName', 'Unknown')}
					</div>
				</div>
			);
		}

		return (
			<div className="info-data y-scroll">
				<div className="actions hbox vcenter hcenter">
					<Button
						large
						onClick={() => {
							const patientPhone = _.get(
								this,
								'data.activeUser.user.contactInfo.phone',
								''
							);
							ExternalService.handleExternalRequest('call', patientPhone);
						}}
					>
						Call Patient
					</Button>
					<Button large onClick={this.openMessagingForm}>
						Message Patient
					</Button>
				</div>
				{items}
			</div>
		);
	}

	getLocationsPicker() {
		console.log('Calling get locations picker');
		let list = [];
		let { changeProviderData, selectedLocation } = this.data;
		_.forEach(changeProviderData.locations, (l) => {
			list.push(
				<div
					className="location-list-item hbox vcenter ellipse"
					key={l._id}
					onClick={() => {
						this.data.selectedLocation = l._id;
					}}
				>
					<i
						className={`fad ${
							l._id === selectedLocation ? 'fa-check' : ''
						} m-r-16`}
					/>
					<div className="ellipse">{`${l.street1} ${l.city} ${l.state} ${l.zip}`}</div>
				</div>
			);
		});
		return (
			<div className="provider-change-location-picker">
				<div className="change-dialog elevation-12 animated zoomIn">
					<div className="title">Select A Location</div>
					<div className="locations-ctn y-scroll">{list}</div>
					<div className="btn-ctn hbox vcenter hcenter">
						<Button
							large
							round
							className="m-r-16"
							onClick={() => {
								this.data.selectedLocation = null;
								this.data.changeProviderData = {};
								this.data.showLocationPicker = false;
							}}
						>
							Cancel
						</Button>
						<Button
							fill
							round
							large
							disabled={!this.data.selectedLocation}
							onClick={() => {
								this.$f7.dialog.preloader();
								this.submitProviderChange(
									changeProviderData.npi,
									this.data.selectedLocation
								);
								this.data.selectedLocation = null;
								this.data.changeProviderData = {};
								this.data.showLocationPicker = false;
							}}
						>
							Complete
						</Button>
					</div>
				</div>
			</div>
		);
	}

	submitProviderChange(npi, locationId) {
		CloudAPI.changeAppointmentLocation(
			this.data.activeAppt._id,
			npi,
			locationId
		)
			.then((res) => {
				let newAppt = { ...res.appt, provider: res.provider };
				this.data.activeAppt = newAppt;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}
	//1760570360 has 8 locations
	startProviderChange(npi) {
		CloudAPI.getProvider(npi)
			.then((res) => {
				let locations = _.get(res, '[0].locations', []);
				if (locations.length > 1) {
					//Ask about locations
					this.data.changeProviderData = { npi, locations };
					this.data.showLocationPicker = true;
					this.$f7.dialog.close();
				} else {
					//we only have one location and can set it on the provider
					this.submitProviderChange(npi, _.get(locations, '[0]._id'));
				}
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	onChangeProvider() {
		this.$f7.dialog.prompt(
			'Enter Provider NPI',
			(npi) => {
				this.$f7.dialog.preloader();
				this.startProviderChange(npi);
			},
			() => {
				//cancel called
			}
		);
	}

	buildProviderInfo() {
		let items = [];
		let appt = _.get(this.data, 'activeAppt', {});
		let excluded = [
			'_id',
			'isPerson',
			'lastUpdated',
			'billingAddress',
			'geoLocation',
			'latitude',
			'longitude',
			'fullName',
			'locations',
			'networks',
			'rawSpecialties',
			'fromNPI'
		];
		_.forEach(Object.keys(_.get(appt, `provider`, {})), (field) => {
			if (excluded.indexOf(field) < 0) {
				items.push(
					<div key={`lv-pair-${Math.random()}`}>
						<div className="label">{field}:</div>
						<div className="field">
							{this.handleInfoField(appt.provider, field)}
						</div>
					</div>
				);
			}
		});
		_.forEach(Object.keys(_.get(appt, `location`, {})), (field) => {
			if (excluded.indexOf(field) < 0) {
				items.push(
					<div key={`lv-pair-${Math.random()}`}>
						<div className="label">{field}:</div>
						<div className={`field ${field.split(' ').join('')}`}>
							{this.handleInfoField(appt.location, field)}
						</div>
					</div>
				);
			}
		});
		return (
			<div className="info-data y-scroll">
				<div className="actions hbox vcenter hcenter">
					<Button
						large
						onClick={() => {
							const providerPhone = _.get(appt, 'location.phone', '');
							ExternalService.handleExternalRequest('call', providerPhone);
						}}
					>
						Call Provider
					</Button>
					<Button large onClick={this.onChangeProvider}>
						Change Provider
					</Button>
				</div>
				{items}
			</div>
		);
	}

	buildRequestInfo() {
		let appt = _.get(this.data, 'activeAppt', {});
		let items = [];
		let excluded = [
			'patient',
			'location',
			'provider',
			'_id',
			'__v',
			'npi',
			'scheduler',
			'updatedDate',
			'status',
			'requestType',
			'surveyShown'
		];
		_.forEach(Object.keys(appt), (field) => {
			if (excluded.indexOf(field) < 0) {
				items.push(
					<div key={`lv-pair-${Math.random()}`}>
						<div className="label">{field}:</div>
						<div className="field">{this.handleInfoField(appt, field)}</div>
					</div>
				);
			}
		});
		return (
			<div className="info-data y-scroll">
				{appt.status === 'Scheduled' && (
					<div className="actions hbox vcenter hcenter">
						<Button large onClick={this.confirmAppointment}>
							Confirm Appointment
						</Button>
					</div>
				)}
				{items}
			</div>
		);
	}

	getInfoContent() {
		switch (this.data.tgglType) {
			case 'patient': {
				return this.buildPatientInfo();
			}
			case 'provider': {
				return this.buildProviderInfo();
			}
			case 'request': {
				return this.buildRequestInfo();
			}
		}
	}
	confirmAppointment() {
		const appt = this.data.activeAppt;
		let { confirmApptText, confirmedText, schTime } = this.text;
		let message = `${schTime} <br/> <strong>${format(
			new Date(appt.scheduledDate),
			'MM/dd/yyyy p'
		)}</strong>`;
		this.$f7.dialog.confirm(message, confirmApptText, () => {
			this.$f7.dialog.preloader();
			CloudAPI.confirmAppt(appt)
				.then(async () => {
					await this.fetchAppointments();
					this.$f7.dialog.close();
					let options = {
						position: 'center',
						closeTimeout: 1500,
						text: confirmedText,
						icon: `<i class="fad fa-check large-icon-toast" ></i>`
					};
					let toast = this.$f7.toast.create(options);
					toast.open();
				})
				.catch((err) => {
					console.log(err);
					this.$f7.dialog.close();
					this.$f7.dialog.alert(
						_.get(
							JSON.parse(err.response),
							'message',
							'An error occured, please try again.'
						)
					);
				});
		});
	}

	onDateChange(val) {
		const currentDate = Array.isArray(this.data.scheduledDate)
			? this.data.scheduledDate[0]
			: this.data.scheduledDate;
		const requestedVal = val[0];
		const formattedDate = currentDate && format(currentDate, 'MM/dd/yyyy p');
		const formattedVal = requestedVal && format(requestedVal, 'MM/dd/yyyy p');
		if (formattedDate !== formattedVal && formattedVal) {
			this.data.scheduledDate = val;
		}
	}

	onSubmitTime() {
		this.$f7.dialog.confirm(
			format(this.data.scheduledDate[0], 'MM/dd/yyyy p'),
			'Confirm Date',
			() => {
				this.$f7.dialog.preloader();
				CloudAPI.scheduleAppointment(
					this.data.activeAppt,
					this.data.scheduledDate[0]
				)
					.then(() => {
						this.onSchedulerClose();
						this.fetchAppointments().then(() => {
							this.$f7.dialog.close();
						});
					})
					.catch((err) => {
						console.log(err);
						this.$f7.dialog.close();
					});
			}
		);
	}
	closeMessagingForm() {
		this.data.showMessagingForm = false;
		adminStore.pendingRecipients = [];
		adminStore.pendingMessages = [];
	}
	openTimePicker() {
		//Stolen from f7 calendar-class.js openTimePicker
		const calendar = this.$f7.calendar.get();
		const { $el, app } = calendar;

		if (!$el || !$el.length) return;
		$el.append('<div class="calendar-time-picker"></div>');
		const hoursArr = [];
		const minutesArr = [];
		for (let i = 1; i <= 12; i += 1) {
			hoursArr.push(i);
		} // modified this
		for (let i = 0; i <= 59; i += 1) {
			minutesArr.push(i);
		}
		let value;
		if (calendar.value && calendar.value.length) {
			value = [calendar.value[0].getHours(), calendar.value[0].getMinutes()];
		} else {
			value = [new Date().getHours(), new Date().getMinutes()];
		}
		if (value[0] < 12) {
			value.push('AM');
		} else {
			const h12 = value[0] > 12 ? value[0] - 12 : value[0];
			value[0] = h12;
			value.push('PM');
		}
		if (value[0] === 0) {
			value[0] = 12;
		}
		calendar.timePickerInstance = app.picker.create({
			containerEl: $el.find('.calendar-time-picker'),
			value,
			toolbar: true,
			rotateEffect: false,
			toolbarCloseText: calendar.params.toolbarCloseText,
			cols: [
				{
					values: hoursArr,
					onChange: (picker, val, disVal) => {
						calendar.apptHour = val;
					}
				},
				{
					divider: true,
					content: ':'
				},
				{
					values: minutesArr,
					displayValues: minutesArr.map((m) => (m < 10 ? `0${m}` : m)),
					onChange: (picker, val, disVal) => {
						calendar.apptMin = val;
					}
				},
				{
					values: ['AM', 'PM'],
					onChange: (picker, val, disVal) => {
						calendar.apptTod = val;
					}
				}
			]
		});
		calendar.timePickerInstance.$el
			.find('.toolbar a')
			.removeClass('sheet-close popover-close')
			.addClass('cv-custom-time-picker-close');
		calendar.timePickerInstance.$el
			.find('.cv-custom-time-picker-close')
			.on('click', this.closeTimePicker);
	}
	closeTimePicker(evt) {
		// mostly stolen from f7 calendar-class.js
		const calendar = this.$f7.calendar.get();
		const picker = this.$f7.picker.get();
		calendar.timePickerInstance = _.cloneDeep(picker);
		let hours = calendar.apptHour;
		let minutes = calendar.apptMin;
		let tod = calendar.apptTod;
		hours = parseInt(hours, 10);
		minutes = parseInt(minutes, 10);
		if (tod === 'PM' && hours < 12) {
			hours = hours + 12;
		}
		if (tod === 'AM' && hours === 12) {
			hours = 0;
		}
		let value = calendar.value && calendar.value.length && calendar.value[0];
		if (!value) {
			value = new Date();
			value.setHours(hours, minutes, 0, 0);
		} else {
			value = new Date(value);
			value.setHours(hours, minutes);
		}
		calendar.setValue([value]);
		calendar.$el
			.find('.cv-custom-time-selector a')
			.text(
				calendar.timeSelectorFormatter.format(value) ||
					calendar.params.timePickerPlaceholder
			);
		calendar.timePickerInstance.close();
		calendar.timePickerInstance.destroy();
		delete calendar.timePickerInstance;
		if (calendar.$el && calendar.$el.length) {
			calendar.$el.find('.calendar-time-picker').remove();
		}
	}
	getSchedulerDate() {
		const pendingDate = this.data.scheduledDate;
		const activeAppt = _.cloneDeep(_.get(this, 'data.activeAppt', {}));
		const currentScheduledDate = _.get(activeAppt, 'scheduledDate');
		const today = new Date();
		const requestType = _.get(activeAppt, 'requestType', 'first');
		const dateSpecified = requestType === 'suggested';
		const requestedDates = _.get(activeAppt, 'requestDate');
		const requestTime = _.get(activeAppt, 'requestTime', 'anytime');
		let startDate = _.get(requestedDates, '[0]');
		// if we've already entered something - always use that
		if (pendingDate) {
			return pendingDate;
		}
		// if it has already been scheduled at some point, use that
		if (currentScheduledDate) {
			return [currentScheduledDate];
		}
		// if there was a specific date range requested start there
		if (dateSpecified) {
			switch (requestTime) {
				case 'anytime':
					startDate = new Date(startDate).setHours(9, 0, 0, 0);
					break;
				case 'afternoon':
					startDate = new Date(startDate).setHours(13, 0, 0, 0);
					break;
				default:
					startDate = new Date(startDate).setHours(9, 0, 0, 0);
					break;
			}
			return [new Date(startDate)];
		} else {
			//if it's never been scheduled & first available selected - use today's date
			return [today];
		}
	}
	buildSchedulerForm() {
		let { dateRangeText, dateRangePlaceholder } = this.text;
		let { scheduledDate } = this.data;
		const dateVal = this.getSchedulerDate() || scheduledDate;
		try {
			return (
				<div className="scheduler-form">
					<div className="label">Select A Date & Time</div>
					<List className="no-hairlines date-picker-list">
						<ListInput
							label={dateRangeText}
							type="datepicker"
							placeholder={dateRangePlaceholder}
							value={dateVal}
							calendarParams={{
								timePicker: true,
								backdrop: true,
								url: '/',
								openIn: 'customModal',
								header: true,
								footer: true,
								minDate: addDays(new Date(), -1),
								dateFormat: {
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric'
								},
								on: {
									opened: (c) => {
										//to keep the calendar input from doing its normal picker things (military time selector)
										c.$el
											.find('.calendar-time-selector')
											.removeClass('calendar-time-selector')
											.addClass('cv-custom-time-selector');
										c.$el
											.find('.cv-custom-time-selector a')
											.on('click', this.openTimePicker);
									},
									change: (c) => {
										this.onDateChange(c.value);
									}
								}
							}}
						/>
					</List>
					<Button large fill round onClick={this.onSubmitTime}>
						Submit
					</Button>
					<Button
						large
						round
						onClick={() => {
							this.data.showScheduler = false;
						}}
					>
						back
					</Button>
				</div>
			);
		} catch (err) {
			console.log(err);
		}
	}

	//TODO make popups their own components later
	render() {
		let { tgglType, schedulerOpen, showScheduler, activeAppt, activeUser } =
			this.data;
		return (
			<div className="appt-mgmt-page">
				{this.buildLists()}
				<Popup
					opened={schedulerOpen}
					className="scheduler-popup"
					onPopupClosed={this.onSchedulerClose}
				>
					<div className="scheduler-header hbox vcenter hright">
						<h2 className="grow-1">{'Schedule Appointment'}</h2>
						<Link popupClose>
							<i className="fad fa-times-square" />
						</Link>
					</div>
					{!showScheduler && (
						<div className="scheduler-content">
							<Segmented raised round small className="info-toggle">
								<Button
									round
									small
									active={tgglType === 'patient'}
									onClick={() => {
										this.data.tgglType = 'patient';
									}}
								>
									Patient
								</Button>
								<Button
									round
									small
									active={tgglType === 'provider'}
									onClick={() => {
										this.data.tgglType = 'provider';
									}}
								>
									Provider
								</Button>
								<Button
									round
									small
									active={tgglType === 'request'}
									onClick={() => {
										this.data.tgglType = 'request';
									}}
								>
									Request
								</Button>
							</Segmented>
							{schedulerOpen && this.getInfoContent()}
							<div className="btn-ctn hbox vcenter hcenter">
								{this.getActionBtns()}
							</div>
						</div>
					)}
					{showScheduler && (
						<div className="scheduler-content">{this.buildSchedulerForm()}</div>
					)}
					{this.data.showLocationPicker && this.getLocationsPicker()}
				</Popup>
				{this.data.showDocViewer && (
					<MemberDocumentViewer
						onClose={this.closeDocumentViewer}
						docType="all"
						dependentData={{
							...activeAppt,
							...activeUser,
							hash: _.get(activeAppt, 'patient.hash')
						}}
						emailTemplate={'appointmentRequest'}
						emailSubject={`Appointment Request - on behalf of ${_.get(
							this.data,
							'activeAppt.patient.firstName'
						)} ${_.get(this.data, 'activeAppt.patient.lastName')}`}
					/>
				)}
				<MessagingForm
					isOpen={this.data.showMessagingForm}
					close={this.closeMessagingForm}
				/>
			</div>
		);
	}
}
export default AppointmentManagement;
