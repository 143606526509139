import _ from 'lodash';
import appStore from '../stores/AppStore';
import userStore from '../stores/UserStore';
import providerStore from '../stores/ProviderStore';
import getDistance from 'geolib/es/getDistance';
import convertDistance from 'geolib/es/convertDistance';
import StorageService from './StorageService';
import CloudAPI from './CloudAPI';
import { getCmpText, standardizeLocale } from '../utils/Localization';
import LocationService from './LocationService';
import ConfigService from './ConfigService';
import { format } from 'date-fns';

const storageService = new StorageService();
class UserService {
	async exitDemoMode() {
		appStore.demoMode = null;
		storageService.setValue('app', 'demoMode', null);
		ConfigService.resetConfig();
		window.location.reload();
	}
	async getUserAppointments() {
		if (!userStore.isLoggedIn) return;
		const ApptStatus = {
			REQUESTED: 'Requested',
			SCHEDULED: 'Scheduled',
			CONFIRMED: 'Confirmed',
			CANCELED: 'Canceled'
		};
		const res = await CloudAPI.getMyAppointments();
		let upcoming = [];
		let previous = [];
		let requested = [];

		_.forEach(res, (appt) => {
			if (appt.status === ApptStatus.REQUESTED) {
				requested.push(appt);
			} else if (appt.status === ApptStatus.CANCELED) {
				previous.push(appt);
			} else if (
				appt.scheduledDate &&
				new Date(appt.scheduledDate) > new Date()
			) {
				upcoming.push(appt);
			} else {
				const updatedAppt = {
					...appt,
					status: 'Completed'
				};
				previous.push(updatedAppt);
			}
		});
		const upcomingScheduled = upcoming.filter(
			(appt) => appt.status === ApptStatus.SCHEDULED
		);
		appStore.apptActions = upcomingScheduled ? upcomingScheduled.length : 0;
		return { upcoming, previous, requested };
	}

	async checkForAppointmentSurvey() {
		const surveyConfig = ConfigService.getConfigValue(
			'patientSatisfactionSurvey'
		);
		if (!surveyConfig || !surveyConfig.enabled) return [];

		const appts = await this.getUserAppointments();
		const prevAppts = appts.previous;
		const eligibleAppts = prevAppts.filter(
			(appt) => appt.status === 'Completed'
		);

		const apptsText = getCmpText('Appointments', {
			surveyTitle:
				'About your recent appointment with <%= provider.fullname %>',
			surveySubtitle: 'How was your appointment on <%= scheduledDate %>',
			surveyWaitTime:
				'Did the provider see you within 30 minutes of your scheduled appointment?',
			surveyConcernResolution:
				'Do you feel the doctor treatment resolved your health concern?',
			surveyOfficeStaff: 'Did the office staff treat you well?',
			surveyRetention: 'Would you return to this physician again?',
			surveyComments:
				'Would you like to share any comments about your experience?',
			surveyDecline: 'Decline',
			surveySubmit: 'Submit'
		});

		const surveyQuestionsConfig = surveyConfig.questions;
		const unansweredSurveys = eligibleAppts.filter((appt) => !appt.surveyShown);
		const questionIds = Object.keys(surveyQuestionsConfig);

		const formattedQuestions = questionIds.map((question) => {
			let textVal = surveyQuestionsConfig[question].text;
			const textKey = surveyQuestionsConfig[question].textKey;
			if (textKey) {
				const localizedTextVal = apptsText[textKey];
				textVal = localizedTextVal || textVal;
			}
			const textTemplate = _.template(textVal);
			return {
				type: surveyQuestionsConfig[question].type,
				text: textTemplate,
				id: question,
				hidden: surveyQuestionsConfig[question].hidden,
				action: surveyQuestionsConfig[question].action
			};
		});

		const titleTemplate = _.template(apptsText.surveyTitle);
		const subTitleTemplate = _.template(apptsText.surveySubtitle);

		const currentQuestionnaires = unansweredSurveys.map((survey) => {
			const cleanApptInfo = _.cloneDeep(survey);
			cleanApptInfo.scheduledDate = format(
				new Date(cleanApptInfo.scheduledDate),
				'PP'
			);
			cleanApptInfo.provider.fullName = _.startCase(
				cleanApptInfo.provider.fullName
			);
			const surveyQuestions = formattedQuestions.map((question) => ({
				...question,
				text: question.text(survey),
				label: question.text(survey)
			}));
			const formatQuestionnaire = {
				submitDecline: true,
				header: '',
				title: titleTemplate(cleanApptInfo),
				subtitle: subTitleTemplate(cleanApptInfo),
				id: `patientSurvey.${survey._id}`,
				action: { rules: [{ type: 'none' }] },
				content: surveyQuestions
			};
			return formatQuestionnaire;
		});
		return currentQuestionnaires;
	}

	async handleClientTimezone() {
		try {
			const userTz = _.get(userStore, 'userData.user.timezone');
			const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
			if (userTz !== currentTz) {
				//we can later use this to handle timezone variations
				await CloudAPI.setUserTimezone(currentTz);
			}
		} catch (err) {
			console.log('error setting user timezone: ', err);
		}
	}

	async handleLanguagePreferences(f7) {
		const deviceLanguage = await storageService.getValue('app', 'language');
		const deviceSettingsLanguage = standardizeLocale(
			_.get(window, 'navigator.language')
		);
		const userLanguage = _.get(userStore, 'userData.user.languageCode');
		const fallBackLanguage = deviceSettingsLanguage || appStore.locale;
		const selectedLanguage = userLanguage || deviceLanguage || fallBackLanguage;
		if (
			selectedLanguage !== fallBackLanguage ||
			selectedLanguage !== userLanguage
		) {
			await this.setLocale(selectedLanguage);
			if (_.get(f7, 'views.appShell.router.refreshPage')) {
				f7.views.appShell.router.refreshPage();
			}
		}
	}
	async updateUIPreference(status) {
		try {
			_.set(userStore, 'userData.user.accessibilityModeOn', status);
			await CloudAPI.setUIPreference(status);
			await this.handleUIPreference();
		} catch (err) {
			console.log('unable to set UI preference: ', err);
		}
	}
	async handleUIPreference() {
		const userPreference = _.get(
			userStore,
			'userData.user.accessibilityModeOn',
			false
		);
		if (userPreference) {
			document.documentElement.style.setProperty(
				'--accessibility-border',
				'1px solid #4788FF'
			);
		} else {
			document.documentElement.style.setProperty(
				'--accessibility-border',
				'0px solid #fff'
			);
		}
	}

	getPlanDocuments(type, dependent) {
		if (appStore.demoMode) {
			dependent = { hash: _.get(userStore, 'userData.demoHash') };
		}
		return CloudAPI.getPlanDocuments({
			firebaseUserId: _.get(userStore, 'userData.user.firebaseUid'),
			requestedDependent: dependent
		})
			.then((response) => {
				switch (type) {
					case 'idCard':
						return response.idCards;
					case 'sobDocs':
						return response.statementOfBenefits;
					default:
						return response;
				}
			})
			.catch((err) => {
				console.log('there has been a problem');
			});
	}

	sharePlanDocuments(data) {
		const shareType = data.method;
		switch (shareType) {
			case 'email':
				return CloudAPI.emailPlanDocuments(data);
			case 'fax': // Deprecated
				return CloudAPI.faxPlanDocuments(data);
			case 'mail':
				return CloudAPI.mailPlanDocuments(data);
			case 'download':
				return CloudAPI.downloadPlanDocuments(data);
			default:
				console.log('unknown selection');
				break;
		}
	}

	getCurrentPcp() {
		const careteam = providerStore.careTeam;
		const careteamPcp = _.find(careteam, { isPcp: true });
		if (careteamPcp) {
			return {
				fullName: careteamPcp.name,
				npi: careteamPcp.NPI
			};
		}
		return false;
	}

	shouldAutoAssignPcp(specialty) {
		const currentPcp = this.getCurrentPcp();
		const isPrimaryCare = Array.isArray(specialty)
			? specialty.indexOf('Primary Care') > -1
			: specialty === 'Primary Care';
		return !currentPcp && isPrimaryCare;
	}

	async autoAddProviderAsPcp(providerData, location, member) {
		const currentUserMemberUid = _.get(userStore, 'userData.member._id');
		const isCurrentUser = currentUserMemberUid === member;
		if (!isCurrentUser && !!member) {
			return;
		}
		const name = _.get(providerData, 'fullName', '').split(' llc').join(' LLC');
		const requestBody = {
			NPI: providerData.NPI,
			networks: location.networks,
			isPerson: providerData.isPerson,
			isPcp: true,
			name: `${_.startCase(name)} ${_.get(
				providerData,
				'personDetails.credential',
				''
			)}`,
			gender: _.get(providerData, 'personDetails.gender', null),
			location: location,
			specialty: 'Primary Care'
		};
		try {
			const updatedCareTeam = await CloudAPI.addToCareTeam(requestBody);
			providerStore.careTeam = updatedCareTeam;
		} catch (err) {
			console.log('unable to auto add provider as PCP: ', err);
		}
	}

	getDistanceFromLocation(location) {
		//TODO make this work with addresses later by doing lat long lookup for now just use coordinates
		let { lat, lon } = appStore.currentLocation;
		let latlon = _.get(location, 'geoLocation.coordinates', []);
		try {
			return convertDistance(
				getDistance(
					{ latitude: lat, longitude: lon },
					{ latitude: latlon[1], longitude: latlon[0] },
					1
				),
				'mi'
			).toFixed(1);
		} catch {
			return 'Unk';
		}
	}

	async citySearch(query) {
		console.log('doing city search');
		return new Promise((resolve, reject) => {
			CloudAPI.findGeoLocation(query)
				.then((res) => {
					resolve(res.candidates);
				})
				.catch((err) => {
					console.log(err);
					reject();
				});
		});
	}

	submitConsumerAccountRequest(form) {
		return CloudAPI.submitConsumerAccountRequest(form);
	}

	submitFeedback(data) {
		return CloudAPI.submitFeedback(data);
	}

	getHealthNetwork() {
		return _.get(userStore, 'userData.group.healthNetwork', null);
	}

	getNetworkTiers() {
		return _.get(userStore, 'userData.group.tiers', null);
	}

	getPlanTelehealthData() {
		const defaults = {
			copay: 0
		};
		const planData = _.get(userStore, 'userData.group.telehealth', defaults);
		return {
			...defaults,
			...planData
		};
	}

	isAdmin() {
		return _.get(userStore, 'userData.role.type', 'user') === 'admin';
	}

	isPDM() {
		return _.get(userStore, 'userData.role.type', 'user') === 'pdm';
	}

	isConsumerEnrolled() {
		return _.get(userStore, 'userData.user.enrolled', false);
	}

	isConsumerActivated() {
		return _.get(userStore, 'userData.user.activated', false);
	}

	isConsumer() {
		return _.get(userStore, 'userData.user.consumer', false);
	}

	isHybrid() {
		return _.get(userStore, 'userData.group.isHybridPlan', false);
	}

	hasNetworkTiers() {
		return _.get(userStore, 'userData.group.tiers', []).length > 0;
	}

	hasHealthNetworks() {
		return _.get(userStore, 'userData.group.healthNetwork', null);
	}

	async setDefaultLocation() {
		try {
			const location = await LocationService.setLocation('bgGeo');
		} catch (err) {
			console.log('error setting default location: ', err);
		}
	}

	async setUserLocation(data) {
		console.log({ data });
		LocationService.saveLocation(data);
	}

	async setLocale(langCode) {
		appStore.locale = langCode;
		storageService.setValue('app', 'language', langCode);
		try {
			await CloudAPI.setLanguagePreference(langCode);
		} catch (err) {
			console.log('Unable to store user language preference: ', err);
		}
	}
}

export default new UserService();
