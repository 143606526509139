import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveWaffle } from '@nivo/waffle';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './waffle-chart.scss';

@observer
export default class WaffleChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	getColor(data) {
		return data.color;
	}

	handleItemClick(data) {
		console.log('waffle item was clicked: ', data);
	}

	formatData() {
		const data = _.get(this.props, 'data.data') || [];
		const formattedData = data
			.map((item) => {
				const itemTotal = item.data.reduce((total, curr) => total + curr.y, 0);
				return {
					id: item.id,
					label: item.label,
					value: itemTotal,
					color: item.color
				};
			})
			.filter((x) => x.value > 0);
		return formattedData;
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const data = this.formatData();
		const chartConfig = chartProperties.config;
		const selectedFilters = this.props.selectedFilters;
		const dataTotal = data.reduce((total, curr) => total + curr.value, 0);
		return (
			<ResponsiveWaffle
				data={data}
				total={dataTotal}
				rows={10}
				columns={20}
				margin={{ top: 10, right: 10, bottom: isMobile ? 70 : 50, left: 10 }}
				colors={chartConfig.customColors ? (e) => this.getColor(e) : { scheme: 'nivo' }}
				borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
				animate={true}
				motionStiffness={90}
				motionDamping={11}
				onClick={this.handleItemClick}
			/>
		);
	}
}
