import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';

@observer
export default class NewCanvasTemplate extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	handleSubmit() {
		const { onSubmit } = this.props;
		if (onSubmit) {
			onSubmit();
		}
	}

	getFormConfig() {
		return {
			name: {
				label: 'Template mame',
				type: 'text',
				placeholder: 'Enter a name for the template',
				validator: {
					type: 'length',
					value: 4
				}
			},
			type: {
				label: 'Choose type',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: 'far fa-arrows-alt-h',
				listItems: { idCard: 'ID Card', other: 'Other' }
			},
			row1: {
				width: {
					label: 'Width',
					type: 'number',
					placeholder: '300'
				},
				height: {
					label: 'Height',
					type: 'number',
					placeholder: '200'
				}
			},
			tags: {
                label: 'Tags',
                type: 'text',
                placeholder: 'Enter tags seperated by a comma'
            }
		};
	}

	render() {
		const { onSubmit, opened, onClose, templateData } = this.props;
		return (
			<FormPopup
				opened={opened}
				title="New Template"
				buttonText="Continue"
				formConfig={this.getFormConfig()}
				formData={templateData}
				onSubmit={this.handleSubmit}
				onClose={onClose}
				fullScreen={false}
			/>
		);
	}
}
