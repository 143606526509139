import React, { Component } from 'react';
import { getCmpText } from '@/utils/Localization';
import { Link } from 'framework7-react';
import { autoBind } from '@/utils/GeneralUtils';
import ExternalService from '@/services/ExternalService';
import { observer, observable } from '@/utils/State';
import './support.scss';
@observer
export default class Support extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('Support', {
			helpText: 'For help with CareValet please call ',
			helpHeader: 'Need Help?'
		});
		this.phoneNumber = '866-549-9533';
		autoBind(this);
	}

	callCareValet() {
		ExternalService.handleExternalRequest('call', this.phoneNumber);
	}

	render() {
		return (
			<div className="support-group vbox vcenter hcenter">
				<div className="group-heading">{this.text.helpHeader}</div>
				<div className="group-details">
					<div className="group-item">
						<p>
							{this.text.helpText}
							<Link className="phone-cta" onClick={this.callCareValet}>
								{this.phoneNumber}
							</Link>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
