import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import expressStore from '@/stores/ExpressStore';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Button, Preloader } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';
import './plans-list.scss';
import PlanWizard from './plan-wizard/PlanWizard';
import PlanDetails from './plan-details/PlanDetails';
import StatusPopup from '@/components/_EXPRESS/status-poup/StatusPopup';

@observer
export default class PlansList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			searchInput: '',
			plans: [],
			loading: false,
			loadedFrom: null,
			wizardOpen: false,
			showStatusPopup: false
		});
		autoBind(this);
	}

	componentWillUnmount() {
		expressStore.activePlan = null;
	}

	asyncLoadPlans() {
		setTimeout(() => {
			this.loadPlans();
		}, 0);
	}

	async loadPlans() {
		if (!this.data.loading) {
			this.data.loading = true;
			this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
			if (expressStore.activePage == 'groups') {
				ExpressAPI.getPlans(expressStore.activeGroup?._id)
					.then((res) => {
						this.data.loading = false;
						this.data.plans = res;
					})
					.catch((err) => {
						this.data.loading = false;
						console.log(err);
					});
			}
			if (expressStore.activePage == 'allPlans') {
				ExpressAPI.getAllPlans()
					.then((res) => {
						this.data.loading = false;
						this.data.plans = res;
					})
					.catch((err) => {
						this.data.loading = false;
						console.log(err);
					});
			}
		}
	}

	deletePlan(plan) {
		this.$f7.dialog.confirm(
			`Are you sure you want to delete the "${plan.name}" plan?`,
			'Please Double Check',
			() => {
				this.data.loading = true;
				ExpressAPI.deletePlan(plan._id)
					.then((res) => {
						this.data.loading = false;
						this.data.plans = res;
					})
					.catch((err) => {
						this.data.loading = false;
						showExpressError(this.$f7, err);
					});
			},
			() => {}
		);
	}

	unLinkPlan(plan) {
		if (expressStore.activeGroup?._id) {
			this.$f7.dialog.confirm(
				`Are you sure you want to remove the "${plan.name}" plan from the group?`,
				'Please Double Check',
				() => {
					this.data.loading = true;
					ExpressAPI.unlinkPlanFromGroup(
						expressStore.activeGroup?._id,
						plan._id
					)
						.then((res) => {
							this.data.loading = false;
							this.data.plans = res;
						})
						.catch((err) => {
							this.data.loading = false;
							showExpressError(this.$f7, err);
						});
				},
				() => {}
			);
		}
	}

	onOpenCreatePlan() {
		this.data.wizardOpen = true;
	}

	searchChange(e) {
		this.data.searchInput = e.currentTarget.value;
	}
	openStatusPopup() {
		this.data.showStatusPopup = true;
	}

	closeStatusPopup() {
		expressStore.activePlan = null;
		this.data.showStatusPopup = false;
	}

	statusChip(healthplans) {
		switch (healthplans.status) {
			case 'completed': {
				return (
					<div
						className={`status-chip completed`}
						onClick={() => {
							this.data.showStatusPopup = true;
						}}
					>
						<i className="fad fa-check-circle"></i> Setup Completed
					</div>
				);
			}
			case 'pending': {
				return (
					<div
						className={`status-chip pending`}
						onClick={() => {
							this.data.showStatusPopup = true;
						}}
					>
						<i className="fad fa-exclamation-triangle"></i> Setup Incomplete
					</div>
				);
			}
			case 'review': {
				return (
					<div
						className={`status-chip review`}
						onClick={() => {
							this.data.showStatusPopup = true;
						}}
					>
						<i className="fad fa-eye"></i> Under Review
					</div>
				);
			}
			default:
				return '';
		}
	}

	buildList() {
		let { plans, loading, searchInput } = this.data;
		let { activePage } = expressStore;
		const isGroupsPage = activePage === 'groups';
		let items = [];
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') &&
			expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		_.forEach(plans, (p) => {
			if (
				searchInput.length > 0 &&
				_.toLower(p.name).indexOf(searchInput.toLowerCase()) < 0
			) {
				return;
			}
			items.push(
				<div
					className="table-list-item plan-list-item hbox vcenter"
					key={`${p.orgId}-${p._id}`}
					onClick={(e) => {
						// @ts-ignore
						if (e.target?.classList?.contains('fa-trash-alt')) {
							return;
						}
						expressStore.activePlan = p;
					}}
				>
					<div className="name ellipse" title={_.toUpper(p.name)}>
						{p.name}
					</div>
					<div className="field grow-1">{p.planId}</div>
					<div className="field grow-1">
						{p.healthPlanCoverageType ? p.healthPlanCoverageType : '-'}
					</div>
					<div className="field grow-1">{p.healthNetworkName}</div>
					<div className="field grow-1">{_.startCase(p.lastUpdatedBy)}</div>
					<div className="field grow-1" onClick={this.openStatusPopup}>
						{this.statusChip(p)}
					</div>
					{!canEdit && !isGroupsPage ? null : !isGroupsPage ? (
						<i
							className="fad fa-trash-alt"
							title="Delete Plan"
							onClick={() => {
								this.deletePlan(p);
							}}
						></i>
					) : null}
					{!canEdit && isGroupsPage ? null : isGroupsPage ? (
						<i
							className="fad fa-trash-alt"
							title="Remove Plan From Group"
							onClick={() => {
								this.unLinkPlan(p);
							}}
						></i>
					) : null}
				</div>
			);
		});
		return (
			<div className={`plan-list ${loading ? '' : 'shadow'}`}>
				{plans.length !== 0 && (
					<div className="table-list-header plan-list-header hbox vcenter">
						<div className="name">Plan Name</div>
						<div className="field grow-1">Plan ID</div>
						<div className="field grow-1">Plan Coverage Type</div>
						<div className="field grow-1">Network</div>
						<div className="field grow-1">Last Updated By</div>
						<div className="field grow-1">Status</div>
					</div>
				)}
				{!loading ? (
					items
				) : (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
			</div>
		);
	}

	render() {
		let { searchInput, plans, loading, loadedFrom, wizardOpen } = this.data;
		let { currentOrg, activePage, activePlan } = expressStore;
		if (loadedFrom !== currentOrg._id) {
			this.asyncLoadPlans();
		}
		const showDetails = activePlan && !this.data.showStatusPopup;
		return showDetails ? (
			<PlanDetails loadPlans={this.asyncLoadPlans} />
		) : (
			<div
				className={`express-health-plans-list ${
					activePage === 'allPlans' ? 'add-padding' : ''
				}`}
			>
				{activePage == 'allPlans' && (
					<h1 className="text-4 mb-xl grow-1">HealthPlans</h1>
				)}
				<div className="list-controls hbox vcenter">
					<div className="input-ctn hbox vcenter">
						<i className="fas fa-search"></i>
						<input
							placeholder="Search for plans by name..."
							value={searchInput}
							onChange={this.searchChange}
						/>
					</div>
					<div className="grow-1"></div>
					{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
					expressStore.currentOrgRole().role ===
						expressStore.roleEnums.GROUP_USER ? null : (
						<Button
							fill
							small
							className="list-btn ios"
							onClick={this.onOpenCreatePlan}
						>
							<i className="fa fa-plus" />
							{activePage === 'allPlans' ? 'Add Plan' : 'Connect Plan'}
						</Button>
					)}
				</div>
				{plans.length <= 0 && !loading && (
					<div className="empty-message vbox vcenter hcenter">
						<div className="icon-box hbox vcenter hcenter">
							<i className="fad fa-users-class" />
						</div>
						{activePage === 'allPlans' ? (
							<h3>Create your first healthplan</h3>
						) : (
							<h3>Connect one or more healthplans to this group</h3>
						)}
						{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
						expressStore.currentOrgRole().role ===
							expressStore.roleEnums.GROUP_USER ? null : (
							<Button
								fill
								small
								className="round-btn list-btn animated pulse infinite"
								onClick={this.onOpenCreatePlan}
							>
								<i className="fa fa-plus" />
								{activePage === 'allPlans' ? 'Add Plan' : 'Connect Plan'}
							</Button>
						)}
					</div>
				)}
				{this.buildList()}
				<PlanWizard
					opened={wizardOpen}
					onClose={(saved) => {
						this.data.wizardOpen = false;
						if (saved === true) {
							this.loadPlans();
						}
					}}
				/>
				<StatusPopup
					open={this.data.showStatusPopup}
					status={_.get(expressStore, 'activePlan.status')}
					type="plan"
					itemId={_.get(expressStore, 'activePlan._id')}
					onClose={this.closeStatusPopup}
					title={`${_.get(expressStore, 'activePlan.name')} Status Summary`}
				/>
			</div>
		);
	}
}
