import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Chip } from 'framework7-react';
import { observer } from '@/utils/State';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import UserService from '@/services/UserService';
import userStore from '@/stores/UserStore';
import ProviderService from '@/services/ProviderService';
import './network-chip.scss';
import appStore from '@/stores/AppStore';

@observer
export default class NetworkChip extends Component {
	constructor(props) {
		super(props);
		this.text = getCmpText('ProviderService', {
			inNetworkText: 'In-Network',
			outNetworkText: 'Out-Network',
			tier1Text: 'In-Network',
			tier2Text: 'In-Network',
			tier3Text: 'In-Network',
			tier4Text: 'In-Network',
			tier5Text: 'Out-Network'
		});
		autoBind(this);
	}

	handleClick() {
		if (this.props.handleClick) {
			this.props.handleClick();
		}
	}

	render() {
		const isConsumer = UserService.isConsumer();
		const isHybrid = _.get(userStore, 'userData.group.isHybridPlan');

		let networkData;
		if (isConsumer || isHybrid || this.props.suppress) {
			return <Fragment />;
		}

		if (!appStore.demoMode) {
			networkData = ProviderService.getNetworkData(this.props.provider);
			if (!networkData && _.isEmpty(this.props.color)) {
				return <Fragment />;
			}
		} else {
			networkData = ProviderService.getNetworkData(this.props.provider);
			//* Hard coded demo data
			if (!networkData)
				networkData = {
					color: 'green',
					inNetworkText: 'In-Network',
					inNetwork: true
				};
		}

		if (!networkData) {
			networkData = {};
		}
		const hasTiers = networkData && networkData.tier;
		const networkText = networkData.inNetwork
			? this.text.inNetworkText
			: this.text.outNetworkText;
		const chipText = hasTiers
			? this.text[`tier${networkData.tier}Text`]
			: networkText;
		const tierDefaultIcon =
			hasTiers && networkData.tier > 3 ? 'fas fa-exclamation' : 'fas fa-check';
		const tierIcon =
			hasTiers && networkData.icon ? networkData.icon : tierDefaultIcon;
		const networkIcon = networkData.inNetwork
			? 'fas fa-check'
			: 'fas fa-exclamation';

		return (
			<Chip
				text={this.props.text || chipText}
				mediaBgColor={this.props.color || networkData.color}
				className="ios network-chip-container"
				onClick={this.handleClick}
			>
				<i
					slot="media"
					className={
						this.props.icon
							? this.props.icon.replace('fad', 'fas')
							: hasTiers
							? tierIcon
							: networkIcon
					}
				/>
			</Chip>
		);
	}
}
