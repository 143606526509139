import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {
	PhotoBrowser,
	Fab,
	FabButtons,
	FabButton,
	Icon,
	Popup
} from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import UserService from '../../services/UserService';
import ConfigService from '../../services/ConfigService';
import ShareDocuments from '../share-documents/ShareDocuments';
import userStore from '../../stores/UserStore';
import appStore from '../../stores/AppStore';
import AnalyticsService from '../../services/AnalyticsService';
import './member-document-viewer.scss';

const analytics = new AnalyticsService();
const URL = window.URL || window.webkitURL;
@observer
export default class MemberDocumentViewer extends Component {
	constructor(props) {
		super();
		this.data = observable({
			formOpen: false,
			selectedForm: null,
			images: []
		});
		this.photoRef = React.createRef();
		this.fabControl = null;
		this.text = getCmpText('PlanDocuments', {
			paginationText: 'of',
			sobCaption: 'Statement of Benefits',
			zoomInstruction: '*Double click to zoom*',
			idCardFrontCaption: 'ID Card - Front',
			idCardBackCaption: 'ID Card - Back',
			emailLabel: 'Email',
			faxLabel: 'Fax',
			mailLabel: 'Mail',
			shareCta: 'Share',
			closeCta: 'Close',
			downloadLabel: 'Download PDF',
			appDownloadLabel: 'Other',
			shareDocumentError: 'Error sharing documents'
		});
		autoBind(this);
	}
	onGalleryOpened(data) {
		switch (this.props.docType) {
			case 'all':
				analytics.track('view_documents_all');
				break;
			case 'idCard':
			case 'idcard':
				analytics.track('view_documents_id');
				break;
			case 'sobDocs':
			case 'sob':
				analytics.track('view_documents_sob');
				break;
			default:
				analytics.trackWithData(
					`view_documents_${this.props.docType || 'all'}`
				);
		}
		const imageViewer = _.get(this.photoRef, 'current');
		const galleryChildren = imageViewer.f7PhotoBrowser.el.children;
		const fabHolder = document.createElement('div');
		fabHolder.id = 'fabPlaceholder';
		imageViewer.f7PhotoBrowser.el.appendChild(fabHolder);
		ReactDOM.render(this.getFab(), document.getElementById('fabPlaceholder'));
	}
	emailInit(data) {
		analytics.trackWithData('share_plan_documents', {
			format: 'email',
			type: this.props.docType || 'all'
		});
		this.data.selectedForm = 'email';
		this.data.formOpen = true;
		this.fabControl.click();
	}
	faxInit(data) {
		analytics.trackWithData('share_plan_documents', {
			format: 'fax',
			type: this.props.docType || 'all'
		});
		this.data.selectedForm = 'fax';
		this.data.formOpen = true;
		this.fabControl.click();
	}
	async downloadInit(data) {
		this.fabControl.click();
		let docType = this.props.docType;
		this.$f7.dialog.preloader();
		try {
			if (appStore.isApp()) {
				analytics.trackWithData('share_plan_documents', {
					format: 'device',
					type: this.props.docType || 'all'
				});
				this.triggerDeviceShareMenu();
				return;
			}
			analytics.trackWithData('share_plan_documents', {
				format: 'download',
				type: this.props.docType || 'all'
			});
			if (docType === 'all') {
				this.$f7.dialog
					.create({
						title: 'Select Document Type',
						text: 'Please choose the document you wish to download',
						cssClass: 'document-download-type-prompt',
						buttons: [
							{
								text: 'ID Card',
								cssClass: 'doc-type-btn',
								onClick: async () => {
									await this.handleWebDownloads('idCard');
								}
							},
							{
								text: 'Statement of Benefits',
								cssClass: 'doc-type-btn',
								onClick: async () => {
									await this.handleWebDownloads('sobDocs');
								}
							},
							{
								text: 'Cancel',
								cssClass: 'doc-type-btn',
								close: true
							}
						],
						verticalButtons: true
					})
					.open();
			} else {
				await this.handleWebDownloads(docType);
			}
			this.$f7.dialog.close();
		} catch (err) {
			this.shareError(err);
		}
	}

	async handleWebDownloads(docType) {
		try {
			const documentData = await this.getDocumentPdf(docType);
			this.$f7.dialog.close(); // need to close it before the download happens it loses scope & doesnt complete the preloader close
			if (documentData) {
				const prepareData = new Uint8Array(documentData);
				const documentBlob = new Blob([prepareData], {
					type: 'application/pdf'
				});
				if (appStore.isPWA() && !appStore.isIos) {
					// no longer works on ios as of ios 14
					this.triggerMobileWebDownload(documentBlob);
				} else {
					this.triggerDesktopDownload(documentBlob, docType);
				}
			} else {
				this.shareError('No data returned');
			}
		} catch (err) {
			this.shareError(err);
		}
	}
	async getDocumentPdf(docType) {
		try {
			const requestData = {
				method: 'download',
				documentsRequested:
					docType !== 'all' ? docType.toLowerCase() : 'idcard',
				dependentRequested: this.props.dependentData,
				media: this.data.images[docType] || this.data.images['idCard']
			};
			if (docType === 'sobDocs') {
				requestData.documentsRequested = 'sob';
			}
			const documents = await UserService.sharePlanDocuments(requestData);
			const documentData = _.get(documents, 'Body.data');
			return documentData;
		} catch (err) {
			this.shareError(err);
		}
	}
	triggerDesktopDownload(documentBlob, docType) {
		this.$f7.dialog.close(); //close any open dialogs before it loses scope
		const documentUrl = URL.createObjectURL(documentBlob);
		const trigger = document.createElement('a');
		trigger.href = documentUrl;
		trigger.target = appStore.isIos ? '_self' : '_blank';
		trigger.download = `${docType !== 'all' ? docType : 'MemberDocuments'}.pdf`;
		trigger.click();
		URL.revokeObjectURL(documentUrl);
	}
	triggerMobileWebDownload(documentBlob) {
		let reader = new FileReader();
		reader.readAsDataURL(documentBlob);
		reader.onload = () => {
			const documentUrl = reader.result;
			window.location.href = documentUrl;
		};
	}
	triggerDeviceShareMenu() {
		try {
			const docType = this.props.docType;
			console.log({ docType });
			let images = this.data.images[docType];
			if (docType === 'all') {
				images = _.get(this.data, 'images.idCard', []).concat(
					_.get(this.data, 'images.sobDocs', [])
				);
			}
			const options = {
				files: images,
				url: 'https://www.carevalet.io',
				chooserTitle: this.text.shareCta
			};
			window.plugins.socialsharing.shareWithOptions(
				options,
				this.shareSuccessful,
				this.shareError
			);
			return;
		} catch (err) {
			this.shareError(err);
		}
	}
	shareSuccessful(data) {
		this.$f7.dialog.close();
		if (appStore.isIos) {
			try {
				this.$f7.statusbar.overlaysWebView(false);
				this.$f7.statusbar.overlaysWebView(true);
			} catch (err) {
				console.log('error resetting status bar state');
			}
		}
	}
	shareError(err) {
		console.log('error sharing document: ', err);
		this.$f7.dialog.close();
		this.$f7.dialog.alert(this.text.shareDocumentError);
		if (appStore.isIos) {
			try {
				this.$f7.statusbar.overlaysWebView(false);
				this.$f7.statusbar.overlaysWebView(true);
			} catch (err) {
				console.log('error resetting status bar state');
			}
		}
	}
	mailInit(data) {
		this.data.selectedForm = 'mail';
		const requestData = {
			method: 'mail',
			documentsRequested: this.props.docType,
			dependentRequested: this.props.dependentData
		};
		UserService.sharePlanDocuments(requestData);
		this.fabControl.click();
	}
	fabClick(data) {
		this.fabControl = data.currentTarget;
	}
	getFab() {
		const configuredActions = ConfigService.getConfigValue(
			'member.documents.shareOptions',
			{}
		);
		return (
			<Fab
				position="left-top"
				slot="fixed"
				onClick={this.fabClick}
				text={this.text.shareCta}
			>
				<Icon ios="f7:plus" aurora="f7:add" md="material:add"></Icon>
				<Icon ios="f7:multiply" aurora="f7:close" md="material:close"></Icon>
				<FabButtons className="share-document-buttons" position="bottom">
					{configuredActions.email && (
						<FabButton onClick={this.emailInit}>
							{this.text.emailLabel}
						</FabButton>
					)}
					{configuredActions.fax && (
						<FabButton onClick={this.faxInit}>{this.text.faxLabel}</FabButton>
					)}
					{configuredActions.mail && (
						<FabButton onClick={this.mailInit}>{this.text.mailLabel}</FabButton>
					)}
					{configuredActions.download && (
						<FabButton onClick={this.downloadInit}>
							{appStore.isApp()
								? this.text.appDownloadLabel
								: this.text.downloadLabel}
						</FabButton>
					)}
				</FabButtons>
			</Fab>
		);
	}
	closeViewer() {
		this.closeForm(); // make sure any open forms are closed to prevent persistent overlay
		this.props.onClose();
	}
	formatIdCardData(data) {
		return [
			{
				html: this.getImageContainer(data.cardFront),
				caption: `${appStore.isMobile ? '' : this.text.zoomInstruction} ${
					this.text.idCardFrontCaption
				}`
			},
			{
				html: this.getImageContainer(data.cardBack),
				caption: `${appStore.isMobile ? '' : this.text.zoomInstruction} ${
					this.text.idCardBackCaption
				}`
			}
		];
	}
	formatSobData(imageUrls) {
		const formatted = imageUrls.map((image) => {
			return {
				html: this.getImageContainer(image),
				caption: `${appStore.isMobile ? '' : this.text.zoomInstruction} ${
					this.text.sobCaption
				}`
			};
		});
		return formatted;
	}
	async componentDidMount() {
		console.log('MemberDocumentViewer Mounted');
		const imageViewer = _.get(this.photoRef, 'current');
		this.$f7.dialog.preloader();
		let photos = [];
		switch (this.props.docType) {
			case 'idCard':
				const idCardData = await UserService.getPlanDocuments(
					'idCard',
					this.props.dependentData
				);
				if (!idCardData) {
					break;
				}
				photos = this.formatIdCardData(idCardData[0]);
				this.data.images = {
					idCard: [idCardData[0].cardFront, idCardData[0].cardBack]
				};
				break;
			case 'sobDocs':
				const sobData = await UserService.getPlanDocuments(
					'sobDocs',
					this.props.dependentData
				);
				if (!sobData) {
					break;
				}
				photos = this.formatSobData(sobData);
				this.data.images = { sobDocs: [...sobData] };
				break;
			default:
				const documents = await UserService.getPlanDocuments(
					'all',
					this.props.dependentData
				);
				const idCards = this.formatIdCardData(documents.idCards);
				const sobDocs = this.formatSobData(documents.statementOfBenefits);
				this.data.images = {
					idCard: [documents.idCard[0].cardFront, documents.idCard[0].cardBack],
					sobDocs: [...documents.statementOfBenefits]
				};
				photos = idCards.concat(sobDocs);
				break;
		}
		this.$f7.dialog.close();
		if (imageViewer) {
			imageViewer.f7PhotoBrowser.params.photos = photos;
			imageViewer.open(0);
		}
	}
	getImageContainer(url) {
		return `
            <div class="cv-gallery-container y-scroll">
                <div class="photo-gallery-image-container">
                    <span class="swiper-zoom-container">
                        <img class="photo-gallery-image" src=${url} />
                    </span>
                </div>
            </div>
        `;
	}
	closeForm(data) {
		this.data.formOpen = false;
	}
	render() {
		return (
			<div>
				<PhotoBrowser
					type="popup"
					ref={this.photoRef}
					routableModals={false}
					navbarOfText={this.text.paginationText}
					navbarShowCount={appStore.isMobile}
					exposition={false}
					onPhotoBrowserOpened={this.onGalleryOpened}
					onPhotoBrowserClose={this.closeViewer}
					swipeToClose={false}
					popupCloseLinkText={this.text.closeCta}
				/>
				<Popup
					className="share-form-sheet"
					opened={this.data.formOpen}
					onPopupClose={this.closeForm}
				>
					{this.data.formOpen && (
						<ShareDocuments
							docType={this.props.docType}
							selectedMember={
								this.props.dependentData || userStore.userData.member
							}
							isCurrentUser={!this.props.dependentData}
							shareType={this.data.selectedForm}
							closeForm={this.closeForm}
							emailTemplate={this.props.emailTemplate}
							emailSubject={this.props.emailSubject}
						/>
					)}
				</Popup>
			</div>
		);
	}
}
