import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '../../../utils/GeneralUtils';
import { Popup, Link, Button } from 'framework7-react';
import { observer, observable } from '../../../utils/State';
import appStore from '../../../stores/AppStore';
import { format } from 'date-fns';
import './consumer-panel.scss';
import BasicInput from '@/components/basic-input/BasicInput';
import CloudAPI from '@/services/CloudAPI';
import userStore from '@/stores/UserStore';

@observer
export default class ConsumerPanel extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentView: 'dashboard',
			consumer: {},
			existingCount: 0,
			credit: 0,
			transactions: [],
			calllog: [],
			creditHistory: [],
			user: {},
			adminMap: {},
			notes: '',
			creditType: 'increase'
		});
		autoBind(this);
	}

	componentDidMount() {
		CloudAPI.getAdmins()
			.then((res) => {
				let adminMap = {};
				_.forEach(res, (a) => {
					adminMap[a._id] = a;
				});
				this.data.adminMap = adminMap;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	closePrompt() {
		this.data.currentView = 'dashboard';
		this.data.consumer = {};
		this.data.user = {};
		this.data.credit = 0;
		this.data.notes = '';
		this.data.creditHistory = [];
		this.data.creditType = 'increase';
		if (this.props.closePanel) {
			this.props.closePanel();
		}
	}

	async onPopupOpen() {
		console.log('consumer popup open');
		this.data.consumer = _.cloneDeep(this.props.consumer);
		CloudAPI.getUserData(this.data.consumer.userUid)
			.then((res) => {
				this.data.user = res;
				this.data.existingCount = res.user.tokenCount;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	onCreditChange(e) {
		this.data.credit = e.currentTarget.value;
	}

	onNoteChange(e) {
		this.data.notes = e.currentTarget.value;
	}

	issueCredit() {
		this.$f7.dialog.preloader();
		const currentUser = _.get(userStore, 'userData.user._id', null);
		CloudAPI.issueCareCredit(this.data.consumer.userUid, this.data.credit, this.data.creditType, currentUser, this.data.notes)
			.then((res) => {
				this.data.user = res;
				this.data.credit = res.tokenCount;
				this.data.currentView = 'dashboard';
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	loadTransactionHistory() {
		this.$f7.dialog.preloader();
		CloudAPI.consumerTransactions(this.data.consumer.userUid)
			.then((res) => {
				this.data.transactions = res;
				this.data.currentView = 'transactions';
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	loadCallHistory() {
		this.$f7.dialog.preloader();
		CloudAPI.consumerCallLog(this.data.consumer.firebaseUid)
			.then((res) => {
				this.data.calllog = res;
				this.data.currentView = 'calllog';
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	loadCreditHistory() {
		this.$f7.dialog.preloader();
		CloudAPI.creditHistory(this.data.consumer.userUid)
			.then((res) => {
				this.data.creditHistory = res;
				this.data.currentView = 'credit-history';
				this.$f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	refundTransaction(trans) {
		setTimeout(() => {
			window.open(
				`https://dashboard.stripe.com/${process.env.NODE_ENV === 'development' ? 'test/' : ''}payments/${trans.payment_intent}`,
				'_blank'
			);
		}, 0);
	}

	buildConsumerContent() {
		if (!this.props.consumer) {
			return '';
		}
		let { consumer } = this.data;
		if (!consumer.memberId) {
			return '';
		}
		console.log(`Opened ${consumer.memberId}`);
		switch (this.data.currentView) {
			case 'dashboard': {
				return (
					<div className="consumer-dashboard animated fadeInLeft">
						<div className="options">
							<div className="hbox vcenter hcenter">
								<div className="option neumorphic neuhover vbox vcenter hcenter" onClick={this.loadTransactionHistory}>
									<i className="fad fa-file-invoice-dollar"></i>
									Transaction History
								</div>
								<div className="option neumorphic neuhover vbox vcenter hcenter" onClick={this.loadCallHistory}>
									<i className="fad fa-phone"></i>
									Call History
								</div>
							</div>
							<div className="hbox vcenter hcenter">
								<div
									className="option neumorphic neuhover vbox vcenter hcenter"
									onClick={() => {
										this.data.currentView = 'issue-credit';
									}}
								>
									<i className="fad fa-coin"></i>
									Manage Credits
								</div>
								<div className="option neumorphic neuhover vbox vcenter hcenter" onClick={this.loadCreditHistory}>
									<i className="fad fa-abacus"></i>
									Credit History
								</div>
							</div>
						</div>
					</div>
				);
			}
			case 'issue-credit': {
				return (
					<div className="issue-credit animated fadeInRight">
						<Button
							onClick={() => {
								this.data.credit = 0;
								this.data.notes = '';
								this.data.existingCount = 0;
								this.data.creditType = 'increase';
								this.data.currentView = 'dashboard';
							}}
						>{`< Back`}</Button>
						<div className="message-bdy">Increase or decrease credits as needed.</div>
						<h2>Currrent Credits: {this.data.existingCount}</h2>
						<div className="credit-type hbox vcenter">
							<Button
								className={`neumorphic ${this.data.creditType === 'increase' ? 'selected green' : ''}`}
								onClick={() => {
									this.data.creditType = 'increase';
								}}
							>
								Increase
							</Button>
							<Button
								className={`neumorphic ${this.data.creditType === 'decrease' ? 'selected red' : ''}`}
								onClick={() => {
									this.data.creditType = 'decrease';
								}}
							>
								Decrease
							</Button>
						</div>
						<BasicInput
							label="Credits"
							type="number"
							value={this.data.credit}
							placeholder={`Credit Amount`}
							onChange={this.onCreditChange}
						></BasicInput>
						<BasicInput
							label="Notes"
							type="textarea"
							value={this.data.notes}
							placeholder={`What is the reason for this change? (10 char min)`}
							onChange={this.onNoteChange}
						></BasicInput>
						<Button
							className="round-btn purple"
							onClick={this.issueCredit}
							disabled={this.data.credit <= 0 || this.data.notes.length <= 10}
						>
							Submit
						</Button>
					</div>
				);
			}
			case 'transactions': {
				let total = 0;
				_.forEach(this.data.transactions, (t) => {
					if (t.paid && !t.refunded && !t.disputed) {
						total += t.amount;
					}
				});
				return (
					<div className="transactions animated fadeInRight">
						<Button
							onClick={() => {
								this.data.transactions = [];
								this.data.currentView = 'dashboard';
							}}
						>{`< Back`}</Button>
						<div className="total">Total ${total / 100}</div>
						{this.data.transactions.map((t) => {
							return (
								<div className="transaction hbox vcenter" key={t.id}>
									<div className="details vbox vcenter">
										<div className="title">
											{t.description} - ${t.amount / 100}
										</div>
										<div className="date">{format(new Date(t.created * 1000), 'MM/dd/yyyy p')}</div>
										<div className="chips hbox vcenter">
											{t.paid && <div className="chip paid">Paid</div>}
											{t.disputed && <div className="chip disputed">Disputed</div>}
											{t.refunded && <div className="chip refunded">Refunded</div>}
										</div>
									</div>
									<Button
										className="reciept"
										onClick={() => {
											setTimeout(() => {
												window.open(t.receipt_url, '_blank');
											}, 0);
										}}
									>
										Receipt
									</Button>
									{!t.refunded && (
										<Button
											className="refund"
											onClick={() => {
												this.refundTransaction(t);
											}}
										>
											Refund
										</Button>
									)}
								</div>
							);
						})}
					</div>
				);
			}
			case 'calllog': {
				let sorted = _.sortBy(_.cloneDeep(this.data.calllog), 'timestamp');
				let mapped = {};
				_.forEach(sorted, (s) => {
					if (s.callSid) {
						if (!mapped[s.callSid]) {
							mapped[s.callSid] = [];
						}
						mapped[s.callSid].push(s);
					}
				});
				return (
					<div className="call-logs animated fadeInRight">
						<div className="log-header">
							<Button
								onClick={() => {
									this.data.calllog = [];
									this.data.currentView = 'dashboard';
								}}
							>{`< Back`}</Button>
							<div className="details">
								Consumer call logs represent the CareValet leg of the call. Does not include actual DR call info.
							</div>
						</div>
						{_.sortBy(Object.values(mapped), 'timestamp')
							.reverse()
							.map((logs) => {
								let initiated = logs[0];
								let final = logs[logs.length - 1];
								let seconds = Math.floor((new Date(final.timestamp).getTime() - new Date(initiated.timestamp).getTime()) / 1000);
								return (
									<div className="call-log hbox vcenter" key={final._id}>
										<div className="event vbox vcenter hleft">
											<div className="status">{initiated.callStatus}:</div>
											<div className="date">{format(new Date(initiated.timestamp), 'MM/dd/yyyy p')}</div>
										</div>
										<div className="event vbox vcenter hleft">
											<div className="status">{final.callStatus}:</div>
											<div className="date">{format(new Date(final.timestamp), 'MM/dd/yyyy p')}</div>
										</div>
										<div className="duration">{seconds >= 60 ? `${Math.floor(seconds / 60)} Minute(s)` : `${seconds} Seconds`}</div>
									</div>
								);
							})}
					</div>
				);
			}
			case 'credit-history': {
				return (
					<div className="credit-history animated fadeInRight">
						<div className="log-header">
							<Button
								onClick={() => {
									this.data.creditHistory = [];
									this.data.currentView = 'dashboard';
								}}
							>{`< Back`}</Button>
						</div>
						{this.data.creditHistory.length == 0 && <div className="details">No Credit History To Show</div>}
						{_.sortBy(Object.values(this.data.creditHistory), 'timestamp')
							.reverse()
							.map((h) => {
								let issuedBy = this.data.adminMap[h.issuerId];
								return (
									<div className="credit-ctn" key={h._id}>
										<div className="hbox vcenter">
											<div className={`type ${h.tokenType}`}>{h.tokenType}</div>
											<div className={`qty ${h.tokenType}`}>
												{h.tokenType === 'credit' ? '+' : '-'}
												{h.quantity}
											</div>
										</div>
										<div className="date">{format(new Date(h.timestamp), 'MM/dd/yyyy p')}</div>
										<div className="reason">
											Reason: <span>{h.tokenReason}</span>
										</div>
										{!_.isEmpty(h.issuerId) && (
											<div className="issuer">
												Issued By: <span>{`${issuedBy.firstName} ${issuedBy.lastName}`}</span>
											</div>
										)}
										<div className="notes">{h.notes}</div>
									</div>
								);
							})}
					</div>
				);
			}
		}
	}

	render() {
		let { consumer } = this.data;
		return (
			<Popup
				opened={this.props.opened}
				className={`consumer-panel-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={true}
				closeOnEscape={true}
				onPopupOpen={this.onPopupOpen}
				onPopupClosed={this.closePrompt}
			>
				<div className="popup-header">
					{this.props.consumer && <div className="title grow-1">{`${consumer.firstName} ${consumer.lastName}`}</div>}
					<Link popupClose>
						<i className="fad fa-times-square" />
					</Link>
				</div>
				<div className="consumer-content y-scroll">{this.buildConsumerContent()}</div>
			</Popup>
		);
	}
}
