import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../../utils/State';
import { autoBind } from '../../../../utils/GeneralUtils';
import { Button, List, ListInput } from 'framework7-react';
import './search-form.scss';

@observer
export default class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			searchForm: this.props.priorSearch
		});
		autoBind(this);
	}

	handleFormChange(evt) {
		const formField = evt.currentTarget.name;
		const formValue = evt.currentTarget.value;
		const currentValues = _.cloneDeep(this.data.searchForm);
		_.set(currentValues, formField, formValue);
		this.data.searchForm = currentValues;
	}

	async submitSearch() {
		const data = _.cloneDeep(this.data.searchForm);
		this.props.search({ searchForm: data });
	}

	clearField(evt) {
		const formField = evt.currentTarget.name;
		const searchData = _.cloneDeep(this.data.searchForm);
		if (searchData[formField]) {
			this.data.searchForm[formField] = null;
		}
	}

	render() {
		return (
			<div className="search-form y-scroll">
				<h2>
					Member Search
					<i className="fad fa-times-square" onClick={this.props.close} />
				</h2>
				<List noHairlines>
					<ListInput
						label="First Name"
						type="text"
						placeholder="Enter First Name"
						clearButton
						className="md"
						floatingLabel
						onChange={this.handleFormChange}
						name="firstName"
						value={_.get(this.data, 'searchForm.firstName', '') || ''}
						onInputClear={this.clearField}
					/>
					<ListInput
						label="Last Name"
						type="text"
						placeholder="Enter Last Name"
						clearButton
						className="md"
						floatingLabel
						onChange={this.handleFormChange}
						name="lastName"
						value={_.get(this.data, 'searchForm.lastName', '') || ''}
						onInputClear={this.clearField}
					/>
					<ListInput
						label="Email Address"
						type="text"
						placeholder="Enter Email Address"
						clearButton
						className="md"
						floatingLabel
						onChange={this.handleFormChange}
						name="email"
						value={_.get(this.data, 'searchForm.email', '') || ''}
						onInputClear={this.clearField}
					/>
					<ListInput
						label="Member Number"
						type="text"
						placeholder="Enter Member Number"
						clearButton
						className="md"
						floatingLabel
						onChange={this.handleFormChange}
						name="memberId"
						value={_.get(this.data, 'searchForm.memberId', '') || ''}
						onInputClear={this.clearField}
					/>
					<ListInput
						label="Group ID"
						type="text"
						placeholder="Enter Group ID"
						clearButton
						className="md"
						floatingLabel
						onChange={this.handleFormChange}
						name="groupId"
						value={_.get(this.data, 'searchForm.groupId', '') || ''}
						onInputClear={this.clearField}
					/>
				</List>
				<Button fill round onClick={this.submitSearch}>
					Search Members
				</Button>
			</div>
		);
	}
}
