import React, { Component } from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { Button, Popup, Link, Popover, List, ListItem, ListInput, Preloader } from 'framework7-react';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import DropDown from '../../components/drop-down/DropDown';
import CloudAPI from '../../services/CloudAPI';
import goodrx from '../../assets/goodrxmb.png';
import AnalyticsService from '../../services/AnalyticsService';
import _ from 'lodash';
import './medication-page.scss';
import appStore from '../../stores/AppStore';
import UserService from '../../services/UserService';
import FeedbackForm from '../../components/feedback-form/FeedbackForm';

const analytics = new AnalyticsService();

@observer
export default class MedicationPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			medication: '',
			info: null,
			error: null,
			loadingPrice: true,
			loadingInfo: true,
			pricingData: {},
			couponData: {},
			activePriceObj: {},
			selectedValues: {
				form: '',
				dosage: '',
				quantity: ''
			}
		});
		this.text = getCmpText('MedicationPage', {
			dosage: 'Dosage',
			form: 'Form',
			priceShop: 'Price Shop',
			coupon: 'Coupon',
			couponSearching: 'Searching for Coupon',
			couponNotFound: 'Unable to find coupon. Please try another pharmacy.',
			savingCoupon: 'Saving...',
			errorSavingCoupon: 'Error saving prescription',
			savings: 'Save',
			noPricingInfo: 'No pricing data found for this form or dosage. Please select another form or dosage to find pricing data.',
			couponDisclaimer:
				'*Please note: If you choose to use a coupon for this prescription, the amount you pay will not apply to your insurance out of pocket maximums',
			medNotAvailable: 'is no longer available.',
			medDiscontinued: ' has been discontinued by the manufacturer or is no longer available through GoodRX.',
			download: 'Download',
			close: 'Close',
			save: 'Save',
			quantity: 'Quantity',
			shareCta: 'Share',
			badDataCta: 'Report Bad Data'
		});
		autoBind(this);
	}

	async componentDidMount() {}

	onClose(reloadList) {
		if (this.props.onClose) {
			this.props.onClose(reloadList === true);
		}
	}

	getDefaultData(data) {
		let form = '';
		let dosage = '';
		let quantity = '';
		const dataInfo = data && data.quantities;
		if (!!dataInfo) {
			try {
				form = Object.keys(data.quantities)[0];
				dosage = Object.keys(data.quantities[form])[0];
				quantity = data.quantities[form][dosage][0];
			} catch (err) {
				console.log(err);
			}
		}
		if (this.props.selectedValues) {
			if (!this.props.selectedValues.quantity && !!dataInfo) {
				const selectedForm = _.get(this.props, 'selectedValues.form') || 0;
				form = dataInfo[selectedForm];
				const selectedDosage = _.get(this.props, 'selectedValues.dosage') || 0;
				form = dataInfo[selectedForm][selectedDosage];
				const selectedQty = _.get(this.props, 'selectedValues.quantity');
				quantity = selectedQty || dataInfo[selectedForm][selectedDosage][0];
				return {
					...this.props.selectedValues,
					quantity: quantity
				};
			}
			return this.props.selectedValues;
		}

		if (!!dataInfo) {
			return { form, dosage, quantity };
		}

		return { form: '', dosage: '', quantity: '' };
	}

	async loadMedInfo() {
		this.data.medication = this.props.medication;
		this.data.info = null;
		this.data.error = null;
		this.data.pricingData = {};
		this.data.loadingInfo = true;
		analytics.track('rx_search');
		CloudAPI.getDrugInfo(this.data.medication)
			.then((res) => {
				this.data.info = res.data || '';
				this.data.selectedValues = this.getDefaultData(res.data);
				this.data.loadingInfo = false;
				this.comparePrices();
				if (this.props.openCoupon) {
					this.getCoupon(null, this.props.openCoupon);
				}
			})
			.catch((err) => {
				this.data.error = true;
				this.data.loadingPrice = false;
				this.data.loadingInfo = false;
				console.log(err);
			});
	}

	comparePrices() {
		this.data.loadingPrice = true;

		CloudAPI.priceCompare({ ...this.data.selectedValues, name: this.data.medication, zipcode: appStore.currentLocation.zip })
			.then((res) => {
				if (res == '') {
					console.log('Empty response from server');
				}
				console.log(res);
				this.data.pricingData = res !== '' && res.data ? res.data : {};
				this.data.loadingPrice = false;
			})
			.catch((err) => {
				this.data.loadingPrice = false;
				console.log(err);
			});
	}

	getCoupon(priceObj, coupon_key) {
		this.$f7.dialog.preloader(this.text.couponSearching);
		CloudAPI.getCoupon(coupon_key ? coupon_key : priceObj.coupon_key)
			.then((res) => {
				this.data.couponData = res;
				this.data.activePriceObj = priceObj;
				if (!res.coupon_html) {
					this.$f7.dialog.alert(this.text.couponNotFound);
					console.log('NO COUPON HTML: ', res);
				}
				this.$f7.dialog.close();
				analytics.track('get_coupon');
			})
			.catch((err) => {
				this.$f7.dialog.close();
				this.$f7.dialog.alert(this.text.couponNotFound);
				console.log('NO COUPON');
				analytics.track('get_coupon_error');
			});
	}

	async saveCoupon() {
		const activePriceObj = this.data.activePriceObj || {};
		let { pharmacy, coupon_key, coupon_url, price, ncpdp } = activePriceObj;
		if (!coupon_key) {
			// this wont exist if the coupon is already saved and the user is just viewing it again, no need to save again
			this.data.couponData = {};
			return;
		}
		this.$f7.dialog.preloader(this.text.savingCoupon);
		try {
			await CloudAPI.savePrescription({
				name: this.data.medication,
				...this.data.selectedValues,
				pharmacy,
				price,
				ncpdp,
				coupon_key,
				coupon_url
			});
			this.data.couponData = {};
			this.data.activePriceObj = {};
			this.$f7.dialog.close();
			this.onClose(true);
			analytics.track('save_coupon');
		} catch (err) {
			console.log(err);
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.errorSavingCoupon);
			analytics.track('save_coupon_error');
		}
	}

	buildPriceList() {
		let prices = _.get(this, 'data.pricingData.prices', []);
		let priceList = [];
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		});
		_.forEach(prices, (p) => {
			if (p.coupon_key) {
				priceList.push(
					<div className="price-list-item" key={Math.random()}>
						<div className="pharmacy">{p.pharmacy}</div>
						<div className="hbox vcenter">
							<div className="ctn grow-1">
								<div className="price ">{formatter.format(p.price)}</div>
							</div>
							{p.retail_price && (
								<div className="savings-data">
									<div className="retail-price">{formatter.format(p.retail_price)}</div>
									<div className="savings">{`${this.text.savings} ${p.savings}`}</div>
								</div>
							)}
							<Button
								large
								className="neubtn hbox vcenter"
								onClick={() => {
									this.getCoupon(p);
								}}
							>
								<i className="fad fa-search"></i> {this.text.coupon}
							</Button>
						</div>
					</div>
				);
			}
		});
		return (
			<div className="pricing-list">
				{!this.data.loadingPrice && !this.data.loadingInfo && _.get(this, 'data.pricingData.prices.length', 0) <= 0 && (
					<div className="nodata hbox vcenter">
						<i className="fad fa-exclamation-circle"></i>
						{this.text.noPricingInfo}
					</div>
				)}
				{_.get(this, 'data.pricingData.prices.length', 0) > 0 && [priceList]}
				{_.get(this, 'data.pricingData.prices.length', 0) > 0 && <div className="note">{this.text.couponDisclaimer}.</div>}
			</div>
		);
	}

	buildDrugForm() {
		let { info, selectedValues } = this.data;
		let { form, dosage, quantity } = selectedValues;
		const getValues = (type) => {
			try {
				switch (type) {
					case 'form': {
						return Object.keys(info.quantities);
					}
					case 'dosage': {
						return Object.keys(info.quantities[form]);
					}
					case 'quantity': {
						return Object.values(info.quantities[form][dosage]);
					}
				}
			} catch {}
		};
		return (
			<>
				<DropDown name="form-dd" label={this.text.form} items={this.buildDropdown(getValues('form'), 'form')} displayValue={form} />
				<DropDown
					name="dosage-dd"
					label={this.text.dosage}
					items={this.buildDropdown(getValues('dosage'), 'dosage')}
					displayValue={dosage}
				/>
				<DropDown
					name="quantity-dd"
					label={this.text.quantity}
					items={this.buildDropdown(getValues('quantity'), 'quantity')}
					displayValue={quantity}
				/>
			</>
		);
	}

	buildDropdown(values, key) {
		let listItems = [];
		_.forEach(values, (rec) => {
			listItems.push(
				<ListItem
					link="#"
					popoverClose
					className="filter-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						let { info, selectedValues } = this.data;
						this.data.selectedValues[key] = rec;
						try {
							switch (key) {
								case 'form': {
									let dosage = Object.keys(info.quantities[rec])[0];
									const quantity = info.quantities[rec][dosage][0];
									this.data.selectedValues.dosage = dosage;
									this.data.selectedValues.quantity = quantity;
									break;
								}
								case 'dosage': {
									const form = selectedValues && selectedValues.form;
									let quantity = info.quantities[form][rec][0];
									this.data.selectedValues.quantity = quantity;
								}
							}
						} catch (err) {
							console.log(err);
						}
						this.comparePrices();
					}}
				>
					<div className="label">{`${rec}`}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	async downloadCoupon() {
		let { couponData } = this.data;
		const linkSource = `data:application/pdf;base64,${couponData.coupon_pdf}`;
		const fileName = 'coupon.pdf';
		const downloadLink = document.createElement('a');

		const decodedPdf = atob(couponData.coupon_pdf);
		const decodedLength = decodedPdf.length;
		const decodedBuffer = new ArrayBuffer(decodedLength);
		const decodedArray = new Uint8Array(decodedBuffer);

		for (let i = 0; i < decodedLength; i++) {
			decodedArray[i] = decodedPdf.charCodeAt(i);
		}

		const pdfBlob = new Blob([decodedArray], { type: 'application/pdf' });
		await this.saveCoupon();
		if (appStore.isApp()) {
			const options = {
				files: [linkSource],
				url: couponData.url,
				chooserTitle: this.text.shareCta
			};
			window.plugins.socialsharing.shareWithOptions(
				options,
				() => {
					this.$f7.dialog.close();
					if (appStore.isIos) {
						try {
							this.$f7.statusbar.overlaysWebView(false);
							this.$f7.statusbar.overlaysWebView(true);
						} catch (err) {
							console.log('error resetting status bar state');
						}
					}
				},
				() => {
					this.$f7.dialog.close();
					if (appStore.isIos) {
						try {
							this.$f7.statusbar.overlaysWebView(false);
							this.$f7.statusbar.overlaysWebView(true);
						} catch (err) {
							console.log('error resetting status bar state');
						}
					}
				}
			);
			return;
		}

		if (appStore.isPWA() && !appStore.isIos) {
			const reader = new FileReader();
			reader.readAsDataURL(pdfBlob);
			reader.onload = () => {
				const documentUrl = reader.result;
				window.location.href = documentUrl;
			};
		} else {
			const pdfUrl = URL.createObjectURL(pdfBlob);
			downloadLink.href = pdfUrl;
			downloadLink.target = appStore.isIos ? 'self' : 'blank';
			downloadLink.download = fileName;
			downloadLink.click();
			URL.revokeObjectURL(pdfUrl);
		}
	}

	openFeedbackForm() {
		this.data.showFeedbackForm = true;
	}

	closeFeedbackForm() {
		this.data.showFeedbackForm = false;
	}

	render() {
		let { open } = this.props;
		let { info, error, medication, loadingPrice, pricingData, couponData } = this.data;
		const feedbackFormData = { error: this.data.error, medication: this.data.medication, selectedValues: this.data.selectedValues };
		return (
			<Popup
				closeByBackdropClick={false}
				className={`medication-popup`}
				opened={open}
				onPopupClosed={this.onClose}
				onPopupOpen={this.loadMedInfo}
			>
				<div className="medication-page y-scroll">
					<div className="header hbox vtop">
						<div className="vbox vcenter w-100">
							<div className="hbox vcenter w-100">
								<i className="fad fa-pills"></i>
								<div className="img-ctn vbox hcenter grow-1">
									<img src={goodrx} alt="goodrx" style={{ height: '44px' }} />
								</div>
								{(!couponData || !couponData.coupon_pdf) && (
									<Link popupClose>
										<i className="fad fa-times-square" />
									</Link>
								)}
							</div>
							<h2 className="drug-name grow-1">{this.data.medication}</h2>
						</div>
					</div>

					<div className="active-content">
						{!info && !error && (
							<div className="vbox hcenter vcenter">
								<Preloader></Preloader>
							</div>
						)}
						{error && (
							<div className="error w-100">
								<div className="hbox vcenter w-100">
									<i className="fad fa-exclamation-triangle m-r-16" />
									<h3>{`${medication} ${this.text.medNotAvailable}`}</h3>
								</div>
								<p>
									<span>{medication}</span> {this.text.medDiscontinued}
								</p>
							</div>
						)}
						{info && this.buildDrugForm()}
						<FeedbackForm
							show={this.data.showFeedbackForm}
							close={this.closeFeedbackForm}
							type="RX"
							data={_.cloneDeep(feedbackFormData)}
						/>
						<Button className="feedback-cta btnfix neumorphic neuhover" round large onClick={this.openFeedbackForm}>
							{this.text.badDataCta}
						</Button>
						{loadingPrice && (
							<div className="loader vbox hcenter vcenter">
								<Preloader></Preloader>
							</div>
						)}
						{!loadingPrice && pricingData && !error && this.buildPriceList()}
					</div>
					{couponData && couponData.coupon_pdf && (
						<div className="coupon-ctn">
							<PDFViewer
								scale={1.25}
								scaleStep={0.25}
								navbarOnTop={true}
								document={{
									base64: couponData.coupon_pdf
								}}
							></PDFViewer>
							<div className="actions hbox vcenter hright">
								<Button onClick={this.downloadCoupon} className="neubtn">
									{this.text.download}
								</Button>
								<Button
									onClick={() => {
										this.data.couponData = {};
										this.data.activePriceObj = {};
									}}
									className="neubtn"
								>
									{this.text.close}
								</Button>
								<Button onClick={this.saveCoupon} fill className="neubtn">
									{this.text.save}
								</Button>
							</div>
						</div>
					)}
				</div>
			</Popup>
		);
	}
}
