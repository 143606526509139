import { observable } from '../utils/State';
const providerStore = observable({
	toggleValue: 'list',
	filters: {
		category: 'physician',
		specialty: 'Primary Care',
		inNetwork: true,
		gender: 'any',
		sort: 'distance',
		radius: 10
	},
	activeProvider: {},
	searchResults: [],
	careTeam: [],
	openProvider: {},
	selectedCareTeam: null,
	openProviderBDData: {},
	page: 0,
	mapCenter: { lat: 27.95457, lon: -82.524221 }
});

export default providerStore;
