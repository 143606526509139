import _ from 'lodash';
import NotFoundPage from '../pages/404.jsx';
import Login from '../pages/login/Login.js';
import LandingPage from '../pages/application-shell/ApplicationShell.js';
import ProviderProfile from '../components/provider-profile/ProviderProfile.js';
import ProviderManagement from '../pages/provider-management/ProviderMagement.js';
import ProviderLogin from '../pages/provider-portal/login/ProviderLogin.js';
import ProviderHome from '../pages/provider-portal/home/ProviderHome.js';
import userStore from '@/stores/UserStore';
import StorageService from '@/services/StorageService';
import appStore from '@/stores/AppStore.js';
import ExpressLogin from '@/pages/EXPRESS/login/ExpressLogin.js';
import ExpressShell from '@/pages/EXPRESS/appshell/ExpressShell.js';
import expressStore from '@/stores/ExpressStore.js';
import IdCardEditor from '@/pages/EXPRESS/idcards/id-card-editor/IdCardEditor.js';
import SettingsPage from '@/pages/EXPRESS/settings/SettingsPage.js';

const storageService = new StorageService();

var routes = [
	{
		path: '/',
		async: async function (routeTo, routeFrom, resolve, reject) {
			if (appStore.isExpressPortal) {
				storageService.setValue('app', 'expressUser', true);
				resolve({ component: ExpressLogin });
				return;
			}
			resolve({ component: Login });
		}
	},
	{
		path: '/login',
		async: async function (routeTo, routeFrom, resolve, reject) {
			if(window.f7) {
				//make sure no ghosting components remain open
				//@ts-ignore	
				window.f7.actions.close('.actions-modal.modal-in');
				//@ts-ignore
				window.f7.dialog.close('.dialog.modal-in');
				//@ts-ignore
				window.f7.sheet.close('.sheet-modal.modal-in');
				//@ts-ignore
				window.f7.popover.close('.popover.modal-in');
				//@ts-ignore
				window.f7.popup.close('.popup.modal-in');
			}
			if (appStore.isExpressPortal) {
				storageService.setValue('app', 'expressUser', true);
				resolve({ component: ExpressLogin });
				return;
			}
			const loginRef = _.get(routeTo, 'query.ref');
			const directUrl = _.get(routeTo, 'query.type');
			const currentSignInType = (await storageService.getValue('app', 'signInType')) || '';
			if (loginRef) {
				// moved here because it will work on any sign in entry point (landing-page, login, employer-signin, consumer-signin, etc);
				userStore.isCreatingUser = true; // stop residual auth on device from kicking in
				userStore.signupData.referralSource = loginRef;
				storageService.setValue('app', 'referralSource', loginRef);
				storageService.setValue('app', 'signInType', 'consumer-signup');
			} else if (directUrl) {
				const directToConsumer = directUrl.indexOf('consumer') > -1;
				const directToEmployer = directUrl.indexOf('employer') > -1;
				if (directToConsumer) {
					if (currentSignInType !== 'consumer-signin') {
						storageService.setValue('app', 'signInType', 'consumer-signin');
					}
				} else if (directToEmployer) {
					if (currentSignInType !== 'employer-signin') {
						storageService.setValue('app', 'signInType', 'employer-signin');
					}
				} else {
					if (currentSignInType !== 'unknown') {
						storageService.setValue('app', 'signInType', 'unknown');
					}
				}
			} else {
				const isConsumer = currentSignInType.indexOf('consumer') > -1;
				const isEmployer = currentSignInType.indexOf('employer') > -1;
				if (isConsumer) {
					if (currentSignInType !== 'consumer-signin') {
						storageService.setValue('app', 'signInType', 'consumer-signin');
					}
				} else if (isEmployer) {
					if (currentSignInType !== 'employer-signin') {
						storageService.setValue('app', 'signInType', 'employer-signin');
					}
				} else {
					if (currentSignInType !== 'unknown') {
						storageService.setValue('app', 'signInType', 'unknown');
					}
				}
			}
			resolve({ component: Login });
		}
	},
	{
		path: '/providerlogin',
		component: ProviderLogin
	},
	{
		path: '/providerhome',
		component: ProviderHome
	},
	{
		path: '/home',
		component: LandingPage
	},
	{
		path: '/expresss',
		component: ExpressShell
	},
	{
		path: '/profile',
		component: LandingPage
	},
	{
		path: '/provider',
		component: ProviderProfile
	},
	{
		path: '/providermgmt',
		component: ProviderManagement
	},
	{
		path: '(.*)',
		async: async function (routeTo, routeFrom, resolve, reject) {
			if (appStore.isExpressPortal) {
				storageService.setValue('app', 'expressUser', true);
				resolve({ component: ExpressLogin });
				return;
			}
			resolve({ component: Login });
		}
	}
];

export default routes;
