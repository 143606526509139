import React, { Component } from 'react';
import _ from 'lodash';
import { List, ListInput, Button, Toolbar, Link } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import UserService from '../../services/UserService';
import EmailTemplate from './email-templates/EmailTemplate';
import './share-documents.scss';

@observer
export default class ShareDocuments extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			shareFormValid: false,
			deliveryAddress: '',
			emailBody: '',
			subject: this.props.emailSubject || ''
		});
		this.text = getCmpText('PlanDocuments', {
			emailFormLabel: 'Enter Recipient E-mail *',
			faxFormLabel: 'Enter Fax Number *',
			emailFormPlaceholder: 'Email Address',
			faxFormPlaceholder: 'Fax Number',
			emailSubjectLabel: 'E-mail Subject',
			emailSubjectPlaceholder: 'E-mail Subject',
			emailBodyLabel: 'E-mail Body',
			shareSuccess: 'Document Sent',
			submitBtn: 'Submit'
		});
		autoBind(this);
	}
	componentDidMount() {
		this.$f7.dialog.preloader();
		UserService.getPlanDocuments(this.props.docType, this.props.isCurrentUser ? null : this.props.selectedMember).then((res) => {
			this.$f7.dialog.close();
			this.data.planDocs = res;
		});
	}
	formatIdCardsForDelivery(data) {
		return [{ path: data.cardFront }, { path: data.cardBack }];
	}
	formatSobDocsForDelivery(data) {
		return data.map((docUrl) => ({ path: docUrl }));
	}
	async submitForm(data) {
		let docType = this.props.docType;
		let attachments = [];
		let idCardDocs = [];
		let sobDocs = [];
		const memberHash = _.get(this.props, 'selectedMember.hash');
		this.data.shareFormValid = false;
		this.$f7.dialog.preloader();
		switch (this.props.docType) {
			case 'idCard':
				docType = 'idcard';
				idCardDocs = this.formatIdCardsForDelivery(this.data.planDocs);
				attachments = idCardDocs;
				break;
			case 'sobDocs':
				docType = 'sob';
				sobDocs = this.formatSobDocsForDelivery(this.data.planDocs);
				attachments = sobDocs;
				break;
			default:
				docType = 'documents';
				idCardDocs = this.formatIdCardsForDelivery(_.get(this.data, 'planDocs.idCard', {}));
				sobDocs = this.formatSobDocsForDelivery(_.get(this.data, 'planDocs.statementOfBenefits', []));
				attachments = idCardDocs.concat(sobDocs);
				break;
		}
		const requestData = {
			method: this.props.shareType,
			docType: docType,
			id: this.props.isCurrentUser ? null : _.get(this.props, 'selectedMember.hash'),
			memberHash: memberHash
		};
		try {
			if (this.props.shareType === 'fax') {
				_.set(requestData, 'fax.to', this.data.deliveryAddress);
				_.set(requestData, 'fax.attachments', attachments);
				if (docType === 'documents') {
					_.set(requestData, 'fax.attachments', idCardDocs);
					_.set(requestData, 'docType', 'idcard');
					await this.sendDocuments(requestData, true);
					_.set(requestData, 'fax.attachments', sobDocs);
					_.set(requestData, 'docType', 'sob');
					await this.sendDocuments(requestData);
				} else {
					await this.sendDocuments(requestData);
				}
			} else {
				_.set(requestData, 'email.to', this.data.deliveryAddress);
				_.set(requestData, 'email.attachments', attachments);
				_.set(requestData, 'email.body', this.data.emailBody);
				_.set(requestData, 'email.subject', this.data.subject);

				await this.sendDocuments(requestData);
			}
		} catch (err) {
			console.log('unable to send documents: ', err);
		}
		this.$f7.dialog.close();
	}
	async sendDocuments(requestData, skipToast) {
		return UserService.sharePlanDocuments(requestData)
			.then(() => {
				if (skipToast) return;
				let toast = this.$f7.toast.create({
					position: 'center',
					closeTimeout: 2000,
					icon: `<i class="fad fa-check large-icon-toast" ></i>`,
					text: this.text.shareSuccess
				});
				toast.open();
				this.props.closeForm();
			})
			.catch((err) => {
				console.log('error processing request: ', err);
				this.$f7.dialog.close();
				this.$f7.dialog.alert(_.get(JSON.parse(err.response), 'message', 'An error occured, please try again.'));
			});
	}
	closeSheet() {
		this.props.closeForm();
	}
	inputChange(evt) {
		const value = evt.currentTarget.value;
		const field = evt.currentTarget.name;
		const fieldClasses = evt.currentTarget.classList.value;
		const isInvalid = fieldClasses.indexOf('input-invalid') > -1;
		this.data.shareFormValid = !isInvalid;
		this.data[field] = value;
	}
	updateEmailBody(value) {
		this.data.emailBody = value;
	}
	render() {
		return (
			<div className="h-100">
				<Toolbar>
					<div className="left">Share Plan Documents</div>
					<div className="right">
						<Link onClick={this.closeSheet}>Close</Link>
					</div>
				</Toolbar>
				<div className="share-form-container y-scroll">
					<List noHairlines>
						<ListInput
							outline
							label={this.props.shareType === 'email' ? this.text.emailFormLabel : this.text.faxFormLabel}
							type={this.props.shareType === 'email' ? 'email' : 'tel'}
							name="deliveryAddress"
							placeholder={this.props.shareType === 'email' ? this.text.emailFormPlaceholder : this.text.faxFormPlaceholder}
							onChange={this.inputChange}
							inputId="deliveryAddress"
							noStoreData
							required
							validate
							value={this.data.deliveryAddress}
							className="md"
							floatingLabel={true}
						/>
						{this.props.shareType === 'email' && (
							<EmailTemplate
								template={this.props.emailTemplate}
								onSubjectChange={this.inputChange}
								onBodyChange={this.updateEmailBody}
								subjectLabel={this.text.emailSubjectLabel}
								subjectPlaceholder={this.text.emailSubjectPlaceholder}
								bodyLabel={this.text.emailBodyLabel}
								subject={this.data.subject}
								body={this.data.emailBody}
								selectedMember={this.props.selectedMember}
							/>
						)}
					</List>
					<Button fill round large onClick={this.submitForm} disabled={!this.data.shareFormValid}>
						{this.text.submitBtn}
					</Button>
				</div>
			</div>
		);
	}
}
