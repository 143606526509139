import React, { Component } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import BasicInput from '@/components/basic-input/BasicInput';

@observer
export default class DateInput extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			date: format(new Date(), 'yyyy-MM-dd'),
			dateTextDisplay: ''
		});
		autoBind(this);
	}

	componentDidMount() {
		this.onDateChange({ currentTarget: { value: this.props.value || '', name: 'datePicker' } });
	}

	openDatePicker(evt) {
		let displayDate = this.data.dateTextDisplay;
		const dateIsValid = this.validateDate(this.data.date);
		if (!dateIsValid) {
			displayDate = new Date().toDateString();
		}
		const cal = this.$f7.calendar.create({
			value: [displayDate],
			backdrop: true,
			openIn: 'customModal',
			firstDay: 0,
			closeOnSelect: true,
			dateFormat: 'mm/dd/yyyy',
			on: {
				change: this.onCalendarDateChange
			}
		});
		cal.open();
	}

	onCalendarDateChange(calendar) {
		const value = calendar.value[0];
		const getDate = new Date(value);
		const displayDate = format(getDate, 'MM/dd/yyyy');
		const dateValue = format(getDate, 'yyyy-MM-dd');
		this.data.dateTextDisplay = displayDate;
		this.data.date = dateValue;
		this.props.onChange(dateValue, 'dateText', this.validateFields());
	}

	onDateChange(evt) {
		const value = evt.currentTarget.value;
		const cleanValue = value.replace(/[^0-9]/g, '');
		const cleanParts = cleanValue.split('');
		const field = evt.currentTarget.name;

		let month = cleanParts.slice(0, 2).join('');
		let day = cleanParts.slice(2, 4).join('');
		let year = cleanParts.slice(4, 8).join('');

		if (field === 'datePicker') {
			year = cleanParts.slice(0, 4).join('');
			month = cleanParts.slice(4, 6).join('');
			day = cleanParts.slice(6, 8).join('');
		}

		const dateValue = `${year}-${month}-${day}`;
		this.data.date = dateValue;
		if (cleanParts.length >= 5) {
			this.data.dateTextDisplay = `${month}/${day}/${year}`;
		} else if (cleanParts.length >= 3) {
			this.data.dateTextDisplay = `${month}/${day}`;
		} else {
			this.data.dateTextDisplay = `${month}`;
		}
		const isValid = this.validateFields();
		this.props.onChange(dateValue, field, isValid);
	}

	validateFields() {
		const date = _.get(this.data, 'date', '');
		const hasDate = date && date.length > 0;
		const validDate = this.validateDate(date);
		return hasDate && validDate;
	}

	validateDate(date) {
		if (!date) return false;
		const dateParts = date.split('-');
		const datePartsLength = dateParts.length;
		const validLength = datePartsLength === 3;
		const validMonth = parseInt(dateParts[1]) < 13;
		const validDay = parseInt(dateParts[2]) <= 31;
		const validYear = dateParts[0].replace(/[^0-9]*/g, '').length === 4;
		return validLength && validMonth && validDay && validYear;
	}

	render() {
		return (
			<div>
				<BasicInput
					label={this.props.label}
					onChange={this.onDateChange}
					name="dateText"
					placeholder={this.props.placeholder}
					value={this.data.dateTextDisplay}
					type="text"
					icon="fal fa-calendar-alt"
					iconAction={this.openDatePicker}
				/>
			</div>
		);
	}
}
