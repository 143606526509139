import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import GradiantStack from '@/components/gradiant-stack/GradiantStack';
import FingerprintSvg from '@/assets/finger_print.svg';
import FaceIdSvg from '@/assets/face_id.svg';
import BiometricsService from '@/services/BiometricsService';
import './biometrics-prompt.scss';

@observer
export default class BiometricsPrompt extends Component {
	constructor(props) {
		super();
		this.data = observable({});
		this.text = getCmpText('EmployerSignin', {
			biometricsAllowTitle: 'Allow sign in with ',
			biometricsFaceLabel: 'FaceID',
			biometricsThumbLabel: 'Fingerprint',
			biometricsEnrollmentError: 'Unable to setup biometric login on your device at this time.'
		});
		this.appText = getCmpText('App', {
			yes: 'Yes',
			no: 'No'
		});
		autoBind(this);
	}

	async componentDidMount() {
		try {
			const type = await BiometricsService.getBiometricsType();
			this.data.type = type;
		} catch (err) {
			console.log('error setting up bio data: ', err);
			this.data.type = 'face';
		}
	}

	closePrompt() {
		this.props.closePrompt(this.props.username, this.props.password);
	}

	async handleAccept() {
		try {
			await BiometricsService.handleBiometricsEnrollment(this.props.username, this.props.password);
			this.closePrompt();
		} catch (err) {
			console.log('error enrolling user in biometrics auth: ', err);
			this.$f7.dialog.alert(this.text.biometricsEnrollmentError, () => this.closePrompt());
		}
	}

	async handleDecline() {
		try {
			await BiometricsService.turnOffBiometrics(true);
		} catch (err) {
			console.log('error declining biometrics: ', err);
		}
		this.closePrompt();
	}

	render() {
		const icon = this.data.type === 'face' ? FaceIdSvg : FingerprintSvg;
		const typeText = this.data.type === 'face' ? this.text.biometricsFaceLabel : this.text.biometricsThumbLabel;
		const titleLine = `${this.text.biometricsAllowTitle} ${typeText}`;
		return (
			<div className="biometrics-prompt-container">
				<GradiantStack stack="3" consumer={false} id="biometrics-prompt" />
				<div className=" animated fadeInDown">
					<div className="icon-container">
						<img src={icon} />
					</div>
					<h1>{titleLine}</h1>
					<div className="card-container">
						<div className="enroll neucard vbox vcenter hleft" onClick={this.handleAccept}>
							<i className="fas fa-check" />
							<div>{this.appText.yes}</div>
						</div>
						<div className="skip neucard vbox vcenter hleft" onClick={this.handleDecline}>
							<i className="fas fa-times" />
							<div>{this.appText.no}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
