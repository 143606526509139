import React, { Component } from 'react';
import appStore from '@/stores/AppStore';
import { observer, observable } from '@/utils/State';
import './publishing.scss';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Preloader } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';

@observer
export default class Publishing extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ publishing: null, loading: true, loadedFrom: null });
		autoBind(this);
	}

	async asyncGetPublishedData() {
		setTimeout(() => {
			this.getPublishingDetails();
		}, 0);
	}

	async getPublishingDetails() {
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		this.data.loading = true;
		ExpressAPI.getPublishingData()
			.then((res) => {
				this.data.publishing = res;
				this.data.loading = false;
			})
			.catch((err) => {
				console.log(err);
				this.data.publishing = {
					currentMembers: {
						total: 0,
						primary: 0,
						dependent: 0
					},
					expressMembers: {
						total: 0,
						primary: 0,
						dependent: 0
					},
					currentGroups: {
						total: 0
					},
					expressGroups: {
						total: 0,
						updated: 0
					},
					currentPlans: {
						total: 0
					},
					expressPlans: {
						total: 0,
						updated: 0
					}
				};
				this.data.loading = false;
			});
	}

	getMemberDetails() {
		let { publishing } = this.data;
		if (!publishing) {
			return '';
		}
		let { currentMembers, expressMembers } = publishing;
		let totalChange = Number(expressMembers.total) - Number(currentMembers.total);
		let primaryChange = Number(expressMembers.primary) - Number(currentMembers.primary);
		let depChange = Number(expressMembers.dependent) - Number(currentMembers.dependent);
		return (
			<div className="details-row hbox vcenter">
				<div className="value">
					{expressMembers.total}
					<span className={`${totalChange >= 0 ? 'positive' : 'negative'}`}>({`${totalChange >= 0 ? '+' : ''}${totalChange}`})</span>
				</div>
				<div className="value">
					{expressMembers.primary}
					<span className={`${primaryChange >= 0 ? 'positive' : 'negative'}`}>
						({`${primaryChange >= 0 ? '+' : ''}${primaryChange}`})
					</span>
				</div>
				<div className="value">
					{expressMembers.dependent}
					<span className={`${depChange >= 0 ? 'positive' : 'negative'}`}>({`${depChange >= 0 ? '+' : ''}${depChange}`})</span>
				</div>
			</div>
		);
	}

	getGroupDetails() {
		let { publishing } = this.data;
		if (!publishing) {
			return '';
		}
		let { currentGroups, expressGroups } = publishing;
		let totalChange = Number(expressGroups.total) - Number(currentGroups.total);

		return (
			<div className="details-row hbox vcenter">
				<div className="value">
					{expressGroups.total}
					<span className={`${totalChange >= 0 ? 'positive' : 'negative'}`}>({`${totalChange >= 0 ? '+' : ''}${totalChange}`})</span>
				</div>
				<div className="value updated">+{expressGroups.updated}</div>
			</div>
		);
	}

	getPlanDetails() {
		let { publishing } = this.data;
		if (!publishing) {
			return '';
		}
		let { currentPlans, expressPlans } = publishing;
		let totalChange = Number(expressPlans.total) - Number(currentPlans.total);

		return (
			<div className="details-row hbox vcenter">
				<div className="value">
					{expressPlans.total}
					<span className={`${totalChange >= 0 ? 'positive' : 'negative'}`}>({`${totalChange >= 0 ? '+' : ''}${totalChange}`})</span>
				</div>
				<div className="value updated">+{expressPlans.updated}</div>
			</div>
		);
	}

	async triggerPublishing() {
		const currentOrg = expressStore.currentOrg._id;
		const isSystemAdmin = expressStore.isSystemAdmin();
		try {
			if(isSystemAdmin) {
				this.$f7.dialog.preloader();
				const publishRes = await ExpressAPI.publishOrg();
				await this.getPublishingDetails();
				this.$f7.dialog.close();
				if(publishRes) {
					this.$f7.dialog.alert('Production Import Complete!');
				} else {
					this.$f7.dialog.alert('We were unable to complete the publishing job');
				}
			} else {
				this.$f7.dialog.alert('You are not authorized to publish.  Please contact a system admin');
			}
		} catch(err) {
			this.$f7.dialog.close();
			this.$f7.dialog.alert('There was an error publishing your data');
		}
	}

	render() {
		if (this.data.loadedFrom !== expressStore.currentOrg._id) {
			this.asyncGetPublishedData();
		}
		return (
			<div className="express-publishing-page">
				<h1 className="text-4 mb-xl">Data Publishing</h1>
				<div className="details-text">
					<p>
						Working with data inside of Express is easy and things like importing, processing, and validating thousands of records only
						takes a few minutes. However, all of these changes stay within the Express platform. In order for these changes to make it
						to the CareValet application they must be published to the application platform.
					</p>
					<p>
						<strong>Don't worry</strong>, we automatically publish all the changes you make to your data via the Express platform
						nightly at
						<strong> 4AM EST.</strong> This dashboard updates in real time and will show you the lastest unpublished changes that will
						go live next. If you publish changes sooner than the next 4AM load, please reach out at
						<a
							onClick={() => {
								let url = 'mailto:help@carevalet.com';
								if (appStore.isApp()) {
									cordova.InAppBrowser.open(url, '_system', 'userwkwebview=yes');
								} else {
									window.open(url, '_blank');
								}
							}}
						>
							help@carevalet.com
						</a>
					</p>
				</div>
				{this.data.loading && (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
				{!this.data.loading && (
					<div className="update-details">
						<h3>Member Updates</h3>
						<p>After the next publish runs here is what is changing for members:</p>
						<div className="table-list-header hbox vcenter">
							<div className="header-title">Total Members</div>
							<div className="header-title">Total Primary</div>
							<div className="header-title">Total Dependents</div>
						</div>
						{this.getMemberDetails()}
						<h3>Group Updates</h3>
						<p>After the next publish runs here is what is changing for groups:</p>
						<div className="table-list-header hbox vcenter">
							<div className="header-title">Total Groups</div>
							<div className="header-title">Update Groups</div>
						</div>
						{this.getGroupDetails()}
						<h3>HealthPlan Updates</h3>
						<p>After the next publish runs here is what is changing for healthplans:</p>
						<div className="table-list-header hbox vcenter">
							<div className="header-title">Total Plans</div>
							<div className="header-title">Updated Plans</div>
						</div>
						{this.getPlanDetails()}
						{expressStore.isSystemAdmin() && (
							<Button className="publish-btn" onClick={this.triggerPublishing}>
								Force Publish Now
							</Button>
						)}
					</div>
				)}
			</div>
		);
	}
}
