import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, formatPhoneNumber } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import './express-profile.scss';
import { Button } from 'framework7-react';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import ExpressAPI from '@/services/ExpressAPI';

@observer
export default class ExpressProfile extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			emailForm: { email: '' },
			phoneForm: { phone: '' },
			emailEditOpen: false,
			phoneEditOpen: false
		});
		autoBind(this);
	}

	changeEmail() {
		this.data.emailEditOpen = true;
	}

	changePhone() {
		this.data.phoneEditOpen = true;
	}

	onSaveEmail() {
		this.$f7.dialog.preloader();
		ExpressAPI.updateUser(this.data.emailForm)
			.then((res) => {
				this.$f7.dialog.close();
				if (res.email !== _.get(expressStore, 'userData.user.email', '')) {
					firebase.auth().signOut();
				} else {
					this.$f7.dialog.alert('Please try again later', 'Something went wrong');
				}
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
		this.data.emailEditOpen = false;
		this.data.emailForm = {
			email: ''
		};
	}

	onSavePhone() {
		this.$f7.dialog.preloader();
		ExpressAPI.updateUser(this.data.phoneForm)
			.then((res) => {
				this.$f7.dialog.close();
				if (res.phone !== _.get(expressStore, 'userData.user.phone', '')) {
					_.set(expressStore, 'userData.user', res);
				} else {
					this.$f7.dialog.alert('Please try again later', 'Something went wrong');
				}
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
		this.data.phoneEditOpen = false;
		this.data.phoneForm = {
			phone: ''
		};
	}

	render() {
		let { userData, currentOrg } = expressStore;
		let role = _.get(_.find(userData.roles, { orgId: currentOrg._id }), 'role', 'systems-admin');

		return (
			<div className="express-profile-page" key={`${userData.user._id}${currentOrg._id}`}>
				<div className="org-card hbox vcenter">
					<i className="fad fa-building"></i>
					<div className="org-details">
						<div className="name">{currentOrg.name}</div>
						<div className="role">
							{
								{
									'systems-admin': 'System Admin',
									'organization-admin': 'Organization Administrator',
									'group-admin': 'Group Administrator',
									'group-user': 'Basic User'
								}[role]
							}
						</div>
					</div>
				</div>
				<div className="profile-content vbox vcenter hcenter">
					<div className="field hbox vcenter">
						<div className="label">Name:</div>
						<div className="value name">{`${userData.user.firstName} ${userData.user.lastName}`}</div>
					</div>
					<div className="field hbox vcenter">
						<div className="label">Title:</div>
						<div className="value">{userData.user.jobTitle}</div>
					</div>
					<div className="field hbox vtop action">
						<div className="label">Email:</div>
						<div className="value">
							<div>{userData.user.email}</div>
							<Button onClick={this.changeEmail}>Change Email</Button>
						</div>
					</div>

					<div className="field hbox vtop action">
						<div className="label">Phone:</div>
						<div className="value">
							<div>{formatPhoneNumber(userData.user.phone)}</div>
							<Button onClick={this.changePhone}>Change Phone</Button>
						</div>
					</div>
				</div>
				<FormPopup
					opened={this.data.emailEditOpen}
					title="Change Your Email Address"
					buttonText="Save"
					onSubmit={this.onSaveEmail}
					formData={this.data.emailForm}
					onClose={() => {
						this.data.emailEditOpen = false;
						this.data.emailForm = {
							email: ''
						};
					}}
					formConfig={{
						email: {
							label: 'New Email Address',
							placeholder: 'This is what you use to sign in to Express',
							type: 'email',
							validator: {
								type: 'email'
							}
						}
					}}
				></FormPopup>
				<FormPopup
					opened={this.data.phoneEditOpen}
					title="Change Your Phone Number"
					buttonText="Save"
					onSubmit={this.onSavePhone}
					formData={this.data.phoneForm}
					onClose={() => {
						this.data.phoneEditOpen = false;
						this.data.phoneForm = {
							phone: ''
						};
					}}
					formConfig={{
						phone: {
							label: 'New Phone Number',
							placeholder: 'This number is used for validation and important alerts',
							type: 'phone',
							validator: {
								type: 'phone'
							}
						}
					}}
				></FormPopup>
			</div>
		);
	}
}
