import React, { Component } from 'react';
import './accumulator-widgit.scss';
import _ from 'lodash';
import { Progressbar } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { autoBind } from '../../utils/GeneralUtils';
import { getCmpText } from '../../utils/Localization';
import CloudAPI from '../../services/CloudAPI';
import userStore from '../../stores/UserStore';

const accumTextTypes = {
	oop: 'oopText',
	deductible: 'deductibleText'
};
const accumMaxKeys = {
	oopMax: 'oopMax',
	deductible: 'deductible'
};

@observer
export default class AccumulatorWidgit extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			accums: []
		});
		this.text = getCmpText('AccumulatorWidgit', {
			deductibleText: 'Deductible',
			oopText: 'Out-of-Pocket'
		});
		autoBind(this);
	}

	componentDidMount() {
		this.refresh();
	}

	refresh() {
		CloudAPI.getAccumulators()
			.then((res) => {
				this.data.accums = res;
			})
			.catch((err) => {
				console.log('unablet to get accumulators: ', err);
				this.data.accums = [];
			});
	}

	buildAccumulators() {
		let { accums } = this.data;
		let items = [];
		let planData = _.get(userStore, 'userData.group', {});
		_.forEach(accums, (a) => {
			let max = _.get(planData, `${accumMaxKeys[a._id]}`, null);
			if (max) {
				items.push(
					<div className="accum vbox vcenter w-100" key={a._id + Math.random()}>
						<div>{`${this.text[accumTextTypes[a._id]]} ($${Number(a.value || 0)} of $${Number(max)})`} </div>
						<Progressbar
							progress={(Number(a.value || 0) / Number(max)) * 100}
							color={items.length % 2 == 0 ? 'green' : 'blue'}
							className="neumorphic"
						></Progressbar>
					</div>
				);
			}
		});
		return items;
	}

	//Note for test data OOP is always greator than deductible
	render() {
		return (
			<div className={`accum-widget vboc vcenter hcenter w-100`} onClick={this.refresh}>
				{this.buildAccumulators()}
			</div>
		);
	}
}
