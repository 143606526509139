import { autoBind } from '@/utils/GeneralUtils';
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import './canvas-context-menu.scss';
import CanvasFactory from '@/factories/CanvasFactory';
@observer
export default class CanvasContextMenu extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	renderTools() {
		const {
			editorStore: { clipboard, selectMode },
			getSelectMode,
			copyNode,
			pasteNode,
			deleteNode,
			moveNode,
			zoomIn,
			zoomOut
		} = CanvasFactory.getActiveService();
		const thisStore = CanvasFactory.getActiveStore();
		const isNode = selectMode === 'single';
		const tools = [];
		if(thisStore.backgroundData && thisStore.backgroundEdit) {
			tools.push(
				<div className="item hbox vcenter" onClick={() => deleteNode()}>
					<i className="fad fa-minus-circle"></i>
					<div className="menu-text">Delete</div>
				</div>
			)
		}
		if (isNode) {
			tools.push(
				<div className="item hbox vcenter" onClick={(e) => copyNode(e)}>
					<i className="fad fa-copy"></i>
					<div className="menu-text">Copy</div>
				</div>
			);
		}
		if (clipboard) {
			tools.push(
				<div className="item hbox vcenter" onClick={() => pasteNode()}>
					<i className="fad fa-paste"></i>
					<div className="menu-text">Paste</div>
				</div>
			);
		}

		if (isNode) {
			tools.push(
				<>
					<div className="item hbox vcenter" onClick={() => deleteNode()}>
						<i className="fad fa-minus-circle"></i>
						<div className="menu-text">Delete</div>
					</div>
					<div className="divider"></div>
					<div className="item hbox vcenter" onClick={() => moveNode('up')}>
						<i className="fad fa-bring-forward"></i>
						<div className="menu-text">Move Forward</div>
					</div>
					<div className="item hbox vcenter" onClick={() => moveNode('top')}>
						<i className="fad fa-bring-front"></i>
						<div className="menu-text">Move to Front</div>
					</div>
					<div className="item hbox vcenter" onClick={() => moveNode('down')}>
						<i className="fad fa-send-backward"></i>
						<div className="menu-text">Move Backward</div>
					</div>
					<div className="item hbox vcenter" onClick={() => moveNode('bottom')}>
						<i className="fad fa-send-back"></i>
						<div className="menu-text">Move to Back</div>
					</div>
					<div className="divider"></div>
				</>
			);
		} else {
			tools.push(
				<>
					<div className="item hbox vcenter" onClick={() => zoomIn()}>
						<i className="fa fa-plus"></i>
						<div className="menu-text">Zoom In</div>
					</div>
					<div className="item hbox vcenter" onClick={() => zoomOut()}>
						<i className="fa fa-minus"></i>
						<div className="menu-text">Zoom Out</div>
					</div>
				</>
			);
		}

		return tools;
	}

	render() {
		const {
			showMenu,
			menu: { x, y }
		} = CanvasFactory.getActiveStore();
		if (showMenu) {
			return (
				<div className="canvas-context-menu vbox hcenter" style={{ transform: `translate(${x}px, ${y}px)` }}>
					{this.renderTools()}
				</div>
			);
		} else {
			return [];
		}
	}
}
