import ButtonSegment from '@/components/_EXPRESS/segmented-button/button-segment/ButtonSegment';
import SegmentedButton from '@/components/_EXPRESS/segmented-button/SegmentedButton';
import React, { Component } from 'react';

export default class FontAlign extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { align, onChange } = this.props;
		console.log('align');
		return (
			<div className="vbox property-section">
				<div className="property-label">Align</div>
				<div className="property-edit">
					<SegmentedButton>
						<ButtonSegment active={align === 'left'} onClick={() => onChange('left')}>
							<i className="fa fa-align-left"></i>
						</ButtonSegment>
						<ButtonSegment active={align === 'center'} onClick={() => onChange('center')}>
							<i className="fa fa-align-center"></i>
						</ButtonSegment>
						<ButtonSegment active={align === 'right'} onClick={() => onChange('right')}>
							<i className="fa fa-align-right"></i>
						</ButtonSegment>
					</SegmentedButton>
				</div>
			</div>
		);
	}
}
