import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import { Button, List, ListItem, ListInput } from 'framework7-react';
import FiveImg from './FiveImg';
import Logo from '../../static/logo/Logo';
import ExternalService from '../../services/ExternalService';
import CloudAPI from '../../services/CloudAPI';
import './app-questionnaire.scss';
import appStore from '../../stores/AppStore';
import StorageService from '../../services/StorageService';

const storageService = new StorageService();

@observer
export default class AppQuestionnaire extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showQuestionnaire: true
		});
		autoBind(this);
	}

	getQuestionnaireConfig() {
		const questionnaireConfig =
			appStore.questionnaires && appStore.questionnaires.length > 0 && _.cloneDeep(appStore.questionnaires[0]);
		return questionnaireConfig;
	}

	handleDialogClick(data) {
		const config = this.getQuestionnaireConfig();
		this.$f7.dialog.close();
		const isTelehealthCall = data.data && data.data === 'telehealth' && data.type === 'call';
		if (isTelehealthCall) {
			ExternalService.onTelehealthClick('call');
		} else {
			ExternalService.handleExternalRequest(data.type, data.data);
		}
		if (!data.preventClose) {
			this.checkBeforeClosing(config.id);
		}
	}

	checkBeforeClosing(id) {
		const currentQuestionnaires = _.cloneDeep(appStore.questionnaires);
		const queuedQuestionnaires = currentQuestionnaires.filter((x) => x.id !== id);
		appStore.questionnaires = _.cloneDeep(queuedQuestionnaires);
		if (!queuedQuestionnaires || queuedQuestionnaires.length < 1) {
			this.closeForm();
		}
	}

	clearForm() {
		const responseData = _.cloneDeep(this.data);
		delete responseData.showQuestionnaire;
		const currentData = Object.keys(responseData);
		for (let dataItem of currentData) {
			this.data[dataItem] = null;
		}
	}

	async submitQuestionnaire(data) {
		const responseData = _.cloneDeep(this.data);
		const config = this.getQuestionnaireConfig();
		if (!config) {
			this.closeForm();
			return;
		}
		if (config.storagePath && config.storageValue) {
			await storageService.setValue('app', config.storagePath, config.storageValue);
		}
		const defaultValues = config.content.map((item) => {
			if (item.hasOwnProperty('defaultValue') && item.id) {
				const hasValue = responseData.hasOwnProperty(item.id);
				if (!hasValue) {
					responseData[item.id] = item.defaultValue;
				}
			}
		});
		const actionsConfig = config.action;
		if (data.submit === false) {
			if (config.required) {
				this.$f7.dialog.alert(
					_.get(config, 'action.negativeResult.text') || '',
					_.get(config, 'action.negativeResult.title') || 'Submission Required',
					() => {
						firebase.auth().signOut();
					}
				);
			}
			if (config.submitDecline) {
				this.$f7.dialog.preloader();
				await CloudAPI.submitQuestionnaire({ type: config.id, responses: { declined: true } });
				this.$f7.dialog.close();
			}
			this.checkBeforeClosing(config.id);
			return;
		} else {
			this.$f7.dialog.preloader();
			try {
				delete responseData.showQuestionnaire;
				await CloudAPI.submitQuestionnaire({ type: config.id, responses: responseData, acceptanceData: config.acceptanceData });
				this.clearForm();
				this.$f7.dialog.close();
			} catch (err) {
				this.$f7.dialog.close();
			}
		}
		actionsConfig.rules.map((rule) => {
			const ruleFields = rule.fields;
			switch (rule.type) {
				case 'OR':
					const positiveRules = ruleFields.filter((ruleField) => {
						const currentValue = responseData[ruleField.field];
						const positiveValue = currentValue === ruleField.value;
						return positiveValue;
					});
					const alertConfig = positiveRules && positiveRules.length > 0 ? actionsConfig.positiveResult : actionsConfig.negativeResult;
					if (!alertConfig) {
						this.checkBeforeClosing(config.id);
					} else {
						const alertActions =
							alertConfig.actions &&
							alertConfig.actions.filter((action) => {
								if (action.condition) {
									const conditionalField = action.condition.field;
									const conditionalValue = action.condition.value;
									const currentValue = responseData[conditionalField] || false;
									return currentValue === conditionalValue;
								}
								return true;
							});
						const alert = this.$f7.dialog.create({
							title: alertConfig.title,
							text: alertConfig.text,
							buttons: alertActions.map((action) => ({
								text: action.label,
								close: action.type === 'exit',
								onClick: action.type === 'exit' ? () => this.checkBeforeClosing(config.id) : () => this.handleDialogClick(action),
								color: action.color ? action.color : ''
							})),
							verticalButtons: true
						});
						alert.open();
					}
					break;
				default:
					this.checkBeforeClosing(config.id);
					break;
			}
		});
	}

	handleToggleChange(currentValue, field) {
		const val = !currentValue;
		this.data[field] = val;
	}

	getImage(data) {
		switch (data) {
			case 'covid':
				return <FiveImg />;
			default:
				return <img src={data} />;
		}
	}

	handleRatingChange(evt) {
		const ratingValue = evt.currentTarget.getAttribute('data-value');
		const ratedItem = evt.currentTarget.getAttribute('data-item');
		this.data[ratedItem] = ratingValue;
	}

	handleTextInputChange(evt) {
		const field = evt.currentTarget.name;
		const value = evt.currentTarget.value;
		this.data[field] = value;
	}

	getContentBlock(itemConfig, reqIndex) {
		if (itemConfig.hidden) return;
		switch (itemConfig.type) {
			case 'ol':
				return (
					<div key={`content-item-ol-${reqIndex}`}>
						<ol type={itemConfig.olType ? itemConfig.olType : 1}>
							{itemConfig.items.map((listItem, listIndex) => (
								<li className="ordered-list-item" key={`ol-item-${reqIndex}-${listIndex}-${listItem.text}`}>
									{listItem.title && <strong>{`${listItem.title} `}</strong>}
									{listItem.text}
									{listItem.type && listItem.type !== 'text' && this.getContentBlock(listItem, `${reqIndex}-${listIndex}`)}
								</li>
							))}
						</ol>
					</div>
				);
			case 'rating':
				const currentValue = this.data[itemConfig.id] || 0;
				return (
					<ListItem className="rating-input" key={`rating-item-${reqIndex}`}>
						<div className="rating-label">{itemConfig.text}</div>
						<div className="rating-group">
							<i
								className={currentValue > 0 ? 'fas fa-star' : 'far fa-star'}
								data-item={itemConfig.id}
								data-value="1"
								onClick={this.handleRatingChange}
							/>
							<i
								className={currentValue > 1 ? 'fas fa-star' : 'far fa-star'}
								data-item={itemConfig.id}
								data-value="2"
								onClick={this.handleRatingChange}
							/>
							<i
								className={currentValue > 2 ? 'fas fa-star' : 'far fa-star'}
								data-item={itemConfig.id}
								data-value="3"
								onClick={this.handleRatingChange}
							/>
							<i
								className={currentValue > 3 ? 'fas fa-star' : 'far fa-star'}
								data-item={itemConfig.id}
								data-value="4"
								onClick={this.handleRatingChange}
							/>
							<i
								className={currentValue > 4 ? 'fas fa-star' : 'far fa-star'}
								data-item={itemConfig.id}
								data-value="5"
								onClick={this.handleRatingChange}
							/>
						</div>
					</ListItem>
				);
			case 'textInput':
				const textValue = this.data[itemConfig.id] || '';
				return (
					<ListItem className="text-area-input" key={`text-area-item-${reqIndex}`}>
						<div>{itemConfig.text}</div>
						<ListInput name={itemConfig.id} type="textarea" value={textValue} onChange={this.handleTextInputChange} />
					</ListItem>
				);
			case 'toggle':
				const toggleValue = this.data[itemConfig.id];
				return (
					<ListItem key={`content-item-toggle-${reqIndex}`} className="toggle-item">
						<div className="toggle-text">{itemConfig.text}</div>
						<Button
							className={`toggle-button ${toggleValue ? 'active' : ''}`}
							active={!toggleValue}
							fill={toggleValue || false}
							color={toggleValue ? itemConfig.trueColor : 'white'}
							round
							onClick={() => this.handleToggleChange(toggleValue, itemConfig.id)}
						>
							{!toggleValue && (
								<div className="icon-holder elevation-6">
									<i className="fas fa-circle" />
								</div>
							)}
							{toggleValue ? itemConfig.trueLabel : itemConfig.falseLabel}
							{toggleValue && (
								<div className="icon-holder active elevation-6">
									<i className="fas fa-circle" />
								</div>
							)}
						</Button>
					</ListItem>
				);
			case 'link':
				return (
					<a
						key={`content-item-link-${reqIndex}`}
						onClick={() =>
							this.handleDialogClick({
								type: 'web',
								data: itemConfig.href,
								preventClose: true
							})
						}
					>
						{itemConfig.text}
					</a>
				);
			case 'inlineText':
				if (itemConfig.decorator === 'bold') {
					return (
						<span key={`content-item-inlineText-${reqIndex}`}>
							<strong>{itemConfig.text}</strong>
						</span>
					);
				}
				if (itemConfig.decorator === 'underline') {
					return (
						<span key={`content-item-inlineText-${reqIndex}`}>
							<u>{itemConfig.text}</u>
						</span>
					);
				}
				return <span key={`content-item-inlineText-${reqIndex}`}>{itemConfig.text}</span>;
			case 'text':
				const configTemplate = itemConfig.template;
				const configSections = itemConfig.sections;
				let itemText = itemConfig.text;
				let formattedSections;
				if (configTemplate) {
					const itemTemplate = _.template(itemText);
					itemText = itemTemplate(configTemplate);
				}
				if (configSections) {
					formattedSections = configSections.map((section, sectionIndex) =>
						this.getContentBlock(section, `${reqIndex}-${sectionIndex}`)
					);
				}
				if (itemConfig.decorator === 'bold') {
					return (
						<div key={`content-item-text-${reqIndex}`}>
							<strong>{itemText}</strong>
						</div>
					);
				} else {
					return <div key={`content-item-text-${reqIndex}`}>{formattedSections ? formattedSections : itemText}</div>;
				}
			default:
				return;
		}
	}

	closeWithoutSubmitting() {
		this.submitQuestionnaire({ submit: false });
	}

	closeForm() {
		this.data.showQuestionnaire = false;
		if (this.props.close) {
			this.props.close();
		}
	}

	render() {
		const config = this.getQuestionnaireConfig();
		const displayItems = _.get(config, 'content', []).map((item, itemIndex) => this.getContentBlock(item, itemIndex));
		const submitConfig = _.find(_.get(config, 'content', []), { action: 'submit' });
		const cancelConfig = _.find(_.get(config, 'content', []), { action: 'cancel' });
		const image = config && config.image && this.getImage(config.image);
		if (!this.data.showQuestionnaire || !config) {
			return <Fragment />;
		}
		return (
			<div className="app-questionnaire">
				<div className="full-page-container">
					<div className="header">
						<Logo icon size="small" />
						{config.header}
						<div className="close-container" onClick={this.closeWithoutSubmitting}>
							<i className="fad fa-times-square" />
						</div>
					</div>
					<div className="body y-scroll">
						<div className="image-container">{image}</div>
						<h2>{config.title}</h2>
						<p className="subtitle">{config.subtitle}</p>
						<List noHairlines>{displayItems}</List>
					</div>
					{(submitConfig || cancelConfig) && (
						<div className="btn-ctn">
							{cancelConfig && (
								<Button
									className={`questionnaire-button submit m-r-16 cancel-btn`}
									large
									outline
									onClick={this.closeWithoutSubmitting}
								>
									{cancelConfig.label}
								</Button>
							)}
							{submitConfig && (
								<Button className={`questionnaire-button submit`} large fill onClick={this.submitQuestionnaire}>
									{submitConfig.label}
								</Button>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}
