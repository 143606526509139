import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import { Button, Link, Popup } from 'framework7-react';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import healthPlanSVG from '@/assets/healthplan.svg';
import _ from 'lodash';
import './plan-wizard.scss';
import expressStore from '@/stores/ExpressStore';
import ExpressAPI from '@/services/ExpressAPI';
import NetworkRequestForm from '@/pages/EXPRESS/networks/network-request-form/NetworkRequestForm';
import IdCardDetail from '@/pages/EXPRESS/idcards/id-card-details/id-detail/IdCardDetail';
import telehealthConfig from '@/pages/EXPRESS/form-configs/telehealth';

@observer
export default class PlanWizard extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			activeStep: 1,
			stepAnimation: '',
			step2Valid: false,
			step3Valid: false,
			step4Valid: false,
			loadingPlans: false,
			loadingNetworks: false,
			loadingTemplates: false,
			loadingCards: false,
			existingPlans: [],
			existingNetworks: [],
			idCardTemplates: [],
			availableNetworks: [],
			step5Config: {},
			step1Data: { planId: 0 },
			step2Data: {
				name: '',
				planId: '',
				type: 'traditional',
				healthPlanCoverageType: 'medical',
				primaryPhone: '',
				primaryWebsite: ''
			},
			step3Data: {
				networkId: 0,
				name: '',
				contactName: '',
				contactPhone: '',
				contactEmail: '',
				request: '',
				existingNetwork: false,
				tiers: null
			},
			step4Data: {
				type: 'carevalet',
				careValetType: 'Teladoc',
				name: '',
				phone: '',
				website: '',
				copay: 0,
				serviceOffering: 'vuc'
			},
			step5Data: {
				templateIdFront: 'default_front',
				templateIdBack: 'default_back',
				rxPCN: '',
				rxGroup: '',
				rxBin: '',
				payerId: '',
				officeVisitCopay: '',
				specialistCopay: '',
				urgentCareCopay: '',
				emergencyCopay: '',
				famOOPMax: '',
				famDeductible: '',
				deductible: '',
				oopMax: ''
			}
		});
		autoBind(this);
	}

	closeLinkPopup(saved) {
		this.data.step1Data = { planId: 0 };
		this.data.step2Data = {
			name: '',
			planId: '',
			type: 'traditional',
			healthPlanCoverageType: 'medical',
			primaryPhone: '',
			primaryWebsite: ''
		};
		this.data.step3Data = {
			networkId: 0,
			name: '',
			contactName: '',
			contactPhone: '',
			contactEmail: '',
			request: '',
			existingNetwork: false,
			tiers: null
		};
		this.data.step4Data = {
			type: 'carevalet',
			name: '',
			phone: '',
			website: '',
			copay: 0,
			careValetType: 'Teladoc',
			serviceOffering: 'vuc'
		};
		this.data.step5Data = {
			templateIdFront: 'default_front',
			templateIdBack: 'default_back',
			rxPCN: '',
			rxGroup: '',
			rxBin: '',
			payerId: '',
			officeVisitCopay: '',
			specialistCopay: '',
			urgentCareCopay: '',
			emergencyCopay: '',
			famOOPMax: '',
			famDeductible: '',
			deductible: '',
			oopMax: ''
		};
		this.data.activeStep = 1;
		this.data.stepAnimation = '';
		this.data.step2Valid = false;
		this.data.step3Valid = false;
		this.data.step4Valid = false;
		if (this.props.onClose) {
			this.props.onClose(saved);
		}
	}

	loadPlans() {
		this.data.loadingPlans = true;
		this.data.loadingNetworks = true;
		this.data.loadingTemplates = true;
		ExpressAPI.getAllPlans()
			.then((res) => {
				this.data.existingPlans = res;
				if (res.length > 0) {
					this.data.step1Data.planId = res[0]._id;
				}
				this.data.loadingPlans = false;
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
				this.data.loadingPlans = false;
			});
		ExpressAPI.getNetworks()
			.then((res) => {
				this.data.existingNetworks = res;
				this.data.loadingNetworks = false;
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
				this.data.loadingNetworks = false;
			});
		ExpressAPI.listIdCards('ALL', true)
			.then((res) => {
				this.data.idCardTemplates = res;
				this.data.loadingTemplates = false;
				if (res.length > 0) {
					const fronts = res.filter((c) => c.side === 'front');
					const backs = res.filter((c) => c.side === 'back');
					const defaultCards = res.filter((c) => c.defaultCard === true);

					if (defaultCards.length > 0) {
						this.data.step5Data.templateIdFront = defaultCards.filter(
							(c) => c.side === 'front'
						)[0]._id;
						this.data.step5Data.templateIdBack = defaultCards.filter(
							(c) => c.side === 'back'
						)[0]._id;
					}
				}
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
				this.data.loadingTemplates = false;
			});
		ExpressAPI.getAvailableNetworks()
			.then((res) => {
				this.data.availableNetworks = res;
			})
			.catch((err) => {
				showExpressError(this.$f7, err, 'Unknown Error');
			});
	}

	handlePopupOpen() {
		this.loadPlans();
	}

	addExistingPlan() {
		if (this.data.step1Data.planId) {
			this.$f7.dialog.preloader('Saving...');
			ExpressAPI.linkPlanToGroup(
				expressStore.activeGroup?._id,
				this.data.step1Data.planId
			)
				.then(() => {
					this.$f7.dialog.close();
					this.closeLinkPopup(true);
				})
				.catch((err) => {
					showExpressError(this.$f7, err, 'Unknown Error');
				});
		}
	}

	cloneExistingPlan() {
		let { existingPlans, step1Data } = this.data;
		if (step1Data && step1Data.planId) {
			let plan = _.find(existingPlans, { _id: step1Data.planId });
			if (plan) {
				// TODO: HERE IS WHERE I STOPPED. Add Key here in step 2 along with form schema. DROP DOWN TYPE
				this.data.step2Data = {
					name: '',
					planId: '',
					type: plan.type,
					healthPlanCoverageType: plan.healthPlanCoverageType || 'medical',
					primaryPhone: plan.primaryPhone,
					primaryWebsite: plan.primaryWebsite
				};
				// @ts-ignore
				this.data.step3Data = {
					networkId: plan.internalNetworkId,
					name: plan.healthNetworkName,
					contactName: '',
					contactPhone: '',
					contactEmail: '',
					request: '',
					existingNetwork: true
				};
				if (plan.telehealth && plan.telehealth?.type === 'carevalet') {
					this.data.step4Data.type = 'carevalet';
				} else if (plan.telehealth && plan.telehealth?.type !== 'carevalet') {
					const careValetType =
						plan.telehealth.type === 'carevalet'
							? !plan.telehealth?.careValetType
								? 'Teladoc'
								: plan.telehealth?.careValetType
							: '';
					this.data.step4Data = {
						type: plan.telehealth?.type,
						careValetType,
						name: plan.telehealth?.name,
						phone: plan.telehealth?.phone,
						website: plan.telehealth?.website,
						copay: plan.telehealth?.copay,
						serviceOffering: plan.telehealth?.serviceOffering || 'vuc'
					};
				}
				this.data.step5Data = {
					templateIdFront: plan.idCardFront,
					templateIdBack: plan.idCardBack,
					rxPCN: plan.rxPCN,
					rxGroup: plan.rxGroup,
					rxBin: plan.rxBin,
					payerId: plan.payerId,
					officeVisitCopay: plan.officeVisitCopay,
					specialistCopay: plan.specialistCopay,
					urgentCareCopay: plan.urgentCareCopay,
					emergencyCopay: plan.emergencyCopay,
					famOOPMax: plan.famOOPMax,
					famDeductible: plan.famDeductible,
					deductible: plan.deductible,
					oopMax: plan.oopMax
				};
				this.data.stepAnimation = 'slideInRight';
				this.data.activeStep = 2;
				this.data.step3Valid = true;
			}
		}
	}

	savePlan() {
		let { step2Data, step3Data, step4Data, step5Data } = this.data;
		const planDetails = {
			details: _.cloneDeep(step2Data),
			networkReq: {
				...step3Data,
				networkId: step3Data.existingNetwork
					? step3Data._id
					: step3Data.networkId
			},
			telehealth: { ...step4Data },
			idcard: { ...step5Data }
		};
		this.$f7.dialog.preloader('Saving...');
		if (expressStore.activePage == 'groups') {
			ExpressAPI.saveGroupPlan(expressStore.activeGroup?._id, planDetails)
				.then(() => {
					this.$f7.dialog.close();
					this.closeLinkPopup(true);
				})
				.catch((err) => {
					this.$f7.dialog.close();
					console.log(err);
				});
		} else {
			ExpressAPI.savePlan(planDetails)
				.then(() => {
					this.$f7.dialog.close();
					this.closeLinkPopup(true);
				})
				.catch((err) => {
					this.$f7.dialog.close();
					console.log(err);
				});
		}
	}

	submitNetworkRequest(data) {
		this.data.stepAnimation = 'slideInRight';
		this.data.activeStep = 4;
		this.data.step3Data = _.cloneDeep(data);
	}

	async handlePlanFormChange(property) {
		if (property === 'templateIdFront' || property === 'templateIdBack') {
			await this.setStep5Config();
		}
	}

	async setStep5Config() {
		const cardFront = this.data.step5Data.templateIdFront;
		const cardBack = this.data.step5Data.templateIdBack;
		const customFormData = await ExpressAPI.getIdCardCustomFormData(
			cardFront,
			cardBack
		);
		const standardConfig = {
			row1: {
				rxPCN: {
					label: 'Rx PCN',
					placeholder: 'Rx PCN',
					type: 'text'
				},
				rxBin: {
					label: 'RxBin',
					placeholder: 'RxBin#',
					type: 'text'
				}
			},
			row2: {
				rxGroup: {
					label: 'Rx Group#',
					placeholder: 'Rx Group Number',
					type: 'text'
				},
				payerId: {
					label: 'PayerID',
					placeholder: 'PayerID',
					type: 'text'
				}
			},
			row3: {
				officeVisitCopay: {
					label: 'Office Visit Copay',
					placeholder: 'Office Visit Copay',
					type: 'text'
				},
				specialistCopay: {
					label: 'Specialist Copay',
					placeholder: 'Specialist Copay',
					type: 'text'
				}
			},
			row4: {
				urgentCareCopay: {
					label: 'Urgent Care Copay',
					placeholder: 'Urgent Care Copay',
					type: 'text'
				},
				emergencyCopay: {
					label: 'ER Copay',
					placeholder: 'ER Copay',
					type: 'text'
				}
			},
			row5: {
				deductible: {
					label: 'Deductible',
					placeholder: 'Deductible',
					type: 'text'
				},
				oopMax: {
					label: 'Out of Pocket Max',
					placeholder: 'Out of Pocket Max',
					type: 'text'
				}
			},
			row6: {
				famDeductible: {
					label: 'Family Deductible',
					placeholder: 'Family Deductible',
					type: 'text'
				},
				famOOPMax: {
					label: 'Family Out of Pocket Max',
					placeholder: 'Family Out of Pocket Max',
					type: 'text'
				}
			}
		};
		this.data.step5Config = {
			...standardConfig,
			...customFormData
		};
	}

	getMainContent() {
		switch (this.data.activeStep) {
			case 1: {
				let listItems = {};
				_.forEach(this.data.existingPlans, (p) => {
					listItems[p._id] = p.name;
				});
				if (this.data.existingPlans.length == 0) {
					listItems[0] = 'No Existing Plans';
				}
				if (expressStore.activePage === 'allPlans') {
					return (
						<div
							className={`step-1 vbox vcenter hcenter animated ${this.data.stepAnimation}`}
							key={`step-1-${this.data.stepAnimation}`}
						>
							<div className="add-plan hbox vcenter">
								<ExpressFormBuilder
									formData={this.data.step1Data}
									formConfig={{
										planId: {
											label: 'Clone an existing plan',
											type: 'dropdown',
											className: 'dropdown-form-input',
											icon: 'fal fa-file-medical',
											listItems: listItems
										}
									}}
								/>
								<Button
									large
									fill
									className="round-btn ios"
									onClick={this.cloneExistingPlan}
								>
									Clone
								</Button>
							</div>
							<div className="message">OR</div>
							<img src={healthPlanSVG} className="healthplan-svg" />
							<Button
								large
								fill
								className="round-btn ios"
								onClick={() => {
									this.data.stepAnimation = 'slideInRight';
									this.data.activeStep = 2;
								}}
							>
								Create New
							</Button>
						</div>
					);
				} else {
					return (
						<div
							className={`step-1 vbox vcenter hcenter animated ${this.data.stepAnimation}`}
							key={`step-1-${this.data.stepAnimation}`}
						>
							<div className="add-plan hbox vcenter">
								<ExpressFormBuilder
									formData={this.data.step1Data}
									formConfig={{
										planId: {
											label: 'Select from an existing plan',
											type: 'dropdown',
											className: 'dropdown-form-input',
											icon: 'fal fa-file-medical',
											listItems: listItems
										}
									}}
								/>
								<Button
									large
									fill
									className="round-btn ios"
									onClick={this.addExistingPlan}
								>
									Connect
								</Button>
							</div>
							<div className="message">OR</div>
							<img src={healthPlanSVG} className="healthplan-svg" />
							<Button
								large
								fill
								className="round-btn ios"
								onClick={() => {
									this.data.stepAnimation = 'slideInRight';
									this.data.activeStep = 2;
								}}
							>
								Create New
							</Button>
						</div>
					);
				}
			}
			case 2: {
				return (
					<div
						className={`step-2 vbox vcenter hcenter animated ${this.data.stepAnimation}`}
						key={`step-2-${this.data.stepAnimation}`}
					>
						<ExpressFormBuilder
							formData={this.data.step2Data}
							formConfig={{
								row1: {
									name: {
										label: 'HealthPlan Name',
										placeholder: 'Friendly name of this plan',
										type: 'text',
										validator: {
											type: 'length',
											value: 2
										}
									},
									planId: {
										label: 'HealthPlan ID',
										placeholder: 'ID of plan (matching eligibility)',
										type: 'text',
										validator: {
											type: 'length',
											value: 2
										}
									}
								},
								row2: {
									healthPlanCoverageType: {
										label: 'Health Plan Coverage Type',
										type: 'dropdown',
										icon: 'far fa-files-medical',
										className: 'dropdown-form-input',
										listItems: {
											Medical: 'Medical Coverage',
											Dental: 'Dental Coverage',
											Vision: 'Vision Coverage'
										}
									}
								},
								row3: {
									primaryPhone: {
										label: 'Primary Phone Number',
										placeholder: 'Of Plan Administrator',
										type: 'phone',
										validator: {
											type: 'phone'
										}
									},
									primaryWebsite: {
										label: 'Primary Website URL',
										placeholder: 'Of Plan Administrator',
										type: 'text',
										validator: {
											type: 'url'
										}
									}
								}
							}}
							setValidationState={(valid) => {
								this.data.step2Valid = valid;
							}}
						/>
						<Button
							large
							fill
							className="round-btn ios"
							disabled={!this.data.step2Valid}
							onClick={() => {
								this.data.stepAnimation = 'slideInRight';
								this.data.activeStep = 3;
							}}
						>
							Next
						</Button>
					</div>
				);
			}
			case 3: {
				let networkListItems = { 0: 'Request New Network' };
				let requestableNetworks = _.cloneDeep(this.data.availableNetworks);
				let existingNetworksMap = {};
				_.forEach(this.data.existingNetworks, (n) => {
					networkListItems[n._id] = n.name;
					existingNetworksMap[n._id] = n;
					if (n.networkId && requestableNetworks[n.networkId]) {
						delete requestableNetworks[n.networkId];
					}
				});
				_.forEach(Object.keys(requestableNetworks), (key) => {
					networkListItems[key] = requestableNetworks[key];
				});

				return (
					<div
						className={`step-3 vbox vcenter hcenter animated ${this.data.stepAnimation}`}
						key={`step-3-${this.data.stepAnimation}`}
					>
						<NetworkRequestForm
							network={this.data.step3Data}
							onSubmit={this.submitNetworkRequest}
							disableEditExisting
						/>
					</div>
				);
			}
			case 4: {
				return (
					<div
						className={`step-4 vbox vcenter hcenter animated ${this.data.stepAnimation}`}
						key={`step-4-${this.data.stepAnimation}`}
					>
						<ExpressFormBuilder
							formData={this.data.step4Data}
							formConfig={telehealthConfig(this.data.step4Data)}
							setValidationState={(valid) => {
								if (
									['carevalet', 'none'].indexOf(this.data.step4Data.type) >= 0
								) {
									this.data.step4Valid = true;
								} else {
									this.data.step4Valid = valid;
								}
							}}
						/>
						<Button
							large
							fill
							className="round-btn ios"
							disabled={
								this.data.step4Data.type === 'carevalet'
									? false
									: !this.data.step4Valid
							}
							onClick={async () => {
								this.data.stepAnimation = 'slideInRight';
								this.data.activeStep = 5;
								await this.setStep5Config();
							}}
						>
							Next
						</Button>
					</div>
				);
			}
			case 5: {
				let cardFronts = {};
				let cardBacks = {};
				let templateMap = {};
				if (this.data.idCardTemplates) {
				}
				this.data.idCardTemplates.forEach((card) => {
					templateMap[card._id] = card;
					if (card.side === 'front') {
						cardFronts[card._id] = card.name;
					}

					if (card.side === 'back') {
						cardBacks[card._id] = card.name;
					}
				});

				return (
					<div
						className={`step-5 vbox vcenter hcenter animated ${this.data.stepAnimation}`}
						key={`step-5-${this.data.stepAnimation}`}
					>
						<div className="message-text mb-l">
							Select an ID card template for front and back of the ID card, to
							create new templates click on the "ID Cards" navigation item and
							use our ID Card builder tool.
						</div>
						<ExpressFormBuilder
							formData={this.data.step5Data}
							formConfig={{
								templateIdFront: {
									label: 'ID Card Template - Front',
									type: 'dropdown',
									className: 'dropdown-form-input',
									icon: 'fal fa-id-card',
									listItems: cardFronts
								}
							}}
							afterChange={this.handlePlanFormChange}
						/>

						<IdCardDetail
							loading={false}
							side="front"
							thumbnailKey={
								templateMap[this.data.step5Data.templateIdFront]?.thumbnails
									.thumbnailMedium
							}
							size="medium"
							id="front-card"
						/>

						<ExpressFormBuilder
							formData={this.data.step5Data}
							formConfig={{
								templateIdBack: {
									label: 'ID Card Template - Back',
									type: 'dropdown',
									className: 'dropdown-form-input',
									icon: 'fal fa-id-card',
									listItems: cardBacks
								}
							}}
							afterChange={this.handlePlanFormChange}
						/>
						<IdCardDetail
							loading={false}
							side="back"
							thumbnailKey={
								templateMap[this.data.step5Data.templateIdBack]?.thumbnails
									.thumbnailMedium
							}
							size="medium"
							id="back-card"
						/>

						<div className="message-text mb-l">
							The following fields are not required however are often associated
							with the plan and if setup can be placed on the ID Card.
						</div>
						<ExpressFormBuilder
							formData={this.data.step5Data}
							formConfig={this.data.step5Config}
							setValidationState={(valid) => {
								this.data.step4Valid = valid;
							}}
						/>
						<Button
							large
							fill
							className="round-btn ios"
							disabled={
								this.data.step4Data.type === 'carevalet'
									? false
									: !this.data.step4Valid
							}
							onClick={this.savePlan}
						>
							Save
						</Button>
					</div>
				);
			}
		}
	}

	getTitleText() {
		let { activeStep } = this.data;
		switch (activeStep) {
			case 1: {
				return expressStore.activePage === 'allPlans'
					? 'Create HealthPlan'
					: 'Connect Existing or Create HealthPlan';
			}
			case 2: {
				return 'HealthPlan Details';
			}
			case 3: {
				return 'Network Setup';
			}
			case 4: {
				return 'Telehealth Details';
			}
			case 5: {
				return 'ID Card';
			}
		}
	}

	render() {
		let { opened } = this.props;
		return (
			<Popup
				className="express-plan-wizard-popup"
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpen={this.handlePopupOpen}
			>
				<div className="popup-header hbox vcenter">
					{this.data.activeStep !== 1 && (
						<div
							className="back-btn hbox vcenter"
							onClick={() => {
								this.data.stepAnimation = 'slideInLeft';
								this.data.activeStep--;
							}}
						>
							<i className="far fa-long-arrow-left"></i>
							Back
						</div>
					)}
					<div className="title grow-1">{this.getTitleText()}</div>
					<Link popupClose>
						<i className="fad fa-times-square"></i>
					</Link>
				</div>
				<div className="main-content y-scroll">{this.getMainContent()}</div>
			</Popup>
		);
	}
}
