import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import expressStore from '@/stores/ExpressStore';
import { autoBind, formatDOB, formatPhoneNumber } from '@/utils/GeneralUtils';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import ExpressAPI from '@/services/ExpressAPI';
import underConstruction from '@/assets/construction.png';
import _ from 'lodash';
import './group-details.scss';
import PlansList from '../plans-list/PlansList';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import TabSlider from '@/components/_EXPRESS/tab-slider/TabSlider';
import MembersList from '../../members/MembersList';
import ConfigEditor from '@/components/_EXPRESS/config-editor/ConfigEditor';

@observer
export default class GroupDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			activePlan: null,
			groupEditOpened: null,
			activeTab: 0,
			editFormData: {
				name: '',
				groupId: '',
				effectiveDate: '',
				renewalDate: '',
				contactName: '',
				contactPhone: '',
				contactEmail: '',
				memberDataType: 'manual'
			}
		});
		autoBind(this);
	}

	setActiveTab(tab) {
		if (this.data.activeTab === 1) {
			const changes = Object.keys(expressStore.pendingConfigs || {});
			if (changes && changes.length > 0) {
				this.$f7.dialog.confirm(
					'You have unsaved changes, if you leave now you will lose your changes.  Press OK to continue or Cancel to save your changes first.',
					() => {
						this.data.activeTab = tab;
					},
					() => console.log('revew and save')
				);
			} else {
				this.data.activeTab = tab;
			}
		} else {
			this.data.activeTab = tab;
		}
	}

	componentWillUnmount() {
		expressStore.activeGroup = null;
		expressStore.activePlan = null;
	}

	buildConstruction() {
		return (
			<div className="construction-ctn">
				<h2>Coming Soon!</h2>
				<img src={underConstruction} />
			</div>
		);
	}

	onSaveGroup() {
		this.$f7.dialog.preloader('Saving...');
		ExpressAPI.saveGroup({
			_id: expressStore.activeGroup._id,
			...this.data.editFormData
		})
			.then((res) => {
				expressStore.activeGroup = res;
				this.data.groupEditOpened = false;
				this.$f7.dialog.close();
				if (expressStore.activeMember) {
					expressStore.activeMember = null;
				}
			})
			.catch((err) => {
				console.log(err);
				this.$f7.dialog.close();
			});
	}

	onEditGroupClick() {
		const { name, groupId, effectiveDate, renewalDate, contactName, contactPhone, contactEmail, memberDataType } = _.cloneDeep(
			expressStore.activeGroup
		);
		let effDate = formatDOB(effectiveDate, 'yyyy-MM-dd', 'MM/dd/yyyy');
		let renewDate = formatDOB(renewalDate, 'yyyy-MM-dd', 'MM/dd/yyyy');
		this.data.editFormData = {
			name,
			groupId,
			contactName,
			contactPhone,
			contactEmail,
			effectiveDate: effDate,
			renewalDate: renewDate,
			memberDataType: memberDataType
		};
		this.data.groupEditOpened = true;
	}

	getEditFormConfig() {
		return {
			row1: {
				name: {
					label: 'Group Name',
					placeholder: 'Name of this group',
					type: 'text',
					validator: {
						type: 'length',
						value: 2
					}
				},
				groupId: {
					label: 'Group ID',
					placeholder: `ID for this group`,
					type: 'text',
					validator: {
						type: 'length',
						value: 2
					}
				}
			},
			row2: {
				effectiveDate: {
					label: 'Effective Date',
					type: 'date',
					validator: {
						type: 'date'
					}
				},
				renewalDate: {
					label: 'Renewal Date',
					type: 'date',
					validator: {
						type: 'date'
					}
				}
			},
			contactName: {
				label: 'Contact Name',
				placeholder: 'Main contact for this group',
				type: 'text',
				validator: {
					type: 'length',
					value: 2
				}
			},
			contactPhone: {
				label: 'Contact Phone',
				placeholder: 'Main contact phone number',
				type: 'phone',
				validator: {
					type: 'phone'
				}
			},
			contactEmail: {
				label: 'Contact Email',
				placeholder: 'Main contact email address',
				type: 'email',
				validator: {
					type: 'email'
				}
			},
			memberDataType: {
				label: 'Member Data',
				type: 'dropdown',
				className: 'dropdown-form-input',
				icon: this.data.editFormData.memberDataType === 'manual' ? 'fad fa-user-edit' : 'fad fa-file-upload',
				listItems: { manual: 'Manually Manage Member Data', import: 'Import Member Data via Files' }
			}
		};
	}

	buildGroupDetailsCard() {
		const { activeGroup } = expressStore;
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		const cardConfig = {
			name: { label: 'Group Name' },
			groupId: { label: 'Group ID' },
			status: { label: 'Status' },
			effectiveDate: { label: 'Effective Date' },
			renewalDate: { label: 'Renewal Date' },
			lastUpdated: { label: 'Last Updated' },
			contactName: { label: 'Contact Name' },
			contactPhone: { label: 'Contact Phone' },
			contactEmail: { label: 'Contact Email' },
			memberDataType: { label: 'Member Data Type', standalone: true }
		};

		const formatValue = (key, value) => {
			if (key === 'status') {
				return this.props.statusChipBuilder(value);
			}
			if (['effectiveDate', 'renewalDate', 'lastUpdated'].indexOf(key) >= 0) {
				return formatDOB(value, 'yyyy-MM-dd', 'MM/dd/yyyy');
			}
			if (key === 'contactPhone') {
				return formatPhoneNumber(value);
			}
			if (key === 'memberDataType') {
				return value === 'manual'
					? `Manual Data Management \nMembers can be manually created, edited, and termed. Any members included on files with this groupId will not be loaded.`
					: `Data File Import \nMembers can only be loaded via the data import process. You cannot manually edit any members in this group.`;
			}
			return value;
		};

		return (
			<div className="group-details-card animated fadeInUp">
				<div className="card-header">
					<div className="title grow-1">Group Details</div>
					{canEdit && <i className="fad fa-pen" onClick={this.onEditGroupClick}></i>}
				</div>
				<div className="card-fields">
					{Object.keys(cardConfig).map((fieldName) => {
						let value = activeGroup[fieldName];
						let config = cardConfig[fieldName];
						return (
							<div key={`${activeGroup._id}-${fieldName}`} className={`card-field ${config.standalone ? 'standalone' : ''}`}>
								<div className="label">{config.label}:</div>
								<div className="value">{formatValue(fieldName, value)}</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	getBreadCrumb() {
		let currentKey = 0;
		if (expressStore.activeGroup) {
			currentKey++;
		}
		if (expressStore.activePlan) {
			currentKey++;
		}
		return (
			<Breadcrumb
				pageName="groups"
				config={{
					0: {
						name: 'All Groups',
						icon: 'fas fa-users-class',
						onClick: () => {
							expressStore.activeGroup = null;
							this.props.loadGroups();
						}
					},
					1: {
						name: expressStore.activeGroup?.name,
						onClick: () => {
							if (expressStore.activePlan) {
								expressStore.activePlan = null;
							}
						}
					},
					2: {
						name: expressStore.activePlan?.name
					}
				}}
				currentKey={currentKey}
			/>
		);
	}

	buildTabSlider() {
		return (
			<TabSlider
				activeTab={this.data.activeTab}
				setActiveTab={this.setActiveTab}
				config={{
					0: {
						name: 'HealthPlans'
					},
					1: {
						name: 'App Config'
					},
					// 2: {
					// 	name: 'Notes'
					// },
					2: {
						name: 'Members'
					}
				}}
			/>
		);
	}

	getTabContent() {
		switch (this.data.activeTab) {
			case 0: {
				return <PlansList />;
			}
			case 1: {
				return <ConfigEditor type="group" />;
			}
			// case 2: {
			// 	return this.buildConstruction();
			// }
			case 2: {
				return <MembersList groupId={expressStore.activeGroup._id}></MembersList>;
			}
		}
	}

	render() {
		let { groupEditOpened, editFormData } = this.data;
		return (
			<div className="group-details-page">
				{this.getBreadCrumb()}
				{this.buildGroupDetailsCard()}
				{this.buildTabSlider()}
				{this.getTabContent()}
				<FormPopup
					opened={groupEditOpened}
					title="Edit Group"
					buttonText="Save"
					formData={editFormData}
					formConfig={this.getEditFormConfig()}
					onSubmit={this.onSaveGroup}
					additionalContent={
						<div className="form-additional-details">
							<div className="form-message">
								{editFormData.memberDataType === 'manual'
									? `Prefered for smaller groups. This group's members can be manually created, edited, and termed straight from express. Any members included on files with this group ID will not be loaded, updated, or termed via that process.`
									: `Prefered for large groups or initially seeding a group with data then switching to manual later. Members will only be loaded via the data import process. You cannot manually edit any members in this group.`}
							</div>
							<div className="form-message">
								<i className="fad fa-exclamation-triangle"></i>
								You can change this at anytime but note: <br />
								Data may be lost or overridden if you switch processes and have not backed up the changes in the destination system.
							</div>
						</div>
					}
					onClose={() => {
						this.data.groupEditOpened = false;
						this.data.editFormData = {
							name: '',
							groupId: '',
							effectiveDate: '',
							renewalDate: '',
							contactName: '',
							contactPhone: '',
							contactEmail: '',
							memberDataType: 'manual'
						};
					}}
				/>
			</div>
		);
	}
}
