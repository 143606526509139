import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import UserBillingService from '@/services/UserBillingService';
import appStore from '@/stores/AppStore';
import { Popup, Preloader, Button, Page, Tabs, Tab, Badge } from 'framework7-react';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import './call-log-popup.scss';

import CloudAPI from '@/services/CloudAPI';
import CallList from './call-list/CallList';
import CallDetail from './call-detail/CallDetail';

@observer
export default class CallLogPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true,
			callHistory: [],
			detailLoading: true,
			callDetails: []
		});
		autoBind(this);
	}

	async onPopupOpen() {
		this.fetchData();
	}

	async fetchData() {
		try {
			this.data.loading = true;
			this.data.callHistory = await UserBillingService.getUserCallHistory();
			this.data.loading = false;
		} catch (e) {
			this.data.loading = false;
			console.log("ERROR: An error occured while attempting to retrieve the user's payment methods");
		}
	}

	closePrompt() {
		this.data.callHistory = [];
		if (this.props.onCloseCallLog) {
			this.props.onCloseCallLog();
		}
	}

	show(id) {
		this.$f7.tab.show('#'+ id, true);
	}

	viewDetail(id) {
        this.fetchCallDetail(id);
        this.show('call-detail');
	}

	async fetchCallDetail(id) {
		this.data.detailLoading = true;
		try {
			let call = await CloudAPI.getCallSequence(id);
			this.data.callDetails = call;
			this.data.detailLoading = false;
		} catch (e) {
			console.log(e);
			this.data.detailLoading = false;
			//TODO throw error dialog
		}
	}
	render() {
		let { loading, detailLoading, callHistory, callDetails } = this.data;
		return (
			<Popup
				opened={this.props.opened}
				className={`call-log-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={true}
				closeOnEscape={true}
				onPopupClosed={this.closePrompt}
				onPopupOpen={this.onPopupOpen}
			>
				<Page>
					<Tabs animated>
						<Tab id="call-list">
							<CallList onViewDetail={this.viewDetail} callHistory={callHistory} loading={loading} onClose={this.closePrompt}></CallList>
						</Tab>
                        <Tab id="call-detail">
                            <CallDetail onBack={() => this.show('call-list')} callDetails={callDetails} loading={detailLoading}/>
                       </Tab>
					</Tabs>
				</Page>
			</Popup>
		);
	}
}
