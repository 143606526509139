import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Popup, Link, Preloader } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import appStore from '@/stores/AppStore';
import BasicInput from '@/components/basic-input/BasicInput';
import './affiliate-management.scss';
import CloudAPI from '@/services/CloudAPI';
import AffiliateLinkPopup from '@/components/affiliate-link-popup/AffiliateLinkPopup';

@observer
export default class AffiliateManagement extends Component {
	constructor(props) {
		super(props);
		this.linkRef = React.createRef();
		this.data = observable({
			openAffiliate: null,
			affiliateDetails: [],
			affiliateDetailsLoading: false,
			editingAffiliate: null,
			linkAff: null,
			editOpen: false,
			createOpen: false,
			linkOpen: false,
			loading: true,
			downloadImageFunction: () => {
				console.log('No Function Set');
			},
			createForm: {
				name: '',
				phone: '',
				code: Math.floor(new Date().getTime() * Math.random()).toString(),
				email: ''
			},
			affiliates: []
		});
		autoBind(this);
	}

	componentDidMount() {
		this.loadAffs();
	}

	loadAffs() {
		this.data.loading = true;
		CloudAPI.getAffiliates()
			.then((res) => {
				this.data.affiliates = res;
				this.data.loading = false;
			})
			.catch((err) => {
				this.data.loading = false;
				console.log(err);
			});
	}

	isFormValid(data) {
		let valid = true;
		_.forEach(['name', 'email', 'phone'], (key) => {
			if (_.isEmpty(data[key])) {
				valid = false;
			}
		});
		return valid;
	}

	onCreateAffiliate() {
		let valid = this.isFormValid(this.data.createForm);
		if (valid) {
			this.$f7.dialog.preloader();
			CloudAPI.createAffiliate(this.data.createForm)
				.then((res) => {
					this.loadAffs();
					this.closeCreate();
					this.$f7.dialog.close();
				})
				.catch((err) => {
					this.$f7.dialog.close();
					console.log(err);
				});
		} else {
			this.$f7.dialog.alert('Please complete the form');
		}
	}

	onSaveAffiliate() {
		let valid = this.isFormValid(this.data.editingAffiliate);
		if (valid) {
			this.$f7.dialog.preloader();
			CloudAPI.saveAffiliate(this.data.editingAffiliate)
				.then((res) => {
					this.loadAffs();
					this.closeEdit();
					this.$f7.dialog.close();
				})
				.catch((err) => {
					this.$f7.dialog.close();
					console.log(err);
				});
		} else {
			this.$f7.dialog.alert('Please complete the form');
		}
	}

	generateLink(aff) {
		this.data.linkAff = aff;
		this.data.linkOpen = true;
	}

	closeCreate() {
		this.data.createOpen = false;
		this.data.createForm = {
			name: '',
			phone: '',
			code: Math.floor(new Date().getTime() * Math.random()).toString(),
			email: ''
		};
	}

	isCreateValid() {
		return true;
	}

	onEditAffiliate(affiliate) {
		this.data.editingAffiliate = affiliate;
		this.data.editOpen = true;
	}

	closeEdit() {
		this.data.editingAffiliate = null;
		this.data.editOpen = false;
	}

	editOpen() {
		this.data.editingAffiliate = null;
	}

	closeLinkPopup() {
		this.data.linkAff = null;
		this.data.linkOpen = false;
	}

	onInputChange(e) {
		_.set(
			this.data,
			`${this.data.editingAffiliate !== null ? 'editingAffiliate' : 'createForm'}.${e.currentTarget.name}`,
			e.currentTarget.value
		);
	}

	buildAffFields() {
		let name = '';
		let email = '';
		let phone = '';
		if (this.data.editingAffiliate) {
			name = this.data.editingAffiliate.name;
			email = this.data.editingAffiliate.email;
			phone = this.data.editingAffiliate.phone;
		} else {
			name = this.data.createForm.name;
			email = this.data.createForm.email;
			phone = this.data.createForm.phone;
		}
		return (
			<>
				<BasicInput
					name={'name'}
					label={`Full Name`}
					type="text"
					placeholder={'Influencer Full Name'}
					value={name}
					onChange={this.onInputChange}
					validate={true}
				/>
				<BasicInput
					name={'email'}
					label={`Email`}
					type="email"
					placeholder={'Email address'}
					value={email}
					onChange={this.onInputChange}
					validate={true}
				/>
				<BasicInput
					onChange={this.onInputChange}
					label="Phone"
					value={phone}
					type="number"
					placeholder={'Mobile Phone'}
					name="phone"
				/>
			</>
		);
	}

	closeAffiliateDetails() {
		this.data.openAffiliate = null;
		this.data.affiliateDetails = [];
	}

	async getAffiliateDetails(evt) {
		const affiliate = evt.currentTarget.getAttribute('data-affiliate');
		const regCount = evt.currentTarget.getAttribute('data-registered');
		if (regCount < 1) return;
		if (affiliate === this.data.openAffiliate) return;
		this.data.affiliateDetails = [];
		this.data.openAffiliate = affiliate;
		this.data.affiliateDetailsLoading = true;
		try {
			const affiliateDetails = await CloudAPI.getAffiliate(affiliate);
			this.data.affiliateDetailsLoading = false;
			this.data.affiliateDetails = affiliateDetails;
		} catch (err) {
			this.data.affiliateDetailsLoading = false;
			console.log('Error getting aff details: ', err);
		}
	}

	buildAff(aff, isDetail) {
		return (
			<div
				className={`affiliate hbox vcenter ${aff.regCount > 0 && !isDetail ? 'has-action' : ''}`}
				data-affiliate={aff._id}
				data-registered={aff.regCount}
				onClick={!isDetail ? this.getAffiliateDetails : () => {}}
			>
				<div className="field ellipse name">
					{aff.consumer && !isDetail && (
						<div className="consumer" title="Consumer">
							C
						</div>
					)}
					{aff.name}
				</div>
				<div className="field ellipse name">{aff.consumer ? aff.code : aff.email}</div>
				<div className="field ellipse">{aff.phone}</div>
				<div className="field small">{aff.hits}</div>
				<div className={`field small ${aff.regCount > 0 && !isDetail ? 'has-action' : ''}`}>{aff.regCount}</div>
				<div className="field small">{aff.purchases}</div>
				{!isDetail && (
					<Fragment>
						<Button
							small
							className="icon-btn"
							tooltip="Generate Link"
							onClick={() => {
								this.generateLink(aff);
							}}
						>
							<i className="fad fa-qrcode"></i>
						</Button>
						<Button
							small
							className="icon-btn"
							tooltip="Edit"
							onClick={() => {
								this.onEditAffiliate(aff);
							}}
						>
							<i className="fad fa-pencil"></i>
						</Button>
					</Fragment>
				)}
				{isDetail && (
					<Fragment>
						<div className="icon-btn" />
						<div className="icon-btn" />
					</Fragment>
				)}
			</div>
		);
	}

	buildAffs() {
		if (this.data.loading) {
			return (
				<div className="load-ctn">
					<Preloader></Preloader>
				</div>
			);
		}
		let items = [];
		_.forEach(this.data.affiliates, (aff) => {
			items.push(
				<div key={aff._id}>
					{this.buildAff(aff, false)}
					{this.data.openAffiliate && this.data.openAffiliate === aff._id && (
						<div className="affiliate-details animated fadeIn">
							{this.data.affiliateDetailsLoading && (
								<div className="load-ctn">
									<Preloader></Preloader>
								</div>
							)}
							{!this.data.affiliateDetailsLoading && (
								<p className="details-header animated fadeInUp">
									<span className="close-btn" onClick={this.closeAffiliateDetails}>
										<i className="fad fa-eye-slash" />
										Hide {aff.name} Registration Details
									</span>
								</p>
							)}
							{this.data.affiliateDetails.map((affiliateUser) => {
								return (
									<div className="animated fadeInUp" key={affiliateUser._id}>
										{this.buildAff(affiliateUser, true)}
									</div>
								);
							})}
						</div>
					)}
				</div>
			);
		});
		return items;
	}

	editAffiliatePopup() {
		return (
			<Popup
				opened={this.data.editOpen}
				className={`edit-affiliate-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeEdit}
			>
				<div className="popup-header">
					<div className="title grow-1">Edit Influencer</div>
					<Link popupClose>
						<i className="fad fa-times-square" />
					</Link>
				</div>
				<div className="form-ctn y-scroll">{this.buildAffFields()}</div>
				<div className="btn-ctn vbox vcenter hcenter elevation-2">
					<Button fill raised large round className="w-100" onClick={this.onSaveAffiliate}>
						Save
					</Button>
				</div>
			</Popup>
		);
	}

	createAffiliatePopup() {
		return (
			<Popup
				opened={this.data.createOpen}
				className={`create-affiliate-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeCreate}
			>
				<div className="popup-header">
					<div className="title grow-1">New Influencer</div>
					<Link popupClose>
						<i className="fad fa-times-square" />
					</Link>
				</div>
				<div className="form-ctn y-scroll">{this.buildAffFields()}</div>
				<div className="btn-ctn vbox vcenter hcenter elevation-2">
					<Button fill raised large round className="w-100" onClick={this.onCreateAffiliate}>
						Create
					</Button>
				</div>
			</Popup>
		);
	}

	render() {
		return (
			<div className="affiliate-mgmt-page">
				<div className="affiliate-ctn">
					<div className="aff-header hbox vcenter">
						<div className="field name">Name</div>
						<div className="field name">Email/MemberId</div>
						<div className="field">Phone</div>
						<div className="field small">Hits</div>
						<div className="field small">Registrations</div>
						<div className="field small">Purchases</div>
						<Button
							fill
							small
							className="create-btn"
							tooltip="Create Influencer"
							popupOpen=".create-affiliate-popup"
							onClick={() => {
								this.data.createOpen = true;
							}}
						>
							<i className="fal fa-plus" />
							Create
						</Button>
					</div>
					<div className="aff-list y-scroll"> {this.buildAffs()}</div>
				</div>
				{this.createAffiliatePopup()}
				{this.editAffiliatePopup()}
				<AffiliateLinkPopup
					isOpen={this.data.linkOpen}
					onClose={this.closeLinkPopup}
					title="Influencer Link"
					code={_.get(this.data, 'linkAff.code')}
				/>
			</div>
		);
	}
}
