import ConfigService from '../services/ConfigService';
import _ from 'lodash';
import providerStore from '../stores/ProviderStore';
import { getLocalizedDate } from './Localization';
import { parse } from 'date-fns';

export function getStripeKey() {
	if (process.env.NODE_ENV === 'development') {
		console.log('++++++++ PAYMENTS ARE IN DEV MODE +++++');
		return 'pk_test_6CfMi0M58qwCfvsfpNyxwrnl';
	} else {
		console.log('++++++++ PAYMENTS ARE IN PRODUCTION MODE +++++');
		return 'pk_live_Z0y7tHJB4YsN6F4Bqq47W17B';
	}
}
export function autoBind(cmp, usingHOC, exclude) {
	const reactInternals = new Set([
		'constructor',
		'render',
		'componentWillMount',
		'componentDidMount',
		'componentWillReceiveProps',
		'shouldComponentUpdate',
		'componentWillUpdate',
		'componentDidUpdate',
		'componentWillUnmount'
	]);
	if (exclude && exclude.length > 0) {
		for (let i = 0; i < exclude.length; i++) {
			reactInternals.add(exclude[i]);
		}
	}
	let proto = cmp.__proto__;
	for (let key of Object.getOwnPropertyNames(proto)) {
		const { value } = Object.getOwnPropertyDescriptor(proto, key);
		// do not try to bind properties and getter/setters
		if (typeof value === 'function' && !reactInternals.has(key)) {
			cmp[key] = value.bind(cmp);
		}
	}
}
const credMap = {
	'NURSE PRACTITIONER': 'NP',
	ANDREWS: 'DR'
};
export function credentialMapper(credential) {
	let mapped = credMap[credential];
	return mapped ? mapped : credential;
}

export function formatDOB(dobString, incomingFormat = 'yyyy-MM-dd', desiredFormat = 'PP') {
	if (!dobString) {
		return '';
	}
	const dateParts = dobString.split('T');
	const dateOnly = (dateParts && dateParts[0]) || dobString;
	const birthdayDisplay = getLocalizedDate(parse(dateOnly, incomingFormat, new Date()), desiredFormat);
	return birthdayDisplay;
}

export function getFindCareIcon(p) {
	const currentCategory = _.get(providerStore, 'filters.category', '');
	let cats = Object.values(ConfigService.getConfigValue('search.categories', {}));
	let cat = _.find(cats, { type: currentCategory });
	if (cat) {
		if (cat.icon === 'fad fa-ellipsis-h-alt') {
			return 'fad fa-hospital';
		} else {
			return cat.icon;
		}
	}
	return 'fad fa-hospital';
}

export function getProviderIcon(p, careTeam) {
	let currentCategory = _.get(providerStore, 'filters.category', '');
	const currentCategoryIndex = _.get(p, 'allSpecialties', []).indexOf(currentCategory);
	let matched = currentCategoryIndex >= 0;
	let icon;
	if (careTeam) {
		let cats = Object.values(ConfigService.getConfigValue('search.categories', {}));
		let cat = _.find(cats, { type: _.get(p, 'specialty', '') });
		if (cat) {
			icon = cat.icon;
		} else {
			icon = 'fad fa-hospital';
		}
		return icon;
	}
	if (matched) {
		let cats = Object.values(ConfigService.getConfigValue('search.categories', {}));
		let cat = _.find(cats, { type: currentCategory });
		if (cat) {
			if (cat.icon === 'fad fa-ellipsis-h-alt') {
				icon = 'fad fa-hospital';
			} else {
				icon = cat.icon;
			}
		}
		return icon;
	}
	if (!matched) {
		let cats = Object.values(ConfigService.getConfigValue('search.categories', {}));
		let providerSpecialties = _.get(p, 'allSpecialties', []);
		const isGeneralAcute =
			providerSpecialties.indexOf('General Acute') > -1 || providerSpecialties.indexOf('General Acute Hospitals') > -1;
		const isChildrens = providerSpecialties.indexOf('Childrens') > -1 || providerSpecialties.indexOf('Childrens Hospitals');
		const isRehab = providerSpecialties.indexOf('Rehab') > -1 || providerSpecialties.indexOf('Rehab Hospitals') > -1;

		if (!p.isPerson && (isGeneralAcute || isChildrens || isRehab)) {
			return 'fad fa-hospital';
		}
		icon = null;
		_.forEach(cats, (c) => {
			if (_.get(p, 'allSpecialties', []).indexOf(c.type) >= 0) {
				icon = c.icon;
			}
		});
	}
	if (icon) {
		if (icon === 'fad fa-ellipsis-h-alt') {
			return 'fad fa-hospital';
		}
		return icon;
	}
	return 'fad fa-hospital';
}

export function getUrlParams() {
	const urlParams = _.chain(window.location.href.split('?')[1])
		.replace('?', '')
		.split('&')
		.map(_.partial(_.split, _, '=', 2))
		.fromPairs()
		.value();
	delete urlParams[''];
	return urlParams;
}

export function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

export function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validateUrl(str) {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
}
/**
 *
 * @param {*} f7 framework7 instance
 * @param {*} err server error object
 * @param {*} alternativeMessage if no message found on error what to say
 */
export function showExpressError(f7, err, alternativeMessage) {
	//close any previously opened dialog
	f7.dialog.close();
	try {
		//first try message text
		const message = err.message ? err.message : JSON.parse(err.response).message;
		if (message.indexOf('/api/express') < 0) {
			f7.dialog.alert(message, 'Error:');
		} else {
			f7.dialog.alert(alternativeMessage || 'Unkwown Error Occurred Please Try Again', 'Error:');
		}
	} catch {
		//fallback text
		f7.dialog.alert(alternativeMessage || 'Unkwown Error Occurred Please Try Again', 'Error:');
	}
}

export function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
	try {
		//ensure number
		let amountNumber = Number(amount);
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amountNumber < 0 ? '-' : '';

		// @ts-ignore
		let i = parseInt((amountNumber = Math.abs(Number(amountNumber) || 0).toFixed(decimalCount))).toString();
		let j = i.length > 3 ? i.length % 3 : 0;

		return (
			negativeSign +
			(j ? i.substr(0, j) + thousands : '') +
			i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
			(decimalCount
				? decimal +
				  Math.abs(amountNumber - i)
						.toFixed(decimalCount)
						.slice(2)
				: '')
		);
	} catch (e) {
		console.log(e);
	}
	return amount;
}
