// @ts-nocheck
import React, { Component } from 'react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import './express-shell.scss';
import { Link, List, ListItem, Page, Popover, Popup } from 'framework7-react';
import appStore from '@/stores/AppStore';
import logoSVG from '@/assets/logo-grey-blue.svg';
import expressStore from '@/stores/ExpressStore';
import Home from '../home/Home';
import Reporting from '../reporting/Reporting';
import IDCardList from '../idcards/IDCardList';
import GroupsHome from '../groups/GroupsHome';
import PlansList from '../groups/plans-list/PlansList';
import NetworkList from '../networks/NetworkList';
import ImportHome from '../members/import-home/ImportHome';
import MembersList from '../members/MembersList';
import CanvasEditor from '@/components/canvas-editor/CanvasEditor';
import CanvasFactory from '@/factories/CanvasFactory';
import DocumentsHome from '../documents/DocumentsHome';
import Publishing from '../publishing/Publishing';
import ExpressAdmin from '../admin/ExpressAdmin';
import { Walktour } from 'walktour';
import CanvasTemplates from '../canvas-templates/CanvasTemplates';
import SettingsPage from '../settings/SettingsPage';
import SupportPage from '../support/SupportPage';
import PrintingPage from '../printing/PrintingPage';

@observer
export default class ExpressShell extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}
	getSubNav() {
		const navmap = {
			members: {
				importdata: 'Import Data'
			},
			importdata: {
				importdata: 'Import Data'
			},
			idcards: {
				printing: 'Printing & Fulfillment'
			},
			printing: {
				printing: 'Printing & Fulfillment'
			},
			admin: {
				templates: 'Templates'
			},
			templates: {
				templates: 'Templates'
			}
		};
		return navmap[expressStore.activePage];
	}

	buildSubNav(active) {
		let { activePage } = expressStore;
		if (active.indexOf(activePage) >= 0) {
			let nav = this.getSubNav();
			let items = [];
			_.forEach(Object.keys(nav), (k) => {
				items.push(
					<div
						key={`sub-nav-${k}`}
						className={`nav-item sub ${activePage === k ? 'active' : ''} animated fadeInDown`}
						onClick={() => {
							expressStore.activePage = k;
						}}
					>
						<span className="text-2">{nav[k]}</span>
					</div>
				);
			});
			return items;
		}
		return '';
	}

	getOrganizationsList() {
		const myOrganizations = _.cloneDeep(expressStore.myOrganizations);
		let items = [];
		myOrganizations.forEach((org) => {
			items.push(
				<ListItem
					link="#"
					popoverClose
					className="drop-down-list-item"
					key={`${org._id}-selector-dropdown-item`}
					onClick={() => {
						expressStore.currentOrg = org;
						expressStore.activeGroup = null;
						expressStore.activePlan = null;
						if (expressStore.activePage === 'printing') {
							expressStore.activePage = 'idcards';
						}
					}}
				>
					<div className="dd-label">{org.name}</div>
				</ListItem>
			);
		});
		return items;
	}

	getActiveContent() {
		switch (expressStore.activePage) {
			case 'home': {
				return <Home></Home>;
			}
			case 'idcards': {
				return <IDCardList></IDCardList>;
			}
			case 'printing': {
				return <PrintingPage></PrintingPage>;
			}
			case 'members': {
				return <MembersList></MembersList>;
			}
			case 'groups': {
				return <GroupsHome></GroupsHome>;
			}
			case 'support': {
				return <SupportPage></SupportPage>;
			}
			case 'allPlans': {
				return <PlansList></PlansList>;
			}
			case 'networks': {
				return <NetworkList />;
			}
			case 'importdata': {
				return <ImportHome></ImportHome>;
			}
			case 'documents': {
				return <DocumentsHome></DocumentsHome>;
			}
			case 'reporting': {
				return <Reporting />;
			}
			case 'publishing': {
				return <Publishing />;
			}
			case 'settings': {
				return <SettingsPage></SettingsPage>;
			}
			case 'admin': {
				return expressStore.isSystemAdmin() ? <ExpressAdmin /> : <div>Invalid Page</div>;
			}
			case 'templates': {
				return expressStore.isSystemAdmin() ? <CanvasTemplates /> : <div>Invalid Page</div>;
			}
		}
	}

	render() {
		let { activePage, activated, isLoggedIn } = expressStore;
		if (!isLoggedIn) {
			this.$f7.popover.close('.popover', false);
			setTimeout(() => {
				return <Page className="shell-not-logged-in"></Page>;
			}, 500);
		}
		const hasAdminRole = expressStore.hasAnAdminRole();
		const isSystemAdmin = expressStore.isSystemAdmin();
		const canPrint = (hasAdminRole || isSystemAdmin) && _.get(expressStore, 'currentOrg.idCardFulfillment');
		return (
			<Page className={`express-portal-shell ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<div className="navigation vbox hleft">
					<div className="logo hbox vcenter">
						<img className="cvsvg" src={logoSVG} />
						<span className="text-2">Care</span>
						<span className="text-4">Valet</span>
					</div>
					<Link className="org-picker hbox vcenter" popoverOpen={`.global-org-selector`} id="global-org-selector">
						<div className="selection ellipse">
							<div className="hbox vcenter">
								<i className="fal fa-building"></i>
								<div className="label">Current Organization</div>
							</div>
							<div className="current ellipse">{_.get(expressStore, 'currentOrg.name', 'Unknown Org')}</div>
						</div>
						<div className="dd-icon">
							<i className="fas fa-chevron-up"></i>
							<i className="fas fa-chevron-down"></i>
						</div>
					</Link>
					<div
						className={`nav-item home-nav ${activePage === 'home' ? 'active' : ''}`}
						onClick={() => {
							expressStore.activePage = 'home';
						}}
					>
						<i className="fad fa-house"></i>
						<span className="text-2">Home</span>
					</div>
					{(hasAdminRole || isSystemAdmin) && (
						<div
							className={`nav-item ${activePage === 'reporting' ? 'active' : ''}`}
							onClick={() => {
								expressStore.activePage = 'reporting';
							}}
						>
							<i className={expressStore.activated ? 'fad fa-chart-bar' : 'fad fa-lock-alt'}></i>
							<span className="text-2">Reporting</span>
						</div>
					)}
					<div
						id="groupsButton"
						className={`nav-item ${activePage === 'groups' ? 'active' : ''}`}
						onClick={() => {
							if (activated) {
								expressStore.activePage = 'groups';
							}
						}}
					>
						<i className={expressStore.activated ? 'fad fa-users-class' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Groups</span>
					</div>
					<div
						id="plansButton"
						className={`nav-item ${activePage === 'allPlans' ? 'active' : ''}`}
						onClick={() => {
							if (activated) {
								expressStore.activePage = 'allPlans';
							}
						}}
					>
						<i className={expressStore.activated ? 'fad fa-files-medical' : 'fad fa-lock-alt'}></i>
						<span className="text-2">HealthPlans</span>
					</div>

					<div
						className={`nav-item ${activePage === 'networks' ? 'active' : ''}`}
						onClick={() => {
							expressStore.activePage = 'networks';
						}}
					>
						<i className={expressStore.activated ? 'fad fad fa-hospital-user' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Networks</span>
					</div>
					<div
						className={`nav-item ${activePage === 'members' ? 'active' : ''}`}
						onClick={() => {
							if (activated) {
								expressStore.activePage = 'members';
							}
						}}
					>
						<i className={expressStore.activated ? 'fad fa-users' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Members</span>
					</div>
					{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
					expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
						? null
						: this.buildSubNav(['members', 'importdata'])}
					<div
						className={`nav-item ${activePage === 'idcards' ? 'active' : ''}`}
						onClick={() => {
							expressStore.activePage = 'idcards';
						}}
					>
						<i className={expressStore.activated ? 'fad fa-id-card' : 'fad fa-lock-alt'}></i>
						<span className="text-2">ID Cards</span>
					</div>
					{canPrint && this.buildSubNav(['idcards', 'printing'])}
					<div
						className={`nav-item ${activePage === 'documents' ? 'active' : ''}`}
						onClick={() => {
							expressStore.activePage = 'documents';
						}}
					>
						<i className={expressStore.activated ? 'fad fa-folders' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Documents</span>
					</div>
					<div
						className={`nav-item ${activePage === 'publishing' ? 'active' : ''}`}
						onClick={() => {
							expressStore.activePage = 'publishing';
						}}
					>
						<i className={expressStore.activated ? 'fad fa-cloud-upload-alt' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Publishing</span>
					</div>

					<div
						className={`nav-item ${activePage === 'settings' ? 'active' : ''}`}
						onClick={() => {
							if (activated) {
								expressStore.activePage = 'settings';
							}
						}}
					>
						<i className={expressStore.activated ? 'fad fa-cogs' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Settings</span>
					</div>
					<div
						className={`nav-item ${activePage === 'support' ? 'active' : ''}`}
						onClick={() => {
							if (activated) {
								expressStore.activePage = 'support';
							}
						}}
					>
						<i className={expressStore.activated ? 'fad fa-life-ring' : 'fad fa-lock-alt'}></i>
						<span className="text-2">Support</span>
					</div>

					{expressStore.isSystemAdmin() && (
						<div
							className={`nav-item ${activePage === 'admin' ? 'active' : ''}`}
							onClick={() => {
								expressStore.activePage = 'admin';
							}}
						>
							<i className={expressStore.activated ? 'fad fa-user-shield' : 'fad fa-lock-alt'}></i>
							<span className="text-2">Admin</span>
						</div>
					)}
					{this.buildSubNav(['admin', 'templates'])}
					<div
						className={`nav-item signout`}
						onClick={() => {
							firebase.auth().signOut();
						}}
					>
						<i className="fad fa-sign-out"></i>
						<span className="text-2">Sign Out</span>
					</div>
					<Popover className="global-org-selector">
						<div className="global-org-list-container y-scroll">
							<List>{this.getOrganizationsList()}</List>
						</div>
					</Popover>
				</div>
				<div className="active-content grow-1">{this.getActiveContent()}</div>
				<Walktour
					isOpen={!!expressStore.activeTour}
					customCloseFunc={() => {
						expressStore.activeTour = null;
					}}
					steps={[
						{
							selector: '#groupsButton',
							title: 'Create a Group',
							description: 'One foot in front of the other'
						},
						{
							selector: '#plansButton',
							title: 'Create A Plan',
							description: 'One foot in front of the other'
						}
					]}
				/>
				<Popup tabletFullscreen={true} opened={!!expressStore.activeEditor} className="editor-popup">
					{expressStore.activeEditor && (
						<CanvasEditor
							documentWidth={505.2}
							documentHeight={318.75}
							onClose={() => {
								this.$f7.popover.close('.variable-fields', false);
								this.$f7.dialog.preloader();
								setTimeout(() => {
									CanvasFactory.getActiveService().onClose();
									expressStore.activeEditor = null;
									expressStore.activeCard = null;
									this.$f7.dialog.close();
								}, 500);
							}}
						/>
					)}
				</Popup>
			</Page>
		);
	}
}
