import React, { Component } from 'react';
import { observer, observable } from '../../../utils/State';
import _ from 'lodash';
import { autoBind, getUrlParams } from '@/utils/GeneralUtils';
import './express-login.scss';
import { Button, Page } from 'framework7-react';
import appStore from '@/stores/AppStore';
import expressBG from '@/assets/expressBg.png';
import logoSVG from '@/assets/logo-grey-blue.svg';
import expressIllistration from '@/assets/expressIllistration.png';
import BasicInput from '@/components/basic-input/BasicInput';
import expressStore from '@/stores/ExpressStore';
import ExpressAuthService from '@/services/ExpressAuthService';
import ExpressSignupForm from '../signup/ExpressSignupForm';
import ExpressInviteForm from '../signup/ExpressInviteForm';
import StorageService from '@/services/StorageService';
const storageService = new StorageService();
@observer
export default class ExpressLogin extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			password: '',
			email: '',
			forgotPassword: false,
			emailValid: false,
			rememberMe: false,
			showSignup: false,
			showInvite: false,
			completedSignup: false,
			inviteCode: null,
			urlParams: null
		});
		autoBind(this);
	}

	async componentDidMount() {
		try {
			this.data.urlParams = getUrlParams();
			let inviteCode = _.get(this.data.urlParams, 'invite', null);
			if (inviteCode && !this.data.completedSignup) {
				console.info(`User invited by: ${inviteCode}`);
				this.data.inviteCode = inviteCode;
				this.data.showInvite = true;
			}
			const userLoginInfo = await storageService.getValue('app', 'expressLogin');
			const loginInfo = JSON.parse(userLoginInfo);
			const rememberMe = loginInfo && loginInfo.rememberMe;
			this.data.rememberMe = rememberMe;
			if (rememberMe) {
				this.data.email = loginInfo && loginInfo.loginEmail;
			}
		} catch (err) {
			console.log('error getting remember me status: ', err);
			this.data.rememberMe = false;
			this.data.email = '';
		}
	}

	onInputChange(evt) {
		const isEmailValid = this.$f7.input.validate('.email-address');
		const target = evt.currentTarget;
		const field = target.name;
		const value = target.value;
		this.data[field] = value;
		this.data.emailValid = isEmailValid && value.length > 1;
	}

	handleEmailCheck(isValid) {
		const email = this.data.email || '';
		this.data.emailValid = isValid && email.length > 0;
	}

	rememberToggle() {
		this.data.rememberMe = !this.data.rememberMe;
	}

	forgotPassword() {
		const email = this.data.email || '';
		const isEmailValid = this.$f7.input.validateInputs('.email-address');
		this.data.emailValid = isEmailValid && email.length > 0;
		this.data.forgotPassword = true;
	}

	closePasswordReset() {
		this.data.forgotPassword = false;
	}

	async onSignIn() {
		ExpressAuthService.handleSignIn(this.data, this.$f7);
	}

	onSignup() {
		this.data.showSignup = true;
	}

	async sendPasswordResetEmail() {
		const email = this.data.email;
		if (!email) return;
		this.$f7.dialog.preloader();
		try {
			const fbResp = await firebase.auth().sendPasswordResetEmail(email);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('Please check your email to reset your password');
			this.closePasswordReset();
		} catch (err) {
			this.$f7.dialog.close();
			console.log('error sending password reset email: ', err);
			this.$f7.dialog.alert('Unable to complete password reset.  Please try again later');
		}
	}

	render() {
		let { email, password, forgotPassword, rememberMe, emailValid } = this.data;
		return (
			<Page className={`express-portal-login ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<img className="bg-stack" src={expressBG} />
				<img className="bg-illistration" src={expressIllistration} />
				<div className="title-ctn vbox hcenter">
					<h1>Express</h1>
					<div className="powered-by hbox vcenter">
						Powered By: <img className="logo" src={logoSVG} /> <span className="text-2">Care</span>
						<span className="text-4">Valet</span>
					</div>
				</div>
				{!this.data.showSignup && !this.data.showInvite && (
					<div className="signin-form vbox vcenter hcenter">
						<h2 className="text-4">Sign In</h2>
						{!expressStore.isLoggedIn && (
							<BasicInput
								validate
								label="Email"
								name="email"
								type="email"
								placeholder={'Your email'}
								value={email}
								onChange={this.onInputChange}
								onValidate={this.handleEmailCheck}
								className="email-address"
							/>
						)}
						{!expressStore.isLoggedIn && !forgotPassword && (
							<BasicInput
								label="Password"
								name="password"
								type="password"
								placeholder={'Your password'}
								value={password}
								onChange={this.onInputChange}
							/>
						)}
						<div className="actions hbox vcenter">
							<div className="remember-toggle hbox vcenter" onClick={this.rememberToggle}>
								<div className="toggle-indicator vbox vcenter hcenter">{rememberMe && <i className="fad fa-dot-circle"></i>}</div>
								<div className="text">Remember me</div>
							</div>
							<div className="w-100"></div>
							{!forgotPassword && (
								<div className="password-reset" onClick={this.forgotPassword}>
									Forgot Password?
								</div>
							)}
							{forgotPassword && (
								<div className="password-reset" onClick={this.closePasswordReset}>
									Return to sign in
								</div>
							)}
						</div>
						{!forgotPassword && (
							<Button fill large className="round-btn blue" disabled={!(emailValid && password.length > 0)} onClick={this.onSignIn}>
								Sign in <i className="far fa-long-arrow-right"></i>
							</Button>
						)}
						{forgotPassword && (
							<Button fill large className="round-btn blue" disabled={!emailValid} onClick={this.sendPasswordResetEmail}>
								{`Send Password Reset`} <i className="far fa-long-arrow-right"></i>
							</Button>
						)}
						{/* <div className="text-link hbox vcenter" onClick={this.onSignup}>
							<div className="text">Don't have an account?</div>
							<div className="cta">Sign up</div>
						</div> */}
						<p>
							This system contains Protected Health Information as defined under 45 CFR 160.103 ("Protected Information"). By logging
							onto this system, I acknowledge that I will use or disclose such protected information only for the purposes of
							performing services as a staff member, consultant or client of CareValet LLC and for such other purposes as expressly
							provided in 45 CFR 164.504(e)(4)
						</p>
					</div>
				)}
				{this.data.showSignup && (
					<ExpressSignupForm
						onBack={() => {
							this.data.showSignup = false;
						}}
					></ExpressSignupForm>
				)}
				{this.data.showInvite && (
					<ExpressInviteForm
						inviteCode={this.data.inviteCode}
						onBack={(completedSignup) => {
							window.location.hash = '#!'; //keeps the signup code from getting picked back up
							this.data.showSignup = false;
							this.data.showInvite = false;
							this.data.completedSignup = true;
						}}
					></ExpressInviteForm>
				)}
			</Page>
		);
	}
}
