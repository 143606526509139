import React, { PureComponent } from 'react';

export default class Stack2 extends PureComponent {
	render() {
		return (
			<svg viewBox="0 0 1030 410" width="1030" height="410" className="stack2SVG">
				{this.props.purple ? (
					<>
						<rect
							width="583.065"
							height="523.372"
							fill="#8CA5FF"
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 63.95)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint0_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 63.95)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill="#8CA5FF"
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 30.16)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint1_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 30.16)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint2_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 -4)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint2_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 -4)"
						></rect>
					</>
				) : (
					<>
						<rect
							width="583.065"
							height="523.372"
							fill="#4486FF"
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 63.95)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint0_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 63.95)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint1_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 63.95)"
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint2_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 30.16)"
							style={{ mixBlendMode: 'multiply' }}
						></rect>
						<rect
							width="583.065"
							height="523.372"
							fill={`url(#paint3_linear-${this.props.id})`}
							rx="24"
							transform="matrix(.9563 .29237 -.94513 .32669 481.656 -4)"
							style={{ mixBlendMode: 'multiply' }}
						></rect>
					</>
				)}
				{this.props.purple ? (
					<defs>
						<linearGradient
							id={`paint0_linear-${this.props.id}`}
							x1="586.929"
							x2="56.777"
							y1="417.247"
							y2="425.005"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#4839EA" stopOpacity="0.3"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint1_linear-${this.props.id}`}
							x1="-142.578"
							x2="560.526"
							y1="522.467"
							y2="513.211"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#fff" stopOpacity="0"></stop>
							<stop offset="1" stopColor="#4839EA" stopOpacity="0.5"></stop>
						</linearGradient>
						<linearGradient
							id={`paint2_linear-${this.props.id}`}
							x1="-451.11"
							x2="570.256"
							y1="535.981"
							y2="481.286"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#fff" stopOpacity="0"></stop>
							<stop offset="1" stopColor="#6363F2"></stop>
						</linearGradient>
					</defs>
				) : (
					<defs>
						<linearGradient
							id={`paint0_linear-${this.props.id}`}
							x1="586.929"
							x2="56.777"
							y1="417.247"
							y2="425.005"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#66FFA6" stopOpacity="0.3"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint1_linear-${this.props.id}`}
							x1="1003.54"
							x2="28.977"
							y1="901.555"
							y2="-47.709"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.141" stopColor="#71FFAC"></stop>
							<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint2_linear-${this.props.id}`}
							x1="586.929"
							x2="56.777"
							y1="417.247"
							y2="425.005"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
						<linearGradient
							id={`paint3_linear-${this.props.id}`}
							x1="586.929"
							x2="56.777"
							y1="417.247"
							y2="425.005"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#4486FF" stopOpacity="0.22"></stop>
							<stop offset="0.717" stopColor="#fff" stopOpacity="0"></stop>
						</linearGradient>
					</defs>
				)}
			</svg>
		);
	}
}
