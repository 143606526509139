import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link, List, ListItem, Popover, Popup, Preloader } from 'framework7-react';
import './document-list.scss';
import { format, formatDistance } from 'date-fns';
import { DOC_ICON } from '../document-constants';
import DocumentPreview from '../document-preview/DocumentPreview';
import expressStore from '@/stores/ExpressStore';
@observer
export default class DocumentList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			controlValues: {
				sort: 'date',
				search: '',
				type: 'all'
			},
			showPreview: false,
			previewDocument: null
		});
		autoBind(this);
	}

	handleClose() {
		this.props.onNewDocumentClose();
	}

	filterAndSortDocuments() {
		const { documents } = this.props;
		let { search, sort, type } = this.data.controlValues;
		search = search.toLowerCase();
		let filtered =
			search.length > 0
				? documents.filter((document) => {
						let name = document.name.toLowerCase();
						if (search.length === 1) {
							return name.search(search) === 0;
						} else {
							return name.search(search) > -1;
						}
				  })
				: documents;

		if (type !== 'all') {
			filtered = filtered.filter((document) => {
				return type === document.type;
			});
		}

		let sorted = filtered;
		switch (sort) {
			case 'date':
				sorted = filtered.sort((a, b) => {
					return new Date(b.lastUpdated) - new Date(a.lastUpdated);
				});
				break;
			case 'name':
				sorted = filtered.slice().sort((a, b) => {
					return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
				});
				break;
			case 'type':
				sorted = filtered.slice().sort((a, b) => {
					return a.type.toLowerCase() === 'sob' ? -1 : 1;
				});
				break;
			default:
				sorted = filtered;
		}

		return sorted;
	}

	handleSearch(e) {
		this.data.controlValues.search = e.target.value;
	}

	handlePreviewClose() {
		this.data.showPreview = false;
		this.data.previewDocument = null;
	}

	showDocumentPreview(document) {
		console.log(document);
		this.data.previewDocument = document;
		this.data.showPreview = true;
	}

	render() {
		const { documentsLoading, onDocumentClick, showNewDocument, onNewDocumentClick, onDocumentDelete } = this.props;
		const { showPreview, previewDocument } = this.data;
		const filteredDocuments = this.filterAndSortDocuments();
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;

		return (
			<div className="document-list">
				<div className="hbox vcenter">
					<h1 className="text-4 mb-xl grow-1">Documents</h1>
					{expressStore.currentOrgRole()?.hasOwnProperty('role') &&
					expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER ? null : (
						<Button fill small className="list-btn ios" onClick={onNewDocumentClick}>
							<i className="fa fa-plus" />
							Add Document
						</Button>
					)}
				</div>
				<DocumentControls
					onFilter={(val) => (this.data.controlValues.type = val)}
					onSort={(val) => (this.data.controlValues.sort = val)}
					onSearch={this.handleSearch}
					controlValues={this.data.controlValues}
				/>
				<DocumentListHeader />
				{documentsLoading && (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
				{filteredDocuments.map((document, key) => {
					return (
						<DocumentListItem
							onDocumentClick={() => onDocumentClick(document)}
							name={document.name}
							type={document.type}
							addedBy={document.createdBy}
							date={document.lastUpdated}
							fileData={document.fileData}
							documentId={document._id}
							onDelete={() => onDocumentDelete(document)}
							onPreview={() => {
								this.showDocumentPreview(document);
							}}
							canEdit={canEdit}
						/>
					);
				})}
				<DocumentPreview opened={showPreview} onClose={this.handlePreviewClose} document={previewDocument} />
			</div>
		);
	}
}

const DocumentControls = (props) => {
	const {
		onSort,
		onFilter,
		onSearch,
		controlValues: { sort, search, type }
	} = props;
	return (
		<div className="hbox vcenter list-controls">
			<div className="left-controls hbox vcenter grow-1">
				<a className="btn active">
					<i className="fa fa-grip-horizontal"></i>
				</a>
				<a className="btn">
					<i className="fa fa-bars"></i>
				</a>
				<div className="input-ctn hbox vcenter">
					<i className="fas fa-search"></i>
					<input placeholder="Search by name..." value={search} onChange={onSearch} />
				</div>
			</div>
			<div className="hbox vcenter right-controls">
				<Link popoverOpen=".document-type-popover" className="sort-btn">
					<div className="title">Type</div>
					<div className="selection hbox hcenter">
						<div className="selection-label grow-1">{type}</div>
						<i className="fa fa-angle-down"></i>
					</div>
				</Link>

				<Link popoverOpen=".sort-documents-popover" className="sort-btn">
					<div className="title">Sort</div>
					<div className="selection hbox hcenter">
						<div className="selection-label grow-1">By {sort}</div>
						<i className="fa fa-angle-down"></i>
					</div>
				</Link>
			</div>
			<Popover className="sort-documents-popover">
				<List className="tools-list">
					<ListItem popoverClose link="#" key="document-date-filter1" className="tool-item" onClick={() => onSort('date')}>
						<div className="description">Date</div>
					</ListItem>
					<ListItem popoverClose link="#" key="document-name-filter1" className="sort-item" onClick={() => onSort('name')}>
						<div className="description">Name</div>
					</ListItem>
					<ListItem popoverClose link="#" key="document-type-filter1" className="sort-item" onClick={() => onSort('type')}>
						<div className="description">Type</div>
					</ListItem>
				</List>
			</Popover>
			<Popover className="document-type-popover">
				<List className="tools-list">
					<ListItem popoverClose link="#" key="all_filter1" className="sort-item" onClick={() => onFilter('all')}>
						<div className="description">All</div>
					</ListItem>
					<ListItem popoverClose link="#" key="pdf-filter1" className="tool-item" onClick={() => onFilter('sob')}>
						<div className="description">SOB</div>
					</ListItem>
					<ListItem popoverClose link="#" key="doc-1" className="sort-item" onClick={() => onFilter('other')}>
						<div className="description">Other</div>
					</ListItem>
				</List>
			</Popover>
		</div>
	);
};

const DocumentListHeader = (props) => {
	return (
		<div className="table-list-header document-list-header hbox vcenter">
			<div className="field grow-1">Document Name</div>
			<div className="field grow-1">Type</div>
			<div className="field grow-1">Added by</div>
			<div className="field grow-1">Last modified</div>
			<div className="field"></div>
		</div>
	);
};

const DocumentListItem = (props) => {
	const { documentId, name, type, addedBy, date, fileData, onDocumentClick, onDelete, onPreview, canEdit } = props;
	const dateFormatted = date ? format(new Date(date), 'MM-dd-yy hh:mm a') : '';

	return (
		<div
			onClick={(e) => {
				if (e.target?.classList?.contains('action')) {
					return;
				}
				onDocumentClick();
			}}
			className="table-list-item members-list-item hbox vcenter"
			key={`${documentId}`}
		>
			<div className="field grow-1">
				<i className={`fa ${DOC_ICON[fileData.fileType]}`}></i>
				{name}
			</div>
			<div className="field grow-1">{type}</div>
			<div className="field grow-1">{_.startCase(addedBy)}</div>
			<div className="field grow-1">{dateFormatted} </div>
			<div className="field">
				<a className="btn action preview" onClick={onPreview}>
					<i className="fad fa-eye action"></i>
				</a>
				{canEdit && (
					<a className="btn action trash" onClick={onDelete}>
						<i className="fad fa-trash action"></i>
					</a>
				)}
			</div>
		</div>
	);
};
