import React, { Component } from 'react';
import { Chip } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import _ from 'lodash';
import doctorMale from '@/assets/doctor-male.svg';
import doctorFemale from '@/assets/doctor-female.svg';
import NetworkChip from '@/components/network-chip/NetworkChip';
import './provider-list-item.scss';

@observer
export default class ProviderListItem extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.text = getCmpText('FindCare', {
			careTeamText: 'Care Team',
			pcpText: 'Primary Care Provider'
		});
		autoBind(this);
	}

	render() {
		const provider = this.props.provider;
		return (
			<div onClick={this.props.onClick} className="provider-list-item">
				<div className="hbox vcenter">
					<div className="vbox hcenter m-r-16">
						<div className="image-ctn vbox hcenter">
							{this.props.image && <img src={this.props.gender === 'm' ? doctorMale : doctorFemale} />}
							{this.props.icon && (
								<div className="organization">
									<i className={this.props.icon}></i>
								</div>
							)}
						</div>
					</div>
					<div className="details grow-1">
						<div className="chips">
							<NetworkChip
								provider={provider}
								color={this.props.chipColor}
								text={this.props.chipText}
								icon={this.props.chipIcon}
								suppress={this.props.hideChip}
							/>
							{this.props.isCareTeam && (
								<Chip text={this.text.careTeamText} mediaBgColor="purple" className="ios">
									<i slot="media" className={`fad fa-heart`}></i>
								</Chip>
							)}
							{this.props.isPcp && (
								<Chip text={this.text.pcpText} mediaBgColor="blue" className="ios">
									<i slot="media" className={`fad fa-parking-circle`}></i>
								</Chip>
							)}
						</div>
						<div className="provider-name">{this.props.name}</div>
						<div className="specialty">{this.props.specialties}</div>
					</div>
				</div>
			</div>
		);
	}
}
