import { autoBind } from '@/utils/GeneralUtils';
import { observable, observer } from '@/utils/State';
import { format } from 'date-fns';
import { Preloader } from 'framework7-react';
import React, { Component } from 'react';

@observer
export default class IdList extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}
	render() {
		const { idsLoading, onEdit, onDelete, cards } = this.props;

		return (
			<div className="id-list">
				<DocumentListHeader />
				{idsLoading && (
					<div className="hbox vcenter hcenter w-100">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
				{cards.map((card, key) => {
					console.log(card);
					return (
						<IdListItem
							onClick={() => onEdit(card)}
							name={card.name}
							side={card.side}
							addedBy={card.createdBy ? card.createdBy : card.lastUpdatedBy}
							date={card.lastUpdated}
							fileData={card.fileData}
							documentId={card._id}
							onDelete={(evt) => {
								evt.stopPropagation();
								onDelete(card);
							}}
						/>
					);
				})}
			</div>
		);
	}
}

const DocumentListHeader = (props) => {
	return (
		<div className="table-list-header document-list-header hbox vcenter">
			<div className="field grow-1">Name</div>
			<div className="field grow-1">Side</div>
			<div className="field grow-1">Added by</div>
			<div className="field grow-1">Last modified</div>
			<div className="field"></div>
		</div>
	);
};

const IdListItem = (props) => {
	const { documentId, name, side, addedBy, date, fileData, onClick, onDelete, onPreview } = props;
	const dateFormatted = date ? format(new Date(date), 'MM-dd-yy hh:mm a') : '';
	return (
		<div
			onClick={(e) => {
				if (e.target?.classList?.contains('action')) {
					return;
				}
				onClick();
			}}
			className="table-list-item members-list-item hbox vcenter"
			key={`${documentId}`}
		>
			<div className="field grow-1">{name}</div>
			<div className="field grow-1">{side}</div>
			<div className="field grow-1">{addedBy}</div>
			<div className="field grow-1">{dateFormatted} </div>
			<div className="field">
				<a className="btn action trash" onClick={onDelete}>
					<i className="fad fa-trash action"></i>
				</a>
			</div>
		</div>
	);
};
