import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './file-drop.scss';
@observer
export default class FileDrop extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			files: [],
			error: null,
			dragEnter: false
		});
		this.dropArea = React.createRef();
		this.fileRef = React.createRef();
		autoBind(this);
	}

	dragOver(e) {
		e.preventDefault();
	}

	dragEnter(e) {
		e.preventDefault();
		this.data.dragEnter = true;
	}

	dragLeave(e) {
		e.preventDefault();
		this.data.dragEnter = false;
	}

	drop(e) {
		e.preventDefault();
		const files = e.dataTransfer.files;
		this.handleChangeFile(files);
		this.data.dragEnter = false;
	}

	handleMulti(files) {
		const { onError } = this.props;
		let invalid = files.filter((f) => !this.valid(f));
		if (invalid && invalid.length > 0 && onError) {
			onError({ error: 'INVALID_TYPE', files: invalid });
		} else {
			this.data.files = files;
		}
	}

	valid(file) {
		const { accept } = this.props;
		const fileTypesArr = accept.replaceAll(' ', '').replaceAll(',','|');
		return file.type.match(fileTypesArr);
	}

	handleDone() {
		const { multi, onComplete } = this.props;
		if (multi) {
			return this.data.files;
		} else {
			return this.data.files[0];
		}
	}

	handleFileClick(e) {
		this.fileRef.current.click();
	}

	handleChangeFile(files) {
		const { multi, onError, onComplete } = this.props;
		if (files.length >= 0 && multi) {
			this.handleMulti(files);
		} else if (files.length > 1 && !multi) {
			if (onError) {
				onError({ error: 'EXCEED_LIMIT' });
			}
		} else if (!this.valid(files[0])) {
			onError({ error: 'INVALID_TYPE', files: files[0] });
		} else {
			if (onComplete) {
				onComplete(files[0]);
			}
		}
	}

	render() {
		const { accept } = this.props;
		return (
			<div className="file-drop" onClick={this.handleFileClick}>
				<div
					className={`drop-area vbox vcenter hcenter ${this.data.dragEnter ? 'drag-enter' : ''}`}
					onDragOver={this.dragOver}
					onDragEnter={this.dragEnter}
					onDragLeave={this.dragLeave}
					onDrop={this.drop}
				>
					<i className="fad fa-upload"></i>
					<div className="drop-message">Click or drop files here</div>
				</div>
				<input
					type="file"
					accept={accept}
					style={{ display: 'none' }}
					ref={this.fileRef}
					onChange={(e) => this.handleChangeFile(e.target.files)}
				/>
			</div>
		);
	}
}
