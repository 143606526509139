import React, { Component } from 'react';
import { observer } from '@/utils/State';
import './billing-home.scss';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import CardCarousel from '../../card-carousel/CardCarousel';
import { getCmpText } from '@/utils/Localization';
import TransactionHistory from '../transaction-history/TransactionHistory';
import { Preloader } from 'framework7-react';
import UserBillingService from '@/services/UserBillingService';

@observer
export default class BillingHome extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
		this.text = getCmpText('BillingHome', {
			cancelBtnTxt: 'Close',
			billingHeaderTxt: 'Billing Info',
			cardSectionHeaderTxt: 'Cards',
			addCardTxt: '+ Add Credit Card',
			noPaymentsTxt: 'No Payment Methods',
			deletingCardTxt: 'Deleting Card',
			transactionHistoryTxt: 'Transaction History',
			deleteErrorTxt: 'An error occurred while attempting to delete the credit card'
		});
	}

	handleTransactionSelect(transaction) {
		this.props.onTransactionSelect(transaction);
	}

	async handleDelete(paymentMethodId) {
		this.$f7.dialog.confirm(
			'Are you sure you wish to delete this card?',
			async () => {
				this.$f7.dialog.preloader(this.text.deletingCardTxt);
				try {
					await UserBillingService.deletePaymentMethod(paymentMethodId);
					this.$f7.dialog.close();
				} catch (e) {
					this.$f7.dialog.close();
					this.$f7.dialog.alert(this.text.deleteErrorTxt);
				}
			},
			async (e) => {
				console.log('canceled');
			}
		);
	}

	renderCardCarousel() {
		let { paymentMethods, paymentMethodsLoading } = this.props;
		if (paymentMethodsLoading) {
			return <Preloader></Preloader>;
		} else if (paymentMethods && paymentMethods.length < 1) {
			return (
				<div>
					<h2>{this.text.noPaymentsTxt}</h2>
				</div>
			);
		} else {
			return <CardCarousel paymentMethods={paymentMethods} onDelete={this.handleDelete} />;
		}
	}

	render() {
		let { onCancel, showAddCard, paymentMethods, transactionHistory, transactionHistoryLoading, paymentMethodsLoading } = this.props;
		let { cancelBtnTxt, billingHeaderTxt, cardSectionHeaderTxt, addCardTxt, transactionHistoryTxt } = this.text;
		return (
			<div>
				<div className="page-wrapper">
					<div className="global-header-popup">
						<div className="header-ctn">
							<div className="back hbox vcenter animated fadeInLeft" onClick={onCancel}>
								{cancelBtnTxt}
							</div>
							<h1>{billingHeaderTxt}</h1>
						</div>
					</div>
					<div className="section-header">
						<div className="title">{cardSectionHeaderTxt}</div>
						<div className="action" onClick={showAddCard}>
							{addCardTxt}
						</div>
					</div>
					<div className="payment-methods-container">
						{this.renderCardCarousel()}
						{/* {paymentMethodsLoading && <Preloader></Preloader>}
						{paymentMethods && paymentMethods.length > 0 && <CardCarousel paymentMethods={paymentMethods} onDelete={this.handleDelete}/>} */}
					</div>
					<div className="section-header">
						<div className="title">{transactionHistoryTxt}</div>
					</div>
					<div>
						{transactionHistoryLoading && <Preloader></Preloader>}
						<TransactionHistory transactionHistory={transactionHistory} onTransactionSelect={this.handleTransactionSelect} />
					</div>
				</div>
			</div>
		);
	}
}
