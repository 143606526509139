import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link, Popup } from 'framework7-react';
import './form-popup.scss';
import ExpressFormBuilder from '../express-form-builder/ExpressFormBuilder';

@observer
export default class FormPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ formValid: false });
		autoBind(this);
	}

	closeLinkPopup() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	render() {
		let {
			title,
			opened,
			buttonText,
			formData,
			formConfig,
			onSubmit,
			customValidator,
			afterChange,
			additionalContent,
			fullScreen
		} = this.props;
		return (
			<Popup
				className={`express-form-popup ${fullScreen ? 'fullscreen' : ''}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				tabletFullscreen={fullScreen}
			>
				<div className="popup-header">
					<div className="title grow-1">{title}</div>
					<Link popupClose>
						<i className="fad fa-times-square"></i>
					</Link>
				</div>
				<div className="main-content y-scroll">
					{opened && (
						<ExpressFormBuilder
							formData={formData}
							formConfig={formConfig}
							setValidationState={(valid) => {
								if (customValidator) {
									this.data.formValid = customValidator(valid);
								} else {
									this.data.formValid = valid;
								}
							}}
							afterChange={afterChange}
						></ExpressFormBuilder>
					)}
					{additionalContent}
				</div>
				<div className="btn-ctn">
					<Button
						fill
						large
						className="round-btn"
						disabled={!this.data.formValid}
						onClick={onSubmit}
					>
						{buttonText}
					</Button>
				</div>
			</Popup>
		);
	}
}
