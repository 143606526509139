import Framework7 from 'framework7/framework7.esm.bundle.js';
import userStore from '../stores/UserStore';
import _ from 'lodash';
import appStore from '../stores/AppStore';
import expressStore from '@/stores/ExpressStore';

const getBaseURL = () => {
	const env = process.env.NODE_ENV;
	const isDev = env === 'development';
	let base = isDev
		? 'http://localhost:3000/api'
		: 'https://carevalet.cloud/api'; // setup initial fall back in case all others fail
	if (appStore.isApp()) {
		//installed apps have no location.host value
		base = isDev
			? 'https://api.carevalet.dev/api'
			: 'https://carevalet.cloud/api';
		return base;
	}
	const API_ENVS = {
		'carevalet-development.web.app': 'https://api.carevalet.dev/api',
		'bs-local.com:8080': 'http://bs-local.com:3000/api',
		'carevalet.io': 'https://carevalet.cloud/api',
		'carevalet.doctor': 'https://carevalet.cloud/api',
		'carevalet.express': 'https://carevalet.cloud/api',
		'dev.carevalet.app': 'https://api.carevalet.dev/api',
		'localhost:8080': 'http://localhost:3000/api',
		'0.0.0.0:8080': 'http://0.0.0.0:3000/api',
		'cvdemos.web.app': 'https://carevalet.dev/api',
		'doctyrs.tech': 'https://api.carevalet.dev/api'
	};
	const checkForSpecialRouting = API_ENVS[location.host];
	if (location.port === '' && !API_ENVS[location.host]) {
		return 'http://' + location.host + '/api';
	}
	return checkForSpecialRouting || base;
};
class ExpressAPI {
	getBaseUrl() {
		return getBaseURL();
	}

	getHeaders() {
		const headers = {
			version: _.get(userStore, 'frontendVersion', '')
		};
		const userToken = _.get(expressStore, 'authToken');
		if (userToken) {
			headers.authorization = userToken;
		}
		return headers;
	}

	get(url, noToken, noBase) {
		try {
			return new Promise((resolve, reject) => {
				Framework7.request({
					method: 'GET',
					url: noBase ? url : getBaseURL() + url,
					crossDomain: true,
					headers: this.getHeaders(),
					success: function (res) {
						try {
							resolve(JSON.parse(res));
						} catch {
							resolve(res);
						}
					},
					error: (xhr) => {
						this.handleError(xhr);
						reject(xhr);
					}
				});
			});
		} catch {
			return Promise.reject('Unable to access API');
		}
	}

	post(url, data, noToken, noBase, contentType) {
		return new Promise((resolve, reject) => {
			Framework7.request({
				method: 'POST',
				contentType: contentType ? contentType : 'application/json',
				url: noBase ? url : getBaseURL() + url,
				data: data,
				crossDomain: true,
				headers: this.getHeaders(),
				success: function (res) {
					try {
						resolve(JSON.parse(res));
					} catch {
						resolve(res);
					}
				},
				error: (xhr) => {
					this.handleError(xhr);
					reject(xhr);
				}
			});
		});
	}

	patch(url, data, noToken, noBase, contentType) {
		return new Promise((resolve, reject) => {
			Framework7.request({
				method: 'PATCH',
				contentType: contentType ? contentType : 'application/json',
				url: noBase ? url : getBaseURL() + url,
				data: data,
				crossDomain: true,
				headers: this.getHeaders(),
				success: function (res) {
					try {
						resolve(JSON.parse(res));
					} catch {
						resolve(res);
					}
				},
				error: (xhr) => {
					this.handleError(xhr);
					reject(xhr);
				}
			});
		});
	}

	delete(url, data, noToken, noBase, contentType) {
		return new Promise((resolve, reject) => {
			Framework7.request({
				method: 'DELETE',
				contentType: contentType ? contentType : 'application/json',
				url: noBase ? url : getBaseURL() + url,
				data: data,
				crossDomain: true,
				headers: this.getHeaders(),
				success: function (res) {
					try {
						resolve(JSON.parse(res));
					} catch {
						resolve(res);
					}
				},
				error: (xhr) => {
					this.handleError(xhr);
					reject(xhr);
				}
			});
		});
	}

	//TODO eval if we should signout a user or not on these errors
	handleError(xhr) {
		const errorCode = Number(_.get(xhr, 'status', 0));
		console.log('Handling API Error: ', errorCode);
		switch (errorCode) {
			case 401:
				firebase.auth().signOut();
				console.log('Logging out user due to 401');
				break;
			case 403:
				firebase.auth().signOut();
				console.log('Logging out user due to 403');
				break;
			case 426:
				appStore.forceAppUpdate = true;
				break;
			default:
				break;
		}
		return xhr;
	}

	//======EXPRESS API START=========================================================================================

	getExpressOrgUrl(route) {
		return `/express/organization/${expressStore.currentOrg._id}${route}`;
	}

	async login() {
		return this.get('/express/user/login');
	}

	async setupCompleted() {
		return this.get('/express/user/setupCompleted');
	}

	async validateInvite(invite) {
		return this.post('/express/user/validateInvite', invite);
	}

	async acceptInvite(userDetails) {
		userDetails.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return this.post('/express/user/acceptInvite', userDetails);
	}

	async createAccount(userDetails) {
		userDetails.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return this.post('/express/user/createAccount', userDetails);
	}

	async updateUser(userDetails) {
		return this.post('/express/user/updateUser', userDetails);
	}

	async inviteUser(invite) {
		return this.post(
			`/express/user/${expressStore.currentOrg._id}/inviteUser`,
			invite
		);
	}

	async updateUserRole(userId, role) {
		return this.post(
			`/express/user/${expressStore.currentOrg._id}/${userId}/updateRole`,
			role
		);
	}

	async deleteUser(userId) {
		return this.delete(
			`/express/user/${expressStore.currentOrg._id}/${userId}`
		);
	}

	async getAllUsers() {
		return this.get(this.getExpressOrgUrl('/users'));
	}

	async getOrgs() {
		return this.get('/express/user/organizations');
	}

	async getOrgItemStatus(type, itemId) {
		return this.get(this.getExpressOrgUrl(`/status/${type}/${itemId}`));
	}

	async getOrganizationById() {
		return this.get(this.getExpressOrgUrl(``));
	}

	async updateOrgSettings(data) {
		return this.post(this.getExpressOrgUrl('/update-settings'), data);
	}

	async updateOrg(data) {
		return this.post(this.getExpressOrgUrl('/update'), data);
	}

	async deleteOrg(orgId) {
		return this.get(`/express/organization/${orgId}/delete`);
	}

	async getOrgFtpInfo() {
		return this.get(this.getExpressOrgUrl('/ftp/info'));
	}

	async updateOrgFtpInfo(data) {
		return this.post(this.getExpressOrgUrl('/ftp/info'), data);
	}

	async createOrg(data) {
		return this.post('/express/organization/create', data);
	}

	async getPublishingData() {
		return this.get(this.getExpressOrgUrl('/publishing-data'));
	}

	async publishOrg() {
		return this.get(this.getExpressOrgUrl('/publish'));
	}

	async getGroups() {
		return this.get(this.getExpressOrgUrl('/groups'));
	}

	async getGroupById(id) {
		return this.get(this.getExpressOrgUrl(`/groups/${id}`));
	}

	async saveGroup(groupDetails) {
		return this.post(this.getExpressOrgUrl('/groups/save'), groupDetails);
	}

	async getGroupMembers(groupId, projection) {
		return this.post(
			this.getExpressOrgUrl(`/groups/${groupId}/members`),
			projection
		);
	}

	async savePrintingGroups(payload) {
		return this.post(this.getExpressOrgUrl('/groups/printing/save'), payload);
	}

	async getFulfillments() {
		return this.get(this.getExpressOrgUrl(`/groups/printing/fulfillment`));
	}

	async cancelFulfillment(id) {
		return this.delete(
			this.getExpressOrgUrl(`/groups/printing/fulfillment/${id}`)
		);
	}

	async getShipmentHistory() {
		return this.get(this.getExpressOrgUrl(`/groups/printing/history`));
	}

	// /:orgId/groups/printing/history/cardImage/:batchId/:idCardFront/:idCardBack
	async getIdCardPrinted(batchId, idCardFront, idCardBack) {
		return this.get(
			this.getExpressOrgUrl(
				`/groups/printing/history/cardImage/${batchId}/${idCardFront}/${idCardBack}`
			)
		);
	}

	async getIDCardsForPlans(planIds) {
		return this.post(
			this.getExpressOrgUrl('/groups/printing/plancards'),
			planIds
		);
	}

	async submitIDCardOrder(orderDetails) {
		return this.post(
			this.getExpressOrgUrl('/groups/printing/order'),
			orderDetails
		);
	}

	async getPlans(internalGroupId) {
		return this.get(this.getExpressOrgUrl(`/groups/${internalGroupId}/plans`));
	}

	async getAllPlans() {
		return this.get(this.getExpressOrgUrl(`/plans`));
	}

	async savePlan(planDetails) {
		return this.post(this.getExpressOrgUrl('/plans/save'), planDetails);
	}

	async getIdCardCustomFormData(cardFront, cardBack) {
		return this.get(
			this.getExpressOrgUrl(`/plans/form/custom-data/${cardFront}/${cardBack}`)
		);
	}

	async savePlanPart(part, data) {
		return this.post(this.getExpressOrgUrl('/plans/savepart'), {
			type: part,
			data
		});
	}

	async saveGroupPlan(groupId, planDetails) {
		return this.post(
			this.getExpressOrgUrl(`/groups/${groupId}/plans/save`),
			planDetails
		);
	}

	async linkPlanToGroup(groupId, planId) {
		return this.get(this.getExpressOrgUrl(`/groups/${groupId}/${planId}/link`));
	}

	async unlinkPlanFromGroup(groupId, planId) {
		return this.get(
			this.getExpressOrgUrl(`/groups/${groupId}/${planId}/unlink`)
		);
	}

	async deletePlan(planId) {
		return this.get(this.getExpressOrgUrl(`/plans/${planId}/delete`));
	}

	async getAllNetworks() {
		return this.get('/express/admin/networks');
	}

	async getAllAdminPlans() {
		return this.get('/express/admin/plans');
	}

	async getAllAdminGroups() {
		return this.get('/express/admin/groups');
	}

	async getAllAdminFulfillments() {
		return this.get(`/express/admin/fulfillments`);
	}

	async updateFulfillmentShipDate(val, batch, org) {
		return this.post(`/express/organization/${org}/fulfillment/shipping`, {
			date: val,
			batch: batch,
			org: org
		});
	}

	async getGroupStatus(id) {
		return this.get(`/express/admin/group/status/${id}`);
	}

	async getPlanStatus(id) {
		return this.get(`/express/admin/plan/status/${id}`);
	}

	async getFeatureFlags() {
		return this.get(`/express/admin/feature-flags`);
	}

	async toggleFeatureFlag(id, data) {
		return this.patch(`/express/admin/feature-flags/${id}`, data);
	}

	async deleteFeatureFlag(id) {
		return this.delete(`/express/admin/feature-flags/${id}`);
	}

	async addFeatureFlag(data) {
		return this.post(`/express/admin/feature-flags/`, data);
	}

	async getNetworks() {
		return this.get(this.getExpressOrgUrl('/networks'));
	}

	//Key Value of NetworkID -> Network Name
	async getAvailableNetworks() {
		return this.get(this.getExpressOrgUrl('/availablenetworks'));
	}

	async getNetworkById(networkId) {
		return this.get(this.getExpressOrgUrl(`/networks/${networkId}`));
	}

	async saveNetwork(networkDetails) {
		return this.post(this.getExpressOrgUrl('/networks/save'), networkDetails);
	}

	async getMembers(query) {
		return this.post(this.getExpressOrgUrl('/members'), query);
	}

	async createMember(memberData) {
		return this.post(this.getExpressOrgUrl('/members/create'), memberData);
	}

	async saveMember(memberData) {
		return this.post(this.getExpressOrgUrl('/members/save'), memberData);
	}

	async getDependents(memberData) {
		return this.post(this.getExpressOrgUrl('/members/dependents'), memberData);
	}

	async getIDCardMetadata() {
		return this.get(this.getExpressOrgUrl('/idcardmetadata'));
	}

	async getMetrics() {
		return this.get(this.getExpressOrgUrl('/metrics'));
	}

	async saveOrgCustomFieldsFormConfig(orgId, formValues) {
		return this.post(
			`/express/organization/${orgId}/config/customFields`,
			formValues
		);
	}

	async deleteOrgCustomFieldsFormConfig(orgId, customField) {
		return this.delete(
			`/express/organization/${orgId}/config/customFields/${customField}`
		);
	}

	async getConfig(type) {
		const configType = type ? type : appStore.demoMode;
		const currentGroup = _.get(expressStore, 'activeGroup._id') || 'all';
		const currentPlan = _.get(expressStore, 'activePlan._id') || 'all';
		const configData = await this.get(
			this.getExpressOrgUrl(`/config/${currentGroup}/${currentPlan}`)
		);
		if (configData.plan && configType === 'plan') {
			userStore.userData.group = configData.plan;
		}
		return configData.config;
	}

	async getConfigForms() {
		return this.get(
			this.getExpressOrgUrl(`/config-forms/${appStore.demoMode}`)
		);
	}

	async saveConfig(formData) {
		const currentGroup = _.get(expressStore, 'activeroup._id') || 'all';
		const currentPlan = _.get(expressStore, 'activePlan._id') || 'all';
		return this.post(
			this.getExpressOrgUrl(`/config/${currentGroup}/${currentPlan}`),
			formData,
			'multipart/form-data'
		);
	}

	async getReportList(selectedFilters) {
		return this.post(this.getExpressOrgUrl('/reports'), selectedFilters);
	}

	async getReportData(reportId, selectedFilters) {
		return this.post(
			this.getExpressOrgUrl(`/report/${reportId}`),
			selectedFilters
		);
	}

	//=====EXPRESS MEMBER IMPORT =====/

	async processValidMembers(members) {
		return this.post(this.getExpressOrgUrl('/members/process'), members);
	}

	async processExcelFile(data) {
		return this.post(
			this.getExpressOrgUrl('/members/excelImport'),
			data,
			'multipart/form-data'
		);
	}

	async getMemberImports() {
		return this.get(this.getExpressOrgUrl('/members/imports'));
	}

	async getImportById(importId) {
		return this.get(this.getExpressOrgUrl(`/members/imports/${importId}`));
	}

	async deleteFileImport(importId) {
		return this.delete(this.getExpressOrgUrl(`/members/imports/${importId}`));
	}

	async publishEligibilityFile(importId) {
		return this.get(
			this.getExpressOrgUrl(`/members/imports/${importId}/publish`)
		);
	}

	async generateEDIFileElements(data) {
		return this.post(
			this.getExpressOrgUrl('/edi/generateEDIFileElements'),
			data,
			'multipart/form-data'
		);
	}

	async getDefaultEDIConfig() {
		return this.get(this.getExpressOrgUrl(`/edi/default`));
	}

	async deleteEDIConfig(id) {
		return this.delete(this.getExpressOrgUrl(`/edi/EDIFileConfig/${id}`));
	}

	async saveEDIConfig(config) {
		return this.post(this.getExpressOrgUrl(`/edi/EDIFileConfig`), config);
	}

	async parseEDIFileToMembers(config, formData) {
		return this.post(
			this.getExpressOrgUrl(`/edi/parseEDIFileToMembers/${config._id}`),
			formData,
			'multipart/form-data'
		);
	}

	//=====EXPRESS ID CARDS =====/

	async saveIdCard(data) {
		return this.post(
			`/express/idcards/${expressStore.currentOrg._id}/save`,
			data,
			'multipart/form-data'
		);
	}

	async updateIdCard(data, id) {
		return this.post(
			`/express/idcards/${expressStore.currentOrg._id}/card/${id}`,
			data,
			'multipart/form-data'
		);
	}

	async listIdCards(type, withThumbs) {
		let query = type ? `?type=${type}` : '';
		query += withThumbs ? `&withThumbs=${withThumbs}` : '';
		return await this.get(
			`/express/idcards/${expressStore.currentOrg._id}${query}`
		);
	}

	async getIdCard(id, withThumb) {
		console.log(id);
		let query = withThumb ? `?withThumb=${withThumb}` : '';
		return await this.get(
			`/express/idcards/${expressStore.currentOrg._id}/card/${id}${query}`
		);
	}

	async deleteIdCard(id, forceDelete) {
		return await this.delete(
			`/express/idcards/${expressStore.currentOrg._id}/card/${id}?forceDelete=${forceDelete}`
		);
	}

	async getIdCardFields(id) {
		return await this.get(
			`/express/idcards/${expressStore.currentOrg._id}/card/${id}/template-fields`
		);
	}

	async getIdThumbnail(documentId, size) {
		return await this.get(
			`/express/idcards/${
				expressStore.currentOrg._id
			}/thumbnail?documentId=${documentId}${size ? '&size=' + size : ''}`
		);
	}

	async getDocumentAsset(documentName, fileName) {
		return await this.get(
			`/express/idcards/${expressStore.currentOrg._id}/asset?documentName=${documentName}&fileName=${fileName}`
		);
	}

	async cardExists(cardName) {
		return await this.get(
			`/express/idcards/${expressStore.currentOrg._id}/card/exists?cardName=${cardName}`
		);
	}

	//=====EXPRESS CANVAS EDITOR SERVICE =====/
	async getTemplates(queryParams) {
		queryParams.orgId = expressStore.currentOrg._id;
		if (queryParams) queryParams = new URLSearchParams(queryParams);
		return await this.get(
			`/express/canvas-editor/templates${
				queryParams ? `?${queryParams.toString()}` : ''
			}`
		);
	}

	async saveTemplate(formData) {
		return await this.post(
			`/express/canvas-editor/templates`,
			formData,
			'multipart-form-data'
		);
	}

	async updateTemplate(formData, id) {
		return await this.post(
			`/express/canvas-editor/templates/${id}`,
			formData,
			'multipart-form-data'
		);
	}

	async getTemplate(templateId) {
		return await this.get(`/express/canvas-editor/templates/${templateId}`);
	}

	async getCanvasAsset(key) {
		return await this.get(`/express/canvas-editor/asset/${btoa(key)}`);
	}

	async deleteTemplate(templateId) {
		return await this.delete(`/express/canvas-editor/templates/${templateId}`);
	}

	//====EXPRESS DOCUMENTS ====/
	async uploadDoucment(data) {
		return await this.post(
			this.getExpressOrgUrl('/documents'),
			data,
			'multipart/form-data'
		);
	}

	async getDocuments() {
		return await this.get(this.getExpressOrgUrl('/documents'));
	}

	async getDocument(documentId) {
		return await this.get(
			this.getExpressOrgUrl(`/documents/${documentId}?withFile=true`)
		);
	}

	async deleteDocument(documentId, forceDelete) {
		return await this.delete(
			this.getExpressOrgUrl(
				`/documents/${documentId}?forceDelete=${forceDelete}`
			)
		);
	}

	async getPlanDocuments(planId) {
		return this.get(this.getExpressOrgUrl(`/plans/${planId}/documents`));
	}

	async getAvailableDocuments(planId) {
		return this.get(
			this.getExpressOrgUrl(`/plans/${planId}/documents/available`)
		);
	}
	async linkDocumentToPlan(planId, documentId) {
		return this.post(
			this.getExpressOrgUrl(`/plans/${planId}/documents/${documentId}`)
		);
	}

	async unlinkDocument(planId, documentId) {
		return this.delete(
			this.getExpressOrgUrl(`/plans/${planId}/documents/${documentId}`)
		);
	}

	async manualUploadTeladoc() {
		return this.post('/express/scheduler/upload/teladoc');
	}
	async manualUploadRecuro() {
		return this.post('/express/scheduler/upload/recuro');
	}

	//=====EXPRESS DOCUMENTS END ====/

	//======EXPRESS API END=========================================================================================
}

export default new ExpressAPI();
