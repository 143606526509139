import React from 'react';
import { Preloader } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import './demo-app-shell.scss';
import appStore from '@/stores/AppStore';
import userStore from '@/stores/UserStore';
import expressStore from '@/stores/ExpressStore';
import ProviderProfile from '@/components/provider-profile/ProviderProfile';
import ConfigService from '@/services/ConfigService';
import _ from 'lodash';
import CloudAPI from '@/services/CloudAPI';
import AnalyticsService from '@/services/AnalyticsService';
import ApplicationShell from '@/pages/application-shell/ApplicationShell';
import MockAPI from './mock-data';
import ExternalServicesMenu from '@/components/external-services-menu/ExternalServicesMenu';
const originalConfigService = _.cloneDeep(ConfigService);
@observer
class DemoAppShell extends ApplicationShell {
	constructor(props) {
		super(props);
		this.data = observable({ demoStatus: 'loading', test: false });
		this.text = getCmpText('ApplicationShell', {
			setLocation: 'Set Location',
			home: 'Home',
			doctors: 'Find Care',
			appointments: 'Appointment',
			myInfo: 'Account',
			adminText: 'Admin',
			prescriptionText: 'Prescriptions'
		});
		autoBind(this);
	}
	async componentDidMount() {
		console.log('===== APPLICATION RUNNING IN DEMO MODE ======');
		appStore.isMobile = true;
		userStore.isLoggedIn = true;
		appStore.demoMode = _.cloneDeep(this.props.type);
		this.demoAppMockCloudAPI(true);
		this.demoAppMockRouter(true);
		this.demoAppMockAnalytics(true);
		this.interceptPopups(true);
		await this.demoAppSetInitialConfig();
		this.demoAppMockConfigService(true);
		this.data.demoStatus = 'ready';
		if (this.props.handleDemoRender) {
			this.props.handleDemoRender();
		}
	}

	async componentWillUnmount() {
		console.log('app unmounting!');
		console.log('===== STOPPED RUNNING IN DEMO MODE ======');
		appStore.isMobile = false;
		userStore.isLoggedIn = false;
		appStore.demoMode = null;
		appStore.activeTab = 'home';
		appStore.openProviderProfile = false;
		this.interceptPopups(false);
		this.demoAppMockCloudAPI(false);
		this.demoAppMockRouter(false);
		this.demoAppMockAnalytics(false);
		this.demoAppMockConfigService(false);
		await originalConfigService.resetConfig();
	}

	interceptPopups(intercept) {
		if (intercept) {
			this.$f7.on('popupOpen popoverOpen actionsOpen sheetOpen', this.checkPopupAvailability);
		} else {
			this.$f7.off('popupOpen popoverOpen actionsOpen sheetOpen', this.checkPopupAvailability);
		}
	}

	checkPopupAvailability(popupInstance) {
		const popupClasses = popupInstance.el.className;
		const disabledPopups = _.get(this.props, 'demoConfig.disablePopups') || [];
		const unrelatedPopups = _.get(this.props, 'demoConfig.ignorePopups') || [];
		const disabledCheck = disabledPopups.map((x) => ({ ...x, hasClass: popupClasses.indexOf(x.id) > -1 })).filter((x) => !!x.hasClass);
		const isDisabled = disabledCheck.length > 0 || popupInstance.type === 'popover';
		const unrelatedCheck = unrelatedPopups.map((x) => popupClasses.indexOf(x) > -1).filter((x) => !!x);
		const isUnrelated = unrelatedCheck.length > 0;
		if (isUnrelated) return;
		const previewElement = document.getElementById('preview-container');
		if (isDisabled) {
			popupInstance.off('open');
			popupInstance.close(false);
			this.$f7.dialog.preloader();
			const message = _.get(disabledCheck, '[0].message') || 'This feature is disabled in config editor mode';
			const withDelay = disabledCheck.filter((x) => x.hasDelay);
			if (withDelay && withDelay.length > 0) {
				setTimeout(() => {
					// using time out in case there is other loading taking place with the popup on open
					this.$f7.dialog.close();
					this.$f7.dialog.alert(message);
				}, 300);
			} else {
				this.$f7.dialog.close();
				this.$f7.dialog.alert(message);
			}
		}
		if (previewElement) {
			const html = document.getElementsByTagName('html');
			const currentClasses = html[0].className;
			const newClasses = currentClasses
				.split(' ')
				.filter((x) => x !== 'with-modal-popup')
				.join(' ');
			html[0].className = newClasses;
			if (!isDisabled) {
				const oldRoot = document.getElementById('framework7-root');
				if (oldRoot) {
					try {
						popupInstance.el.remove();
						popupInstance.backdropEl.remove();
					} catch (err) {
						console.log('error removing original popup: ', err);
					}
				}
				previewElement.appendChild(popupInstance.el);
				previewElement.appendChild(popupInstance.backdropEl);
			}
		}
	}

	async demoAppSetInitialConfig() {
		await originalConfigService.initConfig();
		const config = _.cloneDeep(originalConfigService.getConfiguration());
		if (this.props.setInitialConfig) {
			this.props.setInitialConfig(config);
		}
	}

	demoAppMockCloudAPI(mock) {
		let source = MockAPI;
		if (mock) {
			this.originalCloudAPI = _.cloneDeep(CloudAPI);
			source.mockUserData();
		} else {
			source = this.originalCloudAPI;
		}
		CloudAPI.get = source.get;
		CloudAPI.post = source.post;
		CloudAPI.getAccumulators = source.getAccumulators;
		CloudAPI.getPlanDocuments = source.getPlanDocuments;
		CloudAPI.getUserPrescriptions = source.getUserPrescriptions;
		CloudAPI.getNearbyPharmacyByName = source.getNearbyPharmacyByName;
		CloudAPI.providerSearch = source.providerSearch;
		CloudAPI.nameSearch = source.providerSearch;
		CloudAPI.getProvider = source.getProvider;
		CloudAPI.findGeoLocation = source.findGeoLocation;
		CloudAPI.getMyAppointments = source.getMyAppointments;
		CloudAPI.getCareTeam = source.getCareTeam;
		if (_.get(expressStore, 'currentOrg.costAndQualityEnabled') === false) {
			CloudAPI.getProviderScores = async () => null;
		} else {
			CloudAPI.getProviderScores = source.getProviderScores;
		}
	}

	onRefreshPage() {
		this.data.demoStatus = 'loading';
		setTimeout(() => {
			this.data.demoStatus = 'ready';
		}, 500);
	}

	onRoute() {
		const isLoggedIn = firebase.auth().currentUser;
		if (!isLoggedIn) {
			this.demoAppMockRouter(false);
			window.location.reload(); //clear all the mocking & restart with a fresh login
		}
		console.log('someone tried to route somewhere in demo mode');
	}

	demoAppMockRouter(mock) {
		if (mock) {
			this.originalCurrentRouter = _.cloneDeep(this.$f7.router.app.views.current.router);
			this.originalMainRouter = _.cloneDeep(this.$f7.views.main.router);
			this.originalGlobalRouter = _.cloneDeep(window.globalRouter);
		}
		this.$f7.router.app.views.current.router.back = mock ? this.onRoute : this.originalCurrentRouter.back;
		this.$f7.views.main.router.refreshPage = mock ? this.onRefreshPage : this.originalMainRouter.refreshPage;
		window.globalRouter.navigate = mock ? this.onRoute : this.originalGlobalRouter.navigate;
	}

	demoAppMockAnalytics(mock) {
		if (mock) {
			this.originalAnalytics = _.cloneDeep(AnalyticsService.prototype);
		}
		AnalyticsService.prototype.track = mock ? () => Promise.resolve() : this.originalAnalytics.track;
		AnalyticsService.prototype.trackWithData = mock ? () => Promise.resolve() : this.originalAnalytics.trackWithData;
	}

	demoAppMockConfigService(mock) {
		ConfigService.getConfigValue = mock
			? (path, defaultValue) => {
					const value = _.cloneDeep(_.get(this.props.config, path, defaultValue));
					return value;
			  }
			: originalConfigService.getConfigValue;
		ConfigService.getConfiguration = mock ? () => _.cloneDeep(this.props.config || {}) : originalConfigService.getConfiguration;
	}

	componentDidUpdate() {
		if (this.props.handleDemoRender) {
			this.props.handleDemoRender();
		}
		if (this.props.reload) {
			this.data.demoStatus = 'loading';
			setTimeout(() => (this.data.demoStatus = 'ready'), 1000);
		}
	}

	componentDidCatch(err, errInfo) {
		console.log('component catch hit: ', err);
		console.log('component catch info: ', errInfo);
		this.data.demoStatus = 'loading';
		setTimeout(() => {
			this.data.demoStatus = 'ready';
		}, 1000);
	}

	getDemoApp() {
		switch (this.data.demoStatus) {
			case 'loading':
				return (
					<div className="demo-pending">
						<div className="preloader-container">
							<Preloader color="blue" size={34} />
						</div>
						<div className="pending-status">Loading</div>
					</div>
				);
			case 'ready':
				return (
					<div id="new-root" className="demo-app-app ios ios-translucent-bars ios-translucent-modals device-pixel-ratio-3 device-ios">
						<ApplicationShell />
						{appStore.openProviderProfile && <ProviderProfile />}
						{appStore.externalServicesOpen && (
							<ExternalServicesMenu
								onClose={() => {
									appStore.externalServicesOpen = false;
								}}
							/>
						)}
						<div id="preview-container" className="preview-container"></div>
					</div>
				);
			case 'error':
				return (
					<div className="demo-pending">
						<div className="preloader-container">
							<Preloader color="blue" size={34} />
						</div>
						<div className="pending-status">Error Displaying App Demo</div>
					</div>
				);
			default:
				return <div className="demo-pending">IDK Whats going on</div>;
		}
	}
	render() {
		return (
			<div className="demo-app-container">
				<div className="app-shell-overlay">
					<div className="app-shell-spotlight" />
				</div>
				{this.getDemoApp()}
			</div>
		);
	}
}

export default DemoAppShell;
