import { autoBind } from '@/utils/GeneralUtils';
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import CanvasFactory from '@/factories/CanvasFactory';

@observer
export default class ZoomUndo extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const {
			canEdit,
			download,
			zoomIn,
			zoomOut,
			undo,
			redo,
			editorStore: { zoom }
		} = CanvasFactory.getActiveService();
		return (
			<div className="zoom-undo">
				<div className="hbox">
					<div className="hbox">
						{canEdit && (
							<div className="btn grow-1" onClick={(e) => undo(e)}>
								<i className="fa fa-undo"></i>
							</div>
						)}
						{canEdit && (
							<div className="btn" onClick={(e) => redo(e)}>
								<i className="fa fa-redo"></i>
							</div>
						)}
						<div className="btn" onClick={(e) => download(e)}>
							<i className="fa fa-download"></i>
						</div>
						{canEdit && (
							<div className="zoom-btn">
								<div className="btn zoom-out" onClick={(e) => zoomOut(e)}>
									<i className="fa fa-minus"></i>
								</div>
								<div className="value grow-1">{zoom}%</div>
								<div className="btn zoom-in" onClick={(e) => zoomIn(e)}>
									<i className="fa fa-plus"></i>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
