import React, { Component } from 'react';
import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import TextareaAutosize from 'react-textarea-autosize';
import CanvasFactory from '@/factories/CanvasFactory';

@observer
export default class TextNodeEditor extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			value: props.text,
			offset: 0,
			height: 0
		});
		this.textArea = React.createRef();
		autoBind(this);
	}

	componentDidMount() {
		if (CanvasFactory.getActiveStore().showTextEditor) {
			this.textArea.current.focus();
			this.textArea.current.selectionStart = this.textArea.current.value.length;
			this.textArea.current.selectionEnd = this.textArea.current.value.length;
		}
	}

	componentDidUpdate() {
		if (CanvasFactory.getActiveStore().showTextEditor) {
			//wasnt sure why it was here, but it was causing cursor to jump to end of string after each keypress
			// this.textArea.current.focus();
			// this.textArea.current.selectionStart = this.textArea.current.value.length;
			// this.textArea.current.selectionEnd = this.textArea.current.value.length;
		}
	}

	onChange(e) {
		const { selectedNodes, nodeData } = CanvasFactory.getActiveStore();
		const selectedNode = nodeData[selectedNodes[0].replace('#', '')];
		selectedNode.text = e.currentTarget.value;
	}

	onHeightChange(height) {
		this.data.offset = (height - this.data.height) / 2 + 1;
	}

	render() {
		const { selectedNodes, nodeData, zoom, showTextEditor } = CanvasFactory.getActiveStore();

		if (!showTextEditor) {
			return <></>;
		} else {
			const selectedNode = nodeData[selectedNodes[0].replace('#', '')];
			const fontStyle = selectedNode.fontStyle;
			const fStyle = fontStyle && fontStyle.split(' ').includes('italic') ? 'italic' : 'normal';
			const fontWeight = fontStyle && fontStyle.split(' ').includes('bold') ? 'bold' : 'normal';
			const { width, fontFamily, textDecoration, fill, fontSize, name, align, getKonvaNode, text } = selectedNode;

			let textPosition = selectedNode.getKonvaNode().getAbsolutePosition();
			let stageBox = CanvasFactory.getActiveService().getStage().current.container().getBoundingClientRect();
			let areaPosition = {
				x: stageBox.left + textPosition.x,
				y: stageBox.top + textPosition.y
			};
			let top = areaPosition.y;
			let left = areaPosition.x;

			this.data.height = getKonvaNode().getClientRect().height;
			return (
				<div>
					<TextareaAutosize
						ref={this.textArea}
						minRows={1}
						style={{
							position: 'absolute',
							zIndex: 1,
							top: `0`,
							left: `0`,
							transform: `translate(${left}px, ${top}px) scale(${zoom / 100})`,
							fontSize: `${fontSize}px`,
							width: width,
							height: 0,
							border: 'none',
							padding: '0px',
							margin: '0px',
							overflow: 'hidden',
							textDecoration: textDecoration,
							background: 'none !imporant',
							outline: 'none',
							resize: 'none',
							lineHeight: '100%',
							fontWeight: fontWeight,
							fontStyle: fStyle,
							fontFamily: fontFamily,
							transformOrigin: 'left top',
							textAlign: align,
							color: fill,
							verticalAlign: 'top',
							display: showTextEditor ? 'block' : 'none'
						}}
						onBlur={(e) => CanvasFactory.getActiveService().onTextNodeEditorBlur(e)}
						id={name}
						value={text}
						onChange={this.onChange}
						onHeightChange={this.onHeightChange}
						autoFocus={true}
					/>
				</div>
			);
		}
	}
}
