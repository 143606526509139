import React, { Component } from 'react';
import { autoBind } from '@/utils/GeneralUtils';
import { Badge } from 'framework7-react';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import './call-timeline.scss';

export default class CallTimeline extends Component {
    constructor(props){
        super(props);
        autoBind(this);
    }

    getColor(status) {
		switch (status) {
			case 'initiated':
				return 'grey';
			case 'ringing':
				return 'orange';
			case 'in-progress':
				return 'blue';
			case 'completed':
				return 'green';
			default:
				return '';
		}
    }

    getIcon(status) {
        switch (status) {
			case 'initiated':
				return 'fa-rocket';
			case 'ringing':
				return 'fa-bell';
			case 'in-progress':
				return 'fa-sync';
			case 'completed':
				return 'fa-check';
			default:
				return '';
		}
    }

	render() {
		let { callDetails } = this.props;
		let items = [];
		callDetails.forEach((event, key) => {
			items.push(
				<div className="timeline-item" key={key}>
					<div className="timeline-item-date">{format(new Date(event.timestamp), 'K:mm:ss a')}</div>
					<div className={'timeline-item-divider ' + event.callStatus}><i className={'fa ' + this.getIcon(event.callStatus)}></i></div>
					<div className="timeline-item-content">
						<Badge color={event.callStatus}>{event.callStatus}</Badge>
					</div>
				</div>
			);
		});
		return <div className="call-timeline timeline">{items}</div>;
	}
}
