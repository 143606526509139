import { Link, List, ListItem, Popover } from 'framework7-react';
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import Draggable from 'react-draggable';
import './tools.scss';
import { autoBind } from '@/utils/GeneralUtils';
import whiteout from '@/assets/whiteout.svg';
import CanvasFactory from '@/factories/CanvasFactory';
import windowStore from '@/stores/WindowStore';
@observer
export default class Tools extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showVariableMenu: false,
			variableOptions: {}
		});
		this.hiddenFile = React.createRef();
		autoBind(this);
		this.toolsContainer = React.createRef();
	}

	handleChangeFile = (file) => {
		const { addNode } = CanvasFactory.getActiveService();
		let fileData = new FileReader();

		fileData.onloadend = (e) => {
			const content = e.target.result;
			let image = new Image();
			image.src = content;
			image.onload = (e) => {
				addNode('image', { image: image });
			};

			this.hiddenFile.current.value = '';
		};
		fileData.readAsDataURL(file);
	};

	handleImageAdd(e) {
		this.hiddenFile.current.click();
	}


	render() {
		const { bounds } = this.props;
		const {
			addNode,
			toggleWhiteout,
			editorStore: { whiteoutActive }
		} = CanvasFactory.getActiveService();
		return (
			<div className="tools-container vbox vcenter" ref={this.toolsContainer}>
				<Draggable bounds={bounds} handle=".handle">
					<div className="tools vbox hcenter vcenter">
						<div className="handle">
							<i className="fa fa-grip-horizontal"></i>
						</div>
						<div
							className="tool vbox hcenter vcenter"
							onClick={() => {
								addNode('text', { text: 'hello' });
							}}
						>
							<div className="tool-icon">
								<i className="fad fa-text"></i>
							</div>
							<div className="tool-name">Text</div>
						</div>
						<Link className="tool vbox hcenter vcenter" popoverOpen=".editor-shapes">
							<div className="tool-icon">
								<i className="fad fa-shapes"></i>
							</div>
							<div className="tool-name">Shape</div>
						</Link>
						<div className="tool vbox hcenter vcenter" onClick={this.handleImageAdd}>
							<div className="tool-icon">
								<i className="fad fa-image"></i>
							</div>
							<div className="tool-name">Image</div>
							<input
								type="file"
								ref={this.hiddenFile}
								accept="image/*"
								style={{ display: 'none' }}
								onChange={(e) => this.handleChangeFile(e.target.files[0])}
							/>
						</div>
						<div className={`tool vbox hcenter vcenter ${whiteoutActive ? 'active' : ''}`} onClick={toggleWhiteout}>
							<div className="tool-icon">
								<img src={whiteout} height={23} />
							</div>
							<div className="tool-name">Whiteout</div>
						</div>
						<Link className="tool vbox hcenter vcenter" popoverOpen=".group-fields">
							<div className="tool-icon">
								<i className="fad fa-users-class"></i>
							</div>
							<div className="tool-name">Group field</div>
						</Link>
						<Link className="tool vbox hcenter vcenter" popoverOpen=".plan-fields">
							<div className="tool-icon">
								<i className="fad fa-file-plus"></i>
							</div>
							<div className="tool-name">Plan field</div>
						</Link>
						<Link className="tool vbox hcenter vcenter" popoverOpen=".member-fields">
							<div className="tool-icon">
								<i className="fad fa-users"></i>
							</div>
							<div className="tool-name">Member field</div>
						</Link>
					</div>
				</Draggable>
				<Popover className="editor-shapes">
					<List className="tools-list">
						<ListItem
							popoverClose
							link="#"
							key="add-rectangle-95959"
							className="tool-item"
							onClick={() => addNode('rectangle', { width: 100, height: 100, fill: '#000000' })}
						>
							<i className="fa fa-square"></i>
							<div className="description">Rectangle</div>
						</ListItem>
						<ListItem
							popoverClose
							link="#"
							key="add-circle-95959"
							className="tool-item"
							onClick={() => addNode('circle', { fill: '#000000' })}
						>
							<i className="fa fa-circle"></i>
							<div className="description">Circle</div>
						</ListItem>
					</List>
				</Popover>
			</div>
		);
	}
}
