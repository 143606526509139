import React, { Component } from 'react';
import EmptyBg from '../../assets/careteamsvg.svg';
import { Chip, List, ListItem, SkeletonBlock, Button, f7 } from 'framework7-react';
import './care-team.scss';
import CloudAPI from '../../services/CloudAPI';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind, getProviderIcon } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import providerStore from '../../stores/ProviderStore';
import ProviderService from '../../services/ProviderService';
import appStore from '../../stores/AppStore';
import UserService from '../../services/UserService';
import ExternalService from '../../services/ExternalService';
import AnalyticsService from '../../services/AnalyticsService';
import doctorMale from '../../assets/doctor-male.svg';
import doctorFemale from '../../assets/doctor-female.svg';
import ConfigService from '../../services/ConfigService';
import NetworkChip from '@/components/network-chip/NetworkChip';
import userStore from '@/stores/UserStore';
const analytics = new AnalyticsService();
@observer
class CareTeam extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true
		});
		this.text = getCmpText('CareTeam', {
			titlePart1: `My Care`,
			titlePart2: 'Team',
			navText: 'Navigate',
			callText: 'Call',
			bookText: 'Book Appt.',
			inNetworkText: 'In-Network',
			outNetworkText: 'Out-Network',
			miAway: ' mi away',
			searchText: 'Search',
			careTeamText: 'Care Team',
			pcpText: 'Primary Care Provider'
		});
		autoBind(this);
	}
	async componentDidMount() {
		// * NOTE: this is so that we can correctly view the user's providers in admin mode
		const isDemoMode = appStore.demoMode ? userStore.userData.demoHash : false;
		CloudAPI.getCareTeam(isDemoMode)
			.then((res) => {
				this.data.loading = false;
				providerStore.careTeam = res;
			})
			.catch((err) => {
				console.log(err);
				this.data.loading = false;
			});
	}

	onNavigate(p) {
		ProviderService.openNavigation(p, this.$f7);
	}

	async onCall(p) {
		const doCall = async () => {
			const shouldAddPcp = UserService.shouldAutoAssignPcp(p.specialty);
			if (shouldAddPcp) {
				this.$f7.dialog.preloader();
				const providerData = {
					...p,
					fullName: p.name,
					personDetails: {
						gender: p.gender
					}
				};
				const locationData = {
					...p.location,
					networks: p.networks
				};
				try {
					await UserService.autoAddProviderAsPcp(providerData, locationData);
				} catch (err) {
					console.log('unable to auto add PCP: ', err);
				}
				this.$f7.dialog.close();
			}
			const phone = _.get(p, 'location.phone', '');
			analytics.track('called_provider');
			ExternalService.handleExternalRequest('call', phone);
		};
		const isInNetwork = ProviderService.getNetworkData(p).inNetwork;
		if (!isInNetwork) {
			let config = ConfigService.getConfiguration();
			const warningDisabled = _.get(config, 'features.disableOONWarning', false);
			let TransparencySettingsText = getCmpText('TransparencySettings', { oonWarningText: '' });
			if (!warningDisabled && !_.isEmpty(TransparencySettingsText.oonWarningText)) {
				//* Show OON Warning
				analytics.trackWithData('oon_warning', { npi: p.npi });
				f7.dialog.alert(TransparencySettingsText.oonWarningText, 'Warning!', doCall);
			} else {
				doCall();
			}
		} else {
			doCall();
		}
	}

	onBookAppt(p) {
		const doBook = async () => {
			const config = ConfigService.getConfiguration();
			const bookingSpecialtyOrCategoryConfig = _.cloneDeep(ProviderService.getLocationBookingConfig(p, true) || {});
			let sendExternal = false;
			if (bookingSpecialtyOrCategoryConfig.enabled) {
				analytics.trackWithData('specialty booking override', bookingSpecialtyOrCategoryConfig);
				ExternalService.handleExternalRequest(bookingSpecialtyOrCategoryConfig.type, bookingSpecialtyOrCategoryConfig.data);
				return;
			}
			const bookingUrl = _.get(config, 'booking.apptBookingUrl');
			const bookingMessage = _.get(config, 'booking.apptBookingMessage');
			if (!!bookingUrl || !!bookingMessage) {
				//check to external booking setup
				let apptBookingNetworks = _.get(config, 'booking.apptBookingNetworks', []);
				_.forEach(p.networks, (n) => {
					if (apptBookingNetworks.indexOf(n) >= 0) {
						sendExternal = true;
					}
				});
			}
			if (sendExternal) {
				if (bookingMessage) {
					analytics.track('opened_external_booking_message');
					appStore.showExternalServiceMessage = true;
					appStore.externalServiceMessage = {
						icon: 'fad fa-calendar-edit',
						message: bookingMessage,
						title: this.text.bookText
					};
					return;
				} else {
					analytics.trackWithData('opened_external_site', { url: bookingUrl });
					ExternalService.handleExternalRequest('web', bookingUrl);
					return;
				}
			}

			providerStore.selectedCareTeam = _.cloneDeep(p);
			appStore.bookingOpen = true;
		};
		const isInNetwork = ProviderService.getNetworkData(p).inNetwork;
		if (!isInNetwork) {
			let config = ConfigService.getConfiguration();
			const warningDisabled = _.get(config, 'features.disableOONWarning', false);
			let TransparencySettingsText = getCmpText('TransparencySettings', { oonWarningText: '' });
			if (!warningDisabled && !_.isEmpty(TransparencySettingsText.oonWarningText)) {
				//* Show OON Warning
				analytics.trackWithData('oon_warning', { npi: p.npi });
				f7.dialog.alert(TransparencySettingsText.oonWarningText, 'Warning!', doBook);
			} else {
				doBook();
			}
		} else {
			doBook();
		}
	}

	onSearch() {
		appStore.activeTab = 'doctors';
	}

	getPhotoUrl(p) {
		return _.get(p, 'personDetails.gender', 'm') === 'm' ? doctorMale : doctorFemale;
	}

	buildList() {
		let { careTeam } = providerStore;
		const sortedCareTeam = _.cloneDeep(careTeam).filter((provider) => !provider.isPcp);
		const isPcp = _.find(careTeam, { isPcp: true });
		let config = ConfigService.getConfiguration();
		if (isPcp) {
			sortedCareTeam.unshift(isPcp);
		}
		let listItems = [];
		let { navText, callText, bookText, searchText, inNetworkText, outNetworkText, miAway, careTeamText, pcpText } = this.text;
		_.forEach(sortedCareTeam, (p) => {
			let inNetwork = ProviderService.isInNetwork(p);
			let distance = UserService.getDistanceFromLocation(p.location);
			const locationBookingConfig = ProviderService.getLocationBookingConfig(p, true) || {};
			const hasBooking =
				p.isPerson &&
				!UserService.isConsumer() &&
				inNetwork &&
				_.get(config, 'features.appointments') &&
				!locationBookingConfig.disabled;
			const showNavigation = ProviderService.allowLocationNavigation({ ...p.location, specialties: [p.specialty] }, true);
			const showCallBtn = ProviderService.allowLocationCall({ ...p.location, specialties: [p.specialty] }, true);
			listItems.push(
				<div
					onClick={(e) => {
						if (e.target.classList.contains('fad') || e.target.parentElement.classList.contains('action')) {
							return;
						}
						ProviderService.openProviderProfile(p, 'careTeam');
					}}
					key={p.NPI}
					className="provider-list-item hbox vcenter"
				>
					<div className="hbox vcenter">
						<div className="vbox hcenter m-r-16">
							<div className="image-ctn vbox hcenter">
								{p.isPerson && (
									<object data={this.getPhotoUrl(p)} type="image/png" className="provider-photo">
										<img src={_.get(p, 'personDetails.gender', 'm') === 'm' ? doctorMale : doctorFemale} />
									</object>
								)}
								{!p.isPerson && (
									<div className="organization">
										<i className={`${getProviderIcon(p, true)}`}></i>
									</div>
								)}
							</div>
							<div className="distance hbox vcenter">
								<i className="fal fa-map-marker-alt"></i>
								{distance + miAway}
							</div>
						</div>
					</div>

					<div className="details grow-1">
						{/* //TODO need to make configs for chips & also generate tier chips from provider network tiers */}
						<div className="chips">
							<NetworkChip provider={p} />
							{ProviderService.isOnCareTeam(p) && (
								<Chip text={careTeamText} mediaBgColor="purple" className="ios">
									<i slot="media" className={`fad fa-heart`}></i>
								</Chip>
							)}
							{p.isPcp && (
								<Chip text={pcpText} mediaBgColor="blue" className="ios">
									<i slot="media" className={`fad fa-parking-circle`}></i>
								</Chip>
							)}
						</div>
						<div className="provider-name">{ProviderService.getCleanName(p)}</div>
						<div className="specialty">
							{_.get(p, 'specialty', '').toString().split(',').join(', ').split('Other Medical Specialists,').join('')}
						</div>
						<div className="hbox vcenter action-ctn">
							{showNavigation && (
								<div
									className="action vbox vcenter hcenter"
									onClick={() => {
										this.onNavigate(p, 'should be a single unique location');
									}}
								>
									<i className="fad fa-directions neumorphic neuhover"></i>
									<div>{navText}</div>
								</div>
							)}

							{showCallBtn && _.get(p, 'location.phone', false) && (
								<div
									className="action vbox vcenter hcenter "
									onClick={() => {
										this.onCall(p, 'should be a single unique phone number');
									}}
								>
									<i className="fad fa-phone neumorphic neuhover"></i>
									<div>{callText}</div>
								</div>
							)}
							{hasBooking && (
								<div
									className="action vbox vcenter hcenter"
									onClick={() => {
										this.onBookAppt(p);
									}}
								>
									<i className="fad fa-calendar-edit neumorphic neuhover"></i>
									<div>{bookText}</div>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		});
		return listItems.length > 0 ? (
			listItems
		) : (
			<div className="empty-careteam">
				<img className="empty-svg" src={EmptyBg} />
				<Button fill large className="care-team-btn animated pulse infinite" color="purple" onClick={this.onSearch}>
					{searchText}
				</Button>
			</div>
		);
	}

	getSkeleton() {
		return [1, 2, 3, 4, 5].map((n) => (
			<ListItem
				key={n}
				className={`skeleton-text skeleton-effect-fade`}
				title="________    ______ "
				subtitle="_________________________________"
				text="______   ________   ________"
			>
				<SkeletonBlock style={{ width: '72px', height: '72px' }} slot="media" />
			</ListItem>
		));
	}

	render() {
		let { titlePart1, titlePart2 } = this.text;
		let { loading } = this.data;
		return (
			<div className="care-team-cmp">
				{!this.props.hideTitle && (
					<div className="title hbox vcenter hcenter">
						{titlePart1}
						<i className="fad fa-heartbeat" />
						{titlePart2}
					</div>
				)}
				<List className="provider-list no-hairlines" mediaList>
					{loading ? '' : this.buildList()}
				</List>
			</div>
		);
	}
}

export default CareTeam;
