import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import './image-cropper.scss';
import Draggable from 'react-draggable';

import windowStore from '@/stores/WindowStore';
import { Button, Range } from 'framework7-react';
@observer
export default class ImageCropper extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			scale: 1,
			image: {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
				originalWidth: 0,
				originalHeight: 0
			},
			crop: {
				width: 0,
				height: 0
			},
			windowWidth: 0,
			windowHeight: 0
		});
		autoBind(this);
		this.imageRef = React.createRef();
		this.containerRef = React.createRef();
	}

	onDragStop(e, d) {
		this.data.image.x = d.x;
		this.data.image.y = d.y;

        if(this.props.onCrop) {
            this.props.onCrop(_.cloneDeep(this.data))
        }
	}

	onChange() {
		if (this.props.onChange) {
			this.props.onChange(this.data.image);
		}
	}

	calculateSizes() {
		let { imageRef, containerRef } = this;
		const { aspect } = this.props;
		if (imageRef.current && containerRef.current) {
			(this.data.image.width = imageRef.current.getBoundingClientRect().width),
				(this.data.image.height = imageRef.current.getBoundingClientRect().height);
			this.data.image.originalWidth = imageRef.current.naturalWidth;
			this.data.image.originalHeight = imageRef.current.naturalHeight;

			let fitWidth = Math.min(imageRef.current.offsetWidth, containerRef.current.getBoundingClientRect().width);
			let fitHeight = Math.min(imageRef.current.offsetHeight, containerRef.current.getBoundingClientRect().height);

			if (fitWidth > fitHeight * aspect) {
				this.data.crop.width = fitHeight * aspect;
				this.data.crop.height = fitHeight;
			} else {
				(this.data.crop.width = fitWidth), (this.data.crop.height = fitWidth / aspect);
			}
		}
	}

	handleScale(val) {
		this.data.scale = val;
        if(this.props.onCrop) {
            this.props.onCrop(_.cloneDeep(this.data))
        }
	}

    autoPosition() {
        this.data.scale = 1;
        this.data.image.x = 0;
        this.data.image.y = 0;

        if(this.props.onCrop) {
            this.props.onCrop(_.cloneDeep(this.data))
        }

    }

	componentDidMount() {
		this.calculateSizes();
        if(this.props.onCrop) {
            this.props.onCrop(_.cloneDeep(this.data))
        }
	}

	componentDidUpdate(prevProps) {
		this.calculateSizes();
        if(this.props.onCrop) {
            this.props.onCrop(_.cloneDeep(this.data))
        }
	}

	loaded(e) {
		this.calculateSizes();
        if(this.props.onCrop) {
            this.props.onCrop(_.cloneDeep(this.data))
        }
	}

	render() {
		const { documentWidth, documentHeight, image, cropperWidth, cropperHeight } = this.props;
		const { innerWidth, innerHeight } = windowStore;
		return (
			<div className="image-cropper">
				<div className="image-cropper-container vbox hcenter vcenter" ref={this.containerRef}>
					{image && (
						<div className="image-container  hbox vcenter hcenter" style={{ transform: `scale(${this.data.scale})` }}>
							<Draggable onStop={this.onDragStop} position={{x: this.data.image.x, y: this.data.image.y}} scale={this.data.scale}>
								<img src={image.src} alt="" draggable="false" ref={this.imageRef} onLoad={this.loaded}/>
							</Draggable>
						</div>
					)}
					<div className="crop-mask" style={{ width: this.data.crop.width, height: this.data.crop.height }}></div>
				</div>
				<div className="controls hbox">
					<div className="control vbox">
						<div className="zoom">Zoom</div>
                        <div className="hbox" style={{width: '100%'}}>
                            <div>-</div>
                            <Range
							min={0}
							max={2}
							step={0.1}
							value={this.data.scale}
							onRangeChange={this.handleScale}
						/>
                        <div>+</div>
                        </div>
				
					</div>
					<div className="control vbox">
						<Button onClick={this.autoPosition}>AUTO</Button>
					</div>
				</div>
			</div>
		);
	}
}
