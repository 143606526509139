import React, { Component } from 'react';
import _ from 'lodash';
import { autoBind } from '@/utils/GeneralUtils';
import { Popup, Link } from 'framework7-react';
import { observer } from '@/utils/State';
import appStore from '@/stores/AppStore';
import './cv-sheet.scss';

@observer
export default class CvSheet extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleOnClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	async onPopupOpen() {
		if (this.props.onOpen) {
			this.props.onOpen();
		}
	}

	handleBack() {
		if (this.props.onBack) {
			this.props.onBack();
		}
	}

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`${this.props.className} carevalet-sheet-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpen={this.onPopupOpen}
				onPopupClosed={this.handleOnClose}
			>
				<div
					className={`${this.props.opened ? 'container' : ''} content-ctn animated ${this.props.contentAnimation}`}
					key="test-list-ctn"
				>
					<div className="main-body">
						<div className="header">
							<div className="title">{this.props.title}</div>
							<Link popupClose>{this.props.closeText}</Link>
						</div>
						{this.props.children}
					</div>
				</div>
			</Popup>
		);
	}
}
