import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ExpressAPI from '@/services/ExpressAPI';
import { Preloader } from 'framework7-react';
import './id-card-detail.scss';
import expressStore from '@/stores/ExpressStore';
@observer
export default class IdDetail extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			active: false,
			imageLoading: true,
			imageUrl: ''
		});
		autoBind(this);
	}

	handleImageLoad(e) {
		this.data.imageLoading = false;
	}

	componentDidMount() {
		if (this.props.thumbnailKey) {
			this.data.imageLoading = true;
			ExpressAPI.getCanvasAsset(this.props.thumbnailKey)
				.then((res) => {
					this.data.imageUrl = res;
					// this.data.imageLoading = false;
				})
				.catch((e) => {
					console.log('ERROR LOADING IMAGE');
					console.log(e);
					this.data.imageLoading = false;
				});
		}
	}

	componentDidUpdate(props) {
		if (this.props.thumbnailKey !== props.thumbnailKey) {
			this.data.imageLoading = true;
			ExpressAPI.getCanvasAsset(this.props.thumbnailKey)
				.then((res) => {
					this.data.imageUrl = res;
					// this.data.imageLoading = false;
				})
				.catch((e) => {
					console.log('ERROR LOADING IMAGE');
					console.log(e);
					this.data.imageLoading = false;
				});
		}
	}

	render() {
		const { loading, thumbnailKey } = this.props;
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') && expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;
		const thumbnailUrl = this.props.thumbnailKey ? this.data.imageUrl : this.props.thumbnailUrl;
		if (!loading) {
			return (
				<IdCard
					{...this.props}
					active={this.data.active}
					onMouseOver={(e) => (this.data.active = true)}
					onMouseOut={(e) => (this.data.active = false)}
					onImageLoad={this.handleImageLoad}
					thumbnailUrl={thumbnailUrl}
					imageLoading={this.data.imageLoading}
					canEdit={canEdit}
				/>
			);
		} else {
			return <Preloader size={32} color="blue"></Preloader>;
		}
	}
}

const IdCard = observer((props) => {
	const {
		canEdit,
		side,
		thumbnailUrl,
		onClick,
		onPreview,
		onEdit,
		onDelete,
		active,
		onMouseOver,
		onMouseOut,
		onImageLoad,
		imageLoading
	} = props;
	if (thumbnailUrl) {
		return (
			<div
				className={`id-card-detail vbox hcenter vcenter ${active ? 'active' : ''}`}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				onClick={(e) => {
					// @ts-ignore
					if (e.target?.classList?.contains('fa')) {
						return;
					}
					if (onClick) {
						onClick(e);
					}
				}}
			>
				<div className="actions vcenter hcenter hbox">
					{onPreview && (
						<a className="btn" onClick={onPreview}>
							<i className="fa fa-eye"></i>
						</a>
					)}
					{canEdit && onEdit && (
						<a className="btn" onClick={onEdit}>
							<i className="fa fa-pencil"></i>
						</a>
					)}
					{canEdit && onDelete && (
						<a className="btn delete" onClick={onDelete}>
							<i className="fa fa-trash"></i>
						</a>
					)}
					{!canEdit && (
						<a className="btn" onClick={onPreview}>
							<i className="fa fa-eye"></i>
						</a>
					)}
				</div>
				{imageLoading && (
					<div className="preloader-container">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
				<img src={thumbnailUrl} onLoad={onImageLoad} className="id-card-image" />
				<div className="side">{side}</div>
			</div>
		);
	} else {
		return <></>;
	}
});
