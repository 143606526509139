import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import './transform-properties.scss';
import BasicInput from '@/components/basic-input/BasicInput';
@observer
export default class TransformProperties extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const {
			onPropertyChange,
			selectedNode: { x, y, width, height, type }
		} = this.props;

		return (
			<div className="transform-properties property-section  vbox vcenter">
				<div className="property-label">Transform</div>

				<div className="property-group hbox">
					<div className="property-input hbox vcenter">
						<label htmlFor="">x</label>
						<BasicInput
							type="number"
							name="x"
							value={x}
							step="1"
							onChange={(e) => {
								onPropertyChange('x', parseFloat(e.target.value));
							}}
						/>
					</div>
					<div className="property-input hbox vcenter">
						<label htmlFor="">y</label>
						<BasicInput
							type="number"
							name="y"
							value={y}
							step="1"
							onChange={(e) => {
								onPropertyChange('y', parseFloat(e.target.value));
							}}
						/>
					</div>
				</div>

				<div className="property-group hbox">
					<div className="property-input hbox vcenter">
						<label htmlFor="">W</label>
						<BasicInput
							type="number"
							name="width"
							value={width}
							step="1"
							onChange={(e) => {
								onPropertyChange('width', parseFloat(e.target.value));
							}}
						/>
					</div>
					{type !== 'text' && (
						<div className="property-input hbox vcenter">
							<label htmlFor="">H</label>
							<BasicInput
								type="number"
								name="height"
								value={height}
								step="1"
								onChange={(e) => {
									onPropertyChange('height', parseFloat(e.target.value));
								}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
