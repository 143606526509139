import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Link, Popup, Progressbar } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import './status-popup.scss';

@observer
export default class StatusPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			steps: [],
			status: null
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.data.status = _.clone(this.props.status);
	}

	async loadStatusData() {
		const latestStatus = await ExpressAPI.getOrgItemStatus(this.props.type, this.props.itemId);
		this.data.status = latestStatus.complete ? 'completed' : 'pending';
		this.data.steps = latestStatus.steps || [];
	}

	closeStatusPopup() {
		this.props.onClose();
	}

	openStatusPopup() {
		this.loadStatusData();
	}

	render() {
		const popupOpen = this.props.open;
		const title = this.props.title;
		const steps = this.data.steps || [];
		const completedSteps = steps.filter((x) => !!x.complete);
		const amountComplete = Math.ceil((completedSteps.length / steps.length) * 100) || 0;
		return (
			<div className="express-status-chip">
				<Popup
					className={`status-steps-popup`}
					id={`express-item-status-popup-${this.props.itemId}`}
					opened={popupOpen}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupClosed={this.closeStatusPopup}
					onPopupOpen={this.openStatusPopup}
				>
					<div className="popup-header">
						<div className="title grow-1">{title}</div>
						<Link onClick={this.closeStatusPopup}>
							<i className="fad fa-times-square"></i>
						</Link>
					</div>
					<div className="main-content y-scroll">
						{popupOpen && (
							<div>
								<Progressbar progress={amountComplete} id="group-inline-progressbar" />
								<div className="step-list">
									{/* TODO 
                                    Componentize this inner content and then user the component in the PlanDetails page. PlanStatusCard is the component name.
                                    */}
									{steps.map((step, stepIndex) => {
										return (
											<div className={`step hbox vcenter ${step.complete ? 'completed' : ''}`} key={`group-admin-steps-${stepIndex}`}>
												<div className="status">
													{step.complete && <i className="fas fa-check animated bounceIn" />}
													{!step.complete && <i className="fas fa-exclamation animated bounceIn" />}
												</div>
												<div className="step-text">
													<div>
														<strong>{step.title}</strong>
													</div>
													<div className="step-info">{step.info}</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</Popup>
			</div>
		);
	}
}
