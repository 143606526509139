import React, { Component } from 'react';
import { List, ListItem, Button, Badge, Toggle, Preloader, f7 } from 'framework7-react';
import { getCmpText } from '@/utils/Localization';
import { observer, observable } from '@/utils/State';
import _ from 'lodash';
import providerStore from '@/stores/ProviderStore';
import ProviderService from '@/services/ProviderService';
import ExternalService from '@/services/ExternalService';
import { autoBind } from '@/utils/GeneralUtils';
import UserService from '@/services/UserService';
import AnalyticsService from '@/services/AnalyticsService';
import NetworkChip from '@/components/network-chip/NetworkChip';
import CvSheet from '@/components/cv-sheet/CvSheet';
import DropDown from '@/components/drop-down/DropDown';
import RangeSlider from '@/components/range-slider/RangeSlider';
import './provider-locations.scss';
import ConfigService from '@/services/ConfigService';

const analytics = new AnalyticsService();
@observer
export default class ProviderLocations extends Component {
	constructor(props) {
		super(props);
		this.locationDataRef = React.createRef();
		this.data = observable({
			sharedLocationData: null,
			showFilterMenu: false,
			pendingFilters: {
				radius: 50,
				inNetwork: true,
				specialty: 'Initial value',
				showAllLocations: false
			}
		});
		this.text = getCmpText('ProviderProfile', {
			locationsTitle: 'Locations',
			miles: ' mi',
			inNetworkOnlyFilterLabel: 'In-Network Only',
			filtersTitle: 'Filters',
			closeFilter: 'Close',
			applyFiltersBtn: 'Apply Filters',
			emptyLocationList: 'Adjust filter criteria, no locations meet current criteria',
			openFilterMenu: 'Open Filter Menu',
			viewMoreLocations: 'View All Locations',
			shareAlert: 'The location information has been copied to your clipboard',
			shareTitle: 'Check out this provider',
			networkMessage:
				'Providers are ranked based on your health plan network structure between green and red. Green being an often, better cost or quality provider, and red being an out of network provider. '
		});
		autoBind(this);
	}

	async onCallLocation(provider, location) {
		this.showNetworkWarning(location, async () => {
			const phone = _.get(location, 'phone', null);
			const shouldAddPcp = UserService.shouldAutoAssignPcp(_.get(location, 'specialties'));
			if (shouldAddPcp) {
				this.$f7.dialog.preloader();
				try {
					await UserService.autoAddProviderAsPcp(provider, location);
				} catch (err) {
					console.log('unable to auto add PCP: ', err);
				}
				this.$f7.dialog.close();
			}
			analytics.track('called_provider');
			ExternalService.handleExternalRequest('call', phone);
		});
	}

	openNavigation(location) {
		this.showNetworkWarning(location, () => {
			const openProvider = _.cloneDeep(providerStore.openProvider);
			ProviderService.openNavigation({ ...openProvider, location: location }, this.$f7);
		});
	}

	showNetworkWarning(location, action) {
		let isInNetwork = ProviderService.isInNetwork(location);
		if (!isInNetwork) {
			let config = ConfigService.getConfiguration();
			const warningDisabled = _.get(config, 'features.disableOONWarning', false);
			let TransparencySettingsText = getCmpText('TransparencySettings', { oonWarningText: '' });
			if (!warningDisabled && !_.isEmpty(TransparencySettingsText.oonWarningText)) {
				//* Show OON Warning
				analytics.trackWithData('oon_warning', { npi: location.npi });
				f7.dialog.alert(TransparencySettingsText.oonWarningText, 'Warning!', action);
			} else {
				action();
			}
		} else {
			action();
		}
	}

	getLocationTitle(l) {
		let p = providerStore.openProvider;
		if (p && p.fullName !== l.fullName && l.source === 'eliance' && !p.isPerson) {
			return <div className="title">{l.fullName}</div>;
		}
		return '';
	}

	openFilter() {
		this.data.pendingFilters = _.cloneDeep(ProviderService.getOpenProviderLocationFilters());
		this.data.showFilterMenu = true;
	}

	closeFilter() {
		this.data.showFilterMenu = false;
		this.data.pendingFilters = _.cloneDeep(ProviderService.getOpenProviderLocationFilters());
	}

	applyFilters() {
		const pendingFilters = _.cloneDeep(this.data.pendingFilters);
		providerStore.openProvider.appliedFilters = pendingFilters;
		this.closeFilter();
	}

	getAllSpecialties() {
		const locations = ProviderService.getOpenProviderLocations();
		if (locations) {
			const locSpecialties = _.flattenDeep(locations.map((loc) => loc.specialties));
			const uniqueSpecialtyList = Array.from(new Set(locSpecialties));
			return uniqueSpecialtyList;
		}
		return [];
	}

	onRadiusChange(value) {
		if (this.data.showFilterMenu) {
			if (value === 0) value = 1;
			this.data.pendingFilters.radius = value;
			this.handleAllLocationsToggle();
		}
	}

	setSpecialty(value) {
		this.data.pendingFilters.specialty = value;
		this.handleAllLocationsToggle();
		this.$f7.popover.close('.provider-location-specialty-filter');
	}

	updateNetworkFilter(val) {
		this.data.pendingFilters.inNetwork = !val;
		this.handleAllLocationsToggle();
	}

	handleAllLocationsToggle() {
		const allLocationsToggle = this.$f7.toggle.get('.show-all-locations-toggle');
		if (allLocationsToggle && allLocationsToggle.checked) {
			allLocationsToggle.toggle();
		}
	}

	toggleAllLocations(val) {
		this.data.pendingFilters.showAllLocations = val;
	}

	shareProvider(location) {
		const provider = providerStore.openProvider;
		const address = ProviderService.getAddressText(location).toUpperCase();
		const phone = location.phone ? ExternalService.getFormattedPhoneNumber(location.phone) : '';
		const name = ProviderService.formatProviderName(provider);
		const phoneLine = phone ? `Phone Number: ${phone} \n` : '';
		const specialties = location.specialties.join(', ');
		const locationText = `ProviderName: ${name} \nProvider Specialties: ${specialties} \n${phoneLine}Address: ${address}`;
		this.data.sharedLocationData = locationText;

		if (navigator.share) {
			analytics.trackWithData('Share Provider Location', { provider: provider.NPI, location: location.street1, method: 'navigator' });
			const shareData = {
				title: this.text.shareTitle,
				text: locationText
			};
			navigator.share(shareData);
		} else {
			setTimeout(() => {
				analytics.trackWithData('Share Provider Location', {
					provider: provider.NPI,
					location: location.street1,
					method: 'clipboard'
				});
				const locationDataDiv = this.locationDataRef && this.locationDataRef.current;
				locationDataDiv.select();
				document.execCommand('copy');
				this.$f7.dialog.alert(this.text.shareAlert);
			}, 300);
		}
	}

	render() {
		const provider = providerStore.openProvider;
		if (!provider || !providerStore.openProviderLoaded)
			return (
				<div className="provider-locations-loader-container">
					<Preloader size={42} color="blue" />
				</div>
			);
		const locationData = ProviderService.getFilteredOpenProviderLocations(false, false);
		const locations = _.get(locationData, 'locations') || [];
		const allSpecialties = this.getAllSpecialties();
		const specialtyItems = allSpecialties.map((specialty) => {
			return (
				<ListItem key={specialty} className="specialty-option" onClick={() => this.setSpecialty(specialty)}>
					{specialty}
					<i className="fas fa-chevron-right" />
				</ListItem>
			);
		});
		const showAllLocations = this.data.pendingFilters.showAllLocations;
		return (
			<div className="locations-container">
				<div className="locations-header">
					<div className="location-title">{this.text.locationsTitle}</div>
					<div className="filter-btn-container" onClick={this.openFilter}>
						<div className="filter-menu-btn neubtn">
							<i className="fas fa-filter" />
							<Badge color="red" />
						</div>
					</div>
				</div>
				<CvSheet
					id={`providerLocationFilterMenu-${this.props.id}`}
					className={`provider-location-filter-sheet ${this.data.showFilterMenu ? '' : ''}`}
					opened={this.data.showFilterMenu}
					onClose={this.closeFilter}
					backdrop
					contentAnimation="fadeIn"
					title={this.text.filtersTitle}
					closeText={this.text.closeFilter}
				>
					<div className={`body ${showAllLocations ? 'show-all-selected' : ''}`}>
						<div className="filter-option">
							<DropDown
								name="provider-location-specialty-filter"
								label="Specialty"
								items={specialtyItems}
								displayValue={this.data.pendingFilters.specialty}
							/>
						</div>
						{(!_.isEmpty(UserService.getNetworkTiers()) || !!UserService.getHealthNetwork()) && (
							<div className="filter-option">
								<div className="label">{this.text.inNetworkOnlyFilterLabel}</div>
								<Toggle
									name="inNetworkLocFilter"
									value={this.data.pendingFilters.inNetwork.toString()}
									checked={this.data.pendingFilters.inNetwork}
									onToggleChange={this.updateNetworkFilter}
									color={showAllLocations ? 'disabled' : 'green'}
								/>
							</div>
						)}
						<div className="filter-option">
							<RangeSlider
								min={0}
								max={this.data.pendingFilters.radius > 50 ? this.data.pendingFilters.radius : 50}
								step={1}
								value={this.data.pendingFilters.radius}
								scale={true}
								scaleSteps={5}
								scaleSubSteps={5}
								onChange={this.onRadiusChange}
								source="providerLocationFilter"
							/>
						</div>
						<div className="filter-option">
							<div className="show-all-locations">
								<div className="label">{this.text.viewMoreLocations}</div>
								<Toggle
									className="show-all-locations-toggle"
									name="showAllLocations"
									value={(this.data.pendingFilters.showAllLocations || false).toString()}
									onToggleChange={this.toggleAllLocations}
									color="green"
								/>
							</div>
						</div>
					</div>
					<div className="footer">
						<Button fill large round onClick={this.applyFilters}>
							{this.text.applyFiltersBtn}
						</Button>
					</div>
				</CvSheet>

				<List medialist className="locations-list no-hairlines">
					{locations.map((location) => {
						const phone = _.get(location, 'phone');
						const locationNetworks = _.clone(location.networks);
						const providerWithLocationNetworks = { ...provider, networks: locationNetworks };
						const showNavigation = ProviderService.allowLocationNavigation(location);
						const showCall = ProviderService.allowLocationCall(location);
						return (
							<div className="location-item hbox vcenter" key={`provider-${provider._id}-location-${location._id}`}>
								<div className="vbox vcenter hcenter">
									<i className={`fad fa-map-marker-alt`} />
									<div className="distance">
										{location.distance}
										{this.text.miles}
									</div>
								</div>
								<div className="address grow-1">
									<NetworkChip
										provider={providerWithLocationNetworks}
										handleClick={() => this.$f7.dialog.alert(this.text.networkMessage)}
									/>
									{this.getLocationTitle(location)}
									<div className="address-text">{ProviderService.getAddressText(location)}</div>
									<div className="specialties-text">
										{_.get(location, 'specialties', []).toString().split(',').join(', ').split('Other Medical Specialists,').join('')}
									</div>
									<div className="hbox hright w-100 actions">
										{showNavigation && (
											<i
												className="fad fa-directions"
												onClick={() => {
													this.openNavigation(location);
												}}
											/>
										)}
										{showCall && phone && (
											<i
												className="fad fa-phone-alt"
												onClick={() => {
													this.onCallLocation(provider, location);
												}}
											></i>
										)}
										<i
											className="fad fa-share-alt"
											onClick={() => {
												this.shareProvider(location);
											}}
										></i>
									</div>
								</div>
							</div>
						);
					})}
					{locations.length < 1 && (
						<div className="location-item hbox vcenter empty-state">
							<div className="vbox vcenter hcenter">{this.text.emptyLocationList}</div>
							<div className="filter-link-text" onClick={this.openFilter}>
								{this.text.openFilterMenu}
							</div>
						</div>
					)}
					<textarea value={this.data.sharedLocationData || ''} readOnly className="hidden-data" ref={this.locationDataRef} />
				</List>
			</div>
		);
	}
}
