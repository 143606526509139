import React, { Component } from 'react';
import { Page, Toolbar, Link } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { autoBind } from '../../utils/GeneralUtils';
import adminStore from '../../stores/AdminStore';
import AppointmentManagement from './appointment-management/AppointmentManagement';
import GroupManagement from './group-management/GroupManagement';
import Dashboard from './dashboard/Dashboard';
import Reporting from './reporting/Reporting';
import FeedbackManagement from './feedback-management/FeedbackManagement';
import './admin-page.scss';
import appStore from '../../stores/AppStore';
import AffiliateManagement from './affiliate-management/AffiliateManagement';

@observer
class AdminPage extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	changeTab(e) {
		adminStore.activeTab = e.currentTarget.dataset.tab;
	}

	getAdminContent() {
		switch (adminStore.activeTab) {
			case 'reporting': {
				return <Reporting key="reporting-page" />;
			}
			case 'dashboard': {
				return <Dashboard key="dashboard-page" />;
			}
			case 'appointments': {
				return <AppointmentManagement key={Math.random()} />;
			}
			case 'groups': {
				return <GroupManagement key={Math.random()} />;
			}
			case 'feedback': {
				return <FeedbackManagement key={Math.random()} />;
			}
			case 'influencers': {
				return <AffiliateManagement key={Math.random()} />;
			}
		}
	}

	render() {
		return (
			<Page className={`admin-page ${appStore.isMobile ? '' : 'desktop'}`}>
				{adminStore.activeTab === 'menu' && (
					<div className="admin-menu-ctn">
						<h1>Where do you want to go?</h1>
						<div className="hbox vcenter menu-items">
							<Link
								text="Dashboard"
								tabLink="dashboard"
								tabLinkActive={adminStore.activeTab === 'dashboard'}
								onClick={this.changeTab}
								className="use-icon neumorphic neuhover"
							>
								<i className="fad fa-chart-pie"></i>
							</Link>
							<Link
								text="Appointments"
								tabLink="appointments"
								tabLinkActive={adminStore.activeTab === 'appointments'}
								onClick={this.changeTab}
								className="use-icon neumorphic neuhover"
							>
								<i className="fad fa-calendar-check"></i>
							</Link>
						</div>
						<div className="hbox vcenter menu-items">
							<Link
								text="Groups"
								tabLink="groups"
								tabLinkActive={adminStore.activeTab === 'groups'}
								onClick={this.changeTab}
								className="use-icon neumorphic neuhover"
							>
								<i className="fad fa-users-class"></i>
							</Link>
							<Link
								text="Feedback"
								tabLink="feedback"
								tabLinkActive={adminStore.activeTab === 'feedback'}
								onClick={this.changeTab}
								className="use-icon neumorphic neuhover"
							>
								<i className="fad fa-comments"></i>
							</Link>
						</div>
						<div className="hbox vcenter menu-items">
							<Link
								text="Influencers"
								tabLink="influencers"
								tabLinkActive={adminStore.activeTab === 'influencers'}
								onClick={this.changeTab}
								className="use-icon neumorphic neuhover"
							>
								<i className="fad fa-handshake"></i>
							</Link>
							<Link
								text="Reporting"
								tabLink="reporting"
								tabLinkActive={adminStore.activeTab === 'reporting'}
								onClick={this.changeTab}
								className="use-icon neumorphic neuhover"
							>
								<i className="fad fa-chart-line"></i>
							</Link>
						</div>
					</div>
				)}
				{adminStore.activeTab !== 'menu' && (
					<>
						<div className="admin-header hbox vcenter">
							<div data-tab="menu" onClick={this.changeTab} className="back-btn">
								<i className="fad fa-home" />
								Admin Home
							</div>
							<div className="active grow-1">{adminStore.activeTab}</div>
						</div>
						<div className="admin-content y-scroll">{this.getAdminContent()}</div>
					</>
				)}
			</Page>
		);
	}
}

export default AdminPage;
