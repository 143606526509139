import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import IdCardDetail from './id-detail/IdCardDetail';
import './id-card-details.scss';
import expressStore from '@/stores/ExpressStore';
import CanvasFactory from '@/factories/CanvasFactory';
import ChangeIdCardPopup from './change-id-card-popup/ChangeIdCardPopup';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';
@observer
export default class IdCardDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			cardLoading: {},
			front: null,
			back: null,
			changeIdCard: false,
			cards: {},
			changeIdData: {
				templateIdFront: '',
				templateIdBack: ''
			}
		});
		autoBind(this);
	}

	componentDidMount() {
		this.fetchCards();
	}

	renderNoCards() {
		return (
			<div className="card-body">
				<h2>No Cards!</h2>
				<p>Would you like to add one?</p>
			</div>
		);
	}

	onEdit(card) {
		expressStore.activeEditor = 'id-cards';
		expressStore.activeCard = card._id;
		CanvasFactory.getActiveService().loadSavedEditor(card);
		CanvasFactory.getActiveService().setSaveCallback(this.fetchCards);
	}

	fetchCards() {
		const { frontId, backId } = this.props;
		if (frontId) {
			this.fetchCard(frontId, 'front');
		}

		if (backId) {
			this.fetchCard(backId, 'back');
		}
	}

	fetchCard(id, side) {
		this.data.cardLoading[side] = true;
		ExpressAPI.getIdCard(id, true)
			.then((res) => {
				this.data.cards[side] = res;
				this.data.cardLoading[side] = false;
			})
			.catch((e) => {
				showExpressError(this.$f7, e);
				this.data.cardLoading[side] = false;
			});
	}

	onSaveId() {
		this.$f7.dialog.preloader('Saving...');
		let request = _.cloneDeep(this.data.changeIdData);
		request._id = _.cloneDeep(expressStore.activePlan._id);
		ExpressAPI.savePlanPart('idCards', request)
			.then((res) => {
				this.$f7.dialog.close();
				this.data.changeIdCard = false;
				expressStore.activePlan = res;
				this.fetchCards();
			})
			.catch((e) => {
				console.log(e);
			});
	}

	renderCards() {
		return (
			<div className="card-body cards hbox hcenter vcenter">
				<div className="front-container">
					<IdCardDetail
						side="front"
						thumbnailKey={this.data.cards.front?.thumbnails?.thumbnailMedium}
						id={this.props.frontId}
						onClick={() => this.onEdit(this.data.cards.front)}
						onEdit={() => this.onEdit(this.data.cards.front)}
						loading={this.data.cardLoading.front}
					/>
				</div>
				<div className="back-container">
					<IdCardDetail
						side="back"
						thumbnailKey={this.data.cards.back?.thumbnails?.thumbnailMedium}
						loading={this.data.cardLoading.back}
						onClick={() => this.onEdit(this.data.cards.back)}
						onEdit={() => this.onEdit(this.data.cards.back)}
					/>
				</div>
			</div>
		);
	}

	render() {
		const noCards = !this.props.frontId && !this.props.backId;
		return (
			<div className="id-card-details card vbox hcenter animated sldeIn">
				<div className="card-header">
					<div className="title grow-2">ID Cards</div>
					<i className="fad fa-exchange" title="Change ID Cards" onClick={() => (this.data.changeIdCard = true)}></i>
				</div>
				{noCards ? this.renderNoCards() : this.renderCards()}
				<ChangeIdCardPopup
					changeIdData={this.data.changeIdData}
					opened={this.data.changeIdCard}
					frontId={this.props.frontId}
					backId={this.props.backId}
					onSave={this.onSaveId}
					onClose={() => (this.data.changeIdCard = false)}
				/>
			</div>
		);
	}
}
