import CloudAPI from '@/services/CloudAPI';
import userStore from '@/stores/UserStore';
import _ from 'lodash';
import appStore from '@/stores/AppStore';
import expressStore from '@/stores/ExpressStore';
import ExpressAPI from '@/services/ExpressAPI';

class MockAPI {
	constructor() {
		appStore.currentLocation = {
			lat: '27.9506',
			lon: '-82.4572',
			name: 'Tampa'
		};
		appStore.isMobile = true;
		appStore.locale = 'en-US';
	}

	mockUserData() {
		const activePlan = _.cloneDeep(expressStore.activePlan);
		const activeGroup = _.cloneDeep(expressStore.activeGroup);
		const currentUserData = _.cloneDeep(_.get(userStore, 'userData') || {});
		const fakeGroupObj = {
			groupId: 'testGroup',
			planId: 'testPlan',
			tiers: [
				{
					tier: 1,
					network: '68895',
					coveredPercent: '100'
				},
				{
					tier: 2,
					network: '2599',
					coveredPercent: '80'
				}
			],
			deductible: 3000,
			oopMax: 5000,
			customAccumulators: {
				oopTier2: '7350.00',
				deductibleTier2: '1500.00',
				oopOutNetwork: '12000.00',
				deductibleOutNetwork: '5000.00'
			},
			telehealth: {
				phone: '##########',
				website: 'https://www.google.com',
				copay: '#'
			},
			...currentUserData.group
		};
		const userGroupData = {
			...fakeGroupObj,
			...activeGroup,
			...activePlan
		};
		if (userGroupData.tiers) {
			userGroupData.healthNetwork = null;
			userGroupData.healthNetworkName = null;
		}
		userStore.userData = {
			...currentUserData,
			user: {
				...currentUserData.user,
				contactInfo: {
					email: 'demo@carevalet.com',
					phone: '1112223333'
				}
			},
			member: {
				...currentUserData.member,
				_id: '123',
				firstName: 'Config',
				lastName: 'Demo',
				dob: '1991-02-25T04:00:00.000Z',
				healthPlan: 'dummy health plan',
				relation: 'self',
				memberId: '123456',
				type: 'express'
			},
			group: userGroupData
		};
	}
	async getMyAppointments() {
		return [
			{
				_id: '60538a7892bfc816095b43d5',
				requestDate: ['2021-03-23T17:14:16.369Z', '2021-03-24T17:14:16.369Z'],
				scheduler: '5e3d955bed290031b4c2c122',
				patient: {
					dependents: ['CV1001', 'CV1002', 'CV1008'],
					_id: '5dc0a437b738ae8e1892cd59',
					address: { street: '4009 tampa rd', city: 'oldsmar', state: 'fl', zipcode: '34677' },
					relation: 'self',
					status: 'active',
					memberId: 'CV1003',
					groupId: 'CAREVALET',
					lastName: 'Account 2',
					firstName: 'Employee',
					insurerCode: 'e95ac9a6f91c51f1a610a94a3351e279:42c34d5831d326097c76797ca586ad59',
					email: 'amanda@carevalet.com',
					hash: 'cvhash-2',
					dob: '1991-02-25T04:00:00.000Z',
					gender: 'f',
					startDate: '2019-03-01T05:00:00.000Z',
					termDate: '2025-10-16T04:00:00.000Z',
					coverageType: 'health',
					healthPlan: 'network-tiers'
				},
				npi: '1083727317',
				location: {
					_id: '7c3aefa6b195f308241c73a09e43efc1',
					NPI: '1083727317',
					street1: '1840 mease dr',
					street2: 'ste 402',
					city: 'safety harbor',
					state: 'fl',
					zip: '34695',
					networks: ['68895', '2599', '51252', '44349'],
					phone: '7273307652',
					fax: '7276697460',
					source: 'zelis',
					rawSpecialties: ['Internal Medicine', '207R00000X', 'Family Practice'],
					specialties: ['Primary Care', 'Internal Medicine'],
					longitude: '-82.706212',
					latitude: '28.039729',
					fullName: 'atta u butt',
					isPerson: true,
					lastUpdatedBy: '5f3c521e5905500db5051605',
					lastUpdated: '2021-02-02T22:55:44.118+00:00',
					surpressed: false,
					geoLocation: { coordinates: [-82.706212, 28.039729], type: 'Point' },
					cvKey: '10837273171840'
				},
				patientType: 'existing',
				requestType: 'first',
				requestTime: 'anytime',
				reason: '0',
				note: 'testing a new appointment for amanda blah blah blah blah blah blah blah blah blah',
				faxSent: false,
				createdDate: '2021-03-18T17:14:32.066Z',
				status: 'Requested',
				updatedDate: '2021-03-18T17:14:32.066Z',
				__v: 0,
				provider: {
					_id: '95363276d53670b6d9aa6294bfe0a487',
					NPI: '1083727317',
					isPerson: true,
					billingAddress: { street1: '1840 mease dr', street2: 'suite 402', city: 'safety harbor', zip: '34695', phone: '7276692969' },
					fullName: 'atta  butt',
					links: [],
					personDetails: { firstName: 'atta', lastName: 'butt', credential: 'MD', gender: 'm' },
					verified: false,
					lastUpdatedBy: '5f3c521e5905500db5051605',
					lastUpdated: '2021-02-02T22:47:13.204+00:00'
				}
			}
		];
	}
	async getCareTeam() {
		const providers = await this.providerSearch();
		const updateProviders = providers.map((x, i) => {
			const firstLocation = _.cloneDeep(x.locations[0]);
			if (i === 0) firstLocation.phone = '7273307652';
			x.location = firstLocation;
			x.specialty = x.allSpecialties[0];
			return x;
		});
		return updateProviders.slice(0, 3);
	}
	async getPlanDocuments(data) {
		return;
		// const cardInfo = await ExpressAPI.getIdCard('60534bf618c5ee2864bf3cf7', false);
		const front = await ExpressAPI.getDocumentAsset(
			'60534c5318c5ee2864bf3cf9',
			'background-image-node-99dbc65d-231f-476c-88f7-f3ffcb5c6558'
		);
		return {
			idCard: {
				cardFront: front,
				cardBack: front
			},
			statementOfBenefits: [front]
		};
	}

	async findGeoLocation() {
		return {
			candidates: [
				{
					_id: '5dfbd962c1860048b08d0cc8',
					zip: '33603',
					lat: '27.98544',
					lng: '-82.46462',
					city: 'Tampa',
					state_id: 'FL',
					state_name: 'Florida',
					zcta: 'TRUE',
					parent_zcta: '',
					population: '19100',
					density: '1782.6',
					county_fips: '12057',
					county_name: 'Hillsborough',
					all_county_weights: "{'12057':100}",
					imprecise: 'FALSE',
					military: 'FALSE',
					timezone: 'America/New_York',
					name: 'Tampa',
					formatted_address: 'Tampa Florida, 33603',
					lon: '-82.46462'
				}
			]
		};
	}

	async getProvider(npi) {
		const providers = await this.providerSearch();
		const matchingProvider = _.find(providers, { NPI: npi });
		return [matchingProvider];
	}

	async getProviderScores() {
		return { _id: '63768ebf282577d3ea758b54', cost: 4, overall_score: 3.5 };
	}

	async providerSearch() {
		let items = [
			{
				_id: '4106c52f15c4aa53ad26b644f05d8bab',
				NPI: '1376618470',
				isPerson: true,
				billingAddress: {
					street1: '1921 w dr martin luther king jr blvd',
					street2: '',
					city: 'tampa',
					zip: '33607',
					phone: '8138767600'
				},
				fullName: 'stanley robert dennison',
				links: [],
				personDetails: { firstName: 'stanley', lastName: 'dennison', credential: 'MD', gender: 'm' },
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2022-07-06T19:47:34.754+00:00',
				locations: [
					{
						_id: 'e7c47f13ec249d34384730e7285b50ad',
						NPI: '1376618470',
						street1: '13000 bruce b downs blvd',
						city: 'tampa',
						state: 'fl',
						zip: '33612',
						networks: ['9836', 'cvprime001'],
						phone: '8139722000',
						source: 'zelis',
						rawSpecialties: ['Pain Management'],
						specialties: ['Primary Care'],
						longitude: '-82.426170',
						latitude: '28.064675',
						fullName: 'stanley r dennison',
						isPerson: true,
						lastUpdatedBy: '5f3c521e5905500db5051605',
						lastUpdated: '2022-07-06T19:55:51.148+00:00',
						surpressed: false,
						geoLocation: { coordinates: [-82.42617, 28.064675], type: 'Point' },
						cvKey: '137661847013000'
					},
					{
						_id: 'cfef183d45ac2e1fcc9ca7093ae47db7',
						NPI: '1376618470',
						street1: '1921w 3lk jb blvd',
						street2: 'ste a',
						city: 'tampa',
						state: 'fl',
						zip: '33607',
						networks: ['2599', '51252', '68874', 'cvprime001'],
						phone: '8138767600',
						source: 'zelis',
						rawSpecialties: ['Pain Management'],
						specialties: ['Primary Care'],
						longitude: '-82.501354',
						latitude: '27.964768',
						fullName: 'stanley r dennison',
						isPerson: true,
						lastUpdatedBy: '5f3c521e5905500db5051605',
						lastUpdated: '2022-07-06T19:55:51.148+00:00',
						surpressed: false,
						geoLocation: { coordinates: [-82.501354, 27.964768], type: 'Point' },
						cvKey: '13766184701921W'
					},
					{
						_id: '0e9b4a31a8fbd87f49df5a68671ab223',
						NPI: '1376618470',
						street1: '1127 nikki view dr',
						city: 'brandon',
						state: 'fl',
						zip: '33511',
						networks: ['79500', '79325', '69050', '69051', '79328', '79392', 'cvprime001'],
						phone: '8138767600',
						source: 'zelis',
						rawSpecialties: ['Pain Management'],
						specialties: ['Primary Care'],
						longitude: '-82.324549',
						latitude: '27.919747',
						fullName: 'stanley r dennison',
						isPerson: true,
						lastUpdatedBy: '5f3c521e5905500db5051605',
						lastUpdated: '2022-07-06T19:55:51.148+00:00',
						surpressed: false,
						geoLocation: { coordinates: [-82.324549, 27.919747], type: 'Point' },
						cvKey: '13766184701127'
					},
					{
						_id: 'd09f1f8ea3814274d9115e0a222847b8',
						NPI: '1376618470',
						street1: '1921 w martin luther king jr blvd',
						street2: 'ste a',
						city: 'tampa',
						state: 'fl',
						zip: '33607',
						networks: ['79500', '79325', '69050', '69051', '9836', '2599', '79328', '79392', '51252', '44349', '68874', 'cvprime001'],
						phone: '8138767600',
						fax: '8138767675',
						source: 'zelis',
						rawSpecialties: ['Pain Management', '2086S0129X', '207LP2900X', '208VP0014X'],
						specialties: ['Primary Care', 'Other Medical Specialists'],
						longitude: '-82.479249',
						latitude: '27.981306',
						fullName: 'stanley r dennison',
						isPerson: true,
						lastUpdatedBy: '5f3c521e5905500db5051605',
						lastUpdated: '2022-07-06T19:55:51.148+00:00',
						surpressed: false,
						geoLocation: { coordinates: [-82.479249, 27.981306], type: 'Point' },
						cvKey: '13766184701921'
					},
					{
						_id: 'b3f783c646afc10433ba47cbc70a680d',
						NPI: '1376618470',
						street1: '3600 w kennedy blvd',
						city: 'tampa',
						state: 'fl',
						zip: '33609',
						networks: ['79500', '79325', '69050', '69051', '79328', '79392', 'cvprime001'],
						phone: '8138768700',
						source: 'zelis',
						rawSpecialties: ['Pain Management'],
						specialties: ['Primary Care'],
						longitude: '-82.501805',
						latitude: '27.944734',
						fullName: 'stanley r dennison',
						isPerson: true,
						lastUpdatedBy: '5f3c521e5905500db5051605',
						lastUpdated: '2022-07-06T19:55:51.148+00:00',
						surpressed: false,
						geoLocation: { coordinates: [-82.501805, 27.944734], type: 'Point' },
						cvKey: '13766184703600'
					},
					{
						_id: '0ae96a8151862ea9543fff77a9c46f09',
						NPI: '1376618470',
						street1: '1125 nikki view dr',
						city: 'brandon',
						state: 'fl',
						zip: '33511',
						networks: ['79500', '79325', '69050', '69051', '9836', '2599', '79328', '79392', '51252', '44349', '68874', 'cvprime001'],
						phone: '8138767600',
						source: 'zelis',
						rawSpecialties: ['Pain Management'],
						specialties: ['Primary Care'],
						longitude: '-82.324336',
						latitude: '27.919932',
						fullName: 'stanley r dennison',
						isPerson: true,
						lastUpdatedBy: '5f3c521e5905500db5051605',
						lastUpdated: '2022-07-06T19:55:51.148+00:00',
						surpressed: false,
						geoLocation: { coordinates: [-82.324336, 27.919932], type: 'Point' },
						cvKey: '13766184701125'
					}
				],
				networks: ['2599', '44349', '51252', '68874', '69050', '69051', '79325', '79328', '79392', '79500', '9836', 'cvprime001'],
				allSpecialties: ['Other Medical Specialists', 'Primary Care']
			},
			{
				_id: '1215040621',
				NPI: '1215040621',
				isPerson: true,
				billingAddress: {
					street1: '208 w. martin luther king jr. blvd.',
					street2: '',
					city: 'tampa',
					zip: '33603',
					phone: '8132218131'
				},
				fullName: 'keshava h babu',
				links: [],
				personDetails: {
					firstName: 'keshava',
					lastName: 'babu',
					credential: 'MD',
					gender: 'm'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Cardiology', 'Pediatrics'],
						_id: '1215040621',
						NPI: '1215040621',
						street1: '208 w martin luther king jr blvd',
						city: 'tampa',
						state: 'fl',
						zip: '33603',
						phone: '8132218131',
						fax: '8132218138',
						latitude: '27.981520',
						longitude: '-82.462439',
						geoLocation: {
							coordinates: [-82.462439, 27.98152],
							type: 'Point'
						},
						rawSpecialties: ['Pediatric Cardiology', 'Pediatrics', '2080P0202X']
					}
				],
				tier: 1,
				distance: 486.19569109775205,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Cardiology'],
				photo: 'https://asset2.betterdoctor.com/assets/general_doctor_male.png'
			},
			{
				_id: '1780675686',
				NPI: '1780675686',
				isPerson: true,
				billingAddress: {
					street1: '714 w martin luther king blvd',
					street2: '',
					city: 'tampa',
					zip: '336033104',
					phone: '8132236222'
				},
				fullName: 'stephen adebowale williams',
				links: [],
				personDetails: {
					firstName: 'stephen',
					lastName: 'williams',
					credential: 'MD',
					gender: 'm'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Pediatrics'],
						_id: '1780675686',
						NPI: '1780675686',
						street1: '714 w martin luther king jr blvd',
						city: 'tampa',
						state: 'fl',
						zip: '33603',
						phone: '8132236222',
						fax: '8132236020',
						latitude: '27.981552',
						longitude: '-82.468755',
						geoLocation: {
							coordinates: [-82.468755, 27.981552],
							type: 'Point'
						},
						rawSpecialties: ['Pediatrics', '208000000X']
					}
				],
				tier: 2,
				distance: 593.7620497908122,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Pediatrics']
			},
			{
				_id: '1679528111',
				NPI: '1679528111',
				isPerson: true,
				billingAddress: {
					street1: '2995 drew st fl 2',
					street2: '',
					city: 'clearwater',
					zip: '337593012',
					phone: '7275321355'
				},
				fullName: 'radhika  ranganathan',
				links: [],
				personDetails: {
					firstName: 'radhika',
					lastName: 'ranganathan',
					credential: 'MD',
					gender: 'f'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Pediatrics'],
						_id: '1679528111',
						NPI: '1679528111',
						street1: '308 e martin luther king jr blvd',
						street2: 'ste d',
						city: 'tampa',
						state: 'fl',
						zip: '33603',
						phone: '8132379900',
						latitude: '27.981537',
						longitude: '-82.457045',
						geoLocation: {
							coordinates: [-82.457045, 27.981537],
							type: 'Point'
						},
						rawSpecialties: ['Pediatrics']
					}
				],
				tier: 3,
				distance: 862.1344279838796,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Pediatrics'],
				photo: 'https://asset1.betterdoctor.com/images/53e829d14214f81c050001d2-1_thumbnail.jpg'
			},
			{
				_id: '1851558480',
				NPI: '1851558480',
				isPerson: true,
				billingAddress: {
					street1: '17809 saint lucia isle dr',
					street2: '',
					city: 'tampa',
					zip: '336472715',
					phone: '7184832933'
				},
				fullName: 'lalitha  raguthu',
				links: [],
				personDetails: {
					firstName: 'lalitha',
					lastName: 'raguthu',
					credential: 'M.D.',
					gender: 'f'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Pediatrics'],
						_id: '1851558480',
						NPI: '1851558480',
						street1: '507 e martin luther king jr blvd',
						street2: 'ste 112',
						city: 'tampa',
						state: 'fl',
						zip: '33603',
						phone: '8132296139',
						fax: '',
						latitude: '27.981434',
						longitude: '-82.454964',
						geoLocation: {
							coordinates: [-82.454964, 27.981434],
							type: 'Point'
						},
						rawSpecialties: ['Pediatrics', '208000000X']
					}
				],
				tier: 3,
				distance: 1048.7549951568428,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Pediatrics'],
				photo: 'https://asset1.betterdoctor.com/assets/general_doctor_female.png'
			},
			{
				_id: '1699769505',
				NPI: '1699769505',
				isPerson: true,
				billingAddress: {
					street1: '1213 w hillsborough ave',
					street2: '',
					city: 'tampa',
					zip: '336031313',
					phone: '8132341315'
				},
				fullName: 'daksha r patel',
				links: [],
				personDetails: {
					firstName: 'daksha',
					lastName: 'patel',
					credential: 'MD',
					gender: 'f'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Pediatrics'],
						_id: '1699769505',
						NPI: '1699769505',
						street1: '1213 w hillsborough ave',
						street2: 'ste a',
						city: 'tampa',
						state: 'fl',
						zip: '33603',
						phone: '8132341315',
						fax: '8132343354',
						latitude: '27.996058',
						longitude: '-82.473882',
						geoLocation: {
							coordinates: [-82.473882, 27.996058],
							type: 'Point'
						},
						rawSpecialties: ['Pediatrics', '208000000X']
					}
				],
				tier: 3,
				distance: 1491.9666140235656,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Pediatrics']
			},
			{
				_id: '1780688432',
				NPI: '1780688432',
				isPerson: true,
				billingAddress: {
					street1: '4635 westford cir',
					street2: '',
					city: 'tampa',
					zip: '336188357',
					phone: '8138731177'
				},
				fullName: 'diana patricia martin',
				links: [],
				personDetails: {
					firstName: 'diana',
					lastName: 'martin',
					credential: 'M.D.',
					gender: 'f'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Pediatrics', 'Other Medical Specialists', 'Allergy & Immunology'],
						_id: '1780688432',
						NPI: '1780688432',
						street1: '1918 w martin luther king jr blvd',
						city: 'tampa',
						state: 'fl',
						zip: '33607',
						phone: '8138731177',
						fax: '8138731166',
						latitude: '27.981276',
						longitude: '-82.479156',
						geoLocation: {
							coordinates: [-82.479156, 27.981276],
							type: 'Point'
						},
						rawSpecialties: ['Pediatrics', '207K00000X', 'Allergy & Immunology', '207KA0200X', 'Pediatric Allergy & Immunology']
					}
				],
				tier: 3,
				distance: 1502.2470039768639,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Allergy & Immunology', 'Other Medical Specialists', 'Pediatrics']
			},
			{
				_id: '1235127127',
				NPI: '1235127127',
				isPerson: true,
				billingAddress: {
					street1: 'po box 15876',
					street2: '',
					city: 'tampa',
					zip: '336845876',
					phone: '8138495437'
				},
				fullName: 'agustin alfonso martin',
				links: [],
				personDetails: {
					firstName: 'agustin',
					lastName: 'martin',
					credential: 'MD',
					gender: 'm'
				},
				verified: false,
				lastUpdatedBy: '5f3c521e5905500db5051605',
				lastUpdated: '2021-02-02T22:47:13.204+00:00',
				tierData: [
					[
						{
							tier: 3,
							network: '2599'
						}
					]
				],
				locations: [
					{
						networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
						specialties: ['Pediatrics'],
						_id: '1235127127',
						NPI: '1235127127',
						street1: '1922 w martin luther king jr blvd',
						city: 'tampa',
						state: 'fl',
						zip: '33607',
						phone: '8138495437',
						fax: '8138492624',
						latitude: '27.981276',
						longitude: '-82.479222',
						geoLocation: {
							coordinates: [-82.479222, 27.981276],
							type: 'Point'
						},
						rawSpecialties: ['Pediatrics', '208000000X']
					}
				],
				tier: 3,
				distance: 1508.4198054924389,
				networks: ['cveliance001', '2599', '51252', '68895', '69050', '69051', '79325', '79328', '79392'],
				allSpecialties: ['Pediatrics'],
				photo: 'https://asset3.betterdoctor.com/assets/general_doctor_male.png'
			}
		];
		const currentUserInfo = userStore.userData.group;
		if (currentUserInfo && currentUserInfo.tiers && currentUserInfo.tiers.length > 0) {
			items = items.map((x) => {
				x.tierData = currentUserInfo.tiers;
				x.tier = 5;
				return x;
			});
			const firstTier = currentUserInfo.tiers[0];
			items[0].tier = firstTier.tier;
			items[0].networks.push(firstTier.network);
			const secondTier = currentUserInfo.tiers[1];
			if (secondTier) {
				items[1].tier = secondTier.tier;
				items[1].networks.push(secondTier.network);
			}
		}
		return items;
	}

	async getNearbyPharmacyByName() {
		return [
			{
				_id: '7dbf379b7e2763fe1568bf5b9e4c3e8d',
				cvID: '4007395000-7632538100',
				fullName: 'wal-mart pharmacy 10-5200',
				searchname: 'walmartpharmacy105200',
				street1: '2030 fruitville pike',
				street2: '',
				city: 'lancaster',
				state: 'pa',
				zip: '17601',
				geoLocation: { coordinates: [-76.325381, 40.07395], type: 'Point' },
				phone: '7175811500',
				status: 'open',
				distance: 923.5358732525264
			},
			{
				_id: 'bb7c180884c22b6d991ff9a23af09107',
				cvID: '4002957500-7624118600',
				fullName: 'wal-mart pharmacy 10-2334',
				searchname: 'walmartpharmacy102334',
				street1: '2034 lincoln hwy east',
				street2: '',
				city: 'lancaster',
				state: 'pa',
				zip: '17602',
				geoLocation: { coordinates: [-76.241186, 40.029575], type: 'Point' },
				phone: '7173907031',
				status: 'open',
				distance: 7942.0489128312165
			},
			{
				_id: 'a019e520db9b04a686a8301f59f82258',
				cvID: '4016720200-7615271000',
				fullName: 'wal-mart pharmacy 10-2340',
				searchname: 'walmartpharmacy102340',
				street1: '890 east main street',
				street2: '',
				city: 'ephrata',
				state: 'pa',
				zip: '17522',
				geoLocation: { coordinates: [-76.15271, 40.167202], type: 'Point' },
				phone: '7177216690',
				status: 'open',
				distance: 17274.53645484918
			}
		];
	}

	async getUserPrescriptions() {
		return [
			{
				_id: '5fc927ab14749e8348519031',
				name: 'allegra',
				form: 'tablet',
				dosage: '12 hour',
				quantity: '20',
				pharmacy: 'Tampa Road Pharmacy',
				price: 10.96,
				ncpdp: '5724198',
				coupon_key:
					'Wma6pnFX2arjGdEDysMy8fnFjIGK09jmFONLCljVU3CrYWxcUMT1pGSnFjJC9RRxmNEWgBihautGtLrTkO_FFkLKaZqEZn-e_lYDf23omCPAT92uQuy4-dHgfD3WWJ2R28ngzgpLz6FtncE8AzBnw0QiO3o7Tg4=',
				coupon_url:
					'https://www.goodrx.com/coupon?drug_id=8586&pharmacy_id=166598&quantity=20&extras=EabyoTXSDnsfJwEgDTY7ub3BXGc%3D+d50fAXsibmV0d29yayI6IG51bGwsICJzZWFyY2hfdGltZSI6IDE2MDcwMTg0MDcuNzcwMDQ1LCAicHJpY2UiOiAxMC45NjE2NjYxMDcxNzc3MzQsICJsYXQiOiAyOC4wNDU2NDksICJsb24iOiAtODIuNjg3MTA0LCAiZGlzdGFuY2VfbWkiOiA2MCwgInppcF9jb2RlIjogIjM0Njc3IiwgInByaWNlX2ZpbHRlcnMiOiBbXSwgInBoYXJtX2ZpbHRlcnMiOiBudWxsfQ%3D%3D&c=carevalet',
				userId: '5e3d955bed290031b4c2c122',
				__v: 0
			},
			{
				_id: '5fc92bc5b7ffd65c71ecd66c',
				name: 'tylenol',
				form: 'tablet',
				dosage: '325mg',
				quantity: '20',
				pharmacy: 'Walmart',
				price: 2.34,
				ncpdp: null,
				coupon_key:
					'wvztTuMZMtt7-6FrG6N1Ju_K7bGSWT_gh9Xmagjll83AwHW7MMN5Q1WaTTvp-eC5SAq4c7hhVX9JiA1WQT_mApdfEkK2btoC5yZId6H4G5HYBkp_QiDJFjBlzWF9nsROPCJel0FUoL8rlSB3WRDP2P9vEU79MA==',
				coupon_url:
					'https://www.goodrx.com/coupon?drug_id=7790&pharmacy_id=1&quantity=20&extras=KXYDjRGae2n1MaQnzzbG5icBOls%3D+icV9YHsibmV0d29yayI6IG51bGwsICJzZWFyY2hfdGltZSI6IDE2MDcwMTk0NTYuNTM1MTcwMywgInByaWNlIjogMi4zMzk2Nzk5NTY0MzYxNTcyLCAibGF0IjogMjguMDQ1NjQ5LCAibG9uIjogLTgyLjY4NzEwNCwgImRpc3RhbmNlX21pIjogNjAsICJ6aXBfY29kZSI6ICIzNDY3NyIsICJwcmljZV9maWx0ZXJzIjogW10sICJwaGFybV9maWx0ZXJzIjogbnVsbH0%3D&c=carevalet',
				userId: '5e3d955bed290031b4c2c122',
				__v: 0
			},
			{
				_id: '5fc92bd7b7ffd65c71ecd66d',
				name: 'zyrtec-d',
				form: 'tablet',
				dosage: '12 hour',
				quantity: '12',
				pharmacy: 'Community, a Walgreens Pharmacy',
				price: 10.07,
				ncpdp: null,
				coupon_key:
					'YMula3-6XZHZSvytUJ_OaNS3cwTdrqgeOZdBbtrq1mPrlAYCB2J7ZRQ-gk14ZrE_csC8l5wtadO6v0BVTfKDEuJwP8Cxdl3SZUUZ_9LUPXhSkJeNOrn8jAeUQdHY1ei5RwqKjgdUnP_IPjjKsqL8NANvY5qb2M3tWmc8sQ==',
				coupon_url:
					'https://www.goodrx.com/coupon?drug_id=31187&pharmacy_id=125173&quantity=12&extras=cgIk6Qv3-Qj8b6yP2_1yct-txS0%3D+7p3FlHsibmV0d29yayI6IG51bGwsICJzZWFyY2hfdGltZSI6IDE2MDcwMTk0NzIuOTUzMDc2OCwgInByaWNlIjogMTAuMDcyNjc0NzUxMjgxNzM4LCAibGF0IjogMjguMDQ1NjQ5LCAibG9uIjogLTgyLjY4NzEwNCwgImRpc3RhbmNlX21pIjogNjAsICJ6aXBfY29kZSI6ICIzNDY3NyIsICJwcmljZV9maWx0ZXJzIjogW10sICJwaGFybV9maWx0ZXJzIjogbnVsbH0%3D&c=carevalet',
				userId: '5e3d955bed290031b4c2c122',
				__v: 0
			}
		];
	}

	// const idCards = await ExpressAPI.listIdCards('all', false);
	// console.log({ idCards });
	get(url, noToken, noBase) {
		return Promise.resolve([]);
	}

	post(url, data, noToken, noBase) {
		return Promise.resolve([]);
	}

	getAccumulators() {
		return Promise.resolve([
			{ _id: 'oop', value: 1025, type: 'oop' },
			{ _id: 'deductible', value: 1025, type: 'deductible' },
			{ _id: 'oopTier2', type: 'oopTier2', value: '735.00' },
			{ _id: 'deductibleTier2', type: 'deductibleTier2', value: '150.00' },
			{ _id: 'oopOutNetwork', type: 'oopOutNetwork', value: '100.00' },
			{ _id: 'deductibleOutNetwork', type: 'deductibleOutNetwork', value: '500.00' }
		]);
	}
}
export default new MockAPI();
