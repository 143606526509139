import _ from 'lodash';
import screeningStore from '@/stores/ScreeningStore';

class ScreeningService {
	constructor() {
		this.msg1 = 'no_testing';
		this.msg2 = 'contact_provider';
		this.msg3 = 'contact_provider_testing_recommended';
		this.msg4 = 'testing_recommended';
		this.msg5 = 'contact_occupational_health';
		this.msg6 = 'contact_facility';
		this.msg7 = 'contact_provider';
		this.msg8 = 'self_isolate';
		this.msg9 = 'home_quarantine';
		this.msg10 = 'social_distance';
		this.msg11 = 'monitor_symptoms';
		this.screeningScript = {
			intro: {
				type: 'intro',
				next: 'emergency',
				choices: [
					{
						key: 'intro_self',
						text: 'Use for myself'
					},
					{
						key: 'intro_other',
						text: 'Use for someone else'
					}
				]
			},
			emergency: {
				type: 'emergency',
				next: 'age',
				choices: [
					{
						key: 'emergency_experiencing',
						text: "{I'm|They're} experiencing one of these"
					},
					{
						key: 'emergency_none',
						text: '{I|They} do not have any of these'
					}
				]
			},

			age: {
				header: 'How old are {you|they}?',
				type: 'single',
				choices: [
					{
						key: 'age_under18',
						text: 'Under 18',
						next: 'under18'
					},
					{
						key: 'age_between1864',
						text: 'Between 18 and 64',
						next: 'symptoms'
					},
					{
						key: 'age_greater65',
						text: 'Greater than 65',
						next: 'symptoms'
					}
				]
			},
			under18: {
				header: 'This tool is intended for people who are at least 18 years old',
				details: 'Visit the CDC site to get information about COVID‑19 and younger people.',
				type: 'end'
			},
			symptoms: {
				header: 'Have {you|they} recently started experiencing any of these symptoms?',
				details: 'Select all that apply',
				type: 'multi',
				next: 'severity',
				none: 'conditions',
				choices: [
					{
						key: 'symptoms_covid_fever',
						text: 'Fever or chills'
					},
					{
						key: 'symptoms_covid_breathing',
						text: 'Mild or moderate difficulty breathing'
					},
					{
						key: 'symptoms_covid_cough',
						text: 'New or worsening cough'
					},
					{
						key: 'symptoms_other_tasteSmell',
						text: 'Sudden loss of taste or smell'
					},
					{
						key: 'symptoms_other_soreThroat',
						text: 'Sore throat'
					},
					{
						key: 'symptoms_other_vomiting',
						text: 'Vomiting or diarrhea'
					},
					{
						key: 'symptoms_other_fatigue',
						text: 'Unexplained, significant fatigue or aching throughout the body'
					},
					{
						key: 'symptoms_none',
						text: 'None of the above',
						type: 'none'
					}
				]
			},
			severity: {
				header: 'How severe are {your|their} symptoms?',
				type: 'single',
				choices: [
					{
						key: 'severity_none',
						text: 'Symptoms have little to no impact.',
						detail: '{I|They} am able to do normal activities like exercising',
						next: 'conditions'
					},
					{
						key: 'severity_minor',
						text: 'Symptoms having some impact.',
						detail: '{I|They} can only do some daily activities like cleaning the house or making food.',
						next: 'conditions'
					},
					{
						key: 'severity_major',
						text: 'Symptoms are having a major impact.',
						detail: "{I|They} can only do what's necessary such as using the bathroom or eating.",
						next: 'conditions'
					}
				]
			},
			conditions: {
				header: 'Do any of these apply to {you|them}?',
				description: 'Select all that apply',
				type: 'multi',
				next: 'exposure',
				none: 'exposure',
				choices: [
					{
						key: 'conditions_obesity',
						text: 'Obesity'
					},
					{
						key: 'conditions_smokingVaping',
						text: 'Smoking or vaping'
					},
					{
						key: 'conditions_pregnancy',
						text: 'Pregnancy'
					},
					{
						key: 'conditions_diabetes',
						text: 'Diabetes, high blood pressure, chronic kidney disease, or liver disease'
					},
					{
						key: 'conditions_lung',
						text:
							'Chronic lung disease, such as moderate to severe asthma, COPD (chronic obstructive pulmonary disease), cystic fibrosis, or pulmonary fibrosis'
					},
					{
						key: 'conditions_heart',
						text: 'Serious heart condition, such as heart failure, prior heart attack, or cardiomyopathy'
					},
					{
						key: 'conditions_immune',
						text: 'Weakened immune system from HIV, cancer treatment, use of medicines causing immune suppression, or other factors'
					},
					{
						key: 'conditions_stroke',
						text: 'Stroke, dementia, or other cerebrovascular disease or neurologic condition'
					},
					{
						key: 'conditions_sickle',
						text: 'Sickle cell disease, thalassemia, or other blood disorder'
					},
					{
						key: 'conditions_none',
						text: 'None of the above',
						type: 'none'
					}
				]
			},
			exposure: {
				header: 'In the last 14 days, did any of these apply to {you|them}?',
				description: 'Select all that apply',
				type: 'multi',
				next: 'careFacilityLive',
				none: 'careFacilityLive',
				choices: [
					{
						key: 'exposure_lived',
						text: '{I|They} live with someone who has COVID-19'
					},
					{
						key: 'exposure_cared',
						text: '{I|They} cared for someone who has COVID-19'
					},
					{
						key: 'exposure_sixFeet',
						text: 'For at least 15 minutes, {I was|they were} within 6 feet of someone who has COVID-19'
					},
					{
						key: 'exposure_maybe',
						text: '{I|They} might have been exposed to COVID-19'
					},
					{
						key: 'exposure_none',
						text: "{I've|They have} had no known exposure to COVID-19",
						type: 'none'
					}
				]
			},
			careFacilityLive: {
				header: 'Do {you|they} live in a long-term care facility?',
				description: 'This includes nursing homes or assisted living facilities.',
				type: 'single',
				choices: [
					{
						key: 'careFacilityLive_yes',
						text: '{I|They} live in a long-term care facility',
						next: 'careFacilityWork'
					},
					{
						key: 'careFacilityLive_no',
						text: "{I|They} don't live in a long-term care facility",
						next: 'careFacilityWork'
					}
				]
			},
			careFacilityWork: {
				header: 'In the last 14 days, did {you|they} work or volunteer in a healthcare facility?',
				description: 'This includes a clinic, hospital, emergency room, other medical setting, or long-term care facility.',
				type: 'single',
				choices: [
					{
						key: 'careFacilityWork_yes',
						text: '{I|They} worked or volunteered in a healthcare facility in the last 14 days',
						next: 'ppe'
					},
					{
						key: 'careFacilityWork_no',
						text: '{I|They} have not worked or volunteered in a healthcare facility in the last 14 days',
						next: 'getOutcome'
					}
				]
			},
			ppe: {
				header: 'Did {you|they} wear the recommended personal protective equipment while at the healthcare facility?',
				type: 'single',
				choices: [
					{
						key: 'ppe_yes',
						text: 'Yes',
						next: 'getOutcome'
					},
					{
						key: 'ppe_no',
						text: 'No',
						next: 'getOutcome'
					}
				]
			}
		};
	}

	getScript() {
		return this.screeningScript;
	}

	onSelect(value) {
		const current = _.get(screeningStore, 'currentQuestion', 'intro');
		const currentObj = this.screeningScript[current];
		const type = _.get(currentObj, 'type');
		switch (type) {
			case 'single':
				screeningStore.results[current] = value.key;
				break;
			case 'multi':
				this.setMultiResult(value);
				break;
			case 'emergency':
			case 'intro':
				screeningStore.results[current] = value.key;
			default:
				break;
		}

		this.setNextQuestion();
	}

	setMultiResult(value) {
		const current = _.get(screeningStore, 'currentQuestion', 'intro');
		const currentObj = this.screeningScript[current];

		if (screeningStore.results[current].filter((i) => i.split('_')[1] === 'none').length > 0) {
			screeningStore.results[current] = [];
		}

		console.log(_.get(value, 'type', ''))

		if (_.get(value, 'type', '') === 'none') {
			screeningStore.results[current] = [value.key];
		} else {
			// screeningStore.nextQuestion = currentObj.next;
			var index = screeningStore.results[current].indexOf(value.key);
			if (index >= 0) {
				screeningStore.results[current].splice(index, 1);
			} else {
				screeningStore.results[current].push(value.key);
			}
		}
	}

	

	onNext() {
		const next = _.get(screeningStore, 'nextQuestion', '');
		if (next === 'getOutcome') {
			screeningStore.completed = true;
		} else {
			if (next !== '') {
				screeningStore.questionTrail.push(screeningStore.currentQuestion);
				screeningStore.currentQuestion = next;
				this.setNextQuestion();
			}
		}
	}

	onBack() {
		let previous = '';
		if (screeningStore.completed) {
			screeningStore.completed = false;
			previous = screeningStore.currentQuestion;
		} else {
			previous = _.last(screeningStore.questionTrail);
			if (previous !== '') {
				screeningStore.currentQuestion = previous;
				this.setNextQuestion();
				screeningStore.questionTrail.pop();
			}
		}
	
		screeningStore.completed = false;

		if (screeningStore.call911) {
			screeningStore.call911 = false;
			this.reset();
		}
	}

	setNextQuestion() {
		const current = _.get(screeningStore, 'currentQuestion', 'intro');
		const currentObj = this.screeningScript[current];
		const type = _.get(currentObj, 'type');

		const result = _.get(screeningStore.results, current, '');
		if ((type !== 'multi' && result !== '') || result.length > 0) {
			console.log(result > 0)
			switch (type) {
				case 'single':
					const choiceObj = _.find(currentObj.choices, { key: screeningStore.results[current] });
					screeningStore.nextQuestion = _.get(choiceObj, 'next', '');
					break;
				case 'multi':
					if (screeningStore.results[current].filter((i) => i.split('_')[1] === 'none').length > 0) {
						screeningStore.nextQuestion = currentObj.none;
					} else {
						screeningStore.nextQuestion = currentObj.next;
					}
					break;
				default:
					screeningStore.nextQuestion = currentObj.next;
			}
		} else {
			screeningStore.nextQuestion = '';
		}
	}

	pronounPlural(text) {
		const regExp = /[^{\}]+(?=})/g;
		const matches = text.match(regExp);
		if (matches && matches.length > 0) {
			const proNoun = screeningStore.results.intro === 'intro_self' ? matches[0].split('|')[0] : matches[0].split('|')[1];
			return text.replace(/{([^}]+)}/, proNoun);
		} else {
			return text;
		}
	}

	generateOutcome() {
		const { age, symptoms, severity, conditions, exposure, careFacilityLive, careFacilityWork, ppe } = screeningStore.results;
		const greaterThan65 = age === 'age_greater65';
		const covidSymptomCount = symptoms.filter((i) => i.split('_')[1] == 'covid').length;
		const otherSymptomCount = symptoms.filter((i) => i.split('_')[1] == 'other').length;
		const exposed = !exposure.includes('exposure_none');
		const conditionCount = conditions.length;
		let result = [];

		if(this.isSymptomatic(symptoms)) { // Symptomatic
			result.push(this.msg2);
			result.push(this.msg4);
			result.push(this.msg8);
			console.log('HAS SYMPTOMS')
			if(exposed) { // Exposed
				if (greaterThan65 && conditionCount >= 1 && (covidSymptomCount > 0 || otherSymptomCount > 0)) {
					result.push(this.msg3);
				} else if (!greaterThan65 && conditionCount >= 1 && (covidSymptomCount > 0 || otherSymptomCount > 0)) {
					result.push(this.msg3);
				} else if (greaterThan65 && conditionCount === 0 && covidSymptomCount > 0) {
					result.push(this.msg3);
				} else if (this.isSymptomSevere(severity)) {
					result.push(this.msg3);
				}

				if (careFacilityLive === 'careFacilityLive_yes') {
					result.push(this.msg6);
				}
				if (careFacilityWork === 'careFacilityWork_yes') {
					result.push(this.msg5);
				}
			} else { // Symptomatic not exposed
				console.log('NOT EXPOSED')
				if (greaterThan65 && conditionCount >= 1 && covidSymptomCount >= 1) {
					result.push(this.msg3);
					result.push(this.msg2);
					result.push(this.msg8);
				} else if (greaterThan65 && conditionCount >= 1 && covidSymptomCount === 0 && otherSymptomCount > 0) {
					result.push(this.msg2);
					result.push(this.msg8);
				} else if (!greaterThan65 && conditionCount >= 1 && covidSymptomCount >= 1) {
					result.push(this.msg3);
					result.push(this.msg2);
					result.push(this.msg8);
				} else if (!greaterThan65 && conditionCount >= 1 && covidSymptomCount === 0 && otherSymptomCount >= 1) {
					result.push(this.msg2);
					result.push(this.msg8);
				} else if (!greaterThan65 && conditionCount == 0 && covidSymptomCount > 0) {
					result.push(this.msg2);
					result.push(this.msg8);
				} else {
					// result.push(this.msg1);
					result.push(this.msg2);
					result.push(this.msg8);
				}
			}
		} else  { //asymptomatic
			console.log('HAS NO SYMPTONS')
			if (exposed) { //asymptomatic exposed
				console.log('WAS EXPOSED');

			
				if (careFacilityWork === 'careFacilityWork_yes' && this.isSevereExposure(exposure)) {
					console.log('WORKS AT FACILITY AND WAS EXPOSED SEVERE');
					result.push(this.msg5);
					result.push(this.msg4);
					result.push(this.msg8);
				} else if (this.isSevereExposure(exposure)) {
					console.log('DOES NOT WORK AT FACILITY AND WAS EXPOSED SEVERE');
					result.push(this.msg9);
					result.push(this.msg1);
				} else {
					console.log('MINOR EXPOSURE');
					result.push(this.msg1);
					result.push(this.msg10);
				}
	 
			}  else {
				console.log('NO SYMPTOMS, NO EXPOSURE');
				result.push(this.msg1);
				result.push(this.msg10);
			}
		} 



		console.log(result);
		return result;
	}

	isSevereExposure(exposure) {
		let seriousItems = ['exposure_lived', 'exposure_cared', 'exposure_sixFeet'];
		seriousItems.forEach((item) => {
			if (exposure.includes(item)) {
				return true;
			}
		});
		return false;
	}

	isSymptomSevere(severity) {
		return severity.includes('severity_major');
	}

	isSymptomatic(symptoms) {
		// console.log(symptoms);
		return !symptoms.includes('symptoms_none');
	}

	isTestingRecommended(outcome) {
		return outcome.includes('contact_provider_testing_recommended') || outcome.includes('testing_recommended')
	}
	

	reset() {
		screeningStore.currentQuestion = 'intro';
		screeningStore.questionTrail = [];
		screeningStore.call911 = false;
		screeningStore.completed = false;
		screeningStore.nextQuestion = '';
		screeningStore.results = {
			intro: '',
			age: '',
			symptoms: [],
			severity: '',
			conditions: [],
			exposure: [],
			careFacilityLive: '',
			careFacilityWork: '',
			ppe: ''
		};
	}
}

export default new ScreeningService();
