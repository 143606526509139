import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../../../utils/Localization';
import { autoBind } from '../../../../../utils/GeneralUtils';
import { observer, observable } from '../../../../../utils/State';
import BasicInput from '../../../../../components/basic-input/BasicInput';

@observer
export default class SsnLookup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			lastName: '',
			zip: '',
			ssn: ''
		});
		this.text = getCmpText('EmployerSignup', {
			ssnLabel: 'SSN',
			ssnPlaceholder: '555555555',
			lastNameLabel: 'Last Name',
			lastNamePlaceholder: 'Doe',
			zipLabel: 'Zip Code',
			zipPlaceholder: '12345'
		});
		autoBind(this);
	}

	componentDidMount() {
		this.props.formCallback({ values: _.cloneDeep(this.data), isValid: this.validateForm(), type: 'ssn' });
	}

	validateForm() {
		const hasZip = _.get(this.data, 'zip', '').length > 0;
		const hasSSN = _.get(this.data, 'ssn', '').length > 0;
		const hasLast = _.get(this.data, 'lastName', '').length > 0;
		return hasLast && hasSSN && hasZip;
	}

	onInputChange(evt) {
		const field = evt.currentTarget.name;
		let value = evt.currentTarget.value || '';
		if (field === 'ssn') {
			value = value.trim();
		}
		this.data[field] = value;
		this.props.formCallback({ values: _.cloneDeep(this.data), isValid: this.validateForm(), type: 'ssn' });
	}

	render() {
		return (
			<div>
				<BasicInput
					name="lastName"
					onChange={this.onInputChange}
					label={this.text.lastNameLabel}
					value={this.data.lastName}
					type="text"
					placeholder={this.text.lastNamePlaceholder}
				/>
				<BasicInput
					name="ssn"
					onChange={this.onInputChange}
					label={this.text.ssnLabel}
					value={this.data.ssn}
					type="number"
					maxLength={9}
					placeholder={this.text.ssnPlaceholder}
				/>
				<BasicInput
					name="zip"
					onChange={this.onInputChange}
					label={this.text.zipLabel}
					value={this.data.zip}
					type="number"
					maxLength={5}
					placeholder={this.text.zipPlaceholder}
				/>
			</div>
		);
	}
}
