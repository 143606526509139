import { autoBind } from '@/utils/GeneralUtils';
import { Preloader } from 'framework7-react';
import React, { Component } from 'react';
import { observer } from '../../../utils/State';
import './metrics-bar.scss';
@observer
export default class MetricsBar extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	loadMetrics() {
		if (this.props.loadMetrics) {
			this.props.loadMetrics();
		}
	}

	render() {
		let { metrics, showRefresh, title, loading } = this.props;
		return (
			<div className="express-metrics-bar">
				<h3 className="category hbox vcenter">
					<div className="grow-1">{title}</div>{' '}
					{showRefresh && <i className="fad fa-sync tooltip-init" data-tooltip="Refresh Metrics" onClick={this.loadMetrics}></i>}
				</h3>
				<div className="metrics-ctn hbox vcenter">
					<div className="metric">
						<div className="label">Groups</div>
						<div className="value">{loading ? <Preloader color="blue" size="26"></Preloader> : metrics.groups}</div>
					</div>
					<div className="metric">
						<div className="label">Plans</div>
						<div className="value">{loading ? <Preloader color="blue" size="26"></Preloader> : metrics.plans}</div>
					</div>
					<div className="metric">
						<div className="label">Total Members</div>
						<div className="value">{loading ? <Preloader color="blue" size="26"></Preloader> : metrics.totalMembers}</div>
					</div>
					<div className="metric">
						<div className="label">Primary Members</div>
						<div className="value">{loading ? <Preloader color="blue" size="26"></Preloader> : metrics.primaryMembers}</div>
					</div>
					<div className="metric">
						<div className="label">Dependent Members</div>
						<div className="value">
							{loading ? (
								<Preloader color="blue" size="26"></Preloader>
							) : (
								Number(metrics.totalMembers) - Number(metrics.primaryMembers)
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
