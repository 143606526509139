import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Button, ListInput, List, Popup, Toolbar } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { autoBind } from '../../utils/GeneralUtils';
import adminStore from '../../stores/AdminStore';
import CloudAPI from '../../services/CloudAPI';
import AdminService from '../../services/AdminService';
import './messaging-form.scss';

@observer
export default class MessagingForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			notificationTitle: null,
			notificationBody: null
		});
		autoBind(this);
	}

	close() {
		this.props.close();
	}

	clearAll() {
		AdminService.messagesRemoveAllPending();
		this.close();
	}
	handleBulkSelection(evt) {
		const type = evt.currentTarget.getAttribute('data-type');
		const isActive = evt.currentTarget.getAttribute('data-active') === 'true';
		if (isActive) {
			AdminService.messagesBulkRemoveType(_.cloneDeep(adminStore.pendingRecipients), type, false);
		} else {
			AdminService.messagesBulkAddType(_.cloneDeep(adminStore.pendingRecipients), type);
		}
	}
	removeRecipient(evt) {
		const uid = evt.currentTarget.getAttribute('data-recipient');
		AdminService.messageRemovePending(uid, 'all');
	}
	updateRecipientSelections(evt) {
		const type = evt.currentTarget.getAttribute('data-type');
		const status = evt.currentTarget.getAttribute('data-status');
		const memberUid = evt.currentTarget.getAttribute('data-member');
		if (status !== 'active') {
			AdminService.messageAddPending(memberUid, type);
		} else {
			AdminService.messageRemovePending(memberUid, type);
		}
	}
	async sendMessages() {
		this.$f7.dialog.preloader('Sending Messages');
		const languages = AdminService.getMessagingLanguages();
		for (let language of languages) {
			const recipients = adminStore.pendingRecipients.filter((recipient) => recipient.languageDisplay === language);
			const recipientIds = recipients.map((recipient) => recipient.memberUid);
			const messages = adminStore.pendingMessages.filter((message) => recipientIds && recipientIds.indexOf(message.memberUid) > -1);
			const messageBody = this.data[`notificationBody-${language}`];
			const messageTitle = this.data[`notificationTitle-${language}`];

			const pushNotifications = messages
				.filter((x) => x.type === 'PUSH')
				.map((message) => {
					const recipientData = _.find(recipients, { memberUid: message.memberUid });
					return { device: recipientData.devices, memberUid: message.memberUid };
				});
			const pushNotificationDevices = _.flattenDeep(pushNotifications);

			const textNotifications = messages
				.filter((x) => x.type === 'TEXT')
				.map((message) => {
					const recipientData = _.find(recipients, { memberUid: message.memberUid });
					return { phone: recipientData.phone, memberUid: message.memberUid };
				});
			const textNotificationNumbers = _.flattenDeep(textNotifications);

			const emailNotifications = messages
				.filter((x) => x.type === 'EMAIL')
				.map((message) => {
					const recipientData = _.find(recipients, { memberUid: message.memberUid });
					return { email: recipientData.email, memberUid: message.memberUid };
				});
			const emailNotificationAddresses = _.flattenDeep(emailNotifications);
			try {
				if (pushNotificationDevices && pushNotificationDevices.length > 0) {
					await CloudAPI.sendPushNotifications(
						messageTitle,
						messageBody,
						pushNotificationDevices.filter((x) => x)
					);
				}
				if (textNotificationNumbers && textNotificationNumbers.length > 0) {
					await CloudAPI.sendTextMessages(messageBody, textNotificationNumbers);
				}
				if (emailNotificationAddresses && emailNotificationAddresses.length > 0) {
					await CloudAPI.sendEmailMessages(messageTitle, messageBody, emailNotificationAddresses);
				}
			} catch (err) {
				console.log({ err });
				this.$f7.dialog.close();
				this.$f7.dialog.alert(`Error sending messages in ${language}: ${err}`);
			}
		}
		this.$f7.dialog.close();
		this.$f7.dialog.alert('Messages Sent');
		this.clearAll();
	}
	handleFormChange(evt) {
		const formField = evt.currentTarget.name;
		const formValue = evt.currentTarget.value;
		this.data[formField] = formValue;
	}
	validateForm() {
		const pendingMessages = AdminService.getMessagesPending();
		const hasMessageSelections = pendingMessages && pendingMessages.length > 0;
		const languages = AdminService.getMessagingLanguages();
		const languageBodies = languages.map((language) => this.data[`notificationBody-${language}`]).filter((body) => !!body);
		const hasBodyForEachLanguage = languageBodies.length === languages.length;
		return hasMessageSelections && hasBodyForEachLanguage;
	}

	render() {
		const { isOpen, showBulkOptions } = this.props;
		const canSubmit = this.validateForm();
		const notificationTypesAvailable = AdminService.getMessagingOptions();
		const recipientData = AdminService.getMessagingRecipientsData();
		const showMemberSummary = recipientData && recipientData.length > 0;
		const languages = AdminService.getMessagingLanguages();
		return (
			<Popup opened={isOpen} className="messaging-form-popup" closeByBackdropClick={false} closeOnEscape={false} swipeToClose={false}>
				{isOpen && (
					<Fragment>
						<Toolbar className="messaging-header">
							<h2>
								Send Notifications
								<i className="fad fa-times-square" onClick={this.close} />
							</h2>
						</Toolbar>
						<div className="group-mgmt-notification-form y-scroll">
							{notificationTypesAvailable && (
								<div className="notification-summary">
									{showBulkOptions && (
										<Fragment>
											<h4>
												Summary
												<Button onClick={this.clearAll}>Clear All</Button>
											</h4>
											<div className="bulk-notification-options">
												{notificationTypesAvailable &&
													notificationTypesAvailable.map((option) => {
														const isActive = option.pending;
														return (
															<div
																key={`bulk-option-${option.type}`}
																className="bulk-notification-option"
																data-active={isActive}
																data-type={option.type}
																onClick={this.handleBulkSelection}
															>
																<i className={`${isActive ? 'fad fa-check-circle' : 'fal fa-circle'}`} />
																{`${option.type.toLowerCase()} All`}
															</div>
														);
													})}
											</div>
										</Fragment>
									)}
									{showMemberSummary && !!recipientData && (
										<Fragment>
											{recipientData.map((recipient) => {
												const recipientOptions = AdminService.getMessagingRecipientStatus(recipient);
												return (
													<div key={`member-${recipient.memberUid}`} className="member-notification-summary">
														<div className="notification-summary-name">
															<div className="remove-container" onClick={this.removeRecipient} data-recipient={recipient.memberUid}>
																<i className="fad fa-times" />
															</div>
															{`${recipient.firstName} ${recipient.lastName}`}
														</div>
														<div className="member-action-items">
															{recipientOptions.map((option) => {
																const isPending = option.pending;
																return (
																	<div
																		className={`member-action-item ${isPending ? 'active' : 'inactive'} ${
																			option.disabled ? 'disabled' : ''
																		}`}
																		key={`${recipient.memberUid}-option-${option.type}`}
																		data-type={option.type}
																		onClick={this.updateRecipientSelections}
																		data-status={isPending ? 'active' : 'inactive'}
																		data-member={recipient.memberUid}
																	>
																		<i className={option.icon} />
																	</div>
																);
															})}
														</div>
													</div>
												);
											})}
										</Fragment>
									)}
								</div>
							)}
							{languages.map((language) => {
								return (
									<div className="message-language-form-container" key={`message-language-${language}`}>
										<h4>{`Message(${language})`}</h4>
										<List noHairlines className="message-form">
											<ListInput
												label="Notification Title"
												type="text"
												placeholder="Notification Title"
												className="md"
												floatingLabel
												info="*Push Notifications and Emails Only"
												onChange={this.handleFormChange}
												name={`notificationTitle-${language}`}
												value={this.data[`notificationTitle-${language}`] || ''}
											/>
											<ListInput
												label="Message Body"
												type="textarea"
												placeholder="Message Body"
												className="md"
												floatingLabel
												onChange={this.handleFormChange}
												name={`notificationBody-${language}`}
												value={this.data[`notificationBody-${language}`] || ''}
											/>
										</List>
										{this.data[`notificationBody-${language}`] && (
											<div className={`char-count ${this.data[`notificationBody-${language}`].length > 160 ? 'red' : ''}`}>
												Character Count: {this.data[`notificationBody-${language}`].length}
											</div>
										)}
									</div>
								);
							})}
							{(!languages || languages.length < 1) && (
								<div className="selection-required-message">Select at least one message type to continue.</div>
							)}
						</div>
					</Fragment>
				)}
				<div className="messaging-footer">
					<Button onClick={this.sendMessages} round fill disabled={!canSubmit}>
						Send Notifications
					</Button>
				</div>
			</Popup>
		);
	}
}
