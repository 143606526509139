import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Rect } from 'react-konva';
import CanvasFactory from '@/factories/CanvasFactory';
@observer
export default class CanvasMask extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	render() {
		const { documentX, documentY, documentWidth, documentHeight, containerWidth, containerHeight } = this.props;
        const {zoom} = CanvasFactory.getActiveStore();
       
        let x = ((containerWidth * (1 / (zoom / 100))) - containerWidth) / 2;
        let y = ((containerHeight * (1 / (zoom / 100))) - containerHeight) / 2;

		return (
			<>
				<Rect fill="#e2e8f0" opacity={0.8} x={-x} y={-y}  width={containerWidth * (1 / (zoom / 100)) } height={containerHeight * (1 / (zoom / 100))} listening={false} />
				<Rect
					fill="#e2e8f0"
					x={documentX}
					y={documentY}
					width={documentWidth}
					height={documentHeight}
					listening={false}
                    globalCompositeOperation="destination-out"
				/>
			</>
		);
	}
}
