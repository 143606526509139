import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Progressbar } from 'framework7-react';
import ExpressAPI from '@/services/ExpressAPI';
import './plan-status-card.scss';

@observer
export default class PlanStatusCard extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			steps: [],
			status: null
		});
		autoBind(this);
		this.loadStatusData = this.loadStatusData.bind(this);
	}

	async componentDidMount() {
		this.data.status = _.clone(this.props.status);
		this.loadStatusData();
	}

	async loadStatusData() {
		const latestStatus = await ExpressAPI.getOrgItemStatus(this.props.type, this.props.itemId);
		this.data.status = latestStatus.complete ? 'completed' : 'pending';
		this.data.steps = latestStatus.steps || [];
	}

	render() {
		const steps = this.data.steps || [];
		const completedSteps = steps.filter((x) => !!x.complete);
		const amountComplete = Math.ceil((completedSteps.length / steps.length) * 100) || 0;
		return (
			<div className="plan-status-card">
				<div className="main-content">
					<div>
						<h2>
							This HealthPlan's setup is incomplete. The HealthPlan will not work for users until the morning after the steps below are
							completed.
						</h2>
						<Progressbar progress={amountComplete} id="group-inline-progressbar" />
						<div className="step-list">
							{steps.map((step, stepIndex) => {
								return (
									<div className={`step hbox vcenter ${step.complete ? 'completed' : ''}`} key={`group-admin-steps-${stepIndex}`}>
										<div className="status">
											{step.complete && <i className="fas fa-check animated bounceIn" />}
											{!step.complete && <i className="fas fa-exclamation animated bounceIn" />}
										</div>
										<div className="step-text">
											<div>
												<strong>{step.title}</strong>
											</div>
											<div className="step-info">{step.info}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
