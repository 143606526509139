// @ts-nocheck
import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError, validateEmail } from '@/utils/GeneralUtils';
import _ from 'lodash';
import ExpressAPI from '@/services/ExpressAPI';
import { Button, f7 } from 'framework7-react';
import EDIConfigManager from '../edi-config-mgmt/EDIConfigManager';
import ExpressFormBuilder from '@/components/_EXPRESS/express-form-builder/ExpressFormBuilder';
import expressStore from '@/stores/ExpressStore';
import './member-import-settings.scss';

@observer
export default class MemberImportSettings extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showPassword: false,
			formValues: {},
			importToggles: {},
			accumulatorForm: { accumulatorParser: null },
			ftpData: null,
			ftpEditForm: {}
		});
		autoBind(this);
	}

	async componentDidMount() {
		await this.setFtpInfo();
		expressStore.currentOrg = await ExpressAPI.getOrganizationById(expressStore.currentOrg._id);
		this.data.accumulatorForm.accumulatorParser = expressStore.currentOrg.accumulatorParser
			? expressStore.currentOrg.accumulatorParser
			: null;
	}

	async setFtpInfo() {
		try {
			const ftpInfo = await ExpressAPI.getOrgFtpInfo();
			this.data.importToggles = _.cloneDeep(ftpInfo.toggles);
			this.data.ftpEditForm = _.cloneDeep(ftpInfo.edit);
			this.data.ftpData = _.cloneDeep(ftpInfo.data);
			this.setFormValues();
		} catch (err) {
			showExpressError(err);
		}
	}

	getFtpForm() {
		if (!this.data.ftpData) return { hasFtp: false };
		const config = {
			...this.data.importToggles,
			...this.data.ftpEditForm
		};
		const formData = _.get(this.data, 'formValues');
		const configKeys = Object.keys(config);
		const newConfig = {};
		configKeys.filter((configKey) => {
			const configData = config[configKey];
			const dependency = configData.dependency;
			if (dependency && dependency.method) {
				const dependencyFn = _[dependency.method];
				if (dependencyFn && typeof dependencyFn === 'function') {
					const dependencyValue = _.get(formData, dependency.key);
					const dependencyResult = _[dependency.method](dependencyValue, dependency.value);
					const isMet = dependency.hasOwnProperty('expect') ? _.isEqual(dependencyResult, dependency.expect) : dependencyResult;
					if (isMet) {
						newConfig[configKey] = configData;
					}
					return isMet;
				}
			}
			newConfig[configKey] = configData;
			return true;
		});
		return newConfig;
	}

	setFormValues() {
		if (!this.data.ftpData) return { hasFtp: false };
		const data = _.cloneDeep(this.data.ftpData);
		this.data.formValues = data;
		return data;
	}

	validateForm() {
		if (!this.data.ftpData) return false;
		const tempData = _.cloneDeep(this.data.formValues);
		const originalData = _.cloneDeep(this.data.ftpData);
		const config = this.getFtpForm();
		const configKeys = Object.keys(config || {});
		const changes = configKeys.filter((configKey) => {
			const configData = config[configKey];
			const originalValue = originalData[configKey];
			const newValue = tempData[configKey];
			const isChanged = newValue !== originalValue;
			return !configData.disabled && isChanged;
		});
		const missingReq = configKeys.filter((configKey) => {
			const configData = config[configKey];
			const newValue = tempData[configKey];
			const reqMet = !configData.required || (newValue && newValue.length > 0);
			if (configData.type === 'email') {
				const emailValid = validateEmail(newValue);
				return !configData.disabled && (!reqMet || !emailValid);
			}
			return !configData.disabled && !reqMet;
		});
		return changes.length > 0 && missingReq.length < 1;
	}

	showPassword() {
		this.data.showPassword = true;
	}

	hidePassword() {
		this.data.showPassword = false;
	}

	async submitChanges() {
		console.log('time to submit');
		try {
			this.$f7.dialog.preloader();
			await ExpressAPI.updateOrgFtpInfo(this.data.formValues);
			this.setFtpInfo();
			this.$f7.dialog.close();
		} catch (err) {
			console.log('error submitting changes: ', err);
			this.$f7.dialog.close();
			showExpressError(err);
		}
	}

	copyPassword() {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(this.data.formValues.password);
			this.$f7.dialog.alert('Copied to clipboard');
		}
	}

	copyUsername() {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(this.data.formValues.username);
			this.$f7.dialog.alert('Copied to clipboard');
		}
	}

	render() {
		const formValues = this.data.formValues;
		const ftpDataReady = !!this.data.ftpData && !!this.data.ftpEditForm;
		const canSubmit = this.validateForm();
		const canCopy = navigator.clipboard;
		return (
			<div className="member-import-settings-page">
				<div className="config-header hbox vcenter">
					<h2 className="grow-1">Member Imports</h2>
				</div>
				{ftpDataReady && (
					<div>
						<ExpressFormBuilder formData={formValues} formConfig={this.data.importToggles} />
						{_.get(formValues, 'hasFtp') && (
							<div className="member-import-details">
								<h3 className="ftp-header">SFTP Details:</h3>
								<div className="sftp-data">
									<span className="label">Host: </span>
									{_.get(this.data, 'ftpData.host')}
								</div>
								<div className="sftp-data">
									<span className="label">Username: </span>
									<span className={`${canCopy ? 'copy' : ''}`} onClick={this.copyUsername}>
										{_.get(this.data, 'ftpData.username')}
									</span>
								</div>
								{this.data.showPassword && (
									<div className="sftp-data masked-field">
										<span className="label">Password:</span>
										<i className="fad fa-eye-slash" onClick={this.hidePassword} />
										<span className={`${canCopy ? 'copy' : ''}`} onClick={this.copyPassword}>
											{_.get(this.data, 'ftpData.password')}
										</span>
									</div>
								)}
								{!this.data.showPassword && (
									<div className="sftp-data masked-field" onClick={this.showPassword}>
										<span className="label">Password:</span>
										<i className="fad fa-eye" />
									</div>
								)}
							</div>
						)}
						{_.get(formValues, 'hasFtp') && (
							<div className="member-import-details">
								<ExpressFormBuilder formData={formValues} formConfig={this.data.ftpEditForm} />
							</div>
						)}
					</div>
				)}
				<Button fill large className="list-btn ios" disabled={!canSubmit} onClick={this.submitChanges}>
					Save Member Settings
				</Button>
				{ftpDataReady && (
					<div className="config-header accumulators">
						<h2 className="grow-1">Accumulators</h2>
						<p>
							If you have spoken with the CareValet team about an accumulator data feed you can turn on that integration here. These
							settings are global and will apply to all groups and plans. However, you must configure the display settings on a group
							or plan level as you see fit, by default they will not appear until you turn it on via 'App Config'.
						</p>
						<ExpressFormBuilder
							formData={this.data.accumulatorForm}
							formConfig={{
								accumulatorParser: {
									label: 'Accumulator Integration Type',
									type: 'dropdown',
									className: 'dropdown-form-input',
									icon: 'fa-light fa-puzzle-piece-simple',
									listItems: {
										null: 'No Integration',
										WLT: 'WLT Integration',
										STANDARD: 'Standard File Integration'
									}
								}
							}}
						/>
						<Button
							fill
							large
							className="list-btn ios"
							onClick={() => {
								f7.dialog.preloader('Saving...');
								ExpressAPI.updateOrgSettings(this.data.accumulatorForm)
									.then((res) => {
										expressStore.currentOrg = res;
										let toast = this.$f7.toast.create({
											position: 'center',
											closeTimeout: 2000,
											icon: `<i class="fad fa-check large-icon-toast" ></i>`,
											text: 'Success'
										});
										toast.open();
										f7.dialog.close();
										f7.dialog.close();
									})
									.catch((err) => {
										console.log(err);
										f7.dialog.close();
									});
							}}
						>
							Save Accumulator Settings
						</Button>
					</div>
				)}
				<EDIConfigManager />
			</div>
		);
	}
}
