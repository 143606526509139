import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getCmpText } from '@/utils/Localization';
import { autoBind } from '@/utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '@/utils/State';
import StorageService from '@/services/StorageService';
import userStore from '@/stores/UserStore';
import AnalyticsService from '@/services/AnalyticsService';
import GradiantStack from '@/components/gradiant-stack/GradiantStack';
import EmailSVG from '@/assets/email.svg';
import CloudAPI from '@/services/CloudAPI';
import AuthService from '@/services/AuthService';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
export default class ConsumerVerifyContact extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.text = getCmpText('EmployerEmailCheck', {
			verifyContact: 'Please confirm your contact information is correct',
			sendCodeError: 'Unable to send validation code, please try again',
			createAccountError: 'Unable to create account',
			continue: "It's correct!",
			returnToFix: 'I need to fix it'
		});
		autoBind(this);
	}

	componentDidMount() {
		try {
			AuthService.initRecaptcha('recaptcha2');
		} catch (err) {
			console.log('Unable to render the recaptcha');
			console.log(err);
		}
	}

	componentWillUnmount() {
		//reset this so that it initializes if the component gets remounted, if not there will be an error proceeding
		userStore.recaptchaId = null;
	}

	async onNext() {
		let data = _.cloneDeep(userStore.signupData);
		analytics.trackWithData('Consumer Sign Up', { step: 'confirm phone number' });
		if (_.isEmpty(data.referralSource)) {
			data.referralSource = await storageService.getValue('app', 'referralSource');
		}
		this.$f7.dialog.preloader();
		const accountExists = await CloudAPI.isValidConsumer(data.mobile);
		if (!accountExists) {
			const codeSent = await AuthService.sendSigninCode(data.mobile, this.$f7);
			if (codeSent) {
				analytics.trackWithData('Consumer Sign Up', { step: 'text verification code sent' });
				this.$f7.dialog.close();
				this.props.onNext('consumer-verification');
			} else {
				analytics.trackWithData('Consumer Sign Up', { step: 'send verification code failed', reason: 'fb error' });
				this.$f7.dialog.close();
				this.$f7.dialog.alert(this.text.createAccountError, () => this.props.onBack());
			}
		} else {
			analytics.trackWithData('Consumer Sign Up', { step: 'send verification code failed', reason: 'duplicate account' });
			this.$f7.dialog.close();
			this.$f7.dialog.alert(this.text.createAccountError, () => {
				this.props.onBack();
			});
		}
	}

	onBack() {
		analytics.trackWithData('Consumer Sign Up', { step: 'back to form' });
		if (this.props.onBack) {
			this.props.onBack();
		}
	}

	render() {
		return (
			<Fragment>
				<GradiantStack stack="3" consumer={true} id="consumer-contact-verify" />
				<div className="employer-email-check-page animated fadeInDown">
					<div className="content-ctn center">
						<img src={EmailSVG} className="herosvg" />
						<div>{this.text.verifyContact}</div>
						<h1>{_.get(userStore, 'signupData.mobile', '5551113333')}</h1>
						<Button fill large className="round-btn" onClick={this.onNext}>
							{this.text.continue}
						</Button>
						<Button fill large className="round-btn red" onClick={this.onBack}>
							{this.text.returnToFix}
						</Button>
						<div id="recaptcha2"></div>
					</div>
				</div>
			</Fragment>
		);
	}
}
