import React, { Component } from 'react';
import _ from 'lodash';
import { ResponsiveCalendar } from '@nivo/calendar';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import { observer } from '@/utils/State';
import './calendar-chart.scss';
import { format } from 'date-fns';

@observer
export default class CalendarChart extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleItemClick(data) {
		console.log('calendar item was clicked: ', data);
	}

	formatData() {
		const data = _.get(this.props, 'data.data') || [];
		const allDataPoints = _.flatten(data.map((item) => item.data));
		const uniqueDates = Array.from(new Set(allDataPoints.map((x) => x.x)));
		const formattedData = uniqueDates
			.map((item) => {
				const total = allDataPoints.filter((x) => x.x === item).reduce((total, curr) => total + curr.y, 0);
				const formattedDate = format(new Date(item), 'yyyy-MM-dd');
				return {
					day: formattedDate,
					value: total
				};
			})
			.filter((x) => x.value > 0);
		return formattedData;
	}

	render() {
		const isMobile = appStore.isMobile;
		const chartProperties = this.props.data;
		const data = this.formatData();
		const chartConfig = chartProperties.config;
		const selectedFilters = this.props.selectedFilters;
		const chartData = _.get(this.props, 'data.data') || [];
		const colors = chartData.map((x) => x.color);
		return (
			<ResponsiveCalendar
				data={data}
				from={new Date(selectedFilters.startDate || '')}
				to={new Date(selectedFilters.endDate || '')}
				emptyColor="#eeeeee"
				colors={colors}
				margin={{ top: 40, right: 40, bottom: isMobile ? 70 : 50, left: 40 }}
				yearSpacing={40}
				monthBorderColor="#ffffff"
				dayBorderWidth={2}
				dayBorderColor="#ffffff"
				onClick={this.handleItemClick}
				legends={[
					{
						anchor: 'bottom-right',
						direction: 'row',
						translateY: 36,
						itemCount: 4,
						itemWidth: 42,
						itemHeight: 36,
						itemsSpacing: 14,
						itemDirection: 'right-to-left'
					}
				]}
			/>
		);
	}
}
