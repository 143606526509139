import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { autoBind } from '../../../../utils/GeneralUtils';
import { observer, observable } from '../../../../utils/State';
import Lookup from './lookup/Lookup';
import VerifyInfo from './verify-info/VerifyInfo';
import VerifyContact from './verify-contact/VerifyContact';
import CreatePassword from './create-password/CreatePassword';
import './employer-signup.scss';
import { getCmpText } from '@/utils/Localization';
import ExternalService from '@/services/ExternalService';

@observer
export default class EmployerSignup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			signupDetails: {},
			currentPage: 'lookup'
		});
		this.text = getCmpText('EmployerSignup', {
			signupHelper: 'If you need help signing up please contact CareValet at 866-549-9533.',
			signupHelperTitle: 'Need Help?',
			signupHelperCallCta: 'Call Now',
			signupHelperCloseCta: 'Cancel'
		});
		autoBind(this);
	}

	onBack(currentPage) {
		switch (currentPage) {
			case 'lookup':
				if (this.props.onBack) {
					this.props.onBack(`employer-${currentPage}`);
				}
				break;
			case 'verifyInfo':
				this.data.currentPage = 'lookup';
				break;
			case 'verifyContact':
				this.data.currentPage = 'verifyInfo';
				break;
			default:
				break;
		}
	}

	async onNext(currentPage, pageInfo) {
		const currentDetails = _.cloneDeep(this.data.signupDetails);
		const newInfo = _.cloneDeep(pageInfo);
		const updatedInfo = {
			...currentDetails,
			...newInfo
		};
		switch (currentPage) {
			case 'lookup':
				this.data.signupDetails = updatedInfo;
				this.data.currentPage = 'verifyInfo';
				break;
			case 'verifyInfo':
				this.data.signupDetails = updatedInfo;
				this.data.currentPage = 'verifyContact';
				break;
			case 'verifyContact':
				this.data.currentPage = 'createPassword';
				break;
			case 'accountVerification':
				this.closeSignup();
				break;
			default:
				break;
		}
	}

	onHelpClick() {
		this.$f7.dialog
			.create({
				title: this.text.signupHelperTitle,
				text: this.text.signupHelper,
				buttons: [
					{
						text: this.text.signupHelperCallCta,
						onClick: () => {
							ExternalService.handleExternalRequest('call', '866-549-9533');
						}
					},
					{
						text: this.text.signupHelperCloseCta,
						onClick: () => {
							console.log('cancel clicked');
						}
					}
				],
				verticalButtons: false
			})
			.open();
	}

	closeSignup() {
		firebase.auth().signOut();
		this.props.onNext('employer-signin');
	}

	render() {
		return (
			<Fragment>
				{this.data.currentPage === 'lookup' && (
					<Lookup onBack={this.onBack} onNext={this.onNext} step={{ current: 2, total: 4 }} type={this.props.type} />
				)}
				{this.data.currentPage === 'verifyInfo' && (
					<VerifyInfo info={this.data.signupDetails} step={{ current: 3, total: 4 }} onBack={this.onBack} onNext={this.onNext} />
				)}
				{this.data.currentPage === 'verifyContact' && (
					<VerifyContact info={this.data.signupDetails} onBack={this.onBack} onNext={this.onNext} />
				)}
				{this.data.currentPage === 'createPassword' && (
					<CreatePassword onNext={this.onNext} step={{ current: 4, total: 4 }} info={this.data.signupDetails} />
				)}
				<div className="signup-help" onClick={this.onHelpClick}>
					<i className="fad fa-question-circle" />
				</div>
			</Fragment>
		);
	}
}
