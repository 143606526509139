import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import expressStore from '@/stores/ExpressStore';
import {
	autoBind,
	formatDOB,
	formatMoney,
	formatPhoneNumber,
	showExpressError
} from '@/utils/GeneralUtils';
import { Preloader } from 'framework7-react';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import ExpressAPI from '@/services/ExpressAPI';
import _ from 'lodash';
import './plan-details.scss';
import TabSlider from '@/components/_EXPRESS/tab-slider/TabSlider';
import Breadcrumb from '@/components/_EXPRESS/breadcrumb/Breadcrumb';
import underConstruction from '@/assets/construction.png';
import NetworkDetails from '@/pages/EXPRESS/networks/network-details/NetworkDetails';
import IdCardDetails from '@/pages/EXPRESS/idcards/id-card-details/IdCardDetails';
import PlanDocumentDetails from '@/pages/EXPRESS/documents/plan-document-details/PlanDocumentDetails';
import ConfigEditor from '@/components/_EXPRESS/config-editor/ConfigEditor';
import telehealthConfig from '@/pages/EXPRESS/form-configs/telehealth';
import PlanStatusCard from '@/components/_EXPRESS/plan-status-card/PlanStatusCard';

export function derviePlanBaseConfig() {
	//*NOTE: This has been extracted to a method with which we can call in the settings > customFields
	//*      component for field validation (no dups)
	const idcardConfig = {
		rxGroup: { label: 'Rx Group#' },
		rxBin: { label: 'RxBin#' },
		rxPCN: { label: 'Rx PCN' },
		payerId: { label: 'PayerID' },
		officeVisitCopay: { label: 'Office Visit Copay' },
		specialistCopay: { label: 'Specialist Copay' },
		urgentCareCopay: { label: 'Urgent Care Copay' },
		emergencyCopay: { label: 'ER Copay' },
		deductible: { label: 'Deductible' },
		oopMax: { label: 'Out of Pocket Max' },
		famDeductible: { label: 'Family Deductible' },
		famOOPMax: { label: 'Family Out of Pocket Max' }
	};

	const basicConfig = {
		name: { label: 'Plan Name' },
		planId: { label: 'Plan ID' },
		healthPlanCoverageType: { label: 'Health Plan Coverage Type' },
		status: { label: 'Status' },
		primaryPhone: { label: 'Primary Plan Phone' },
		primaryWebsite: { label: 'Primary Plan Website ' },
		lastUpdated: { label: 'Last Updated' },
		lastUpdatedBy: { label: 'Last Updated By' }
	};

	return {
		idcardConfig,
		basicConfig
	};
}

@observer
export default class PlanDetails extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			activeNetwork: null,
			tierData: [],
			idCardFront: null,
			idCardBack: null,
			planEditOpened: false,
			telehealthEditOpened: false,
			additionalEditOpen: false,
			networkChangeOpen: false,
			tierBuilderOpen: false,
			activeTab: 0,
			networks: [],
			availableNetworks: [],
			loadingNetworkData: false,
			networkListItems: {},
			existingNetworksMap: {},
			planEditData: {
				name: '',
				planId: '',
				healthPlanCoverageType: 'Medical',
				primaryPhone: '',
				primaryWebsite: ''
			},
			telehealthEditData: {
				type: 'carevalet',
				careValetType: 'Teladoc',
				name: '',
				phone: '',
				website: '',
				copay: 0,
				serviceOffering: 'vuc'
			},
			additionalEditData: {
				rxPCN: '',
				rxGroup: '',
				rxBin: '',
				payerId: '',
				officeVisitCopay: '',
				specialistCopay: '',
				urgentCareCopay: '',
				emergencyCopay: '',
				famOOPMax: '',
				famDeductible: '',
				deductible: '',
				oopMax: ''
			},
			networkChangeData: {
				type: 'traditional',
				networkId: _.cloneDeep(expressStore.activePlan?.internalNetworkId),
				name: '',
				contactName: '',
				contactPhone: '',
				contactEmail: '',
				request: '',
				existingNetwork: false,
				tiers: null
			},
			customDataForm: {},
			customFields: {},
			customFieldsFormConfig: {}
		});
		autoBind(this);
	}

	async componentDidMount() {
		this.loadPlanNetwork();
		await this.loadPlanEditData();
	}

	async loadPlanEditData() {
		const customDataForm = await ExpressAPI.getIdCardCustomFormData(
			expressStore.activePlan?.idCardFront,
			expressStore.activePlan?.idCardBack
		);
		this.data.customDataForm = _.cloneDeep(customDataForm);

		this.data.customFields = _.get(expressStore, 'activePlan.customFields', {});

		const customFieldsFormConfig = _.get(
			expressStore.currentOrg,
			'customFieldsFormConfig',
			{}
		);
		const sanitizedFields = {};
		Object.keys(customFieldsFormConfig)?.forEach((k) => {
			let sanitizedKey = k;

			if (k.match(/plan/)) {
				const [_placeholder, customField] = k.split(/plan./);
				sanitizedKey = customField;
			}
			sanitizedFields[sanitizedKey] = customFieldsFormConfig[k];
		});
		this.data.customFieldsFormConfig = sanitizedFields;
	}

	async loadPlanNetwork() {
		if (expressStore.activePlan?.internalNetworkId) {
			this.data.tierData = null;
			ExpressAPI.getNetworkById(expressStore.activePlan?.internalNetworkId)
				.then((res) => {
					this.data.activeNetwork = res;
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	async loadAllNetworks() {
		if (!this.data.loadingNetworkData) {
			this.data.loadingNetworkData = true;
			try {
				const availableNetworks = await ExpressAPI.getAvailableNetworks();
				const networks = await ExpressAPI.getNetworks();
				this.data.availableNetworks = availableNetworks;
				this.data.networks = networks;
				let networkListItems = { 0: 'Request New Network' };
				let requestableNetworks = _.cloneDeep(availableNetworks);
				let existingNetworksMap = {};
				_.forEach(networks, (n) => {
					networkListItems[n._id] = n.name;
					existingNetworksMap[n._id] = n;
					if (n.networkId && requestableNetworks[n.networkId]) {
						delete requestableNetworks[n.networkId];
					}
				});
				_.forEach(Object.keys(requestableNetworks), (key) => {
					networkListItems[key] = requestableNetworks[key];
				});
				this.data.existingNetworksMap = existingNetworksMap;
				this.data.networkListItems = networkListItems;
				this.data.loadingNetworkData = false;
			} catch (err) {
				this.data.loadingNetworkData = false;
				showExpressError(this.$f7, err);
			}
		}
	}

	setActiveTab(tab) {
		this.data.activeTab = tab;
	}

	savePlan(part, data) {
		this.$f7.dialog.preloader('Saving...');
		ExpressAPI.savePlanPart(part, data)
			.then((res) => {
				expressStore.activePlan = res;
				this.data.planEditOpened = false;
				this.data.telehealthEditOpened = false;
				this.data.additionalEditOpen = false;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				showExpressError(this.$f7, err);
			});
	}

	onSavePlanData() {
		this.savePlan('plan', {
			_id: expressStore.activePlan._id,
			...this.data.planEditData
		});
	}

	onSaveTelehealth() {
		this.savePlan('telehealth', {
			_id: expressStore.activePlan._id,
			telehealth: { ...this.data.telehealthEditData }
		});
	}

	onSaveAdditionalData() {
		this.savePlan('additional', {
			_id: expressStore.activePlan._id,
			...this.data.additionalEditData,
			customFields: this.data.customFields
		});
	}

	onEditTelehealth() {
		let { telehealth } = _.cloneDeep(expressStore.activePlan);
		if (!telehealth) {
			telehealth = {
				type: 'carevalet',
				careValetType: 'Teladoc',
				name: '',
				phone: '',
				website: '',
				copay: 0,
				serviceOffering: 'vuc'
			};
		}

		const careValetType =
			telehealth.type === 'carevalet'
				? !telehealth.careValetType
					? 'Teladoc'
					: telehealth.careValetType
				: '';
		this.data.telehealthEditData = {
			type: telehealth.type,
			careValetType: careValetType,
			name: telehealth.type === 'custom' ? telehealth.name : '',
			phone: telehealth.phone === 'custom' ? telehealth.phone : '',
			website: telehealth.website === 'custom' ? telehealth.website : '',
			copay: telehealth.copay === 'custom' ? telehealth.copay : '',
			serviceOffering: telehealth?.serviceOffering || 'vuc'
		};
		this.data.telehealthEditOpened = true;
	}

	onSwapIdCards() {
		let { idCardFront, idCardBack } = _.cloneDeep(expressStore.activePlan);
		this.data.idCardFront = idCardFront;
		this.data.idCardBack = idCardBack;
	}

	onEditAdditionalData() {
		const {
			rxPCN,
			rxGroup,
			rxBin,
			payerId,
			officeVisitCopay,
			specialistCopay,
			urgentCareCopay,
			emergencyCopay,
			famOOPMax,
			famDeductible,
			deductible,
			oopMax,
			customData,
			customAccumulators,
			customFields = {}
		} = _.cloneDeep(expressStore.activePlan);
		this.data.additionalEditData = {
			rxPCN,
			rxGroup,
			rxBin,
			payerId,
			officeVisitCopay,
			specialistCopay,
			urgentCareCopay,
			emergencyCopay,
			famOOPMax,
			famDeductible,
			deductible,
			oopMax,
			customAccumulators,
			customData,
			customFields
		};

		Object.keys(customFields)?.forEach((customField) => {
			this.data.additionalEditData[customField] = customFields[customField];
		});

		this.data.additionalEditOpen = true;
	}

	onEditPlanClick() {
		const {
			name,
			planId,
			primaryPhone,
			primaryWebsite,
			type,
			healthPlanCoverageType = 'Medical'
		} = _.cloneDeep(expressStore.activePlan);
		this.data.planEditData = {
			name,
			planId,
			healthPlanCoverageType,
			primaryPhone,
			primaryWebsite,
			type
		};
		this.data.planEditOpened = true;
	}

	getEditConfig(type) {
		switch (type) {
			case 'plan': {
				return {
					row1: {
						name: {
							label: 'HealthPlan Name',
							placeholder: 'Friendly name of this plan',
							type: 'text',
							validator: {
								type: 'length',
								value: 2
							}
						},
						planId: {
							label: 'HealthPlan ID',
							placeholder: 'ID of plan (matching eligibility)',
							type: 'text',
							validator: {
								type: 'length',
								value: 2
							}
						}
					},
					row2: {
						healthPlanCoverageType: {
							label: 'Health Plan Coverage Type',
							type: 'dropdown',
							icon: 'far fa-files-medical',
							className: 'dropdown-form-input',
							listItems: {
								Medical: 'Medical Coverage',
								Dental: 'Dental Coverage',
								Vision: 'Vision Coverage'
							}
						}
					},
					row3: {
						primaryPhone: {
							label: 'Primary Phone Number',
							placeholder: 'Of Plan Administrator',
							type: 'phone',
							validator: {
								type: 'phone'
							}
						},
						primaryWebsite: {
							label: 'Primary Website URL',
							placeholder: 'Of Plan Administrator',
							type: 'text',
							validator: {
								type: 'url'
							}
						}
					}
				};
			}
			case 'telehealth': {
				return telehealthConfig(this.data.telehealthEditData);
			}
			case 'additional': {
				return {
					row1: {
						rxPCN: {
							label: 'Rx PCN',
							placeholder: 'Rx PCN',
							type: 'text'
						},
						rxBin: {
							label: 'RxBin',
							placeholder: 'RxBin#',
							type: 'text'
						}
					},
					row2: {
						rxGroup: {
							label: 'Rx Group#',
							placeholder: 'Rx Group Number',
							type: 'text'
						},
						payerId: {
							label: 'PayerID',
							placeholder: 'PayerID',
							type: 'text'
						}
					},
					row3: {
						officeVisitCopay: {
							label: 'Office Visit Copay',
							placeholder: 'Office Visit Copay',
							type: 'text'
						},
						specialistCopay: {
							label: 'Specialist Copay',
							placeholder: 'Specialist Copay',
							type: 'text'
						}
					},
					row4: {
						urgentCareCopay: {
							label: 'Urgent Care Copay',
							placeholder: 'Urgent Care Copay',
							type: 'text'
						},
						emergencyCopay: {
							label: 'ER Copay',
							placeholder: 'ER Copay',
							type: 'text'
						}
					},
					row5: {
						deductible: {
							label: 'Deductible',
							placeholder: 'Deductible',
							type: 'text'
						},
						oopMax: {
							label: 'Out of Pocket Max',
							placeholder: 'Out of Pocket Max',
							type: 'text'
						}
					},
					row6: {
						famDeductible: {
							label: 'Family Deductible',
							placeholder: 'Family Deductible',
							type: 'text'
						},
						famOOPMax: {
							label: 'Family Out of Pocket Max',
							placeholder: 'Family Out of Pocket Max',
							type: 'text'
						}
					},
					...this.data.customFieldsFormConfig
				};
			}
			case 'network': {
				if (this.data.loadingNetworkData) {
					return { loader: { type: 'loader' } };
				}
				return {
					networkId: {
						label: 'Request to a new network, or use an exsting one',
						type: 'dropdown',
						className: 'dropdown-form-input',
						icon: 'far fa-hospital-user',
						listItems: this.data.networkListItems,
						displayValues: (key, data) => {
							if (this.data.existingNetworksMap[key]) {
								return (
									<>
										<span className="tag">Existing</span>
										<span>{data[key]}</span>
									</>
								);
							}
							return data[key];
						}
					},
					row1: {
						name: {
							label: 'Network Name',
							placeholder: 'Name of the network',
							type: 'text',
							disabled: this.data.networkChangeData.networkId != 0,
							validator: {
								type: 'length',
								value: 3
							}
						},
						contactName: {
							label: 'Network Contact Name',
							placeholder: 'Contact to connect network',
							type: 'text',
							disabled: this.data.existingNetworksMap[
								this.data.networkChangeData.networkId
							]
								? true
								: false,
							validator: {
								type: 'length',
								value: 3
							}
						}
					},
					row2: {
						contactEmail: {
							label: 'Network Contact Email',
							placeholder: 'Network contact email',
							type: 'email',
							disabled: this.data.existingNetworksMap[
								this.data.networkChangeData.networkId
							]
								? true
								: false,
							validator: {
								type: 'email'
							}
						},
						contactPhone: {
							label: 'Network Contact Phone Number',
							placeholder: 'Network contact phone',
							type: 'phone',
							disabled: this.data.existingNetworksMap[
								this.data.networkChangeData.networkId
							]
								? true
								: false,
							validator: {
								type: 'phone'
							}
						}
					},
					request: {
						label: 'Request Details',
						disabled: this.data.existingNetworksMap[
							this.data.networkChangeData.networkId
						]
							? true
							: false,
						placeholder:
							'Please add any additional details that may help us connect to this network and load them into the system for you.',
						type: 'textarea',
						validator: {
							type: 'length',
							value: 3
						}
					}
				};
			}
		}
	}

	onSaveNetworkData() {
		this.$f7.dialog.preloader('Saving...');
		const currentPlan = _.cloneDeep(expressStore.activePlan?._id);
		ExpressAPI.savePlanPart('network', {
			...this.data.networkChangeData,
			_id: currentPlan,
			planId: currentPlan
		})
			.then((res) => {
				expressStore.activePlan = res;
				this.data.networkChangeOpen = false;
				this.loadPlanNetwork();
				this.data.networkChangeData = {
					type: res.type,
					networkId: res.type === 'traditional' && res.internalNetworkId,
					tiers: res.type === 'tiers' ? res.tiers : [],
					name: '',
					contactName: '',
					contactPhone: '',
					contactEmail: '',
					request: '',
					existingNetwork: false
				};
				this.$f7.dialog.close();
			})
			.catch((err) => {
				this.$f7.dialog.close();
				showExpressError(this.$f7, err);
			});
	}

	async onChangeNetwork() {
		if (this.data.tierData && this.data.tierData.length > 0) {
			await this.loadAllNetworks();
			const currentTiers = _.cloneDeep(this.data.tierData);
			const config = await ExpressAPI.getConfig('plan');
			const uniqueTiers = Array.from(new Set(currentTiers.map((x) => x.tier)));
			const updatedTiers = uniqueTiers.map((tier) => {
				const tierNetworks = currentTiers
					.filter((x) => x.tier === tier)
					.map((networkData) => {
						return {
							name: networkData.requestData.name,
							id: networkData.internalNetworkId
						};
					});
				return {
					tier: tier,
					networks: tierNetworks,
					color: _.get(config, `tierColorMap.tier${tier}`),
					text: _.get(
						config,
						`language.english.translations.ProviderService.tier${tier}Text`
					)
				};
			});
			this.data.networkChangeData.tiers = _.cloneDeep(updatedTiers);
			this.data.networkChangeData.oonConfig = {
				text: _.get(
					config,
					'language.english.translations.ProviderService.tier5Text'
				),
				color: _.get(config, 'tierColorMap.tier5')
			};
			this.data.tierBuilderOpen = true;
		} else {
			delete this.data.networkChangeData.tiers;
			this.loadAllNetworks();
			this.data.networkChangeOpen = true;
		}
	}

	buildPlanDetailsCard() {
		const { activePlan } = expressStore;
		const canEdit =
			expressStore.currentOrgRole()?.hasOwnProperty('role') &&
			expressStore.currentOrgRole().role === expressStore.roleEnums.GROUP_USER
				? false
				: true;

		const baseAndIdCardConfig = derviePlanBaseConfig();

		const telehealthConfig = {
			'telehealth.type': { label: 'Telehealth Provider' }
		};
		if (_.get(activePlan, 'telehealth.type', '') === 'custom') {
			telehealthConfig['telehealth.name'] = { label: 'Name' };
			telehealthConfig['telehealth.phone'] = { label: 'Phone' };
			telehealthConfig['telehealth.website'] = { label: 'Website' };
			telehealthConfig['telehealth.copay'] = { label: 'CoPay' };
			delete telehealthConfig['telehealth.type'];
		}

		const customKeys = Object.keys(this.data.customFieldsFormConfig || {});
		customKeys.forEach((customKey) => {
			const itemConfig =
				_.get(this.data.customFieldsFormConfig, customKey) || {};
			baseAndIdCardConfig.idcardConfig[customKey] = { ...itemConfig };
		});

		const customFields = Object.keys(activePlan.customFields || {});
		customFields.forEach((customFieldKey) => {
			const value = _.get(activePlan.customFields, customFieldKey) || '';

			if (value !== '')
				baseAndIdCardConfig.idcardConfig[`customFields['${customFieldKey}']`] =
					{
						...this.data.customFieldsFormConfig?.[customFieldKey]
					};
		});

		const formatValue = (key, value, config) => {
			if (key === 'status') {
				return _.capitalize(value);
			}
			if (['effectiveDate', 'renewalDate', 'lastUpdated'].indexOf(key) >= 0) {
				return formatDOB(value, 'yyyy-MM-dd', 'MM/dd/yyyy');
			}
			if (key === 'contactPhone') {
				return formatPhoneNumber(value);
			}
			if (key === 'type') {
				return _.capitalize(value);
			}
			if (key === 'lastUpdatedBy') {
				return _.startCase(value);
			}

			if (config.isUsd && config.type === 'number')
				return value.toString().match(/\$/) ? value : `$${value}`;
			if (config.isPercentage && config.type === 'number')
				return value.toString().match(/\%/) ? value : `${value}%`;

			if (
				[
					'officeVisitCopay',
					'specialistCopay',
					'urgentCareCopay',
					'emergencyCopay',
					'deductible',
					'oopMax',
					'famDeductible',
					'famOOPMax',
					'telehealth.copay'
				].indexOf(key) >= 0 &&
				!_.isEmpty(value)
			) {
				if (!isNaN(value)) {
					return `$${formatMoney(value)}`;
				} else {
					return value;
				}
			}
			if (key.indexOf('Accumulator') > -1 && !_.isEmpty(value)) {
				if (!isNaN(value)) {
					return `$${formatMoney(value)}`;
				} else {
					return value;
				}
			}
			if (key === 'telehealth.type') {
				switch (value) {
					case 'carevalet': {
						return 'CareValet Preferred Solution';
					}
					case 'none': {
						return 'No Telehealth has been setup for this plan';
					}
				}
			}

			return value;
		};

		const buildFields = (cardConfig) => {
			const fields = Object.keys(cardConfig)
				.map((fieldName) => {
					let value = _.get(activePlan, fieldName);
					let config = cardConfig[fieldName];

					if (_.isEmpty(value)) {
						return null;
					}
					return (
						<div key={`${activePlan._id}-${fieldName}`} className="card-field">
							<div className="label">{config.label}:</div>
							<div className="value ellipse">
								{formatValue(fieldName, value, config)}
							</div>
						</div>
					);
				})
				.filter((f) => f !== null);
			if (fields.length === 0) {
				return (
					<div className="card-field">
						<div className="label">No Data</div>
					</div>
				);
			}
			return fields;
		};

		return (
			<div className="plan-details-card animated fadeInUp">
				<div className="card-header">
					<div className="title grow-1">Plan Details</div>
					{canEdit && (
						<i className="fad fa-pen" onClick={this.onEditPlanClick}></i>
					)}
				</div>
				<div className="card-fields">
					{buildFields(baseAndIdCardConfig.basicConfig)}
				</div>
				<div className="card-header">
					<div className="title grow-1">Telehealth</div>
					{canEdit && (
						<i className="fad fa-pen" onClick={this.onEditTelehealth}></i>
					)}
				</div>
				<div className="card-fields">{buildFields(telehealthConfig)}</div>
				<div className="card-header">
					<div className="title grow-1">Additional Data</div>
					{canEdit && (
						<i className="fad fa-pen" onClick={this.onEditAdditionalData}></i>
					)}
				</div>
				<div className="card-fields">
					{buildFields(baseAndIdCardConfig.idcardConfig)}
				</div>
			</div>
		);
	}

	buildTabSlider() {
		return (
			<TabSlider
				activeTab={this.data.activeTab}
				setActiveTab={this.setActiveTab}
				config={{
					0: {
						name: 'Network'
					},
					1: {
						name: 'ID Cards'
					},
					3: {
						name: 'App Config'
					},
					2: {
						name: 'Documents'
					}
				}}
			/>
		);
	}

	onNetworkSave(network, changeOnly) {
		if (changeOnly) {
			this.data.networkChangeData = _.cloneDeep(network);
			this.data.networkChangeData.networkId = network._id;
			this.onSaveNetworkData();
		}
		this.data.activeNetwork = network;
	}

	buildConstruction() {
		return (
			<div className="construction-ctn">
				<h2>Coming Soon!</h2>
				<img src={underConstruction} />
			</div>
		);
	}

	getTabContent() {
		switch (this.data.activeTab) {
			case 0: {
				return !this.data.activeNetwork && !this.data.tierData ? (
					<Preloader size={32} color="blue"></Preloader>
				) : (
					<NetworkDetails
						network={this.data.activeNetwork}
						onSave={this.onNetworkSave}
						canChange
					/>
				);
			}
			case 1: {
				return (
					<IdCardDetails
						frontId={expressStore.activePlan.idCardFront}
						onSwap={this.onSwapIdCards}
						onSave={this.onSaveIdCardData}
						backId={expressStore.activePlan.idCardBack}
					/>
				);
			}
			case 2: {
				return <PlanDocumentDetails />;
			}
			case 3: {
				return <ConfigEditor type="plan" />;
			}
		}
	}

	getBreadCrumb() {
		let currentKey = 0;
		if (expressStore.activePlan) {
			currentKey++;
		}
		return (
			<Breadcrumb
				pageName="plans"
				config={{
					0: {
						name: 'All Plans',
						icon: 'fad fa-files-medical',
						onClick: () => {
							expressStore.activePlan = null;
							if (this.props.loadPlans) {
								this.props.loadPlans();
							}
						}
					},
					1: {
						name: expressStore.activePlan?.name,
						onClick: () => {}
					}
				}}
				currentKey={currentKey}
			/>
		);
	}

	render() {
		let {
			planEditOpened,
			telehealthEditOpened,
			additionalEditOpen,
			planEditData,
			telehealthEditData,
			additionalEditData
		} = this.data;

		return (
			<div
				className={`plan-details-page ${
					expressStore.activePage === 'allPlans' ? 'standalone' : 'subpage'
				}`}
			>
				{expressStore.activePage === 'allPlans' ? (
					this.getBreadCrumb()
				) : (
					<div className="page-header">
						<div
							className="back hbox vcenter"
							onClick={() => {
								expressStore.activePlan = null;
								if (this.props.loadPlans) {
									this.props.loadPlans();
								}
							}}
						>
							<i className="fas fa-chevron-left"></i>
							Back To List
						</div>
					</div>
				)}
				{expressStore.activePlan?.status !== 'completed' ? (
					<PlanStatusCard
						status={_.get(expressStore, 'activePlan.status')}
						type="plan"
						itemId={_.get(expressStore, 'activePlan._id')}
					></PlanStatusCard>
				) : (
					''
				)}
				{this.buildPlanDetailsCard()}
				{this.buildTabSlider()}
				{this.getTabContent()}

				<FormPopup
					opened={planEditOpened}
					title="Edit Plan Details"
					buttonText="Save"
					formData={planEditData}
					formConfig={this.getEditConfig('plan')}
					onSubmit={this.onSavePlanData}
					onClose={() => {
						this.data.planEditOpened = false;
						this.data.planEditData = {
							name: '',
							planId: '',
							healthPlanCoverageType: 'Medical',
							primaryPhone: '',
							primaryWebsite: ''
						};
					}}
				/>
				<FormPopup
					opened={telehealthEditOpened}
					title="Edit Telehealth Details"
					buttonText="Save"
					formData={telehealthEditData}
					formConfig={this.getEditConfig('telehealth')}
					onSubmit={this.onSaveTelehealth}
					onClose={() => {
						this.data.telehealthEditOpened = false;
						this.data.telehealthEditData = {
							type: 'carevalet',
							name: '',
							phone: '',
							website: '',
							copay: 0,
							careValetType: 'Teladoc',
							serviceOffering: 'vuc'
						};
					}}
					customValidator={(valid) => {
						if (
							['carevalet', 'none'].indexOf(
								this.data.telehealthEditData.type
							) >= 0
						) {
							return true;
						} else {
							return valid;
						}
					}}
				/>
				<FormPopup
					opened={additionalEditOpen}
					title="Edit Additional Details"
					buttonText={'Save'}
					formData={additionalEditData}
					formConfig={this.getEditConfig('additional')}
					onSubmit={this.onSaveAdditionalData}
					onClose={() => {
						this.data.additionalEditOpen = false;
						this.data.additionalEditData = {
							rxPCN: '',
							rxGroup: '',
							rxBin: '',
							payerId: '',
							officeVisitCopay: '',
							specialistCopay: '',
							urgentCareCopay: '',
							emergencyCopay: '',
							deductible: '',
							oopMax: '',
							famOOPMax: '',
							famDeductible: ''
						};
					}}
				/>
			</div>
		);
	}
}
