import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../../../../utils/Localization';
import { autoBind } from '../../../../../utils/GeneralUtils';
import { Button } from 'framework7-react';
import { observer, observable } from '../../../../../utils/State';
import GradiantStack from '../../../../../components/gradiant-stack/GradiantStack';
import EmailSVG from '@/assets/email.svg';
import AuthService from '../../../../../services/AuthService';

@observer
export default class VerifyContact extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.text = getCmpText('EmployerSignup', {
			verifyContactTitle: 'Please confirm your contact information is correct',
			contactInfoCorrect: "It's correct!",
			contactInfoIncorrect: 'I need to fix it'
		});
		autoBind(this);
	}

	componentDidMount() {
		try {
			AuthService.initRecaptcha('recaptcha2');
		} catch (err) {
			console.log('Unable to render the recaptcha');
			console.log(err);
		}
	}

	async onNext() {
		this.props.onNext('verifyContact');
	}

	onBack() {
		this.props.onBack('verifyContact');
	}

	render() {
		return (
			<Fragment>
				<GradiantStack stack="3" consumer={false} id="employer-verify-contact" />
				<div className="employer-email-check-page animated fadeInDown">
					<div className="content-ctn center">
						<img src={EmailSVG} className="herosvg" />
						<div>{this.text.verifyContactTitle}</div>
						<h1>{_.get(this.props, 'info.email')}</h1>
						<h1>{_.get(this.props, 'info.phone')}</h1>
						<Button fill large className="round-btn" onClick={this.onNext}>
							{this.text.contactInfoCorrect}
						</Button>
						<Button fill large className="round-btn red" onClick={this.onBack}>
							{this.text.contactInfoIncorrect}
						</Button>
						<div id="recaptcha2"></div>
					</div>
				</div>
			</Fragment>
		);
	}
}
