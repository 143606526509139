import React, { Component } from 'react';
import _ from 'lodash';
import { getCmpText } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import { Popup, Link, Block, Button } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import appStore from '../../stores/AppStore';
import Vimeo from '@u-wave/react-vimeo';
import AnalyticsService from '@/services/AnalyticsService';
import ConfigService from '@/services/ConfigService';
import './video-popup.scss';
const analytics = new AnalyticsService();
@observer
export default class VideoPopup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		this.text = getCmpText('VideoPopup', {
			close: 'Close'
		});
		autoBind(this);
	}
	//*===START CONTROLS===============================================================

	componentDidMount() {
		const config = ConfigService.getConfigValue('balanceBillPopup');
		if (config) {
			//got balance bill config
		}
	}

	handleOnClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	async onPopupOpen() {
		analytics.trackWithData(`opened_video`, { url: appStore.openVideoId });
	}

	//*===END CONTROLS===============================================================

	render() {
		return (
			<Popup
				opened={this.props.opened}
				className={`video-player-popup ${appStore.isMobile ? 'mobile' : 'desktop'}`}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpen={this.onPopupOpen}
				onPopupClosed={this.handleOnClose}
			>
				<div className="content-ctn y-scroll" key="balance-bill-ctn">
					<div className="popup-header">
						<div></div>
						<Link popupClose>{this.text.close}</Link>
					</div>
					<div className="page-cnt ">{appStore.openVideoId && <Vimeo className={`videmo`} video={appStore.openVideoId} />}</div>
				</div>
			</Popup>
		);
	}
}
