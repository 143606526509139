import React, { Component } from 'react';
import { Button, Popup, Page, Link, Sheet, List, ListItem, PageContent, Toggle } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText, getLocalizedCategories, getLocalizedSpecialties, getSpecialtyKey } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import _ from 'lodash';
import './provider-filter.scss';
import providerStore from '../../stores/ProviderStore';
import UserService from '../../services/UserService';
import AnalyticsService from '../../services/AnalyticsService';
import RangeSlider from '../../components/range-slider/RangeSlider';
import ProviderService from '../../services/ProviderService';
import ConfigService from '../../services/ConfigService';
const analytics = new AnalyticsService();
@observer
class ProviderFilter extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ filters: _.cloneDeep(props.filters), specialtyOpen: false });
		this.text = getCmpText('ProviderFilter', {
			title: 'Filter',
			category: 'Category',
			specialty: 'Specialty',
			network: 'In - Network Only',
			gender: 'Gender',
			radius: 'Search Radius ',
			miles: ' (miles)',
			apply: 'Apply Filters',
			sort: 'Sort By',
			distance: 'Distance',
			lastName: 'Last Name',
			doneText: 'Done',
			male: 'Male',
			female: 'Female',
			any: 'Any',
			reset: 'Reset'
		});
		autoBind(this);
	}

	componentDidMount() {
		this.reset();
		if (!_.get(this.data, 'filters.radius')) {
			const radiusConfig = _.cloneDeep(ProviderService.getRadiusConfig());
			this.data.filters.radius = radiusConfig.default || 10;
		}
	}

	reset() {
		const configRadius = ConfigService.getConfigValue('search.radius', {});
		const specialties = getLocalizedSpecialties('physician');
		this.data.filters = {
			category: 'physician',
			specialty: specialties[0],
			inNetwork: true,
			gender: 'any',
			sort: 'distance',
			radius: _.clone(_.get(configRadius, 'physician.default') || _.get(configRadius, 'default.default') || 10)
		};
	}

	onCatClick(evt) {
		let category = evt.currentTarget.dataset.cat;
		this.data.filters.category = category;
		let cat = _.get(this.data, 'filters.category', null);
		let catDetails = _.find(Object.values(getLocalizedCategories()), { type: cat });
		if (cat && catDetails && catDetails.specialty) {
			const localizedSpecialties = _.cloneDeep(getLocalizedSpecialties(cat));
			this.data.filters.specialty = localizedSpecialties ? localizedSpecialties[0] : null;
		} else {
			this.data.filters.specialty = null;
		}
		const radiusConfig = _.cloneDeep(ProviderService.getRadiusConfig(category));
		const currentValue = _.clone(this.data.filters.radius);
		const newDefault = radiusConfig.default || 10;
		if (currentValue !== newDefault) {
			this.data.filters.radius = radiusConfig.default || 10;
		}
	}

	toggleSpecialtyMenu(evt) {
		this.data.specialtyOpen = !this.data.specialtyOpen;
	}

	onApplyFilters() {
		const normalizedFilters = _.cloneDeep(this.data.filters);
		const specialtyKey = getSpecialtyKey(normalizedFilters.category, normalizedFilters.specialty);
		normalizedFilters.specialty = specialtyKey;
		providerStore.filters = normalizedFilters;
		analytics.trackWithData('search', normalizedFilters);
		this.props.onClose(true);
	}

	onClose() {
		this.data.filters = _.cloneDeep(providerStore.filters);
		this.props.onClose();
	}

	onSpecialtyChange(evt) {
		const value = evt.currentTarget.value;
		this.data.filters.specialty = value;
		this.$f7.sheet.get('#filterSpecialtyMenu').close();
	}

	onNetworkChange(evt) {
		const isChecked = evt.currentTarget.checked;
		this.data.filters.inNetwork = isChecked;
	}

	onChangeGender(gender) {
		this.data.filters.gender = gender;
	}

	onRadiusChange(value) {
		if (this.props.open) {
			if (value === 0) {
				value = 1;
			}
			this.data.filters.radius = value;
		}
	}

	onChangeSort(sortBy) {
		this.data.filters.sort = sortBy;
	}

	buildCats() {
		let cats = [];
		let { filters } = this.data;
		_.forEach(Object.values(getLocalizedCategories()), (cat) => {
			if (!cat.hide) {
				cats.push(
					<div
						className={`category vbox vcenter hcenter ${cat.disabled ? 'disabled' : ''}`}
						data-cat={cat.type}
						onClick={!cat.disabled ? this.onCatClick : () => {}}
						key={cat.type}
					>
						<i className={`${cat.icon} neumorphic ${filters.category === cat.type ? ' selected ' : ''}`} />
						<div>{cat.name}</div>
					</div>
				);
			}
		});
		return cats;
	}

	buildSpecialtiesMenu() {
		let cat = _.get(this.data, 'filters.category', null);
		let items = [];
		const specialties = getLocalizedSpecialties(cat);
		if (specialties && specialties.length > 0) {
			_.forEach(specialties, (s, i) => {
				items.push(
					<ListItem
						radio
						checked={this.data.filters.specialty === s}
						name="specialty"
						value={s}
						onChange={this.onSpecialtyChange}
						key={s + Math.random()}
					>
						{s}
					</ListItem>
				);
			});
		}
		return items;
	}

	showGender() {
		let { category, specialty } = this.data.filters;
		return category === 'physician' || specialty === 'Mental & Behavioral Health';
	}

	onOpenPopup() {
		const cat = _.get(this.props, 'filters.category');
		const currentSpecialty = _.get(this.props, 'filters.specialty');
		const configData = ConfigService.getConfigValue('search.categories', {}) || {};
		const catConfig = _.find(configData, { type: cat }) || {};
		const engSpecialties = _.get(catConfig, 'specialty.en-US') || '';
		const currentSpecialtyIndex = engSpecialties.indexOf(currentSpecialty);
		const specialties = getLocalizedSpecialties(cat);
		this.data.specialtyOpen = false;
		const updatedFilters = _.cloneDeep(this.props.filters);
		updatedFilters.specialty = specialties[currentSpecialtyIndex];
		this.data.filters = updatedFilters;
	}

	render() {
		let {
			title,
			category,
			specialty,
			miles,
			network,
			gender,
			radius,
			apply,
			sort,
			distance,
			lastName,
			doneText,
			male,
			female,
			any,
			reset
		} = this.text;
		let { open } = this.props;
		let { specialtyOpen, filters } = this.data;
		const radiusConfig = _.cloneDeep(ProviderService.getRadiusConfig(filters.category));
		return (
			<Popup className="provider-filter-popup" opened={open} onPopupClosed={this.onClose} onPopupOpened={this.onOpenPopup}>
				<Page className="filter-page">
					<div className="filter-header hbox vcenter hright">
						<h1 className="grow-1">{title}</h1>
						<Button className="reset-btn neumorphic neuhover" onClick={this.reset}>
							{reset}
						</Button>
						{!specialtyOpen && (
							<Link popupClose>
								<i className="fad fa-times-square" />
							</Link>
						)}
					</div>
					<div className="inner-page-content y-scroll">
						<div className="label">{category}</div>
						<div className="category-picker">{this.buildCats()}</div>
						{filters.specialty && (
							<div className="animated fadeIn">
								<div className="label">{specialty}</div>
								<div className="label-value" onClick={this.toggleSpecialtyMenu}>
									<div className="selected-specialty">
										{filters.specialty} <i className="fal fa-chevron-down"></i>
									</div>
								</div>
								{specialtyOpen && <div className="popup-mask"></div>}
								<Sheet
									id="filterSpecialtyMenu"
									className="specialty-popup"
									opened={specialtyOpen}
									onSheetClosed={this.toggleSpecialtyMenu}
								>
									<div className="popup-toolbar hbox vcenter hright">
										<Link sheetClose>{doneText}</Link>
									</div>
									<PageContent>
										<List mediaList>{this.buildSpecialtiesMenu()}</List>
									</PageContent>
								</Sheet>
							</div>
						)}
						{(!_.isEmpty(UserService.getNetworkTiers()) || !!UserService.getHealthNetwork()) && (
							<>
								<div className="label">{network}</div>
								<Toggle checked={filters.inNetwork} onChange={this.onNetworkChange} color="green" />
							</>
						)}
						<RangeSlider
							min={radiusConfig.min || 0}
							max={radiusConfig.max || 50}
							step={radiusConfig.step || 1}
							value={filters.radius}
							scale={true}
							scaleSteps={radiusConfig.steps || 5}
							scaleSubSteps={radiusConfig.subSteps || 5}
							onChange={this.onRadiusChange}
							source="findCareFilter"
						/>
						{this.showGender() && (
							<>
								<div className="label">{gender}</div>
								<div tag="p" className="gender-picker hbox vcenter hcenter">
									<Button
										round
										active={filters.gender === 'any'}
										onClick={() => {
											this.onChangeGender('any');
										}}
									>
										{any}
									</Button>
									<Button
										active={filters.gender === 'm'}
										round
										onClick={() => {
											this.onChangeGender('m');
										}}
									>
										{male}
									</Button>
									<Button
										round
										active={filters.gender === 'f'}
										onClick={() => {
											this.onChangeGender('f');
										}}
									>
										{female}
									</Button>
								</div>
							</>
						)}
					</div>
					<div className="btn-ctn vbox vcenter hcenter elevation-2">
						<Button fill raised large round className="w-100" onClick={this.onApplyFilters}>
							{apply}
						</Button>
					</div>
				</Page>
			</Popup>
		);
	}
}

export default ProviderFilter;
