import { observable } from '@/utils/State';
import _ from 'lodash';
const screeningStore = observable({
    currentQuestion: 'intro',
    call911: false,
    questionTrail: [],
    nextQuestion: '',
    completed: false,
	results: {
        intro: '',
        age: '',
        symptoms: [],
        severity: '',
        conditions: [],
        exposure: [],
        careFacilityLive: '',
        careFacilityWork: '',
        ppe: ''
    }
});
window.screeningStore = screeningStore;
export default screeningStore;
