import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import ProviderListItem from '@/pages/find-care/provider-list-item/ProviderListItem';
import FormPopup from '@/components/_EXPRESS/form-popup/FormPopup';
import './tier-builder.scss';

@observer
export default class TierBuilder extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			currentTier: 0,
			tierFormOpen: false,
			tiers: [],
			networks: [],
			oonFormOpen: false,
			oonData: {
				color: 'red',
				text: 'Out-Network'
			},
			hideOonConfig: false,
			tierFormData: {
				color: '',
				text: '',
				networks: [],
				tier: 1
			},
			oonForm: {
				color: 'red',
				text: 'Out-Network',
				hideChip: false
			}
		});
		autoBind(this);
	}

	openTierForm() {
		const targetTier = this.data.currentTier;
		if (targetTier <= 5) {
			this.data.tierFormOpen = true;
		} else {
			this.$f7.dialog.alert('You can only have 5 tiers');
		}
	}

	closeTierForm() {
		this.data.tierFormOpen = false;
	}

	saveTier() {
		const data = _.cloneDeep(this.data.tierFormData);
		this.data.tierFormOpen = false;
		this.props.saveTier({ tier: data });
	}

	editTier(evt) {
		const tier = evt.currentTarget.getAttribute('data-tier');
		this.data.currentTier = tier;
		const emptyTierHolder = { tier: tier, networks: [] };
		const currentTierData = _.cloneDeep(this.props.data.tiers).find((x) => x.tier.toString() === tier.toString()) || emptyTierHolder;
		this.data.tierFormData = _.cloneDeep(currentTierData);
		this.data.tierFormOpen = true;
	}

	deleteTier(evt) {
		const tier = evt.currentTarget.getAttribute('data-tier');
		if (Number(tier) === 1) {
			this.$f7.dialog.alert(
				'At a minimum, Tier 1 is required and cannot be deleted.  Please go back and switch to a traditional plan if needed.'
			);
			return;
		}
		this.$f7.dialog.confirm(`Are you sure you want to delete Tier ${tier}?`, 'Please Confirm', () => {
			const originalTiers = _.cloneDeep(this.props.data.tiers);
			const newTiers = originalTiers.filter((x) => Number(x.tier) !== Number(tier));
			this.data.tiers = newTiers;
			this.props.saveTier({ tier: this.data.tierFormData, delete: true });
		});
	}

	getHighestTier() {
		const currentTierData = _.cloneDeep(this.props.data.tiers) || [];
		const currentTiers = currentTierData.map((tier) => tier.tier).sort();
		const highestTier = currentTiers[currentTiers.length - 1];
		return highestTier;
	}

	addTierInit() {
		const highestTier = this.getHighestTier();
		if (highestTier < 4) {
			const newTierData = {
				tier: highestTier + 1,
				networks: [],
				text: highestTier + 1 > 3 ? 'Out-Network' : 'In-Network',
				color: highestTier + 1 > 3 ? 'red' : 'green'
			};
			this.props.saveTier({ tier: newTierData });
		} else {
			this.$f7.dialog.alert('You may only have a maximum of 4 tiers');
		}
	}

	openOonForm() {
		this.data.oonFormOpen = true;
		this.data.oonForm = _.cloneDeep(this.props.data.oonConfig);
	}

	closeOonForm() {
		this.data.oonFormOpen = false;
	}

	saveOonConfig() {
		this.data.oonFormOpen = false;
		this.props.saveTier({ oonConfig: this.data.oonForm });
	}

	handleTierCardClick() {
		this.$f7.dialog.alert(
			'This is an example of how the network status for providers in the each tier will be displayed on the Care Team, Provider Finder, and Provider Profile pages based on your current selections.'
		);
	}

	getTierIcon(tier) {
		const currentTierIcon = tier > 3 ? 'fas fa-exclamation' : 'fas fa-check';
		return currentTierIcon;
	}

	render() {
		const chipColors = {
			green: 'Green',
			'green-yellow': 'Green/Yellow',
			yellow: 'Yellow',
			orange: 'Orange',
			red: 'Red',
			purple: 'Purple',
			blue: 'Blue',
			darkgreen: 'Dark Green',
			grey: 'Grey'
		};
		const highestTier = this.getHighestTier();
		const selectedNetworks = _.flattenDeep(_.cloneDeep(this.props.data.tiers).map((tier) => tier.networks)).map((x) => x.id);
		const availableNetworks = this.props.networks
			.filter((x) => !!x.isExisting)
			.map((x) => ({ name: x.name, id: x.id }))
			.filter((network) => {
				const isChosen = selectedNetworks.indexOf(network.id) > -1;
				return !isChosen;
			});
		const currentTierData = this.props.data.tiers.find((x) => x.tier.toString() === this.data.currentTier.toString()) || {};
		const oonText = _.get(this.props, 'data.oonConfig.text');
		const oonColor = _.get(this.props, 'data.oonConfig.color');
		const oonIcon = _.get(this.props, 'data.oonConfig.icon');
		return (
			<div className={`step-3 vbox vcenter hcenter animated ${this.props.animation} tier-builder-container`}>
				<div className="tiers-list">
					<div className="tier-items">
						{this.props.data.tiers.map((tier) => {
							const tierIcon = tier.tier > 3 ? 'fas fa-exclamation' : 'fas fa-check';
							const tierColor = tier.color ? tier.color : tier.tier > 3 ? 'red' : 'green';
							const tierText = tier.text ? tier.text : tier.tier > 3 ? 'Out-Network' : 'In-Network';
							return (
								<div className="tier-item" key={`tier-data-${tier.tier}`}>
									<div className="tier-title">Tier {tier.tier}</div>
									<div className="tier-summary">
										<div className="tier-data">
											<ProviderListItem
												image={'https://asset2.betterdoctor.com/images/55efd92ff228666c4000cc91-2_thumbnail.jpg'}
												gender="f"
												provider={{}}
												name={`Tier ${tier.tier} Test Provider`}
												specialties={'Specialty 1, Specialty 2'}
												chipColor={tierColor}
												chipText={tierText}
												chipIcon={tierIcon}
												onClick={this.handleTierCardClick}
											/>
											{!this.props.disableEdit && (
												<div className="tier-menu">
													<div className="tier-option" data-tier={tier.tier} onClick={this.editTier}>
														<i className="fad fa-edit" />
													</div>
													<div className="tier-option" data-tier={tier.tier} onClick={this.deleteTier}>
														<i className="fad fa-trash" />
													</div>
												</div>
											)}
										</div>
										<div className="tier-networks">
											{tier.networks && tier.networks.length > 0 ? (
												`Networks: ${tier.networks.map((x) => x.name).join(', ')}`
											) : (
												<span className="empty" data-tier={tier.tier} onClick={this.editTier}>
													Add Networks to Tier
												</span>
											)}
										</div>
									</div>
								</div>
							);
						})}
						<div className="tier-item">
							<div className="tier-title">All {_.get(this.props, 'data.type') !== 'none' ? 'Other' : ''} Providers</div>
							<div className="tier-summary">
								<div className="tier-data">
									<ProviderListItem
										image={'https://asset2.betterdoctor.com/images/55efd92ff228666c4000cc91-2_thumbnail.jpg'}
										gender="f"
										provider={{}}
										name={`Test Provider`}
										specialties={'Specialty 1, Specialty 2'}
										chipColor={oonColor}
										chipText={oonText}
										chipIcon={oonIcon}
										hideChip={_.get(this.props, 'data.oonConfig.hideChip')}
										onClick={this.handleTierCardClick}
									/>
									{!this.props.disableEdit && (
										<div className="tier-menu">
											<div className="tier-option" data-tier="oon" onClick={this.openOonForm}>
												<i className="fad fa-edit" />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="helpers">
						{highestTier < 4 && !this.props.disableEdit && (
							<div className="add-tier-cta" onClick={this.addTierInit}>
								<i className="fad fa-plus-square" />
								Add Another Tier
							</div>
						)}
					</div>
					<FormPopup
						opened={this.data.oonFormOpen}
						title="Configure Other Providers"
						buttonText="Continue"
						formData={this.data.oonForm || {}}
						formConfig={{
							hideChip: {
								label: 'Hide Network Chip',
								type: this.props.data.type === 'none' ? 'toggle' : 'none'
							},
							color: {
								label: `Select Chip Color for All ${_.get(this.props, 'data.type') !== 'none' ? ' Other ' : ''} Providers`,
								type: _.get(this.data, 'oonForm.hideChip') ? 'none' : 'dropdown',
								default: 'green',
								listItems: chipColors,
								disabled: _.get(this.data, 'oonForm.hideChip'),
								displayValues: (key, data) => {
									return (
										<div className="chip-dropdown-container network-chip-container">
											<span className={`swatch chip-media bg-color-${key}`}></span>
											<span>{data[key]}</span>
										</div>
									);
								}
							},
							icon: {
								label: 'Select Chip Icon',
								type: 'iconPicker',
								default: 'fas fa-exclamation'
							},
							text: {
								label: `Label for All ${
									_.get(this.props, 'data.type') !== 'none' ? ' Other ' : ''
								} Providers *Maximum of 15 characters`,
								type: _.get(this.data, 'oonForm.hideChip') ? 'none' : 'text',
								placeholder: `${_.get(this.props, 'data.type') !== 'none' ? ' Other ' : ''} Providers Chip Text`
							}
						}}
						afterChange={(item) => {
							if (item === 'text') {
								const currentVal = _.cloneDeep(this.data.oonForm.text);
								if (currentVal && currentVal.length > 15) {
									this.data.oonForm.text = currentVal.slice(0, 15);
								}
							}
							if (item === 'hideChip') {
								const currentVal = _.cloneDeep(_.get(this.data, 'oonForm.hideChip'));
								if (currentVal) {
									this.data.oonForm.hideChip = true;
								} else {
									this.data.oonForm.hideChip = false;
								}
							}
						}}
						onClose={this.closeOonForm}
						onSubmit={this.saveOonConfig}
						additionalContent={
							<div className="provider-card-example">
								<div>Example of Configuration: </div>
								<ProviderListItem
									image={'https://asset2.betterdoctor.com/images/55efd92ff228666c4000cc91-2_thumbnail.jpg'}
									gender="f"
									provider={{}}
									name={`Test Provider`}
									specialties={'Specialty 1, Specialty 2'}
									chipColor={this.data.oonForm.color}
									chipText={this.data.oonForm.text}
									chipIcon={this.data.oonForm.icon}
									hideChip={_.get(this.data, 'oonForm.hideChip')}
									onClick={this.handleTierCardClick}
								/>
							</div>
						}
					/>
					<FormPopup
						opened={this.data.tierFormOpen}
						title={`Configure Tier ${this.data.currentTier}`}
						buttonText="Continue"
						formData={this.data.tierFormData}
						formConfig={{
							networks: {
								label: 'Select Networks *At least 1 Network is Required',
								type: 'tagpicker',
								className: 'dropdown-form-input',
								tags: availableNetworks,
								placeholder: `Select Tier ${this.data.currentTier} Networks`
							},
							color: {
								label: `Select Tier ${this.data.currentTier} Color`,
								type: 'dropdown',
								default: 'green',
								listItems: chipColors,
								displayValues: (key, data) => {
									return (
										<div className="chip-dropdown-container network-chip-container">
											<span className={`swatch chip-media bg-color-${key}`}></span>
											<span>{data[key]}</span>
										</div>
									);
								}
							},
							text: {
								label: `Select Tier ${this.data.currentTier} Label *Maximum of 15 characters`,
								type: 'text',
								placeholder: `Tier ${this.data.currentTier} Chip Text`
							}
						}}
						afterChange={(item) => {
							if (item === 'text') {
								const currentVal = _.cloneDeep(currentTierData.text);
								if (currentVal && currentVal.length > 15) {
									currentTierData.text = currentVal.slice(0, 15);
								}
							}
						}}
						onSubmit={this.saveTier}
						additionalContent={
							<div className="tier-builder-additional-content">
								<div className="provider-card-example">
									<div>Example of Configuration: </div>
									<ProviderListItem
										image={'https://asset2.betterdoctor.com/images/55efd92ff228666c4000cc91-2_thumbnail.jpg'}
										gender="f"
										provider={{}}
										name={`Tier ${this.data.tierFormData.tier} Test Provider`}
										specialties={'Specialty 1, Specialty 2'}
										chipColor={this.data.tierFormData.color}
										chipText={this.data.tierFormData.text}
										chipIcon={this.getTierIcon(this.data.tierFormData.tier)}
										onClick={this.handleTierCardClick}
									/>
								</div>
								<div className="form-message">
									Don't see the network you're looking for? Make sure the standard network request has already been submitted and is
									not already assigned to another tier for this tiered network.
								</div>
							</div>
						}
						onClose={this.closeTierForm}
					/>
				</div>
			</div>
		);
	}
}
