import _ from 'lodash';
import userStore from '../stores/UserStore';
import appStore from '../stores/AppStore';
import { getCmpText } from '../utils/Localization';
import ConfigService from './ConfigService';
import AnalyticsService from './AnalyticsService';
import CloudAPI from './CloudAPI';
import NotificationService from './NotificationService';
import UserService from './UserService';
import StorageService from './StorageService';
import providerStore from '../stores/ProviderStore';
import { loadStripe } from '@stripe/stripe-js/pure';
import { getStripeKey } from '@/utils/GeneralUtils';
const analytics = new AnalyticsService();
const notifications = new NotificationService();
const storageService = new StorageService();

class AuthService {
	constructor() {
		this.text = getCmpText('App', {
			unknownLoginError: 'Unable to Login, please try again.',
			inactiveMember: 'Your member record indicates you are no longer an active participant in this plan.  Please reach out to your plan adminsistrator for further assistance'
		});
	}
	init(f7) {
		//Will auto login if token is good
		const firebaseConfig = {
			apiKey: 'AIzaSyAQJOf5_VufYubApXcU6-zdlP9UYS5eGWg',
			authDomain: 'care-valet-cloud.firebaseapp.com',
			databaseURL: 'https://care-valet-cloud.firebaseio.com',
			projectId: 'care-valet-cloud',
			storageBucket: '',
			messagingSenderId: '810993602385',
			appId: '1:810993602385:web:0036f64b3555f73e'
		};
		firebase.initializeApp(firebaseConfig);
		firebase.auth().onAuthStateChanged((authUser) => this.handleAuthChange(authUser, f7));
	}

	async handleAuthProviderLogin(authUser) {
		try {
			const token = await firebase.auth().currentUser.getIdToken(true);
			userStore.userToken = token;
			userStore.firebaseUser = authUser;
			return { status: true };
		} catch (err) {
			console.log('unable to get ID token: ', err);
			return { status: false, reason: 'auth_token_error', err: err };
		}
	}

	async getUpdatedCareTeam() {
		if (providerStore.careTeam && providerStore.careTeam.length <= 0) {
			try {
				const careteam = await CloudAPI.getCareTeam();
				providerStore.careTeam = careteam;
			} catch (err) {
				console.log('unable to get careteam: ', err);
			}
		}
	}

	async handleQuestionnaireConfig() {
		const apptSurveys = await UserService.checkForAppointmentSurvey();
		const hasSurveys = apptSurveys && apptSurveys.length > 0;
		const config = _.cloneDeep(ConfigService.getConfiguration());
		const hasQuestionnaire = _.get(config, 'features.questionnaire');
		const questionnaireObjects = _.get(config, 'questionnaires');
		if (!questionnaireObjects) return;
		const questionnaireConfigs = Object.values(questionnaireObjects).filter((x) => x.enabled);
		let showQuestionnaire = hasQuestionnaire && questionnaireConfigs;
		if (showQuestionnaire || hasSurveys) {
			let queuedQuestionnaires = _.cloneDeep(appStore.questionnaires) || [];
			if (hasSurveys) {
				for (let survey of apptSurveys) {
					queuedQuestionnaires.push(survey);
				}
			}
			for (let qConfig of questionnaireConfigs) {
				try {
					const storagePath = `questionnaire.${qConfig.id}`;
					const deviceData = await storageService.getValue('app', storagePath);
					const frequencyConfig = _.get(qConfig, 'frequency') || {};
					const isTimeBased = !!frequencyConfig.days;
					const isAtLogin = !!frequencyConfig.atLogin;
					if (isTimeBased) {
						const questionnaireLastSeen = (await storageService.getValue('app', storagePath)) || 0;
						const frequency = _.get(qConfig, 'frequency.days', 1);
						const oneDay = 60 * 60 * 24 * 1000;
						const currentDate = new Date().getTime();

						if (currentDate - questionnaireLastSeen > oneDay * frequency) {
							qConfig.storagePath = storagePath;
							qConfig.storageValue = currentDate;
							queuedQuestionnaires.unshift(_.cloneDeep(qConfig));
							appStore.questionnaires = queuedQuestionnaires;
						} else {
							queuedQuestionnaires = queuedQuestionnaires.filter((x) => x.id !== qConfig.id);
							appStore.questionnaires = queuedQuestionnaires;
						}
					} else {
						const limiter = _.get(qConfig, 'frequency.limit');
						const limitRequirement = _.get(qConfig, 'frequency.limitValue');
						if (limiter) {
							const limitStatus = _.get(userStore, limiter);
							const limitMet = limitStatus === limitRequirement;
							if (!limitMet) {
								queuedQuestionnaires.unshift(_.cloneDeep(qConfig));
								appStore.questionnaires = queuedQuestionnaires;
							} else {
								queuedQuestionnaires = queuedQuestionnaires.filter((x) => x.id !== qConfig.id);
								appStore.questionnaires = queuedQuestionnaires;
							}
						}
					}
					appStore.showQuestionnaire = !!appStore.questionnaires && appStore.questionnaires.length > 0;
				} catch (err) {
					console.log('error configuring questionnaire: ', err);
				}
			}
			return;
		} else {
			appStore.showQuestionnaire = false;
		}
	}

	async handlePwaPrompt() {
		const isTargetDevice = appStore.isMobile && (appStore.isIos || appStore.deviceDetails.android);
		const isNotPwa = !appStore.isPWA();
		const isNotApp = !appStore.isApp();
		const config = _.cloneDeep(ConfigService.getConfiguration());
		const hasPrompt = _.get(config, 'features.pwaPrompt');
		const promptConfig = _.get(config, 'pwaPrompt');
		if (isTargetDevice && isNotPwa && isNotApp) {
			const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
			const isConsumer = UserService.isConsumer();
			const currentIcon = appleTouchIcon && appleTouchIcon.href;
			console.log({ currentIcon });
			const consumerIcon = 'consumer-single-page-icon.png';
			if (isConsumer && appleTouchIcon && currentIcon && currentIcon.indexOf(consumerIcon) < 0) {
				document.querySelector('#appManifest').setAttribute('href', '/consumer-manifest.json');
				appleTouchIcon.href = `images/${consumerIcon}`;
			}
			if (!isConsumer && appleTouchIcon && currentIcon && currentIcon.indexOf(consumerIcon) > -1) {
				document.querySelector('#appManifest').setAttribute('href', '/manifest.json');
				appleTouchIcon.href = `images/single-page-icon.png`;
			}
		}
		if (hasPrompt && promptConfig) {
			const promptLastSeen = (await storageService.getValue('app', 'pwaPrompt')) || 0;
			const frequency = _.get(promptConfig, 'frequency.days', 7);
			const oneDay = 60 * 60 * 24 * 1000;
			const currentDate = new Date().getTime();
			const meetsTimeRequirements = currentDate - promptLastSeen > oneDay * frequency;
			if (meetsTimeRequirements && isTargetDevice && isNotPwa && isNotApp) {
				await storageService.setValue('app', 'pwaPrompt', currentDate);
				appStore.showPwaPrompt = true;
				analytics.track('Show PWA Prompt');
			} else {
				appStore.showPwaPrompt = false;
			}
		} else {
			appStore.showPwaPrompt = false;
		}
	}

	async apiLogin(f7) {
		try {
			const apiUser = await CloudAPI.login();
			if (apiUser) {
				userStore.isLoggedIn = true;
				userStore.userData = apiUser;
				userStore.consumerUser = apiUser.user;
				appStore.apiIssues = false;
				await UserService.setDefaultLocation();
				storageService.setValue('app', 'showFilterMenu', true);
				return { status: true };
			} else {
				if (!userStore.isCreatingUser) {
					f7.dialog.alert(this.text.unknownLoginError);
					firebase.auth().signOut();
				}
				return { status: false, reason: 'api_user_not_found' };
			}
		} catch (err) {
			appStore.apiIssues = true;
			if (!userStore.isCreatingUser) {
				const errorMessage = _.get(JSON.parse(err.response), 'message') || 'unknownLoginError';
				const errorText = _.get(this.text, errorMessage) || this.text.unknownLoginError;
				f7.dialog.alert(errorText, () => {
					firebase.auth().signOut();
					return { status: false, reason: 'api_login_error', err: err };
				});
			}
			analytics.trackWithData('login_error', { reason: err.response });
		}
	}

	async handleLogout(f7) {
		const currentPath = _.get(f7, 'views.main.router.currentRoute.path');
		const isLoginPage = currentPath && currentPath.indexOf('login') > -1;
		if (!currentPath || !isLoginPage) {
			f7.views.main.router.navigate('/login', { clearPreviousHistory: true, reloadAll: false });
		} else if (isLoginPage && appStore.apiIssues) {
			f7.views.main.router.refreshPage();
		}
		if (appStore.demoMode) {
			//if the user has been logged out & we're going to take them to login we need to clear this in case subsequent login admin check fails
			await storageService.setValue('app', 'demoMode', null);
			appStore.demoMode = null;
		}
		providerStore.searchResults = [];
		userStore.userToken = undefined;
		userStore.isLoggedIn = false;
		userStore.firebaseUser = null;
		userStore.userData = null;
		userStore.manualLocation = false;
		appStore.configLoaded = false;
		appStore.questionnaires = null;
		appStore.showQuestionnaire = false;
		userStore.recaptchaId = null;
		ConfigService.resetConfig();
		analytics.track('logout');
		amplitude.getInstance().setUserId(null);
	}

	async handleAuthChange(authUser, f7) {
		const signinType = (await storageService.getValue('app', 'signInType')) || 'unknown';
		if (signinType.indexOf('consumer') > -1) {
			await this.handleConsumerSignin(authUser, f7);
			return;
		}
		if (userStore.isCreatingUser || (authUser && !authUser.emailVerified)) return false;
		appStore.showReauthLoader = true;
		if (authUser) {
			await this.handleAuthProviderLogin(authUser);
			const apiLoginStatus = await this.apiLogin(f7);
			analytics.initUser();
			notifications.init();
			await UserService.handleClientTimezone();
			await UserService.handleLanguagePreferences();
			await UserService.handleUIPreference();
			await UserService.getUserAppointments();
			if (apiLoginStatus.status) {
				analytics.trackWithData('login', { type: 'employer' });
				const demoVal = await storageService.getValue('app', 'demoMode');
				appStore.demoMode = demoVal || null;
				await ConfigService.initConfig();
				this.handleQuestionnaireConfig();
				this.handlePwaPrompt();
				this.getUpdatedCareTeam();
				appStore.activeTab = 'home';
				if (_.get(f7, 'views.appShell.router')) {
					f7.views.appShell.router.navigate('/home');
					f7.views.appShell.router.refreshPage();
				}
			} else {
				analytics.trackWithData('login_error', { reason: apiLoginStatus.reason, type: 'employer' });
				this.handleLogout(f7);
			}
		} else {
			analytics.initUser();
			this.handleLogout(f7);
		}
		appStore.showReauthLoader = false;
		f7.dialog.close();
	}

	//! ============= CONSUMER CALLS ===========================

	initRecaptcha(id) {
		console.log('init recaptcha called: ', id);
		const verifierId = id ? id : 'recaptcha';
		const alreadyRendered = userStore.recaptchaId === verifierId;
		if (!alreadyRendered) {
			userStore.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(verifierId, {
				size: 'invisible',
				callback: function (response) {
					console.log('It works!');
				}
			});
			userStore.recaptchaId = verifierId;
			userStore.reCaptchaVerifier.render();
		}
	}

	initRecaptchaListener(f7) {
		const recaptchaWindow = document.getElementsByTagName('body')[0].lastChild;
		window.recaptchaListener = new MutationObserver((x) => this.onRecaptchaClose(f7, recaptchaWindow.style.visibility === 'hidden'));
		window.recaptchaListener.observe(recaptchaWindow, { attributes: true, attributeFilter: ['style'] });
	}

	onRecaptchaClose(f7, closed) {
		console.log('Recaptcha was closed: ', closed);
		if (closed) {
			window.recaptchaListener.disconnect();
			window.recaptchaListener = null;
			if (!userStore.confirmationResult) {
				console.log('no confirmation result');
				f7.dialog.close();
			} else {
				console.log('has confirmation result');
			}
		}
	}

	async sendSigninCode(phone, f7) {
		if (!window.recaptchaListener) {
			this.initRecaptchaListener(f7);
		}
		return new Promise((resolve) => {
			firebase
				.auth()
				.signInWithPhoneNumber(`+1${phone}`, userStore.reCaptchaVerifier)
				.then((confirmationResult) => {
					console.log('Code sent successfully.');
					//this is the object that allows us tpass the code to it
					userStore.confirmationResult = confirmationResult;
					resolve(true);
				})
				.catch((err) => {
					console.log('recaptcha error happened: ', err);
					const errMessage = err.message;
					analytics.trackWithData('Consumer Send Verification Code failure', { message: errMessage });
					//example fb block message: We have blocked all requests from this device due to unusual activity. Try again later.
					if (errMessage && errMessage.indexOf('blocked all requests') > -1) {
						f7.dialog.alert(errMessage, () => resolve(false));
					} else {
						resolve(false);
					}
				});
		});
	}

	async confirmSigninCode(code) {
		return new Promise((resolve) => {
			if (!userStore.confirmationResult) {
				analytics.track('Invalid sign in code');
				resolve({ success: false, error: 'no confirmation result' });
			}
			userStore.confirmationResult
				.confirm(code)
				.then((result) => {
					userStore.firebaseUser = result.user;
					userStore.confirmationResult = null;
					resolve({ success: true });
				})
				.catch((err) => {
					console.log(err);
					resolve({ success: false, error: err.toString() });
				});
		});
	}

	async createConsumer(consumer) {
		return new Promise((resolve) => {
			CloudAPI.createConsumer(consumer)
				.then((res) => {
					userStore.consumerUser = res;
					resolve(true);
				})
				.catch((err) => {
					console.log(err);
					resolve(false);
				});
		});
	}

	async setupConsumerToken() {
		await this.handleAuthProviderLogin(userStore.firebaseUser);
	}

	async handleConsumerSignin(authUser, f7) {
		if (userStore.isCreatingUser) {
			analytics.initUser();
			return false;
		}
		analytics.trackWithData('consumer_authentication_triggered', { isCreatingUser: userStore.isCreatingUser, hasUser: !!authUser });
		appStore.showReauthLoader = true;
		if (authUser) {
			await this.handleAuthProviderLogin(authUser);
			const apiLoginStatus = await this.apiLogin(f7);
			analytics.initUser();
			notifications.init();
			await UserService.handleClientTimezone();
			await UserService.handleLanguagePreferences();
			await UserService.handleUIPreference();
			if (apiLoginStatus.status) {
				analytics.trackWithData('login', { type: 'consumer' });
				await ConfigService.initConfig();
				this.handleQuestionnaireConfig();
				this.handlePwaPrompt();
				this.getUpdatedCareTeam();
				appStore.activeTab = 'home';
				if (!appStore.stripePromise && UserService.isConsumer()) {
					appStore.stripePromise = loadStripe(getStripeKey());
				}
				if (_.get(f7, 'views.appShell.router')) {
					f7.views.appShell.router.navigate('/home');
					f7.views.appShell.router.refreshPage();
				}
			} else {
				analytics.trackWithData('login_error', { reason: apiLoginStatus.reason, type: 'consumer' });
				this.handleLogout(f7);
			}
		} else {
			analytics.initUser();
			this.handleLogout(f7);
		}
		appStore.showReauthLoader = false;
		f7.dialog.close();
	}
}

export default new AuthService();
