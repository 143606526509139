import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../../utils/State';
import { autoBind } from '../../../../utils/GeneralUtils';
import {
	Button,
	Link,
	ListInput,
	List,
	ListItem,
	Toggle
} from 'framework7-react';
import CloudAPI from '../../../../services/CloudAPI';
import UserNotes from '../user-notes/UserNotes';
import './edit-user.scss';

@observer
export default class EditUserForm extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			email: _.get(this.props, 'user.email', ''),
			phone: _.get(this.props, 'user.phone', ''),
			password: ''
		});
		autoBind(this);
	}

	updateField(evt) {
		const field = evt.currentTarget.name;
		const value = evt.currentTarget.value;
		this.data[field] = value;
	}

	async smsPasswordReset(evt) {
		const userUid = _.get(this.props, 'user.userUid');
		const response = await CloudAPI.smsUserPasswordReset(
			userUid,
			this.data.email
		);

		let options = {
			position: 'bottom',
			text: response.success ?? 'ERROR OCCURRED: ' + response.error,
			closeTimeout: 3000
		};

		if (response.success) {
			options.icon = `<i class="fad fa-check large-icon-toast" ></i>`;
		}

		if (response.error) {
			options.icon = `<i class="fa fa-exclamation large-icon-toast" style="color:#daaf12" ></i>`;
			options.closeTimeout = 5000;
		}

		let toast = this.$f7.toast.create(options);
		toast.open();
		if (response.error) console.log('response:: ', response); // DEBUGGING
	}

	async emailPasswordReset(evt) {
		const userUid = _.get(this.props, 'user.userUid');
		const response = await CloudAPI.emailUserPasswordReset(
			userUid,
			this.data.email
		);
		let options = {
			position: 'bottom',
			text: response.success ?? 'ERROR OCCURRED: ' + response.error,
			closeTimeout: 3000
		};

		if (response.success) {
			options.icon = `<i class="fad fa-check large-icon-toast" ></i>`;
		}

		if (response.error) {
			options.icon = `<i class="fa fa-exclamation large-icon-toast" style="color:#daaf12" ></i>`;
			options.closeTimeout = 5000;
		}

		let toast = this.$f7.toast.create(options);
		toast.open();
		if (response.error) console.log('response:: ', response); // DEBUGGING
	}

	async submitForm() {
		const data = _.cloneDeep(this.data);
		const userUid = _.get(this.props, 'user.userUid');
		this.$f7.dialog.preloader('Processing User Update');
		try {
			await CloudAPI.updateUser(userUid, data);
			this.$f7.dialog.close();
			this.$f7.dialog.alert('User Updated', () =>
				this.props.close({ refresh: true })
			);
		} catch (err) {
			console.log({ err });
			this.$f7.dialog.close();
			this.$f7.dialog.alert(`Error editing the user: ${err}`);
		}
	}

	clearField(evt) {
		const field = evt.currentTarget.name;
		this.data[field] = '';
	}

	updateEmailVerification() {
		const { emailVerified } = this.data;
		this.data.emailVerified = !emailVerified;
	}

	async getVerificationStatus() {
		const { user } = this.props;
		try {
			this.$f7.dialog.preloader('Checking email verification status');
			const emailVerificationStatus = await CloudAPI.getEmailVerificationStatus(
				user.userUid
			);
			this.data.emailVerified = _.get(
				emailVerificationStatus,
				'verified',
				false
			);
			this.$f7.dialog.close();
		} catch (err) {
			console.log({ err });
			this.$f7.dialog.close();
			this.$f7.dialog.alert(
				`Error retrieving email verification status: ${err}`
			);
		}
	}

	async viewNotes() {
		this.data.showNotes = true;
	}

	closeNotes() {
		this.data.showNotes = false;
	}

	render() {
		const { user } = this.props;
		const { email, phone, emailVerified } = this.data;
		const hasVerificationData = this.data.hasOwnProperty('emailVerified');
		return (
			<div className="edit-user-form">
				<h2>
					Edit {user.firstName} {user.lastName}
					<i className="fad fa-times-square" onClick={this.props.close} />
				</h2>
				<List noHairlines>
					<ListItem>
						{hasVerificationData && (
							<Fragment>
								<span>Email Verified</span>
								<Toggle
									checked={emailVerified}
									onToggleChange={this.updateEmailVerification}
								/>
							</Fragment>
						)}
						{!hasVerificationData && (
							<Link onClick={this.getVerificationStatus}>
								Check Email Verification Status
							</Link>
						)}
					</ListItem>
					<ListItem>
						<Link onClick={this.viewNotes}>
							User Notes And Past SMS Messages
						</Link>
					</ListItem>
					<ListInput
						label="Email Address"
						type="text"
						placeholder="Email Address"
						clearButton
						className="md"
						floatingLabel
						name="email"
						value={email}
						onChange={this.updateField}
						onInputClear={this.clearField}
						info={`*If you edit the email address the user's login username will also be udpated`}
					/>
					<ListInput
						label="Phone Number"
						type="text"
						placeholder="Phone Number"
						clearButton
						className="md"
						floatingLabel
						name="phone"
						value={phone}
						onChange={this.updateField}
						onInputClear={this.clearField}
					/>
				</List>
				<div className="actions-container">
					<Button fill round onClick={this.submitForm}>
						Update User
					</Button>
					<Button fill round onClick={this.smsPasswordReset}>
						SMS Password Reset
					</Button>
					<Button fill round onClick={this.emailPasswordReset}>
						Email Password Reset
					</Button>
				</div>
				<UserNotes
					show={this.data.showNotes}
					close={this.closeNotes}
					userUid={_.get(this.props, 'user.userUid')}
				/>
			</div>
		);
	}
}
