import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showExpressError } from '@/utils/GeneralUtils';
import expressStore from '@/stores/ExpressStore';
import _ from 'lodash';
import './edi-config-mgmt.scss';
import ExpressAPI from '@/services/ExpressAPI';
import { Button } from 'framework7-react';
import { format } from 'date-fns';

@observer
export default class EDIConfigManager extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loadedFrom: null,
			defaultConfig: null
		});
		autoBind(this);
	}

	componentDidMount() {}

	asyncFetchConfig() {
		setTimeout(() => {
			this.fetchConfig();
		}, 0);
	}

	fetchConfig() {
		this.$f7.dialog.preloader('Fetching Configuration');
		this.data.loadedFrom = _.cloneDeep(expressStore.currentOrg._id);
		ExpressAPI.getDefaultEDIConfig()
			.then(async (res) => {
				this.data.defaultConfig = res;
				this.$f7.dialog.close();
			})
			.catch((err) => {
				showExpressError(this.$f7, err);
			});
	}

	onDeleteConfig() {
		this.$f7.dialog.confirm('Deleting this config cannot be undone', 'Are you sure?', () => {
			this.$f7.dialog.preloader('Deleting EDI Config');
			ExpressAPI.deleteEDIConfig(this.data.defaultConfig._id)
				.then((res) => {
					this.$f7.dialog.close();
					//causes a reload of everything
					this.data.loadedFrom = null;
				})
				.catch((err) => {
					showExpressError(this.$f7, err);
				});
		});
	}

	render() {
		let { currentOrg } = expressStore;
		let { loadedFrom, defaultConfig } = this.data;
		if (loadedFrom !== currentOrg._id) {
			this.asyncFetchConfig();
		}
		return (
			<div className="express-edi-config-page">
				<div className="config-header hbox vcenter">
					<h2 className="grow-1">EDI Configuration</h2>
					{!defaultConfig && (
						<Button
							small
							fill
							className="list-btn ios"
							onClick={() => {
								expressStore.activePage = 'importdata';
							}}
						>
							<i className="fad fa-sliders-h"></i>
							Configure EDI
						</Button>
					)}
				</div>
				{!defaultConfig && (
					<div className="no-config vbox vcenter hcenter">
						<h3>No EDI Configuration Found</h3>
						<p>
							In order to process your unique EDI 834 format, we need to create a one time mapping file to identify where the data that
							Express needs to function lives in your file format.
						</p>
						<Button
							small
							fill
							className="list-btn ios"
							onClick={() => {
								expressStore.activePage = 'importdata';
							}}
						>
							<i className="fad fa-sliders-h"></i>
							Configure EDI
						</Button>
					</div>
				)}
				{defaultConfig && (
					<div className="vbox vcenter hleft">
						<div className="config-card">
							<div className="hbox vcenter config-card-header">
								<div className="grow-1"></div>
								<i
									className="fad fa-trash"
									onClick={() => {
										this.onDeleteConfig();
									}}
								></i>
							</div>
							<div className="config-icon">
								<i className="fad fa-file"></i>
								<i className="fad fa-sliders-h"></i>
							</div>
							<h4>{defaultConfig.name}</h4>
							<div className="field hbox vcenter">
								<div className="label">Created:</div>
								<div className="value">{format(new Date(defaultConfig.lastUpdated), 'M/dd/yy h:mm b')}</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
