import { getCmpText } from '@/utils/Localization';

import { observer } from '@/utils/State';
import React, { Component } from 'react';
import './authorizing-loader.scss';

@observer
export default class AuthorizingLoader extends Component {
	constructor() {
		super();
		this.text = getCmpText('authorizingLoaderCmp', {
			authorizingText: 'Authorizing'
		});
	}
	render() {
		return (
			<div className="payment-loader">
				<div className="pad">
					<div className="chip"></div>
					<div className="line line1"></div>
					<div className="line line2"></div>
				</div>
				<h2>{this.text.authorizingText}</h2>
			</div>
		);
	}
}
