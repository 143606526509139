import React, { Component } from 'react';
import { Actions, ActionsButton, ActionsGroup, ActionsLabel, Gauge, Link, List, ListItem, Popover, Preloader } from 'framework7-react';
import { observer, observable } from '../../utils/State';
import { getCmpText, getLocalizedQuickLinks } from '../../utils/Localization';
import { autoBind } from '../../utils/GeneralUtils';
import _ from 'lodash';
import './home-page.scss';
import appStore from '../../stores/AppStore';
import GlobalHeader from '../../components/global-header/GlobalHeader';
import CareTeam from '../../components/care-team/CareTeam';
import ExternalService from '../../services/ExternalService';
import UserService from '../../services/UserService';
import providerStore from '../../stores/ProviderStore';
import StorageService from '../../services/StorageService';
import MemberDocumentViewer from '../../components/member-document-viewer/MemberDocumentViewer';
import AnalyticsService from '../../services/AnalyticsService';
import ConfigService from '../../services/ConfigService';
import ProviderService from '../../services/ProviderService';
import providerSearch from '@/assets/provider-search2.svg';
import mapSVG from '@/assets/mapbg.svg';
import AccumulatorWidgit from '../../components/accumulator-widgit/AccumulatorWidget';
import userStore from '@/stores/UserStore';
import CloudAPI from '@/services/CloudAPI';
import testingSVG from '@/assets/testfinder.svg';
import cvsSVG from '@/assets/consumerservices.png';
import walletPng from '@/assets/wallet.png';
import TestFinder from '@/components/test-finder/TestFinder';
import { ScreeningToolPopup } from '@/components/screening-tool-popup/ScreeningToolPopup';
import HomeCoverflow from './HomeCoverflow';
import AffiliateLinkPopup from '@/components/affiliate-link-popup/AffiliateLinkPopup';
import WalletPopup from '@/components/wallet-popup/WalletPopup';

const storageService = new StorageService();
const analytics = new AnalyticsService();

@observer
class HomePage extends Component {
	constructor(props) {
		super(props);
		this.linkRef = React.createRef();
		this.data = observable({
			walletOpen: false,
			urgentOpen: false,
			telehealthOpen: false,
			showGallery: false,
			rewardsLoading: true,
			documentType: null,
			activeCard: 1,
			rewards: 0,
			showRewardsLinkPopup: false
		});
		this.text = getCmpText('HomePage', {
			walletText: 'Benefits Wallet',
			walletMessage: 'Tap here to view ID Cards, Benefit Documents, and more!',
			nearbyTitle: 'Quick Search:',
			urgentCareTitle: 'Urgent Care',
			cancel: 'Cancel',
			telehealth: 'Talk to a physician now',
			emergency: '911 Emergency',
			crisis: '211 Crisis Hotline',
			urgentCare: 'Find Nearest Urgent Care Center',
			viewMemberCard: 'View ID Card',
			viewBenefits: 'View Benefits',
			loading: 'Loading',
			finderText: 'Provider Finder',
			visitUrgentCareUrlText: 'Search for Urgent Care Locations',
			shareAlert: 'Your unique CareValet link has been copied to your clipboard and is ready to share.',
			shareText:
				'You can join CareValet for free with my link and gain access to best in class telehealth, prescription discounts, and more!',
			rewardsDetails: 'Invite your friends to signup and use CareValet. When they do, you get points towards a free care credit. ',
			rewardsTitle: 'Rewards Progress',
			rewardsSubtitle: 'Free Care Credit',
			details: 'Details',
			inviteFriends: 'Invite Friends',
			testFinderText: 'Test Finder',
			visitsText: 'In Person Visits',
			visitsMessage: 'Over 125 different services you can schedule today!',
			covidText: 'testing site finder, updated daily',
			showQrCode: 'Show QR Code',
			shareRewardsLink: 'Share Invite Link'
		});
		autoBind(this);
	}

	componentDidMount() {
		if (UserService.isConsumer()) {
			CloudAPI.getUserRewards()
				.then((res) => {
					setTimeout(() => {
						this.data.rewardsLoading = false;
						setTimeout(() => {
							this.data.rewards = res.points;
						}, 300);
					}, 1500);

					if (res.addedToken) {
						_.set(userStore, 'userData.user.tokenCount', res.tokenCount);
						let toast = this.$f7.toast.create({
							position: 'center',
							closeTimeout: 4000,
							icon: `<i class="fad fa-coin large-icon-toast" ></i>`,
							text: '1 Care Credit Earned!'
						});
						toast.open();
					}
				})
				.catch((err) => {
					this.data.rewardsLoading = false;
					console.log(err);
				});
		}
	}

	async componentWillUnmount() {
		this.$f7.popover.close('.quick-links-dd', false);
	}

	async onFindNearby(specialty, data) {
		storageService.setValue('app', 'showFilterMenu', false);
		providerStore.filters.category = data.category || 'physician';
		providerStore.filters.specialty = specialty;
		this.$f7.dialog.preloader(`${this.text.loading} ${data.label}`);
		analytics.trackWithData('quick_search', { specialty: specialty });
		try {
			await ProviderService.searchForProviders();
		} catch (err) {
			console.log('error searching providers: ', err);
		}
		this.$f7.dialog.close();
		appStore.activeTab = 'doctors';
	}

	async fireUrgentCareSearch() {
		analytics.track('Urgent Care Search');
		storageService.setValue('app', 'showFilterMenu', false);
		providerStore.filters.category = 'Urgent Care';
		providerStore.filters.specialty = null;
		this.$f7.dialog.preloader(`${this.text.loading} ${this.text.urgentCareTitle}`);
		try {
			await ProviderService.searchForProviders();
		} catch (err) {
			console.log('error searching providers: ', err);
		}
		this.$f7.dialog.close();
		appStore.activeTab = 'doctors';
	}

	onUrgentCare() {
		analytics.track('open_urgent_care_menu');
		this.data.urgentOpen = true;
	}

	launchImageViewer(evt) {
		const selectedOption = evt.currentTarget.getAttribute('data-type');
		this.data.documentType = selectedOption;
		this.data.showGallery = true;
	}

	closeGallery() {
		this.data.showGallery = false;
	}

	onQuickSearch() {}

	onTestFinderClick() {
		appStore.testFinderOpen = true;
	}

	buildMemberInfo(config) {
		return (
			<>
				{!_.get(config, 'features.turnOffProvider', false) && (
					<div
						className="search-card hbox vcenter hcenter neumorphic neuhover"
						onClick={this.onFindCareClick}
						data-type="providerFinder"
					>
						<img src={providerSearch} className="searchsvg" />
						<div className="vbox vcenter hcenter">
							<img src={mapSVG} className="mapsvg" />
							<div className="btn-text">{this.text.finderText}</div>
						</div>
					</div>
				)}

				<div className="membership-info-ctn hbox vcenter">
					<div
						className={`member-card vbox vcenter hcenter neumorphic neuhover ${UserService.isConsumer() ? '' : 'm-r-16'}`}
						onClick={this.onUrgentCare}
						data-type="idCard"
					>
						<i className="fad fa-bell-exclamation"></i>
						<div className="btn-text">{this.text.urgentCareTitle}</div>
					</div>
					{!_.get(config, 'features.turnOffProvider', false) && (
						<Link
							className="quick-search member-card vbox vcenter hcenter neumorphic neuhover"
							popoverOpen={`.quick-links-dd`}
							id="quick-links-dd"
						>
							<i className="fad fa-bolt"></i>
							<div className="btn-text">{this.text.nearbyTitle}</div>
						</Link>
					)}
				</div>
				{!UserService.isConsumer() && !userStore.isExpressUser() && (
					<div className="membership-info-ctn hbox vcenter">
						<div
							className="member-card vbox vcenter hcenter neumorphic neuhover m-r-16"
							onClick={this.launchImageViewer}
							data-type="idCard"
						>
							<i className="fad fa-id-card"></i>
							<div className="btn-text">{this.text.viewMemberCard}</div>
						</div>
						<div className="member-card vbox vcenter hcenter neumorphic neuhover" onClick={this.launchImageViewer} data-type="sobDocs">
							<i className="fad fa-files-medical"></i>
							<div className="btn-text">{this.text.viewBenefits}</div>
						</div>
					</div>
				)}
			</>
		);
	}

	onFindCareClick() {
		storageService.setValue('app', 'showFilterMenu', true);
		appStore.activeTab = 'doctors';
	}

	buildUrgentActions(config) {
		let urgentConfig = _.get(config, 'urgentCare.actions', {
			1: { type: 'call', text: this.text.telehealth },
			2: { type: 'emergency', color: 'red' },
			3: { type: 'crisis' },
			4: { type: 'urgentCare' }
		});
		const isConsumer = UserService.isConsumer();
		const filteredOptions = Object.values(urgentConfig).filter((option) => {
			if (isConsumer && option.type === 'call') {
				return false;
			}
			if (isConsumer && option.type === 'urgentCare') {
				return false;
			}
			if (option.type === 'call') {
				const telehealthNumber = ExternalService.getTelehealthNumber();
				return !!telehealthNumber;
			}
			if (option.type === 'hide') {
				return false;
			}
			return true;
		});
		const options = filteredOptions.map((option, index) => {
			const optionText = option.text ? option.text : option.textKey ? this.text[option.textKey] : this.text[option.type];
			const isUrgentCare = option.type === 'urgentCare';
			const isTelehealth = option.type === 'call';
			const optionType = option.type === 'custom' ? option.action : option.type;
			if (isUrgentCare) {
				return (
					<ActionsButton
						key={`${option.type}-${index}-urgent-care-action`}
						onClick={this.fireUrgentCareSearch}
						color={option.color || 'black'}
					>
						{optionText}
					</ActionsButton>
				);
			}
			if (isTelehealth) {
				return (
					<ActionsButton
						key={`${option.type}-${index}-urgent-care-action`}
						onClick={() => ExternalService.onTelehealthClick(optionType)}
						color={option.color || 'black'}
					>
						{optionText}
					</ActionsButton>
				);
			}
			return (
				<ActionsButton
					key={`${option.type}-${index}-urgent-care-action`}
					onClick={() => ExternalService.handleExternalRequest(optionType, option.data)}
					color={option.color || 'black'}
				>
					{optionText}
				</ActionsButton>
			);
		});
		return options;
	}

	buildQuickLinks() {
		const quickLinks = getLocalizedQuickLinks().filter((ql) => !ql.hide);
		let listItems = [];
		_.forEach(quickLinks, (s) => {
			listItems.push(
				<ListItem
					link="#"
					className="quick-list-item hbox vcenter"
					key={Math.random()}
					onClick={() => {
						this.onFindNearby(s.value, s);
					}}
				>
					<div className="label">{s.label}</div>
				</ListItem>
			);
		});
		return listItems;
	}

	generateRewardsLink() {
		const shareUrl = `https://${window.location.host}/#!/login?ref=${_.get(userStore, 'userData.member.memberId')}`;
		if (navigator.share) {
			analytics.trackWithData('Share rewards link', { data: shareUrl, type: 'navigator' });
			const shareData = {
				title: 'Check Out CareValet',
				text: this.text.shareText,
				url: shareUrl
			};
			navigator.share(shareData);
		} else {
			analytics.trackWithData('Share rewards link', { data: shareUrl, type: 'copied' });
			const linkDiv = this.linkRef && this.linkRef.current;
			linkDiv.select();
			document.execCommand('copy');
			this.$f7.dialog.alert(this.text.shareAlert);
		}
	}

	closeRewardsLinkPopup() {
		this.data.showRewardsLinkPopup = false;
	}

	openRewardsLinkPopup() {
		const shareUrl = `https://${window.location.host}/#!/login?ref=${_.get(userStore, 'userData.member.memberId')}`;
		analytics.trackWithData('Share rewards link', { data: shareUrl, type: 'show QR code' });
		this.data.showRewardsLinkPopup = true;
	}

	initRewardsShareOptions() {
		this.$f7.dialog
			.create({
				title: this.text.inviteFriends,
				cssClass: 'rewards-share-options-dialog',
				buttons: [
					{
						text: this.text.showQrCode,
						cssClass: 'create-rewards-qr-code',
						onClick: this.openRewardsLinkPopup
					},
					{
						text: this.text.shareRewardsLink,
						cssClass: 'create-rewards-link',
						onClick: this.generateRewardsLink
					},
					{
						text: this.text.cancel,
						cssClass: 'rewards-link-cancel',
						onClick: () => this.$f7.dialog.close()
					}
				],
				verticalButtons: true
			})
			.open();
	}

	buildRewardsCard() {
		return (
			<div className="rewards-card neumorphic">
				<div className="title">{this.text.rewardsTitle}</div>
				{this.data.rewardsLoading && <Preloader color="multi"></Preloader>}
				{!this.data.rewardsLoading && (
					<Gauge
						type="circle"
						value={this.data.rewards / 100}
						size={100}
						borderColor="#6769f3"
						borderWidth={12}
						valueText={`${this.data.rewards}%`}
						valueFontSize={22}
						valueTextColor="#6769f3"
					></Gauge>
				)}
				<div className="sub-text">{this.text.rewardsSubtitle}</div>
				<div className="card-btns hbox vcenter">
					<div
						className="action"
						onClick={() => {
							this.$f7.dialog.alert(this.text.rewardsDetails);
						}}
					>
						<i className="fad fa-info-circle"></i>
						{this.text.details}
					</div>
					<div className="action" onClick={this.initRewardsShareOptions}>
						<textarea
							readOnly
							className="link-text"
							value={`https://${window.location.host}/#!/login?ref=${_.get(userStore, 'userData.member.memberId')}`}
							ref={this.linkRef}
						></textarea>
						<i className="fad fa-share-alt"></i>
						{this.text.inviteFriends}
					</div>
				</div>
			</div>
		);
	}

	onCVSClick() {
		analytics.track('cvs_link_clicked');
		ExternalService.handleExternalRequest(
			'web',
			`https://www.cvs.com/minuteclinic/clinic-locator/?q=${_.get(appStore, 'currentLocation.zip', '33607')}`
		);
	}

	onWalletClose() {
		this.data.walletOpen = false;
	}

	onWalletClick() {
		analytics.track('wallet_opened');
		this.data.walletOpen = true;
	}

	buildWalletCard() {
		return (
			<div
				className="wallet-card hbox vcenter hcenter neumorphic neuhover"
				onClick={this.onWalletClick}
				data-type="benefitWallet"
				key="home-page-benefits-wallet"
			>
				<img src={walletPng} className="walletpng" />
				<div className="vbox vcenter hcenter">
					<div className="btn-text">{this.text.walletText}</div>
					<div className="msgtxt">{this.text.walletMessage}</div>
				</div>
			</div>
		);
	}

	render() {
		if (UserService.isPDM()) {
			return (
				<div className={`home-page-component ${appStore.isMobile ? '' : 'desktop'}`} key="home-page-component">
					<GlobalHeader />
					<div className="pdm-intro">
						<h1>Welcome Data Manager</h1>
						<h2>Click 'Provider Mgmt' To Get Started</h2>
					</div>
				</div>
			);
		}
		let { urgentCareTitle, cancel } = this.text;
		let config = ConfigService.getConfiguration();
		return (
			<div className={`home-page-component ${appStore.isMobile ? '' : 'desktop'}`} key="home-page-component">
				<GlobalHeader />
				<div className="home-content y-scroll animated fadeInUp">
					<div className="left">
						<HomeCoverflow />
						{userStore.isExpressUser() && this.buildWalletCard()}
						{_.get(config, 'features.testFinder', true) && !appStore.isApp() && (
							<div
								className="test-finder-card hbox vcenter hcenter neumorphic neuhover"
								onClick={this.onTestFinderClick}
								data-type="testFinder"
							>
								<img src={testingSVG} className="searchsvg" />
								<div className="vbox vcenter hcenter">
									<div className="btn-text">{this.text.testFinderText}</div>
									<div className="msgtxt">
										<span>COVID-19</span>
										{this.text.covidText}
									</div>
								</div>
							</div>
						)}
						{_.get(config, 'features.cvsLink', true) && !appStore.isApp() && (
							<div
								className="cvs-finder-card hbox vcenter hcenter neumorphic neuhover"
								onClick={this.onCVSClick}
								data-type="testFinder"
							>
								<img src={cvsSVG} className="searchsvg" />
								<div className="vbox vcenter hcenter">
									<div className="btn-text">{this.text.visitsText}</div>
									<div className="msgtxt">{this.text.visitsMessage}</div>
								</div>
							</div>
						)}
						{UserService.isConsumer() && this.buildRewardsCard()}
						{this.buildMemberInfo(config)}
						{_.get(config, 'features.homeAccumulators', false) && <AccumulatorWidgit />}
					</div>
					<div className="right">{!UserService.isConsumer() && !_.get(config, 'features.turnOffProvider', false) && <CareTeam />}</div>
					<Actions
						id="urgent-care-actions"
						opened={this.data.urgentOpen}
						onActionsClosed={() => {
							this.data.urgentOpen = false;
						}}
					>
						<ActionsGroup>
							<ActionsLabel bold className="hbox vcenter hcenter">
								{urgentCareTitle}
							</ActionsLabel>
							{this.buildUrgentActions(config)}
						</ActionsGroup>
						<ActionsGroup>
							<ActionsButton color="red">{cancel}</ActionsButton>
						</ActionsGroup>
					</Actions>
					<Popover className="quick-links-dd">
						<List>{this.buildQuickLinks()}</List>
					</Popover>
					<TestFinder
						opened={appStore.testFinderOpen}
						onCloseFinder={() => {
							appStore.testFinderOpen = false;
						}}
					/>
					<ScreeningToolPopup opened={appStore.screenerOpen} onClose={appStore.closeScreener} />
					{this.data.showGallery && <MemberDocumentViewer onClose={this.closeGallery} docType={this.data.documentType} />}
					<AffiliateLinkPopup
						isOpen={this.data.showRewardsLinkPopup}
						onClose={this.closeRewardsLinkPopup}
						title={this.text.inviteFriends}
						code={_.get(userStore, 'userData.member.memberId')}
					/>
					{userStore.isExpressUser() && <WalletPopup opened={this.data.walletOpen} onClose={this.onWalletClose}></WalletPopup>}
				</div>
			</div>
		);
	}
}

export default HomePage;
