import { autoBind } from '@/utils/GeneralUtils';
import { observer, observable } from '@/utils/State';
import React, { Component } from 'react';
import ImageNode from '../image-node/ImageNode';
import RectangleNode from '../rectangle-node/RectangleNode';
import TextNode from '../text-node/TextNode';
import _ from 'lodash';
import CanvasFactory from '@/factories/CanvasFactory';
import CircleNode from '../circle-node/CircleNode';

@observer
export default class CanvasNodes extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
		autoBind(this);
	}

	renderNodes() {
		const { nodeData } = CanvasFactory.getActiveStore();
		const canvasService = CanvasFactory.getActiveService();

		return Object.keys(nodeData).sort((a,b) => {return nodeData[a].zIndex - nodeData[b].zIndex} ).map((key) => {
			let node = nodeData[key];
			switch (node.type) {
				case 'variable':
				case 'text': {
					return (
						<TextNode
							{..._.cloneDeep(node)}
							onMouseOver={(e) => canvasService.onHighlight(e)}
							onMouseOut={(e) => canvasService.onHighlightOut(e)}
						></TextNode>
					);
				}
				case 'image': {
					return (
						<ImageNode
							{..._.cloneDeep(node)}
							onMouseOver={(e) => canvasService.onHighlight(e)}
							onMouseOut={(e) => canvasService.onHighlightOut(e)}
						/>
					);
				}
				case 'rectangle': {
					return (
						<RectangleNode
							{..._.cloneDeep(node)}
							onMouseOver={(e) => canvasService.onHighlight(e)}
							onMouseOut={(e) => canvasService.onHighlightOut(e)}
						/>
					);
				}
				case 'circle': {
					return (
						<CircleNode
							{..._.cloneDeep(node)}
							onMouseOver={(e) => canvasService.onHighlight(e)}
							onMouseOut={(e) => canvasService.onHighlightOut(e)}
						/>

					);
				}
				default:
					return <></>;
			}
		});
	}

	render() {
		const { nodeData } = CanvasFactory.getActiveStore();

		if (!nodeData) {
			return <></>;
		} else {
			return <>{this.renderNodes()}</>;
		}
	}
}
