import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import appStore from '@/stores/AppStore';
import DropDown from '@/components/drop-down/DropDown';
import './reporting-container.scss';
import { Button, ListItem, Popup, View, Page } from 'framework7-react';
import { format } from 'date-fns';
import Chart from '@/components/charts/Chart';

@observer
export default class Reporting extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			configData: {},
			selectedFilters: {},
			reportData: null,
			selectedReport: null,
			showDateFilter: false,
			dateFilterType: 'predefined'
		});
		autoBind(this);
	}

	async componentDidMount() {
		await this.setConfigurations();
	}

	async setConfigurations() {
		const configData = await this.getReportingConfigs();
		this.data.configData = _.cloneDeep(configData);
		for (let filterItem of configData.filters) {
			const itemKey = filterItem.itemKey;
			const receivedSelection = _.get(configData, `selectedFilters.${itemKey}`);
			if (receivedSelection) {
				this.data.selectedFilters[itemKey] = receivedSelection;
			} else {
				this.data.selectedFilters[itemKey] = 'all';
			}
		}
	}

	async handleDropdownSelection(category, selection, preventDataRefresh) {
		this.data.selectedFilters[category] = selection;
		if (this.data.selectedReport) {
			if (!preventDataRefresh) {
				await this.getReportData(this.data.selectedReport);
			}
		} else {
			await this.setConfigurations();
		}
	}

	async getReportingConfigs() {
		this.$f7.dialog.preloader();
		try {
			const requestData = _.cloneDeep(this.data);
			delete requestData.configData;
			delete requestData.reportData;
			const response = await this.props.getConfigs(this.data.selectedReport || 'all', requestData);
			this.$f7.dialog.close();
			return response;
		} catch (err) {
			this.$f7.dialog.close();
			console.log('error getting report configs: ', err);
			this.$f7.dialog.alert('Error getting reports data, please try again later');
			return {};
		}
	}

	getCategoryItems(category) {
		const categoryConfigs = _.get(this.data, `configData.filters`) || [];
		const categoryConfig = categoryConfigs.find((x) => x.itemKey === category);
		const categoryItems = _.get(this.data, `configData.filterData.${category}`) || [];
		return categoryItems;
	}

	getDropdownItems(category) {
		const categoryConfigs = _.get(this.data, `configData.filters`) || [];
		const categoryConfig = categoryConfigs.find((x) => x.itemKey === category);
		const categoryItems = _.get(this.data, `configData.filterData.${category}`) || [];
		const categorySelection = _.get(this.data, `selectedFilters.${category}`);
		const items = categoryItems.map((item) => {
			const itemId = item[categoryConfig.idKey];
			const itemDisplay = item[categoryConfig.displayKey];
			const isActive = categorySelection === itemId;
			return (
				<ListItem
					className={`reporting-dropdown-item ${isActive ? 'active' : ''}`}
					key={`orgDropdown-${category}-${itemId}`}
					onClick={() => this.handleDropdownSelection(category, itemId)}
					data-category={category}
					data-item={itemId}
					popoverClose=".cv-dropdown"
					link="#"
				>
					<div>{itemDisplay}</div>
				</ListItem>
			);
		});
		return items;
	}

	openDatePicker(evt) {
		const pickerId = evt.currentTarget.getAttribute('data-category');
		this.data.openPicker = pickerId;
		this.data.showDateFilter = true;
	}

	onCalendarChange(data) {
		const currentValues = data.getValue();
		const startDate = currentValues && currentValues[0] ? currentValues[0] : new Date();
		const endDate = currentValues && currentValues[1] ? currentValues[1] : startDate;
		const newDates = [startDate, endDate];
		this.data.selectedFilters.dateRanges = newDates;
		this.data.selectedFilters.displayDate = `${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}`;
	}

	closeDatePicker() {
		this.data.showDateFilter = false;
		const calendar = this.$f7.calendar;
		this.data.dateFilterType = 'predefined';
		if (calendar) {
			calendar.destroy();
		}
	}

	async openReport(evt) {
		try {
			const reportId = evt.currentTarget.getAttribute('data-report');
			this.data.selectedReport = reportId;
			await this.getReportData(reportId, true);
		} catch (err) {
			console.log('error getting report data: ', err);
			this.$f7.dialog.alert('Error getting report data, please try again later');
		}
	}

	async getReportData(reportId, clearReportFilters) {
		this.$f7.dialog.preloader();
		let selectedFilters = _.cloneDeep(this.data.selectedFilters);
		if (clearReportFilters) {
			const standardFilters = _.get(this.data, 'configData.filters') || [];
			const standardFilterKeys = standardFilters.map((x) => x.itemKey);
			const currentFilters = Object.keys(_.get(this.data, 'selectedFilters') || {});
			const newSelectedFilterKeys = currentFilters.filter((x) => standardFilterKeys.indexOf(x) > -1);
			const newFilters = {};
			newSelectedFilterKeys.map((x) => {
				const key = x;
				const val = _.get(this.data, `selectedFilters.${key}`);
				newFilters[key] = val;
			});
			selectedFilters = newFilters;
			this.data.reportData = null;
		}
		try {
			const reportsData = _.get(this.data, 'configData.reports') || [];
			const reportInfo = reportsData.find((x) => x.id === reportId);
			const requestData = _.cloneDeep(this.data);
			delete requestData.configData;
			delete requestData.reportData;
			requestData.selectedFilters = selectedFilters;
			const chartData = await this.props.getReportData(reportId, requestData);
			this.$f7.dialog.close();
			if (chartData.filters && chartData.filterData) {
				this.data.configData.filters = chartData.filters;
				this.data.configData.filterData = chartData.filterData;
			}
			this.data.selectedFilters = chartData.selectedFilters;
			this.data.reportData = {
				config: {
					...chartData.config,
					title: chartData.config.title || reportInfo.title,
					id: reportId,
					reportFilters: chartData.reportFilters,
					export: chartData.export,
					exportData: chartData.exportData
				},
				data: chartData.data,
				disclaimer: chartData.disclaimer
			};
		} catch (err) {
			this.$f7.dialog.close();
			console.log('error getting report data: ', err);
			this.$f7.dialog.alert('Error getting report data, please try again later');
		}
	}

	async backToMenu() {
		this.data.reportData = null;
		this.data.selectedReport = null;
		await this.setConfigurations();
	}

	toggleDateFilterType(evt) {
		const type = evt.currentTarget.getAttribute('data-type');
		this.data.dateFilterType = type;
		const calendar = this.$f7.calendar;
		const calendarContainer = '#datePickerContainer';
		if (type === 'custom') {
			setTimeout(() => {
				// give div a chance to mount
				this.$f7.calendar.create({
					value: _.get(this.data, 'selectedFilters.dateRanges'),
					containerEl: calendarContainer,
					rangePicker: true,
					touchMove: appStore.isMobile ? true : false,
					maxDate: new Date(),
					on: {
						change: this.onCalendarChange
					}
				});
			}, 500);
		} else {
			if (calendar) {
				calendar.destroy('.calendar');
			}
		}
	}

	async applyDateFilter() {
		this.data.showDateFilter = false;
		if (this.data.selectedReport) {
			await this.getReportData(this.data.selectedReport);
		} else {
			await this.setConfigurations();
		}
	}

	handleReportDateSelection(optionId) {
		const category = _.clone(this.data.openPicker);
		const pickerData = _.cloneDeep(_.get(this.data, `configData.filterData.dateRanges`) || []);
		const selectedPickerBody = pickerData.find((x) => x.id === this.data.dateFilterType);
		const thisOptionConfig = selectedPickerBody.options.find((x) => x.id === optionId) || {};
		const thisOptionValue = thisOptionConfig.values;
		const newDates = [new Date(thisOptionValue[0]), new Date(thisOptionValue[1])];
		this.data.selectedFilters[category] = newDates;
		this.data.selectedFilters.displayDate = thisOptionConfig.display;
	}

	render() {
		const filterOptions = _.get(this.data, 'configData.filters') || [];
		const reports = _.get(this.data, 'configData.reports') || [];
		const pickerData = _.cloneDeep(_.get(this.data, `configData.filterData.dateRanges`) || []);
		const selectedPickerBody = pickerData.find((x) => x.id === this.data.dateFilterType);
		const dateRangeSelected = _.get(this.data, 'selectedFilters.dateRanges');
		const selectedDateRange = dateRangeSelected && dateRangeSelected !== 'all';
		let selectedStartDate = _.get(this.data, 'selectedFilters.dateRanges[0]');
		let selectedEndDate = _.get(this.data, 'selectedFilters.dateRanges[1]');
		if (typeof selectedStartDate !== 'object' && selectedDateRange && selectedDateRange !== 'all') {
			selectedStartDate = new Date(selectedStartDate);
		}
		if (typeof selectedEndDate !== 'object' && selectedDateRange && selectedDateRange !== 'all') {
			selectedEndDate = new Date(selectedEndDate);
		}
		return (
			<div className={`vbox vcenter hcenter reporting-content ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
				<div className={`reporting-filters ${appStore.isMobile ? 'mobile' : 'desktop'}`}>
					{filterOptions.map((filter) => {
						switch (filter.type) {
							case 'dropdown':
								const dropdownItems = this.getDropdownItems(filter.itemKey);
								const activeItem = _.get(this.data, `selectedFilters.${filter.itemKey}`);
								const activeItems = this.getCategoryItems(filter.itemKey);
								const activeItemData = activeItems.find((x) => x[filter.idKey] === activeItem) || activeItems[0];
								return (
									<DropDown
										id={`reporting-dropdown-${filter.itemKey}`}
										className="filter-item"
										name={`reporting-dropdown-${filter.itemKey}`}
										key={`reporting-dropdown-${filter.itemKey}`}
										items={dropdownItems}
										displayValue={activeItemData ? activeItemData[filter.displayKey] : ''}
									/>
								);
							case 'datePicker':
								const selectedRange = _.get(this.data, `selectedFilters.${filter.itemKey}`);
								const startDate = _.get(this.data, `selectedFilters.${filter.itemKey}[0]`);
								const endDate = _.get(this.data, `selectedFilters.${filter.itemKey}[1]`);
								return (
									<div
										key={`reporting-date-picker-${filter.id}`}
										className="filter-item date-picker-btn neumorphic active"
										onClick={this.openDatePicker}
										data-category={filter.itemKey}
									>
										{_.get(this.data, 'selectedFilters.displayDate') || filter.name}
										<i className="fad fa-calendar-alt datepicker-icon" />
									</div>
								);
							default:
								return (
									<div className="filter-item" key={`unknown-display-type-${filter.id}`}>
										{filter.id} | {filter.type} | {filter.name} | {filter.itemKey} | {filter.displayKey} | {filter.idKey}{' '}
									</div>
								);
						}
					})}
				</div>
				{(!this.data.reportData || this.props.showReportMenu) && (
					<div className={`reports-menu  animated slideInRight`}>
						{reports.map((report) => {
							return (
								<div
									className={`report-item neumorphic ${report.id === this.data.selectedReport ? 'active' : ''}`}
									key={`report-item-${report.id}`}
									data-report={report.id}
									onClick={this.openReport}
								>
									<div className="report-icons">
										{report.icon && <i className={`report-icon ${report.icon}`} />}
										{report.icon2 && <i className={`report-icon ${report.icon2}`} />}
									</div>
									<div className="report-title">{report.title}</div>
								</div>
							);
						})}
					</div>
				)}
				{this.data.reportData && (
					<div className="kpi-container">
						<div className="kpi-header">
							{!this.props.showReportMenu && (
								<div className="kpi-header-cta back-btn-container" onClick={this.backToMenu}>
									<i className="fad fa-arrow-left" />
									<span className="back-text">All Reports</span>
								</div>
							)}
						</div>
						<Chart
							data={_.cloneDeep(this.data.reportData)}
							onFilterSelection={this.handleDropdownSelection}
							selectedFilters={this.data.selectedFilters}
							animate={true}
						/>
					</div>
				)}
				<Popup className="reporting-date-filter" opened={this.data.showDateFilter} onPopupClosed={this.closeDatePicker}>
					<View>
						<Page>
							<div className="header">
								{pickerData &&
									pickerData.map((pickerType) => {
										return (
											<div
												key={`datePickerType-${pickerType.id}`}
												className={`header-option ${this.data.dateFilterType === pickerType.id ? 'active' : ''}`}
												onClick={this.toggleDateFilterType}
												data-type={pickerType.id}
											>
												{pickerType.label}
											</div>
										);
									})}
							</div>
							<div className="current-selection">{_.get(this.data, 'selectedFilters.displayDate') || 'Please make a selection'}</div>
							<div className="calendar-container">
								{this.data.dateFilterType !== 'custom' && (
									<div className="date-options">
										{selectedPickerBody &&
											selectedPickerBody.options.map((option) => {
												const optionStartDate = option.values ? format(new Date(option.values[0]), 'MM/dd/yyyy') : '';
												const optionEndDate = option.values ? format(new Date(option.values[1]), 'MM/dd/yyyy') : '';
												const formatSelectedStart = selectedDateRange ? format(new Date(selectedStartDate), 'MM/dd/yyyy') : false;
												const formatSelectedEnd = selectedDateRange ? format(new Date(selectedEndDate), 'MM/dd/yyyy') : false;
												const startMatch = optionStartDate === formatSelectedStart;
												const endMatch = optionEndDate === formatSelectedEnd;
												return (
													<Button
														key={`picker-option-${option.id}`}
														className={`date-picker-btn neubtn ${startMatch && endMatch ? 'active' : ''}`}
														data-option={option.id}
														onClick={() => this.handleReportDateSelection(option.id)}
													>
														{option.label}
													</Button>
												);
											})}
									</div>
								)}
								{this.data.dateFilterType === 'custom' && (
									<div id="datePickerContainer" className={this.data.dateFilterType === 'custom' ? '' : 'hidden'} />
								)}
							</div>
							<div className="date-picker-footer btn-ctn">
								<Button className="round-btn" large onClick={this.applyDateFilter}>
									Apply
								</Button>
							</div>
						</Page>
					</View>
				</Popup>
			</div>
		);
	}
}
