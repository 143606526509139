import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '../../../../utils/State';
import { autoBind } from '../../../../utils/GeneralUtils';
import MembersData from '../members-data/MembersData';
import './groups-data.scss';
import AdminService from '../../../../services/AdminService';

@observer
export default class GroupsData extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			expandedGroups: this.props.groups.filter((x) => !!x.members).map((x) => x.groupId)
		});
		autoBind(this);
	}

	async toggleGroup(evt) {
		const isDisabled = evt.currentTarget.getAttribute('data-disabled') === 'true';
		const groupUid = evt.currentTarget.getAttribute('data-groupuid');
		const currentExpandedGroups = _.cloneDeep(this.data.expandedGroups);
		const isExpanded = isDisabled || currentExpandedGroups.indexOf(groupUid) > -1;
		if (isExpanded) {
			const newExpandedGroups = currentExpandedGroups.filter((x) => x !== groupUid);
			this.data.expandedGroups = newExpandedGroups;
			return;
		}
		try {
			await this.props.getGroupMembers(groupUid);
			currentExpandedGroups.push(groupUid);
			this.data.expandedGroups = currentExpandedGroups;
		} catch (err) {
			console.log({ err });
		}
	}

	async getUpdatedMembers(groupUid) {
		try {
			await this.props.getGroupMembers(groupUid);
		} catch (err) {
			console.log({ err });
		}
	}

	bulkMessageInit(evt) {
		evt.stopPropagation();
		const type = evt.currentTarget.getAttribute('data-type');
		const groupUid = evt.currentTarget.getAttribute('data-groupuid');
		const groupData = this.props.groups.find((x) => x.groupUid === groupUid);
		const groupMembers = groupData.members;
		const isSelected = evt.currentTarget.getAttribute('data-status');
		if (isSelected === 'true') {
			AdminService.messagesBulkRemoveType(groupMembers, type, true);
		} else {
			AdminService.messagesBulkAddType(groupMembers, type);
		}
	}

	render() {
		const { groups } = this.props;
		const { expandedGroups } = this.data;
		return (
			<div className="groups-container">
				{groups.map((group) => {
					const hasMembers = _.get(group, 'members', []).length > 0;
					const userExpanded = expandedGroups.indexOf(group.groupUid) > -1;
					const isExpanded = hasMembers && userExpanded;
					const notificationOptions = AdminService.getMessagingOptions(group.members || []);
					return (
						<div className="card" key={`member-grouping-${group.groupUid}`}>
							<div
								data-groupuid={group.groupUid}
								key={`${group.orgId}-${group.groupId}`}
								className={`group-container ${isExpanded ? 'card-header' : 'card-header closed'}`}
								onClick={this.toggleGroup}
								data-disabled={group.memberCount < 1}
							>
								<div className="group-info-container">
									<div className="group-meta-data-container">
										<p className="group-title">
											<span className="data">{group.groupDisplayName || group.groupId}</span> - {group.groupName}
										</p>
										<p className="group-info">Organization: {group.orgName}</p>
										<p className="group-info">{group.memberCount} Members</p>
									</div>
									{notificationOptions && (
										<div className="bulk-notification-options">
											{notificationOptions
												.filter((option) => !option.disabled)
												.map((option) => {
													return (
														<div
															key={`notification-option-${option.type}-${group.groupUid}`}
															className="bulk-notification-option"
															data-status={option.pending}
															data-type={option.type}
															data-groupuid={group.groupUid}
															onClick={this.bulkMessageInit}
														>
															<i className={option.pending ? 'fad fa-check-circle' : 'fal fa-circle'} />
															{`${option.type.toLowerCase()} All (${option.qtyAvailable})`}
														</div>
													);
												})}
										</div>
									)}
								</div>
								{!isExpanded && <i className={`fad fa-plus-square animated ${group.memberCount < 1 ? 'disabled' : 'blue'}`} />}
								{isExpanded && <i className="fad fa-minus-square purple animated" />}
							</div>
							{isExpanded && (
								<MembersData
									members={group.members}
									refreshMembers={this.getUpdatedMembers}
									groupUid={group.groupUid}
									groupData={group}
								/>
							)}
						</div>
					);
				})}
				{groups && groups.length < 1 && <div className="group-empty-state">No Members meet current criteria</div>}
			</div>
		);
	}
}
