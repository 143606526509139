import ButtonSegment from '@/components/_EXPRESS/segmented-button/button-segment/ButtonSegment';
import SegmentedButton from '@/components/_EXPRESS/segmented-button/SegmentedButton';
import { observable, observer } from '@/utils/State';
import React, { Component } from 'react';

@observer
export default class FontStyle extends Component {
	constructor(props) {
		super(props);
		this.data = observable({});
	}

	setStyle(value) {
		let { onChange, fontStyle } = this.props;
		let styles = fontStyle.split(' ');
		const index = styles.indexOf(value);
		if (index > -1) {
			styles.splice(index, 1);
		} else {
			styles.push(value);
		}

		onChange(styles.join(' '));
	}

	setDecoration(value) {
		let { onDecorationChange, textDecoration } = this.props;
		let decorations = textDecoration.split(' ');

		const index = decorations.indexOf(value);
		if (index > -1) {
			decorations.splice(index, 1);
		} else {
			decorations.push(value);
		}

		onDecorationChange(decorations.join(' '));
	}

	render() {
		const { fontStyle, textDecoration } = this.props;
		let styles = fontStyle.split(' ');
		return (
			<div className="vbox property-section">
				<div className="property-label">Style</div>
				<div className="property-edit">
					<SegmentedButton>
						<ButtonSegment active={styles.includes('bold')} onClick={() => this.setStyle('bold')}>
							<i className="fa fa-bold"></i>
						</ButtonSegment>
						<ButtonSegment active={styles.includes('italic')} onClick={() => this.setStyle('italic')}>
							<i className="fa fa-italic"></i>
						</ButtonSegment>
						<ButtonSegment active={textDecoration.includes('underline')} onClick={() => this.setDecoration('underline')}>
							<i className="fa fa-underline"></i>
						</ButtonSegment>
						<ButtonSegment active={textDecoration.includes('line-through')} onClick={() => this.setDecoration('line-through')}>
							<i className="fa fa-strikethrough"></i>
						</ButtonSegment>
					</SegmentedButton>
				</div>
			</div>
		);
	}
}
