import React, { useState } from 'react';
import ExpressAPI from '@/services/ExpressAPI';
import './api-actions.scss';

export default function () {
	const [error, setError] = useState(false);

	async function sendRecuroFile() {
		try {
			return await ExpressAPI.manualUploadRecuro();
		} catch (err) {
			console.log('err sending recuro file:: ', err);
			setError(true);
			setTimeout(() => setError(false), 10000);
		}
	}

	async function sendTeladocFile() {
		try {
			return await ExpressAPI.manualUploadTeladoc();
		} catch (err) {
			console.log('err sending teladoc file:: ', err);
			setError(true);
			setTimeout(() => setError(false), 10000);
		}
	}

	return (
		<div className="api-actions-page">
			<div className="api-actions-header">
				<div
					className="list-btn ios button button-small button-fill"
					onClick={sendTeladocFile}
				>
					<i className="fad fa-plus-square" />
					Send Teladoc File
				</div>
				<div
					className="list-btn ios button button-small button-fill"
					onClick={sendRecuroFile}
				>
					<i className="fad fa-plus-square" />
					Send Recuro File
				</div>
			</div>
			<div>
				{error && <p>Sorry, Internal Server Error Error Generating File</p>}
			</div>
		</div>
	);
}
